import React, { useState, useEffect } from 'react';
import Radio from 'styleguide/src/components/Radio';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import classNames from 'classnames';
import './main.less';

interface Props {
  className?: string;
  'data-cy'?: string;
  errorText?: string;
  label?: string;
  onChange: (value: string) => void;
  options: Selectable[];
  required?: boolean;
  showError?: boolean;
  value?: string;
}

export const ImageSelect = (props: Props) => {
  const {
    className,
    'data-cy': dataCy = 'image-select',
    errorText,
    label,
    onChange,
    options,
    required,
    showError,
    value,
  } = props;

  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleOnChange = (newValue: string) => {
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const ImageOption = (option: Selectable) => (
    <Radio
      checked={selectedValue === option.value}
      className="image-option"
      data-cy="image-option"
      showError={showError}
      onChange={() => handleOnChange(option.value.toString())}
    >
      <img
        data-cy={option.value}
        alt={option.value.toString()}
        src={typeof option.label === 'string' ? option.label : undefined}
      />
    </Radio>
  );

  const classes = classNames(className, 'image-select', {
    'has-error': showError,
  });

  return (
    <div className={classes} data-cy={dataCy}>
      {label && (
        <label>
          {label}
          {required && ' *'}
        </label>
      )}
      {showError && errorText && <p className="error-red">{errorText}</p>}
      <div className="image-options" data-cy="all-images">
        {options.map(ImageOption)}
      </div>
    </div>
  );
};

export default ImageSelect;
