import React, { Component } from 'react';
import { Selectable } from '@vestahealthcare/common/enums/Enum';

// Copied + modified from:
// https://github.com/JedWatson/react-select/blob/master/examples/src/components/CustomComponents.js
interface Props {
  className?: string;
  isFocused?: boolean;
  isSelected?: boolean;
  onFocus: (option: Selectable, event: any) => void;
  onSelect: (option: Selectable, event: any) => void;
  option: Selectable;
}

export default class MultiSelectOption extends Component<Props> {
  handleMouseDown(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();
    event.stopPropagation();
    const { onSelect, option } = this.props;
    onSelect(option, event);
  }

  handleMouseEnter(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const { onFocus, option } = this.props;
    onFocus(option, event);
  }

  handleMouseMove(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const { isFocused, onFocus, option } = this.props;
    if (isFocused) {
      return;
    }
    onFocus(option, event);
  }

  render() {
    const { className, isSelected, option } = this.props;

    return (
      <div
        className={className}
        onMouseDown={(event) => this.handleMouseDown(event)}
        onMouseEnter={(event) => this.handleMouseEnter(event)}
        onMouseMove={(event) => this.handleMouseMove(event)}
        title={option.value.toString()}
      >
        {option.label || option.value}
        {isSelected && <i className="fa fa-check text-right" />}
      </div>
    );
  }
}
