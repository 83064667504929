import { RosterFileMetadata } from './RosterFileMetadata';
import { make } from './Model';

export class RosterFile {
  id = 0;

  rosterStatus = '';

  currentProcessingOrg = '';

  rawArchive?: RosterFileMetadata;

  processedArchive?: RosterFileMetadata;

  createdAt?: string;

  updatedAt?: string;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.rawArchive = make(obj.rawArchive, RosterFileMetadata);
    this.processedArchive = make(obj.processedArchive, RosterFileMetadata);
  }

  get processedFileName() {
    return (
      this.processedArchive?.originalFilename ||
      this.processedArchive?.s3Location.substring(
        this.processedArchive?.s3Location.lastIndexOf('/') + 1,
      )
    );
  }

  get rawFileName() {
    return (
      this.rawArchive?.originalFilename ||
      this.rawArchive?.s3Location.substring(
        this.rawArchive?.s3Location.lastIndexOf('/') + 1,
      )
    );
  }
}
