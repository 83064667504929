import { ExternalIdSource } from './ExternalIdSource';
import { make } from './Model';

export class MemberExternalId {
  id = 0;

  memberId = 0;

  externalId = '';

  externalIdSource: ExternalIdSource;

  createdAt = 0;

  updatedAt = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.externalIdSource = make(obj.externalIdSource || {}, ExternalIdSource);
  }
}

export default MemberExternalId;
