import moment from 'moment';

import { DATE_FORMAT } from '../utils/constants';
import { Employee } from './Employee';
import { make } from './Model';
import { Organization } from './Organization';

export class MemberReferral {
  id = 0;

  memberId = 0;

  organization: Organization;

  externalId = '';

  enrollmentStart = moment().format(DATE_FORMAT);

  enrollmentEnd?: string;

  createdBy?: Employee;

  createdAt = 0;

  updatedBy?: Employee;

  updatedAt = 0;

  discontinuedAt?: number;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdBy = obj.createdBy && make(obj.createdBy, Employee);
    this.updatedBy = obj.updatedBy && make(obj.updatedBy, Employee);
    this.organization = make(obj.organization, Organization);
  }
}

export default MemberReferral;
