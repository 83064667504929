import React from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { Table } from 'styleguide-v2';
import { MemberMedication } from '@vestahealthcare/common/models';

interface Props {
  medications: MemberMedication[];
}

const MEDICATIONS_COLUMNS = () => [
  {
    headerName: translate(
      'memberProfile.clinicalSummary.tables.medications.name',
    ),
    field: 'name',
  },
  {
    headerName: translate(
      'memberProfile.clinicalSummary.tables.medications.directions',
    ),
    field: 'directions',
  },
  {
    headerName: translate(
      'memberProfile.clinicalSummary.tables.medications.dispense',
    ),
    field: 'dispense',
  },
  {
    headerName: translate(
      'memberProfile.clinicalSummary.tables.medications.refills',
    ),
    field: 'refills',
  },
  {
    headerName: translate(
      'memberProfile.clinicalSummary.tables.medications.source',
    ),
    field: 'source',
  },
  {
    headerName: translate(
      'memberProfile.clinicalSummary.tables.medications.action',
    ),
    field: 'action',
  },
];

export const MedicationsTable = (props: Props) => (
  <Table
    config={{
      columns: MEDICATIONS_COLUMNS(),
      compact: true,
      data: props.medications,
      paginationOptions: [-1],
    }}
  />
);

export default MedicationsTable;
