import classnames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { makeStyles } from '@mui/styles';

import { MedicalEquipment } from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { PatientMedicalEquipment } from '@vestahealthcare/common/models';

import { Button, CollapsableSidebar, IconButton, Select } from 'styleguide-v2';

const useStyles = makeStyles({
  deleteIcon: {
    height: '4.5rem',
  },
  marginTop: {
    marginTop: '2rem!important',
  },
});
interface Props {
  dmes?: PatientMedicalEquipment[];
  onClose: () => void;
  onSubmit: (params: PatientMedicalEquipment[]) => Promise<boolean>;
  open: boolean;
}

export const DMEAddModal = ({
  dmes: currentDMEs,
  onClose,
  onSubmit,
  open,
}: Props) => {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [dmes, setDMEs] = useState<PatientMedicalEquipment[]>([]);

  useEffect(() => {
    if (open) {
      setDMEs([{} as PatientMedicalEquipment]);
    }
  }, [open]);

  const doSubmit = async () => {
    setSubmitted(true);
    if (!dmes.find((dme) => !dme.medicalEquipment)) {
      setLoading(true);
      try {
        await onSubmit(dmes);
      } finally {
        setSubmitted(false);
        setLoading(false);
        onClose();
      }
    }
  };

  const getEquipmentOption = (item?: MedicalEquipment) =>
    Enum.toSelectable(
      MedicalEquipment.asArray.filter(
        (equipment) =>
          item?.value === equipment.value ||
          (!dmes.find(
            (pme) =>
              pme?.medicalEquipment &&
              pme?.medicalEquipment?.value === equipment?.value,
          ) &&
            !currentDMEs?.find(
              (pme) =>
                pme?.medicalEquipment &&
                pme?.medicalEquipment?.value === equipment?.value,
            )),
      ),
    );

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={
        <h2>
          {translate('memberProfile.clinicalSummary.tables.dme.addModal')}
        </h2>
      }
      size={450}
    >
      <CollapsableSidebar.Body>
        <div className="grid-wrapper fit">
          {dmes.map((item, index) => (
            <Fragment key={`dme-id-${item?.medicalEquipment?.value || index}`}>
              <Select
                className="grid-span-11"
                data-cy="add-dme-option"
                error={submitted && !item.medicalEquipment}
                items={getEquipmentOption(item?.medicalEquipment)}
                label={
                  index === 0
                    ? translate('memberProfile.clinicalSummary.tables.dme.dme')
                    : ''
                }
                onChange={(item?: Selectable) => {
                  dmes[index] = {
                    medicalEquipment: item
                      ? MedicalEquipment.byKey[item.value]
                      : undefined,
                  } as PatientMedicalEquipment;
                  setDMEs([...dmes]);
                }}
                required
                value={
                  item?.medicalEquipment
                    ? Enum.toSelectable([item.medicalEquipment])[0]
                    : undefined
                }
              />
              <IconButton
                className={classnames(
                  'grid-span-1',
                  styles.deleteIcon,
                  index === 0 && styles.marginTop,
                )}
                data-cy="add-dme-delete-row"
                disabled={dmes.length < 2}
                size="small"
                onClick={() => {
                  dmes.splice(index, 1);
                  setDMEs([...dmes]);
                }}
              >
                <DeleteOutlineIcon fontSize="large" />
              </IconButton>
            </Fragment>
          ))}
          <Button
            className="grid-span-12"
            color="tertiary"
            icon={<AddIcon />}
            onClick={() => setDMEs([...dmes, {} as PatientMedicalEquipment])}
          >
            {translate('memberProfile.clinicalSummary.button.dme')}
          </Button>
        </div>
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button color="tertiary" data-cy="add-dme-close" onClick={onClose}>
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="add-dme-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default DMEAddModal;
