import bandaid from 'dash/static/images/Bandaid@2x.png';
import hospital from 'dash/static/images/Hospital@2x.png';
import info from 'dash/static/images/Info@2x.png';
import logo from 'dash/static/images/Logo@2x.png';
import markdownExample from 'dash/static/images/MarkdownExample.png';
import meds from 'dash/static/images/Meds@2x.png';
import mentalHealth from 'dash/static/images/MentalHealth.svg';
import news from 'dash/static/images/News@2x.png';
import vestaLogo from 'dash/static/images/VestaLogo.svg';

const images = {
  bandaid,
  hospital,
  info,
  logo,
  markdownExample,
  mentalHealth,
  meds,
  news,
  vestaLogo,
};

type ImageType = { [key in keyof typeof images]: string };

export default images as ImageType;
