import React from 'react';
import { FieldProps } from 'react-jsonschema-form';
import { JSONSchema6 } from 'json-schema';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

export interface TitleFieldJSONSchema extends JSONSchema6 {
  children?: string[];
}

export interface TitleFieldProps extends FieldProps {
  schema: TitleFieldJSONSchema;
}

const TitleOnlyField = (props: TitleFieldProps) => {
  const {
    formContext: { formData, readonly },
    schema: { title, children },
  }: TitleFieldProps = props;
  const showEmpty = !!children && !children.some((child) => !!formData[child]);

  return (
    <>
      <label className="control-label">{title}</label>
      {showEmpty && readonly && <p>{EMPTY}</p>}
    </>
  );
};

export default TitleOnlyField;
