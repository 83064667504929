import React, { useState } from 'react';
import { EditIcon, Table, AddIcon, Button, EmptyState } from 'styleguide';
import { translate } from '@vestahealthcare/common/i18n';
import { MemberGoal, Patient } from '@vestahealthcare/common/models';
import { EditGoalModal } from './EditGoalModal';
import { editMemberGoal, addMemberGoal } from 'dash/src/services/PHIPServices';
import { GoalType } from '@vestahealthcare/common/enums';
import { sortBy } from 'lodash';

interface Props {
  goals: MemberGoal[];
  member: Patient;
  onSubmit: () => void;
}

interface GoalProps {
  goal: MemberGoal;
  member: Patient;
  onSubmit: () => void;
}

export const Goal = ({ goal, member, onSubmit }: GoalProps) => {
  const [showModal, setShowModal] = useState(false);
  const editable = member.isEditable();

  const isOtherType = goal.type === GoalType.OTHER;

  return (
    <tr>
      <td>{goal.title}</td>
      <td>
        {isOtherType ? `Other - ${goal.otherType}` : goal.type.toString()}
      </td>
      <td>{goal.getTargetDateFormatted()}</td>
      <td>
        <p className="medium">{goal.status.toString()}</p>
        {!!goal.outcome && <p>{goal.outcome}</p>}
      </td>
      <td>
        {editable && (
          <EditIcon
            tag="button"
            data-cy="edit-goal"
            onClick={() => setShowModal(true)}
          />
        )}
        {showModal && (
          <EditGoalModal
            goal={goal}
            onHide={() => setShowModal(false)}
            onSave={async (params: any) => {
              await editMemberGoal(member.id, goal.id, params);
              setShowModal(false);
              onSubmit();
            }}
          />
        )}
      </td>
    </tr>
  );
};

export const Goals = ({ goals, member, onSubmit }: Props) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const editable = member.isEditable();
  return (
    <>
      <div className="h7 grid-span-6">{translate('goalsOfCare.goals')}</div>
      <div className="right-side grid-span-6">
        {editable && (
          <Button
            color="secondary"
            data-cy="add-goal"
            isPill
            onClick={() => setShowAddModal(true)}
          >
            <AddIcon className="margin-right" />
            {translate('goalsOfCare.addGoal')}
          </Button>
        )}
        {showAddModal && (
          <EditGoalModal
            onHide={() => setShowAddModal(false)}
            onSave={async (params: any) => {
              await addMemberGoal(member.id, params);
              onSubmit();
            }}
          />
        )}
      </div>
      <div className="grid-span-12">
        {goals.length ? (
          <Table>
            <thead>
              <tr>
                <th style={{ width: '18%' }}>
                  {translate('goalsOfCare.goal')}
                </th>
                <th style={{ width: '20%' }}>
                  {translate('goalsOfCare.type')}
                </th>
                <th style={{ width: '15%' }}>
                  {translate('goalsOfCare.targetDate')}
                </th>
                <th style={{ width: '40%' }}>
                  {translate('goalsOfCare.status')}
                </th>
                <th style={{ width: '6%' }} />
              </tr>
            </thead>
            <tbody>
              {sortBy(goals, 'id').map((goal) => (
                <Goal
                  key={goal.id}
                  goal={goal}
                  member={member}
                  onSubmit={onSubmit}
                />
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyState>{translate('goalsOfCare.emptyState')}</EmptyState>
        )}
      </div>
    </>
  );
};

export default Goals;
