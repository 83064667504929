import { TaskFieldChanges } from './TaskFieldChanges';
import { Employee } from './Employee';
import { make, makeArray } from './Model';

export class TaskNote {
  id = 0;

  taskId = 0;

  note?: string;

  taskFieldChanges?: TaskFieldChanges[];

  createdBy?: Employee;

  createdAt = 0;

  updatedAt = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdBy = make(this.createdBy, Employee);
    this.taskFieldChanges = makeArray(this.taskFieldChanges, TaskFieldChanges);
  }
}

export default TaskNote;
