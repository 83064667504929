import { Enum } from './Enum';
import { RiskLevel } from './RiskLevel';

// All risk levels, ORDERED BY LOWER TO HIGHER
const defaultLevels = [
  RiskLevel.NONE,
  RiskLevel.LOW,
  RiskLevel.MODERATE,
  RiskLevel.HIGH,
];

// Levels that activates a Care Track
const defaultActiveCareTrackLevels = [RiskLevel.MODERATE, RiskLevel.HIGH];

export class CareTrackType extends Enum {
  translationKey = 'careTrackType';

  static asArray: CareTrackType[] = [];

  static byKey: { [name: string]: CareTrackType } = {};

  // todo: consider to fetch risk level configuration from backend

  static readonly CEREBROVASCULAR = new CareTrackType(
    'CEREBROVASCULAR',
    defaultLevels,
    defaultActiveCareTrackLevels,
  );

  static readonly CHF_EXACERBATION = new CareTrackType(
    'CHF_EXACERBATION',
    defaultLevels,
    defaultActiveCareTrackLevels,
  );

  static readonly COPD_EXACERBATION = new CareTrackType(
    'COPD_EXACERBATION',
    defaultLevels,
    defaultActiveCareTrackLevels,
  );

  static readonly DIABETES_TYPE_II = new CareTrackType(
    'DIABETES_TYPE_II',
    defaultLevels,
    defaultActiveCareTrackLevels,
  );

  static readonly DEMENTIA = new CareTrackType(
    'DEMENTIA',
    defaultLevels,
    defaultActiveCareTrackLevels,
  );

  static readonly FALL = new CareTrackType(
    'FALL',
    defaultLevels,
    defaultActiveCareTrackLevels,
  );

  static readonly GENERAL = new CareTrackType(
    'GENERAL',
    defaultLevels,
    defaultActiveCareTrackLevels,
  );

  static readonly GI_COMPLICATION = new CareTrackType(
    'GI_COMPLICATION',
    defaultLevels,
    defaultActiveCareTrackLevels,
  );

  static readonly PRESSURE_ULCER = new CareTrackType(
    'PRESSURE_ULCER',
    defaultLevels,
    defaultActiveCareTrackLevels,
  );

  static readonly SOCIAL = new CareTrackType(
    'SOCIAL',
    defaultLevels,
    defaultActiveCareTrackLevels,
  );

  static readonly UTI_DEHYDRATION = new CareTrackType(
    'UTI_DEHYDRATION',
    defaultLevels,
    defaultActiveCareTrackLevels,
  );

  static readonly TRANSFORMS_OF_CARE = new CareTrackType(
    'TRANSITIONS_OF_CARE',
    [RiskLevel.NONE, RiskLevel.HIGH],
    [RiskLevel.HIGH],
  );

  static readonly ELEMENT_CARE = new CareTrackType(
    'ELEMENT_CARE',
    [RiskLevel.HIGH],
    [RiskLevel.HIGH],
  );

  constructor(
    public readonly value: string,
    public readonly riskLevels: RiskLevel[],
    public readonly activeLevels: RiskLevel[],
  ) {
    super();
    CareTrackType.asArray.push(this);
    CareTrackType.byKey[value] = this;
  }

  static isHiddenType(type: CareTrackType) {
    return [CareTrackType.ELEMENT_CARE].includes(type);
  }

  static isCustomType(program: string) {
    return [CareTrackType.ELEMENT_CARE.value].includes(program);
  }
}

export default CareTrackType;
