export class Observation {
  answers: string[] = [];

  form: string = '';

  key: string = '';

  title: string = '';

  type: string = '';

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  get displayAnswer() {
    return this.answers.join(', ');
  }

  get hasAnswer() {
    return this.answers.length > 0;
  }
}

export default Observation;
