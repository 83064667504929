import { useEffect, useRef } from 'react';

export const useCallbackRef = <T>(callback: T) => {
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  return savedCallback;
};

export default useCallbackRef;
