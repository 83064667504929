import { PhoneType } from '../enums/PhoneType';

export class Phone {
  number = '';

  type?: PhoneType;

  textReminderOptOut?: boolean;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.type = PhoneType.byKey[obj.type];
  }

  get isSmartphone() {
    return this.type === PhoneType.CELL;
  }

  getDisplayNumber() {
    return this.number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
}

export default Phone;
