import React, { useContext, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/styles';

import { Patient } from '@vestahealthcare/common/models';

import { Button } from 'styleguide';

import { VestaChatContext } from 'dash/src/contexts/VestaChatContext';

import { Channel } from 'stream-chat';

interface Props {
  patient: Patient;
}

const useStyles = makeStyles({
  unreadIndicator: {
    position: 'absolute',
    top: 5,
    left: 37,
    height: 10,
    width: 10,
    backgroundColor: 'red',
    borderRadius: 50,
  },
});

export const ChatButton = ({ patient }: Props) => {
  const styles = useStyles();
  const [channels, setChannels] = useState<Channel[]>();
  const { chatClient } = useContext(VestaChatContext);

  useEffect(() => {
    const fetchChannelsForMember = async () => {
      const filters = { type: 'messaging', memberId: patient.id };
      const memberChannels = await chatClient?.queryChannels(
        filters,
        {},
        { watch: false },
      );

      setChannels(memberChannels);
    };

    fetchChannelsForMember();
  }, [patient]);

  const hasChannels = channels?.filter((c) => c.data?.memberId === patient.id);
  if (!hasChannels?.length) {
    return null;
  }

  const hasUnreadMessages = hasChannels?.some((c) => c.countUnread() > 0);

  return (
    <Button
      href={`/#/chats?memberId=${patient.id}`}
      className="start-video-call-button"
      data-cy="member-chats-button"
      color="tertiary"
      btnSize="md"
    >
      <>
        <span className="start-video-call-container chat">
          <i className="fa fa-comments video-icon" />
        </span>
        <span className="video-chat-text">Chat</span>
      </>
      {hasUnreadMessages && <div className={styles.unreadIndicator} />}
    </Button>
  );
};

export default ChatButton;
