import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';
import { Colors } from 'styleguide-v2/src/styles/Colors';

type StyleProps = {
  bodyHeight: number;
};

const useStyles = makeStyles<Theme, StyleProps>({
  hitbox: {
    alignItems: 'center',
    alignSelf: 'stretch',
    cursor: 'col-resize',
    display: 'flex',
    margin: '1rem 0',
    padding: '0 1rem',
  },

  divider: {
    height: '100%',
    border: `1px solid ${Colors.lightGray}`,
  },

  dividerHover: {
    borderColor: Colors.blue,
  },

  container: {
    gridArea: 'detail',
    height: ({ bodyHeight }) => bodyHeight,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },

  childrenContainer: {
    height: '100%',
    overflowY: 'auto',
    padding: '2rem',
    width: '100%',
  },
});

type Props = {
  children?: ReactNode;
  className?: string;
  width?: number;
};

export const Detail = ({
  children,
  className,
  width: defaultWidth = 400,
}: Props) => {
  const MIN_WIDTH = 300;
  const MAX_WIDTH = document.documentElement.clientWidth - 530;
  const [bodyHeight, setBodyHeight] = useState<number>(500);

  const styles = useStyles({ bodyHeight });
  const [width, setWidth] = useState<undefined | number>(defaultWidth);
  const [separatorXPosition, setSeparatorXPosition] = useState<
    undefined | number
  >(undefined);
  const [dragging, setDragging] = useState(false);
  const [dividerClass, setDividerClass] = useState<string>();

  const onMouseDown = (e: React.MouseEvent) => {
    setSeparatorXPosition(e.clientX);
    setDragging(true);
  };

  const onTouchStart = (e: React.TouchEvent) => {
    setSeparatorXPosition(e.touches[0].clientX);
    setDragging(true);
  };

  const onMove = (clientX: number) => {
    if (dragging && width && separatorXPosition) {
      let newWidth = 0;
      const bodyWidth = window.document.body.clientWidth;
      if (bodyWidth > 1680) {
        const newSeparatorXPosition = clientX - (bodyWidth - 1680) / 2;
        newWidth = 1680 - newSeparatorXPosition + 11;
        setSeparatorXPosition(clientX - (bodyWidth - 1680) / 2);
      } else {
        newWidth = bodyWidth - separatorXPosition + 11;
        setSeparatorXPosition(clientX);
      }
      if (newWidth < MIN_WIDTH) {
        setWidth(MIN_WIDTH);
      } else if (newWidth > MAX_WIDTH) {
        setWidth(MAX_WIDTH);
      } else {
        setWidth(newWidth);
      }
    }
  };

  const onMouseMove = (e: MouseEvent) => {
    e.preventDefault();
    onMove(e.clientX);
  };

  const onMouseUp = () => {
    setDragging(false);
  };

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  });

  setTimeout(() => {
    setBodyHeight(document.getElementById('panel-body')?.clientHeight || 500);
  }, 0);

  return (
    <div className={classnames(className, styles.container)} style={{ width }}>
      <div
        className={styles.hitbox}
        role="presentation"
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        onTouchEnd={onMouseUp}
        onMouseEnter={() => setDividerClass(styles.dividerHover)}
        onMouseLeave={() => setDividerClass(undefined)}
      >
        <div className={classnames(styles.divider, dividerClass)} />
      </div>
      <div className={styles.childrenContainer}>{children}</div>
    </div>
  );
};
