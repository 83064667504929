import { Enum } from './Enum';

export class DocumentType extends Enum {
  translationKey = 'documentType';

  static asArray: DocumentType[] = [];

  static byKey: { [name: string]: DocumentType } = {};

  static readonly CARE_PLAN = new DocumentType('CARE_PLAN');

  static readonly HIE = new DocumentType('HIE');

  constructor(public readonly value: string) {
    super();
    DocumentType.asArray.push(this);
    DocumentType.byKey[value] = this;
  }
}

export default DocumentType;
