export class OrganizationStatuses {
  name = '';

  id = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default OrganizationStatuses;
