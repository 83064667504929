import { Moment } from 'moment';

import { Brand, FacilityType, States } from '../enums';
import { Employee } from './Employee';
import { InsurancePlan } from './InsurancePlan';
import { make, makeArray, makeUnix } from './Model';
import { Organization } from './Organization';

export class Facility {
  id = 0;

  brand: Brand;

  ecwId = 0;

  insurances: InsurancePlan[];

  name = '';

  npi = 0;

  organizations: Organization[];

  state: States;

  type: FacilityType;

  updatedAt: Moment;

  updatedBy: Employee;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.brand = Brand.byKey[obj.brandId];
    this.insurances = makeArray(obj.insurancePlans, InsurancePlan);
    this.organizations = makeArray(obj.organizations, Organization);
    this.state = States.byKey[obj.state];
    this.type = FacilityType.byKey[obj.type];
    this.updatedAt = makeUnix(obj.updatedAt) as Moment;
    this.updatedBy = make(obj.updatedBy, Employee);
  }
}

export default Facility;
