import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { makeStyles } from '@mui/styles';

import { Colors } from 'styleguide-v2/src/styles/Colors';
import { Fonts } from 'styleguide-v2/src/styles/Variables';

type Props = {
  className?: string;
  label: string;
  value?: ReactNode;
  subvalue?: ReactNode;
  type?: 'primary' | 'secondary';
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
    gap: '0.25rem',
  },
  labelPrimary: {
    color: Colors.iconGray,
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: `calc(${Fonts.fontSize} * 0.625)`,
    letterSpacing: '1px',
    marginBottom: '0.25rem',
  },
  labelSecondary: {
    color: Colors.textBlack,
    fontWeight: 500,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    marginBottom: '0.25rem',
  },
  value: {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    fontWeight: 500,
    textWrap: 'wrap',
  },
  subvalue: {
    color: Colors.iconGray,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
  },
});

export const Data = ({
  className,
  label,
  value,
  subvalue,
  type = 'primary',
}: Props) => {
  const styles = useStyles();

  return (
    <div className={classNames(className, styles.container)}>
      {label && (
        <span
          className={classNames(
            type === 'primary' && styles.labelPrimary,
            type === 'secondary' && styles.labelSecondary,
          )}
        >
          {label}
        </span>
      )}
      {value && <span className={styles.value}>{value}</span>}
      {subvalue && <span className={styles.subvalue}>{subvalue}</span>}
    </div>
  );
};

export default Data;
