import { BaseEnum } from './BaseEnum';
import { CareTeamMemberSpeciality } from './CareTeamMemberSpeciality';
import { CareTeamMemberType } from './CareTeamMemberType';
import { CareTeamPerson } from './CareTeamPerson';
import {
  CareTeamMemberTypeHelper,
  getTypeWithSubType,
  getTypeWithSubTypeLabel,
} from './Helpers/CareTeamMemberType';
import { make, makeArray } from './Model';
import { Patient } from './Patient';

export class CareTeamMember implements CareTeamMemberTypeHelper {
  id = 0;

  active = false;

  aideType?: BaseEnum;

  campaignEnabled = false;

  careTeamPerson: CareTeamPerson;

  emergencyContact = false;

  hciNotifications = false;

  healthCareProxy = false;

  healthCareProxyFileLocation?: string;

  inactiveReason?: BaseEnum;

  inactiveReasonOther = '';

  member: Patient;

  notes = '';

  otherRelationship?: BaseEnum;

  phiAccess = false;

  phiExceptions = false;

  preferred = false;

  relationship?: BaseEnum;

  relationshipOther = '';

  specialities?: CareTeamMemberSpeciality[];

  type: CareTeamMemberType;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.careTeamPerson = make(obj.careTeamPerson, CareTeamPerson);
    this.member = make(obj.member, Patient);
    this.relationship = make(obj.relationship, BaseEnum);
    this.otherRelationship = make(obj.otherRelationship, BaseEnum);
    this.aideType = make(obj.aideType, BaseEnum);
    this.type = make(obj.type, CareTeamMemberType);
    this.specialities = makeArray(obj.specialities, CareTeamMemberSpeciality);
    this.inactiveReason = make(obj.inactiveReason, BaseEnum);
  }

  get typeWithSubType(): {
    type?: CareTeamMemberType;
    subtype?: string | undefined;
  } {
    return getTypeWithSubType(this);
  }

  get typeWithSubTypeLabel(): string {
    return getTypeWithSubTypeLabel(getTypeWithSubType(this));
  }

  static sortPreferred(
    { preferred: a, careTeamPerson: { fullName: aName } }: CareTeamMember,
    { preferred: b, careTeamPerson: { fullName: bName } }: CareTeamMember,
  ) {
    if (a) return -1;
    if (b) return 1;
    return aName.localeCompare(bName);
  }
}

export default CareTeamMember;
