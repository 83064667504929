import { Moment } from 'moment';

import {
  Brand,
  CollectionMethod,
  EncounterType,
  EventReporterType,
  InsuranceQualification,
  Language,
  MemberContactMethodType,
  MemberContactTimeType,
  MemberRisk,
  MemberStatus,
  PatientGender,
  PatientLivingArrangement,
  PhoneType,
  ReasonWithdraw,
  ResidenceType,
  States,
} from '@vestahealthcare/common/enums';
import {
  BaseEnum,
  CareQuestion,
  DBEnum,
  EmployeeGroup,
  HealthReport,
  MemberContactInfo,
  MemberContactInfoTimes,
  MemberEligibility,
  MemberEncountersSummary,
  MemberExternalId,
  MemberInsurance,
  MemberInsuranceQualification,
  MemberPhone,
  MemberReferral,
  MemberView,
  MemberWarning,
  MemberWarningCategory,
  MemberWarningDetail,
  Patient,
  PatientSearch,
  ProgramExtension,
  ProgramExtensionStatus,
} from '@vestahealthcare/common/models';
import {
  DATE_FORMAT,
  LOCAL_TIME_FORMAT,
} from '@vestahealthcare/common/utils/constants';

import { DatesTimeItem } from 'dash/src/components/EditDateTimes';
import Api, {
  PaginatedResponse,
  PaginationParams,
} from 'dash/src/services/Api';

interface Device {
  deviceName: string;
  setupCode: string;
  member?: Patient;
  patient?: Patient;
}

export interface PatientServiceParams extends PaginationParams {
  customerId?: number;
  dateFrom?: Moment;
  dateTo?: Moment;
  name?: string;
}

// New patient search
export interface PatientSearchParams extends PaginationParams {
  queryString?: string;
  fullName?: string;
  id?: number[];
  rnOwnerId?: number[];
  npOwnerId?: number[];
  status?: MemberStatus[];
  state?: States[];
  language?: Language[];
  programConfigurationId?: number[];
  programExtensionId?: number[];
  programExtensionStatus?: string[];
  referralSourceId?: number[];
  riskLevel?: MemberRisk[];
  dateOfBirth?: Moment;
}

export interface MemberViewParams extends PaginationParams {
  brandId?: string[];
  careCoordinatorId?: number[];
  carePlanGroupId?: number;
  count?: boolean;
  emptyRiskLevel?: boolean;
  engagementOwnerId?: number[];
  excludedReferralSourceId?: number[];
  excludedState?: States[];
  fullName?: string;
  hasCareTeam?: boolean;
  healthCoachId?: number[];
  hciReporter?: boolean;
  hciReporterStatus?: string[];
  hciReporterMethod?: MemberContactMethodType[];
  id?: number[];
  language?: Language[];
  npOwnerId?: number[];
  podManagerId?: number[];
  programExtensionId?: number[];
  programExtensionStatus?: string[];
  referralSourceId?: number[];
  riskLevel?: MemberRisk[];
  rnOwnerId?: number[];
  state?: States[];
  status?: MemberStatus[];
  vitalCollectionMethod?: CollectionMethod[];
  wasEverOnboarded?: boolean;
}

export type BulkUpdatePEChange = {
  programExtensionStatus?: ProgramExtensionStatus;
  programExtensionId: number;
  opt: 'ADD' | 'REMOVE' | 'EDIT';
};

export interface BulkUpdateParams {
  careCoordinatorIds?: number[];
  carePlanGroupId?: number | null;
  engagementOwnerIds?: number[];
  healthCoachIds?: number[];
  npOwnerId?: number;
  podManagerId?: number;
  programConfigurationId?: number;
  programExtensions: BulkUpdatePEChange[];
  riskLevel?: MemberRisk | null;
  rnOwnerId?: number;
  status?: MemberStatus;
  worklistGroupId?: EmployeeGroup;
}

interface MemberEncountersSummaryParam extends PaginationParams {
  rnOwnerId?: number;
  npOwnerId?: number;
  type: EncounterType[];
  minTotalDuration?: number;
  minProviderDuration?: number;
  maxTotalDuration?: number;
  maxProviderDuration?: number;
  startDate?: string;
  endDate?: string;
  memberStatus?: MemberStatus;
}

export interface CreateParam {
  address?: string;
  address2?: string;
  birthDate: Moment;
  city?: string;
  referral: number;
  externalId?: string;
  firstName: string;
  gender?: PatientGender;
  language?: Language;
  lastName: string;
  phoneNumber: string;
  phoneType: string;
  phoneNumber2: string;
  phoneType2: string;
  phoneNumber3: string;
  phoneType3: string;
  phoneNumber4: string;
  phoneType4: string;
  state: States;
  zipCode?: string;
  test?: boolean;
}

export interface PatientParam {
  authenticationPhone?: string | null;
  address?: {
    address1?: string;
    address2?: string;
    city?: string;
    zipCode?: string;
  };
  birthDate?: Moment;
  brandId?: string;
  deceasedDate?: Moment;
  carePlanGroup?: number | null;
  careTeamInfo?: string;
  engagementOwnerIds?: number[];
  expectedWeeklyHealthReports?: number | null;
  externalId?: string;
  firstName?: string;
  gender?: {
    type?: string;
    comment?: string;
  };
  healthCoachIds?: number[];
  hciNotifications?: boolean;
  hciReporter?: boolean;
  hciReporterMethod?: MemberContactMethodType | null;
  isTest?: boolean;
  language?: string;
  languages?: string[];
  lastName?: string;
  livingArrangement?: string[];
  livingArrangementOther?: string;
  lockedBrand?: boolean;
  careCoordinatorIds?: number[];
  owner?: number;
  npOwner?: number;
  notes?: string;
  phoneNumber?: string;
  podManager?: number;
  reasonWithdraw?: ReasonWithdraw;
  reportingStartDate?: Moment;
  residenceType?: string;
  residenceTypeOther?: string;
  riskLevel?: string | null;
  selfDirecting?: boolean;
  status?: string;
  strengthsAndBarriers?: string;
  virtualVisitDate?: Moment | null;
  virtualVisitStatus?: string | null;
  virtualVisitHostName?: string;
  virtualVisitHostRelationship?: string;
  virtualVisitHostRelationshipOther?: string | null;
  virtualVisitHostPhoneNumber?: string;
  virtualVisitHasTime?: boolean;
  worklistGroupId?: number | null;
}

interface MemberEncountersSummaryResponse
  extends PaginatedResponse<MemberEncountersSummary> {}
interface PatientsResponse extends PaginatedResponse<Patient> {}
interface MemberResponse extends PaginatedResponse<MemberView> {}
interface HealthRecordsResponse extends PaginatedResponse<HealthReport> {}

export const createPatient = async (
  data: CreateParam,
  skipHoneycomb = false,
) => {
  const params = {
    ...data,
    birthDate: data.birthDate.format(DATE_FORMAT),
    gender: data.gender?.valueOf(),
    language: data.language?.valueOf(),
    state: data.state?.valueOf(),
  };

  const { patient } = await Api.postv2JSON(
    `/patients${skipHoneycomb ? '?skipHC=true' : ''}`,
    params,
  );

  if (skipHoneycomb) {
    return new Patient(patient);
  }
};

export const validatePatient = async (data: CreateParam) => {
  const params = {
    ...data,
    birthDate: data.birthDate.format(DATE_FORMAT),
    gender: data.gender?.valueOf(),
    language: data.language?.valueOf(),
    state: data.state?.valueOf(),
  };
  const { validationResult } = await Api.postv2JSON(
    '/patients/validate',
    params,
  );

  const response: ValidationResponse = {
    errors: [],
    warnings: [],
  };

  validationResult.items.forEach((element: ValidatedRow) => {
    switch (element.type) {
      case 'INVALID':
      case 'DUPLICATE_PARAM':
        response.errors.push(element);
        break;
      case 'DUPLICATE_ENTITY':
      case 'WARNING':
        response.warnings.push(element);
        break;
      default:
        break;
    }
  });

  return response;
};

export const searchPatients = async (
  params: PatientSearchParams,
): Promise<PatientsResponse> => {
  const {
    riskLevel,
    status,
    state,
    language,
    dateOfBirth,
    ...restParams
  } = params;
  const convertedParams = {
    // Convert enums
    riskLevel: riskLevel?.map((item) => item.valueOf()),
    status: status?.map((item) => item.valueOf()),
    state: state?.map((item) => item.valueOf()),
    language: language?.map((item) => item.valueOf()),
    dateOfBirth: dateOfBirth?.format(DATE_FORMAT),
    ...restParams,
  };

  const {
    patients: { items, pagination },
  } = await Api.getv2('/patients', convertedParams);

  return {
    items: items.map((patient: any) => new Patient(patient)),
    pagination,
  };
};

export type PatientSearchParamsV2 = PaginationParams & {
  queryString: string;
};

export const searchPatientsV2 = async (
  params: PatientSearchParamsV2,
): Promise<PaginatedResponse<PatientSearch>> => {
  const {
    patients: { items, pagination },
  } = await Api.getv2('/patients/search', params);

  return {
    items: items.map((patient: any) => new PatientSearch(patient)),
    pagination,
  };
};

export const getMembersView = async (
  params: MemberViewParams,
): Promise<MemberResponse> => {
  const {
    excludedState,
    hciReporterMethod,
    language,
    programExtensionStatus,
    riskLevel,
    state,
    status,
    vitalCollectionMethod,
    ...restParams
  } = params;
  const convertedParams = {
    // Convert enums
    excludedState: excludedState?.map((item) => item.valueOf()),
    hciReporterMethod: hciReporterMethod?.map(({ value }) => value),
    language: language?.map((item) => item.valueOf()),
    programExtensionStatus: programExtensionStatus?.map((item) =>
      item.valueOf(),
    ),
    riskLevel: riskLevel?.map((item) => item.valueOf()),
    state: state?.map((item) => item.valueOf()),
    status: status?.map((item) => item.valueOf()),
    vitalCollectionMethod: vitalCollectionMethod?.map((item) => item.valueOf()),
    ...restParams,
  };

  const {
    members: { items, pagination },
  } = await Api.getv2('/patients/views/members-page', convertedParams);

  return {
    items: items.map((patient: any) => new MemberView(patient)),
    pagination,
  };
};

export const getMembersViewIds = async (
  params: MemberViewParams,
): Promise<Number[]> => {
  const {
    excludedState,
    hciReporterMethod,
    language,
    programExtensionStatus,
    riskLevel,
    state,
    status,
    vitalCollectionMethod,
    ...restParams
  } = params;
  const convertedParams = {
    // Convert enums
    excludedState: excludedState?.map((item) => item.valueOf()),
    hciReporterMethod: hciReporterMethod?.map(({ value }) => value),
    language: language?.map((item) => item.valueOf()),
    programExtensionStatus: programExtensionStatus?.map((item) =>
      item.valueOf(),
    ),
    riskLevel: riskLevel?.map((item) => item.valueOf()),
    state: state?.map((item) => item.valueOf()),
    status: status?.map((item) => item.valueOf()),
    vitalCollectionMethod: vitalCollectionMethod?.map((item) => item.valueOf()),
    ...restParams,
  };

  const { ids } = await Api.getv2(
    '/patients/views/members-page-ids',
    convertedParams,
  );
  return ids;
};

const parseDownloadMemberParams = ({
  excludedState,
  hciReporterMethod,
  language,
  programExtensionStatus,
  riskLevel,
  state,
  status,
  vitalCollectionMethod,
  ...restParams
}: MemberViewParams) => ({
  excludedState: excludedState?.map((item) => item.valueOf()),
  hciReporterMethod: hciReporterMethod?.map(({ value }) => value),
  language: language?.map((item) => item.valueOf()),
  programExtensionStatus: programExtensionStatus?.map((item) => item.valueOf()),
  riskLevel: riskLevel?.map((item) => item.valueOf()),
  state: state?.map((item) => item.valueOf()),
  status: status?.map((item) => item.valueOf()),
  vitalCollectionMethod: vitalCollectionMethod?.map((item) => item.valueOf()),
  ...restParams,
});

const downloadMemberExportSimple = async (params: MemberViewParams) => {
  const blob: Blob = await Api.getv2Blob(
    '/patients/views/export-members-page',
    parseDownloadMemberParams(params),
  );
  const csvURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', 'member_export.csv');
  tempLink.click();
};

const TIME_POLLING = 5000;
const checkMemberExportReady = async (uuid: string): Promise<Blob> => {
  const {
    status,
    response,
  }: {
    status: number;
    response: Blob;
  } = await Api.getv2BlobStatus(
    '/patients/views/export-members-page-extended/poll',
    {
      uuid,
    },
  );

  if (status === 202) {
    return await new Promise((res, rej) => {
      setTimeout(
        () => checkMemberExportReady(uuid).then(res).catch(rej),
        TIME_POLLING,
      );
    });
  }

  return response;
};

const downloadMemberExportExtended = async (params: MemberViewParams) => {
  const { uuid } = await Api.getv2(
    '/patients/views/export-members-page-extended',
    parseDownloadMemberParams(params),
  );

  const blob = await checkMemberExportReady(uuid);
  const csvURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', 'member_export.csv');
  tempLink.click();
};

export const downloadMemberExport = async (
  params: MemberViewParams,
  downloadMemberExport: Boolean,
) => {
  if (downloadMemberExport) {
    await downloadMemberExportExtended(params);
  } else {
    await downloadMemberExportSimple(params);
  }
};

type BulkEditValidation = {
  error: boolean;
  rnErrorStates: States[];
  npErrorStates: States[];
};

export const bulkUpdate = async (
  memberIds: number[],
  params: BulkUpdateParams,
): Promise<BulkEditValidation> => {
  const {
    riskLevel,
    programExtensions,
    status,
    worklistGroupId,
    ...restParams
  } = params;

  const { bulkUpdateResponse } = await Api.patchv2JSON(
    '/patients/actions/bulk-update',
    {
      riskLevel: riskLevel !== null ? riskLevel?.value : null,
      programExtensions: programExtensions.map((pe) => ({
        ...pe,
        programExtensionStatus: pe.programExtensionStatus,
      })),
      status: status?.valueOf(),
      worklistGroupId: worklistGroupId?.id,
      ...restParams,
      memberIds,
    },
  );
  return {
    ...bulkUpdateResponse,
    npErrorStates: bulkUpdateResponse.npErrorStates?.map(
      (item: string) => States.byKey[item],
    ),
    rnErrorStates: bulkUpdateResponse.rnErrorStates?.map(
      (item: string) => States.byKey[item],
    ),
  };
};

export const fetchById = async (patientId: number | string) => {
  const { patient } = await Api.getv2(`/patients/${patientId}`);
  return new Patient(patient);
};

export const fetchHealthReportsByPatient = async (
  patientId: number,
  params?: PatientServiceParams,
): Promise<HealthRecordsResponse> => {
  const formattedParams = {
    ...params,
    dateFrom: params && params.dateFrom && params.dateFrom.format(DATE_FORMAT),
    dateTo: params && params.dateTo && params.dateTo.format(DATE_FORMAT),
  };

  const { healthCheckIns } = await Api.getv2(
    `/patients/${patientId}/health-check-ins`,
    formattedParams,
  );

  return {
    items: healthCheckIns.items.map(
      (dailyRecord: any) => new HealthReport(dailyRecord),
    ),
    pagination: healthCheckIns.pagination,
  };
};

export const updatePatient = async (
  id: number,
  {
    birthDate,
    deceasedDate,
    hciReporterMethod,
    reasonWithdraw,
    ...params
  }: PatientParam,
) => {
  const { platformPatient } = await Api.patchv2JSON(`/patients/${id}`, {
    birthDate: birthDate?.format(DATE_FORMAT),
    deceasedDate: deceasedDate?.format(DATE_FORMAT),
    hciReporterMethod:
      hciReporterMethod === null ? null : hciReporterMethod?.value,
    reasonWithdraw: reasonWithdraw?.value,
    ...params,
  });
  return new Patient(platformPatient);
};

export const setLanguages = async (
  id: number,
  languages: Language[],
  preferredLanguage: Language,
): Promise<Patient> =>
  updatePatient(id, {
    language: preferredLanguage.valueOf(),
    languages: languages.map((lang) => lang.valueOf()),
  });

export const updateBirthDate = async (id: number, birthDate: Moment) =>
  updatePatient(id, { birthDate });

export const updateExternalId = async (id: number, externalId: string) =>
  updatePatient(id, { externalId });

export const updateName = async (
  id: number,
  { firstName, lastName }: { firstName: string; lastName: string },
) => updatePatient(id, { firstName, lastName });

export const updatePatientSelfDirectingStatue = async (
  id: number,
  selfDirecting: boolean,
) => updatePatient(id, { selfDirecting });

export const updateGender = async (
  id: number,
  gender: PatientGender,
  comment: string,
) =>
  updatePatient(id, {
    gender: {
      comment,
      type: gender.valueOf(),
    },
  });

export const updateStartDate = async (id: number, reportingStartDate: Moment) =>
  updatePatient(id, {
    reportingStartDate,
  });

export const updateResidence = async (
  id: number,
  residenceType: ResidenceType,
  residenceTypeOther?: string,
) => {
  const params = {
    residenceType: residenceType.valueOf(),
  };

  if (residenceType === ResidenceType.OTHER) {
    Object.assign(params, { residenceTypeOther });
  }

  return updatePatient(id, params);
};

export const updatePatientRisk = async (id: number, riskLevel?: MemberRisk) =>
  updatePatient(id, {
    riskLevel: riskLevel?.valueOf() || null,
  });

export const updatePatientOwner = async (id: number, owner: number) =>
  updatePatient(id, {
    owner,
  });

export const updatePatientNPOwner = async (id: number, npOwner: number) =>
  updatePatient(id, {
    npOwner,
  });

export const updatePatientCareCoordinators = async (
  id: number,
  careCoordinatorIds: number[],
) =>
  updatePatient(id, {
    careCoordinatorIds,
  });

export const updatePatientLanguages = async (
  id: number,
  languages: Language[],
  language: Language,
) =>
  updatePatient(id, {
    language: language.valueOf(),
    languages: languages.map((language) => language.valueOf()),
  });

export const updatePatientCareTeamInfo = async (
  id: number,
  careTeamInfo: string,
) =>
  updatePatient(id, {
    careTeamInfo,
  });

export const updatePatientBrand = async (id: number, brand?: Brand) =>
  updatePatient(id, {
    brandId: brand?.value,
    lockedBrand: true,
  });

interface AddressParams {
  address1: string;
  address2: string;
  city: string;
  state: States;
  zipCode: string;
}

export const updatePatientAddress = async (
  id: number,
  address: AddressParams,
) => {
  const formattedAddress = {
    ...address,
    state: address.state && address.state.value,
  };
  return updatePatient(id, { address: formattedAddress });
};

export const updatePatientLivingArrangement = async (
  id: number,
  livingArrangement: PatientLivingArrangement[],
  livingArrangementOther?: string,
) => {
  const params = {
    livingArrangement: livingArrangement.map((value) => value.valueOf()),
  };

  if (livingArrangement.find((v) => v === PatientLivingArrangement.OTHER)) {
    Object.assign(params, { livingArrangementOther });
  }

  return updatePatient(id, params);
};

export const updateNotes = async (id: number, notes: string) =>
  updatePatient(id, {
    notes,
  });

export const fetchPatient = async (id: string | number): Promise<Patient> => {
  const { patient } = await Api.getv2(`/patients/${id}`);
  return new Patient(patient);
};

export interface PatientMultipleFetchParam {
  id: number[];
}

export const fetchMultiplePatients = async (
  params: PatientMultipleFetchParam,
): Promise<Patient[]> => {
  const {
    patients: { items },
  } = await Api.getv2('patients', params);
  return items.map((i: any) => new Patient(i));
};

const patchPatient = async (patientId: number | string, data: any) => {
  const res = await Api.patchv2JSON(`/patients/${patientId}`, data);
  return new Patient(res.platformPatient);
};

export const updateSocialAssessmentCompletedAt = (
  patientId: number | string,
  completedAt: Moment,
) =>
  patchPatient(patientId, {
    socialAssessmentCompletedAt: completedAt && completedAt.format(DATE_FORMAT),
  });

export const updateLastPCPVisit = (patientId: number | string, date: Moment) =>
  patchPatient(patientId, { lastPCPVisit: date && date.format(DATE_FORMAT) });

export const updateExpectedWeeklyHealthReports = async (
  id: number,
  expectedWeeklyHealthReports?: number,
) => {
  // Our backend requires an explicit null in order to clear fields in PATCH endpoints
  const nullableWeeklyHealthReports = expectedWeeklyHealthReports || null;
  return updatePatient(id, {
    expectedWeeklyHealthReports: nullableWeeklyHealthReports,
  });
};

export const fetchHealthCheckInQuestions = async (
  patientId: number,
  reporterType: EventReporterType,
  reporterId: number,
): Promise<CareQuestion[]> => {
  const { healthQuestions } = await Api.getv2(
    `/patients/${patientId}/health-check-ins/questions`,
    {
      reporterMember:
        reporterType === EventReporterType.MEMBER ? reporterId : undefined,
      reporterCareTeamMember:
        reporterType === EventReporterType.CARE_TEAM ? reporterId : undefined,
    },
  );
  return healthQuestions.items.map((x: any) => new CareQuestion(x));
};

export type PostHealthCheckInQuestionParams = {
  channel: BaseEnum;
  direction: BaseEnum;
  questions: { [key: string]: string };
};

export const postHealthCheckInQuestions = async (
  patientId: number,
  reporterType: EventReporterType,
  reporterId: number,
  { channel, direction, questions }: PostHealthCheckInQuestionParams,
): Promise<CareQuestion[]> =>
  Api.postv2JSON(`/patients/${patientId}/health-check-ins/`, {
    channel: channel.id,
    direction: direction.id,
    questions,
    reporterMember:
      reporterType === EventReporterType.MEMBER ? reporterId : undefined,
    reporterCareTeamMember:
      reporterType === EventReporterType.CARE_TEAM ? reporterId : undefined,
  });

export const getPatientDevice = async (patientId: number | string) => {
  const res = await Api.getv2(`/patients/${patientId}/device`);
  return res.platformDevice as Device;
};

export const getMemberEligibility = async (patientId: number | string) => {
  const res = await Api.getv2(`/patients/${patientId}/insurances/eligibility`);
  return new MemberEligibility(res.memberEligibility);
};

const checkPushEligibilityCheckReady = async (
  memberId: number,
  uuid: string,
): Promise<any> => {
  const {
    status,
    response,
  }: {
    status: number;
    response: any;
  } = await Api.getv2Status(
    `/patients/${memberId}/insurances/pushEligibilityCheck/poll`,
    {
      uuid,
    },
  );

  if (status === 202) {
    return await new Promise((res, rej) => {
      setTimeout(
        () =>
          checkPushEligibilityCheckReady(memberId, uuid).then(res).catch(rej),
        TIME_POLLING,
      );
    });
  }

  return response;
};

export const callPushEligibilityCheck = async (memberId: number | string) => {
  const { uuid } = await Api.postv2JSON(
    `/patients/${memberId}/insurances/pushEligibilityCheck/async`,
  );

  const {
    memberInsurances: { items },
  } = await checkPushEligibilityCheckReady(+memberId, uuid);

  return items.map((item: any) => new MemberInsurance(item));
};

export const fetchMemberInsuranceQualification = async (
  patientId: number | string,
) => {
  const { memberInsuranceQualification } = await Api.getv2(
    `/patients/${patientId}/insurances/qualification`,
  );
  return memberInsuranceQualification?.memberId
    ? new MemberInsuranceQualification(memberInsuranceQualification)
    : null;
};

export type UpdateInsuranceQualificationParams = {
  insuranceQualification?: InsuranceQualification;
};

export const updateMemberInsuranceQualification = async (
  patientId: number | string,
  { insuranceQualification }: UpdateInsuranceQualificationParams,
) =>
  await Api.patchv2JSON(`/patients/${patientId}/insurances/qualification`, {
    insuranceQualification: insuranceQualification?.value,
  });

export const linkPlatformDevice = async (
  patientId: number | string,
  setupCode: string,
) =>
  Api.postv2JSON(
    `/patients/${patientId}/link-device`,
    { setupCode },
    { omitModal: true },
  );

export const unlinkPlatformDevice = async (patientId: number | string) =>
  Api.deletev2(`/patients/${patientId}/unlink-device`, { omitModal: true });

export const sendSMSInvite = async (patientId: number, caregiverId: number) =>
  Api.postv2(
    `/patients/${patientId}/caregivers/${caregiverId}/actions/app-invite`,
  );

export const startVirtualVisit = async (patientId: string | number) =>
  Api.postv2JSON(`patients/${patientId}/actions/start-virtual-visit`);

export const bulkMemberUpload = async (
  file: File,
  applyFutureLead: boolean,
  applyTermByAbsence: boolean,
  rawArchiveId?: number,
) => {
  const formData = new FormData();
  formData.append('file', file);
  if (rawArchiveId) formData.append('rawArchiveId', rawArchiveId?.toString());
  formData.append('futureLeads', String(applyFutureLead));
  formData.append('termByAbsence', String(applyTermByAbsence));
  const resp = await Api.postv2File('patients/bulk', formData);
  return resp.asyncTask.uuid as string;
};

export interface ValidatedRow {
  messages?: string[];
  row: number;
  type:
    | 'VALID'
    | 'INVALID'
    | 'DUPLICATE_PARAM'
    | 'DUPLICATE_ENTITY'
    | 'WARNING';
  duplicateIdentity?: number;
}

export interface ValidationResponse {
  errors: ValidatedRow[];
  warnings: ValidatedRow[];
}

export const fetchAllProgramExtensions = async (): Promise<
  ProgramExtension[]
> => {
  const {
    programExtensions: { items },
  } = await Api.getv2(`/program-extensions`);
  return items.map((item: any) => new ProgramExtension(item));
};

export const fetchProgramExtensionStatus = async (): Promise<
  ProgramExtensionStatus[]
> => {
  const {
    programExtensionStatuses: { items },
  } = await Api.getv2(`/program-extensions/statuses`);
  return items
    .map((item: any) => new ProgramExtensionStatus(item))
    .sort(ProgramExtensionStatus.sort);
};

type ProgramExtensionBulkParams = {
  programExtension: ProgramExtension;
  status?: ProgramExtensionStatus;
};

export const updateProgramExtensions = async (
  memberId: number | string,
  params: ProgramExtensionBulkParams[],
) =>
  await Api.postv2JSON(
    `/patients/${memberId}/program-extensions/bulk-update`,
    params.map(({ programExtension: { abbr }, status }) => ({
      abbr,
      status: status?.id,
    })),
  );

export const fetchMembersEncounterSummaries = async ({
  type,
  ...params
}: MemberEncountersSummaryParam): Promise<MemberEncountersSummaryResponse> => {
  const {
    members: { items, pagination },
  } = await Api.getv2('/patients/views/encounters', {
    type: type.map((t) => t.valueOf()),
    ...params,
  });

  return {
    items: items.map((item: any) => new MemberEncountersSummary(item)),
    pagination,
  } as MemberEncountersSummaryResponse;
};

export const updateProgram = async (
  memberId: number,
  programConfigurationId: number,
) => {
  await Api.postv2JSON(`/patients/${memberId}/actions/enroll-program`, {
    programConfigurationId,
  });
};

export const getMemberPhones = async (memberId: number) => {
  const response = await Api.getv2(`/patients/${memberId}/phones`);
  return response.memberPhones.items.map((item: any) => new MemberPhone(item));
};

export const updateMemberPhones = async (
  memberId: number,
  phones: {
    id?: number;
    type?: PhoneType;
    number?: string;
    primary?: boolean;
    readOnly?: boolean;
    isEmrIntegrated?: boolean;
    isCrmIntegrated?: boolean;
  }[],
) => {
  await Api.putv2JSON(
    `/patients/${memberId}/phones`,
    phones.map(
      ({ id, type, number, primary, isEmrIntegrated, isCrmIntegrated }) => ({
        id: id || undefined,
        type: type?.valueOf(),
        number,
        primary: !!primary,
        emrIntegrated: !!isEmrIntegrated,
        crmIntegrated: !!isCrmIntegrated,
      }),
    ),
    { showToast: true },
  );
};

/* MEMBER INSURANCES */

export const getMemberInsurances = async (
  memberId: number,
): Promise<MemberInsurance[]> => {
  const {
    memberInsurances: { items },
  } = await Api.getv2(`/patients/${memberId}/insurances`);

  return items.map((item: any) => new MemberInsurance(item));
};

interface InsurancePostData {
  id?: number;
  insurancePlanId: number;
  insuranceType: string;
  subscriberId: string;
  insurancePriorityId: number;
  enrollmentStart?: string;
  enrollmentEnd?: string;
  discontinuedAt?: number;
}

export const updateMemberInsurance = async (
  memberId: number,
  insuranceId: number,
  insurance: InsurancePostData,
) => {
  const { memberInsurance } = await Api.putv2JSON(
    `/patients/${memberId}/insurances/${insuranceId}`,
    insurance,
  );

  return new MemberInsurance(memberInsurance);
};

export const createMemberInsurance = async (
  memberId: number,
  insurance: InsurancePostData,
) => {
  const { memberInsurance } = await Api.postv2JSON(
    `/patients/${memberId}/insurances`,
    insurance,
  );

  return new MemberInsurance(memberInsurance);
};

export const removeMemberInsurance = async (
  memberId: number,
  insuranceId: number,
) => {
  await Api.deletev2(`/patients/${memberId}/insurances/${insuranceId}`);
};

/* MEMBER REFERRALS */

export const getMemberReferrals = async (
  memberId: number,
  active?: boolean,
): Promise<MemberReferral[]> => {
  const {
    memberReferrals: { items },
  } = await Api.getv2(`/patients/${memberId}/referrals`, { active });
  return items.map((item: any) => new MemberReferral(item));
};

interface ReferralPostData {
  id?: number;
  organizationId: number;
  externalId: string;
  enrollmentStart: string;
  enrollmentEnd: string | null;
}

export const updateMemberReferrals = async (
  memberId: number,
  referrals: ReferralPostData[],
) => {
  const {
    memberReferrals: { items },
  } = await Api.putv2JSON(`/patients/${memberId}/referrals`, referrals);

  return items.map((item: any) => new MemberInsurance(item));
};

export const updateMemberReferral = async (
  memberId: number,
  referralId: number,
  referral: ReferralPostData,
) => {
  const { memberReferral } = await Api.putv2JSON(
    `/patients/${memberId}/referrals/${referralId}`,
    referral,
  );

  return new MemberReferral(memberReferral);
};

export const createMemberReferral = async (
  memberId: number,
  referral: ReferralPostData,
) => {
  const { id, ...referralData } = referral;

  const { memberReferral } = await Api.postv2JSON(
    `/patients/${memberId}/referrals`,
    referralData,
  );

  return new MemberReferral(memberReferral);
};

export const removeMemberReferral = async (
  memberId: number,
  referralId: number,
) => {
  await Api.deletev2(`/patients/${memberId}/referrals/${referralId}`);
};

/* MEMBER EXTERNAL IDS */

export const getMemberExternalIds = async (memberId: number) => {
  const response = await Api.getv2(`/patients/${memberId}/external-ids`);
  return response.memberExternalIds.items.map(
    (item: any) => new MemberExternalId(item),
  );
};

export const getMemberExternalId = async (
  memberId: number,
  externalId: string,
) => {
  const response = await Api.getv2(
    `/patients/${memberId}/external-ids/${externalId}`,
  );
  return response.memberExternalIds.items.map(
    (item: any) => new MemberExternalId(item),
  );
};

export const createMemberExternalId = async (
  memberId: number,
  externalId: string,
  externalIdSourceId: number,
) => {
  const { memberExternalId } = await Api.postv2JSON(
    `/patients/${memberId}/external-ids`,
    {
      externalId,
      externalIdSourceId,
    },
  );

  return new MemberExternalId(memberExternalId);
};

export const updateMemberExternalIds = async (
  memberId: number,
  externalIds: { externalId: string; externalIdSourceId: number }[],
) => {
  const { memberExternalIds } = await Api.putv2JSON(
    `/patients/${memberId}/external-ids`,
    externalIds,
  );

  return memberExternalIds.items.map((item: any) => new MemberExternalId(item));
};

export const updateMemberExternalId = async (
  memberId: number,
  id: number,
  externalId: string,
  externalIdSourceId: number,
) => {
  const { memberExternalId } = await Api.putv2JSON(
    `/patients/${memberId}/external-ids/${id}`,
    {
      externalId,
      externalIdSourceId,
    },
  );

  return new MemberExternalId(memberExternalId);
};

export const deleteMemberExternalId = async (memberId: number, id: number) => {
  await Api.deletev2(`/patients/${memberId}/external-ids/${id}`);
};

export const getMemberContactInfo = async (memberId: number) => {
  const response = await Api.getv2(`/patients/${memberId}/contact-info`);
  return new MemberContactInfo(response?.memberContactInfo);
};

export const parseMemberContactInfo = (
  memberContactTimes?: MemberContactInfoTimes[],
) => {
  const contactDateTimes: DatesTimeItem[] = [];

  if (memberContactTimes?.length) {
    const reduced = memberContactTimes.reduce((acc, item) => {
      let key: string;
      if (item.type !== MemberContactTimeType.CUSTOM) {
        key = item.type?.value || '';
      } else {
        key = `${item.type?.value}_${item.startTime}_${item.endTime}`;
      }

      if (!acc[key]) {
        acc[key] = { type: item.type } as DatesTimeItem;
      }

      const mapped = acc[key];
      if (item.dayOfWeek) {
        if (!mapped.dates) {
          mapped.dates = [];
        }
        mapped.dates.push(item.dayOfWeek);
      }
      if (item.startTime || item.endTime) {
        mapped.startTime = item.startTime?.toDate();
        mapped.endTime = item.endTime?.toDate();
      }
      return acc;
    }, {} as { [key: string]: DatesTimeItem });
    contactDateTimes.push(...Object.values(reduced));
  }

  return contactDateTimes;
};

export const getMemberContactInfoParsed = async (memberId: number) => {
  const contactInfo = await getMemberContactInfo(memberId);
  const { memberContactMethods, memberContactTimes, note } = contactInfo;

  return {
    contactMethods: memberContactMethods,
    contactDateTimes: parseMemberContactInfo(memberContactTimes),
    note,
  };
};

export type MemberContactInfoParams = {
  contactMethods?: {
    contactMethod: MemberContactMethodType;
    contactMethodOther?: string;
  }[];
  contactTimes?: MemberContactInfoTimes[];
  note?: string;
};

export const createOrUpdateMemberContactInfo = async (
  memberId: number,
  params: MemberContactInfoParams,
) => {
  const p = {
    ...params,
    contactMethods: params.contactMethods?.map(
      ({ contactMethod, contactMethodOther }) => ({
        contactMethod: contactMethod.value,
        contactMethodOther,
      }),
    ),
    contactTimes: (params.contactTimes || [])?.map(
      ({ id, type, dayOfWeek, startTime, endTime }) => ({
        id,
        type: type?.value,
        dayOfWeek: dayOfWeek?.value,
        startTime: startTime?.isValid()
          ? startTime.format(LOCAL_TIME_FORMAT)
          : undefined,
        endTime: endTime?.isValid()
          ? endTime.format(LOCAL_TIME_FORMAT)
          : undefined,
      }),
    ),
  };
  await Api.putv2JSON(`/patients/${memberId}/contact-info`, p);
};

export const getCarePlanGroups = async (): Promise<DBEnum[]> => {
  const { carePlanGroups } = await Api.getv2(`/care-plan-groups`);

  return carePlanGroups.items.map((i: any) => new DBEnum(i));
};

export const sendAppInvite = async (id: number, os: 'android' | 'ios') =>
  await Api.postv2JSON(`/patients/${id}/actions/app-invite/${os}`);

const WARNING_SORT = {
  [MemberWarningCategory.APPOINTMENT]: 0,
  [MemberWarningCategory.TIME]: 5,
  [MemberWarningCategory.MISSING_INFO]: 10,
  [MemberWarningCategory.OPEN_ITEMS]: 15,
  [MemberWarningCategory.RPM_RISK]: 20,
  [MemberWarningCategory.TOC]: 25,
};

const sortMemberWarning = (
  { type: { category: a } }: MemberWarning,
  { type: { category: b } }: MemberWarning,
) => WARNING_SORT[a.id] - WARNING_SORT[b.id];

export const fetchMemberWarnings = async (
  id: number,
): Promise<MemberWarning[]> => {
  const {
    memberWarnings: { items },
  } = await Api.getv2(`/patients/${id}/warnings`);

  return items.map((x: any) => new MemberWarning(x)).sort(sortMemberWarning);
};

export const fetchMemberWarningDetails = async (
  id: number,
): Promise<MemberWarningDetail[]> => {
  const {
    memberWarnings: { items },
  } = await Api.getv2(`/patients/${id}/warnings/detail`);

  return items
    .map((x: any) => MemberWarningDetail.getDetail(x))
    .sort(sortMemberWarning);
};

export type MemberSettings = {
  rpmRemindersApp: boolean;
  rpmRemindersFrequency: 'DAILY' | 'WEEKLY';
  rpmRemindersSMS: boolean;
};

export const getMemberSettings = async (
  memberId: string | number,
): Promise<MemberSettings> => {
  const { settings } = await Api.getv2(`/patients/${memberId}/settings`);

  return settings;
};

export type RPMRemindersFrequencyType = 'DAILY' | 'WEEKLY';

export type MemberSettingsParam = Partial<{
  rpmRemindersApp: boolean;
  rpmRemindersFrequency: RPMRemindersFrequencyType;
  rpmRemindersSMS: boolean;
}>;

export const updateMemberSettings = async (
  memberId: string | number,
  params: MemberSettingsParam,
): Promise<MemberSettings> => {
  const { settings } = await Api.patchv2JSON(
    `/patients/${memberId}/settings`,
    params,
  );

  return settings;
};
