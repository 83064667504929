import React, { PureComponent } from 'react';
import { Modal as BSModal } from 'react-bootstrap';
import classNames from 'classnames';
import { Spinner } from 'styleguide';

interface HeaderProps {
  className?: string;
  closeButton?: boolean;
  modalTitle?: React.ReactChild;
}

export class Header extends PureComponent<HeaderProps> {
  render() {
    const { children, className, closeButton, modalTitle } = this.props;

    const classes = classNames(className);

    return (
      <BSModal.Header className={classes} closeButton={closeButton}>
        {modalTitle && <h4 className="modal-title">{modalTitle}</h4>}
        {children || null}
      </BSModal.Header>
    );
  }
}

interface BodyProps extends React.HTMLProps<HTMLDivElement> {}

export class Body extends PureComponent<BodyProps> {
  render() {
    const { children, className, ...other } = this.props;

    const classes = classNames('modal-body', className);

    return (
      <div className={classes} {...other}>
        {children || null}
      </div>
    );
  }
}

interface FooterProps extends React.HTMLProps<HTMLDivElement> {
  leftSide?: any;
  rightSide?: any;
}

export class Footer extends PureComponent<FooterProps> {
  render() {
    const { children, className, leftSide, rightSide, ...other } = this.props;

    const classes = classNames('modal-footer', className);

    return (
      <div className={classes} {...other}>
        {leftSide && <div className="left-side">{leftSide}</div>}
        {children || null}
        {rightSide && <div className="right-side">{rightSide}</div>}
      </div>
    );
  }
}

export type ModalSize = 'xs' | 'sm' | 'lg';

interface ModalProps {
  color?: string;
  className?: string;
  id?: string;
  loading?: boolean;
  onShow?: () => void;
  onHide: Function;
  show?: boolean;
  modalSize?: ModalSize;
}

export class Modal extends PureComponent<ModalProps> {
  render() {
    const {
      children,
      className,
      color,
      id,
      loading,
      modalSize,
      onShow,
      onHide,
      show,
    } = this.props;

    const classes = classNames(className);

    // We provide our own modal container that lives inside the main react render container,
    // avoiding bootstrap modal scrolling problems on iPads
    return (
      <BSModal
        // @ts-ignore - incorrectly thinks that bsStyle is invalid
        bsStyle={color}
        backdrop="static"
        bsSize={modalSize}
        className={classes}
        container={document.getElementById('modal-container')}
        id={id}
        onHide={onHide}
        onShow={onShow}
        show={show}
      >
        {loading && <Spinner width={80} overlay />}
        {children}
      </BSModal>
    );
  }

  static Header: typeof Header;

  static Body: typeof Body;

  static Footer: typeof Footer;
}

Object.assign(Modal, {
  Header,
  Body,
  Footer,
});

export default Modal;
