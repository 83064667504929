import React, { useMemo, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { ClassNameMap, makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { Facility, PaginationType } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Colors, IconButton, Table, UpdatedBy } from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

import Session from 'dash/src/services/SessionServices';

interface Props {
  defaultPageSize?: number;
  facilities: Facility[];
  onEdit: (emplooyee: Facility) => void;
  pagination?: PaginationType;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
}

const useStyles = makeStyles({
  boolean: {
    verticalAlign: 'middle',
    margin: '0 33%',
  },
  icon: {
    '&&': {
      color: Colors.iconGreen,
      padding: 0,
    },
  },
});

const FACILITY_COLUMNS = (
  { onEdit }: Props,
  styles: ClassNameMap<'icon' | 'boolean'>,
  [loadingId, setLoadingId]: [
    number,
    React.Dispatch<React.SetStateAction<number>>,
  ],
  showEdit: boolean,
) => {
  const columns: Column[] = [
    {
      headerName: translate('facilities.table.id'),
      field: 'id',
    },
    {
      headerName: translate('facilities.table.ecwId'),
      field: 'ecwId',
    },
    {
      headerName: translate('facilities.table.facility'),
      field: 'name',
    },
    {
      headerName: translate('facilities.table.type'),
      component: ({ type }: Facility) => type?.toString() || EMPTY,
    },
    {
      headerName: translate('facilities.table.state'),
      component: ({ state }: Facility) => state?.value || EMPTY,
    },
    {
      headerName: translate('facilities.table.updatedBy'),
      component: ({ updatedAt, updatedBy }: Facility) =>
        updatedAt ? (
          <UpdatedBy date={updatedAt} user={updatedBy.fullName} />
        ) : (
          EMPTY
        ),
    },
  ];
  if (showEdit) {
    columns.push({
      headerName: '',
      component: (f: Facility) => (
        <IconButton
          className={styles.icon}
          onClick={async () => {
            setLoadingId(f.id);
            await onEdit(f);
            setLoadingId(-1);
          }}
          loading={f.id === loadingId}
          size="small"
          tooltip={translate('facilities.edit')}
        >
          <EditIcon />
        </IconButton>
      ),
      width: 50,
    });
  }
  return columns;
};

export const FacilitiesTable = (props: Props) => {
  const styles = useStyles();
  const { facilities, pagination, onChangePage, onChangePageSize } = props;
  const [loadingId, setLoadingId] = useState<number>(-1);

  const columns = useMemo(
    () =>
      FACILITY_COLUMNS(
        props,
        styles,
        [loadingId, setLoadingId],
        Session.actingUser.canEditFacilities,
      ),
    [],
  );

  return (
    <Table
      config={{
        columns,
        compact: true,
        data: facilities,
        pagination,
        defaultPageSize: props.defaultPageSize,
      }}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangePageSize}
    />
  );
};

export default FacilitiesTable;
