import React, { useMemo } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { translate } from '@vestahealthcare/common/i18n';
import { CareTeamMember } from '@vestahealthcare/common/models';

import { MemberInfo, Table } from 'styleguide-v2';

import { CareTeamMemberPermissions } from 'dash/src/pages/CareTeam/components/CareTeamMemberPermissions';

type Props = {
  careTeamMembers: CareTeamMember[];
};

const CARE_TEAM_PERSON_COLUMNS = () => [
  {
    headerName: translate('careTeam.common.linkedMembers'),
    component: ({ member }: CareTeamMember) => (
      <MemberInfo
        link={`#/patients/${member?.id}`}
        member={member}
        showLanguage
        showStatus
        showRiskLevel
        smallName
      />
    ),
  },
  {
    headerName: translate('careTeam.common.linkStatus'),
    component: ({ active }: CareTeamMember) => (
      <span className="flex">
        {active ? (
          <CheckCircleIcon color="primary" />
        ) : (
          <CancelIcon color="error" />
        )}
        &nbsp;
        {active
          ? translate('careTeam.common.active')
          : translate('careTeam.common.inactive')}
      </span>
    ),
  },
  {
    headerName: translate('careTeam.common.role'),
    component: ({ typeWithSubTypeLabel }: CareTeamMember) =>
      typeWithSubTypeLabel,
  },
  {
    headerName: translate('careTeam.common.permissions'),
    component: (member: CareTeamMember) => (
      <div className="flex gap wrap">
        <CareTeamMemberPermissions member={member} />
      </div>
    ),
    width: 450,
  },
];

export const CareTeamMemberTable = (props: Props) => {
  const { careTeamMembers } = props;

  const columns = useMemo(() => CARE_TEAM_PERSON_COLUMNS(), []);

  return (
    <Table
      config={{
        columns,
        compact: true,
        data: careTeamMembers,
        paginationOptions: [-1],
      }}
    />
  );
};

export default CareTeamMemberTable;
