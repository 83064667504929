import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Colors } from 'styleguide';
import { Fonts } from 'styleguide-v2';

const useStyles = makeStyles({
  positionCircle: {
    backgroundColor: Colors.lightMint,
    height: (props: Props) => props.size || 40,
    width: (props: Props) => props.size || 40,
    borderRadius: (props: Props) => (props.size || 40) / 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: {
    fontSize: (props: Props) => props.fontSize,
    letterSpacing: 0,
    '&&': {
      fontFamily: Fonts.fontFamily,
    },
  },
});

interface Props {
  fontSize?: number;
  position: number;
  size?: number;
  style?: any;
}

export const SubtaskPositionCircle = (props: Props) => {
  const styles = useStyles(props);
  return (
    <div className={styles.positionCircle} style={props.style}>
      <h5 className={styles.position}>{props.position}</h5>
    </div>
  );
};

export default SubtaskPositionCircle;
