import { Selectable } from '../enums/Enum';

export class Brand {
  id = '';

  name = '';

  clinicalPhoneNumber = '';

  supportPhoneNumber = '';

  iconPath = '';

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  static toSelectable(items: Brand[]): Selectable[] {
    return items.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }
}

export default Brand;
