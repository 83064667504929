import { IssueDetailFieldType } from '../enums';
import { IssueDetailField } from './IssueDetailField';
import { make } from './Model';

const FALL_INJURY = 'INJURY';
const FALL_DATE = 'FALL_DATE';
const FALL_ANTIAGULANT = 'ANTICOAGULANT_USED';
const FALL_DME = 'DME_IN_USE';
const FALL_DME_YES = '46';
const FALL_DME_TYPE = 'DME_TYPE_USED';
const FALL_EVALUATED = 'MEMBER_EVALUATED';
const FALL_EVALUATED_YES = '58';

const FALL_INJURY_YES = '1';

const HOSPITALIZATION_ADMIT_DATE = 'ADMIT_DATE';
const HOSPITALIZATION_MEDICATION_CHANGE = 'MED_CHANGE';
const HOSPITALIZATION_MEDICATION_CHANGE_DESC = 'MED_CHANGE_DESCRIPTION';
const HOSPITALIZATION_FOLLOW_UP = 'FOLLOW_UP_APPTS';
const HOSPITALIZATION_DISCHARGE_DATE = 'DISCHARGE_DATE';
const HOSPITALIZATION_FACILITY_NAME = 'FACILITY_NAME';

const HOSPITALIZATION_MEDICATION_CHANGE_YES = '60';
export class IssueDetailValue {
  id = 0;

  issueDetailField: IssueDetailField;

  value = '';

  constructor(obj: any) {
    Object.assign(this, obj);
    this.issueDetailField = make(obj.issueDetailField, IssueDetailField);
  }

  static hasTime(fieldType: IssueDetailFieldType, value: string) {
    return (
      fieldType === IssueDetailFieldType.DATE_TIME &&
      /[\d-]+T[\d+-:]+/.test(value)
    );
  }

  // FALL Fields

  get isFallInjuryField(): boolean {
    return this.issueDetailField.id === FALL_INJURY;
  }

  get isFallDateField(): boolean {
    return this.issueDetailField.id === FALL_DATE;
  }

  get isFallDMEField(): boolean {
    return this.issueDetailField.id === FALL_DME;
  }

  get isFallDME(): boolean {
    return this.value === FALL_DME_YES;
  }

  get isFallDMETypeField(): boolean {
    return this.issueDetailField.id === FALL_DME_TYPE;
  }

  get isFallAnticoagulantField(): boolean {
    return this.issueDetailField.id === FALL_ANTIAGULANT;
  }

  get isFallEvaluatedField(): boolean {
    return this.issueDetailField.id === FALL_EVALUATED;
  }

  get isFallEvaluated(): boolean {
    return this.value === FALL_EVALUATED_YES;
  }

  get isFallWithInjury(): boolean {
    return this.isFallInjuryField && FALL_INJURY_YES === this.value;
  }

  // ER + FACILITY Fields

  get isHospAdmitDateField(): boolean {
    return this.issueDetailField.id === HOSPITALIZATION_ADMIT_DATE;
  }

  get isHospMedChangeField(): boolean {
    return this.issueDetailField.id === HOSPITALIZATION_MEDICATION_CHANGE;
  }

  static HOSPITALIZATION_MEDICATION_CHANGE = HOSPITALIZATION_MEDICATION_CHANGE;

  get isHospMedChange(): boolean {
    return this.value === HOSPITALIZATION_MEDICATION_CHANGE_YES;
  }

  get isHospMedChangeDescField(): boolean {
    return this.issueDetailField.id === HOSPITALIZATION_MEDICATION_CHANGE_DESC;
  }

  static HOSPITALIZATION_FOLLOW_UP = HOSPITALIZATION_FOLLOW_UP;

  get isHospFollowUpApptField(): boolean {
    return this.issueDetailField.id === HOSPITALIZATION_FOLLOW_UP;
  }

  static HOSPITALIZATION_DISCHARGE_DATE = HOSPITALIZATION_DISCHARGE_DATE;

  get isHospDischargeDateField(): boolean {
    return this.issueDetailField.id === HOSPITALIZATION_DISCHARGE_DATE;
  }

  get isHospFacilityNameField(): boolean {
    return this.issueDetailField.id === HOSPITALIZATION_FACILITY_NAME;
  }
}

export default IssueDetailValue;
