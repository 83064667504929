import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Language } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  CALL_TO_NURSE,
  SHOW_ON_LOGIN,
} from '@vestahealthcare/common/models/CampaignModal';

import { Button, Checkbox, ImageSelect, Panel, TextInput } from 'styleguide';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import {
  createCampaignModal,
  editCampaignModal,
  fetchCampaignModal,
} from 'dash/src/services/CampaignServices';

import ImageOptions from './ImageOptions';
import MarkdownModal from './MarkdownModal';

type Params = {
  modalId: string;
};

export const EditCampaignModal = withRouter(
  (props: RouteComponentProps<Params>) => {
    const {
      history,
      match: {
        params: { modalId },
      },
    } = props;
    const isNew = !modalId;

    const [loading, setLoading] = useState(true);
    const [showError, setShowError] = useState(false);
    const [showMarkdownModal, setShowMarkdownModal] = useState(false);
    const [name, setName] = useState<string>();
    const [headerEnglish, setHeaderEnglish] = useState<string>();
    const [headerSpanish, setHeaderSpanish] = useState<string>();
    const [headerRussian, setHeaderRussian] = useState<string>();
    const [headerChineseTraditional, setHeaderChineseTraditional] = useState<
      string
    >();
    const [bodyEnglish, setBodyEnglish] = useState<string>();
    const [bodySpanish, setBodySpanish] = useState<string>();
    const [bodyRussian, setBodyRussian] = useState<string>();
    const [bodyChineseTraditional, setBodyChineseTraditional] = useState<
      string
    >();
    const [image, setImage] = useState<string>();
    const [showCallNurse, setShowCallNurse] = useState(false);
    const [showOnLogin, setShowOnLogin] = useState(false);

    const fetchData = async () => {
      setLoading(true);
      if (modalId) {
        const modal = await fetchCampaignModal(modalId);

        setName(modal.name);
        setHeaderEnglish(modal.getTranslation(Language.ENGLISH).header);
        setHeaderSpanish(modal.getTranslation(Language.SPANISH).header);
        setHeaderRussian(modal.getTranslation(Language.RUSSIAN).header);
        setHeaderChineseTraditional(
          modal.getTranslation(Language.CHINESE_TRADITIONAL).header,
        );
        setBodyEnglish(modal.getTranslation(Language.ENGLISH).content);
        setBodySpanish(modal.getTranslation(Language.SPANISH).content);
        setBodyRussian(modal.getTranslation(Language.RUSSIAN).content);
        setBodyChineseTraditional(
          modal.getTranslation(Language.CHINESE_TRADITIONAL).content,
        );
        setImage(modal.image);
        setShowCallNurse(modal.showCallNurse);
        setShowOnLogin(modal.showOnLogin);
      }

      setLoading(false);
    };

    useEffect(() => {
      fetchData();
    }, [modalId]);

    const onSave = async () => {
      setShowError(false);

      // NOTE: If these variables aren't explicitly checked in this clause,
      // TS will complain that the params have an incompatible type.
      // Prevents factoring this check out into its own const or function.
      if (
        !(
          name &&
          headerEnglish &&
          bodyEnglish &&
          ((!!headerSpanish && !!bodySpanish) ||
            (!headerSpanish && !bodySpanish)) && // both or none
          ((!!headerRussian && !!bodyRussian) ||
            (!headerRussian && !bodyRussian)) && // both or none
          ((!!headerChineseTraditional && !!bodyChineseTraditional) ||
            (!headerChineseTraditional && !bodyChineseTraditional)) && // both or none
          image
        )
      ) {
        setShowError(true);
      } else {
        setLoading(true);
        const params = {
          name,
          image,
          translations: [
            // Backend will trim and filter automatically empty translations
            {
              language: Language.ENGLISH.value,
              header: headerEnglish,
              content: bodyEnglish,
            },
            {
              language: Language.SPANISH.value,
              header: headerSpanish,
              content: bodySpanish,
            },
            {
              language: Language.RUSSIAN.value,
              header: headerRussian,
              content: bodyRussian,
            },
            {
              language: Language.CHINESE_TRADITIONAL.value,
              header: headerChineseTraditional,
              content: bodyChineseTraditional,
            },
          ],
          // Backend works with array of enums instead individual flags.
          // so we perform the conversion here.
          ctaButtons: showCallNurse ? [CALL_TO_NURSE] : [],
          eventTriggers: showOnLogin ? [SHOW_ON_LOGIN] : [],
        };

        if (isNew) {
          try {
            await createCampaignModal(params);
            history.push('/admin/campaigns/app-modals');
          } catch (e) {
            setLoading(false);
            showGlobalError(e as string);
          }
        } else {
          try {
            await editCampaignModal(modalId, params);
            history.push('/admin/campaigns/app-modals');
          } catch (e) {
            setLoading(false);
            showGlobalError(e as string);
          }
        }
      }
    };

    return (
      <Panel>
        <Panel.Heading title={isNew ? 'Add Modal' : 'Edit Modal'} />
        <Panel.Body loading={loading} className="grid-wrapper">
          <TextInput
            data-cy="add-modal-name"
            className="grid-span-6"
            errorText={translate('global.missingRequiredField')}
            label={translate('campaigns.modalName')}
            onChange={setName}
            required
            showError={!name && showError}
            value={name}
          />
          <div className="grid-span-12" />
          <p className="medium grid-span-12 no-margin">
            {translate('campaigns.modalHeader')}
          </p>
          <TextInput
            data-cy="add-modal-header-english"
            className="grid-span-4"
            errorText={translate('global.missingRequiredField')}
            label={Language.ENGLISH.toString()}
            onChange={(value) => setHeaderEnglish(value?.trim())}
            required
            showError={!headerEnglish && showError}
            value={headerEnglish}
          />
          <TextInput
            data-cy="add-modal-header-spanish"
            className="grid-span-4"
            errorText={translate('global.missingRequiredField')}
            label={Language.SPANISH.toString()}
            onChange={(value) => setHeaderSpanish(value?.trim())}
            required={!!bodySpanish}
            showError={!!bodySpanish && !headerSpanish && showError}
            value={headerSpanish}
          />
          <TextInput
            data-cy="add-modal-header-russian"
            className="grid-span-4"
            errorText={translate('global.missingRequiredField')}
            label={Language.RUSSIAN.toString()}
            onChange={(value) => setHeaderRussian(value?.trim())}
            required={!!bodyRussian}
            showError={!!bodyRussian && !headerRussian && showError}
            value={headerRussian}
          />
          <TextInput
            data-cy="add-modal-header-chinese-traditional"
            className="grid-span-4"
            errorText={translate('global.missingRequiredField')}
            label={Language.CHINESE_TRADITIONAL.toString()}
            onChange={(value) => setHeaderChineseTraditional(value?.trim())}
            required={!!bodyChineseTraditional}
            showError={
              !!bodyChineseTraditional && !headerChineseTraditional && showError
            }
            value={headerChineseTraditional}
          />
          <div className="grid-span-12" />
          <p className="medium grid-span-6 no-margin">
            {translate('campaigns.modalBody')}
          </p>
          <p className="grid-span-6 no-margin text-right">
            <a onClick={() => setShowMarkdownModal(true)}>
              Markdown Instructions
            </a>
          </p>
          <MarkdownModal
            onHide={() => setShowMarkdownModal(false)}
            show={showMarkdownModal}
          />
          <TextInput
            data-cy="add-modal-body-english"
            className="grid-span-4"
            errorText={translate('global.missingRequiredField')}
            label={Language.ENGLISH.toString()}
            multiline={7}
            onChange={(value) => setBodyEnglish(value?.trim())}
            required
            showError={!bodyEnglish && showError}
            value={bodyEnglish}
          />
          <TextInput
            data-cy="add-modal-body-spanish"
            className="grid-span-4"
            errorText={translate('global.missingRequiredField')}
            label={Language.SPANISH.toString()}
            multiline={7}
            onChange={(value) => setBodySpanish(value?.trim())}
            required={!!headerSpanish}
            showError={!!headerSpanish && !bodySpanish && showError}
            value={bodySpanish}
          />
          <TextInput
            data-cy="add-modal-body-russian"
            className="grid-span-4"
            errorText={translate('global.missingRequiredField')}
            label={Language.RUSSIAN.toString()}
            multiline={7}
            onChange={(value) => setBodyRussian(value?.trim())}
            required={!!headerRussian}
            showError={!!headerRussian && !bodyRussian && showError}
            value={bodyRussian}
          />
          <TextInput
            data-cy="add-modal-body-chinese-traditional"
            className="grid-span-4"
            errorText={translate('global.missingRequiredField')}
            label={Language.CHINESE_TRADITIONAL.toString()}
            multiline={7}
            onChange={(value) => setBodyChineseTraditional(value?.trim())}
            required={!!headerChineseTraditional}
            showError={
              !!headerChineseTraditional && !bodyChineseTraditional && showError
            }
            value={bodyChineseTraditional}
          />
          <ImageSelect
            className="grid-span-12"
            errorText={translate('global.missingRequiredField')}
            label={translate('campaigns.modalImage')}
            onChange={setImage}
            options={ImageOptions}
            required
            showError={!image && showError}
            value={image}
          />
          <Checkbox
            data-cy="show-call-nurse"
            checked={showCallNurse}
            className="grid-span-3"
            label={translate('campaigns.showCallNurseButton')}
            onChange={() => setShowCallNurse(!showCallNurse)}
          />
          <div className="grid-span-9" />
          <Checkbox
            data-cy="show-modal-login"
            checked={showOnLogin}
            className="grid-span-3"
            label={translate('campaigns.showModalOnLogin')}
            onChange={() => setShowOnLogin(!showOnLogin)}
          />
        </Panel.Body>
        <Panel.Footer>
          <div className="left-side">
            <Button color="tertiary" href="/#/admin/campaigns/app-modals">
              {translate('global.cancel')}
            </Button>
          </div>
          <div className="right-side">
            <Button color="primary" onClick={onSave}>
              {translate('global.save')}
            </Button>
          </div>
        </Panel.Footer>
      </Panel>
    );
  },
);

export default EditCampaignModal;
