import { Enum } from './Enum';

export class ServiceLevel extends Enum {
  translationKey = 'serviceLevel';

  static asArray: ServiceLevel[] = [];

  static byKey: { [name: string]: ServiceLevel } = {};

  static readonly CO = new ServiceLevel('CO');

  static readonly HHA = new ServiceLevel('HHA');

  static readonly HM = new ServiceLevel('HM');

  static readonly PC = new ServiceLevel('PC');

  static readonly PCA = new ServiceLevel('PCA');

  static readonly LPN = new ServiceLevel('LPN');

  constructor(public readonly value: string) {
    super();
    ServiceLevel.asArray.push(this);
    ServiceLevel.byKey[value] = this;
  }
}

export default ServiceLevel;
