import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import {
  CareTeamPerson,
  LanguagePreference,
  UserPhone,
} from '@vestahealthcare/common/models';
import { isValidMail } from '@vestahealthcare/common/utils/format';

import { Button, CollapsableSidebar, TextInput } from 'styleguide-v2';

import { EditLanguages } from 'dash/src/components/EditLanguages';
import { EditPhones } from 'dash/src/components/EditPhones';
import { UpdateCareTeamPersonParams } from 'dash/src/services/CareTeamServices';

interface Props {
  open?: boolean;
  onClose: () => void;
  onSubmit: (
    id: number,
    params: UpdateCareTeamPersonParams,
  ) => Promise<boolean>;
  person?: CareTeamPerson;
}

export const EditContactModal = ({
  open,
  onClose,
  onSubmit,
  person,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [phones, setPhones] = useState<UserPhone[]>([]);
  const [languages, setLanguages] = useState<Partial<LanguagePreference>[]>([]);
  const [email, setEmail] = useState<string>();

  useEffect(() => {
    setPhones(person?.phones || []);
    setLanguages(person?.languages || []);
    setEmail(person?.email);
  }, [person, open]);

  useEffect(() => {
    setLoading(false);
    setSubmitted(false);
  }, [open]);

  const validate = () =>
    (!email || isValidMail(email)) &&
    (!languages?.length || !languages.some(({ language }) => !language)) &&
    (!phones?.length ||
      !phones.some(({ phone }) => !phone?.number || !phone?.type));

  const doSubmit = async () => {
    setSubmitted(true);
    if (person && validate()) {
      setLoading(true);
      try {
        await onSubmit(person.id, {
          email,
          phones,
          languages: languages as LanguagePreference[],
        });
      } finally {
        setSubmitted(false);
        setLoading(false);
      }
    }
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={!!open}
      title={
        <h2>
          {translate('careTeam.modals.editContactModalTitle', {
            name: person?.fullName,
          })}
        </h2>
      }
      size={550}
    >
      <CollapsableSidebar.Body>
        <TextInput
          className="grid-span-12"
          data-cy="care-team-steps-email"
          error={
            submitted &&
            email &&
            !isValidMail(email) &&
            translate('common.errors.invalidEmail')
          }
          label={translate('careTeam.common.email')}
          onChange={(email) => {
            setEmail(email);
          }}
          value={email}
        />

        <EditLanguages
          className="grid-span-12"
          languages={languages}
          onChange={(items) => {
            setLanguages(items ? [...items] : []);
          }}
          submitted={submitted && !!languages?.length}
        />
        <EditPhones
          className="grid-span-12"
          phones={phones}
          onChange={(items) => {
            setPhones([...items]);
          }}
          showBusinessType
          submitted={submitted && !!phones?.length}
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="care-team-detail-edit-contact-close"
          onClick={onClose}
        >
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="care-team-detail-edit-contact-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default EditContactModal;
