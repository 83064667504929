import React, { useState } from 'react';
import { Button } from 'styleguide';
import { translate } from '@vestahealthcare/common/i18n';
import { AddIterationIcon } from '../Icons';
import ListPickerRow, { Option } from './ListPickerRow';
import './main.less';

interface Props {
  autoFocus?: boolean;
  options: Option[];
  preferred: string;
  readOnlyPreferred?: boolean;
  selection: string[];
  onChange(selection: string[], preferred: string): void;
  validator(): boolean;
  errorText?: string | boolean;
  submitted?: boolean;
  required?: boolean;
}

const getDefaultPreferred = (selections: string[]) =>
  selections.find((item) => !!item) || '';

const hasEmptyRows = (selections: string[]) => selections.some((item) => !item);

const removeEmptySelections = (selection: string[]) =>
  selection.filter((item) => !!item);

const ListPickerWithPreferred = (props: Props) => {
  const [selections, setSelections] = useState(props.selection);
  const [preferred, setPreferred] = useState(
    props.preferred || getDefaultPreferred(selections),
  );
  const [readOnlyPreferred, setReadOnlyPreferred] = useState(
    props.readOnlyPreferred,
  );

  const addEmptyRow = () => setSelections(selections.concat(['']));

  if (!selections.length) {
    addEmptyRow();
  }

  const onChange = (newSelections: string[], newPreferred: string) => {
    setSelections(newSelections);
    setPreferred(newPreferred);
    props.onChange(removeEmptySelections(newSelections), newPreferred);
  };

  const remove = (item: string) => {
    const filteredList = selections.filter((i) => i !== item);
    const newPreferred =
      item === preferred ? getDefaultPreferred(filteredList) : preferred;
    onChange(filteredList, newPreferred);
  };

  const updatePreferred = (
    item: string,
    isPreferred: boolean,
    selections: string[],
  ) => {
    let newPreferred = isPreferred ? item : preferred;
    // if no preferred, default to first item
    if (!newPreferred || !selections.includes(newPreferred)) {
      newPreferred = getDefaultPreferred(selections);
    }
    return newPreferred;
  };

  const setItemAtIndex = (
    item: string,
    isPreferred: boolean,
    index: number,
  ) => {
    const newSelections = [...selections];
    newSelections[index] = item;
    const newPreferred = updatePreferred(item, isPreferred, newSelections);
    onChange(newSelections, newPreferred);
  };

  const { autoFocus, validator, required, errorText, submitted } = props;

  return (
    <div className="language-picker">
      <div className="grid-wrapper-none">
        <div className="grid-span-2">
          {translate('personalDetails.preferred')}
        </div>
      </div>
      {selections.map((row, index) => (
        <ListPickerRow
          onChange={(value, isPreferred) =>
            setItemAtIndex(value, isPreferred, index)
          }
          options={props.options.filter(
            (item) =>
              !selections.includes(item.value.toString()) || item.value === row,
          )}
          value={row}
          isPreferred={row === preferred}
          disableSelect={readOnlyPreferred && row === preferred}
          disableRadio={readOnlyPreferred}
          autoFocus={autoFocus && index === 0}
          onRemove={
            (readOnlyPreferred && index === 0 && row !== preferred) ||
            (index > 0 && (!readOnlyPreferred || row !== preferred))
              ? remove
              : undefined
          }
          required={required}
          errorText={errorText}
          submitted={submitted}
          validator={validator}
          key={index}
        />
      ))}
      <Button
        className="btn-add"
        color="tertiary"
        onClick={addEmptyRow}
        disabled={hasEmptyRows(selections)}
      >
        <AddIterationIcon className="margin-right" />
        {translate('assessment.languagePicker.addLanguage')}
      </Button>
    </div>
  );
};

export default ListPickerWithPreferred;
