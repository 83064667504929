import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { HealthReport, PaginationType } from '@vestahealthcare/common/models';

import { Panel, ToggleButton, ToggleDateRange } from 'styleguide-v2';

import { HealthReportTable } from 'dash/src/pages/MemberProfile/HealthReports/HealthReportTable';
import { fetchHealthReports } from 'dash/src/services';

const useStyles = makeStyles({
  dateRange: {
    marginTop: '-2.5rem',
  },
});

const PAGE_SIZE = 25;

const HEALTH_REPORT_TAB_COMPLETED = 'health-report-completed-tab';
const HEALTH_REPORT_TAB_IN_PROGRESS = 'health-report-in-progress-tab';
const HEALTH_REPORT_TAB_ALL = 'health-report-all-tab';
const HEALTH_REPORT_TABS = [
  { label: translate('dashboard.all'), value: HEALTH_REPORT_TAB_ALL },
  {
    label: translate('dashboard.inProgress'),
    value: HEALTH_REPORT_TAB_IN_PROGRESS,
  },
  {
    label: translate('dashboard.completed'),
    value: HEALTH_REPORT_TAB_COMPLETED,
  },
];

const REFRESH_INTERVAL = 5 * 60 * 1000;

export const HealthReportDashboard = () => {
  const styles = useStyles();

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);

  const [selectedTab, setSelectedTab] = useState<string>(
    HEALTH_REPORT_TAB_IN_PROGRESS,
  );
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    moment().subtract(1, 'week').toDate(),
    moment().toDate(),
  ]);

  const [loading, setLoading] = useState<boolean>(false);
  const [healthReports, setHealthReports] = useState<HealthReport[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();

  const getCompleted = (tab: string) => {
    if (tab === HEALTH_REPORT_TAB_COMPLETED) return true;
    if (tab === HEALTH_REPORT_TAB_IN_PROGRESS) return true;
  };

  const getReports = async () => {
    if (!dateRange) return;
    if (page && page * pageSize < healthReports.length) return;

    setLoading(true);

    const { items, pagination } = await fetchHealthReports(
      dateRange && dateRange[0]
        ? moment(dateRange[0]?.getTime()).startOf('day').toISOString()
        : '',
      dateRange && dateRange[1]
        ? moment(dateRange[1]?.getTime()).endOf('day').toISOString()
        : '',
      page * pageSize,
      pageSize,
      getCompleted(selectedTab),
    );

    if (page === 0) {
      setHealthReports(items);
    } else {
      setHealthReports([...healthReports, ...items]);
    }
    setPagination(pagination);
    setLoading(false);
  };

  useEffect(() => {
    getReports();
    const refreshInterval = setInterval(() => getReports(), REFRESH_INTERVAL);

    return () => {
      clearTimeout(refreshInterval);
    };
  }, [dateRange, selectedTab, page, pageSize]);

  return (
    <Panel data-cy="health-reports-section">
      <Panel.Heading title={translate('dashboard.healthReports')}>
        <Panel.Filters>
          <ToggleDateRange
            className={styles.dateRange}
            items={[7, 30, 90, 'custom']}
            onChange={(from, to) => {
              if (from && to) {
                // eslint-disable-next-line @typescript-eslint/no-implied-eval
                setDateRange([
                  moment(from.getTime()).toDate(),
                  moment(to.getTime()).toDate(),
                ]);
              }
              setPage(0);
            }}
          />
        </Panel.Filters>
        <Panel.Actions>
          <ToggleButton
            items={HEALTH_REPORT_TABS}
            onChange={setSelectedTab}
            value={selectedTab}
            size="small"
          />
        </Panel.Actions>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <HealthReportTable
          reports={healthReports}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
          pagination={pagination}
          showMember
        />
      </Panel.Body>
    </Panel>
  );
};

export default HealthReportDashboard;
