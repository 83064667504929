// eslint-disable-next-line max-classes-per-file
import { makeArray } from './Model';

class MemberCarePlanObjectiveData {
  description = '';

  interventions: string[] = [];

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}
class MemberCarePlanGoalData {
  description = '';

  objectives: MemberCarePlanObjectiveData[] = [];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.objectives = makeArray(obj.objectives, MemberCarePlanObjectiveData);
  }
}

export class MemberCarePlan {
  description = '';

  goals: MemberCarePlanGoalData[];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.goals = makeArray(obj.goals, MemberCarePlanGoalData);
  }
}

export default MemberCarePlan;
