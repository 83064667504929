import React from 'react';
import { WidgetProps } from '../types';
import { TextInput } from 'styleguide';
import { EMPTY } from '@vestahealthcare/common/utils/constants';
import { translate } from '@vestahealthcare/common/i18n';

const TextWidget = ({
  readonly,
  value,
  onChange,
  formContext,
  rawErrors,
  options,
}: WidgetProps) => {
  if (readonly || formContext.readonly) {
    return <p>{value || EMPTY}</p>;
  }
  const hasErrors = !!rawErrors && !!rawErrors.length;
  const errorText =
    (hasErrors && rawErrors && rawErrors[rawErrors.length - 1]) || undefined;

  return (
    <TextInput
      onChange={onChange}
      value={value}
      placeholder={translate('global.enterValue')}
      errorText={errorText}
      showError={!!errorText}
      hasWarning
      warningText={options.warningText}
    />
  );
};

export default TextWidget;
