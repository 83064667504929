import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ViewIcon from '@mui/icons-material/RemoveRedEye';

import { translate } from '@vestahealthcare/common/i18n';
import { MemberDocument } from '@vestahealthcare/common/models';

import { IconButton, Panel, Table, UpdatedBy } from 'styleguide-v2';

import {
  fetchAllMemberDocuments,
  fetchDocumentBlob,
} from 'dash/src/services/PHIPServices';

type Params = {
  careTrack: string;
  patientId: string;
};

const MEMBER_DOCUMENT_COLUMNS = (
  viewDocument: (doc: MemberDocument) => void,
) => [
  {
    headerName: translate('memberDocuments.name'),
    component: ({ name, type }: MemberDocument) =>
      `${type.toString()} - ${name}`,
  },
  {
    headerName: translate('memberDocuments.uploaded'),
    component: ({ createdBy, createdAt }: MemberDocument) => (
      <UpdatedBy user={createdBy?.fullName} date={createdAt} />
    ),
    width: 250,
  },
  {
    headerName: '',
    component: (doc: MemberDocument) => (
      <IconButton size="small" onClick={() => viewDocument(doc)}>
        <ViewIcon />
      </IconButton>
    ),
    width: 20,
  },
];

const Documents = (props: RouteComponentProps<Params>) => {
  const { patientId } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState<MemberDocument[]>([]);

  const viewDocument = async (doc: MemberDocument) => {
    const docBlob = await fetchDocumentBlob(patientId, doc.id);
    const link = document.createElement('a');
    link.href = URL.createObjectURL(docBlob);
    link.target = '_blank';
    link.click();
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const docs = await fetchAllMemberDocuments(patientId);
      setDocuments(docs);
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <Panel>
      <Panel.Heading title={translate('sidebar.documents')} />
      <Panel.Body loading={loading}>
        <Table
          className="no-margin-bottom"
          config={{
            columns: MEMBER_DOCUMENT_COLUMNS(viewDocument),
            compact: true,
            data: documents,
            paginationOptions: [-1],
          }}
          data-cy="insurance-list"
          empty={translate('memberDocuments.empty')}
        />
      </Panel.Body>
    </Panel>
  );
};

export default withRouter(Documents);
