import React, { useState } from 'react';
import { isEmpty, isNumber, cloneDeep } from 'lodash';
import {
  ObjectFieldTemplateProps,
  FieldTemplateProps,
  UiSchema,
} from 'react-jsonschema-form';
import { Button, Modal, EditIcon } from 'styleguide';
import { translate } from '@vestahealthcare/common/i18n';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

const OnlyInputFieldTemplate = (props: FieldTemplateProps) => {
  const { children, uiSchema } = props;
  const options = uiSchema['ui:options'] || {};
  const label = options.readOnlyLabel;

  return (
    <>
      {label && <span className="pull-left margin-right">{`${label}: `}</span>}
      {children}
    </>
  );
};

const ObjectFieldTemplate = (props: ObjectFieldTemplateProps) => {
  const {
    title,
    description,
    properties,
    idSchema: { $id },
    formData,
    formContext,
    uiSchema,
    schema,
  } = props;
  const canEdit = !formContext.readonly;
  const noData = !formData || Object.keys(formData).length === 0;

  // If there's no data, we assume we created a new element from
  // ArrayFieldTemplate, so we want to show the modal as soon we
  // mount the component.
  const [isOpen, setOpen] = useState(noData);

  // Also store the current form status if we're adding a new item
  // from ArrayFieldTemplate. Fortunately it will store the status
  // previous to the new item creation, so cancelling will remove
  // the empty item.
  const [storedFormData, storeFormData] = useState(
    noData ? formContext.formData : null,
  );

  // Keep root object (main form) as a fieldset (default behaviour)
  if ($id === 'root') {
    return (
      <fieldset id={$id}>
        {properties.map((property) => property.content)}
      </fieldset>
    );
  }

  const edit = () => {
    setOpen(true);
    // react-jsonschema-form stores changes directly in the
    // formData object. Store the current entire form status,
    // for later recovery if we cancel the modal.
    storeFormData(cloneDeep(formContext.formData));
  };

  const cancelEdit = () => {
    setOpen(false);
    if (storedFormData) {
      // Restore old form data status when we opened the modal.
      // We update the entire form data. Not ideal, but easier than
      // try to find the object we're editing in this component.
      formContext.setFormData(storedFormData);
    }
  };

  const getErrors = () => {
    // Validate required fields. Ideally we should check
    // the entire subschema, but went for this lightweight solution
    // for now.
    const required = schema.required || [];
    const errors = required.filter((key) => {
      const value = formData[key];
      return isEmpty(value) && !isNumber(value);
    });

    return errors;
  };

  const save = () => {
    if (getErrors().length > 0) {
      return;
    }

    // Just keep changes made and continue
    storeFormData(null);
    setOpen(false);
  };

  const options = uiSchema['ui:options'] || ({} as any);

  const renderReadOnlyProperty = (property: any) => {
    const isHeader = options.header && property.name === options.header;
    const value = formData[property.name];
    // Do not render if has no value.
    // note: isEmpty returns true with numbers
    if (isEmpty(value) && !isNumber(value)) return null;

    // Clone the actual element with custom props.
    // We want to show the read-only value. Modify the UI schema for that
    const readOnlyContent = React.cloneElement(property.content, {
      readonly: true,
      uiSchema: {
        ...property.content.props.uiSchema,
        // Following undefined are for removing any possible customization
        // We want to use the builtin fields
        'ui:widget': undefined,
        'ui:field': undefined,
        'ui:FieldTemplate': OnlyInputFieldTemplate,
      },
    });

    return isHeader ? (
      <div className="header">{readOnlyContent}</div>
    ) : (
      readOnlyContent
    );
  };

  const hasErrors = getErrors().length > 0;

  const saveButton = (
    <Button color="primary" onClick={() => save()} disabled={hasErrors}>
      {translate('global.save')}
    </Button>
  );

  const cancelButton = (
    <Button color="tertiary" onClick={() => cancelEdit()}>
      {translate('global.cancel')}
    </Button>
  );

  return (
    <>
      {canEdit && (
        <div className="pull-right">
          <EditIcon tag="button" onClick={() => edit()} />
        </div>
      )}
      {noData ? EMPTY : properties.map(renderReadOnlyProperty)}
      {canEdit && (
        <Modal show={isOpen} onHide={() => cancelEdit()}>
          <Modal.Header modalTitle={title} closeButton />
          <Modal.Body className="json-schema-form">
            {description && <p>{description}</p>}
            <fieldset id={$id}>
              {properties.map((property) => property.content)}
            </fieldset>
          </Modal.Body>
          <Modal.Footer leftSide={cancelButton} rightSide={saveButton} />
        </Modal>
      )}
    </>
  );
};

export default ObjectFieldTemplate;
