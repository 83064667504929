import { Employee, StoredFilter } from '@vestahealthcare/common/models';

import { fetchSelfStoredFilters } from 'dash/src/services/EmployeeServices';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const fetchStoredFilters = createAsyncThunk(
  'employeeSlice/fetchStoredFilters',
  async () => {
    return await fetchSelfStoredFilters();
  },
);

type EmployeeEventsState = {
  actingUser?: Employee;
  loadingStoredFilters: boolean;
  storedFilters?: StoredFilter[];
};

const initState: EmployeeEventsState = {
  actingUser: undefined,
  loadingStoredFilters: false,
  storedFilters: undefined,
};

const employeeSlice = createSlice({
  name: 'employeeSlice',
  initialState: initState,
  reducers: {
    onEmployeeUpdate: (state, action) => {
      const actingUser = action.payload as Employee;
      state.actingUser = actingUser;
    },
    onStoredFilterAdd: (state, action) => {
      const filter = action.payload as StoredFilter;
      state.storedFilters = [...(state.storedFilters || []), filter];
    },
    onStoredFilterChange: (state, action) => {
      const filter = action.payload as StoredFilter;
      state.storedFilters = state.storedFilters?.map((item) =>
        item.id === filter.id ? filter : item,
      );
    },
    onStoredFilterDeleted: (state, action) => {
      const filter = action.payload as StoredFilter;
      state.storedFilters = state.storedFilters?.filter(
        (item) => item.id !== filter.id,
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStoredFilters.fulfilled, (state, action) => {
      state.storedFilters = action.payload;
      state.loadingStoredFilters = false;
    });
    builder.addCase(fetchStoredFilters.pending, (state) => {
      state.loadingStoredFilters = true;
    });
  },
});

export const {
  onEmployeeUpdate,
  onStoredFilterAdd,
  onStoredFilterChange,
  onStoredFilterDeleted,
} = employeeSlice.actions;

export default employeeSlice.reducer;
