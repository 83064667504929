import { Frequency } from '../enums';
import { MemberContactInfoMethod } from './MemberContactInfoMethod';
import { MemberContactInfoTimes } from './MemberContactInfoTimes';
import { makeArray } from './Model';

export class CareTeamContactInfo {
  id = 0;

  contactFrequency?: Frequency;

  contactMethods?: MemberContactInfoMethod[];

  contactTimes?: MemberContactInfoTimes[];

  note = '';

  constructor(obj: any) {
    Object.assign(this, obj);

    this.contactFrequency = obj.contactFrequency
      ? Frequency.byKey[obj.contactFrequency]
      : undefined;
    this.contactMethods = makeArray(
      obj.contactMethods,
      MemberContactInfoMethod,
    );
    this.contactTimes =
      obj.contactTimes && makeArray(obj.contactTimes, MemberContactInfoTimes);
  }
}

export default CareTeamContactInfo;
