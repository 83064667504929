import moment, { Moment } from 'moment';

import {
  BaseEnum,
  CreateIncidentParams,
  Employee,
  EmployeeGroup,
  Incident,
  IncidentAction,
  IncidentDetail,
  IncidentInvolvedActor,
  IncidentStatus,
} from '@vestahealthcare/common/models';
import { IncidentDetailResponse } from '@vestahealthcare/common/models/IncidentDetailResponse';
import { EXPORT_DATE_FORMAT } from '@vestahealthcare/common/utils/constants';

import Api, { PaginatedResponse, PaginationParams } from './Api';

const SORT_ENUM = { sort: 'description asc' };

export const fetchIncidentTypes = async (): Promise<BaseEnum[]> => {
  const response = await Api.getv2('/incidents/types', SORT_ENUM);
  const {
    incidentTypes: { items },
  } = response;
  return items.map((x: any) => new BaseEnum(x));
};

export const fetchIncidentLocations = async (): Promise<BaseEnum[]> => {
  const {
    incidentLocations: { items },
  } = await Api.getv2('/incidents/locations', SORT_ENUM);
  return items.map((x: any) => new BaseEnum(x)).sort(BaseEnum.sortOtherLast);
};

export const fetchIncidentActions = async (): Promise<IncidentAction[]> => {
  const {
    incidentActions: { items },
  } = await Api.getv2('/incidents/actions', SORT_ENUM);
  return items
    .map((x: any) => new IncidentAction(x))
    .sort(BaseEnum.sortOtherLast);
};

export const fetchIncidentEntities = async (): Promise<BaseEnum[]> => {
  const {
    incidentFederalEntities: { items },
  } = await Api.getv2('/incidents/federal-entities', SORT_ENUM);
  return items.map((x: any) => new BaseEnum(x)).sort(BaseEnum.sortOtherLast);
};

export const fetchIncidentHelpOptions = async (): Promise<BaseEnum[]> => {
  const {
    incidentHelpTypes: { items },
  } = await Api.getv2('/incidents/help-types');
  return items.map((x: any) => new BaseEnum(x));
};

export const fetchIncidentInvolvedActors = async (): Promise<
  IncidentInvolvedActor[]
> => {
  const {
    incidentInvolvedActors: { items },
  } = await Api.getv2('/incidents/involved-actors', SORT_ENUM);
  return items
    .map((x: any) => new IncidentInvolvedActor(x))
    .sort(
      ({ id: a }: IncidentInvolvedActor, { id: b }: IncidentInvolvedActor) => {
        if (a === IncidentInvolvedActor.MEMBER) return -1;
        if (b === IncidentInvolvedActor.MEMBER) return 1;
        return 0;
      },
    );
};

export const fetchIncidentStatuses = async (): Promise<IncidentStatus[]> => {
  const {
    incidentStatuses: { items },
  } = await Api.getv2('/incidents/statuses');
  return items.map((x: any) => new IncidentStatus(x));
};

export const fetchIncidentDMETypes = async (): Promise<BaseEnum[]> => {
  const {
    incidentDMETypes: { items },
  } = await Api.getv2('/incidents/dme-types');
  return items.map((x: any) => new BaseEnum(x));
};

export const fetchIncidentAnticoagulants = async (): Promise<BaseEnum[]> => {
  const {
    incidentAnticoagulants: { items },
  } = await Api.getv2('/incidents/anticoagulants');
  return items.map((x: any) => new BaseEnum(x));
};

export const fetchIncidentFollowUp = async (): Promise<BaseEnum[]> => {
  const {
    incidentFollowUpAppointmentTypes: { items },
  } = await Api.getv2('/incidents/follow-up-appointment-types');
  return items.map((x: any) => new BaseEnum(x));
};

const prepareParams = ({
  actions,
  actionDate,
  federalEntities,
  incidentDate,
  involvedActors,
  changeOfCondition,
  fall,
  hospitalization,
  location,
  memberId,
  reportedDate,
  types,
  ...rest
}: CreateIncidentParams) => ({
  actions: actions?.map(({ id }) => id),
  actionDate: actionDate ? moment(actionDate.getTime()).format() : undefined,
  federalEntities: federalEntities?.map(({ id }) => id),
  incidentDate: incidentDate
    ? moment(incidentDate.getTime()).format()
    : undefined,
  involvedActors: involvedActors?.map(({ id }) => id),
  changeOfCondition: changeOfCondition
    ? {
        ...changeOfCondition,
        additionalHelpType: changeOfCondition?.additionalHelpType?.id,
      }
    : undefined,
  fall: fall
    ? {
        ...fall,
        dmeTypes: fall?.dmeTypes?.map(({ id }) => id),
        anticoagulantIds: fall?.anticoagulantIds?.map(({ id }) => +id),
      }
    : undefined,
  hospitalization: hospitalization
    ? {
        ...hospitalization,
        followUpAppointmentTypes: hospitalization.followUpAppointmentTypes?.map(
          ({ id }) => id,
        ),
      }
    : undefined,
  location: location?.id,
  memberId: memberId?.id,
  reportedDate: reportedDate
    ? moment(reportedDate?.getTime()).format()
    : undefined,
  types: types?.map(({ id }) => id),
  ...rest,
});

export const createIncident = async (params: CreateIncidentParams) => {
  await Api.postv2JSON('incidents', prepareParams(params));
};

export type UpdateIncidentParams = Partial<
  Omit<CreateIncidentParams, 'eventId' | 'description' | 'memberId'>
> & {
  assigneeId?: Employee | null;
  assigneeGroupId?: EmployeeGroup | null;
  emails?: string[];
  statusNote?: string;
  externalNote?: string;
  status?: IncidentStatus;
};

export const updateIncident = async (
  id: number,
  { assigneeId, assigneeGroupId, status, ...params }: UpdateIncidentParams,
): Promise<IncidentDetail> => {
  const { incident } = await Api.patchv2JSON(`incidents/${id}`, {
    ...prepareParams(params as CreateIncidentParams),
    assigneeId: assigneeId === null ? 0 : assigneeId?.id,
    assigneeGroupId: assigneeGroupId === null ? 0 : assigneeGroupId?.id,
    status: status?.id,
  });
  return new IncidentDetail(incident);
};

export type GetIncidentParams = PaginationParams & {
  assigneeId?: number[];
  assigneeGroupId?: number[];
  brandId?: string[];
  communityResourceId?: number[];
  count?: boolean;
  from?: Moment;
  to?: Moment;
  excludedReferralSourceId?: number[];
  memberId?: number[];
  memberName?: string;
  npOwnerId?: number[];
  referralSourceId?: number[];
  status?: string[];
  type?: string[];
};

export const fetchIncidents = async ({
  from,
  to,
  ...params
}: GetIncidentParams): Promise<PaginatedResponse<Incident>> => {
  const {
    incidents: { pagination, items },
  } = await Api.getv2('/incidents', {
    from: from?.startOf('day')?.format(),
    to: to?.endOf('day')?.format(),
    ...params,
  });
  return {
    items: items.map((x: any) => new Incident(x)),
    pagination,
  };
};

export const fetchIncidentDetail = async (
  id: number,
): Promise<IncidentDetailResponse> => {
  const { incidentDetail } = await Api.getv2(`/incidents/${id}/detail`);
  return new IncidentDetailResponse(incidentDetail);
};

export const downloadIncidentsDashboard = async ({
  from,
  to,
  ...params
}: GetIncidentParams) => {
  const blob: Blob = await Api.getv2Blob('/incidents/export', {
    from: from?.startOf('day')?.format(),
    to: to?.endOf('day')?.format(),
    ...params,
  });
  const csvURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute(
    'download',
    `Incidents-Export_${moment().format(EXPORT_DATE_FORMAT)}.csv`,
  );
  tempLink.click();
};

export const downloadIncidentReport = async (
  incident: IncidentDetail,
  patientId?: string,
) => {
  const blob: Blob = await Api.getv2Blob(
    `/incidents/${incident.id}/export-to-pdf`,
    { patientId },
  );
  const csvURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute(
    'download',
    `${incident.member.firstName}-${
      incident.member.lastName
    }_Incident_${incident.incidentDate?.format(EXPORT_DATE_FORMAT)}.pdf`,
  );
  tempLink.click();
};
