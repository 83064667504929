import { validate as phoneValidate } from '@vestahealthcare/common/utils/phone';

import { Column } from 'styleguide-v2/src/components/Table';

import { isUndefined } from 'lodash';

import { CSVFileInfo } from './index';
import csv from 'csvtojson';

export interface ParsedFile {
  file: File;
  headers: string[];
  fileContents: {}[];
  isCareTeamFile: boolean;
}

/**
 * Parses a CSV file
 *
 * @param file
 */
export function localFileParse(file: File): Promise<ParsedFile> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = async () => {
      if (typeof reader.result === 'string') {
        const fileContents = await csv().fromString(reader.result);
        const headers = reader.result
          .split(/[\r\n]+/)[0]
          ?.trim()
          .split(',');
        const isCareTeamFile = headers[0].toLowerCase() === 'member id';
        resolve({ file, fileContents, headers, isCareTeamFile });
      }
    };
  });
}

/**
 * filters columns of each row of the CSV for only phone number (not type) columns, then validates each phone number found
 *
 * @param fileContents array of objects representing each row of the CSV. Object keys are the headers parsed.
 * If there are more columns in all rows than the header or more columns in the header than all rows, a `field##` key autogenerates
 * @returns invalid phone number strings, including phone numbers from unsupported countries
 */
export function getInvalidPhones(fileContents: any[]) {
  const fixThesePhones: string[] = [];
  fileContents.forEach((fileContent) => {
    Object.entries(fileContent)
      .filter(([key, val]) => {
        const caseInsensitiveKey = key.toLowerCase();
        return (
          caseInsensitiveKey.includes('phone') &&
          !caseInsensitiveKey.includes('type') &&
          val !== ''
        );
      })
      .forEach((entry) => {
        const phone = entry[1] as string;
        if (!phoneValidate(phone)) {
          fixThesePhones.push(phone);
        }
      });
  });
  return fixThesePhones;
}

/**
 * create an object representing the diff between the CSV column headers and the MemberTableColumns
 *
 * @param fileContents array of objects representing each row of the CSV. Object keys are the headers parsed.
 * If there are more columns in all rows than the header or more columns in the header than all rows, a `field##` key autogenerates
 * @param columns the columns of the detected upload type via MemberTableColumns
 * @returns extraAttrs: columns not found in MemberTableColumns, missingAttrs: columns existing in MemberTableColumns missing from the CSV
 */
export function getSchemaDiff(fileContents: any[], columns: Column[]) {
  const requiredAttrs = columns.map((col) => col.field) as string[];
  const providedAttrs = Object.keys(fileContents[0]);

  const extraAttrs: string[] = [];
  const missingAttrs = providedAttrs.reduce((attrs, providedAttr) => {
    const matchingIndex = attrs.findIndex(
      (attr) => attr.toLowerCase() === providedAttr.toLowerCase(),
    );
    if (matchingIndex < 0) {
      extraAttrs.push(providedAttr);
    } else {
      attrs.splice(matchingIndex, 1);
    }
    return attrs;
  }, requiredAttrs);

  return { extraAttrs, missingAttrs };
}

/**
 * Converts a JSON back to a CSV file
 *
 * @param headers This defines the column order
 * @param data
 */
export const jsonToCSV = (headers: string[], data: any[]): string => {
  return [
    headers.join(','),
    ...data.map((item) =>
      headers
        .map((key) => (isUndefined('undefined') ? '' : item[key]))
        .join(','),
    ),
  ].join('\n');
};

/**
 * Generate a new CSV removing the specified rows
 *
 * @param file
 * @param rowsToExclude
 */
export const getFilteredCSVFile = (
  file: CSVFileInfo,
  rowsToExclude: number[],
): string => {
  const { fileContents, headers } = file;

  const filteredContent = fileContents.filter(
    (_item, index) => !rowsToExclude.includes(index),
  );

  return jsonToCSV(headers, filteredContent);
};

/**
 * Make the browser download a string content as a file. Needs to be called on a
 * "click" iteration in order to prevent blocking from the browser
 *
 * @param content
 * @param filename
 */
export const download = (content: string, filename: string) => {
  if (!content) return;

  const element = document.createElement('a');
  element.setAttribute(
    'href',
    `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`,
  );
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();
  document.body.removeChild(element);
};
