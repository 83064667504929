import { Selectable } from '../enums/Enum';

type ConsentRequestName = 'CCM' | 'HIE' | 'HIPAA' | 'VESTA_PROGRAM' | 'TEXT';

const shiftItem = (items: Selectable[], item: string, position: number) => {
  const index = items.findIndex((option) => option.value === item);
  if (index !== position && index !== -1) {
    items.splice(position, 0, items.splice(index, 1)[0]);
  }
  return items;
};

class ConsentRequest {
  id = 0;

  name: ConsentRequestName;

  title = '';

  version = 0;

  answerOptions: Selectable[] = [];

  enabled = true;

  exceptions = false;

  createdAt = 0;

  updatedAt = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.name = obj.name || '';
    this.answerOptions = obj.answerOptions.map(
      ({ key, name }: { key: string; name: string }) => ({
        label: name,
        value: key,
      }),
    );
  }

  get sortValue() {
    switch (this.name) {
      case 'VESTA_PROGRAM':
        return 0;
      case 'CCM':
        return 1;
      case 'HIPAA':
        return 2;
      case 'HIE':
        return 3;
      default:
        return 100; // arbitrary large number so that new/unknown consents appear at the bottom
    }
  }

  get sortedAnswerOptions() {
    const options = this.answerOptions.slice();
    shiftItem(options, 'YES', 0);
    shiftItem(options, 'NO', options.length - 1);
    return options;
  }
}

export default ConsentRequest;
