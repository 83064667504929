import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  CareQuestion,
  HealthReport,
  PaginationType,
} from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import {
  BackgroundColors,
  MemberInfo,
  Table,
  TableItem,
  UpdatedBy,
} from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

import { HealthReportDetail } from './HealthReportDetail';

interface HealthReportWithDetail extends HealthReport, TableItem {}

interface Props {
  reports: HealthReportWithDetail[];
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  pagination?: PaginationType;
  showMember?: boolean;
}

const HEALTH_REPORT_COLUMNS = (showMember?: boolean) => {
  let columns = [] as Column[];

  if (showMember) {
    columns.push({
      headerName: translate('healthReports.table.member'),
      component: ({ patient }: HealthReport) => (
        <MemberInfo
          link={`#/patients/${patient?.id}/health-reports`}
          member={patient}
          showBirthDate
          smallName
        />
      ),
      width: 200,
    });
  }

  columns = [
    ...columns,
    {
      headerName: translate('healthReports.table.reporter'),
      field: 'caregiver.fullName',
      width: 200,
    },
  ];

  if (!showMember) {
    columns = [
      ...columns,
      ...[
        {
          headerName: translate('healthReports.table.enteredBy'),
          field: 'creator.fullName',
        },
        {
          headerName: translate('healthReports.table.method'),
          component: ({ channel }: HealthReport) =>
            channel?.description || EMPTY,
        },
        {
          headerName: translate('healthReports.table.direction'),
          component: ({ direction }: HealthReport) =>
            direction?.description || EMPTY,
        },
      ],
    ];
  }

  columns = [
    ...columns,
    ...[
      {
        headerName: translate('healthReports.table.completion'),
        component: ({ completedQuestions, totalQuestions }: HealthReport) =>
          `${completedQuestions} / ${totalQuestions}`,
      },
      {
        headerName: translate('healthReports.table.date'),
        component: ({ startTime }: HealthReport) => (
          <UpdatedBy date={startTime} />
        ),
        width: 185,
      },
    ],
  ];

  return columns;
};

const useStyles = makeStyles({
  shadowRow: {
    '&&': {
      backgroundColor: `${BackgroundColors.lightCoral}!important`,
    },
  },
});

export const HealthReportTable = ({
  reports,
  onChangePage,
  onChangePageSize,
  pagination,
  showMember,
}: Props) => {
  const styles = useStyles();
  const [rows, setRows] = useState<HealthReportWithDetail[]>([]);

  useEffect(() => {
    setRows(reports);
  }, [reports]);

  const onClickRow = (row: HealthReportWithDetail) => {
    if (row.detail) {
      delete row.detail;
    } else if (row.careQuestions?.length) {
      row.detail = <HealthReportDetail report={row} />;
    }
    setRows([...rows]);
  };

  return (
    <Table
      config={{
        columns: HEALTH_REPORT_COLUMNS(showMember),
        compact: true,
        data: rows,
        defaultPageSize: 25,
        detail: true,
        getRowBrand: showMember
          ? ({ patient }: HealthReportWithDetail) => patient.brand
          : undefined,
        getRowClass: (row: HealthReportWithDetail) => {
          if (
            !showMember &&
            row.careQuestions.find(
              ({ structuredAnswers, question }) =>
                question === CareQuestion.CALL_FROM_NURSE &&
                structuredAnswers.find(
                  ({ answerName, selected }) =>
                    selected && answerName === CareQuestion.NO_NOT_TODAY,
                ),
            )
          ) {
            return styles.shadowRow;
          }
          return '';
        },
        pagination,
      }}
      onClickRow={onClickRow}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangePageSize}
    />
  );
};

export default HealthReportTable;
