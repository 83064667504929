import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/styles';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';

import { BiometricsType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { BiometricsConfig } from '@vestahealthcare/common/models';
import { Measurement } from '@vestahealthcare/common/models/Measurement';
import moment, { Moment } from '@vestahealthcare/common/moment';

import {
  Card,
  CardContent,
  CardHeader,
  Colors,
  Fonts,
  IconButton,
} from 'styleguide-v2';

import { getMeasurements } from 'dash/src/services/BiometricsServices';
import { keyBy, uniqBy } from 'lodash';

import { BiometricsDot } from './BiometricsDot';
import { BiometricsCustomTooltip } from './CustomTooltip';
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const useStyles = makeStyles({
  title: {
    color: Colors.green,
  },
  lastReadingDate: {
    color: Colors.gray,
    fontSize: Fonts.fontSize,
    fontWeight: 400,
  },
  abnormalReadings: {
    color: Colors.textRed,
    fontSize: Fonts.fontSize,
    fontWeight: 500,
  },
  chart: {
    marginLeft: '-2rem',
    '& svg': {
      overflow: 'visible',
    },
  },
  notes: {
    padding: '1.6rem',
  },
  notesTitle: {
    marginBottom: '.4rem',
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
  },
});

export const BloodPressureChart = ({
  className,
  diastolicConfig,
  systolicConfig,
  interval,
  onClickAdd,
  onClickSettings,
}: {
  className?: string;
  diastolicConfig: BiometricsConfig;
  systolicConfig: BiometricsConfig;
  interval: [Moment, Moment];
  onClickAdd: () => void;
  onClickSettings: () => void;
}) => {
  const styles = useStyles();
  const patientId = diastolicConfig.patient.id;
  const [readings, setReadings] = useState<any[]>();
  const [abnormalReadings, setAbnormalReadings] = useState<Measurement[]>();

  const getReadings = async () => {
    const [diastolicReadings, systolicReadings] = await Promise.all([
      getMeasurements(patientId, {
        type: BiometricsType.BP_DIASTOLIC,
        from: interval[0],
        to: interval[1],
      }),
      getMeasurements(patientId, {
        type: BiometricsType.BP_SYSTOLIC,
        from: interval[0],
        to: interval[1],
      }),
    ]);

    const { lowerLimit: dLower, upperLimit: dUpper } = diastolicConfig;
    const { lowerLimit: sLower, upperLimit: sUpper } = systolicConfig;
    const dAbnormalData = diastolicReadings.filter(
      ({ value }) => (dLower && value < dLower) || (dUpper && value > dUpper),
    );
    const sAbnormalData = systolicReadings.filter(
      ({ value }) => (sLower && value < sLower) || (sUpper && value > sUpper),
    );
    setAbnormalReadings((dAbnormalData || []).concat(sAbnormalData || []));

    const transformedReadings: any[] = [];
    // Although systolic and diastolic are read at the same time, they are recorded
    // separately, so we need to match them up by the reading time to unify
    const diastolicByTime = keyBy(diastolicReadings, 'readAt');

    // FIXME: Duplicate readings are coming in, have to be removed
    uniqBy(systolicReadings, 'readAt').forEach((systolic) => {
      const readingTime = moment.unix(systolic.readAt);
      const diastolic = diastolicByTime[systolic.readAt];
      // If the matching reading can't be found, the reading is useless
      if (!diastolic) {
        return;
      }

      transformedReadings.push({
        channel: systolic.channel.toString(),
        diastolic: diastolic.value,
        date: readingTime.format(' MM/DD '),
        systolic: systolic.value,
        time: readingTime.format('h:mma'),
        unit: systolicConfig.unit,
      });
    });
    setReadings(transformedReadings);
  };

  const getLastReading = () => {
    if (!diastolicConfig.lastReading || !systolicConfig.lastReading) return '';
    const days = moment()
      .endOf('day')
      .diff(moment.unix(diastolicConfig.lastReading?.readAt || 0), 'days');

    return (
      <div data-cy="bp-last-reading">
        <span>
          {systolicConfig.lastReading.value}/{diastolicConfig.lastReading.value}
          &nbsp;
          {systolicConfig.unit}
        </span>
        {systolicConfig.lastReading?.readAt && (
          <span className={styles.lastReadingDate}>
            &nbsp;&#9679;&nbsp;
            {days === 0
              ? translate('biometrics.today')
              : translate('biometrics.daysAgo', { count: days })}
          </span>
        )}
      </div>
    );
  };

  useEffect(() => {
    getReadings();
  }, [diastolicConfig, systolicConfig, interval[0], interval[1]]);

  const collectionMethod = diastolicConfig?.collectionMethod?.toString();
  const frequency = diastolicConfig?.frequency?.toString();

  return (
    <Card className={className}>
      <CardContent>
        <CardHeader
          title={
            <div>
              <span className={styles.title}>{translate(`biometrics.bp`)}</span>
              {getLastReading()}
            </div>
          }
          subheader={
            <>
              {collectionMethod}
              {frequency ? <>&nbsp;&#9679;&nbsp;{frequency}</> : ''}
              {abnormalReadings?.length ? (
                <>
                  &nbsp;&#9679;&nbsp;
                  <span className={styles.abnormalReadings}>
                    {translate('biometrics.abnormalEntriesCount', {
                      count: abnormalReadings.length,
                    })}
                  </span>
                </>
              ) : (
                ''
              )}
            </>
          }
          action={
            <>
              <IconButton
                color="default"
                data-cy="bp-add"
                size="small"
                onClick={() => onClickAdd()}
              >
                <AddIcon fontSize="large" />
              </IconButton>
              <IconButton
                color="default"
                data-cy="bp-settings"
                size="small"
                onClick={() => onClickSettings()}
              >
                <SettingsIcon fontSize="large" />
              </IconButton>
            </>
          }
        />
        {readings?.length ? (
          <ResponsiveContainer className={styles.chart} height={300}>
            <LineChart
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              height={250}
              data={readings}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="date"
                axisLine={false}
                interval="preserveStartEnd"
              />
              <YAxis
                allowDecimals={false}
                axisLine={false}
                domain={[
                  diastolicConfig.lowerLimit || 'auto',
                  systolicConfig.upperLimit || 'auto',
                ]}
                interval="preserveStartEnd"
              />
              <ReferenceLine
                y={systolicConfig.upperLimit}
                stroke={Colors.gold}
              />
              <ReferenceLine
                y={systolicConfig.lowerLimit}
                stroke={Colors.gold}
              />
              <ReferenceLine
                y={diastolicConfig.upperLimit}
                stroke={Colors.gold}
              />
              <ReferenceLine
                y={diastolicConfig.lowerLimit}
                stroke={Colors.gold}
              />
              <Tooltip content={<BiometricsCustomTooltip />} />
              <Line
                activeDot={{ r: 4 }}
                dataKey="systolic"
                isAnimationActive={false}
                stroke={Colors.blue}
                strokeWidth={4}
                type="monotone"
                dot={
                  <BiometricsDot config={systolicConfig} color={Colors.blue} />
                }
              />
              <Line
                activeDot={{ r: 4 }}
                dataKey="diastolic"
                isAnimationActive={false}
                stroke={Colors.green}
                strokeWidth={4}
                type="monotone"
                dot={
                  <BiometricsDot
                    config={diastolicConfig}
                    color={Colors.green}
                  />
                }
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <CardContent>
            <span>{`No ${translate('biometrics.bp')} readings found.`}</span>
          </CardContent>
        )}
        {diastolicConfig?.note && (
          <div className={styles.notes}>
            <p className={styles.notesTitle}>{translate('biometrics.notes')}</p>
            <p>{diastolicConfig?.note}</p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};
