import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ConversationStatus } from '@vestahealthcare/common/enums';
import { getSMSConversations } from 'dash/src/services/MessageServices';

export const fetchSMSUnreadCount = createAsyncThunk(
  'chatSlice/fetchSMSUnreadCount',
  async (patientPhones: string[] | undefined, { dispatch }) => {
    const { pagination } = await getSMSConversations({
      count: true,
      lastConversation: true,
      read: false,
      status: ConversationStatus.OPEN.value,
      userPhone: patientPhones,
    });
    if (patientPhones) {
      return dispatch(onPatientSMSUnreadCountUpdated(pagination.total));
    }
    return dispatch(onSMSUnreadCountUpdated(pagination.total));
  },
);

type ChatSlice = {
  patientSMSUnreadCount: number;
  smsUnreadCount: number;
};

const initState: ChatSlice = {
  patientSMSUnreadCount: 0,
  smsUnreadCount: 0,
};

const chatSlice = createSlice({
  name: 'chatSlice',
  initialState: initState,
  reducers: {
    onSMSUnreadCountUpdated: (state, action: PayloadAction<number>) => {
      state.smsUnreadCount = action.payload;
    },
    onPatientSMSUnreadCountUpdated: (state, action: PayloadAction<number>) => {
      state.patientSMSUnreadCount = action.payload;
    },
  },
});

export const {
  onSMSUnreadCountUpdated,
  onPatientSMSUnreadCountUpdated,
} = chatSlice.actions;

export default chatSlice.reducer;
