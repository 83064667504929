import classnames from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

import {
  CareTeamPerson,
  CareTeamPersonStatus,
} from '@vestahealthcare/common/models';

import { Chip } from '../Chip';

type Props = {
  className?: string;
  'data-cy'?: string;
  link?: string;
  person: CareTeamPerson;
  showLanguage?: boolean;
  showStatus?: boolean;
  smallName?: boolean;
};

const useStyles = makeStyles({
  small: {
    fontSize: '0.875em',
  },
  bold: {
    fontWeight: 500,
  },
});

export const CareTeamPersonInfo = ({
  className,
  'data-cy': dataCy,
  link,
  person,
  showLanguage,
  showStatus,
  smallName,
}: Props) => {
  const styles = useStyles();

  return (
    <div className={className} data-ct={dataCy}>
      <p className={smallName ? styles.small : ''}>
        <span className={styles.bold}>{person?.fullName}</span>{' '}
        {link ? (
          <a href={link} rel="noreferrer" target="_blank">
            ({person?.id})
          </a>
        ) : (
          <span>({person?.id})</span>
        )}
      </p>
      <div className={classnames('flex gap wrap', styles.small)}>
        {showStatus && person?.status && (
          <Chip
            color={CareTeamPersonStatus.COLOR_MAP.get(person.status.id)}
            size="small"
          >
            {person.status.description}
          </Chip>
        )}
        {showLanguage &&
          !!person?.languages?.length &&
          person.languages.map((language) => (
            <Chip
              color="info"
              size="small"
              type="outlined"
              key={`ctp-${person.id}-language-${language.language.value}`}
            >
              {language?.language.toString()}
            </Chip>
          ))}
      </div>
    </div>
  );
};

export default CareTeamPersonInfo;
