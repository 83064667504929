import React from 'react';
import { FieldProps } from 'react-jsonschema-form';

const BradenScoreHeaderField = (props: FieldProps) => {
  const {
    schema: { title },
  }: FieldProps = props;

  return (
    <div className="braden-scale-table-header">
      <label className="control-label h6">{title}</label>
    </div>
  );
};

export default BradenScoreHeaderField;
