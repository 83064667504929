import React from 'react';
import { WidgetProps } from '../types';
import { DateTimePicker } from 'styleguide';
import moment from '@vestahealthcare/common/moment';
import { TIME_FORMAT, EMPTY } from '@vestahealthcare/common/utils/constants';

const TimePickerWidget = ({
  value,
  placeholder,
  onChange,
  formContext: { readonly },
}: WidgetProps) => {
  if (readonly) {
    if (!value) {
      return <p>{EMPTY}</p>;
    }

    return <p>{value}</p>;
  }

  return (
    <DateTimePicker
      time
      date={false}
      onChange={(val) => val && onChange(val.format(TIME_FORMAT))}
      value={value && moment(value, TIME_FORMAT)}
      placeholder={placeholder}
    />
  );
};

export default TimePickerWidget;
