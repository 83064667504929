import React, { useState } from 'react';
import {
  Button,
  Panel,
  Select,
  ButtonSelect,
  DateTimePicker,
} from 'styleguide';
import moment from '@vestahealthcare/common/moment';
import { Moment } from 'moment';

const StyleguideDropdowns = () => {
  const [showError, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [singleSelect, setSingleSelect] = useState<string>();
  const [multiSelect, setMultiSelect] = useState<string[]>();
  const [date, setDate] = useState<Moment>();
  const [time, setTime] = useState<Moment>();

  const exampleOptions = [
    { label: 'Option 1', value: '1' },
    { label: 'Option 2', value: '2' },
    { label: 'Option 3 is a long value', value: '3' },
    {
      label: `Option 4 is an
      extremely long value - who would ever
      think to make such an absurdly long value?`,
      value: '4',
    },
  ];

  return (
    <Panel>
      <Panel.Heading>
        <h2>Dropdowns</h2>
        <div className="right-side">
          <Button color="secondary" onClick={() => setError(!showError)}>
            {showError ? 'Hide' : 'Show'} Errors
          </Button>
          <Button color="secondary" onClick={() => setDisabled(!disabled)}>
            {disabled ? 'Enable' : 'Disable'}
          </Button>
        </div>
      </Panel.Heading>
      <Panel.Body className="grid-wrapper">
        <div className="grid-span-12 grid-wrapper-none">
          <Select
            className="grid-span-6"
            disabled={disabled}
            errorText="Error"
            label="Single-select"
            onChange={(value: string) => setSingleSelect(value)}
            options={exampleOptions}
            required
            submitted={showError}
            value={singleSelect}
          />
        </div>

        <div className="grid-span-12 grid-wrapper-none">
          <ButtonSelect
            className="grid-span-6"
            color="primary"
            disabled={disabled}
            onChange={(value: string) => setSingleSelect(value)}
            options={exampleOptions}
            value={singleSelect}
          />
        </div>

        <div className="grid-span-12 grid-wrapper-none">
          <Select
            className="grid-span-6"
            disabled={disabled}
            errorText="Error"
            label="Multi-select"
            multiple
            onChange={setMultiSelect}
            options={exampleOptions}
            required
            submitted={showError}
            value={multiSelect}
          />
        </div>

        <div className="grid-span-12 grid-wrapper-none">
          <Select
            className="grid-span-6"
            disabled={disabled}
            errorText="Error"
            label="Secondary Style"
            onChange={setSingleSelect}
            options={exampleOptions}
            required
            secondary
            submitted={showError}
            value={singleSelect}
          />
        </div>

        <div className="h7 grid-span-12 grid-wrapper-none">Date / Time</div>

        <div className="grid-span-12 grid-wrapper-none">
          <DateTimePicker
            className="grid-span-6"
            disabled={disabled}
            errorText="Error"
            label="Date Picker"
            max={moment().add(2, 'days')}
            min={moment().subtract(1, 'year')}
            onChange={(value) => setDate(value)}
            placeholder="Choose a date"
            required
            submitted={showError}
            value={date}
          />
        </div>

        <div className="grid-span-12 grid-wrapper-none">
          <DateTimePicker
            className="grid-span-6"
            disabled={disabled}
            date={false}
            errorText="Error"
            label="Time Picker"
            onChange={(value) => setTime(value)}
            placeholder="Choose a time"
            required
            submitted={showError}
            time
            value={time}
          />
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default StyleguideDropdowns;
