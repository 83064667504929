import React from 'react';

import { TEST_PATIENT_ID } from '@vestahealthcare/common/utils/constants';

import { Sidebar } from 'styleguide';

const TOOLS = {
  header: 'Dev',
  links: [
    {
      href: '/dev/dashboard',
      text: 'Dev Home',
      dataCy: 'sidebar-dev-link',
    },
    {
      href: '/dev/styleguide/',
      text: 'Style Guide',
      dataCy: 'sidebar-styleguide-link',
    },
    {
      href: '/dev/styleguide-v2/',
      text: 'Style Guide V2',
      dataCy: 'sidebar-styleguide-link',
    },
    {
      href: '/dev/mobile-dashboard/tablet',
      text: 'Tablet Releases',
      dataCy: 'sidebar-tablet-dashboard-link',
    },
    {
      href: '/dev/mobile-dashboard/smartphone',
      text: 'Smartphone Releases',
      dataCy: 'sidebar-smartphone-dashboard-link',
    },
    {
      href: `/patients/${TEST_PATIENT_ID}`,
      text: 'Test Member Profile',
      dataCy: 'sidebar-clinical-profile-link',
    },
  ],
};

export const DevSidebar = () => <Sidebar collapsible sections={[TOOLS]} />;

export default DevSidebar;
