import React, { useState } from 'react';

import { Enum, States } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Address, Patient } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';
import {
  isValidCity,
  isValidZipCode,
} from '@vestahealthcare/common/utils/format';

import { Select, TextInput } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { updatePatientAddress } from 'dash/src/services/PatientServices';

import { BaseModal } from './BaseModal';

interface Props {
  patient: Patient;
  onSubmit: (patient: Patient) => void;
}

const AddressComponent = React.memo(({ address }: { address: Address }) => {
  if (address.isEmpty) {
    return <span>{EMPTY}</span>;
  }

  return (
    <>
      {address.address1 && <div>{address.address1}</div>}
      {address.address2 && <div>{address.address2}</div>}
      <div>{address.formattedAddress.city || EMPTY}</div>
      <div>
        <a
          href={address.googleMapsLink}
          target="_blank"
          rel="noopener noreferrer"
          className="small"
        >
          (view on map)
        </a>
      </div>
    </>
  );
});

const DetailsComponent = React.memo(({ patient }: { patient: Patient }) => (
  <>
    <h4 className="grid-span-5 grid-span-row-2">
      {translate('personalDetails.homeAddress')}
    </h4>
    <div className="grid-span-6 grid-span-row-2" data-cy="member-address">
      <AddressComponent address={patient.address} />
    </div>
  </>
));

export const EditAddressModal = React.memo(({ patient, onSubmit }: Props) => {
  const editable = patient.isEditable() && patient.isDemographicsEditable();
  const [address1, setAddress1] = useState(patient.address.address1 || '');
  const [address2, setAddress2] = useState(patient.address.address2 || '');
  const [city, setCity] = useState(patient.address.city || '');
  const [state, setState] = useState(patient.address.state || null);
  const [zipCode, setZipCode] = useState(patient.address.zipCode || '');
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const isValid = () =>
    address1 && state && isValidCity(city) && isValidZipCode(zipCode);

  const handleSubmit = async () => {
    let result = true;
    setSubmitted(true);

    if (!isValid()) {
      return false;
    }

    setLoading(true);
    try {
      const newPatient = await updatePatientAddress(patient.id, {
        address1,
        address2,
        city,
        state,
        zipCode,
      } as any);

      onSubmit(newPatient);
    } catch (e) {
      showGlobalError(e as string);
      result = false;
    }
    setLoading(false);
    setSubmitted(false);
    return result;
  };

  const reset = () => {
    setAddress1(patient.address.address1 || '');
    setAddress2(patient.address.address2 || '');
    setCity(patient.address.city || '');
    setState(patient.address.state || null);
    setZipCode(patient.address.zipCode || '');
    setSubmitted(false);
    setLoading(false);
  };

  return (
    <>
      <BaseModal
        data-cy="patient-address"
        details={<DetailsComponent patient={patient} />}
        editable={editable}
        loadingSubmit={loading}
        onSubmit={handleSubmit}
        onCancel={reset}
        title={translate('personalDetails.addressModal.title')}
      >
        <div className="grid-wrapper fit">
          <TextInput
            className="grid-span-12"
            error={!address1 && submitted}
            value={address1}
            label={translate('personalDetails.addressModal.address1')}
            onChange={(address1?: string) => setAddress1(address1 || '')}
            required
          />

          <TextInput
            className="grid-span-12"
            value={address2}
            label={translate('personalDetails.addressModal.address2')}
            onChange={(address2?: string) => setAddress2(address2 || '')}
          />

          <TextInput
            className="grid-span-6"
            error={
              !isValidCity(city) &&
              submitted &&
              (!city || translate('global.cityFormatError'))
            }
            value={city}
            label={translate('personalDetails.addressModal.city')}
            onChange={(city?: string) => setCity(city || '')}
            required
          />

          <Select
            className="grid-span-3"
            error={submitted && !state}
            getItemLabel={(item: Selectable) => item.value}
            items={States.toSelectable(States.asArray)}
            label={translate('personalDetails.addressModal.state')}
            value={state ? Enum.toSelectable([state])[0] : undefined}
            onChange={(item?: Selectable) =>
              setState(item ? States.byKey[item.value] : null)
            }
            required
          />

          <TextInput
            className="grid-span-3"
            error={
              !isValidZipCode(zipCode) &&
              submitted &&
              (!zipCode || translate('global.zipFormatError'))
            }
            value={zipCode}
            label={translate('personalDetails.addressModal.zip')}
            maxLength={10}
            onChange={(zipCode?: string) => setZipCode(zipCode || '')}
            required
          />
        </div>
      </BaseModal>
    </>
  );
});

export default EditAddressModal;
