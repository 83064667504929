export class ExternalIdSource {
  id: number = 0;

  name: string = '';

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default ExternalIdSource;
