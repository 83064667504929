import classNames from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import { Tooltip } from '../Tooltip';
import CareAtHomeIcon from './icons/CareAtHome.png';

type Props = {
  brand: 'careAtHome';
  className?: string;
  size?: 's' | 'm' | 'l';
};

const useStyles = makeStyles({
  small: {
    height: '2rem',
    width: '2rem',
  },
  medium: {
    height: '3.2rem',
    width: '3.2rem',
  },
  large: {
    height: '4rem',
    width: '4rem',
  },
});

export const BrandMemberIcon = ({ brand, className, size = 's' }: Props) => {
  const styles = useStyles();

  return (
    <Tooltip text={translate(`components.brandMemberIcon.${brand}`)}>
      <img
        alt="brand-logo"
        className={classNames(
          className,
          size === 's' && styles.small,
          size === 'm' && styles.medium,
          size === 'l' && styles.large,
        )}
        src={CareAtHomeIcon}
      />
    </Tooltip>
  );
};
