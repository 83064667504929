import React from 'react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  PodReportingGroup,
  PodReportingSummary,
} from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Fonts, Table } from 'styleguide-v2';

interface Props {
  data: PodReportingGroup[];
  onClick: (data: PodReportingGroup) => void;
}

const TOTAL = 'Total';

const boldSpan = (field: keyof PodReportingSummary) => (
  data: PodReportingGroup,
) => (
  <span style={{ fontWeight: data.worklistName === TOTAL ? 500 : 400 }}>
    {data.summary[field] || EMPTY}
  </span>
);

const POD_REPORTING_WORKLIST_COLUMNS = () => [
  {
    headerName: translate('podReporting.table.worklist'),
    component: ({ worklistName }: PodReportingGroup) => (
      <span style={{ fontWeight: worklistName === TOTAL ? 500 : 400 }}>
        {worklistName || EMPTY}
      </span>
    ),
  },
  {
    headerName: translate('podReporting.table.total'),
    component: boldSpan('total'),
  },
  {
    headerName: translate('podReporting.table.completed'),
    component: boldSpan('completed'),
  },
  {
    headerName: translate('podReporting.table.notStarted'),
    component: boldSpan('notStarted'),
  },
  {
    headerName: translate('podReporting.table.attempted'),
    component: boldSpan('attempted'),
  },
  {
    headerName: translate('podReporting.table.patientsPerDay'),
    component: boldSpan('perDay'),
  },
  {
    headerName: translate('podReporting.table.patientsPerDayGoal'),
    component: boldSpan('perDayGoal'),
  },
  {
    headerName: '',
    component: (data: PodReportingGroup) =>
      data.worklistName !== TOTAL ? (
        <ArrowForwardIosIcon color="action" fontSize="small" />
      ) : (
        <></>
      ),
    width: 20,
  },
];

const useStyles = makeStyles({
  table: {
    '&& tbody': {
      '& td': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
      '& a': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
    },
  },
  defaultCursor: {
    '&&': {
      cursor: 'default',
    },
  },
});

export const PodReportingWorklistTable = (props: Props) => {
  const styles = useStyles();

  return (
    <Table
      className={styles.table}
      config={{
        columns: POD_REPORTING_WORKLIST_COLUMNS(),
        compact: true,
        data: props.data,
        defaultPageSize: 25,
        getRowClass: (data: PodReportingGroup) =>
          data.worklistName === TOTAL ? styles.defaultCursor : '',
        paginationOptions: props.data?.length > 25 ? [25, 50, 100] : [-1],
      }}
      onClickRow={(data) => data.worklistName !== TOTAL && props.onClick(data)}
      whiteBackground
    />
  );
};

export default PodReportingWorklistTable;
