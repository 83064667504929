import React from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { CampaignBanner } from '@vestahealthcare/common/models';

import {
  AddIcon,
  Button,
  ButtonModal,
  DeleteIcon,
  EditIcon,
  EmptyState,
  Table,
} from 'styleguide';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { deleteCampaignBanner } from 'dash/src/services/CampaignServices';

interface Props {
  banners: CampaignBanner[];
  onChange: () => void;
}

export const CampaignBanners = ({ banners, onChange }: Props) => (
  <article>
    <header>
      <div className="h7">{translate('campaigns.banners')}</div>
      <div className="right-side">
        <Button
          color="secondary"
          data-cy="add-banner"
          href="/#/admin/campaigns/app-modals/banner"
          isPill
        >
          <AddIcon className="margin-right" />
          {translate('campaigns.addBanner')}
        </Button>
      </div>
    </header>
    {banners.length ? (
      <CampaignBannersTable banners={banners} onChange={onChange} />
    ) : (
      <EmptyState>No banners added yet.</EmptyState>
    )}
  </article>
);

const CampaignBannersTable = ({ banners, onChange }: Props) => (
  <Table>
    <thead>
      <tr>
        <th>{translate('campaigns.name')}</th>
        <th>{translate('campaigns.languages')}</th>
        <th>{translate('campaigns.includesModal')}</th>
        <th style={{ width: 80 }} />
      </tr>
    </thead>
    <tbody>
      {banners.map((banner) => (
        <tr key={banner.id} data-cy="banner-information">
          <td>{banner.name}</td>
          <td>{banner.getLanguages().join(', ')}</td>
          <td>{banner.modal && banner.modal.name}</td>
          <td>
            <a href={`/#/admin/campaigns/app-modals/banner/${banner.id}`}>
              <EditIcon tag="button" marginRight />
            </a>
            <ButtonModal
              iconComponent={<DeleteIcon tag="span" />}
              modalTitle={translate('campaigns.confirmDeleteBannerTitle')}
              onSubmit={async () => {
                try {
                  await deleteCampaignBanner(banner.id);
                  onChange();
                  return Promise.resolve();
                } catch (err) {
                  showGlobalError(err as string);
                }
              }}
            >
              {translate('campaigns.confirmDeleteBannerBody', {
                name: banner.name,
              })}
            </ButtonModal>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export default CampaignBanners;
