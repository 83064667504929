export class InsurancePriority {
  id = 0;

  name = '';

  createdAt = 0;

  updatedAt = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default InsurancePriority;
