import Api from 'dash/src/services/Api';
import { Document } from '@vestahealthcare/common/models';

export const fetchDocument = async (
  documentId: number | string,
): Promise<Document> => {
  const { document } = await Api.getv2(`/documents/${documentId}`);
  return new Document(document);
};

export const downloadDocument = async (
  documentId: number | string,
  fileName: string,
) => {
  const blob: Blob = await Api.getv2Blob(
    `documents/${documentId}/download-file`,
  );
  const csvURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', fileName);
  tempLink.click();
};
