import React, { useState } from 'react';
import classnames from 'classnames';

import makeStyles from '@mui/styles/makeStyles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DuoIcon from '@mui/icons-material/Duo';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import { Colors, Fonts, IconButton, Tooltip } from 'styleguide-v2';
import { DATE_FORMAT_SHORT_WITH_TIME } from '@vestahealthcare/common/utils/constants';
import {
  EventTriage,
  EventTriageHandout,
} from '@vestahealthcare/common/models';
import { VIDEO_VISIT_STRING } from '../modals/MemberEvaluationAddTriage';
import { translate } from '@vestahealthcare/common/i18n';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
    gap: '2rem',
  },
  triageContainer: {
    alignItems: 'initial',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem 2rem',
  },
  summary: {
    flex: '0 1 30rem',
  },
  questions: {
    flex: '1 1 50rem',
    '& .answer': {
      fontWeight: 500,
      minWidth: 'max-content',
    },
  },
  createdInfo: {
    color: Colors.gray,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
  },
  triageInfoIcon: {
    height: 0,
    left: '0.5rem',
    width: 0,
  },
});

type Props = {
  className?: string;
  triages?: EventTriage[];
  onClickInfo: (triage?: EventTriage) => void;
};

export const EventDetailTriage = ({
  className,
  triages,
  onClickInfo,
}: Props) => {
  const styles = useStyles();
  const [openTooltip, setOpenTooltip] = useState<number>();
  const [openAllTooltip, setOpenAllTooltip] = useState<boolean>();

  const copyHandout = (evt: React.MouseEvent, address: string, id: number) => {
    evt.preventDefault();
    evt.stopPropagation();
    navigator.clipboard.writeText(address);
    setOpenTooltip(id);
    setTimeout(() => setOpenTooltip(undefined), 1000);
  };

  const copyAllHandouts = (
    evt: React.MouseEvent,
    handouts: EventTriageHandout[],
  ) => {
    evt.preventDefault();
    evt.stopPropagation();
    navigator.clipboard.writeText(
      handouts.map(({ address }) => address).join('\r\n\r'),
    );
    setOpenAllTooltip(true);
    setTimeout(() => setOpenAllTooltip(false), 1000);
  };

  return !triages?.length ? (
    <></>
  ) : (
    <div className={classnames(className, styles.container)}>
      {triages?.map((triage: EventTriage) => {
        const { id, adviceDetails, disposition, questions, handouts } = triage;
        const shouldShowInfo = !!adviceDetails?.filter(
          ({ title }) => title !== VIDEO_VISIT_STRING,
        )?.length;
        const video = adviceDetails?.find(
          ({ title }) => title === VIDEO_VISIT_STRING,
        );
        return (
          <div key={`triage-id-${id}`} className={styles.triageContainer}>
            <div className={styles.summary}>
              <p>
                {disposition}
                {!!video && (
                  <IconButton
                    className={styles.triageInfoIcon}
                    size="small"
                    tooltip={`${video.title} ${video.detail}`}
                  >
                    <DuoIcon color="primary" />
                  </IconButton>
                )}
                {shouldShowInfo && (
                  <IconButton
                    className={styles.triageInfoIcon}
                    size="small"
                    onClick={() => onClickInfo(triage)}
                    tooltip={translate('memberEvents.detail.triageMoreInfo')}
                  >
                    <InfoIcon color="primary" />
                  </IconButton>
                )}
              </p>
              <p className={styles.createdInfo}>
                {triage.createdAt.format(DATE_FORMAT_SHORT_WITH_TIME)} by{' '}
                {triage.createdBy.fullName}
              </p>
              <div style={{ margin: '0.25rem' }} />
              {handouts?.map(({ id: handoutId, title, address }) => (
                <p
                  className="flex"
                  key={`triage-id-${id}-handout-id-${handoutId}`}
                >
                  <a
                    className="flex"
                    href=""
                    onClick={(evt) => copyHandout(evt, address, handoutId)}
                  >
                    {title}{' '}
                    <Tooltip text="Copied" open={openTooltip === handoutId}>
                      <ContentCopyIcon color="info" fontSize="small" />
                    </Tooltip>
                  </a>
                </p>
              ))}
              {handouts?.length > 1 && (
                <a href="" onClick={(evt) => copyAllHandouts(evt, handouts)}>
                  Copy All{' '}
                  <Tooltip text="Copied" open={!!openAllTooltip}>
                    <ContentCopyIcon color="info" fontSize="small" />
                  </Tooltip>
                </a>
              )}
            </div>
            <div className={styles.questions}>
              {questions?.map(({ id: questionId, question, answer }) => (
                <p key={`triage-id-${id}-question-id-${questionId}`}>
                  <span>{question}</span>{' '}
                  <span className="answer">{answer}</span>
                </p>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default EventDetailTriage;
