import classNames from 'classnames';
import React, { Fragment } from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  MemberDashboardBiannual,
  MemberWarning,
  Patient,
} from '@vestahealthcare/common/models';
import MemberWarningType from '@vestahealthcare/common/models/member-warnings/MemberWarningType';
import { DATE_FORMAT_SHORT } from '@vestahealthcare/common/utils/constants';

import { Card, Chip, Data, Fonts } from 'styleguide-v2';

type Props = {
  className?: string;
  data?: MemberDashboardBiannual;
  member?: Patient;
  warnings?: MemberWarning[];
};

const useStyles = makeStyles({
  container: {
    '&&': {
      borderRadius: '8px',
    },

    display: 'flex',

    '& > div': {
      flex: 1,
      padding: '2.4rem 3.2rem',
    },
  },
  dataContainer: {
    display: 'flex',
    flewWrap: 'wrap',
    gap: '0.5rem',

    '& > *': {
      flex: `1 1 calc(100% - 1rem)`,
    },
  },
  subContainer: {
    display: 'flex',
    flexFlow: 'column',
  },
  title: {
    alignItems: 'center',
    display: 'flex',

    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontWeight: 500,
      margin: 0,
    },
  },
});

export const NPBiannualCard = ({ className, data, warnings }: Props) => {
  const styles = useStyles();

  const getNextBiannualSubtitle = () => {
    if (!data?.nextBiannualDate && data?.nextBiannualDue)
      return `Due: ${data.nextBiannualDue.format('MMMM, YYYY')}`;
    if (data?.nextBiannualType || data?.nextBiannualEmployee)
      `${data?.nextBiannualType ? `${data?.nextBiannualType}` : ''}${
        data.nextBiannualType && data.nextBiannualEmployee ? ', ' : ''
      }${
        data?.nextBiannualEmployee ? `with ${data.nextBiannualEmployee}` : ''
      }`;
    return undefined;
  };

  return (
    <Card className={classNames(className, styles.container)}>
      <div className={styles.subContainer}>
        <div className="flex spaced">
          <span className={styles.title}>
            {translate('memberProfile.dashboard.npBiannuals')}
            {warnings
              ?.filter(({ type }) =>
                [MemberWarningType.DUE_NP_BIANNUAL].includes(type.id),
              )
              .map(({ type: { id, description } }) => (
                <Fragment key={`member-dashboar-warning-${id}`}>
                  &nbsp; &nbsp;
                  <Chip color="error" type="contained-2">
                    <span className="bold">
                      {description.toLocaleUpperCase()}
                    </span>
                  </Chip>
                </Fragment>
              ))}
          </span>
        </div>
        <br />
        <div className={styles.dataContainer}>
          <Data
            label={translate('memberProfile.dashboard.lastAppointment')}
            value={data?.lastBiannualDate?.format(DATE_FORMAT_SHORT) || 'N/A'}
            subvalue={
              data?.lastBiannualType || data?.lastBiannualEmployee
                ? `${
                    data?.lastBiannualType ? `${data?.lastBiannualType}` : ''
                  }${
                    data.lastBiannualType && data.lastBiannualEmployee
                      ? ', '
                      : ''
                  }${
                    data?.lastBiannualEmployee
                      ? `with ${data.lastBiannualEmployee}`
                      : ''
                  }`
                : undefined
            }
          />
          <Data
            label={translate('memberProfile.dashboard.nextAppointment')}
            value={data?.nextBiannualDate?.format(DATE_FORMAT_SHORT) || 'N/A'}
            subvalue={getNextBiannualSubtitle()}
          />
        </div>
      </div>
    </Card>
  );
};
