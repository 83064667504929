import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';

import { translate } from '@vestahealthcare/common/i18n';
import { EventsConfiguration, EventType } from '@vestahealthcare/common/models';

import { IconButton, Panel } from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import {
  createEventType,
  updateEventType,
  updateEventsConfig,
  fetchEventType,
} from 'dash/src/services/EventServices';
import { showGlobalError } from 'dash/src/components/GlobalMessage';

import { EventsTypesTable } from './EventTypesTable';
import { EventTypesEditModal } from './EventTypesEdit';
import { EventsSettingsModal } from './EventsSettings';

export const EventTypesDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState<EventType[]>([]);
  const [eventsConfig, setEventsConfig] = useState<EventsConfiguration>();
  const [openConfig, setOpenConfig] = useState(false);
  const [editEvent, setEditEvent] = useState<EventType>();

  const getData = async () => {
    setLoading(true);
    const events = await CacheServices.getEventTypes();
    const eventsConfig = await CacheServices.getEventsConfig();
    setEvents(events);
    setEventsConfig(eventsConfig);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Panel id="event-types-section">
      <Panel.Heading title={translate('events.types.title')}>
        <Panel.Actions>
          <IconButton
            onClick={() => setEditEvent({ active: true } as EventType)}
            tooltip={translate('events.addEvent')}
          >
            <AddIcon fontSize="large" />
          </IconButton>
          <IconButton
            onClick={() => setOpenConfig(true)}
            tooltip={translate('events.settings')}
          >
            <SettingsIcon fontSize="large" />
          </IconButton>
        </Panel.Actions>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <EventsTypesTable events={events} onEdit={setEditEvent} />
        <EventTypesEditModal
          event={editEvent}
          events={events}
          onSubmit={async (id, params) => {
            let result = true;
            try {
              if (id) {
                const detail = await fetchEventType(id);
                await updateEventType(id, {
                  ...params,
                  issueTypes: params.active ? detail.issueTypes || [] : [],
                });
              } else {
                await createEventType({
                  ...params,
                  issueTypes: [],
                });
              }
              CacheServices.invalidateEventTypes();
              await getData();
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
          onClose={() => setEditEvent(undefined)}
        />
        <EventsSettingsModal
          config={eventsConfig}
          open={openConfig}
          onClose={() => setOpenConfig(false)}
          onSubmit={async (params) => {
            let result = true;
            try {
              await updateEventsConfig(params);
              CacheServices.invalidateEventsConfig();
              const eventsConfig = await CacheServices.getEventsConfig();
              setEventsConfig(eventsConfig);
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
        />
      </Panel.Body>
    </Panel>
  );
};

export default EventTypesDashboard;
