import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';

import { Button, CollapsableSidebar, SwitchGroup } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { PatientParam } from 'dash/src/services/PatientServices';

interface Props {
  onClose: () => void;
  onSubmit: (id: number, settings: PatientParam) => void;
  open: boolean;
  patient?: Patient;
}

export const HCISettingsModal = ({
  onClose,
  onSubmit,
  open,
  patient,
}: Props) => {
  const [hciReminder, setHciReminder] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setHciReminder(!!patient?.hciNotifications);
  }, [patient]);

  const submit = async () => {
    if (!patient) return;

    try {
      setLoading(true);
      await onSubmit(patient.id, { hciNotifications: hciReminder });
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  return (
    <>
      <CollapsableSidebar
        title={<h2>{translate('global.settings')}</h2>}
        open={open}
        onClose={onClose}
      >
        <CollapsableSidebar.Body>
          <SwitchGroup
            items={[
              {
                label: translate('healthReports.hciNotifications'),
                checked: hciReminder,
              },
            ]}
            onChange={(items) => {
              setHciReminder(!!items[0].checked);
            }}
          />
        </CollapsableSidebar.Body>
        <CollapsableSidebar.Buttons>
          <Button color="tertiary" onClick={onClose}>
            {translate('global.cancel')}
          </Button>
          <Button color="secondary" loading={loading} onClick={submit}>
            {translate('global.save')}
          </Button>
        </CollapsableSidebar.Buttons>
      </CollapsableSidebar>
    </>
  );
};

export default HCISettingsModal;
