import { AppUser } from './AppUser';
import { CareTeamMember } from './CareTeamMember';
import { CareTeamMemberAide } from './CareTeamMemberAide';
import { CareTeamMemberFamily } from './CareTeamMemberFamily';
import { CareTeamMemberOther } from './CareTeamMemberOther';
import { CareTeamMemberProvider } from './CareTeamMemberProvider';
import { CareTeamMemberType } from './CareTeamMemberType';
import { CareTeamPerson } from './CareTeamPerson';
import { make } from './Model';
import { Patient } from './Patient';
import { PatientSearch } from './PatientSearch';

export class CareTeamPersonExtended extends CareTeamPerson {
  appUser: AppUser;

  careTeamMembers: CareTeamMember[];

  constructor(obj: any) {
    super(obj);

    this.appUser = make(obj.appUser, AppUser);

    this.careTeamMembers = obj.careTeamMembers?.map((item: any) => {
      if (item.type.id === CareTeamMemberType.FAMILY_FRIEND) {
        return new CareTeamMemberFamily(item);
      }
      if (item.type.id === CareTeamMemberType.AIDE) {
        return new CareTeamMemberAide(item);
      }
      if (item.type.id === CareTeamMemberType.PROVIDER) {
        return new CareTeamMemberProvider(item);
      }
      if (item.type.id === CareTeamMemberType.OTHER) {
        return new CareTeamMemberOther(item);
      }
      return new CareTeamMember(item);
    });
  }

  hasAlreadyLinkedMember(patient?: Patient | PatientSearch) {
    return !!this?.careTeamMembers?.find(
      ({ member }) => member.id === patient?.id,
    );
  }
}

export default CareTeamPersonExtended;
