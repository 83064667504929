import { Enum } from './Enum';

export class PatientGender extends Enum {
  translationKey = 'patientGender';

  static asArray: PatientGender[] = [];

  static byKey: { [name: string]: PatientGender } = {};

  static readonly FEMALE = new PatientGender('FEMALE');

  static readonly MALE = new PatientGender('MALE');

  static readonly UNKNOWN = new PatientGender('UNKNOWN');

  constructor(public readonly value: string) {
    super();
    PatientGender.asArray.push(this);
    PatientGender.byKey[value] = this;
  }
}

export default PatientGender;
