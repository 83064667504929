import { Enum } from './Enum';

export class States extends Enum {
  translationKey = 'states';

  static asArray: States[] = [];

  static byKey: { [name: string]: States } = {};

  static readonly AL = new States('AL');

  static readonly AK = new States('AK');

  static readonly AZ = new States('AZ');

  static readonly AR = new States('AR');

  static readonly CA = new States('CA');

  static readonly CO = new States('CO');

  static readonly CT = new States('CT');

  static readonly DE = new States('DE');

  static readonly DC = new States('DC');

  static readonly FL = new States('FL');

  static readonly GA = new States('GA');

  static readonly HI = new States('HI');

  static readonly ID = new States('ID');

  static readonly IL = new States('IL');

  static readonly IN = new States('IN');

  static readonly IA = new States('IA');

  static readonly KS = new States('KS');

  static readonly KY = new States('KY');

  static readonly LA = new States('LA');

  static readonly ME = new States('ME');

  static readonly MD = new States('MD');

  static readonly MA = new States('MA');

  static readonly MI = new States('MI');

  static readonly MN = new States('MN');

  static readonly MS = new States('MS');

  static readonly MO = new States('MO');

  static readonly MT = new States('MT');

  static readonly NE = new States('NE');

  static readonly NV = new States('NV');

  static readonly NH = new States('NH');

  static readonly NJ = new States('NJ');

  static readonly NM = new States('NM');

  static readonly NY = new States('NY');

  static readonly NC = new States('NC');

  static readonly ND = new States('ND');

  static readonly OH = new States('OH');

  static readonly OK = new States('OK');

  static readonly OR = new States('OR');

  static readonly PA = new States('PA');

  static readonly RI = new States('RI');

  static readonly SC = new States('SC');

  static readonly SD = new States('SD');

  static readonly TN = new States('TN');

  static readonly TX = new States('TX');

  static readonly UT = new States('UT');

  static readonly VT = new States('VT');

  static readonly VA = new States('VA');

  static readonly WA = new States('WA');

  static readonly WV = new States('WV');

  static readonly WI = new States('WI');

  static readonly WY = new States('WY');

  static readonly statesWeServe = [States.NY, States.NJ, States.PA];

  constructor(public readonly value: string) {
    super();
    States.asArray.push(this);
    States.byKey[value] = this;
  }
}

export default States;
