export class FilterHandler<T> {
  key = '';

  obj?: T;

  constructor(key: string) {
    this.key = key;
  }

  setFilter(filters: T): void {
    this.obj = filters;
    localStorage.setItem(this.key, JSON.stringify(this.obj));
  }

  getFilter(): T | undefined {
    const filters = localStorage.getItem(this.key);
    this.obj = filters ? JSON.parse(filters) : undefined;
    return this.obj;
  }

  clearFilter(): void {
    this.obj = undefined;
    localStorage.removeItem(this.key);
  }
}
