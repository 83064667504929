import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import React, { Fragment } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { CareTeamPersonExtended } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Button, CareTeamPersonInfo, Table } from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

const phoneUtil = PhoneNumberUtil.getInstance();

interface Props {
  className?: string;
  disabled?: number[];
  hideSelect?: boolean;
  people: CareTeamPersonExtended[];
  onSelect?: (group: CareTeamPersonExtended) => void;
}

const CTP_COLUMNS = ({ disabled, hideSelect, onSelect }: Props) => {
  const colums: Column[] = [
    {
      headerName: hideSelect ? '' : translate('careTeam.common.nameCTPID'),
      component: (ctp: CareTeamPersonExtended) => (
        <CareTeamPersonInfo
          link={`#/care-team/${ctp?.id}?firstName=${ctp?.firstName}&lastName=${ctp.lastName}`}
          person={ctp}
        />
      ),
    },
    {
      headerName: hideSelect ? '' : translate('careTeam.common.contact'),
      component: ({ phones, email }: CareTeamPersonExtended) => (
        <>
          {!!phones?.length &&
            phones?.map(({ id, phone: { number } }) => (
              <p className="no-margin" key={`care-team-person-phone-${id}`}>
                {phoneUtil.format(
                  phoneUtil.parse(number, 'US'),
                  PhoneNumberFormat.NATIONAL,
                )}
              </p>
            ))}
          {email}
          {!phones?.length && !email && EMPTY}
        </>
      ),
    },
  ];

  if (!hideSelect) {
    colums.push({
      headerName: ' ',
      component: (ctp: CareTeamPersonExtended) => (
        <Button
          color="secondary"
          disabled={disabled?.includes(ctp.id)}
          onClick={() => onSelect && onSelect(ctp)}
          type="outlined"
          size="s"
        >
          {translate('global.continue')}
        </Button>
      ),
      width: 20,
    });
  }

  return colums;
};

export const SearchTable = (props: Props) => (
  <Table
    className={props.className}
    config={{
      columns: CTP_COLUMNS(props),
      compact: true,
      data: props.people,
      paginationOptions: [-1],
    }}
  />
);

export default SearchTable;
