import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { ClassNameMap, makeStyles } from '@mui/styles';

import { Colors, IconButton, Table } from 'styleguide-v2';

import { translate } from '@vestahealthcare/common/i18n';
import { EventInterventionType } from '@vestahealthcare/common/models';

interface Props {
  interventions: EventInterventionType[];
  onEdit: (intervention: EventInterventionType) => void;
}

const EVENT_INTERVENTION_COLUMNS = (
  { onEdit }: Props,
  styles: ClassNameMap<'intervention' | 'textGrey'>,
) => [
  {
    headerName: translate('events.interventions.intervention'),
    component: ({ abbr, description }: EventInterventionType) => (
      <div className={styles.intervention}>
        <span>{description}</span>
        <span className={styles.textGrey}>{abbr}</span>
      </div>
    ),
  },
  {
    headerName: translate('events.interventions.category'),
    field: 'category.description',
  },
  {
    headerName: translate('events.interventions.status'),
    component: ({ active }: EventInterventionType) => (
      <span className="flex center">
        {active ? (
          <CheckCircleIcon color="primary" />
        ) : (
          <CancelIcon color="error" />
        )}
      </span>
    ),
    width: 50,
  },
  {
    headerName: '',
    component: (intervention: EventInterventionType) => (
      <IconButton size="small" onClick={() => onEdit(intervention)}>
        <EditIcon />
      </IconButton>
    ),
    width: 20,
  },
];

const useStyles = makeStyles({
  intervention: {
    display: 'flex',
    flexFlow: 'column',
  },
  textGrey: {
    color: Colors.textGray,
  },
});

export const EventInterventionsTable = (props: Props) => {
  const { interventions } = props;
  const styles = useStyles();
  return (
    <Table
      config={{
        columns: EVENT_INTERVENTION_COLUMNS(props, styles),
        compact: true,
        data: interventions,
        paginationOptions: [-1],
      }}
    />
  );
};

export default EventInterventionsTable;
