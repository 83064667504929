import Api from './Api';

type ICDCodeCategory = {
  code: string;
  description: string;
  score: number;
};

type ICDCodeTrait = {
  name: string;
  score: number;
};

export type ICDCodeItem = {
  beginOffset: number;
  category: string;
  concepts: ICDCodeCategory[];
  endOffset: number;
  text: string;
  traits: ICDCodeTrait[];
  type: string;
};

export const getICDCodesFromText = async (
  text: string,
): Promise<ICDCodeItem[]> => {
  const {
    icd10codes: { items },
  } = await Api.postv2JSON('/integrations/comprehend-medical', { text });

  return items as ICDCodeItem[];
};
