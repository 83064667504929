import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  bulletedList: {
    marginLeft: 7,
  },
  marginBottom: {
    marginBottom: 14,
  },
});

interface Props {
  className?: string;
  'data-cy'?: string;
  items: any[];
  marginBottom?: boolean;
}

export const BulletedList = (props: Props) => {
  const {
    className,
    'data-cy': dataCy = 'bulleted-list',
    items,
    marginBottom = true,
  } = props;
  const styles = useStyles();

  const classes = classNames(
    className,
    styles.bulletedList,
    marginBottom && styles.marginBottom,
  );

  return (
    <ul className={classes} data-cy={dataCy}>
      {items.map((item, i) => (
        <li key={i}>{item}</li>
      ))}
    </ul>
  );
};

export default BulletedList;
