import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import LaunchIcon from '@mui/icons-material/Launch';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  PaginationType,
  SkilledEvent,
  TOCEvent,
} from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  CollapseIcon,
  Colors,
  Fonts,
  Spinner,
  Table,
  Tooltip,
} from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

const MAX_EVENTS = 5;

type Props = {
  className?: string;
  data: TOCEvent[] | SkilledEvent[];
  onSeeMore: () => Promise<void>;
  pagination?: PaginationType;
  type: 'TOC' | 'SKILLED';
};

const RECENT_EVENTS_COLUMNS = ({ type }: Props) => {
  let columns: Column[] = [
    {
      headerName: 'Event',
      component: ({ type: eventType }: TOCEvent) => (
        <span className="semi-bold">{eventType?.description || EMPTY}</span>
      ),
      width: 200,
    },
  ];

  if (type === 'TOC') {
    columns = [
      ...columns,
      {
        headerName: 'Admit Date',
        component: ({ admitDate }: TOCEvent) => (
          <span className="semi-bold">
            {admitDate?.format(DATE_FORMAT_SHORT) || EMPTY}
          </span>
        ),
        width: 125,
      },
      {
        headerName: 'Discharge Date',
        component: ({ dischargeDate }: TOCEvent) => (
          <span className="semi-bold">
            {dischargeDate?.format(DATE_FORMAT_SHORT) || EMPTY}
          </span>
        ),
        width: 125,
      },
    ];
  } else {
    columns = [
      ...columns,
      {
        headerName: 'Episode Start Date',
        component: ({ episodeStart }: SkilledEvent) => (
          <span className="semi-bold">
            {episodeStart?.format(DATE_FORMAT_SHORT) || EMPTY}
          </span>
        ),
        width: 125,
      },
      {
        headerName: 'Episode End Date',
        component: ({ episodeEnd }: SkilledEvent) => (
          <span className="semi-bold">
            {episodeEnd?.format(DATE_FORMAT_SHORT) || EMPTY}
          </span>
        ),
        width: 125,
      },
    ];
  }

  columns = [
    ...columns,
    {
      headerName: 'Description',
      component: ({ chiefComplaint }: TOCEvent | SkilledEvent) => (
        <Tooltip text={chiefComplaint}>
          <div
            className="ellipsis"
            style={{ maxWidth: 'calc(100vw - 115rem)', width: 'fit-content' }}
          >
            {chiefComplaint}
          </div>
        </Tooltip>
      ),
    },
  ];

  if (type === 'TOC') {
    columns = [
      ...columns,
      {
        headerName: 'TOC End Date',
        component: ({ tocRecordClosedAt }: TOCEvent) => (
          <span className="semi-bold">
            {tocRecordClosedAt?.format(DATE_FORMAT_SHORT) || EMPTY}
          </span>
        ),
        width: 150,
      },
      {
        headerName: '',
        component: ({ tocRecordId }: TOCEvent) =>
          tocRecordId ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={`#/dashboard/toc/${tocRecordId}`}
            >
              <span className="flex gap middle">
                <LaunchIcon fontSize="small" />
                {translate('toc.detail.seeTOCDetails')}
              </span>
            </a>
          ) : (
            ''
          ),
        width: 175,
      },
    ];
  } else {
    columns = [
      ...columns,
      {
        headerName: 'Skilled End Date',
        component: ({ skilledRecordClosedAt }: SkilledEvent) => (
          <span className="semi-bold">
            {skilledRecordClosedAt?.format(DATE_FORMAT_SHORT) || EMPTY}
          </span>
        ),
        width: 150,
      },
      {
        headerName: '',
        component: ({ skilledRecordId }: SkilledEvent) =>
          skilledRecordId ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={`#/dashboard/skilled/${skilledRecordId}`}
            >
              <span className="flex gap middle">
                <LaunchIcon fontSize="small" />
                {translate('toc.detail.seeSkilledDetails')}
              </span>
            </a>
          ) : (
            ''
          ),
        width: 175,
      },
    ];
  }

  return columns;
};

const useStyles = makeStyles({
  table: {
    marginTop: 1,
  },
  tableContainer: {
    display: 'flex',
    marginBottom: '1rem',
    minHeight: '2rem',
    maxHeight: '19.5rem',
    overflow: 'hidden',
    transition: 'max-height 0.3s ease',
  },
  seeMore: {
    color: Colors.textGreen,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
  },
});

export const TOCEventsTable = (props: Props) => {
  const styles = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const columns = useMemo(() => RECENT_EVENTS_COLUMNS(props), [props.type]);

  useEffect(() => setOpen(false), [props.data]);

  return (
    <div className={props.className}>
      <div
        className={styles.tableContainer}
        style={{
          maxHeight: open ? `${props.data.length * 3.252 + 4}rem` : undefined,
        }}
      >
        <Table
          className={styles.table}
          config={{
            columns,
            compact: true,
            data: props.data,
            paginationOptions: [-1],
          }}
          empty={translate(`toc.detail.noTOCEvents.${props.type}`)}
          whiteBackground
        />
      </div>
      {props.pagination && props.pagination.total > MAX_EVENTS && (
        <div className="flex spaced">
          <a
            className={classNames('flex middle min-gap', styles.seeMore)}
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              if (loading) return;
              if (
                open === false &&
                props.data.length < (props.pagination?.total || 0)
              ) {
                setLoading(true);
                await props.onSeeMore();
                setLoading(false);
              }
              setTimeout(() => {
                setOpen(!open);
              }, 0);
            }}
          >
            <CollapseIcon open={open} htmlColor={Colors.textGreen} />
            {loading ? (
              <Spinner text="Loading" />
            ) : (
              translate(
                `memberProfile.dashboard.${open ? 'hide' : 'see'}More`,
                {
                  count: props.pagination?.total - MAX_EVENTS,
                },
              )
            )}
          </a>
        </div>
      )}
    </div>
  );
};
