import React, { PureComponent } from 'react';
import { findDOMNode } from 'react-dom';
import { Overlay, Popover } from 'react-bootstrap';
import { BodyPart, BodySection } from '@vestahealthcare/common/enums';
import { PopoverPlacement } from '.';

import './main.less';
import Figure from './Figure';

interface Props {
  disabled?: boolean;
  section?: BodySection;
  selectedParts: Set<BodyPart>;
  onMouseEnterPart: Function;
  onMouseLeavePart: Function;
  onTogglePart: Function;
  onRootClose: Function;
  target?: Function | Element | React.Component;
  placement: PopoverPlacement;
  container?: Figure;
}

export default class PartSelector extends PureComponent<Props> {
  container?: Popover | null;

  static defaultProps = {
    placement: 'right',
    disabled: false,
  };

  componentDidMount() {
    window.addEventListener('click', this.handleClick, true);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClick);
  }

  handleClick = (e?: MouseEvent) => {
    const { onRootClose, section } = this.props;

    if (
      this.container &&
      section &&
      onRootClose &&
      !findDOMNode(this.container)?.contains(e?.target as Node)
    ) {
      onRootClose();
    }
  };

  render() {
    const {
      disabled,
      section,
      selectedParts,
      onMouseEnterPart,
      onMouseLeavePart,
      onTogglePart,
      target,
      container,
      placement,
    } = this.props;

    if (disabled || !section) {
      return false;
    }

    return (
      <Overlay
        show={!!section}
        target={target}
        placement={placement}
        container={container}
      >
        <Popover
          id="boris-popover"
          className="clinical-figure-popover"
          ref={(ref) => {
            this.container = ref;
          }}
        >
          <ul>
            {section.parts?.map((part) => (
              <li
                key={part.value}
                onMouseEnter={() => onMouseEnterPart(part)}
                onMouseLeave={() => onMouseLeavePart(part)}
              >
                {selectedParts.has(part) ? (
                  <i className="fa fa-check clinical-figure-check" />
                ) : (
                  false
                )}
                <span onClick={(event) => onTogglePart(part, event)}>
                  {part.toString()}
                </span>
              </li>
            ))}
          </ul>
        </Popover>
      </Overlay>
    );
  }
}
