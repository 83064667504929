import classNames from 'classnames';
import React from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import makeStyles from '@mui/styles/makeStyles';

type Props = {
  className?: string;
  color?:
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  htmlColor?: string;
  open: boolean;
  size?: 'medium' | 'large' | 'small';
};

const useStyles = makeStyles({
  icon: {
    '&&': {
      transition: 'all 250ms ease',
    },
    transform: 'rotate(0deg)',
  },
  iconClose: {
    transform: 'rotate(180deg)',
  },
});

export const CollapseIcon = ({
  className,
  color = 'primary',
  htmlColor,
  open,
  size = 'large',
}: Props) => {
  const styles = useStyles();
  return (
    <ExpandLessIcon
      className={classNames(className, styles.icon, !open && styles.iconClose)}
      color={htmlColor ? undefined : color}
      fontSize={size}
      htmlColor={htmlColor}
    />
  );
};
