import React, { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import { IconButton } from 'styleguide-v2/src/components/IconButton';

type Props = {
  defaultOn?: boolean;
  timer: number;
  onTimer: () => void;
};

const INTERVAL_TICK = 100;

export const ButtonCountDown = ({ defaultOn, timer, onTimer }: Props) => {
  const [isOn, setOn] = useState(!!defaultOn);
  let timerInterval: NodeJS.Timer;
  const [progress, setProgress] = useState<number>(INTERVAL_TICK);

  useEffect(() => {
    if (timerInterval) {
      clearInterval(timerInterval);
    }
    if (isOn) {
      timerInterval = setInterval(
        () =>
          setProgress((count) => {
            if (count % timer === 0) {
              onTimer();
            }
            return count + INTERVAL_TICK;
          }),
        INTERVAL_TICK,
      );
    } else {
      setProgress(INTERVAL_TICK);
    }
    return () => {
      if (timerInterval) {
        clearInterval(timerInterval);
      }
    };
  }, [isOn]);

  return (
    <>
      <IconButton onClick={() => setOn(!isOn)}>
        {isOn ? <PauseIcon /> : <PlayArrowIcon />}
        {isOn && (
          <CircularProgress
            color="primary"
            sx={{
              position: 'absolute',
            }}
            size={32}
            value={(progress * 100) / timer}
            variant="determinate"
          />
        )}
      </IconButton>
    </>
  );
};
