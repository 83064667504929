import React, { PureComponent } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { PaginationType } from '@vestahealthcare/common/models';

import { Select } from 'styleguide';

import './main.less';

const PAGE_SIZE_OPTIONS = [
  {
    value: 10,
    label: '10',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100',
  },
];

interface Props {
  pagination: PaginationType;
  onChange: Function;
}

interface State {
  resultsPerPage: number;
}

export default class Pagination extends PureComponent<Props, State> {
  state: State = {
    resultsPerPage: this.props.pagination.limit,
  };

  render() {
    const { resultsPerPage } = this.state;
    const {
      pagination: { total, limit, offset },
      onChange,
    } = this.props;

    const numberOfPages = (total && Math.ceil(total / limit)) || 1;
    const currentPage = (total && Math.ceil(offset / limit) + 1) || 1;
    const nextOffset = offset + limit;
    const previousOffset = offset - limit;
    const hasNext = numberOfPages > 1 && nextOffset < total;
    const hasPrevious = numberOfPages > 1 && previousOffset >= 0;

    return (
      <div className="flex ht-pagination">
        <div className="flex ht-pagination-nav">
          <button
            className="fa fa-chevron-left"
            disabled={!hasPrevious}
            onClick={() =>
              onChange(
                Object.assign(this.props.pagination, {
                  offset: previousOffset,
                }),
              )
            }
          />
          <span className="ht-pagination-total">
            {currentPage} {translate('pagination.of')} {numberOfPages}
          </span>
          <button
            className="fa fa-chevron-right"
            disabled={!hasNext}
            onClick={() =>
              onChange(
                Object.assign(this.props.pagination, { offset: nextOffset }),
              )
            }
          />
        </div>
        <div className="flex ht-pagination-size">
          <Select
            value={resultsPerPage}
            onChange={(val: number) => {
              this.setState({ resultsPerPage: val });
              onChange(
                Object.assign(this.props.pagination, { offset: 0, limit: val }),
              );
            }}
            options={PAGE_SIZE_OPTIONS}
          />
          <span>{translate('pagination.perPage')}</span>
        </div>
      </div>
    );
  }
}
