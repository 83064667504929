import favicon from 'dash/static/images/favicons/production.png';

const config: any = {
  favicon,
  apiv2: {
    url: 'https://api.hometeamcare.com/v2',
    credentials: {
      consumerKey: 2,
    },
  },
  auth: {
    redirectUrl: 'https://dash.hometeamcare.com',
    clientId: '5k87tfotgl48v1lb1njbms0qbc',
    hostedDomainUrl: 'https://vesta-dash.auth.us-east-1.amazoncognito.com',
  },
  env: 'production',
  staticUrl: 'https://s3.amazonaws.com/hometeam-static',
  isDemo: false,
  isDev: false,
  isProd: true,
  isQA: false,
  isStage: false,
  segment: {
    writeKey: 'Apdr83sV5gLrO3H42EyAH9XzwFGd7cp2',
  },
  sentry: {
    dsn:
      'https://aca920a78f314819b1afa421471045e0@sentry.vestahealthcare.com/4',
  },
  appCenter: {
    apiToken: '85e072e6f4285e95def6ea7e6e2a0065e2c2f8a4',
  },
  googleAnalytics: 'UA-49155721-5',
  launchDarkly: '5e57e83037c5f409cd7f9d2a',
  streamChat: 'zqbsq4z6azhr',
  clearTriageToken: 'lKdYlQWk1sMCRWn5EyUc',
};

export default config;
