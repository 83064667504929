export class TaskDefinitionOutcome {
  id = 0;

  name = '';

  enabled = true;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default TaskDefinitionOutcome;
