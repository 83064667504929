import React, { ReactElement, ReactFragment } from 'react';

import MTooltip from '@mui/material/Tooltip';

type Props = {
  text: string | ReactFragment;
  children: ReactElement<any, any>;
  enterDelay?: number;
  followCursor?: boolean;
  open?: boolean;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
    | undefined;
};

export const Tooltip = ({
  text,
  children,
  enterDelay,
  followCursor,
  open,
  placement,
}: Props) => (
  <MTooltip
    title={text}
    placement={placement}
    open={open}
    enterDelay={enterDelay}
    followCursor={followCursor}
  >
    {children}
  </MTooltip>
);
