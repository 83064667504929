import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { FormControlLabel, FormGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CustomCheckbox } from 'styleguide-v2/src/components/CheckboxWithLabel/CustomCheckbox';

import { Label } from '../Label';

export type CheckboxItem = {
  checked?: boolean;
  disabled?: boolean;
  value: string;
  label: string;
};

type Props = {
  className?: string;
  columns?: number;
  'data-cy'?: string;
  disabled?: boolean;
  items?: CheckboxItem[];
  label?: string;
  onChange?: (items: CheckboxItem[]) => void;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
  },
});

const labelStyle = {
  margin: 0,
  '& > .MuiCheckbox-root': {
    padding: '0.5rem 0.5rem 0.5rem 0',
  },
  '& > .MuiFormControlLabel-label': {
    fontSize: '1em',
    margin: 0,
  },
};

export const CheckboxGroup = ({
  className,
  columns,
  'data-cy': dataCy,
  disabled,
  items,
  label,
  onChange,
}: Props) => {
  const styles = useStyles();
  const classes = classNames(className, styles.container);
  const [checkboxes, setCheckboxes] = useState<CheckboxItem[]>([]);

  useEffect(() => setCheckboxes(items || []), [items]);

  const handleChange = (idx: number, checked: boolean) => {
    checkboxes[idx].checked = checked;
    setCheckboxes([...checkboxes]);
    if (onChange) onChange(checkboxes);
  };

  return (
    <div className={classes} data-cy={dataCy}>
      {label && <Label>{label}</Label>}
      <FormGroup>
        <div className={columns ? styles.gridContainer : ''}>
          {items?.map(
            ({ checked, label: itemLabel, disabled: itemDisabled }, index) => (
              <FormControlLabel
                className={columns ? `grid-span-${columns}` : ''}
                control={
                  <CustomCheckbox
                    checked={checked || false}
                    onChange={(_, c) => handleChange(index, c)}
                    disabled={disabled || itemDisabled}
                  />
                }
                key={itemLabel}
                label={itemLabel || ''}
                sx={labelStyle}
              />
            ),
          )}
        </div>
      </FormGroup>
    </div>
  );
};

export default CheckboxGroup;
