import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { translate } from '@vestahealthcare/common/i18n';
import { Text, Link } from 'styleguide/src/components/Text';
import { Icon } from 'styleguide/src/components/Icons';
import Colors from 'styleguide/src/styles/Colors';
import { ControlLabel } from 'react-bootstrap';
import classnames from 'classnames';

const useStyles = makeStyles({
  root: {
    backgroundColor: Colors.bkgdBlue,
    padding: 20,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  error: {
    border: '1px solid red',
  },
  icon: {
    color: Colors.textBlack,
    paddingBottom: 10,
  },
});

interface Props {
  onChange: (file: File) => void;
  className?: string;
  accept?: string;
  fileName?: string;
  label?: string;
  required?: boolean;
  showError?: boolean;
}

function FileUpload({
  onChange,
  className,
  accept,
  label,
  fileName,
  required,
  showError,
}: Props) {
  const styles = useStyles();
  const fileUploaderRef = React.useRef<HTMLInputElement>(null);

  function blockEvent(
    e: React.DragEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement>,
  ) {
    e.preventDefault();
    e.stopPropagation();
  }

  return (
    <>
      {label && (
        <ControlLabel>
          {label}
          {required && ' *'}
        </ControlLabel>
      )}
      <div
        className={classnames(
          styles.root,
          className,
          showError && styles.error,
        )}
        onDragOver={blockEvent}
        onDrop={(e) => {
          blockEvent(e);
          onChange(e.dataTransfer.files[0]);
        }}
      >
        <input
          ref={fileUploaderRef}
          type="file"
          style={{ display: 'none' }}
          accept={accept}
          onChange={(e) => {
            blockEvent(e);
            if (e?.target?.files && e?.target?.files[0]) {
              onChange(e.target.files[0]);

              // we need to wipe the value of the input, so user can upload same file again
              // For example, if first upload there was an error, then they fix it, then try and upload it again.
              if (fileUploaderRef.current) {
                fileUploaderRef.current.value = '';
              }
            }
          }}
        />
        <Icon size="lg">
          <i className={`fa fa-file-text ${styles.icon}`} />
        </Icon>
        <Text>{fileName || translate('rosterIngestion.dragAndDrop')}</Text>
        <Link onClick={() => fileUploaderRef.current?.click()}>
          {translate('rosterIngestion.clickHere')}
        </Link>
      </div>
    </>
  );
}

export default FileUpload;
