import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/styles';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';

import { translate } from '@vestahealthcare/common/i18n';
import { BiometricsConfig } from '@vestahealthcare/common/models';
import { Measurement } from '@vestahealthcare/common/models/Measurement';
import moment, { Moment } from '@vestahealthcare/common/moment';

import {
  Card,
  CardContent,
  CardHeader,
  Colors,
  Fonts,
  IconButton,
} from 'styleguide-v2';

import { getMeasurements } from 'dash/src/services/BiometricsServices';
import { uniqBy } from 'lodash';

import { BiometricsDot } from './BiometricsDot';
import { BiometricsCustomTooltip } from './CustomTooltip';
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const CHART_HEIGHT = 250;
const CHART_MARGIN = { top: 0, right: 0, bottom: 0, left: 0 };

const useStyles = makeStyles({
  title: {
    color: Colors.green,
  },
  lastReadingDate: {
    color: Colors.gray,
    fontSize: Fonts.fontSize,
    fontWeight: 400,
  },
  abnormalReadings: {
    color: Colors.textRed,
    fontSize: Fonts.fontSize,
    fontWeight: 500,
  },
  chart: {
    marginLeft: '-2rem',
    '& svg': {
      overflow: 'visible',
    },
  },
  notes: {
    padding: '1.6rem',
  },
  notesTitle: {
    marginBottom: '.4rem',
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
  },
});

export interface TransformedReading {
  date: string;
  time: string;
  unit: string;
  value: number;
}

// Reformat the data in preparation for use by the chart
const getTransformedReadings = (
  readings: Measurement[],
  config: BiometricsConfig,
): TransformedReading[] => {
  const transformedReadings: any[] = [];
  // FIXME: Duplicate readings are coming in, have to be removed
  uniqBy(readings, 'readAt').forEach((reading) => {
    const readingTime = moment.unix(reading.readAt);

    transformedReadings.push({
      channel: reading.channel.toString(),
      date: readingTime.format(' MM/DD '),
      time: readingTime.format('h:mma'),
      unit: config.unit,
      value: reading.value,
    });
  });
  return transformedReadings;
};

type DateRange = [Moment, Moment];

interface ChartProps {
  className?: string;
  config: BiometricsConfig;
  interval: DateRange;
  onClickAdd: () => void;
  onClickSettings: () => void;
}

export const BiometricsChart = ({
  className,
  config,
  interval,
  onClickAdd,
  onClickSettings,
}: ChartProps) => {
  const styles = useStyles();
  const collectionMethod = config?.collectionMethod?.toString();
  const frequency = config?.frequency?.toString();

  const [readings, setReadings] = useState<TransformedReading[]>();
  const [abnormalReadings, setAbnormalReadings] = useState<Measurement[]>();

  const getReadings = async () => {
    const newReadings = await getMeasurements(config.patient.id, {
      type: config.type,
      from: interval[0],
      to: interval[1],
    });

    setReadings(getTransformedReadings(newReadings, config));

    const { lowerLimit, upperLimit } = config;
    const abnormalData = newReadings.filter(
      ({ value }) =>
        (lowerLimit && value < lowerLimit) ||
        (upperLimit && value > upperLimit),
    );
    setAbnormalReadings(abnormalData || []);
  };

  useEffect(() => {
    getReadings();
  }, [config, interval[0], interval[1]]);

  const getLastReading = () => {
    if (!config.lastReading) return '';
    const days = moment()
      .endOf('day')
      .diff(moment.unix(config.lastReading?.readAt || 0), 'days');

    return (
      <div data-cy={`${config.type.value}-last-reading`}>
        <span>
          {config.lastReading.value} {config.unit}
        </span>
        {config.lastReading?.readAt && (
          <span className={styles.lastReadingDate}>
            &nbsp;&#9679;&nbsp;
            {days === 0
              ? translate('biometrics.today')
              : translate('biometrics.daysAgo', { count: days })}
          </span>
        )}
      </div>
    );
  };

  return (
    <Card className={className}>
      <CardContent>
        <CardHeader
          title={
            <div>
              <span className={styles.title}>{config.type.toString()}</span>
              {getLastReading()}
            </div>
          }
          subheader={
            <>
              {collectionMethod}
              {frequency ? <>&nbsp;&#9679;&nbsp;{frequency}</> : ''}
              {abnormalReadings?.length ? (
                <>
                  &nbsp;&#9679;&nbsp;
                  <span className={styles.abnormalReadings}>
                    {translate('biometrics.abnormalEntriesCount', {
                      count: abnormalReadings.length,
                    })}
                  </span>
                </>
              ) : (
                ''
              )}
            </>
          }
          action={
            <>
              <IconButton
                color="default"
                data-cy={`${config.type.value}-add`}
                size="small"
                onClick={() => onClickAdd()}
              >
                <AddIcon fontSize="large" />
              </IconButton>
              <IconButton
                color="default"
                data-cy={`${config.type.value}-settings`}
                size="small"
                onClick={() => onClickSettings()}
              >
                <SettingsIcon fontSize="large" />
              </IconButton>
            </>
          }
        />
        {readings?.length ? (
          <ResponsiveContainer height={300}>
            <LineChart
              className={styles.chart}
              margin={CHART_MARGIN}
              height={CHART_HEIGHT}
              data={readings}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="date"
                axisLine={false}
                interval="preserveStartEnd"
              />
              <YAxis
                allowDecimals={false}
                axisLine={false}
                domain={[
                  config.lowerLimit || 'auto',
                  config.upperLimit || 'auto',
                ]}
                interval="preserveStartEnd"
              />
              <Tooltip content={<BiometricsCustomTooltip />} />
              <ReferenceLine y={config.upperLimit} stroke={Colors.gold} />
              <ReferenceLine y={config.lowerLimit} stroke={Colors.gold} />
              <Line
                activeDot={{ r: 4 }}
                dataKey="value"
                isAnimationActive={false}
                stroke={Colors.blue}
                strokeWidth={4}
                type="monotone"
                dot={<BiometricsDot config={config} color={Colors.blue} />}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <CardContent>
            <span>{`No ${config.type.toString()} readings found.`}</span>
          </CardContent>
        )}

        {config?.note && (
          <div className={styles.notes}>
            <p className={styles.notesTitle}>{translate('biometrics.notes')}</p>
            <p>{config?.note}</p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};
