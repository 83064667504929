import React from 'react';

import { Task } from '@vestahealthcare/common/models';

import { Subtask } from './Subtask';
import TaskDetails from './TaskDetails';

interface Props {
  task: Task;
  onUpdate: (newTask: Task) => void;
  hideAssignee?: boolean;
  hideGroup?: boolean;
  hideStatus?: boolean;
  showSubtask?: boolean;
}

export const TaskCompleteDetails = ({
  task,
  onUpdate,
  hideAssignee,
  hideGroup,
  hideStatus,
  showSubtask,
}: Props) => (
  <>
    <TaskDetails
      task={task}
      onUpdate={onUpdate}
      hideAssignee={hideAssignee}
      hideGroup={hideGroup}
      hideStatus={hideStatus}
    />
    {showSubtask &&
      task.children
        ?.sort((a, b) => a.position - b.position)
        .map((subtask) => (
          <Subtask
            key={`subtask-${subtask.id}`}
            task={subtask}
            onUpdate={() => onUpdate(task)}
          />
        ))}
  </>
);

export default TaskCompleteDetails;
