import { Language, TimeUnit } from '@vestahealthcare/common/enums';
import {
  BaseEnum,
  EngagementAudience,
  EngagementCampaign,
  EngagementCampaignAudience,
  EngagementCampaignCondition,
  EngagementCampaignTimingOption,
  EngagementContent,
  EngagementContentBase,
  EngagementFileType,
} from '@vestahealthcare/common/models';

import Api, { PaginatedResponse } from 'dash/src/services/Api';

export type CreateContentLibraryParams = {
  name: string;
  description?: string;
  contentTypeId: string;
  fileTypeId: string;
  active?: boolean;
  contentItems: {
    languageId: string;
    fieldName?: string;
    hasChanged?: boolean;
    rawText?: string;
  }[];
  tagIds: string[];
};

export const getEngagementContentAudiences = async (): Promise<
  EngagementAudience[]
> => {
  const {
    engagementTags: { items },
  } = await Api.getv2('engagement/tags');
  return items.map((item: any) => new EngagementAudience(item));
};

export const getEngagementCampaignAudiences = async (): Promise<
  EngagementCampaignAudience[]
> => {
  const {
    audiences: { items },
  } = await Api.getv2('engagement/campaigns/audiences');
  return items.map((item: any) => new EngagementCampaignAudience(item));
};

export const getEngagementContentTypes = async (): Promise<BaseEnum[]> => {
  const {
    engagementContentTypes: { items },
  } = await Api.getv2('engagement/content-types');
  return items.map((item: any) => new BaseEnum(item));
};

export const getEngagementFileTypes = async (): Promise<
  EngagementFileType[]
> => {
  const {
    engagementFileTypes: { items },
  } = await Api.getv2('engagement/file-types');
  return items.map((item: any) => new EngagementFileType(item));
};

export const getEngagementLanguages = async (): Promise<Language[]> => {
  const {
    engagementLanguages: { items },
  } = await Api.getv2('engagement/languages');
  return items.map(({ id }: { id: string }) => Language.byKey[id]);
};

export const getEngagementDeliveryMethods = async (): Promise<BaseEnum[]> => {
  const {
    'delivery-methods': { items },
  } = await Api.getv2('engagement/campaigns/delivery-methods');
  return items.map((item: any) => new BaseEnum(item));
};

export const getEngagementCampaignConditions = async (): Promise<
  EngagementCampaignCondition[]
> => {
  const {
    conditions: { items },
  } = await Api.getv2('engagement/campaigns/conditions');
  return items.map((item: any) => new EngagementCampaignCondition(item));
};

export const getEngagementCampaignTimingOptions = async (): Promise<
  EngagementCampaignTimingOption[]
> => {
  const {
    'timing-options': { items },
  } = await Api.getv2('engagement/campaigns/timing-options');
  return items.map((item: any) => new EngagementCampaignTimingOption(item));
};

export const createContentLibrary = async (
  params: CreateContentLibraryParams,
  files: File[],
) => {
  const blob = new Blob([JSON.stringify({ ...params, active: true })], {
    type: 'application/json',
  });

  const formData = new FormData();
  formData.append('content_metadata', blob, 'content_metadata');
  files.forEach((file) => {
    formData.append(file.name, file, file.name);
  });
  await Api.postv2File('engagement/contents', formData);
};

export const updateContentLibrary = async (
  contentId: number,
  params: CreateContentLibraryParams,
  files: File[],
) => {
  const blob = new Blob([JSON.stringify({ ...params })], {
    type: 'application/json',
  });

  const formData = new FormData();
  formData.append('content_metadata', blob, 'content_metadata');
  files.forEach((file) => {
    formData.append(file.name, file, file.name);
  });
  await Api.putv2File(`engagement/contents/${contentId}`, formData);
};

export type GetEngagementContentsParams = {
  active?: boolean;
  contentTypeIds?: BaseEnum[];
  id?: number;
  fileTypeIds?: EngagementFileType[];
  languages?: Language[];
  tags?: EngagementAudience[];
};

export const getEngagementContents = async ({
  contentTypeIds,
  fileTypeIds,
  languages,
  tags,
  ...rest
}: GetEngagementContentsParams): Promise<EngagementContent[]> => {
  const {
    contents: { items },
  } = await Api.getv2('engagement/contents', {
    contentTypeIds: contentTypeIds?.map(({ id }) => id),
    fileTypeIds: fileTypeIds?.map(({ id }) => id),
    languages: languages?.map(({ value }) => value),
    tags: tags?.map(({ id }) => id),
    ...rest,
    sort: 'active desc, id asc',
  });
  return items.map((item: any) => new EngagementContent(item));
};

export type GetEngagementCampaignsParams = {
  active?: boolean;
  audienceId?: EngagementCampaignAudience[];
  conditionId?: EngagementCampaignCondition[];
  delayFrom?: number;
  delayTo?: number;
  delayUnit?: TimeUnit;
  deliveryMethodId?: BaseEnum[];
  exceptionId?: EngagementCampaignCondition[];
  id?: number;
  name?: string;
  timingOptionId?: EngagementCampaignTimingOption;
  triggerId?: EngagementCampaignCondition[];
  limit?: number;
  offset?: number;
  sort?: string;
};

export const getEngagementCampaigns = async ({
  conditionId,
  deliveryMethodId,
  exceptionId,
  audienceId,
  timingOptionId,
  triggerId,
  ...rest
}: GetEngagementCampaignsParams): Promise<
  PaginatedResponse<EngagementCampaign>
> => {
  const {
    campaigns: { items, pagination },
  } = await Api.getv2('engagement/campaigns', {
    conditionId: conditionId?.map(({ id }) => id),
    deliveryMethodId: deliveryMethodId?.map(({ id }) => id),
    exceptionId: exceptionId?.map(({ id }) => id),
    audienceId: audienceId?.map(({ id }) => id),
    timingOptionId: timingOptionId?.id,
    triggerId: triggerId?.map(({ id }) => id),
    ...rest,
    sort: 'active desc, id asc',
  });
  return {
    items: items.map((item: any) => new EngagementCampaign(item)),
    pagination,
  };
};

export type CreateEngagementCampaignParams = {
  active?: boolean;
  audienceIds: EngagementCampaignAudience[];
  brands: string[];
  conditionIds?: EngagementCampaignCondition[];
  contentId: EngagementContentBase;
  delay?: number;
  delayUnit?: TimeUnit;
  deliveryMethodIds: BaseEnum;
  exceptionIds?: EngagementCampaignCondition[];
  name: string;
  timingOptionId: EngagementCampaignTimingOption;
  triggerIds: EngagementCampaignCondition[];
};

export const createEngagementCampaign = async ({
  audienceIds,
  conditionIds,
  contentId,
  delayUnit,
  deliveryMethodIds,
  exceptionIds,
  timingOptionId,
  triggerIds,
  ...rest
}: CreateEngagementCampaignParams) =>
  await Api.postv2JSON('engagement/campaigns', {
    audienceIds: audienceIds?.map(({ id }) => id) || [],
    conditionIds: conditionIds?.map(({ id }) => id) || [],
    contentId: contentId.id,
    delayUnit: delayUnit?.value,
    deliveryMethodIds: [deliveryMethodIds.id],
    exceptionIds: exceptionIds?.map(({ id }) => id) || [],
    maxDeliveries: 1,
    timingOptionId: timingOptionId.id,
    triggerIds: triggerIds.map(({ id }) => id) || [],
    ...rest,
  });

export const updateEngagementCampaign = async (
  campaignId: number,
  {
    audienceIds,
    conditionIds,
    contentId,
    delayUnit,
    deliveryMethodIds,
    exceptionIds,
    timingOptionId,
    triggerIds,
    ...rest
  }: CreateEngagementCampaignParams,
) =>
  await Api.putv2JSON(`engagement/campaigns/${campaignId}`, {
    audienceIds: audienceIds?.map(({ id }) => id) || [],
    conditionIds: conditionIds?.map(({ id }) => id) || [],
    contentId: contentId.id,
    delayUnit: delayUnit?.value,
    deliveryMethodIds: [deliveryMethodIds.id],
    exceptionIds: exceptionIds?.map(({ id }) => id) || [],
    maxDeliveries: 1,
    timingOptionId: timingOptionId.id,
    triggerIds: triggerIds.map(({ id }) => id) || [],
    ...rest,
  });
