import React, { useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import AddIcon from '@mui/icons-material/Add';

import {
  Button,
  FilterButton,
  IconButton,
  Panel,
  Select,
  TextInput,
} from 'styleguide-v2';
import { showGlobalError } from 'dash/src/components/GlobalMessage';

import { translate } from '@vestahealthcare/common/i18n';
import {
  CampaignTiming,
  CampaignTimingDelay,
  Choice,
  TimeUnit,
} from '@vestahealthcare/common/enums';
import {
  EngagementCampaign,
  EngagementCampaignAudience,
  EngagementCampaignCondition,
  BaseEnum,
  PaginationType,
  EngagementCampaignTimingOption,
} from '@vestahealthcare/common/models';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';

import {
  createEngagementCampaign,
  getEngagementCampaigns,
  GetEngagementCampaignsParams,
  updateEngagementCampaign,
} from 'dash/src/services/EngagementServices';
import { CacheServices } from 'dash/src/services';

import { CampaignEditModal } from './CampaignEditModal';
import { CampaignManagerTable } from './CampaignManagerTable';

export const CampaignManagerDashboard = () => {
  const { enableCampaignManagerCampaigns } = useFlags();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timer>();

  const [audiences, setAudiences] = useState<EngagementCampaignAudience[]>([]);
  const [deliveryMethods, setDeliveryMethods] = useState<BaseEnum[]>([]);
  const [triggers, setTriggers] = useState<EngagementCampaignCondition[]>([]);
  const [conditions, setConditions] = useState<EngagementCampaignCondition[]>(
    [],
  );
  const [timingOptions, setTimingOptions] = useState<
    EngagementCampaignTimingOption[]
  >([]);

  const [filters, setFilters] = useState<GetEngagementCampaignsParams>({});
  const [timing, setTiming] = useState<Selectable>();
  const [delay, setDelay] = useState<Selectable>();

  const [campaigns, setCampaigns] = useState<EngagementCampaign[]>([]);
  const [pagination, setPagination] = useState<PaginationType>(
    {} as PaginationType,
  );
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const [showEditContent, setShowEditContent] = useState<boolean>(false);
  const [editContent, setEditContent] = useState<EngagementCampaign>();

  let idleGetCampaign = true;

  const getFiltersData = async () => {
    setLoadingFilters(true);
    const [
      audiences,
      deliveryMethods,
      conditions,
      timingOptions,
    ] = await Promise.all([
      CacheServices.getEngagementCampaignAudiences(),
      CacheServices.getEngagementDeliveryMethods(),
      CacheServices.getEngagementCampaignConditions(),
      CacheServices.getEngagementCampaignTimingOptions(),
    ]);
    setAudiences(audiences);
    setDeliveryMethods(deliveryMethods);
    setTriggers(conditions?.filter(({ trigger }) => trigger) || []);
    const rules = [
      ...(conditions || []),
      ...(conditions?.map((item) => ({ ...item, exception: true })) || []),
    ].sort((a, b) => a.id.localeCompare(b.id)) as EngagementCampaignCondition[];
    setConditions(rules);
    setTimingOptions(timingOptions);
    setLoadingFilters(false);
  };

  const getDelayUnit = (timing?: Selectable, delay?: Selectable) => {
    if (timing?.value === CampaignTiming.IMMEDIATE.value) return undefined;
    if (
      [
        CampaignTimingDelay.UP_TO_3_HOURS.value,
        CampaignTimingDelay.UP_TO_6_HOURS.value,
        CampaignTimingDelay.UP_TO_12_HOURS.value,
        CampaignTimingDelay.UP_TO_24_HOURS.value,
      ].indexOf(delay?.value as string) !== -1
    ) {
      return TimeUnit.HOURS;
    }
    if (
      [
        CampaignTimingDelay.UP_TO_2_DAYS.value,
        CampaignTimingDelay.UP_TO_5_DAYS.value,
        CampaignTimingDelay.UP_TO_15_DAYS.value,
        CampaignTimingDelay.UP_TO_30_DAYS.value,
        CampaignTimingDelay.UP_TO_ANY_DAYS.value,
      ].indexOf(delay?.value as string) !== -1
    ) {
      return TimeUnit.HOURS;
    }
    return undefined;
  };

  const getDelayFrom = (timing?: Selectable, delay?: Selectable) => {
    if (timing?.value === CampaignTiming.IMMEDIATE.value) return 0;
    if (timing?.value === CampaignTiming.DELAYED.value) {
      if (!delay) return 1;
      if (delay?.value === CampaignTimingDelay.UP_TO_3_HOURS.value) return 1;
      if (delay?.value === CampaignTimingDelay.UP_TO_6_HOURS.value) return 4;
      if (delay?.value === CampaignTimingDelay.UP_TO_12_HOURS.value) return 7;
      if (delay?.value === CampaignTimingDelay.UP_TO_24_HOURS.value) return 13;
      if (delay?.value === CampaignTimingDelay.UP_TO_2_DAYS.value) return 1;
      if (delay?.value === CampaignTimingDelay.UP_TO_5_DAYS.value) return 3;
      if (delay?.value === CampaignTimingDelay.UP_TO_15_DAYS.value) return 6;
      if (delay?.value === CampaignTimingDelay.UP_TO_30_DAYS.value) return 16;
      if (delay?.value === CampaignTimingDelay.UP_TO_ANY_DAYS.value) return 31;
    }
    return undefined;
  };

  const getDelayTo = (timing?: Selectable, delay?: Selectable) => {
    if (timing?.value === CampaignTiming.IMMEDIATE.value) return 0;
    if (timing?.value === CampaignTiming.DELAYED.value) {
      if (delay?.value === CampaignTimingDelay.UP_TO_3_HOURS.value) return 3;
      if (delay?.value === CampaignTimingDelay.UP_TO_6_HOURS.value) return 6;
      if (delay?.value === CampaignTimingDelay.UP_TO_12_HOURS.value) return 12;
      if (delay?.value === CampaignTimingDelay.UP_TO_24_HOURS.value) return 24;
      if (delay?.value === CampaignTimingDelay.UP_TO_2_DAYS.value) return 2;
      if (delay?.value === CampaignTimingDelay.UP_TO_5_DAYS.value) return 5;
      if (delay?.value === CampaignTimingDelay.UP_TO_15_DAYS.value) return 15;
      if (delay?.value === CampaignTimingDelay.UP_TO_30_DAYS.value) return 30;
    }
    return undefined;
  };

  const getCampaigns = async () => {
    setLoading(true);
    if (idleGetCampaign) {
      idleGetCampaign = false;
      try {
        const {
          items,
          pagination: newPagination,
        } = await getEngagementCampaigns({
          ...filters,
          limit: pageSize,
          offset: pageSize * page,
          delayFrom: getDelayFrom(timing, delay),
          delayTo: getDelayTo(timing, delay),
          delayUnit: getDelayUnit(timing, delay),
          sort: 'active asc, id asc',
        });
        if (page) {
          setCampaigns([...campaigns, ...items]);
        } else {
          setCampaigns(items);
        }
        setPagination(newPagination);
      } catch (e) {
        showGlobalError(e as string);
      }
      idleGetCampaign = true;
      setLoading(false);
    }
  };

  const onAddContent = () => {
    setEditContent(undefined);
    setShowEditContent(true);
  };

  const onEditContent = (item: EngagementCampaign) => {
    setEditContent(item);
    setShowEditContent(true);
  };

  useEffect(() => {
    getFiltersData();
  }, []);

  useEffect(() => {
    getCampaigns();
  }, [filters, timing, delay, page, pageSize]);

  return (
    <Panel id="groups-section">
      <Panel.Heading title={translate('campaigns.manager.title')}>
        <FilterButton
          className="filters"
          data-cy="members-filters"
          filters={filters}
          onClick={() => setFiltersOpen(!filtersOpen)}
        />
        <Panel.Actions>
          <IconButton
            onClick={onAddContent}
            tooltip={translate('campaigns.manager.add')}
          >
            <AddIcon fontSize="large" />
          </IconButton>
        </Panel.Actions>
        <Panel.Collapse open={filtersOpen}>
          <div className="grid-wrapper fit">
            <TextInput
              className="grid-span-3"
              data-cy="campaign-manager-name"
              onChange={(name) => {
                if (!name || name?.length > 2) {
                  if (searchTimer) {
                    clearTimeout(searchTimer);
                  }
                  setSearchTimer(
                    setTimeout(() => {
                      setPage(0);
                      setFilters({ ...filters, name });
                    }, 300),
                  );
                }
              }}
              placeholder={translate('employees.filters.name')}
              value={filters.name}
            />
            <Select
              className="grid-span-3"
              data-cy="campaign-manager-trigger"
              getItemLabel={({ descTrigger }: EngagementCampaignCondition) =>
                descTrigger
              }
              items={triggers}
              limitTags={1}
              loading={loadingFilters}
              multiple
              onChange={(val?: EngagementCampaignCondition[]) => {
                setFilters({ ...filters, triggerId: val });
                setPage(0);
              }}
              placeholder={translate('campaigns.manager.triggers')}
              value={filters.triggerId || []}
            />
            <Select
              className="grid-span-3"
              data-cy="campaign-manager-conditions"
              getItemLabel={({
                descCondition,
                descException,
                exception,
              }: EngagementCampaignCondition) =>
                exception ? descException : descCondition
              }
              isOptionEqualToValue={(
                a: EngagementCampaignCondition,
                b: EngagementCampaignCondition,
              ) => a.id === b.id && a.exception === b.exception}
              items={conditions}
              limitTags={1}
              loading={loadingFilters}
              multiple
              onChange={(rules: EngagementCampaignCondition[]) => {
                setFilters({
                  ...filters,
                  conditionId: rules?.filter(({ exception }) => !exception),
                  exceptionId: rules?.filter(({ exception }) => exception),
                });
                setPage(0);
              }}
              placeholder={translate('campaigns.manager.conditions')}
              value={[
                ...(filters.conditionId || []),
                ...(filters.exceptionId || []),
              ]}
            />
            <Select
              className="grid-span-3"
              data-cy="campaign-manager-content-audience"
              getItemLabel={({ description }: EngagementCampaignAudience) =>
                description
              }
              items={audiences}
              loading={loadingFilters}
              limitTags={1}
              multiple
              onChange={(audienceId?: EngagementCampaignAudience[]) => {
                setFilters({ ...filters, audienceId });
                setPage(0);
              }}
              placeholder={translate('campaigns.manager.audience')}
              value={filters.audienceId || []}
            />
            <Select
              className="grid-span-3"
              data-cy="campaign-manager-content-timing-option"
              getItemLabel={(item?: EngagementCampaignTimingOption) =>
                item?.toString()
              }
              items={timingOptions}
              onChange={(timingOptionId?: EngagementCampaignTimingOption) => {
                setFilters({ ...filters, timingOptionId });
                setPage(0);
              }}
              placeholder={translate('campaigns.manager.timingOption')}
              value={filters.timingOptionId}
            />
            <Select
              className="grid-span-3"
              data-cy="campaign-manager-content-timing"
              items={Enum.toSelectable(CampaignTiming.asArray)}
              onChange={(val?: Selectable) => {
                setTiming(val);
                setDelay(undefined);
                setPage(0);
              }}
              placeholder={translate('campaigns.manager.timing')}
              value={timing}
            />
            <Select
              className="grid-span-3"
              data-cy="campaign-manager-content-timing-delay"
              disabled={timing?.value !== CampaignTiming.DELAYED.value}
              items={Enum.toSelectable(CampaignTimingDelay.asArray)}
              onChange={(delay?: Selectable) => {
                setDelay(delay);
                setPage(0);
              }}
              placeholder={translate('campaigns.manager.timingDelay')}
              value={delay}
            />
            <Select
              className="grid-span-3"
              data-cy="campaign-manager-content-delivery-method"
              getItemLabel={({ description }: BaseEnum) => description}
              items={deliveryMethods}
              loading={loadingFilters}
              limitTags={1}
              multiple
              onChange={(deliveryMethodId?: BaseEnum[]) => {
                setFilters({ ...filters, deliveryMethodId });
                setPage(0);
              }}
              placeholder={translate('campaigns.manager.deliveryMethod')}
              value={filters.deliveryMethodId || []}
            />
            <Select
              className="grid-span-3"
              data-cy="campaign-manager-active"
              items={Choice.getChoices()}
              onChange={(val?: Selectable) => {
                setFilters({
                  ...filters,
                  active: val !== undefined ? Boolean(val.value) : undefined,
                });
                setPage(0);
              }}
              placeholder={translate('campaigns.manager.active')}
              value={
                filters.active !== undefined
                  ? Choice.getChoices()[filters.active ? 0 : 1]
                  : undefined
              }
            />
            <div className="grid-span-9" />
            <Button
              className="grid-span-3"
              data-cy="campaign-manager-clear"
              color="secondary"
              type="outlined"
              onClick={() => {
                setFilters({});
                setTiming(undefined);
                setDelay(undefined);
                setPage(0);
              }}
            >
              {translate('global.clearFilters')}
            </Button>
          </div>
        </Panel.Collapse>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <CampaignManagerTable
          allowEditActive={enableCampaignManagerCampaigns}
          campaigns={campaigns}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
          onEdit={onEditContent}
          pagination={pagination}
        />
        <CampaignEditModal
          allowEditActive={enableCampaignManagerCampaigns}
          open={showEditContent}
          campaign={editContent}
          onClose={() => setShowEditContent(false)}
          onSubmit={async (params, campaign) => {
            let result = true;
            try {
              if (campaign?.id) {
                await updateEngagementCampaign(campaign.id, params);
              } else {
                await createEngagementCampaign(params);
              }
              await getCampaigns();
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
        />
      </Panel.Body>
    </Panel>
  );
};

export default CampaignManagerDashboard;
