import React from 'react';
import { Switch as MSwitch } from '@mui/material';

type Props = {
  checked?: boolean;
  className?: string;
  'data-cy'?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange: (val: boolean) => void;
  size?: 'small' | 'medium';
};

export const Switch = ({
  checked,
  className,
  'data-cy': dataCy,
  defaultChecked,
  disabled,
  onChange,
  size,
}: Props) => (
  <MSwitch
    checked={checked}
    className={className}
    data-cy={dataCy}
    defaultChecked={defaultChecked}
    disabled={disabled}
    onChange={(evt) => onChange(evt.target.checked)}
    size={size}
  />
);
