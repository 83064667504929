import { Enum } from './Enum';

export class MedicalEquipment extends Enum {
  translationKey = 'medicalEquipment';

  static asArray: MedicalEquipment[] = [];

  static byKey: { [name: string]: MedicalEquipment } = {};

  static readonly AIR_MATTRESS = new MedicalEquipment('AIR_MATTRESS');

  static readonly BATHTUB_SAFETY_RAIL = new MedicalEquipment(
    'BATHTUB_SAFETY_RAIL',
  );

  static readonly BED_ALARM = new MedicalEquipment('BED_ALARM');

  static readonly BED_RAILS = new MedicalEquipment('BED_RAILS');

  static readonly BEDPAN = new MedicalEquipment('BEDPAN');

  static readonly BEDSIDE_COMMODE = new MedicalEquipment('BEDSIDE_COMMODE');

  static readonly BIPAP = new MedicalEquipment('BIPAP');

  static readonly CPAP = new MedicalEquipment('CPAP');

  static readonly CANE = new MedicalEquipment('CANE');

  static readonly CHUX = new MedicalEquipment('CHUX');

  static readonly CONDOM_CATHETER = new MedicalEquipment('CONDOM_CATHETER');

  static readonly COMMODE = new MedicalEquipment('COMMODE');

  static readonly COMPRESSION_STOCKINGS = new MedicalEquipment(
    'COMPRESSION_STOCKINGS',
  );

  static readonly DENTURES = new MedicalEquipment('DENTURES');

  static readonly DIALYSIS_MACHINE = new MedicalEquipment('DIALYSIS_MACHINE');

  static readonly ENTERAL_PUMP = new MedicalEquipment('ENTERAL_PUMP');

  static readonly EYEGLASSES = new MedicalEquipment('EYEGLASSES');

  static readonly FEEDING_TUBES = new MedicalEquipment('FEEDING_TUBES');

  static readonly FIXED_WALKER = new MedicalEquipment('FIXED_WALKER');

  static readonly FOLEY_CATHETER = new MedicalEquipment('FOLEY_CATHETER');

  static readonly GERI_CHAIR = new MedicalEquipment('GERI_CHAIR');

  static readonly GRAB_BARS = new MedicalEquipment('GRAB_BARS');

  static readonly HANDHELD_SHOWER = new MedicalEquipment('HANDHELD_SHOWER');

  static readonly HEARING_AID = new MedicalEquipment('HEARING_AID');

  static readonly HOSPITAL_BED = new MedicalEquipment('HOSPITAL_BED');

  static readonly HOYER_LIFT = new MedicalEquipment('HOYER_LIFT');

  static readonly IV_PUMP = new MedicalEquipment('IV_PUMP');

  static readonly LEG_BRACES = new MedicalEquipment('LEG_BRACES');

  static readonly LIFE_ALERT = new MedicalEquipment('LIFE_ALERT');

  static readonly LIFT_CHAIR = new MedicalEquipment('LIFT_CHAIR');

  static readonly MERRY_WALKER = new MedicalEquipment('MERRY_WALKER');

  static readonly MOTORIZED_WHEELCHAIR = new MedicalEquipment(
    'MOTORIZED_WHEELCHAIR',
  );

  static readonly NEBULIZER = new MedicalEquipment('NEBULIZER');

  static readonly ORTHOTICS = new MedicalEquipment('ORTHOTICS');

  static readonly OSTOMY_BAG = new MedicalEquipment('OSTOMY_BAG');

  static readonly OVERBED_TABLE = new MedicalEquipment('OVERBED_TABLE');

  static readonly OXYGEN = new MedicalEquipment('OXYGEN');

  static readonly PERS = new MedicalEquipment('PERS');

  static readonly PIC_LINE_CATHETER = new MedicalEquipment('PIC_LINE_CATHETER');

  static readonly POWER_RECLINER = new MedicalEquipment('POWER_RECLINER');

  static readonly PRESSURE_PAD = new MedicalEquipment('PRESSURE_PAD');

  static readonly PROSTHESES = new MedicalEquipment('PROSTHESES');

  static readonly QUAD_CANE = new MedicalEquipment('QUAD_CANE');

  static readonly RAISED_TOILET_SEAT = new MedicalEquipment(
    'RAISED_TOILET_SEAT',
  );

  static readonly ROLLING_WALKER = new MedicalEquipment('ROLLING_WALKER');

  static readonly ROLLATOR = new MedicalEquipment('ROLLATOR');

  static readonly SHOWER_CHAIR = new MedicalEquipment('SHOWER_CHAIR');

  static readonly SIT_TO_STAND_LIFT = new MedicalEquipment('SIT_TO_STAND_LIFT');

  static readonly SPECIALIZED_MATTRESS = new MedicalEquipment(
    'SPECIALIZED_MATTRESS',
  );

  static readonly STAIRLIFT = new MedicalEquipment('STAIRLIFT');

  static readonly TRANSPORT_CHAIR = new MedicalEquipment('TRANSPORT_CHAIR');

  static readonly VENTILATOR = new MedicalEquipment('VENTILATOR');

  static readonly WALKER = new MedicalEquipment('WALKER');

  static readonly WOUND_VAC = new MedicalEquipment('WOUND_VAC');

  static readonly WHEELCHAIR = new MedicalEquipment('WHEELCHAIR');

  static readonly URINAL = new MedicalEquipment('URINAL');

  static readonly UNKNOWN = new MedicalEquipment('UNKNOWN');

  static readonly OTHER = new MedicalEquipment('OTHER');

  static readonly UNANSWERED = new MedicalEquipment('UNANSWERED', true);

  static readonly BEDBOUND = new MedicalEquipment('BEDBOUND', true);

  static readonly CATHETER_CARE = new MedicalEquipment('CATHETER_CARE', true);

  static readonly GLASSES = new MedicalEquipment('GLASSES', true);

  static readonly INCONTINENCE = new MedicalEquipment('INCONTINENCE', true);

  static readonly WC = new MedicalEquipment('WC', true);

  static readonly OBT = new MedicalEquipment('OBT', true);

  static readonly FLOOR_MATS = new MedicalEquipment('FLOOR_MATS', true);

  static readonly STAND_PIVOT = new MedicalEquipment('STAND_PIVOT', true);

  static readonly SLIDE_BOARD = new MedicalEquipment('SLIDE_BOARD', true);

  static readonly GAIT_BELT = new MedicalEquipment('GAIT_BELT', true);

  constructor(
    public readonly value: string,
    public readonly deprecated = false,
  ) {
    super();
    MedicalEquipment.asArray.push(this);
    MedicalEquipment.byKey[value] = this;
  }

  static toSelectable() {
    return Enum.toSelectable(
      MedicalEquipment.asArray.filter((val) => !val.deprecated),
    );
  }

  get isOther() {
    return this === MedicalEquipment.OTHER;
  }
}

export default MedicalEquipment;
