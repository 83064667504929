import { States } from '@vestahealthcare/common/enums';
import {
  BaseEnum,
  InsuranceMLTC,
  InsurancePlan,
  InsurancePlanEcw,
} from '@vestahealthcare/common/models';

import Api from 'dash/src/services/Api';

export type InsurancePlanParams = {
  inNetwork?: boolean;
  insuranceType?: string;
  isMedicaid?: boolean;
  isMedicare?: boolean;
  mltcPlanId?: number;
  name?: string;
  ecwId?: string;
};

export const fetchInsurancePlans = async (): Promise<InsurancePlan[]> => {
  const {
    insurancePlans: { items },
  } = await Api.getv2('insurance-plans');
  return items
    .map((item: any) => new InsurancePlan(item))
    .sort(InsurancePlan.sort);
};

export type InsurancePlanUpdateParams = {
  ecwIds?: InsurancePlanEcw[];
  facilityExceptions?: number[];
  name?: string;
  insuranceType?: string;
  inNetwork?: boolean;
  isGeneric?: boolean;
  isMedicaid?: boolean;
  isMedicare?: boolean;
  states: States[];
};

export const createInsurancePlan = async ({
  states,
  ...params
}: InsurancePlanUpdateParams) => {
  await Api.postv2JSON(
    'insurance-plans',
    {
      states: states.map(({ value }) => value),
      ...params,
    },
    { showToast: true },
  );
};

export const updateInsurancePlan = async (
  id: number,
  { states, ...params }: InsurancePlanUpdateParams,
) => {
  await Api.putv2JSON(
    `insurance-plans/${id}`,
    {
      states: states.map(({ value }) => value),
      ...params,
    },
    { showToast: true },
  );
};

export const fetchInsuranceMLTCs = async (): Promise<InsuranceMLTC[]> => {
  const {
    mltcInsurancePlans: { items },
  } = await Api.getv2('mltc-insurance-plans');
  return items.map((item: any) => new InsuranceMLTC(item));
};

export const fetchInsuranceTypes = async (): Promise<BaseEnum[]> => {
  const {
    insuranceTypes: { items },
  } = await Api.getv2('insurance-types');
  return items.map((item: any) => new BaseEnum(item));
};
