import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import React, { useEffect, useState } from 'react';

import {
  DeviceOS,
  MemberContactMethodType,
  PhoneType,
} from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  Patient,
  Phone,
  UserPhone,
} from '@vestahealthcare/common/models';
import { NO_TIME_DATE_FORMAT } from '@vestahealthcare/common/utils/constants';

import {
  Invite,
  Modal,
  Select,
  SelectPhoneOption,
  SwitchGroup,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CacheServices } from 'dash/src/services';
import {
  sendAppInvite,
  updatePatient,
} from 'dash/src/services/PatientServices';

import { BaseModal } from './BaseModal';

const phoneUtil = PhoneNumberUtil.getInstance();

type Props = {
  patient: Patient;
  onSubmit: () => void;
};

type DetailsProps = {
  patient?: Patient;
  onSubmit: () => void;
};

const DetailsComponent = React.memo(({ patient, onSubmit }: DetailsProps) => {
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [showInviteModalType, setShowInviteModalType] = useState<DeviceOS>();

  return (
    <>
      <h4 className="grid-span-4" data-cy="member-contact-info-label">
        {translate('personalDetails.selfReporter.label')}
      </h4>
      <p className="grid-span-7" data-cy="member-contact-info-readonly">
        <p className="no-margin">
          {translate(`global.${patient?.hciReporter ? 'yes' : 'no'}`)}{' '}
          {patient?.hciReporter && (
            <span className="small">
              [
              {patient?.hciReporterStatus?.description ||
                translate('global.new')}
              ]
            </span>
          )}
        </p>

        {patient?.hciReporterMethod && (
          <p className="no-margin">{patient.hciReporterMethod.toString()}</p>
        )}

        {patient &&
          patient.hciReporter &&
          patient.hciReporterMethod ===
            MemberContactMethodType.MOBILE_APP_CHAT &&
          patient.appUser?.authenticationPhone &&
          !patient.appUser?.lastLoginAt && (
            <>
              <br />
              <div className="flex gap wrap">
                <Invite
                  sent={patient.appUser.inviteOS === DeviceOS.IOS}
                  type="ios"
                  onClick={() => {
                    setShowInviteModalType(DeviceOS.IOS);
                    setShowInviteModal(true);
                  }}
                />
                <Invite
                  sent={patient.appUser.inviteOS === DeviceOS.ANDROID}
                  type="android"
                  onClick={() => {
                    setShowInviteModalType(DeviceOS.ANDROID);
                    setShowInviteModal(true);
                  }}
                />
              </div>
              <Modal
                onClose={() => setShowInviteModal(false)}
                onSubmit={async () => {
                  await sendAppInvite(
                    patient.id,
                    showInviteModalType === DeviceOS.IOS ? 'ios' : 'android',
                  );
                  onSubmit();
                }}
                submitText={translate('global.send')}
                open={!!showInviteModal}
                title={translate('personalDetails.selfReporter.sendAppInvite')}
                body={translate(
                  'personalDetails.selfReporter.sendAppInviteBody',
                  {
                    member: patient.fullName,
                    number: phoneUtil.format(
                      phoneUtil.parse(
                        patient.appUser.authenticationPhone.number,
                        'US',
                      ),
                      PhoneNumberFormat.NATIONAL,
                    ),
                    os: showInviteModalType?.toString(),
                  },
                )}
              />
            </>
          )}
      </p>
    </>
  );
});

export const EditSelfReporter = ({ patient, onSubmit }: Props) => {
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [selfReporterStatuses, setSelfReporterStatuses] = useState<BaseEnum[]>(
    [],
  );

  const [hciNotifications, setHCINotifications] = useState<boolean>(false);
  const [selfReporter, setSelfReporter] = useState<boolean>(false);
  const [authenticationPhone, setAuthenticationPhone] = useState<Phone>();
  const [hciReporterMethod, setHCIReporterMethod] = useState<
    MemberContactMethodType
  >();

  const reset = () => {
    setButtonLoading(false);
    setSubmitted(false);
  };

  useEffect(() => reset(), [patient]);

  const getInitialData = async () => {
    setLoading(true);
    const statuses = await CacheServices.getCareTeamPersonStatuses();
    setSelfReporterStatuses(statuses);
    setLoading(false);
  };

  useEffect(() => {
    getInitialData();
    setSelfReporter(patient?.hciReporter);
    setAuthenticationPhone(patient?.appUser?.authenticationPhone);
    setHCIReporterMethod(patient?.hciReporterMethod);
    setHCINotifications(!!patient?.hciNotifications);
  }, [patient]);

  const validate = () =>
    !selfReporter ||
    hciReporterMethod !== MemberContactMethodType.MOBILE_APP_CHAT ||
    authenticationPhone;

  const submit = async () => {
    let result = true;
    setSubmitted(true);

    if (!validate()) return false;

    setButtonLoading(true);
    try {
      await updatePatient(patient.id, {
        hciNotifications,
        hciReporter: selfReporter,
        hciReporterMethod: selfReporter ? hciReporterMethod || null : null,
        authenticationPhone:
          selfReporter &&
          hciReporterMethod === MemberContactMethodType.MOBILE_APP_CHAT
            ? authenticationPhone?.number || null
            : null,
      });
      onSubmit();
      setSubmitted(false);
      await getInitialData();
    } catch (e) {
      showGlobalError(e as string);
      result = false;
    }
    setButtonLoading(false);
    return result;
  };

  return (
    <>
      <BaseModal
        details={<DetailsComponent patient={patient} onSubmit={onSubmit} />}
        data-cy="member-contact-info"
        loading={loading}
        loadingSubmit={buttonLoading}
        onCancel={reset}
        onSubmit={submit}
        title={translate('personalDetails.selfReporter.title')}
      >
        <div className="grid-wrapper fit">
          <Select.Choice
            className="grid-span-6"
            disableClearable
            label={translate('personalDetails.selfReporter.selfReporter')}
            onChange={(val?: boolean) => setSelfReporter(!!val)}
            value={selfReporter}
          />

          {selfReporter ? (
            <Select
              className="grid-span-6"
              disabled
              items={selfReporterStatuses}
              getItemLabel={({ description }: BaseEnum) => description}
              label={translate(
                'personalDetails.selfReporter.selfReporterStatus',
              )}
              onChange={() => {}}
              value={patient?.hciReporterStatus || selfReporterStatuses[0]}
            />
          ) : (
            <div className="grid-span-6" />
          )}

          {selfReporter && (
            <>
              <Select
                className="grid-span-6"
                items={Enum.toSelectable([
                  MemberContactMethodType.MOBILE_APP_CHAT,
                  MemberContactMethodType.TEXT,
                ])}
                label={translate(
                  'personalDetails.selfReporter.hciReporterMethod',
                )}
                onChange={(item?: Selectable) =>
                  setHCIReporterMethod(
                    item
                      ? MemberContactMethodType.byKey[item.value]
                      : undefined,
                  )
                }
                value={
                  hciReporterMethod
                    ? Enum.toSelectable([hciReporterMethod])[0]
                    : undefined
                }
              />
              {hciReporterMethod === MemberContactMethodType.MOBILE_APP_CHAT ? (
                <Select
                  className="grid-span-6"
                  error={submitted && !authenticationPhone}
                  getItemLabel={({ phone: { number, type } }: UserPhone) =>
                    `${phoneUtil.format(
                      phoneUtil.parse(number, 'US'),
                      PhoneNumberFormat.NATIONAL,
                    )} - ${type?.toString()}`
                  }
                  renderOption={(phone: UserPhone) => (
                    <SelectPhoneOption userPhone={phone} />
                  )}
                  items={
                    patient?.phones?.filter(
                      ({ phone: { type } }) => type === PhoneType.CELL,
                    ) || []
                  }
                  label={translate(
                    'personalDetails.preferredContactModal.authenticationPhone',
                  )}
                  onChange={(item?: UserPhone) =>
                    setAuthenticationPhone(item?.phone)
                  }
                  value={patient?.phones?.find(
                    ({ phone }) => phone.number === authenticationPhone?.number,
                  )}
                  required
                />
              ) : (
                <div className="grid-span-6" />
              )}
              <SwitchGroup
                className="grid-span-6"
                items={[
                  {
                    label: translate(
                      'personalDetails.selfReporter.hciNotifications',
                    ),
                    checked: hciNotifications,
                  },
                ]}
                onChange={(items) => {
                  setHCINotifications(!!items[0].checked);
                }}
              />
            </>
          )}
        </div>
      </BaseModal>
      {patient?.hciReporter && patient?.appUser?.lastLoginAt && (
        <>
          <h4 className="grid-span-4">
            {`${translate('personalDetails.selfReporter.lastLoginInfo')} `}
          </h4>
          <p className="grid-span-8">
            <p>
              {'on '}
              {patient?.appUser?.lastLoginAt?.format(NO_TIME_DATE_FORMAT)}
            </p>
            <p>
              {patient?.appUser?.lastLoginDevice &&
                `v${
                  patient?.appUser?.lastLoginDevice.appVersion
                } - ${DeviceOS.byKey[
                  patient?.appUser?.lastLoginDevice.os
                ].toString()} `}
              {patient?.appUser?.lastLoginDevice?.osVersion &&
                `${patient?.appUser?.lastLoginDevice.osVersion} `}
            </p>
          </p>
        </>
      )}
    </>
  );
};

export default EditSelfReporter;
