import React from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { Table } from 'styleguide-v2';
import { MemberDiagnostic } from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

interface Props {
  problems: MemberDiagnostic[];
}

const PROBLEM_COLUMNS = () => [
  {
    headerName: translate('memberProfile.clinicalSummary.tables.problems.code'),
    field: 'code',
  },
  {
    headerName: translate('memberProfile.clinicalSummary.tables.problems.name'),
    field: 'name',
  },
  {
    headerName: translate(
      'memberProfile.clinicalSummary.tables.problems.onsetDate',
    ),
    component: ({ onsetDate }: MemberDiagnostic) =>
      onsetDate?.format(DATE_FORMAT_SHORT) || EMPTY,
  },
  {
    headerName: translate(
      'memberProfile.clinicalSummary.tables.problems.status',
    ),
    component: ({ status }: MemberDiagnostic) => (
      <span className="capitalize">{status}</span>
    ),
  },
];

export const ProblemsTable = (props: Props) => (
  <Table
    config={{
      columns: PROBLEM_COLUMNS(),
      compact: true,
      data: props.problems,
      paginationOptions: [-1],
    }}
  />
);

export default ProblemsTable;
