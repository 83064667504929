import React, { PureComponent } from 'react';
import { CareTrack } from '@vestahealthcare/common/models';
import { RiskLevel } from '@vestahealthcare/common/enums';
import { Table } from 'styleguide';
import { translateToArray } from '@vestahealthcare/common/i18n';

interface Props {
  careTrack: CareTrack;
}

export class RiskCriteriaTable extends PureComponent<Props> {
  render() {
    const { careTrack } = this.props;
    return (
      <Table className="bordered-table">
        <tbody>
          <tr>
            {careTrack.type.riskLevels.map((riskLevel) =>
              renderRiskLevel(riskLevel, careTrack),
            )}
          </tr>
        </tbody>
      </Table>
    );
  }
}

const renderRiskLevel = (riskLevel: RiskLevel, careTrack: CareTrack) => {
  const criteria = translateToArray(
    `riskCriteria.${careTrack.type.value}.${riskLevel.value}`,
  ).join('\n\n');
  return (
    <td
      className={careTrack.riskLevel === riskLevel ? 'selected' : undefined}
      key={riskLevel.value}
    >
      <p className="medium">{riskLevel.toString()}</p>
      <p>{criteria}</p>
    </td>
  );
};

export default RiskCriteriaTable;
