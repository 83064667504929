import moment, { Moment } from 'moment';

import { TaskStatus } from '../enums/TaskStatus';
import { Employee } from './Employee';
import { EmployeeGroup } from './EmployeeGroup';
import { make } from './Model';

export class MemberDashboardTask {
  definition = '';

  status: TaskStatus;

  date: Moment;

  assignee: Employee;

  assigneeGroup: EmployeeGroup;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.status = TaskStatus.byKey[obj.status];
    this.date = moment.unix(obj.date);
    this.assignee = make(obj.assignee, Employee);
    this.assigneeGroup = make(obj.assigneeGroup, EmployeeGroup);
  }
}

export default MemberDashboardTask;
