import { Moment } from 'moment-timezone';
import React, { useState } from 'react';

import { makeStyles } from '@mui/styles';

import {
  CaregiverRelationship,
  Enum,
  VirtualVisitStatus,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';
import moment from '@vestahealthcare/common/moment';
import { getDiff } from '@vestahealthcare/common/utils/api';
import {
  DATE_FORMAT_SHORT,
  DATE_FORMAT_SHORT_WITH_TIME,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';
import { format, validate } from '@vestahealthcare/common/utils/phone';

import { DateTimePicker, PhoneInput, Select, TextInput } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { updatePatient } from 'dash/src/services/PatientServices';
import Session from 'dash/src/services/SessionServices';

import { BaseModal } from './BaseModal';

const useStyles = makeStyles({
  container: {
    borderRight: '0 !important',
    marginRight: '-4rem',
  },
});
interface Props {
  patient: Patient;
  onSubmit: (patient: Patient) => void;
}

const renderDetails = ({
  virtualVisitDate: date,
  virtualVisitStatus: status,
  virtualVisitHostName: name,
  virtualVisitHostRelationship: relationship,
  virtualVisitHostRelationshipOther: relationshipOther,
  virtualVisitHostPhoneNumber: number,
  virtualVisitHasTime: hasTime,
}: Patient) => {
  const styles = useStyles();
  return (
    <div className={`grid-span-11 grid-wrapper ${styles.container}`}>
      {(date || status) && (
        <>
          <h4 className="grid-span-5">
            {translate('personalDetails.virtualVisitInfo')}
          </h4>
          <p className="grid-span-6">
            {!!date &&
              moment
                .unix(date)
                .format(
                  hasTime ? DATE_FORMAT_SHORT_WITH_TIME : DATE_FORMAT_SHORT,
                )}
            {!!status && (
              <p className="small no-margin">[{status?.toString()}]</p>
            )}
          </p>
        </>
      )}
      {name && (
        <>
          <h4 className="grid-span-5">
            {translate('personalDetails.virtualVisitHost')}
          </h4>
          <p className="grid-span-6">
            {name}
            {(relationship || number) && (
              <p className="small no-margin">
                <a href={`tel:${number}`}>{number && format(String(number))}</a>{' '}
                {relationship &&
                  `[${
                    relationship === CaregiverRelationship.OTHER
                      ? relationshipOther
                      : relationship.toString()
                  }]`}
              </p>
            )}
          </p>
        </>
      )}
      {!date && !status && !name && (
        <>
          <h4 className="grid-span-5">
            {translate('personalDetails.virtualVisitInfo')}
          </h4>
          <p className="grid-span-6">{EMPTY}</p>
        </>
      )}
    </div>
  );
};

export const EditVirtualVisitDate = React.memo((props: Props) => {
  const { patient, onSubmit } = props;
  const { virtualVisitDate } = patient;
  const [date, setDate] = useState<Date | undefined>(
    virtualVisitDate ? moment.unix(virtualVisitDate)?.toDate() : undefined,
  );
  const [time, setTime] = useState<Date | undefined>(
    virtualVisitDate ? moment.unix(virtualVisitDate)?.toDate() : undefined,
  );
  const readonly =
    patient?.virtualVisitStatus === VirtualVisitStatus.COMPLETED &&
    !Session.actingUser.isAdmin;
  const [status, setStatus] = useState(
    patient?.virtualVisitStatus
      ? Enum.toSelectable([patient?.virtualVisitStatus])[0]
      : undefined,
  );
  const [name, setName] = useState(patient?.virtualVisitHostName);
  const [relationship, setRelationship] = useState(
    patient?.virtualVisitHostRelationship
      ? Enum.toSelectable([patient?.virtualVisitHostRelationship])[0]
      : undefined,
  );
  const [relationshipOther, setRelationshipOther] = useState(
    patient?.virtualVisitHostRelationshipOther,
  );
  const [number, setNumber] = useState(
    patient?.virtualVisitHostPhoneNumber
      ? String(patient.virtualVisitHostPhoneNumber)
      : undefined,
  );
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    let result = true;

    setSubmitted(true);

    if (
      !patient.isEditable() ||
      !number ||
      (relationship?.value === CaregiverRelationship.OTHER.value &&
        !relationshipOther) ||
      (time && !date) ||
      !name ||
      !validate(number)
    ) {
      return false;
    }

    setLoading(true);

    try {
      let virtualVisitDate: Moment | null = null;
      if (date) {
        virtualVisitDate = moment(date.getTime())
          .hours(time?.getHours() || 0)
          .minutes(time?.getMinutes() || 0);
      }
      const newPatient = await updatePatient(
        patient.id,
        getDiff(
          {
            ...patient,
            virtualVisitDate: patient.virtualVisitDate
              ? moment.unix(patient.virtualVisitDate)
              : undefined,
            virtualVisitHostRelationship:
              patient?.virtualVisitHostRelationship?.value,
            virtualVisitStatus: patient?.virtualVisitStatus?.value,
          },
          {
            virtualVisitDate,
            virtualVisitStatus: status?.value || null,
            virtualVisitHostName: name,
            virtualVisitHostRelationship: relationship?.value,
            virtualVisitHostRelationshipOther:
              relationship?.value === CaregiverRelationship.OTHER.value
                ? relationshipOther
                : null,
            virtualVisitHostPhoneNumber: number,
            virtualVisitHasTime: !!date && !!time,
          },
        ),
      );
      await onSubmit(newPatient);
      setSubmitted(false);
    } catch (e) {
      showGlobalError(e as string);
      result = false;
    }

    setLoading(false);
    return result;
  };

  const reset = () => {
    setSubmitted(false);
    setDate(
      virtualVisitDate ? moment.unix(virtualVisitDate)?.toDate() : undefined,
    );
    setTime(
      virtualVisitDate && patient?.virtualVisitHasTime
        ? moment.unix(virtualVisitDate)?.toDate()
        : undefined,
    );
    setStatus(
      patient?.virtualVisitStatus
        ? Enum.toSelectable([patient?.virtualVisitStatus])[0]
        : undefined,
    );
    setName(patient?.virtualVisitHostName);
    setRelationship(
      patient?.virtualVisitHostRelationship
        ? Enum.toSelectable([patient?.virtualVisitHostRelationship])[0]
        : undefined,
    );
    setRelationshipOther(patient?.virtualVisitHostRelationshipOther);
    setNumber(
      patient?.virtualVisitHostPhoneNumber
        ? String(patient.virtualVisitHostPhoneNumber)
        : undefined,
    );
  };

  return (
    <>
      <BaseModal
        data-cy="patient-virtual-visit-date"
        details={renderDetails(patient)}
        editable={patient.isEditable() && !readonly}
        loadingSubmit={loading}
        onSubmit={submit}
        onShowModal={reset}
        title={translate('personalDetails.virtualVisit')}
      >
        <div className="grid-wrapper">
          <h4 className="grid-span-12" style={{ margin: 0 }}>
            {translate('personalDetails.virtualVisitInfo')}
          </h4>
          <DateTimePicker
            className="grid-span-6"
            label={translate('personalDetails.virtualVisitDate')}
            onChange={setDate}
            placeholder={translate('global.chooseADate')}
            required={!!time}
            error={submitted && !!time && !date}
            value={date}
          />
          <DateTimePicker
            className="grid-span-6"
            label={translate('personalDetails.virtualVisitTime')}
            onChange={setTime}
            placeholder={translate('global.chooseATime')}
            type="time"
            value={time}
          />
          <Select
            className="grid-span-12"
            data-cy="virtual-visit-edit-status"
            label={translate('personalDetails.virtualVisitStatus')}
            onChange={setStatus}
            items={VirtualVisitStatus.toSelectable()}
            placeholder={translate('global.chooseAStatus')}
            value={status}
          />
          <h4 className="grid-span-12" style={{ margin: 0 }}>
            {translate('personalDetails.virtualVisitHost')}
          </h4>
          <TextInput
            className="grid-span-12"
            data-cy="virtual-visit-edit-host-name"
            label={translate('personalDetails.fullName')}
            onChange={setName}
            error={submitted && !name}
            required
            value={name}
          />
          <Select
            className={`grid-span-${
              relationship?.value === CaregiverRelationship.OTHER.value ? 6 : 12
            }`}
            data-cy="virtual-visit-edit-host-relationship"
            label={translate('personalDetails.relationship')}
            onChange={setRelationship}
            items={Enum.toSelectable(CaregiverRelationship.asArray)}
            value={relationship}
          />
          {relationship?.value === CaregiverRelationship.OTHER.value && (
            <TextInput
              className="grid-span-6"
              data-cy="virtual-visit-edit-host-relationship-other"
              label={translate('global.other')}
              onChange={setRelationshipOther}
              value={relationshipOther}
              error={submitted && !relationshipOther}
              required
            />
          )}
          <PhoneInput
            className="grid-span-12"
            data-cy="virtual-visit-edit-host-number"
            label={translate('personalDetails.phoneNumber')}
            onChange={setNumber}
            value={number}
            submitted={submitted}
            required
          />
        </div>
      </BaseModal>
    </>
  );
});
