import classNames from 'classnames';
import React from 'react';

import { translate } from '@vestahealthcare/common/i18n';

import { GridColumns } from '../../utils/types';

interface BasicProps {
  className?: string;
  children: any;
  columns?: GridColumns;
  'data-cy'?: string;
  flex?: boolean;
  key?: string | number;
  style?: React.CSSProperties;
  translationKey?: string;
}

interface TextProps extends BasicProps {
  breakAfter?: boolean;
  bold?: boolean;
  color?:
    | 'highlight'
    | 'warning'
    | 'error'
    | 'hint'
    | 'success'
    | 'danger'
    | 'info';
  isTag?: boolean;
  hint?: boolean;
  italic?: boolean;
  large?: boolean;
  overflowEllipsis?: boolean;
  small?: boolean;
  inline?: boolean;
  warning?: boolean;
}

export const Text = (props: TextProps) => {
  const {
    breakAfter,
    bold,
    children,
    className,
    color,
    columns,
    'data-cy': dataCy = 'text',
    flex,
    isTag,
    italic,
    large,
    overflowEllipsis,
    small,
    inline,
    style,
    translationKey,
  } = props;

  // TODO: clean up error / tag CSS classes
  const classes = classNames(
    className,
    {
      'line-break-after': breakAfter,
      'error-red': !isTag && color === 'error',
      'error-tag': isTag,
      flex,
      highlight: color === 'highlight',
      hint: color === 'hint',
      success: color === 'success',
      danger: color === 'danger',
      info: color === 'info',
      italic,
      large,
      medium: bold,
      'overflow-ellipsis': overflowEllipsis,
      small,
      warning: color === 'warning',
    },
    columns ? `grid-span-${columns}` : undefined,
  );

  return React.createElement(
    inline ? 'span' : 'p',
    {
      className: classes,
      'data-cy': dataCy,
      style,
    },
    translationKey ? translate(translationKey) : undefined,
    children,
  );
};

interface LinkProps extends BasicProps {
  bold?: boolean;
  href?: string;
  italic?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  openNewTab?: boolean;
}

export const Link = (props: LinkProps) => {
  const {
    bold,
    children,
    className,
    columns,
    'data-cy': dataCy = 'link',
    flex,
    href,
    italic,
    onClick,
    openNewTab,
    style,
    translationKey,
  } = props;

  const classes = classNames(
    className,
    {
      flex,
      italic,
      medium: bold,
    },
    href ? undefined : 'link',
    columns ? `grid-span-${columns}` : undefined,
  );

  return React.createElement(
    href ? 'a' : 'button',
    {
      className: classes,
      'data-cy': dataCy,
      href,
      target: openNewTab ? '_blank' : undefined,
      rel: openNewTab ? 'noopener noreferrer' : undefined,
      onClick,
      style,
      role: href ? undefined : 'button',
    },
    translationKey && translate(translationKey),
    children,
  );
};

interface HeaderProps extends BasicProps {
  color?: 'warning' | 'error' | 'invalid';
  isTag?: boolean;
  size: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7';
  useDiv?: boolean; // Bypasses some issues with inherited styling
}

export const Header = (props: HeaderProps) => {
  const {
    children,
    className,
    color,
    columns,
    'data-cy': dataCy = 'header',
    flex,
    isTag,
    size,
    style,
    translationKey,
    useDiv = true,
  } = props;

  const classes = classNames(
    className,
    {
      danger: color === 'error',
      flex,
      invalid: color === 'invalid',
      warning: color === 'warning',
      tag: isTag,
    },
    useDiv ? size : undefined,
    columns ? `grid-span-${columns}` : undefined,
  );

  return React.createElement(
    useDiv ? 'div' : size,
    {
      className: classes,
      'data-cy': dataCy,
      style,
    },
    translationKey ? translate(translationKey) : undefined,
    children,
  );
};
