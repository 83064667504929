import React, { useEffect, useState } from 'react';

import OpenExternalIcon from '@mui/icons-material/OpenInNew';

import { translate } from '@vestahealthcare/common/i18n';
import { IncidentDetail } from '@vestahealthcare/common/models';

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  HorizontalRule,
  TextArea,
} from 'styleguide-v2';

import { IncidentModalFields } from 'dash/src/pages/Incidents/IncidentModalFields';
import { UpdateIncidentParams } from 'dash/src/services/IncidentServices';

type Props = {
  className?: string;
  detail: IncidentDetail;
  editable?: boolean;
  onChange: (params: Partial<UpdateIncidentParams>) => void;
  submitted?: boolean;
};

export const IncidentsDetailIncident = ({
  className,
  detail,
  editable,
  onChange,
  submitted,
}: Props) => {
  const [externalNote, setExternalNote] = useState<string>();
  const [params, setParams] = useState<UpdateIncidentParams>();

  useEffect(() => {
    setExternalNote(detail.externalNote || detail.description);
  }, [detail]);

  useEffect(() => {
    onChange({
      ...params,
      externalNote,
    });
  }, [externalNote, params]);

  return (
    <Card className={className}>
      <CardHeader
        title={translate('incidents.detail.incidentCardTitle')}
        action={
          !!detail.sourceEventId && (
            <Button
              color="quaternary"
              data-cy="incident-open-event"
              icon={<OpenExternalIcon />}
              size="s"
              onClick={() =>
                window.open(`#/patients/${detail.member.id}/events`)
              }
            >
              <span className="bold">
                {translate('incidents.detail.openEvent')}
              </span>
            </Button>
          )
        }
      />
      <CardContent className="grid-wrapper fit" sx={{ paddingTop: 0 }}>
        <IncidentModalFields
          className="grid-span-12"
          disabled={!editable}
          incident={detail}
          onChange={setParams}
          readonlyDescription
          submitted={submitted}
        />
        <HorizontalRule className="grid-span-12" />
        <TextArea
          className="grid-span-12"
          data-cy="incident-partner-note"
          disabled={!editable}
          error={
            submitted &&
            !externalNote &&
            translate('global.missingRequiredField')
          }
          label={translate('incidents.common.partnerNote')}
          onChange={setExternalNote}
          rows={3}
          value={externalNote || ''}
          required={editable}
        />
      </CardContent>
    </Card>
  );
};
