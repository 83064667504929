import { make } from './Model';
import { EventType } from './EventType';

export class MemberEventsSummaryCount {
  count = 0;

  type: EventType;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.type = make(obj.type, EventType);
  }
}

export default MemberEventsSummaryCount;
