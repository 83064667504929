import { Enum } from './Enum';

export class MemberEligibilityError extends Enum {
  translationKey = 'memberEligibilityError';

  static asArray: MemberEligibilityError[] = [];

  static byKey: { [name: string]: MemberEligibilityError } = {};

  static readonly C04 = new MemberEligibilityError('C04');

  static readonly C15 = new MemberEligibilityError('C15');

  static readonly C33 = new MemberEligibilityError('C33');

  static readonly C35 = new MemberEligibilityError('C35');

  static readonly C41 = new MemberEligibilityError('C41');

  static readonly C42 = new MemberEligibilityError('C42');

  static readonly C43 = new MemberEligibilityError('C43');

  static readonly C44 = new MemberEligibilityError('C44');

  static readonly C45 = new MemberEligibilityError('C45');

  static readonly C46 = new MemberEligibilityError('C46');

  static readonly C47 = new MemberEligibilityError('C47');

  static readonly C48 = new MemberEligibilityError('C48');

  static readonly C49 = new MemberEligibilityError('C49');

  static readonly C50 = new MemberEligibilityError('C50');

  static readonly C51 = new MemberEligibilityError('C51');

  static readonly C52 = new MemberEligibilityError('C52');

  static readonly C53 = new MemberEligibilityError('C53');

  static readonly C54 = new MemberEligibilityError('C54');

  static readonly C55 = new MemberEligibilityError('C55');

  static readonly C56 = new MemberEligibilityError('C56');

  static readonly C57 = new MemberEligibilityError('C57');

  static readonly C58 = new MemberEligibilityError('C58');

  static readonly C60 = new MemberEligibilityError('C60');

  static readonly C61 = new MemberEligibilityError('C61');

  static readonly C62 = new MemberEligibilityError('C62');

  static readonly C63 = new MemberEligibilityError('C63');

  static readonly C64 = new MemberEligibilityError('C64');

  static readonly C65 = new MemberEligibilityError('C65');

  static readonly C66 = new MemberEligibilityError('C66');

  static readonly C67 = new MemberEligibilityError('C67');

  static readonly C68 = new MemberEligibilityError('C68');

  static readonly C69 = new MemberEligibilityError('C69');

  static readonly C70 = new MemberEligibilityError('C70');

  static readonly C71 = new MemberEligibilityError('C71');

  static readonly C72 = new MemberEligibilityError('C72');

  static readonly C73 = new MemberEligibilityError('C73');

  static readonly C74 = new MemberEligibilityError('C74');

  static readonly C75 = new MemberEligibilityError('C75');

  static readonly C76 = new MemberEligibilityError('C76');

  static readonly C77 = new MemberEligibilityError('C77');

  static readonly C78 = new MemberEligibilityError('C78');

  static readonly C79 = new MemberEligibilityError('C79');

  static readonly C80 = new MemberEligibilityError('C80');

  static readonly C97 = new MemberEligibilityError('C97');

  static readonly C98 = new MemberEligibilityError('C98');

  static readonly CAA = new MemberEligibilityError('CAA');

  static readonly CAE = new MemberEligibilityError('CAE');

  static readonly CAF = new MemberEligibilityError('CAF');

  static readonly CAG = new MemberEligibilityError('CAG');

  static readonly CAO = new MemberEligibilityError('CAO');

  static readonly CCI = new MemberEligibilityError('CCI');

  static readonly CE8 = new MemberEligibilityError('CE8');

  static readonly CIA = new MemberEligibilityError('CIA');

  static readonly CMA = new MemberEligibilityError('CMA');

  static readonly CT4 = new MemberEligibilityError('CT4');

  constructor(public readonly value: string) {
    super();
    MemberEligibilityError.asArray.push(this);
    MemberEligibilityError.byKey[value] = this;
  }
}

export default MemberEligibilityError;
