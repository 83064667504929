import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';

import { translate } from '@vestahealthcare/common/i18n';
import { Facility, PaginationType } from '@vestahealthcare/common/models';

import { IconButton, Panel } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import {
  createFacility,
  deleteFacility,
  fetchFacilities,
  updateFacility,
} from 'dash/src/services/FacilityServices';
import Session from 'dash/src/services/SessionServices';

import { FacilitiesTable } from './FacilitiesTable';
import FacilityEditModal from './FacilityEditModal';

const DEFAULT_PAGE_SIZE = 25;

export const FacilitiesDashboard = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [showEditFacility, setShowEditFacility] = useState<boolean>(false);

  const [editFacility, setEditFacility] = useState<Facility>();
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  const getFacilities = async () => {
    if (page && page * pageSize < facilities.length) return;

    setLoading(true);

    try {
      const { items, pagination } = await fetchFacilities({
        offset: page * pageSize,
        limit: pageSize,
      });

      if (page) {
        setFacilities([...facilities, ...items]);
      } else {
        setFacilities(items);
      }
      setPagination(pagination);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  const onAddFacility = () => {
    setEditFacility(undefined);
    setShowEditFacility(true);
  };

  useEffect(() => {
    getFacilities();
  }, [page, pageSize]);

  return (
    <Panel id="facilities-section">
      <Panel.Heading title={translate('facilities.title')}>
        <Panel.Actions>
          {Session.actingUser.canEditFacilities && (
            <IconButton
              onClick={onAddFacility}
              tooltip={translate('facilities.add')}
            >
              <AddIcon fontSize="large" />
            </IconButton>
          )}
        </Panel.Actions>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <FacilitiesTable
          defaultPageSize={DEFAULT_PAGE_SIZE}
          facilities={facilities}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
          onEdit={async (facility: Facility) => {
            setEditFacility(facility);
            setShowEditFacility(true);
          }}
          pagination={pagination}
        />
        <FacilityEditModal
          facility={editFacility}
          onClose={() => setShowEditFacility(false)}
          onDelete={async (id) => {
            try {
              await deleteFacility(id);
              await getFacilities();
            } catch (e: any) {
              showGlobalError(e as string);
              return false;
            }
            return true;
          }}
          onSubmit={async (id, params) => {
            try {
              if (id) {
                await updateFacility(id, params);
              } else {
                await createFacility(params);
              }
              await getFacilities();
            } catch (e: any) {
              if (e.type === 'badResponseType') {
                return e.errors?.length
                  ? e.errors.map((e: any) => e.message).join('; ')
                  : e.message;
              }
              showGlobalError(e as string);
              return false;
            }
            return true;
          }}
          open={showEditFacility}
        />
      </Panel.Body>
    </Panel>
  );
};

export default FacilitiesDashboard;
