export class Notification {
  id = 0;

  title = '';

  body = '';

  relativeUrl = '';

  createdAt = 0;

  seen?: boolean;

  memberId?: number;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default Notification;
