// NOTE: originally I built this as a base class for other models to extend from
//  but this ends up being very challenging to do in a type-safe manner.
import moment from 'moment';

import { DATE_FORMAT } from '../utils/constants';

// Safely instantiate nested models
export const make = (obj: any, Model: any) =>
  obj ? new Model(obj) : undefined;

export const makeMoment = (obj: any) =>
  obj ? moment(obj, DATE_FORMAT) : undefined;

export const makeUnix = (obj: any) => (obj ? moment.unix(obj) : undefined);

export const makeArray = (obj: any, model: any) =>
  obj ? obj.map((d: any) => make(d, model)) : undefined;

export const makeItems = (obj: any, model: any) =>
  obj && obj.items ? obj.items.map((d: any) => make(d, model)) : undefined;

export const makeEnums = (obj: any, enumClass: any) => {
  // parses { items: [string] } or [string]
  const list: [] = obj && (obj.items ? obj.items : obj);
  return list ? list.map((d: string) => enumClass.byKey[d]) : []; // we want as minimum an empty array
};
