import React from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { PatientSearch } from '@vestahealthcare/common/models';

interface Props {
  patients: PatientSearch[];
  searchTerm?: string;
  onClick: (patient: PatientSearch) => void;
  selectedItem?: number;
}

function renderMemberDetails(patient: PatientSearch, searchTerm?: string) {
  const memberInfo = [
    ...(patient.activeReferrals?.map((ref) =>
      ref.organization.abbr?.toUpperCase(),
    ) || []),
    patient.birthDateFormatted,
  ]
    .filter(Boolean)
    .join(', ');

  const activeReferralSearch = patient.activeReferrals?.find(
    ({ externalId }) => externalId === searchTerm?.trim(),
  );
  const idString = activeReferralSearch
    ? translate('search.externalID', { id: activeReferralSearch.externalId })
    : translate('search.dashID', { id: patient.id });

  return (
    <span className="small">{`${memberInfo} ${idString} - ${patient.status
      ?.toString()
      .toUpperCase()}`}</span>
  );
}

export const PatientSearchList = (props: Props) => {
  const { patients, onClick, selectedItem } = props;

  if (!patients.length) {
    return (
      <div className="no-results-found">
        {translate('search.noPatientsFound')}
      </div>
    );
  }

  return (
    <>
      <h4>{translate('global.patients')}</h4>
      <ul>
        {patients.map((patient, index) => (
          <li
            className={`patient-search-list-item ${
              index === selectedItem ? 'selected-item' : ''
            }`}
            data-cy={`patient-search-item-${patient.id}`}
            key={patient.id}
            onClick={() => onClick(patient)}
          >
            <span className="medium">{patient.fullName}</span>
            <br />
            {renderMemberDetails(patient, props.searchTerm)}
          </li>
        ))}
      </ul>
    </>
  );
};

export default PatientSearchList;
