import classnames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import EventNoteIcon from '@mui/icons-material/EventNote';
import InfoIcon from '@mui/icons-material/Info';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import ReportIcon from '@mui/icons-material/Report';
import FileWarningIcon from '@mui/icons-material/SdCardAlert';
import makeStyles from '@mui/styles/makeStyles';

import {
  BiometricsType,
  IssueDetailFieldType,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { EventIntervention } from '@vestahealthcare/common/models';
import { EventIssue } from '@vestahealthcare/common/models/EventIssue';
import IssueDetailValue from '@vestahealthcare/common/models/IssueDetailValue';
import { Measurement } from '@vestahealthcare/common/models/Measurement';
import {
  DATE_FORMAT,
  DATE_FORMAT_SHORT,
  DATE_FORMAT_SHORT_WITH_TIME,
  EMPTY,
  SERVER_DATE_TIME_FORMAT_ZONED,
} from '@vestahealthcare/common/utils/constants';

import {
  BackgroundColors,
  Button,
  Colors,
  Fonts,
  IconButton,
  Tooltip,
} from 'styleguide-v2';

const useStyles = makeStyles({
  '@keyframes notifySelectable': {
    '0%': {
      background: BackgroundColors.lightMint,
      borderColor: Colors.mint,
    },
    '50%': {
      background: `${Colors.blue}37`,
      borderColor: Colors.blue,
    },
    '100%': {
      background: BackgroundColors.lightMint,
      borderColor: Colors.mint,
    },
  },
  label: {
    marginBottom: '.4rem',
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
  },
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  innerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
    height: 'fit-content',
  },
  issue: {
    background: BackgroundColors.lightMint,
    border: `2px solid ${Colors.mint}`,
    borderRadius: '0.5rem',
    flex: '1 1 20rem',
    maxWidth: '25rem',
  },
  issueInvalid: {
    background: BackgroundColors.gray,
    border: `2px solid ${Colors.gray}`,
    borderRadius: '0.5rem',
    flex: '1 1 20rem',
    maxWidth: '25rem',
  },
  issueUrgent1: {
    background: `${Colors.textRed}11`,
    border: `2px solid ${Colors.textRed}`,
  },
  issueUrgent2: {
    background: `${Colors.gold}11`,
    border: `2px solid ${Colors.gold}`,
  },
  issueUrgent3: {
    background: `repeating-linear-gradient(
      135deg,
      ${BackgroundColors.lightMint} 0px,
      ${BackgroundColors.lightMint} 20px,
      ${Colors.green}22 20px,
      ${Colors.green}22 40px
      )`,
    border: `2px solid ${Colors.green}`,
  },
  header: {
    margin: '0.5rem 0.5rem 0 0.5rem',
    '&& p': {
      margin: '0',
      fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    },

    '&& span': {
      fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    },
  },
  title: {
    '&&': {
      display: 'flex',
      fontSize: `calc(${Fonts.fontSize} * 0.875)!important`,
      fontWeight: '500',
      gap: '0.25rem',
    },
  },
  interventionsCount: {
    '&:after': {
      background: Colors.iconGreen,
      borderRadius: '1rem',
      color: Colors.white,
      content: 'attr(data-count)',
      display: 'block',
      fontStyle: 'normal',
      fontWeight: 400,
      height: '1.5rem',
      lineHeight: '15px',
      marginTop: '0.125rem',
      minWidth: '1.5rem',
      padding: '1px 5px',
      textAlign: 'center',
    },
  },
  editIssue: {
    '&&': {
      height: Fonts.fontSize,
      marginLeft: 'auto',
      padding: 0,
    },
  },
  unlinkIssue: {
    animation: `$notifySelectable 500ms`,
    animationDelay: '50ms',
    animationIterationCount: 2,
    transition: 'all 300ms ease',
    cursor: 'pointer',
  },
  unlinkIssueSelected: {
    background: `${Colors.blue}37`,
    borderColor: Colors.blue,

    '& svg': {
      color: Colors.blue,
    },
  },
  tableHeader: {
    fontWeight: 500,
    paddingRight: '1rem',
  },
  tableRow: {
    verticalAlign: 'top',
    paddingBottom: '0.5rem',
  },
  buttons: {
    display: 'flex',
    gap: '0.5rem',
    justifyContent: 'flex-end',
    padding: '0.5rem',
    width: '100%',
  },
});

const getReadingColor = (readings: (Measurement | MeasurementMapped)[]) => {
  let color: 'primary' | 'error' | 'action' = 'primary';
  const validReadings = readings.filter(({ valid }) => valid);

  if (readings?.length && !validReadings.length) {
    color = 'action';
  } else if (validReadings[0].abnormal) {
    color = 'error';
  }
  return color;
};

interface MeasurementMapped extends Omit<Measurement, 'value'> {
  value: string;
}

const mergeReadings = (readings: Measurement[]) => {
  const r = readings.sort(({ createdAt: a }, { createdAt: b }) => b - a);
  const sReadings = r.filter(({ type }) => type === BiometricsType.BP_SYSTOLIC);
  if (sReadings?.length) {
    return r
      .filter(({ type }) => type !== BiometricsType.BP_SYSTOLIC)
      .map(
        ({ value, ...rest }, index) =>
          ({
            value: `${sReadings[index].value}/${value}`,
            ...rest,
          } as MeasurementMapped),
      );
  }
  return r;
};

interface SelectableIssue extends EventIssue {
  selected?: boolean;
}

type Props = {
  className?: string;
  mode?: 'normal' | 'unlink' | 'missingDetails';
  issues?: EventIssue[];
  interventions?: EventIntervention[];
  onUnlinkCancel?: () => void;
  onEditIssue?: (issue: EventIssue) => void;
  onUnlinkSubmit?: (issues: EventIssue[]) => void;
};

export const EventDetailIssues = ({
  className,
  issues,
  interventions,
  mode = 'normal',
  onEditIssue,
  onUnlinkCancel,
  onUnlinkSubmit,
}: Props) => {
  const styles = useStyles();

  const [currentIssues, setIssues] = useState<SelectableIssue[]>([]);

  useEffect(() => setIssues(issues || []), [issues]);

  useEffect(() => {
    if (mode === 'normal') {
      currentIssues.forEach((issue) => (issue.selected = false));
    }
  }, [mode]);

  const getInterventions = (id: number) =>
    interventions?.filter(({ issueId }) => issueId === id);

  const renderTooltipTable = (interventions: EventIntervention[]) => (
    <table width={550}>
      <thead>
        <th className={styles.tableHeader} style={{ width: 250 }}>
          {translate('memberEvents.interventionName')}
        </th>
        <th className={styles.tableHeader} style={{ width: 150 }}>
          {translate('memberEvents.detail.interventionStatus')}
        </th>
        <th className={styles.tableHeader} style={{ width: 150 }}>
          {translate('memberEvents.detail.interventionResults')}
        </th>
      </thead>
      <tbody>
        {interventions?.map(({ type, outcomeStatus, outcomeResult }, index) => (
          <tr key={index}>
            <td className={styles.tableRow}>{type.description}</td>
            <td className={styles.tableRow}>{outcomeStatus?.description}</td>
            <td className={styles.tableRow}>{outcomeResult?.description}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderReadingTooltip = (
    issueId: number,
    readings: (Measurement | MeasurementMapped)[],
  ) => {
    const finalReadings = [] as (Measurement | MeasurementMapped)[];
    readings.forEach((reading) => {
      const { value, type, readAt } = reading;
      const storedReading = finalReadings?.find(
        ({ readAt: storedReadAt, type }) =>
          type === BiometricsType.BP_SYSTOLIC && readAt === storedReadAt,
      );
      if (type === BiometricsType.BP_DIASTOLIC && storedReading) {
        // @ts-ignore
        storedReading.value = `${storedReading.value}/${value}`;
      } else {
        finalReadings.push({ ...reading });
      }
    });
    return (
      <table width={500}>
        <thead>
          <th className={styles.tableHeader} style={{ width: 135 }}>
            {translate('memberEvents.issues.reading')}
          </th>
          <th className={styles.tableHeader} style={{ width: 75 }}>
            {translate('memberEvents.issues.value')}
          </th>
          <th className={styles.tableHeader} style={{ width: 30 }}>
            {translate('memberEvents.issues.valid')}
          </th>
          <th className={styles.tableHeader} style={{ width: 30 }}>
            {translate('memberEvents.issues.abnormal')}
          </th>
          <th className={styles.tableHeader} style={{ width: 135 }}>
            {translate('memberEvents.issues.received')}
          </th>
        </thead>
        <tbody>
          {finalReadings.map(
            (
              {
                abnormal,
                createdAt,
                readAt,
                type,
                valid,
                value,
              }: Measurement | MeasurementMapped,
              index: number,
            ) => (
              <tr key={`issue-${issueId}-reading-${index}`}>
                <td className={styles.tableRow}>
                  {`${moment
                    .unix(readAt)
                    .format(DATE_FORMAT_SHORT_WITH_TIME)} ${moment
                    .tz(moment.tz.guess())
                    .zoneAbbr()
                    .replace(/[DS]T$/, 'T')}`}
                </td>
                <td className={styles.tableRow}>
                  {value}
                  {BiometricsType.getUnit(type)}
                </td>
                <td className={styles.tableRow}>
                  {translate(`global.${valid ? 'yes' : 'no'}`)}
                </td>
                <td className={styles.tableRow}>
                  {translate(`global.${abnormal ? 'yes' : 'no'}`)}
                </td>
                <td className={styles.tableRow}>
                  {`${moment
                    .unix(createdAt)
                    .format(DATE_FORMAT_SHORT_WITH_TIME)} ${moment
                    .tz(moment.tz.guess())
                    .zoneAbbr()
                    .replace(/[DS]T$/, 'T')}`}
                </td>
              </tr>
            ),
          )}
        </tbody>
      </table>
    );
  };

  const toggleUnlinkIssue = (issueId: number) => {
    const issue = currentIssues.find(({ id }) => id === issueId);
    if (issue) {
      issue.selected = !issue.selected;
      setIssues([...currentIssues]);
    }
  };

  const doUnlinkSubmit = () => {
    if (onUnlinkSubmit && onUnlinkCancel) {
      try {
        onUnlinkSubmit(currentIssues.filter(({ selected }) => selected));
      } finally {
        onUnlinkCancel();
      }
    }
  };

  const getUrgencyColor = (urgencyLevel?: number) => {
    if (urgencyLevel === 1) {
      return Colors.textRed;
    }
    if (urgencyLevel === 2) {
      return Colors.gold;
    }
    if (urgencyLevel === 3) {
      return Colors.green;
    }
    return Colors.gray;
  };

  return !issues?.length ? (
    <></>
  ) : (
    <div className={classnames(className, styles.container)}>
      <span className={styles.label}>Issues</span>
      <div className={styles.innerContainer}>
        {currentIssues.map((issue) => {
          const {
            createdAt,
            details,
            direction,
            id,
            issueType,
            missingDetails,
            notes,
            readings,
            reporter,
            selected,
            source,
            sourceOther,
            urgencyLevel,
            urgent,
            valid,
          } = issue;
          const dateFields = details?.filter(
            ({ issueDetailField: { fieldType }, value }) =>
              IssueDetailFieldType.DATE === fieldType && value,
          );
          const dateTimeFields = details?.filter(
            ({ issueDetailField: { fieldType }, value }) =>
              IssueDetailFieldType.DATE_TIME === fieldType && value,
          );
          const otherFields = details?.filter(
            ({ issueDetailField: { fieldType }, value }) =>
              ![
                IssueDetailFieldType.DATE,
                IssueDetailFieldType.DATE_TIME,
              ].includes(fieldType) && value,
          );
          return (
            <div
              className={classnames(
                valid ? styles.issue : styles.issueInvalid,
                mode === 'unlink' && styles.unlinkIssue,
                mode === 'unlink' && selected && styles.unlinkIssueSelected,
                mode === 'missingDetails' &&
                  missingDetails &&
                  styles.unlinkIssue,
                valid && urgent && urgencyLevel === 1 && styles.issueUrgent1,
                valid && urgent && urgencyLevel === 2 && styles.issueUrgent2,
                valid && urgent && urgencyLevel === 3 && styles.issueUrgent3,
              )}
              key={`event-issue-${id}`}
              onClick={
                mode === 'unlink' ? () => toggleUnlinkIssue(id) : undefined
              }
            >
              <div className={styles.header}>
                <span className={styles.title}>
                  {issueType.description}
                  {urgent && urgencyLevel && (
                    <Tooltip
                      text={
                        urgencyLevel
                          ? translate('memberEvents.table.urgencyLevel', {
                              count: urgencyLevel,
                            })
                          : translate('memberEvents.table.urgent')
                      }
                    >
                      <ReportIcon htmlColor={getUrgencyColor(urgencyLevel)} />
                    </Tooltip>
                  )}
                  {!missingDetails && (
                    <Tooltip
                      text={translate(
                        `memberEvents.detail.${
                          valid ? 'valid' : 'invalid'
                        }Issue`,
                      )}
                    >
                      {valid ? (
                        <CheckCircleIcon color="primary" />
                      ) : (
                        <CancelIcon color="action" />
                      )}
                    </Tooltip>
                  )}
                  {missingDetails && (
                    <Tooltip
                      text={translate(
                        'memberEvents.detail.missingFieldsTooltip',
                      )}
                    >
                      <FileWarningIcon htmlColor={Colors.textRed} />
                    </Tooltip>
                  )}
                  {!!readings?.length && (
                    <Tooltip
                      text={renderReadingTooltip(id, mergeReadings(readings))}
                    >
                      <MonitorHeartIcon
                        color={getReadingColor(mergeReadings(readings))}
                      />
                    </Tooltip>
                  )}

                  {!!notes?.length && (
                    <Tooltip
                      text={notes.map(({ note }, index) => (
                        <p
                          key={`event-issue-${id}-note-${index}`}
                          style={{ color: Colors.white, margin: 0 }}
                        >
                          {note}
                        </p>
                      ))}
                    >
                      <EventNoteIcon color={valid ? 'primary' : 'action'} />
                    </Tooltip>
                  )}
                  {!!otherFields?.length && (
                    <Tooltip
                      text={otherFields.map(
                        ({
                          value,
                          issueDetailField: {
                            id: fieldId,
                            description,
                            fieldType,
                            options,
                          },
                        }) => {
                          let result = value;
                          if (
                            fieldType === IssueDetailFieldType.SELECT ||
                            fieldType === IssueDetailFieldType.SKILLED_AGENCY
                          ) {
                            result =
                              options?.find(({ id }) => id === value)
                                ?.description || '';
                          }
                          if (fieldType === IssueDetailFieldType.MULTI_SELECT) {
                            const values = value.split(',');
                            result =
                              options
                                ?.filter(({ id }) => values.indexOf(id) !== -1)
                                ?.map(({ description }) => description)
                                .join(', ') || '';
                          }
                          if (fieldType === IssueDetailFieldType.TOGGLE) {
                            result = translate(
                              `global.${value === 'true' ? 'yes' : 'no'}`,
                            );
                          }
                          return (
                            <p
                              key={`event-issue-${id}-note-${fieldId}`}
                              style={{ color: Colors.white, margin: 0 }}
                            >
                              {description}
                              {': '}
                              {result}
                            </p>
                          );
                        },
                      )}
                    >
                      <InfoIcon color={valid ? 'primary' : 'action'} />
                    </Tooltip>
                  )}
                  {!!getInterventions(id)?.length && (
                    <Tooltip
                      text={renderTooltipTable(getInterventions(id) || [])}
                    >
                      <i
                        className={styles.interventionsCount}
                        data-count={getInterventions(id)?.length}
                      />
                    </Tooltip>
                  )}
                  {mode === 'normal' && onEditIssue && (
                    <IconButton
                      className={styles.editIssue}
                      size="small"
                      onClick={() => onEditIssue(issue)}
                      tooltip={translate('global.edit')}
                    >
                      <EditIcon color={valid ? 'primary' : 'action'} />
                    </IconButton>
                  )}
                </span>
                <p>{reporter?.fullName}</p>
                <p>
                  {`${source.description}${
                    source.isOther() && sourceOther ? ` - ${sourceOther}` : ''
                  } (${direction?.description})`}
                </p>
                <p>
                  <span>{`${translate('memberEvents.creationDate')}: `}</span>
                  <span>{createdAt?.format(DATE_FORMAT_SHORT_WITH_TIME)}</span>
                </p>
                {dateTimeFields?.map(
                  ({
                    value,
                    issueDetailField: { id: fieldId, description },
                  }) => (
                    <p key={`issue-${id}-detail-field-${fieldId}`}>
                      <span>{description}</span>:&nbsp;
                      <span>
                        {!value && EMPTY}
                        {value &&
                          (IssueDetailValue.hasTime(
                            IssueDetailFieldType.DATE_TIME,
                            value,
                          )
                            ? moment(
                                value,
                                SERVER_DATE_TIME_FORMAT_ZONED,
                              ).format(DATE_FORMAT_SHORT_WITH_TIME)
                            : moment(value, DATE_FORMAT).format(
                                DATE_FORMAT_SHORT,
                              ))}
                      </span>
                    </p>
                  ),
                )}
                {dateFields?.map(
                  ({
                    value,
                    issueDetailField: { id: fieldId, description },
                  }) => (
                    <p key={`issue-${id}-detail-field-${fieldId}`}>
                      <span>{description}</span>:&nbsp;
                      <span>
                        {value
                          ? moment(value, DATE_FORMAT).format(DATE_FORMAT_SHORT)
                          : EMPTY}
                      </span>
                    </p>
                  ),
                )}
              </div>
            </div>
          );
        })}
      </div>
      {mode === 'unlink' && onUnlinkCancel && doUnlinkSubmit && (
        <div className={styles.buttons}>
          <Button
            color="tertiary"
            data-cy="unlink-event-issue-close"
            onClick={onUnlinkCancel}
          >
            {translate('global.cancel')}
          </Button>
          <Button
            color="secondary"
            data-cy="unlink-event-issue-submit"
            disabled={
              !currentIssues.find(({ selected }) => selected) ||
              !currentIssues.find(({ selected }) => !selected)
            }
            onClick={doUnlinkSubmit}
          >
            {translate('memberEvents.createEvent')}
          </Button>
        </div>
      )}
    </div>
  );
};
export default EventDetailIssues;
