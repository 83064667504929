import React, { useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';
import moment from '@vestahealthcare/common/moment';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { DateTimePicker } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { updateStartDate } from 'dash/src/services/PatientServices';

import { BaseModal } from './BaseModal';

interface Props {
  patient: Patient;
  onSubmit: (patient: Patient) => void;
}

const renderDetails = (patient: Patient) => (
  <>
    <h4 className="grid-span-5">{translate('personalDetails.startDate')}</h4>
    <p className="grid-span-6">
      {patient.reportingStartDate
        ? moment(patient.reportingStartDate).format('MMM Do, YYYY')
        : EMPTY}
    </p>
  </>
);

export const EditStartDate = React.memo((props: Props) => {
  const { patient, onSubmit } = props;
  const [date, setDate] = useState<Date | undefined>(
    moment(patient.reportingStartDate).toDate(),
  );
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    let result = true;

    setSubmitted(true);

    const currentDate = moment(date?.getTime()).zone(0);
    if (!date || !currentDate.isValid()) {
      return false;
    }
    setLoading(true);

    try {
      const newPatient = await updateStartDate(patient.id, currentDate);
      onSubmit(newPatient);
      setSubmitted(false);
    } catch (e) {
      showGlobalError(e as string);
      result = false;
    }

    setLoading(false);
    return result;
  };

  return (
    <>
      <BaseModal
        data-cy="patient-start-date"
        details={renderDetails(patient)}
        editable={patient.isEditable()}
        loadingSubmit={loading}
        onSubmit={submit}
        title={translate('personalDetails.startedReporting')}
      >
        <DateTimePicker
          error={submitted && !date}
          label={translate('personalDetails.startDate')}
          onChange={setDate}
          placeholder={translate('global.chooseADate')}
          required
          value={date}
        />
      </BaseModal>
    </>
  );
});
