import React from 'react';

import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

interface Props {
  className?: string;
  'data-cy'?: string;
  value: string;
  isPrimary?: boolean;
}

export const PhoneNumberText = (props: Props) => {
  const { className, 'data-cy': dataCy, value, isPrimary, ...rest } = props;
  if (!value) {
    return <span className={className} data-cy={dataCy} />;
  }
  let prettyString = value;
  try {
    // country code here is used whenever there's no +country code
    // In the future, we should store all numbers in +1-AAAPPPNNNN format
    // After that, we should decide how we display US vs non-US numbers.
    // I believe this does NOT validate area codes, for instance.
    // it will be fine if the number is +another_country (and then we can use null instead)
    prettyString = phoneUtil.format(
      phoneUtil.parse(value, 'US'),
      PhoneNumberFormat.NATIONAL,
    );
  } catch (e) {
    // nothing
  }

  return (
    <>
      {isPrimary ? <span>*</span> : ''}
      <a className={className} data-cy={dataCy} href={`tel:${value}`} {...rest}>
        {prettyString}
      </a>
    </>
  );
};

export default PhoneNumberText;
