import React, { useState, useEffect } from 'react';
import ReactTable, { CellInfo, Filter, TableProps } from 'react-table';
import classnames from 'classnames';
import { defaultPaginationComponent } from './Pagination';
import { CustomColumn } from './Column';
import { Checkbox, Radio } from 'styleguide';
import './main.less';

const RADIO = 'radio';
const MULTI = 'multi';
type Selectable = typeof RADIO | typeof MULTI;

interface Props extends Partial<TableProps> {
  columnHider?: boolean;
  columns: CustomColumn[];
  onSelect?: (selections: any[], changed: any) => void;
  selectable?: Selectable;
  selections?: any[];
  striped?: boolean;
}

export const SelectionCell = ({ row, rowSelections, onChange, type }: any) => {
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    setIsChecked(rowSelections.has(row.original.id));
  }, [row]);

  const props = {
    checked: isChecked,
    onChange: () => {
      setIsChecked(!isChecked);
      onChange(row);
    },
  };

  if (type === RADIO) {
    return <Radio {...props} />;
  }
  return <Checkbox {...props} />;
};

// export const MassSelectionHeader = ({ data, rowSelections, onChange }: any) => {
//   const [isChecked, setIsChecked] = useState(data ? data.length === rowSelections.size : false);
//   return (
//     <Checkbox
//       checked={isChecked}
//       onChange={() => {
//         setIsChecked(!isChecked);
//         onChange(!isChecked);
//       }}
//     />
//   );
// };

export const DataTable = (props: Props) => {
  const {
    columnHider,
    columns,
    data = [],
    defaultFilterMethod = (filter: Filter, row: any) =>
      String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase()),
    defaultPageSize = 25,
    filterable = false,
    onSelect,
    PaginationComponent = defaultPaginationComponent,
    selectable,
    pageSize,
    selections = [],
    striped = false,
    ...otherProps
  } = props;

  const classes = classnames('data-table', {
    '-striped': striped,
  });

  const visibleColumns = columns.filter((col) => !col.hidden);

  if (selectable) {
    const [rowSelections, setRowSelections] = useState(new Set());

    useEffect(() => {
      setRowSelections(new Set(selections));
    }, [data, selections, selectable]);

    const onChange = (row: CellInfo) => {
      const { id } = row.original;
      if (selectable === MULTI) {
        rowSelections.has(id)
          ? rowSelections.delete(id)
          : rowSelections.add(id);
      } else {
        rowSelections.clear();
        rowSelections.add(id);
      }
      setRowSelections(rowSelections);
      if (onSelect) {
        onSelect(Array.from(rowSelections), id);
      }
    };

    const selectCol: CustomColumn = {
      // TODO: More work needed for this to behave correctly
      // Header: (row) => {
      //   if (selectable === RADIO) return null;
      //   return (
      //     <MassSelectionHeader
      //       data={data}
      //       onChange={(allSelected: boolean) => setRowSelections(new Set(allSelected
      //         ? data.map((val) => val.id)
      //         : []))}
      //       rowSelections={rowSelections}
      //     />
      //   );
      // },
      Cell: (row) => (
        <SelectionCell
          onChange={onChange}
          row={row}
          rowSelections={rowSelections}
          type={selectable}
        />
      ),
      width: 70,
    };
    visibleColumns.unshift(selectCol);
  }

  return (
    <ReactTable
      pageSize={pageSize}
      className={classes}
      columns={visibleColumns}
      data={data}
      defaultFilterMethod={defaultFilterMethod}
      defaultPageSize={defaultPageSize}
      filterable={filterable}
      PaginationComponent={PaginationComponent}
      {...otherProps}
    />
  );
};

export default DataTable;
