import React, { ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';

export type ActionType = {
  icon: ReactNode;
  label: string;
  value: string | number;
};

export type Props = {
  actions: ActionType[];
  label: ReactNode;
  onClick: (action: ActionType) => void;
};

const useStyles = makeStyles({
  dial: {
    '&& > button': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      color: 'transparent',
      display: 'block',
      position: 'inherit',
      textTransform: 'inherit',
      transition: 'none!important',
      width: 'max-content',
      '& > *': {
        width: 'max-content',
      },
    },
  },
});

export const FloatingActions = ({ actions, label, onClick }: Props) => {
  const styles = useStyles();
  return (
    <>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        className={styles.dial}
        icon={label}
        direction="right"
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.label}
            icon={action.icon}
            tooltipTitle={action.label}
            tooltipPlacement="bottom"
            onClick={() => onClick(action)}
          />
        ))}
      </SpeedDial>
    </>
  );
};
