import { Enum } from './Enum';

export class TimeUnit extends Enum {
  translationKey = 'timeUnit';

  static asArray: TimeUnit[] = [];

  static byKey: { [name: string]: TimeUnit } = {};

  static readonly HOURS = new TimeUnit('HOURS');

  static readonly DAYS = new TimeUnit('DAYS');

  constructor(public readonly value: string) {
    super();
    TimeUnit.asArray.push(this);
    TimeUnit.byKey[value] = this;
  }
}

export default TimeUnit;
