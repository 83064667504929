import { BaseEnum } from './BaseEnum';

export class TOCStatus extends BaseEnum {
  static DISCHARGED = 'DISCHARGED';

  static INPATIENT = 'INPATIENT';

  static HANDOFF = 'HANDOFF';

  static REHAB = 'REHAB';

  static CLOSED = 'CLOSED';

  static INVALID = 'INVALID';

  get isClosed() {
    return [TOCStatus.CLOSED].includes(this.id);
  }

  static getColor(status: TOCStatus | string) {
    const value = typeof status === 'string' ? status : status.id;
    switch (value) {
      case TOCStatus.INVALID:
        return 'closed';
      case TOCStatus.CLOSED:
        return 'success';
      default:
        return 'warning';
    }
  }
}

export default TOCStatus;
