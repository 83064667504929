import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { EventReporterType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  CareQuestion,
  CareTeamMember,
  Patient,
  SourceChannel,
} from '@vestahealthcare/common/models';

import { Button, CollapsableSidebar, Select, Spinner } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { QuestionFields } from 'dash/src/components/QuestionFields';
import { CareTeamQuickAddModal } from 'dash/src/pages/CareTeam/modals/CareTeamQuickAdd';
import { EventReporter } from 'dash/src/pages/MemberProfile/Events/components/EventReporter';
import { onCareTeamMembersUpdated } from 'dash/src/redux/slices/MemberInfoSlice';
import { CacheServices } from 'dash/src/services';
import {
  fetchMemberCareTeamMembers,
  quickAddCareTeam,
} from 'dash/src/services/CareTeamServices';
import {
  PostHealthCheckInQuestionParams,
  fetchHealthCheckInQuestions,
} from 'dash/src/services/PatientServices';

interface Props {
  isSubmitting: boolean;
  onCancel: () => void;
  onSubmit: (
    reporterType: EventReporterType,
    reporter: Patient | CareTeamMember,
    params: PostHealthCheckInQuestionParams,
  ) => void;
  open: boolean;
  patient?: Patient;
}

export const HealthReportAdd = ({
  onCancel,
  onSubmit,
  open,
  patient,
}: Props) => {
  const dispatch = useDispatch();

  const [questions, setQuestions] = useState<CareQuestion[]>();

  const [data, setData] = useState<{ [key: string]: any }>({});
  const [fields, setFields] = useState<{ id: string; valid?: boolean }[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loadingQuestions, setLoadingQuestions] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [openAddCTM, setOpenAddCTM] = useState<boolean>(false);

  const [reporterType, setReporterType] = useState(EventReporterType.MEMBER);
  const [careTeamMember, setCareTeamMember] = useState<CareTeamMember>();

  const [methods, setMethods] = useState<BaseEnum[]>([]);
  const [directions, setDirections] = useState<BaseEnum[]>([]);

  const [method, setMethod] = useState<SourceChannel>();
  const [direction, setDirection] = useState<BaseEnum>();

  const getInitialData = async () => {
    setLoadingData(true);
    const [methods, directions] = await Promise.all([
      CacheServices.getSourceChannels(),
      CacheServices.getSourceChannelDirections(),
    ]);
    setMethods(methods.filter(({ manual }) => manual));
    setDirections(directions);
    setLoadingData(false);
  };

  useEffect(() => {
    if (open && (!methods.length || !directions.length)) {
      getInitialData();
    }
  }, [open]);

  const getHealthReportSchema = async () => {
    if (!patient) return;

    const reporterId =
      reporterType === EventReporterType.CARE_TEAM
        ? careTeamMember?.id
        : patient.id;

    let promise: Promise<CareQuestion[]>;
    if (reporterId) {
      promise = fetchHealthCheckInQuestions(
        patient.id,
        reporterType,
        reporterId,
      );
    } else {
      return;
    }

    setLoadingQuestions(true);
    const healthCheckinQuestions = await promise;
    setQuestions(healthCheckinQuestions);
    setTimeout(() => {
      setLoadingQuestions(false);
    }, 300);
  };

  useEffect(() => {
    if (open) {
      setData({});
      getHealthReportSchema();
    }
  }, [open, reporterType, careTeamMember]);

  useEffect(() => {
    setData({});
    setFields([]);
    setQuestions(undefined);
    setLoading(false);
    setMethod(undefined);
    setDirection(undefined);
    setSubmitted(false);
  }, [open]);

  const validate = (fields: { id: string; valid?: boolean }[]) =>
    (fields || []).reduce((acc, { valid }) => acc && valid !== false, true);

  const doSubmit = async () => {
    setSubmitted(true);
    if (
      !patient ||
      (reporterType === EventReporterType.CARE_TEAM && !careTeamMember) ||
      !method ||
      !direction ||
      !validate(fields)
    )
      return;

    setLoading(true);

    const newReporter =
      reporterType === EventReporterType.CARE_TEAM ? careTeamMember : patient;
    if (newReporter) {
      await onSubmit(reporterType, newReporter, {
        channel: method,
        direction,
        questions: data,
      });
    }

    setLoading(false);
  };

  return (
    <>
      <CollapsableSidebar
        title={<h2>{translate('healthReports.add')}</h2>}
        onClose={onCancel}
        open={open}
        size={550}
      >
        <CollapsableSidebar.Body>
          <div className="grid-wrapper fit">
            <EventReporter
              addCareTeamMember
              className="grid-span-12"
              onAddCareTeamMember={() => setOpenAddCTM(true)}
              onChange={(type, reporter) => {
                setReporterType(type);
                if (type === EventReporterType.CARE_TEAM) {
                  setCareTeamMember(reporter as CareTeamMember);
                }
              }}
              patient={patient}
              submitted={submitted}
            />
            <Select
              className="grid-span-6"
              data-cy="health-alert-method"
              error={submitted && !method}
              getItemLabel={({ description }) => description}
              items={methods}
              label={translate('healthReports.method')}
              loading={loadingData}
              onChange={setMethod}
              value={method}
              required
            />
            <Select
              className="grid-span-6"
              data-cy="health-alert-direction"
              error={submitted && !direction}
              getItemLabel={({ description }) => description}
              items={directions}
              label={translate('healthReports.direction')}
              loading={loadingData}
              onChange={setDirection}
              value={direction}
              required
            />
            {questions && !loadingQuestions && (
              <QuestionFields
                className="grid-span-12"
                onChange={(fields, data) => {
                  setFields(fields);
                  setData(data);
                }}
                questions={questions}
              />
            )}
          </div>
          {loadingQuestions && (
            <div className="flex" style={{ margin: 'auto' }}>
              <Spinner width={48} color="primary" />
            </div>
          )}
        </CollapsableSidebar.Body>
        {questions && !loadingQuestions && (
          <CollapsableSidebar.Buttons>
            <Button
              color="tertiary"
              data-cy="health-checkin-close"
              onClick={onCancel}
            >
              {translate('global.close')}
            </Button>
            <Button
              color="secondary"
              data-cy="health-checkin-submit"
              loading={loading}
              onClick={doSubmit}
            >
              {translate('global.add')}
            </Button>
          </CollapsableSidebar.Buttons>
        )}
      </CollapsableSidebar>
      <CareTeamQuickAddModal
        hideExtraButtons
        open={openAddCTM}
        onClose={() => setOpenAddCTM(false)}
        onSubmit={async (params) => {
          let ctm: CareTeamMember | undefined;
          try {
            if (patient) {
              ctm = await quickAddCareTeam(params);
              const { items } = await fetchMemberCareTeamMembers(patient.id);
              dispatch(onCareTeamMembersUpdated(items));
              setOpenAddCTM(false);
            }
          } catch (e) {
            showGlobalError(e as string);
          }
          return ctm?.careTeamPerson || false;
        }}
        selectedMember={patient}
      />
    </>
  );
};

export default HealthReportAdd;
