export class Account {
  canCreateMembers = false;

  createdAt = 0;

  enabled = false;

  name = '';

  id = 0;

  updatedAt = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default Account;
