import moment, { Moment } from 'moment';

import {
  MemberAllergy,
  MemberCarePlan,
  MemberDiagnostic,
  MemberMedication,
} from '@vestahealthcare/common/models';
import { makeMoment } from '@vestahealthcare/common/models/Model';

import Api from 'dash/src/services/Api';

const LAST_UPDATED = moment('8:30Z', 'hh:mmZ');

export type MemberAllergiesResponse = {
  items: MemberAllergy[];
  nkda: boolean;
  updatedAt: Moment;
};

export const fetchMemberAllergies = async (
  memberId: number | string,
): Promise<MemberAllergiesResponse> => {
  const {
    responseAllergies: { memberAllergies, nkda, updatedAt },
  } = await Api.getv2(`/patients/${memberId}/ecw/allergies`);
  return {
    items: memberAllergies.map((i: any) => new MemberAllergy(i)),
    nkda,
    updatedAt: updatedAt ? moment.unix(updatedAt) : LAST_UPDATED,
  };
};

export type MemberDiagnosticsResponse = {
  items: MemberDiagnostic[];
  nkp: boolean;
  updatedAt: Moment;
};

export const fetchMemberDiagnostics = async (
  memberId: number | string,
): Promise<MemberDiagnosticsResponse> => {
  const {
    responseDiagnostics: { memberDiagnostics, nkp, updatedAt },
  } = await Api.getv2(`/patients/${memberId}/ecw/diagnostics`);
  return {
    items: memberDiagnostics.map((i: any) => new MemberDiagnostic(i)),
    nkp,
    updatedAt: updatedAt ? moment.unix(updatedAt) : LAST_UPDATED,
  };
};

export type MemberMedicationsResponse = {
  items: MemberMedication[];
  updatedAt: Moment;
};

export const fetchMemberMedications = async (
  memberId: number | string,
): Promise<MemberMedicationsResponse> => {
  const {
    responseMedications: { memberMedications, updatedAt },
  } = await Api.getv2(`/patients/${memberId}/ecw/medications`);
  return {
    items: memberMedications.map((i: any) => new MemberMedication(i)),
    updatedAt: updatedAt ? moment.unix(updatedAt) : LAST_UPDATED,
  };
};

export type MemberCarePlanResponse = {
  items: MemberCarePlan[];
  updatedAt: Moment;
  updatedBy?: string;
};

export const fetchMemberCarePlan = async (
  memberId: number | string,
): Promise<MemberCarePlanResponse> => {
  const { responseCarePlanDetails } = await Api.getv2(
    `/patients/${memberId}/ecw/care-plan-details`,
    {},
    {
      omitModal: true,
    },
  );

  if (!responseCarePlanDetails?.problems?.length) {
    return { items: [], updatedAt: LAST_UPDATED, updatedBy: '' };
  }

  const { problems, updatedAt, updatedBy } = responseCarePlanDetails;
  return {
    items: (problems || []).map((i: any) => new MemberCarePlan(i)),
    updatedAt: makeMoment(updatedAt) || LAST_UPDATED,
    updatedBy,
  };
};
