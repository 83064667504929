import React, { Component } from 'react';
import { CareTrack, Patient } from '@vestahealthcare/common/models';
import { Table, Section, Toast, CollapseIcon, ButtonSelect } from 'styleguide';
import { translate } from '@vestahealthcare/common/i18n';
import RiskCriteriaTable from './RiskCriteriaTable';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  CareTrackType,
  RiskLevel,
  CareTrackSource,
} from '@vestahealthcare/common/enums';
import RiskLevelButtonModal from './RiskLevelButtonModal';
import { setRiskLevel } from 'dash/src/services/PHIPServices';
import { EMPTY } from '@vestahealthcare/common/utils/constants';
import { MemberProfileContext } from 'dash/src/contexts/MemberProfileContext';
import Session from 'dash/src/services/SessionServices';
import { ButtonColor } from 'styleguide/src/components/Button';

type Params = {
  careTrack?: string;
  patientId: string;
};

interface Props {
  onSubmit: Function;
  careTrack: CareTrack;
}

interface State {
  collapsed: boolean;
  showRiskCriteria: boolean;
}

export class CareTrackSection extends Component<
  Props & RouteComponentProps<Params>
> {
  static contextType = MemberProfileContext;

  state: State = {
    collapsed: true,
    showRiskCriteria: false,
  };

  renderRiskCriteria() {
    const { careTrack } = this.props;
    const { showRiskCriteria } = this.state;

    // Social pathway doesn't have criteria yet
    if (careTrack.type === CareTrackType.SOCIAL) {
      return;
    }

    return (
      <>
        <button
          className="h6 show-criteria-btn"
          onClick={() => this.setState({ showRiskCriteria: !showRiskCriteria })}
          type="button"
        >
          <CollapseIcon active={showRiskCriteria} className="margin-left" />
          {showRiskCriteria
            ? translate('riskProfile.hideCriteria')
            : translate('riskProfile.seeCriteria')}
        </button>
        {showRiskCriteria && <RiskCriteriaTable careTrack={careTrack} />}
      </>
    );
  }

  submitRiskLevel = async ({
    evaluationNote,
    riskLevel,
    sources,
  }: {
    evaluationNote?: string;
    riskLevel: RiskLevel;
    sources?: CareTrackSource[];
  }) => {
    const {
      match: { params },
      careTrack,
      history,
    } = this.props;
    const newCareTrack = await setRiskLevel({
      evaluationNote,
      riskLevel,
      careTrack,
      sources,
      patientId: params.patientId,
    });

    // NOTE: There is business logic in the backend that automatically sets the owner
    // of the patient to the current user, if the patient does not have an owner.
    // Since this dictates permissions around the buttons, we need the patient to be
    // updated on the front-end immediately.
    // TODO: In the future, we should implement such updates manually on the front-end
    if (!this.context.patient.owner) {
      await this.context.updatePatient();
    }

    new Toast(getToastOptions(careTrack, newCareTrack));
    history.push(
      `/patients/${
        params.patientId
      }/phip-profile/${careTrack.type.valueOf().toLowerCase()}`,
    );
    this.props.onSubmit(newCareTrack);
  };

  toggleCollapsed = async () => {
    const { collapsed } = this.state;
    this.setState({ collapsed: !collapsed });
  };

  render() {
    const { careTrack } = this.props;
    const { riskLevel } = careTrack;
    const { patient }: { patient: Patient } = this.context;
    const { actingUser } = Session;

    const showSetRiskLevel =
      patient.isEditable() &&
      careTrack.type !== CareTrackType.SOCIAL &&
      actingUser.canEditPHIP;

    return (
      <Section data-cy="care-track-section" collapsed={this.state.collapsed}>
        <Section.Heading onClick={this.toggleCollapsed}>
          <Table className="section-style">
            <thead>
              <tr>
                <th style={{ width: '25%' }}>
                  <span className="h3" data-cy="care-track-name">
                    {careTrack.type.toString()}
                  </span>
                </th>
                <th style={{ width: '25%' }}>
                  <span
                    className="h3 margin-right"
                    data-cy="care-track-risk-level"
                  >
                    {careTrack.isEvaluated && riskLevel.toString()}
                  </span>
                </th>
                <th>
                  {careTrack.isEvaluated && (
                    <span className="small" data-cy="care-track-updated-stamp">
                      {translate('global.lastUpdated', {
                        date: careTrack.getUpdatedAtFormatted(),
                      })}
                      <br />
                      {careTrack.updatedBy && careTrack.updatedBy.fullName}
                    </span>
                  )}
                </th>
                <th className="text-right" onClick={(e) => e.stopPropagation()}>
                  {showSetRiskLevel && (
                    <div style={{ display: 'flex', gap: 10 }}>
                      <ButtonSelect
                        dataCy="risk-level-inline"
                        className="grid-span-4"
                        color={
                          RiskLevel.COLOR_MAP[riskLevel.value] as ButtonColor
                        }
                        onChange={(riskLevel: string) =>
                          this.submitRiskLevel({
                            riskLevel: RiskLevel.byKey[riskLevel],
                          })
                        }
                        options={careTrack.selectableRiskLevels}
                        value={
                          careTrack.isEvaluated
                            ? careTrack.riskLevel.value
                            : RiskLevel.NONE.value
                        }
                      />
                      <RiskLevelButtonModal
                        data-cy="set-update-risk-level-button-in-line"
                        onSubmit={this.submitRiskLevel}
                        careTrack={careTrack}
                      />
                    </div>
                  )}
                </th>
              </tr>
            </thead>
          </Table>
        </Section.Heading>
        <Section.Body>
          <article className="grid-wrapper-short">
            <div className="grid-span-6 h7 no-margin">
              {translate('riskProfile.summary')}
            </div>
            <div className="grid-span-12 grid-wrapper-tall">
              <div className="grid-span-12">
                <h6>{translate('riskProfile.riskLevel')}</h6>
                <p className="medium no-margin">
                  {careTrack.isEvaluated
                    ? riskLevel.toString()
                    : translate('riskProfile.noLevelSet')}
                  {this.renderRiskCriteria()}
                </p>
                {renderNotes(careTrack)}
              </div>
              {renderObservations(careTrack)}
            </div>
          </article>
        </Section.Body>
      </Section>
    );
  }
}

const renderNotes = (careTrack: CareTrack) => {
  const { evaluationNote, sources } = careTrack;
  if (!evaluationNote && !careTrack.hasSources) return <></>;
  return (
    <p className="no-margin">
      {sources && sources.map((source) => source.toString()).join(', ')}
      {careTrack.hasSources && evaluationNote && ' - '}
      {evaluationNote}
    </p>
  );
};

const renderObservations = (careTrack: CareTrack) =>
  careTrack.observations.map((observation) => (
    <div className="grid-span-6 care-track-observations" key={observation.key}>
      <h6>{observation.title}</h6>
      <p>
        {observation.answers.length ? observation.answers.join(', ') : EMPTY}
      </p>
    </div>
  ));

export const getToastOptions = (
  oldCareTrack: CareTrack,
  newCareTrack: CareTrack,
) => {
  let title;
  let body;
  if (oldCareTrack.isEvaluated) {
    title = translate('riskProfile.riskLevelUpdated', {
      careTrack: newCareTrack.type.toString(),
    });
  } else {
    title = translate('riskProfile.riskLevelSet', {
      careTrack: newCareTrack.type.toString(),
    });
  }
  if (newCareTrack.isActive) {
    if (!oldCareTrack.isActive || !oldCareTrack.isEvaluated) {
      body = translate('riskProfile.nowEvaluateInterventions');
    }
  }
  return {
    title,
    body,
  };
};

// this (props: any) => ...props is to stop the context provider from gobbling up the router props
export default withRouter((props: any) => <CareTrackSection {...props} />);
