import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Language, TaskDefinitionStatus } from '@vestahealthcare/common/enums';
import TaskStatus from '@vestahealthcare/common/enums/TaskStatus';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  Organization,
  Task,
} from '@vestahealthcare/common/models';
import TaskDefinition from '@vestahealthcare/common/models/TaskDefinition';

import {
  Button,
  CollapseIcon,
  DateTimePicker,
  Panel,
  Select,
  TextInput,
} from 'styleguide';
import { CheckboxWithLabel } from 'styleguide-v2';

import TasksTable from 'dash/src/pages/Tasks/TasksTable';
import { CacheServices } from 'dash/src/services';
import { fetchSupportedLanguages } from 'dash/src/services/LanguageServices';
import Session from 'dash/src/services/SessionServices';
import {
  fetchAllTasks,
  fetchTaskDefinitions,
} from 'dash/src/services/TaskServices';
import { FilterHandler } from 'dash/src/utils/personalFiltersHandler';
import { isEqual } from 'lodash';

import AddTaskButton from './AddTaskButton';

type DateRange = [Moment | undefined, Moment | undefined];

// Just a key/value object to help with sorting
const sortingColumns: { [key: string]: keyof Task | 'tt.name' } = {
  status: 'status',
  description: 'tt.name',
  dueDate: 'dueDate',
  completed: 'completedAt',
  createdAt: 'createdAt',
};
const datePresets: { [keyof: string]: (() => DateRange) | null } = {
  last7Days: () => [
    moment().subtract('7', 'days').startOf('day'),
    moment().endOf('day'),
  ],
  last30Days: () => [
    moment().subtract('30', 'days').startOf('day'),
    moment().endOf('day'),
  ],
  last365Days: () => [
    moment().subtract('365', 'days').startOf('day'),
    moment().endOf('day'),
  ],
  custom: null,
};

type DatePreset = keyof typeof datePresets;

export const DateRangePicker = ({
  value,
  onChange,
  label,
  futureSearch = false,
}: {
  value: DateRange;
  onChange: (value: DateRange) => void;
  label: string;
  futureSearch?: boolean;
}) => {
  const [preset, setPreset] = useState<DatePreset>('last30Days');

  const doChange = (preset: DatePreset | DateRange) => {
    if (!preset) return;

    if (typeof preset === 'string') {
      datePresets[preset] && onChange(datePresets[preset]!());
      setPreset(preset);
    } else {
      onChange(preset as DateRange);
      setPreset('custom');
    }
  };

  return (
    <>
      <Select
        label={label}
        value={preset}
        options={Object.keys(datePresets).map((option) => ({
          label: translate(`tasks.dateRangePicker.${option}`),
          value: option,
          disabled: !datePresets[option],
        }))}
        onChange={doChange}
        columns={3}
      />
      <DateTimePicker
        label={translate(`tasks.dueDateFrom`)}
        value={value[0]}
        onChange={(date) => date && doChange([date, value[1]])}
        max={value[1] || moment()}
        columns={3}
      />
      <DateTimePicker
        label={translate(`tasks.dueDateTo`)}
        value={value[1]}
        onChange={(date) => date && doChange([value[0], date])}
        min={value[0]}
        max={futureSearch ? moment() : undefined}
        columns={3}
      />
    </>
  );
};

const ROWS_PER_PAGE = 25;

const DEFAULT_SORTING = 'dueDate asc';

export const Tasks = () => {
  const history = useHistory();
  const filterHandler = new FilterHandler<{
    statuses: string[];
    taskDefinitionIds: number[];
    employeeGroupIds: number[];
    assigneeIds: number[];
    referralSourceIds: number[];
    languageIds: string[];
    createdAtDateRange: (string | undefined)[];
    dueDateRange: (string | undefined)[];
    completedDateRange: (string | undefined)[];
    createdByIds: number[];
    showTopLevel: boolean;
    showSubtasks: boolean;
  }>('task-filter');
  const filters = filterHandler.getFilter();

  const [isLoading, setIsLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(true);

  const [tasks, setTasks] = useState<Task[]>([]);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  // selectable filters
  const [statuses, setStatuses] = useState<string[]>(filters?.statuses || []);
  const [assigneeIds, setAssigneeIds] = useState<number[]>(
    filters?.assigneeIds || [],
  );
  const [createdByIds, setCreatedByIds] = useState<number[]>(
    filters?.createdByIds || [],
  );
  const [taskDefinitionIds, setTaskDefinitionIds] = useState<number[]>(
    filters?.taskDefinitionIds || [],
  );
  const [employeeGroupIds, setEmployeeGroupIds] = useState<number[]>(
    filters?.employeeGroupIds || [],
  );

  const [dueDateRange, setDueDateRange] = useState<DateRange>(
    filters?.dueDateRange
      ? [
          filters.dueDateRange[0] ? moment(filters.dueDateRange[0]) : undefined,
          filters.dueDateRange[1] ? moment(filters.dueDateRange[1]) : undefined,
        ]
      : [undefined, undefined],
  );

  const [completedDateRange, setCompletedDateRange] = useState<DateRange>(
    filters?.completedDateRange
      ? [
          filters.completedDateRange[0]
            ? moment(filters.completedDateRange[0])
            : undefined,
          filters.completedDateRange[1]
            ? moment(filters.completedDateRange[1])
            : undefined,
        ]
      : [undefined, undefined],
  );

  const [createdAtDateRange, setCreatedAtDateRange] = useState<DateRange>(
    filters?.createdAtDateRange
      ? [
          filters.createdAtDateRange[0]
            ? moment(filters.createdAtDateRange[0])
            : undefined,
          filters.createdAtDateRange[1]
            ? moment(filters.createdAtDateRange[1])
            : undefined,
        ]
      : [undefined, undefined],
  );

  const [languageIds, setLanguageIds] = useState<string[]>(
    filters?.languageIds || [],
  );

  const [referralSourceIds, setReferralSourceIds] = useState<number[]>(
    filters?.referralSourceIds || [],
  );

  const [showTopLevel, setShowTopLevel] = useState<boolean>(
    filters?.showTopLevel || true,
  );

  const [showSubtasks, setShowSubtasks] = useState<boolean>(
    filters?.showSubtasks || false,
  );
  const [queryString, setQueryString] = useState<string | undefined>();

  const [sort, setSort] = useState<string>(DEFAULT_SORTING);

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [employeeGroups, setEmployeeGroups] = useState<EmployeeGroup[]>([]);
  const [taskDefinitions, setTaskDefinitions] = useState<TaskDefinition[]>([]);
  const [referralSources, setReferralSources] = useState<Organization[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);

  const clearAllFilters = () => {
    setStatuses([]);
    setAssigneeIds([]);
    setEmployeeGroupIds([]);
    setCreatedByIds([]);
    setTaskDefinitionIds([]);
    setReferralSourceIds([]);
    setDueDateRange([undefined, undefined]);
    setCompletedDateRange([undefined, undefined]);
    setCreatedAtDateRange([undefined, undefined]);
    setLanguageIds([]);
    setSort(DEFAULT_SORTING);
    setShowTopLevel(true);
    setShowSubtasks(false);
    setOffset(0);
    filterHandler.clearFilter();
  };

  const saveFilters = () => {
    filterHandler.setFilter({
      statuses,
      taskDefinitionIds,
      employeeGroupIds,
      assigneeIds,
      languageIds,
      referralSourceIds,
      createdAtDateRange: [
        createdAtDateRange[0]?.format(),
        createdAtDateRange[1]?.format(),
      ],
      dueDateRange: [dueDateRange[0]?.format(), dueDateRange[1]?.format()],
      completedDateRange: [
        completedDateRange[0]?.format(),
        completedDateRange[1]?.format(),
      ],
      createdByIds,
      showTopLevel,
      showSubtasks,
    });
  };

  const fetchData = async () => {
    setIsLoading(true);
    const { items, pagination } = await fetchAllTasks({
      status:
        statuses.length > 0 ? statuses : TaskStatus.getNotClosedStatuses(),
      taskDefinitionId: taskDefinitionIds,
      taskDefinitionOutcomeId: [],
      employeeGroupId: employeeGroupIds,
      memberId: [],
      assigneeId: assigneeIds,
      queryString,
      createdById: createdByIds,
      topLevelOnly: showTopLevel && showSubtasks ? false : showTopLevel,
      subTasksOnly: showTopLevel && showSubtasks ? false : showSubtasks,
      createdAtFrom: createdAtDateRange[0]?.format(),
      createdAtTo: createdAtDateRange[1]?.format(),
      dueDateFrom: dueDateRange[0]?.format(),
      dueDateTo: dueDateRange[1]?.format(),
      completedAtFrom: completedDateRange[0]?.format(),
      completedAtTo: completedDateRange[1]?.format(),
      referralSourceId: referralSourceIds,
      language: languageIds,
      offset,
      limit: ROWS_PER_PAGE,
      sort: `${sort}, position asc`,
    });
    setTasks(items);
    setTotal(pagination.total);
    saveFilters();
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      const [t, e, g, o, l] = await Promise.all([
        fetchTaskDefinitions({
          topLevelOnly: true,
          status: TaskDefinitionStatus.PUBLISHED.value,
        }),
        CacheServices.getEmployees(),
        CacheServices.getEmployeeGroupsAssignee(),
        CacheServices.getOrganizations(),
        fetchSupportedLanguages(),
      ]);
      setTaskDefinitions(t);
      setEmployees(e);
      setEmployeeGroups(g);
      setReferralSources(o);
      setLanguages(l);
    })();
  }, []);

  useEffect(() => {
    fetchData();
  }, [
    statuses,
    assigneeIds,
    createdByIds,
    taskDefinitionIds,
    employeeGroupIds,
    referralSourceIds,
    languageIds,
    dueDateRange,
    completedDateRange,
    createdAtDateRange,
    queryString,
    showTopLevel,
    showSubtasks,
    offset,
    sort,
  ]);

  const assignedTo = employees.map(({ id, fullName }) => ({
    label: fullName,
    value: id,
  }));
  assignedTo.unshift({
    label: translate('tasks.unassigned'),
    value: 0,
  });

  const sortColumns = (key: string) => {
    if (!sortingColumns[key]) return;
    const sortingKey = sortingColumns[key];
    let currentSort = sort;
    const actualIndex = currentSort.split(' ');
    let order = ' asc';
    if (actualIndex[1] === 'asc') order = ' desc';
    currentSort = sortingKey + order;
    setSort(currentSort);
  };

  return (
    <div id="page">
      <Panel>
        <Panel.Heading
          title={translate('tasks.dashboardHeader')}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div
            className="pull-right"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h3 style={{ marginRight: 10 }}>
              <button
                onClick={() => setShowFilters(!showFilters)}
                type="button"
              >
                <CollapseIcon active={showFilters} />
                {translate('global.filter')}
              </button>
            </h3>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 10,
              }}
            >
              <CheckboxWithLabel
                label={translate('tasks.showAllParents')}
                checked={showTopLevel}
                onChange={() => {
                  setShowTopLevel(!showTopLevel);
                  setOffset(0);
                }}
              />
              <CheckboxWithLabel
                label={translate('tasks.showAllSubTasks')}
                checked={showSubtasks}
                onChange={() => {
                  setShowSubtasks(!showSubtasks);
                  setOffset(0);
                }}
              />
              <CheckboxWithLabel
                label={translate('tasks.assignedToMe')}
                checked={
                  assigneeIds.length === 1 &&
                  assigneeIds.includes(Session.actingUser.id)
                }
                onChange={() => {
                  if (
                    assigneeIds.length === 1 &&
                    assigneeIds.includes(Session.actingUser.id)
                  ) {
                    setAssigneeIds([]);
                  } else {
                    setShowTopLevel(true);
                    setShowSubtasks(true);
                    setAssigneeIds([Session.actingUser.id]);
                  }
                  setOffset(0);
                }}
              />
              <CheckboxWithLabel
                label={translate('tasks.showClosed')}
                checked={isEqual(statuses, TaskStatus.getClosedStatuses())}
                onChange={() => {
                  if (isEqual(statuses, TaskStatus.getClosedStatuses()))
                    setStatuses([]);
                  else setStatuses(TaskStatus.getClosedStatuses());
                  setOffset(0);
                }}
              />
            </div>
            <AddTaskButton
              onSubmit={(memberId, taskId) =>
                history.push({
                  pathname: memberId
                    ? `/patients/${memberId}/tasks`
                    : `/dashboard/tasks/${taskId}`,
                  state: { taskId },
                })
              }
            />
          </div>
        </Panel.Heading>
        {showFilters && (
          <div
            className="panel-controls grid-wrapper-short"
            style={{ marginBottom: 15 }}
          >
            <Select
              label={translate('tasks.status')}
              options={TaskStatus.toSelectable(TaskStatus.asArray)}
              multiple
              value={statuses}
              onChange={(value: string[]) => {
                setStatuses(value);
                setOffset(0);
              }}
              columns={3}
            />
            <Select
              columns={3}
              value={taskDefinitionIds}
              onChange={(ids: number[]) => {
                setTaskDefinitionIds(ids);
                setOffset(0);
              }}
              label={translate('tasks.type')}
              multiple
              options={taskDefinitions.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
            />
            <Select
              onChange={(ids: number[]) => {
                setEmployeeGroupIds(ids);
                setOffset(0);
              }}
              label={translate('tasks.group')}
              multiple
              options={employeeGroups.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
              value={employeeGroupIds}
              columns={3}
            />
            <Select
              label={translate('tasks.assignedTo')}
              multiple
              options={assignedTo}
              onChange={(ids: number[]) => {
                setAssigneeIds(ids);
                setOffset(0);
              }}
              value={assigneeIds}
              columns={3}
            />
            <Select
              label={translate('tasks.referralSources')}
              multiple
              options={referralSources.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
              onChange={(ids: number[]) => {
                setReferralSourceIds(ids);
                setOffset(0);
              }}
              value={referralSourceIds}
              columns={6}
            />
            <Select
              label={translate('tasks.languages')}
              multiple
              options={Language.toSelectable(languages)}
              onChange={(ids: string[]) => {
                setLanguageIds(ids);
                setOffset(0);
              }}
              value={languageIds}
              columns={3}
            />
            <Select
              label={translate('tasks.createdBy')}
              multiple
              options={employees.map(({ id, fullName }) => ({
                label: fullName,
                value: id,
              }))}
              onChange={(ids: number[]) => {
                setCreatedByIds(ids);
                setOffset(0);
              }}
              value={createdByIds}
              columns={3}
            />
            <DateRangePicker
              label={translate(`tasks.dueDate`)}
              value={dueDateRange}
              onChange={(range) => {
                setDueDateRange(range);
                setOffset(0);
              }}
            />
            <div className="grid-span-1" />
            <DateRangePicker
              label={translate(`tasks.completedDate`)}
              value={completedDateRange}
              onChange={(range) => {
                setCompletedDateRange(range);
                setOffset(0);
              }}
              futureSearch
            />
            <div className="grid-span-1" />
            <DateRangePicker
              label={translate(`tasks.createdAt`)}
              value={createdAtDateRange}
              onChange={(range) => {
                setCreatedAtDateRange(range);
                setOffset(0);
              }}
            />
            <div className="grid-span-7" />
            <div className="grid-span-5 text-right">
              <Button
                data-cy="clear-alert-filters"
                className="neg-right-margin"
                color="secondary"
                type="outlined"
                onClick={() => clearAllFilters()}
              >
                {translate('global.clearFilters')}
              </Button>
            </div>
          </div>
        )}
        <Panel.Body loading={isLoading}>
          <div
            className="grid-wrapper grid-span-12"
            style={{ marginBottom: 20 }}
          >
            <div className="grid-span-8" />
            <TextInput
              onChange={(value) => {
                if (timer) {
                  clearTimeout(timer);
                }
                const t = setTimeout(() => {
                  if (!value) {
                    setQueryString(undefined);
                    setOffset(0);
                  } else if (value.length > 2) {
                    setQueryString(value);
                    setOffset(0);
                  }
                }, 300);

                setTimer(t);
              }}
              value={queryString}
              placeholder={translate('tasks.searchTasks')}
              columns={4}
            />
          </div>
          <div
            className="grid-wrapper grid-span-12"
            style={{ marginBottom: 20 }}
          >
            <div className="grid-span-8" />
          </div>
          <TasksTable
            loading={isLoading}
            tasks={tasks}
            onTaskUpdate={() => fetchData()}
            numPages={Math.ceil(total / ROWS_PER_PAGE)}
            currentPage={offset / ROWS_PER_PAGE + 1}
            onSort={(key) => {
              sortColumns(key);
              setOffset(0);
            }}
            onPrev={() => setOffset(offset - ROWS_PER_PAGE)}
            onNext={() => setOffset(offset + ROWS_PER_PAGE)}
          />
        </Panel.Body>
      </Panel>
    </div>
  );
};

export default Tasks;
