import { BaseEnum } from '.';

export class TOCAcuityLevel extends BaseEnum {
  static CRITICAL = 'CRITICAL';

  static SEVERE = 'SEVERE';

  static MODERATE = 'MODERATE';

  static LOW = 'LOW';
}

export default TOCAcuityLevel;
