import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { isValidMail } from '@vestahealthcare/common/utils/format';

import { Button, CollapsableSidebar, TextInput } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';

interface Props {
  email?: string;
  open: boolean;
  onClose: () => void;
  onEdit: (email?: string) => Promise<void>;
  title?: string;
}

export const EditEmailModal = ({
  email: defaultEmail,
  open,
  onClose,
  onEdit,
  title,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();

  const reset = () => {
    setSubmitted(false);
    setLoading(false);
    setEmail(defaultEmail);
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  const doSubmit = async () => {
    setSubmitted(true);
    if (!email || !isValidMail(email)) return;

    setLoading(true);
    try {
      await onEdit(email);
      onClose();
    } catch (e) {
      showGlobalError(e as string);
    } finally {
      setSubmitted(false);
      setLoading(false);
    }
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={<h2>{title || translate(`incidents.modals.titleAddEmail`)}</h2>}
    >
      <CollapsableSidebar.Body>
        <TextInput
          error={
            submitted &&
            (email
              ? !isValidMail(email) && translate('common.errors.invalidEmail')
              : translate('global.missingRequiredField'))
          }
          label={translate('incidents.modals.email')}
          onChange={setEmail}
          value={email}
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button color="tertiary" data-cy="edit-email-close" onClick={onClose}>
          {translate('global.cancel')}
        </Button>
        <Button
          color="secondary"
          data-cy="edit-email-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.add')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default EditEmailModal;
