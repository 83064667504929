export class EventsConfiguration {
  id = 0;

  timeGrouping = false;

  timeGroupingHours = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default EventsConfiguration;
