import classNames from 'classnames';
import React from 'react';

import { FileUpload } from 'styleguide';

import { ParsedFile, localFileParse } from './utils';

interface Props {
  className?: string;
  onFileChange: (parsedFile: ParsedFile) => void;
}

export function CSVUpload({ className, onFileChange }: Props) {
  return (
    <FileUpload
      className={classNames('grid-span-12', className)}
      onChange={async (file) => {
        const parsedFile = await localFileParse(file);
        onFileChange(parsedFile);
      }}
      accept=".csv"
    />
  );
}
