import ConsentRequest from '@vestahealthcare/common/models/ConsentRequest';
import MemberConsentAnswer from '@vestahealthcare/common/models/MemberConsentAnswer';

import Api from 'dash/src/services/Api';

export const fetchConsentRequests = async (
  audience?: 'MEMBER' | 'CAREGIVER',
): Promise<ConsentRequest[]> => {
  const { consentRequests } = await Api.getv2('consent-requests', { audience });
  return consentRequests.items.map((data: any) => new ConsentRequest(data));
};

export const fetchMemberConsentAnswers = async (
  memberId: number,
): Promise<MemberConsentAnswer[]> => {
  const { memberConsentAnswers } = await Api.getv2(
    `members/${memberId}/consent-answers`,
  );
  return memberConsentAnswers.items.map(
    (data: any) => new MemberConsentAnswer(data),
  );
};

export interface MemberConsentAnswerParam {
  answer?: string;
  consentRequestId: number;
  dateReceived?: number;
  dateSent?: number;
  notes?: string;
  type: string;
}

export const createMemberConsentAnswer = async (
  memberId: number,
  param: MemberConsentAnswerParam,
): Promise<MemberConsentAnswer> => {
  const { memberConsentAnswer } = await Api.postv2JSON(
    `members/${memberId}/consent-answers`,
    param,
  );
  return new MemberConsentAnswer(memberConsentAnswer);
};

export const updateMemberConsentAnswer = async (
  memberId: number,
  consentAnswerId: number,
  param: MemberConsentAnswerParam,
): Promise<MemberConsentAnswer> => {
  const { memberConsentAnswer } = await Api.putv2JSON(
    `members/${memberId}/consent-answers/${consentAnswerId}`,
    param,
  );
  return new MemberConsentAnswer(memberConsentAnswer);
};

export const deleteMemberConsentAnswer = async (
  memberId: number,
  consentAnswerId: number,
) => {
  await Api.deletev2(`members/${memberId}/consent-answers/${consentAnswerId}`);
};

export const fetchCaregiverConsentAnswers = async (
  caregiverId: number,
): Promise<MemberConsentAnswer[]> => {
  const { caregiverConsentAnswers } = await Api.getv2(
    `caregivers/${caregiverId}/consent-answers`,
  );
  return caregiverConsentAnswers.items.map(
    (data: any) => new MemberConsentAnswer(data),
  );
};

export const createCaregiverConsentAnswer = async (
  caregiverId: number,
  param: MemberConsentAnswerParam,
): Promise<MemberConsentAnswer> => {
  const { caregiverConsentAnswer } = await Api.postv2JSON(
    `caregivers/${caregiverId}/consent-answers`,
    param,
  );
  return new MemberConsentAnswer(caregiverConsentAnswer);
};

export const updateCaregiverConsentAnswer = async (
  caregiverId: number,
  consentAnswerId: number,
  param: MemberConsentAnswerParam,
): Promise<MemberConsentAnswer> => {
  const { caregiverConsentAnswer } = await Api.putv2JSON(
    `caregivers/${caregiverId}/consent-answers/${consentAnswerId}`,
    param,
  );
  return new MemberConsentAnswer(caregiverConsentAnswer);
};

export const deleteCaregiverConsentAnswer = async (
  caregiverId: number,
  consentAnswerId: number,
) => {
  await Api.deletev2(
    `caregivers/${caregiverId}/consent-answers/${consentAnswerId}`,
  );
};

export const fetchCTPConsentAnswers = async (
  id: number,
): Promise<MemberConsentAnswer[]> => {
  const { consentAnswers } = await Api.getv2(
    `care-team/care-team-persons/${id}/consent-answers`,
  );
  return consentAnswers.items.map((data: any) => new MemberConsentAnswer(data));
};

export const createCTPConsentAnswer = async (
  id: number,
  param: MemberConsentAnswerParam,
): Promise<MemberConsentAnswer> => {
  const { consentAnswer } = await Api.postv2JSON(
    `care-team/care-team-persons/${id}/consent-answers`,
    param,
  );
  return new MemberConsentAnswer(consentAnswer);
};

export const updateCTPConsentAnswer = async (
  id: number,
  consentAnswerId: number,
  param: MemberConsentAnswerParam,
): Promise<MemberConsentAnswer> => {
  const { consentAnswer } = await Api.putv2JSON(
    `care-team/care-team-persons/${id}/consent-answers/${consentAnswerId}`,
    param,
  );
  return new MemberConsentAnswer(consentAnswer);
};

export const deleteCTPConsentAnswer = async (
  id: number,
  consentAnswerId: number,
) => {
  await Api.deletev2(
    `care-team/care-team-persons/${id}/consent-answers/${consentAnswerId}`,
  );
};
