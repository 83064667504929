import classnames from 'classnames';
import moment, { Moment } from 'moment';
import React, {
  Fragment,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { ClassNameMap, makeStyles } from '@mui/styles';

import { BiometricsType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { BiometricsConfig } from '@vestahealthcare/common/models';
import { Measurement } from '@vestahealthcare/common/models/Measurement';
import {
  BLOOD_GLUCOSE,
  BLOOD_OXYGEN,
  BLOOD_PRESSURE,
  DATE_FORMAT_SHORT,
  FAHRENHEIT,
  HEART_RATE,
  POUNDS,
  TIME_FORMAT,
} from '@vestahealthcare/common/utils/constants';

import {
  BackgroundColors,
  Card,
  CardContent,
  Colors,
  Fonts,
  IconButton,
  Modal,
  Table,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { BIOMETRICS_ORDER } from 'dash/src/pages/MemberProfile/Biometrics';
import {
  ConfigPostParam,
  createOrUpdateBpConfig,
  getMeasurements,
} from 'dash/src/services/BiometricsServices';

import { BiometricsTableAbnormalEntries } from './BiometricsTableAbnormalEntries';
import { BiometricsTableNotes } from './BiometricsTableNotes';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: '20px',
  },
  columnTitle: {
    fontWeight: 500,
  },
  date: {
    color: Colors.iconGray,
  },
  extraInfo: {
    flex: '.3 10rem',
    '& > *': {
      margin: '1rem 0',
    },
  },
  extraInfoContainer: {
    gap: '5px',
  },
  extraInfoTitle: {
    color: Colors.iconGreen,
    fontSize: `calc(${Fonts.fontSize} * 1.25)`,
    fontWeight: 500,
  },
  extraInfoSubtitle: {
    fontWeight: 500,
  },
  evenRow: {
    '&.MuiTableCell-root.MuiTableCell-body': {
      backgroundColor: `${BackgroundColors.gray}`,
    },
  },
  pullRight: {
    textAlign: 'end',
  },
  smallTitle: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
  },
  outOfRange: {
    color: Colors.textRed,
    fontWeight: 500,
  },
  table: {
    flex: 1,
    height: 'fit-content',
  },
  icon: {
    left: '1rem',
    padding: 0,
    position: 'relative',
    top: '-0.25rem',
    width: 0,
  },
});

type Props = {
  configs?: BiometricsConfig[];
  interval: [Moment, Moment];
  patientId: number;
  onInvalidateReading: (readingId: number[]) => Promise<void>;
};

const BIOMETRIC_COLUMNS = (
  styles: ClassNameMap<'columnTitle' | 'date'>,
  getBiometricsValue: (
    t: BiometricsType | 'bp',
  ) => ({ values }: TableReading) => ReactNode,
) => [
  {
    headerName: 'Date',
    component: ({ readAt }: Measurement) => (
      <>
        <p className={styles.date}>
          {moment.unix(readAt).format(DATE_FORMAT_SHORT)}
        </p>
        <p className={styles.date}>
          {moment.unix(readAt).format(TIME_FORMAT)}{' '}
          {moment
            .tz(moment.tz.guess())
            .zoneAbbr()
            .replace(/[DS]T$/, 'T')}
        </p>
      </>
    ),
    width: 110,
  },
  {
    headerName: (
      <>
        <p className={styles.columnTitle}>{translate('biometrics.bp')}</p>
        <p className={styles.columnTitle}>({BLOOD_PRESSURE})</p>
      </>
    ),
    component: getBiometricsValue('bp'),
  },
  {
    headerName: (
      <>
        <p className={styles.columnTitle}>{BiometricsType.OXYGEN.toString()}</p>
        <p className={styles.columnTitle}>({BLOOD_OXYGEN})</p>
      </>
    ),
    component: getBiometricsValue(BiometricsType.OXYGEN),
  },
  {
    headerName: (
      <>
        <p className={styles.columnTitle}>{BiometricsType.WEIGHT.toString()}</p>
        <p className={styles.columnTitle}>({POUNDS})</p>
      </>
    ),
    component: getBiometricsValue(BiometricsType.WEIGHT),
  },
  {
    headerName: (
      <>
        <p className={styles.columnTitle}>{BiometricsType.PULSE.toString()}</p>
        <p className={styles.columnTitle}>({HEART_RATE})</p>
      </>
    ),
    component: getBiometricsValue(BiometricsType.PULSE),
  },
  {
    headerName: (
      <>
        <p className={styles.columnTitle}>
          {BiometricsType.TEMPERATURE.toString()}
        </p>
        <p className={styles.columnTitle}>({FAHRENHEIT})</p>
      </>
    ),
    component: getBiometricsValue(BiometricsType.TEMPERATURE),
  },
  {
    headerName: (
      <>
        <p className={styles.columnTitle}>
          {BiometricsType.BLOOD_SUGAR.toString()}
        </p>
        <p className={styles.columnTitle}>({BLOOD_GLUCOSE})</p>
      </>
    ),
    component: getBiometricsValue(BiometricsType.BLOOD_SUGAR),
  },
];

type CellData = {
  id: string;
  value: string;
  outOfRange?: boolean;
};

type RowMap = Map<BiometricsType | 'bp', CellData>;

type TableReading = {
  readAt: number;
  outOfRange?: boolean;
  values: RowMap;
};

export const BiometricsTable = ({
  configs,
  interval,
  patientId,
  onInvalidateReading,
}: Props) => {
  const styles = useStyles();

  const [readings, setReadings] = useState<Measurement[]>([]);
  const [readingHovered, setReadingHovered] = useState<{
    [x: string]: boolean;
  }>({});
  const [invalidateReading, setInvalidateReading] = useState<string>();
  const [transformedReadings, setTransformedReadings] = useState<
    TableReading[]
  >([]);

  const configMap = useMemo(
    () =>
      configs?.reduce((acc, config) => {
        if (config?.type) {
          acc.set(config.type, config);
        }
        return acc;
      }, new Map<BiometricsType, BiometricsConfig>()),
    [configs],
  );

  const transformReadings = (ms: Measurement[]) => {
    const transformedReadings: TableReading[] = [];
    ms.forEach(({ id, readAt, value, type }) => {
      const config = configMap?.get(type);
      const lastItem = transformedReadings[transformedReadings.length - 1];
      const outOfRange =
        (config?.lowerLimit && value < config.lowerLimit) ||
        (config?.upperLimit && value > config.upperLimit) ||
        false;

      if (
        readAt &&
        lastItem &&
        moment.unix(readAt).isSame(moment.unix(lastItem?.readAt), 'minute')
      ) {
        lastItem.values.set(type, {
          id: String(id),
          outOfRange,
          value: value.toString(),
        });
        lastItem.outOfRange = lastItem.outOfRange || outOfRange;

        const lastDiastolic = lastItem.values.get(BiometricsType.BP_DIASTOLIC);
        const lastSystolic = lastItem.values.get(BiometricsType.BP_SYSTOLIC);
        if (lastDiastolic && lastSystolic) {
          lastItem.values.set('bp', {
            id: `${lastDiastolic.id},${lastSystolic.id}`,
            outOfRange: lastDiastolic.outOfRange || lastSystolic.outOfRange,
            value: `${lastSystolic.value}/${lastDiastolic.value}`,
          });
        }
      } else {
        transformedReadings.push({
          readAt,
          outOfRange,
          values: new Map<BiometricsType | 'bp', CellData>([
            [
              type,
              {
                id: String(id),
                outOfRange,
                value: value.toString(),
              },
            ],
          ]),
        });
      }
    });

    return transformedReadings;
  };

  const getReadings = async () => {
    const newReadings = await getMeasurements(patientId, {
      from: interval[0],
      to: interval[1],
    });

    setReadings(newReadings);
    setTransformedReadings(transformReadings(newReadings.reverse()));
  };

  const getBiometricsValue = (t: BiometricsType | 'bp') => ({
    values,
  }: TableReading) => {
    const reading = values.get(t);
    const id = reading?.id || '';
    const value = reading?.value || '';
    const outOfRange = reading?.outOfRange;

    return (
      <div
        onMouseEnter={() =>
          setReadingHovered({ ...readingHovered, [id]: true })
        }
        onMouseLeave={() =>
          setReadingHovered({ ...readingHovered, [id]: false })
        }
        style={{ width: '100%' }}
      >
        <span className={outOfRange ? styles.outOfRange : ''}>{value}</span>

        {!!readingHovered[id] && (
          <IconButton
            className={styles.icon}
            size="small"
            onClick={() => setInvalidateReading(id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
        )}
      </div>
    );
  };

  useEffect(() => {
    getReadings();
  }, [interval, patientId, configs]);

  const saveNotes = async (configs: BiometricsConfig[]) => {
    try {
      await createOrUpdateBpConfig(patientId, configs as ConfigPostParam[]);
    } catch (e) {
      showGlobalError(e as string);
    }
  };

  return (
    <div className={styles.container}>
      <Table
        className={styles.table}
        config={{
          columns: BIOMETRIC_COLUMNS(styles, getBiometricsValue),
          compact: true,
          data: transformedReadings,
          getRowClass: (_, idx) => (idx % 2 === 1 ? styles.evenRow : ''),
        }}
      />
      {!!configs?.length && (
        <div className={styles.extraInfo}>
          <BiometricsTableAbnormalEntries
            configs={configs}
            measurements={readings}
          />
          <Card>
            <CardContent
              className={classnames('grid-wrapper', styles.extraInfoContainer)}
            >
              <span
                className={classnames(styles.extraInfoTitle, 'grid-span-12')}
              >
                {translate('biometrics.lastReadings')}
              </span>
              <span className={classnames(styles.smallTitle, 'grid-span-6')}>
                {translate('biometrics.type')}
              </span>
              <span
                className={classnames(
                  styles.smallTitle,
                  styles.pullRight,
                  'grid-span-6',
                )}
              >
                {translate('biometrics.daysAgoLabel')}
              </span>
              {BIOMETRICS_ORDER.map(
                (type) =>
                  configMap?.get(type)?.lastReading && (
                    <Fragment key={`biometric-lastReading-${type.toString()}`}>
                      <span
                        className={classnames(
                          'grid-span-10',
                          styles.extraInfoSubtitle,
                        )}
                      >
                        {type === BiometricsType.BP_DIASTOLIC
                          ? translate('biometrics.bp')
                          : type.toString()}
                      </span>
                      <span className="grid-span-6">
                        {type === BiometricsType.BP_DIASTOLIC
                          ? `${
                              configMap?.get(BiometricsType.BP_SYSTOLIC)
                                ?.lastReading?.value
                            }/${configMap?.get(type)?.lastReading?.value}`
                          : configMap?.get(type)?.lastReading?.value}
                        &nbsp;
                        {configMap?.get(type)?.unit}
                      </span>
                      <span
                        className={classnames(styles.pullRight, 'grid-span-6')}
                      >
                        {configMap?.get(type)?.lastReading?.readAt &&
                          translate('biometrics.daysAgo', {
                            count: moment()
                              .endOf('day')
                              .diff(
                                configMap?.get(type)?.lastReading?.readAt &&
                                  moment.unix(
                                    configMap?.get(type)?.lastReading?.readAt ||
                                      0,
                                  ),
                                'days',
                              ),
                          })}
                      </span>
                    </Fragment>
                  ),
              )}
            </CardContent>
          </Card>
          <BiometricsTableNotes configs={configs} onSave={saveNotes} />
        </div>
      )}
      <Modal
        title={translate('global.warning')}
        body={translate('biometrics.warningInvalidate')}
        onClose={() => setInvalidateReading(undefined)}
        onSubmit={async () => {
          if (invalidateReading) {
            await onInvalidateReading(invalidateReading.split(',').map(Number));
            await getReadings();
          }
        }}
        open={!!invalidateReading}
      />
    </div>
  );
};
