import { RosterFile, RosterFileMetadata } from '@vestahealthcare/common/models';
import Api from 'dash/src/services/Api';
import { PaginatedResponse } from '.';

export interface FetchParams {
  offset?: number;
  limit?: number;
  sort?: string;
  sourceOrgId?: number | number[];
  destinationOrgId?: number | number[];
  fileDateFrom?: string;
  fileDateTo?: string;
  transferDateFrom?: string;
  transferDateTo?: string;
  filename?: string;
}

interface RosterFileParams extends FetchParams {
  filename?: string;
  sort?: string;
}

export const fetchAllRosterFiles = async (
  params?: RosterFileParams,
): Promise<PaginatedResponse<RosterFile>> => {
  const {
    rosterFiles: { items, pagination },
  } = await Api.getv2(`roster-files`, params);
  return { items: items.map((i: any) => new RosterFile(i)), pagination };
};

export const downloadFileHistory = async (fileId: number, fileName: string) => {
  const blob: Blob = await Api.getv2Blob(`roster-files/${fileId}`);
  const csvURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', fileName);
  tempLink.click();
};

export const fetchAllArchiveFiles = async (
  params?: RosterFileParams,
): Promise<PaginatedResponse<RosterFileMetadata>> => {
  const {
    archiveFiles: { items, pagination },
  } = await Api.getv2(`roster-files/archive`, params);
  return {
    items: items.map((i: any) => new RosterFileMetadata(i)),
    pagination,
  };
};

export const uploadReferralFile = async (
  referral: number,
  date: string,
  file: File,
) => {
  const formData = new FormData();
  formData.append('referralSourceId', referral.toString());
  formData.append('receivedAt', date);
  formData.append('file', file);
  await Api.postv2File('roster-files', formData);
};
