import React, { useState } from 'react';

import { Search } from '@mui/icons-material';
import { Card, CardContent } from '@mui/material';

import {
  Button,
  DateTimePicker,
  Panel,
  RadioGroup,
  SearchInput,
  Select,
  SwitchGroup,
  TextArea,
  TextInput,
} from 'styleguide-v2';
import { CheckboxWithLabel } from 'styleguide-v2/src/components/CheckboxWithLabel';
import { IconButton } from 'styleguide-v2/src/components/IconButton';
import { SwitchItem } from 'styleguide-v2/src/components/Switch/SwitchGroup';

const SELECT_OPTIONS = [
  { label: 'Option 1', value: 1 },
  { label: 'Option 2', value: 2 },
  { label: 'Option 3', value: 3 },
];

const SELECT_OPTIONS_GROUPED = [
  { label: 'Adrian', value: 1 },
  { label: 'Alicia', value: 2 },
  { label: 'Alvaro', value: 3 },
  { label: 'David', value: 4 },
  { label: 'Diego', value: 5, disabled: true },
  { label: 'Jeff', value: 6 },
  { label: 'Felix', value: 7 },
  { label: 'Matheus', value: 8, disabled: true },
];

const StyleguideButtons = () => {
  const [isLoading, setLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [selectOption, setSelectOption] = useState(null);
  const [checkbox, setCheckbox] = useState(false);
  const [switchItems, setSwitchItems] = useState<SwitchItem[]>([
    { label: 'Option 1' },
    { label: 'Option 2', checked: true },
  ]);
  const radioItems = [
    { label: 'Option 1' },
    { label: 'Option 2' },
    { label: 'Option 3 (disabled)', disabled: true, value: '3' },
  ];
  const [radioValue, setRadioValue] = useState('3');
  const [inputLog, setInputLog] = useState(' ');
  const [dateInputLog, setDateInputLog] = useState(' ');
  const [textAreaLog, setTextAreaLog] = useState(' ');
  const [checkBoxLog, setCheckBoxLog] = useState(' ');
  const [switchLog, setSwitchLog] = useState(' ');
  const [radioLog, setRadioLog] = useState(' ');
  const minDate = new Date();
  const maxDate = new Date();
  minDate.setHours(9);
  maxDate.setHours(18);

  return (
    <Panel>
      <Panel.Heading title="Inputs">
        <Panel.Actions>
          <Button
            className="grid-span-4"
            type={error ? 'contained' : 'outlined'}
            onClick={() => setError(!error)}
          >
            Toggle Error
          </Button>
          <Button
            className="grid-span-4"
            type={isLoading ? 'contained' : 'outlined'}
            onClick={() => setLoading(!isLoading)}
          >
            Toggle Loading
          </Button>
          <Button
            className="grid-span-4"
            type={isDisabled ? 'contained' : 'outlined'}
            onClick={() => setDisabled(!isDisabled)}
          >
            Toggle Disabled
          </Button>
        </Panel.Actions>
      </Panel.Heading>
      <Panel.Body className="grid-wrapper">
        <Card
          className="grid-span-6"
          sx={{ padding: '2rem', height: 'max-content' }}
        >
          <CardContent className="grid-wrapper">
            <h4 className="grid-span-12">Inputs</h4>
            <TextInput
              className="grid-span-6"
              error={error}
              loading={isLoading}
              label="Normal"
              disabled={isDisabled}
              onChange={(value?: string) =>
                setInputLog(`Change: "${value}" ${new Date().getTime()}`)
              }
            />
            <TextInput
              className="grid-span-6"
              error={error}
              loading={isLoading}
              label="Placeholder + Required"
              disabled={isDisabled}
              required
              placeholder="Hint text"
              onChange={(value?: string) =>
                setInputLog(`Change: "${value}" ${new Date().getTime()}`)
              }
            />
            <SearchInput
              className="grid-span-6"
              error={error}
              loading={isLoading}
              disabled={isDisabled}
              onChange={(value?: string) =>
                setInputLog(`Change: "${value}" ${new Date().getTime()}`)
              }
              onSearch={(value?: string) =>
                setInputLog(`Search: "${value}" ${new Date().getTime()}`)
              }
            />
            <TextInput
              className="grid-span-6"
              disabled={isDisabled}
              error={error && 'Custom Error message'}
              label="Custom Icon"
              loading={isLoading}
              placeholder="Text hint"
              required
              tooltip="Example tooltip"
              icon={
                <IconButton
                  size="small"
                  onClick={() => setInputLog(`Add ${new Date().getTime()}`)}
                >
                  <i className="fa fa-plus" style={{ marginRight: '.5rem' }} />
                </IconButton>
              }
              onChange={(value?: string) =>
                setInputLog(`Change: "${value}" ${new Date().getTime()}`)
              }
            />
            <pre className="grid-span-12">{inputLog}</pre>
          </CardContent>
        </Card>
        <Card
          className="grid-span-6"
          sx={{ padding: '2rem', height: 'max-content' }}
        >
          <CardContent className="grid-wrapper">
            <h4 className="grid-span-12">DateTimePicker</h4>
            <DateTimePicker
              className="grid-span-6"
              error={error}
              label="Date"
              disabled={isDisabled}
              onChange={(value?: string) =>
                setDateInputLog(`Change: "${value}" ${new Date().getTime()}`)
              }
            />
            <DateTimePicker
              className="grid-span-6"
              error={error}
              label="Date + Required"
              disabled={isDisabled}
              onChange={(value?: string) =>
                setDateInputLog(`Change: "${value}" ${new Date().getTime()}`)
              }
              required
            />
            <DateTimePicker
              className="grid-span-6"
              error={error}
              label="Date + Max"
              disabled={isDisabled}
              maxDate={Date.now()}
              onChange={(value?: string) =>
                setDateInputLog(`Change: "${value}" ${new Date().getTime()}`)
              }
              type="date"
            />
            <DateTimePicker
              className="grid-span-6"
              error={error}
              label="Time + Min + Max"
              disabled={isDisabled}
              minTime={minDate}
              maxTime={maxDate}
              onChange={(value?: string) =>
                setDateInputLog(`Change: "${value}" ${new Date().getTime()}`)
              }
              type="time"
              required
            />
            <pre className="grid-span-12">{dateInputLog}</pre>
          </CardContent>
        </Card>
        <Card
          className="grid-span-6"
          sx={{ padding: '2rem', height: 'max-content' }}
        >
          <CardContent className="grid-wrapper">
            <h4 className="grid-span-12">Select</h4>
            <Select
              className="grid-span-6"
              error={error}
              disabled={isDisabled}
              items={SELECT_OPTIONS}
              label="Normal"
              loading={isLoading}
              value={selectOption}
              onChange={(value: any) => {
                setSelectOption(value);
              }}
            />
            <Select
              className="grid-span-6"
              error={error}
              disabled={isDisabled}
              icon={<Search />}
              items={SELECT_OPTIONS}
              label="Placeholder + Required + Icon"
              loading={isLoading}
              value={selectOption}
              required
              placeholder="Text hint"
              onChange={(value: any) => {
                setSelectOption(value);
              }}
            />
            <Select
              className="grid-span-6"
              error={error}
              disabled={isDisabled}
              items={SELECT_OPTIONS_GROUPED}
              label="Grouped + Disabled Items"
              loading={isLoading}
              value={selectOption}
              onChange={(value: any) => {
                setSelectOption(value);
              }}
            />
            <Select
              allowAddNewValue
              className="grid-span-6"
              error={error}
              disabled={isDisabled}
              items={SELECT_OPTIONS_GROUPED}
              label="Add new Items"
              loading={isLoading}
              multiple
              value={selectOption}
              required
              placeholder="Text hint"
              onChange={(value: any) => {
                setSelectOption(value);
              }}
            />
            <Select
              className="grid-span-6"
              error={error}
              disabled={isDisabled}
              items={SELECT_OPTIONS}
              label="Small"
              loading={isLoading}
              value={selectOption}
              onChange={(value: any) => {
                setSelectOption(value);
              }}
              size="small"
            />
            <Select
              className="grid-span-6"
              error={error}
              disabled={isDisabled}
              items={SELECT_OPTIONS}
              label="Extra Small"
              loading={isLoading}
              value={selectOption}
              onChange={(value: any) => {
                setSelectOption(value);
              }}
              size="xs"
            />
            <pre className="grid-span-12">{JSON.stringify(selectOption)}</pre>
          </CardContent>
        </Card>
        <Card
          className="grid-span-6"
          sx={{ padding: '2rem', height: 'max-content' }}
        >
          <CardContent className="grid-wrapper">
            <h4 className="grid-span-12">Text Area</h4>
            <TextArea
              className="grid-span-6"
              error={error}
              label="Min 3 Rows"
              minRows={3}
              disabled={isDisabled}
              placeholder="Text hint"
              onChange={(value?: string) =>
                setTextAreaLog(`Change: "${value}" ${new Date().getTime()}`)
              }
            />
            <TextArea
              className="grid-span-6"
              error={error}
              label="Fixed 3 Rows"
              disabled={isDisabled}
              rows={3}
              value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "
              required
              onChange={(value?: string) =>
                setTextAreaLog(`Change: "${value}" ${new Date().getTime()}`)
              }
            />
            <div className="grid-span-12" style={{ height: '2.75rem' }} />
            <pre className="grid-span-12">
              {textAreaLog.replace(/\n/g, '\\n')}
            </pre>
          </CardContent>
        </Card>
        <Card
          className="grid-span-6"
          sx={{ padding: '2rem', height: 'max-content' }}
        >
          <CardContent className="grid-wrapper">
            <h4 className="grid-span-12">Checkboxes</h4>
            <CheckboxWithLabel
              label="Test"
              className="grid-span-6"
              disabled={isDisabled}
              checked={checkbox}
              onChange={(value?: boolean) => {
                setCheckbox(value || false);
                setCheckBoxLog(
                  `Change checkbox: "${value}" ${new Date().getTime()}`,
                );
              }}
            />
            <CheckboxWithLabel
              label="Test Checked"
              className="grid-span-6"
              disabled={isDisabled}
              checked={!checkbox}
              onChange={(value?: boolean) => {
                setCheckbox(value || false);
                setCheckBoxLog(
                  `Change checkbox: "${value}" ${new Date().getTime()}`,
                );
              }}
            />
            <div className="grid-span-12" style={{ height: '3.25rem' }} />
            <pre className="grid-span-12">
              {checkBoxLog.replace(/\n/g, '\\n')}
            </pre>
          </CardContent>
        </Card>
        <Card
          className="grid-span-6"
          sx={{ padding: '2rem', height: 'max-content' }}
        >
          <CardContent className="grid-wrapper">
            <h4 className="grid-span-12">Switch</h4>
            <SwitchGroup
              className="grid-span-12"
              disabled={isDisabled}
              items={switchItems}
              title={['Title', 'On/Off']}
              onChange={(items: SwitchItem[]) => {
                setSwitchItems(items);
                setSwitchLog(
                  `Change switch: "${JSON.stringify(
                    items,
                  )}" ${new Date().getTime()}`,
                );
              }}
            />
            <pre className="grid-span-12">
              {switchLog.replace(/\n/g, '\\n')}
            </pre>
          </CardContent>
        </Card>
        <Card
          className="grid-span-6"
          sx={{ padding: '2rem', height: 'max-content' }}
        >
          <CardContent className="grid-wrapper">
            <h4 className="grid-span-12">Radio Group</h4>
            <RadioGroup
              className="grid-span-12"
              disabled={isDisabled}
              items={radioItems}
              title="Title"
              onChange={(value) => {
                setRadioValue(String(value));
                setRadioLog(
                  `Change switch: "${value}" ${new Date().getTime()}`,
                );
              }}
              value={radioValue}
            />
            <pre className="grid-span-12">{radioLog.replace(/\n/g, '\\n')}</pre>
          </CardContent>
        </Card>
      </Panel.Body>
    </Panel>
  );
};

export default StyleguideButtons;
