import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';

import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  EventInterventionType,
} from '@vestahealthcare/common/models';

import { Button, FilterButton, IconButton, Panel, Select } from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import {
  updateEventInterventionType,
  createEventInterventionType,
  fecthEventInterventionTypes,
  GetInterventionTypParams,
} from 'dash/src/services/EventServices';
import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { Choice } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';

import { EventInterventionsTable } from './EventInterventionsTable';
import { EventInterventionEditModal } from './EventInterventionEdit';

export const EventInterventionsDashboard = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<BaseEnum[]>([]);
  const [interventions, setInterventions] = useState<EventInterventionType[]>(
    [],
  );
  const [filters, setFilters] = useState<GetInterventionTypParams>({});
  const [openFilters, setOpenFilters] = useState<boolean>(false);

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<EventInterventionType>();

  const getInitialData = async () => {
    const categories = await CacheServices.getEventInterventionCategories();
    setCategories(categories);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const interventions = await fecthEventInterventionTypes({
      ...filters,
      sort: 'active desc, description asc',
    });
    setInterventions(interventions);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [filters]);

  return (
    <Panel id="event-types-section">
      <Panel.Heading title={translate('events.interventions.title')}>
        <FilterButton
          className="filters"
          data-cy="intervention-filters"
          filters={filters}
          onClick={() => setOpenFilters(!openFilters)}
        />
        <Panel.Actions>
          <IconButton
            onClick={() => {
              setModalData({ active: true } as EventInterventionType);
              setOpenEditModal(true);
            }}
            tooltip={translate('events.interventions.add')}
          >
            <AddIcon fontSize="large" />
          </IconButton>
        </Panel.Actions>
        <Panel.Collapse open={openFilters}>
          <div className="grid-wrapper fit">
            <Select
              className="grid-span-3"
              data-cy="intervention-filter-category"
              getItemLabel={({ description }: BaseEnum) => description}
              items={categories}
              limitTags={1}
              multiple
              onChange={(categoryId?: BaseEnum[]) =>
                setFilters({ ...filters, categoryId })
              }
              placeholder={translate('events.interventions.category')}
              value={filters.categoryId}
            />
            <Select
              className="grid-span-3"
              data-cy="intervention-filter-active"
              items={Choice.getChoices()}
              onChange={(item?: Selectable) =>
                setFilters({
                  ...filters,
                  active: item ? !!item.value : undefined,
                })
              }
              placeholder={translate('events.interventions.active')}
              value={
                filters.active !== undefined
                  ? Choice.getChoices()[filters.active ? 0 : 1]
                  : undefined
              }
            />
            <div className="grid-span-3" />
            <Button
              className="grid-span-3"
              data-cy="intervention-clear-filters"
              color="secondary"
              type="outlined"
              onClick={() => setFilters({})}
            >
              {translate('global.clearFilters')}
            </Button>
          </div>
        </Panel.Collapse>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <EventInterventionsTable
          interventions={interventions}
          onEdit={(intervention) => {
            setModalData(intervention);
            setOpenEditModal(true);
          }}
        />
        <EventInterventionEditModal
          intervention={modalData}
          onSubmit={async (id, params) => {
            let result = true;
            try {
              if (id) {
                await updateEventInterventionType(id, params);
              } else {
                await createEventInterventionType(params);
              }
              CacheServices.invalidateEventInterventionTypes();
              await getData();
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
          onClose={() => setOpenEditModal(false)}
          open={openEditModal}
        />
      </Panel.Body>
    </Panel>
  );
};

export default EventInterventionsDashboard;
