import React from 'react';
import { NumericInput } from 'styleguide';
import { WidgetProps } from '../types';

const NumericWidget = (props: WidgetProps) => {
  const {
    onChange,
    rawErrors,
    options: { allowFloat },
  } = props;

  const hasErrors = !!rawErrors && !!rawErrors.length;
  // TODO: note that we're assuming only one error, this is the only way I found to update the error state when
  // the data is changed after validation
  const errorText = hasErrors ? rawErrors && rawErrors.pop() : undefined;

  return (
    <NumericInput
      className="numeric"
      errorText={errorText}
      // FIXME: Backend is expecting a string?
      onBlur={(value) => onChange(value && String(value))}
      placeholder="#"
      required={hasErrors}
      value={props.value}
      showError={hasErrors}
      allowFloat={allowFloat}
    />
  );
};

export default NumericWidget;
