import { States } from '../enums';

export class Address {
  address1 = '';

  address2 = '';

  city = '';

  state?: States;

  zipCode = '';

  constructor(obj: any) {
    Object.assign(this, obj);
    this.state = States.byKey[obj.state];
  }

  get formattedAddress() {
    return {
      street: this.address1,
      city: [this.city, this.state, this.zipCode].filter((v) => !!v).join(', '),
    };
  }

  get googleMapsLink() {
    const { street, city } = this.formattedAddress;
    return `http://maps.google.com/?q=${[street, city]
      .filter((v) => !!v)
      .join(' ')}`;
  }

  get isEmpty() {
    return (
      !this.address1?.trim() &&
      !this.address2?.trim() &&
      !this.city?.trim() &&
      !this.state &&
      !this.zipCode?.trim()
    );
  }
}

export default Address;
