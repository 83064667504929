import React from 'react';
import { Button } from 'styleguide';
// @ts-ignore
import ReactSelect from 'react-select'; // rename so it doesn't conflict with ours
import classNames from 'classnames';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { ButtonColor } from '../Button';
import './main.less';

interface Props {
  dataCy?: string;
  autoFocus?: boolean;
  className?: string;
  color?: ButtonColor;
  disabled?: boolean;
  id?: string;
  loading?: boolean;
  onChange: (selection: string) => void;
  options: Selectable[];
  placeholder?: string;
  value?: string;
}

const ButtonSelect = (props: Props) => {
  const {
    autoFocus,
    className,
    color = 'primary',
    disabled = false,
    id,
    loading,
    onChange,
    options,
    placeholder,
    value,
    dataCy = 'button-select',
  } = props;

  const classes = classNames('btn-dropdown', className);

  return (
    <Button
      data-cy={dataCy}
      className={classes}
      color={color}
      disabled={disabled}
      id={id}
      loading={loading}
    >
      <ReactSelect
        autoFocus={autoFocus}
        clearable={false}
        disabled={disabled}
        onChange={(selection: Selectable) =>
          onChange(selection?.value?.toString())
        }
        options={options}
        placeholder={placeholder}
        searchable={false}
        value={value}
      />
    </Button>
  );
};

export default ButtonSelect;
