import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';

import { translate } from '@vestahealthcare/common/i18n';
import { EmployeeGroup } from '@vestahealthcare/common/models';
import { IconButton, Panel } from 'styleguide-v2';

import CacheServices from 'dash/src/services/CacheServices';
import {
  createEmployeeGroup,
  fetchEmployeeGroups,
  updateEmployeeGroup,
} from 'dash/src/services/EmployeeServices';
import { showGlobalError } from 'dash/src/components/GlobalMessage';

import { GroupsTable } from './GroupsTable';
import GroupsEditModal from './GroupsEditModal';

export const CaregiversDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [editGroup, setEditGroup] = useState<EmployeeGroup>();
  const [groups, setGroups] = useState<EmployeeGroup[]>([]);

  const getGroups = async () => {
    setLoading(true);
    try {
      const groups = await fetchEmployeeGroups(true);
      setGroups(groups);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  const onAddGroup = () => {
    setEditGroup({} as EmployeeGroup);
  };

  useEffect(() => {
    getGroups();
  }, []);

  return (
    <Panel id="groups-section">
      <Panel.Heading title={translate('groups.title')}>
        <Panel.Actions>
          <IconButton
            onClick={onAddGroup}
            tooltip={translate('groups.addGroup')}
          >
            <AddIcon fontSize="large" />
          </IconButton>
        </Panel.Actions>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <GroupsTable
          groups={groups}
          onEdit={(g) => {
            setEditGroup(g);
          }}
        />
        <GroupsEditModal
          group={editGroup}
          groups={groups}
          onClose={() => setEditGroup(undefined)}
          onSubmit={async (id, params) => {
            try {
              if (id) {
                await updateEmployeeGroup(id, params);
              } else {
                await createEmployeeGroup(params);
              }
              CacheServices.invalidateEmployeeGroupsCache();
              const groups = await fetchEmployeeGroups(true);
              setGroups(groups);
            } catch (e) {
              showGlobalError(e as string);
            }
            return true;
          }}
        />
      </Panel.Body>
    </Panel>
  );
};

export default CaregiversDashboard;
