import classnames from 'classnames';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

import { ClassNameMap, makeStyles } from '@mui/styles';

import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import TaskStatus from '@vestahealthcare/common/enums/TaskStatus';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  PaginationType,
  Patient,
  Task,
} from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  DATE_FORMAT_SHORT_WITH_TIME,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  Colors,
  Fonts,
  Select,
  SelectAssignee,
  Table,
  TableItem,
  UNASSIGNED,
  UpdatedBy,
} from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

import { useSelector } from 'dash/src/redux/store';
import { CacheServices } from 'dash/src/services';
import Session from 'dash/src/services/SessionServices';

const TABLE_HEADERS = {
  status: translate('clinicalDashboard.tasks.table.status'),
  dueDate: translate('clinicalDashboard.tasks.table.dueDate'),
  completedDate: translate('clinicalDashboard.tasks.table.completedDate'),
  description: translate('clinicalDashboard.tasks.table.description'),
  group: translate('clinicalDashboard.tasks.table.group'),
  assignee: translate('clinicalDashboard.tasks.table.assignee'),
  created: translate('clinicalDashboard.tasks.table.created'),
};

const SORT_FIELDS = {
  [TABLE_HEADERS.status]: 'status',
  [TABLE_HEADERS.dueDate]: 'dueDate',
  [TABLE_HEADERS.completedDate]: 'completedAt',
  [TABLE_HEADERS.description]: 'taskTemplate',
  [TABLE_HEADERS.created]: 'createdAt',
};

const formatTaskDate = (date: number) => {
  if (!date) return undefined;
  const momentDate = moment.unix(date);

  // Check if the date is at the beginnning of the day (12am)
  return momentDate.isSame(moment.unix(date).startOf('day'))
    ? momentDate.format(DATE_FORMAT_SHORT)
    : momentDate.format(DATE_FORMAT_SHORT_WITH_TIME);
};

export interface TaskTable extends Task, TableItem {}

type Props = {
  defaultSort?: string;
  tasks: Task[];
  loading?: boolean;
  onChangePage: (page: number) => void;
  onChangePageSize: (size: number) => void;
  onClickRow: (task: TaskTable, idx: number) => void;
  onChangeAssignee: (id: number, assignee: Employee) => void;
  onChangeGroup: (id: number, group: EmployeeGroup) => void;
  onChangeStatus: (id: number, status: TaskStatus) => void;
  onDefaultSort: () => void;
  onSort: (field: string, direction: string) => void;
  pagination?: PaginationType;
  showCompleted?: boolean;
};

type TableData = {
  employees: Employee[];
  groups: EmployeeGroup[];
  member?: Patient;
};

const DASHBOARD_TASKS_COLUMNS = (
  styles: ClassNameMap<'bold' | 'redText'>,
  { onChangeAssignee, onChangeGroup, onChangeStatus, showCompleted }: Props,
  { employees, groups, member }: TableData,
  defaultSort?: string,
) => {
  const field = defaultSort?.split(' ')[0];
  const direction = defaultSort?.split(' ')[1] as 'asc' | 'desc';

  let columns: Column[] = [
    {
      align: 'center',
      headerName: TABLE_HEADERS.status,
      component: ({ id, status }: Task) => (
        <Select
          color={TaskStatus.COLOR_MAP.get(status)?.color || 'action'}
          disableClearable
          items={Enum.toSelectable(TaskStatus.asArray)}
          key={`task-id-${id}-status`}
          onChange={(val: Selectable) =>
            onChangeStatus(id, TaskStatus.byKey[val.value])
          }
          readOnly={status.closed && !Session.actingUser.isAdmin}
          size="xs"
          type={TaskStatus.COLOR_MAP.get(status)?.type || 'outlined'}
          value={{ value: status.value, label: status.toString() }}
        />
      ),
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.status] ? direction : undefined,
      sortable: true,
      width: 150,
    },
    {
      headerName: TABLE_HEADERS.dueDate,
      component: ({ dueDate, status }: Task) => (
        <span
          className={
            !status.closed && dueDate && moment().isAfter(moment.unix(dueDate))
              ? styles.redText
              : ''
          }
        >
          {dueDate ? formatTaskDate(dueDate) : EMPTY}
        </span>
      ),
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.dueDate] ? direction : undefined,
      sortable: true,
      width: 150,
    },
  ];

  if (showCompleted) {
    columns = [
      ...columns,
      {
        headerName: TABLE_HEADERS.completedDate,
        component: ({ completedAt }: Task) => (
          <span>{completedAt ? formatTaskDate(completedAt) : EMPTY}</span>
        ),
        direction:
          field === SORT_FIELDS[TABLE_HEADERS.completedDate]
            ? direction
            : undefined,
        sortable: true,
        width: 150,
      },
    ];
  }
  columns = [
    ...columns,
    {
      headerName: TABLE_HEADERS.description,
      field: 'taskDefinition.name',
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.description]
          ? direction
          : undefined,
      sortable: true,
    },
    {
      headerName: TABLE_HEADERS.group,
      component: ({ id, employeeGroup, status }: Task) => (
        <Select
          disableClearable
          getItemLabel={(group?: EmployeeGroup) => group?.name}
          items={groups}
          key={`task-${id}-group`}
          onChange={(val: EmployeeGroup) => onChangeGroup(id, val)}
          size="xs"
          readOnly={status.closed && !Session.actingUser.isAdmin}
          value={employeeGroup}
        />
      ),
      width: 185,
    },
    {
      headerName: TABLE_HEADERS.assignee,
      component: ({ id, assignee, status }: Task) => (
        <SelectAssignee
          allowUnassigned
          assignee={assignee || UNASSIGNED}
          currentUser={Session.actingUser}
          key={`task-${id}-assignee`}
          items={employees}
          member={member}
          onChange={(val?: Employee | EmployeeGroup | Selectable) => {
            if (val && val instanceof Employee) {
              onChangeAssignee(id, val);
            }
          }}
          readOnly={status.closed && !Session.actingUser.isAdmin}
          size="xs"
        />
      ),
      width: 155,
    },
    {
      headerName: TABLE_HEADERS.created,
      component: ({ createdAt, createdBy }: Task) => (
        <UpdatedBy date={createdAt} user={createdBy?.fullName} />
      ),
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.created] ? direction : undefined,
      sortable: true,
      width: 150,
    },
  ];

  return columns;
};

const useStyles = makeStyles({
  urgent: {
    '&:first-child': {
      borderLeft: `3px solid ${Colors.textOrange}`,
    },
  },
  bold: {
    fontWeight: 500,
  },
  table: {
    '&& thead': {
      '& th': {
        padding: '6px',
      },
    },
    '&& tbody': {
      '& td': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
        padding: '6px',
        '& > button': {
          maxWidth: 'fit-content',
        },
      },
      '& a': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
    },
  },
  redText: {
    color: Colors.textRed,
    fontWeight: 500,
  },
});

export const MemberTaskTable = (props: Props) => {
  const styles = useStyles();
  const {
    defaultSort,
    tasks,
    loading,
    onChangePage,
    onChangePageSize,
    onClickRow,
    onDefaultSort,
    onSort,
    pagination,
  } = props;

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [groups, setGroups] = useState<EmployeeGroup[]>([]);
  const member = useSelector((state) => state.memberInfoSlice.patient);

  const getInitialData = async () => {
    const [employees, groups] = await Promise.all([
      CacheServices.getEmployees(),
      CacheServices.getEmployeeGroupsAssignee(),
    ]);
    setEmployees(employees);
    setGroups(groups);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const getRowClass = ({ isUrgent }: Task) =>
    classnames(isUrgent && styles.urgent);

  const columns = useMemo(
    () =>
      DASHBOARD_TASKS_COLUMNS(
        styles,
        props,
        {
          employees,
          groups,
          member,
        },
        defaultSort,
      ),
    [
      defaultSort,
      employees,
      groups,
      member,
      props.showCompleted,
      props.onChangeAssignee,
      props.onChangeGroup,
      props.onChangeStatus,
    ],
  );

  return (
    <Table
      bottomLoading={loading}
      className={styles.table}
      config={{
        compact: true,
        data: tasks,
        defaultPageSize: 50,
        columns,
        detail: true,
        getRowClass,
        pagination,
        paginationOptions: [10, 25, 50, 100],
      }}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangePageSize}
      onClickRow={onClickRow}
      onDefaultSort={onDefaultSort}
      onSort={({ headerName, direction }) => {
        if (SORT_FIELDS[headerName as string] && direction) {
          onSort(SORT_FIELDS[headerName as string], direction);
        }
      }}
    />
  );
};

export default MemberTaskTable;
