import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { BaseEnum, CareTeamMember } from '@vestahealthcare/common/models';

import { Button, CollapsableSidebar, Select, TextInput } from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import { DeleteCareTeamMemberParams } from 'dash/src/services/CareTeamServices';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (id: number, params: DeleteCareTeamMemberParams) => void;
  member?: CareTeamMember;
};

export const CareTeamMemberDeleteModal = ({
  member,
  onClose,
  onSubmit,
  open,
}: Props) => {
  const [invalidateReasons, setInvalidateReasons] = useState<BaseEnum[]>([]);
  const [invalidateReason, setInvalidateReason] = useState<BaseEnum>();
  const [invalidateReasonOther, setInvalidateReasonOther] = useState<string>();

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingdata, setLoadingData] = useState<boolean>(false);

  const reset = () => {
    setInvalidateReason(undefined);
    setInvalidateReasonOther(undefined);
    setSubmitted(false);
  };

  const getInitialData = async () => {
    setLoadingData(true);
    const reasons = await CacheServices.getCareTeamMemberInactiveReasons();
    setInvalidateReasons(reasons);
    setLoadingData(false);
  };

  useEffect(() => {
    if (open) {
      reset();
      if (!invalidateReasons?.length) {
        getInitialData();
      }
    }
  }, [open]);

  const validate = () =>
    invalidateReason &&
    (invalidateReason.id !== BaseEnum.OTHER || invalidateReasonOther);

  const handleSubmit = async () => {
    setSubmitted(true);
    if (!member || !validate() || !invalidateReason) return;

    setLoading(true);
    await onSubmit(member.id, {
      inactiveReasonId: invalidateReason,
      inactiveReasonOther:
        invalidateReason?.id === BaseEnum.OTHER
          ? invalidateReasonOther
          : undefined,
    });

    setLoading(false);
    setSubmitted(false);
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={
        <div>
          <h2>{translate('careTeam.modals.deleteMember.title')}</h2>
        </div>
      }
    >
      <CollapsableSidebar.Body>
        <Select
          error={submitted && !invalidateReason}
          getItemLabel={({ description }: BaseEnum) => description}
          items={invalidateReasons}
          label={translate('careTeam.modals.deleteMember.inactiveReasons')}
          loading={loadingdata}
          onChange={setInvalidateReason}
          required
          value={invalidateReason}
        />
        {invalidateReason?.id === BaseEnum.OTHER && (
          <TextInput
            error={submitted && !invalidateReasonOther}
            label={translate(
              'careTeam.modals.deleteMember.inactiveReasonOther',
            )}
            onChange={setInvalidateReasonOther}
            required
            value={invalidateReasonOther}
          />
        )}
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="care-team-quick-add-back"
          onClick={onClose}
        >
          {translate('global.back')}
        </Button>

        <Button
          color="secondary"
          data-cy="care-team-quick-add-submit"
          disabled={!invalidateReason}
          loading={loading}
          onClick={handleSubmit}
        >
          {translate('careTeam.modals.deleteMember.inactivate')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};
