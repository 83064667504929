import React from 'react';
import { Colors, Panel } from 'styleguide';

const StyleguideColors = () => (
  <Panel>
    <Panel.Heading>
      <h2>Colors</h2>
    </Panel.Heading>
    <Panel.Body className="grid-wrapper">
      {Object.entries(Colors).map(([color, hex]) => (
        <React.Fragment key={color}>
          <div className="flex grid-span-2">{color}</div>
          <div className="color grid-span-3" style={{ backgroundColor: hex }} />
          <div className="flex grid-span-2" style={{ color: hex }}>
            Sample Text
          </div>
          <div
            className="flex grid-span-2"
            style={{ backgroundColor: Colors.lightMint, color: hex }}
          >
            Sample Text
          </div>
          <pre className="grid-span-3">{hex}</pre>
        </React.Fragment>
      ))}
    </Panel.Body>
  </Panel>
);

export default StyleguideColors;
