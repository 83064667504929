import { Employee } from '.';
import { make } from './Model';

export class ClinicalNote {
  id = 0;

  createdAt = 0;

  updatedAt = 0;

  createdBy: Employee;

  note = '';

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdBy = make(obj.author, Employee);
  }
}

export default ClinicalNote;
