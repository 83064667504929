import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Panel, Select } from 'styleguide';

import { groupBy } from 'lodash';

import { Filters, MobileDashboardTable } from './MobileDashboardTable';
import { AppOS, Build, BuildDetails, getFullDetails } from './ReleaseInfo';
import { DashboardType, getReleaseDetails, getReleases } from './services';

const NUMBER_OF_RELEASES_TO_SHOW = 30;

interface RouteParams {
  deviceType: DashboardType;
}

const sortReleases = (a: Build, b: Build) => {
  if (moment(b.uploaded_at).isAfter(moment(a.uploaded_at))) {
    return 1;
  }
  return -1;
};

// Return only the latest release for each branch
const sortAndFilterReleases = (
  releases?: Build[],
  details?: Map<string, BuildDetails>,
) => {
  if (!releases?.length || !details?.size) return [];
  const releasesByBranch = groupBy(
    releases,
    (release) =>
      (details.get(release.key)?.branchName || release.id) + release.appOS,
  );
  return Object.values(releasesByBranch).map(
    (releases) => releases.sort(sortReleases)[0],
  );
};

const MobileDashboard = () => {
  const [releases, setReleases] = useState<Build[]>();
  const [details, setDetails] = useState<Map<string, BuildDetails>>(new Map());
  const [versions, setVersions] = useState<Set<string>>(new Set());
  const [tickets, setTickets] = useState<Set<string>>(new Set());
  const [filters, setFilters] = useState<Filters>({});
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const { deviceType } = useParams<RouteParams>() as RouteParams;

  const fetchAppVersions = async (deviceType: DashboardType) => {
    setError(undefined);

    try {
      setLoading(true);
      const releases: Build[] = await getReleases(deviceType);
      const lastReleases = releases
        .sort(sortReleases)
        .splice(0, NUMBER_OF_RELEASES_TO_SHOW);
      setReleases(lastReleases);
      getDetails(deviceType, lastReleases);
      setLoading(false);
      setError(undefined);
    } catch (error) {
      console.error(error);
      // @ts-ignore
      setError(error.message);
      setLoading(false);
    }
  };

  const getDetails = async (
    deviceType: DashboardType,
    lastReleases: Build[],
  ) => {
    const releaseDetailsRequests = lastReleases.map((release) =>
      getReleaseDetails(deviceType, release.appOS, release.id),
    );
    const releaseDetailsList = await Promise.all(releaseDetailsRequests);

    const newDetails = new Map<string, BuildDetails>();
    const newVersions = new Set<string>();
    const newTickets = new Set<string>();

    releaseDetailsList.forEach((d: BuildDetails) => {
      const fullDetails = getFullDetails(d);
      newVersions.add(fullDetails.version);
      fullDetails.jiraTicket && newTickets.add(fullDetails.jiraTicket);
      newDetails.set(d.key, fullDetails);
    });

    setDetails(new Map(newDetails));
    setVersions(newVersions);
    setTickets(newTickets);
  };

  useEffect(() => {
    fetchAppVersions(deviceType);
  }, [deviceType]);

  const filteredReleases = sortAndFilterReleases(releases, details);

  return (
    <Panel>
      <Panel.Heading>
        <h2>{`${deviceType} app last ${NUMBER_OF_RELEASES_TO_SHOW} releases`}</h2>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <div className="grid-wrapper" style={{ marginBottom: 50 }}>
          <Select
            options={[AppOS.ANDROID, AppOS.IOS].sort().map((t) => ({
              label: t,
              value: t,
            }))}
            label="App OS"
            onChange={(appOS: AppOS) => setFilters({ ...filters, appOS })}
            value={filters.appOS}
            className="grid-span-3"
          />
          <Select
            options={Array.from(versions)
              .sort()
              .map((v) => ({
                label: v,
                value: v,
              }))}
            label="Versions"
            onChange={(version: string) => setFilters({ ...filters, version })}
            value={filters.version}
            className="grid-span-3"
          />
          <Select
            options={Array.from(tickets)
              .sort()
              .map((t) => ({
                label: t,
                value: t,
              }))}
            label="Jira Tickets"
            onChange={(ticket: string) => setFilters({ ...filters, ticket })}
            value={filters.ticket}
            className="grid-span-3"
          />
        </div>
        {error ? (
          <p>Oops something went wrong, refresh to retry! [{error}]</p>
        ) : (
          (() => {
            if (!releases || !details) {
              return null;
            }

            return (
              <MobileDashboardTable
                deviceType={deviceType}
                releases={filteredReleases}
                details={details}
                filters={filters}
              />
            );
          })()
        )}
      </Panel.Body>
    </Panel>
  );
};

export default MobileDashboard;
