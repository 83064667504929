import makeStyles from '@mui/styles/makeStyles';
import { Colors, Fonts } from 'styleguide-v2';

export const eventEvaluationDetailStyles = makeStyles({
  card: {
    padding: '1rem',
    flex: '1 1 52.5rem',
  },
  triage: {
    flexBasis: '100%',
  },
  spinner: {
    margin: '1rem auto',
  },
  cardTitleAction: {
    height: '2rem',
    marginTop: '-0.5rem',
    width: '2rem',
  },
  container: {
    backgroundColor: Colors.white,
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
  },
  createdInfo: {
    color: Colors.gray,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
  },
  interventionContainer: {
    display: 'flex',
    flexFlow: 'column',
    gap: '1rem',
  },
  interventionOptions: {
    marginTop: '-2.5rem',
  },
  interventionRow: {
    display: 'flex',
    gap: '0.5rem',
    '& > div': {
      alignSelf: 'center',
      width: '30%',
      '& > input': {
        fontSize: Fonts.fontSize,
      },

      '&:first-child': {
        alignSelf: 'center',
        width: '40%',

        '& > p': {
          margin: 0,
        },
      },
    },
  },
  noteContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    '& > li ': {
      display: 'flex',
      flex: '1 1 60rem',
      gap: '1rem',
    },

    '& > li > span:first-child': {
      flex: '10 1 40rem',
    },

    '& > li > .note-created': {
      flex: '0 1 12.5rem',
    },

    '& > li > span.note-edit': {
      margin: '-0.5rem auto auto',
    },
  },
  question: {
    fontWeight: 500,
  },
  buttons: {
    flex: '1 1 100%',
    padding: '1rem',

    '& > button': {
      display: 'flex',
      margin: 'auto',
    },
  },
});
