import classNames from 'classnames';
import React, { useState } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import WarningIcon from '@mui/icons-material/Warning';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { MemberWarning, Patient } from '@vestahealthcare/common/models';
import { CCMMemberDetailHeader } from '@vestahealthcare/common/models/CCMMemberDetailHeader';

import { BackgroundColors, Colors, Fonts } from 'styleguide-v2';

import { MemberWarningIcon } from 'dash/src/components/MemberWarningIcon';
import { useSelector } from 'dash/src/redux/store';

import { MemberWarningsModal } from './MemberWarningsModal';

type Props = {
  clickable?: boolean;
  patient: Patient | CCMMemberDetailHeader;
  warnings?: MemberWarning[];
};

const useStyles = makeStyles({
  container: {
    backgroundColor: BackgroundColors.lighterBlue,
    borderLeft: `4px solid ${Colors.blue}`,
    borderRadius: '4px',
    marginLeft: '1rem',
    height: 'calc(100% - 3rem)',
    padding: '1rem 4rem 1rem 1rem',
  },
  clickable: {
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: BackgroundColors.lightBlue,
    },
  },
  containerLarge: {
    '&&': {
      height: 'calc(100% - 1rem)',
    },
  },
  containerXL: {
    '&&': {
      height: '100%',
    },
  },
  columns: {
    display: 'flex',
    flexFlow: 'column',
    flexWrap: 'wrap',
    gap: '0 1rem',
    maxHeight: '5rem',
  },
  columnsLarge: {
    maxHeight: '7rem',
  },
  columnsXL: {
    maxHeight: '9rem',
  },
  title: {
    color: Colors.blue,
    display: 'flex',
    fontFamily: Fonts.fontFamily,
    fontSize: `calc(${Fonts.fontSize} * 0.625)`,
    fontWeight: 500,
    gap: '0.5rem',
    letterSpacing: '1px',
    marginBottom: '0.5rem',
    textTransform: 'uppercase',
  },
  text: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
  },
  warningIcon: {
    marginTop: '-2px',
  },
});

export const MemberWarnings = ({
  clickable,
  patient,
  warnings: defaultWarnings,
}: Props) => {
  const styles = useStyles();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const warnings =
    defaultWarnings || useSelector((state) => state.memberInfoSlice.warnings);

  if (!warnings?.length) return <></>;

  const extraLarge = warnings?.length > 8;
  const large = !extraLarge && warnings?.length > 4;

  return (
    <>
      <div
        className={classNames(
          styles.container,
          clickable && styles.clickable,
          large && styles.containerLarge,
          extraLarge && styles.containerXL,
        )}
        onClick={() => clickable && setOpenModal(true)}
      >
        <div className={styles.title}>
          <WarningIcon fontSize="small" color="info" />
          <span style={{ marginTop: '0.125rem' }}>
            {translate('memberWarnings.memberHeader.title')}
          </span>
          {clickable && <ArrowForwardIcon fontSize="small" color="info" />}
        </div>
        <div
          className={classNames(
            styles.columns,
            large && styles.columnsLarge,
            extraLarge && styles.columnsXL,
          )}
        >
          {warnings?.map(({ type }) => (
            <div
              className="flex gap"
              key={`member-${patient.id}-warning-${type.description}`}
            >
              <MemberWarningIcon
                category={type.category}
                className={styles.warningIcon}
                size="small"
              />
              <span className={styles.text}>{type.description}</span>
            </div>
          ))}
        </div>
      </div>
      {clickable && (
        <MemberWarningsModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          patient={patient}
        />
      )}
    </>
  );
};

export default MemberWarnings;
