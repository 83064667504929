import { Enum } from './Enum';

export class CollectionMethod extends Enum {
  translationKey = 'collectionMethod';

  static asArray: CollectionMethod[] = [];

  static byKey: { [name: string]: CollectionMethod } = {};

  static readonly HEALTH_REPORT = new CollectionMethod('HEALTH_REPORT');

  static readonly MAGPIE = new CollectionMethod('MAGPIE');

  static readonly CONTACT_HEALTH = new CollectionMethod('CONTACT_HEALTH');

  static readonly LIVECARE = new CollectionMethod('LIVECARE');

  static readonly MANUAL = new CollectionMethod('MANUAL');

  static readonly SMART_METER = new CollectionMethod('SMART_METER');

  static readonly CLEAR_ARCH = new CollectionMethod('CLEAR_ARCH');

  static readonly UNKNOWN = new CollectionMethod('UNKNOWN', false);

  constructor(
    public readonly value: string,
    public readonly selectable = true,
  ) {
    super();
    CollectionMethod.asArray.push(this);
    CollectionMethod.byKey[value] = this;
  }

  static toSelectable = () =>
    CollectionMethod.asArray
      .filter((val) => val.selectable)
      .map((val) => ({ value: val.valueOf(), label: val.toString() }));

  static toVendorSelectable = () =>
    CollectionMethod.asArray
      .filter(
        (val) =>
          ![
            CollectionMethod.HEALTH_REPORT,
            CollectionMethod.MANUAL,
            CollectionMethod.UNKNOWN,
          ].includes(val),
      )
      .map((val) => ({ value: val.valueOf(), label: val.toString() }));

  get isHealthReport() {
    return this === CollectionMethod.HEALTH_REPORT;
  }
}

export default CollectionMethod;
