import React, { PureComponent, ReactNode } from 'react';
import classNames from 'classnames';

interface Props extends React.HTMLProps<HTMLElement> {
  children?: ReactNode;
  collapsed?: boolean;
  disabled?: boolean;
  headRef?: React.Ref<HTMLElement>;
  inputRef?: React.Ref<HTMLElement>;
}

const SectionComponent = (props: Props) => {
  const { children, className, collapsed, disabled, ...other } = props;

  const classes = classNames('section', className || '', {
    collapsed,
    disabled,
  });

  return (
    <section className={classes} {...other}>
      {children}
    </section>
  );
};

export const Heading = (props: Props) => {
  const { children, className, headRef, ...other } = props;

  const classes = classNames('section-header', className);

  return (
    <header className={classes} ref={headRef} {...other}>
      {children}
    </header>
  );
};

export const Body = (props: Props) => {
  const { children, className, collapsed, inputRef, ...other } = props;

  const classes = classNames('section-body', className);

  return collapsed ? (
    <></>
  ) : (
    <article className={classes} ref={inputRef} {...other}>
      {children}
    </article>
  );
};

export const Footer = (props: Props) => {
  const { children, className, collapsed, ...other } = props;

  const classes = classNames('section-footer', className);

  return collapsed ? (
    <></>
  ) : (
    <footer className={classes} {...other}>
      {children}
    </footer>
  );
};

export const Section = Object.assign(SectionComponent, {
  Heading,
  Body,
  Footer,
});

export default Section;
