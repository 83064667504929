export class EventLinkReason {
  id = 0;

  reason = '';

  oppositeReason = '';

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default EventLinkReason;
