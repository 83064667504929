import { BaseEnum } from '../BaseEnum';

export class MemberWarningCategory extends BaseEnum {
  static APPOINTMENT = 'APPOINTMENT';

  static MISSING_INFO = 'MISSING_INFO';

  static OPEN_ITEMS = 'OPEN_ITEMS';

  static RPM_RISK = 'RPM_RISK';

  static TIME = 'TIME';

  static TOC = 'TOC';
}

export default MemberWarningCategory;
