import React from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { MemberView, PaginationType } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import {
  CheckboxWithLabel,
  MemberExtraInfo,
  MemberInfo,
  Table,
} from 'styleguide-v2';

interface Props {
  allChecked: boolean;
  members: MemberView[];
  membersSelected: number[];
  onClickCheckbox: (member: MemberView) => void;
  onClickCheckboxAll: (checked: boolean) => void;
  onChangePage: (page: number) => void;
  onChangePageSize: (page: number) => void;
  pagination?: PaginationType;
  seeAll?: boolean;
}

const BULK_EDIT_COLUMNS = ({
  allChecked,
  members,
  membersSelected,
  onClickCheckbox,
  onClickCheckboxAll,
}: Props) => [
  {
    headerName: (
      <CheckboxWithLabel
        checked={allChecked}
        disabled={!members.length}
        onChange={() => onClickCheckboxAll(allChecked)}
      />
    ),
    component: (member: MemberView) => (
      <CheckboxWithLabel
        checked={membersSelected.indexOf(member.id) !== -1}
        onChange={() => onClickCheckbox(member)}
      />
    ),
    width: 50,
  },
  {
    headerName: translate('common.member'),
    component: (member: MemberView) => (
      <MemberInfo
        link={`#/patients/${member?.id}`}
        member={member}
        showLanguage
        showRiskLevel
        showStatus
      />
    ),
    width: 250,
  },
  {
    headerName: ' ',
    component: ({ id, activeReferrals, programExtensions }: MemberView) => (
      <MemberExtraInfo
        activeReferrals={activeReferrals}
        key={`bulk-edit-row-${id}-member-extra-info`}
        memberId={id}
        programExtensions={programExtensions}
      />
    ),
    width: 200,
  },
  {
    headerName: translate('members.state'),
    component: ({ state }: MemberView) => state?.toString() || EMPTY,
  },
  {
    headerName: translate('members.carePlanGroup'),
    field: 'carePlanGroup.carePlanGroup.description',
  },
  {
    headerName: translate('members.vestaTeam'),
    component: ({
      id,
      careCoordinators,
      engagementOwners,
      healthCoaches,
      npOwner,
      podManager,
      owner,
    }: MemberView) => (
      <div key={`member-${id}-vesta-team`} className="grid-wrapper fit">
        <div className="grid-span-3">
          <p>
            <span className="bold">
              {translate('members.podManager')}:&nbsp;
            </span>
            <span>{podManager?.fullName || EMPTY}</span>
          </p>
          <p>
            <span className="bold">{translate('members.npOwner')}:&nbsp;</span>
            <span>{npOwner?.fullName || EMPTY}</span>
          </p>
        </div>
        <div className="grid-span-4">
          <p>
            <span className="bold">{translate('members.rnOwner')}:&nbsp;</span>
            <span>{owner?.fullName || EMPTY}</span>
          </p>
          <p>
            <span className="bold">
              {translate('members.careCoordinators')}:&nbsp;
            </span>
            <span>
              {careCoordinators?.map(({ fullName }) => fullName)?.join(', ') ||
                EMPTY}
            </span>
          </p>
        </div>
        <div className="grid-span-4">
          <p>
            <span className="bold">
              {translate('members.healthCoaches')}:&nbsp;
            </span>
            <span>
              {healthCoaches?.map(({ fullName }) => fullName)?.join(', ') ||
                EMPTY}
            </span>
          </p>
          <p>
            <span className="bold">
              {translate('members.engagementOwners')}:&nbsp;
            </span>
            <span>
              {engagementOwners?.map(({ fullName }) => fullName)?.join(', ') ||
                EMPTY}
            </span>
          </p>
        </div>
      </div>
    ),
  },
];

export const BulkEditTable = (props: Props) => (
  <Table
    config={{
      columns: BULK_EDIT_COLUMNS(props),
      compact: true,
      data: props.members,
      pagination: props.seeAll ? undefined : props.pagination,
      paginationOptions: props.seeAll ? [-1] : [10, 25, 50, 100],
    }}
    onChangePage={props.onChangePage}
    onChangeRowsPerPage={props.onChangePageSize}
  />
);

export default BulkEditTable;
