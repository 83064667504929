import React from 'react';
import classnames from 'classnames';
import { Radio } from 'styleguide';
import { translate } from '@vestahealthcare/common/i18n';
import './main.less';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { EMPTY } from '@vestahealthcare/common/utils/constants';
import { kebabCase } from 'lodash';

interface Props {
  title?: string;
  columnTitles: string[];
  options: Selectable[];
  descriptions: string[][];
  value?: string;
  onChange: (val: string) => void;
  isReadOnly: boolean;
}

const RadioTable = ({
  title,
  options,
  columnTitles,
  descriptions,
  value,
  onChange,
  isReadOnly = false,
}: Props) => (
  <>
    <table className="radio-table">
      <thead>
        <tr className="header">
          {!isReadOnly && <th>{translate('common.select')}</th>}
          {columnTitles.map((title: string) => (
            <th className={`${kebabCase(title)}-th`} key={title}>
              {title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {options.map((option: Selectable, i: number) => {
          const isSelected = option.value === value;
          const classes = classnames('option-row', {
            selected: isSelected,
            selectable: !isReadOnly,
          });

          return (
            <tr
              data-cy="palliative-table-row"
              className={classes}
              key={option.value}
              onClick={() => !isReadOnly && onChange(option.value.toString())}
            >
              {!isReadOnly && (
                <td className="radio-td">
                  <Radio
                    onChange={() => null}
                    checked={option.value === value}
                  />
                </td>
              )}
              <td className="label-td">{option.label}</td>
              {descriptions[i].map((desc: string, j: number) => (
                <td
                  className={`${kebabCase(columnTitles[j])}-td`}
                  key={`${desc}-${i}-${j}`}
                >
                  {desc}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
    {title && (
      <>
        <label className="control-label">{title}</label>
        <p data-cy="pps-fast-score">
          {value
            ? options.map((option) =>
                option.value === value ? option.label : null,
              )
            : EMPTY}
        </p>
      </>
    )}
  </>
);

export default RadioTable;
