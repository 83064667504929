import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { makeStyles } from '@material-ui/styles';

import { BackgroundColors, Colors } from 'styleguide-v2';

type Props = {
  className?: string;
  label: string;
  value: ReactNode;
};

const useStyles = makeStyles({
  container: {
    backgroundColor: BackgroundColors.lightMint,
    borderLeft: `3px solid ${Colors.lightMint}`,
    height: 'fit-content',
    marginLeft: '1rem',
    minHeight: '7.25rem',
    padding: '0.5rem 2rem 1rem',
    width: 'fit-content',
  },
  title: {
    margin: '0 0 0.25rem 0',
  },
});

export const InfoHeaderCard = ({ className, label, value }: Props) => {
  const styles = useStyles();

  return (
    <div className={classNames(className, styles.container)}>
      <h4 className={styles.title}>{label}</h4>
      <p className="small no-margin">{value}</p>
    </div>
  );
};
