import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from '@vestahealthcare/common/i18n';
import { Modal, Button } from 'styleguide';
import { getUserChangedWarningStatus } from 'dash/src/redux/actions/GlobalActions';
import Session from 'dash/src/services/SessionServices';

interface Props {
  showUserChangedMessage: boolean;
}

const mapStateToProps = (state: any): Props => ({
  showUserChangedMessage: getUserChangedWarningStatus(state),
});

class UserChangedWarning extends Component<Props> {
  doReload() {
    // Todo: improve this
    Session.removeImpersonator();
    window.location.reload();
  }

  render() {
    const { showUserChangedMessage } = this.props;

    return (
      <Modal show={showUserChangedMessage} onHide={() => this.doReload()}>
        <Modal.Header
          modalTitle={translate('global.authenticatedUserMismatch')}
        />
        <Modal.Body>
          <p>{translate('global.authenticatedUserMismatchDetail')}</p>
        </Modal.Body>
        <Modal.Footer
          rightSide={
            <Button onClick={() => this.doReload()}>
              {translate('global.reload')}
            </Button>
          }
        />
      </Modal>
    );
  }
}

export default connect(mapStateToProps)(UserChangedWarning);
