import { Enum } from './Enum';

export class AppointmentType extends Enum {
  translationKey = 'appointmentType';

  static asArray: AppointmentType[] = [];

  static byKey: { [name: string]: AppointmentType } = {};

  static readonly EST_TEL = new AppointmentType('EST TEL');

  static readonly EST_VV = new AppointmentType('EST VV');

  static readonly PSTHOSP_TE = new AppointmentType('PSTHOSP TE');

  static readonly PSTHOSP_VV = new AppointmentType('PSTHOSP VV');

  static readonly TCM_VV = new AppointmentType('TCM VV');

  static readonly SIX_MO_TEL = new AppointmentType('6 MO TEL');

  static readonly SIX_MO_VV = new AppointmentType('6 MO VV');

  static readonly ACUTE_TEL = new AppointmentType('ACUTE TEL');

  static readonly ACUTE_VV = new AppointmentType('ACUTE VV');

  static readonly ANNU_TEL = new AppointmentType('ANNU TEL');

  static readonly ANNU_VV = new AppointmentType('ANNU VV');

  static readonly CCA_SIX_MO = new AppointmentType('CCA 6 MO');

  static readonly SIX_MO_VV_ONE = new AppointmentType('6 MO VV 1');

  static readonly CCA_SIX_MO_1 = new AppointmentType('CCA 6 MO 1');

  static readonly PHARM_CON = new AppointmentType('PHARM CON');

  constructor(public readonly value: string) {
    super();
    AppointmentType.asArray.push(this);
    AppointmentType.byKey[value] = this;
  }
}

export default AppointmentType;
