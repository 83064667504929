import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { usePrevious } from '@vestahealthcare/common/hooks';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  CreateIncidentParams,
  IncidentAction,
  IncidentDetail,
  IncidentInvolvedActor,
  IncidentType,
} from '@vestahealthcare/common/models';

import {
  DateTimePicker,
  HorizontalRule,
  Select,
  TextArea,
  TextInput,
} from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';

type Props = {
  className?: string;
  defaultTypeId?: string;
  defaultFields?: Partial<CreateIncidentParams>;
  disabled?: boolean;
  incident?: IncidentDetail;
  isEventWorkflow?: boolean;
  onChange: (params: Partial<CreateIncidentParams>) => void;
  readonlyDescription?: boolean;
  submitted?: boolean;
};

export const IncidentModalFields = ({
  className,
  defaultTypeId,
  defaultFields,
  disabled,
  incident,
  isEventWorkflow,
  onChange,
  readonlyDescription,
  submitted,
}: Props) => {
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const [incidentTypes, setIncidentTypes] = useState<IncidentType[]>([]);
  const [otherIncidentTypes, setOtherIncidentTypes] = useState<IncidentType[]>(
    [],
  );
  const [locationOptions, setLocationOptions] = useState<BaseEnum[]>([]);
  const [actionsTaken, setActionsTaken] = useState<IncidentAction[]>([]);
  const [kindOfHelpOptions, setKindOfHelpOptions] = useState<BaseEnum[]>([]);
  const [entitites, setEntities] = useState<BaseEnum[]>([]);
  const [involvedOptions, setInvolvedOptions] = useState<BaseEnum[]>([]);
  const [dmeTypeOtions, setDMETypeOptions] = useState<BaseEnum[]>([]);
  const [anticoagulantOptions, setAnticoagulantOptions] = useState<BaseEnum[]>(
    [],
  );
  const [followUpOptions, setFollowUpOptions] = useState<BaseEnum[]>([]);

  const [incidentType, setIncidentType] = useState<IncidentType[]>([]);
  const [incidentDate, setIncidentDate] = useState<Date>();
  const [reportDate, setReportDate] = useState<Date>(new Date());
  const [involved, setInvolved] = useState<IncidentInvolvedActor[]>([]);
  const [location, setLocation] = useState<BaseEnum>();
  const [description, setDescription] = useState<string>();
  const [actionTaken, setActionTaken] = useState<IncidentAction[]>([]);
  const lastActionTaken = (usePrevious(
    actionTaken,
  ) as unknown) as IncidentAction[];
  const [actionDate, setActionDate] = useState<Date>();
  const [actionOther, setActionOther] = useState<string>();
  const [entityNotified, setEntityNotified] = useState<BaseEnum[]>([]);
  const [change, setChange] = useState<string>();
  const [needsAdditionalHelp, setNeedsAdditionalHelp] = useState<boolean>();
  const [kindOfHelp, setKindOfHelp] = useState<BaseEnum>();

  const [dme, setDME] = useState<boolean>();
  const [dmeType, setDMEType] = useState<BaseEnum[]>([]);
  const [anticoagulant, setAnticoagulant] = useState<BaseEnum[]>([]);
  const [memberEvaluated, setMemberEvaluated] = useState<boolean>();

  const [admitDate, setAdmitDate] = useState<Date>();
  const [dischargeDate, setDischargeDate] = useState<Date>();
  const [facilityName, setFacilityName] = useState<string>();
  const [followUpAppt, setFollowUpAppt] = useState<BaseEnum[]>();
  const [medicationChange, setMedicationChange] = useState<boolean>();
  const [medicationChangeDesc, setMedicationChangeDesc] = useState<string>();

  const getInitialData = async () => {
    setLoadingData(true);
    const [
      incidentTypes,
      locations,
      actions,
      entities,
      kindOfHelp,
      involved,
      dmeTypes,
      anticoagulants,
      followUp,
    ] = await Promise.all([
      CacheServices.getIncidentTypes(),
      CacheServices.getIncidentLocations(),
      CacheServices.getIncidentActions(),
      CacheServices.getIncidentEntities(),
      CacheServices.getIncidentHelpOptions(),
      CacheServices.getIncidentInvolvedActors(),
      CacheServices.getIncidentDMETypes(),
      CacheServices.getIncidentAnticoagulants(),
      CacheServices.getIncidentFollowUp(),
    ]);
    setIncidentTypes(incidentTypes);
    setOtherIncidentTypes(IncidentType.getOtherModalTypes(incidentTypes));
    setLocationOptions(locations);
    setActionsTaken(actions);
    setEntities(entities);
    setKindOfHelpOptions(kindOfHelp);
    setInvolvedOptions(involved);
    if (!incident?.involvedActors?.length) {
      setInvolved(
        involved.filter(({ id }) => id === IncidentInvolvedActor.MEMBER),
      );
    }
    setDMETypeOptions(dmeTypes);
    setAnticoagulantOptions(anticoagulants);
    setFollowUpOptions(followUp);
    setLoadingData(false);
  };

  const isChangeOfCondition = !!incidentType?.find(
    ({ id }) => id === IncidentType.CHANGE_OF_CONDITION,
  );

  const hasActions = !!actionTaken?.length;

  const isStateFederal = !!actionTaken?.find(
    ({ id }) => id === IncidentAction.STATE_FEDERAL,
  );

  const isOtherAction = !!actionTaken?.find(
    ({ id }) => id === IncidentAction.OTHER,
  );

  const isFall = !!incidentType?.find(({ id }) =>
    [IncidentType.FALL_INJURY, IncidentType.FALL_NO_INJURY].includes(id || ''),
  );

  const isHosp = !!incidentType?.find(({ id }) =>
    [IncidentType.ER_VISIT, IncidentType.HOSPITALIZATION].includes(id),
  );

  const isFallInjury = !!incidentType?.find(
    ({ id }) => IncidentType.FALL_INJURY === id,
  );

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (lastActionTaken?.length === 0 && actionTaken?.length > 0) {
      setActionDate(reportDate);
    }
  }, [actionTaken]);

  useEffect(() => {
    setActionTaken(incident?.actions || []);
    setActionDate(incident?.actionDate?.toDate());
    setActionOther(incident?.actionOther);
    setDescription(incident?.description);
    setEntityNotified(incident?.federalEntities || []);
    setIncidentDate(incident?.incidentDate?.toDate());
    setInvolved(incident?.involvedActors || []);
    setLocation(incident?.location);
    setReportDate(incident?.reportedDate?.toDate() || new Date());
    setIncidentType(incident?.types || []);

    // Change of condition type
    setChange(incident?.changeOfCondition?.description);
    setNeedsAdditionalHelp(incident?.changeOfCondition?.additionalHelp);
    setKindOfHelp(incident?.changeOfCondition?.helpType);

    // Fall type
    setDME(incident?.fall?.dme);
    setDMEType(incident?.fall?.dmeTypes || []);
    setAnticoagulant(incident?.fall?.anticoagulants || []);
    setMemberEvaluated(incident?.fall?.evaluated);

    // Hospitalization type
    setAdmitDate(incident?.hospitalization?.admitDate?.toDate());
    setDischargeDate(incident?.hospitalization?.dischargeDate?.toDate());
    setFacilityName(incident?.hospitalization?.facilityName);
    setFollowUpAppt(incident?.hospitalization?.followUpAppointmentTypes || []);
    setMedicationChange(incident?.hospitalization?.medicationChange);
    setMedicationChangeDesc(
      incident?.hospitalization?.medicationChangeDescription,
    );
  }, [incident]);

  useEffect(() => {
    if (defaultTypeId && otherIncidentTypes.length) {
      setIncidentType(
        otherIncidentTypes.filter(({ id }) => id === defaultTypeId),
      );
    }
  }, [defaultTypeId, otherIncidentTypes]);

  useEffect(() => {
    if (defaultFields) {
      setDescription(defaultFields?.description);
      setIncidentDate(defaultFields?.incidentDate);
      setReportDate(defaultFields?.reportedDate || new Date());

      // FIXME: Since the Fall Event extra data does not share the ids with the Fall Incident extra data,
      // we must match the values comparing the descriptions of both fields

      // Fall Fields
      setDME(defaultFields?.fall?.dme);
      const DMETypeDescriptions = defaultFields?.fall?.dmeTypes?.map(
        ({ description }) => description,
      );
      setDMEType(
        dmeTypeOtions?.filter(({ description }) =>
          DMETypeDescriptions?.includes(description),
        ),
      );
      const anticoagulantDescriptions = defaultFields?.fall?.anticoagulantIds?.map(
        ({ description }) => description,
      );
      setAnticoagulant(
        anticoagulantOptions?.filter(({ description }) =>
          anticoagulantDescriptions?.includes(description),
        ) || [],
      );
      setMemberEvaluated(defaultFields?.fall?.evaluated);

      // Hospitalization Fields
      setAdmitDate(defaultFields?.hospitalization?.admitDate);
      setDischargeDate(defaultFields?.hospitalization?.dischargeDate);
      setFacilityName(defaultFields?.hospitalization?.facilityName);
      const followUpDescriptions = defaultFields?.hospitalization?.followUpAppointmentTypes?.map(
        ({ description }) => description,
      );
      setFollowUpAppt(
        followUpOptions?.filter(({ description }) =>
          followUpDescriptions?.includes(description),
        ) || [],
      );
      setMedicationChange(defaultFields?.hospitalization?.medicationChange);
      setMedicationChangeDesc(
        defaultFields?.hospitalization?.medicationChangeDescription,
      );
    }
  }, [defaultFields, anticoagulantOptions, dmeTypeOtions, followUpOptions]);

  useEffect(() => {
    onChange({
      actions: actionTaken,
      actionDate: hasActions ? actionDate : undefined,
      actionOther,
      changeOfCondition: isChangeOfCondition
        ? {
            changeDescription: change,
            additionalHelp: needsAdditionalHelp,
            additionalHelpType: needsAdditionalHelp ? kindOfHelp : undefined,
          }
        : null,
      description,
      fall: isFall
        ? {
            dme,
            dmeTypes: dme ? dmeType : undefined,
            anticoagulantIds: anticoagulant,
            evaluated: isFallInjury ? memberEvaluated : undefined,
          }
        : null,
      federalEntities: isStateFederal ? entityNotified : undefined,
      hospitalization: isHosp
        ? {
            admitDate,
            dischargeDate,
            facilityName,
            followUpAppointmentTypes: followUpAppt,
            medicationChange,
            medicationChangeDescription: medicationChange
              ? medicationChangeDesc
              : undefined,
          }
        : undefined,
      incidentDate,
      involvedActors: involved,
      location,
      reportedDate: reportDate,
      types: incidentType,
    });
  }, [
    actionDate,
    actionOther,
    actionTaken,
    admitDate,
    anticoagulant,
    change,
    description,
    dischargeDate,
    dme,
    dmeType,
    entityNotified,
    facilityName,
    followUpAppt,
    incidentDate,
    incidentType,
    involved,
    kindOfHelp,
    location,
    medicationChange,
    medicationChangeDesc,
    memberEvaluated,
    needsAdditionalHelp,
    reportDate,
  ]);

  return (
    <div className={classNames(className, 'grid-wrapper fit')}>
      {!defaultTypeId && (
        <Select
          className="grid-span-12"
          data-cy="incident-type"
          disableClearable={isFall}
          disabled={disabled}
          error={submitted && !incidentType?.length}
          getItemLabel={({ description }: BaseEnum) => description}
          items={
            isFall
              ? IncidentType.getFallModalTypes(incidentTypes)
              : IncidentType.getModalTypes(incidentTypes)
          }
          label={translate('incidents.common.incidentType')}
          loading={loadingData}
          multiple={!isFall}
          note={
            readonlyDescription || isEventWorkflow ? undefined : (
              <>
                <b>{translate('common.note')}</b>:{' '}
                {translate('incidents.modals.fallNote')}
              </>
            )
          }
          onChange={(value: BaseEnum | BaseEnum[]) => {
            setIncidentType(value instanceof Array ? value : [value]);
          }}
          value={
            (isFall ? incidentType && incidentType[0] : incidentType) || []
          }
          readOnly={!!defaultTypeId || isHosp}
          required={!disabled}
        />
      )}

      {isChangeOfCondition && (
        <>
          <TextInput
            className="grid-span-6"
            data-cy="incident-change"
            disabled={disabled}
            error={submitted && !change}
            label={translate('incidents.common.changeQuestion')}
            onChange={setChange}
            value={change || ''}
            required={!disabled}
          />
          <Select.Choice
            className="grid-span-6"
            data-cy="incident-additional-help"
            disabled={disabled}
            error={submitted && needsAdditionalHelp === undefined}
            onChange={(option) => setNeedsAdditionalHelp(!!option)}
            label={translate('incidents.common.needsAdditionalHelpQuestion')}
            value={needsAdditionalHelp}
            required={!disabled}
          />
          {needsAdditionalHelp && (
            <Select
              className="grid-span-12"
              data-cy="incident-kind-help"
              disabled={disabled}
              error={submitted && !kindOfHelp}
              getItemLabel={({ description }: BaseEnum) => description}
              items={kindOfHelpOptions}
              label={translate('incidents.common.kindHelpQuestion')}
              loading={loadingData}
              onChange={setKindOfHelp}
              value={kindOfHelp}
              required={!disabled}
            />
          )}
          <HorizontalRule className="grid-span-12" />
        </>
      )}

      {!defaultFields?.fall && isFall && (
        <>
          <Select.Choice
            className="grid-span-6"
            data-cy="incident-dme"
            disabled={disabled}
            label={translate('incidents.common.dmeQuestion')}
            onChange={setDME}
            value={dme}
          />
          {dme ? (
            <Select
              className="grid-span-6"
              data-cy="incident-dme-type"
              disabled={disabled}
              getItemLabel={({ description }: BaseEnum) => description}
              items={dmeTypeOtions}
              label={translate('incidents.common.dmeTypeQuestion')}
              limitTags={1}
              loading={loadingData}
              multiple
              onChange={setDMEType}
              value={dmeType}
            />
          ) : (
            <div className="grid-span-6" />
          )}
          <Select
            className="grid-span-6"
            data-cy="incident-anticoagulant"
            disabled={disabled}
            getItemLabel={({ description }: BaseEnum) => description}
            items={anticoagulantOptions}
            label={translate('incidents.common.anticoagulantQuestion')}
            limitTags={1}
            multiple
            onChange={setAnticoagulant}
            value={anticoagulant}
          />
          {isFallInjury && (
            <Select.Choice
              className="grid-span-6"
              data-cy="incident-member-evaluated"
              disabled={disabled}
              label={translate('incidents.common.evaluatedQuestion')}
              onChange={setMemberEvaluated}
              value={memberEvaluated}
            />
          )}
          <HorizontalRule className="grid-span-12" />
        </>
      )}

      {!defaultFields?.hospitalization && isHosp && (
        <>
          <Select.Choice
            className="grid-span-6"
            data-cy="incident-medication-change"
            disableClearable
            disabled={disabled}
            error={submitted && !medicationChange}
            label={translate('incidents.common.medicationChange')}
            onChange={setMedicationChange}
            required={!disabled}
            value={medicationChange}
          />
          {medicationChange ? (
            <TextInput
              className="grid-span-6"
              data-cy="incident-medication-change-desc"
              disabled={disabled}
              error={submitted && !medicationChangeDesc}
              label={translate('incidents.common.medicationChangeDesc')}
              onChange={setMedicationChangeDesc}
              required={!disabled}
              value={medicationChangeDesc || ''}
            />
          ) : (
            <div className="grid-span-6" />
          )}
          <DateTimePicker
            className="grid-span-6"
            data-cy="incident-admit-date"
            disabled={disabled}
            label={translate('incidents.common.admitDate')}
            maxDate={new Date()}
            onChange={setAdmitDate}
            type="date"
            value={admitDate}
          />
          <DateTimePicker
            className="grid-span-6"
            data-cy="incident-discharge-date"
            disabled={disabled}
            label={translate('incidents.common.dischargeDate')}
            maxDate={new Date()}
            onChange={setDischargeDate}
            type="date"
            value={dischargeDate}
          />
          <Select
            className="grid-span-6"
            data-cy="incident-follow-up"
            disableClearable
            disabled={disabled}
            error={submitted && !followUpAppt}
            getItemLabel={({ description }: BaseEnum) => description}
            items={followUpOptions}
            label={translate('incidents.common.followUp')}
            limitTags={1}
            multiple
            onChange={setFollowUpAppt}
            required={!disabled}
            value={followUpAppt || []}
          />
          <TextInput
            className="grid-span-6"
            data-cy="incident-medication-facility-name"
            disabled={disabled}
            label={translate('incidents.common.facilityName')}
            onChange={setFacilityName}
            value={facilityName || ''}
          />
          <HorizontalRule className="grid-span-12" />
        </>
      )}

      {!defaultFields?.incidentDate && (
        <>
          <DateTimePicker
            className="grid-span-6"
            data-cy="incident-date"
            disabled={disabled}
            error={submitted && !incidentDate}
            label={translate('incidents.common.incidentDate')}
            maxDate={new Date()}
            onChange={setIncidentDate}
            required={!disabled}
            type="date"
            value={incidentDate}
          />
          <DateTimePicker
            className="grid-span-6"
            data-cy="report-date"
            disabled={disabled}
            error={submitted && !reportDate}
            label={translate('incidents.common.reportDate')}
            maxDate={new Date()}
            onChange={setReportDate}
            required={!disabled}
            type="date"
            value={reportDate}
          />
        </>
      )}

      {!isHosp && (
        <>
          <Select
            className="grid-span-6"
            data-cy="incident-involved"
            disabled={disabled}
            disableClearable
            error={submitted && !involved?.length}
            getItemLabel={({ description }: IncidentInvolvedActor) =>
              description
            }
            getItemDisabled={({ id }: IncidentInvolvedActor) =>
              id === IncidentInvolvedActor.MEMBER
            }
            items={involvedOptions}
            label={translate('incidents.common.involvedQuestion')}
            limitTags={1}
            loading={loadingData}
            multiple
            onChange={setInvolved}
            value={involved}
            required={!disabled}
          />

          <Select
            className="grid-span-6"
            data-cy="incident-location"
            disabled={disabled}
            error={submitted && !location}
            getItemLabel={({ description }: BaseEnum) => description}
            items={locationOptions}
            label={translate('incidents.common.locationQuestion')}
            loading={loadingData}
            onChange={setLocation}
            value={location}
            required={!disabled}
          />
        </>
      )}

      {!defaultFields?.description && (
        <TextArea
          className="grid-span-12"
          data-cy="incident-description"
          disabled={disabled}
          error={submitted && !description}
          label={translate(
            `incidents.common.${
              readonlyDescription ? 'readonlyDescription' : 'description'
            }`,
          )}
          onChange={setDescription}
          rows={3}
          value={description || ''}
          readOnly={readonlyDescription}
          required={!disabled}
        />
      )}

      <Select
        className="grid-span-12"
        data-cy="incident-actions"
        disabled={disabled}
        getItemLabel={({ description }: BaseEnum) => description}
        items={actionsTaken}
        label={translate('incidents.common.immediateActions')}
        loading={loadingData}
        multiple
        onChange={setActionTaken}
        value={actionTaken}
      />

      {hasActions && (
        <DateTimePicker
          className="grid-span-6"
          data-cy="incident-action-date"
          disabled={disabled}
          error={submitted && !actionDate}
          label={translate('incidents.common.actionsDate')}
          maxDate={new Date()}
          onChange={setActionDate}
          required={!disabled}
          type="date"
          value={actionDate}
        />
      )}
      {isOtherAction && (
        <TextInput
          className="grid-span-6"
          data-cy="incident-action-other"
          disabled={disabled}
          error={submitted && !actionOther}
          label={translate('incidents.common.actionsOther')}
          onChange={setActionOther}
          required={!disabled}
          value={actionOther || ''}
        />
      )}
      {isStateFederal && (
        <Select
          className="grid-span-6"
          data-cy="incident-entity"
          disabled={disabled}
          error={submitted && !entityNotified?.length}
          getItemLabel={({ description }: BaseEnum) => description}
          items={entitites}
          label={translate('incidents.common.entityNotified')}
          limitTags={1}
          loading={loadingData}
          multiple
          onChange={setEntityNotified}
          required={!disabled}
          value={entityNotified}
        />
      )}
    </div>
  );
};
