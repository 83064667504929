import React from 'react';
import { uniqueId } from 'lodash';
import { OverlayTrigger, Tooltip as BTooltip } from 'react-bootstrap';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  'data-cy'?: string;
  label?: string;
  position?: 'bottom' | 'top' | 'left' | 'right';
  arrow?: boolean;
}

export const Tooltip = ({ children, ...props }: Props) => {
  const { arrow, 'data-cy': dataCy, label, position = 'bottom' } = props;

  const tooltip = label && (
    <BTooltip
      id={`tooltip-${uniqueId()}`}
      bsClass={arrow ? 'tooltip' : 'tooltip-no-arrow'}
      data-cy={dataCy}
    >
      {label}
    </BTooltip>
  );

  return (
    <OverlayTrigger
      delayShow={350}
      delayHide={150}
      overlay={tooltip}
      placement={position}
    >
      <span>{children}</span>
    </OverlayTrigger>
  );
};

export default Tooltip;
