import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import {
  ToggleButton as MToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Fonts } from 'styleguide-v2/src/styles/Variables';

import { Colors } from '../../styles/Colors';
import { Label } from '../Label';

type ButtonSize = 'small' | 'medium' | 'xs';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  horizontal: {
    alignItems: 'center',
    flexFlow: 'row',
    gap: '0.5rem',
  },
  toggle: {
    height: '4.5rem',
    '&.small': {
      height: '3.5rem',
      '& > button.MuiButtonBase-root': {
        minWidth: '6.5rem',
        padding: '0.75rem',
      },
    },
    '&.xs': {
      height: '2.5rem',
      '& > button.MuiButtonBase-root': {
        fontSize: `calc(${Fonts.fontSize} * .75)`,
        minWidth: '5.5rem',
        padding: '0.5rem',
      },
    },
  },
  outlined: {
    '&&': {
      '& .MuiButtonBase-root:not([disabled])': {
        borderColor: Colors.mint,
      },
      '& .MuiButtonBase-root:not([disabled]).Mui-selected': {
        backgroundColor: Colors.mint,
        color: Colors.textGreen,
      },
      '& .MuiButtonBase-root:not([disabled]):not(.Mui-selected)': {
        backgroundColor: Colors.paleGreen,
        color: Colors.textLightGreen,
      },
    },
  },
});

export type ToggleItem = {
  'data-cy'?: string;
  disabled?: boolean;
  label: string;
  value?: any;
};

interface Props {
  className?: string;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  'data-cy'?: string;
  disabled?: boolean;
  items: ToggleItem[];
  label?: string;
  layout?: 'horizontal' | 'vertical';
  onChange: (value: any) => void;
  value?: any;
  size?: ButtonSize;
  type?: 'contained' | 'outlined';
}

export const ToggleButton = ({
  className,
  color,
  'data-cy': dataCy,
  disabled,
  items,
  label,
  layout = 'vertical',
  value,
  onChange,
  size = 'medium',
  type = 'contained',
}: Props) => {
  const styles = useStyles();
  const classes = classnames(
    className,
    styles.container,
    type === 'outlined' && styles.outlined,
    layout === 'horizontal' && styles.horizontal,
  );
  const [currentValue, setValue] = useState(
    value !== undefined ? value : items[0].value || items[0].label,
  );

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <div className={classes} data-cy={dataCy}>
      {label && (
        <Label noMargin={layout === 'horizontal'}>
          {label}
          {layout === 'horizontal' && ':'}
        </Label>
      )}
      <ToggleButtonGroup
        color={color}
        className={classnames(styles.toggle, size)}
        data-cy={dataCy}
        exclusive
        value={currentValue}
        disabled={disabled}
        onChange={(_, newValue: any) => {
          if (newValue) {
            onChange(newValue);
            setValue(newValue);
          }
        }}
      >
        {items.map(
          ({
            disabled: optionDisabled,
            label: itemLabel,
            value: optionValue,
          }) => (
            <MToggleButton
              color={color}
              sx={{ minWidth: '7.5rem' }}
              className="mbtn"
              key={optionValue}
              value={optionValue || itemLabel}
              disabled={optionDisabled}
            >
              {itemLabel}
            </MToggleButton>
          ),
        )}
      </ToggleButtonGroup>
    </div>
  );
};

export default ToggleButton;
