import React from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import { Spinner } from 'styleguide';
import { BackgroundColors, Colors } from 'styleguide-v2';

import { useSelector } from 'dash/src/redux/store';

const useStyles = makeStyles({
  container: {
    padding: '0.5rem 2rem 1rem',
    marginLeft: '1rem',
    backgroundColor: BackgroundColors.lightMint,
    borderLeft: `3px solid ${Colors.lightMint}`,
    width: 'fit-content',
  },
});

export const EventsInfo = () => {
  const styles = useStyles();
  const total = useSelector((state) => state.eventsSlice.total);
  const opened = useSelector((state) => state.eventsSlice.opened);
  const loading = useSelector((state) => state.eventsSlice.loadingCount);

  if (loading) {
    return <Spinner />;
  }

  return total ? (
    <div className={styles.container}>
      <h4>{translate('memberProfile.header.events')}</h4>
      <div>
        <p className="small">
          {translate('memberProfile.header.openedEvents', {
            count: opened,
          })}
          {', '}
          {translate('memberProfile.header.totalEvents', {
            count: total,
          })}
        </p>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default EventsInfo;
