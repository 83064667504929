import classNames from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { BackgroundColors } from '../../styles/Colors';

type Props = {
  className?: string;
};

const useStyles = makeStyles({
  separator: {
    borderRadius: 1,
    color: BackgroundColors.grayLine,
    margin: '0.25rem',
    width: '100%',
  },
});

export const HorizontalRule = ({ className }: Props) => {
  const styles = useStyles();

  return <hr className={classNames(className, styles.separator)} />;
};
