import classnames from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { CommunityResource } from '@vestahealthcare/common/models';
import { format } from '@vestahealthcare/common/utils/phone';

import { Fonts } from 'styleguide-v2/src/styles/Variables';

type Props = {
  className?: string;
  'data-cy'?: string;
  communityResource: CommunityResource;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  name: {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    fontWeight: 500,
  },
  extraInfo: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 400,
  },
});

export const SelectCommunityResourceOption = ({
  className,
  'data-cy': dataCy,
  communityResource,
}: Props) => {
  const styles = useStyles();
  const classes = classnames(className, styles.container);
  const { name, type, specialities, phone } = communityResource;

  return (
    <div className={classes} data-cy={dataCy}>
      <span className={styles.name}>{name}</span>
      <span className={styles.extraInfo}>
        {type.description}
        {' - '}
        {specialities?.map(({ description }) => description).join(', ')}
      </span>
      <span className={styles.extraInfo}>{format(phone)}</span>
    </div>
  );
};

export default SelectCommunityResourceOption;
