import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import { Sidebar } from 'styleguide';
import { Chip } from 'styleguide-v2';

import { fetchSMSUnreadCount } from 'dash/src/redux/slices/ChatSlice';
import { useSelector } from 'dash/src/redux/store';

const useStyles = makeStyles({
  smsIndicator: {
    position: 'absolute',
    right: '1.6rem',
  },
});

export const ClinicalDashboardSidebar = () => {
  const REFRESH_INTERVAL = 60 * 1000;
  const { pathname } = useLocation();
  const { showCcmClinical, showPodReporting, showTocPage } = useFlags();
  const styles = useStyles();

  const dispatch = useDispatch();
  const smsUnreadCount = useSelector((state) => state.chatSlice.smsUnreadCount);

  const SMS_LINK = {
    active: pathname.includes('/dashboard/chats/sms'),
    href: '/dashboard/chats/sms?status=OPEN&read=false',
    text: translate('sidebar.sms'),
    dataCy: 'sidebar-sms-link',
    children: <></>,
  };

  const LINKS = {
    header: 'Clinical',
    links: [
      {
        active: pathname.includes('/dashboard/tasks'),
        href: '/dashboard/tasks',
        text: translate('clinicalDashboard.sidebar.tasks'),
        dataCy: 'sidebar-tasks-link',
      },
      {
        active: pathname.includes('/dashboard/events'),
        href: '/dashboard/events',
        text: translate('clinicalDashboard.sidebar.events'),
        dataCy: 'sidebar-events-link',
      },
      {
        active: pathname.includes('members'),
        href: '/dashboard/members',
        text: translate('clinicalDashboard.sidebar.members'),
        dataCy: 'sidebar-members-link',
      },
    ],
  };

  LINKS.links.push(SMS_LINK);

  LINKS.links.push(
    {
      active: pathname.includes('/dashboard/health-reports'),
      href: '/dashboard/health-reports',
      text: translate('clinicalDashboard.sidebar.healthReports'),
      dataCy: 'sidebar-health-reports-link',
    },
    {
      active: pathname.includes('/dashboard/encounters'),
      href: '/dashboard/encounters',
      text: translate('clinicalDashboard.sidebar.encounters'),
      dataCy: 'sidebar-encounters-link',
    },
  );

  if (showCcmClinical) {
    LINKS.links.push({
      active: pathname.includes('/dashboard/ccm'),
      href: '/dashboard/ccm',
      text: translate('clinicalDashboard.sidebar.ccm'),
      dataCy: 'sidebar-ccm-link',
    });
  }

  if (showPodReporting) {
    LINKS?.links?.push({
      active: pathname.includes('pod-reporting'),
      href: '/dashboard/pod-reporting',
      text: translate('clinicalDashboard.sidebar.podReporting'),
      dataCy: 'sidebar-pod-reporting-link',
    });
  }

  if (showTocPage) {
    LINKS?.links?.push({
      active: pathname.includes('toc-list'),
      href: '/dashboard/toc-list',
      text: translate('clinicalDashboard.sidebar.toc'),
      dataCy: 'sidebar-toc-list-link',
    });
  }

  const fetchSMSIndicator = async () => {
    dispatch(await fetchSMSUnreadCount());
  };

  useEffect(() => {
    fetchSMSIndicator();
    const interval = setInterval(() => fetchSMSIndicator(), REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  const getLinks = (): any[] => {
    if (smsUnreadCount) {
      SMS_LINK.children = (
        <>
          &nbsp;
          <Chip
            className={styles.smsIndicator}
            label={smsUnreadCount}
            color="secondary"
          />
        </>
      );
    } else {
      SMS_LINK.children = <></>;
    }
    return [
      {
        header: LINKS.header,
        links: [...LINKS.links],
      },
    ];
  };

  return <Sidebar collapsible sections={getLinks()} />;
};

export default ClinicalDashboardSidebar;
