import Api from 'dash/src/services/Api';
import {
  CareTrack,
  MemberDocument,
  MemberGoal,
} from '@vestahealthcare/common/models';
import {
  CareTrackSource,
  DocumentType,
  GoalStatus,
  GoalType,
  RiskLevel,
} from '@vestahealthcare/common/enums';
import { Moment } from 'moment-timezone';

export const fetchCareTracks = async (
  patientId: number | string,
): Promise<CareTrack[]> => {
  const { careTracks } = await Api.getv2(
    `/patients/${patientId}/risk-programs`,
  );
  return careTracks.items.map((i: any) => new CareTrack(i));
};

export const setRiskLevel = async ({
  careTrack,
  evaluationNote,
  patientId,
  riskLevel,
  sources,
}: {
  careTrack: CareTrack;
  evaluationNote?: string;
  patientId: number | string;
  sources?: CareTrackSource[];
  riskLevel: RiskLevel;
}) => {
  const params = {
    evaluationNote,
    riskLevel: riskLevel.value,
    careTrackType: careTrack.type.value,
    sources: sources && sources.map((source) => source.valueOf()),
  };
  const path = careTrack.isEvaluated
    ? `/patients/${patientId}/risk-programs/${careTrack.id}/actions/reevaluate`
    : `/patients/${patientId}/risk-programs/`;
  const { careTrack: ct } = await Api.postv2JSON(path, params);
  return new CareTrack(ct);
};

export const markReviewed = async (
  patientId: number | string,
  careTrack: CareTrack,
): Promise<CareTrack> => {
  const { careTrack: ct } = await Api.postv2JSON(
    `/patients/${patientId}/risk-programs/${careTrack.id}/actions/review`,
  );
  return new CareTrack(ct);
};

export const fetchMemberGoals = async (
  memberId: number | string,
): Promise<MemberGoal[]> => {
  const { goals } = await Api.getv2(`/patients/${memberId}/goals`);
  return goals.items.map((goal: any) => new MemberGoal(goal));
};

interface GoalParams {
  otherType?: string;
  outcome?: string;
  status: GoalStatus;
  targetDate: Moment;
  title: string;
  type: GoalType;
}

export const addMemberGoal = async (
  memberId: number | string,
  params: GoalParams,
) => {
  const transformedParams = {
    otherType: params.otherType,
    outcome: params.outcome,
    status: params.status.valueOf(),
    targetDate: params.targetDate.format('YYYY-MM-DD'),
    title: params.title,
    type: params.type.valueOf(),
  };
  return Api.postv2JSON(`/patients/${memberId}/goals`, transformedParams);
};

export const editMemberGoal = async (
  memberId: number | string,
  goalId: number | string,
  params: GoalParams,
) => {
  const transformedParams = {
    otherType: params.otherType,
    outcome: params.outcome,
    status: params.status.valueOf(),
    targetDate: params.targetDate.format('YYYY-MM-DD'),
    title: params.title,
    type: params.type.valueOf(),
  };
  return Api.putv2JSON(
    `/patients/${memberId}/goals/${goalId}`,
    transformedParams,
  );
};

interface MemberDocumentPostParams {
  type: DocumentType;
  additionalText?: string;
  preview?: boolean;
}

export const generateMemberDocument = async (
  memberId: number | string,
  params: MemberDocumentPostParams,
) => {
  const transformedParams = {
    type: params.type.valueOf(),
    additionalText: params.additionalText,
    preview: false,
  };
  return Api.postv2JSON(`/patients/${memberId}/documents`, transformedParams);
};

export const previewMemberDocument = async (
  memberId: number | string,
  params: MemberDocumentPostParams,
) => {
  const transformedParams = {
    type: params.type.valueOf(),
    additionalText: params.additionalText,
    preview: true,
  };
  const pdfBlob = await Api.postv2PDF(
    `/patients/${memberId}/documents/preview`,
    transformedParams,
  );
  return URL.createObjectURL(pdfBlob);
};

export const fetchAllMemberDocuments = async (
  memberId: number | string,
): Promise<MemberDocument[]> => {
  const { memberDocuments } = await Api.getv2(
    `/patients/${memberId}/documents`,
  );
  return memberDocuments.items.map(
    (item: MemberDocument) => new MemberDocument(item),
  );
};

export const fetchDocumentBlob = async (
  memberId: number | string,
  documentId: number,
) => await Api.getv2Blob(`/patients/${memberId}/documents/${documentId}`);
