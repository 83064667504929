import React, { useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';

import { NoteSection } from 'dash/src/components/NoteSection';
import NoteModalAdd from 'dash/src/components/NoteSection/NoteModalAdd';
import { updateNotes } from 'dash/src/services/PatientServices';

interface Props {
  patient: Patient;
  onSubmit: (patient: Patient) => void;
}

const NOTE_MAX_LENGTH = 90;

export const PatientNote = (props: Props) => {
  const { patient, onSubmit } = props;
  const [showAddNoteModal, setShowAddNoteModal] = useState(false);

  return (
    <div style={{ marginRight: 'auto', marginBottom: 5 }}>
      <NoteSection
        color="warning"
        editable={patient?.isEditable()}
        note={patient?.notes}
        onEdit={() => setShowAddNoteModal(true)}
      />
      <NoteModalAdd
        maxLength={NOTE_MAX_LENGTH}
        note={patient?.notes}
        open={showAddNoteModal}
        onEdit={async (notes) => {
          const updatedPatient = await updateNotes(patient.id, notes || '');
          onSubmit(updatedPatient);
        }}
        onClose={() => setShowAddNoteModal(false)}
        title={translate(`patient.modal.editNotes`, {
          name: patient.fullName,
        })}
      />
    </div>
  );
};
