import React, { useState } from 'react';

import PhoneType from '@vestahealthcare/common/enums/PhoneType';
import PhoneModel from '@vestahealthcare/common/models/Phone';

import {
  Button,
  MinMaxInput,
  NumericInput,
  Panel,
  Phone,
  PhoneList,
  PhoneNumber,
  TextInput,
} from 'styleguide';
import { ErrorType as PhoneErrorType } from 'styleguide/src/components/Phone';

const StyleguideFields = () => {
  const [disabled, setDisabled] = useState(false);
  const [basicText2, setBasicText2] = useState<string>();
  const [basicText3, setBasicText3] = useState<string>();
  const [basicNumber, setBasicNumber] = useState<number>();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phone, setPhone] = useState<PhoneModel>();
  const [phones, setPhones] = useState<PhoneModel[]>();
  const [minMaxInput, setMinMaxInput] = useState<string>();
  const [showError, setShowError] = useState(false);

  return (
    <Panel>
      <Panel.Heading>
        <h2>Fields</h2>
        <div className="right-side">
          <Button color="secondary" onClick={() => setShowError(!showError)}>
            {showError ? 'Hide' : 'Show'} Errors
          </Button>
          <Button color="secondary" onClick={() => setDisabled(!disabled)}>
            {disabled ? 'Enable' : 'Disable'}
          </Button>
        </div>
      </Panel.Heading>
      <Panel.Body className="grid-wrapper">
        <div className="grid-span-12">
          <TextInput
            className="grid-span-6"
            disabled={disabled}
            errorText="Validation is based on string length."
            label="Text Input (new)"
            onBlur={setBasicText2}
            required
            showError={showError}
            value={basicText2}
          />
        </div>

        <div className="grid-span-12">
          <TextInput
            className="grid-span-6"
            disabled={disabled}
            errorText="Validation is based on string length."
            label="Text Input (new, multiline)"
            multiline={3}
            onBlur={setBasicText3}
            required
            showError={showError}
            value={basicText3}
          />
        </div>

        <div className="grid-span-12">
          <NumericInput
            className="grid-span-6"
            data-cy="styleguide-integer-field"
            disabled={disabled}
            errorText="Valid if positive whole number"
            value={basicNumber}
            label="Integer Input"
            onChange={setBasicNumber}
            required
            showError={showError}
          />
        </div>

        <div className="grid-span-12">
          <NumericInput
            allowFloat
            className="grid-span-6"
            data-cy="styleguide-decimal-field"
            disabled={disabled}
            errorText="Valid if greater than zero."
            value={basicNumber}
            label="Decimal Input"
            onChange={setBasicNumber}
            required
            showError={showError}
          />
        </div>

        <div className="grid-span-12">
          <PhoneNumber
            className="grid-span-6"
            disabled={disabled}
            errorText="Valid if 10 digits"
            value={phoneNumber}
            label="Phone Number"
            onBlur={setPhoneNumber}
            placeholder="Enter 10-digit phone number"
            required
            showError={showError}
          />
        </div>

        <div className="grid-span-12">
          <Phone
            allowedTypes={[PhoneType.HOME, PhoneType.CELL]}
            disabled={disabled}
            errorText={[
              {
                index: 0,
                message: 'Valid if 10 digits',
                type: PhoneErrorType.NUMBER,
              },
              { index: 0, message: 'Choose a type', type: PhoneErrorType.TYPE },
            ]}
            labelNumber="Phone Number"
            labelType="Phone Type"
            onChange={setPhone}
            placeholderNumber="Enter a phone number"
            placeholderType="Select type of number"
            required
            showError={showError}
            value={phone}
          />
        </div>

        <div className="grid-span-12">
          <PhoneList
            allowedTypes={PhoneType.asMemberArray}
            errors={phones?.map((p: PhoneModel, index: number) => ({
              index,
              message: 'Valid if 10 digits',
              type: PhoneErrorType.NUMBER,
            }))}
            labelNumber="Phone Number"
            labelType="Phone Type"
            onChange={setPhones}
            placeholderNumber="Enter a phone number"
            placeholderType="Select type of number"
            showError={showError}
            value={phones}
          />
        </div>

        <div className="grid-span-12">
          <MinMaxInput
            className="grid-span-6"
            disabled={disabled}
            errorText="Error"
            defaultLowerValue={70}
            defaultUpperValue={140}
            label="Min / Max"
            onChange={setMinMaxInput}
            required
            showError={showError}
            value={minMaxInput}
          />
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default StyleguideFields;
