import React from 'react';
import { FieldProps } from 'react-jsonschema-form';
import { Phone as PhoneComponent } from 'styleguide';
import PhoneType from '@vestahealthcare/common/enums/PhoneType';
import { translate } from '@vestahealthcare/common/i18n';
import Phone from '@vestahealthcare/common/models/Phone';
import { ErrorWithType } from '@vestahealthcare/common/models/ErrorWithType';

const PrimaryPhoneField = (props: FieldProps) => {
  const { formData, onChange, rawErrors, required } = props;

  // TODO: this error handling is really bad, could be done better with "errorSchema" or similar?
  const hasErrors = !!rawErrors && !!rawErrors.length;
  const errors: ErrorWithType[] = rawErrors?.map(
    (error: string, index: number) => {
      const errorParts = error.split('::');
      const errorType = errorParts[0];
      const errorMessage = errorParts[1];
      return {
        index,
        message: errorMessage,
        type: errorType,
      };
    },
  );

  return (
    <PhoneComponent
      allowedTypes={[PhoneType.CELL]}
      errorText={errors}
      labelNumber={translate('formBuilder.labelPrimaryPhoneNumber')}
      labelType={translate('formBuilder.labelPrimaryPhoneType')}
      // sending "Phone" type to "onChange" will not understand the "type" field, it needs to be plain string
      onChange={({ number, type }: Phone) =>
        onChange({ number, type: type?.value })
      }
      placeholderNumber={translate('formBuilder.placeholderNumber')}
      placeholderType={translate('formBuilder.placeholderType')}
      required={required}
      showError={hasErrors}
      // TODO: this is a hacky way to include the duplication check result in the component, should be better
      validator={() => !hasErrors}
      value={formData && new Phone(formData)}
    />
  );
};

export default PrimaryPhoneField;
