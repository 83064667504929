import React, { useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { Breakpoint } from '@mui/material';

import { translate } from '@vestahealthcare/common/i18n';

import { IconButton, Modal, Spinner } from 'styleguide-v2';

interface Props {
  children: any;
  'data-cy'?: string;
  details: JSX.Element;
  disableSubmit?: boolean;
  editable?: boolean;
  loading?: boolean;
  loadingSubmit?: boolean;
  modalSize?: Breakpoint;
  onShowModal?: () => void;
  onCancel?: () => void;
  onSubmit?: () => Promise<boolean>;
  title: string;
}

export const BaseModal = (props: Props) => {
  const {
    details,
    disableSubmit = false,
    editable = true,
    loading,
    modalSize,
    onShowModal,
    onCancel,
    onSubmit,
    title,
  } = props;
  const [showModal, setShowModal] = useState(false);

  const cancelCB = () => {
    onCancel && onCancel();
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal && onShowModal) {
      onShowModal();
    }
  }, [showModal]);

  return (
    <>
      {details}
      {editable && (
        <p className="grid-span-1">
          <IconButton
            data-cy={`${props['data-cy']}-edit-icon`}
            noMargin
            size="small"
            onClick={() => setShowModal(true)}
          >
            <EditIcon />
          </IconButton>
        </p>
      )}
      <Modal
        maxWidth={modalSize}
        onClose={cancelCB}
        onSubmit={async () => {
          if (onSubmit && (await onSubmit())) {
            setShowModal(false);
            return true;
          }
          return false;
        }}
        open={showModal}
        submitDisabled={disableSubmit}
        submitText={translate('global.save')}
        title={title}
      >
        {loading && <Spinner width={24} />}
        {props.children}
      </Modal>
    </>
  );
};
