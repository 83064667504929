import moment, { Moment } from 'moment';

import { BaseEnum } from './BaseEnum';
import { Incident } from './Incident';
import { IncidentAction } from './IncidentAction';
import { IncidentChangeOfConditionDetail } from './IncidentChangeOfConditionDetail';
import IncidentFallDetail from './IncidentFallDetail';
import { IncidentHospDetail } from './IncidentHospDetail';
import { IncidentInvolvedActor } from './IncidentInvolvedActor';
import { make, makeArray } from './Model';

export class IncidentDetail extends Incident {
  actions?: IncidentAction[];

  actionDate?: Moment;

  actionOther?: string;

  changeOfCondition?: IncidentChangeOfConditionDetail;

  description = '';

  fall?: IncidentFallDetail;

  federalEntities?: BaseEnum[];

  hospitalization?: IncidentHospDetail;

  incidentDate?: Moment;

  involvedActors: IncidentInvolvedActor[];

  location: BaseEnum;

  sourceEventId?: number;

  statusNote = '';

  externalNote = '';

  constructor(obj: any) {
    super(obj);
    this.actions = makeArray(obj.actions, IncidentAction);
    this.actionDate = obj.actionDate ? moment.unix(obj.actionDate) : undefined;
    this.actionOther = this.actions?.find(
      ({ id }) => id === IncidentAction.OTHER,
    )?.other;
    this.changeOfCondition = make(
      obj.changeOfCondition,
      IncidentChangeOfConditionDetail,
    );
    this.description = obj.description;
    this.sourceEventId = obj.sourceEventId;
    this.fall = make(obj.fall, IncidentFallDetail);
    this.federalEntities = makeArray(obj.federalEntities, BaseEnum);
    this.hospitalization = make(obj.hospitalization, IncidentHospDetail);
    this.incidentDate = obj.incidentDate
      ? moment.unix(obj.incidentDate)
      : undefined;
    this.involvedActors = makeArray(obj.involvedActors, IncidentInvolvedActor);
    this.location = make(obj.location, BaseEnum);
    this.statusNote = obj.statusNote;
    this.externalNote = obj.externalNote;
  }
}

export default IncidentDetail;
