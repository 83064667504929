import { translate } from '@vestahealthcare/common/i18n';
import { Task } from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT_WITH_TIME,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';
import moment from 'moment';
import React, { RefObject, useEffect, useState } from 'react';
import { Colors, Link, Spinner, Tag, Text } from 'styleguide';
import { fetchTask } from 'dash/src/services/TaskServices';
import Subtask from './Subtask';
import SubtaskPositionCircle from './SubtaskPositionCircle';
import TaskDetails from './TaskDetails';
import { colorsForStatus } from './utils';

interface Props {
  task: Task;
  active?: boolean;
  activeSubtaskId?: number;
  onClick?: (task: Task) => void;
  onTaskUpdate?: () => void;
  colSpan?: number;
  showSubTasks?: boolean;
}

export const TasksTableRow = ({
  task,
  active = false,
  activeSubtaskId,
  onClick,
  onTaskUpdate,
  colSpan = 6,
  showSubTasks,
}: Props) => {
  const [showDetails, setShowDetails] = useState(active);
  const [taskDetail, setTaskDetail] = useState<Task>();
  const isPastDue =
    task.dueDate && moment.unix(task.dueDate).isBefore(moment());
  const hasChildren =
    (taskDetail?.children && taskDetail?.children.length > 0) || false;
  const refs =
    taskDetail?.children?.reduce(
      (acc: { [key: number]: RefObject<HTMLTableRowElement> }, value: Task) => {
        acc[value.id] = React.createRef();
        return acc;
      },
      {},
    ) || [];

  useEffect(() => {
    if (activeSubtaskId) {
      refs[activeSubtaskId].current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [activeSubtaskId]);

  const getTaskDetails = async () => {
    const taskDetail = await fetchTask(task.id);
    if (!showSubTasks) {
      // FIXME: This delete is done to preserve the bad behavior of showing only
      // the parent task in the clinical dashboard (home and alerts page) when
      // we updating the current task instead of reloading the whole list
      delete taskDetail.children;
    }
    setTaskDetail(taskDetail);
  };

  const onTaskUpdateHandler = () => {
    // Load all list to get changes in parent or children task
    if (onTaskUpdate) {
      onTaskUpdate();
    }
  };

  useEffect(() => {
    if (showDetails && !taskDetail) {
      if (showSubTasks && !task.children) {
        getTaskDetails();
      } else {
        setTaskDetail(task);
      }
    }
  }, [showDetails]);

  const classes = [
    'clickable',
    !task.status.closed && isPastDue && ' urgent',
    !task.canBeStarted && ' disabled-row',
  ].filter(Boolean);

  return (
    <>
      <tr
        key={task.id}
        onClick={() => {
          if (onClick) {
            onClick(task);
          } else if (task.canBeStarted) {
            setShowDetails(!showDetails);
          } else {
            return false;
          }
        }}
        className={classes.join(' ')}
      >
        <td>
          <div style={{ display: 'flex' }}>
            {task.isSubtask() && (
              <SubtaskPositionCircle
                position={task.displayPosition()}
                style={{ marginRight: 15 }}
              />
            )}

            <div style={{ justifyItems: 'center' }}>
              <Tag
                backgroundColor={
                  colorsForStatus[task.status.value]?.backgroundColor ||
                  Colors.white
                }
                color={
                  colorsForStatus[task.status.value]?.color || Colors.textBlack
                }
                tag={task.status.toString()}
              />
            </div>
          </div>
        </td>
        <td>
          <Text small>{task.displayDueDate()}</Text>
        </td>
        <td>
          <Text small>{task.displayCompletedDate()}</Text>
        </td>
        <td>
          <Text small>
            {task.taskDefinition?.name}
            {task.healthAlertId && ` (${translate('tasks.createdFromAlert')})`}
            {task.eventId && ` (${translate('tasks.createdFromEvent')})`}
          </Text>
        </td>
        <td>
          <Text small>{task.employeeGroup?.name || EMPTY}</Text>
        </td>
        <td>
          <Text small>{task.assignee?.fullName || EMPTY}</Text>
        </td>
        <td>
          {task.member ? (
            <>
              <Link
                className="external-link"
                data-cy="task-member-link"
                href={`#/patients/${task.member.id}/tasks`}
                openNewTab
              >
                {task.member?.nameWithId}
              </Link>
              <Tag
                backgroundColor={Colors.btnBlue}
                color={Colors.white}
                tag={task.member.language?.toString() || EMPTY}
              />
            </>
          ) : (
            EMPTY
          )}
        </td>
        <td>
          <Text small>
            {moment.unix(task.createdAt).format(DATE_FORMAT_SHORT_WITH_TIME)}
          </Text>
          <Text small>
            {'by '}
            {task.createdBy?.fullName || EMPTY}
          </Text>
        </td>
      </tr>
      {showDetails && taskDetail && onTaskUpdate && (
        <tr style={{ columnSpan: 'all' }}>
          <td colSpan={colSpan}>
            <TaskDetails task={taskDetail} onUpdate={onTaskUpdateHandler} />
          </td>
        </tr>
      )}
      {showDetails && !taskDetail && (
        <tr>
          <td colSpan={colSpan}>
            <Spinner />
          </td>
        </tr>
      )}
      {showDetails && taskDetail && onTaskUpdate && hasChildren && (
        <>
          {taskDetail.children
            ?.sort((a, b) => a.position - b.position)
            .map((subtask) => (
              <tr
                style={{
                  columnSpan: 'all',
                }}
                key={subtask.id}
                ref={refs[subtask.id]}
              >
                <td
                  colSpan={colSpan}
                  style={{
                    backgroundColor: 'white',
                    padding: 0,
                    paddingLeft: 20,
                  }}
                >
                  <Subtask
                    active={subtask.id === activeSubtaskId}
                    task={subtask}
                    onUpdate={onTaskUpdateHandler}
                  />
                </td>
              </tr>
            ))}
        </>
      )}
    </>
  );
};

export default TasksTableRow;
