export default {
  bodyPart: {
    abdomen: '(ES) Abdomen',
    back: '(ES) Back',
    buttocks: '(ES) Buttocks',
    centralChest: '(ES) Central Chest',
    chest: '(ES) Chest',
    face: '(ES) Face',
    groin: '(ES) Groin',
    headBack: '(ES) Head Back',
    headFront: '(ES) Head Front',
    hips: '(ES) Hips',
    lAnkleBack: '(ES) L Ankle Back',
    lAnkleFront: '(ES) L Ankle Front',
    lBreast: '(ES) L Breast',
    lButtock: '(ES) L Buttock',
    lElbow: '(ES) L Elbow',
    lFingersBack: '(ES) L Fingers Back',
    lFingersFront: '(ES) L Fingers Front',
    lFoot: '(ES) L Foot',
    lForearmBack: '(ES) L Forearm Back',
    lForearmFront: '(ES) L Forearm Front',
    lHandBack: '(ES) L Hand Back',
    lHandFront: '(ES) L Hand Front',
    lHeel: '(ES) L Heel',
    lKnee: '(ES) L Knee',
    lLowerLegBack: '(ES) L Lower Leg Back',
    lLowerLegFront: '(ES) L Lower Leg Front',
    lowerBack: '(ES) Lower Back',
    lShoulderBack: '(ES) L Shoulder Back',
    lShoulderFront: '(ES) L Shoulder Front',
    lToes: '(ES) L Toes',
    lUpperArmBack: '(ES) L Upper Arm Back',
    lUpperArmFront: '(ES) L Upper Arm Front',
    lUpperLegBack: '(ES) L Upper Leg Back',
    lUpperLegFront: '(ES) L Upper Leg Front',
    lWristBack: '(ES) L Wrist Back',
    lWristFront: '(ES) L Wrist Front',
    neckBack: '(ES) Neck Back',
    neckFront: '(ES) Neck Front',
    rAnkleBack: '(ES) R Ankle Back',
    rAnkleFront: '(ES) R Ankle Front',
    rBreast: '(ES) R Breast',
    rButtock: '(ES) R Buttock',
    rElbow: '(ES) R Elbow',
    rFingersBack: '(ES) R Fingers Back',
    rFingersFront: '(ES) R Fingers Front',
    rFoot: '(ES) R Foot',
    rForearmBack: '(ES) R Forearm Back',
    rForearmFront: '(ES) R Forearm Front',
    rHandBack: '(ES) R Hand Back',
    rHandFront: '(ES) R Hand Front',
    rHeel: '(ES) R Heel',
    rKnee: '(ES) R Knee',
    rLowerLegBack: '(ES) R Lower Leg Back',
    rLowerLegFront: '(ES) R Lower Leg Front',
    rShoulderBack: '(ES) R Shoulder Back',
    rShoulderFront: '(ES) R Shoulder Front',
    rToes: '(ES) R Toes',
    rUpperArmBack: '(ES) R Upper Arm Back',
    rUpperArmFront: '(ES) R Upper Arm Front',
    rUpperLegBack: '(ES) R Upper Leg Back',
    rUpperLegFront: '(ES) R Upper Leg Front',
    rWristBack: '(ES) R Wrist Back',
    rWristFront: '(ES) R Wrist Front',
    sacrum: '(ES) Sacrum',
    upperBack: '(ES) Upper Back',
  },
  dayOfWeek: {
    friday: 'Viernes',
    monday: 'Lunes',
    saturday: 'Sábado',
    sunday: 'Domingo',
    thursday: 'Jueves',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
  },
  healthAlertChannel: {
    cgApp: '(ES) CG App',
    cgInboundCall: '(ES) CG Inbound Call',
    cgOutboundCall: '(ES) CG Outbound Call',
    HHAX_CARE_INSIGHTS: 'HHAx Care Insights',
    ipadApp: '(ES) iPad App',
    other: '(ES) Other',
    pocCall: '(ES) POC Call',
    sms: '(ES) SMS',
  },
  language: {
    arabic: 'árabe',
    cantonese: 'cantonés',
    corsa: 'corso',
    creole: 'criollo',
    english: 'inglés',
    filipino: 'filipino',
    french: 'francés',
    garifuna: 'garífuna',
    georgian: 'georgiano',
    ibo: 'igbo',
    italian: 'italiano',
    mandarin: 'mandarín',
    patois: 'patois',
    polish: 'polaco',
    portuguese: 'portugués',
    russian: 'ruso',
    signLanguage: 'lenguaje de signos',
    spanish: 'español',
    swahili: 'suajili',
    tagalog: 'tagalo',
    turkish: 'turco',
    ukrainian: 'ucraniano',
  },
  medicalEquipment: {
    airMattress: 'Colchón de aire',
    bedAlarm: 'Alarma de cama',
    bedbound: 'Postrado en cama',
    bedpan: 'Bacinilla',
    bedsideCommode: 'Cómoda junto a cama',
    bipap: 'BiPAP',
    cane: 'Bastón',
    catheterCare: 'Catéter',
    chux: 'Chux (Protectores desechables)',
    commode: 'Cómoda',
    condomCatheter: 'Catéter tipo condón',
    dentures: 'Dentadura postiza',
    dialysisMachine: 'Máquina de diálisis',
    enteralPump: 'Bomba enteral',
    eyeglasses: 'Gafas',
    feedingTubes: 'Tubos de alimentación',
    fixedWalker: 'Andador fijo',
    floorMats: 'Alfombras',
    foleyCatheter: 'Catéter de Foley',
    gaitBelt: 'Cinturón para caminar',
    geriChair: 'Silla Geri',
    glasses: 'Gafas',
    grabBars: 'Barras de apoyo',
    hearingAid: 'Audífono',
    hospitalBed: 'Cama en hospital',
    hoyerLift: 'Elevador Hoyer',
    incontinence: 'Incontinencia',
    ivPump: 'Bomba de infusión',
    lifeAlert: 'Life Alert',
    liftChair: 'Elevador de silla',
    merryWalker: 'Andador Merry',
    motorizedWheelchair: 'Silla de ruedas motorizada',
    nebulizer: 'Nebulizador',
    obt: 'OBT',
    ostomyBag: 'Bolsa de ostonomía',
    other: 'Otro',
    overbedTable: 'Mesa en cama',
    oxygen: 'Oxígeno',
    picLineCatheter: 'Catéter PICC',
    pressurePad: 'Cojín de presión',
    quadCane: 'Bastón de cuadro',
    raisedToiletSeat: 'Asiento de inodoro elevado',
    rollator: 'Andador de ruedas',
    rollingWalker: 'Andador de ruedas',
    scooter: 'Scooter',
    showerChair: 'Silla de ducha',
    sitToStandLift: 'Grúa de bipedestación',
    slideBoard: 'Tabla deslizante',
    specializedMattress: 'Colchón especial',
    stairlift: 'Elevador de escaleras',
    standPivot: 'Stand/Pivote',
    transportChair: 'Silla de transporte',
    unanswered: 'UNANSWERED',
    unknown: 'Desconocido',
    urinal: 'Orinal',
    ventilator: 'Respirador',
    walker: 'Andador',
    wc: 'WC',
    wheelchair: 'Silla de ruedas',
    woundVac: 'Máquina de vacío sobre herida',
  },
  phoneType: {
    android: 'Android',
    ios: 'iOS',
    none: 'No tengo un smartphone',
    other: 'Otro',
  },
  platformContactType: {
    AIDE: '(ES) Aide',
    CARDIOLOGIST: '(ES) Cardiologist',
    CHILD: '(ES) Child',
    DME: '(ES) DME',
    ENDOCRINOLOGIST: '(ES) Endocrinologist',
    GRANDDAUGHTER_GRANDSON: '(ES) Granddaughter / Grandson',
    HOSPICE: '(ES) Hospice',
    IDT: '(ES) IDT',
    MSW: '(ES) MSW',
    NEUROLOGIST: '(ES) Neurologist',
    NIECE_NEPHEW: '(ES) Niece / Nephew',
    OTHER: '(ES) Other',
    OTHER_PHYSICIAN: '(ES) Other Physician',
    OXYGEN: '(ES) Oxygen',
    PALLIATIVE_CARE: '(ES) Palliative Care',
    PCP: '(ES) PCP',
    PHARMACY: '(ES) Pharmacy',
    PSYCHIATRIST: '(ES) Psychiatrist',
    PSYCHOLOGIST: '(ES) Psychologist',
    PT: '(ES) PT',
    RHEUMATOLOGIST: '(ES) Rheumatologist',
    RN: '(ES) RN',
    SELF: '(ES) Self',
    SIBLING: '(ES) Sibling',
    SPOUSE: '(ES) Spouse',
    ST: '(ES) ST',
    TRANSPORTATION: '(ES) Transportation',
    UROLOGIST: '(ES) Urologist',
  },
};
