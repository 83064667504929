import { make } from './Model';
import { MedicalEquipment } from '../enums';
import { Patient } from './Patient';

export class PatientMedicalEquipment {
  discontinueDate?: string;

  id = 0;

  patient: Patient;

  medicalEquipment: MedicalEquipment;

  otherEquipment?: string;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.patient = make(obj.patient, Patient);
    // NOTE: backend sends these as a name/value obj
    this.medicalEquipment = MedicalEquipment.byKey[obj.medicalEquipment.value];
  }

  get description(): string {
    if (this.medicalEquipment.isOther) {
      return this.otherEquipment || '';
    }

    return this.medicalEquipment.toString();
  }

  get isOther() {
    return this.medicalEquipment.isOther;
  }

  get hasOtherText() {
    return !!this.otherEquipment?.trim();
  }

  get isActive() {
    return !this.discontinueDate;
  }

  isEqual = (patientEquipment: PatientMedicalEquipment) => {
    const differentItem = this !== patientEquipment;
    const sameDescription =
      this.description.localeCompare(patientEquipment.description) === 0;
    const sameType =
      patientEquipment.medicalEquipment === this.medicalEquipment;
    return differentItem && (this.isOther ? sameDescription : sameType);
  };

  isDuplicated = (equipment: PatientMedicalEquipment[]) =>
    !!equipment.find(this.isEqual);
}

export default PatientMedicalEquipment;
