import { CollectionMethod } from '@vestahealthcare/common/enums';
import {
  Address,
  BiometricsConfig,
  RPMVendorModel,
} from '@vestahealthcare/common/models';

import Api from './Api';

export const fetchSmartMeterModels = async (): Promise<RPMVendorModel[]> => {
  const { rpmDeviceModels } = await Api.getv2(`rpm-devices/models`, {
    vendorId: 1,
  });
  return rpmDeviceModels.items.map((x: any) => new RPMVendorModel(x));
};

export type OrderDeviceParams = {
  address: Address;
  deviceId: number[];
  fullName: string;
  memberId: number;
  taskId: number;
};

export const orderRPMDevices = async ({
  address,
  ...params
}: OrderDeviceParams) =>
  await Api.postv2JSON(
    `rpm-devices/orders`,
    {
      address: { ...address, state: address.state?.value },
      ...params,
    },
    { showToast: true },
  );

export type UpdateRPMOrderIdParams = {
  collectionMethod: CollectionMethod;
  newOrderNumber: string;
  oldOrderNumber: string;
};

export const updateRPMOrderId = async (
  config: BiometricsConfig,
  memberId: number,
  { collectionMethod, ...params }: UpdateRPMOrderIdParams,
) =>
  await Api.patchv2JSON(
    `rpm-devices/patients/${memberId}/orders/${config.type.value}`,
    {
      ...params,
      collectionMethod: collectionMethod.value,
    },
    { showToast: true },
  );
