import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { EventType } from '@vestahealthcare/common/models';

import {
  Button,
  CollapsableSidebar,
  Modal,
  SwitchGroup,
  TextInput,
} from 'styleguide-v2';

import { fetchEventType } from 'dash/src/services/EventServices';

interface Props {
  event?: EventType;
  events: EventType[];
  onClose: () => void;
  onSubmit: (
    id: number,
    params: { name: string; active: boolean },
  ) => Promise<boolean>;
}

export const EventTypesEditModal = ({
  event,
  events,
  onClose,
  onSubmit,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [name, setName] = useState('');
  const [active, setActive] = useState(false);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  useEffect(() => {
    if (event) {
      setName(event.name);
      setActive(event.active);
    }
  }, [event]);

  const validate = () => {
    if (event && name) {
      if (
        name === event.name ||
        !events.find(
          ({ name: gName }) =>
            name.toLocaleLowerCase() === gName.toLocaleLowerCase(),
        )
      ) {
        return true;
      }
    }
  };

  const getErrorText = () => {
    if (submitted && event) {
      if (!name) {
        return translate('global.missingRequiredFieldMin');
      }
      if (
        name !== event.name &&
        events.find(
          ({ name: eName }) =>
            name.toLocaleLowerCase() === eName.toLocaleLowerCase(),
        )
      ) {
        return translate('global.uniqueConstraintField');
      }
    }
  };

  const shouldShowConfirmModal = async () => {
    if (event?.id) {
      setLoading(true);
      const detail = await fetchEventType(event.id);
      setLoading(false);
      return !!event?.active && !active && !!detail.issueTypes?.length;
    }
    return false;
  };

  const checkSubmit = async () => {
    setSubmitted(true);
    if (validate()) {
      if (await shouldShowConfirmModal()) {
        setOpenConfirmModal(true);
      } else {
        await doSubmit();
      }
    }
  };

  const doSubmit = async () => {
    setLoading(true);
    try {
      const result = await onSubmit(event?.id || 0, {
        active,
        name,
      });
      if (result) {
        onClose();
      }
    } finally {
      setSubmitted(false);
      setLoading(false);
    }
  };

  const hasChanges = () => name !== event?.name || active !== event?.active;

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={!!event}
      title={
        <h2>
          {event?.id
            ? translate('events.types.editModalTitle')
            : translate('events.types.addModalTitle')}
        </h2>
      }
    >
      <CollapsableSidebar.Body>
        <TextInput
          error={getErrorText()}
          label={translate('events.name')}
          onChange={(value) => {
            const safeValue = value
              ?.trim()
              .split(' ')
              .filter(Boolean)
              .join(' ');
            setName(safeValue || '');
          }}
          value={name}
        />
        <SwitchGroup
          items={[{ label: translate('events.active'), checked: active }]}
          onChange={(items) => setActive(!!items[0].checked)}
        />

        <Modal
          title={translate('global.warning')}
          body={translate('events.warningInvalidateEventType')}
          onClose={() => setOpenConfirmModal(false)}
          open={openConfirmModal}
          onSubmit={doSubmit}
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button color="tertiary" data-cy="edit-event-close" onClick={onClose}>
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="edit-event-submit"
          disabled={!hasChanges()}
          loading={loading}
          onClick={checkSubmit}
        >
          {event?.id ? translate('global.save') : translate('global.add')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default EventTypesEditModal;
