const loadFavicon = (favicon: string) => {
  const link: any =
    document.querySelector("link[rel*='icon']") ||
    document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = favicon;
  document.head.appendChild(link);
};

export default loadFavicon;
