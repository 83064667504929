import React from 'react';
import { DateTimePicker, Select } from 'styleguide';
import moment from 'moment';
import { DATE_FORMAT } from '@vestahealthcare/common/utils/constants';
import TaskCustomField from '@vestahealthcare/common/models/TaskCustomField';
import TaskCustomFieldType from '@vestahealthcare/common/enums/TaskCustomFieldType';
import { GridColumns } from 'styleguide/src/utils/types';
import { translate } from '@vestahealthcare/common/i18n';

type Props = {
  columns?: GridColumns;
  customField: TaskCustomField;
  disabled?: boolean;
  onUpdate: (answer: string | null) => void;
  showError?: boolean;
  required?: boolean;
  value?: string;
};

export const EditTaskCustomField = ({
  columns = 3,
  customField,
  disabled,
  onUpdate,
  showError,
  required,
  value,
}: Props) => {
  const customFieldToRender = () => {
    switch (customField.type) {
      case TaskCustomFieldType.DATE:
        return (
          <DateTimePicker
            columns={columns}
            disabled={disabled}
            errorText={translate('global.missingRequiredField')}
            label={customField.label}
            onChange={(date) =>
              onUpdate(date ? date.format(DATE_FORMAT) : null)
            }
            showError={showError}
            required={required}
            value={moment(value)}
          />
        );

      case TaskCustomFieldType.DATETIME:
        return (
          <>
            <DateTimePicker
              columns={columns}
              disabled={disabled}
              errorText={translate('global.missingRequiredField')}
              label={`${customField.label}`}
              onChange={(date) => onUpdate(date ? date.format() : null)}
              showError={showError}
              required={required}
              value={moment(value)}
            />

            <DateTimePicker
              columns={columns}
              date={false}
              disabled={disabled}
              errorText={translate('global.missingRequiredField')}
              label={`${customField.label} Time`}
              onChange={(date) => onUpdate(date ? date.format() : null)}
              showError={showError}
              required={required}
              time
              value={moment(value)}
            />
          </>
        );

      case TaskCustomFieldType.CHECKBOX:
        if (!customField.options) {
          return <div />;
        }

        return (
          <Select
            columns={columns}
            disabled={disabled}
            errorText={translate('global.missingRequiredField')}
            label={customField.label}
            multiple
            onChange={(values: string[]) =>
              onUpdate(values.length > 0 ? values.join(',') : null)
            }
            options={customField.options.map((option) => ({
              label: option.name,
              value: option.id,
            }))}
            showError={showError}
            required={required}
            value={value?.split(',').map((i) => Number(i))}
          />
        );
      default:
        return <div />;
    }
  };

  return customFieldToRender();
};

export default EditTaskCustomField;
