import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import {
  ChannelPreviewMessenger,
  ChannelPreviewUIComponentProps,
} from 'stream-chat-react';

import {
  DATE_FORMAT_SHORT,
  TIME_FORMAT,
} from '@vestahealthcare/common/utils/constants';

import { Text } from 'styleguide/src/components/Text';

const MessagePreview = (props: ChannelPreviewUIComponentProps) => {
  const classes = classNames(
    'vesta-preview',
    props.active && 'vesta-preview--active',
  );

  const lastMessageAt = props.channel.state.last_message_at
    ? moment(props.channel.state.last_message_at)
    : undefined;
  const lastMessageAtDisplay = () => {
    if (lastMessageAt) {
      if (lastMessageAt.isAfter(moment().startOf('day'))) {
        return lastMessageAt.format(TIME_FORMAT);
      }
      if (lastMessageAt.isAfter(moment().subtract(1, 'day').startOf('day'))) {
        return 'Yesterday';
      }
      return lastMessageAt.format(DATE_FORMAT_SHORT);
    }
    return '';
  };

  return (
    <button
      className={classes}
      onClick={() =>
        props.setActiveChannel && props.setActiveChannel(props.channel)
      }
    >
      <ChannelPreviewMessenger {...props} />
      <Text small color="hint" style={{ marginTop: '2px' }}>
        {lastMessageAtDisplay()}
      </Text>
    </button>
  );
};

export default MessagePreview;
