import React, { useEffect, useMemo, useState } from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  CreateIncidentParams,
  IncidentAction,
  IncidentType,
  Patient,
} from '@vestahealthcare/common/models';

import { Button, CollapsableSidebar, Colors } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';

import { IncidentModalFields } from './IncidentModalFields';

type Props = {
  defaultType?: IncidentType;
  defaultFields?: Partial<CreateIncidentParams>;
  isEventWorkflow?: boolean;
  member: Patient;
  multiStep?: boolean;
  open: boolean;
  onClose: () => void;
  onCreate: (params: CreateIncidentParams) => Promise<void>;
};

const useStyles = makeStyles({
  subtitle: {
    color: Colors.iconGray,
    fontSize: '1.25em',
    margin: '0 1rem',
  },
  title: {
    flex: 2,
  },
});

const TOTAL_STEPS = 2;

export const CreateIncidentModal = ({
  defaultType,
  defaultFields,
  isEventWorkflow,
  member,
  multiStep,
  onClose,
  onCreate,
  open,
}: Props) => {
  const styles = useStyles();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);

  const [params, setParams] = useState<Partial<CreateIncidentParams>>({});

  const hasActions = !!params?.actions?.length;

  const isStateFederal = useMemo(
    () =>
      !!params?.actions?.find(({ id }) => id === IncidentAction.STATE_FEDERAL),
    [params?.actions],
  );

  const isActionOther = useMemo(
    () => !!params?.actions?.find(({ id }) => id === IncidentAction.OTHER),
    [params?.actions],
  );

  const isChangeOfCondition = useMemo(
    () =>
      !!params?.types?.find(
        ({ id }) => id === IncidentType.CHANGE_OF_CONDITION,
      ),
    [params?.types],
  );

  const isHosp = useMemo(
    () =>
      !!params.types?.find(({ id }) =>
        [IncidentType.ER_VISIT, IncidentType.HOSPITALIZATION].includes(id),
      ),
    [params?.types],
  );

  const isFall = [
    IncidentType.FALL_INJURY,
    IncidentType.FALL_NO_INJURY,
  ].includes(defaultType?.id || '');

  const validate = () =>
    params.types?.length &&
    (!isChangeOfCondition ||
      params.changeOfCondition?.additionalHelp !== undefined) &&
    (!isChangeOfCondition ||
      !params.changeOfCondition?.additionalHelp ||
      params.changeOfCondition?.changeDescription) &&
    (!isChangeOfCondition || params.changeOfCondition?.changeDescription) &&
    params.incidentDate &&
    params.reportedDate &&
    params.description &&
    (isHosp || params.location) &&
    (!hasActions || params.actionDate) &&
    (!isStateFederal || params.federalEntities?.length) &&
    (!isActionOther || params.actionOther);

  const doSubmit = async () => {
    setSubmitted(true);

    if (!validate()) return;

    setLoadingButton(true);

    try {
      await onCreate({
        ...params,
        memberId: member,
      } as CreateIncidentParams);
      onClose();
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoadingButton(false);
  };

  const getTitle = () => {
    if (multiStep)
      return (
        <>
          <h2 className={styles.title}>
            {translate('incidents.modals.tittleMultiStep', {
              type: defaultType?.description,
            })}
          </h2>
          <span className={styles.subtitle}>
            (
            {translate('incidents.modals.steps', {
              current: TOTAL_STEPS,
              total: TOTAL_STEPS,
            })}
            )
          </span>
        </>
      );
    return (
      <h2>
        {translate(`incidents.modals.${isFall ? 'titleFall' : 'titleAdd'}`, {
          member: member.fullName,
        })}
      </h2>
    );
  };

  useEffect(() => {
    if (open) {
      setLoadingButton(false);
      setSubmitted(false);
    }
  }, [open]);

  return (
    <CollapsableSidebar
      title={getTitle()}
      onClose={onClose}
      open={open}
      size={650}
    >
      <CollapsableSidebar.Body>
        <p className="grid-span-12">
          {translate(
            `incidents.modals.${isFall ? 'subtitleFall' : 'subtitleAdd'}`,
          )}
        </p>
        <IncidentModalFields
          defaultTypeId={defaultType?.id}
          defaultFields={defaultFields}
          isEventWorkflow={isEventWorkflow}
          onChange={setParams}
          submitted={submitted}
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button color="tertiary" data-cy="edit-group-close" onClick={onClose}>
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="edit-group-submit"
          loading={loadingButton}
          onClick={doSubmit}
        >
          {translate('incidents.modals.submitReport')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default CreateIncidentModal;
