import { CCMCareTeamMember } from './CCMCareTeamMember';
import { makeArray } from './Model';
import { UserPhone } from './UserPhone';

export class CCMMemberDetailContact {
  memberPhones?: UserPhone[];

  careTeamMembers?: CCMCareTeamMember[];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.memberPhones = makeArray(obj.memberPhones, UserPhone);
    this.careTeamMembers = makeArray(obj.careTeamMembers, CCMCareTeamMember);
  }
}

export default CCMMemberDetailContact;
