import { Enum } from './Enum';

export class GoalType extends Enum {
  translationKey = 'goalType';

  static asArray: GoalType[] = [];

  static byKey: { [name: string]: GoalType } = {};

  static readonly TRANSITION_OF_CARE = new GoalType('TRANSITION_OF_CARE');

  static readonly PALLIATIVE_NEEDS = new GoalType('PALLIATIVE_NEEDS');

  static readonly GENERAL_CONDITION_MANAGEMENT = new GoalType(
    'GENERAL_CONDITION_MANAGEMENT',
  );

  static readonly MEDICATION_MANAGEMENT = new GoalType('MEDICATION_MANAGEMENT');

  static readonly ACCESS_TO_CARE = new GoalType('ACCESS_TO_CARE');

  static readonly SOCIAL = new GoalType('SOCIAL');

  static readonly LIFESTYLE = new GoalType('LIFESTYLE');

  static readonly FALL_AVOIDANCE = new GoalType('FALL_AVOIDANCE');

  static readonly CHF_MANAGEMENT = new GoalType('CHF_MANAGEMENT');

  static readonly COPD_MANAGEMENT = new GoalType('COPD_MANAGEMENT');

  static readonly CVA_MANAGEMENT = new GoalType('CVA_MANAGEMENT');

  static readonly DMII_MANAGEMENT = new GoalType('DMII_MANAGEMENT');

  static readonly UTI_MANAGEMENT = new GoalType('UTI_MANAGEMENT');

  static readonly GI_CONDITION_MANAGEMENT = new GoalType(
    'GI_CONDITION_MANAGEMENT',
  );

  static readonly SKIN_CONDITION_MANAGEMENT = new GoalType(
    'SKIN_CONDITION_MANAGEMENT',
  );

  static readonly DEMENTIA_MANAGEMENT = new GoalType('DEMENTIA_MANAGEMENT');

  static readonly OTHER = new GoalType('OTHER');

  constructor(public readonly value: string) {
    super();
    GoalType.asArray.push(this);
    GoalType.byKey[value] = this;
  }
}

export default GoalType;
