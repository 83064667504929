import React, { useEffect, useState } from 'react';

import { CareQuestionType } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { CareQuestion, StructuredAnswer } from '@vestahealthcare/common/models';

import { RadioGroup } from 'styleguide-v2';
import { RadioItem } from 'styleguide-v2/src/components/RadioButton/RadioGroup';

type Data = { [key: string]: any };

interface ExtendedQuestions extends CareQuestion {
  valid?: boolean;
}

type Props = {
  className?: string;
  defaultData?: Data;
  onChange: (
    questionsfields: { id: string; valid?: boolean }[],
    data: Data,
  ) => void;
  questions: CareQuestion[];
  readonly?: boolean;
};

export const QuestionFields = ({ className, onChange, questions }: Props) => {
  const [data, setData] = useState<Data>({});
  const [currentQuestions, setQuestions] = useState<ExtendedQuestions[]>([]);

  useEffect(() => {
    setData({});
    setQuestions([...questions]);
  }, [questions]);

  useEffect(() => {
    currentQuestions?.forEach(({ question }) => {
      if (!data[question]) {
        data[question] = null;
      }
    });
    setData(data);
    onChange(
      currentQuestions.map(({ question, valid }) => ({ id: question, valid })),
      data,
    );
  }, [currentQuestions]);

  const onChangeField = (field: string, update: any, valid?: boolean) => {
    const newData = {
      ...data,
      [field]: update,
    };
    const currentQuestion = currentQuestions.find(
      ({ question }) => question === field,
    );
    if (currentQuestion) {
      currentQuestion.valid = valid;
      if (currentQuestion.type === CareQuestionType.RADIO) {
        const answer = currentQuestion.structuredAnswers.find(
          ({ answerName }) => answerName === update,
        );
        const linkedQuestions = currentQuestion.structuredAnswers
          .filter(({ linkedQuestion }) => linkedQuestion)
          .map(({ linkedQuestion }) => linkedQuestion);
        if (answer?.linkedQuestion) {
          currentQuestions.push(answer?.linkedQuestion);
        }
        linkedQuestions
          .filter(
            ({ question }) => question !== answer?.linkedQuestion?.question,
          )
          .forEach(({ question }) => delete newData[question]);
        setQuestions(
          currentQuestions.filter(
            ({ question }) =>
              question === answer?.linkedQuestion?.question ||
              !linkedQuestions.find(
                ({ question: fQuestion }) => question === fQuestion,
              ),
          ),
        );
      } else {
        setQuestions([...currentQuestions]);
      }
    }

    setData(newData);
    onChange(
      currentQuestions.map(({ question, valid }) => ({ id: question, valid })),
      newData,
    );
  };

  const mapStructureAnswer = ({
    answerName,
    humanReadableAnswer,
  }: StructuredAnswer) =>
    ({
      value: answerName,
      label: humanReadableAnswer,
    } as Selectable);

  return (
    <>
      {currentQuestions.map(({ question, structuredAnswers, title, type }) => {
        if (type === CareQuestionType.RADIO) {
          return (
            <RadioGroup
              className={className}
              expand={structuredAnswers?.length > 2 ? 'vertical' : 'horizontal'}
              items={
                (structuredAnswers?.map(mapStructureAnswer) as RadioItem[]) ||
                []
              }
              onChange={(selected: RadioItem) =>
                onChangeField(question, selected)
              }
              title={title}
            />
          );
        }

        return (
          <p className={className} key={question}>
            {title}: Component not implemented yet
          </p>
        );
      })}
    </>
  );
};

export default QuestionFields;
