import React from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

import { EventModel } from '@vestahealthcare/common/models';

import { Fonts } from 'styleguide-v2/src/styles/Variables';
import {
  DATE_FORMAT_SHORT,
  DATE_FORMAT_SHORT_WITH_TIME,
} from '@vestahealthcare/common/utils/constants';

type Props = {
  className?: string;
  'data-cy'?: string;
  event: EventModel;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  type: {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    fontWeight: 500,
  },
  extraInfo: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 400,
  },
});

export const SelectEventOption = ({
  className,
  'data-cy': dataCy,
  event,
}: Props) => {
  const styles = useStyles();
  const classes = classnames(className, styles.container);
  const { assignee, datetime, hasTime, status, type } = event;

  return (
    <div key={event.id} className={classes} data-cy={dataCy}>
      <span className={styles.type}>{type.name}</span>
      <span className={styles.extraInfo}>
        {status.description}
        {' - '}
        {datetime.format(
          hasTime ? DATE_FORMAT_SHORT_WITH_TIME : DATE_FORMAT_SHORT,
        )}
        {assignee && (
          <>
            {' - '}
            {assignee.fullName}
          </>
        )}
      </span>
    </div>
  );
};

export default SelectEventOption;
