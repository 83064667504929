import React, { HTMLProps, useEffect, useState } from 'react';
import { Collapse as MCollapse } from '@mui/material';

interface Props extends HTMLProps<HTMLElement> {
  collapsedSize?: number;
  'data-cy'?: string;
  defaultOpened?: boolean;
  open: boolean;
  orientation?: 'vertical' | 'horizontal';
}

export const Collapse = ({
  collapsedSize,
  className,
  children,
  'data-cy': dataCy,
  defaultOpened,
  open,
  orientation = 'vertical',
}: Props) => {
  const [timeoutTransition, setTimeoutTransition] = useState<number>();

  useEffect(() => {
    if (defaultOpened) {
      setTimeoutTransition(0);
      setTimeout(() => setTimeoutTransition(undefined), 1000);
    }
  }, []);

  return (
    <MCollapse
      className={className}
      collapsedSize={collapsedSize}
      data-cy={dataCy}
      in={open}
      orientation={orientation}
      timeout={timeoutTransition}
    >
      {children}
    </MCollapse>
  );
};

export default Collapse;
