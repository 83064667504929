import { CareTeamRosterTaskStatus } from '../enums';
import { Employee } from './Employee';
import { make } from './Model';

export class CareTeamRosterTask {
  id = '';

  numRows = 0;

  createdAt = 0;

  createdBy: Employee;

  updatedAt = 0;

  updatedBy: Employee;

  description = '';

  status: CareTeamRosterTaskStatus;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdBy = make(obj.createdBy, Employee);
    this.updatedBy = make(obj.updatedBy, Employee);
    this.status = CareTeamRosterTaskStatus.byKey[obj.status];
  }
}
