import classNames from 'classnames';
import moment, { Moment } from 'moment';
import React, { ReactNode } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventIcon from '@mui/icons-material/Event';
import { makeStyles } from '@mui/styles';

import {
  DATE_FORMAT_SHORT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import { Colors } from 'styleguide-v2/src/styles/Colors';
import { Fonts } from 'styleguide-v2/src/styles/Variables';

import { Tooltip } from '../Tooltip';

type Props = {
  className?: string;
  color?: 'success' | 'warning' | 'error';
  date?: Moment | number;
  tooltip?: ReactNode;
};

const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    display: 'flex',
    gap: '0.5rem',
  },

  text: {
    lineHeight: Fonts.fontSize,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    marginTop: '2px',
  },

  tooltipContainer: {
    width: 'fit-content',
  },

  errorText: { color: Colors.textRed },
  successText: { color: Colors.textGreen },
  warningText: { color: Colors.textBlack },
  warningContainer: {
    backgroundColor: Colors.gold,
    borderRadius: '1rem',
    display: 'flex',
    margin: '1px',
    padding: '0.2rem',

    '& > svg': {
      fontSize: '11px',
    },
  },
});

export const StatusVisit = ({ className, color, date, tooltip }: Props) => {
  const styles = useStyles();
  // @ts-ignore
  const textClass = color ? styles[`${color}Text`] : styles.default;
  // eslint-disable-next-line no-nested-ternary
  const dateFormatted = date
    ? (typeof date === 'number' ? moment.unix(date) : date)?.format(
        DATE_FORMAT_SHORT,
      )
    : EMPTY;
  const content = (
    <div className={classNames(className, styles.container)}>
      {color === 'success' && <CheckCircleIcon htmlColor={Colors.iconGreen} />}
      {color === 'warning' && (
        <div className={styles.warningContainer}>
          <EventIcon htmlColor="black" />
        </div>
      )}
      {color === 'error' && <CancelIcon htmlColor={Colors.error} />}
      <span className={classNames('semi-bold', textClass, styles.text)}>
        {dateFormatted}
      </span>
    </div>
  );

  return tooltip ? (
    <Tooltip text={tooltip}>
      <div className={styles.tooltipContainer}>{content}</div>
    </Tooltip>
  ) : (
    content
  );
};
