import React, { useEffect, useState } from 'react';

import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { EventsConfiguration } from '@vestahealthcare/common/models';

import { Button, CollapsableSidebar, Select, SwitchGroup } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { UpdateEventTypeConfig } from 'dash/src/services/EventServices';

type Props = {
  config?: EventsConfiguration;
  open?: boolean;
  onClose: () => void;
  onSubmit: (c: UpdateEventTypeConfig) => Promise<boolean>;
};

const TIME_OPTIONS = [
  { value: 2, label: '2 hours' },
  { value: 4, label: '4 hours' },
  { value: 12, label: '12 hours' },
  { value: 24, label: '24 hours' },
  { value: 48, label: '48 hours' },
];

export const EventsSettingsModal = ({
  config,
  open,
  onClose,
  onSubmit,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [timeEnabled, setTimeEnabled] = useState(true);
  const [timeOption, setTimeOption] = useState<Selectable>();

  useEffect(() => {
    setTimeEnabled(!!config?.timeGrouping);
    const timeOption =
      TIME_OPTIONS.find(({ value }) => value === config?.timeGroupingHours) ||
      TIME_OPTIONS[3];
    setTimeOption(timeOption);
  }, [config]);

  const validate = () => !timeEnabled || timeOption;

  const doSubmit = async () => {
    setSubmitted(true);

    if (!validate()) {
      return;
    }

    setLoading(true);
    try {
      await onSubmit({
        timeGrouping: timeEnabled,
        timeGroupingHours: timeOption?.value as number,
      });
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
    setSubmitted(false);
    onClose();
  };

  const hasChanges = () =>
    timeEnabled !== config?.timeGrouping ||
    (timeEnabled && timeOption?.value !== config?.timeGroupingHours);

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={!!open}
      title={<h2>{translate('events.settings')}</h2>}
    >
      <CollapsableSidebar.Body>
        <SwitchGroup
          items={[
            { label: translate('events.timeConfig'), checked: timeEnabled },
          ]}
          onChange={(items) => {
            setTimeEnabled(!!items[0].checked);
          }}
        />
        {timeEnabled && (
          <Select
            error={
              submitted &&
              !timeOption &&
              translate('global.missingRequiredFieldMin')
            }
            items={TIME_OPTIONS}
            label={translate('events.timeConfigOption')}
            value={timeOption}
            onChange={setTimeOption}
          />
        )}
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="edit-event--config-close"
          onClick={onClose}
        >
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="edit-event-config-submit"
          disabled={!hasChanges()}
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default EventsSettingsModal;
