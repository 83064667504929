export class UserBase {
  id = 0;

  fullName = '';

  type = '';

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default UserBase;
