import { CareTeamMember } from './CareTeamMember';

export class CareTeamMemberProvider extends CareTeamMember {
  practiceName?: string;

  providerNotes?: string;

  constructor(obj: any) {
    super(obj);

    this.practiceName = obj.practiceName;
    this.providerNotes = obj.providerNotes;
  }
}

export default CareTeamMemberProvider;
