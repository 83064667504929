import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';

import loadAnalytics from '@vestahealthcare/common/utils/analytics';
import { LS_REMOTE_BACKEND } from '@vestahealthcare/common/utils/constants';
import loadFavicon from '@vestahealthcare/common/utils/favicon';

import styles2 from 'styleguide-v2/src/styles/main.useable.less';
import styles from 'styleguide/src/styles/main.useable.less';

import config from 'dash/environments';
import confirmNavigation from 'dash/src/components/NavigationConfirm';
import LoginServices from 'dash/src/services/LoginServices';
import Session from 'dash/src/services/SessionServices';

import App from './App';
import * as Sentry from '@sentry/browser';
// eslint-disable-next-line import/no-unresolved
import Typekit from 'typekit';

styles.use();
styles2.use();

// Shortcuts to set remote backend for devs
if (config.isDev) {
  window.setBackend = (environment) => {
    localStorage.setItem(LS_REMOTE_BACKEND, environment);
    window.location.reload();
  };
  window.clearBackend = () => {
    localStorage.removeItem(LS_REMOTE_BACKEND);
    window.location.reload();
  };
}

if (config.sentry && config.sentry.dsn) {
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: config.env,
    ignoreErrors: [
      // Connection errors. TODO: Not properly handled sometimes.
      'Failed to fetch',
      // Error 400
      'There are some issues with your request. Please review the form data.',
      // Error 500
      'Error 500',
      // Chrome error
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
      // Token error
      'You must provide a valid token',
    ],
  });
}

// Load fonts
if (Typekit) {
  try {
    Typekit.load({ async: true });
  } catch (e) {
    /* ignore */
  }
}

// Dynamic favicon
loadFavicon(config.favicon);
loadAnalytics(config.googleAnalytics);

const render = async (Component) => {
  let appContent = (
    <HashRouter getUserConfirmation={confirmNavigation}>
      <Component />
    </HashRouter>
  );

  // skip the authorization step
  if (!LoginServices.isAuthorizing) {
    try {
      const user = await Session.fetchSessionInformation();
      const LDProvider = await asyncWithLDProvider({
        clientSideID: config.launchDarkly,
        context: {
          key: user?.id,
          kind: 'user',
          name: user?.fullName,
          email: user?.email,
          custom: {
            timezone: user?.timeZone,
            licenses: user?.licences || [],
            groups: user?.employeeGroups?.map(({ name }) => name) || [],
            permissions: user?.dashPermissions?.map(({ name }) => name) || [],
          },
        },
      });

      appContent = <LDProvider>{appContent}</LDProvider>;
    } catch (err) {
      // ignore
    }
  }

  ReactDOM.render(appContent, document.getElementById('ht-root'));
};

render(App);

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept();
}
