import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  CareTeamCandidate,
  CareTeamMemberType,
  CareTeamPerson,
  CareTeamPersonExtended,
  Patient,
} from '@vestahealthcare/common/models';

import { Toast } from 'styleguide';
import { Button, CollapsableSidebar, Colors } from 'styleguide-v2';

import { PostQuickAddParams } from 'dash/src/services/CareTeamServices';

import { InfoStep } from './InfoStep';
import { SearchCareTeamPersonStep } from './SearchStep';

type Props = {
  disabledPeople?: CareTeamPerson[];
  hideExtraButtons?: boolean;
  open: boolean;
  onClose: () => void;
  onSubmit: (params: PostQuickAddParams) => Promise<CareTeamPerson | false>;
  selectedCareTeamCandidate?: CareTeamCandidate;
  selectedMember?: Patient;
};

const TOTAL_STEPS = 2;

const useStyles = makeStyles({
  backButton: {
    flex: 0.5,
  },
  subtitle: {
    color: Colors.iconGray,
    fontSize: '1.25em',
    marginLeft: '1rem',
  },
});

export const CareTeamQuickAddModal = ({
  disabledPeople,
  hideExtraButtons,
  onClose,
  onSubmit,
  open,
  selectedCareTeamCandidate,
  selectedMember,
}: Props) => {
  const history = useHistory();
  const styles = useStyles();

  const [currentStep, setStep] = useState<number>(1);

  const [ctp, setCTP] = useState<CareTeamPersonExtended>();
  const [params, setParams] = useState<Partial<PostQuickAddParams>>({});

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [loadingSaveAndOpen, setLoadingSaveAndOpen] = useState<boolean>(false);
  const [loadingSaveAndNew, setLoadingSaveAndNew] = useState<boolean>(false);

  const reset = () => {
    setCTP(undefined);
    setLoadingSave(false);
    setLoadingSaveAndNew(false);
    setLoadingSaveAndOpen(false);
    setParams({});
    setStep(1);
    setSubmitted(false);
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  const selectCareTeamPerson = (ctp: CareTeamPersonExtended) => {
    setCTP(ctp);
    setStep(2);
  };

  const handleBack = () => {
    setStep(1);
  };

  const validate = () =>
    (params.careTeamPersonId || (params.firstName && params.lastName)) &&
    params.memberId &&
    params.type &&
    !params.phones
      ?.filter(({ phone }) => !!phone)
      ?.find(({ phone: { number, type } }) => !type !== !number) &&
    (!params.healthCareProxy || params.healthCareProxyFileLocation) &&
    (params.type.id !== CareTeamMemberType.FAMILY_FRIEND ||
      params.relationshipId) &&
    (params.type.id !== CareTeamMemberType.AIDE || params.aideTypeId) &&
    (params.type.id !== CareTeamMemberType.PROVIDER ||
      params.specialityIds?.length) &&
    (params.type.id !== CareTeamMemberType.OTHER ||
      params.otherRelationshipId) &&
    (params.type.id !== CareTeamMemberType.PROVIDER ||
      !params.specialityIds?.find(({ id }) => id === 'OTHER') ||
      params.specialityOther) &&
    (params.type?.id !== CareTeamMemberType.OTHER ||
      params.otherRelationshipId?.id !== 'OTHER' ||
      params.relationshipOther);

  const handleSubmit = async () => {
    setSubmitted(true);
    if (!validate()) {
      if (params.healthCareProxy && !params.healthCareProxyFileLocation) {
        new Toast({
          position: 'bottom-right',
          title: 'Validation error',
          body: translate('careTeam.errors.healthCareProxyFileLocation'),
          type: 'error',
        });
      }
      return;
    }
    const { phones, ...rest } = params;
    const ctp = await onSubmit({
      phones: phones?.filter((userPhone) => userPhone?.phone?.number),
      ...rest,
    } as PostQuickAddParams);
    setSubmitted(false);

    return ctp;
  };

  const handleSave = async () => {
    setLoadingSave(true);
    const ctp = await handleSubmit();
    setLoadingSave(false);
    if (ctp) {
      onClose();
    }
  };

  const handleSaveAndNew = async () => {
    setLoadingSaveAndNew(true);
    const ctp = await handleSubmit();
    setLoadingSaveAndNew(false);
    if (ctp) {
      reset();
    }
  };

  const handleSaveAndOpen = async () => {
    setLoadingSaveAndOpen(true);
    const ctp = await handleSubmit();
    setLoadingSaveAndOpen(false);
    if (ctp) {
      history.push(`/admin/care-team/${ctp.id}`);
    }
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      size={650}
      title={
        <div>
          <h2>{translate('careTeam.modals.quickAdd.title')}</h2>
          <span className={styles.subtitle}>
            (
            {translate('careTeam.modals.quickAdd.steps', {
              current: currentStep,
              total: TOTAL_STEPS,
            })}
            )
          </span>
        </div>
      }
    >
      <CollapsableSidebar.Body>
        {currentStep === 1 && (
          <SearchCareTeamPersonStep
            disabledPeople={disabledPeople}
            onSubmit={selectCareTeamPerson}
            firstName={selectedCareTeamCandidate?.firstName}
            lastName={selectedCareTeamCandidate?.lastName}
            phone={selectedCareTeamCandidate?.phone}
          />
        )}
        {ctp && currentStep === 2 && (
          <InfoStep
            candidate={selectedCareTeamCandidate}
            person={ctp}
            onChange={setParams}
            selectedMember={selectedMember}
            submitted={submitted}
          />
        )}
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        {currentStep === 2 && (
          <>
            <Button
              className={hideExtraButtons ? '' : styles.backButton}
              color="tertiary"
              data-cy="care-team-quick-add-back"
              onClick={handleBack}
            >
              {translate('global.back')}
            </Button>
            {!hideExtraButtons && (
              <>
                <Button
                  color="secondary"
                  data-cy="care-team-quick-add-submit-and-open"
                  disabled={
                    !params.memberId ||
                    !params.type ||
                    (params.type.id === CareTeamMemberType.AIDE &&
                      !params.aideTypeId) ||
                    (params.type.id === CareTeamMemberType.FAMILY_FRIEND &&
                      !params.relationshipId) ||
                    (params.type.id === CareTeamMemberType.PROVIDER &&
                      !params.specialityIds?.length) ||
                    (params.type.id === CareTeamMemberType.OTHER &&
                      !params.otherRelationshipId) ||
                    loadingSaveAndNew ||
                    loadingSave
                  }
                  loading={loadingSaveAndOpen}
                  onClick={handleSaveAndOpen}
                  type="outlined"
                >
                  {translate('careTeam.modals.quickAdd.saveAndOpen')}
                </Button>
                <Button
                  color="secondary"
                  data-cy="care-team-quick-add-submit-and-new"
                  disabled={
                    !params.memberId ||
                    !params.type ||
                    (params.type.id === CareTeamMemberType.AIDE &&
                      !params.aideTypeId) ||
                    (params.type.id === CareTeamMemberType.FAMILY_FRIEND &&
                      !params.relationshipId) ||
                    (params.type.id === CareTeamMemberType.PROVIDER &&
                      !params.specialityIds?.length) ||
                    (params.type.id === CareTeamMemberType.OTHER &&
                      !params.otherRelationshipId) ||
                    loadingSaveAndOpen ||
                    loadingSave
                  }
                  loading={loadingSaveAndNew}
                  onClick={handleSaveAndNew}
                  type="outlined"
                >
                  {translate('careTeam.modals.quickAdd.saveAndNew')}
                </Button>
              </>
            )}
            <Button
              color="secondary"
              data-cy="care-team-quick-add-submit"
              disabled={
                !params.memberId ||
                !params.type ||
                (params.type.id === CareTeamMemberType.AIDE &&
                  !params.aideTypeId) ||
                (params.type.id === CareTeamMemberType.FAMILY_FRIEND &&
                  !params.relationshipId) ||
                (params.type.id === CareTeamMemberType.PROVIDER &&
                  !params.specialityIds?.length) ||
                (params.type.id === CareTeamMemberType.OTHER &&
                  !params.otherRelationshipId) ||
                loadingSaveAndOpen ||
                loadingSaveAndNew
              }
              loading={loadingSave}
              onClick={handleSave}
            >
              {translate('careTeam.modals.quickAdd.save')}
            </Button>
          </>
        )}
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};
