import classnames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { makeStyles } from '@mui/styles';

import {
  Brand,
  CampaignTiming,
  Enum,
  TimeUnit,
} from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  EngagementAudience,
  EngagementCampaign,
  EngagementCampaignAudience,
  EngagementCampaignCondition,
  EngagementCampaignTimingOption,
  EngagementContent,
  EngagementContentBase,
} from '@vestahealthcare/common/models';

import {
  Button,
  CollapsableSidebar,
  IconButton,
  Modal,
  PanelInfo,
  Select,
  SelectContentOption,
  SwitchGroup,
  TextInput,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CacheServices } from 'dash/src/services';
import {
  CreateEngagementCampaignParams,
  getEngagementContents,
} from 'dash/src/services/EngagementServices';

type Props = {
  allowEditActive?: boolean;
  campaign?: EngagementCampaign;
  open: boolean;
  onClose: () => void;
  onSubmit: (
    params: CreateEngagementCampaignParams,
    campaign?: EngagementCampaign,
  ) => Promise<boolean>;
};

type RuleItem = {
  rule?: EngagementCampaignCondition;
};

const useStyles = makeStyles({
  deleteIcon: {
    height: '4.5rem',
  },
  marginTop: {
    marginTop: '2rem!important',
  },
});

const BRAND_ALL = 'ALL';
const BRAND_OPTION = { value: BRAND_ALL, label: translate('global.all') };

export const CampaignEditModal = ({
  allowEditActive,
  campaign,
  open,
  onClose,
  onSubmit,
}: Props) => {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [loadingSelect, setLoadingSelect] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [audiences, setAudiences] = useState<EngagementCampaignAudience[]>([]);
  const [contents, setContents] = useState<EngagementContent[]>([]);
  const [deliveryMethods, setDeliveryMethods] = useState<BaseEnum[]>([]);
  const [rulesAvailable, setRulesAvailable] = useState<RuleItem[]>([]);
  const [triggers, setTriggers] = useState<RuleItem[]>([]);
  const [timingOptions, setTimingOptions] = useState<
    EngagementCampaignTimingOption[]
  >([]);
  const [brands, setBrands] = useState<Selectable[]>([]);

  const [active, setActive] = useState<boolean>(false);
  const [audience, setAudience] = useState<EngagementCampaignAudience[]>();
  const [content, setContent] = useState<EngagementContentBase>();
  const [deliveryMethod, setDeliveryMethod] = useState<BaseEnum>();
  const [rules, setRules] = useState<RuleItem[]>([]);
  const [name, setName] = useState<string>();
  const [timing, setTiming] = useState<CampaignTiming>();
  const [timingDelay, setTimingDelay] = useState<number>();
  const [timingOption, setTimingOption] = useState<
    EngagementCampaignTimingOption
  >();
  const [timeUnit, setTimeUnit] = useState<TimeUnit>(TimeUnit.DAYS);
  const [trigger, setTrigger] = useState<RuleItem[]>([]);
  const [brand, setBrand] = useState<Selectable[]>([]);

  const mapBrand = (brands: Selectable[]) => (brand: Brand) =>
    brands.find(({ value }) => value === brand.value);

  const getInitialData = async () => {
    setLoadingSelect(true);
    const [
      audiences,
      deliveryMethods,
      conditions,
      timingOptions,
      brands,
    ] = await Promise.all([
      CacheServices.getEngagementCampaignAudiences(),
      CacheServices.getEngagementDeliveryMethods(),
      CacheServices.getEngagementCampaignConditions(),
      CacheServices.getEngagementCampaignTimingOptions(),
      CacheServices.getOrganizationBrands(),
    ]);
    setAudiences(audiences);
    setDeliveryMethods(deliveryMethods);
    setTriggers(conditions?.map((rule) => ({ rule })) || []);
    const rules = [
      ...(conditions?.map((rule) => ({ rule })) || []),
      ...(conditions?.map((rule) => ({ rule: { ...rule, exception: true } })) ||
        []),
    ].sort((a, b) =>
      a.rule && b.rule ? a.rule.id.localeCompare(b.rule.id) : 0,
    ) as RuleItem[];
    setRulesAvailable(rules);
    setLoadingSelect(false);
    setTimingOptions(timingOptions);
    const b = brands.map(
      ({ id, name }) => ({ value: id, label: name } as Selectable),
    );
    setBrands([BRAND_OPTION, ...b]);
    setBrand(
      (campaign?.brands?.map(mapBrand(b))?.filter(Boolean) as Selectable[]) ||
        b,
    );
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const reset = () => {
    setActive(!!campaign?.active);
    setName(campaign?.name);
    setAudience(campaign?.audience);
    setContent(campaign?.content);
    setDeliveryMethod(campaign?.deliveryMethod);
    const newRules = [
      ...(campaign?.conditions?.map((rule) => ({ rule })) || []),
      ...(campaign?.exceptions?.map((rule) => ({
        rule: { ...rule, exception: true } as EngagementCampaignCondition,
      })) || []),
    ] as RuleItem[];
    setRules(newRules?.length ? newRules : [{} as RuleItem]);
    setTimeUnit(campaign?.delayUnit || TimeUnit.DAYS);
    setTiming(
      campaign?.delay ? CampaignTiming.DELAYED : CampaignTiming.IMMEDIATE,
    );
    setTimingOption(campaign?.timingOption);
    setTimingDelay(campaign?.delay);
    setTrigger(
      campaign?.triggers?.map((rule) => ({ rule })) || [{} as RuleItem],
    );
    setBrand(
      (campaign?.brands
        ?.map(mapBrand(brands))
        ?.filter(Boolean) as Selectable[]) || brands,
    );
  };

  useEffect(() => {
    if (open) {
      setSubmitted(false);
      setLoading(false);
      reset();
    }
  }, [open]);

  useEffect(() => {
    reset();
  }, [campaign]);

  const getContentData = async () => {
    if (audience) {
      try {
        setLoadingContent(true);
        const contents = await getEngagementContents({
          active: true,
          tags: audience.map((item) => new EngagementAudience(item)),
        });
        setContents(contents);
        if (!contents.find(({ id }) => content?.id === id)) {
          setContent(undefined);
        }
        setLoadingContent(false);
      } catch (e) {
        showGlobalError(e as string);
      }
    }
  };

  useEffect(() => {
    getContentData();
  }, [audience]);

  useEffect(() => {
    if (brand.length > 1 && brand.find(({ value }) => value === BRAND_ALL)) {
      setBrand([BRAND_OPTION]);
    }
  }, [brand]);

  const checkShowModal = () => {
    setSubmitted(true);
    if (
      name &&
      audience &&
      content &&
      deliveryMethod &&
      audience &&
      timing &&
      timingOption &&
      (timing === CampaignTiming.IMMEDIATE || timingDelay) &&
      !trigger?.find((item) => !item.rule) &&
      (rules?.length === 1 || !rules?.find((item) => !item.rule)) &&
      (audience?.length !== 1 || !audience[0].isMember || brand.length)
    ) {
      if (!active && campaign?.active) {
        setShowModal(true);
      } else {
        doSubmit();
      }
    }
  };

  const doSubmit = async () => {
    if (name && content && deliveryMethod && timingOption) {
      setLoading(true);
      const result = await onSubmit(
        {
          active,
          audienceIds: audience || [],
          brands:
            audience?.length === 1 && audience[0].isMember
              ? brand?.map(({ value }) => value as string)
              : [],
          conditionIds: rules
            .map(({ rule }) => rule)
            .filter(
              (rule) => rule?.exception === false,
            ) as EngagementCampaignCondition[],
          contentId: content,
          delay: timing === CampaignTiming.DELAYED ? timingDelay || 0 : 0,
          delayUnit: timing === CampaignTiming.DELAYED ? timeUnit : undefined,
          deliveryMethodIds: deliveryMethod,
          exceptionIds: rules
            .map(({ rule }) => rule)
            .filter(
              (rule) => rule?.exception === true,
            ) as EngagementCampaignCondition[],
          name,
          timingOptionId: timingOption,
          triggerIds:
            (trigger
              .map(({ rule }) => rule)
              .filter(
                (rule) => rule?.exception === false,
              ) as EngagementCampaignCondition[]) || [],
        },
        campaign,
      );
      if (result) {
        onClose();
      }
      setLoading(false);
    }
  };

  const getAvailableRules = (rule?: RuleItem) => {
    const ruleCondition = rules.map(({ rule }) => rule);
    const triggerCondition = trigger.map(({ rule }) => rule);
    return rulesAvailable.filter(
      (item) =>
        (rule?.rule && item.rule && rule?.rule?.id === item.rule?.id) ||
        (!ruleCondition?.find(
          (ruleC) => ruleC && item.rule && ruleC?.id === item.rule?.id,
        ) &&
          !triggerCondition?.find(
            (ruleT) => ruleT && item.rule && ruleT?.id === item.rule?.id,
          )),
    );
  };

  const getAvailableTiggers = (rule?: RuleItem) => {
    const ruleCondition = rules.map(({ rule }) => rule);
    const triggerCondition = trigger.map(({ rule }) => rule);
    return triggers.filter(
      (item) =>
        (rule?.rule && item.rule && rule?.rule?.id === item.rule?.id) ||
        (!ruleCondition?.find(
          (ruleC) => ruleC && item.rule && ruleC?.id === item.rule?.id,
        ) &&
          !triggerCondition?.find(
            (ruleT) => ruleT && item.rule && ruleT?.id === item.rule?.id,
          )),
    );
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={
        <h2>
          {translate(
            `campaigns.manager.modalTitle${campaign ? 'Edit' : 'Add'}`,
          )}
        </h2>
      }
      size={650}
    >
      <CollapsableSidebar.Body>
        <div className="grid-wrapper fit">
          <SwitchGroup
            className="grid-span-12"
            disabled={!allowEditActive}
            items={[
              { label: translate('campaigns.manager.active'), checked: active },
            ]}
            onChange={(items) => setActive(!!items[0].checked)}
          />
          <TextInput
            className="grid-span-6"
            data-cy="edit-campaign-name"
            error={submitted && !name}
            label={translate('campaigns.manager.name')}
            onChange={(value) => {
              const safeValue = value
                ?.trim()
                .split(' ')
                .filter(Boolean)
                .join(' ');
              setName(safeValue || '');
            }}
            required
            value={name}
          />
          <Select
            className="grid-span-6"
            data-cy="edit-campaign-audience"
            error={submitted && !audience}
            getItemLabel={({ description }: EngagementCampaignAudience) =>
              description
            }
            items={audiences}
            label={translate('campaigns.manager.audience')}
            limitTags={1}
            loading={loadingSelect}
            multiple
            onChange={setAudience}
            required
            value={audience}
          />
          {audience?.length === 1 && audience[0].isMember && (
            <Select
              className="grid-span-12"
              data-cy="edit-campaign-brand"
              error={submitted && !brand?.length}
              getItemDisabled={({ value }) =>
                value !== BRAND_ALL && brand[0]?.value === BRAND_ALL
              }
              items={brands}
              label={translate('campaigns.manager.brand')}
              limitTags={3}
              loading={loadingSelect}
              multiple
              onChange={setBrand}
              required
              value={brand}
            />
          )}
          <div className="grid-span-12 grid-wrapper fit">
            {trigger.map((item, index) => (
              <Fragment key={`trigger-id-${item?.rule?.id || index}`}>
                <Select
                  className="grid-span-11"
                  data-cy="edit-campaign-trigger"
                  error={submitted && !item.rule}
                  getItemLabel={(item: RuleItem) =>
                    item.rule?.descTrigger || ''
                  }
                  items={getAvailableTiggers(item).filter(
                    (item) => item.rule?.trigger,
                  )}
                  label={
                    index === 0 ? translate('campaigns.manager.triggers') : ''
                  }
                  limitTags={1}
                  loading={loadingSelect}
                  onChange={(val?: RuleItem) => {
                    trigger[index].rule = val?.rule;
                    setTrigger([...trigger]);
                  }}
                  required
                  value={item}
                />
                <IconButton
                  className={classnames(
                    'grid-span-1',
                    styles.deleteIcon,
                    index === 0 && styles.marginTop,
                  )}
                  data-cy="edit-campaign-triggers-delete-row"
                  disabled={trigger.length < 2}
                  size="small"
                  onClick={() => {
                    trigger.splice(index, 1);
                    setTrigger([...trigger]);
                  }}
                >
                  <DeleteOutlineIcon fontSize="large" />
                </IconButton>
              </Fragment>
            ))}
            {!!getAvailableRules().filter((item) => item.rule?.trigger)
              ?.length && (
              <>
                <div className="grid-span-2" />
                <Button
                  className="grid-span-8"
                  color="tertiary"
                  icon={<AddIcon />}
                  onClick={() => setTrigger([...trigger, {} as RuleItem])}
                >
                  {translate('campaigns.manager.addTrigger')}
                </Button>
              </>
            )}
          </div>
          <div className="grid-span-12 grid-wrapper fit">
            {rules.map((item, index) => (
              <Fragment
                key={`rule-id-${item?.rule?.id || index}-expcetion-${
                  item?.rule?.exception || index
                }`}
              >
                <Select
                  className="grid-span-11"
                  data-cy="edit-campaign-condition-row"
                  error={
                    submitted &&
                    rules.length !== 1 &&
                    !item.rule &&
                    (index === rules.length - 1
                      ? translate('global.missingRequiredFieldMin')
                      : true)
                  }
                  getItemLabel={(item: RuleItem) =>
                    (item.rule?.exception
                      ? item.rule.descException
                      : item.rule?.descCondition) || ''
                  }
                  items={getAvailableRules(item)}
                  isOptionEqualToValue={(a: RuleItem, b: RuleItem) =>
                    a.rule?.id === b.rule?.id &&
                    a.rule?.exception === b.rule?.exception
                  }
                  label={
                    index === 0 ? translate('campaigns.manager.conditions') : ''
                  }
                  limitTags={1}
                  loading={loadingSelect}
                  onChange={(val?: RuleItem) => {
                    rules[index].rule = val?.rule;
                    setRules([...rules]);
                  }}
                  value={item}
                />
                <IconButton
                  className={classnames(
                    'grid-span-1',
                    styles.deleteIcon,
                    index === 0 && styles.marginTop,
                  )}
                  data-cy="edit-campaign-conditions-delete-row"
                  disabled={rules.length < 2}
                  size="small"
                  onClick={() => {
                    rules.splice(index, 1);
                    setRules([...rules]);
                  }}
                >
                  <DeleteOutlineIcon fontSize="large" />
                </IconButton>
              </Fragment>
            ))}
            {!!getAvailableRules()?.length && (
              <>
                <div className="grid-span-2" />
                <Button
                  className="grid-span-8"
                  color="tertiary"
                  icon={<AddIcon />}
                  onClick={() => setRules([...rules, {} as RuleItem])}
                >
                  {translate('campaigns.manager.addCondition')}
                </Button>
              </>
            )}
          </div>
          <Select
            className={`grid-span-${
              timing === CampaignTiming.DELAYED ? 6 : 12
            }`}
            data-cy="edit-campaign-timing"
            disableClearable
            error={submitted && !timing}
            items={Enum.toSelectable(CampaignTiming.asArray)}
            label={translate('campaigns.manager.timing')}
            loading={loadingSelect}
            onChange={(val?: Selectable) =>
              setTiming(val ? CampaignTiming.byKey[val.value] : undefined)
            }
            required
            value={timing ? Enum.toSelectable([timing])[0] : undefined}
          />
          {timing === CampaignTiming.DELAYED && (
            <>
              <TextInput
                className="grid-span-3"
                data-cy="edit-campaign-description"
                error={submitted && !timingDelay}
                label={translate('campaigns.manager.timingDelay')}
                maxLength={2}
                onChange={(val?: string) => {
                  const number = Number.parseInt(val || '', 10);
                  setTimingDelay(number || 0);
                }}
                required
                value={String(timingDelay || '')}
              />
              <Select
                className="grid-span-3"
                data-cy="edit-campaign-time-unit"
                disableClearable
                error={submitted && !timeUnit}
                items={Enum.toSelectable([TimeUnit.HOURS, TimeUnit.DAYS])}
                label={translate('campaigns.manager.timeUnit')}
                onChange={(val: Selectable) =>
                  setTimeUnit(TimeUnit.byKey[val.value])
                }
                required
                value={Enum.toSelectable([timeUnit])[0]}
              />
            </>
          )}
          <Select
            className="grid-span-12"
            data-cy="edit-campaign-timing-option"
            disableClearable
            error={submitted && !timingOption}
            getItemLabel={(item?: EngagementCampaignTimingOption) =>
              item?.toString()
            }
            items={timingOptions}
            label={translate('campaigns.manager.timingOption')}
            loading={loadingSelect}
            onChange={setTimingOption}
            required
            value={timingOption}
          />
          <Select
            className="grid-span-6"
            data-cy="edit-campaign-content"
            disableClearable
            disabled={!audience}
            error={submitted && !content}
            getItemLabel={({ name }: EngagementContent) => name}
            renderOption={(content: EngagementContent) => (
              <SelectContentOption content={content} />
            )}
            items={contents}
            label={translate('campaigns.manager.content')}
            loading={loadingContent}
            onChange={setContent}
            required
            value={contents.find(({ id }) => content?.id === id)}
          />
          <Select
            className="grid-span-6"
            data-cy="edit-campaign-delivery-method"
            disableClearable
            error={submitted && !deliveryMethod}
            getItemLabel={({ description }: BaseEnum) => description}
            items={deliveryMethods}
            label={translate('campaigns.manager.deliveryMethod')}
            loading={loadingSelect}
            onChange={setDeliveryMethod}
            required
            value={deliveryMethod}
          />
          {(trigger?.filter(({ rule }) => !!rule)?.length || 0) +
            (rules?.filter(({ rule }) => !!rule)?.length || 0) >
            1 && (
            <PanelInfo
              className="grid-span-12"
              type="warning"
              title={translate(
                'campaigns.manager.warningMultipleTriggersConditions',
              )}
            />
          )}
        </div>
        <Modal
          title={translate('global.warning')}
          body={translate('campaigns.manager.warningModalBody')}
          onClose={() => setShowModal(false)}
          onSubmit={doSubmit}
          open={showModal}
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="edit-campaign-close"
          onClick={onClose}
        >
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="edit-campaign-submit"
          loading={loading}
          onClick={checkShowModal}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default CampaignEditModal;
