/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Choice as ChoiceEnum } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';

import { Data, Props } from './Props';
import { SelectComponent } from './Select';

interface ChoiceProps extends Omit<Props, 'items' | 'value' | 'onChange'> {
  items?: Data[];
  onChange: (value?: boolean) => void;
  value?: boolean;
}

export const Choice = ({ items, value, onChange, ...props }: ChoiceProps) => (
  <SelectComponent
    items={items || ChoiceEnum.getChoices()}
    onChange={(val?: Selectable) =>
      onChange(
        val !== undefined && val !== null ? Boolean(val.value) : undefined,
      )
    }
    value={
      value !== undefined && value !== null
        ? ChoiceEnum.getChoices()[value ? 0 : 1]
        : undefined
    }
    {...props}
  />
);
