import { Moment } from 'moment';

import { makeMoment } from '../Model';
import { MemberWarning } from './MemberWarning';

export class MemberWarningInitialCarePlan extends MemberWarning {
  lastOnboardingDate?: Moment;

  lastOnboardingEmployee = '';

  constructor(obj: any) {
    super(obj);
    Object.assign(this, obj);
    this.lastOnboardingDate = makeMoment(obj.lastOnboardingDate);
  }
}

export default MemberWarningInitialCarePlan;
