import moment from 'moment';
import React, { useEffect, useState } from 'react';

import LaunchIcon from '@mui/icons-material/Launch';

import { translate } from '@vestahealthcare/common/i18n';
import { TOCDetail } from '@vestahealthcare/common/models';
import {
  DATE_FORMAT,
  DATE_FORMAT_SHORT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  DateTimePicker,
  Label,
  TextArea,
  TextInput,
} from 'styleguide-v2';

import { UpdateTOCParams } from 'dash/src/services/TOCServices';

type Props = {
  className?: string;
  detail?: TOCDetail;
  onChange: (params: Partial<UpdateTOCParams>) => void;
  readonly?: boolean;
  submitted?: boolean;
};

export const TOCHospitalizationCard = ({
  className,
  detail,
  onChange,
  readonly,
  submitted,
}: Props) => {
  const [diagnosis, setDiagnosis] = useState<string>();
  const [admitDate, setAdmitDate] = useState<Date>();
  const [dischargeDate, setDischageDate] = useState<Date>();
  const [facility, setFacility] = useState<string>();

  useEffect(() => {
    setDiagnosis(detail?.tocRecord?.chiefComplaint);
    setAdmitDate(detail?.tocRecord?.admitDate?.toDate());
    setDischageDate(detail?.tocRecord?.dischargeDate?.toDate());
    setFacility(detail?.tocRecord?.facility);
  }, [detail]);

  useEffect(() => {
    onChange({
      admitDate: admitDate
        ? moment(admitDate.getTime()).format(DATE_FORMAT)
        : null,
      chiefComplaint: diagnosis,
      dischargeDate: dischargeDate
        ? moment(dischargeDate.getTime()).format(DATE_FORMAT)
        : null,
      facility,
    });
  }, [admitDate, diagnosis, dischargeDate, facility]);

  return (
    <Card className={className}>
      <CardHeader
        title={translate('toc.detail.hospitalizationTitle')}
        action={
          <Button
            color="quaternary"
            icon={<LaunchIcon />}
            size="s"
            onClick={() =>
              window.open(
                `/#/patients/${detail?.memberDetail?.id}/events/${detail?.tocRecord?.sourceEventId}`,
                '_blank',
              )
            }
          >
            <span className="bold">{translate('toc.detail.openEvent')}</span>
          </Button>
        }
      />
      <CardContent className="grid-wrapper" sx={{ paddingTop: 0 }}>
        <TextArea
          className="grid-span-12"
          error={submitted && !diagnosis}
          label={translate('toc.detail.diagnosis')}
          maxRows={5}
          onChange={setDiagnosis}
          readOnly={readonly}
          value={diagnosis}
          required
        />
        {readonly ? (
          <div className="grid-span-3">
            <p className="no-margin">
              <Label>{translate('toc.detail.admitDate')}</Label>
            </p>
            <span
              className="flex middle extra-gap"
              style={{ marginTop: readonly ? undefined : '1rem' }}
            >
              {detail?.tocRecord?.admitDate?.format(DATE_FORMAT_SHORT) || EMPTY}{' '}
              {detail?.tocRecord?.daysInpatient !== undefined && (
                <Chip color="action" type="contained" size="small">
                  <span>{detail.tocRecord.daysInpatient}</span>
                </Chip>
              )}
            </span>
          </div>
        ) : (
          <DateTimePicker
            className="grid-span-3"
            label={translate('toc.detail.admitDate')}
            maxDate={detail?.tocRecord?.dischargeDate?.toDate()}
            onChange={setAdmitDate}
            value={admitDate}
          />
        )}
        {readonly ? (
          <div className="grid-span-3">
            <p className="no-margin">
              <Label>{translate('toc.detail.dischargeDate')}</Label>
            </p>
            <span
              className="flex middle extra-gap"
              style={{ marginTop: readonly ? undefined : '1rem' }}
            >
              {detail?.tocRecord?.dischargeDate?.format(DATE_FORMAT_SHORT) ||
                EMPTY}{' '}
              {detail?.tocRecord?.businessDaysSinceDischargeDate !==
                undefined && (
                <Chip color="action" type="contained" size="small">
                  <span>{detail.tocRecord.businessDaysSinceDischargeDate}</span>
                </Chip>
              )}
            </span>
          </div>
        ) : (
          <DateTimePicker
            className="grid-span-3"
            label={translate('toc.detail.dischargeDate')}
            minDate={detail?.tocRecord?.admitDate?.toDate()}
            maxDate={new Date()}
            onChange={setDischageDate}
            value={dischargeDate}
          />
        )}
        <TextInput
          className="grid-span-6"
          error={submitted && !facility}
          label={translate('toc.detail.facility')}
          onChange={setFacility}
          readOnly={readonly}
          required
          value={readonly ? facility || EMPTY : facility}
        />
      </CardContent>
    </Card>
  );
};
