import { Enum } from './Enum';

export class TaskDefinitionStatus extends Enum {
  translationKey = 'taskDefinitionStatus';

  static asArray: TaskDefinitionStatus[] = [];

  static byKey: { [name: string]: TaskDefinitionStatus } = {};

  static readonly DRAFT = new TaskDefinitionStatus('DRAFT');

  static readonly INACTIVE = new TaskDefinitionStatus('INACTIVE');

  static readonly PUBLISHED = new TaskDefinitionStatus('PUBLISHED');

  constructor(public readonly value: string) {
    super();
    TaskDefinitionStatus.asArray.push(this);
    TaskDefinitionStatus.byKey[value] = this;
  }
}

export default TaskDefinitionStatus;
