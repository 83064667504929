import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  EncounterChannel,
  EncounterDirection,
  EncounterMethod,
  EncounterMethodOutcome,
  EncounterType,
  EventReporterType,
} from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  CareTeamMember,
  EventDetail,
  MemberCommunityResource,
  Patient,
} from '@vestahealthcare/common/models';

import {
  Button,
  CollapsableSidebar,
  DateTimePicker,
  Select,
  TextArea,
  TextInput,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CareTeamQuickAddModal } from 'dash/src/pages/CareTeam/modals/CareTeamQuickAdd';
import { onCareTeamMembersUpdated } from 'dash/src/redux/slices/MemberInfoSlice';
import {
  fetchMemberCareTeamMembers,
  quickAddCareTeam,
} from 'dash/src/services/CareTeamServices';
import { CreateEventEncounterParams } from 'dash/src/services/EventServices';

import { EventReporter } from '../components/EventReporter';

type Props = {
  event?: EventDetail;
  open: boolean;
  onClose: () => void;
  onSubmit: (
    event: EventDetail,
    params: CreateEventEncounterParams,
  ) => Promise<boolean>;
  patient?: Patient;
};

export const MemberEventAddEncounter = ({
  event,
  open,
  onClose,
  onSubmit,
  patient,
}: Props) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [openAddCTM, setOpenAddCTM] = useState<boolean>(false);

  const [date, setDate] = useState<Date>(new Date());
  const [time, setTime] = useState<Date>();
  const [source, setSource] = useState<EncounterMethod>();
  const [sourceDirection, setSourceDirection] = useState<EncounterDirection>();
  const [sourceOutcome, setSourceOutcome] = useState<EncounterMethodOutcome>();
  const [reporterType, setReporterType] = useState(EventReporterType.MEMBER);
  const [careTeamMember, setCareTeamMember] = useState<CareTeamMember>();
  const [communityResource, setCommunityResource] = useState<
    MemberCommunityResource
  >();
  const [type, setType] = useState<EncounterType>();
  const [totalMinutes, setTotalMinutes] = useState<number>();
  const [note, setNote] = useState<string>();

  const reset = () => {
    setSubmitted(false);
    setDate(new Date());
    setTime(undefined);
    setSource(undefined);
    setSourceDirection(undefined);
    setSourceOutcome(undefined);
    setType(undefined);
    setTotalMinutes(undefined);
    setNote(undefined);
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  useEffect(() => {
    setSourceOutcome(undefined);
  }, [source]);

  const validate = () =>
    date &&
    time &&
    (source === EncounterMethod.VIDEO || sourceDirection) &&
    totalMinutes !== Number.NaN &&
    (reporterType !== EventReporterType.CARE_TEAM || careTeamMember);

  const doSubmit = async () => {
    setSubmitted(true);
    if (patient && event && source && type && totalMinutes && validate()) {
      setLoading(true);
      const datetime = moment(date.getTime());
      datetime
        .hours(time?.getHours() || 0)
        .minutes(time?.getMinutes() || 0)
        .seconds(0);
      try {
        const result = await onSubmit(event, {
          careTeamMemberId:
            reporterType === EventReporterType.CARE_TEAM
              ? careTeamMember?.id
              : undefined,
          communityResourceId:
            reporterType === EventReporterType.COMMUNITY_RESOURCE
              ? communityResource?.id
              : undefined,
          channel: EncounterChannel.EVENT,
          direction:
            source !== EncounterMethod.VIDEO
              ? sourceDirection
              : EncounterDirection.OUTBOUND,
          duration: totalMinutes,
          encounterDate: datetime,
          memberId: patient.id,
          method: source,
          methodOutcome: sourceOutcome,
          type,
          note,
        });
        if (result) {
          onClose();
        }
      } finally {
        setSubmitted(false);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <CollapsableSidebar
        onClose={onClose}
        open={open}
        title={<h2>{translate('memberEvents.addEncounterModalTitle')}</h2>}
        size={550}
      >
        <CollapsableSidebar.Body>
          <div className="grid-wrapper fit">
            <DateTimePicker
              className="grid-span-6"
              error={submitted && !date}
              label={translate('memberEvents.encounterDate')}
              maxDate={new Date()}
              onChange={setDate}
              required
              type="date"
              value={date}
            />
            <DateTimePicker
              className="grid-span-6"
              error={submitted && !time}
              label={translate('memberEvents.encounterTime')}
              maxTime={
                date && moment(date.getTime()).isSame(moment(), 'day')
                  ? new Date()
                  : undefined
              }
              onChange={setTime}
              required
              type="time"
              value={time}
            />
          </div>

          <div className="grid-wrapper fit">
            <Select
              className={
                source && source !== EncounterMethod.VIDEO
                  ? 'grid-span-6'
                  : 'grid-span-12'
              }
              error={submitted && !source}
              getItemLabel={(item: EncounterMethod) => item.toString()}
              items={EncounterMethod.asArray.sort(
                ({ value: a }, { value: b }) => a.localeCompare(b),
              )}
              label={translate('memberEvents.method')}
              onChange={setSource}
              required
              value={source}
            />
            {source && source !== EncounterMethod.VIDEO && (
              <Select
                className="grid-span-6"
                error={submitted && !sourceDirection}
                getItemLabel={(item: EncounterDirection) => item.toString()}
                items={EncounterDirection.asArray}
                label={translate('memberEvents.direction')}
                onChange={setSourceDirection}
                required
                value={sourceDirection}
              />
            )}
            {sourceDirection === EncounterDirection.OUTBOUND && (
              <Select
                className="grid-span-12"
                error={submitted && !sourceOutcome}
                getItemLabel={(item: EncounterMethodOutcome) => item.toString()}
                items={EncounterMethod.getEncounterOutomes(source)}
                label={translate('memberEvents.sourceOutcome')}
                onChange={(item: Selectable) =>
                  setSourceOutcome(
                    item ? EncounterMethodOutcome.byKey[item.value] : undefined,
                  )
                }
                required
                value={sourceOutcome}
              />
            )}
          </div>
          <EventReporter
            addCareTeamMember
            onAddCareTeamMember={() => setOpenAddCTM(true)}
            onChange={(type, reporter) => {
              setReporterType(type);
              if (type === EventReporterType.CARE_TEAM) {
                setCareTeamMember(reporter as CareTeamMember);
                setCommunityResource(undefined);
              } else if (type === EventReporterType.COMMUNITY_RESOURCE) {
                setCareTeamMember(undefined);
                setCommunityResource(reporter as MemberCommunityResource);
              } else {
                setCareTeamMember(undefined);
                setCommunityResource(undefined);
              }
            }}
            patient={patient}
            showCommunityResources
            submitted={submitted}
          />

          <div className="grid-wrapper fit">
            <Select
              className="grid-span-12"
              error={submitted && !type}
              getItemLabel={(item) => item.toString()}
              items={EncounterType.asArray}
              label={translate('memberEvents.type')}
              onChange={setType}
              required
              value={type}
            />
          </div>
          <TextInput
            error={submitted && !totalMinutes && totalMinutes !== Number.NaN}
            label={translate('memberEvents.totalMinutes')}
            onChange={(val) => setTotalMinutes(Number(val))}
            required
          />

          <TextArea
            label={translate('memberEvents.note')}
            onChange={setNote}
            rows={3}
          />
        </CollapsableSidebar.Body>
        <CollapsableSidebar.Buttons>
          <Button
            color="tertiary"
            data-cy="add-encounter-close"
            onClick={onClose}
          >
            {translate('global.cancel')}
          </Button>
          <Button
            color="secondary"
            data-cy="add-encounter-submit"
            loading={loading}
            onClick={doSubmit}
          >
            {translate('memberEvents.saveEncounter')}
          </Button>
        </CollapsableSidebar.Buttons>
      </CollapsableSidebar>
      <CareTeamQuickAddModal
        hideExtraButtons
        open={openAddCTM}
        onClose={() => setOpenAddCTM(false)}
        onSubmit={async (params) => {
          let ctm: CareTeamMember | undefined;
          const patientId = patient instanceof Patient ? patient.id : patient;
          try {
            if (patientId) {
              ctm = await quickAddCareTeam(params);
              const { items } = await fetchMemberCareTeamMembers(patientId);
              dispatch(onCareTeamMembersUpdated(items));
              setOpenAddCTM(false);
            }
          } catch (e) {
            showGlobalError(e as string);
          }
          return ctm?.careTeamPerson || false;
        }}
        selectedMember={patient instanceof Patient ? patient : undefined}
      />
    </>
  );
};

export default MemberEventAddEncounter;
