import classNames from 'classnames';
import { Moment } from 'moment';
import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { MemberDashboardAction } from '@vestahealthcare/common/models';

import { Card, Chip, Colors, Fonts, Tooltip } from 'styleguide-v2';

import { RecentActionTable } from './RecentActionTable';

type Props = {
  className?: string;
  data?: MemberDashboardAction[];
  lastUpdated?: Moment;
  openActions?: number;
};

const useStyles = makeStyles({
  container: {
    '&&': {
      borderRadius: '8px',
    },

    display: 'flex',

    '& > div': {
      flex: 1,
      padding: '2.4rem 3.2rem',
    },
  },
  subContainer: {
    display: 'flex',
    flexFlow: 'column',
  },
  title: {
    alignItems: 'center',
    display: 'flex',

    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontWeight: 500,
      margin: 0,
    },
  },
  updated: {
    color: Colors.textGray,
    fontSize: `calc(${Fonts.fontSize} * 0.625)`,
    fontStyle: 'italic',
  },
});

export const RecentActionsCard = ({
  className,
  data,
  lastUpdated,
  openActions,
}: Props) => {
  const styles = useStyles();

  return (
    <Card className={classNames(className, styles.container)}>
      <div className={styles.subContainer}>
        <div className="flex spaced">
          <span className={styles.title}>
            {translate('memberProfile.dashboard.recentActions')}
            &nbsp;
            <Tooltip
              text={translate('memberProfile.dashboard.recentActionsTooltip')}
            >
              <InfoOutlinedIcon htmlColor={Colors.iconGray} />
            </Tooltip>
            &nbsp;&nbsp;
            {!!openActions && (
              <Chip color="error" type="contained-2">
                <span className="bold">{openActions} OPEN</span>
              </Chip>
            )}
          </span>
        </div>
        <br />
        <RecentActionTable data={data || []} lastUpdated={lastUpdated} />
      </div>
    </Card>
  );
};
