import classNames from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { Colors, SecondaryColors } from 'styleguide';
import { Table } from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

import { ValidationResponse } from 'dash/src/services/RosterServices';

import { CSVFileInfo } from '.';

interface Props {
  validationResponse: ValidationResponse;
  fileInfo?: CSVFileInfo;
  columns: Column[];
}

const useStyles = makeStyles({
  container: {
    overflow: 'scroll',
    height: 'max-content',
    width: '100%',
  },
  table: {
    width: 'max-content!important',
    '& tr:has(> td.pink-stripped)': {
      background: `repeating-linear-gradient(
          135deg,
          ${Colors.white} 0px,
          ${Colors.white} 20px,
          ${SecondaryColors.pink} 20px,
          ${SecondaryColors.pink} 40px
          )!important`,
    },
  },
  pink: {
    backgroundColor: `${SecondaryColors.pink}!important`,
  },
  yellow: {
    backgroundColor: `${Colors.yellow}!important`,
  },
});

const getPropCaseInsensitive = (item: any, caseInsensitiveKey: string) => {
  if (Object.keys(item).length > 0) {
    const foundKey = Object.keys(item).find(
      (key) => key.toLowerCase() === caseInsensitiveKey.toLowerCase(),
    );
    if (foundKey) return item[foundKey];
  }
  return undefined;
};

export function FileContentTable({
  fileInfo,
  validationResponse,
  columns,
}: Props) {
  const styles = useStyles();
  const rowsWithErrors = validationResponse.errors.map((row) => row.row);
  const rowsWithWarnings = validationResponse.warnings.map((row) => row.row);
  const rowsWithDuplicated = validationResponse.warnings
    .filter((row) => row.type === 'DUPLICATE_ENTITY')
    .map((row) => row.row);
  const rowsDuplicated = validationResponse.errors
    .filter((row) => row.type === 'DUPLICATE_PARAM')
    .map((row) => row.duplicateIdentity);

  if (!fileInfo || !fileInfo.fileContents.length) {
    return null;
  }

  const caseInsensitiveColumns = (columns || []).map((column) => ({
    ...column,
    component: (row: any) => getPropCaseInsensitive(row, column?.field || ''),
  }));

  return (
    <div className={classNames('grid-span-12', styles.container)}>
      <Table
        className={styles.table}
        config={{
          columns: caseInsensitiveColumns,
          compact: true,
          data: [
            ...fileInfo?.fileContents.map((item, index) => ({
              ...item,
              originalRow: index,
            })),
          ].sort(({ originalRow: a }, { originalRow: b }) => {
            if (rowsWithDuplicated.includes(a)) return 1;
            if (rowsWithDuplicated.includes(b)) return -1;
            return 0;
          }),
          getRowClass: (rowInfo: any) => {
            if (rowsDuplicated.includes(rowInfo?.originalRow)) {
              return 'pink-stripped';
            }

            if (rowsWithErrors.includes(rowInfo?.originalRow)) {
              return styles.pink;
            }

            if (rowsWithWarnings.includes(rowInfo?.originalRow)) {
              return styles.yellow;
            }

            return '';
          },
        }}
        fontSize="small"
        whiteBackground
      />
      <br />
    </div>
  );
}
