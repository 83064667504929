import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import {
  BiometricsRPMAdherenceCycle,
  Patient,
} from '@vestahealthcare/common/models';

import { Button, CollapsableSidebar, Spinner } from 'styleguide-v2';

import { fetchRPMAdherenceHistory } from 'dash/src/services/BiometricsServices';

import RPMAdherenceTable from './RPMAdherenceTable';

type Props = {
  member?: Patient;
  open: boolean;
  onClose: () => void;
};

export const RPMAdherenceModal = ({ member, onClose, open }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [cycles, setCycles] = useState<BiometricsRPMAdherenceCycle[]>([]);
  const [lastMemberId, setLastMemberId] = useState<number>();

  const getData = async () => {
    if (!member) return;

    setLoading(true);
    const cycles = await fetchRPMAdherenceHistory(member.id);
    setLastMemberId(member?.id || 0);
    setCycles(cycles.reverse().slice(1));
    setLoading(false);
  };

  useEffect(() => {
    if (open && lastMemberId !== member?.id) {
      getData();
    }
  }, [open]);

  return (
    <CollapsableSidebar
      title={
        <h2>
          {translate('biometrics.rpmAdherence.titleHistoryModal', {
            member: member?.fullName,
          })}
        </h2>
      }
      onClose={onClose}
      open={open}
    >
      <CollapsableSidebar.Body>
        {loading ? (
          <div className="flex center">
            <Spinner width={42} />{' '}
          </div>
        ) : (
          <RPMAdherenceTable data={cycles} />
        )}
      </CollapsableSidebar.Body>
      {!loading && (
        <CollapsableSidebar.Buttons>
          <Button color="tertiary" data-cy="edit-group-close" onClick={onClose}>
            {translate('global.close')}
          </Button>
        </CollapsableSidebar.Buttons>
      )}
    </CollapsableSidebar>
  );
};

export default RPMAdherenceModal;
