import { translate } from '@vestahealthcare/common/i18n';
import { Task } from '@vestahealthcare/common/models';
import React, { CSSProperties } from 'react';
import { EmptyState, Table } from 'styleguide';
import TasksTableRow from './TasksTableRow';

const tableColumns = [
  'status',
  'dueDate',
  'completedAt',
  'description',
  'group',
  'assignee',
  'member',
  'createdAt',
];

const columnStyles: { [key: string]: CSSProperties } = {
  status: { width: 125, cursor: 'pointer' },
  dueDate: { width: 125, cursor: 'pointer' },
  completedAt: { width: 125, cursor: 'pointer' },
  description: { cursor: 'pointer', width: 200 },
  group: { width: 100 },
  assignee: { width: 100 },
  member: { width: 150 },
  createdAt: { width: 150, cursor: 'pointer' },
};

interface Props {
  loading: boolean;
  tasks: Task[];
  inactive?: boolean;
  activeId?: number;
  activeSubtaskId?: number;
  currentPage?: number;
  numPages?: number;
  onNext?: () => void;
  onPrev?: () => void;
  onTaskUpdate?: () => void;
  onClickRow?: (task: Task) => void;
  onSort?: (key: string) => void;
  colSpan?: number;
  showSubTasks?: boolean;
}

export const TasksTable = ({
  loading,
  tasks,
  inactive,
  activeId,
  activeSubtaskId,
  onClickRow,
  currentPage,
  numPages,
  onNext,
  onPrev,
  onSort,
  onTaskUpdate,
  colSpan,
  showSubTasks,
}: Props) => {
  return (
    <>
      <Table
        color={inactive ? 'inactive' : 'default'}
        className="no-margin-bottom data-table"
        currentPage={currentPage}
        numPages={numPages}
        onNext={(numPages || 0) > 0 ? onNext : undefined}
        onPrev={(numPages || 0) > 0 ? onPrev : undefined}
      >
        <thead>
          <tr>
            {tableColumns.map((column) => (
              <th
                key={column}
                style={columnStyles[column] || {}}
                onClick={() => {
                  if (!onSort) return;
                  onSort(column);
                }}
              >
                {translate(`tasks.tableHeaders.${column}`)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => (
            <TasksTableRow
              task={task}
              key={task.id}
              active={task.id === activeId}
              activeSubtaskId={activeSubtaskId}
              onClick={onClickRow}
              onTaskUpdate={onTaskUpdate}
              colSpan={colSpan || tableColumns.length}
              showSubTasks={showSubTasks}
            />
          ))}
        </tbody>
      </Table>
      <EmptyState show={!loading && !tasks.length}>
        {translate('tasks.empty')}
      </EmptyState>
    </>
  );
};

export default TasksTable;
