import React, { PureComponent } from 'react';
import { NumericInput } from 'styleguide';
import { ControlLabel } from 'react-bootstrap';
import './main.less';

const NOOP = () => {};

interface Props {
  'data-cy'?: string;
  asNumbers?: boolean;
  className?: string;
  defaultLowerValue?: number;
  defaultUpperValue?: number;
  disabled?: boolean;
  errorText?: string;
  label?: string;
  lowerValue?: number;
  onChange: (any: any) => any;
  required?: boolean;
  showError?: boolean;
  units?: string;
  upperValue?: number;
}

export default class MinMaxInput extends PureComponent<Props> {
  static defaultProps = {
    asNumbers: false,
    validate: true,
    value: '',
  };

  state = {
    upperVal: this.props.upperValue
      ? this.props.upperValue
      : this.props.defaultUpperValue,
    lowerVal: this.props.lowerValue
      ? this.props.lowerValue
      : this.props.defaultLowerValue,
  };

  // TODO: refactor me when we deprecate assessments
  onChange() {
    const { asNumbers } = this.props;
    const { upperVal, lowerVal } = this.state;

    const fallBack = asNumbers ? undefined : 0;
    const upper = upperVal || this.props.defaultUpperValue || fallBack;
    const lower = lowerVal || this.props.defaultLowerValue || fallBack;

    asNumbers
      ? this.props.onChange([upper, lower])
      : this.props.onChange(`${upper}/${lower}`);
  }

  render() {
    const {
      className,
      defaultLowerValue,
      defaultUpperValue,
      disabled,
      label,
      required,
      showError,
      units,
    } = this.props;

    const { upperVal, lowerVal } = this.state;

    return (
      <div className={className} data-cy={this.props['data-cy']}>
        <ControlLabel>
          {label}
          {required && ' *'}
        </ControlLabel>
        <div className={units ? 'ratio-input wide' : 'ratio-input'}>
          <NumericInput
            data-cy="systolic-bp"
            disabled={disabled}
            errorText={this.props.errorText}
            value={upperVal || defaultUpperValue}
            onBlur={NOOP}
            onChange={(val) => this.setState({ upperVal: val }, this.onChange)}
            placeholder="#"
            required={required}
            showError={showError}
          />
          <span className="ratio-input-divider">/</span>
          <NumericInput
            data-cy="diastolic-bp"
            disabled={disabled}
            errorText={this.props.errorText}
            value={lowerVal || defaultLowerValue}
            onBlur={NOOP}
            onChange={(val) => this.setState({ lowerVal: val }, this.onChange)}
            placeholder="#"
            required={required}
            showError={showError}
          />
          <div className="left-margin">{units}</div>
        </div>
      </div>
    );
  }
}
