import { MemberContactInfoMethod } from './MemberContactInfoMethod';
import { MemberContactInfoTimes } from './MemberContactInfoTimes';
import { makeArray } from './Model';

export class MemberContactInfo {
  id = 0;

  memberId = 0;

  note = '';

  memberContactMethods?: MemberContactInfoMethod[];

  memberContactTimes?: MemberContactInfoTimes[];

  constructor(obj: any) {
    Object.assign(this, obj);
    if (obj?.memberContactMethods) {
      this.memberContactMethods = makeArray(
        obj.memberContactMethods,
        MemberContactInfoMethod,
      );
    }
    if (obj?.memberContactTimes) {
      this.memberContactTimes =
        obj.memberContactTimes &&
        makeArray(obj.memberContactTimes, MemberContactInfoTimes);
    }
  }
}

export default MemberContactInfo;
