import classNames from 'classnames';
import React, { Fragment, useMemo } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { makeStyles } from '@mui/styles';

import { Enum, States } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { StateCities } from '@vestahealthcare/common/models';
import { City } from '@vestahealthcare/common/models/StateCities';

import { Button, IconButton, Select, TextInput } from 'styleguide-v2';

const useStyles = makeStyles({
  firstIcon: {
    '&&': {
      margin: '2.25rem auto auto',
    },
  },
  icon: {
    alignSelf: 'baseline',
    margin: '0.5rem auto auto',
  },
});

export type Props = {
  className?: string;
  disabled?: boolean;
  onChange: (stateCities: StateCities[]) => void;
  value?: StateCities[];
  readOnly?: boolean;
  required?: boolean;
  submitted?: boolean;
};

export const EditStateCities = ({
  className,
  disabled,
  onChange,
  value: paramStateCities,
  readOnly,
  required,
  submitted,
}: Props) => {
  const styles = useStyles();

  const stateCities: Partial<StateCities>[] = useMemo(
    () =>
      paramStateCities?.length
        ? paramStateCities?.map((item) => ({
            ...item,
            cities: [...item.cities],
          }))
        : [new StateCities({ cities: [] })],
    [paramStateCities],
  );

  const handleChange = (states?: Partial<StateCities>[]) =>
    onChange((states || []) as StateCities[]);

  return (
    <div className={classNames(className, 'grid-wrapper fit')}>
      {[...(stateCities || [])].map((stateCity, idx) => (
        <Fragment key={`edit-state-city-${idx}`}>
          <Select
            className="grid-span-10"
            disabled={disabled}
            disableClearable={stateCities?.length > 1}
            error={submitted && !stateCity.state}
            items={Enum.toSelectable(States.asArray)}
            label={
              idx === 0
                ? translate('components.editStateCities.coverageArea')
                : undefined
            }
            onChange={(val?: Selectable) => {
              if (val) {
                stateCity.state = States.byKey[val.value];
                handleChange(stateCities);
              } else {
                stateCity.state = undefined;
                stateCity.cities = [];
                handleChange([new StateCities({ cities: [] })]);
              }
            }}
            placeholder={translate('components.editStateCities.state')}
            readOnly={readOnly}
            value={
              stateCity.state
                ? Enum.toSelectable([stateCity.state])[0]
                : undefined
            }
            required={required}
          />
          <IconButton
            className={classNames(
              idx === 0 && styles.firstIcon,
              styles.icon,
              'grid-span-1',
            )}
            disabled={disabled || !stateCity.state}
            size="small"
            onClick={() => {
              stateCity.cities?.push(new City({}));
              handleChange(stateCities);
            }}
            tooltip={translate('components.editStateCities.addCity')}
          >
            <AddIcon fontSize="large" />
          </IconButton>
          <IconButton
            className={classNames(
              idx === 0 && styles.firstIcon,
              styles.icon,
              'grid-span-1',
            )}
            disabled={(stateCities && stateCities.length < 2) || disabled}
            size="small"
            onClick={() => {
              stateCities?.splice(idx, 1);
              handleChange(stateCities);
            }}
            tooltip={translate('components.editStateCities.deleteState')}
          >
            <DeleteOutlineIcon fontSize="large" />
          </IconButton>

          {stateCity.cities?.map(({ city, zip }, cityIdx) => (
            <Fragment key={`edit-state-${idx}-city${cityIdx}`}>
              <TextInput
                className="grid-span-6"
                disabled={disabled}
                placeholder={translate('components.editStateCities.city')}
                onChange={(val) => {
                  if (stateCity.cities) {
                    stateCity.cities[cityIdx].city = val || '';
                    handleChange(stateCities);
                  }
                }}
                readOnly={readOnly}
                value={city}
              />
              <TextInput
                className="grid-span-5"
                disabled={disabled}
                placeholder={translate('components.editStateCities.zipCode')}
                onChange={(val) => {
                  if (stateCity.cities) {
                    stateCity.cities[cityIdx].zip = val || '';
                    handleChange(stateCities);
                  }
                }}
                readOnly={readOnly}
                value={zip}
              />
              <IconButton
                className={classNames(styles.icon, 'grid-span-1')}
                disabled={disabled}
                size="small"
                onClick={() => {
                  stateCity.cities?.splice(cityIdx, 1);
                  handleChange(stateCities);
                }}
                tooltip={translate('components.editStateCities.deleteCity')}
              >
                <DeleteOutlineIcon fontSize="large" />
              </IconButton>
            </Fragment>
          ))}
        </Fragment>
      ))}
      {!readOnly && !disabled && (
        <Button
          className="grid-span-12"
          color="tertiary"
          icon={<AddIcon />}
          onClick={() => {
            handleChange([
              ...(stateCities || []),
              new StateCities({ cities: [] }),
            ]);
          }}
        >
          {translate('components.editStateCities.addAnotherState')}
        </Button>
      )}
    </div>
  );
};
