import React from 'react';

import EditIcon from '@mui/icons-material/Edit';

import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Card, CardContent, CardHeader, IconButton } from 'styleguide-v2';

type Props = {
  allowEdit?: boolean;
  className?: string;
  member?: Patient;
  onEdit: () => void;
};

export const VestaTeamCard = ({
  allowEdit,
  className,
  member,
  onEdit,
}: Props) => {
  return (
    <Card className={className} sx={{ height: 'max-content' }}>
      <CardHeader
        action={
          allowEdit && (
            <IconButton
              color="default"
              data-cy="member-profile-network-edit"
              size="small"
              onClick={() => onEdit()}
            >
              <EditIcon color="primary" />
            </IconButton>
          )
        }
        title={translate('memberProfile.network.vestaTeam')}
      />
      <CardContent className="grid-wrapper fit">
        <span className="grid-span-6 bold">
          {translate('memberProfile.network.podManager')}:
        </span>
        <span className="grid-span-6">
          {member?.podManager?.fullName || EMPTY}
        </span>
        <span className="grid-span-6 bold">
          {translate('memberProfile.network.npOwner')}:
        </span>
        <span className="grid-span-6">
          {member?.npOwner?.fullName || EMPTY}
        </span>
        <span className="grid-span-6 bold">
          {translate('memberProfile.network.rnOwner')}:
        </span>
        <span className="grid-span-6">{member?.owner?.fullName || EMPTY}</span>
        <span className="grid-span-6 bold">
          {translate('memberProfile.network.careCoordinators')}:
        </span>
        <span className="grid-span-6">
          {member?.careCoordinators
            ?.map(({ fullName }) => fullName)
            ?.join(', ') || EMPTY}
        </span>
        <span className="grid-span-6 bold">
          {translate('memberProfile.network.engagementOwners')}:
        </span>
        <span className="grid-span-6">
          {member?.engagementOwners
            ?.map(({ fullName }) => fullName)
            ?.join(', ') || EMPTY}
        </span>
        <span className="grid-span-6 bold">
          {translate('memberProfile.network.healthCoaches')}:
        </span>
        <span className="grid-span-6">
          {member?.healthCoaches?.map(({ fullName }) => fullName)?.join(', ') ||
            EMPTY}
        </span>
      </CardContent>
    </Card>
  );
};
