import classNames from 'classnames';
import React, { Fragment, useMemo } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { makeStyles } from '@mui/styles';

import { Enum, Language } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';

import { Button, IconButton, RadioGroup, Select } from 'styleguide-v2';

const useStyles = makeStyles({
  firstRadio: {
    height: '7rem',
  },
  radio: {
    '&& .MuiFormGroup-root': {
      margin: '1.5rem 0 0 1.5rem',
    },
  },
  firstIcon: {
    '&&': {
      margin: '2.25rem auto auto',
    },
  },
  icon: {
    margin: '0.5rem auto auto',
  },
});

export type LanguagePreference = {
  language?: Language;
  preferred: boolean;
};

export type Props = {
  className?: string;
  disabled?: boolean;
  disabledPreferred?: boolean;
  onChange: (phones: Partial<LanguagePreference>[]) => void;
  languages?: Partial<LanguagePreference>[];
  readOnly?: boolean;
  submitted?: boolean;
};

export const EditLanguages = ({
  className,
  disabled,
  disabledPreferred,
  onChange,
  languages: paramLanguages,
  readOnly,
  submitted,
}: Props) => {
  const styles = useStyles();
  const languages: Partial<LanguagePreference>[] = useMemo(
    () =>
      paramLanguages?.length
        ? paramLanguages?.map(({ language, preferred }) => ({
            language,
            preferred: paramLanguages?.length === 1 || preferred,
          }))
        : [{ preferred: true } as Partial<LanguagePreference>],
    [paramLanguages],
  );

  const getLanguages = (lang?: Partial<LanguagePreference>) => {
    return Language.toSelectable(Language.asArray)
      .filter(({ disabled }) => !disabled)
      .filter(
        ({ value }) =>
          value === lang?.language?.value ||
          !languages.find(({ language }) => language?.value === value),
      );
  };

  const handleChange = (languages?: Partial<LanguagePreference>[]) => {
    onChange(languages || []);
  };

  return (
    <div className={classNames(className, 'grid-wrapper fit')}>
      {languages.map((language, idx) => (
        <Fragment key={`caregiver-phone-${language?.language?.value || idx}`}>
          <RadioGroup
            className={classNames(
              idx === 0 && styles.firstRadio,
              styles.radio,
              'grid-span-1',
            )}
            disabled={readOnly || disabled || disabledPreferred}
            items={[{ value: idx + 1, label: '' }]}
            title={idx === 0 ? translate('global.preferred') : undefined}
            onChange={() => {
              languages?.forEach((item, phonesIdx) => {
                item.preferred = idx === phonesIdx;
              });
              handleChange(languages);
            }}
            value={language.preferred ? idx + 1 : -1}
          />
          <Select
            className="grid-span-10"
            disabled={disabled || (disabledPreferred && language.preferred)}
            error={
              submitted &&
              !language.language &&
              translate('global.missingRequiredFieldMin')
            }
            items={getLanguages(language)}
            grouped={({ preferred }) =>
              preferred
                ? translate('global.preferred')
                : translate('global.other')
            }
            label={idx === 0 ? translate('global.language') : undefined}
            onChange={(val: Selectable) => {
              if (val) {
                language.language = Language.byKey[val.value];
              } else {
                delete language.language;
              }
              handleChange(languages);
            }}
            readOnly={readOnly}
            value={
              language.language
                ? Enum.toSelectable([language.language])[0]
                : undefined
            }
          />
          {!readOnly && (
            <IconButton
              className={classNames(
                idx === 0 && styles.firstIcon,
                styles.icon,
                'grid-span-1',
              )}
              disabled={
                (languages && languages.length < 2) ||
                disabled ||
                (disabledPreferred && language.preferred)
              }
              size="small"
              onClick={() => {
                languages?.splice(idx, 1);
                handleChange(languages);
              }}
            >
              <DeleteOutlineIcon fontSize="large" />
            </IconButton>
          )}
        </Fragment>
      ))}
      {!readOnly && !disabled && (
        <Button
          className="grid-span-12"
          color="tertiary"
          icon={<AddIcon />}
          onClick={() =>
            handleChange([...(languages || []), {} as LanguagePreference])
          }
        >
          {translate('global.addAnotherLanguage')}
        </Button>
      )}
    </div>
  );
};
