import moment from 'moment';
import React, { useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';

import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  EventModel,
  Patient,
} from '@vestahealthcare/common/models';
import TaskDefinition from '@vestahealthcare/common/models/TaskDefinition';

import {
  Button,
  CollapsableSidebar,
  DateTimePicker,
  Select,
  SelectAssignee,
  SelectMemberOption,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CacheServices } from 'dash/src/services';
import {
  PatientSearchParams,
  searchPatients,
} from 'dash/src/services/PatientServices';
import Session from 'dash/src/services/SessionServices';
import { createTask } from 'dash/src/services/TaskServices';

import { getDueDate } from './utils';

const SEARCH_LIMIT = 25;

interface Props {
  event?: EventModel;
  member?: Patient;
  onClose: () => void;
  onSubmit:
    | (() => Promise<void>)
    | ((memberId?: number, taskId?: number) => void);
  open: boolean;
}

export const AddTaskModal = ({
  event,
  member: currentMember,
  onClose,
  onSubmit,
  open,
}: Props) => {
  const [taskDefinitionId, setTaskDefinitionId] = useState<TaskDefinition>();
  const [employeeGroupId, setEmployeeGroupId] = useState<EmployeeGroup>();
  const [member, setMember] = useState<Patient>();
  const [assigneeId, setAssigneeId] = useState<Employee>();
  const [date, setDate] = useState<Date>();
  const [time, setTime] = useState<Date>();
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [memberFilter, setMemberFilter] = useState<string>('');
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timer>();

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [members, setMembers] = useState<Patient[]>([]);
  const [employeeGroups, setEmployeeGroups] = useState<EmployeeGroup[]>([]);
  const [taskDefinitions, setTaskDefinitions] = useState<TaskDefinition[]>([]);

  const getInitialData = async () => {
    const [t, e, g] = await Promise.all([
      CacheServices.getTaskDefinitions(),
      CacheServices.getEmployees(),
      CacheServices.getEmployeeGroupsAssignee(),
    ]);
    setTaskDefinitions(t);
    setEmployees(e);
    setEmployeeGroups(g);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const onSave = async () => {
    if (!taskDefinitionId || !employeeGroupId) {
      setShowError(true);
      return;
    }

    setLoading(true);
    try {
      const id = member?.id || ((currentMember?.id || 0) as number); // (!!member === !!memberId) should ensure this always has a value, but we cast to avoid wrangling with TS
      const newTask = await createTask({
        taskDefinitionId: taskDefinitionId?.id,
        employeeGroupId: employeeGroupId?.id,
        dueDate: getDueDate(
          date ? moment(date?.getTime()) : undefined,
          time ? moment(time?.getTime()) : undefined,
        ),
        assigneeId: assigneeId?.id,
        memberId: Number(currentMember?.id) || member?.id,
        eventId: event?.id,
      });
      onSubmit(id, newTask.id);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
    onClose();
  };

  const onChangeEmployeeGroup = (employeeGroup?: EmployeeGroup) => {
    if (employeeGroup) {
      setEmployeeGroupId(employeeGroup);

      if (employeeGroup.isNPOwner() && currentMember?.npOwner) {
        setAssigneeId(currentMember.npOwner);
      } else if (employeeGroup.isRNOwner() && currentMember?.owner) {
        setAssigneeId(currentMember.owner);
      } else {
        setAssigneeId(undefined);
      }
    }
  };

  const getMembers = async () => {
    setLoadingMembers(true);
    const filters: PatientSearchParams = {
      limit: SEARCH_LIMIT,
      sort: 'firstName asc',
    };
    if (isNaN(Number(memberFilter))) {
      filters.fullName = memberFilter;
    } else {
      filters.id = [Number(memberFilter)];
    }
    try {
      const { items } = await searchPatients(filters);
      setMembers(items);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoadingMembers(false);
  };

  useEffect(() => {
    if (memberFilter.length > 1 && memberFilter !== member?.fullName) {
      setMembers([]);
      getMembers();
    }
  }, [memberFilter, member]);

  useEffect(() => {
    if (open) {
      setAssigneeId(undefined);
      setTaskDefinitionId(undefined);
      setEmployeeGroupId(undefined);
      setDate(undefined);
      setTime(undefined);
    }
  }, [open]);

  return (
    <CollapsableSidebar
      title={<h2>{translate('tasks.addTask')}</h2>}
      onClose={onClose}
      open={open}
      size={450}
    >
      <CollapsableSidebar.Body>
        <div className="grid-wrapper fit">
          <Select
            className="grid-span-12"
            error={!taskDefinitionId && showError}
            getItemLabel={({ name }: TaskDefinition) => name}
            items={taskDefinitions}
            label={translate('tasks.type')}
            onChange={(taskDefinition?: TaskDefinition) => {
              setTaskDefinitionId(taskDefinition);
              setEmployeeGroupId(
                employeeGroups.find(
                  ({ id }) => id === taskDefinition?.employeeGroupId,
                ),
              );
            }}
            required
            value={taskDefinitionId}
          />

          {!currentMember && (
            <Select
              className="grid-span-12"
              getItemLabel={({ fullName }) => fullName}
              icon={<SearchIcon />}
              items={members}
              label={translate('common.member')}
              loading={loadingMembers}
              noOptionsText={
                memberFilter.length < 2
                  ? translate('chat.typeToSearch')
                  : translate('chat.noMembersFound')
              }
              onChange={(value: Patient) => setMember(value)}
              onInputChange={(value) => {
                const timer = setTimeout(() => {
                  setMemberFilter(value);
                }, 300);
                if (searchTimer) {
                  clearTimeout(searchTimer);
                }
                setSearchTimer(timer);
              }}
              placeholder={translate('tasks.searchMember')}
              renderOption={(member: Patient) => (
                <SelectMemberOption member={member} />
              )}
              value={member}
            />
          )}

          <Select
            className="grid-span-12"
            error={!employeeGroupId && showError}
            getItemLabel={({ name }: EmployeeGroup) => name}
            items={employeeGroups}
            label={translate('tasks.group')}
            onChange={onChangeEmployeeGroup}
            required
            value={employeeGroupId}
          />

          <SelectAssignee
            allowClear
            assignee={assigneeId}
            className="grid-span-12"
            currentUser={Session.actingUser}
            items={employees}
            label={translate('tasks.assignedTo')}
            member={member}
            onChange={(val?: Employee | EmployeeGroup) =>
              setAssigneeId(val as Employee)
            }
          />
          <DateTimePicker
            className="grid-span-6"
            label={translate('tasks.dueDate')}
            minDate={Date.now()}
            onChange={setDate}
            value={date}
          />
          <DateTimePicker
            className="grid-span-6"
            label={translate('tasks.dueTime')}
            minTime={
              date && moment(date.getTime()).isSame(moment(), 'day')
                ? Date.now()
                : undefined
            }
            onChange={setTime}
            type="time"
            value={time}
          />
        </div>
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button color="tertiary" data-cy="add-task-close" onClick={onClose}>
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="add-task-submit"
          disabled={!taskDefinitionId || !employeeGroupId}
          loading={loading}
          onClick={onSave}
        >
          {translate('tasks.addTask')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default AddTaskModal;
