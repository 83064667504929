import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { DocumentType } from '@vestahealthcare/common/enums';
import { translate, translateToArray } from '@vestahealthcare/common/i18n';
import { MemberDocument, Patient } from '@vestahealthcare/common/models';
import {
  DATE_FORMAT,
  DATE_FORMAT_SHORT,
} from '@vestahealthcare/common/utils/constants';

import { Button, EmptyState, Modal, PDFPreview, TextInput } from 'styleguide';

import {
  fetchAllMemberDocuments,
  fetchDocumentBlob,
  generateMemberDocument,
  previewMemberDocument,
} from 'dash/src/services/PHIPServices';
import Session from 'dash/src/services/SessionServices';

interface Props {
  member?: Patient;
}

const PlanDocumentsSection = ({ member }: Props) => {
  const [documents, setDocuments] = useState<MemberDocument[]>();
  const [additionalText, setAdditionalText] = useState<string>();
  const [preview, setPreview] = useState<string>();
  const [showModal, setShowModal] = useState(false);

  const fetchDocuments = async () => {
    if (member) {
      const docs = await fetchAllMemberDocuments(member.id);
      setDocuments(docs.filter((doc) => doc.type === DocumentType.CARE_PLAN));
    }
  };

  const onCloseModal = () => {
    setAdditionalText(undefined);
    setPreview(undefined);
    setShowModal(false);
  };

  useEffect(() => {
    fetchDocuments();
  }, [member]);

  if (!member) {
    return null;
  }

  const { actingUser } = Session;

  return (
    <article>
      <header>
        <div className="h7">{translate('planDocuments.header')}</div>
      </header>

      {member.isEditable() && actingUser.canEditPHIP && (
        <div className="create-plan">
          <Button
            data-cy="create-plan-button-modal"
            color="primary"
            onClick={() => setShowModal(true)}
          >
            {translate('planDocuments.createPlan')}
          </Button>
        </div>
      )}
      <Modal show={showModal} onHide={onCloseModal}>
        <Modal.Header
          modalTitle={translate('planDocuments.modalHeader')}
          closeButton
        />
        <Modal.Body>
          {translate('planDocuments.modalBody')}
          <ul style={{ marginTop: 10, marginBottom: 10 }}>
            {translateToArray('planDocuments.modalDetails').map((d) => (
              <li key={d}>
                &bull;&nbsp;
                {d}
              </li>
            ))}
          </ul>
          <TextInput
            label={translate('planDocuments.summaryNote')}
            multiline={3}
            onBlur={setAdditionalText}
            value={additionalText}
          />
        </Modal.Body>
        <Modal.Footer
          leftSide={
            <Button
              color="tertiary"
              onClick={onCloseModal}
              data-cy="button-modal-cancel-btn"
            >
              {translate('global.cancel')}
            </Button>
          }
          rightSide={
            <div>
              <Button
                color="secondary"
                onClick={async () => {
                  const docPreview = await previewMemberDocument(member.id, {
                    type: DocumentType.CARE_PLAN,
                    additionalText,
                  });
                  setPreview(docPreview);
                }}
              >
                {translate('planDocuments.preview')}
              </Button>
              <Button
                className="submit-btn"
                data-cy="button-modal-submit-btn"
                color="primary"
                onClick={async () => {
                  await generateMemberDocument(member.id, {
                    type: DocumentType.CARE_PLAN,
                    additionalText,
                  });
                  await fetchDocuments();
                  onCloseModal();
                }}
              >
                {translate('planDocuments.signCreate')}
              </Button>
            </div>
          }
        />
      </Modal>

      <ul>
        {documents?.map((d) => (
          <li key={d.id}>
            <Button
              data-cy="care-plan-pdf"
              color="tertiary"
              onClick={async () => {
                const doc = await fetchDocumentBlob(member.id, d.id);
                const link = document.createElement('a');
                link.href = URL.createObjectURL(doc);
                link.download = `plan-doc_${member.id}_${moment
                  .unix(d.createdAt)
                  .format(DATE_FORMAT)}`;
                link.click();
              }}
            >
              {translate('planDocuments.carePlanBy', {
                employee: d.createdBy.fullName,
                date: moment.unix(d.createdAt).format(DATE_FORMAT_SHORT),
              })}
            </Button>
          </li>
        ))}
        {!documents?.length && (
          <EmptyState>
            <p>No plan documents added.</p>
          </EmptyState>
        )}
      </ul>

      {preview && (
        <PDFPreview
          file={preview}
          overlayOnly
          onCloseOverlay={() => setPreview(undefined)}
        />
      )}
    </article>
  );
};

export default PlanDocumentsSection;
