import { Employee } from '.';
import { Selectable } from '../enums/Enum';
import { TaskDefinitionStatus } from '../enums/TaskDefinitionStatus';
import moment from '../moment';
import { DATE_TIME_FORMAT } from '../utils/constants';
import { make, makeArray } from './Model';
import { TaskCustomField } from './TaskCustomField';
import { TaskDefinitionOutcome } from './TaskDefinitionOutcome';

export class TaskDefinition {
  id = 0;

  name = '';

  employeeGroupId?: number;

  createdAt: number = 0;

  version: number = 0;

  createdBy: Employee;

  reference = '';

  status: TaskDefinitionStatus;

  customFields?: TaskCustomField[];

  subtasks?: TaskDefinition[];

  outcomes?: TaskDefinitionOutcome[];

  taskPrecedentIds?: number[];

  new = false;

  updatedAt? = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdBy = make(obj.createdBy, Employee);
    this.outcomes = makeArray(obj.outcomes, TaskDefinition);
    this.customFields = makeArray(obj.customFields, TaskCustomField);
    this.subtasks = makeArray(obj.subtasks, TaskDefinition);
    this.status = TaskDefinitionStatus.byKey[obj.status];
  }

  displayTime = () => {
    if (this.createdAt) {
      return moment.unix(this.createdAt).format(DATE_TIME_FORMAT);
    }
    return undefined;
  };

  static toSelectable = (items: TaskDefinition[]): Selectable[] =>
    items.map(({ id, name }) => ({
      label: name,
      value: id,
    }));

  static RPM_NEW_ORDER_REQUEST = 1;

  static RPM_TRAINING_REQUEST = 2;

  static RPM_RETURN_DEVICE = 3;

  static RPM_TECH_ISSUE = 4;
}

export default TaskDefinition;
