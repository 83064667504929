import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';

import {
  Choice,
  EmployeeRole,
  Enum,
  States,
} from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  PaginationType,
} from '@vestahealthcare/common/models';
import { DashPermission } from '@vestahealthcare/common/models/DashPermission';

import {
  Button,
  FilterButton,
  IconButton,
  Panel,
  Select,
  TextInput,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CacheServices } from 'dash/src/services';
import {
  EmployeeParams,
  createEmployee,
  fetchPaginatedEmployees,
  updateEmployee,
} from 'dash/src/services/EmployeeServices';
import Session from 'dash/src/services/SessionServices';

import { EmployeeEditModal } from './EmployeeEdit';
import { EmployeesTable } from './EmployeesTable';

export const EmployeesDashboard = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<EmployeeParams>({});
  const [loading, setLoading] = useState<boolean>(false);

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [groups, setGroups] = useState<EmployeeGroup[]>([]);
  const [permissions, setPermissions] = useState<DashPermission[]>([]);
  const [nlcStates, setNLCStates] = useState<States[]>([]);

  const [showEditEmployee, setShowEditEmployee] = useState<boolean>(false);
  const [modalData, setModalData] = useState<Employee>();

  const getInitialData = async () => {
    const groups = await CacheServices.getEmployeeGroupsAssignee();
    setGroups(groups);
    const permissions = await CacheServices.getDashPermissions();
    setPermissions(permissions);
    const nlcStates = await CacheServices.getEmployeeNLCStates();
    setNLCStates(nlcStates);
  };

  const getEmployees = async () => {
    setLoading(true);
    try {
      const { items, pagination } = await fetchPaginatedEmployees({
        ...filters,
        limit: pageSize,
        offset: page * pageSize,
        sort: 'fullName asc',
      });
      if (page === 0) {
        setEmployees(items);
      } else {
        setEmployees([...employees, ...items]);
      }
      setPagination(pagination);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    getEmployees();
  }, [filters, page, pageSize]);

  const addEmployee = () => {
    setModalData(new Employee({}));
    setShowEditEmployee(true);
  };

  const isAdmin = Session.actingUser.role === EmployeeRole.ADMIN;

  return (
    <Panel>
      <Panel.Heading title={translate('employees.title')}>
        <FilterButton
          className="filters"
          data-cy="caregivers-filters"
          filters={filters}
          onClick={() => setOpen(!isOpen)}
        />
        {isAdmin && (
          <Panel.Actions>
            <IconButton
              onClick={addEmployee}
              tooltip={translate('employees.add')}
            >
              <AddIcon fontSize="large" />
            </IconButton>
          </Panel.Actions>
        )}
        <Panel.Collapse open={isOpen}>
          <div className="grid-wrapper fit">
            <TextInput
              className="grid-span-3"
              data-cy="employees-filters-name"
              onChange={(val) => {
                setPage(0);
                setFilters({ ...filters, searchName: val || undefined });
              }}
              placeholder={translate('employees.filters.name')}
              value={filters.searchName}
            />
            <TextInput
              className="grid-span-3"
              data-cy="employees-filters-mail"
              onChange={(val) => {
                setPage(0);
                setFilters({ ...filters, email: val || undefined });
              }}
              placeholder={translate('employees.filters.mail')}
              value={filters.email}
            />
            <Select
              className="grid-span-3"
              data-cy="employees-filters-group"
              getItemLabel={({ name }: EmployeeGroup) => name}
              items={groups}
              multiple
              onChange={(employeeGroupId: EmployeeGroup[]) => {
                setPage(0);
                setFilters({
                  ...filters,
                  employeeGroupId: employeeGroupId?.length
                    ? employeeGroupId
                    : undefined,
                });
              }}
              placeholder={translate('employees.filters.groups')}
              value={filters.employeeGroupId}
            />
            <Select
              className="grid-span-3"
              data-cy="employees-filters-role"
              items={Enum.toSelectable(
                EmployeeRole.asArray,
              ).sort(({ label: a }, { label: b }) =>
                (a as string).localeCompare(b as string),
              )}
              multiple
              onChange={(roles: Selectable[]) => {
                setPage(0);
                setFilters({
                  ...filters,
                  role: roles?.length
                    ? roles?.map(({ value }) => EmployeeRole.byKey[value])
                    : undefined,
                });
              }}
              placeholder={translate('employees.filters.role')}
              value={Enum.toSelectable(filters.role || [])}
            />
            <Select
              className="grid-span-3"
              data-cy="employees-filters-permission"
              getItemLabel={({ name }: DashPermission) =>
                `${name.charAt(0).toLocaleUpperCase()}${name
                  .substring(1)
                  .toLocaleLowerCase()}`.replace('_', ' ')
              }
              items={permissions}
              multiple
              onChange={(permission: DashPermission[]) => {
                setPage(0);
                setFilters({
                  ...filters,
                  permission: permission?.length ? permission : undefined,
                });
              }}
              placeholder={translate('employees.filters.permission')}
              value={filters.permission}
            />
            <Select
              className="grid-span-3"
              data-cy="employees-filters-active"
              items={Choice.getChoices()}
              onChange={(enabled: Selectable) => {
                setPage(0);
                setFilters({
                  ...filters,
                  enabled:
                    enabled?.value !== undefined
                      ? Boolean(enabled?.value)
                      : undefined,
                });
              }}
              placeholder={translate('employees.filters.active')}
              value={
                filters.enabled === undefined
                  ? undefined
                  : Choice.getChoices()[filters.enabled ? 0 : 1]
              }
            />
            <Select
              className="grid-span-3"
              data-cy="employees-filters-phi-access"
              items={Choice.getChoices()}
              onChange={(phi: Selectable) => {
                setPage(0);
                setFilters({
                  ...filters,
                  phiAccess:
                    phi?.value !== undefined ? Boolean(phi?.value) : undefined,
                });
              }}
              placeholder={translate('employees.filters.phiAccess')}
              value={
                filters.phiAccess === undefined
                  ? undefined
                  : Choice.getChoices()[filters.phiAccess ? 0 : 1]
              }
            />
            <Select
              className="grid-span-3"
              data-cy="employees-filters-states"
              items={States.toSelectable(States.asArray)}
              multiple
              onChange={(state: Selectable[]) => {
                setPage(0);
                setFilters({
                  ...filters,
                  state: state?.length
                    ? state?.map(({ value }) => States.byKey[value])
                    : undefined,
                });
              }}
              placeholder={translate('employees.filters.state')}
              value={States.toSelectable(filters.state || [])}
            />
            <TextInput
              className="grid-span-3"
              data-cy="employees-filters-id"
              onChange={(val) => {
                setPage(0);
                setFilters({
                  ...filters,
                  employeeId: val ? Number(val) : undefined,
                });
              }}
              placeholder={translate('employees.filters.id')}
              value={String(filters.employeeId || '')}
            />
            <div className="grid-span-6" />
            <Button
              className="grid-span-3"
              data-cy="employees-clear-filters"
              color="secondary"
              type="outlined"
              onClick={() => {
                setPage(0);
                setFilters({});
              }}
            >
              {translate('global.clearFilters')}
            </Button>
          </div>
        </Panel.Collapse>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <EmployeesTable
          employees={employees}
          nlcStates={nlcStates}
          onChangePage={setPage}
          onChangeRowsPerPage={(size) => {
            setPage(0);
            setPageSize(size);
          }}
          onEdit={(employee) => {
            setModalData(employee);
            setShowEditEmployee(true);
          }}
          pagination={pagination}
        />
        <EmployeeEditModal
          employee={modalData}
          open={showEditEmployee}
          onClose={() => setShowEditEmployee(false)}
          onCreate={async (params) => {
            await createEmployee(params);
            CacheServices.invalidateEmployees();
            if (page !== 0) {
              setPage(0);
            } else {
              await getEmployees();
            }
          }}
          onEdit={async (id, params) => {
            await updateEmployee(id, params);
            CacheServices.invalidateEmployees();
            if (Session.actingUser.id === id) {
              await Session.fetchSessionInformation();
            }
            if (page !== 0) {
              setPage(0);
            } else {
              await getEmployees();
            }
          }}
        />
      </Panel.Body>
    </Panel>
  );
};

export default EmployeesDashboard;
