import classNames from 'classnames';
import React from 'react';

import { Box } from '@mui/material';

import { Spinner } from 'styleguide';

interface Props extends React.HTMLProps<HTMLDivElement> {
  loading?: boolean;
  title?: string;
}

const PanelComponent = (props: Props) => {
  const { children, className, ...other } = props;

  const classes = classNames('panel', 'panel-default', className);

  return (
    <div className={classes} {...other}>
      {children}
    </div>
  );
};

export const Heading = (props: Props) => {
  const { children, className, title, ...other } = props;

  const classes = classNames('panel-heading', className);

  return (
    <div className={classes} {...other}>
      {title ? <h2>{title}</h2> : null}
      {children}
    </div>
  );
};

export const Body = (props: Props) => {
  const { children, className, loading, ...other } = props;

  const classes = classNames('panel-body', className);

  return (
    <div className={classes} {...other}>
      {children}
      {loading && <Spinner width={40} overlay />}
    </div>
  );
};

export const Footer = (props: Props) => {
  const { children, className, ...other } = props;

  const classes = classNames('panel-footer', className);

  return (
    <footer className={classes} {...other}>
      {children}
    </footer>
  );
};

export const Panel = Object.assign(PanelComponent, {
  Heading,
  Body,
  Footer,
});

export default Panel;
