import { CareTrack, Patient } from '@vestahealthcare/common/models';

import { ReduxStoreType } from '../store';
import { createStructuredSelector } from 'reselect';

// todo: keep a detailedAlerts store slice along with a minimal alerts slice
// right now we're mixing fully-fetched alerts alongside minimally-fetched
// alerts in the same list which could potentially cause issues
export const STORE_VALUES = {
  patient: 'patient',
  careTracks: 'careTracks',
};

const selectors = {
  careTracks: (state: ReduxStoreType): CareTrack[] =>
    state.memberInfoSlice.careTracks,
  patient: (state: ReduxStoreType): Patient | undefined =>
    state.memberInfoSlice.patient,
};

// Given an array of store attributes, return each selector for
// the given attribute.
export const getSelector = (storeValues: string[]) => {
  const selectorObj: any = {};
  storeValues.forEach((valueName) => {
    const selector = selectors[valueName as keyof typeof selectors];
    if (selector) {
      selectorObj[valueName] = selector;
    } else {
      throw new Error(`Invalid store property ${valueName}`);
    }
  });

  return createStructuredSelector(selectorObj);
};
