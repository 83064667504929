import {
  BLOOD_GLUCOSE,
  BLOOD_OXYGEN,
  BLOOD_PRESSURE,
  FAHRENHEIT,
  HEART_RATE,
  POUNDS,
} from '../utils/constants';
import { Enum } from './Enum';

export class BiometricsType extends Enum {
  translationKey = 'biometricsType';

  static asArray: BiometricsType[] = [];

  static byKey: { [name: string]: BiometricsType } = {};

  static readonly BP_DIASTOLIC = new BiometricsType('BP_DIASTOLIC');

  static readonly BP_SYSTOLIC = new BiometricsType('BP_SYSTOLIC');

  static readonly BLOOD_SUGAR = new BiometricsType('BLOOD_SUGAR');

  static readonly OXYGEN = new BiometricsType('OXYGEN');

  static readonly PULSE = new BiometricsType('PULSE');

  static readonly TEMPERATURE = new BiometricsType('TEMPERATURE');

  static readonly WEIGHT = new BiometricsType('WEIGHT');

  constructor(public readonly value: string) {
    super();
    BiometricsType.asArray.push(this);
    BiometricsType.byKey[value] = this;
  }

  static getUnit(type: BiometricsType) {
    if (
      type === BiometricsType.BP_DIASTOLIC ||
      type === BiometricsType.BP_SYSTOLIC
    ) {
      return BLOOD_PRESSURE;
    }
    if (type === BiometricsType.BLOOD_SUGAR) {
      return BLOOD_GLUCOSE;
    }
    if (type === BiometricsType.OXYGEN) {
      return BLOOD_OXYGEN;
    }
    if (type === BiometricsType.PULSE) {
      return HEART_RATE;
    }
    if (type === BiometricsType.TEMPERATURE) {
      return FAHRENHEIT;
    }
    if (type === BiometricsType.WEIGHT) {
      return POUNDS;
    }

    return '';
  }
}

export default BiometricsType;
