import classNames from 'classnames';
import { Moment } from 'moment';
import React from 'react';

import LoadingIcon from '@mui/icons-material/Autorenew';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import { IconButton } from '../IconButton';

const useStyles = makeStyles({
  updatedAt: {
    alignItems: 'center',
    display: 'flex',
    gap: '1rem',
    marginRight: '2rem',
    '& > div.text': {
      alignSelf: 'center',
      display: 'flex',
      flexFlow: 'column',
      '& > .text': {
        fontSize: '0.625em',
      },
      '& > .date': {
        fontSize: '0.75em',
      },
    },
  },
  updatedAtButton: {
    '&& > button': {
      cursor: 'default',
      height: '3.2rem',
      minWidth: '3.2rem',
      width: '3.2rem',
    },

    '&&.small > button': {
      cursor: 'default',
      height: '2.4rem',
      minWidth: '2.4rem',
      width: '2.4rem',
    },
  },
});

export type Props = {
  label?: string;
  lastUpdatedAt: Moment;
  tooltip?: string;
  size?: 'small' | 'medium';
};

export const LastUpdated = ({
  label,
  lastUpdatedAt,
  tooltip,
  size = 'medium',
}: Props) => {
  const styles = useStyles();
  return (
    <div className={styles.updatedAt}>
      <IconButton
        className={classNames(styles.updatedAtButton, size)}
        color="info"
        data-cy="member-last-updated"
        tooltip={tooltip}
      >
        <LoadingIcon fontSize={size === 'medium' ? 'large' : 'small'} />
      </IconButton>
      <div className="text">
        <span className="gray text">
          {label || translate('ccm.actions.lastUpdatedTitle')}:
        </span>
        <span className="bold date">{lastUpdatedAt.calendar()}</span>
      </div>
    </div>
  );
};
