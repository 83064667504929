import React from 'react';
import { Radio } from 'styleguide';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import classnames from 'classnames';
import { GridColumns } from '../../utils/types';

interface Props {
  autoFocus?: boolean;
  className?: string;
  columns?: GridColumns;
  'data-cy'?: string;
  disabled?: boolean;
  errorText?: string;
  label?: string;
  onChange: (value: string) => any;
  options: Selectable[];
  required?: boolean;
  showError?: boolean;
  value?: string;
}

const Radios = ({
  autoFocus,
  className,
  columns,
  'data-cy': dataCy,
  disabled,
  errorText,
  label,
  onChange,
  options,
  required,
  showError,
  value,
  ...props
}: Props) => {
  const classes = classnames(
    className,
    'radio-btns',
    columns ? `grid-span-${columns}` : undefined,
    {
      'has-error': showError,
    },
  );

  return (
    <div className={classes} data-cy={dataCy} {...props}>
      {label && (
        <label>
          {label}
          {required ? ' *' : ''}
        </label>
      )}
      {showError && errorText && (
        <p className="flex no-margin error">{errorText}</p>
      )}
      {options.map((option, i) => (
        <Radio
          autoFocus={autoFocus && options[0] === option}
          checked={option.value === value}
          data-cy={`${dataCy}-${i}`}
          disabled={disabled}
          showError={showError}
          key={option.value}
          label={option.label}
          onChange={() => onChange(option.value.toString())}
        />
      ))}
    </div>
  );
};

export default Radios;
