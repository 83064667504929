import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import {
  Button,
  CollapsableSidebar,
  Colors,
  Fonts,
  RadioGroup,
  SwitchGroup,
} from 'styleguide-v2';
import { SwitchItem } from 'styleguide-v2/src/components/Switch/SwitchGroup';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import {
  MemberSettings,
  MemberSettingsParam,
  RPMRemindersFrequencyType,
  getMemberSettings,
  updateMemberSettings,
} from 'dash/src/services/PatientServices';

const useStyles = makeStyles({
  subtitle: {
    color: Colors.textBlack,
    fontFamily: Fonts.fontFamily,
    fontSize: `calc(${Fonts.fontSize} * 1.5)`,
    fontWeight: 500,
    textTransform: 'none',
    letterSpacing: 0,
  },
  frequency: {
    marginTop: '1rem',
  },
  buttons: {
    marginTop: '4rem',
  },
});

type Props = {
  onClose: () => void;
  open: boolean;
  patientId: number;
};

export const BiometricSettings = ({ onClose, open, patientId }: Props) => {
  const styles = useStyles();
  const [loadingButton, setLoadingButton] = useState(false);
  const [settings, setSettings] = useState<MemberSettings>();
  const [reminderOptions, setReminderOptions] = useState<SwitchItem[]>([]);
  const [frequency, setFrequency] = useState<RPMRemindersFrequencyType>();
  const frequencyOptions = [
    { label: translate('biometrics.daily'), value: 'DAILY' },
    { label: translate('biometrics.weekly'), value: 'WEEKLY' },
  ];

  const updateFromBackend = (settings: MemberSettings) => {
    setReminderOptions([
      {
        label: translate('biometrics.reminderSMS'),
        checked: settings.rpmRemindersSMS,
      },
      {
        label: translate('biometrics.reminderApp'),
        checked: settings.rpmRemindersApp,
      },
    ]);
    setFrequency(settings.rpmRemindersFrequency || 'DAILY');
  };

  const fetchMemberSettings = useCallback(async () => {
    if (patientId) {
      const settings = await getMemberSettings(patientId);
      setSettings(settings);
      updateFromBackend(settings);
    }
  }, [patientId]);

  const updateSettings = async (params: Partial<MemberSettingsParam>) => {
    if (patientId) {
      const s = await updateMemberSettings(patientId, params);
      updateFromBackend(s);
    }
  };

  useEffect(() => {
    fetchMemberSettings();
  }, [patientId]);

  const onChangeReminder = async (items: SwitchItem[]) => {
    setReminderOptions(items);
  };

  const onChangeReminderFrequency = async (newFrequency: string | number) => {
    if (newFrequency === 'DAILY' || newFrequency === 'WEEKLY') {
      setFrequency(newFrequency);
    }
  };

  const onCancel = () => {
    if (settings) {
      updateFromBackend(settings);
    }
    onClose();
  };

  const onSave = async () => {
    setLoadingButton(true);
    try {
      await updateSettings({
        rpmRemindersSMS: reminderOptions[0].checked || false,
        rpmRemindersApp: reminderOptions[1].checked || false,
        rpmRemindersFrequency: frequency,
      });
      await fetchMemberSettings();
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoadingButton(false);
    onClose();
  };

  return (
    <CollapsableSidebar
      open={open}
      size={450}
      title={<h2>{translate('biometrics.settings')}</h2>}
      onClose={onCancel}
    >
      <SwitchGroup
        data-cy="biometrics-reminder"
        items={reminderOptions}
        onChange={onChangeReminder}
        title={[translate('biometrics.rpmReminders'), 'On/Off']}
      />
      <RadioGroup
        className={styles.frequency}
        data-cy="biometrics-frequency"
        items={frequencyOptions}
        title={translate('biometrics.frequency')}
        onChange={onChangeReminderFrequency}
        value={frequency || ''}
      />
      <div className={classnames('grid-wrapper', styles.buttons)}>
        <Button
          className="grid-span-6"
          data-cy="biometrics-settings-cancel"
          color="tertiary"
          onClick={() => onCancel()}
        >
          {translate('global.cancel')}
        </Button>
        <Button
          className="grid-span-6"
          color="secondary"
          data-cy="biometrics-settings-save"
          loading={loadingButton}
          onClick={() => onSave()}
        >
          {translate('global.save')}
        </Button>
      </div>
    </CollapsableSidebar>
  );
};
