import TaskDefinition from '@vestahealthcare/common/models/TaskDefinition';
import React, { ReactNode, useState } from 'react';
import { fetchTaskDefinition } from '../services/TaskServices';

const SESSION_KEY = 'cache-task-definition-map';
interface TaskDefinitionContextProps {
  definition: TaskDefinition;
  clearDefinition: (id: number) => void;
  fetchDefinition: (id: number) => Promise<TaskDefinition>;
  setDefinition: (definition: TaskDefinition) => void;
}

export const TaskDefinitionContext = React.createContext<
  TaskDefinitionContextProps
>({
  definition: new TaskDefinition({}),
  clearDefinition: (id: number) => {
    id;
  },
  fetchDefinition: () => Promise.resolve(new TaskDefinition({})),
  setDefinition: () => {},
});

export const TaskDefinitionProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [definition, setDefinition] = useState(new TaskDefinition({}));

  const getMapDefinition = () => {
    let map;
    try {
      map = JSON.parse(sessionStorage.getItem(SESSION_KEY) || '');
    } catch (e) {
      map = { items: {} };
    }
    return map;
  };

  const getItemMap = (id: number) => {
    const map = getMapDefinition() || {};
    const item = map.items[`${id}`];
    const outdatedTime = new Date().getTime() / 1000 - 24 * 3600;

    if (item?.updatedAt < outdatedTime) {
      clearItemMap(id);
      return null;
    }

    return item;
  };

  const saveItemMap = (item: TaskDefinition) => {
    const map = getMapDefinition() || {};
    item.updatedAt = new Date().getTime() / 1000;
    map.items[`${item.id}`] = item;
    sessionStorage.setItem(SESSION_KEY, JSON.stringify(map));
  };

  const clearItemMap = (id: number) => {
    const map = getMapDefinition() || {};
    delete map.items[`${id}`];
    sessionStorage.setItem(SESSION_KEY, JSON.stringify(map));
  };

  const value = {
    definition,
    clearDefinition: (id: number) => {
      setDefinition(new TaskDefinition({}));
      clearItemMap(id);
    },
    fetchDefinition: async (id: number) => {
      const sessionDefinition = getItemMap(id);
      let newDefinition = definition;

      if (sessionDefinition) {
        newDefinition = sessionDefinition;
      }

      if (id !== -1) {
        const serverDefinition = await fetchTaskDefinition(id);
        if (
          newDefinition.id !== id ||
          newDefinition.version < serverDefinition?.version
        ) {
          newDefinition = serverDefinition;
        }
      } else if (newDefinition.id !== id) {
        newDefinition = new TaskDefinition({ id });
      }

      setDefinition(newDefinition);
      return newDefinition;
    },
    setDefinition: (definition: TaskDefinition) => {
      setDefinition(definition);
      saveItemMap(definition);
    },
  };

  return (
    <TaskDefinitionContext.Provider value={value}>
      {children}
    </TaskDefinitionContext.Provider>
  );
};
