import { Enum } from './Enum';

export class EmployeeGroupType extends Enum {
  translationKey = 'employeeGroupType';

  static asArray: EmployeeGroupType[] = [];

  static byKey: { [name: string]: EmployeeGroupType } = {};

  static readonly ASSIGNEE = new EmployeeGroupType('ASSIGNEE');

  static readonly WORKLIST = new EmployeeGroupType('WORKLIST');

  constructor(public readonly value: string) {
    super();
    EmployeeGroupType.asArray.push(this);
    EmployeeGroupType.byKey[value] = this;
  }
}

export default EmployeeGroupType;
