import React from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import { Fonts } from 'styleguide-v2/src/styles/Variables';

import { Colors } from '../../styles/Colors';

interface Props {
  error?: boolean | string;
  required?: boolean;
}

const useStyles = makeStyles({
  error: {
    color: Colors.error,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    marginTop: '.4rem',
  },
});

export const ErrorLabel = ({ error, required }: Props) => {
  const styles = useStyles();

  return typeof error === 'string' || (error && required) ? (
    <span className={styles.error}>
      *{' '}
      {typeof error === 'string'
        ? error
        : translate('global.missingRequiredFieldMin')}
    </span>
  ) : (
    <></>
  );
};

export default ErrorLabel;
