export const Colors = {
  bkgdBlue: '#F4F7F6',
  btnBlue: '#3F8BF4',
  gold: '#FEC25F',
  gray: '#BBBBBB',
  green: '#00A384',
  lightGray: '#FBFBFB',
  lightMint: '#D8F2EC',
  mint: '#A7E6D7',
  red: '#FF5035',
  shadowGreen: '#006B57',
  tertiaryBlue: '#0E68E8',
  textBlack: '#444444',
  white: '#FFFFFF',
  yellow: '#FFF3C6',
};

// Only to be used when primary colors are not viable
export const SecondaryColors = {
  darkRed: '#C40A08',
  lightPink: '#FFE2DA',
  magenta: '#E2B7E0',
  orange: '#E48C41',
  pink: '#FFC0BD',
  rejectRed: '#FD3C2D',
  translucentBlack: 'rgba(52, 52, 52, 0.6)',
};

export default Colors;
