import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

import {
  BiometricsLocation,
  BiometricsType,
} from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';

import {
  Button,
  CollapsableSidebar,
  Colors,
  DateTimePicker,
  RadioGroup,
  Select,
  TextInput,
} from 'styleguide-v2';
import { RadioItem } from 'styleguide-v2/src/components/RadioButton/RadioGroup';

import { BiometricsPostParam } from 'dash/src/services/BiometricsServices';

const BIOMETRICS_MAX = {
  bp: 3,
  [BiometricsType.WEIGHT.value]: 6,
  [BiometricsType.OXYGEN.value]: 3,
  [BiometricsType.PULSE.value]: 3,
  [BiometricsType.TEMPERATURE.value]: 5,
};

const BIOMETRIC_MAX_VALUE = {
  [BiometricsType.OXYGEN.value]: 100,
  [BiometricsType.TEMPERATURE.value]: 110,
};

const BIOMETRIC_MIN_VALUE = {
  [BiometricsType.WEIGHT.value]: 40,
};

const useStyles = makeStyles({
  inputs: {
    alignItems: 'center',
    display: 'flex',
    gap: '0.5rem',
    maxWidth: '100%',
  },
  input: {
    flex: '1 1 100%',
  },
  separator: {
    color: Colors.iconGray,
    flex: '0 0 max-content',
    fontSize: '36px',
    fontWeight: 700,
    margin: '2rem -0.25rem 0 -0.25rem',
  },
});

type Props = {
  onClose: () => void;
  onSubmit: (params: BiometricsPostParam) => Promise<void>;
  open: boolean;
  type?: BiometricsType | 'bp';
};

const BIOMETRIC_TYPE_OPTIONS = [
  { label: translate('biometrics.bp'), value: 'bp' },
  {
    label: BiometricsType.OXYGEN.toString(),
    value: BiometricsType.OXYGEN.value,
  },
  {
    label: BiometricsType.WEIGHT.toString(),
    value: BiometricsType.WEIGHT.value,
  },
  { label: BiometricsType.PULSE.toString(), value: BiometricsType.PULSE.value },
  {
    label: BiometricsType.TEMPERATURE.toString(),
    value: BiometricsType.TEMPERATURE.value,
  },
  {
    label: BiometricsType.BLOOD_SUGAR.toString(),
    value: BiometricsType.BLOOD_SUGAR.value,
  },
];

export const BiometricAddReadingModal = ({
  onClose,
  onSubmit,
  open,
  type: defaultType,
}: Props) => {
  const styles = useStyles();
  const [submitted, setSubmitted] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const [type, setType] = useState<string | 'bp'>();
  const [date, setDate] = useState<Date>(new Date());
  const [time, setTime] = useState<Date>(
    moment()
      .minutes(Math.floor(moment().minutes() / 5) * 5)
      .toDate(),
  );
  const [values, setValues] = useState<number[]>([]);
  const [location, setLocation] = useState<BiometricsLocation>(
    BiometricsLocation.HOME,
  );

  useEffect(() => {
    if (open) {
      setSubmitted(false);
      setLoadingButton(false);
      setType(defaultType?.valueOf());
      setDate(new Date());
      setTime(
        moment()
          .minutes(Math.floor(moment().minutes() / 5) * 5)
          .toDate(),
      );
      setValues([]);
      setLocation(BiometricsLocation.HOME);
    }
  }, [open]);

  const doSubmit = async () => {
    setSubmitted(true);

    if (
      !type ||
      !date ||
      !time ||
      !values[0] ||
      Number.isNaN(values[0]) ||
      (type === 'bp' && (!values[1] || Number.isNaN(values[1]))) ||
      (!!BIOMETRIC_MIN_VALUE[type] && BIOMETRIC_MIN_VALUE[type] > values[0]) ||
      (!!BIOMETRIC_MAX_VALUE[type] && BIOMETRIC_MAX_VALUE[type] < values[0]) ||
      !location
    ) {
      return;
    }
    try {
      setLoadingButton(true);

      const readAt = moment(date.getTime())
        .hours(time.getHours())
        .minutes(time.getMinutes())
        .seconds(0)
        .utc()
        .format();
      const requestValues = new Map<string, number>();
      if (type === 'bp') {
        requestValues.set(BiometricsType.BP_SYSTOLIC.value, values[0]);
        requestValues.set(BiometricsType.BP_DIASTOLIC.value, values[1]);
      } else {
        requestValues.set(type, values[0]);
      }
      await onSubmit({
        readAt,
        readingLocation: location,
        values: requestValues,
      });
      onClose();
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <CollapsableSidebar
      open={open}
      size={450}
      title={
        <h2>
          {translate('biometrics.addReadingTitle', {
            type:
              defaultType === 'bp'
                ? translate('biometrics.bp')
                : defaultType?.toString() || '',
          })}
        </h2>
      }
      onClose={onClose}
    >
      <CollapsableSidebar.Body>
        {!defaultType && (
          <Select
            data-cy="biometrics-add-type"
            error={submitted && !type}
            items={BIOMETRIC_TYPE_OPTIONS}
            label={translate('biometrics.type')}
            onChange={(val: Selectable) =>
              setType(val ? (val.value as string) : undefined)
            }
            value={BIOMETRIC_TYPE_OPTIONS.find(({ value }) => value === type)}
            required
          />
        )}

        <div className="grid-wrapper">
          <DateTimePicker
            className="grid-span-6"
            data-cy="biometrics-add-date"
            error={submitted && !date}
            label={translate('global.date')}
            maxDate={Date.now()}
            onChange={setDate}
            type="date"
            value={date}
            required
          />

          <DateTimePicker
            className="grid-span-6"
            data-cy="biometrics-add-time"
            error={submitted && !time}
            interval={5}
            label={translate('global.time')}
            maxTime={
              moment(date.getTime()).isSame(moment(), 'day')
                ? Date.now()
                : undefined
            }
            onChange={setTime}
            type="time"
            value={time}
            required
          />
        </div>

        <div className={styles.inputs}>
          <>
            {type === 'bp' && (
              <>
                <TextInput
                  className="grid-span-5"
                  data-cy="biometrics-add-bp-0"
                  error={
                    submitted &&
                    ((!values[0] &&
                      translate('global.missingRequiredFieldMin')) ||
                      ((Number.isNaN(values[0]) || values[0] <= 0) &&
                        translate('global.positiveNumberInvalid')))
                  }
                  label={`${translate(
                    'biometrics.systolic',
                  )} (${BiometricsType.getUnit(BiometricsType.BP_SYSTOLIC)})`}
                  maxLength={BIOMETRICS_MAX.bp}
                  onChange={(value) => setValues([Number(value), values[1]])}
                  value={values[0] ? Number(values[0]) : ''}
                />
                <label className={styles.separator}>/</label>
                <TextInput
                  className="grid-span-5"
                  data-cy="biometrics-add-bp-1"
                  error={
                    submitted &&
                    ((!values[1] &&
                      translate('global.missingRequiredFieldMin')) ||
                      ((Number.isNaN(values[1]) || values[1] <= 0) &&
                        translate('global.positiveNumberInvalid')))
                  }
                  label={`${translate(
                    'biometrics.diastolic',
                  )} (${BiometricsType.getUnit(BiometricsType.BP_DIASTOLIC)})`}
                  maxLength={BIOMETRICS_MAX.bp}
                  onChange={(value) => setValues([values[0], Number(value)])}
                  value={values[1] ? Number(values[1]) : ''}
                />
              </>
            )}

            {type && type !== 'bp' && (
              <TextInput
                className={styles.input}
                data-cy={`biometrics-add-${type}`}
                error={
                  submitted &&
                  ((!values[0] &&
                    translate('global.missingRequiredFieldMin')) ||
                    ((Number.isNaN(values[0]) || values[0] <= 0) &&
                      translate('global.positiveNumberInvalid')) ||
                    (!!BIOMETRIC_MIN_VALUE[type] &&
                      BIOMETRIC_MIN_VALUE[type] > values[0] &&
                      translate('common.errors.minValue', {
                        value: BIOMETRIC_MIN_VALUE[type],
                      })) ||
                    (!!BIOMETRIC_MAX_VALUE[type] &&
                      BIOMETRIC_MAX_VALUE[type] < values[0] &&
                      translate('common.errors.maxValue', {
                        value: BIOMETRIC_MAX_VALUE[type],
                      })))
                }
                label={`${BiometricsType.byKey[
                  type
                ].toString()} (${BiometricsType.getUnit(
                  BiometricsType.byKey[type],
                )})`}
                maxLength={BIOMETRICS_MAX[type]}
                onChange={(value) =>
                  setValues(
                    [
                      BiometricsType.OXYGEN.value,
                      BiometricsType.PULSE.value,
                    ].indexOf(type) !== -1
                      ? [Math.floor(Number(value))]
                      : [Number(value)],
                  )
                }
                value={values[0] ? Number(values[0]) : ''}
              />
            )}
          </>
        </div>

        <RadioGroup
          data-cy="biometrics-add-location"
          items={Enum.toSelectable(BiometricsLocation.asArray) as RadioItem[]}
          onChange={(value) => setLocation(BiometricsLocation.byKey[value])}
          title={translate('biometrics.location')}
          value={location.value}
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          data-cy="biometrics-add-cancel"
          color="tertiary"
          onClick={() => onClose()}
        >
          {translate('global.cancel')}
        </Button>
        <Button
          color="secondary"
          data-cy="biometrics-add-save"
          disabled={!defaultType && !type}
          loading={loadingButton}
          onClick={() => doSubmit()}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default BiometricAddReadingModal;
