import { Enum } from './Enum';

export class PhoneType extends Enum {
  translationKey = 'phoneType';

  static asArray: PhoneType[] = [];

  static byKey: { [name: string]: PhoneType } = {};

  static readonly HOME = new PhoneType('HOME');

  static readonly CELL = new PhoneType('CELL');

  static readonly FAX = new PhoneType('FAX');

  static readonly BUSINESS = new PhoneType('BUSINESS');

  constructor(public readonly value: string) {
    super();
    PhoneType.asArray.push(this);
    PhoneType.byKey[value] = this;
  }

  static toSelectable() {
    return Enum.toSelectable(Object.values(PhoneType.byKey));
  }

  static asMemberArray = [PhoneType.HOME, PhoneType.CELL, PhoneType.FAX];
}

export default PhoneType;
