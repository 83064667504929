import { EMPTY } from '../../utils/constants';
import { BaseEnum } from '../BaseEnum';
import { CareTeamMemberSpeciality } from '../CareTeamMemberSpeciality';
import { CareTeamMemberType } from '../CareTeamMemberType';

export interface CareTeamMemberTypeHelper {
  type?: CareTeamMemberType;
  aideType?: BaseEnum;
  otherRelationship?: BaseEnum;
  relationship?: BaseEnum;
  relationshipOther: string;
  specialities?: CareTeamMemberSpeciality[];

  typeWithSubType: { type?: CareTeamMemberType; subtype?: string };
  typeWithSubTypeLabel: string;
}

export const getTypeWithSubType = ({
  type,
  aideType,
  relationship,
  relationshipOther,
  otherRelationship,
  specialities,
}: CareTeamMemberTypeHelper) => {
  let subtype: string | undefined;

  switch (type?.id) {
    case CareTeamMemberType.AIDE:
      subtype = aideType?.description;
      break;
    case CareTeamMemberType.FAMILY_FRIEND:
      subtype = relationship?.description;
      break;
    case CareTeamMemberType.OTHER:
      if (otherRelationship?.id === 'OTHER' && relationshipOther) {
        subtype = relationshipOther;
      } else {
        subtype = otherRelationship?.description;
      }
      break;
    case CareTeamMemberType.PROVIDER:
      subtype = specialities
        ?.map(({ specialityType, otherSpeciality }) =>
          specialityType.id === 'OTHER'
            ? otherSpeciality
            : specialityType.description,
        )
        .join(', ');
      break;
    default:
  }

  return {
    type,
    subtype,
  };
};

export const getTypeWithSubTypeLabel = (typeWithSubType: {
  type?: CareTeamMemberType;
  subtype?: string;
}) =>
  `${typeWithSubType.type?.description || EMPTY}${
    typeWithSubType.subtype ? ` (${typeWithSubType.subtype})` : ''
  }`;
