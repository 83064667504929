import { BaseEnum } from './BaseEnum';
import { make } from './Model';
import { PatientSearch } from './PatientSearch';

export class HouseHoldMember {
  id = 0;

  linkedMember: PatientSearch;

  relationship: BaseEnum;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.linkedMember = make(obj.linkedMember, PatientSearch);
    this.relationship = make(obj.relationship, BaseEnum);
  }
}

export default HouseHoldMember;
