export class OrganizationType {
  name = '';

  id = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  static AGENCY = 1;
}

export default OrganizationType;
