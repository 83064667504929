import Api from 'dash/src/services/Api';
import {
  Organization,
  ProgramConfiguration,
} from '@vestahealthcare/common/models';

export const fetchPrograms = async (): Promise<ProgramConfiguration[]> => {
  const { programConfigurations } = await Api.getv2('programs');
  return programConfigurations.items
    .map((item: any) => new ProgramConfiguration(item))
    .sort((a: ProgramConfiguration, b: ProgramConfiguration) =>
      a.name.localeCompare(b.name),
    );
};

export const fetchClients = async (): Promise<Organization[]> => {
  const { clients } = await Api.getv2('programs/clients');
  return clients.items.map((item: any) => new Organization(item));
};
