import React from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/WarningOutlined';
import { ClassNameMap, makeStyles } from '@mui/styles';

import { TimeUnit } from '@vestahealthcare/common/enums';
import { PaginationType } from '@vestahealthcare/common/hooks/useAsyncData';
import { translate } from '@vestahealthcare/common/i18n';
import {
  EngagementCampaign,
  EngagementCampaignCondition,
} from '@vestahealthcare/common/models';

import { Chip, Colors, Fonts, IconButton, Table, Tooltip } from 'styleguide-v2';

interface Props {
  allowEditActive?: boolean;
  campaigns: EngagementCampaign[];
  onChangePage: (page: number) => void;
  onChangePageSize: (size: number) => void;
  onEdit: (group: EngagementCampaign) => void;
  pagination: PaginationType;
}

const getTriggerText = (trigger?: EngagementCampaignCondition) => {
  if (trigger?.isMemberOnboarded) {
    return (
      <span className="flex gap middle">
        {translate('campaigns.manager.member')}{' '}
        <ArrowForwardIcon htmlColor="white" fontSize="small" />{' '}
        {translate('campaigns.manager.active')}
      </span>
    );
  }

  if (trigger?.isCGOnboarded) {
    return (
      <span className="flex gap middle">
        {translate('campaigns.manager.caregiver')}{' '}
        <ArrowForwardIcon htmlColor="white" fontSize="small" />{' '}
        {translate('campaigns.manager.onboarded')}
      </span>
    );
  }

  if (trigger?.isFirstRPMVital) {
    return (
      <span className="flex gap middle">
        {translate('campaigns.manager.vital')}{' '}
        <ArrowForwardIcon htmlColor="white" fontSize="small" />{' '}
        {translate('campaigns.manager.RPM')}
      </span>
    );
  }

  if (trigger?.isRPMOnboarded) {
    return (
      <span className="flex gap middle">
        {translate('campaigns.manager.RPM')}{' '}
        <ArrowForwardIcon htmlColor="white" fontSize="small" />{' '}
        {translate('campaigns.manager.active')}
      </span>
    );
  }

  if (trigger?.isPINGDischargeFacility) {
    return (
      <span className="flex gap middle">
        {translate('campaigns.manager.PING')}{' '}
        <ArrowForwardIcon htmlColor="white" fontSize="small" />{' '}
        {translate('campaigns.manager.dischargeFacility')}
      </span>
    );
  }

  if (trigger?.isPINGDischargeER) {
    return (
      <span className="flex gap middle">
        {translate('campaigns.manager.PING')}{' '}
        <ArrowForwardIcon htmlColor="white" fontSize="small" />{' '}
        {translate('campaigns.manager.dischargeER')}
      </span>
    );
  }

  return undefined;
};

const CAMPAIGN_COLUMNS = (
  { allowEditActive, onEdit }: Props,
  styles: ClassNameMap<'textGray'>,
) => [
  {
    headerName: translate('campaigns.manager.name'),
    field: 'name',
  },
  {
    headerName: translate('campaigns.manager.triggers'),
    component: ({ id: cId, triggers }: EngagementCampaign) => (
      <div className="flex gap wrap">
        {triggers.map((trigger) => (
          <Chip
            color="secondary"
            key={`campaign-${cId}-trigger-${trigger.id}`}
            title={getTriggerText(trigger) && trigger.descTrigger}
          >
            {getTriggerText(trigger) || trigger.descTrigger}
          </Chip>
        ))}
      </div>
    ),
    width: 75,
  },
  {
    headerName: translate('campaigns.manager.timing'),
    component: ({ delay, delayUnit, timingOption }: EngagementCampaign) => (
      <>
        <p>
          {delay
            ? translate(
                `global.${
                  delayUnit === TimeUnit.HOURS ? 'hours' : 'days'
                }Count`,
                { count: delay },
              )
            : translate('campaigns.manager.nextAvailable')}
        </p>
        <p className={styles.textGray}>{timingOption.toString()}</p>
      </>
    ),
  },
  {
    headerName: translate('campaigns.manager.audience'),
    component: ({ id: cId, audience }: EngagementCampaign) => (
      <div className="flex gap wrap">
        {audience.map(({ id, description }) => (
          <Chip
            color="info"
            key={`campaign-${cId}-audience-${id}`}
            type="outlined"
            size="xs"
          >
            {description}
          </Chip>
        ))}
      </div>
    ),
    width: 75,
  },
  {
    headerName: translate('campaigns.manager.action'),
    component: ({ content, deliveryMethod }: EngagementCampaign) => (
      <>
        <a
          href={`#/admin/campaigns/content-library?id=${content.id}`}
          rel="noreferrer"
          target="_blank"
        >
          {content.name}
        </a>{' '}
        <Chip color="info" type="outlined" size="xs">
          {deliveryMethod.description}
        </Chip>
      </>
    ),
  },
  {
    headerName: translate('campaigns.manager.conditions'),
    component: ({ id, conditions, exceptions }: EngagementCampaign) => (
      <span className="flex center">
        {!!(conditions?.length || exceptions?.length) && (
          <Tooltip
            text={
              <>
                {conditions.map(({ id: eID, descCondition }) => (
                  <p
                    key={`campaign-${id}-condition-${eID}`}
                    style={{ color: 'white' }}
                  >
                    {descCondition}
                  </p>
                ))}
                {exceptions.map(({ id: eID, descException }) => (
                  <p
                    key={`campaign-${id}-exception-${eID}`}
                    style={{ color: 'white' }}
                  >
                    {descException}
                  </p>
                ))}
              </>
            }
          >
            <WarningIcon color="warning" />
          </Tooltip>
        )}
      </span>
    ),
    width: 20,
  },
  {
    headerName: translate('campaigns.manager.active'),
    component: ({ active }: any) => (
      <span className="flex center">
        {active ? (
          <CheckCircleIcon color="primary" />
        ) : (
          <CancelIcon color="error" />
        )}
      </span>
    ),
    width: 20,
  },
  {
    headerName: '',
    component: (campaign: EngagementCampaign) => (
      <IconButton
        disabled={campaign.active && !allowEditActive}
        onClick={() => onEdit(campaign)}
        size="small"
      >
        <EditIcon />
      </IconButton>
    ),
    width: 20,
  },
];

const useStyles = makeStyles({
  table: {
    '&& tbody': {
      '& td': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
      '& a': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
    },
  },
  textGray: {
    color: Colors.gray,
  },
});

export const CampaignManagerTable = (props: Props) => {
  const styles = useStyles();
  return (
    <Table
      className={styles.table}
      config={{
        columns: CAMPAIGN_COLUMNS(props, styles),
        compact: true,
        data: props.campaigns,
        pagination: props.pagination,
      }}
      onChangePage={props.onChangePage}
      onChangeRowsPerPage={props.onChangePageSize}
    />
  );
};

export default CampaignManagerTable;
