import { UserPhone } from './UserPhone';

export class MemberPhone extends UserPhone {
  emrIntegrated?: boolean;

  crmIntegrated?: boolean;

  constructor(obj: any) {
    super(obj);
    this.emrIntegrated = obj.emrIntegrated;
    this.crmIntegrated = obj.crmIntegrated;
  }

  get readOnly() {
    return this.emrIntegrated === true || this.crmIntegrated === true;
  }

  get isPhoneEmrIntegrated() {
    return this.emrIntegrated;
  }

  get isPhoneCrmIntegrated() {
    return this.crmIntegrated;
  }
}

export default MemberPhone;
