import classNames from 'classnames';
import React from 'react';

import AddIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import {
  BackgroundColors,
  Button,
  Colors,
  Fonts,
  IconButton,
} from 'styleguide-v2';

interface Props {
  className?: string;
  color?: 'info' | 'warning';
  editable?: boolean;
  emptyLabel?: string;
  note?: string;
  onEdit: () => void;
}

const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    borderRadius: '0.5rem',
    display: 'flex',
    gap: '0.5rem',
    paddingLeft: '1rem',
  },
  containerNoEditable: {
    paddingRight: '1rem',
  },
  containerInfo: {
    backgroundColor: BackgroundColors.lightBlue,
  },
  containerWarning: {
    backgroundColor: BackgroundColors.lightYellow,
  },
  iconButton: {
    padding: '0.75rem',
  },
  text: {
    lineHeight: `calc(${Fonts.fontSize} * 1.5)`,
    padding: '0.25rem',
  },
});

export const NoteSection = ({
  className,
  color,
  editable,
  emptyLabel,
  note,
  onEdit,
}: Props) => {
  const styles = useStyles();

  return (
    <div className={className}>
      {note && (
        <>
          <div
            className={classNames(
              styles.container,
              !editable && styles.containerNoEditable,
              color === 'info' && styles.containerInfo,
              color === 'warning' && styles.containerWarning,
            )}
          >
            <span className={styles.text}>{note}</span>
            {editable && (
              <IconButton
                className={styles.iconButton}
                disabled={!editable}
                size="small"
                onClick={onEdit}
              >
                <EditIcon htmlColor={Colors.blue} />
              </IconButton>
            )}
          </div>
        </>
      )}
      {!note && editable && (
        <Button color="quaternary" onClick={onEdit} size="xs">
          <AddIcon htmlColor={Colors.blue} />
          <span className="bold" style={{ marginLeft: '0.25rem' }}>
            {emptyLabel || translate('components.noteSection.addNotes')}
          </span>
        </Button>
      )}
    </div>
  );
};
