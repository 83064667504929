import React from 'react';

import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import CalendarIcon from '@mui/icons-material/Event';
import ListIcon from '@mui/icons-material/FormatListBulleted';
import MissingInfoIcon from '@mui/icons-material/HelpCenter';
import RPMIcon from '@mui/icons-material/MonitorHeart';

import { MemberWarningCategory } from '@vestahealthcare/common/models';

type Props = {
  category: MemberWarningCategory;
  className?: string;
  size?: 'small' | 'inherit' | 'large' | 'medium';
};

export const MemberWarningIcon = ({ category, className, size }: Props) => {
  if (category.id === MemberWarningCategory.TOC)
    return <AddBoxOutlinedIcon className={className} fontSize={size} />;
  if (category.id === MemberWarningCategory.APPOINTMENT)
    return <CalendarIcon className={className} fontSize={size} />;
  if (category.id === MemberWarningCategory.MISSING_INFO)
    return <MissingInfoIcon className={className} fontSize={size} />;
  if (category.id === MemberWarningCategory.OPEN_ITEMS)
    return <ListIcon className={className} fontSize={size} />;
  if (category.id === MemberWarningCategory.RPM_RISK)
    return <RPMIcon className={className} fontSize={size} />;
  if (category.id === MemberWarningCategory.TIME)
    return <AvTimerIcon className={className} fontSize={size} />;

  return <></>;
};
