import classNames from 'classnames';
import React, { MouseEvent, ReactNode } from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import { BackgroundColors, Colors } from 'styleguide-v2/src/styles/Colors';

interface Props {
  className?: string;
  children?: ReactNode | ReactNode[];
  label?: string;
  link?: string;
  onClickLink?: () => void;
  whiteBackground?: boolean;
}

const useStyles = makeStyles({
  container: {
    background: BackgroundColors.gray,
    fontStyle: 'italic',
    marginBottom: '1rem',
    padding: '4rem 1rem',
    textAlign: 'center',

    '& p': {
      marginBottom: 0,
    },

    '& a': {
      fontSize: '1em',
      marginLeft: '0.2em',
    },
  },
  whiteBackground: {
    '&&': {
      background: Colors.white,
      padding: 0,
      color: Colors.textGray,
    },
  },
});

export const EmptyState = ({
  children,
  className,
  label,
  link,
  onClickLink,
  whiteBackground,
}: Props) => {
  const styles = useStyles();
  const handleClick = (evt: MouseEvent) => {
    evt.preventDefault();
    if (onClickLink) onClickLink();
  };
  return (
    <div
      className={classNames(
        className,
        styles.container,
        whiteBackground && styles.whiteBackground,
      )}
    >
      <span>{label || translate('components.common.noResults')}</span>
      {link && '. '}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      {link && <a onClick={handleClick}>{link}</a>}
      {children && <div>{children}</div>}
    </div>
  );
};

export default EmptyState;
