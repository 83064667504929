/* eslint-disable @typescript-eslint/no-use-before-define */
import moment, { Moment } from 'moment';

import { RPMAdherenceLevel } from '../enums';
import { BiometricsRPMAdherenceCycle } from './BiometricsRPMAdherenceCycle';
import { Measurement } from './Measurement';
import { make, makeArray } from './Model';

export class BiometricsRPMAdherence {
  currentCycleStart: Moment;

  currentCycleEnd: Moment;

  onboardedRPM: Moment;

  totalReadings = 0;

  opportunityLevel: RPMAdherenceLevel;

  remainingReadings = 0;

  lastReading?: Measurement;

  adherenceTrend = 0;

  completedCycles = 0;

  totalCycles = 0;

  recentCycles: BiometricsRPMAdherenceCycle[];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.currentCycleStart = moment.unix(obj.currentCycleStart);
    this.currentCycleEnd = moment.unix(obj.currentCycleEnd);
    this.onboardedRPM = moment.unix(obj.onboardedRPM);
    this.opportunityLevel = RPMAdherenceLevel.byKey[obj.opportunityLevel];
    this.lastReading = make(obj.lastReading, Measurement);
    this.recentCycles = makeArray(
      obj.recentCycles,
      BiometricsRPMAdherenceCycle,
    );
  }
}

export default BiometricsRPMAdherence;
