export class DBEnum {
  id = 0;

  description = '';

  active = false;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.active = obj.active === undefined ? true : obj.active;
  }

  static toSelectable(items: DBEnum[]) {
    return items.map(({ id, description }) => ({
      value: id,
      label: description,
    }));
  }
}

export default DBEnum;
