import favicon from 'dash/static/images/favicons/stage.png';

export default {
  favicon,
  apiv2: {
    url: 'https://api.stage.hometeamcare.com/v2',
    credentials: {
      consumerKey: 2,
    },
  },
  auth: {
    redirectUrl: 'https://dash.stage.hometeamcare.com',
    clientId: '60b4b6b2hrmcqdb9p4b0csbp0d',
    hostedDomainUrl:
      'https://nonprod-vesta-dash.auth.us-east-1.amazoncognito.com',
  },
  env: 'stage',
  staticUrl: 'https://s3.amazonaws.com/hometeam-static',
  isDemo: false,
  isDev: false,
  isProd: false,
  isQA: false,
  isStage: true,
  segment: {
    writeKey: 'AumyqOPYuayxR5GMX1L407gHOaRlWnAW',
  },
  sentry: {
    dsn:
      'https://aca920a78f314819b1afa421471045e0@sentry.vestahealthcare.com/4',
  },
  appCenter: {
    apiToken: '85e072e6f4285e95def6ea7e6e2a0065e2c2f8a4',
  },
  googleAnalytics: 'UA-49155721-6',
  launchDarkly: '5e73de4dcd123c077b2862ba',
  streamChat: 'c6qrdnvrcp6n',
  clearTriageToken: 'lKdYlQWk1sMCRWn5EyUc',
};
