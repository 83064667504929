import Api from 'dash/src/services/Api';

interface ChatToken {
  chat: {
    userId: string;
    token: string;
  };
}

export const getChatJWT = async (): Promise<ChatToken> =>
  await Api.getv2('chat/token');
