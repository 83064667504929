import { Employee } from './Employee';
import { IssueType } from './IssueType';
import { make, makeArray } from './Model';

export class EventType {
  id = 0;

  name = '';

  active = true;

  createdAt = 0;

  createdBy: Employee;

  updatedAt = 0;

  updatedBy: Employee;

  issueTypes?: IssueType[];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdBy = make(obj.createdBy, Employee);
    this.updatedBy = make(obj.updatedBy, Employee);
    this.issueTypes = makeArray(obj.issueTypes, IssueType);
  }

  static FALL = 1;

  static ER_VISIT = 3;

  static FACILITY = 4;

  static HOSPITALIZATION = 4;

  static toSelectable(types: EventType[]) {
    return types.map(({ id, name }) => ({ value: id, label: name }));
  }
}

export default EventType;
