import React from 'react';

import { makeStyles } from '@mui/styles';

import { CareQuestion, HealthReport } from '@vestahealthcare/common/models';

import { BackgroundColors, Table, UpdatedBy } from 'styleguide-v2';

interface Props {
  report: HealthReport;
}

const HEALTH_REPORT_QUESTIONS = [
  {
    headerName: '',
    component: ({ title, displayedAnswer }: CareQuestion) => (
      <>
        <p className="no-margin">
          <i>{title}</i>
        </p>
        <p className="no-margin semi-bold">{displayedAnswer}</p>
      </>
    ),
  },
  {
    headerName: '',
    component: ({ completed, updatedAt }: CareQuestion) =>
      completed ? <UpdatedBy date={updatedAt} /> : <></>,
    width: 175,
  },
];

const useStyles = makeStyles({
  evenRow: {
    '&.MuiTableCell-root.MuiTableCell-body': {
      backgroundColor: `${BackgroundColors.gray}`,
    },
  },
});

export const HealthReportDetail = ({ report: { careQuestions } }: Props) => {
  const styles = useStyles();

  return (
    <Table
      config={{
        columns: HEALTH_REPORT_QUESTIONS,
        compact: true,
        data: careQuestions,
        paginationOptions: [-1],
        getRowClass: (_, idx) => (idx % 2 === 1 ? styles.evenRow : ''),
      }}
    />
  );
};

export default HealthReportDetail;
