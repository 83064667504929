const NOOP = () => {};

// Notifications
class GlobalMessage {
  show: Function;

  dismiss: Function;

  constructor() {
    this.unregister();
    this.show = NOOP;
    this.dismiss = NOOP;
  }

  register(show: () => void, dismiss: () => void) {
    this.show = show;
    this.dismiss = dismiss;
  }

  unregister() {
    this.show = NOOP;
    this.dismiss = NOOP;
  }
}

const globalSuccess = new GlobalMessage();
const globalError = new GlobalMessage();

export { globalSuccess as Success, globalError as ErrorModal };
