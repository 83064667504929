// @ts-ignore
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';

import {
  Choice,
  EncounterChannel,
  EncounterDirection,
  EncounterMethod,
  EncounterType,
} from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  Encounter,
  PaginationType,
} from '@vestahealthcare/common/models';
import moment from '@vestahealthcare/common/moment';
import { LS_ENCOUNTER_FILTERS } from '@vestahealthcare/common/utils/constants';

import {
  Button,
  FilterButton,
  IconButton,
  Modal,
  Panel,
  Select,
  ToggleDateRange,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { fetchPatientWarnings } from 'dash/src/redux/slices/MemberInfoSlice';
import { useSelector } from 'dash/src/redux/store';
import { CacheServices } from 'dash/src/services';
import {
  fetchAllEncounters,
  invalidateEncounter,
} from 'dash/src/services/EncounterServices';
import { transformDateToDaysRange } from 'dash/src/utils/dateUtils';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { AddEncounterModal } from './AddEncounterModal';
import EncounterTable from './EncounterTable';

type Params = {
  patientId: string;
};

const ROWS_PER_PAGE = 10;

const getArrayQueryParam = (
  query: URLSearchParams,
  param: string,
): string[] | undefined => {
  const val = query.get(param);
  return val?.split(',');
};

const useStyles = makeStyles({
  dateRange: {
    marginTop: '-2.5rem',
  },
  filtersContainer: {
    display: 'flex',
    gap: '1rem',
  },
});

const getFilterValue = (
  query?: string | null,
  storage?: string,
  mapper?: (value: string) => any,
) => {
  let result: string = '';
  if (query) {
    result = query;
  } else if (storage) {
    result = storage;
  }
  return mapper ? mapper(result) : result;
};

const initFilters = (query: URLSearchParams) => {
  const filtersString = localStorage.getItem(LS_ENCOUNTER_FILTERS) || '';
  const f = qs.parse(filtersString) || {};

  const dateRange =
    query.get('defaultToggleRange') || f.defaultToggleRange || 30;
  const defaultToggleRange = isNaN(Number(dateRange))
    ? dateRange
    : Number(dateRange);

  let valid;
  if (query.get('valid') === 'true' || f.valid === 'true') {
    [valid] = Choice.getChoices();
  } else if (query.get('valid') === 'false' || f.valid === 'false') {
    [, valid] = Choice.getChoices();
  }

  return {
    valid,
    channel: getFilterValue(query.get('channel'), f.channel, (x) =>
      x ? Enum.toSelectable([EncounterChannel.byKey[x]])[0] : undefined,
    ),
    direction: getFilterValue(query.get('direction'), f.direction, (x) =>
      x ? Enum.toSelectable([EncounterDirection.byKey[x]])[0] : undefined,
    ),
    method: getFilterValue(query.get('method'), f.method, (x) =>
      x ? Enum.toSelectable([EncounterMethod.byKey[x]])[0] : undefined,
    ),
    dashUser: getFilterValue(query.get('dashUser'), f.dashUser, (x) =>
      x ? Number(x) : undefined,
    ),
    defaultToggleRange,
  };
};

export const Encounters = (props: RouteComponentProps<Params>) => {
  const { patientId } = props.match.params;
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQueryParams();
  const styles = useStyles();

  const [loading, setLoading] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [isOpenAddEncounter, setOpenAddEncounter] = useState(false);
  const [confirmInvalidateEncounter, setConfirmInvalidateEncounter] = useState(
    false,
  );

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(ROWS_PER_PAGE);

  const { defaultToggleRange: dTR, ...filters } = initFilters(query);
  const [defaultToggleRange, setDefaultToggleRange] = useState<number | string>(
    dTR,
  );
  const [interval, setInterval] = useState<[Date, Date]>();

  const [valid, setValid] = useState<{ value: boolean } | undefined>(
    filters.valid,
  );
  const [channel, setChannel] = useState<Selectable | undefined>(
    filters.channel,
  );
  const [direction, setDirection] = useState<Selectable | undefined>(
    filters.direction,
  );
  const [method, setMethod] = useState<Selectable | undefined>(filters.method);
  const [dashUser, setDashUser] = useState<number | undefined>(
    filters.dashUser,
  );

  const [editEncounter, setEditEncounter] = useState<Encounter>();
  const [encounters, setEncounters] = useState<Encounter[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [ids, setIds] = useState(getArrayQueryParam(query, 'ids') || []);

  const patient = useSelector((state) => state.memberInfoSlice.patient);
  const editable = patient?.isEditable();

  const clearAllFilters = () => {
    setValid(undefined);
    setDirection(undefined);
    setMethod(undefined);
    setChannel(undefined);
    setDashUser(undefined);
    setIds([]);
    history.push('?');
  };

  const storeFilters = () => {
    const flatFilters = {
      channel: channel?.value,
      dashUser,
      defaultToggleRange,
      direction: direction?.value,
      method: method?.value,
      valid: valid?.value,
    };
    const query = qs.stringify(flatFilters);
    history.replace(`?${query}`);

    localStorage.setItem(LS_ENCOUNTER_FILTERS, qs.stringify(flatFilters));
  };

  const fetchData = async () => {
    setLoading(true);

    try {
      const { items, pagination: currentPagination } = await fetchAllEncounters(
        patientId,
        {
          ids,
          method: method?.value as string,
          channel: channel?.value as string,
          direction: direction?.value as string,
          encounterDateTimeStart: !ids.length
            ? moment(interval && interval[0].getTime()).format()
            : undefined,
          encounterDateTimeEnd: !ids.length
            ? moment(interval && interval[1].getTime()).format()
            : undefined,
          createdById: dashUser,
          valid: valid ? valid.value : undefined,
          offset: page * pageSize,
          limit: pageSize,
          sort: 'createdAt desc',
        },
      );
      if (page !== 0) {
        setEncounters([...encounters, ...items]);
      } else {
        setEncounters(items);
      }
      setPagination(currentPagination);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  const checkWarnings = (type?: EncounterType) => {
    if (
      patientId &&
      type &&
      [EncounterType.CCM, EncounterType.RPM].includes(type)
    ) {
      dispatch(fetchPatientWarnings(+patientId));
    }
  };

  useEffect(() => {
    if (interval) {
      storeFilters();
      fetchData();
    }
  }, [
    channel,
    dashUser,
    defaultToggleRange,
    direction,
    method,
    page,
    pageSize,
    patientId,
    valid,
    interval,
  ]);

  useEffect(() => {
    (async () => {
      const employees = await CacheServices.getEmployees();
      setEmployees(employees);
    })();
  }, []);

  return (
    <Panel>
      <Panel.Heading title={translate('encounters.title')}>
        <Panel.Filters className={styles.filtersContainer}>
          <ToggleDateRange
            className={styles.dateRange}
            items={[1, 7, 30, 90, 'custom']}
            onChange={(from, to) => {
              if (from && to) {
                // eslint-disable-next-line @typescript-eslint/no-implied-eval
                setInterval([from, to]);
                setDefaultToggleRange(
                  transformDateToDaysRange(from, to, [1, 7, 30, 90]),
                );
              }
            }}
            defaultValue={defaultToggleRange}
          />
          <FilterButton
            data-cy="members-filters"
            filters={filters}
            onClick={() => setOpen(!isOpen)}
          />
        </Panel.Filters>
        <Panel.Actions>
          <IconButton
            onClick={() => {
              setEditEncounter(undefined);
              setOpenAddEncounter(true);
            }}
            tooltip={translate('encounters.addEncounter')}
          >
            <AddIcon fontSize="large" />
          </IconButton>
        </Panel.Actions>
        <Panel.Collapse data-cy="encounters-filters-collapsible" open={isOpen}>
          <div className="grid-wrapper fit">
            <Select
              className="grid-span-3"
              items={Choice.getChoices()}
              onChange={(val: { value: boolean }) => {
                setPage(0);
                setValid(val);
              }}
              placeholder={translate('encounters.valid')}
              value={valid}
            />
            <Select
              className="grid-span-3"
              items={EncounterMethod.toSelectable()}
              onChange={(val: Selectable) => {
                setPage(0);
                setMethod(val);
              }}
              placeholder={translate('encounters.method')}
              value={method}
            />
            <Select
              className="grid-span-3"
              items={EncounterDirection.toSelectable()}
              onChange={(val: Selectable) => {
                setPage(0);
                setDirection(val);
              }}
              placeholder={translate('encounters.direction')}
              value={direction}
            />
            <Select
              className="grid-span-3"
              items={EncounterChannel.toSelectable()}
              onChange={(val: Selectable) => {
                setPage(0);
                setChannel(val);
              }}
              placeholder={translate('encounters.reason')}
              value={channel}
            />
            <Select
              className="grid-span-3"
              getItemLabel={({ fullName }) => fullName}
              items={employees}
              onChange={(employee?: Employee) => {
                setPage(0);
                setDashUser(employee?.id);
              }}
              placeholder={translate('encounters.createdBy')}
              value={employees.find(({ id }: Employee) => id === dashUser)}
            />
            <div className="grid-span-6" />
            <Button
              className="grid-span-3"
              data-cy="encounters-clear-filters"
              color="secondary"
              type="outlined"
              onClick={() => clearAllFilters()}
            >
              {translate('global.clearFilters')}
            </Button>
          </div>
        </Panel.Collapse>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        {patient && pagination && (
          <EncounterTable
            patient={patient}
            encounters={encounters}
            pagination={pagination}
            onEdit={
              editable
                ? (encounter) => {
                    setEditEncounter(encounter);
                    setOpenAddEncounter(true);
                  }
                : undefined
            }
            onShow={
              editable
                ? undefined
                : (encounter) => {
                    setEditEncounter(encounter);
                    setOpenAddEncounter(true);
                  }
            }
            onInvalidate={
              editable
                ? (encounter) => {
                    setEditEncounter(encounter);
                    setConfirmInvalidateEncounter(true);
                  }
                : undefined
            }
            onChangePage={setPage}
            onChangePageSize={setPageSize}
          />
        )}
        <Modal
          title={translate('encounters.invalidateHeader')}
          body={translate('encounters.invalidateBody')}
          onClose={() => setConfirmInvalidateEncounter(false)}
          onSubmit={async () => {
            if (editEncounter) {
              await invalidateEncounter(patientId, editEncounter.id);
              await fetchData();
              checkWarnings(editEncounter.type);
            }
          }}
          open={confirmInvalidateEncounter}
        />
        <AddEncounterModal
          avoidRequiredMinutes
          encounter={editEncounter}
          readonly={!editable}
          onClose={() => {
            setOpenAddEncounter(false);
          }}
          onSubmit={async (encounter) => {
            await fetchData();
            checkWarnings(encounter?.type);
            checkWarnings(editEncounter?.type);
            setOpenAddEncounter(false);
          }}
          open={isOpenAddEncounter}
          patient={patient}
        />
      </Panel.Body>
    </Panel>
  );
};

export default Encounters;
