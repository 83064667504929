import React, { useEffect, useState } from 'react';

import { Brand } from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Select } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CacheServices } from 'dash/src/services';
import { updatePatientBrand } from 'dash/src/services/PatientServices';

import { BaseModal } from './BaseModal';

interface Props {
  patient: Patient;
  onSubmit: (patient: Patient) => void;
}

const DetailsComponent = React.memo(
  ({ brands, patient }: { brands: Selectable[]; patient: Patient }) => {
    return (
      <>
        <h4 className="grid-span-5" data-cy="member-brand-label">
          {translate('personalDetails.brand')}
        </h4>
        <p className="grid-span-6" data-cy="member-brand-readonly">
          {brands?.length
            ? brands.find(({ value }) => patient.brand.value === value)
                ?.label || ''
            : EMPTY}
        </p>
      </>
    );
  },
);

export const EditBrand = ({ patient, onSubmit }: Props) => {
  const { brand } = patient;

  const [brands, setBrands] = useState<Selectable[]>([]);

  const [currentBrand, setBrand] = useState<Selectable>(
    Enum.toSelectable([brand])[0],
  );
  const [buttonLoading, setButtonLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const getInitialData = async () => {
    const b = await CacheServices.getOrganizationBrands();
    setBrands(b.map(({ id, name }) => ({ value: id, label: name })));
  };

  const submit = async () => {
    let result = true;
    setSubmitted(true);
    setButtonLoading(true);

    try {
      const newPatient = await updatePatientBrand(
        patient.id,
        Brand.byKey[currentBrand.value],
      );
      await onSubmit(newPatient);
    } catch (e) {
      result = false;
      showGlobalError(e as string);
    }

    setButtonLoading(false);
    return result;
  };

  const reset = () => {
    setBrand(Enum.toSelectable([brand])[0]);
    setButtonLoading(false);
    setSubmitted(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => reset(), [patient]);

  return (
    <BaseModal
      details={<DetailsComponent brands={brands} patient={patient} />}
      data-cy="member-brand"
      onCancel={reset}
      onSubmit={submit}
      loadingSubmit={buttonLoading}
      title={translate('personalDetails.brand')}
    >
      <Select
        data-cy="edit-brand-options"
        error={submitted && !currentBrand}
        label={translate('personalDetails.brand')}
        note={translate('personalDetails.warningBrand')}
        onChange={setBrand}
        items={brands}
        value={currentBrand}
      />
    </BaseModal>
  );
};

export default EditBrand;
