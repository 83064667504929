import { MemberDashboardEncounter, SkilledItem } from '.';
import { make, makeArray } from './Model';
import { TOCDetailMember } from './TOCDetailMember';

export class SkilledDetail {
  encounters: MemberDashboardEncounter[];

  memberDetail: TOCDetailMember;

  skilledRecord: SkilledItem;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.encounters = makeArray(obj.encounters, MemberDashboardEncounter);
    this.memberDetail = make(obj.memberDetail, TOCDetailMember);
    this.skilledRecord = make(obj.skilledRecord, SkilledItem);
  }
}

export default SkilledDetail;
