import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';
import { Fonts } from 'styleguide-v2/src/styles/Variables';
import { Switch } from './Switch';

const useStyles = makeStyles({
  container: {
    width: '100%',
  },
  title: {
    fontSize: `calc(${Fonts.fontSize} * .75)`,
    fontWeight: 700,
    marginBottom: '1rem',
    '& :last-child': {
      marginRight: '1rem',
    },
  },
  row: {
    display: 'flex',
    width: '100%',
    '& :first-child': {
      flex: '1',
    },
  },
  label: {
    margin: 'auto',
  },
});

export type SwitchItem = {
  checked?: boolean;
  disabled?: boolean;
  label: string | ReactNode;
  value?: number | string;
};

type Props = {
  className?: string;
  'data-cy'?: string;
  disabled?: boolean;
  items: SwitchItem[];
  onChange: (items: SwitchItem[]) => void;
  title?: [string, string];
};

export const SwitchGroup = ({
  className,
  'data-cy': dataCy,
  disabled,
  items,
  onChange,
  title,
}: Props) => {
  const styles = useStyles();
  const [currentItems, setItems] = useState(items);

  useEffect(() => {
    setItems(items);
  }, [items]);

  const handleChange = (value: boolean, index: number) => {
    currentItems[index].checked = value;
    setItems([...currentItems]);
    onChange([...currentItems]);
  };

  return (
    <div className={classnames(className, styles.container)} data-cy={dataCy}>
      {title && (
        <div className={classnames(styles.row, styles.title)}>
          <span>{title[0]}</span>
          <span>{title[1]}</span>
        </div>
      )}
      {items.map((item, index) => (
        <div className={styles.row} key={`switch-group-${index}`}>
          <span className={styles.label}>{item.label}</span>
          <Switch
            checked={item.checked}
            data-cy={`${dataCy}-${index}`}
            disabled={disabled || item.disabled}
            onChange={(value) => handleChange(value, index)}
          />
        </div>
      ))}
    </div>
  );
};
