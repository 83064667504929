import { Moment } from 'moment';

import { makeUnix } from '../Model';
import { MemberWarning } from './MemberWarning';

export class MemberWarningRPM extends MemberWarning {
  currentCycleStart?: Moment;

  currentCycleEnd?: Moment;

  totalReadings = 0;

  remainingReadings = 0;

  adherenceTrend = 0;

  completedCycles = 0;

  totalCycles = 0;

  lastReadingReadAt?: Moment;

  constructor(obj: any) {
    super(obj);
    Object.assign(this, obj);
    this.currentCycleStart = makeUnix(obj.currentCycleStart);
    this.currentCycleEnd = makeUnix(obj.currentCycleEnd);
    this.lastReadingReadAt = makeUnix(obj.lastReadingReadAt);
  }
}

export default MemberWarningRPM;
