import React, { useEffect, useState } from 'react';

import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { EventModel } from '@vestahealthcare/common/models';

import { Button, CollapsableSidebar, Select, SwitchGroup } from 'styleguide-v2';

import { UpdateEventParams } from 'dash/src/services/EventServices';

type Props = {
  event?: EventModel;
  open: boolean;
  onClose: () => void;
  onSubmit: (id: number, params: UpdateEventParams) => Promise<boolean>;
};

const MEMBER_EVENT_URGENCY_LEVEL = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
];

export const MemberModifyUrgencyModal = ({
  event,
  open,
  onClose,
  onSubmit,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [urgent, setUrgent] = useState<boolean>(false);
  const [urgencyLevel, setUrgencyLevel] = useState<Selectable>();

  useEffect(() => {
    reset();
  }, [event]);

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  const reset = () => {
    setLoading(false);
    if (event) {
      const { urgent, urgencyLevel } = event;
      setUrgent(urgent);
      setUrgencyLevel(
        MEMBER_EVENT_URGENCY_LEVEL[urgencyLevel ? urgencyLevel - 1 : 2],
      );
    }
  };

  const doSubmit = async () => {
    if (event) {
      setLoading(true);
      try {
        const result = await onSubmit(event.id, {
          urgent,
          urgencyLevel: urgent ? (urgencyLevel?.value as number) : undefined,
        });
        if (result) {
          onClose();
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={<h2>{translate('memberEvents.modifyUrgencyModalTitle')}</h2>}
    >
      <CollapsableSidebar.Body>
        <SwitchGroup
          data-cy="edit-event-urgent"
          items={[{ label: translate('memberEvents.urgent'), checked: urgent }]}
          onChange={(checks) => {
            setUrgent(!!checks[0].checked);
          }}
        />
        {urgent && (
          <Select
            data-cy="edit-event-urgency-level"
            disableClearable
            items={MEMBER_EVENT_URGENCY_LEVEL}
            label={translate('memberEvents.urgencyLevel')}
            onChange={setUrgencyLevel}
            required
            value={urgencyLevel}
          />
        )}
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button color="tertiary" data-cy="edit-event-close" onClick={onClose}>
          {translate('global.cancel')}
        </Button>
        <Button
          color="secondary"
          data-cy="edit-event-urgency-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default MemberModifyUrgencyModal;
