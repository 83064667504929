import React, { useState, useEffect } from 'react';
import { FieldProps } from 'react-jsonschema-form';
import { JSONSchema6 } from 'json-schema';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

interface IScoreData {
  [key: string]: {
    [key: string]: number;
  };
}

interface IScoreSchema extends JSONSchema6 {
  scoreData?: IScoreData;
}

const calculateScore = (
  formData: any,
  scoreData: IScoreData,
): number | undefined => {
  const answeredQuestions = Object.keys(scoreData).filter(
    (key) => !!formData[key],
  );
  const allQuestionsAnswered =
    answeredQuestions.length === Object.keys(scoreData).length;

  if (!allQuestionsAnswered) {
    return undefined;
  }

  return answeredQuestions
    .map((key) => scoreData[key][formData[key]])
    .reduce((curr, prev) => curr + prev, 0);
};

const ScoreField = (props: FieldProps) => {
  const {
    formData: value,
    schema,
    onChange,
    formContext: { formData, readonly },
  } = props;
  const { title, scoreData }: IScoreSchema = schema;

  if (!scoreData) {
    throw new Error('No score data for score field');
  }

  // If readonly we show the stored value.
  // If not use the autocalculated
  const score = readonly ? value : calculateScore(formData, scoreData);

  useEffect(() => {
    // Trigger onChange ONLY if it changes
    if (!readonly && Number(value) !== score) {
      onChange(score);
    }
  });

  // Null, undefined, empty string or non numeric value will trigger EMPTY
  const isEmpty =
    score === null ||
    score === undefined ||
    !String(score).trim().length ||
    Number.isNaN(Number(score));

  return (
    <>
      <label className="control-label">{title}</label>
      <p>{isEmpty ? EMPTY : score}</p>
    </>
  );
};

export default ScoreField;
