import { Enum } from './Enum';

export class CareTeamRosterTaskStatus extends Enum {
  translationKey = 'careTeamRosterTaskStatus';

  static asArray: CareTeamRosterTaskStatus[] = [];

  static byKey: { [name: string]: CareTeamRosterTaskStatus } = {};

  static readonly IN_PROGRESS = new CareTeamRosterTaskStatus('IN_PROGRESS');

  static readonly ERROR = new CareTeamRosterTaskStatus('ERROR');

  static readonly COMPLETED = new CareTeamRosterTaskStatus('COMPLETED');

  constructor(public readonly value: string) {
    super();
    CareTeamRosterTaskStatus.asArray.push(this);
    CareTeamRosterTaskStatus.byKey[value] = this;
  }
}

export default CareTeamRosterTaskStatus;
