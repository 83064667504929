import classNames from 'classnames';
import React, { useMemo } from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  CareTeamMember,
  ClinicalMember,
  LanguagePreference,
  UserPhone,
} from '@vestahealthcare/common/models';
import CCMMemberDetailContact from '@vestahealthcare/common/models/CCMMemberDetailContact';

import { Card, Fonts } from 'styleguide-v2';

import { ContactsInfoTable } from './ContactsInfoTable';

type Props = {
  className?: string;
  data?: CCMMemberDetailContact;
  member?: ClinicalMember;
};

const useStyles = makeStyles({
  container: {
    '&&': {
      borderRadius: '8px',
    },

    display: 'flex',

    '& > div': {
      flex: 1,
      padding: '2.4rem 3.2rem',
    },
  },
  subContainer: {
    display: 'flex',
    flexFlow: 'column',
  },
  title: {
    alignItems: 'center',
    display: 'flex',

    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontWeight: 500,
      margin: 0,
    },
  },
});

export const ContactsInfoCard = ({ className, data, member }: Props) => {
  const styles = useStyles();

  if (!data?.careTeamMembers?.length && !data?.memberPhones?.length)
    return <></>;

  const memberPhones = useMemo(
    () => [
      {
        fullName: member?.fullName,
        languages: member?.language
          ? [
              new LanguagePreference({
                language: member?.language.value,
                preferred: true,
              }),
            ]
          : [],
        phones: data?.memberPhones?.sort(UserPhone.sortPrimary),
      },
    ],
    [member, data.memberPhones],
  );

  const careTeamPhones = useMemo(
    () =>
      data.careTeamMembers
        ?.filter(({ active }) => active)
        ?.sort(CareTeamMember.sortPreferred)
        ?.map((person) => ({
          fullName: person.careTeamPerson.fullName,
          preferred: person.preferred,
          languages: person.careTeamPerson.languages,
          notes: person.notes,
          phones: person.careTeamPerson.phones?.sort(UserPhone.sortPrimary),
          phi: person.phiAccess,
          subtype: `${person.type.description} - ${person.subtype}`,
        })),
    [data.careTeamMembers],
  );

  return (
    <Card className={classNames(className, styles.container)}>
      <div className={styles.subContainer}>
        <div className="flex spaced">
          <span className={styles.title}>
            {translate('ccm.modals.info.contactInfo')}
          </span>
        </div>
        {!!data?.memberPhones?.length && member && (
          <ContactsInfoTable data={memberPhones} label="MEMBER" />
        )}
        {!!data?.careTeamMembers?.length && (
          <ContactsInfoTable data={careTeamPhones || []} label="CARE TEAM" />
        )}
      </div>
    </Card>
  );
};
