import { makeStyles } from '@material-ui/styles';
import { translate } from '@vestahealthcare/common/i18n';
import { Notification, Patient } from '@vestahealthcare/common/models';
import moment from 'moment';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Text } from 'styleguide';

interface Props {
  data: Notification;
  onEdit: (notification: Notification) => void;
  members?: Patient[];
}

const useStyles = makeStyles({
  titleHeader: {
    display: 'flex',
  },
  title: {
    width: '100%',
  },
  date: {
    marginLeft: 'auto',
    textAlign: 'right',
    width: 90,
  },
  unreadIcon: {
    marginLeft: 'auto',
    backgroundColor: '#fa600f',
    width: 8,
    height: 8,
    borderRadius: '50%',
    content: ' ',
  },
  bodyWrapper: {
    display: 'flex',
  },
  body: {
    width: '98%',
    color: '#aaa',
  },
  divider: {
    borderBottom: '1px solid #ccc',
  },
  marginWrapper: {
    padding: '10px 20px',
  },
  backgroundWrapper: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e7e7e7',
    },
  },
});
const NotificationItem = (props: Props & RouteComponentProps) => {
  const { data, onEdit, members } = props;
  const styles = useStyles();

  const markAsReadAndRedirect = () => {
    if (!data.seen) onEdit(data);
    props.history.push(`/${data.relativeUrl}`);
  };
  const member = members?.find((m) => m.id === data.memberId);
  return (
    <div
      className={styles.backgroundWrapper}
      onClick={() => markAsReadAndRedirect()}
      id={`notification-menu-${data.id}`}
      data-cy={`notification-menu-${data.id}`}
    >
      <div className={styles.marginWrapper}>
        <div className={styles.titleHeader}>
          <Text bold className={styles.title}>
            {data.title}
          </Text>
          <Text small className={styles.date}>
            {moment.unix(data.createdAt).fromNow()}
          </Text>
        </div>
        <div className={styles.bodyWrapper}>
          <div className={styles.body}>
            <Text small inline>
              {data.body}
            </Text>
            {data.memberId && (
              <Text small className={styles.body}>
                {translate('notification.member')} {member?.nameWithId}
              </Text>
            )}
          </div>
          {!data.seen && <span className={styles.unreadIcon} />}
        </div>
      </div>
      <div className={styles.divider} />
    </div>
  );
};

export default withRouter(NotificationItem);
