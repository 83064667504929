import { translate } from '@vestahealthcare/common/i18n';
import TaskNote from '@vestahealthcare/common/models/TaskNote';
import {
  DATE_FORMAT_SHORT_WITH_TIME,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';
import moment from 'moment';
import React from 'react';
import { Header, Section, Text } from 'styleguide';

interface Props {
  taskNote: TaskNote;
}

export const TaskNoteDetail = ({ taskNote }: Props) => {
  return (
    <Section key={`${taskNote.id}`}>
      <Section.Body style={{ padding: 10, margin: '0px 20px' }}>
        <Header columns={12} size="h4">
          {translate('global.note')}
        </Header>
        <div className="grid-wrapper">
          <div className="grid-span-10">
            {taskNote.note || EMPTY}
            {taskNote.taskFieldChanges?.map((changed) => {
              return (
                <Text color="hint" key={changed.id}>
                  {translate('tasks.noteFieldChanged', {
                    field: changed.field?.toString(),
                    value: changed.newValuePretty,
                  })}
                </Text>
              );
            })}
          </div>
          <div className="grid-span-2">
            <Text>{taskNote.createdBy?.fullName}</Text>
            <Text>
              {moment
                .unix(taskNote.createdAt)
                .format(DATE_FORMAT_SHORT_WITH_TIME)}
            </Text>
          </div>
        </div>
      </Section.Body>
    </Section>
  );
};

export default TaskNoteDetail;
