import moment, { Moment } from 'moment';
import React, { useEffect, useReducer } from 'react';
import { Link } from 'react-router-dom';

import { EncounterType, MemberStatus } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { MemberEncountersSummary } from '@vestahealthcare/common/models';
import { DATE_FORMAT, EMPTY } from '@vestahealthcare/common/utils/constants';

import {
  DateTimePicker,
  EmptyState,
  NavItem,
  NavTabs,
  Panel,
  Select,
  Table,
} from 'styleguide';

import { sortBy } from 'lodash';

import { CacheServices } from '../../../services';
import { fetchActiveTimeTrackingRNOwners } from '../../../services/EmployeeServices';
import { fetchMembersEncounterSummaries } from '../../../services/PatientServices';

enum MinuteFilter {
  LT_TWENTY = 'LT_TWENTY',
  TWENTY_THIRTYNINE = 'TWENTY_THIRTYNINE',
  FORTY_FIFTYNINE = 'FORTY_FIFTYNINE',
  GTE_SIXTY = 'GTE_SIXTY',
}

const SelectableMinuteFilter = [
  { value: MinuteFilter.LT_TWENTY, label: '< 20' },
  { value: MinuteFilter.TWENTY_THIRTYNINE, label: '20 - 39' },
  { value: MinuteFilter.FORTY_FIFTYNINE, label: '40 - 59' },
  { value: MinuteFilter.GTE_SIXTY, label: '60+' },
];

const totalMinsFilterToMinMax = (filter?: MinuteFilter) => {
  switch (filter) {
    case MinuteFilter.LT_TWENTY:
      return { minTotalDuration: 0, maxTotalDuration: 19 };
    case MinuteFilter.TWENTY_THIRTYNINE:
      return { minTotalDuration: 20, maxTotalDuration: 39 };
    case MinuteFilter.FORTY_FIFTYNINE:
      return { minTotalDuration: 40, maxTotalDuration: 59 };
    case MinuteFilter.GTE_SIXTY:
      return { minTotalDuration: 60 };
    default:
      return { minTotalDuration: 0 };
  }
};

enum ProviderMinuteFilter {
  LT_FIFTEEN = 'LT_FIFTEEN',
}

const SelectableProviderMinuteFilter = [
  { value: ProviderMinuteFilter.LT_FIFTEEN, label: '< 15' },
];

const providerMinsFilterToMinMax = (filter?: ProviderMinuteFilter) => {
  switch (filter) {
    case ProviderMinuteFilter.LT_FIFTEEN:
      return { minProviderDuration: 0, maxProviderDuration: 14 };
    default:
      return { minProviderDuration: 0 };
  }
};

const ROWS_PER_PAGE = 25;

// Types shown as filter
const MANAGED_TYPES = [EncounterType.ACP, EncounterType.CCM, EncounterType.RPM];

interface State {
  isLoading: boolean;
  activeRNs?: Selectable[];
  activeNPs?: Selectable[];
  rnOwnerId?: number;
  npOwnerId?: number;
  memberSummaries?: MemberEncountersSummary[];
  type: EncounterType;
  memberStatus?: MemberStatus;
  totalMinsFilter?: MinuteFilter;
  providerMinsFilter?: ProviderMinuteFilter;
  startDate?: Moment;
  endDate?: Moment;
  offset: number;
  totalItems: number;
}

const initialState: State = {
  isLoading: true,
  type: EncounterType.RPM,
  startDate: moment().subtract(30, 'days'),
  endDate: moment(),
  offset: 0,
  totalItems: 0,
};

enum Action {
  LOADING,
  LOADING_COMPLETE,
  INIT_EMPLOYEES,
  UPDATE_ENCOUNTER_TYPE,
  UPDATE_FILTER,
  NEXT_PAGE,
  PREVIOUS_PAGE,
}

const reducer = (
  state: State,
  action: { type: Action; payload?: Partial<State> },
): State => {
  const { type, payload } = action;
  switch (type) {
    case Action.LOADING:
      return { ...state, isLoading: true };
    case Action.LOADING_COMPLETE:
      return {
        ...state,
        ...payload,
        isLoading: false,
      };
    case Action.INIT_EMPLOYEES:
      return { ...state, ...payload };
    case Action.UPDATE_ENCOUNTER_TYPE:
      return {
        ...state,
        ...payload,
        providerMinsFilter: undefined,
        offset: 0,
      };
    case Action.UPDATE_FILTER:
      return { ...state, ...payload, offset: 0 };
    case Action.NEXT_PAGE:
      return { ...state, offset: state.offset + ROWS_PER_PAGE };
    case Action.PREVIOUS_PAGE:
      return { ...state, offset: state.offset - ROWS_PER_PAGE };
    default:
      throw new Error();
  }
};

export const MemberTimeTrackingDashboard = () => {
  const [
    {
      isLoading,
      activeRNs,
      activeNPs,
      rnOwnerId,
      npOwnerId,
      memberSummaries,
      totalItems,
      type,
      totalMinsFilter,
      providerMinsFilter,
      startDate,
      endDate,
      memberStatus,
      offset,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchEmployees = async () => {
      const [rns, nps] = await Promise.all([
        fetchActiveTimeTrackingRNOwners(),
        CacheServices.getNPs(),
      ]);

      const activeRNs = sortBy(
        rns.map(({ fullName, id }) => ({
          label: fullName,
          value: `${id}`,
        })),
        'label',
      );
      const activeNPs = sortBy(
        nps.map(({ fullName, id }) => ({
          label: fullName,
          value: `${id}`,
        })),
        'label',
      );
      activeRNs.push({ label: translate('global.unassigned'), value: '0' });
      activeNPs.push({ label: translate('global.unassigned'), value: '0' });

      dispatch({
        type: Action.INIT_EMPLOYEES,
        payload: { activeRNs, activeNPs },
      });
    };
    fetchEmployees();
  }, []);

  useEffect(() => {
    const fetchSummaries = async () => {
      dispatch({ type: Action.LOADING });
      const { minTotalDuration, maxTotalDuration } = totalMinsFilterToMinMax(
        totalMinsFilter,
      );
      const {
        minProviderDuration,
        maxProviderDuration,
      } = providerMinsFilterToMinMax(providerMinsFilter);

      const { items, pagination } = await fetchMembersEncounterSummaries({
        rnOwnerId,
        npOwnerId,
        type: [type],
        minTotalDuration,
        minProviderDuration,
        maxTotalDuration,
        maxProviderDuration,
        startDate: startDate?.format(DATE_FORMAT),
        endDate: endDate?.format(DATE_FORMAT),
        memberStatus,
        offset,
        limit: ROWS_PER_PAGE,
      });
      dispatch({
        type: Action.LOADING_COMPLETE,
        payload: {
          memberSummaries: items,
          totalItems: pagination.total,
        },
      });
    };
    fetchSummaries();
  }, [
    type,
    totalMinsFilter,
    providerMinsFilter,
    startDate,
    endDate,
    rnOwnerId,
    npOwnerId,
    memberStatus,
    offset,
  ]);

  return (
    <div id="page">
      <Panel>
        <Panel.Heading>
          <div className="left-side">
            <h2>{translate('timeTrackingDashboard.header')}</h2>
            <NavTabs style={{ marginLeft: 20 }}>
              {MANAGED_TYPES.map((t) => (
                <NavItem
                  data-cy="rpm-type"
                  active={type === t}
                  key={t.valueOf()}
                  title={t.toString()}
                  onClick={() =>
                    dispatch({
                      type: Action.UPDATE_ENCOUNTER_TYPE,
                      payload: { type: t },
                    })
                  }
                >
                  {t.toString()}
                </NavItem>
              ))}
            </NavTabs>
          </div>
        </Panel.Heading>
        <Panel.Body loading={isLoading}>
          <div
            className="grid-wrapper grid-span-12"
            style={{ marginBottom: 20 }}
          >
            <Select
              data-cy="encouter-member-status"
              label={translate('personalDetails.memberStatus')}
              columns={4}
              placeholder={translate('global.select')}
              onChange={(status: MemberStatus) =>
                dispatch({
                  type: Action.UPDATE_FILTER,
                  payload: { memberStatus: status || undefined },
                })
              }
              options={MemberStatus.toFilterSelectable()}
              value={memberStatus}
            />
            <DateTimePicker
              date
              columns={4}
              label={translate('timeTrackingDashboard.startDate')}
              value={startDate}
              data-cy="encounter-start-date"
              placeholder={translate('assessment.datePlaceholder')}
              onChange={(date) =>
                dispatch({
                  type: Action.UPDATE_FILTER,
                  payload: { startDate: date },
                })
              }
            />
            <DateTimePicker
              data-cy="encounter-end-date"
              date
              columns={4}
              label={translate('timeTrackingDashboard.endDate')}
              value={endDate}
              placeholder={translate('assessment.datePlaceholder')}
              onChange={(date) =>
                dispatch({
                  type: Action.UPDATE_FILTER,
                  payload: { endDate: date },
                })
              }
            />
            <Select
              data-cy="encounter-rn-owner"
              label={translate('personalDetails.rnOwner')}
              columns={4}
              placeholder={translate('global.select')}
              onChange={(id: number) =>
                dispatch({
                  type: Action.UPDATE_FILTER,
                  payload: { rnOwnerId: id },
                })
              }
              options={activeRNs || []}
              value={rnOwnerId}
            />
            <Select
              data-cy="encounter-np-owner"
              label={translate('personalDetails.npOwner')}
              columns={4}
              placeholder={translate('global.select')}
              onChange={(id: number) =>
                dispatch({
                  type: Action.UPDATE_FILTER,
                  payload: { npOwnerId: id },
                })
              }
              options={activeNPs || []}
              value={npOwnerId}
            />
            <Select
              data-cy="encounter-total-minutes"
              label={translate('encounters.totalMinutes')}
              columns={4}
              placeholder={translate('global.select')}
              onChange={(filter: MinuteFilter) =>
                dispatch({
                  type: Action.UPDATE_FILTER,
                  payload: { totalMinsFilter: filter },
                })
              }
              options={SelectableMinuteFilter}
              value={totalMinsFilter}
            />
            {type === EncounterType.CCM && (
              <Select
                data-cy="encounter-provider-minutes"
                label={translate('encounters.providerMinutes')}
                columns={4}
                placeholder={translate('global.select')}
                onChange={(filter: ProviderMinuteFilter) =>
                  dispatch({
                    type: Action.UPDATE_FILTER,
                    payload: { providerMinsFilter: filter },
                  })
                }
                options={SelectableProviderMinuteFilter}
                value={providerMinsFilter}
              />
            )}
          </div>
          <Table
            data-cy="members-encounters-summaries-table"
            numPages={Math.ceil(totalItems / ROWS_PER_PAGE)}
            currentPage={offset / ROWS_PER_PAGE + 1}
            onPrev={() => dispatch({ type: Action.PREVIOUS_PAGE })}
            onNext={() => dispatch({ type: Action.NEXT_PAGE })}
          >
            <thead>
              <tr>
                <th>{translate('personalDetails.patientId')}</th>
                <th>{translate('global.name')}</th>
                <th>{translate('personalDetails.rnOwner')}</th>
                <th>{translate('personalDetails.npOwner')}</th>
                <th>{translate('encounters.clinicalMinutes')}</th>
                <th>{translate('encounters.providerMinutes')}</th>
                <th>{translate('encounters.totalMinutes')}</th>
              </tr>
            </thead>
            <tbody>
              {memberSummaries?.map((summary) => (
                <tr key={summary.id}>
                  <td>
                    <Link
                      to={`/patients/${summary.id}`}
                      data-cy="encounter-member-id"
                    >
                      {summary.id}
                    </Link>
                  </td>
                  <td data-cy="encounters-member-name">{summary.fullName}</td>
                  <td data-cy="encounters-rn-name">
                    {summary.owner?.fullName || EMPTY}
                  </td>
                  <td data-cy="encounters-np-name">
                    {summary.npOwner?.fullName || EMPTY}
                  </td>
                  <td data-cy="encounters-clinical-minutes">
                    {summary.encountersClinicalMins}
                  </td>
                  <td data-cy="encounters-provider-minutes">
                    {summary.encountersProviderMins}
                  </td>
                  <td data-cy="encounters-total-minutes">
                    {summary.encountersTotalMins}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <EmptyState show={!memberSummaries?.length && !isLoading}>
            {translate('timeTrackingDashboard.empty')}
          </EmptyState>
        </Panel.Body>
      </Panel>
    </div>
  );
};

export default MemberTimeTrackingDashboard;
