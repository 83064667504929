import { MemberWarning } from './MemberWarning';

export class MemberWarningTime extends MemberWarning {
  minutes = 0;

  constructor(obj: any) {
    super(obj);
    Object.assign(this, obj);
  }
}

export default MemberWarningTime;
