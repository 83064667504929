import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { CommunityResource } from '@vestahealthcare/common/models';
import { isValidPhone } from '@vestahealthcare/common/utils/format';
import { formatPhone } from '@vestahealthcare/common/utils/phone';

import { Button, Fonts, PhoneInput, TextInput } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { fetchCommunityResources } from 'dash/src/services/CommunityResourcesServices';

import { SearchTable } from '../../components/SearchTable';

type Props = {
  disabledCRs?: CommunityResource[];
  onSubmit: (cr: CommunityResource) => void;
};

const useStyles = makeStyles({
  container: {},
  searchButton: {
    alignSelf: 'end',
    height: 'fit-content',
    marginBottom: '0.25rem',
    width: '9rem',
  },
  addButon: {
    height: 'fit-content',
    width: 'fit-content',
  },
  separator: {
    margin: 'auto',
    width: '99%',
  },
  subtitle: {
    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: Fonts.fontSize,
      fontWeight: 500,
    },
  },
});

export const SearchCommunityReourceStep = ({
  disabledCRs,
  onSubmit,
}: Props) => {
  const styles = useStyles();

  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [searched, setSearched] = useState<boolean>(false);
  const [crs, setCRs] = useState<CommunityResource[]>();

  const [name, setName] = useState<string | undefined>();
  const [phone, setPhone] = useState<string | undefined>();
  const [searchText, setSearchText] = useState<string | undefined>();

  const handleSearch = async () => {
    if (!name && !phone) return;
    setLoadingSearch(true);

    try {
      const { items } = await fetchCommunityResources({
        name,
        phone,
        limit: 25,
      });
      setSearchText(name || formatPhone(phone || ''));
      setCRs(items);
    } catch (e) {
      showGlobalError(e as string);
    }

    setLoadingSearch(false);
    setSearched(true);
  };

  const createNewCR = () => {
    onSubmit(
      new CommunityResource({
        name,
        interventions: [],
        specialities: [],
        phone,
      }),
    );
  };

  useEffect(() => {
    setSearchText(undefined);
  }, []);

  return (
    <div className={classNames(styles.container, 'grid-wrapper fit')}>
      <span className="grid-span-12">
        {translate('communityResources.steps.searchCommunityResource')}
      </span>
      <TextInput
        className="grid-span-5"
        data-cy="cr-steps-name"
        label={translate('communityResources.modal.name')}
        onChange={(val) => setName(val?.trim())}
        value={name}
      />
      <PhoneInput
        className="grid-span-5"
        data-cy="cr-steps-phone"
        label={translate('communityResources.modal.phoneNumber')}
        onChange={(val) => setPhone(val?.trim())}
        value={phone}
      />
      <Button
        className={classNames(styles.searchButton, 'grid-span-2')}
        color="secondary"
        data-cy="cr-steps-search"
        disabled={!name && (!phone || !isValidPhone(phone))}
        loading={loadingSearch}
        icon={<SearchIcon />}
        onClick={handleSearch}
      >
        {translate('global.search')}
      </Button>

      {searched && (
        <>
          <br />
          <hr className={classNames(styles.separator, 'grid-span-12')} />
          <br />
          {crs?.length ? (
            <div className="grid-span-12">
              <p>
                {translate('communityResources.steps.showingResultsFor')}
                <b>{` "${searchText}"`}</b>:
              </p>
              <SearchTable
                disabled={disabledCRs?.map(({ id }) => id)}
                crs={crs}
                onSelect={onSubmit}
              />
              <br />
              <h4 className={styles.subtitle}>
                {translate('communityResources.steps.notAMatch')}
              </h4>
              <p>
                {translate(
                  'communityResources.steps.noCommunityResourcesFoundBody',
                )}
              </p>
              <br />
              <Button
                className={classNames(styles.addButon, 'grid-span-12')}
                color="secondary"
                type="outlined"
                onClick={createNewCR}
              >
                {translate(
                  'communityResources.steps.noCommunityResourcesFoundButton',
                )}
              </Button>
            </div>
          ) : (
            <div className="grid-span-12">
              <h4 className={styles.subtitle}>
                {translate(
                  'communityResources.steps.noCommunityResourcesFoundTitle',
                )}
              </h4>
              <p>
                {translate(
                  'communityResources.steps.noCommunityResourcesFoundBody',
                )}
              </p>
              <br />
              <Button
                className={classNames(styles.addButon, 'grid-span-12')}
                color="secondary"
                type="outlined"
                onClick={createNewCR}
              >
                {translate(
                  'communityResources.steps.noCommunityResourcesFoundButton',
                )}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
