import { Enum } from './Enum';

export class AppointmentStatus extends Enum {
  translationKey = 'appointmentStatus';

  static asArray: AppointmentStatus[] = [];

  static byKey: { [name: string]: AppointmentStatus } = {};

  static readonly COMP = new AppointmentStatus('COMP');

  static readonly KEPT = new AppointmentStatus('KEPT');

  static readonly PEN = new AppointmentStatus('PEN');

  static readonly N_S = new AppointmentStatus('N/S');

  static readonly R_S = new AppointmentStatus('R/S');

  constructor(public readonly value: string) {
    super();
    AppointmentStatus.asArray.push(this);
    AppointmentStatus.byKey[value] = this;
  }
}

export default AppointmentStatus;
