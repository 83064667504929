import { MemberContactMethodType } from '../enums';

export class MemberContactInfoMethod {
  contactMethod: MemberContactMethodType;

  contactMethodOther?: string;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.contactMethod = MemberContactMethodType.byKey[obj.contactMethod];
  }
}

export default MemberContactInfoMethod;
