import { BiometricsType, CollectionMethod } from '../enums';

export class Measurement {
  id = 0;

  channel: CollectionMethod;

  createdAt = 0;

  readAt = 0;

  type: BiometricsType;

  value = 0;

  valid = false;

  abnormal = false;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.channel = CollectionMethod.byKey[obj.channel];
    this.type = BiometricsType.byKey[obj.type];
  }
}
