import { EncounterType } from '.';
import { Enum } from './Enum';

export class EncounterChannel extends Enum {
  translationKey = 'encounterChannel';

  static asArray: EncounterChannel[] = [];

  static byKey: { [name: string]: EncounterChannel } = {};

  static readonly APPOINTMENT_SCHEDULING = new EncounterChannel(
    'APPOINTMENT_SCHEDULING',
  );

  static readonly BLOOD_OXYGEN = new EncounterChannel('BLOOD_OXYGEN', true);

  static readonly BLOOD_PRESSURE = new EncounterChannel('BLOOD_PRESSURE', true);

  static readonly BLOOD_SUGAR = new EncounterChannel('BLOOD_SUGAR', true);

  static readonly CAREGIVER_SUPPORT_NEED = new EncounterChannel(
    'CAREGIVER_SUPPORT_NEED',
  );

  static readonly CARE_COORDINATION = new EncounterChannel('CARE_COORDINATION');

  static readonly CHANGE_MEMBER_CONDITION = new EncounterChannel(
    'CHANGE_MEMBER_CONDITION',
  );

  static readonly CCM_VISIT = new EncounterChannel('CCM_VISIT');

  static readonly COMMUNITY_PARAMEDICINE = new EncounterChannel(
    'COMMUNITY_PARAMEDICINE',
  );

  static readonly DME_NEED = new EncounterChannel('DME_NEED');

  static readonly ENGAGEMENT = new EncounterChannel('ENGAGEMENT');

  static readonly ENGAGEMENT_CALL = new EncounterChannel(
    'ENGAGEMENT_CALL',
    true,
  );

  static readonly EVENT = new EncounterChannel('EVENT');

  static readonly EVENT_FOLLOW_UP = new EncounterChannel('EVENT_FOLLOW_UP');

  static readonly FALL = new EncounterChannel('FALL');

  static readonly FOOD_INSECURITY = new EncounterChannel('FOOD_INSECURITY');

  static readonly HEART_RATE = new EncounterChannel('HEART_RATE');

  static readonly HCI = new EncounterChannel('HCI');

  static readonly IMMUNIZATIONS = new EncounterChannel('IMMUNIZATIONS');

  static readonly INSURANCE_BILLING_CALL = new EncounterChannel(
    'INSURANCE_BILLING_CALL',
    true,
  );

  static readonly INSURANCE_BILLING = new EncounterChannel('INSURANCE_BILLING');

  static readonly MEDICATION_NEEDS = new EncounterChannel('MEDICATION_NEEDS');

  static readonly MEMBER_CG_ONBOARDING = new EncounterChannel(
    'MEMBER_CG_ONBOARDING',
  );

  static readonly NURSE_HOME_VISIT = new EncounterChannel('NURSE_HOME_VISIT');

  static readonly RPM_ADHERENCE = new EncounterChannel('RPM_ADHERENCE');

  static readonly RPM_TECH_ISSUE = new EncounterChannel('RPM_TECH_ISSUE');

  static readonly RPM_TRAINING = new EncounterChannel('RPM_TRAINING');

  static readonly SUPPLIES = new EncounterChannel('SUPPLIES');

  static readonly TECH_ISSUE = new EncounterChannel('TECH_ISSUE');

  static readonly TEMPERATURE = new EncounterChannel('TEMPERATURE', true);

  static readonly TRANSPORTATION = new EncounterChannel('TRANSPORTATION');

  static readonly WEIGHT = new EncounterChannel('WEIGHT', true);

  static readonly WOUND_CARE_RN = new EncounterChannel('WOUND_CARE_RN');

  static readonly OTHER = new EncounterChannel('OTHER');

  static toSelectable() {
    return Enum.toSelectable(EncounterChannel.asArray);
  }

  static fromEncounterType(type?: EncounterType) {
    switch (type) {
      case EncounterType.CCM:
        return Enum.toSelectable([
          EncounterChannel.CCM_VISIT,
          EncounterChannel.OTHER,
        ]);
      case EncounterType.RPM:
        return Enum.toSelectable([
          EncounterChannel.EVENT,
          EncounterChannel.RPM_ADHERENCE,
          EncounterChannel.OTHER,
        ]);
      case EncounterType.ENGAGEMENT:
        return Enum.toSelectable([
          EncounterChannel.MEMBER_CG_ONBOARDING,
          EncounterChannel.HCI,
          EncounterChannel.ENGAGEMENT,
          EncounterChannel.INSURANCE_BILLING,
          EncounterChannel.RPM_TRAINING,
          EncounterChannel.RPM_TECH_ISSUE,
          EncounterChannel.TECH_ISSUE,
          EncounterChannel.OTHER,
        ]);
      case EncounterType.CLINICAL:
        return Enum.toSelectable([
          EncounterChannel.CHANGE_MEMBER_CONDITION,
          EncounterChannel.CARE_COORDINATION,
          EncounterChannel.EVENT_FOLLOW_UP,
          EncounterChannel.FOOD_INSECURITY,
          EncounterChannel.FALL,
          EncounterChannel.CAREGIVER_SUPPORT_NEED,
          EncounterChannel.DME_NEED,
          EncounterChannel.SUPPLIES,
          EncounterChannel.MEDICATION_NEEDS,
          EncounterChannel.NURSE_HOME_VISIT,
          EncounterChannel.APPOINTMENT_SCHEDULING,
          EncounterChannel.TRANSPORTATION,
          EncounterChannel.IMMUNIZATIONS,
          EncounterChannel.WOUND_CARE_RN,
          EncounterChannel.COMMUNITY_PARAMEDICINE,
          EncounterChannel.OTHER,
        ]);
      default:
        return [];
    }
  }

  constructor(
    public readonly value: string,
    public readonly deprecated: boolean = false,
  ) {
    super();
    EncounterChannel.asArray.push(this);
    EncounterChannel.byKey[value] = this;
  }

  get isOther() {
    return this === EncounterChannel.OTHER;
  }
}

export default EncounterChannel;
