import { make, makeArray } from './Model';
import { EngagementAudience } from './EngagementAudience';
import { EngagementContentItem } from './EngagementContentItem';
import { EngagementFileType } from './EngagementFileType';
import { EngagementContentBase } from './EngagementContentBase';
import { BaseEnum } from './BaseEnum';

export class EngagementContent extends EngagementContentBase {
  contentType: BaseEnum;

  fileType: EngagementFileType;

  active = false;

  external = false;

  tags: EngagementAudience[];

  contentItems: EngagementContentItem[];

  constructor(obj: any) {
    super(obj);
    Object.assign(this, obj);
    this.tags = makeArray(obj.tags, EngagementAudience);
    this.contentType = make(obj.contentType, BaseEnum);
    this.fileType = make(obj.fileType, EngagementFileType);
    this.contentItems = makeArray(obj.contentItems, EngagementContentItem)
      .filter(({ enabled }: EngagementContentItem) => enabled)
      .sort(
        (
          { language: a }: EngagementContentItem,
          { language: b }: EngagementContentItem,
        ) => a.toString().localeCompare(b.toString()),
      );
  }
}

export default EngagementContent;
