import React, { useState } from 'react';
import { Section, Table, Icon } from 'styleguide';
import { translate } from '@vestahealthcare/common/i18n';
import { MemberGoal, Patient } from '@vestahealthcare/common/models';
import { GoalTips } from './GoalTips';
import { Goals } from './Goals';
import { MemberStrengthsBarriers } from './MemberStrengthsBarriers';

interface Props {
  goals?: MemberGoal[];
  member?: Patient;
  onSubmit: () => void;
}

export const GoalsOfCare = ({ goals, member, onSubmit }: Props) => {
  const [collapsed, setCollapsed] = useState(true);

  if (!goals || !member) {
    return null;
  }

  const hasGoals = !!goals.length;

  return (
    <article>
      <header>
        <div className="h7">{translate('goalsOfCare.goalsOfCare')}</div>
      </header>
      <Section data-cy="goals-of-care" collapsed={collapsed}>
        <Section.Heading
          data-cy="goals-collapse"
          onClick={() => setCollapsed(!collapsed)}
        >
          <Table className="section-style">
            <thead>
              <tr>
                <th>
                  <span className="h3">
                    {translate('goalsOfCare.goalsOfCare')}
                  </span>
                </th>
                <th>
                  <span className="h3">
                    {hasGoals ? (
                      translate('goalsOfCare.setGoals', {
                        numGoals: goals.length,
                      })
                    ) : (
                      <span className="margin-left normal color-gold">
                        <Icon className="fa fa-warning margin-right" />
                        {translate('goalsOfCare.noGoals')}
                      </span>
                    )}
                  </span>
                </th>
                <th className="text-right">
                  {hasGoals && (
                    <span className="small" data-cy="care-track-updated-stamp">
                      {translate('global.lastUpdated', {
                        date: goals[0].getUpdatedAtFormatted(),
                      })}
                      {' | '}
                      {goals[0].updatedBy && goals[0].updatedBy.fullName}
                    </span>
                  )}
                </th>
              </tr>
            </thead>
          </Table>
        </Section.Heading>
        <Section.Body>
          <article className="grid-wrapper">
            <GoalTips />
            <Goals goals={goals} member={member} onSubmit={onSubmit} />
            <MemberStrengthsBarriers member={member} onSubmit={onSubmit} />
          </article>
        </Section.Body>
      </Section>
    </article>
  );
};

export default GoalsOfCare;
