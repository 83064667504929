import { GlobalActions, timerTick } from 'dash/src/redux/actions/GlobalActions';
import store from 'dash/src/redux/store';

const COUNTDOWN_INTERVAL = 1000;
const initTimeRemaining = 20;

const initMessagePayload = {
  isOpen: false,
};

const initState = {
  activeTimer: null,
  hasConnection: true,
  messagePayload: initMessagePayload,
  timeRemaining: initTimeRemaining,
  showUserChangedWarning: false,
  conflictDataWarningMessage: null,
};

const getNewTime = (currTime: number) => {
  let newTime = currTime - 1;
  if (newTime < 0) {
    newTime = initTimeRemaining;
  }

  return newTime;
};

// if we already have a timer, return it.  Else, start a new timer
const getTimer = (activeTimer: number) =>
  activeTimer ||
  window.setInterval(() => {
    store.dispatch(timerTick());
  }, COUNTDOWN_INTERVAL);

const globalReducer = (state: any = initState, action: any) => {
  switch (action.type) {
    case GlobalActions.BROADCAST_MESSAGE:
      return { ...state, messagePayload: action.messagePayload };
    case GlobalActions.CLOSE_MESSAGE:
      return { ...state, messagePayload: initMessagePayload };
    case GlobalActions.SET_CONNECTION:
      return { ...state, hasConnection: action.hasConnection };
    case GlobalActions.SET_UPDATE_AVAILABLE:
      return { ...state, updateAvailable: action.updateAvailable };
    case GlobalActions.STOP_TIMER:
      window.clearInterval(state.activeTimer);
      return { ...state, timeRemaining: initTimeRemaining, activeTimer: null };
    case GlobalActions.START_TIMER:
      return { ...state, activeTimer: getTimer(state.activeTimer) };
    case GlobalActions.TIMER_TICK:
      return { ...state, timeRemaining: getNewTime(state.timeRemaining) };
    case GlobalActions.USER_CHANGED_WARNING:
      return { ...state, showUserChangedWarning: true };
    case GlobalActions.SET_CONFLICTING_DATA_WARNING:
      return { ...state, conflictingDataWarningMessage: action.message };
    default:
      return state;
  }
};

export default globalReducer;
