import { Patient } from './Patient';

export class MemberEncountersSummary extends Patient {
  encountersClinicalMins = 0;

  encountersProviderMins = 0;

  encountersTotalMins = 0;

  constructor(obj: any) {
    super(obj);
    Object.assign(this, obj);
  }
}

export default MemberEncountersSummary;
