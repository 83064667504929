import moment, { Moment } from 'moment';
import { DATE_FORMAT } from '../utils/constants';

export class MemberMedication {
  name = '';

  directions = '';

  duration = '';

  dispense = '';

  refills = '';

  source = '';

  action = '';

  updatedAt?: Moment;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.updatedAt = obj.updatedAt
      ? moment(obj.updatedAt, DATE_FORMAT)
      : undefined;
  }
}

export default MemberMedication;
