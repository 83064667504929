import { Moment } from 'moment';

import {
  Brand,
  Organization,
  OrganizationStatuses,
  OrganizationType,
} from '@vestahealthcare/common/models';
import { DATE_FORMAT } from '@vestahealthcare/common/utils/constants';

import Api from 'dash/src/services/Api';

export type OrganizationParams = {
  abbr?: string;
  affiliation?: Organization;
  brand?: string;
  emails?: string[];
  endDate?: Moment;
  incidentAuto?: boolean;
  incidentCareManagers?: boolean;
  medicareDiscovery?: boolean;
  name?: string;
  rpmOutsourcing?: boolean;
  startDate?: Moment;
  status?: OrganizationStatuses;
  type?: OrganizationType;
};

export interface OrganizationFetchParams {
  name?: string;
  ids?: number[];
  sort?: string;
  offset?: number;
  limit?: number;
}

export const fetchOrganizations = async (
  params?: OrganizationFetchParams,
): Promise<Organization[]> => {
  const {
    organizations: { items },
  } = await Api.getv2('organizations', params);
  return items.map((item: any) => new Organization(item));
};

export const fetchOrganizationTypes = async (
  params?: OrganizationFetchParams,
): Promise<OrganizationType[]> => {
  const {
    organizationTypes: { items },
  } = await Api.getv2('organization-types', params);
  return items.map((item: any) => new OrganizationType(item));
};

export const fetchOrganizationStatuses = async (
  params?: OrganizationFetchParams,
): Promise<OrganizationStatuses[]> => {
  const {
    organizationStatuses: { items },
  } = await Api.getv2('organization-statuses', params);
  return items.map((item: any) => new OrganizationStatuses(item));
};

export const addOrganization = async (params: OrganizationParams) => {
  const transformedParams = {
    abbr: params.abbr,
    brandId: params.brand,
    emails: params.emails,
    endDate: params.endDate?.format(DATE_FORMAT),
    incidentAuto: params.incidentAuto,
    incidentCareManagers: params.incidentCareManagers,
    medicareDiscovery: params.medicareDiscovery,
    name: params.name,
    parentId: params.affiliation?.id,
    rpmOutsourcing: params.rpmOutsourcing,
    startDate: params.startDate?.format(DATE_FORMAT),
    statusId: params.status?.id,
    typeId: params.type?.id,
  };
  return Api.postv2JSON(`organizations`, transformedParams);
};

export const editOrganization = async (
  organizationId: number,
  params: OrganizationParams,
) => {
  const transformedParams = {
    abbr: params.abbr,
    brandId: params.brand,
    emails: params.emails,
    endDate: params.endDate?.format(DATE_FORMAT),
    incidentAuto: params.incidentAuto,
    incidentCareManagers: params.incidentCareManagers,
    medicareDiscovery: params.medicareDiscovery,
    name: params.name,
    parentId: params.affiliation?.id,
    rpmOutsourcing: params.rpmOutsourcing,
    startDate: params.startDate?.format(DATE_FORMAT),
    statusId: params.status?.id,
    typeId: params.type?.id,
  };
  return Api.putv2JSON(`organizations/${organizationId}`, transformedParams);
};

export const fetchOrganizationBrands = async (): Promise<Brand[]> => {
  const {
    brands: { items },
  } = await Api.getv2('brands', {
    sort: 'id asc',
  });
  return items?.map((et: any) => new Brand(et));
};
