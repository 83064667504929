import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Language } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { CampaignModal } from '@vestahealthcare/common/models';

import {
  Button,
  Checkbox,
  ImageSelect,
  Panel,
  Select,
  TextInput,
} from 'styleguide';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import {
  createCampaignBanner,
  editCampaignBanner,
  fetchCampaignBanner,
  fetchCampaignModals,
} from 'dash/src/services/CampaignServices';

import ImageOptions from './ImageOptions';

type Params = {
  bannerId: string;
};

const getButtonCopyOptions = () => [
  {
    value: 'LEARN_MORE',
    label: translate('campaigns.learnMore'),
  },
  {
    value: 'LEARN_WHY',
    label: translate('campaigns.learnWhy'),
  },
  {
    value: 'LEARN_HOW',
    label: translate('campaigns.learnHow'),
  },
];

export const EditCampaignBanner = withRouter(
  (props: RouteComponentProps<Params>) => {
    const {
      history,
      match: {
        params: { bannerId },
      },
    } = props;
    const isNew = !bannerId;

    const [loading, setLoading] = useState(true);
    const [showError, setShowError] = useState(false);
    const [allModals, setAllModals] = useState<CampaignModal[]>([]);
    const [name, setName] = useState<string>();
    const [messageEnglish, setMessageEnglish] = useState<string>();
    const [messageSpanish, setMessageSpanish] = useState<string>();
    const [messageRussian, setMessageRussian] = useState<string>();
    const [messageChineseTraditional, setMessageChineseTraditional] = useState<
      string
    >();
    const [image, setImage] = useState<string>();
    const [hasModal, setHasModal] = useState(false);
    const [selectedModal, setSelectedModal] = useState<number>();
    const [buttonCopy, setButtonCopy] = useState<string>();

    const fetchData = async () => {
      setLoading(true);

      const modals = await fetchCampaignModals();
      setAllModals(modals);

      if (bannerId) {
        const banner = await fetchCampaignBanner(bannerId);

        setName(banner.name);
        setMessageEnglish(banner.getTranslation(Language.ENGLISH).content);
        setMessageSpanish(banner.getTranslation(Language.SPANISH).content);
        setMessageRussian(banner.getTranslation(Language.RUSSIAN).content);
        setMessageChineseTraditional(
          banner.getTranslation(Language.CHINESE_TRADITIONAL).content,
        );
        setImage(banner.image);
        setHasModal(!!banner.modal);
        setSelectedModal(banner.modal?.id);
        setButtonCopy(banner.ctaLabel);
      }

      setLoading(false);
    };

    useEffect(() => {
      fetchData();
    }, [bannerId]);

    const onSave = async () => {
      setShowError(false);

      // NOTE: If these variables aren't explicitly checked in this clause,
      // TS will complain that the params have an incompatible type.
      // Prevents factoring this check out into its own const or function.
      if (!(name && messageEnglish?.trim() && image)) {
        setShowError(true);
      } else {
        setLoading(true);
        const params = {
          name,
          image,
          translations: [
            // Backend will trim and filter automatically empty translations
            { language: Language.ENGLISH.value, content: messageEnglish },
            { language: Language.SPANISH.value, content: messageSpanish },
            { language: Language.RUSSIAN.value, content: messageRussian },
            {
              language: Language.CHINESE_TRADITIONAL.value,
              content: messageChineseTraditional,
            },
          ],
          modal: hasModal ? selectedModal : undefined,
          ctaLabel: hasModal ? buttonCopy : undefined,
        };

        if (isNew) {
          try {
            await createCampaignBanner(params);
            history.push('/admin/campaigns/app-modals');
          } catch (e) {
            setLoading(false);
            showGlobalError(e as string);
          }
        } else {
          try {
            await editCampaignBanner(bannerId, params);
            history.push('/admin/campaigns/app-modals');
          } catch (e) {
            setLoading(false);
            showGlobalError(e as string);
          }
        }
      }
    };

    return (
      <Panel>
        <Panel.Heading title={isNew ? 'Add Banner' : 'Edit Banner'} />
        <Panel.Body loading={loading} className="grid-wrapper">
          <TextInput
            data-cy="add-banner-name"
            className="grid-span-6"
            errorText={translate('global.missingRequiredField')}
            label={translate('campaigns.bannerName')}
            onChange={setName}
            required
            showError={!name && showError}
            value={name}
          />
          <div className="grid-span-12" />
          <p className="medium grid-span-12 no-margin">
            {translate('campaigns.bannerMessage')}
          </p>
          <TextInput
            data-cy="add-banner-message-english"
            className="grid-span-4"
            errorText={translate('global.missingRequiredField')}
            label={Language.ENGLISH.toString()}
            onChange={setMessageEnglish}
            required
            showError={!messageEnglish?.trim() && showError}
            value={messageEnglish}
          />
          <TextInput
            data-cy="add-banner-message-spanish"
            className="grid-span-4"
            label={Language.SPANISH.toString()}
            onChange={setMessageSpanish}
            showError={!messageSpanish && showError}
            value={messageSpanish}
          />
          <TextInput
            data-cy="add-banner-message-russian"
            className="grid-span-4"
            label={Language.RUSSIAN.toString()}
            onChange={setMessageRussian}
            showError={!messageRussian && showError}
            value={messageRussian}
          />
          <TextInput
            data-cy="add-banner-message-chinese-traditional"
            className="grid-span-4"
            label={Language.CHINESE_TRADITIONAL.toString()}
            onChange={setMessageChineseTraditional}
            showError={!messageChineseTraditional && showError}
            value={messageChineseTraditional}
          />
          <ImageSelect
            className="grid-span-12"
            errorText={translate('global.missingRequiredField')}
            label={translate('campaigns.bannerImage')}
            onChange={setImage}
            options={ImageOptions}
            required
            showError={!image && showError}
            value={image}
          />
          <Checkbox
            data-cy="add-modal-to-banner"
            checked={hasModal && !!allModals.length}
            className="grid-span-3"
            disabled={!allModals.length}
            label={translate('campaigns.addModalToBanner')}
            onChange={() => setHasModal(!hasModal)}
          />
          <div className="grid-span-9" />
          {hasModal && (
            <>
              <Select
                data-cy="select-banner-modal"
                className="grid-span-6"
                errorText={translate('global.missingRequiredField')}
                label={translate('campaigns.selectBannerModal')}
                onChange={setSelectedModal}
                options={allModals.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                required
                submitted={showError}
                value={selectedModal}
              />
              <Select
                data-cy="banner-button-cta"
                className="grid-span-6"
                errorText={translate('global.missingRequiredField')}
                label={translate('campaigns.bannerButtonCTA')}
                onChange={setButtonCopy}
                options={getButtonCopyOptions()}
                required
                submitted={showError}
                value={buttonCopy}
              />
            </>
          )}
        </Panel.Body>
        <Panel.Footer>
          <div className="left-side">
            <Button color="tertiary" href="/#/admin/campaigns/app-modals">
              {translate('global.cancel')}
            </Button>
          </div>
          <div className="right-side">
            <Button color="primary" onClick={onSave}>
              {translate('global.save')}
            </Button>
          </div>
        </Panel.Footer>
      </Panel>
    );
  },
);

export default EditCampaignBanner;
