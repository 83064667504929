import React from 'react';

import PhoneType from '@vestahealthcare/common/enums/PhoneType';
import { translate } from '@vestahealthcare/common/i18n';
import { ErrorWithType } from '@vestahealthcare/common/models/ErrorWithType';
import Phone from '@vestahealthcare/common/models/Phone';

import { Button, Phone as PhoneComponent } from 'styleguide';

import { AddIterationIcon } from '../Icons';
import './main.less';

interface Props {
  allowedTypes: PhoneType[];
  errors?: ErrorWithType[];
  labelNumber?: string;
  labelType?: string;
  onChange: (val: Phone[]) => void;
  placeholderNumber?: string;
  placeholderType?: string;
  required?: boolean;
  showError?: boolean;
  value?: Phone[];
}

const PhoneList = (props: Props) => {
  const {
    allowedTypes,
    errors,
    labelNumber,
    labelType,
    placeholderNumber,
    placeholderType,
    required,
    showError,
    value,
  } = props;

  const phones = (value?.length && value) || [new Phone({})];

  const onAdd = () => {
    const updatedPhoneList = [...phones, new Phone({})];
    props.onChange(updatedPhoneList);
  };

  const onChange = (val: Phone, index: number) => {
    const updatedPhoneList = [...phones];
    updatedPhoneList[index] = val;
    props.onChange(updatedPhoneList);
  };

  const onRemove = (index?: number) => {
    if (index) {
      const updatedPhoneList = phones.filter(
        (_: Phone, i: number) => i !== index,
      );
      props.onChange(updatedPhoneList);
    }
  };

  const hasEmptyRows = () => phones.some((phone: Phone) => !phone.number);

  return (
    <div className="phone-list">
      {phones.map((phone: Phone, index: number) => (
        <PhoneComponent
          allowedTypes={allowedTypes}
          elementIndex={index}
          errorText={errors?.filter((err) => err.index === index)}
          key={index}
          labelNumber={index ? undefined : labelNumber}
          labelType={index ? undefined : labelType}
          onChange={(val: Phone) => onChange(val, index)}
          onRemove={index ? onRemove : undefined}
          placeholderNumber={placeholderNumber}
          placeholderType={placeholderType}
          required={required}
          showError={showError}
          value={phone}
        />
      ))}
      <Button
        color="tertiary"
        onClick={() => onAdd()}
        disabled={hasEmptyRows()}
      >
        <AddIterationIcon className="margin-right" />
        {translate('global.addAnotherNumber')}
      </Button>
    </div>
  );
};

export default PhoneList;
