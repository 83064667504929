import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import { BiometricsConfig } from '@vestahealthcare/common/models';

import {
  Button,
  Card,
  CardContent,
  Colors,
  Fonts,
  IconButton,
  TextArea,
} from 'styleguide-v2';
import { BiometricsType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { BIOMETRICS_ORDER } from 'dash/src/pages/MemberProfile/Biometrics';

const useStyles = makeStyles({
  extraInfoContainer: {
    gap: '5px',
  },
  extraInfoTitle: {
    color: Colors.iconGreen,
    fontSize: `calc(${Fonts.fontSize} * 1.25)`,
    fontWeight: 500,
  },
  extraInfoSubtitle: {
    fontWeight: 500,
  },
  smallTitle: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
  },
});

type Props = {
  configs?: BiometricsConfig[];
  onSave: (configs: BiometricsConfig[]) => Promise<void>;
};

export const BiometricsTableNotes = ({ configs, onSave }: Props) => {
  const styles = useStyles();

  const [editNotes, setEditNotes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notesMap, setNotesMap] = useState<{ [key: string]: string }>();

  const initNotesMap = () => {
    const map: { [key: string]: string } = {};
    configs?.filter(Boolean)?.forEach(({ note, type }) => {
      map[type.value] = note;
    });
    setNotesMap(map);
  };

  const close = () => {
    initNotesMap();
    setEditNotes(false);
  };

  const save = async () => {
    configs?.forEach((c) => {
      c.note = (notesMap && notesMap[c.type.value]) || '';
    });
    setLoading(true);
    await onSave(configs || []);
    setLoading(false);
    setEditNotes(false);
  };

  useEffect(() => {
    initNotesMap();
  }, [configs]);

  return (
    <Card>
      <CardContent
        className={classnames('grid-wrapper', styles.extraInfoContainer)}
      >
        <span className={classnames(styles.extraInfoTitle, 'grid-span-12')}>
          {translate('biometrics.notes')}
          <IconButton
            className="pull-right"
            size="small"
            icon="edit"
            color="primary"
            onClick={() => (editNotes ? close() : setEditNotes(true))}
          />
        </span>
        {!editNotes &&
          BIOMETRICS_ORDER.map(
            (type) =>
              type !== BiometricsType.BP_SYSTOLIC &&
              notesMap &&
              notesMap[type.value] && (
                <>
                  <span
                    className={classnames('grid-span-12', styles.smallTitle)}
                  >
                    {type === BiometricsType.BP_DIASTOLIC
                      ? translate('biometrics.bp')
                      : type.toString()}
                  </span>
                  <span className="grid-span-12">{notesMap[type.value]}</span>
                </>
              ),
          )}

        {editNotes &&
          BIOMETRICS_ORDER.map(
            (type) =>
              type !== BiometricsType.BP_SYSTOLIC &&
              notesMap && (
                <TextArea
                  className="grid-span-12"
                  label={
                    type === BiometricsType.BP_DIASTOLIC
                      ? translate('biometrics.bp')
                      : type.toString()
                  }
                  onChange={(val) => {
                    notesMap[type.value] = val || '';
                    setNotesMap({ ...notesMap });
                  }}
                  value={notesMap[type.value]}
                />
              ),
          )}

        {editNotes && (
          <>
            <div className="grid-span-12" />
            <Button className="grid-span-6" color="tertiary" onClick={close}>
              {translate('global.close')}
            </Button>
            <Button
              className="grid-span-6"
              color="secondary"
              loading={loading}
              onClick={save}
            >
              {translate('global.save')}
            </Button>
          </>
        )}
      </CardContent>
    </Card>
  );
};
