import moment from 'moment';

import {
  Address,
  BaseEnum,
  Employee,
  EventInterventionType,
  InsuranceMLTC,
  InsurancePlan,
  MemberContactInfoTimes,
  Organization,
  StateCities,
} from '.';
import { DayOfWeek, MemberContactTimeType } from '../enums';
import { Selectable } from '../enums/Enum';
import { CommunityResourceSpeciality } from './CommunityResourceSpeciality';
import { make, makeArray } from './Model';

export class CommunityResource {
  id = 0;

  address?: Address;

  createdAt = 0;

  createdBy?: Employee;

  fax = '';

  hasLinkedMembers = false;

  insurances: InsurancePlan[];

  interventions: EventInterventionType[];

  mltcPlan?: InsuranceMLTC;

  name = '';

  notes = '';

  phone = '';

  pocEmail = '';

  pocExtension = '';

  pocName = '';

  pocNumber = '';

  referrals: Organization[];

  specialities: CommunityResourceSpeciality[];

  states: StateCities[];

  status = false;

  times: MemberContactInfoTimes[];

  type: BaseEnum;

  updatedAt = 0;

  updatedBy?: Employee;

  website = '';

  constructor(obj: any) {
    Object.assign(this, obj);
    this.address = make(obj.address, Address);
    this.type = make(obj.type, BaseEnum);
    this.referrals = makeArray(obj.referrals, Organization);
    this.insurances = makeArray(obj.insurances, InsurancePlan);
    this.mltcPlan = make(obj.mltcPlan, InsuranceMLTC);
    this.interventions = makeArray(
      (obj.interventions || []).map(({ type }: any) => type),
      EventInterventionType,
    );
    this.specialities = makeArray(
      (obj.specialities || []).map(({ type }: any) => type),
      CommunityResourceSpeciality,
    )?.sort((a: CommunityResourceSpeciality, b: CommunityResourceSpeciality) =>
      a.description.localeCompare(b.description),
    );
    this.times = makeArray(obj.times, MemberContactInfoTimes);
    this.states = makeArray(obj.states, StateCities);
    this.status = obj.status === 'ACTIVE';
  }

  getHoursOfOperation() {
    const map = {} as { [x: string]: DayOfWeek[] };
    this?.times?.forEach(({ dayOfWeek, startTime, endTime, type }) => {
      if (type && type !== MemberContactTimeType.CUSTOM) {
        if (!map[type.value]) {
          map[type.value] = [] as DayOfWeek[];
        }
        if (dayOfWeek) {
          map[type.value].push(dayOfWeek);
        }
      } else {
        const key = `${startTime || ''}__${endTime || ''}`;
        if (!map[key]) {
          map[key] = [] as DayOfWeek[];
        }
        if (dayOfWeek) {
          map[key].push(dayOfWeek);
        }
      }
    });

    return Object.keys(map).map((key) => {
      const hasType = key.indexOf('__') === -1;
      const type = hasType ? key : undefined;
      const start = hasType ? undefined : key.split('__')[0];
      const end = hasType ? undefined : key.split('__')[1];
      return {
        dates: map[key],
        startTime: start ? moment(Number(start)).toDate() : undefined,
        endTime: end ? moment(Number(end)).toDate() : undefined,
        type: type
          ? MemberContactTimeType.byKey[type]
          : MemberContactTimeType.CUSTOM,
      };
    });
  }

  static toSelectable(items: CommunityResource[]): Selectable[] {
    return items.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }
}

export default CommunityResource;
