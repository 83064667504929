import React from 'react';
import classNames from 'classnames';
import Button from '../Button';
import { Text } from '../Text';
import { translate } from '@vestahealthcare/common/i18n';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  footer: {
    display: 'flex',
  },
  footerText: {
    marginLeft: 10,
    marginRight: 10,
    whiteSpace: 'nowrap',
    alignSelf: 'center',
  },
});

interface Props {
  color?: 'default' | 'inactive';
  currentPage?: number;
  numPages?: number;
  onNext?: () => void;
  onPrev?: () => void;
}

export const Table = React.memo(
  (props: Props & React.HTMLProps<HTMLTableElement>) => {
    const {
      children,
      className,
      color,
      currentPage,
      numPages,
      onPrev,
      onNext,
      ...other
    } = props;

    const styles = useStyles();
    const classes = classNames('table', className, color && `${color}-color`);
    const isPaginated = !!(onPrev && onNext);

    return (
      <>
        <table className={classes} {...other}>
          {children}
        </table>
        {isPaginated && (
          <div className="grid-wrapper grid-span-12">
            <span className={styles.footer}>
              <Button
                color="secondary"
                onClick={onPrev}
                disabled={currentPage === 1}
              >
                {translate('global.previous')}
              </Button>
              <Text inline className={styles.footerText}>
                {translate('global.currentOfTotal', {
                  current: currentPage,
                  total: numPages,
                })}
              </Text>
              <Button
                color="primary"
                onClick={onNext}
                disabled={currentPage === numPages}
              >
                {translate('global.next')}
              </Button>
            </span>
          </div>
        )}
      </>
    );
  },
);

export default Table;
