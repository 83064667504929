import React from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { CampaignAudience } from '@vestahealthcare/common/models';

import {
  AddIcon,
  Button,
  ButtonModal,
  Checkbox,
  DeleteIcon,
  EditIcon,
  EmptyState,
  Table,
} from 'styleguide';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import {
  activateCampaign,
  deactivateCampaign,
  deleteCampaign,
} from 'dash/src/services/CampaignServices';

interface Props {
  campaigns: CampaignAudience[];
  onChange: () => void;
}

export const Campaigns = ({ campaigns, onChange }: Props) => (
  <article>
    <header>
      <div className="h7">{translate('campaigns.campaigns')}</div>
      <div className="right-side">
        <Button
          color="secondary"
          data-cy="add-campaign"
          href="/#/admin/campaigns/app-modals/campaign"
          isPill
        >
          <AddIcon className="margin-right" />
          {translate('campaigns.addCampaign')}
        </Button>
      </div>
    </header>
    {campaigns.length ? (
      <CampaignsTable campaigns={campaigns} onChange={onChange} />
    ) : (
      <EmptyState>No campaigns added yet.</EmptyState>
    )}
  </article>
);

const CampaignsTable = ({ campaigns, onChange }: Props) => (
  <Table>
    <thead>
      <tr>
        <th>{translate('campaigns.name')}</th>
        <th>{translate('campaigns.audience')}</th>
        <th>{translate('campaigns.modals')}</th>
        <th>{translate('campaigns.banners')}</th>
        <th style={{ width: 150 }}>{translate('campaigns.status')}</th>
        <th style={{ width: 80 }} />
      </tr>
    </thead>
    <tbody>
      {campaigns.map((campaign) => (
        <tr key={campaign.id} data-cy="campaign-record">
          <td data-cy="campaign-name">{campaign.name}</td>
          <td>
            {campaign.organizations.map((organization) => (
              <p key={organization.id}>
                {organization.name} <i>{`(${campaign.targets.toString()})`}</i>
              </p>
            ))}
          </td>
          <td>
            {campaign.modals.map((modal) => (
              <p key={modal.id}>{modal.name}</p>
            ))}
          </td>
          <td>
            {campaign.banners.map((banner) => (
              <p key={banner.id}>{banner.name}</p>
            ))}
          </td>
          <td data-cy="activation">
            <Checkbox
              data-cy="activate-campaign"
              checked={campaign.active}
              className="slide-toggle"
              icon={campaign.active ? 'toggle-on' : 'toggle-off'}
              label={translate(
                campaign.active ? 'campaigns.active' : 'campaigns.inactive',
              )}
              onChange={async () => {
                try {
                  if (campaign.active) {
                    await deactivateCampaign(campaign.id);
                  } else {
                    await activateCampaign(campaign.id);
                  }
                  onChange();
                  return Promise.resolve();
                } catch (err) {
                  showGlobalError(err as string);
                }
              }}
            />
          </td>
          <td>
            <a href={`/#/admin/campaigns/app-modals/campaign/${campaign.id}`}>
              <EditIcon tag="button" marginRight />
            </a>
            <ButtonModal
              iconComponent={<DeleteIcon tag="button" />}
              modalTitle={translate('campaigns.confirmDeleteCampaignTitle')}
              onSubmit={async () => {
                try {
                  await deleteCampaign(campaign.id);
                  onChange();
                } catch (err) {
                  showGlobalError(err as string);
                }
              }}
            >
              {translate('campaigns.confirmDeleteCampaignBody', {
                name: campaign.name,
              })}
            </ButtonModal>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export default Campaigns;
