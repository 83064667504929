import { MemberDashboardEncounter, TOCItem } from '.';
import { make, makeArray } from './Model';
import { TOCDetailMember } from './TOCDetailMember';

export class TOCDetail {
  encounters: MemberDashboardEncounter[];

  memberDetail: TOCDetailMember;

  tocRecord: TOCItem;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.encounters = makeArray(obj.encounters, MemberDashboardEncounter);
    this.memberDetail = make(obj.memberDetail, TOCDetailMember);
    this.tocRecord = make(obj.tocRecord, TOCItem);
  }
}

export default TOCDetail;
