import React, { PureComponent } from 'react';
import { DeleteIcon } from 'styleguide';
import { BodyPart } from '@vestahealthcare/common/enums';

interface Props {
  disabled?: boolean;
  hideSelectionText?: boolean;
  onTogglePart: Function;
  selectedParts: Set<BodyPart>;
}

export default class PartList extends PureComponent<Props> {
  render() {
    const { selectedParts, onTogglePart, hideSelectionText } = this.props;

    const sortedParts = Array.from(selectedParts).sort((a, b) =>
      a.value > b.value ? 1 : -1,
    );

    return (
      <ul className="clinical-figure-list">
        {!hideSelectionText &&
          sortedParts.map((part) => (
            <li className="clinical-figure-list-item" key={part.value}>
              {this.props.disabled || (
                <DeleteIcon
                  onClick={() => onTogglePart(part)}
                  className="clinical-figure-icon-remove"
                  tag="button"
                />
              )}
              {part.toString()}
            </li>
          ))}
      </ul>
    );
  }
}
