import moment from 'moment';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { BackgroundColors, Colors, Fonts } from 'styleguide-v2';

import { TooltipProps } from 'recharts';

const useStyles = makeStyles({
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: BackgroundColors.lightMint,
    borderTop: `2px solid ${Colors.mint}`,
    color: Colors.textBlack,
    boxShadow: '0 0 4px rgba(0, 0, 0, 0.4)',
    padding: 10,
  },
  label: {
    fontFamily: Fonts.fontFamily,
    fontSize: Fonts.fontSize,
  },
  data: {
    fontFamily: Fonts.fontFamily,
    fontSize: Fonts.fontSize,
    fontWeight: 500,
  },
});

export const BiometricsCustomTooltip = ({ active, payload }: TooltipProps) => {
  const styles = useStyles();
  if (!active || !payload || !payload[0]) return null;
  const reading = payload[0].payload;
  return (
    <div className={styles.tooltip}>
      <span className={styles.label}>{`${reading.date} at ${
        reading.time
      } ${moment
        .tz(moment.tz.guess())
        .zoneAbbr()
        .replace(/[DS]T$/, 'T')} via ${reading.channel}`}</span>
      {reading.systolic && (
        <span
          className={styles.data}
        >{`${reading.systolic} / ${reading.diastolic} ${reading.unit}`}</span>
      )}
      {reading.value && (
        <span
          className={styles.data}
        >{`${reading.value} ${reading.unit}`}</span>
      )}
    </div>
  );
};
