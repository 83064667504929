import moment, { Moment } from 'moment';

export class EligibilityJob {
  id = 0;

  processType = '';

  status = '';

  numMembers = 0;

  numMembersSuccess = 0;

  startedAt?: Moment;

  endedAt?: Moment;

  comparisionReport = '';

  constructor(obj: any) {
    Object.assign(this, obj);
    this.startedAt = obj.startedAt ? moment(obj.startedAt) : undefined;
    this.endedAt = obj.endedAt ? moment(obj.endedAt) : undefined;
  }

  get isInProgress() {
    return this.status === 'IN_PROGRESS';
  }

  get isCompleted() {
    return this.status === 'COMPLETE';
  }

  get isError() {
    return this.status === 'ERROR';
  }
}

export default EligibilityJob;
