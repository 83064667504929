import React from 'react';
import { Panel, DataTable, Table } from 'styleguide';

const StyleguideTables = () => {
  const columns = [
    {
      Header: 'A',
      accessor: 'a',
      width: 185,
    },
    {
      Header: 'B',
      accessor: 'b',
      width: 100,
    },
    {
      Header: 'C',
      accessor: 'c',
      width: 60,
    },
    {
      Header: 'D',
      accessor: 'd',
      width: 200,
    },
    {
      Header: 'E',
      accessor: 'e',
      width: 300,
    },
  ];
  const data: any[] = [];

  return (
    <Panel>
      <Panel.Heading>
        <h2>Tables</h2>
      </Panel.Heading>
      <Panel.Body className="grid-wrapper">
        <Table
          className="grid-span-12"
          currentPage={1}
          numPages={5}
          onNext={() => {}}
          onPrev={() => {}}
        >
          <thead>
            <tr>
              <th>Title 1</th>
              <th colSpan={2}>Title 2</th>
              <th>Title 3</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p className="large">Value 1</p>
                <p className="small">Detail 1</p>
                <p className="small">Detail 2</p>
              </td>
              <td colSpan={2}>
                <p>Attribute 1</p>
              </td>
              <td />
            </tr>
            <tr>
              <td>
                <p className="large">Value 1</p>
                <p className="small">Detail 1</p>
                <p className="small">Detail 2</p>
              </td>
              <td colSpan={2}>
                <p>Attribute 2</p>
              </td>
              <td>
                <i className="fa fa-check" />
              </td>
            </tr>
            <tr>
              <td>
                <p className="large">Value 1</p>
              </td>
              <td colSpan={2}>
                <p>Attribute 2</p>
              </td>
              <td>
                <i className="fa fa-check" />
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="h7 grid-span-12">Datatable</div>
        <div className="grid-span-12">
          <DataTable
            columns={columns}
            data={data}
            filterable
            defaultPageSize={10}
          />
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default StyleguideTables;
