import { BaseEnum, SourceChannel } from '@vestahealthcare/common/models';

import Api from './Api';

export const fetchSourceChannels = async (): Promise<SourceChannel[]> => {
  const {
    channels: { items },
  } = await Api.getv2('sources/channels', {
    sort: 'description asc',
  });
  return items?.map((et: any) => new SourceChannel(et));
};

export const fetchSourceChannelDirections = async (): Promise<BaseEnum[]> => {
  const {
    directions: { items },
  } = await Api.getv2('sources/directions', {
    sort: 'description asc',
  });
  return items?.map((et: any) => new BaseEnum(et));
};
