import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { TaskDefinitionStatus } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { TaskCustomFieldType } from '@vestahealthcare/common/enums/TaskCustomFieldType';
import { translate } from '@vestahealthcare/common/i18n';
import { EmployeeGroup } from '@vestahealthcare/common/models';
import { TaskCustomField } from '@vestahealthcare/common/models/TaskCustomField';
import { TaskDefinition } from '@vestahealthcare/common/models/TaskDefinition';
import { TaskDefinitionOutcome } from '@vestahealthcare/common/models/TaskDefinitionOutcome';

import {
  Button,
  Checkbox,
  Modal,
  Panel,
  Select,
  Text,
  TextInput,
} from 'styleguide';

import { TaskDefinitionContext } from 'dash/src/contexts/TaskDefinitionContext';
import { CacheServices } from 'dash/src/services';

import { CustomFields } from './CustomFields';
import { Outcomes } from './Outcomes';

type Params = {
  templateId: string;
  subtaskId: string;
};

export const SubtaskDefinitionDetail = () => {
  const history = useHistory();
  const searchParams = useParams<Params>();
  const { templateId, subtaskId } = searchParams;

  const parseId = (id: string) => parseFloat(id);

  const [loading, setLoading] = useState(false);
  const { definition, setDefinition } = useContext(TaskDefinitionContext);
  const [subtask, setSubtask] = useState({} as TaskDefinition);
  const [groups, setGroups] = useState<Selectable[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [outcomes, setOutcomes] = useState<TaskDefinitionOutcome[]>([]);
  const [customFields, setCustomFields] = useState<TaskCustomField[]>([]);
  const [showInactiveSubtaskModal, setShowInactiveSubtaskModal] = useState(
    false,
  );
  const { fetchDefinition } = useContext(TaskDefinitionContext);

  const fetchGroups = async () => {
    const groups = await CacheServices.getEmployeeGroupsAssignee();
    setGroups(
      groups.map(({ name, id }: EmployeeGroup) => ({ label: name, value: id })),
    );
  };

  const getTaskDefinition = async () => {
    setLoading(true);
    const id = templateId === 'new' ? -1 : parseId(templateId);
    await fetchDefinition(id);
    setLoading(false);
  };

  useEffect(() => {
    fetchGroups();
    getTaskDefinition();
  }, []);

  useEffect(() => {
    const subtask = definition.subtasks?.find(
      ({ id }) => id === parseId(subtaskId),
    );
    if (subtask) {
      setSubtask({ ...subtask });
      setOutcomes(subtask.outcomes ? [...subtask.outcomes] : []);
      setCustomFields(subtask.customFields ? [...subtask.customFields] : []);
    }
  }, [definition]);

  const checkBeforeToggleActive = (checked: boolean) => {
    if (!checked) {
      if (subtask.taskPrecedentIds && subtask.taskPrecedentIds.length > 0) {
        setShowInactiveSubtaskModal(true);
        return false;
      }
    }
    onToggleActive(checked);
  };
  const onToggleActive = (checked: boolean) => {
    if (checked) {
      setSubtask({ ...subtask, status: TaskDefinitionStatus.PUBLISHED });
    } else {
      setSubtask({
        ...subtask,
        status: TaskDefinitionStatus.INACTIVE,
        taskPrecedentIds: [],
      });
    }
  };

  const validate = () => {
    if (!subtask.name || !outcomes?.length) return false;
    const invalidOutcome = outcomes?.find(({ name }) => !name);
    const invalidCustomField = (customFields || []).find(
      ({ label, type, options }) =>
        !label || (type === TaskCustomFieldType.CHECKBOX && !options?.length),
    );
    if (invalidCustomField || invalidOutcome) return false;
    return true;
  };

  const saveSubtask = () => {
    setSubmitted(true);
    if (validate()) {
      if (definition.subtasks?.length) {
        const index = definition.subtasks.findIndex(
          ({ id }) => id === parseId(subtaskId),
        );
        if (index !== -1) {
          definition.subtasks[index] = { ...subtask, outcomes, customFields };
        }
        setDefinition({ ...definition });
      }
      setSubmitted(false);
      history.goBack();
    }
  };

  return (
    <Panel>
      <Panel.Heading
        data-cy="task-definitions-page"
        title={subtask.name || translate('taskDefinitions.subtask.name')}
      />
      <Panel.Body loading={loading} className="grid-wrapper">
        <TextInput
          data-cy="subtask-definition-name-input"
          className="grid-span-6"
          errorText={translate('global.missingRequiredField')}
          label={translate('taskDefinitions.name')}
          onChange={(name?: string) => name && setSubtask({ ...subtask, name })}
          required
          showError={submitted && !subtask.name}
          value={subtask.name}
        />
        <Select
          data-cy="subtask-definition-group-input"
          columns={6}
          label={translate('taskDefinitions.group')}
          onChange={(employeeGroupId?: number) =>
            employeeGroupId && setSubtask({ ...subtask, employeeGroupId })
          }
          options={groups}
          value={subtask.employeeGroupId}
        />

        <Checkbox
          checked={
            subtask.status?.value !== TaskDefinitionStatus.INACTIVE.value
          }
          data-cy="subtask-definition-name-input"
          label={translate('taskDefinitions.active')}
          onChange={checkBeforeToggleActive}
        />

        <Outcomes
          onEdit={setOutcomes}
          outcomes={outcomes || []}
          submitted={submitted}
        />

        <CustomFields
          onEdit={setCustomFields}
          customFields={subtask.customFields || []}
          submitted={submitted}
        />
      </Panel.Body>
      <Panel.Footer>
        <div className="right-side">
          <Button
            color="tertiary"
            data-cy="subtask-cancel"
            onClick={() => history.goBack()}
          >
            {translate('global.cancel')}
          </Button>
          <Button
            isPill
            color="primary"
            data-cy="subtask-save"
            onClick={() => saveSubtask()}
          >
            {translate('global.save')}
          </Button>
        </div>
      </Panel.Footer>
      {showInactiveSubtaskModal && (
        <Modal onHide={() => setShowInactiveSubtaskModal(false)} show>
          <Modal.Header closeButton modalTitle={translate('global.warning')} />
          <Modal.Body>
            <Text>
              {translate('taskDefinitions.subtask.inactiveWithPrecedent')}
            </Text>
          </Modal.Body>
          <Modal.Footer
            leftSide={
              <Button
                color="secondary"
                onClick={() => setShowInactiveSubtaskModal(false)}
              >
                {translate('global.cancel')}
              </Button>
            }
          >
            <div className="right-side">
              <Button
                color="primary"
                onClick={() => {
                  setShowInactiveSubtaskModal(false);
                  onToggleActive(false);
                }}
              >
                {translate('global.confirm')}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </Panel>
  );
};

export default SubtaskDefinitionDetail;
