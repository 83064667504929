import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';

import {
  DayOfWeek,
  MemberContactMethodType,
  MemberContactTimeType,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  CareTeamPerson,
  CareTeamPersonExtended,
  PaginationType,
} from '@vestahealthcare/common/models';
import { EMPTY, TIME_FORMAT } from '@vestahealthcare/common/utils/constants';

import {
  CareTeamPersonInfo,
  CollapseIcon,
  IconButton,
  Table,
  TableItem,
} from 'styleguide-v2';

import { CareTeamMemberTable } from './CareTeamMemberTable';

type Props = {
  careTeamPersons: CareTeamPersonExtended[];
  onAdd: () => void;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onEdit: (group: CareTeamPersonExtended) => void;
  pagination?: PaginationType;
};

interface CareTeamPersonTableItem extends CareTeamPersonExtended, TableItem {}

const CARE_TEAM_COLUMNS = ({ onEdit }: Props) => [
  {
    headerName: translate('careTeam.common.name'),
    component: (ctp: CareTeamPerson) => (
      <CareTeamPersonInfo
        link={`#/admin/care-team/${ctp?.id}?firstName=${ctp?.firstName}&lastName=${ctp.lastName}`}
        person={ctp}
        showLanguage
        showStatus
      />
    ),
  },
  {
    headerName: translate('careTeam.common.engagementPreference'),
    component: ({ contactInfo }: CareTeamPerson) =>
      contactInfo?.contactMethods?.length
        ? contactInfo.contactMethods
            .map(({ contactMethod, contactMethodOther }) => {
              if (contactMethod === MemberContactMethodType.OTHER)
                return contactMethodOther;
              return contactMethod.toString();
            })
            .join(', ')
        : EMPTY,
  },
  {
    headerName: translate('careTeam.common.preferredDateTimes'),
    component: (ctp: CareTeamPerson) =>
      ctp
        .getDaysOfService()
        ?.map(({ dates, startTime, endTime, type }, index) => (
          <p
            className="no-margin"
            key={`care-team-person-${ctp.id}-days-${index}`}
          >
            {!!dates?.length && (
              <span>
                {DayOfWeek.stringify(dates)}
                {(type || startTime || endTime) && ': '}
              </span>
            )}
            {type && type !== MemberContactTimeType.CUSTOM && (
              <span>{`${type?.toString()} `}</span>
            )}
            {(startTime || endTime) && (
              <span>
                {startTime
                  ? moment(startTime.getTime()).format(TIME_FORMAT)
                  : '*'}
                {' - '}
                {endTime ? moment(endTime.getTime()).format(TIME_FORMAT) : '*'}
              </span>
            )}
          </p>
        )) || EMPTY,
  },
  {
    headerName: translate('careTeam.common.engagementOwner'),
    field: 'engagementOwner.fullName',
  },
  {
    headerName: '',
    component: (ctm: CareTeamPersonTableItem) => (
      <div className="flex gap">
        <IconButton
          onClick={(evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            onEdit(ctm);
          }}
          size="small"
        >
          <EditIcon />
        </IconButton>
        <CollapseIcon open={!!ctm.open} />
      </div>
    ),
    width: 20,
  },
];

export const CareTeamPersonTable = (props: Props) => {
  const {
    careTeamPersons,
    onAdd,
    onChangePage,
    onChangePageSize,
    pagination,
  } = props;

  const [items, setItems] = useState<CareTeamPersonTableItem[]>([]);

  const onOpen = (ctp: CareTeamPersonTableItem) => {
    const idx = items.findIndex(({ id }) => id === ctp.id);
    if (idx !== -1) {
      if (items[idx].open) {
        delete items[idx].detail;
      } else {
        items[idx].detail = (
          <CareTeamMemberTable careTeamMembers={items[idx].careTeamMembers} />
        );
      }
      items[idx].open = !items[idx].open;
      setItems([...items]);
    }
  };

  const columns = useMemo(() => CARE_TEAM_COLUMNS(props), [props.onEdit]);

  useEffect(() => {
    setItems(careTeamPersons);
  }, [careTeamPersons]);

  return (
    <Table
      config={{
        columns,
        compact: true,
        data: items,
        detail: true,
        pagination,
        paginationOptions: [10, 25, 50, 100],
      }}
      empty={translate('careTeam.noResults')}
      emptyLink={translate('careTeam.add')}
      onClickEmptyLink={onAdd}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangePageSize}
      onClickRow={onOpen}
    />
  );
};

export default CareTeamPersonTable;
