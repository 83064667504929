import classNames from 'classnames';
import React, { ReactFragment, ReactNode } from 'react';

import { Chip as MUIChip, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles, styled } from '@mui/styles';

import { Fonts } from 'styleguide-v2/src/styles/Variables';
import { ColorProps, getColors } from 'styleguide-v2/src/utils/colors';

export type ChipColor =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'warning'
  | 'error'
  | 'action';
type Props = {
  className?: string;
  children?: ReactNode;
  color?: ChipColor;
  'data-cy'?: string;
  icon?: ReactNode;
  label?: string | number | ReactNode;
  onClick?: () => void;
  title?: string | ReactFragment;
  type?: 'contained' | 'contained-2' | 'outlined';
  size?: 'xs' | 'small' | 'medium' | 'large';
};

const useStyles = makeStyles<Theme, ColorProps>({
  container: {
    '&': {
      backgroundColor: ({ bkgColor }: ColorProps) => bkgColor,
      color: ({ color }: ColorProps) => color,
    },

    '&.button:hover': {
      backgroundColor: ({ bkgColor }: ColorProps) => `${bkgColor}99`,
    },
    borderRadius: '.4rem',
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    height: 'max-content',
    lineHeight: Fonts.fontSize,
    padding: '.2rem .8rem 0rem',
    width: 'max-content',
  },
  noClickable: {
    cursor: 'initial',
    display: 'inline-block',
    textAlign: 'center',
  },
  contained: {
    border: 0,
    padding: '.5rem .9rem .3rem',
  },
  large: {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    lineHeight: `calc(${Fonts.fontSize} * 1.125)`,
  },
  small: {
    lineHeight: `calc(${Fonts.fontSize} - 1px)`,
    padding: '.1rem .4rem 0rem',
  },
  xs: {
    lineHeight: `calc(${Fonts.fontSize} * 0.875)`,
    fontSize: `calc(${Fonts.fontSize} * 0.625)`,
  },
  icon: {
    height: '2rem',
    padding: '.2rem',
  },
  outlined: {
    border: '1px solid',
  },
});

export const MChip = styled(MUIChip)({
  '& .MuiChip-label': {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
  },
});

export const Chip = ({
  className,
  color = 'primary',
  children,
  'data-cy': dataCy,
  icon,
  label,
  onClick,
  title,
  type = 'contained',
  size = 'medium',
}: Props) => {
  const styles = useStyles(getColors(color, type));
  const classes = classNames(
    'mbtn',
    className,
    styles.container,
    icon && styles.icon,
    onClick ? 'button' : styles.noClickable,
    type === 'outlined' ? styles.outlined : styles.contained,
    size === 'xs' && styles.xs,
    ['small', 'xs'].indexOf(size) !== -1 && styles.small,
    size === 'large' && styles.large,
  );

  const renderChip = () =>
    onClick ? (
      <button
        type="button"
        className={classes}
        data-cy={dataCy}
        onClick={onClick}
      >
        {label || children}
      </button>
    ) : (
      <span className={classes} data-cy={dataCy}>
        {label || children || icon}
      </span>
    );

  return title ? (
    <Tooltip arrow title={title}>
      {renderChip()}
    </Tooltip>
  ) : (
    renderChip()
  );
};

export default Chip;
