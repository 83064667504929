import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { ClassNameMap, makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  MemberDashboardEvent,
  PaginationType,
} from '@vestahealthcare/common/models';
import EventStatus from '@vestahealthcare/common/models/EventStatus';
import { DATE_FORMAT_SHORT_WITH_TIME } from '@vestahealthcare/common/utils/constants';

import {
  CollapseIcon,
  Colors,
  Fonts,
  Spinner,
  StatusIndicator,
  Table,
  UNASSIGNED,
} from 'styleguide-v2';

import { MAX_DASHBOARD_TABLE_ITEMS } from './contansts';

type Props = {
  className?: string;
  data: MemberDashboardEvent[];
  onSeeMore: () => Promise<void>;
  pagination?: PaginationType;
  shouldShowLink?: boolean;
};

const RECENT_EVENTS_COLUMNS = (
  styles: ClassNameMap<'firstParragraph' | 'status'>,
) => [
  {
    headerName: 'Event',
    component: ({ type, date }: MemberDashboardEvent) => (
      <>
        <p className={classNames(styles.firstParragraph, 'bold no-margin')}>
          {type.name}
        </p>
        <p className="gray no-margin semi-bold size-xs">
          {date.format(DATE_FORMAT_SHORT_WITH_TIME)}
        </p>
      </>
    ),
  },
  {
    headerName: 'Status',
    component: ({ status, assignee }: MemberDashboardEvent) => (
      <StatusIndicator
        className={styles.status}
        color={EventStatus.getColor(status)}
        status={status.description}
        subtitle={assignee || UNASSIGNED?.fullName}
      />
    ),
  },
];

const useStyles = makeStyles({
  table: {
    marginTop: 1,
  },
  tableContainer: {
    display: 'flex',
    marginBottom: '1rem',
    minHeight: '2rem',
    maxHeight: '30.5rem',
    overflow: 'hidden',
    transition: 'max-height 0.3s ease',
  },
  seeMore: {
    color: Colors.textGreen,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
  },
  firstParragraph: {
    '&&': {
      margin: '4px 0 -2px',
    },
  },
  status: {
    '& > :first-child': {
      margin: '4px 0 0',
    },
    '& > :last-child': {
      marginTop: '-3px',
    },
  },
});

export const RecentEventsTable = (props: Props) => {
  const styles = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => setOpen(false), [props.data]);

  return (
    <div className={props.className}>
      <div
        className={styles.tableContainer}
        style={{
          maxHeight: open ? `${props.data.length * 5.5 + 4}rem` : undefined,
        }}
      >
        <Table
          className={styles.table}
          config={{
            columns: RECENT_EVENTS_COLUMNS(styles),
            compact: true,
            data: props.data,
            paginationOptions: [-1],
          }}
          empty={translate('memberProfile.dashboard.noRecentEvents')}
          whiteBackground
        />
      </div>
      {props.shouldShowLink &&
        props.pagination &&
        props.pagination.total > MAX_DASHBOARD_TABLE_ITEMS && (
          <div className="flex spaced">
            <a
              className={classNames('flex middle min-gap', styles.seeMore)}
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                if (loading) return;
                if (
                  open === false &&
                  props.data.length < (props.pagination?.total || 0)
                ) {
                  setLoading(true);
                  await props.onSeeMore();
                  setLoading(false);
                }
                setTimeout(() => {
                  setOpen(!open);
                }, 0);
              }}
            >
              <CollapseIcon open={open} htmlColor={Colors.textGreen} />
              {loading ? (
                <Spinner text="Loading" />
              ) : (
                translate(
                  `memberProfile.dashboard.${open ? 'hide' : 'see'}More`,
                  {
                    count: props.pagination?.total - MAX_DASHBOARD_TABLE_ITEMS,
                  },
                )
              )}
            </a>
          </div>
        )}
    </div>
  );
};
