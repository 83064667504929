export class EventSummaryCount {
  open = 0;

  all = 0;

  unassigned = 0;

  assignedToMe = 0;

  escalated = 0;

  nonUrgentFollowUp = 0;

  urgentFollowUp = 0;

  vitalsAbnormal = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default EventSummaryCount;
