import classNames from 'classnames';
import React, { useMemo } from 'react';

import ForwardIcon from '@mui/icons-material/ArrowForwardIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  ClinicalMember,
  MemberDashboardEvent,
  PaginationType,
  Patient,
} from '@vestahealthcare/common/models';

import { Card, Chip, Colors, Fonts, Tooltip } from 'styleguide-v2';

import Session from 'dash/src/services/SessionServices';

import { RecentEventsTable } from './RecentEventsTable';

type Props = {
  className?: string;
  data: MemberDashboardEvent[];
  member: Patient | ClinicalMember;
  onSeeMore: () => Promise<void>;
  openEvents?: number;
  pagination?: PaginationType;
};

const useStyles = makeStyles({
  container: {
    '&&': {
      borderRadius: '8px',
    },

    display: 'flex',

    '& > div': {
      flex: 1,
      padding: '2.4rem 3.2rem',
    },
  },
  subContainer: {
    display: 'flex',
    flexFlow: 'column',
  },
  title: {
    alignItems: 'center',
    display: 'flex',

    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontWeight: 500,
      margin: 0,
    },
  },
});

export const RecentEventsCard = ({
  className,
  data,
  member,
  onSeeMore,
  openEvents,
  pagination,
}: Props) => {
  const styles = useStyles();

  const memberId = useMemo(
    () => (member instanceof Patient ? member?.id : member.memberId),
    [member],
  );
  const openNewTab = useMemo(() => !(member instanceof Patient), [member]);
  const shouldShowLink = !Session.actingUser.isExternal;

  return (
    <Card className={classNames(className, styles.container)}>
      <div className={styles.subContainer}>
        <div className="flex spaced">
          <span className={styles.title}>
            {translate('memberProfile.dashboard.recentEvents')}
            &nbsp;
            <Tooltip
              text={translate('memberProfile.dashboard.recentEventsTooltip')}
            >
              <InfoOutlinedIcon htmlColor={Colors.iconGray} />
            </Tooltip>
            {!!openEvents && (
              <>
                &nbsp;&nbsp;
                <Chip color="error" type="contained-2">
                  <span className="bold">{openEvents} OPEN</span>
                </Chip>
              </>
            )}
          </span>
          {shouldShowLink && (
            <a
              className="flex middle min-gap"
              href={`#/patients/${memberId}/events`}
              style={{ color: Colors.textGreen, fontWeight: 500 }}
              rel="noreferrer"
              target={openNewTab ? '_blank' : undefined}
            >
              {translate('memberProfile.dashboard.goToEvents')}
              <ForwardIcon fontSize="small" />
            </a>
          )}
        </div>
        <br />
        <RecentEventsTable
          data={data}
          pagination={pagination}
          onSeeMore={onSeeMore}
          shouldShowLink={shouldShowLink}
        />
      </div>
    </Card>
  );
};
