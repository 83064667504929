import moment, { Moment } from 'moment';

import { DATE_FORMAT } from '../utils/constants';

export class MemberDashboardBiannual {
  lastBiannualDate?: Moment;

  lastBiannualType = '';

  lastBiannualEmployee = '';

  nextBiannualDate?: Moment;

  nextBiannualType = '';

  nextBiannualEmployee = '';

  nextBiannualDue?: Moment;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.lastBiannualDate = obj.lastBiannualDate
      ? moment(obj.lastBiannualDate, DATE_FORMAT)
      : undefined;
    this.nextBiannualDate = obj.nextBiannualDate
      ? moment(obj.nextBiannualDate, DATE_FORMAT)
      : undefined;
    this.nextBiannualDue = obj.nextBiannualDue
      ? moment(obj.nextBiannualDue, DATE_FORMAT)
      : undefined;
  }
}

export default MemberDashboardBiannual;
