import React, { useCallback, useEffect, useState } from 'react';
import { translate } from '@vestahealthcare/common/i18n';
import { Button, Icon, Panel } from 'styleguide';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Task } from '@vestahealthcare/common/models';

import { fetchTask } from 'dash/src/services/TaskServices';

import TasksTable from './TasksTable';

type Params = {
  taskId: string;
  subtaskId: string;
};

export const TaskPage = ({
  match: {
    params: { taskId, subtaskId },
  },
}: RouteComponentProps<Params>) => {
  const [task, setTask] = useState<Task>();
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();

  const getTask = useCallback(async () => {
    setLoading(true);
    const task = await fetchTask(Number(taskId));
    setLoading(false);

    setTask(task);
  }, [taskId]);

  useEffect(() => {
    getTask();
  }, [taskId, getTask]);

  const onUpdate = () => {
    getTask();
  };

  return (
    <div id="page">
      <Button color="tertiary" onClick={() => history.goBack()}>
        <Icon className="fa fa-chevron-left" marginRight />
        {translate('tasks.backToPreviousPage')}
      </Button>
      <div id="dashboard">
        <Panel>
          <Panel.Heading
            title={translate(
              task?.parentId ? 'tasks.subtaskDetails' : 'tasks.taskDetails',
            )}
          >
            {task?.parentId && (
              <Button
                color="tertiary"
                onClick={() => history.push(`/tasks/${task?.parentId}`)}
              >
                {translate('tasks.backToParent')}
              </Button>
            )}
          </Panel.Heading>
          <Panel.Body>
            {task && (
              <TasksTable
                loading={isLoading}
                activeId={task.id}
                activeSubtaskId={Number(subtaskId)}
                tasks={[task]}
                onTaskUpdate={onUpdate}
                showSubTasks
              />
            )}
          </Panel.Body>
        </Panel>
      </div>
    </div>
  );
};

export default TaskPage;
