import { RPMVendorDeviceSize } from '../enums';

export class RPMVendorDevice {
  id = 0;

  size: RPMVendorDeviceSize;

  sku = '';

  constructor(obj: any) {
    Object.assign(this, obj);
    this.size = RPMVendorDeviceSize.byKey[obj.size];
  }
}

export default RPMVendorDevice;
