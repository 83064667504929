import React, { Fragment, useEffect, useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';

import { translate } from '@vestahealthcare/common/i18n';
import { EventIntervention } from '@vestahealthcare/common/models';
import { DATE_FORMAT_SHORT_WITH_TIME } from '@vestahealthcare/common/utils/constants';

import { Chip, Fonts, Spinner } from 'styleguide-v2';
import { ChipColor } from 'styleguide-v2/src/components/Chip';

const MAX_ENTRIES = 5;
interface Props {
  interventions: EventIntervention[];
  loading?: boolean;
}

const useStyles = makeStyles({
  container: {
    minHeight: '8.25rem',
    maxWidth: '32.5rem',
    '& > h6': {
      fontFamily: Fonts.fontFamily,
      fontWeight: 500,
      margin: 0,
      width: 'fit-content',
    },
    '& > p': {
      fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      margin: 0,
      width: 'fit-content',
    },
  },
  innerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
  },
  loadingContainer: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    minHeight: '8.25rem',
    minWidth: '16rem',
  },
  tableHeader: {
    fontWeight: 500,
    paddingRight: '1rem',
  },
  tableRow: {
    verticalAlign: 'top',
    paddingBottom: '0.5rem',
  },
});

type InterventionMap = {
  [key: string]: {
    count: number;
    items: {
      [key: string]: EventIntervention[];
    };
  };
};

export const EventsInterventionsSummary = ({
  interventions,
  loading,
}: Props) => {
  const styles = useStyles();
  const [interventionsMap, setInterventionsMap] = useState<InterventionMap>({});

  useEffect(() => {
    const interventionsMap = interventions.reduce((acc, item) => {
      const { category, description } = item.type;
      const categoryKey = category?.description || translate('global.other');
      if (!acc[categoryKey]) {
        acc[categoryKey] = {
          items: {},
          count: 0,
        };
      }
      if (!acc[categoryKey].items[description]) {
        acc[categoryKey].items[description] = [];
      }
      acc[categoryKey].items[description].push(item);
      acc[categoryKey].count += 1;
      return acc;
    }, {} as InterventionMap);
    setInterventionsMap(interventionsMap);
  }, [interventions]);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <Spinner color="primary" width={36} />
      </div>
    );
  }

  return Object.keys(interventionsMap)?.length ? (
    <div className={styles.container}>
      <h6>{translate('memberEvents.interventionsSummary')}</h6>
      <div className={styles.innerContainer}>
        {Object.keys(interventionsMap)
          .sort((a, b) => a.localeCompare(b))
          .map((category, idx) => (
            <Chip
              color={
                ['primary', 'secondary', 'info', 'warning'][
                  idx % 4
                ] as ChipColor
              }
              key={category}
              type="outlined"
              title={
                <table width={350}>
                  <thead>
                    <th className={styles.tableHeader} style={{ width: 200 }}>
                      {translate('memberEvents.interventionName')}
                    </th>
                    <th className={styles.tableHeader} style={{ width: 40 }}>
                      {translate('memberEvents.interventionCount')}
                    </th>
                    <th className={styles.tableHeader} style={{ width: 150 }}>
                      {translate('memberEvents.interventionDates')}
                    </th>
                  </thead>
                  <tbody>
                    {Object.keys(interventionsMap[category].items)?.map(
                      (type: string, index: number) => (
                        <tr key={index}>
                          <td className={styles.tableRow}>{type}</td>
                          <td className={styles.tableRow}>
                            {interventionsMap[category].items[type]?.length}
                          </td>
                          <td className={styles.tableRow}>
                            {(interventionsMap[category].items[type].length >
                            MAX_ENTRIES
                              ? [
                                  ...interventionsMap[category].items[type],
                                ].slice(0, MAX_ENTRIES - 1)
                              : interventionsMap[category].items[type]
                            ).map(
                              (
                                { createdAt }: EventIntervention,
                                index: number,
                              ) => (
                                <Fragment key={index}>
                                  {createdAt.format(
                                    DATE_FORMAT_SHORT_WITH_TIME,
                                  )}
                                  <br />
                                </Fragment>
                              ),
                            )}
                            {interventionsMap[category].items[type].length >
                              MAX_ENTRIES && '...'}
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
              }
            >
              <span>
                {interventionsMap[category].count} {category}
              </span>
            </Chip>
          ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default EventsInterventionsSummary;
