import * as React from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Button, ListPickerWithPreferred, Modal } from 'styleguide';
import { Option } from 'styleguide/src/components/ListPickerWithPreferred/ListPickerRow';

import './main.less';

interface Props {
  autoFocus?: boolean;
  show: boolean;
  items: string[];
  preferred: string;
  readOnlyPreferred?: boolean;
  options: Option[];
  required?: boolean;
  sort?: (values: string[]) => string[];
  onSave: (items: string[], preferred: string) => Promise<boolean>;
  onClose: () => void;
}

interface State {
  submitted: boolean;
  loading: boolean;
  preferred: string;
  readOnlyPreferred?: boolean;
  items: string[];
}

class EditListModal extends React.Component<Props, State> {
  state: State = {
    preferred: this.props.preferred || '',
    readOnlyPreferred: this.props.readOnlyPreferred,
    items: this.props.items ? [...this.props.items] : [],
    submitted: false,
    loading: false,
  };

  isValid(): boolean {
    const { items, preferred } = this.state;
    return !!(items.length && preferred);
  }

  async onSave() {
    const { required } = this.props;
    const { items, preferred } = this.state;

    if (required) {
      this.setState({ submitted: true });
      if (!this.isValid()) {
        return;
      }
    }
    try {
      this.setState({ loading: true });
      await this.props.onSave(items, preferred);
      this.close();
    } finally {
      this.setState({ loading: false });
    }
  }

  close() {
    this.setState({ submitted: false });
    this.props.onClose();
  }

  renderLanguageList(items: string[]) {
    const { preferred } = this.state;

    if (items.length) {
      return items.map((language: string, index: number) => (
        <div className="column-list-item" key={index}>
          <a key={index}>{language}</a>
          {language === preferred && (
            <>
              &nbsp;
              <a className="small">(preferred)</a>
            </>
          )}
        </div>
      ));
    }

    // no values
    return EMPTY;
  }

  render() {
    const { autoFocus, options, sort, required, show } = this.props;
    const {
      readOnlyPreferred,
      preferred,
      items,
      submitted,
      loading,
    } = this.state;

    let formattedItems = items;

    if (sort) {
      formattedItems = sort(items);
    }

    return (
      <Modal show={show} onHide={() => this.close()}>
        <Modal.Header
          modalTitle={translate('contacts.addLanguage')}
          closeButton
        />
        <Modal.Body>
          <ListPickerWithPreferred
            autoFocus={autoFocus}
            options={options}
            preferred={preferred}
            readOnlyPreferred={readOnlyPreferred}
            selection={formattedItems}
            onChange={(items: string[], preferred: string) =>
              this.setState({ items, preferred })
            }
            errorText={translate('global.pleaseMakeSelection')}
            required={required}
            submitted={submitted}
            validator={() => this.isValid()}
          />
        </Modal.Body>
        <Modal.Footer className="edit-language-footer">
          <div className="left-side">
            <Button color="secondary" onClick={() => this.close()}>
              {translate('global.cancel')}
            </Button>
          </div>

          <Button
            color="primary"
            loading={loading}
            onClick={() => this.onSave()}
          >
            {translate('global.save')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditListModal;
