import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/styles';

import { AsyncTaskType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';

import { CollapseIcon, Colors, Link, Text } from 'styleguide';
import { Button, CheckboxWithLabel, Modal, TextArea } from 'styleguide-v2';

import { ValidationResponse } from 'dash/src/services/RosterServices';

import { CSVFileInfo } from '.';
import AddLinkModal from './AddLinkModal';

function getFileName(fileInfo: CSVFileInfo) {
  return fileInfo.uuid
    ? translate('rosterIngestion.successfullyAdded', {
        fileName: fileInfo.file.name,
      })
    : translate('rosterIngestion.previewBelow', {
        fileName: fileInfo.file.name,
      });
}

function getFileCount(fileInfo: CSVFileInfo, hasErrors: boolean) {
  if (hasErrors) {
    return translate('rosterIngestion.cannotCreateUsingThisCSV');
  }

  return fileInfo.uuid
    ? translate('rosterIngestion.profilesCreated', {
        identifierType: fileInfo.isCareTeamFile ? 'care team' : 'member',
        count: fileInfo.fileContents.length,
      })
    : translate('rosterIngestion.profilesWillBeCreated', {
        identifierType: fileInfo.isCareTeamFile ? 'care team' : 'member',
        count: fileInfo.fileContents.length,
      });
}

const useStyles = makeStyles({
  buttonLink: {
    minWidth: '20rem',
  },
  downloadInfoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoContainerFlexItem: {
    flex: '1 0 0',
  },
  infoContainerButton: {
    marginLeft: '1em',
  },
  issuesBox: {
    backgroundColor: Colors.bkgdBlue,
    padding: '1em',
  },
});

interface Props {
  fileInfo: CSVFileInfo;
  onUpload: (options: {
    applyFutureLead?: boolean;
    applyTermByAbsence?: boolean;
    description?: string;
    rawArchiveId?: number;
    shouldReplaceCT?: boolean;
  }) => Promise<void>;
  validationResponse: ValidationResponse;
  validating: boolean;
  uploadInProgress: boolean;
  errorValidate: boolean;
  rosterType: AsyncTaskType;
}

export function FileInfoPanel({
  fileInfo,
  onUpload,
  validationResponse,
  validating,
  uploadInProgress,
  errorValidate,
  rosterType,
}: Props) {
  const { disableAddMembers, showRosterHistory } = useFlags();
  const styles = useStyles();
  const [expandErrors, setExpandErrors] = useState(false);
  const [expandWarnings, setExpandWarnings] = useState(false);
  const [expandInfo, setExpandInfo] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [description, setDescription] = useState<string>();
  const [shouldReplaceCT, setShouldReplaceCT] = useState<boolean>();
  const hasInfo = !!validationResponse.info.length;
  const hasErrors = !!validationResponse.errors.length;
  const hasWarnings = !!validationResponse.warnings.length;
  const rowsWithDuplicated = validationResponse.warnings
    .filter((row) => row.type === 'DUPLICATE_ENTITY')
    .map((row) => row.row);

  const rows = [
    ...fileInfo?.fileContents.map((item, index) => ({
      ...item,
      originalRow: index,
    })),
  ].sort(({ originalRow: a }, { originalRow: b }) => {
    if (rowsWithDuplicated.includes(a)) return 1;
    if (rowsWithDuplicated.includes(b)) return -1;
    return 0;
  });

  useEffect(() => {
    // Auto-expand if error count is le than 10
    setExpandErrors(
      validationResponse && validationResponse.errors.length <= 10,
    );
    setExpandWarnings(
      validationResponse && validationResponse.warnings.length <= 10,
    );
    setExpandInfo(validationResponse && validationResponse.info.length <= 10);
  }, [validationResponse]);

  useEffect(() => {
    setShowDescriptionModal(false);
    setDescription(undefined);
    setShowLinkModal(false);
  }, [rosterType]);

  useEffect(() => {
    if (!showDescriptionModal) {
      setTimeout(() => setDescription(undefined), 250);
    }
  }, [showDescriptionModal]);

  return (
    <>
      <div className={`grid-span-12 ${styles.downloadInfoContainer}`}>
        <div className={styles.infoContainerFlexItem}>
          <Text bold color={hasErrors ? 'error' : 'highlight'}>
            {getFileName(fileInfo)}
          </Text>
          <Text>{getFileCount(fileInfo, hasErrors)}</Text>
        </div>
        <div className={styles.infoContainerButton}>
          {showRosterHistory && rosterType === AsyncTaskType.MEMBER_ROSTER && (
            <>
              <Button
                className={styles.buttonLink}
                style={{ marginRight: '1em' }}
                disabled={
                  disableAddMembers ||
                  hasErrors ||
                  uploadInProgress ||
                  errorValidate
                }
                loading={validating}
                onClick={() => {
                  setShowLinkModal(true);
                }}
                color="secondary"
                type="outlined"
                size="s"
              >
                {translate('rosterIngestion.linkToRawFile')}
                {disableAddMembers &&
                  ` (${translate('global.disabledDueToMaintenance')})`}
              </Button>
              {showLinkModal && (
                <AddLinkModal
                  onHide={() => {
                    setShowLinkModal(false);
                  }}
                  onSubmit={async (
                    applyFutureLead,
                    applyTermByAbsence,
                    rawArchiveId,
                  ) => {
                    setShowLinkModal(false);
                    await onUpload({
                      applyFutureLead,
                      applyTermByAbsence,
                      rawArchiveId,
                    });
                  }}
                />
              )}
            </>
          )}
          {showRosterHistory && rosterType === AsyncTaskType.CARE_TEAM_ROSTER && (
            <>
              <Button
                className={styles.buttonLink}
                style={{ marginRight: '1em' }}
                disabled={
                  disableAddMembers ||
                  hasErrors ||
                  uploadInProgress ||
                  errorValidate
                }
                loading={validating}
                onClick={() => {
                  setShowDescriptionModal(true);
                }}
                color="secondary"
                type="outlined"
                size="s"
              >
                {translate('rosterIngestion.uploadFile')}
                {disableAddMembers &&
                  ` (${translate('global.disabledDueToMaintenance')})`}
              </Button>
              <Modal
                title={translate('rosterIngestion.uploadFile')}
                submitDisabled={!description}
                onClose={() => {
                  setShowDescriptionModal(false);
                }}
                onSubmit={async () => {
                  setShowDescriptionModal(false);
                  await onUpload({ description, shouldReplaceCT });
                }}
                open={showDescriptionModal}
              >
                <TextArea
                  label={translate('rosterIngestion.uploadFileDescription')}
                  minRows={3}
                  onChange={setDescription}
                  value={description}
                />
                <CheckboxWithLabel
                  checked={!!shouldReplaceCT}
                  label={translate('rosterIngestion.shouldReplaceCT')}
                  onChange={setShouldReplaceCT}
                  tooltip={translate('rosterIngestion.shouldReplaceCTTooltip')}
                />
              </Modal>
            </>
          )}
        </div>
      </div>
      {hasErrors && (
        <div className="grid-span-12 grid-wrapper-none">
          <Text className="h3" color="error" columns={12}>
            <i className="fa fa-warning margin-right" />
            {translate('rosterIngestion.followingMustBeFixed', {
              count: validationResponse.errors.length,
            })}
            <Button
              className="margin-left"
              color="quaternary"
              onClick={() => setExpandErrors(!expandErrors)}
              size="s"
            >
              <CollapseIcon active={expandErrors} className="margin-right" />
              {translate('global.expand')}
            </Button>
          </Text>
          {expandErrors && (
            <div className={`grid-span-12 ${styles.issuesBox}`}>
              {validationResponse.errors
                .map((error) => ({
                  ...error,
                  originalRow: rows.find(
                    ({ originalRow: idx }) => idx === error.row,
                  )?.originalRow,
                }))
                .sort(({ originalRow: a }, { originalRow: b }) => a - b)
                .map((error, index) => {
                  const isDuplicateParam =
                    error.type === 'DUPLICATE_PARAM' &&
                    fileInfo?.isCareTeamFile;
                  const message = isDuplicateParam
                    ? translate('rosterIngestion.possibleDuplicate', {
                        row:
                          error.originalRow !== -1
                            ? error.originalRow + 1
                            : 'Unknown',
                        indentifier:
                          (rows.findIndex(
                            ({ originalRow }) =>
                              originalRow === error.duplicateIdentity,
                          ) || 0) + 1,
                        // eslint-disable-next-line no-nested-ternary
                        identifierType: 'row',
                        extraMsg: '',
                      })
                    : translate('rosterIngestion.rowError', {
                        row:
                          error.originalRow !== -1
                            ? error.originalRow + 1
                            : 'Unknown',
                        errorMsg: error.messages?.join(' ; '),
                      });
                  return <p key={`validation-error-${index}`}>{message}</p>;
                })}
            </div>
          )}
        </div>
      )}
      {hasWarnings && (
        <div className="grid-span-12 grid-wrapper-none">
          <Text className="h3" color="warning" columns={12}>
            <i className="fa fa-warning margin-right" />
            {translate('rosterIngestion.followingWarnings', {
              count: validationResponse.warnings.length,
            })}
            <Button
              className="margin-left"
              color="quaternary"
              onClick={() => setExpandWarnings(!expandWarnings)}
              size="s"
            >
              <CollapseIcon active={expandWarnings} className="margin-right" />
              {translate('global.expand')}
            </Button>
          </Text>
          {expandWarnings && (
            <div className={`grid-span-12 ${styles.issuesBox}`}>
              {validationResponse.warnings
                .map((warning) => ({
                  ...warning,
                  originalRow: rows.find(
                    ({ originalRow: idx }) => idx === warning.row,
                  )?.originalRow,
                }))
                .sort(({ originalRow: a }, { originalRow: b }) => a - b)
                .map((warning, index) => {
                  const isDuplicateEntity = warning.type === 'DUPLICATE_ENTITY';
                  const showLink =
                    isDuplicateEntity && !fileInfo.isCareTeamFile;
                  const message = isDuplicateEntity
                    ? translate('rosterIngestion.possibleDuplicate', {
                        row:
                          warning.originalRow !== -1
                            ? warning.originalRow + 1
                            : 'Unknown',
                        indentifier: warning.duplicateIdentity,
                        // eslint-disable-next-line no-nested-ternary
                        identifierType: isDuplicateEntity
                          ? fileInfo.isCareTeamFile
                            ? 'care team'
                            : 'member'
                          : 'row',
                        extraMsg: warning.messages?.length
                          ? `(${warning.messages.join('; ')})`
                          : '',
                      })
                    : translate('rosterIngestion.rowError', {
                        row:
                          warning.originalRow !== -1
                            ? warning.originalRow + 1
                            : 'Unknown',
                        errorMsg: warning.messages?.join(' ; '),
                      });
                  return showLink ? (
                    <p key={index}>
                      <Link
                        columns={12}
                        openNewTab
                        href={`#/patients/${warning.duplicateIdentity}/personal-details`}
                      >
                        {message}
                        {` ${translate('rosterIngestion.updateEntityWarning')}`}
                      </Link>
                    </p>
                  ) : (
                    <p key={`validation-warning-${index}`}>
                      {message}
                      {isDuplicateEntity
                        ? ` ${translate('rosterIngestion.skipRowWarning')}`
                        : ''}
                    </p>
                  );
                })}
            </div>
          )}
        </div>
      )}
      {hasInfo && (
        <div className="grid-span-12 grid-wrapper-none">
          <Text className="h3" color="info" columns={12}>
            <i className="fa fa-info margin-right" />
            {translate('rosterIngestion.followingInfo', {
              count: validationResponse.info.length,
            })}
            <Button
              className="margin-left"
              color="quaternary"
              onClick={() => setExpandInfo(!expandInfo)}
              size="s"
            >
              <CollapseIcon active={expandInfo} className="margin-right" />
              {translate('global.expand')}
            </Button>
          </Text>
          {expandInfo && (
            <div className={`grid-span-12 ${styles.issuesBox}`}>
              {validationResponse.info
                .map((info) => ({
                  ...info,
                  originalRow: rows.find(
                    ({ originalRow: idx }) => idx === info.row,
                  )?.originalRow,
                }))
                .sort(({ originalRow: a }, { originalRow: b }) => a - b)

                .map((info, index) => {
                  const message = translate('rosterIngestion.rowError', {
                    row:
                      info.originalRow !== -1
                        ? info.originalRow + 1
                        : 'Unknown',
                    errorMsg: info.messages?.join('; '),
                  });
                  return <p key={`validation-info-${index}`}>{message}</p>;
                })}
            </div>
          )}
        </div>
      )}
    </>
  );
}
