import classnames from 'classnames';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  EventDetail,
  EventEvaluation,
  EventIntervention,
  EventNote,
  EventTriage,
  IssueDetailFieldGroup,
  IssuePrompt,
  Patient,
} from '@vestahealthcare/common/models';
import { EventIssue } from '@vestahealthcare/common/models/EventIssue';
import EventStatus from '@vestahealthcare/common/models/EventStatus';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import {
  Actions,
  Button,
  Colors,
  Fonts,
  Modal,
  Spinner,
  Tabs,
  TextArea,
} from 'styleguide-v2';
import { TabsItem } from 'styleguide-v2/src/components/Tabs';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { EscalationCriteriaModal } from 'dash/src/pages/MemberProfile/Events/modals/EscalationCriteriaModal';
import { CacheServices } from 'dash/src/services';
import { updateMeasurement } from 'dash/src/services/BiometricsServices';
import {
  IssueDetail,
  acceptEventIssuePrompt,
  completeEventEvaluation,
  createEventEvaluation,
  createEventEvaluationNote,
  createEventIntervention,
  createEventTriage,
  fetchEventDetail,
  unlinkEvent,
  updateEvent,
  updateEventEvaluationNote,
  updateEventIssue,
} from 'dash/src/services/EventServices';
import Session from 'dash/src/services/SessionServices';

import { EventDetailEDDiversion } from './components/EventDetailEDDiversion';
import { EventDetailEncounters } from './components/EventDetailEncounters';
import { EventDetailEvaluation } from './components/EventDetailEvaluation';
import { EventDetailIssues } from './components/EventDetailIssues';
import { EventDetailLinkedEvents } from './components/EventDetailLinkedEvents';
import { EventDetailNotes } from './components/EventDetailNotes';
import { EventDetailTasks } from './components/EventDetailTasks';
import { MemberEvaluationAddIntervention } from './modals/MemberEvaluationAddIntervention';
import { MemberEvaluationAddTriageModal } from './modals/MemberEvaluationAddTriage';
import { MemberEvaluationShowTriageModal } from './modals/MemberEvaluationShowTriage';
import { MemberEventAddNote } from './modals/MemberEventAddNote';
import { MemberEventEditIssue } from './modals/MemberEventEditIssue';
import { EventActions } from './types/EventActions';
import { EventEvaluationActions } from './types/EventEvaluationActions';

type StyleProps = {
  numberOfIssues?: number;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  detailContainer: {
    display: 'flex',
    flexFlow: 'column',
    gap: '1rem',
    position: 'relative',
  },
  infoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    justifyContent: 'space-between',

    '& > .chief-complaint': {
      flex: '10 1 55rem',
    },

    '& > .info': {
      display: 'flex',
      flex: '0 1 max-content',
      flexFlow: 'column',
      gap: '1rem',
      margin: '0 1rem 0 0',
    },
  },
  evaluationTabs: {
    background: `${Colors.iconGreen}`,
    border: '5px solid transparent',
    borderBottom: 0,
    borderRadius: '0.5rem 0.5rem 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '0.5rem',

    '& .MuiTabs-root': {
      '& .MuiTabs-flexContainer': {
        '& > button.MuiTab-root': {
          background: '#FFFFFFC7',
          borderRadius: '1rem 1rem 0 0',
          boxShadow: `0px 0px 5px 0px ${Colors.lightGray}`,
        },
        '& > button.MuiTab-root.Mui-selected': {
          background: '#FFFFFF',
          boxShadow: `0px -5px 5px 5px ${Colors.lightGray}`,
          zIndex: 1,

          '&:first-child': {
            boxShadow: `5px 0px 5px 0px ${Colors.lightGray}`,
          },

          '&:last-child': {
            boxShadow: `-5px 0px 5px 0px ${Colors.lightGray}`,
          },

          '&:only-child': {
            boxShadow: 'none',
          },
        },
      },
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    },

    '& > .evaluation-actions': {
      marginTop: '-0.5rem',

      '& svg': {
        color: Colors.white,
      },
    },
  },
  initialActions: {
    display: 'flex',
    gap: '2rem',
    justifyContent: 'center',
  },
  initialActionButton: {
    minWidth: '20rem',
  },
  evaluationDetail: {
    border: `5px solid ${Colors.iconGreen}`,
    borderRadius: '0 0 0.5rem 0.5rem',
    borderTop: 0,
  },
  loadingContainer: {
    background: 'white',
    height: '100%',
    minHeight: '20rem',
    opacity: 0.5,
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    '& > *': {
      margin: '7.5rem auto',
    },
  },
  closeEvaluationButton: {
    display: 'flex',
    margin: 'auto',
  },
  tabAddEvaluation: {
    display: 'flex',
    '&& > *': {
      fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    },
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
  },
  cardItem: {
    flex: '1 1 55rem',
  },
  notes: {
    [theme.breakpoints.up(1450)]: {
      flex: '0 50%',
    },
  },
}));

type Props = {
  patient?: Patient;
  eventId: number;
  onEventAction: (
    action: Selectable,
    event: EventDetail,
    index?: number,
    modalData?: any,
  ) => Promise<void>;
  onCreateIntervention?: () => Promise<void>;
  onRetrieveDetail?: (event: EventDetail) => void;
  onUnlinkIssue?: (event: EventDetail, issues: EventIssue[]) => void;
};

export type RefreshHandle = {
  editIssue: (issue: EventIssue, submitted?: boolean) => void;
  missingDetails: () => void;
  refresh: () => void;
  unlinkIssues: () => void;
};

export const MemberEventsDetail = forwardRef(
  (
    {
      eventId,
      onEventAction,
      onCreateIntervention,
      onRetrieveDetail,
      onUnlinkIssue,
      patient,
    }: Props,
    ref,
  ) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingClose, setLoadingClose] = useState<boolean>(false);
    const [loadingCloseDocumented, setLoadingCloseDocumented] = useState<
      boolean
    >(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [ccTimer, setCCTimer] = useState<NodeJS.Timeout>();
    const [issuesMode, setIssuesMode] = useState<
      'normal' | 'unlink' | 'missingDetails'
    >('normal');

    const [editNoteModal, setEditNoteModal] = useState<
      false | 'evaluation' | 'event'
    >(false);
    const [editIssueModal, setEditIssueModal] = useState<boolean>(false);
    const [editIssueModalSubmitted, setEditIssueModalSubmitted] = useState<
      boolean
    >(false);
    const [addInterventionModal, setAddInterventionModal] = useState<boolean>(
      false,
    );
    const [addTriageModal, setAddTriageModal] = useState<boolean>(false);
    const [showTriageModal, setShowTriageModal] = useState<boolean>(false);
    const [showPromptModal, setShowPromptModal] = useState<boolean>(false);
    const [showConfirmNewEvaluation, setShowConfirmNewEvaluation] = useState<
      boolean
    >(false);
    const [modalData, setModalData] = useState<any>();
    let evaluationCreated = false;

    const [eventDetail, setEventDetail] = useState<EventDetail>();
    const [chiefComplaint, setChiefComplaint] = useState<string>();

    const [evaluationTabs, setEvaluationTabs] = useState<TabsItem[]>([]);
    const [evaluationTabSelected, setEvaluationSelected] = useState<number>();

    const { actingUser } = Session;
    const showEventCloseButton =
      (!eventDetail?.isClosed && !!eventDetail?.evaluations?.length) ||
      eventDetail?.canForceCloseEvent(actingUser, patient);
    const showEventInvalidateButton = eventDetail?.canInvalidateEvent(
      actingUser,
    );
    const eventCanBeClosed =
      eventDetail?.canCloseEvent() ||
      eventDetail?.canForceCloseEvent(actingUser, patient);

    const [issueDetailFields, setIssueDetailFields] = useState<
      IssueDetailFieldGroup[]
    >([]);

    const styles = useStyles({ numberOfIssues: eventDetail?.issues?.length });

    const mapEvaluationToTab = (
      { id, closed }: EventEvaluation,
      index: number,
    ): TabsItem => ({
      value: id,
      label: `${translate('memberEvents.detail.evaluationTab', {
        count: index + 1,
      })} ${!closed ? `(${translate('global.open')})` : ''}`,
    });

    const getInitialData = async () => {
      const issueDetailFields = await CacheServices.getIssueDetailFields();
      setIssueDetailFields(issueDetailFields);
    };

    useEffect(() => {
      getInitialData();
    }, []);

    const getEventDetail = async (preserveSelectedTab?: boolean) => {
      setLoading(true);

      try {
        const detail = await fetchEventDetail(eventId);
        setEventDetail(detail);
        setChiefComplaint(detail.chiefComplaint);

        const tabs = detail.evaluations.map(mapEvaluationToTab);
        if (detail.hasEvaluationOpen() || detail.isClosed) {
          setEvaluationTabs(tabs);
        } else {
          setEvaluationTabs([
            ...tabs,
            {
              value: -1,
              label: (
                <div className={styles.tabAddEvaluation}>
                  <AddIcon />
                  <span>
                    {translate('memberEvents.detail.evaluationTabNew')}
                  </span>
                </div>
              ),
            },
          ]);
        }
        if (tabs.length && !preserveSelectedTab) {
          setEvaluationSelected(tabs[tabs.length - 1]?.value);
        }
        const prompts = detail.getPendingPrompts();
        if (prompts?.length) {
          setModalData(prompts[0]);
          setShowPromptModal(true);
        }
        if (onRetrieveDetail) {
          onRetrieveDetail(detail);
        }
      } catch (e) {
        showGlobalError(e as string);
      }

      setLoading(false);
    };

    useEffect(() => {
      if (eventId) {
        getEventDetail();
      }
    }, [eventId]);

    useImperativeHandle(ref, () => ({
      editIssue: (issue: IssueDetail, submitted?: boolean) => {
        setModalData(issue);
        setEditIssueModalSubmitted(!!submitted);
        setEditIssueModal(true);
      },
      missingDetails: () => {
        setIssuesMode('missingDetails');
        setTimeout(() => setIssuesMode('normal'), 1000);
      },
      refresh: () => getEventDetail(),
      unlinkIssues: () => setIssuesMode('unlink'),
    }));

    const startEvaluation = async (button?: boolean) => {
      if (eventDetail) {
        if (button) {
          setButtonLoading(true);
        } else {
          setLoading(true);
        }
        try {
          await createEventEvaluation(eventDetail.id);
          await getEventDetail();
        } catch (e) {
          showGlobalError(e as string);
        }
        setButtonLoading(false);
        setLoading(false);
      }
    };

    const updateChiefComplaint = async (chiefComplaint: string) => {
      if (
        eventDetail &&
        chiefComplaint &&
        chiefComplaint !== eventDetail?.chiefComplaint
      ) {
        try {
          await updateEvent(eventId, { chiefComplaint });
          eventDetail.chiefComplaint = chiefComplaint;
        } catch (e) {
          showGlobalError(e as string);
        }
      }
    };

    useEffect(() => {
      if (chiefComplaint) {
        if (ccTimer) {
          clearTimeout(ccTimer);
        }
        setCCTimer(
          setTimeout(() => updateChiefComplaint(chiefComplaint), 1000),
        );
      }
    }, [chiefComplaint]);

    const completeEvaluation = async () => {
      setLoading(true);
      if (eventDetail?.id && evaluationSelected?.id)
        try {
          await completeEventEvaluation(eventDetail.id, evaluationSelected?.id);
          await getEventDetail();
        } catch (e) {
          showGlobalError(e as string);
        }

      setLoading(false);
    };

    const onEvaluationAction = ({ value }: Selectable) => {
      if (value === EventEvaluationActions.ADD_ADDENDUM.value) {
        setEditNoteModal('evaluation');
        setModalData(new EventNote({ addendum: true }));
      } else if (value === EventEvaluationActions.ADD_INTERVENTION.value) {
        setAddInterventionModal(true);
      } else if (value === EventEvaluationActions.ADD_NOTE.value) {
        setEditNoteModal('evaluation');
        setModalData(new EventNote({}));
      } else if (value === EventEvaluationActions.ADD_TRIAGE.value) {
        setAddTriageModal(true);
      }
    };

    const evaluationSelected = eventDetail?.evaluations.find(
      ({ id }) => id === evaluationTabSelected,
    );

    const renderButtonCloseDocumented = () => (
      <Button
        className={styles.initialActionButton}
        color="primary"
        icon={<DeleteIcon />}
        disabled={
          !eventDetail?.canCloseDocumented(actingUser) &&
          translate('memberEvents.detail.warningNoAssignedYouEvent')
        }
        loading={loadingCloseDocumented}
        onClick={async () => {
          if (eventDetail) {
            setLoadingCloseDocumented(true);
            await onEventAction(
              {
                value: EventActions.CLOSE_DOCUMENTED.value,
                label: EventActions.CLOSE_DOCUMENTED.toString(),
              } as Selectable,
              eventDetail,
            );
            setLoadingCloseDocumented(false);
          }
        }}
      >
        {translate('memberEvents.detail.closeDocumented')}
      </Button>
    );

    return (
      <div className={styles.detailContainer}>
        {loading && (
          <div className={styles.loadingContainer}>
            <Spinner color="primary" width={75} />
          </div>
        )}
        <div className={styles.detailContainer}>
          <div>
            <EscalationCriteriaModal />
          </div>
          <EventDetailIssues
            interventions={eventDetail?.evaluations.reduce(
              (acc, { interventions }) => [...acc, ...interventions],
              [] as EventIntervention[],
            )}
            issues={eventDetail?.issues}
            mode={issuesMode}
            onEditIssue={
              !eventDetail?.isClosed || Session.actingUser.isAdmin
                ? (issue) => {
                    setEditIssueModal(true);
                    setModalData(issue);
                  }
                : undefined
            }
            onUnlinkCancel={() => setIssuesMode('normal')}
            onUnlinkSubmit={(issues) => {
              if (eventDetail && onUnlinkIssue) {
                onUnlinkIssue(eventDetail, issues);
              }
            }}
          />
          <div className={styles.infoContainer}>
            <TextArea
              className="chief-complaint"
              label={translate('memberEvents.chiefComplaint')}
              rows={5}
              onChange={setChiefComplaint}
              readOnly={eventDetail?.isClosed}
              value={chiefComplaint || (eventDetail?.isClosed ? EMPTY : '')}
            />
            {(!!eventDetail?.encounters?.length ||
              !!eventDetail?.tasks?.length) && (
              <div className="info">
                <EventDetailEncounters
                  patient={patient}
                  encounters={eventDetail?.encounters}
                />
                <EventDetailTasks
                  patient={patient}
                  tasks={eventDetail?.tasks}
                />
              </div>
            )}
            {!!eventDetail?.links?.length && (
              <div className="info">
                <EventDetailLinkedEvents
                  event={eventDetail}
                  patient={patient}
                  onRemove={async (eventLink) => {
                    try {
                      await unlinkEvent(eventLink);
                      getEventDetail(true);
                    } catch (e) {
                      showGlobalError(e as string);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {!eventDetail?.isClosed &&
          !eventDetail?.evaluations?.length &&
          !loading && (
            <div className={styles.initialActions}>
              {renderButtonCloseDocumented()}
              <Button
                className={styles.initialActionButton}
                color="primary"
                icon={<DeleteIcon />}
                disabled={
                  !eventDetail?.canInvalidateEvent(actingUser) &&
                  translate('memberEvents.detail.warningNoAssignedEvent')
                }
                onClick={() => {
                  if (eventDetail) {
                    onEventAction(
                      {
                        value: EventActions.INVALIDATE_EVENT.value,
                        label: EventActions.INVALIDATE_EVENT.toString(),
                      } as Selectable,
                      eventDetail,
                    );
                  }
                }}
              >
                {translate('memberEvents.detail.invalidate')}
              </Button>
              {showEventCloseButton && (
                <Button
                  className={styles.initialActionButton}
                  color="secondary"
                  icon={<CloseIcon />}
                  loading={loadingClose}
                  onClick={async () => {
                    if (eventDetail) {
                      setLoadingClose(true);
                      await onEventAction(
                        {
                          value: EventActions.CLOSE_EVENT.value,
                          label: EventActions.CLOSE_EVENT.toString(),
                        } as Selectable,
                        eventDetail,
                      );
                      setLoadingClose(false);
                    }
                  }}
                  type="outlined"
                >
                  {translate('enums.eventActions.CLOSE_EVENT')}
                </Button>
              )}
              <Button
                color="secondary"
                className={styles.initialActionButton}
                disabled={
                  !eventDetail?.assignee &&
                  translate('memberEvents.detail.warningNoAssignedEvent')
                }
                loading={buttonLoading}
                onClick={() => startEvaluation(true)}
              >
                {translate('memberEvents.detail.startEvaluation')}
              </Button>
            </div>
          )}
        {!!eventDetail?.evaluations?.length && (
          <div>
            <div className={styles.evaluationTabs}>
              {evaluationTabs && evaluationTabSelected && (
                <Tabs
                  items={evaluationTabs}
                  onChange={async (val: number) => {
                    if (val === -1) {
                      setShowConfirmNewEvaluation(true);
                    }
                    evaluationCreated = false;
                    setEvaluationSelected(val);
                  }}
                  value={evaluationTabSelected}
                />
              )}
              <div className="flex evaluation-actions">
                <Actions
                  actions={
                    evaluationSelected?.closed
                      ? Enum.toSelectable(EventEvaluationActions.asClosedArray)
                      : Enum.toSelectable(EventEvaluationActions.asOpenArray)
                  }
                  onActionSelected={onEvaluationAction}
                />
              </div>
            </div>
            {evaluationTabSelected && (
              <EventDetailEvaluation
                className={styles.evaluationDetail}
                evaluation={evaluationSelected}
                event={eventDetail}
                onCompleteEvaluation={completeEvaluation}
                onAddIntervention={() => {
                  setAddInterventionModal(true);
                }}
                onAddTriage={() => {
                  setAddTriageModal(true);
                }}
                onEditNote={(note) => {
                  setEditNoteModal('evaluation');
                  setModalData(note);
                }}
                onUpdateEvaluation={() => {}}
                onShowTriage={(triage) => {
                  setShowTriageModal(true);
                  setModalData(triage);
                }}
              />
            )}
          </div>
        )}
        <div className={styles.cardsContainer}>
          <EventDetailNotes
            className={classnames(styles.cardItem, styles.notes)}
            canAdd={!eventDetail?.isClosed}
            notes={eventDetail?.notes?.filter(({ addendum }) => !addendum)}
            onEdit={(note) =>
              eventDetail &&
              onEventAction(
                {
                  value: EventActions.ADD_NOTE.value,
                  label: EventActions.ADD_NOTE.toString(),
                } as Selectable,
                eventDetail,
                undefined,
                note,
              )
            }
          />
          {eventDetail?.status?.id === EventStatus.COMPLETE && (
            <EventDetailEDDiversion
              className={styles.cardItem}
              event={eventDetail}
              onEdit={() =>
                onEventAction(
                  {
                    value: EventActions.CLOSE_EVENT.value,
                    label: EventActions.CLOSE_EVENT.toString(),
                  } as Selectable,
                  eventDetail,
                )
              }
            />
          )}
          <EventDetailNotes
            addendum
            canAdd
            className={classnames(styles.cardItem, styles.notes)}
            notes={eventDetail?.notes?.filter(({ addendum }) => addendum)}
            onEdit={(note) =>
              eventDetail &&
              onEventAction(
                {
                  value: EventActions.ADD_NOTE.value,
                  label: EventActions.ADD_NOTE.toString(),
                } as Selectable,
                eventDetail,
                undefined,
                note,
              )
            }
          />
        </div>
        <div className={styles.initialActions}>
          {showEventCloseButton && renderButtonCloseDocumented()}
          {showEventInvalidateButton && !!eventDetail?.evaluations?.length && (
            <Button
              className={styles.initialActionButton}
              color="primary"
              icon={<DeleteIcon />}
              onClick={() => {
                if (eventDetail) {
                  setButtonLoading(true);
                  onEventAction(
                    {
                      value: EventActions.INVALIDATE_EVENT.value,
                      label: EventActions.INVALIDATE_EVENT.toString(),
                    } as Selectable,
                    eventDetail,
                  );
                }
              }}
            >
              {translate('memberEvents.detail.invalidate')}
            </Button>
          )}
          {showEventCloseButton && (
            <Button
              className={styles.initialActionButton}
              color="secondary"
              disabled={
                !eventCanBeClosed &&
                translate('memberEvents.detail.warningCloseEvent')
              }
              onClick={() =>
                eventDetail &&
                onEventAction(
                  {
                    value: EventActions.CLOSE_EVENT.value,
                    label: EventActions.CLOSE_EVENT.toString(),
                  } as Selectable,
                  eventDetail,
                )
              }
            >
              {translate('enums.eventActions.CLOSE_EVENT')}
            </Button>
          )}
        </div>

        <MemberEventAddNote
          note={modalData as EventNote}
          open={!!editNoteModal}
          onClose={() => setEditNoteModal(false)}
          onSubmit={async (note) => {
            let result = true;
            try {
              if (eventDetail?.id && evaluationTabSelected) {
                if (editNoteModal === 'evaluation') {
                  if (note.id) {
                    await updateEventEvaluationNote(
                      eventDetail?.id,
                      evaluationTabSelected,
                      note,
                    );
                  } else {
                    await createEventEvaluationNote(
                      eventDetail?.id,
                      evaluationTabSelected,
                      note,
                    );
                  }
                  await getEventDetail(true);
                }
              }
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
        />
        <MemberEventEditIssue
          issue={modalData as EventIssue}
          issueFields={issueDetailFields?.filter(
            ({ issueTypeId }) =>
              issueTypeId === (modalData as EventIssue)?.issueType?.id,
          )}
          open={editIssueModal}
          onClose={() => {
            setEditIssueModal(false);
            setEditIssueModalSubmitted(false);
          }}
          onSubmit={async ({ id }, params, readings) => {
            let result = true;
            if (eventDetail?.id && id) {
              try {
                await updateEventIssue(eventDetail?.id, id, params);
                if (readings?.length && patient?.id) {
                  const promises: Promise<any>[] = [];
                  readings.forEach(({ id, valid }, index) => {
                    if (modalData?.readings[index]?.valid !== valid) {
                      promises.push(
                        updateMeasurement(patient?.id, id, { valid }),
                      );
                    }
                  });
                  await Promise.all(promises);
                }
                await getEventDetail(true);
                onEventAction(
                  {
                    value: EventActions.CHECK_PENDING_ACTIONS.value,
                    label: EventActions.CHECK_PENDING_ACTIONS.toString(),
                  } as Selectable,
                  eventDetail,
                );
              } catch (e) {
                showGlobalError(e as string);
                result = false;
              }
            }
            return result;
          }}
          submitted={editIssueModalSubmitted}
        />
        <MemberEvaluationAddIntervention
          open={addInterventionModal}
          onClose={() => setAddInterventionModal(false)}
          onSubmit={async (params) => {
            let result = true;
            if (eventDetail?.id && evaluationSelected?.id) {
              try {
                await createEventIntervention(
                  eventDetail.id,
                  evaluationSelected?.id,
                  params,
                );
                await getEventDetail(true);
                if (onCreateIntervention) {
                  await onCreateIntervention();
                }
              } catch (e) {
                showGlobalError(e as string);
                result = false;
              }
            }
            return result;
          }}
        />
        <MemberEvaluationAddTriageModal
          evaluation={evaluationSelected}
          open={addTriageModal}
          onClose={() => setAddTriageModal(false)}
          onSubmit={async (evaluation, params) => {
            let result = true;
            try {
              await createEventTriage(evaluation.id, params);
              await getEventDetail(true);
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
        />
        <MemberEvaluationShowTriageModal
          triage={modalData as EventTriage}
          open={showTriageModal}
          onClose={() => setShowTriageModal(false)}
        />
        <Modal
          open={showPromptModal}
          body={modalData && <p>{(modalData as IssuePrompt).message}</p>}
          maxWidth="sm"
          onSubmit={async () => {
            try {
              if (eventDetail) {
                const issue = eventDetail.issues?.find(
                  ({ pendingPrompts }) =>
                    !!pendingPrompts?.find(
                      ({ id }) => id === (modalData as IssuePrompt).id,
                    ),
                );
                if (issue) {
                  await acceptEventIssuePrompt(
                    eventDetail?.id,
                    issue.id,
                    modalData.id,
                  );
                  issue.pendingPrompts?.shift();
                  const prompts = eventDetail.getPendingPrompts();
                  if (prompts.length) {
                    setModalData(prompts[0]);
                  } else {
                    setShowPromptModal(false);
                  }
                }
              }
            } catch (e) {
              showGlobalError(e as string);
            }
          }}
          title={translate('global.warning')}
        />
        <Modal
          title={translate('memberEvents.detail.confirmNewEvaluationTitle')}
          onClose={() => {
            if (!evaluationCreated) {
              setEvaluationSelected(
                evaluationTabs[evaluationTabs.length - 2]?.value,
              );
            }
            setShowConfirmNewEvaluation(false);
          }}
          onSubmit={async () => {
            await startEvaluation();
            evaluationCreated = true;
          }}
          open={showConfirmNewEvaluation}
        >
          <p className="no-margin">
            {translate('memberEvents.detail.confirmNewEvaluationBody')}
          </p>
          <p className="no-margin red">
            {translate('memberEvents.detail.confirmNewEvaluationWarning')}
          </p>
        </Modal>
      </div>
    );
  },
);

export default MemberEventsDetail;
