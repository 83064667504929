import moment, { Moment } from 'moment/moment';

import { InsuranceLeadQualification, InsuranceQualification } from '../enums';
import { Employee } from './Employee';
import { make } from './Model';

export class MemberInsuranceQualification {
  memberId = 0;

  insuranceQualification: InsuranceQualification;

  insuranceQualificationUpdatedAt?: Moment;

  insuranceQualificationUpdatedBy: Employee;

  leadQualification: InsuranceLeadQualification;

  leadQualificationUpdatedAt?: Moment;

  leadQualificationUpdatedBy: Employee;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.insuranceQualification =
      InsuranceQualification.byKey[obj.insuranceQualification];
    this.insuranceQualificationUpdatedAt = obj.insuranceQualificationUpdatedAt
      ? moment.unix(obj.insuranceQualificationUpdatedAt)
      : undefined;
    this.insuranceQualificationUpdatedBy = make(
      obj.insuranceQualificationUpdatedBy,
      Employee,
    );
    this.leadQualification =
      InsuranceLeadQualification.byKey[obj.leadQualification];
    this.leadQualificationUpdatedAt = obj.leadQualificationUpdatedAt
      ? moment.unix(obj.leadQualificationUpdatedAt)
      : undefined;
    this.leadQualificationUpdatedBy = make(
      obj.leadQualificationUpdatedBy,
      Employee,
    );
  }
}

export default MemberInsuranceQualification;
