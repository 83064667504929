/* eslint-disable react/prop-types */
import classnames from 'classnames';
import React, { FunctionComponent, ReactNode, SyntheticEvent } from 'react';

import { Box, Drawer, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { Colors } from 'styleguide-v2/src/styles/Colors';
import { Fonts } from 'styleguide-v2/src/styles/Variables';

interface Props {
  anchor?: 'left' | 'top' | 'right' | 'bottom';
  className?: string;
  full?: boolean;
  onClose?: () => void;
  open: boolean;
  size?: number;
  tabs?: ReactNode;
  title: ReactNode;
}

const useStyles = makeStyles({
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  title: {
    fontWeight: '500',
    fontSize: `calc(${Fonts.fontSize} * 2.25)`,
  },
  closeButton: {
    color: Colors.gray,
    background: 'transparent',
    fontSize: `calc(${Fonts.fontSize} * 1.75)`,
  },
});

const Sidebar: FunctionComponent<Props> = ({
  anchor = 'right',
  className,
  children,
  onClose,
  full,
  open = false,
  size = 450,
  tabs,
  title,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const showDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const content = (
    <>
      <Box sx={{ paddingX: 5, paddingTop: 5 }}>
        <div className={styles.titleContainer}>
          {title && typeof title === 'string' ? <h2>{title}</h2> : title}
          {onClose && (
            <button
              type="button"
              className={styles.closeButton}
              onClick={() => onClose()}
            >
              <span>&times;</span>
            </button>
          )}
        </div>
      </Box>
      {tabs && (
        <Box
          sx={{
            width: '100%',
            borderBottom: '1px solid lightgray',
            marginBottom: 2,
          }}
        >
          {tabs}
        </Box>
      )}
      <Box sx={{ paddingX: 5, paddingBottom: full ? 0 : 2.5 }}>{children}</Box>
    </>
  );
  return showDesktop ? (
    <Drawer
      className={className}
      open={open}
      anchor={anchor}
      onClose={(evt: SyntheticEvent) => {
        if (
          onClose &&
          !evt.currentTarget?.classList?.contains('MuiBackdrop-root')
        )
          onClose();
      }}
      sx={{
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: size },
      }}
    >
      {content}
    </Drawer>
  ) : (
    <Drawer
      className={className}
      variant="temporary"
      open={open}
      anchor={anchor}
      onClose={() => onClose && onClose()}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
      }}
    >
      {content}
    </Drawer>
  );
};

const bodyStyles = makeStyles({
  body: {
    display: 'flex',
    flexFlow: 'column',
    gap: '1rem',
    maxHeight: 'calc(100vh - 18rem)',
    overflowY: 'auto',
  },
  full: {
    maxHeight: 'calc(100vh - 10rem)',
  },
});

const Body = ({
  children,
  className,
  'data-cy': dataCy,
  full,
}: {
  children: ReactNode;
  className?: string;
  'data-cy'?: string;
  full?: boolean;
}) => {
  const styles = bodyStyles();
  return (
    <div
      className={classnames(className, styles.body, full && styles.full)}
      data-cy={dataCy}
    >
      {children}
    </div>
  );
};

const buttonsStyles = makeStyles({
  buttons: {
    display: 'flex',
    gap: '1rem',
    marginTop: '2rem',
    '& > *': {
      flex: 1,
    },
  },
});

const Buttons = ({ children }: { children: ReactNode }) => {
  const styles = buttonsStyles();
  return <div className={styles.buttons}>{children}</div>;
};

export const CollapsableSidebar = Object.assign(Sidebar, { Body, Buttons });

export default CollapsableSidebar;
