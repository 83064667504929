import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { EventNote } from '@vestahealthcare/common/models';

import { Button, CollapsableSidebar, TextArea } from 'styleguide-v2';

interface Props {
  note?: EventNote;
  open: boolean;
  onClose: () => void;
  onSubmit: (note: EventNote) => Promise<boolean>;
}

export const MemberEventAddNote = ({
  note: defaultNote,
  open,
  onClose,
  onSubmit,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [note, setNote] = useState<string>();

  useEffect(() => {
    setNote(defaultNote?.note || '');
  }, [defaultNote]);

  const reset = () => {
    setSubmitted(false);
    setLoading(false);
    setNote(defaultNote?.note || '');
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  const doSubmit = async () => {
    setSubmitted(true);

    if (note) {
      setLoading(true);
      try {
        const result = await onSubmit(
          new EventNote({
            ...defaultNote,
            note,
          }),
        );
        if (result) {
          onClose();
        }
      } finally {
        setSubmitted(false);
        setLoading(false);
      }
    }
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={
        <h2>
          {translate(
            `memberEvents.${defaultNote?.note ? 'edit' : 'add'}${
              defaultNote?.addendum ? 'Addendum' : 'Note'
            }ModalTitle`,
          )}
        </h2>
      }
      size={550}
    >
      <CollapsableSidebar.Body>
        <TextArea
          error={submitted && !note}
          label={translate('memberEvents.note')}
          onChange={setNote}
          rows={5}
          value={note}
          required
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button color="tertiary" data-cy="edit-event-close" onClick={onClose}>
          {translate('global.cancel')}
        </Button>
        <Button
          color="secondary"
          disabled={!note || note === defaultNote?.note}
          data-cy="edit-event-note-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {translate(`global.${defaultNote?.id ? 'save' : 'add'}`)}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default MemberEventAddNote;
