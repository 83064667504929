import { Enum } from './Enum';

export class TaskFields extends Enum {
  translationKey = 'taskFields';

  static asArray: TaskFields[] = [];

  static byKey: { [name: string]: TaskFields } = {};

  static readonly STATUS = new TaskFields('STATUS');

  static readonly DUE_DATE = new TaskFields('DUE_DATE');

  static readonly ASSIGNEE = new TaskFields('ASSIGNEE');

  static readonly TASK_DEFINITION_OUTCOME = new TaskFields(
    'TASK_DEFINITION_OUTCOME',
  );

  static readonly MEMBER = new TaskFields('MEMBER');

  static readonly EMPLOYEE_GROUP = new TaskFields('EMPLOYEE_GROUP');

  static readonly HEALTH_ALERT = new TaskFields('HEALTH_ALERT');

  constructor(public readonly value: string) {
    super();
    TaskFields.asArray.push(this);
    TaskFields.byKey[value] = this;
  }
}

export default TaskFields;
