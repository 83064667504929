import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AddMember from './AddMember';
import EligibilityJobs from './EligibilityJobs';
import { ICDDetection } from './ICDDetection';
import MemberIngestion from './MemberIngestion';
import Members from './Members';
import Messaging from './Messaging';

export const Ops = () => (
  <Switch>
    <Route path="/ops/messaging" component={Messaging} />
    <Route path="/ops/roster-ingestion" component={MemberIngestion} />
    <Route path="/ops/add-member" component={AddMember} />
    <Route path="/ops/members" component={Members} />
    <Route path="/ops/eligibility-jobs" component={EligibilityJobs} />
    <Route path="/ops/icd-detection" component={ICDDetection} />
    <Redirect from="/ops**" to="/" />
  </Switch>
);

export default Ops;
