import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  tag: {
    marginRight: 5,
    paddingRight: 5,
    paddingLeft: 5,
  },
});

interface TagProps {
  tag: string;
  color?: string;
  backgroundColor?: string;
  classNames?: string;
}

export const Tag = ({ tag, color, backgroundColor, classNames }: TagProps) => {
  const styles = useStyles();

  return (
    <div
      className={`h4 ${styles.tag} tag-shadowGreen ${classNames}`}
      style={{ color, backgroundColor }}
    >
      {tag}
    </div>
  );
};

interface Props {
  tags: string[];
  defaultTag: string;
  color?: string;
  backgroundColor?: string;
  classNames?: string;
}

const tagList = ({
  tags,
  defaultTag,
  color,
  backgroundColor,
  classNames = '',
}: Props) => {
  const tagList = tags && tags.length ? tags : [defaultTag];

  return (
    <div>
      {tagList.map((tag, index) => (
        <Tag
          key={index}
          classNames={classNames}
          color={color}
          backgroundColor={backgroundColor}
          data-cy="member-details-language"
          tag={tag}
        />
      ))}
    </div>
  );
};

export default tagList;
