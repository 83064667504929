import classnames from 'classnames';
import moment from 'moment';
import React, { useMemo } from 'react';

import { ClassNameMap, makeStyles } from '@mui/styles';

import TaskStatus from '@vestahealthcare/common/enums/TaskStatus';
import { translate } from '@vestahealthcare/common/i18n';
import { PaginationType, Task } from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  DATE_FORMAT_SHORT_WITH_TIME,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  Chip,
  Colors,
  Fonts,
  MemberInfo,
  Table,
  TableItem,
  UpdatedBy,
} from 'styleguide-v2';

const formatTaskDate = (date: number) => {
  if (!date) return undefined;
  const momentDate = moment.unix(date);

  // Check if the date is at the beginnning of the day (12am)
  return momentDate.isSame(moment.unix(date).startOf('day'))
    ? momentDate.format(DATE_FORMAT_SHORT)
    : momentDate.format(DATE_FORMAT_SHORT_WITH_TIME);
};

export interface TaskTable extends Task, TableItem {}

type Props = {
  defaultSort?: string;
  tasks: Task[];
  loading?: boolean;
  onChangePage: (page: number) => void;
  onChangePageSize: (size: number) => void;
  onClickRow: (evt: TaskTable, idx: number) => void;
  onDefaultSort: () => void;
  onSort: (field: string, direction: string) => void;
  pagination?: PaginationType;
};

const TABLE_HEADERS = {
  status: translate('clinicalDashboard.tasks.table.status'),
  dueDate: translate('clinicalDashboard.tasks.table.dueDate'),
  completedDate: translate('clinicalDashboard.tasks.table.completedDate'),
  description: translate('clinicalDashboard.tasks.table.description'),
  group: translate('clinicalDashboard.tasks.table.group'),
  assignee: translate('clinicalDashboard.tasks.table.assignee'),
  member: translate('clinicalDashboard.tasks.table.member'),
  created: translate('clinicalDashboard.tasks.table.created'),
};

const SORT_FIELDS = {
  [TABLE_HEADERS.status]: 'status',
  [TABLE_HEADERS.dueDate]: 'dueDate',
  [TABLE_HEADERS.completedDate]: 'completedAt',
  [TABLE_HEADERS.description]: 'taskTemplate',
  [TABLE_HEADERS.created]: 'createdAt',
};

const DASHBOARD_TASKS_COLUMNS = (
  styles: ClassNameMap<'bold' | 'redText'>,
  defaultSort?: string,
) => {
  const field = defaultSort?.split(' ')[0];
  const direction = defaultSort?.split(' ')[1] as 'asc' | 'desc';
  return [
    {
      headerName: TABLE_HEADERS.status,
      component: ({ status }: Task) => (
        <Chip
          color={TaskStatus.COLOR_MAP.get(status)?.color || 'action'}
          type={TaskStatus.COLOR_MAP.get(status)?.type || 'outlined'}
        >
          {status.toString()}
        </Chip>
      ),
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.status] ? direction : undefined,
      sortable: true,
      width: 125,
    },
    {
      headerName: TABLE_HEADERS.dueDate,
      component: ({ dueDate, status }: Task) => (
        <span
          className={
            !status.closed && dueDate && moment().isAfter(moment.unix(dueDate))
              ? styles.redText
              : ''
          }
        >
          {dueDate ? formatTaskDate(dueDate) : EMPTY}
        </span>
      ),
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.dueDate] ? direction : undefined,
      sortable: true,
      width: 155,
    },
    {
      headerName: TABLE_HEADERS.completedDate,
      component: ({ completedAt }: Task) => (
        <span>{completedAt ? formatTaskDate(completedAt) : EMPTY}</span>
      ),
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.completedDate]
          ? direction
          : undefined,
      sortable: true,
      width: 155,
    },
    {
      headerName: TABLE_HEADERS.description,
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.description]
          ? direction
          : undefined,
      sortable: true,
      field: 'taskDefinition.name',
    },
    {
      headerName: TABLE_HEADERS.group,
      field: 'employeeGroup.name',
    },
    {
      headerName: TABLE_HEADERS.assignee,
      field: 'assignee.fullName',
    },
    {
      headerName: TABLE_HEADERS.member,
      component: ({ member }: Task) =>
        member ? (
          <MemberInfo
            link={`#/patients/${member?.id}/tasks`}
            member={member}
            showLanguage
          />
        ) : (
          <>{EMPTY}</>
        ),
      width: 175,
    },
    {
      headerName: TABLE_HEADERS.created,
      component: ({ createdAt, createdBy }: Task) => (
        <UpdatedBy date={createdAt} user={createdBy?.fullName} />
      ),
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.created] ? direction : undefined,
      sortable: true,
      width: 155,
    },
  ];
};

const useStyles = makeStyles({
  urgent: {
    '&:first-child': {
      borderLeft: `3px solid ${Colors.textOrange}`,
    },
  },
  bold: {
    fontWeight: 500,
  },
  table: {
    '&& tbody': {
      '& td': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
        '& > button': {
          maxWidth: 'fit-content',
        },
      },
      '& a': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
    },
  },
  redText: {
    color: Colors.textRed,
    fontWeight: 500,
  },
});

export const ClinicalTasksTable = (props: Props) => {
  const styles = useStyles();
  const {
    defaultSort,
    tasks,
    loading,
    onChangePage,
    onChangePageSize,
    onClickRow,
    onDefaultSort,
    onSort,
    pagination,
  } = props;

  const getRowClass = (task: Task) =>
    classnames(task?.isUrgent && styles.urgent);

  const columns = useMemo(() => DASHBOARD_TASKS_COLUMNS(styles, defaultSort), [
    defaultSort,
    styles,
  ]);

  return (
    <Table
      bottomLoading={loading}
      className={styles.table}
      config={{
        compact: true,
        data: tasks,
        defaultPageSize: 50,
        columns,
        detail: true,
        getRowBrand: ({ member }: Task) => member?.brand,
        getRowClass,
        pagination,
        paginationOptions: [10, 25, 50, 100],
      }}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangePageSize}
      onClickRow={onClickRow}
      onDefaultSort={onDefaultSort}
      onSort={({ headerName, direction }) => {
        if (SORT_FIELDS[headerName as string] && direction) {
          onSort(SORT_FIELDS[headerName as string], direction);
        }
      }}
    />
  );
};

export default ClinicalTasksTable;
