import React, { useState } from 'react';

import { GoalStatus, GoalType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { MemberGoal } from '@vestahealthcare/common/models';
import moment from '@vestahealthcare/common/moment';

import { Button, DateTimePicker, Modal, Select, TextInput } from 'styleguide';

import { showGlobalError } from 'dash/src/components/GlobalMessage';

interface Props {
  goal?: MemberGoal;
  onHide: any;
  onSave: any;
}

export const EditGoalModal = ({ goal, onHide, onSave }: Props) => {
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(goal?.title);
  const [goalType, setGoalType] = useState(goal?.type.value);
  const [otherType, setOtherType] = useState(goal?.otherType);
  const [targetDate, setTargetDate] = useState(
    goal?.targetDate ? moment(goal.targetDate) : undefined,
  );
  const [goalStatus, setGoalStatus] = useState(goal?.status.value);
  const [outcome, setOutcome] = useState(goal?.outcome);

  const isOtherType = goalType === GoalType.OTHER.valueOf();

  const handleOnSave = async () => {
    if (
      !title?.trim() ||
      !goalType ||
      !targetDate ||
      !goalStatus ||
      (isOtherType && !otherType?.trim())
    ) {
      setShowError(true);
      return;
    }

    try {
      setLoading(true);
      await onSave({
        otherType: otherType?.trim(),
        outcome: outcome?.trim(),
        status: goalStatus,
        targetDate,
        title: title.trim(),
        type: goalType,
      });
      onHide();
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  return (
    <Modal onHide={onHide} show>
      <Modal.Header
        closeButton
        modalTitle={translate(
          goal ? 'goalsOfCare.editGoal' : 'goalsOfCare.addGoal',
        )}
      />
      <Modal.Body className="grid-wrapper">
        <TextInput
          className="grid-span-12"
          errorText={translate('global.missingRequiredField')}
          label={translate('goalsOfCare.goal')}
          onChange={setTitle}
          required
          showError={!title?.trim() && showError}
          value={title}
        />
        <Select
          className="grid-span-8"
          errorText={translate('global.missingRequiredField')}
          label={translate('goalsOfCare.goalType')}
          onChange={setGoalType}
          options={GoalType.toSelectable(GoalType.asArray)}
          required
          submitted={!goalType && showError}
          value={goalType}
        />
        {isOtherType && (
          <TextInput
            className="grid-span-12"
            errorText={translate('global.missingRequiredField')}
            label={translate('goalsOfCare.otherType')}
            onChange={setOtherType}
            required
            showError={!otherType?.trim() && showError}
            value={otherType}
          />
        )}
        <DateTimePicker
          className="grid-span-8"
          errorText={translate('global.missingRequiredField')}
          label={translate('goalsOfCare.targetDate')}
          min={moment()}
          onChange={setTargetDate}
          required
          showError={!targetDate && showError}
          value={targetDate}
        />
        <Select
          className="grid-span-8"
          errorText={translate('global.missingRequiredField')}
          label={translate('goalsOfCare.status')}
          onChange={setGoalStatus}
          options={GoalStatus.toSelectable(GoalStatus.asArray)}
          required
          submitted={!goalStatus && showError}
          value={goalStatus}
        />
        <TextInput
          className="grid-span-12"
          label={translate('goalsOfCare.outcomeNote')}
          onChange={setOutcome}
          value={outcome}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="right-side">
          <Button color="secondary" onClick={onHide}>
            {translate('global.cancel')}
          </Button>
          <Button color="primary" loading={loading} onClick={handleOnSave}>
            {translate('global.save')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditGoalModal;
