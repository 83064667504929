export class StoredFilter {
  id = 0;

  description = '';

  filters = '';

  page = '';

  preferred = false;

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  static filter(filter: string) {
    return ({ page }: StoredFilter) => page === filter;
  }
}

export default StoredFilter;
