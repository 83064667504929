import React, { ReactNode } from 'react';
import classnames from 'classnames';

import makeStyles from '@mui/styles/makeStyles';
import { Colors, Fonts } from 'styleguide-v2';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
    gap: '0.5rem',
  },
  title: {
    color: Colors.iconGreen,
    fontWeight: 500,
    margin: 0,
    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} 1.5)`,
    },
  },
});

type Props = {
  className?: string;
  title: string;
  titleIcon?: ReactNode;
  children?: ReactNode;
};

export const EventDetailCard = ({
  className,
  title,
  titleIcon,
  children,
}: Props) => {
  const styles = useStyles();

  return (
    <div className={classnames(className, styles.container)}>
      <h4 className={styles.title}>
        {title}
        {titleIcon}
      </h4>
      <div>{children}</div>
    </div>
  );
};
export default EventDetailCard;
