import React, { useEffect, useState } from 'react';

import { States } from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';

import { Panel, Select, TextInput } from 'styleguide-v2';
import {
  FilterGroup,
  FilterItem,
  FilterSelectable,
} from 'styleguide-v2/src/components/Panel';

import { ReferralDropdown } from 'dash/src/components/ReferralDropdown';
import { GetTOCParams } from 'dash/src/services/TOCServices';
import {
  getFilterCount,
  getFilterItem,
  getFilterValue,
} from 'dash/src/utils/filterUtils';

import {
  TOC_TAB_ALL,
  TOC_TAB_HANDOFF,
  TOC_TOGGLE_ITEM_SKILLED,
  TOC_TOGGLE_ITEM_TOC,
} from './types';

type KeyGetTOCParams = keyof GetTOCParams;

type Props = {
  data: {
    acuityLevels: Selectable[];
    assignees: Selectable[];
    brands: Selectable[];
    communityResources: Selectable[];
    eligibilityStatuses: Selectable[];
    handoffDispositions: Selectable[];
    languages: Selectable[];
    programExtensions: Selectable[];
    programExtensionStatuses: Selectable[];
    nps: Selectable[];
    referralSources: Selectable[];
    statuses: Selectable[];
    visitStatus: Selectable[];
  };
  disabledFilters?: KeyGetTOCParams[];
  filters?: {
    [x in KeyGetTOCParams]?: FilterItem;
  };
  loadingFilters?: boolean;
  open: boolean;
  onChange: (
    filters: {
      [x in KeyGetTOCParams]?: FilterItem;
    },
  ) => void;
  onClose: () => void;
  tab?: string;
  toggle?: string;
};

const MEMBER_FILTERS: KeyGetTOCParams[] = [
  'memberId',
  'memberName',
  'state',
  'language',
  'brandId',
  'referralSourceId',
  'communityResourceId',
];

const PROGRAM_FILTERS: KeyGetTOCParams[] = [
  'programExtensionId',
  'programExtensionStatus',
  'needsInitialCarePlan',
  'npOwnerId',
];

const TOC_FILTERS: KeyGetTOCParams[] = [
  'acuityLevel',
  'assigneeId',
  'closed',
  'npVisitStatus',
  'rnVisitStatus',
  'trueTCMStatus',
  'handoffDisposition',
  'status',
];

export const TOCFiltersModal = ({
  data,
  filters,
  loadingFilters,
  open,
  onChange,
  onClose,
  tab,
  toggle,
}: Props) => {
  const [pendingFilters, setPendingFilters] = useState<
    {
      [x in KeyGetTOCParams]?: FilterItem;
    }
  >(filters || {});

  useEffect(() => {
    setPendingFilters(filters || {});
  }, [filters]);

  return (
    <Panel.FilterModal
      open={open}
      onClear={() => onChange({})}
      onClose={onClose}
      onSubmit={() => onChange(pendingFilters)}
    >
      <FilterGroup
        open
        title="Member Details"
        count={getFilterCount(pendingFilters, MEMBER_FILTERS)}
      >
        <Select
          allowAddNewValue
          data-cy="toc-filter-modal-id"
          items={[]}
          inputType="number"
          multiple
          noOptionsText={translate('members.typeToSearch')}
          onChange={(values: Selectable[]) =>
            setPendingFilters({
              ...pendingFilters,
              memberId: getFilterItem(
                translate('toc.common.memberId'),
                values.map(({ label }) => ({
                  value: label,
                  label,
                })),
              ),
            })
          }
          label={translate('toc.common.memberId')}
          size="small"
          value={pendingFilters.memberId?.value}
        />
        <TextInput
          data-cy="toc-filters-modal-name"
          label={translate('toc.common.memberName')}
          onChange={(name?: string) =>
            setPendingFilters({
              ...pendingFilters,
              memberName: getFilterItem(
                translate('toc.common.memberName'),
                name,
              ),
            })
          }
          size="small"
          value={getFilterValue(pendingFilters.memberName?.value) as string}
        />
        <Select
          data-cy="toc-filter-modal-state"
          items={Enum.toSelectable(States.asArray)}
          label={translate('toc.common.state')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              state: getFilterItem(translate('toc.common.state'), values),
            });
          }}
          size="small"
          value={pendingFilters.state?.value || []}
        />
        <Select
          data-cy="toc-filter-modal-language"
          grouped={({ preferred }) =>
            preferred
              ? translate('global.preferred')
              : translate('global.other')
          }
          items={data.languages.filter(({ disabled }) => !disabled)}
          label={translate('toc.common.language')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              language: getFilterItem(translate('toc.common.language'), values),
            });
          }}
          size="small"
          value={pendingFilters.language?.value || []}
        />
        <Select
          data-cy="toc-filter-modal-brand"
          items={data.brands}
          label={translate('toc.common.brand')}
          loading={loadingFilters}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              brandId: getFilterItem(translate('toc.common.brand'), values),
            });
          }}
          size="small"
          value={pendingFilters.brandId?.value || []}
        />
        <ReferralDropdown
          data-cy="toc-filter-modal-referral-sources"
          items={data.referralSources}
          label={translate('toc.common.referralSources')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              referralSourceId: getFilterItem(
                translate('toc.common.referralSources'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.referralSourceId?.value || []}
        />
        <Select
          data-cy="toc-filter-modal-homehealth"
          items={data.communityResources}
          label={translate('toc.common.homeHealth')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              communityResourceId: getFilterItem(
                translate('toc.common.homeHealth'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.communityResourceId?.value || []}
        />
      </FilterGroup>
      <FilterGroup
        open
        title="Program Details"
        count={getFilterCount(pendingFilters, PROGRAM_FILTERS)}
      >
        <Select
          data-cy="toc-filter-modal-pe"
          items={data.programExtensions}
          label={translate('toc.common.programExtensions')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              programExtensionId: getFilterItem(
                translate('toc.common.programExtensions'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.programExtensionId?.value || []}
        />
        <Select
          data-cy="toc-filter-modal-pe-status"
          items={data.programExtensionStatuses}
          label={translate('toc.common.programExtensionStatus')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              programExtensionStatus: getFilterItem(
                translate('toc.common.programExtensionStatus'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.programExtensionStatus?.value || []}
        />
        <Select.Choice
          data-cy="toc-filter-modal-unreachable"
          label={translate('toc.common.initialCP')}
          onChange={(value?: boolean) => {
            setPendingFilters({
              ...pendingFilters,
              needsInitialCarePlan: getFilterItem(
                translate('toc.common.initialCP'),
                value,
              ),
            });
          }}
          size="small"
          value={
            (pendingFilters.needsInitialCarePlan?.value as FilterSelectable)
              ?.value as boolean | undefined
          }
        />
        <Select
          data-cy="toc-filter-modal-np"
          items={data.nps}
          label={translate('ccm.common.npOwner')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              npOwnerId: getFilterItem(translate('ccm.common.npOwner'), values),
            });
          }}
          size="small"
          value={pendingFilters.npOwnerId?.value || []}
        />
      </FilterGroup>
      <FilterGroup
        open
        title="TOC Details"
        count={getFilterCount(
          pendingFilters,
          TOC_FILTERS.filter(
            (item) =>
              (!['acuityLevel', 'trueTCMStatus'].includes(item) ||
                toggle !== TOC_TOGGLE_ITEM_SKILLED) &&
              (item !== 'handoffDisposition' ||
                toggle !== TOC_TOGGLE_ITEM_TOC ||
                tab === TOC_TAB_HANDOFF) &&
              (item !== 'status' ||
                toggle !== TOC_TOGGLE_ITEM_TOC ||
                tab === TOC_TAB_ALL) &&
              (item !== 'closed' ||
                (toggle === TOC_TOGGLE_ITEM_SKILLED &&
                  tab === TOC_TAB_HANDOFF)),
          ),
        )}
      >
        {toggle !== TOC_TOGGLE_ITEM_SKILLED && (
          <Select
            data-cy="toc-filter-acuity-level"
            items={data.acuityLevels}
            label={translate('toc.common.acuityLevel')}
            loading={loadingFilters}
            multiple
            onChange={(value: Selectable) => {
              setPendingFilters({
                ...pendingFilters,
                acuityLevel: getFilterItem(
                  translate('toc.common.acuityLevel'),
                  value,
                ),
              });
            }}
            size="small"
            value={pendingFilters.acuityLevel?.value || []}
          />
        )}
        <Select
          data-cy="toc-filter-modal-assignees"
          items={data.assignees}
          label={translate('toc.common.assignee')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              assigneeId: getFilterItem(
                translate('toc.common.assignee'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.assigneeId?.value || []}
        />
        {toggle === TOC_TOGGLE_ITEM_TOC && tab === TOC_TAB_ALL && (
          <Select
            data-cy="toc-filter-modal-status"
            items={data.statuses}
            label={translate('toc.common.status')}
            limitTags={1}
            loading={loadingFilters}
            multiple
            onChange={(values: Selectable[]) => {
              setPendingFilters({
                ...pendingFilters,
                status: getFilterItem(translate('toc.common.status'), values),
              });
            }}
            size="small"
            value={pendingFilters.status?.value || []}
          />
        )}
        {toggle === TOC_TOGGLE_ITEM_SKILLED && tab === TOC_TAB_HANDOFF && (
          <Select.Choice
            data-cy="toc-filter-modal-status"
            label={translate('toc.common.closed')}
            onChange={(value?: boolean) => {
              setPendingFilters({
                ...pendingFilters,
                closed: getFilterItem(translate('toc.common.closed'), value),
              });
            }}
            size="small"
            value={
              ((pendingFilters.closed?.value as FilterSelectable)
                ?.value as boolean) || false
            }
          />
        )}
        <Select
          data-cy="toc-filter-modal-np-visit-status"
          items={data.visitStatus}
          label={translate('toc.common.npVisitStatus')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              npVisitStatus: getFilterItem(
                translate('toc.common.npVisitStatus'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.npVisitStatus?.value || []}
        />
        <Select
          data-cy="toc-filter-modal-rn-visit-status"
          items={data.visitStatus}
          label={translate('toc.common.rnVisitStatus')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              rnVisitStatus: getFilterItem(
                translate('toc.common.rnVisitStatus'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.rnVisitStatus?.value || []}
        />
        {tab === TOC_TAB_HANDOFF && (
          <Select
            data-cy="toc-filter-modal-handoff-disposition"
            items={data.handoffDispositions}
            multiple
            label={translate('toc.common.handoffDisposition')}
            onChange={(value?: Selectable) => {
              setPendingFilters({
                ...pendingFilters,
                handoffDisposition: getFilterItem(
                  translate('toc.common.handoffDisposition'),
                  value,
                ),
              });
            }}
            size="small"
            value={pendingFilters.handoffDisposition?.value}
          />
        )}
        {toggle !== TOC_TOGGLE_ITEM_SKILLED && (
          <Select
            data-cy="toc-filter-modal-tcm-eligibility"
            items={data.eligibilityStatuses}
            label={translate('toc.common.tcmEligibility')}
            onChange={(value?: Selectable) => {
              setPendingFilters({
                ...pendingFilters,
                trueTCMStatus: getFilterItem(
                  translate('toc.common.tcmEligibility'),
                  value,
                ),
              });
            }}
            size="small"
            value={pendingFilters.trueTCMStatus?.value}
          />
        )}
      </FilterGroup>
    </Panel.FilterModal>
  );
};
