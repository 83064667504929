import { Enum } from './Enum';

export class IssueDetailFieldType extends Enum {
  translationKey = 'issueDetailFieltType';

  static asArray: IssueDetailFieldType[] = [];

  static byKey: { [name: string]: IssueDetailFieldType } = {};

  static readonly DATE = new IssueDetailFieldType('DATE');

  static readonly DATE_TIME = new IssueDetailFieldType('DATE_TIME');

  static readonly TEXT = new IssueDetailFieldType('TEXT');

  static readonly TEXT_AREA = new IssueDetailFieldType('TEXT_AREA');

  static readonly SELECT = new IssueDetailFieldType('SELECT');

  static readonly MULTI_SELECT = new IssueDetailFieldType('MULTI_SELECT');

  static readonly SKILLED_AGENCY = new IssueDetailFieldType('SKILLED_AGENCY');

  static readonly TOGGLE = new IssueDetailFieldType('TOGGLE');

  constructor(public readonly value: string) {
    super();
    IssueDetailFieldType.asArray.push(this);
    IssueDetailFieldType.byKey[value] = this;
  }
}

export default IssueDetailFieldType;
