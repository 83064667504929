import React from 'react';

import LaunchIcon from '@mui/icons-material/Launch';

import { translate } from '@vestahealthcare/common/i18n';
import {
  PaginationType,
  SkilledEvent,
  TOCEvent,
} from '@vestahealthcare/common/models';
import { TOCDetailMember } from '@vestahealthcare/common/models/TOCDetailMember';

import { Button, Card, CardContent, CardHeader } from 'styleguide-v2';

import { TOCEventsTable } from './TOCEventsTable';

type Props = {
  className?: string;
  data?: TOCEvent[] | SkilledEvent[];
  member?: TOCDetailMember;
  onSeeMore: () => Promise<void>;
  pagination?: PaginationType;
  type: 'TOC' | 'SKILLED';
};

export const TOCEventsHistoryCard = ({
  className,
  data,
  member,
  onSeeMore,
  pagination,
  type,
}: Props) => {
  return (
    <Card className={className}>
      <CardHeader
        title={translate(`toc.detail.eventsTitle.${type}`)}
        action={
          <Button
            color="quaternary"
            icon={<LaunchIcon />}
            size="s"
            onClick={() =>
              window.open(`/#/patients/${member?.id}/events`, '_blank')
            }
          >
            <span className="bold">{translate('toc.detail.goToEvents')}</span>
          </Button>
        }
      />
      <CardContent sx={{ paddingTop: 0 }}>
        <TOCEventsTable
          data={data || []}
          pagination={pagination}
          onSeeMore={onSeeMore}
          type={type}
        />
      </CardContent>
    </Card>
  );
};
