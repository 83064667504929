import { Moment } from 'moment';

import { makeUnix } from '../Model';

export class MemberWarningItem {
  type = '';

  dateTime?: Moment;

  status = '';

  assignee = '';

  constructor(obj: any) {
    Object.assign(this, obj);
    this.dateTime = makeUnix(obj.dateTime);
  }
}

export default MemberWarningItem;
