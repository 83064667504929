import {
  EncounterChannel,
  EncounterDirection,
  EncounterMethod,
  EncounterMethodOutcome,
  EncounterType,
} from '../enums';
import { Employee } from './Employee';
import { make } from './Model';

export class Encounter {
  direction?: EncounterDirection;

  method?: EncounterMethod;

  methodOutcome?: EncounterMethodOutcome;

  source = '';

  channel: EncounterChannel;

  createdBy: Employee;

  duration = 0;

  encounterDate = '';

  encounterDateTime = 0;

  eventId?: number;

  id = 0;

  note?: string;

  otherChannel?: string;

  type?: EncounterType;

  createdAt = 0;

  valid = true;

  invalidatedAt = 0;

  careTeamMemberId?: number;

  communityResourceId?: number;

  taskId?: number;

  skilledRecordId?: number;

  tocRecordId?: number;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.direction = obj.direction && EncounterDirection.byKey[obj.direction];
    this.method = obj.method && EncounterMethod.byKey[obj.method];
    this.methodOutcome =
      obj.methodOutcome && EncounterMethodOutcome.byKey[obj.methodOutcome];
    this.type = obj.type && EncounterType.byKey[obj.type];
    this.channel = EncounterChannel.byKey[obj.channel];
    this.createdBy = make(obj.createdBy, Employee);
  }

  get displayChannel() {
    return this.channel.isOther
      ? `${this.channel.toString()} - ${this.otherChannel}`
      : this.channel.toString();
  }
}
