import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { sortBy } from 'lodash';
import { CareTrack, MemberGoal, Patient } from '@vestahealthcare/common/models';
import { Panel } from 'styleguide';
import { translate } from '@vestahealthcare/common/i18n';
import {
  fetchCareTracks,
  fetchMemberGoals,
} from 'dash/src/services/PHIPServices';
import { fetchById } from 'dash/src/services/PatientServices';
import { CareTrackCategories } from './CareTrackCategory';
import { GoalsOfCare } from './GoalsOfCare';
import './main.less';
import PlanDocumentsSection from './PlanDocumentsSection';

type Params = {
  careTrack: string;
  patientId: string;
};

const PHIPProfile = (props: RouteComponentProps<Params>) => {
  const [loading, setLoading] = useState(true);
  const [careTracks, setCareTracks] = useState<CareTrack[]>([]);
  const [goals, setGoals] = useState<MemberGoal[]>();
  const { careTrack, patientId } = props.match.params;
  const [member, setMember] = useState<Patient>();

  const loadCareTracks = async () => {
    const tracks = await fetchCareTracks(patientId);
    return sortBy(tracks, 'order', 'type.value');
  };

  const fetchData = async () => {
    setLoading(true);
    const [careTracks, goals, patient] = await Promise.all([
      loadCareTracks(),
      fetchMemberGoals(patientId),
      fetchById(patientId),
    ]);
    setCareTracks(careTracks);
    setGoals(goals);
    setMember(patient);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="page">
      <Panel id="risk-profile-page" data-cy="risk-profile-page">
        <Panel.Heading
          title={translate('assessment.riskAssessment.riskProfile')}
        />
        <Panel.Body loading={loading}>
          <GoalsOfCare
            goals={goals}
            member={member}
            onSubmit={fetchData} // NOTE: this overkill, but fine for now
          />
          <CareTrackCategories
            careTracks={careTracks}
            onSubmit={fetchData}
            focus={careTrack}
          />
          <PlanDocumentsSection member={member} />
        </Panel.Body>
      </Panel>
    </div>
  );
};

export default withRouter(PHIPProfile);
