import { PhoneType } from '@vestahealthcare/common/enums';
import { UserPhone } from '@vestahealthcare/common/models';
import React from 'react';
import PhoneNumberText from '.';

interface Props {
  phones: string[] | UserPhone[];
}

/**
 *
 * @param phones string[] | UserPhone[]
 * @returns an empty state or an array of PhoneNumberTexts. If the `phones` provided are UserPhone,
 * they will be accompanied by a primary (*) indicator and the corresponding PhoneType.
 * NOTE: PhoneNumberTexts will be displayed in the exact order they are passed.
 */
export const PhoneNumberTextList = ({ phones }: Props) => {
  if (!phones || !phones.length) {
    return <p>-</p>;
  }

  return (
    <>
      {phones.map((phone: string | UserPhone) => {
        if (typeof phone === 'string') {
          return <PhoneNumberText key={phone} value={phone} />;
        }

        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'space-between',
            }}
            key={phone.id}
          >
            <div>
              <h4>
                {phone.phone.type
                  ? PhoneType.byKey[phone.phone.type?.valueOf()].toString()
                  : '-'}
              </h4>
              {phone.primary ? (
                <h4 data-cy="member-phone-primary" className="text-uppercase">
                  &nbsp;( primary )
                </h4>
              ) : (
                ''
              )}
            </div>
            <div style={{ textAlign: 'end' }}>
              <p>
                <PhoneNumberText value={phone.phone.number} />
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default PhoneNumberTextList;
