import React, { useState } from 'react';

import { Enum, ResidenceType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Select, TextInput } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { updateResidence } from 'dash/src/services/PatientServices';

import { BaseModal } from './BaseModal';

interface Props {
  patient: Patient;
  onSubmit: (patient: Patient) => void;
}

const renderDetails = (patient: Patient) => (
  <>
    <h4 className="grid-span-5">{translate('personalDetails.residence')}</h4>
    <p className="grid-span-6">
      {(patient.residenceType && patient.residenceType.toString()) || EMPTY}
      {patient.residenceTypeOther && (
        <span> -{patient.residenceTypeOther}</span>
      )}
    </p>
  </>
);

export const EditResidenceModal = React.memo((props: Props) => {
  const { patient, onSubmit } = props;
  const { residenceType } = patient;
  const editable = patient.isEditable();
  const [residence, setResidence] = useState(
    residenceType ? Enum.toSelectable([residenceType])[0] : undefined,
  );
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [residenceOther, setResidenceOther] = useState(
    patient.residenceTypeOther,
  );

  const onCancel = () => {
    setResidence(
      residenceType ? Enum.toSelectable([residenceType])[0] : undefined,
    );
    setSubmitted(false);
    setResidenceOther(patient.residenceTypeOther);
  };

  const submit = async () => {
    let result = true;

    setSubmitted(true);
    if (
      !residence ||
      (residence.value === ResidenceType.OTHER.value && !residenceOther)
    ) {
      return false;
    }
    setLoading(true);

    try {
      const newPatient = await updateResidence(
        patient.id,
        ResidenceType.byKey[residence.value],
        residenceOther,
      );
      onSubmit(newPatient);
      setSubmitted(false);
    } catch (e) {
      showGlobalError(e as string);
      result = false;
    }
    setLoading(false);
    return result;
  };

  return (
    <BaseModal
      data-cy="patient-residence"
      details={renderDetails(patient)}
      editable={editable}
      loadingSubmit={loading}
      onCancel={onCancel}
      onSubmit={submit}
      title={translate('personalDetails.residence')}
    >
      <Select
        error={submitted && !residence}
        label={translate('personalDetails.whereDoesTheClientReside')}
        onChange={setResidence}
        items={ResidenceType.toSelectable(ResidenceType.asArray)}
        required
        value={residence}
      />
      {residence?.value === ResidenceType.OTHER.value && (
        <>
          <br />
          <TextInput
            value={residenceOther}
            onChange={setResidenceOther}
            error={!residenceOther && submitted}
            required
          />
        </>
      )}
    </BaseModal>
  );
});
