import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import BulkEditIcon from '@mui/icons-material/AppRegistration';
import ClearIcon from '@mui/icons-material/Clear';
import SeeFiltersIcon from '@mui/icons-material/FilterAlt';
import CustomSelectionIcon from '@mui/icons-material/GroupAdd';
import AddAllIcon from '@mui/icons-material/PlaylistAdd';
import SeeSelectionIcon from '@mui/icons-material/RemoveRedEye';
import { makeStyles } from '@mui/styles';

import { Selectable, SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  DBEnum,
  Employee,
  EmployeeGroup,
  MemberView,
  PaginationType,
  ProgramExtension,
  ProgramExtensionStatus,
} from '@vestahealthcare/common/models';

import { FilterButton, IconButton, Panel, PanelInfo } from 'styleguide-v2';

import { MemberViewFilters } from 'dash/src/components/MemberViewFilters';
import { CacheServices } from 'dash/src/services';
import {
  MemberViewParams,
  getMembersView,
  getMembersViewIds,
} from 'dash/src/services/PatientServices';

import BulkEditModal from './BulkEditModal';
import { BulkEditTable } from './BulkEditTable';
import CustomSelectionModal from './CustomSelectionModal';

const PAGE_SIZE = 10;

const UNASSIGNED_CARE_PLAN_GROUP = new DBEnum({
  description: translate('global.unassigned'),
  id: null,
});

const UNASSIGNED_EMPLOYEE_GROUP = new EmployeeGroup({
  name: translate('global.unassigned'),
  id: null,
});

const UNASSIGNED_SELECTABLE = {
  label: translate('global.unassigned'),
  value: 0,
};

const uniq = (array: any[]) => [...new Set(array)];

const useStyles = makeStyles({
  tabs: {
    '&&': {
      paddingBottom: '0.5rem',
      paddingTop: '0.5rem',
    },
  },
});

export const MemberSearch = () => {
  const styles = useStyles();
  const [seeSelection, setSeeSelection] = useState<boolean>(false);
  const [selection, setSelection] = useState<number[]>([]);

  const [activeCareCoordinators, setActiveCareCoordinators] = useState<
    Selectable[]
  >([]);
  const [activeEngagementOwners, setActiveEngagementOwners] = useState<
    Selectable[]
  >([]);
  const [activeHealthCoaches, setActiveHealthCoaches] = useState<Selectable[]>(
    [],
  );
  const [activeRNs, setActiveRNs] = useState<SelectableInfo<Employee>[]>([]);
  const [activeNPs, setActiveNPs] = useState<SelectableInfo<Employee>[]>([]);
  const [activePodManagers, setActivePodManagers] = useState<
    SelectableInfo<Employee>[]
  >([]);
  const [allCarePlanGroups, setAllCarePlanGroups] = useState<DBEnum[]>([]);
  const [allProgramExtensions, setAllProgramExtensions] = useState<
    ProgramExtension[]
  >([]);
  const [
    allProgramExtensionStatuses,
    setAllProgramExtensionStatuses,
  ] = useState<ProgramExtensionStatus[]>([]);
  const [allWorklistGroups, setAllWorklistGroups] = useState<EmployeeGroup[]>(
    [],
  );

  const [allLoading, setAllLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [filtersOpened, setFiltersOpened] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<number>(1);

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const [filters, setFilters] = useState<MemberViewParams>({});
  const [members, setMembers] = useState<MemberView[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();

  const [openBulkEditModal, setOpenBulkEditModal] = useState<boolean>(false);
  const [openCustomSelectionModal, setOpenCustomSelectionModal] = useState<
    boolean
  >(false);

  const hasSelection = !!selection.length;
  const hasResults = !!members?.length;

  const emptyFilters =
    !filters?.brandId?.length &&
    !filters?.careCoordinatorId?.length &&
    filters?.carePlanGroupId === undefined &&
    !filters?.emptyRiskLevel &&
    !filters?.engagementOwnerId &&
    !filters?.fullName?.trim() &&
    filters?.hasCareTeam === undefined &&
    filters?.hciReporter === undefined &&
    !filters?.hciReporterMethod?.length &&
    !filters?.hciReporterStatus?.length &&
    !filters?.healthCoachId &&
    !filters?.language?.length &&
    !filters?.npOwnerId?.length &&
    !filters?.programExtensionId?.length &&
    !filters?.programExtensionStatus?.length &&
    !filters?.referralSourceId?.length &&
    !filters?.riskLevel?.length &&
    !filters?.rnOwnerId?.length &&
    !filters?.state?.length &&
    !filters?.status?.length &&
    !filters?.vitalCollectionMethod &&
    !filters?.podManagerId?.length;

  const fetchInitialData = async () => {
    const [
      employees,
      cpgs,
      allProgramExtensions,
      allProgramExtensionStatuses,
      allWorklistGroups,
    ] = await Promise.all([
      CacheServices.getEmployees(),
      CacheServices.getCarePlanGroups(),
      CacheServices.getAllProgramExtensions(),
      CacheServices.getProgramExtensionStatus(),
      CacheServices.getEmployeeGroupsWorklist(),
    ]);

    setActiveCareCoordinators(
      mapEmployees(employees.filter((e) => e.isCareCoordinator)),
    );
    setActiveEngagementOwners([
      UNASSIGNED_SELECTABLE,
      ...mapEmployees(employees.filter((e) => e.isInEngagement)),
    ]);
    setActiveHealthCoaches([
      UNASSIGNED_SELECTABLE,
      ...mapEmployees(employees.filter((e) => e.isInHealthCoaches)),
    ]);
    setActiveRNs(mapEmployees(employees.filter((e) => e.isRN)));
    setActiveNPs(mapEmployees(employees.filter((e) => e.isNP)));
    setActivePodManagers(mapEmployees(employees.filter((e) => e.isPodManager)));
    setAllCarePlanGroups([
      UNASSIGNED_CARE_PLAN_GROUP,
      ...cpgs.filter(({ active }) => active),
    ]);
    setAllProgramExtensions(allProgramExtensions);
    setAllProgramExtensionStatuses(allProgramExtensionStatuses);
    setAllWorklistGroups([UNASSIGNED_EMPLOYEE_GROUP, ...allWorklistGroups]);
  };

  const getMembers = async (params: MemberViewParams) => {
    const offset = page * pageSize;
    if (!offset || offset >= (members?.length || 0)) {
      setLoading(true);
      const { items, pagination } = await getMembersView(params);
      setMembers(offset ? [...(members || []), ...items] : items);
      setPagination(pagination);
      setLoading(false);
    }
  };

  const getMembersList = async (id: number[]) => {
    const offset = page * pageSize;
    if (offset && offset + pageSize < members?.length + 1) return;
    setLoading(true);

    const ids = [...id].slice(offset, offset + pageSize);
    const { items } = await getMembersView({
      id: ids,
      offset: 0,
      limit: pageSize,
    });

    if (offset !== 0) {
      setMembers([...members, ...items]);
    } else {
      setMembers(items);
    }
    setPagination({
      limit: pageSize,
      offset,
      total: selection?.length || 0,
    } as PaginationType);
    setLoading(false);
  };

  const mapEmployees = (array: Employee[]) =>
    Employee.toSelectable(array).sort(({ label: a }, { label: b }) =>
      (a as string).localeCompare(b as string),
    );

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (seeSelection) {
      if (selection.length) {
        getMembersList(selection);
      }
    } else if (emptyFilters) {
      setMembers([]);
      setPagination(undefined);
      setLoading(false);
    } else {
      getMembers({
        ...filters,
        offset: page * pageSize,
        limit: pageSize,
      });
    }
  }, [filters, page, pageSize, seeSelection, refresh]);

  const updateFilter = (newFilters: Partial<MemberViewParams>) => {
    setPage(0);
    if (Object.keys(newFilters)?.length === 0) {
      setFilters({});
    } else {
      setFilters({ ...filters, ...newFilters });
    }
  };

  const updateSelection = (newSelection: number[]) => {
    setSelection(newSelection);
  };

  const seeSelectionMode = () => {
    setPage(0);
    setMembers([]);
    setFiltersOpened(false);
    setSeeSelection(true);
  };

  const updateSelectionList = () => {
    setPage(0);
    setFiltersOpened(false);
    setSeeSelection(true);
    setRefresh(refresh + 1);
  };

  const filterMode = (avoidClearSelection?: boolean) => {
    setPage(0);
    if (!avoidClearSelection) {
      setMembers([]);
    }
    setFiltersOpened(true);
    setSeeSelection(false);
  };

  const selectAll = async () => {
    setAllLoading(true);
    const ids = await getMembersViewIds(filters);
    updateSelection(uniq([...selection, ...ids]));
    setAllLoading(false);
  };

  return (
    <Panel>
      <Panel.Heading title={translate('memberBulkEdit.header')}>
        <FilterButton
          className="filters"
          filters={filters}
          data-cy="bulk-edit-filters"
          onClick={() => {
            setFiltersOpened(!filtersOpened);
            setSeeSelection(false);
          }}
        />
        <Panel.Actions>
          <IconButton
            data-cy="bulk-custom-selection-button"
            disabled={allLoading}
            onClick={() => setOpenCustomSelectionModal(true)}
            tooltip={translate('memberBulkEdit.customSelection')}
          >
            <CustomSelectionIcon />
          </IconButton>
          <IconButton
            data-cy="bulk-see-selection-button"
            disabled={!hasSelection || allLoading}
            onClick={() => (!seeSelection ? seeSelectionMode() : filterMode())}
            tooltip={translate(
              `memberBulkEdit.${seeSelection ? 'seeFilters' : 'seeSelection'}`,
            )}
          >
            {seeSelection ? <SeeFiltersIcon /> : <SeeSelectionIcon />}
          </IconButton>
          <IconButton
            data-cy="bulk-edit-button"
            disabled={!hasSelection || allLoading}
            onClick={() => setOpenBulkEditModal(true)}
            tooltip={translate('memberBulkEdit.bulkEdit')}
          >
            <BulkEditIcon />
          </IconButton>
        </Panel.Actions>
        <Panel.Tabs className={classNames('flex spaced buttons', styles.tabs)}>
          <span>
            {translate('memberBulkEdit.selectedItems', {
              count: selection.length,
            })}
          </span>
          <div className="flex gap">
            <IconButton
              data-cy="bulk-add-all-button"
              disabled={seeSelection || !hasResults}
              loading={allLoading}
              onClick={() => selectAll()}
              tooltip={translate('memberBulkEdit.addAllToSelection')}
            >
              <AddAllIcon />
            </IconButton>
            <IconButton
              data-cy="bulk-clear-button"
              disabled={!hasSelection || allLoading}
              onClick={() => {
                updateSelection([]);
                filterMode(true);
              }}
              tooltip={translate('memberBulkEdit.clearSelection')}
            >
              <ClearIcon />
            </IconButton>
          </div>
        </Panel.Tabs>
        <Panel.Collapse open={filtersOpened}>
          <div className="grid-wrapper fit">
            <MemberViewFilters
              filters={filters}
              hideIdFilter
              onChange={updateFilter}
            />
          </div>
        </Panel.Collapse>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        {!members?.length &&
        !Object.values(filters).filter((item) =>
          item instanceof Array ? item?.length : item,
        ).length ? (
          <PanelInfo
            type="info"
            title={translate('memberBulkEdit.noDataInfo')}
          />
        ) : (
          <BulkEditTable
            allChecked={
              !!(
                members?.length &&
                (seeSelection
                  ? members
                  : [...members]?.slice(page * pageSize, (page + 1) * pageSize)
                )?.every((member) => selection.includes(member.id))
              )
            }
            members={members || []}
            membersSelected={selection}
            onChangePage={setPage}
            onChangePageSize={(ps) => {
              setMembers([]);
              setPage(0);
              setPageSize(ps);
            }}
            onClickCheckbox={(member) =>
              updateSelection(
                selection.includes(member.id)
                  ? selection.filter((id) => member.id !== id)
                  : uniq([...selection, member.id]),
              )
            }
            onClickCheckboxAll={(checked) => {
              const offset = page * pageSize;
              const ids =
                (seeSelection
                  ? members
                  : [...members]?.slice(offset, offset + pageSize)
                )?.map((member) => member.id) || [];
              if (!ids.length) return;

              updateSelection(
                checked
                  ? selection.filter((id) => !ids.includes(id))
                  : uniq([...selection, ...ids]),
              );
            }}
            pagination={pagination}
          />
        )}
        <BulkEditModal
          activeCareCoordinators={activeCareCoordinators}
          activeEngagementOwners={activeEngagementOwners}
          activeHealthCoaches={activeHealthCoaches}
          activeNPs={activeNPs}
          activePodManagers={activePodManagers}
          activeRNs={activeRNs}
          activeCarePlanGroups={allCarePlanGroups}
          allProgramExtension={allProgramExtensions}
          allProgramExtensionStatuses={allProgramExtensionStatuses}
          allWorklistGroups={allWorklistGroups}
          onClose={() => setOpenBulkEditModal(false)}
          onSubmit={updateSelectionList}
          open={openBulkEditModal}
          selection={selection}
        />
        <CustomSelectionModal
          onClose={() => setOpenCustomSelectionModal(false)}
          onSubmit={(selection: number[]) => {
            updateSelection(selection);
            seeSelectionMode();
          }}
          open={openCustomSelectionModal}
        />
        <br />
        <br />
      </Panel.Body>
    </Panel>
  );
};

export default MemberSearch;
