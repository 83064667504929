import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestoreFromTrash from '@mui/icons-material/RestoreFromTrash';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { BaseEnum, CareTeamMember } from '@vestahealthcare/common/models';

import {
  Button,
  CareTeamPersonInfo,
  CollapseIcon,
  Colors,
  IconButton,
  MemberInfo,
  Panel,
  Table,
} from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

import { CareTeamMemberExtraInfo } from './CareTeamMemberExtraInfo';
import { CareTeamMemberPermissions } from './CareTeamMemberPermissions';

interface Props {
  members: CareTeamMember[];
  onActivate: (member: CareTeamMember) => void;
  onDelete: (member: CareTeamMember) => void;
  onEdit: (member: CareTeamMember) => void;
  mode?: 'member' | 'person';
}

const LINKED_MEMBERS_COLUMNS = (
  { mode = 'person', onActivate, onEdit, onDelete }: Props,
  active: boolean,
) => {
  const columns: Column[] = [];

  if (mode === 'member') {
    columns.push({
      headerName: translate('global.member'),
      component: ({ member }: CareTeamMember) => (
        <MemberInfo member={member} link={`#/patients/${member?.id}`} />
      ),
    });
  } else {
    columns.push({
      headerName: translate('careTeam.common.careTeamPerson'),
      component: ({ careTeamPerson }: CareTeamMember) => (
        <CareTeamPersonInfo person={careTeamPerson} />
      ),
    });
  }
  return [
    ...columns,
    {
      headerName: translate('careTeam.common.role'),
      component: (member: CareTeamMember) => (
        <>
          <p className="no-margin bold">{member.type.description}</p>
          <p className="no-margin gray">{member.typeWithSubType.subtype}</p>
        </>
      ),
    },
    {
      headerName: translate('careTeam.common.permissions'),
      component: (member: CareTeamMember) => (
        <div className="flex wrap gap">
          <CareTeamMemberPermissions member={member} />
        </div>
      ),
    },
    {
      headerName: translate('careTeam.common.notes'),
      component: ({ notes }: CareTeamMember) => notes || '',
    },
    {
      headerName: translate(
        `careTeam.common.${active ? 'details' : 'inactiveReason'}`,
      ),
      component: (member: CareTeamMember) =>
        active ? (
          <CareTeamMemberExtraInfo member={member} />
        ) : (
          <span>
            {member.inactiveReason?.id === BaseEnum.OTHER
              ? member.inactiveReasonOther
              : member.inactiveReason?.description}
          </span>
        ),
    },
    {
      headerName: ' ',
      component: (member: CareTeamMember) =>
        active ? (
          <div className="flex">
            <IconButton size="small" onClick={() => onEdit(member)}>
              <EditIcon />
            </IconButton>
            <IconButton size="small" onClick={() => onDelete(member)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ) : (
          <IconButton
            size="small"
            tooltip={translate('careTeam.common.activate')}
            onClick={() => onActivate(member)}
          >
            <RestoreFromTrash />
          </IconButton>
        ),
      width: 50,
    },
  ];
};

const useStyles = makeStyles({
  showInactiveButton: {
    '&&': {
      display: 'flex',
      margin: '0.5rem 0 0 auto',
    },
  },
  inactiveRow: {
    '&&': {
      backgroundColor: `${Colors.lighterGray}!important`,
    },
  },
});

export const LinkedMembersTable = (props: Props) => {
  const styles = useStyles();
  const activeMembers = props.members.filter(({ active }) => active);
  const inactiveMembers = props.members.filter(({ active }) => !active);

  const [showInactive, setShowInactive] = useState<boolean>(false);

  return (
    <>
      <Table
        config={{
          columns: LINKED_MEMBERS_COLUMNS(props, true),
          compact: true,
          data: activeMembers,
          paginationOptions: [-1],
        }}
      />
      {!!inactiveMembers?.length && (
        <>
          <Button
            className={styles.showInactiveButton}
            onClick={() => setShowInactive(!showInactive)}
            color="quaternary"
            size="xs"
          >
            {showInactive ? 'Hide Inactive Members' : 'Show Inactive Members'}
            <CollapseIcon open={showInactive} color="disabled" />
          </Button>
          <Panel.Collapse open={showInactive}>
            <Table
              config={{
                columns: LINKED_MEMBERS_COLUMNS(props, false),
                compact: true,
                data: inactiveMembers,
                getRowClass: () => styles.inactiveRow,
                paginationOptions: [-1],
              }}
            />
          </Panel.Collapse>
        </>
      )}
    </>
  );
};

export default LinkedMembersTable;
