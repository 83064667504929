import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import EventNoteIcon from '@mui/icons-material/EventNote';
import { makeStyles } from '@mui/styles';

import {
  DayOfWeek,
  MemberContactMethodType,
  MemberContactTimeType,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  MemberContactInfoMethod,
  MemberContactInfoTimes,
  Patient,
} from '@vestahealthcare/common/models';
import { EMPTY, TIME_FORMAT } from '@vestahealthcare/common/utils/constants';

import { CheckboxGroup, TextArea, TextInput, Tooltip } from 'styleguide-v2';
import { CheckboxItem } from 'styleguide-v2/src/components/CheckboxWithLabel/CheckboxGroup';

import {
  DatesTimeItem,
  EditDateTimes,
} from 'dash/src/components/EditDateTimes';
import { showGlobalError } from 'dash/src/components/GlobalMessage';
import {
  MemberContactInfoParams,
  createOrUpdateMemberContactInfo,
  getMemberContactInfoParsed,
} from 'dash/src/services/PatientServices';

import { BaseModal } from './BaseModal';

type Props = {
  patient: Patient;
  onSubmit: () => void;
};

const useStyles = makeStyles({
  deleteIcon: {
    height: '4.5rem',
  },
  otherText: {
    alignSelf: 'flex-end',
    marginBottom: '-0.5rem',
  },
  marginTop: {
    marginTop: '2rem!important',
  },
  lastLogin: {
    fontSize: '0.875em',
    margin: 0,
  },
});

type DetailsProps = {
  contactMethods: MemberContactInfoMethod[];
  contactTimes: DatesTimeItem[];
  note: string;
};

const SORT_TIME_ORDER = [
  undefined,
  MemberContactTimeType.ANY_TIME,
  MemberContactTimeType.MORNING,
  MemberContactTimeType.AFTERNOON,
  MemberContactTimeType.CUSTOM,
];

const DetailsComponent = React.memo(
  ({ contactMethods = [], contactTimes = [], note = '' }: DetailsProps) => (
    <>
      <h4 className="grid-span-4" data-cy="member-contact-info-label">
        {translate('personalDetails.preferredContactModal.label')}
      </h4>
      <p className="grid-span-7" data-cy="member-contact-info-readonly">
        {contactMethods && (
          <p>
            {contactMethods
              ?.map(({ contactMethod, contactMethodOther }) =>
                contactMethod === MemberContactMethodType.OTHER
                  ? `${contactMethod.toString()} - ${contactMethodOther}`
                  : contactMethod.toString(),
              )
              .sort((a, b) => a.localeCompare(b))
              .join(', ')}
          </p>
        )}
        {[...contactTimes]
          ?.sort(
            (a, b) =>
              SORT_TIME_ORDER.indexOf(a?.type) -
              SORT_TIME_ORDER.indexOf(b?.type),
          )
          .map(({ dates, type, startTime, endTime }, idx) => (
            <p key={`member-contact-info-days-${idx}`}>
              {dates?.length && <>{DayOfWeek.stringify(dates)}:&nbsp;</>}
              {type !== MemberContactTimeType.CUSTOM && type?.toString()}
              {type === MemberContactTimeType.CUSTOM && (
                <>
                  {startTime
                    ? moment(startTime.getTime()).format(TIME_FORMAT)
                    : '*'}
                  &nbsp;-&nbsp;
                  {endTime
                    ? moment(endTime.getTime()).format(TIME_FORMAT)
                    : '*'}
                </>
              )}
            </p>
          ))}
        {note && (
          <Tooltip text={note}>
            <EventNoteIcon
              color="primary"
              fontSize="small"
              sx={{ marginTop: '0.5rem' }}
            />
          </Tooltip>
        )}
        {!contactMethods?.length &&
          !contactTimes[0]?.dates &&
          !contactTimes[0]?.type &&
          EMPTY}
      </p>
    </>
  ),
);

export const EditPreferredContactInfo = ({ patient, onSubmit }: Props) => {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [contactMethods, setContactMethods] = useState<
    MemberContactInfoMethod[]
  >([]);
  const [contactTypes, setContactTypes] = useState<CheckboxItem[]>([]);
  const [contactTypeOther, setContactTypeOther] = useState('');
  const [contactDateTimes, setContactDateTimes] = useState<DatesTimeItem[]>([]);
  const [note, setNote] = useState('');

  const [originalContactDateTimes, setOriginalContactDateTimes] = useState<
    DatesTimeItem[]
  >([]);
  const [originalNote, setOriginalNote] = useState('');

  useEffect(() => reset(), [patient]);

  const getInitialData = async () => {
    setLoading(true);
    const contactInfo = await getMemberContactInfoParsed(patient.id);

    const other = contactInfo.contactMethods?.find(
      ({ contactMethod }) => contactMethod === MemberContactMethodType.OTHER,
    );
    const contactTypes = MemberContactMethodType.toSelectable().map(
      ({ value, label }) =>
        ({
          checked: contactInfo.contactMethods?.find(
            ({ contactMethod }) => contactMethod?.value === value,
          ),
          disabled: other && value !== MemberContactMethodType.OTHER.value,
          value,
          label,
        } as CheckboxItem),
    );

    setContactTypes(contactTypes);
    setContactMethods(
      contactInfo.contactMethods ? [...contactInfo.contactMethods] : [],
    );
    setContactTypeOther(other?.contactMethodOther || '');
    const contactTimes = contactInfo.contactDateTimes?.sort(
      (a, b) =>
        SORT_TIME_ORDER.indexOf(a?.type) - SORT_TIME_ORDER.indexOf(b?.type),
    );

    setContactDateTimes([...contactTimes]);
    setOriginalContactDateTimes(contactTimes);
    setNote(contactInfo.note || '');
    setOriginalNote(contactInfo.note || '');
    setLoading(false);
  };

  useEffect(() => {
    getInitialData();
  }, [patient]);

  const getOtherContactType = () =>
    contactTypes.find(
      ({ value }) => value === MemberContactMethodType.OTHER.value,
    );

  const onChangeContactType = (items: CheckboxItem[]) => {
    const otherOption = getOtherContactType();
    setContactTypes(
      items.map((item) => ({
        ...item,
        disabled:
          otherOption?.checked &&
          item.value !== MemberContactMethodType.OTHER.value,
      })),
    );
  };

  const buildParams = (): MemberContactInfoParams => {
    const otherType = getOtherContactType();
    let contactMethods = [];
    if (otherType?.checked) {
      contactMethods = [
        {
          contactMethod: MemberContactMethodType.OTHER,
          contactMethodOther: contactTypeOther,
        },
      ];
    } else {
      contactMethods = contactTypes
        .filter(({ checked, disabled }) => checked && !disabled)
        .map(({ value }) => ({
          contactMethod: MemberContactMethodType.byKey[value],
        }));
    }

    const contactTimes: MemberContactInfoTimes[] = [];
    contactDateTimes.forEach(({ dates, type, startTime, endTime }) => {
      if (!dates?.length) {
        if (type) {
          contactTimes.push({
            type: type || MemberContactTimeType.ANY_TIME,
            startTime:
              type === MemberContactTimeType.CUSTOM && startTime
                ? moment(startTime.getTime())
                : undefined,
            endTime:
              type === MemberContactTimeType.CUSTOM && endTime
                ? moment(endTime.getTime())
                : undefined,
          } as MemberContactInfoTimes);
        }
      } else {
        dates.forEach((day) => {
          contactTimes.push({
            dayOfWeek: day,
            type: type || MemberContactTimeType.ANY_TIME,
            startTime:
              type === MemberContactTimeType.CUSTOM && startTime
                ? moment(startTime.getTime())
                : undefined,
            endTime:
              type === MemberContactTimeType.CUSTOM && endTime
                ? moment(endTime.getTime())
                : undefined,
          } as MemberContactInfoTimes);
        });
      }
    });

    return {
      contactMethods,
      contactTimes,
      note,
    };
  };

  const validate = () =>
    (!getOtherContactType()?.checked || contactTypeOther) &&
    !(contactDateTimes.length > 1
      ? contactDateTimes.find(
          ({ dates, type, startTime, endTime }) =>
            (!dates && !type) ||
            (type === MemberContactTimeType.CUSTOM && !startTime && !endTime),
        )
      : contactDateTimes.find(
          ({ type, startTime, endTime }) =>
            type === MemberContactTimeType.CUSTOM && !startTime && !endTime,
        ));

  const submit = async () => {
    let result = true;
    setSubmitted(true);

    if (!validate()) {
      return false;
    }

    setButtonLoading(true);
    try {
      const params = buildParams();
      await createOrUpdateMemberContactInfo(patient.id, params);
      onSubmit();
      setSubmitted(false);
      await getInitialData();
    } catch (e) {
      showGlobalError(e as string);
      result = false;
    }
    setButtonLoading(false);
    return result;
  };

  const reset = () => {
    getInitialData();
    setButtonLoading(false);
    setSubmitted(false);
  };

  return (
    <BaseModal
      details={
        <DetailsComponent
          contactMethods={contactMethods}
          contactTimes={originalContactDateTimes || []}
          note={originalNote}
        />
      }
      data-cy="member-contact-info"
      loading={loading}
      loadingSubmit={buttonLoading}
      modalSize="lg"
      onCancel={reset}
      onSubmit={submit}
      title={translate('personalDetails.preferredContactModal.title')}
    >
      <div className="grid-wrapper fit">
        <CheckboxGroup
          className="grid-span-6"
          columns={6}
          items={contactTypes}
          label={translate('personalDetails.preferredContactModal.method')}
          onChange={onChangeContactType}
        />

        <div className="grid-span-6" />

        {getOtherContactType()?.checked && (
          <TextInput
            className="grid-span-6"
            error={
              submitted &&
              !contactTypeOther &&
              translate('global.missingRequiredFieldMin')
            }
            value={contactTypeOther}
            onChange={(value) => setContactTypeOther(value || '')}
          />
        )}
        <EditDateTimes
          className="grid-span-12"
          defaultValue={contactDateTimes}
          title={translate('personalDetails.preferredContactModal.date')}
          onChange={setContactDateTimes}
          showType
          submitted={submitted}
        />

        <TextArea
          className={classNames('grid-span-12', styles.marginTop)}
          label={translate('personalDetails.preferredContactModal.note')}
          onChange={(note) => setNote(note || '')}
          rows={3}
          value={note}
        />
      </div>
    </BaseModal>
  );
};

export default EditPreferredContactInfo;
