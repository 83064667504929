import React, { useEffect, useState } from 'react';
import { Link } from 'styleguide';
import {
  fetchDocument,
  downloadDocument,
} from 'dash/src/services/DocumentServices';
import { Document } from '@vestahealthcare/common/models';

interface Props {
  documentId: number;
}

export const TaskAttachments = ({ documentId }: Props) => {
  const [document, setDocument] = useState<Document>();

  const fetchData = async () => {
    const doc = await fetchDocument(documentId);
    setDocument(doc);
  };

  useEffect(() => {
    fetchData();
  }, [documentId]);

  if (!document) return null;
  return (
    <>
      <Link onClick={() => downloadDocument(document.id, document.name)}>
        <span>
          <i className="fa fa-paperclip" />
          &nbsp;{document.name}
        </span>
      </Link>
    </>
  );
};
