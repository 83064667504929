import { Enum } from './Enum';

export class FacilityType extends Enum {
  translationKey = 'facilityType';

  static asArray: FacilityType[] = [];

  static byKey: { [name: string]: FacilityType } = {};

  static readonly VESTA = new FacilityType('VESTA');

  static readonly EXTERNAL = new FacilityType('EXTERNAL');

  constructor(public readonly value: string) {
    super();
    FacilityType.asArray.push(this);
    FacilityType.byKey[value] = this;
  }
}

export default FacilityType;
