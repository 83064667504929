const fontProxima = "'Proxima Nova', sans-serif";
const fontGascogne = 'Gascogne, sans-serif';
const fontRoboto = "'Roboto Condensed', sans-serif";
const fontWorkSans = "'Work Sans', sans-serif";

export const Fonts = {
  fontProxima,
  fontGascogne,
  fontFamily: fontProxima,
  fontTitle: fontGascogne,
  fontRoboto,
  fontSize: '16px',
  fontWorkSans,
};

export default Fonts;
