import { BaseEnum } from './BaseEnum';

export class EngagementFileType extends BaseEnum {
  static get Image() {
    return 'IMAGE';
  }

  static get PDF() {
    return 'PDF';
  }

  static get Video() {
    return 'VIDEO';
  }

  static get Word() {
    return 'DOC';
  }

  get isImage() {
    return this.id === EngagementFileType.Image;
  }

  get isPDF() {
    return this.id === EngagementFileType.PDF;
  }

  get isVideo() {
    return this.id === EngagementFileType.Video;
  }

  get isWord() {
    return this.id === EngagementFileType.Word;
  }

  get isText() {
    return (
      [
        EngagementFileType.Image,
        EngagementFileType.PDF,
        EngagementFileType.Video,
        EngagementFileType.Word,
      ].indexOf(this.id) === -1
    );
  }
}

export default EngagementFileType;
