import React, { useState } from 'react';
import { ListPickerWithPreferred } from 'styleguide';
import { WidgetProps } from '../types';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

const ListPickerWithPreferredWidget = (props: WidgetProps) => {
  const {
    formContext: { readonly },
    options: { enumOptions },
    rawErrors,
    required,
  }: WidgetProps = props;
  const [preferred, setPreferred] = useState(props.value[0]);

  if (!enumOptions) {
    throw new Error('No enum options defined');
  }

  if (readonly) {
    if (!props.value || !props.value.length) {
      return <p>{EMPTY}</p>;
    }

    const options = enumOptions
      .filter((option) => props.value.includes(option.value))
      .map((option) => option.label)
      .join(', ');

    return <p>{options}</p>;
  }

  const hasErrors = !!rawErrors && !!rawErrors.length;
  const errorText = hasErrors && rawErrors && rawErrors[rawErrors.length - 1];

  const movePreferredToFront = (val: string[], preferred: string) =>
    val
      .filter((s: string) => s === preferred)
      .concat(val.filter((s: string) => s !== preferred));

  return (
    <ListPickerWithPreferred
      onChange={(val, preferred) => {
        setPreferred(preferred);
        props.onChange(movePreferredToFront(val, preferred));
      }}
      options={enumOptions.map(({ label, value }) => ({
        value,
        label,
      }))}
      preferred={preferred}
      selection={props.value}
      validator={() => !!errorText}
      errorText={errorText}
      required={required}
      submitted={!!errorText}
    />
  );
};

export default ListPickerWithPreferredWidget;
