import React, { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { Fonts } from 'styleguide-v2/src/styles/Variables';
import { Colors } from 'styleguide-v2/src/styles/Colors';
import { Spinner } from 'styleguide-v2/src/components/Spinner';

type Props = {
  actions: Selectable[];
  'data-cy'?: string;
  loading?: boolean;
  onActionSelected: (action: Selectable) => void;
};

const styles = {
  '& .MuiPaper-root .MuiMenuItem-root': {
    fontSize: Fonts.fontSize,
    '&:hover': {
      backgroundColor: Colors.lightMint,
    },
  },
};

export const Actions = ({
  actions,
  'data-cy': dataCy,
  loading,
  onActionSelected,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClose = (evt: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    evt.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        color="primary"
        data-cy={dataCy}
        size="small"
        onClick={(evt) => {
          evt.stopPropagation();
          setAnchorEl(evt.currentTarget);
        }}
      >
        {loading ? <Spinner width={24} /> : <MoreVertIcon fontSize="large" />}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        onClose={handleClose}
        open={!!anchorEl}
        sx={styles}
      >
        {actions.map((action) => (
          <MenuItem
            disabled={action.disabled}
            key={action.value}
            onClick={(evt) => {
              handleClose(evt);
              onActionSelected(action);
            }}
          >
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
