import React from 'react';
import { Checkboxes } from 'styleguide';
import { EMPTY } from '@vestahealthcare/common/utils/constants';
import { WidgetProps } from '../types';

const CheckboxesWidget = (props: WidgetProps) => {
  const {
    formContext: { readonly },
    options: { enumOptions, selectAll },
  }: WidgetProps = props;

  if (!enumOptions) {
    throw new Error('No enum options defined for checkboxes');
  }

  if (readonly) {
    if (!props.value || !props.value.length) {
      return <p>{EMPTY}</p>;
    }

    const options = enumOptions
      .filter((option) => props.value.includes(option.value))
      .map((option) => option.label)
      .join(', ');

    return <p>{options}</p>;
  }

  return (
    <Checkboxes
      onChange={props.onChange}
      options={enumOptions.map(({ label, value }) => ({
        value,
        label,
      }))}
      selectAll={!!selectAll}
      values={props.value}
    />
  );
};

export default CheckboxesWidget;
