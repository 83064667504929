import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';
import ConsentRequest from '@vestahealthcare/common/models/ConsentRequest';
import MemberConsentAnswer from '@vestahealthcare/common/models/MemberConsentAnswer';

import { CollapseIcon, EmptyState } from 'styleguide';
import { Fonts } from 'styleguide-v2';

import { ConsentEditModal } from 'dash/src/components/ConsentEditModal';
import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { fetchPatientWarnings } from 'dash/src/redux/slices/MemberInfoSlice';
import { CacheServices } from 'dash/src/services';
import {
  createMemberConsentAnswer,
  deleteMemberConsentAnswer,
  fetchMemberConsentAnswers,
  updateMemberConsentAnswer,
} from 'dash/src/services/ConsentServices';

import MemberConsentTable from './MemberConsentTable';

interface Props {
  member: Patient;
  onLoading: (loading: boolean) => void;
}

interface ModalData {
  request: ConsentRequest;
  answers?: MemberConsentAnswer[];
}

const useStyles = makeStyles({
  title: {
    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.5)`,
      fontWeight: 500,
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const MemberConsents = ({ member, onLoading }: Props) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [consentRequests, setConsentRequests] = useState<ConsentRequest[]>([]);
  const [consentAnswers, setConsentAnswers] = useState<MemberConsentAnswer[]>(
    [],
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState<ModalData>();
  const [showDisabledRows, setShowDisabledRows] = useState(false);

  useEffect(() => {
    const getData = async () => {
      onLoading(true);
      const [requests, answers] = await Promise.all([
        CacheServices.getMemberConsentRequests(),
        fetchMemberConsentAnswers(member.id),
      ]);
      setConsentRequests(requests.sort((a, b) => a.sortValue - b.sortValue));
      setConsentAnswers(answers);
      onLoading(false);
    };
    getData();
  }, []);

  useEffect(() => {
    if (modalData) {
      setModalOpen(true);
    }
  }, [modalData]);

  const enabledConsents = consentRequests.filter(
    (request) => !!request.enabled,
  );
  const disabledConsents = consentRequests.filter(
    (request) => !request.enabled,
  );

  return (
    <>
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>
          {translate('memberConsents.sectionTitle')}
        </h2>
      </div>
      <MemberConsentTable
        consents={enabledConsents}
        answers={consentAnswers}
        onEdit={
          member.isEditable()
            ? (request, answers) =>
                setModalData({
                  request,
                  answers,
                })
            : undefined
        }
      />
      <EmptyState show={!enabledConsents.length}>
        {translate('memberConsents.empty')}
      </EmptyState>
      {!!disabledConsents.length && (
        <footer>
          <div className="right-side">
            <button
              className="h3 null"
              onClick={() => setShowDisabledRows(!showDisabledRows)}
              data-cy="toggle-disabled-consents"
            >
              <CollapseIcon active={showDisabledRows} />
              {translate('memberConsents.disabled')}
            </button>
          </div>
        </footer>
      )}
      {showDisabledRows && (
        <MemberConsentTable
          data-cy="disabled-consents"
          consents={disabledConsents}
          answers={consentAnswers}
          isInactive
        />
      )}
      <ConsentEditModal
        open={modalOpen}
        request={modalData?.request}
        answers={modalData?.answers}
        onClear={async (id: number) => {
          let result = true;
          try {
            await deleteMemberConsentAnswer(member.id, id);
            const answers = await fetchMemberConsentAnswers(member.id);
            if (member) {
              dispatch(fetchPatientWarnings(member.id));
            }
            setConsentAnswers(answers);
            setModalOpen(false);
          } catch (e) {
            showGlobalError(e as string);
            result = false;
          }
          return result;
        }}
        onClose={() => setModalOpen(false)}
        onSubmit={async (param) => {
          let result = true;
          try {
            if (param.id) {
              await updateMemberConsentAnswer(member.id, param.id, param);
            } else {
              await createMemberConsentAnswer(member.id, param);
            }
            const answers = await fetchMemberConsentAnswers(member.id);
            if (member) {
              dispatch(fetchPatientWarnings(member.id));
            }
            setConsentAnswers(answers);
            setModalOpen(false);
          } catch (e) {
            showGlobalError(e as string);
            result = false;
          }
          return result;
        }}
      />
    </>
  );
};

export default MemberConsents;
