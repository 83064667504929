import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { CareTeamMember } from '@vestahealthcare/common/models';

import { Fonts, IconButton } from 'styleguide-v2';

import { LinkedMembersTable } from 'dash/src/pages/CareTeam/components/LinkedMembersTable';

type Props = {
  className?: string;
  members?: CareTeamMember[];
  onActivate: (ctm: CareTeamMember) => void;
  onAdd: () => void;
  onDelete: (ctm: CareTeamMember) => void;
  onEdit: (ctm: CareTeamMember) => void;
};

const useStyles = makeStyles({
  title: {
    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.5)`,
      fontWeight: 500,
    },
  },
});

export const LinkCareTeamPersonStep = ({
  className,
  members,
  onActivate,
  onAdd,
  onDelete,
  onEdit,
}: Props) => {
  const styles = useStyles();
  const [linkedMembers, setLinkedMembers] = useState<CareTeamMember[]>([]);

  useEffect(() => {
    setLinkedMembers(members?.length ? [...members] : []);
  }, [members]);

  return (
    <div className={className}>
      <div className="flex gap">
        <h2 className={styles.title}>
          {translate('careTeam.steps.linkedMembersTitle')}
        </h2>
        <IconButton
          color="default"
          data-cy="member-profile-network-edit"
          size="small"
          onClick={onAdd}
        >
          <AddIcon color="primary" />
        </IconButton>
      </div>
      {!!linkedMembers?.length && (
        <LinkedMembersTable
          members={linkedMembers}
          mode="member"
          onActivate={onActivate}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      )}
    </div>
  );
};

export default LinkCareTeamPersonStep;
