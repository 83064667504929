import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/styles';

import { Button } from 'styleguide';

import { VestaChatContext } from 'dash/src/contexts/VestaChatContext';
import Session from 'dash/src/services/SessionServices';

const useStyles = makeStyles({
  button: {
    whiteSpace: 'nowrap',
    marginRight: 10,
  },
  countContainer: {
    backgroundColor: 'red',
    borderRadius: 50,
    minHeight: 17,
    minWidth: 17,
    paddingTop: 1,
    marginRight: 5,
    fontSize: 10,
    color: 'white',
  },
});

const UnreadMessages = () => {
  const styles = useStyles();

  const { unreadCount, channels } = useContext(VestaChatContext);
  const linkToChat = channels?.find(
    (c) => c.data?.rnOwnerId === Session.actingUser.id,
  )
    ? `/#/chats&rnOwnerId=${Session.actingUser.id}`
    : '/#/chats';
  const unread = Session.actingUser.isRN
    ? unreadCount?.assigned
    : unreadCount?.total;

  return (
    <Button
      data-cy="unread-messages"
      color="secondary"
      href={linkToChat}
      isPill
      className={styles.button}
    >
      {unread ? (
        <>
          <span className={styles.countContainer}>{unread}</span>
          Unread Chat Messages
        </>
      ) : (
        <>View Chat Messages</>
      )}
    </Button>
  );
};

export default UnreadMessages;
