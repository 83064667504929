import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { CareTeamMemberFamily } from '@vestahealthcare/common/models';

import { Select } from 'styleguide-v2';

import { CreateCareTeamMemberFamilyParams } from 'dash/src/services/CareTeamServices';

type Props = {
  member: CareTeamMemberFamily;
  onChange: (params: Partial<CreateCareTeamMemberFamilyParams>) => void;
};

export const FamilyFields = ({ member, onChange }: Props) => {
  const [paid, setPaid] = useState<boolean>();
  const [livesWithMember, setLivesWithMember] = useState<boolean>();

  useEffect(() => {
    setPaid(member?.paid);
    setLivesWithMember(member?.livesWithMember);
  }, [member]);

  const handleChange = (changes: Partial<any>) => {
    onChange(changes);
  };

  return (
    <div className="grid-wrapper fit">
      <h2 className="grid-span-12" style={{ fontSize: '1em' }}>
        {translate('careTeam.modals.edit.familyFields')}
      </h2>

      <Select.Choice
        className="grid-span-6"
        data-cy="care-team-member-paid"
        label={translate('careTeam.common.paid')}
        onChange={(paid?: boolean) => {
          setPaid(paid);
          handleChange({ paid: paid !== undefined ? paid : null });
        }}
        value={paid}
      />

      <Select.Choice
        className="grid-span-6"
        data-cy="care-team-member-livesWithMember"
        label={translate('careTeam.common.livesWithMember')}
        onChange={(livesWithMember?: boolean) => {
          setLivesWithMember(livesWithMember);
          handleChange({
            livesWithMember:
              livesWithMember !== undefined ? livesWithMember : null,
          });
        }}
        value={livesWithMember}
      />
    </div>
  );
};
