export class EngagementContentBase {
  id = 0;

  name = '';

  description = '';

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default EngagementContentBase;
