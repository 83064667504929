import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';

import { Button, FilterButton, IconButton, Panel, Select } from 'styleguide-v2';
import { showGlobalError } from 'dash/src/components/GlobalMessage';

import { translate } from '@vestahealthcare/common/i18n';
import { Choice, Enum, Language } from '@vestahealthcare/common/enums';
import {
  EngagementAudience,
  EngagementContent,
  EngagementFileType,
  BaseEnum,
} from '@vestahealthcare/common/models';

import {
  createContentLibrary,
  getEngagementContents,
  GetEngagementContentsParams,
  updateContentLibrary,
} from 'dash/src/services/EngagementServices';
import { CacheServices } from 'dash/src/services';

import { ContentEditModal } from './ContentEditModal';
import { ContentLibraryTable } from './ContentLibraryTable';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

export const ContentLibraryDashboard = () => {
  const query = useQueryParams();

  const id = query.get('id');
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

  const [audiences, setAudiences] = useState<EngagementAudience[]>([]);
  const [fileTypes, setFileTypes] = useState<EngagementFileType[]>([]);
  const [types, setTypes] = useState<BaseEnum[]>([]);
  const [languagesAvailable, setLanguagesAvailable] = useState<Language[]>([]);

  const [filters, setFilters] = useState<GetEngagementContentsParams>({});
  const [contents, setContents] = useState<EngagementContent[]>([]);

  const [showEditContent, setShowEditContent] = useState<boolean>(false);
  const [editContent, setEditContent] = useState<EngagementContent>();

  const getFiltersData = async () => {
    setLoadingFilters(true);
    const [audiences, types, fileTypes, languages] = await Promise.all([
      CacheServices.getEngagementContentAudiences(),
      CacheServices.getEngagementContentTypes(),
      CacheServices.getEngagementFileTypes(),
      CacheServices.getEngagementLanguages(),
    ]);
    setAudiences(audiences);
    setTypes(types);
    setFileTypes(fileTypes);
    setLanguagesAvailable(languages);
    setLoadingFilters(false);
  };

  const getContents = async () => {
    setLoading(true);
    try {
      const contents = await getEngagementContents({
        id: id ? Number(id) : undefined,
        ...filters,
      });
      setContents(contents);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  const onAddContent = () => {
    setEditContent(undefined);
    setShowEditContent(true);
  };

  const onEditContent = (item: EngagementContent) => {
    setEditContent(item);
    setShowEditContent(true);
  };

  useEffect(() => {
    getFiltersData();
  }, []);

  useEffect(() => {
    getContents();
  }, [filters, id]);

  return (
    <Panel id="groups-section">
      <Panel.Heading
        title={
          id
            ? translate('campaigns.contentLibrary.detail')
            : translate('campaigns.contentLibrary.title')
        }
      >
        <FilterButton
          className="filters"
          data-cy="members-filters"
          filters={filters}
          onClick={() => setFiltersOpen(!filtersOpen)}
        />
        <Panel.Actions>
          <IconButton
            onClick={onAddContent}
            tooltip={translate('campaigns.contentLibrary.add')}
          >
            <AddIcon fontSize="large" />
          </IconButton>
        </Panel.Actions>
        <Panel.Collapse open={filtersOpen}>
          <div className="grid-wrapper fit">
            <Select
              className="grid-span-3"
              data-cy="content-file-filter-content-type"
              getItemLabel={({ description }: BaseEnum) => description}
              items={types}
              loading={loadingFilters}
              multiple
              onChange={(val?: BaseEnum[]) =>
                setFilters({ ...filters, contentTypeIds: val })
              }
              placeholder={translate('campaigns.contentLibrary.contentType')}
              value={filters.contentTypeIds || []}
            />
            <Select
              className="grid-span-3"
              data-cy="content-file-filter-audience"
              getItemLabel={({ description }: EngagementAudience) =>
                description
              }
              items={audiences}
              loading={loadingFilters}
              multiple
              onChange={(val?: EngagementAudience[]) =>
                setFilters({ ...filters, tags: val })
              }
              placeholder={translate('campaigns.contentLibrary.audience')}
              value={filters.tags || []}
            />
            <Select
              className="grid-span-3"
              data-cy="content-file-filter-file-type"
              getItemLabel={({ description }: EngagementFileType) =>
                description
              }
              items={fileTypes}
              loading={loadingFilters}
              multiple
              onChange={(val?: EngagementFileType[]) =>
                setFilters({ ...filters, fileTypeIds: val })
              }
              placeholder={translate('campaigns.contentLibrary.fileType')}
              value={filters.fileTypeIds || []}
            />
            <Select
              className="grid-span-3"
              data-cy="content-file-filter-language"
              items={Enum.toSelectable(languagesAvailable)}
              loading={loadingFilters}
              multiple
              onChange={(val?: Selectable[]) =>
                setFilters({
                  ...filters,
                  languages: val?.map(({ value }) => Language.byKey[value]),
                })
              }
              placeholder={translate('campaigns.contentLibrary.language')}
              value={Enum.toSelectable(filters.languages || [])}
            />
            <Select
              className="grid-span-3"
              data-cy="content-file-filter-active"
              items={Choice.getChoices()}
              onChange={(val: Selectable) =>
                setFilters({
                  ...filters,
                  active: val !== undefined ? Boolean(val.value) : undefined,
                })
              }
              placeholder={translate('campaigns.contentLibrary.active')}
              value={
                filters.active !== undefined
                  ? Choice.getChoices()[filters.active ? 0 : 1]
                  : undefined
              }
            />
            <div className="grid-span-6" />
            <Button
              className="grid-span-3"
              data-cy="content-file-filter-clear"
              color="secondary"
              type="outlined"
              onClick={() => setFilters({})}
            >
              {translate('global.clearFilters')}
            </Button>
          </div>
        </Panel.Collapse>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <ContentLibraryTable contentItems={contents} onEdit={onEditContent} />
        <ContentEditModal
          open={showEditContent}
          content={editContent}
          onClose={() => setShowEditContent(false)}
          onSubmit={async (params, files, content) => {
            let result = true;
            try {
              if (content?.id) {
                await updateContentLibrary(content.id, params, files);
              } else {
                await createContentLibrary(params, files);
              }
              await getContents();
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
        />
      </Panel.Body>
    </Panel>
  );
};

export default ContentLibraryDashboard;
