import React, { useEffect, useState } from 'react';
import { translate } from '@vestahealthcare/common/i18n';
import { Button, Checkbox, Colors, TextInput } from 'styleguide';
import { makeStyles } from '@material-ui/styles';
import { TaskCustomField } from '@vestahealthcare/common/models/TaskCustomField';
import { TaskCustomFieldType } from '@vestahealthcare/common/enums/TaskCustomFieldType';

type Props = {
  field: TaskCustomField;
  onDelete?: (field: TaskCustomField) => void;
  onEdit: (field: TaskCustomField) => void;
  submitted: boolean;
};

const useStyles = makeStyles({
  container: {
    backgroundColor: Colors.lightGray,
    padding: '1rem 2rem',
    marginTop: '2rem',
    width: '100%',
  },
  header: {
    color: Colors.shadowGreen,
  },
});

export const EditDateCustomField = (props: Props) => {
  const styles = useStyles();
  const { field, onDelete, onEdit, submitted } = props;
  const [editableField, setEditableField] = useState<TaskCustomField>(
    {} as TaskCustomField,
  );

  useEffect(() => {
    setEditableField({ ...field });
  }, [field]);

  return (
    <article className={`grid-wrapper grid-span-12 ${styles.container}`}>
      <header className="grid-wrapper grid-span-12">
        <span className={`h7 grid-span-11 ${styles.header}`}>
          {translate(
            `global.${
              field.type === TaskCustomFieldType.DATE ? 'date' : 'time'
            }`,
          )}
        </span>
        {onDelete && (
          <Button
            color="tertiary"
            className="grid-span-1"
            data-cy="delete-custom-field"
            onClick={() => onDelete && onDelete(field)}
          >
            <i className="fa fa-trash" />
            &nbsp;
            {translate('global.delete')}
          </Button>
        )}
      </header>
      <TextInput
        columns={4}
        data-cy="custom-field-label-input"
        errorText={translate('global.missingRequiredField')}
        label={translate(
          `taskDefinitions.subtask.${
            field.type === TaskCustomFieldType.DATE ? 'date' : 'time'
          }Label`,
        )}
        onChange={(label = '') => {
          setEditableField({ ...editableField, label });
          onEdit({ ...editableField, label });
        }}
        value={editableField.label}
        required
        showError={submitted && !editableField.label}
      />

      <Checkbox
        checked={editableField.required}
        columns={12}
        data-cy="custom-field-required-input"
        label={translate('taskDefinitions.subtask.requiredField')}
        onChange={(required = false) => {
          setEditableField({ ...editableField, required });
          onEdit({ ...editableField, required });
        }}
      />
    </article>
  );
};

export default EditDateCustomField;
