import React, { useEffect, useState } from 'react';

import { Choice } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { BaseEnum, EventDetail } from '@vestahealthcare/common/models';

import { Button, CollapsableSidebar, Select } from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import { CloseEventParams } from 'dash/src/services/EventServices';

interface Props {
  event?: EventDetail;
  open: boolean;
  onClose: () => void;
  onSubmit: (event: EventDetail, params: CloseEventParams) => Promise<boolean>;
}

export const MemberEventCloseModal = ({
  event,
  open,
  onClose,
  onSubmit,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [loadingDiverted, setLoadingDiverted] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [divertedFromOptions, setDivertedFromOptions] = useState<BaseEnum[]>(
    [],
  );

  const [wouldVisitER, setWouldVisitER] = useState<boolean>();
  const [divertedFrom, setDivertedFrom] = useState<BaseEnum>();
  const [interventionPreventedER, setInterventionPreventedER] = useState<
    boolean
  >();
  const [npEscalationRequired, setNPEscalationRequired] = useState<boolean>();

  const reset = () => {
    setLoading(false);
    setSubmitted(false);
    if (event) {
      const {
        isClosed,
        wouldVisitER,
        divertedFrom,
        interventionPreventedER,
        npEscalationRequired,
      } = event;
      setWouldVisitER(isClosed ? wouldVisitER : undefined);
      setDivertedFrom(isClosed ? divertedFrom : undefined);
      setInterventionPreventedER(
        isClosed ? interventionPreventedER : undefined,
      );
      setNPEscalationRequired(isClosed ? npEscalationRequired : undefined);
    }
  };

  const getInitialData = async () => {
    setLoadingDiverted(true);
    const options = await CacheServices.getEventDivertedFromOptions();
    setDivertedFromOptions(options);
    setLoadingDiverted(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  const validate = () => wouldVisitER || divertedFrom !== undefined;

  const doSubmit = async () => {
    setSubmitted(true);

    if (
      event &&
      wouldVisitER !== undefined &&
      interventionPreventedER !== undefined &&
      npEscalationRequired !== undefined &&
      validate()
    ) {
      setLoading(true);
      try {
        const result = await onSubmit(event, {
          wouldVisitER,
          divertedFrom: wouldVisitER ? undefined : divertedFrom,
          interventionPreventedER,
          npEscalationRequired,
        });
        if (result) {
          onClose();
        }
      } finally {
        setSubmitted(false);
        setLoading(false);
      }
    }
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={
        <h2>
          {translate(
            `memberEvents.modals.${
              event?.isClosed ? 'edDiversionTitle' : 'closeEventTitle'
            }`,
          )}
        </h2>
      }
      size={400}
    >
      <CollapsableSidebar.Body>
        <Select
          error={submitted && wouldVisitER === undefined}
          items={Choice.getChoices()}
          label={translate('memberEvents.modals.wouldVisitER')}
          onChange={(item?: { value: boolean }) => setWouldVisitER(item?.value)}
          required
          value={
            wouldVisitER !== undefined
              ? Choice.getChoices()[wouldVisitER ? 0 : 1]
              : undefined
          }
        />
        {wouldVisitER === false && (
          <Select
            error={submitted && !divertedFrom}
            label={translate('memberEvents.modals.divertedFrom')}
            loading={loadingDiverted}
            onChange={setDivertedFrom}
            getItemLabel={({ description }: BaseEnum) => description}
            items={divertedFromOptions}
            required={wouldVisitER === false}
            value={divertedFrom}
          />
        )}
        <Select
          error={submitted && interventionPreventedER === undefined}
          items={Choice.getChoices()}
          label={translate('memberEvents.modals.interventionPreventedER')}
          onChange={(item?: { value: boolean }) =>
            setInterventionPreventedER(item?.value)
          }
          required
          value={
            interventionPreventedER !== undefined
              ? Choice.getChoices()[interventionPreventedER ? 0 : 1]
              : undefined
          }
        />
        <Select
          error={submitted && npEscalationRequired === undefined}
          items={Choice.getChoices()}
          label={translate('memberEvents.modals.npEscalationRequired')}
          onChange={(item?: { value: boolean }) =>
            setNPEscalationRequired(item?.value)
          }
          required
          value={
            npEscalationRequired !== undefined
              ? Choice.getChoices()[npEscalationRequired ? 0 : 1]
              : undefined
          }
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button color="tertiary" data-cy="close-event-close" onClick={onClose}>
          {translate('global.cancel')}
        </Button>
        <Button
          color="secondary"
          data-cy="close-event-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {event?.isClosed
            ? translate('global.save')
            : translate('memberEvents.modals.closeEvent')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default MemberEventCloseModal;
