import React from 'react';
import { Button } from 'styleguide';
import { Icon } from 'styleguide/src/components/Icons';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '@vestahealthcare/common/utils/constants';
import { DashboardType } from './services';

export const iconMap = {
  iOS: 'apple',
  Android: 'android',
};

export enum AppOS {
  IOS = 'iOS',
  ANDROID = 'Android',
}

export interface Build {
  id: number;
  uploaded_at: string;
  short_version: string;
  appOS: AppOS;
  version: string;
  key: string;
}

export interface BuildDetails {
  id: number;
  release_notes: string;
  install_url: string;
  branchName: string;
  jiraTicket?: string;
  version: string;
  key: string;
}

export const getFullDetails = (details: BuildDetails): BuildDetails => {
  const branchName = details.release_notes ?? '';
  const jiraTicket = getTicketFromBranch(branchName);
  return {
    ...details,
    branchName,
    jiraTicket,
  };
};

export const getJiraTicketUrl = (ticketId: string) =>
  `https://hometeamcare.atlassian.net/browse/${ticketId}`;
export const getGithubUrl = (
  deviceType: DashboardType,
  details: BuildDetails,
) =>
  `https://github.com/hometeam/${
    deviceType === DashboardType.TABLET ? 'platform-app' : 'mobile-app'
  }/tree/${details.branchName}`;
export const getBuildUrl = (
  deviceType: DashboardType,
  releaseId: string | number,
) => `/#/dev/mobile-dashboard/${deviceType}/${releaseId}`;

export const BRANCH_REGEX = /\/(ht-[0-9]+)/i;
export const getTicketFromBranch = (branchName: string) => {
  const branchSplit = branchName.split(BRANCH_REGEX);
  return branchSplit[1];
};

interface CopyProps {
  deviceType: DashboardType;
  releaseId: string | number;
}

export const CopyLink = ({ deviceType, releaseId }: CopyProps) => (
  <Icon
    className="margin-left"
    onClick={() =>
      navigator.clipboard.writeText(
        `https://dash.integration.hometeamcare.com${getBuildUrl(
          deviceType,
          releaseId,
        )}`,
      )
    }
  >
    <i className="fa fa-link" />
  </Icon>
);

interface BtnProps {
  release?: Build;
  details?: BuildDetails;
}

export const InstallButton = ({ release, details }: BtnProps) => (
  <Button
    btnSize="xs"
    color={release?.appOS === AppOS.ANDROID ? 'green' : 'primary'}
    href={details?.install_url}
    loading={!release || !details}
    rel="noopener noreferrer"
    target="blank"
  >
    {release && (
      <Icon
        className={`fa fa-${iconMap[release.appOS]} margin-right`}
        tag="span"
      />
    )}
    Install
  </Button>
);

export const JiraLink = ({ details }: BtnProps) => {
  if (!details?.jiraTicket) {
    return null;
  }

  return (
    <Button
      btnSize="xs"
      color="secondary"
      href={getJiraTicketUrl(details.jiraTicket)}
      target="_blank"
    >
      Jira ({details.jiraTicket})
    </Button>
  );
};

interface DetailsProps {
  deviceType: DashboardType;
  details?: BuildDetails;
  release?: Build;
}

const Detail = ({ title, children }: { title: string; children: any }) => (
  <div style={{ marginBottom: '20px' }}>
    <p className="medium">{title}</p>
    <p>{children}</p>
  </div>
);

export const ReleaseInfo = ({ deviceType, release, details }: DetailsProps) => {
  if (!release || !details) {
    return null;
  }

  return (
    <>
      <Detail title="Version">
        {`${release.appOS} - ${release.version} (${release.short_version})`}
      </Detail>
      <Detail title="Uploaded">
        {moment(release.uploaded_at).format(DATE_TIME_FORMAT)}
      </Detail>
      <Detail title="Branch Name">
        <a
          href={getGithubUrl(deviceType, details)}
          rel="noopener noreferrer"
          target="_blank"
        >
          {details.branchName}
        </a>
      </Detail>
    </>
  );
};
