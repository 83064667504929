import classNames from 'classnames';
import { Moment } from 'moment';
import React, { Fragment, useState } from 'react';

import RefreshIcon from '@mui/icons-material/Autorenew';
import { ClassNameMap, makeStyles } from '@mui/styles';

import { MemberDashboardActionStatus } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  MemberDashboardAction,
  PaginationType,
} from '@vestahealthcare/common/models';
import { DATE_FORMAT_SHORT } from '@vestahealthcare/common/utils/constants';

import {
  CollapseIcon,
  Colors,
  Fonts,
  StatusIndicator,
  Table,
  Tooltip,
  UNASSIGNED,
} from 'styleguide-v2';

import { MAX_DASHBOARD_TABLE_ITEMS } from './contansts';

type Props = {
  className?: string;
  data: MemberDashboardAction[];
  lastUpdated?: Moment;
  pagination?: PaginationType;
};

const RECENT_ACTIONS_COLUMNS = (
  styles: ClassNameMap<'firstParragraph' | 'status'>,
) => [
  {
    headerName: 'Action',
    component: ({
      subject,
      createdAt,
      startedAt,
      dueDate,
      completedAt,
      date,
      dateLabel,
      status,
    }: MemberDashboardAction) => (
      <>
        <p
          className={classNames(
            styles.firstParragraph,
            'bold no-margin',
            !subject && 'italic gray',
          )}
        >
          {subject || translate('global.unknown')}
        </p>
        <Tooltip
          text={
            <div key={`action-${createdAt}`} style={{ width: '150px' }}>
              <p className="no-margin white flex spaced">
                <span>{translate('common.created')}:</span>
                <span>{createdAt?.format(DATE_FORMAT_SHORT)}</span>
              </p>

              {![
                MemberDashboardActionStatus.NOT_STARTED.value,
                MemberDashboardActionStatus.CANCELLED.value,
              ].includes(status) && (
                <p className="no-margin white flex spaced">
                  <span>{translate('common.started')}:</span>
                  <span>
                    {startedAt?.format(DATE_FORMAT_SHORT) ||
                      translate('global.unknown')}
                  </span>
                </p>
              )}

              <p className="no-margin white flex spaced">
                <span>{translate('common.due')}:</span>
                <span>
                  {dueDate?.format(DATE_FORMAT_SHORT) ||
                    translate('global.unknown')}
                </span>
              </p>

              {MemberDashboardActionStatus.COMPLETED.value === status && (
                <p className="no-margin white flex spaced">
                  <span>{translate('common.completed')}:</span>
                  <span>
                    {completedAt?.format(DATE_FORMAT_SHORT) ||
                      translate('global.unknown')}
                  </span>
                </p>
              )}
            </div>
          }
        >
          <p className="gray no-margin semi-bold size-xs">
            {`${dateLabel}: ${
              date?.format(DATE_FORMAT_SHORT) || translate('global.unknown')
            }`}
          </p>
        </Tooltip>
      </>
    ),
  },
  {
    headerName: 'Status',
    component: ({ status, employeeName }: MemberDashboardAction) => (
      <StatusIndicator
        className={styles.status}
        color={MemberDashboardActionStatus.getColorByString(status)}
        status={status}
        subtitle={employeeName || UNASSIGNED?.fullName}
      />
    ),
  },
];

const useStyles = makeStyles({
  table: {
    marginTop: 1,
  },
  firstParragraph: {
    '&&': {
      margin: '4px 0 -2px',
    },
  },
  status: {
    '& > :first-child': {
      margin: '4px 0 0',
    },
    '& > :last-child': {
      marginTop: '-3px',
    },
  },
  tableContainer: {
    display: 'flex',
    marginBottom: '1rem',
    minHeight: '2rem',
    maxHeight: '30.5rem',
    overflow: 'hidden',

    transition: 'max-height 0.3s ease',
  },
  updated: {
    color: Colors.textGray,
    fontSize: `calc(${Fonts.fontSize} * 0.625)`,
    fontStyle: 'italic',
  },
  seeMore: {
    color: Colors.textGreen,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
  },
});

export const RecentActionTable = (props: Props) => {
  const styles = useStyles();

  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className={props.className}>
      <div
        className={styles.tableContainer}
        style={{
          maxHeight: open ? `${props.data.length * 5.5 + 3}rem` : undefined,
        }}
      >
        <Table
          className={styles.table}
          config={{
            columns: RECENT_ACTIONS_COLUMNS(styles),
            compact: true,
            data: props.data || [],
            paginationOptions: [-1],
          }}
          empty={translate('memberProfile.dashboard.noRecentActions')}
          whiteBackground
        />
      </div>
      <div className="flex spaced">
        {props.data.length > MAX_DASHBOARD_TABLE_ITEMS ? (
          <a
            className={classNames('flex middle min-gap', styles.seeMore)}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(!open);
            }}
          >
            <CollapseIcon open={open} htmlColor={Colors.textGreen} />
            {translate(`memberProfile.dashboard.${open ? 'hide' : 'see'}More`, {
              count: props.data.length - MAX_DASHBOARD_TABLE_ITEMS,
            })}
          </a>
        ) : (
          <div />
        )}
        {props.lastUpdated ? (
          <div className="flex min-gap">
            <RefreshIcon htmlColor={Colors.iconGray} fontSize="small" />
            <span className={styles.updated}>
              {translate('memberProfile.dashboard.lastUpdatedECW')}
            </span>
            <span className={classNames('bold', styles.updated)}>
              {props.lastUpdated.calendar()}
            </span>
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};
