import { make, makeArray } from './Model';
import { Encounter } from './Encounter';
import { MemberEventsSummaryCount } from './MemberEventsSummaryCount';
import { EventIntervention } from './EventIntervention';

export class MemberEventsSummary {
  events: MemberEventsSummaryCount[];

  lastEncounter: Encounter;

  interventions: EventIntervention[];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.events = makeArray(obj.events, MemberEventsSummaryCount);
    this.interventions = makeArray(obj.interventions, EventIntervention);
    this.lastEncounter = make(obj.lastEncounter, Encounter);
  }
}

export default MemberEventsSummary;
