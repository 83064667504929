import React, { useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { TextInput } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { updateName } from 'dash/src/services/PatientServices';

import { BaseModal } from './BaseModal';

interface Props {
  patient: Patient;
  onSubmit: (patient: Patient) => void;
}

const renderDetails = (patient: Patient) => (
  <>
    <h4 className="grid-span-5">{translate('home.rn.patientName')}</h4>
    <p className="grid-span-6">{patient.fullName || EMPTY}</p>
  </>
);

enum InvalidInput {
  NUMBER = 'NUMBER',
  EMPTY = 'EMPTY',
}

const determineErrorType = (name?: string) => {
  if (name?.trim()) {
    if (name.match(/\d/g)) {
      return InvalidInput.NUMBER;
    }

    return undefined;
  }
  return InvalidInput.EMPTY;
};

const getErrorMessage = (invalidInput: InvalidInput) =>
  invalidInput === InvalidInput.EMPTY
    ? translate('global.missingRequiredFieldMin')
    : translate('global.numbersNotAllowed');

export const EditName = React.memo((props: Props) => {
  const { patient, onSubmit } = props;
  const isEditable = patient.isEditable() && patient.isDemographicsEditable();
  const [firstName, setFirstName] = useState(patient.firstName);
  const [lastName, setLastName] = useState(patient.lastName);
  const [firstError, setFirstError] = useState<InvalidInput>();
  const [lastError, setLastError] = useState<InvalidInput>();
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    let result = true;
    const firstErrorType = determineErrorType(firstName);
    const lastErrorType = determineErrorType(lastName);

    if (firstErrorType || lastErrorType) {
      setFirstError(firstErrorType);
      setLastError(lastErrorType);
      return false;
    }

    setLoading(true);

    try {
      const newPatient = await updateName(patient.id, {
        firstName: firstName?.trim() || '',
        lastName: lastName?.trim() || '',
      });
      onSubmit(newPatient);
    } catch (e) {
      showGlobalError(e as string);
      result = false;
    }
    setLoading(false);
    return result;
  };

  return (
    <BaseModal
      details={renderDetails(patient)}
      data-cy="patient-name"
      editable={isEditable}
      loadingSubmit={loading}
      onSubmit={submit}
      onCancel={() => {
        setFirstName(patient.firstName);
        setLastName(patient.lastName);
        setFirstError(undefined);
        setLastError(undefined);
      }}
      title={translate('home.rn.patientName')}
    >
      <div className="grid-wrapper">
        <TextInput
          className="grid-span-6"
          error={!!firstError && getErrorMessage(firstError)}
          value={firstName}
          label={translate('contacts.firstName')}
          onChange={(val) => {
            setFirstName(val || '');
            setFirstError(undefined);
          }}
        />
        <TextInput
          className="grid-span-6"
          error={!!lastError && getErrorMessage(lastError)}
          value={lastName}
          label={translate('contacts.lastName')}
          onChange={(val) => {
            setLastName(val || '');
            setLastError(undefined);
          }}
        />
      </div>
    </BaseModal>
  );
});

export default EditName;
