import moment from 'moment';
import React, { useEffect, useState } from 'react';

import LaunchIcon from '@mui/icons-material/Launch';

import { translate } from '@vestahealthcare/common/i18n';
import { SkilledDetail } from '@vestahealthcare/common/models';
import {
  DATE_FORMAT,
  DATE_FORMAT_SHORT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  DateTimePicker,
  Label,
  TextInput,
} from 'styleguide-v2';

import { UpdateSkilledParams } from 'dash/src/services/TOCServices';

type Props = {
  className?: string;
  detail?: SkilledDetail;
  onChange: (params: Partial<UpdateSkilledParams>) => void;
  readonly?: boolean;
  submitted?: boolean;
};

export const SkilledEpisodeCard = ({
  className,
  detail,
  onChange,
  readonly,
  submitted,
}: Props) => {
  const [chiefComplaint, setChiefComplaint] = useState<string>();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  useEffect(() => {
    setChiefComplaint(detail?.skilledRecord?.chiefComplaint);
    setStartDate(detail?.skilledRecord?.startDate?.toDate());
    setEndDate(detail?.skilledRecord?.endDate?.toDate());
  }, [detail]);

  useEffect(() => {
    onChange({
      chiefComplaint,
      startDate: startDate
        ? moment(startDate.getTime()).format(DATE_FORMAT)
        : null,
      endDate: endDate ? moment(endDate.getTime()).format(DATE_FORMAT) : null,
    });
  }, [chiefComplaint, startDate, endDate]);

  return (
    <Card className={className}>
      <CardHeader
        title={translate('toc.detail.episodeTitle')}
        action={
          <Button
            color="quaternary"
            icon={<LaunchIcon />}
            size="s"
            onClick={() =>
              window.open(
                `/#/patients/${detail?.memberDetail?.id}/events/${detail?.skilledRecord?.sourceEventId}`,
                '_blank',
              )
            }
          >
            <span className="bold">{translate('toc.detail.openEvent')}</span>
          </Button>
        }
      />
      <CardContent className="grid-wrapper" sx={{ paddingTop: 0 }}>
        <TextInput
          className="grid-span-12"
          error={submitted && !chiefComplaint}
          label={translate('toc.detail.chiefComplaint')}
          onChange={setChiefComplaint}
          readOnly={readonly}
          value={chiefComplaint}
          required
        />
        {readonly ? (
          <div className="grid-span-3">
            <p className="no-margin">
              <Label>{translate('toc.detail.episodeStartDate')}</Label>
            </p>
            <span
              className="flex middle extra-gap"
              style={{ marginTop: readonly ? undefined : '1rem' }}
            >
              {detail?.skilledRecord?.startDate?.format(DATE_FORMAT_SHORT) ||
                EMPTY}{' '}
              {detail?.skilledRecord?.episodeDuration !== undefined && (
                <Chip color="action" type="contained" size="small">
                  <span>{detail.skilledRecord.episodeDuration}</span>
                </Chip>
              )}
            </span>
          </div>
        ) : (
          <DateTimePicker
            className="grid-span-3"
            label={translate('toc.detail.episodeStartDate')}
            maxDate={detail?.skilledRecord?.endDate?.toDate()}
            onChange={setStartDate}
            value={startDate}
          />
        )}
        {readonly ? (
          <div className="grid-span-3">
            <p className="no-margin">
              <Label>{translate('toc.detail.episodeEndDate')}</Label>
            </p>
            <span
              className="flex middle extra-gap"
              style={{ marginTop: readonly ? undefined : '1rem' }}
            >
              {detail?.skilledRecord?.endDate?.format(DATE_FORMAT_SHORT) ||
                EMPTY}{' '}
              {detail?.skilledRecord?.businessDaysSinceEndDate !==
                undefined && (
                <Chip color="action" type="contained" size="small">
                  <span>{detail.skilledRecord.businessDaysSinceEndDate}</span>
                </Chip>
              )}
            </span>
          </div>
        ) : (
          <DateTimePicker
            className="grid-span-3"
            label={translate('toc.detail.episodeEndDate')}
            minDate={detail?.skilledRecord?.startDate?.toDate()}
            onChange={setEndDate}
            value={endDate}
          />
        )}
        <div className="grid-span-6">
          <p className="no-margin">
            <Label>{translate('toc.detail.skilledAgency')}</Label>
          </p>
          <span
            className="flex middle extra-gap"
            style={{ marginTop: readonly ? undefined : '1rem' }}
          >
            {detail?.skilledRecord?.agency?.name || EMPTY}
          </span>
        </div>
      </CardContent>
    </Card>
  );
};
