import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from 'react-redux';

import global from 'dash/src/redux/reducers/GlobalReducers';

import chatSlice from './slices/ChatSlice';
import employeeSlice from './slices/EmployeeSlice';
import eventsSlice from './slices/EventsSlice';
import memberInfoSlice from './slices/MemberInfoSlice';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  global,
  chatSlice,
  employeeSlice,
  eventsSlice,
  memberInfoSlice,
});

/**
 * WARNING: this is a workaround for storing our model class transforms in Redux. Since classes are non-serializable:
 * 1. DevTools will not properly show Redux state
 * 2. Certain Redux capabilities (e.g. persistence) won't work
 * 3. We need to be watchful for mutable state updates
 */
const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware,
});

export type ReduxStoreType = ReturnType<typeof rootReducer>;

export const useSelector: TypedUseSelectorHook<ReduxStoreType> = useReduxSelector;

export default store;
