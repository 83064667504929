import { Enum } from './Enum';

export class MemberContactTimeType extends Enum {
  translationKey = 'memberContactTimeType';

  static asArray: MemberContactTimeType[] = [];

  static byKey: { [name: string]: MemberContactTimeType } = {};

  static readonly ANY_TIME = new MemberContactTimeType('ANY_TIME');

  static readonly MORNING = new MemberContactTimeType('MORNING');

  static readonly AFTERNOON = new MemberContactTimeType('AFTERNOON');

  static readonly CUSTOM = new MemberContactTimeType('CUSTOM');

  constructor(public readonly value: string) {
    super();
    MemberContactTimeType.asArray.push(this);
    MemberContactTimeType.byKey[value] = this;
  }

  static toSelectable = () => Enum.toSelectable(MemberContactTimeType.asArray);
}

export default MemberContactTimeType;
