// eslint-disable-next-line import/no-extraneous-dependencies
import { JSONSchema6 } from 'json-schema';
import React, { Fragment, useEffect, useMemo, useState } from 'react';

import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';

import { Label } from '../Label';
import { RadioGroup, RadioItem } from '../RadioButton/RadioGroup';
import { Select } from '../Select';
import { TextInput } from '../TextInput';

export interface JSONSchema extends JSONSchema6 {
  enumNames?: string[];
}

type Data = { [key: string]: any };

type AvailableType =
  | 'RadioWidget'
  | 'SelectWidget'
  | 'MinMaxWidget'
  | 'NumericWidget';
type Type = { 'ui:widget': AvailableType };
type Types = {
  [key: string]: Type | string[];
};

type Props = {
  className?: string;
  defaultData?: Data;
  omitFields?: string[];
  onChange: (
    fields: { id: string; required: boolean; valid: boolean }[],
    data: Data,
  ) => void;
  readonly?: boolean;
  schema: JSONSchema6;
  submitted?: boolean;
  types: Types;
};

export const JSONSchemaFields = ({
  className,
  omitFields,
  onChange,
  schema,
  submitted,
  types,
}: Props) => {
  const questionTypes = useMemo(
    () =>
      (types['ui:order'] as string[])
        .filter((key) => !(omitFields || []).includes(key))
        .map((key) => {
          const type = (types[key] as Type)['ui:widget'];
          const currentSchema =
            schema?.properties && (schema?.properties[key] as JSONSchema);
          const required = !!schema?.required?.includes(key);
          const title = currentSchema?.title || '';
          const items =
            currentSchema?.enum?.map((value, index) => ({
              value: value as string,
              label: ((currentSchema?.enumNames &&
                currentSchema.enumNames[index]) ||
                value) as string,
            })) || [];

          return {
            id: key,
            items,
            required,
            title,
            type,
          };
        }),
    [types, schema],
  );

  const [data, setData] = useState<Data>({});
  const [questions, setQuestions] = useState(
    questionTypes.map(({ id, required }) => ({
      id,
      required,
      valid: !required,
    })),
  );

  useEffect(() => {
    setData({});
    setQuestions(
      questionTypes.map(({ id, required }) => ({
        id,
        required,
        valid: !required,
      })),
    );
  }, [schema, types]);

  const onChangeField = (field: string, update: any, valid?: boolean) => {
    const newData = {
      ...data,
      [field]: update,
    };
    setData(newData);
    const currentQuestion = questions.find(({ id }) => id === field);
    if (currentQuestion) {
      currentQuestion.valid =
        valid !== undefined ? valid : !!(update || !currentQuestion.required);
      setQuestions([...questions]);
    }
    onChange([...questions], newData);
  };

  const getMinMaxError = (
    values: (number | undefined)[],
    currentValue?: number,
  ) => {
    if (!currentValue) return translate('global.missingRequiredFieldMin');
    if (currentValue < 0) return translate('global.positiveNumberInvalid');
    if (values[0] && values[1] && values[0] < values[1])
      return translate('global.minMaxNumberInvalid');
    return undefined;
  };

  const isMinMaxValid = (values: (number | undefined)[]) =>
    !!(
      values[0] &&
      values[1] &&
      values[0] > 0 &&
      values[1] > 0 &&
      values[0] >= values[1]
    );

  return (
    <>
      {questionTypes.map(({ id, items, title, type, required }) => {
        if (type === 'RadioWidget') {
          const value = data[id];
          return (
            <RadioGroup
              className={className}
              error={submitted && !value}
              expand={items?.length > 2 ? 'vertical' : 'horizontal'}
              items={items as RadioItem[]}
              onChange={(selected: RadioItem) => onChangeField(id, selected)}
              required={required}
              title={title}
            />
          );
        }

        if (type === 'SelectWidget') {
          const value = data[id];
          return (
            <Select
              className={className}
              error={submitted && !value}
              items={items}
              label={title}
              onChange={(item?: Selectable) => onChangeField(id, item?.value)}
              required={required}
            />
          );
        }

        if (type === 'NumericWidget') {
          const value = data[id];
          return (
            <TextInput
              className={className}
              error={
                submitted &&
                ((!value && translate('global.missingRequiredFieldMin')) ||
                  ((Number.isNaN(Number(value)) || value <= 0) &&
                    translate('global.positiveNumberInvalid')))
              }
              label={title}
              maxLength={6}
              onChange={(newValue?: string) =>
                onChangeField(
                  id,
                  newValue,
                  !Number.isNaN(Number(value)) && value >= 0,
                )
              }
              required={required}
            />
          );
        }

        if (type === 'MinMaxWidget') {
          const values = (data[id]?.split('/') || [])
            .map((x: string) => x.trim())
            .map((x: string) =>
              Number.isNaN(Number(x)) ? undefined : Number(x),
            );
          return (
            <div
              className={`${className} grid-wrapper`}
              style={{ gridGap: '0 10px' }}
            >
              <Label
                error={submitted && getMinMaxError(values, values[0])}
                className="grid-span-12"
              >
                {title}
              </Label>
              <TextInput
                className="grid-span-6"
                error={submitted && getMinMaxError(values, values[0])}
                maxLength={6}
                onChange={(newValue?: string) =>
                  onChangeField(
                    id,
                    newValue ? `${Number(newValue)} / ${values[1]}` : undefined,
                    isMinMaxValid([Number(newValue), values[1]]),
                  )
                }
                placeholder={translate('components.common.systolic')}
                required={required}
              />
              <TextInput
                className="grid-span-6"
                error={submitted && getMinMaxError(values, values[1])}
                maxLength={6}
                onChange={(newValue?: string) =>
                  onChangeField(
                    id,
                    newValue ? `${values[0]} / ${Number(newValue)}` : undefined,
                    isMinMaxValid([values[0], Number(newValue)]),
                  )
                }
                placeholder={translate('components.common.diastolic')}
              />
            </div>
          );
        }

        return (
          <p className={className} key={id}>
            {title}: Component not implemented yet
          </p>
        );
      })}
    </>
  );
};

export default JSONSchemaFields;
