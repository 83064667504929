import React from 'react';
import { Select } from 'styleguide';
import { EMPTY } from '@vestahealthcare/common/utils/constants';
import { translate } from '@vestahealthcare/common/i18n';
import { WidgetProps } from '../types';

const SelectWidget = (props: WidgetProps) => {
  const {
    readonly,
    onChange,
    options: { enumOptions },
    rawErrors,
    required,
    value,
    schema: { type },
  }: WidgetProps = props;
  const isArray = type === 'array';

  if (!enumOptions) {
    throw new Error('No enum options defined for select');
  }

  if (readonly) {
    if (!props.value) {
      return <p>{EMPTY}</p>;
    }

    if (isArray) {
      const options =
        props.value &&
        props.value.map((item: string) => {
          const found = enumOptions.find((option) => option.value === item);
          return found ? found.label : item;
        });

      return options.length > 0
        ? options.map((option: string, index: number) => (
            <p key={index}>{option}</p>
          ))
        : EMPTY;
    }

    const option = enumOptions.find((option) => option.value === props.value);
    return <p>{option && option.label}</p>;
  }

  // TODO: we're supposing that when we have an error, it's submitted, not sure if that's always true...
  const showError =
    rawErrors && (!!rawErrors.length || (required && !enumOptions.length));

  // TODO: hacky filter to allow for optional enums. should fix when we generate forms via .ftl
  const options = enumOptions
    .map(({ label, value }) => ({ value, label }))
    .filter((val) => val.label !== 'null');

  return (
    <Select
      data-cy="form-builder-select"
      errorText={translate('formBuilder.required')}
      onChange={onChange}
      options={options}
      required={required}
      showError={showError}
      value={value}
      multiple={isArray}
    />
  );
};

export default SelectWidget;
