import favicon from 'dash/static/images/favicons/qa.png';

const getConfig = (qaEnv = '1') => ({
  favicon,
  apiv2: {
    url: `https://api.qa${qaEnv}.hometeamcare.com/v2`,
    credentials: {
      consumerKey: 2,
    },
  },
  auth: {
    redirectUrl: `https://dash.qa${qaEnv}.hometeamcare.com`,
    clientId: '60b4b6b2hrmcqdb9p4b0csbp0d',
    hostedDomainUrl:
      'https://nonprod-vesta-dash.auth.us-east-1.amazoncognito.com',
  },
  env: 'qa',
  staticUrl: 'https://s3.amazonaws.com/hometeam-static',
  isDemo: false,
  isDev: false,
  isProd: false,
  isQA: true,
  isStage: false,
  segment: {
    writeKey: 'AumyqOPYuayxR5GMX1L407gHOaRlWnAW',
  },
  appCenter: {
    apiToken: '85e072e6f4285e95def6ea7e6e2a0065e2c2f8a4',
  },
  launchDarkly: '5e73e18ccd123c077b2862f8',
  streamChat: 'c6qrdnvrcp6n',
  clearTriageToken: 'lKdYlQWk1sMCRWn5EyUc',
});

export default getConfig;
