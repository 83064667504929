import { Enum } from './Enum';

export class ReasonWithdraw extends Enum {
  translationKey = 'reasonWithdraw';

  static asArray: ReasonWithdraw[] = [];

  static byKey: { [name: string]: ReasonWithdraw } = {};

  static readonly DUPLICATIVE_SERVICES = new ReasonWithdraw(
    'DUPLICATIVE_SERVICES',
  );

  static readonly HIGH_CALL_VOLUME = new ReasonWithdraw('HIGH_CALL_VOLUME');

  static readonly CONTENT_WITH_SUPPORT = new ReasonWithdraw(
    'CONTENT_WITH_SUPPORT',
  );

  static readonly CHOOSE_TO_WIDRAW_DO_NOT_CALL = new ReasonWithdraw(
    'CHOOSE_TO_WIDRAW_DO_NOT_CALL',
  );

  constructor(public readonly value: string) {
    super();
    ReasonWithdraw.asArray.push(this);
    ReasonWithdraw.byKey[value] = this;
  }
}

export default ReasonWithdraw;
