import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { translate } from '@vestahealthcare/common/i18n';
import { AsYouTypeFormatter } from 'google-libphonenumber';

interface Props {
  'data-cy'?: string;
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  errorText?: string;
  id?: string;
  label?: string;
  onBlur?: (str: string) => void;
  onChange?: (str: string) => void;
  onFocus?: any;
  placeholder?: string;
  required?: boolean;
  showError?: boolean;
  value?: string;
}

const PhoneNumber = ({
  autoFocus,
  className,
  disabled,
  errorText,
  id,
  label,
  onBlur,
  onChange,
  onFocus,
  placeholder = translate('global.enterText'),
  required,
  showError,
  value,
  ...props
}: Props) => {
  const normalize = (str?: string) => str?.replace(/\D/g, '') ?? '';

  const formatter = new AsYouTypeFormatter('US');
  const formatAsPhoneNumber = (str?: string): string => {
    formatter.clear();
    return [...normalize(str)]
      .slice(0, 10)
      .reduce((_total, digit) => formatter.inputDigit(digit), '');
  };

  const [displayValue, setDisplayValue] = useState(
    (value && formatAsPhoneNumber(value)) || '',
  );
  const [isFocused, setIsFocused] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current &&
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
  }, [inputRef, cursorPosition]);

  const change = (e: React.ChangeEvent<HTMLInputElement>) => {
    let cursorPos = e.target.selectionStart;
    let fieldValue = e.target.value;

    if (
      cursorPos &&
      normalize(fieldValue).length > normalize(displayValue).length
    ) {
      const numDigitsBeforeCursor =
        normalize(fieldValue.slice(0, cursorPos)).length || 0;
      fieldValue = formatAsPhoneNumber(fieldValue);

      if (cursorPos < fieldValue.length) {
        // TODO: replace for "new RegExp(`(?<=(.*?\\d){${numDigitsBeforeCursor}})`)"
        // when regex lookbehind operator is supported in Safari https://caniuse.com/?search=lookbehind
        let i;
        const regex = new RegExp('\\d', 'g');
        for (i = 0; i < numDigitsBeforeCursor; i += 1) {
          cursorPos = (regex.exec(fieldValue)?.index || 0) + 1;
        }
      }
      setCursorPosition(cursorPos);
    }

    setDisplayValue(fieldValue);
    onChange && onChange(fieldValue);
  };

  const blur = () => {
    setIsFocused(false);
    setDisplayValue(formatAsPhoneNumber(displayValue));
    if (onBlur) onBlur(displayValue);
  };

  const focus = () => {
    setIsFocused(true);
    setDisplayValue(displayValue);
    if (onFocus) onFocus(displayValue);
  };

  const showValidationError = () => showError && !isFocused;

  const classes = classNames(className, 'form-group', 'phone-number', {
    'has-error': showValidationError(),
  });

  return (
    <div data-cy={props['data-cy']} className={classes} id={id || undefined}>
      {label && (
        <label className="control-label">
          {label}
          {required ? ' *' : ''}
        </label>
      )}
      <input
        ref={inputRef}
        autoFocus={autoFocus}
        className="form-control"
        disabled={disabled}
        onBlur={blur}
        onChange={change}
        onFocus={focus}
        placeholder={placeholder}
        type="text"
        value={displayValue}
      />
      {showValidationError() && errorText && (
        <span
          className="error-message help-block"
          data-cy={`${props['data-cy']}-error`}
        >
          {errorText}
        </span>
      )}
    </div>
  );
};

export default PhoneNumber;
