import { AbstractTranslatedEntity } from './AbstractTranslatedEntity';
import { CampaignBanner } from './CampaignBanner';
import { makeArray } from './Model';
import { Translation } from './Translation';

// Eventually we will handle more types, so we will want to move these to enums.
export const CALL_TO_NURSE = 'CALL_TO_NURSE';
export const SHOW_ON_LOGIN = 'ON_LOGIN';

export class CampaignModal extends AbstractTranslatedEntity {
  id = 0;

  name = '';

  image = '';

  eventTriggers: string[] = [];

  ctaButtons: string[] = [];

  banners: CampaignBanner[] = [];

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
    this.translations = obj.translations
      ? makeArray(obj.translations, Translation)
      : [];
    this.banners = obj.banners ? makeArray(obj.banners, CampaignBanner) : [];
  }

  get showCallNurse() {
    return this.ctaButtons.includes(CALL_TO_NURSE);
  }

  get showOnLogin() {
    return this.eventTriggers.includes(SHOW_ON_LOGIN);
  }
}

export default CampaignModal;
