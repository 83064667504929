import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  EventInterventionType,
} from '@vestahealthcare/common/models';

import {
  Button,
  CollapsableSidebar,
  Select,
  SwitchGroup,
  TextArea,
} from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import { UpdateInterventionTypeParams } from 'dash/src/services/EventServices';

interface Props {
  intervention?: EventInterventionType;
  onClose: () => void;
  onSubmit: (
    id: number,
    params: UpdateInterventionTypeParams,
  ) => Promise<boolean>;
  open: boolean;
}

export const EventInterventionEditModal = ({
  intervention,
  onClose,
  onSubmit,
  open,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [categories, setCategories] = useState<BaseEnum[]>([]);

  const [description, setDescription] = useState<string>();
  const [category, setCategory] = useState<BaseEnum>();
  const [active, setActive] = useState(false);

  const getInitialData = async () => {
    const categories = await CacheServices.getEventInterventionCategories();
    setCategories(categories);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    setDescription(intervention?.description || '');
    setCategory(intervention?.category);
    setActive(intervention ? intervention.active : true);
  }, [intervention]);

  const doSubmit = async () => {
    setSubmitted(true);

    if (!category || !description) return;

    setLoading(true);
    try {
      const result = await onSubmit(intervention?.id || 0, {
        active,
        description,
        categoryId: category,
      });
      if (result) {
        onClose();
      }
    } finally {
      setSubmitted(false);
      setLoading(false);
    }
  };

  const hasChanges = () =>
    name !== intervention?.description ||
    active !== intervention?.active ||
    category !== intervention?.category;

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={
        <h2>
          {intervention?.id
            ? translate('events.interventions.editModalTitle')
            : translate('events.interventions.addModalTitle')}
        </h2>
      }
      size={450}
    >
      <CollapsableSidebar.Body>
        <TextArea
          error={submitted && !description}
          label={translate('events.interventions.description')}
          onChange={setDescription}
          required
          value={description}
        />
        <Select
          disableClearable
          error={submitted && !category}
          getItemLabel={({ description }: BaseEnum) => description}
          items={categories}
          label={translate('events.interventions.category')}
          onChange={setCategory}
          required
          value={category}
        />
        <SwitchGroup
          items={[
            {
              label: translate('events.interventions.active'),
              checked: active,
            },
          ]}
          onChange={(items) => setActive(!!items[0].checked)}
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="edit-event-intervention-close"
          onClick={onClose}
        >
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="edit-event-intervention-submit"
          disabled={!hasChanges()}
          loading={loading}
          onClick={doSubmit}
        >
          {intervention?.id
            ? translate('global.save')
            : translate('global.add')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default EventInterventionEditModal;
