import { Moment } from 'moment';

import {
  BiometricsLocation,
  BiometricsType,
  CollectionMethod,
  MeasurementFrequency,
} from '@vestahealthcare/common/enums';
import {
  BiometricsConfig,
  BiometricsRPMAdherence,
  BiometricsRPMAdherenceCycle,
  BiometricsTime,
} from '@vestahealthcare/common/models';
import { Measurement } from '@vestahealthcare/common/models/Measurement';
import { LOCAL_TIME_FORMAT } from '@vestahealthcare/common/utils/constants';

import Api from 'dash/src/services/Api';

export interface BiometricsPostParam {
  values: Map<string, number>;
  readAt?: string;
  readingLocation?: BiometricsLocation;
}

export interface ConfigPostParam {
  biometricsTimes: BiometricsTime[];
  collectionMethod: CollectionMethod;
  frequency: MeasurementFrequency;
  lowerLimit?: number;
  note?: string;
  upperLimit?: number;
  type: BiometricsType;
}

export const addMeasurement = async (
  patientId: string | number,
  { readAt, values, readingLocation }: BiometricsPostParam,
) => {
  const parsedValues: any = {};
  values.forEach((value, key) => (parsedValues[key] = value));
  await Api.postv2JSON(`/patients/${patientId}/biometrics`, {
    patientId,
    readAt,
    values: parsedValues,
    readingLocation: readingLocation?.value,
  });
};

type BiometricsUpdateMeasurementParams = {
  valid: boolean;
};

export const updateMeasurement = async (
  patientId: number,
  id: number,
  params: BiometricsUpdateMeasurementParams,
) => await Api.patchv2JSON(`/patients/${patientId}/biometrics/${id}`, params);

export const fetchEnabledConfigs = async (
  patientId: string | number,
): Promise<BiometricsConfig[]> => {
  const { biometricsConfigs } = await Api.getv2(
    `/patients/${patientId}/biometrics/configs`,
  );
  return biometricsConfigs.items.map(
    (item: BiometricsConfig) => new BiometricsConfig(item),
  );
};

const getFrequencyValue = (f?: MeasurementFrequency) => {
  if (!f) {
    return undefined;
  }
  if (
    [
      MeasurementFrequency.ONCE_PER_DAY,
      MeasurementFrequency.TWICE_PER_DAY,
      MeasurementFrequency.THREE_PER_DAY,
    ].indexOf(f) !== -1
  ) {
    return MeasurementFrequency.DAYS.value;
  }
  return f.value;
};

const transformConfigParams = (params: ConfigPostParam) => ({
  biometricsTimes: transformBiometricsTimes(params),
  collectionMethod: params.collectionMethod.valueOf(),
  frequency: isHealthReport(params.collectionMethod)
    ? getFrequencyValue(params?.frequency)
    : undefined,
  lowerLimit: params.lowerLimit,
  note: params.note || '',
  upperLimit: params.upperLimit,
  type: params.type.valueOf(),
});

const isHealthReport = (collectionMethod: CollectionMethod) =>
  collectionMethod === CollectionMethod.HEALTH_REPORT;

const transformBiometricsTimes = ({
  biometricsTimes,
  collectionMethod,
}: ConfigPostParam) => {
  if (!biometricsTimes.length || !isHealthReport(collectionMethod)) {
    return [];
  }
  return biometricsTimes.map((time) => ({
    measurementDay: time?.measurementDay?.valueOf(),
    measurementTime: time?.measurementTime
      ?.toMoment()
      ?.format(LOCAL_TIME_FORMAT),
    option: time.option?.valueOf(),
  }));
};

export const createOrUpdateBiometricsConfig = async (
  patientId: string | number,
  params: ConfigPostParam,
): Promise<BiometricsConfig> => {
  const transformedParams = transformConfigParams(params);
  const { biometricsConfig } = await Api.postv2JSON(
    `/patients/${patientId}/biometrics/configs`,
    transformedParams,
  );
  return new BiometricsConfig(biometricsConfig);
};

export const createOrUpdateBpConfig = async (
  patientId: string | number,
  params: ConfigPostParam[],
): Promise<BiometricsConfig[]> => {
  const transformedParams = params.map(transformConfigParams);
  const { biometricsConfigs } = await Api.postv2JSON(
    `/patients/${patientId}/biometrics/configs-bp`,
    transformedParams,
  );
  return biometricsConfigs.items.map(
    (data: BiometricsConfig) => new BiometricsConfig(data),
  );
};

interface MeasurementParam {
  type?: BiometricsType;
  from?: Moment;
  to?: Moment;
}

export const getMeasurements = async (
  patientId: string | number,
  params: MeasurementParam,
): Promise<Measurement[]> => {
  const transformedParams = {
    type: params?.type?.valueOf(),
    from: params?.from?.toISOString(),
    to: params?.to?.toISOString(),
  };
  const { measurements } = await Api.getv2(
    `/patients/${patientId}/biometrics`,
    transformedParams,
  );
  return measurements.items.map((data: any) => new Measurement(data));
};

export const fetchRPMAdhrerence = async (
  memberId: string | number,
): Promise<BiometricsRPMAdherence> => {
  const { rpmAdherence } = await Api.getv2(
    `/patients/${memberId}/biometrics/rpm-adherence`,
  );

  return new BiometricsRPMAdherence(rpmAdherence);
};

export const fetchRPMAdherenceHistory = async (
  memberId: string | number,
): Promise<BiometricsRPMAdherenceCycle[]> => {
  const {
    rpmAdherenceHistory: { items },
  } = await Api.getv2(`/patients/${memberId}/biometrics/rpm-adherence/history`);

  return items.map((x: any) => new BiometricsRPMAdherenceCycle(x));
};
