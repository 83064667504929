import { Moment } from 'moment';

import { make, makeMoment } from '../Model';
import { TOCStatus } from '../TOCStatus';
import { MemberWarning } from './MemberWarning';

export class MemberWarningTOC extends MemberWarning {
  admitDate?: Moment;

  dischargeDate?: Moment;

  status: TOCStatus;

  constructor(obj: any) {
    super(obj);
    this.admitDate = makeMoment(obj.admitDate);
    this.dischargeDate = makeMoment(obj.dischargeDate);
    this.status = make(obj.status, TOCStatus);
  }
}

export default MemberWarningTOC;
