import React, { useEffect, useState } from 'react';
import { Checkbox } from 'styleguide';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import classnames from 'classnames';
import { translate } from '@vestahealthcare/common/i18n';
import { GridColumns } from '../../utils/types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  title: {
    display: 'flex',
    '& a': {
      flex: '1',
    },
    '& span': {
      fontWeight: 700,
      marginRight: '1rem',
    },
  },
});
interface Props {
  autoFocus?: boolean;
  className?: string;
  columns?: GridColumns;
  countLabel?: boolean | ((selected: number) => string);
  checkBoxColumns?: GridColumns;
  'data-cy'?: string;
  disabled?: boolean;
  errorText?: string;
  id?: string;
  label?: string;
  onChange: Function; // TODO: give this a proper type
  options: Selectable[];
  required?: boolean;
  selectAll?: boolean;
  selectAllLabel?: string | ((someAreSelected: boolean) => string);
  showError?: boolean;
  values?: string[] | number[];
}

const Checkboxes = ({
  autoFocus,
  className,
  checkBoxColumns,
  columns,
  countLabel,
  'data-cy': dataCy,
  disabled,
  errorText,
  label,
  onChange,
  options,
  required,
  selectAll,
  selectAllLabel,
  showError,
  values = [],
  ...props
}: Props) => {
  const classes = classnames(
    className,
    'checkboxes',
    columns ? `grid-span-${columns}` : undefined,
    {
      disabled,
      'has-error': showError,
    },
  );

  const styles = useStyles();
  const [currentValues, setValues] = useState(values);
  const [selected, setSelected] = useState(new Set(values.map(String)));

  useEffect(() => {
    if (values.toString() !== currentValues.toString()) {
      setValues(values);
      setSelected(new Set(values.map(String)));
    }
  }, [values]);

  const getCountLabel = () => {
    if (typeof countLabel === 'function') {
      return countLabel(selected.size);
    }
    if (selected) {
      return `${selected.size} ${translate('global.selected')}`;
    }
  };

  const getSelectAllLabel = (someAreSelected: boolean) => {
    if (selectAllLabel) {
      if (typeof selectAllLabel === 'function') {
        return selectAllLabel(someAreSelected);
      }
      return selectAllLabel;
    }
    if (someAreSelected) {
      return translate('global.deselectAll');
    }
    return translate(`global.${someAreSelected ? 'deselectAll' : 'selectAll'}`);
  };

  return (
    <div className={classes} data-cy={dataCy} {...props}>
      {label && (
        <label>
          {label}
          {required && ' *'}
        </label>
      )}
      {showError && errorText && (
        <p className="flex no-margin error">{errorText}</p>
      )}
      <div className={styles.title}>
        {selectAll && (
          <a
            onClick={() => {
              if (selected.size) {
                options.forEach((option) =>
                  selected.delete(option.value.toString()),
                );
              } else {
                options.forEach((option) =>
                  selected.add(option.value.toString()),
                );
              }
              setSelected(selected);
              onChange([...selected]);
            }}
          >
            {getSelectAllLabel(!!selected.size)}
          </a>
        )}
        {countLabel && <span>{getCountLabel()}</span>}
      </div>
      <div className={checkBoxColumns ? 'grid-wrapper' : ''} style={{ gap: 0 }}>
        {options.map((option) => (
          <Checkbox
            autoFocus={autoFocus && options[0] === option}
            className={checkBoxColumns ? `grid-span-${checkBoxColumns}` : ''}
            checked={selected.has(option.value.toString())}
            data-cy={`${dataCy}-${option.value}`}
            disabled={disabled}
            key={option.value}
            label={option.label}
            onChange={(checked) => {
              checked
                ? selected.add(option.value.toString())
                : selected.delete(option.value.toString());
              setSelected(selected);
              onChange([...selected]);
            }}
            showError={showError}
          />
        ))}
      </div>
    </div>
  );
};

export default Checkboxes;
