import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/styles';

import {
  Frequency,
  MemberContactMethodType,
  MemberContactTimeType,
  PhoneType,
} from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  CareTeamPersonExtended,
  Employee,
  MemberContactInfoTimes,
  UserPhone,
} from '@vestahealthcare/common/models';

import {
  Button,
  CollapsableSidebar,
  Select,
  SelectPhoneOption,
  TextInput,
  Tooltip,
} from 'styleguide-v2';

import {
  DatesTimeItem,
  EditDateTimes,
} from 'dash/src/components/EditDateTimes';
import { CacheServices } from 'dash/src/services';
import { UpdateCareTeamPersonParams } from 'dash/src/services/CareTeamServices';

const phoneUtil = PhoneNumberUtil.getInstance();

interface Props {
  open?: boolean;
  onClose: () => void;
  onSubmit: (
    id: number,
    params: UpdateCareTeamPersonParams,
  ) => Promise<boolean>;
  person?: CareTeamPersonExtended;
}

const useStyles = makeStyles({
  disabledOption: {
    opacity: 0.38,
    width: '100%',
  },
});

export const EditEngagementModal = ({
  open,
  onClose,
  onSubmit,
  person,
}: Props) => {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [engagementOwners, setEngagementOwners] = useState<Employee[]>([]);
  const [engagementPreference, setEngagementPreference] = useState<
    MemberContactMethodType[]
  >([]);
  const [engagementPreferenceOther, setEngagementPreferenceOther] = useState<
    string
  >();
  const [engagementFrequency, setEngagementFrequency] = useState<Frequency>();
  const [engagementOwner, setEngagementOwner] = useState<Employee>();
  const [contactDateTimes, setContactDateTimes] = useState<DatesTimeItem[]>([]);
  const [authenticationPhone, setAuthenticationPhone] = useState<UserPhone>();

  const getInitialData = async () => {
    const owners = await CacheServices.getEngagementOwners();
    setEngagementOwners(owners);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    setEngagementPreference(
      person?.contactInfo?.contactMethods?.map(
        ({ contactMethod }) => contactMethod,
      ) || [],
    );
    setEngagementPreferenceOther(
      person?.contactInfo?.contactMethods?.find(
        ({ contactMethod }) => contactMethod === MemberContactMethodType.OTHER,
      )?.contactMethodOther,
    );
    setEngagementFrequency(person?.contactInfo?.contactFrequency);
    setEngagementOwner(person?.engagementOwner);
    setContactDateTimes(person?.getDaysOfService() || []);
    setAuthenticationPhone(
      person?.contactInfo?.contactMethods?.find(
        ({ contactMethod }) =>
          contactMethod === MemberContactMethodType.MOBILE_APP_CHAT,
      )
        ? ({ phone: person?.appUser?.authenticationPhone } as UserPhone)
        : undefined,
    );
  }, [person, open]);

  const warningNoPhone = !person?.phones.find(
    ({ id, phone }) => id && phone.number && phone.type === PhoneType.CELL,
  );

  useEffect(() => {
    if (
      warningNoPhone &&
      engagementPreference.find(
        (value) => value === MemberContactMethodType.MOBILE_APP_CHAT,
      )
    ) {
      setEngagementPreference(
        engagementPreference.filter(
          (value) => value !== MemberContactMethodType.MOBILE_APP_CHAT,
        ),
      );
    }
  }, [engagementPreference]);

  const validate = () =>
    (!engagementPreference?.find(
      (item) => item === MemberContactMethodType.OTHER,
    ) ||
      engagementPreferenceOther) &&
    (!engagementPreference?.find(
      (item) => item === MemberContactMethodType.MOBILE_APP_CHAT,
    ) ||
      authenticationPhone);

  const doSubmit = async () => {
    setSubmitted(true);
    if (person && validate()) {
      setLoading(true);
      try {
        const contactTimes: MemberContactInfoTimes[] = [];
        contactDateTimes.forEach(({ dates, type, startTime, endTime }) => {
          if (!dates?.length) {
            if (type) {
              contactTimes.push({
                type: type || MemberContactTimeType.ANY_TIME,
                startTime:
                  type === MemberContactTimeType.CUSTOM && startTime
                    ? moment(startTime.getTime())
                    : undefined,
                endTime:
                  type === MemberContactTimeType.CUSTOM && endTime
                    ? moment(endTime.getTime())
                    : undefined,
              } as MemberContactInfoTimes);
            }
          } else {
            dates.forEach((day) => {
              contactTimes.push({
                dayOfWeek: day,
                type: type || MemberContactTimeType.ANY_TIME,
                startTime:
                  type === MemberContactTimeType.CUSTOM && startTime
                    ? moment(startTime.getTime())
                    : undefined,
                endTime:
                  type === MemberContactTimeType.CUSTOM && endTime
                    ? moment(endTime.getTime())
                    : undefined,
              } as MemberContactInfoTimes);
            });
          }
        });

        await onSubmit(person.id, {
          engagementOwnerId: engagementOwner?.id || null,
          contactInfo: {
            contactFrequency: engagementFrequency,
            contactTimes,
            contactMethods: engagementPreference?.map((contactMethod) => ({
              contactMethod,
              contactMethodOther:
                contactMethod === MemberContactMethodType.OTHER
                  ? engagementPreferenceOther
                  : undefined,
            })),
          },
          authenticationPhone: engagementPreference?.find(
            (item) => item === MemberContactMethodType.MOBILE_APP_CHAT,
          )
            ? authenticationPhone?.phone?.number
            : null,
        });
      } finally {
        setSubmitted(false);
        setLoading(false);
      }
    }
  };

  const hasOtherMethod = !!engagementPreference?.find(
    (item) => item === MemberContactMethodType.OTHER,
  );

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={!!open}
      title={
        <h2>
          {translate('careTeam.modals.editContactModalTitle', {
            name: person?.fullName,
          })}
        </h2>
      }
      size={550}
    >
      <CollapsableSidebar.Body>
        <div className="grid-wrapper fit">
          <Select
            className={`grid-span-${hasOtherMethod ? 6 : 12}`}
            data-cy="care-team-step-engagement-preference"
            renderOption={({ value, label }: Selectable) => {
              const text = label;
              if (
                warningNoPhone &&
                [
                  MemberContactMethodType.MOBILE_APP_CHAT.value,
                  MemberContactMethodType.TEXT.value,
                ].indexOf(value as string) !== -1
              ) {
                return (
                  <Tooltip
                    text={translate('careTeam.common.warningNoPhone')}
                    enterDelay={50}
                    followCursor
                  >
                    <span
                      className={styles.disabledOption}
                      onClick={(evt) => evt.stopPropagation()}
                    >
                      {' '}
                      {text}
                    </span>
                  </Tooltip>
                );
              }
              return text;
            }}
            items={MemberContactMethodType.toSelectable()}
            label={translate('careTeam.common.engagementPreference')}
            multiple
            onChange={(items?: Selectable[]) => {
              const engagementPreferences = items?.map(
                (item) => MemberContactMethodType.byKey[item.value],
              );
              setEngagementPreference(engagementPreferences || []);
            }}
            value={
              engagementPreference
                ? Enum.toSelectable(engagementPreference || [])
                : undefined
            }
          />

          {hasOtherMethod && (
            <TextInput
              className="grid-span-6"
              data-cy="care-team-step-engagement-preference-other"
              error={submitted && !engagementPreferenceOther}
              label={translate('careTeam.common.preferenceOther')}
              onChange={(engagementPreferenceOther) => {
                setEngagementPreferenceOther(engagementPreferenceOther);
              }}
              value={engagementPreferenceOther}
              required
            />
          )}

          {engagementPreference?.find(
            (item) => item === MemberContactMethodType.MOBILE_APP_CHAT,
          ) && (
            <Select
              className="grid-span-12"
              error={submitted && !authenticationPhone}
              getItemLabel={({ phone: { number, type } }: UserPhone) =>
                `${phoneUtil.format(
                  phoneUtil.parse(number, 'US'),
                  PhoneNumberFormat.NATIONAL,
                )} - ${type?.toString()}`
              }
              renderOption={(phone: UserPhone) => (
                <SelectPhoneOption userPhone={phone} />
              )}
              items={
                person?.phones?.filter(
                  ({ phone: { type } }) => type === PhoneType.CELL,
                ) || []
              }
              label={translate('careTeam.common.engagementAppPhone')}
              onChange={setAuthenticationPhone}
              value={person?.phones?.find(
                ({ phone }) =>
                  phone.number === authenticationPhone?.phone?.number,
              )}
              required
            />
          )}

          <Select
            className="grid-span-12"
            data-cy="care-team-step-engagement-frequency"
            items={Enum.toSelectable(Frequency.asArray)}
            label={translate('careTeam.common.frequency')}
            onChange={(newValue?: Selectable) => {
              const frequency = newValue
                ? Frequency.byKey[newValue?.value as string]
                : undefined;
              setEngagementFrequency(frequency);
            }}
            value={Enum.toSelectable(Frequency.asArray).find(
              ({ value }) => value === engagementFrequency?.value,
            )}
          />

          <Select
            className="grid-span-12"
            data-cy="care-team-step-engagement-owner"
            items={engagementOwners}
            getItemLabel={({ fullName }) => fullName}
            label={translate('careTeam.common.engagementOwner')}
            onChange={(newValue?: Employee) => {
              setEngagementOwner(newValue);
            }}
            value={engagementOwner}
          />

          <EditDateTimes
            className="grid-span-12"
            onChange={(newValue?: DatesTimeItem[]) => {
              setContactDateTimes(newValue || []);
            }}
            showType
            size="s"
            submitted={submitted}
            title={translate('careTeam.common.preferredDateTimes')}
            defaultValue={contactDateTimes}
          />
        </div>
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="care-team-detail-edit-contact-close"
          onClick={onClose}
        >
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="care-team-detail-edit-contact-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default EditEngagementModal;
