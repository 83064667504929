import React from 'react';
import moment from 'moment';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';

import { Colors, IconButton, Table } from 'styleguide-v2';
import { translate } from '@vestahealthcare/common/i18n';
import { PatientMedicalEquipment } from '@vestahealthcare/common/models';
import { DATE_FORMAT_SHORT } from '@vestahealthcare/common/utils/constants';

interface Props {
  dmes: PatientMedicalEquipment[];
  onDelete: (problem: PatientMedicalEquipment) => void;
}

const DME_COLUMNS = ({ onDelete }: Props) => [
  {
    headerName: translate('memberProfile.clinicalSummary.tables.dme.dme'),
    component: ({ description }: PatientMedicalEquipment) => description,
  },
  {
    headerName: translate('memberProfile.clinicalSummary.tables.dme.status'),
    component: ({ discontinueDate }: PatientMedicalEquipment) => (
      <div className="flex gap">
        {discontinueDate ? (
          <CancelIcon color="error" />
        ) : (
          <CheckCircleIcon color="primary" />
        )}
        <span>
          {translate(
            `memberProfile.clinicalSummary.tables.dme.${
              discontinueDate ? 'discontinueDate' : 'active'
            }`,
          )}
        </span>
        <span>
          {discontinueDate && moment(discontinueDate).format(DATE_FORMAT_SHORT)}
        </span>
      </div>
    ),
    width: 275,
  },
  {
    headerName: '',
    component: (dme: PatientMedicalEquipment) =>
      dme.discontinueDate ? (
        ''
      ) : (
        <IconButton size="small" onClick={() => onDelete(dme)}>
          <DeleteIcon />
        </IconButton>
      ),
    width: 50,
  },
];

const useStyles = makeStyles({
  discontinued: {
    '&&': {
      backgroundColor: `${Colors.lighterGray}!important`,
      borderBottom: `1px solid ${Colors.lighterGray}!important`,
    },
  },
});

export const DMETable = (props: Props) => {
  const styles = useStyles();
  return (
    <Table
      config={{
        columns: DME_COLUMNS(props),
        compact: true,
        data: props.dmes,
        getRowClass: (row: PatientMedicalEquipment) =>
          row.discontinueDate ? styles.discontinued : '',
        paginationOptions: [-1],
      }}
    />
  );
};

export default DMETable;
