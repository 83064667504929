import moment, { Moment } from 'moment';

import { Employee } from './Employee';
import { EventType } from './EventType';
import { IssueTypeRule } from './IssueTypeRule';
import { make, makeArray } from './Model';

export class IssueType {
  id = '';

  description = '';

  eventType: EventType;

  urgent = false;

  urgencyLevel = 0;

  urgencyRules: IssueTypeRule[];

  updatedAt: Moment;

  updatedBy: Employee;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.eventType = make(obj.eventType, EventType);
    this.updatedAt = moment.unix(obj.updatedAt);
    this.updatedBy = make(obj.updatedBy, Employee);
    this.urgencyRules = makeArray(obj.urgencyRules, IssueTypeRule);
  }

  static get BLOOD_PRESSURE() {
    return 'BLOOD_PRESSURE';
  }

  static get WEIGHT() {
    return 'WEIGHT_CHANGE';
  }

  static FALL = 'FALL';

  static ER_VISIT = 'ER';

  static HOSPITALIZATION = 'HOSPITALIZATION';

  static SYMPTOMS_URGENT = 'OTHER_URGENT';

  static SNF = 'SNF';

  static SURGERY = 'SURGERY';

  static SKILLED = 'SKILLED';

  static SYMPTOMS_NON_URGENT = 'OTHER';

  static sort({ id: a }: IssueType, { id: b }: IssueType) {
    if (a === IssueType.SYMPTOMS_URGENT) return -1;
    if (b === IssueType.SYMPTOMS_URGENT) return 1;
    if (a === IssueType.SYMPTOMS_NON_URGENT) return -1;
    if (b === IssueType.SYMPTOMS_NON_URGENT) return 1;

    return 0;
  }

  get isBiometricType() {
    return (
      [
        'BLOOD_OXYGEN',
        'BLOOD_SUGAR',
        'OXYGEN',
        'HEART_RATE',
        'TEMPERATURE',
        IssueType.BLOOD_PRESSURE,
        IssueType.WEIGHT,
      ].indexOf(this.id) !== -1
    );
  }
}

export default IssueType;
