import React, { useState } from 'react';

import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';

import { Button } from 'styleguide';
import { Colors, Fonts } from 'styleguide-v2';

import { createIncident } from '../../../services/IncidentServices';
import CreateIncidentModal from '../../Incidents/CreateIncidentModal';

interface Props {
  member: Patient;
}

const useStyles = makeStyles<Theme>((theme) => ({
  iconContainer: {
    '&&': {
      padding: 0,
    },
  },
  icon: {
    background: Colors.blue,
    height: '4rem',
    borderRadius: '2rem',
    padding: '0.875rem',

    '& svg': {
      fontSize: `calc(${Fonts.fontSize} * 1.375)`,
    },

    [theme.breakpoints.down(1450)]: {
      height: '3rem',
      padding: '.6rem',
      '& svg': {
        fontSize: `calc(${Fonts.fontSize} * 1.125)`,
      },
    },
  },
  text: {
    marginLeft: '1rem',
    [theme.breakpoints.down(1450)]: {
      fontSize: '0.875em',
    },
  },
}));

export const IncidentButton = ({ member }: Props) => {
  const styles = useStyles();
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <Button
        btnSize="md"
        className={styles.iconContainer}
        color="tertiary"
        data-cy="member-incident-button"
        onClick={() => setOpenModal(true)}
      >
        <>
          <span className={styles.icon}>
            <CrisisAlertIcon htmlColor={Colors.white} />
          </span>
          <span className={styles.text}>
            {translate('memberProfile.header.actions.reportIncident')}
          </span>
        </>
      </Button>
      <CreateIncidentModal
        member={member}
        open={openModal}
        onClose={() => setOpenModal(false)}
        onCreate={async (params) => await createIncident(params)}
      />
    </>
  );
};

export default IncidentButton;
