import { Moment } from 'moment';
import moment from '../moment';

export class LocalTime {
  hour = 0;

  minute = 0;

  second = 0;

  time = `${this.hour}:${this.minute}:${this.second}`;

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  toMoment() {
    // not a fan of this but not sure how to use frozen-moment type
    return Object(moment(this.time, 'HH:mm:ss')).freeze();
  }

  get anteOrPostMeridiem() {
    return this.hour < 12 ? 'AM' : 'PM';
  }

  toDate() {
    return this.toMoment().toDate();
  }

  diff(other: LocalTime) {
    return moment.duration(
      (this.hour - other.hour) * 60 * 60 +
        (this.minute - other.minute) * 60 +
        (this.second - other.second),
      'seconds',
    );
  }

  isBefore(other: LocalTime) {
    return (
      this.hour < other.hour ||
      (this.hour === other.hour && this.minute < other.minute) ||
      (this.hour === other.hour &&
        this.minute === other.minute &&
        this.second < other.second)
    );
  }

  isSame(other: LocalTime) {
    return (
      this.hour === other.hour &&
      this.minute === other.minute &&
      this.second === other.second
    );
  }

  isAfter(other: LocalTime) {
    return !this.isBefore(other) && !this.isSame(other);
  }

  static fromMoment(inMoment: Moment) {
    return new LocalTime({
      hour: inMoment.hour(),
      minute: inMoment.minute(),
      second: inMoment.second(),
    });
  }
}
