import {
  MemberEligibilityReason,
  MemberEligibilityStatus,
  MemberEligibilityError,
} from '../enums';

export class MemberEligibility {
  status: MemberEligibilityStatus;

  reason?: MemberEligibilityReason;

  eligibilityError?: MemberEligibilityError;

  determinedAt?: string;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.reason = MemberEligibilityReason.byKey[obj.reason];
    this.status = MemberEligibilityStatus.byKey[obj.status];
    this.eligibilityError = MemberEligibilityError.byKey[obj.eligibilityError];
  }
}

export default MemberEligibility;
