import { Employee } from '.';
import { make } from './Model';

export class Track {
  id = 0;

  name = '';

  active = false;

  status?: string;

  createdBy?: Employee;

  updatedBy?: Employee;

  createdAt = 0;

  updatedAt = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdBy = make(obj.createdBy, Employee);
    this.updatedBy = make(obj.updatedBy, Employee);
  }
}

export default Track;
