import * as React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { Colors } from 'styleguide-v2/src/styles/Colors';

const Icon = styled('span')(() => ({
  borderRadius: 3,
  width: 18,
  height: 18,
  border: `1px solid ${Colors.green}`,
  backgroundColor: Colors.white,
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: `2px auto ${Colors.green}`,
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    border: `1px solid ${Colors.textGreen}`,
  },
  'input:disabled ~ &': {
    border: `1px solid ${Colors.lightMint}`,
  },
  outline: 0,
}));

const CheckedIcon = styled(Icon)({
  backgroundColor: Colors.green,
  border: `1px solid ${Colors.green}`,
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: Colors.textGreen,
    border: `1px solid ${Colors.textGreen}`,
  },
  'input:disabled ~ &': {
    backgroundColor: Colors.lightMint,
    border: `1px solid ${Colors.lightMint}`,
  },
});

export function CustomCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<CheckedIcon />}
      icon={<Icon />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
