export class ClearTriageModel {
  narrativeText = '';

  callDisposition = {
    title: '',
  };

  protocols = [
    {
      id: 0,
      title: '',
      content_set: '',
      disposition: {
        selectedDisposition: {
          title: '',
        },
        overrideDisposition: {
          title: '',
          reason: '',
          details: '',
        },
      },
      positiveQuestions: [] as string[],
      negativeQuestions: [] as string[],
      adviceSections: [
        {
          title: '',
          adviceTopics: [
            {
              title: '',
              adviceDetails: [] as string[],
            },
          ],
        },
      ],
      otcRecommendations: [] as string[],
      handoutsSent: [
        {
          title: '',
          method: '',
          address: '',
        },
      ],
    },
  ];

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default ClearTriageModel;
