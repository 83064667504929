import { Enum } from './Enum';

export class MemberContactMethodType extends Enum {
  translationKey = 'memberContactMethodType';

  static asArray: MemberContactMethodType[] = [];

  static byKey: { [name: string]: MemberContactMethodType } = {};

  static readonly PHONE_CALL = new MemberContactMethodType('PHONE_CALL');

  static readonly TEXT = new MemberContactMethodType('TEXT');

  static readonly MOBILE_APP_CHAT = new MemberContactMethodType(
    'MOBILE_APP_CHAT',
  );

  static readonly EMAIL = new MemberContactMethodType('EMAIL');

  static readonly OTHER = new MemberContactMethodType('OTHER');

  constructor(public readonly value: string) {
    super();
    MemberContactMethodType.asArray.push(this);
    MemberContactMethodType.byKey[value] = this;
  }

  static toSelectable = () =>
    Enum.toSelectable(MemberContactMethodType.asArray);
}

export default MemberContactMethodType;
