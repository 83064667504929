import React from 'react';
import Session from 'dash/src/services/SessionServices';
import { Panel } from 'styleguide';

export const AdminHome = () => (
  <Panel>
    <Panel.Heading>
      <h2>Welcome to Dash for Vesta, {Session.actingUser.firstName}</h2>
    </Panel.Heading>
  </Panel>
);
