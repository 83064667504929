import CheckboxesWidget from './CheckboxesWidget';
import CheckboxWidget from './CheckboxWidget';
import ClinicalFigureWidget from './ClinicalFigureWidget';
import MinMaxWidget from './MinMaxWidget';
import NumericWidget from './NumericWidget';
import RadioTableWidget from './RadioTableWidget';
import RadioWidget from './RadioWidget';
import SelectWidget from './SelectWidget';
import TextareaWidget from './TextareaWidget';
import TextWidget from './TextWidget';
import TimePickerWidget from './TimePickerWidget';
import ListPickerWithPreferredWidget from './ListPickerWithPreferredWidget';
import PhoneNumberWidget from './PhoneNumberWidget';
import SliderWidget from './SliderWidget';
import SlideToggleWidget from './SlideToggleWidget';

export default {
  CheckboxesWidget,
  CheckboxWidget,
  ClinicalFigureWidget,
  ListPickerWithPreferredWidget,
  MinMaxWidget,
  NumericWidget,
  PhoneNumberWidget,
  RadioTableWidget,
  RadioWidget,
  SelectWidget,
  SliderWidget,
  SlideToggleWidget,
  TextareaWidget,
  TextWidget,
  TimePickerWidget,
};
