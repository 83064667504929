import moment, { Moment } from 'moment';
import { make, makeArray } from './Model';
import { Employee } from './Employee';
import { EventTriageQuestion } from './EventTriageQuestion';
import { EventTriageAdvice } from './EventTriageAdvice';
import { EventTriageHandout } from './EventTriageHandout';

export class EventTriage {
  id = 0;

  evaluationId = 0;

  narrative = '';

  disposition = '';

  questions: EventTriageQuestion[];

  adviceDetails: EventTriageAdvice[];

  handouts: EventTriageHandout[];

  createdBy: Employee;

  updatedBy: Employee;

  createdAt: Moment;

  updatedAt?: Moment;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.questions = makeArray(obj.questions, EventTriageQuestion);
    this.adviceDetails = makeArray(obj.adviceDetails, EventTriageAdvice);
    this.handouts = makeArray(obj.handouts, EventTriageHandout);
    this.createdBy = make(obj.createdBy, Employee);
    this.updatedBy = make(obj.updatedBy, Employee);
    this.createdAt = moment.unix(obj.createdAt);
    this.updatedAt = obj.updatedAt ? moment.unix(obj.updatedAt) : undefined;
  }
}

export default EventTriage;
