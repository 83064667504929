import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Checkboxes,
  Panel,
  Radio,
  Radios,
  ImageSelect,
} from 'styleguide';

const StyleguideToggles = () => {
  const [disabled, setDisabled] = useState(false);
  const [showError, setError] = useState(false);
  const [radio, setRadio] = useState(false);
  const [radios, setRadios] = useState<string>();
  const [isLabeledIconChecked, setLabeledIconChecked] = useState(false);
  const [isIconChecked, setIconChecked] = useState(false);
  const [isToggleOn, setToggle] = useState(false);
  const [isChecked, setCheckbox] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [image, setImage] = useState<string>();
  const options = [
    {
      label: 'Option 1',
      value: '1',
    },
    {
      label: 'Option 2',
      value: '2',
    },
    {
      label: 'Option 3',
      value: '3',
    },
    {
      label: 'Option 4',
      value: '4',
    },
    {
      label: 'Option 5',
      value: '5',
    },
  ];

  const IMAGE_OPTIONS = [
    {
      value: '1',
      label: '/static/Info@2x.png',
    },
    {
      value: '2',
      label: '/static/News@2x.png',
    },
    {
      value: '3',
      label: '/static/Meds@2x.png',
    },
    {
      value: '4',
      label: '/static/Bandaid@2x.png',
    },
    {
      value: '5',
      label: '/static/Hospital@2x.png',
    },
  ];

  return (
    <Panel>
      <Panel.Heading>
        <h2>Toggles</h2>
        <div className="right-side">
          <Button color="secondary" onClick={() => setError(!showError)}>
            {showError ? 'Hide' : 'Show'} Errors
          </Button>
          <Button color="secondary" onClick={() => setDisabled(!disabled)}>
            {disabled ? 'Enable' : 'Disable'}
          </Button>
        </div>
      </Panel.Heading>
      <Panel.Body className="grid-wrapper">
        <div className="h7 grid-span-12">Radios</div>
        <div className="grid-span-12">
          <Radios
            autoFocus
            disabled={disabled}
            errorText="Error"
            label="Radios Label"
            showError={showError}
            onChange={setRadios}
            options={options}
            value={radios}
          />
        </div>
        <div className="grid-span-12">
          <Radio
            checked={radio}
            disabled={disabled}
            label="Radio (single)"
            showError={showError}
            onChange={setRadio}
          />
        </div>

        <div className="h7 grid-span-12">Checkboxes</div>
        <div className="grid-span-12">
          <Checkboxes
            disabled={disabled}
            errorText="Error"
            id="styleguide-checkboxes"
            label="Checkboxes Label"
            onChange={setCheckboxes}
            options={options}
            selectAll
            showError={showError}
            values={checkboxes}
          />
        </div>
        <div className="grid-span-12">
          <Checkbox
            checked={isChecked}
            disabled={disabled}
            label="Checkbox (single)"
            onChange={setCheckbox}
            showError={showError}
          />
        </div>

        <div className="h7 grid-span-12">Icon Toggles</div>
        <div className="grid-span-12">
          <Checkbox
            checked={isToggleOn}
            className="slide-toggle"
            disabled={disabled}
            icon={isToggleOn ? 'toggle-on' : 'toggle-off'}
            label={isToggleOn ? 'ON' : 'OFF'}
            onChange={setToggle}
            showError={showError}
          />
        </div>
        <div className="grid-span-12">
          <Checkbox
            checked={isLabeledIconChecked}
            className="active-toggle"
            disabled={disabled}
            icon={isLabeledIconChecked ? 'heart' : 'heart-o'}
            label="Icon Toggle With Label"
            onChange={setLabeledIconChecked}
            showError={showError}
          />
        </div>
        <div className="grid-span-12">
          <Checkbox
            checked={isIconChecked}
            className="active-toggle"
            disabled={disabled}
            icon={isIconChecked ? 'star' : 'star-o'}
            onChange={setIconChecked}
            showError={showError}
          />
        </div>
        <div className="grid-span-12">
          <ImageSelect
            errorText="Select an image"
            label="Image Select"
            onChange={setImage}
            options={IMAGE_OPTIONS}
            showError={showError}
            value={image}
          />
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default StyleguideToggles;
