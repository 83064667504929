import React, { Fragment, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import CopyIcon from '@mui/icons-material/ContentCopy';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { IncidentDetailEmail } from '@vestahealthcare/common/models';

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  Label,
} from 'styleguide-v2';

type Props = {
  className?: string;
  customMails: string[];
  editable?: boolean;
  emails: IncidentDetailEmail[];
  onAddEmail: () => void;
  onCopyEmails: () => void;
  onDeleteMail: (mail: string) => void;
};

const useStyles = makeStyles({
  closeIcon: {
    '&&': {
      padding: '0 0 0 1rem',
    },
  },
});

export const IncidentsDetailClient = ({
  className,
  customMails,
  editable,
  emails,
  onAddEmail,
  onCopyEmails,
  onDeleteMail,
}: Props) => {
  const styles = useStyles();

  const [showCopyTootlip, setShowCopyTooltip] = useState<boolean>(false);

  const filteredEmails = emails.filter(
    ({ emails: mails, careTeamMembersWithoutEmail }) =>
      mails?.length || careTeamMembersWithoutEmail?.length,
  );
  return (
    <Card className={className}>
      <CardHeader
        title={translate('incidents.detail.clientCardTitle')}
        action={
          <Button
            color="quaternary"
            data-cy="incident-detail-add-email"
            disabled={
              !editable && !customMails?.length && !filteredEmails?.length
            }
            icon={<CopyIcon />}
            size="s"
            onClick={
              editable
                ? onAddEmail
                : () => {
                    onCopyEmails();
                    setShowCopyTooltip(true);
                    setTimeout(() => setShowCopyTooltip(false), 1000);
                  }
            }
            tooltip={showCopyTootlip ? translate('global.copied') : undefined}
          >
            <span className="bold">
              {translate(
                `incidents.detail.${editable ? 'addEmail' : 'copyEmails'}`,
              )}
            </span>
          </Button>
        }
      />
      <CardContent sx={{ paddingTop: 0 }}>
        {!!filteredEmails?.length &&
          filteredEmails.map(
            ({ groupName, emails, careTeamMembersWithoutEmail }) => (
              <Fragment key={groupName}>
                <Label>{groupName}</Label>
                <div className="flex wrap gap">
                  {emails?.map((email) => (
                    <Chip color="action" key={email}>
                      {email}
                    </Chip>
                  ))}
                  {careTeamMembersWithoutEmail?.map(
                    ({ careTeamPerson: { fullName } }) => (
                      <Chip color="error" key={fullName}>
                        {fullName}
                      </Chip>
                    ),
                  )}
                </div>
                <br />
              </Fragment>
            ),
          )}
        {!!customMails?.length && (
          <>
            <Label>{translate('incidents.detail.additionalEmails')}</Label>
            <div className="flex wrap gap">
              {customMails?.map((email) => (
                <Chip color="action" key={email}>
                  {email}
                  <IconButton
                    className={styles.closeIcon}
                    disabled={!editable}
                    size="small"
                    onClick={() => onDeleteMail(email)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Chip>
              ))}
            </div>
          </>
        )}
        {!filteredEmails?.length && !customMails?.length && (
          <span>{translate('incidents.detail.noEmails')}</span>
        )}
      </CardContent>
    </Card>
  );
};
