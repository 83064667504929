import { Employee } from '.';
import { make } from './Model';

export class Goal {
  id = 0;

  name = '';

  active = false;

  numberOfInterventions?: number;

  createdBy?: Employee;

  updatedBy?: Employee;

  createdAt = 0;

  updatedAt = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdBy = make(obj.createdBy, Employee);
    this.updatedBy = make(obj.updatedBy, Employee);
  }
}

export default Goal;
