import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

import { States } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { Employee, Patient } from '@vestahealthcare/common/models';

import { Button, CollapsableSidebar, Select, Tooltip } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CacheServices } from 'dash/src/services';
import { PatientParam } from 'dash/src/services/PatientServices';

const useStyles = makeStyles({
  disabledOption: {
    opacity: 0.38,
    width: '100%',
  },
});

type Props = {
  member?: Patient;
  onClose: () => void;
  onSubmit: (params: PatientParam) => void;
  open: boolean;
};

export const EditVestaTeamModal = ({
  member,
  onClose,
  onSubmit,
  open,
}: Props) => {
  const styles = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);

  const [careCoordinators, setCareCoordinators] = useState<Employee[]>([]);
  const [engagementOwners, setEngagementOwners] = useState<Employee[]>([]);
  const [healthCoaches, setHealthCoaches] = useState<Employee[]>([]);
  const [podManagers, setPodManagers] = useState<Employee[]>([]);
  const [NPs, setNPs] = useState<Employee[]>([]);
  const [RNs, setRNs] = useState<Employee[]>([]);

  const [selectedCareCoordinators, setSelectedCareCoordinators] = useState<
    Employee[]
  >([]);
  const [selectedEngagementOwners, setSelectedEngagementOwners] = useState<
    Employee[]
  >([]);
  const [selectedHealthCoaches, setSelectedHealthCoaches] = useState<
    Employee[]
  >([]);
  const [selectedNP, setSelectedNP] = useState<Employee>();
  const [selectedRN, setSelectedRN] = useState<Employee>();
  const [selectedPodManager, setSelectedPodManager] = useState<Employee>();
  const [nlcStates, setNLCStates] = useState<States[]>([]);

  const fetchInitialData = async () => {
    setLoading(true);
    const [employees, states] = await Promise.all([
      CacheServices.getEmployees(),
      CacheServices.getEmployeeNLCStates(),
    ]);

    setCareCoordinators(employees.filter((e) => e.isInCareCoordinator));
    setEngagementOwners(employees.filter((e) => e.isInEngagement));
    setHealthCoaches(employees.filter((e) => e.isInHealthCoaches));
    setNPs(employees.filter((e) => e.isNP));
    setPodManagers(employees.filter((e) => e.isPodManager));
    setRNs(employees.filter((e) => e.isRN));
    setNLCStates(states || []);

    setLoading(false);
  };

  const resetData = () => {
    setSelectedCareCoordinators(member?.careCoordinators || []);
    setSelectedEngagementOwners(member?.engagementOwners || []);
    setSelectedHealthCoaches(member?.healthCoaches || []);
    setSelectedNP(member?.npOwner);
    setSelectedPodManager(member?.podManager);
    setSelectedRN(member?.owner);
    setLoadingButton(false);
    setSubmit(false);
  };

  useEffect(() => {
    if (open) {
      fetchInitialData();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      resetData();
    }
  }, [open, member]);

  const checkSameIds = (a?: Employee[], b?: Employee[]) => {
    if (!a?.length && !b?.length) return true;
    if (a?.length !== b?.length) return false;
    const aIds = a?.map(({ id }) => id);
    const bIds = b?.map(({ id }) => id);
    return aIds?.reduce((acc, item) => acc && !!bIds?.includes(item), true);
  };

  const hasChanges =
    !checkSameIds(selectedCareCoordinators, member?.careCoordinators) ||
    !checkSameIds(selectedEngagementOwners, member?.engagementOwners) ||
    !checkSameIds(selectedHealthCoaches, member?.healthCoaches) ||
    selectedNP?.id !== member?.npOwner?.id ||
    selectedRN?.id !== member?.owner?.id ||
    selectedPodManager?.id !== member?.podManager?.id;

  const validate = () =>
    selectedPodManager?.id &&
    selectedNP?.id &&
    selectedRN?.id &&
    (!!selectedCareCoordinators?.length || !member?.careCoordinators?.length);

  const doSubmit = async () => {
    if (!hasChanges) return;
    setSubmit(true);
    if (!validate()) return;

    try {
      setLoadingButton(true);
      await onSubmit({
        careCoordinatorIds: selectedCareCoordinators?.map(({ id }) => id),
        engagementOwnerIds: selectedEngagementOwners?.map(({ id }) => id),
        healthCoachIds: selectedHealthCoaches?.map(({ id }) => id),
        npOwner: selectedNP?.id,
        owner: selectedRN?.id,
        podManager: selectedPodManager?.id,
      });
      onClose();
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoadingButton(false);
  };

  const memberState = member?.address.state;

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={translate('memberProfile.network.vestaTeamModalTitle')}
    >
      <CollapsableSidebar.Body>
        <Select
          data-cy="edit-vesta-team-pod-manager"
          disableClearable
          error={submit && !selectedPodManager}
          items={podManagers}
          getItemLabel={({ fullName }: Employee) => fullName}
          label={translate('memberProfile.network.podManager')}
          loading={loading}
          onChange={setSelectedPodManager}
          required
          value={selectedPodManager}
        />
        <Select
          data-cy="edit-vesta-team-np-ownwer"
          disableClearable
          items={NPs}
          getItemLabel={({ fullName }: Employee) => fullName}
          label={translate('memberProfile.network.npOwner')}
          loading={loading}
          onChange={setSelectedNP}
          renderOption={(e: Employee) =>
            e.allowedInState(memberState, nlcStates) ? (
              e.fullName
            ) : (
              <Tooltip
                text={
                  <>
                    <span>
                      {translate(
                        'memberProfile.network.warningEmployeeState.tooltip1',
                      )}
                    </span>
                    <span className="semi-bold">
                      {translate(
                        'memberProfile.network.warningEmployeeState.tooltip2',
                        {
                          name: e.fullName,
                          state: memberState?.toString(),
                        },
                      )}
                    </span>
                    <span>
                      {translate(
                        'memberProfile.network.warningEmployeeState.tooltip3',
                      )}
                    </span>
                  </>
                }
                enterDelay={200}
                followCursor
              >
                <span
                  className={styles.disabledOption}
                  onClick={(evt) => evt.stopPropagation()}
                >
                  {' '}
                  {e.fullName}
                </span>
              </Tooltip>
            )
          }
          required={!!member?.npOwner}
          value={selectedNP}
        />
        <Select
          data-cy="edit-vesta-team-rn-ownwer"
          disableClearable
          items={RNs}
          getItemLabel={({ fullName }: Employee) => fullName}
          label={translate('memberProfile.network.rnOwner')}
          loading={loading}
          onChange={setSelectedRN}
          renderOption={(e: Employee) =>
            e.allowedInState(memberState, nlcStates) ? (
              e.fullName
            ) : (
              <Tooltip
                text={
                  <>
                    <span>
                      {translate(
                        'memberProfile.network.warningEmployeeState.tooltip1',
                      )}
                    </span>
                    <span className="semi-bold">
                      {translate(
                        'memberProfile.network.warningEmployeeState.tooltip2',
                        {
                          name: e.fullName,
                          state: memberState?.toString(),
                        },
                      )}
                    </span>
                    <span>
                      {translate(
                        'memberProfile.network.warningEmployeeState.tooltip3',
                      )}
                    </span>
                  </>
                }
                enterDelay={200}
                followCursor
              >
                <span
                  className={styles.disabledOption}
                  onClick={(evt) => evt.stopPropagation()}
                >
                  {' '}
                  {e.fullName}
                </span>
              </Tooltip>
            )
          }
          required={!!member?.owner}
          value={selectedRN}
        />
        <Select
          data-cy="edit-vesta-team-care-coordinator"
          disableClearable
          error={
            submit &&
            !selectedCareCoordinators?.length &&
            !!member?.careCoordinators?.length
          }
          items={careCoordinators}
          getItemLabel={({ fullName }: Employee) => fullName}
          label={translate('memberProfile.network.careCoordinators')}
          loading={loading}
          multiple
          onChange={setSelectedCareCoordinators}
          required={!!member?.careCoordinators?.length}
          value={selectedCareCoordinators}
        />
        <Select
          data-cy="edit-vesta-team-engagement-owner"
          items={engagementOwners}
          getItemLabel={({ fullName }: Employee) => fullName}
          label={translate('memberProfile.network.engagementOwners')}
          loading={loading}
          multiple
          onChange={setSelectedEngagementOwners}
          value={selectedEngagementOwners}
        />
        <Select
          data-cy="edit-vesta-team-health-coach"
          items={healthCoaches}
          getItemLabel={({ fullName }: Employee) => fullName}
          label={translate('memberProfile.network.healthCoaches')}
          loading={loading}
          multiple
          onChange={setSelectedHealthCoaches}
          value={selectedHealthCoaches}
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="edit-vesta-team-close"
          onClick={onClose}
        >
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="edit-vesta-team-submit"
          disabled={!hasChanges}
          loading={loadingButton}
          onClick={doSubmit}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default EditVestaTeamModal;
