import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { EventModel } from '@vestahealthcare/common/models';
import EventStatusGroup from '@vestahealthcare/common/models/EventStatusGroup';
import { fetchMemberEvents } from 'dash/src/services/EventServices';

export const fetchEventsCount = createAsyncThunk(
  'eventSlice/fetchEventsCount',
  async (patientId: number) => {
    const {
      pagination: { total },
    } = await fetchMemberEvents(patientId, { count: true });
    const {
      pagination: { total: opened },
    } = await fetchMemberEvents(patientId, {
      count: true,
      statusGroup: [EventStatusGroup.OPEN],
    });
    return {
      total,
      opened,
    };
  },
);

interface MemberEventsState {
  loadingCount: boolean;
  opened: number;
  patientId: number;
  total: number;
}

const initState: MemberEventsState = {
  loadingCount: false,
  opened: 0,
  patientId: 0,
  total: 0,
};

const eventsSlice = createSlice({
  name: 'eventsSlice',
  initialState: initState,
  reducers: {
    onEventChangeStatus: (state, action) => {
      const event = action.payload as EventModel;
      if (event.status.isClosedStatus) {
        state.opened -= 1;
      }
    },
    onAddEvent: (state) => {
      state.opened += 1;
      state.total += 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEventsCount.fulfilled, (state, action) => {
      state.total = action.payload.total;
      state.opened = action.payload.opened;
      state.loadingCount = false;
    });
    builder.addCase(fetchEventsCount.pending, (state) => {
      state.loadingCount = true;
    });
  },
});

export const { onAddEvent, onEventChangeStatus } = eventsSlice.actions;

export default eventsSlice.reducer;
