export class IssuePrompt {
  id = 0;

  message = '';

  ack = false;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default IssuePrompt;
