import { Moment } from 'moment';

import { BaseEnum } from './BaseEnum';
import { make, makeMoment, makeUnix } from './Model';

export class SkilledEvent {
  chiefComplaint = '';

  episodeEnd?: Moment;

  episodeStart?: Moment;

  skilledRecordClosedAt?: Moment;

  skilledRecordId = 0;

  type: BaseEnum;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.episodeEnd = makeMoment(obj.episodeEnd);
    this.episodeStart = makeMoment(obj.episodeStart);
    this.skilledRecordClosedAt = makeUnix(obj.skilledRecordClosedAt);
    this.skilledRecordId = obj.skilledRecordId;
    this.type = make(obj.type, BaseEnum);
  }
}

export default SkilledEvent;
