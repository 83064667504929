import React, { useEffect, useState } from 'react';
import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';
import { Icon } from 'styleguide';
import { fetchEnabledConfigs } from 'dash/src/services/BiometricsServices';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { BiometricsType } from '@vestahealthcare/common/enums';

interface Props {
  onSubmit: (patient: Patient) => {};
  patient: Patient;
}

const useStyles = makeStyles({
  container: {
    borderRight: '2px !important',
    paddingRight: '7px !important',
    gridGap: '15px 20px !important',
  },
});

export const ShowRPMVendor = (props: Props) => {
  const styles = useStyles();
  const history = useHistory();
  const { patient } = props;
  const [vendors, setVendors] = useState<Map<string, string[]>>();

  const getBiometricsConfigs = async (id: number) => {
    const configs = await fetchEnabledConfigs(id);
    const vendorsMap = new Map<string, string[]>();
    configs.forEach(({ collectionMethod, type }) => {
      const vendor = collectionMethod?.value || '';
      const array = vendorsMap.get(vendor) || [];
      // Merge BP_DIASTOLIC and BP_SYSTOLIC
      if (type === BiometricsType.BP_DIASTOLIC) {
        vendorsMap.set(vendor, [
          ...array,
          translate('personalDetails.bloodPressure'),
        ]);
      } else if (type !== BiometricsType.BP_SYSTOLIC) {
        vendorsMap.set(vendor, [
          ...array,
          translate(`enums.biometricsType.${type.value}`),
        ]);
      }
    });
    setVendors(vendorsMap);
  };

  useEffect(() => {
    getBiometricsConfigs(patient.id);
  }, [patient.id]);

  return vendors?.size ? (
    <>
      <div
        data-cy="rpm-vendor-readonly"
        className={`grid-span-11 grid-wrapper ${styles.container}`}
      >
        {[...vendors.keys()].sort().map((key) => (
          <div
            className={`grid-span-11 grid-wrapper ${styles.container}`}
            key={key}
          >
            <h4 className="grid-span-6">
              {translate(`enums.collectionMethod.${key}`)}
            </h4>
            <p className="grid-span-6">{vendors.get(key)?.sort().join(', ')}</p>
          </div>
        ))}
      </div>
      <Icon
        tag="button"
        className="fa fa-link"
        onClick={() => history.push(`/patients/${patient.id}/biometrics`)}
      />
    </>
  ) : (
    <>
      <h4 data-cy="rpm-vendor-empty-type-readonly" className="grid-span-5">
        {translate('personalDetails.rpmVendor')}
      </h4>
      <p data-cy="rpm-vendor-empty-type-readonly" className="grid-span-6">
        {EMPTY}
      </p>
      <Icon
        tag="button"
        className="fa fa-link"
        onClick={() => history.push(`/patients/${patient.id}/biometrics`)}
      />
    </>
  );
};

export default ShowRPMVendor;
