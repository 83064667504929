import React, { useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { Button, CollapsableSidebar, TextArea } from 'styleguide-v2';

interface Props {
  onClose: () => void;
  onSubmit: (selection: number[]) => void;
  open: boolean;
}

export const CustomSelectionModal = ({ onClose, onSubmit, open }: Props) => {
  const [idText, setIdText] = useState<string>();

  const parseTextIDs = (idString: string) => {
    return idString
      .split(/[^0-9]+/)
      .map((id) => parseInt(id, 10))
      .filter((id) => !!id);
  };

  const handleSubmit = () => {
    if (!idText) {
      return false;
    }

    const selection = parseTextIDs(idText);
    onSubmit(selection);
    onClose();
    return true;
  };

  return (
    <CollapsableSidebar
      title={<h2>{translate('memberBulkEdit.customSelection')}</h2>}
      onClose={onClose}
      open={open}
      size={450}
    >
      <CollapsableSidebar.Body>
        <div className="grid-wrapper">
          <div className="grid-span-12">
            <TextArea
              label={translate('memberBulkEdit.customSelectionText')}
              rows={3}
              onChange={setIdText}
            />
          </div>
        </div>
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="custom-selection-close"
          onClick={onClose}
        >
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="custom-selection-submit"
          disabled={!idText}
          onClick={handleSubmit}
        >
          {translate('memberBulkEdit.customSelection')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default CustomSelectionModal;
