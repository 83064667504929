import React, { MouseEventHandler, ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import classNames from 'classnames';
import { MarkChatUnread } from '@mui/icons-material';

import {
  DATE_FORMAT_SHORT_WITH_TIME,
  TIME_FORMAT,
} from '@vestahealthcare/common/utils/constants';
import { Colors } from 'styleguide-v2/src/styles/Colors';
import { Fonts } from 'styleguide-v2/src/styles/Variables';
import { IconButton, Tooltip } from '@mui/material';
import { translate } from '@vestahealthcare/common/i18n';

type MessageModel = {
  'data-cy'?: string;
  className?: string;
  color?: 'primary' | 'secondary';
  date: number;
  image?: File;
  message?: string;
  onClickActions?: MouseEventHandler;
  ref?: HTMLDivElement | null;
  startIcon?: ReactNode;
  status?: ReactNode;
  user: string;
};

const useStyles = makeStyles({
  bubble: {
    alignItems: 'baseline',
    borderRadius: '1rem',
    boxShadow: '-2px 2px 4px 0px #0000001F',
    display: 'flex',
    flexFlow: 'column',
    marginTop: '.1rem',
    maxWidth: '100%',
    minWidth: '20rem',
    padding: '2.5rem',
    width: '100%',
  },
  container: {
    minWidth: '20rem',
    width: 'max-content',
    fontFamily: Fonts.fontFamily,
    fontSize: Fonts.fontSize,
  },
  message: {
    width: '100%',
  },
  primary: {
    backgroundColor: Colors.white,
    color: Colors.textBlack,
  },
  secondary: {
    backgroundColor: Colors.green,
    color: Colors.white,
  },
  user: {
    fontWeight: 500,
    marginRight: '1rem',
  },
  date: {
    float: 'right',
    marginRight: '.25rem',
  },
  image: {
    maxHeight: '40rem',
    maxWidth: '100%',
  },
  actions: {
    alignSelf: 'baseline',
    left: '1rem',
    top: '2.5rem',
  },
  startIcon: {
    position: 'relative',
    right: '3rem',
    top: '.25rem',
    height: 0,
  },
});

export const Message = ({
  className,
  color = 'primary',
  'data-cy': dataCy,
  date,
  image,
  message,
  onClickActions,
  startIcon,
  status,
  user,
}: MessageModel) => {
  const styles = useStyles();
  const containerClasses = classNames('flex', className, styles.container);
  const bubbleClasses = classNames(
    styles.bubble,
    color === 'secondary' ? styles.secondary : styles.primary,
  );

  return (
    <div className={containerClasses} data-cy={dataCy}>
      <div className={styles.message}>
        <span className={styles.user}>{user}</span>
        <span className={styles.date}>
          {moment().isAfter(moment.unix(date), 'day')
            ? moment.unix(date).format(DATE_FORMAT_SHORT_WITH_TIME)
            : moment.unix(date).format(TIME_FORMAT)}
        </span>
        {startIcon && <div className={styles.startIcon}>{startIcon}</div>}
        <div className={bubbleClasses}>
          {image && (
            <img
              className={styles.image}
              alt=""
              src={URL.createObjectURL(image)}
            />
          )}
          {image && message && <br />}
          {message && <span>{message}</span>}
        </div>
        {status}
      </div>
      {onClickActions && (
        <Tooltip title={translate('global.markUnread')} placement="right-end">
          <IconButton
            className={styles.actions}
            color="primary"
            onClick={onClickActions}
          >
            <MarkChatUnread />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};
