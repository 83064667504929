import { BaseEnum } from './BaseEnum';

export class CareTeamMemberType extends BaseEnum {
  static FAMILY_FRIEND: string = 'FAMILY';

  static AIDE: string = 'AIDE';

  static PROVIDER: string = 'PROVIDER';

  static OTHER: string = 'OTHER';
}

export default CareTeamMemberType;
