import React from 'react';
import { Table } from 'styleguide';
import moment from '@vestahealthcare/common/moment';
import { DATE_TIME_FORMAT } from '@vestahealthcare/common/utils/constants';
import {
  AppOS,
  Build,
  BuildDetails,
  InstallButton,
  CopyLink,
  getBuildUrl,
} from './ReleaseInfo';
import { DashboardType } from './services';

export interface Filters {
  dev?: string;
  version?: string;
  ticket?: string;
  appOS?: AppOS;
}

type Props = {
  deviceType: DashboardType;
  details: Map<string, BuildDetails>;
  releases: Build[];
  filters: Filters;
};

export const MobileDashboardTable = ({
  deviceType,
  releases,
  details,
  filters,
}: Props) => (
  <Table>
    <thead>
      <tr>
        <th style={{ width: 100 }}>Version</th>
        <th>Upload date</th>
        <th>Branch Name</th>
        <th />
      </tr>
    </thead>
    <tbody>
      {releases.map((release) => {
        const { version, ticket, appOS } = filters;
        const releaseDetails = details.get(release.key);
        if (
          (ticket && releaseDetails?.jiraTicket !== ticket) ||
          (version && releaseDetails?.version !== version) ||
          (appOS && release.appOS !== appOS)
        ) {
          return;
        }

        const branchName = releaseDetails?.branchName ?? '';
        const isDev = branchName === 'dev';
        const isRelease = branchName.startsWith('release');
        let color = '';
        if (isDev) color = 'yellow';
        if (isRelease) color = 'pink';
        const buildLink = getBuildUrl(deviceType, release.id);

        return (
          <tr className={color} key={release.uploaded_at}>
            <td>
              <p className="medium">
                <div className="flex space-between">
                  <a href={buildLink}>{release.id}</a>
                  <CopyLink
                    deviceType={deviceType}
                    releaseId={String(release.id)}
                  />
                </div>
              </p>
              <p className="small">
                {release.short_version} ({release.version})
              </p>
            </td>
            <td>
              <p className="small">
                {moment(release.uploaded_at).format(DATE_TIME_FORMAT)}
              </p>
            </td>
            <td>
              <p className="small">{branchName}</p>
            </td>
            <td>
              <InstallButton release={release} details={releaseDetails} />
            </td>
          </tr>
        );
      })}
    </tbody>
  </Table>
);

export default MobileDashboardTable;
