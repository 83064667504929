import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';

import { AudienceTarget, Enum } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  CampaignBanner,
  CampaignModal,
  Organization,
} from '@vestahealthcare/common/models';

import {
  Button,
  Checkbox,
  Checkboxes,
  Panel,
  Select,
  TextInput,
} from 'styleguide';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CacheServices } from 'dash/src/services';
import {
  createCampaign,
  editCampaign,
  fetchCampaign,
  fetchCampaignBanners,
  fetchCampaignModals,
} from 'dash/src/services/CampaignServices';

type Params = {
  campaignId: string;
};

const useStyles = makeStyles({
  organizations: {
    maxHeight: '31rem',
    overflowY: 'auto',
  },
  organizationAll: {
    marginTop: 'auto',
  },
});

export const EditCampaign = withRouter((props: RouteComponentProps<Params>) => {
  const {
    history,
    match: {
      params: { campaignId },
    },
  } = props;
  const isNew = !campaignId;
  const styles = useStyles();

  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [name, setName] = useState<string>();
  const [allOrganizations, setAllOrganizations] = useState<Organization[]>([]);
  const [organizationFilter, setOrganizationFilter] = useState<
    string | undefined
  >('');
  const [selectedOrganizations, setSelectedOrganizations] = useState<
    string[]
  >();
  const [audienceTarget, setAudienceTarget] = useState<string>();
  const [allModals, setAllModals] = useState<CampaignModal[]>([]);
  const [selectedModals, setSelectedModals] = useState<number[]>();
  const [allBanners, setAllBanners] = useState<CampaignBanner[]>([]);
  const [selectedBanners, setSelectedBanners] = useState<number[]>();
  const [active, setActive] = useState(false);

  const fetchData = async () => {
    setLoading(true);

    const [organizations, modals, banners] = await Promise.all([
      CacheServices.getOrganizations(),
      fetchCampaignModals(),
      fetchCampaignBanners(),
    ]);

    const referralSources = organizations.filter(
      ({ isActiveReferral }) => isActiveReferral,
    );

    setAllOrganizations(referralSources);
    setAllModals(modals);
    setAllBanners(banners);

    if (campaignId) {
      const campaign = await fetchCampaign(campaignId);

      setName(campaign.name);
      setActive(campaign.active);
      setAudienceTarget(campaign.targets[0].value); // we only handle 1 for now

      const filteredOrganizations = campaign.organizations
        .filter(({ id }: Organization) =>
          referralSources.find((organization) => organization.id === id),
        )
        .map((organization) => organization.id.toString());

      setSelectedOrganizations(filteredOrganizations);

      const filteredModals = campaign.modals
        .filter(({ id }: CampaignModal) =>
          modals.find((modal) => modal.id === id),
        )
        .map((modal) => modal.id);

      setSelectedModals(filteredModals);

      const filteredBanners = campaign.banners
        .filter(({ id }: CampaignBanner) =>
          banners.find((banner) => banner.id === id),
        )
        .map((banner) => banner.id);

      setSelectedBanners(filteredBanners);
    } else {
      setSelectedOrganizations(referralSources.map(({ id }) => id.toString()));
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [campaignId]);

  const onSave = async () => {
    setShowError(false);
    if (!name || !audienceTarget || !selectedOrganizations?.length) {
      setShowError(true);
    } else {
      setLoading(true);
      const params = {
        active,
        name,
        targets: audienceTarget ? [audienceTarget] : undefined,
        banners: selectedBanners,
        modals: selectedModals,
        organizationIds: selectedOrganizations.map(Number),
      };

      if (campaignId) {
        try {
          await editCampaign(campaignId, params);
          history.push('/admin/campaigns/app-modals');
        } catch (e) {
          setLoading(false);
          showGlobalError(e as string);
        }
      } else {
        try {
          await createCampaign(params);
          history.push('/admin/campaigns/app-modals');
        } catch (e) {
          setLoading(false);
          showGlobalError(e as string);
        }
      }
    }
  };

  const getFilteredOrganizations = (inverse = false) =>
    allOrganizations.filter(
      ({ name }) =>
        inverse !== name.toLocaleLowerCase().includes(organizationFilter || ''),
    );

  const getFilteredOrganizationsSelected = () => {
    const filteredOrganizations = getFilteredOrganizations(false);
    const filteredOrganizationsSelected: string[] = [];
    filteredOrganizations.forEach(({ id }) => {
      if (selectedOrganizations?.indexOf(id.toString()) !== -1) {
        filteredOrganizationsSelected.push(id.toString());
      }
    });
    return filteredOrganizationsSelected;
  };

  const changeSelectedOrganizations = (selected: string[]) => {
    const filteredOrganizations = getFilteredOrganizations(true);
    const newSelected = [...selected];
    filteredOrganizations.forEach(({ id }) => {
      if (selectedOrganizations?.indexOf(id.toString()) !== -1) {
        newSelected.push(id.toString());
      }
    });
    setSelectedOrganizations(newSelected);
  };

  return (
    <div id="page">
      <Panel>
        <Panel.Heading title={isNew ? 'Add Campaign' : 'Edit Campaign'} />
        <Panel.Body loading={loading} className="grid-wrapper">
          <TextInput
            data-cy="add-campaign-name"
            className="grid-span-6"
            errorText={translate('global.missingRequiredField')}
            label={translate('campaigns.campaignName')}
            onChange={setName}
            required
            showError={!name && showError}
            value={name}
          />
          <div className="grid-span-6" />
          <div className="grid-span-12 grid-wrapper">
            <TextInput
              columns={3}
              label={translate('campaigns.referralSource')}
              onChange={(value) =>
                setOrganizationFilter(value?.toLocaleLowerCase())
              }
              placeholder={translate('global.search')}
            />
            {allOrganizations && (
              <Checkboxes
                checkBoxColumns={4}
                className={styles.organizations}
                columns={12}
                data-cy="add-campaign-organization"
                selectAllLabel={
                  organizationFilter
                    ? (someAreSelected: boolean) =>
                        translate(
                          `global.${
                            someAreSelected
                              ? 'deselectAllFiltered'
                              : 'selectAllFiltered'
                          }`,
                        )
                    : undefined
                }
                onChange={changeSelectedOrganizations}
                options={getFilteredOrganizations().map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                required
                selectAll
                countLabel={() =>
                  selectedOrganizations
                    ? `${selectedOrganizations?.length} ${translate(
                        'campaigns.selectedReferrals',
                      )}`
                    : ''
                }
                values={getFilteredOrganizationsSelected()}
              />
            )}
          </div>
          <Select
            data-cy="add-campaign-audience"
            className="grid-span-4"
            errorText={translate('global.missingRequiredField')}
            label={translate('campaigns.audienceType')}
            onChange={setAudienceTarget}
            options={Enum.toSelectable(AudienceTarget.asArray)}
            required
            submitted={!audienceTarget && showError}
            value={audienceTarget}
          />
          <div className="grid-span-4" />
          <Select
            data-cy="add-campaign-modals"
            className="grid-span-4"
            label={translate('campaigns.modals')}
            multiple
            onChange={setSelectedModals}
            options={allModals.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            value={selectedModals}
          />
          <Select
            data-cy="add-campaign-banners"
            className="grid-span-4"
            label={translate('campaigns.banners')}
            multiple
            onChange={setSelectedBanners}
            options={allBanners.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            value={selectedBanners}
          />
          <div className="grid-span-4" />
          <Checkbox
            data-cy="campaign-activation"
            checked={active}
            className="slide-toggle grid-span-2"
            icon={active ? 'toggle-on' : 'toggle-off'}
            label={translate(
              active ? 'campaigns.active' : 'campaigns.inactive',
            )}
            onChange={() => setActive(!active)}
          />
        </Panel.Body>
        <Panel.Footer>
          <div className="left-side">
            <Button color="tertiary" href="/#/admin/campaigns/app-modals">
              {translate('global.cancel')}
            </Button>
          </div>
          <div className="right-side">
            <Button color="primary" onClick={onSave}>
              {translate('global.save')}
            </Button>
          </div>
        </Panel.Footer>
      </Panel>
    </div>
  );
});

export default EditCampaign;
