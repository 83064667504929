import moment, { Moment } from 'moment';

import { DATE_FORMAT_SHORT } from '@vestahealthcare/common/utils/constants';

export const transformDateToDaysRange = (
  from: Date,
  to: Date,
  validRanges: number[],
) => {
  const diff = moment(to.getTime()).diff(moment(from.getTime()), 'days');
  if (validRanges.indexOf(diff) === -1) {
    return `${moment(from.getTime()).format(DATE_FORMAT_SHORT)},${moment(
      to.getTime(),
    ).format(DATE_FORMAT_SHORT)}`;
  }
  return diff;
};

export const transformDaysRangeToDates = (range: string | number) => {
  let from: Moment | null = null;
  let to: Moment | null = null;

  if (typeof range === 'number') {
    if (range !== -1) {
      from = moment().subtract(range, 'd').startOf('day');
      to = moment().endOf('day');
    }
  } else {
    from = moment(range.split(',')[0]);
    to = moment(range.split(',')[1]);
  }
  return [from?.toDate(), to?.toDate()];
};

export const dateFromNow = (date: Moment) => {
  const fromNow = date.fromNow();
  return date.calendar(null, {
    lastWeek: 'Last dddd',
    lastDay: 'Yesterday',
    sameDay: 'Today',
    nextDay: 'Tomorrow',
    nextWeek: 'dddd',
    sameElse: () => fromNow,
  });
};

export const isDate = (date: string) => /[\d-]+T[\d+-:]+/.test(date);
