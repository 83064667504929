import React, { useState, useEffect } from 'react';
import { Panel } from 'styleguide';
import { withRouter } from 'react-router-dom';
import Campaigns from './Campaigns';
import CampaignModals from './CampaignModals';
import CampaignBanners from './CampaignBanners';
import {
  fetchCampaigns,
  fetchCampaignModals,
  fetchCampaignBanners,
} from 'dash/src/services/CampaignServices';
import {
  CampaignAudience,
  CampaignModal,
  CampaignBanner,
} from '@vestahealthcare/common/models';
import { translate } from '@vestahealthcare/common/i18n';

export const CampaignManagement = withRouter(() => {
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState<CampaignAudience[]>([]);
  const [modals, setModals] = useState<CampaignModal[]>([]);
  const [banners, setBanners] = useState<CampaignBanner[]>([]);

  const fetchAll = async () => {
    setLoading(true);
    const [allCampaigns, allModals, allBanners] = await Promise.all([
      fetchCampaigns(),
      fetchCampaignModals(),
      fetchCampaignBanners(),
    ]);
    setCampaigns(allCampaigns);
    setModals(allModals);
    setBanners(allBanners);
    setLoading(false);
  };

  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <Panel>
      <Panel.Heading
        data-cy="campaigns-page"
        title={translate('campaigns.campaignManagement')}
      />
      <Panel.Body loading={loading}>
        <Campaigns campaigns={campaigns} onChange={fetchAll} />
        <CampaignModals modals={modals} onChange={fetchAll} />
        <CampaignBanners banners={banners} onChange={fetchAll} />
      </Panel.Body>
    </Panel>
  );
});

export default CampaignManagement;
