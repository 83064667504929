import classNames from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { BiometricsRPMAdherenceCycle } from '@vestahealthcare/common/models';

import { Chip, Fonts, Table } from 'styleguide-v2';

const MIN_READINGS = 16;

type Props = {
  className?: string;
  data: BiometricsRPMAdherenceCycle[];
};

const RPM_ADHERENCE_COLUMNS = [
  {
    headerName: translate('biometrics.rpmAdherence.recentCycles'),
    component: ({ cycleStart, cycleEnd }: BiometricsRPMAdherenceCycle) =>
      `${cycleStart.format('MM/DD/YY')} - ${cycleEnd.format('MM/DD/YY')}`,
  },
  {
    headerName: translate('biometrics.rpmAdherence.readings'),
    component: ({ readings }: BiometricsRPMAdherenceCycle) => (
      <div className="flex center" style={{ marginRight: '3rem' }}>
        <Chip
          color={readings < MIN_READINGS ? 'error' : 'secondary'}
          type="contained-2"
          size="small"
        >
          <span className="bold">{readings}</span>
        </Chip>
      </div>
    ),
    width: 100,
  },
];

const useStyles = makeStyles({
  table: {
    '&& tbody': {
      '& td': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
        padding: '1rem 0',
      },
    },
  },
});

export const RPMAdherenceTable = (props: Props) => {
  const styles = useStyles();

  return (
    <Table
      className={classNames(styles.table, props.className)}
      config={{
        columns: RPM_ADHERENCE_COLUMNS,
        compact: true,
        data: props.data,
        paginationOptions: [-1],
      }}
      whiteBackground
    />
  );
};

export default RPMAdherenceTable;
