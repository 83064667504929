import moment, { Moment } from 'moment';
import { make } from './Model';
import { Employee } from './Employee';

export class EventTriageAdvice {
  id = 0;

  title = '';

  detail = '';

  protocol = '';

  createdBy: Employee;

  updatedBy: Employee;

  createdAt: Moment;

  updatedAt?: Moment;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdBy = make(obj.createdBy, Employee);
    this.updatedBy = make(obj.updatedBy, Employee);
    this.createdAt = moment.unix(obj.createdAt);
    this.updatedAt = obj.updatedAt ? moment.unix(obj.updatedAt) : undefined;
  }
}

export default EventTriageAdvice;
