import moment, { Moment } from 'moment';
import { make } from './Model';
import { EventInterventionType } from './EventInterventionType';
import { BaseEnum } from './BaseEnum';
import { Employee } from './Employee';

export class EventIntervention {
  id = 0;

  issueId = 0;

  type: EventInterventionType;

  outcomeResult?: BaseEnum;

  outcomeStatus?: BaseEnum;

  createdAt: Moment;

  createdBy: Employee;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdAt = moment.unix(obj.createdAt);
    this.type = make(obj.type, EventInterventionType);
    this.outcomeResult = make(obj.outcomeResult, BaseEnum);
    this.outcomeStatus = make(obj.outcomeStatus, BaseEnum);
    this.createdAt = moment.unix(obj.createdAt);
    this.createdBy = make(obj.createdBy, Employee);
  }
}

export default EventIntervention;
