import { translate } from '@vestahealthcare/common/i18n';

export const TOC_TOGGLE_ITEM_TOC = 'toc-tab-toc';
export const TOC_TOGGLE_ITEM_SKILLED = 'toc-tab-skilled';
export const TOC_TOGGLE_ITEMS = [
  {
    label: translate('toc.tabs.toc'),
    value: TOC_TOGGLE_ITEM_TOC,
  },
  {
    label: translate('toc.tabs.skilled'),
    value: TOC_TOGGLE_ITEM_SKILLED,
  },
];

export const TOC_TAB_ALL = 'toc-tab-all';
export const TOC_TAB_OPEN = 'toc-tab-open';
export const TOC_TAB_DISCHARGED_INITIAL = 'toc-tab-discharged-initial';
export const TOC_TAB_DISCHARGED_POST = 'toc-tab-discharged-post';
export const TOC_TAB_INPATIENT = 'toc-tab-inpatient';
export const TOC_TAB_REHAB = 'toc-tab-rehab';
export const TOC_TAB_HANDOFF = 'toc-tab-handoff';
export const TOC_TAB_TOC = 'toc-tab-toc';
export const TOC_TAB_CLOSED = 'toc-tab-closed';
