import React from 'react';
import classnames from 'classnames';

import makeStyles from '@mui/styles/makeStyles';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { Fonts, Spinner, Tooltip } from 'styleguide-v2';

import { DATE_FORMAT_SHORT_WITH_TIME } from '@vestahealthcare/common/utils/constants';
import { translate } from '@vestahealthcare/common/i18n';
import { Encounter } from '@vestahealthcare/common/models';
import moment from 'moment';

type Props = {
  className?: string;
  lastEncounter?: Encounter;
  loading?: boolean;
};

const useStyles = makeStyles({
  container: {
    minHeight: '8.25rem',
    maxWidth: '20rem',
    '& > h6': {
      fontFamily: Fonts.fontFamily,
      fontWeight: 500,
      margin: 0,
      width: 'fit-content',
    },
    '& > p': {
      fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      margin: 0,
      width: 'fit-content',
    },
  },
  loadingContainer: {
    alignItems: 'center',
    display: 'flex',
    minHeight: '8.25rem',
    justifyContent: 'center',
  },
});

export const EventsLastEncounter = ({
  className,
  lastEncounter,
  loading,
}: Props) => {
  const styles = useStyles();

  return (
    <div className={classnames(styles.container, className)}>
      {lastEncounter && !loading && (
        <>
          <h6>
            {translate('memberEvents.lastEncounter')}
            {lastEncounter.note && (
              <Tooltip text={lastEncounter.note}>
                <EventNoteIcon fontSize="small" color="primary" />
              </Tooltip>
            )}
          </h6>
          <p>
            {lastEncounter.type?.toString()} /{' '}
            {lastEncounter.channel?.toString()}
          </p>
          <p>
            {moment
              .unix(lastEncounter.encounterDateTime || lastEncounter.createdAt)
              .format(DATE_FORMAT_SHORT_WITH_TIME)}
          </p>
          <p>
            {translate('global.with')} {lastEncounter.createdBy.fullName}
          </p>
        </>
      )}
      {loading && (
        <div className={styles.loadingContainer}>
          <Spinner color="primary" width={36} />
        </div>
      )}
    </div>
  );
};

export default EventsLastEncounter;
