import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@mui/styles';
import DownloadIcon from '@mui/icons-material/Download';
import { Fonts, IconButton, Table } from 'styleguide-v2';

import {
  Organization,
  PaginationType,
  RosterFileMetadata,
} from '@vestahealthcare/common/models';
import { translate } from '@vestahealthcare/common/i18n';
import {
  DATE_FORMAT_SHORT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import { CacheServices } from 'dash/src/services';

type Props = {
  files: RosterFileMetadata[];
  onChangePage: (pageSize: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onDowndload: (file: RosterFileMetadata) => void;
  pagination?: PaginationType;
};

const useStyles = makeStyles({
  table: {
    '&& tbody': {
      '& td': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
      '& a': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
    },
  },
});

const FILE_HISTORY_COLUMNS = (
  { onDowndload }: Props,
  organizations: Organization[],
) => [
  {
    headerName: translate('fileHistory.table.id'),
    field: 'id',
  },
  {
    headerName: translate('fileHistory.table.name'),
    field: 'processedFilename',
  },
  {
    headerName: translate('fileHistory.table.date'),
    component: ({ fileTimestamp }: RosterFileMetadata) =>
      fileTimestamp
        ? moment.unix(fileTimestamp).format(DATE_FORMAT_SHORT)
        : EMPTY,
  },
  {
    headerName: translate('fileHistory.table.originalFile'),
    field: 'rosterSourceArchive.processedFilename',
  },
  {
    headerName: translate('fileHistory.table.transferDate'),
    component: ({ transferTimestamp }: RosterFileMetadata) =>
      transferTimestamp
        ? moment.unix(transferTimestamp).format(DATE_FORMAT_SHORT)
        : EMPTY,
  },
  {
    headerName: translate('fileHistory.table.source'),
    component: ({ sourceOrgId }: RosterFileMetadata) =>
      organizations.find(({ id }) => id === sourceOrgId)?.name || EMPTY,
  },
  {
    headerName: translate('fileHistory.table.destination'),
    component: ({ destinationOrgId }: RosterFileMetadata) =>
      organizations.find(({ id }) => id === destinationOrgId)?.name || EMPTY,
  },
  {
    headerName: '',
    component: (file: RosterFileMetadata) => (
      <IconButton
        onClick={() => onDowndload(file)}
        size="small"
        tooltip={translate('global.download')}
      >
        <DownloadIcon />
      </IconButton>
    ),
    width: 70,
  },
];

export const FileHistoryTable = (props: Props) => {
  const styles = useStyles();
  const { files, onChangePage, onChangePageSize, pagination } = props;
  const [organizations, setOrganization] = useState<Organization[]>([]);

  const fetchInitialData = async () => {
    const organizations = await CacheServices.getOrganizations();
    setOrganization(organizations);
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <Table
      className={styles.table}
      config={{
        columns: FILE_HISTORY_COLUMNS(props, organizations),
        compact: true,
        data: files,
        noItemsLabel: translate('fileHistory.noFiles'),
        pagination,
      }}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangePageSize}
    />
  );
};

export default FileHistoryTable;
