import { Moment } from 'moment';

import { makeArray, makeUnix } from '../Model';
import { MemberWarning } from './MemberWarning';
import { MemberWarningItem } from './MemberWarningItem';

export class MemberWarningOpenItems extends MemberWarning {
  items: MemberWarningItem[];

  updatedAt?: Moment;

  constructor(obj: any) {
    super(obj);
    this.items = makeArray(obj.items, MemberWarningItem);
    this.updatedAt = makeUnix(obj.updatedAt);
  }
}

export default MemberWarningOpenItems;
