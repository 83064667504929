import { Enum } from './Enum';

export class RiskLevel extends Enum {
  translationKey = 'riskLevel';

  static asArray: RiskLevel[] = [];

  static byKey: { [name: string]: RiskLevel } = {};

  static readonly NONE = new RiskLevel('NONE');

  static readonly LOW = new RiskLevel('LOW');

  static readonly MODERATE = new RiskLevel('MODERATE');

  static readonly HIGH = new RiskLevel('HIGH');

  static readonly UNSPECIFIED = new RiskLevel('UNSPECIFIED');

  static COLOR_MAP = {
    [RiskLevel.NONE.value]: 'green',
    [RiskLevel.LOW.value]: 'shadow-green',
    [RiskLevel.MODERATE.value]: 'gold',
    [RiskLevel.HIGH.value]: 'red',
  };

  constructor(public readonly value: string) {
    super();
    RiskLevel.asArray.push(this);
    RiskLevel.byKey[value] = this;
  }

  // ~~ DEPRECATED ~~
  get description() {
    return this.toString();
  }
}

export default RiskLevel;
