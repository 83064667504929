import React, { useEffect, useState } from 'react';

import { Button, Panel, Table } from 'styleguide-v2';

type Data = {
  name: string;
  calories: string;
  fat: string;
};

const StyleguideTable = () => {
  const [size, setSize] = useState<'m' | 'l'>();
  const [data, setData] = useState<Data[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [compact, setCompact] = useState(false);
  const COLUMNS = [
    { field: 'name', headerName: 'Name' },
    { field: 'calories', headerName: 'Calories', align: 'right' },
    {
      component: (row: any) => <strong>{`${row.fat}%`}</strong>,
      headerName: 'Fat',
      align: 'right',
    },
  ];

  const createData = (): Data => ({
    name: [
      'Cupcake',
      'Donut',
      'Eclair',
      'Frozen yoghurt',
      'Gingerbread',
      'Honeycomb',
      'Ice cream sandwich',
      'Jelly Bean',
      'KitKat',
      'Lollipop',
      'Marshmall',
      'Nougat',
      'Oreo',
      'Lollipop',
    ][Math.floor(Math.random() * 14)],
    calories: (Math.random() * 500).toFixed(2),
    fat: (Math.random() * 10).toFixed(2),
  });

  useEffect(() => {
    const newData = [];
    let i = 0;
    const limit = pageSize === -1 ? 150 : pageSize;
    for (; i < limit; i += 1) {
      newData.push(createData());
    }
    if (page !== 0) {
      setData([...data, ...newData]);
    } else {
      setData(newData);
    }
  }, [page, pageSize]);

  return (
    <Panel>
      <Panel.Heading title="Tables">
        <Panel.Actions>
          <Button type="outlined" onClick={() => setSize('m')}>
            Medium
          </Button>
          <Button type="outlined" onClick={() => setSize('l')}>
            Large
          </Button>
          <Button
            color="secondary"
            type="outlined"
            onClick={() => setCompact(true)}
          >
            Compact
          </Button>
          <Button
            color="secondary"
            type="outlined"
            onClick={() => setCompact(false)}
          >
            Expanded
          </Button>
        </Panel.Actions>
      </Panel.Heading>
      <Panel.Body>
        <Table
          config={{
            columns: COLUMNS,
            compact,
            data,
            pagination: { total: 150, limit: 10, offset: 0 },
            size,
          }}
          onChangePage={setPage}
          onChangeRowsPerPage={setPageSize}
        />
      </Panel.Body>
    </Panel>
  );
};

export default StyleguideTable;
