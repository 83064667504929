import { Enum } from './Enum';

export class CampaignTimingDelay extends Enum {
  translationKey = 'campaignTimingDelay';

  static asArray: CampaignTimingDelay[] = [];

  static byKey: { [name: string]: CampaignTimingDelay } = {};

  static readonly UP_TO_3_HOURS = new CampaignTimingDelay('UP_TO_3_HOURS');

  static readonly UP_TO_6_HOURS = new CampaignTimingDelay('UP_TO_6_HOURS');

  static readonly UP_TO_12_HOURS = new CampaignTimingDelay('UP_TO_12_HOURS');

  static readonly UP_TO_24_HOURS = new CampaignTimingDelay('UP_TO_24_HOURS');

  static readonly UP_TO_2_DAYS = new CampaignTimingDelay('UP_TO_2_DAYS');

  static readonly UP_TO_5_DAYS = new CampaignTimingDelay('UP_TO_5_DAYS');

  static readonly UP_TO_15_DAYS = new CampaignTimingDelay('UP_TO_15_DAYS');

  static readonly UP_TO_30_DAYS = new CampaignTimingDelay('UP_TO_30_DAYS');

  static readonly UP_TO_ANY_DAYS = new CampaignTimingDelay('UP_TO_ANY_DAYS');

  constructor(public readonly value: string) {
    super();
    CampaignTimingDelay.asArray.push(this);
    CampaignTimingDelay.byKey[value] = this;
  }
}

export default CampaignTimingDelay;
