import classnames from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { InsuranceMLTC } from '@vestahealthcare/common/models';

import { Fonts } from 'styleguide-v2/src/styles/Variables';

type Props = {
  className?: string;
  'data-cy'?: string;
  mltc: InsuranceMLTC;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  name: {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    fontWeight: 500,
  },
  extraInfo: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 400,
  },
});

export const SelectMLTCOption = ({
  className,
  'data-cy': dataCy,
  mltc,
}: Props) => {
  const styles = useStyles();
  const classes = classnames(className, styles.container);
  const { name, mltcCode, mltcId, mltcType } = mltc;

  return (
    <div className={classes} data-cy={dataCy}>
      <span className={styles.name}>{name}</span>
      {(mltcCode || mltcId || mltcType) && (
        <span className={styles.extraInfo}>
          {[mltcCode, mltcId, mltcType].filter(Boolean).join(' - ')}
        </span>
      )}
    </div>
  );
};

export default SelectMLTCOption;
