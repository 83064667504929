import { BaseEnum } from './BaseEnum';
import { makeArray } from './Model';

export class IncidentChangeOfConditionDetail {
  dme = false;

  dmeTypes?: BaseEnum[];

  anticoagulants?: BaseEnum[];

  evaluated = false;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.dmeTypes = makeArray(obj.dmeTypes, BaseEnum);
    this.anticoagulants = makeArray(obj.anticoagulants, BaseEnum);
  }
}

export default IncidentChangeOfConditionDetail;
