import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { TaskDefinitionStatus } from '@vestahealthcare/common/enums/TaskDefinitionStatus';
import { translate } from '@vestahealthcare/common/i18n';
import { EmployeeGroup } from '@vestahealthcare/common/models';
import { TaskDefinition } from '@vestahealthcare/common/models/TaskDefinition';

import { Button, Checkbox, Panel, Select, TextInput } from 'styleguide';

import { TaskDefinitionContext } from 'dash/src/contexts/TaskDefinitionContext';
import { CacheServices } from 'dash/src/services';
import {
  createTaskDefinition,
  updateTaskDefinition,
} from 'dash/src/services/TaskServices';

import { SubTaskList } from './SubTaskList';

type Params = {
  templateId: string;
};

export const TaskDefinitionDetail = () => {
  const history = useHistory();
  const searchParams = useParams<Params>();
  const { templateId } = searchParams;

  const [loading, setLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const {
    definition,
    clearDefinition,
    fetchDefinition,
    setDefinition,
  } = useContext(TaskDefinitionContext);
  const [groups, setGroups] = useState<Selectable[]>([]);
  const [submitted, setSubmitted] = useState(false);

  const getTaskDefinition = async (id: number) => {
    setLoading(true);
    await fetchDefinition(id);
    setLoading(false);
  };

  useEffect(() => {
    const id = templateId === 'new' ? -1 : parseFloat(templateId);
    getTaskDefinition(id);
  }, [templateId]);

  const fetchGroups = async () => {
    const groups = await CacheServices.getEmployeeGroupsAssignee();
    setGroups(
      groups.map(({ name, id }: EmployeeGroup) => ({ label: name, value: id })),
    );
  };

  const getIdParam = (id: number) => (id === -1 ? 'new' : id);

  useEffect(() => {
    fetchGroups();
  }, []);

  const onToggleActive = (checked: boolean) => {
    if (checked) {
      setDefinition({ ...definition, status: TaskDefinitionStatus.DRAFT });
    } else {
      setDefinition({ ...definition, status: TaskDefinitionStatus.INACTIVE });
    }
  };

  const validate = () => {
    if (!definition.name) return false;
    if (definition.subtasks && definition.subtasks.find(({ name }) => !name))
      return false;

    return true;
  };

  const mapSubTask = (
    {
      id,
      customFields,
      employeeGroupId,
      name,
      new: isNew,
      outcomes,
      version,
      taskPrecedentIds,
      status,
    }: TaskDefinition,
    index: number,
  ) => ({
    customFields: customFields?.map((customField, position) => ({
      ...customField,
      type: customField.type.value,
      position,
    })),
    groupId: employeeGroupId,
    id: isNew ? undefined : id,
    name,
    outcomes,
    taskPrecedentIds: taskPrecedentIds?.filter((val) => val !== -1),
    position: index,
    status: status?.value,
    version,
  });

  const save = async () => {
    setSubmitted(true);
    if (validate()) {
      const {
        id,
        name,
        reference,
        employeeGroupId,
        subtasks = [],
        status = TaskDefinitionStatus.DRAFT,
        version,
      } = definition;
      const subtasksDefinitions = subtasks.map(mapSubTask);

      const data = {
        parentName: name,
        reference,
        parentGroupId: employeeGroupId,
      };

      setSaveLoading(true);

      if (id !== -1) {
        await updateTaskDefinition(id, {
          ...data,
          status: status.value,
          subtasksDefinitions,
          version,
        });
      } else {
        const { id: newTaskId } = await createTaskDefinition(data);
        await updateTaskDefinition(newTaskId, {
          ...data,
          status: status.value,
          subtasksDefinitions,
          version,
        });
      }

      CacheServices.invalidateTaskDefinitions();
      setSubmitted(false);
      setSaveLoading(false);
      clearDefinition(definition.id);

      history.goBack();
    }
  };

  const publish = async () => {
    const {
      id,
      name: parentName,
      employeeGroupId: parentGroupId,
      reference,
      subtasks = [],
      version,
    } = definition;
    const subtasksDefinitions = subtasks.map(mapSubTask);

    setPublishLoading(true);

    await updateTaskDefinition(id, {
      parentName,
      parentGroupId,
      reference,
      status: TaskDefinitionStatus.PUBLISHED.value,
      subtasksDefinitions,
      version,
    });

    CacheServices.invalidateTaskDefinitions();
    setPublishLoading(false);
    clearDefinition(definition.id);

    history.goBack();
  };

  return (
    <Panel>
      <Panel.Heading
        data-cy="task-definitions-page"
        title={definition.name || translate('taskDefinitions.task.title')}
      />
      <Panel.Body loading={loading} className="grid-wrapper">
        <TextInput
          data-cy="task-definition-name-input"
          className="grid-span-6"
          errorText={translate('global.missingRequiredField')}
          label={translate('taskDefinitions.name')}
          onChange={(name?: string) =>
            name && setDefinition({ ...definition, name })
          }
          required
          showError={submitted && !definition.name}
          value={definition.name}
        />
        <Select
          data-cy="task-definition-group-input"
          columns={6}
          label={translate('taskDefinitions.group')}
          onChange={(employeeGroupId?: number) =>
            employeeGroupId && setDefinition({ ...definition, employeeGroupId })
          }
          options={groups}
          value={definition.employeeGroupId}
        />
        <TextInput
          data-cy="task-definition-name-input"
          className="grid-span-12"
          label={translate('taskDefinitions.reference')}
          onChange={(reference?: string) =>
            reference && setDefinition({ ...definition, reference })
          }
          value={definition.reference}
        />
        <Checkbox
          checked={
            definition.status?.value !== TaskDefinitionStatus.INACTIVE.value
          }
          data-cy="subtask-definition-name-input"
          label={translate('taskDefinitions.active')}
          onChange={onToggleActive}
        />

        <SubTaskList
          items={definition.subtasks || []}
          onEdit={(subtasks) => setDefinition({ ...definition, subtasks })}
          onEditItem={(item) => {
            setDefinition({ ...definition });
            history.push(
              `/admin/task-templates/${getIdParam(definition.id)}/subtask/${
                item.id
              }`,
              {
                definition,
              },
            );
          }}
          submitted={submitted}
        />
      </Panel.Body>
      <Panel.Footer>
        <div className="left-side">
          {definition.status?.valueOf() === 'DRAFT' && (
            <Button
              color="secondary"
              data-cy="task-publish"
              isPill
              loading={publishLoading}
              onClick={() => publish()}
            >
              {translate('taskDefinitions.publish')}
            </Button>
          )}
        </div>
        <div className="right-side">
          <Button
            data-cy="task-cancel"
            color="tertiary"
            onClick={() => {
              clearDefinition(definition.id);
              history.goBack();
            }}
          >
            {translate('global.cancel')}
          </Button>
          <Button
            color="primary"
            data-cy="task-save"
            loading={saveLoading}
            isPill
            onClick={() => save()}
          >
            {translate('taskDefinitions.saveDraft')}
          </Button>
        </div>
      </Panel.Footer>
    </Panel>
  );
};

export default TaskDefinitionDetail;
