import React, { ReactText } from 'react';
import {
  Slide,
  ToastContainerProps,
  ToastContent,
  ToastOptions,
  TypeOptions,
  UpdateOptions,
  toast,
} from 'react-toastify';

import { DeleteIcon } from 'styleguide';
import { BrandColors } from 'styleguide-v2/src/styles/Colors';

import './main.less';

export const defaultToastOptions: ToastContainerProps = {
  autoClose: 5000,
  closeButton: <DeleteIcon tag="button" />,
  draggable: false,
  hideProgressBar: true,
  position: 'bottom-left',
  style: {},
  transition: Slide,
};

export type CustomTypeOptions =
  | TypeOptions
  | 'care-at-home'
  | 'vpc'
  | 'vpc-shared';

interface CustomToastOptions extends Omit<ToastOptions, 'type'> {
  title?: ToastContent;
  body?: ToastContent;
  type?: CustomTypeOptions;
}

const getClass = (
  type?: CustomTypeOptions,
): { autoClose?: false; type?: TypeOptions; className?: string } => {
  if (type === 'care-at-home') {
    return {
      autoClose: false,
      className: 'care-at-home',
    };
  }

  if (type === 'vpc' || type === 'vpc-shared') {
    return {
      autoClose: false,
      className: type,
    };
  }

  return { type };
};

export class Toast {
  id: ReactText;

  constructor({ type, ...options }: CustomToastOptions) {
    Toast.dismissAll();
    this.id = toast(renderToast(options), {
      ...getClass(type),
      ...options,
    });
  }

  update(options: UpdateOptions) {
    if (options.toastId) {
      this.id = options.toastId;
    }
    // TODO: if we want to update the render content, we'll need pass the options
    // through renderToast
    toast.update(this.id, options);
  }

  dismiss() {
    toast.dismiss(this.id);
  }

  static dismissAll() {
    toast.dismiss();
  }
}

const renderToast = (options: CustomToastOptions) => {
  const { title, body } = options;
  return (
    <>
      {title && <div className="toast-title h6">{title}</div>}
      {body && <div className="toast-body">{body}</div>}
    </>
  );
};

export default Toast;
