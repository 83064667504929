import enums from './es-enums';

export default {
  enums: {
    ...enums,
  },
  assessment: {
    allergies: {
      allergy: 'Alergia',
      other: '(ES) Other',
      title: 'Alergias',
    },
    diagnoses: {
      addDiagnosis: 'Add diagnosis',
      diagnosis: 'Diagnosis',
      keyDx: 'Key DX',
      onset: '(ES) Onset',
      status: 'Estado',
      title: '(ES) Diagnoses',
    },
    languagePicker: {
      placeholder: '(ES) Select or start typing a language',
      addLanguage: '(ES) Add language',
    },
    medicalHistory: {
      addAnotherSource: 'Añadir otra fuente',
      addDiagnosis: 'Añadir diagnóstico',
      addERVisit: 'Añadir visita de emergencia',
      addFall: 'Añadir caída',
      addHospitalization: 'Añadir hospitalización',
      addSource: 'Añadir fuente',
      addSurgery: 'Añadir operación',
      admitDate: 'Fecha de admisión',
      admitted: '(ES) Admitted',
      date: 'Fecha',
      datePlaceholder: 'Seleccione una fecha',
      description: 'Descripción',
      diagnoses: 'Diagnósticos',
      diagnosesEmpty: 'No diagnoses added.',
      diagnosis: 'Diagnóstico',
      diagnosisPlaceholder: 'Comience a escribir un diagnóstico',
      discharged: '(ES) Discharged',
      dischargeDate: 'Fecha de alta',
      editDiagnosis: 'Editar diagnóstico',
      editERVisit: 'Editar visita de emergencia',
      editFall: 'Editar caída',
      editHospitalization: 'Editar hospitalización',
      editSurgery: 'Editar operación',
      emergencyRoomVisits: 'Visitas de Emergencia',
      errors: {
        existingDiagnosis:
          'El diagnóstico ya ha sido añadido para esta misma fuente.',
      },
      erVisit: '(ES) ER Visit',
      facility: '(ES) Facility',
      fall: 'Caída',
      falls: 'Caídas',
      hospitalization: 'Hospitalización',
      hospitalizations: 'Hospitalizaciones',
      invalidated: '{{item}} invalidados',
      invalidatedBy: 'Invalidado por',
      invalidationSource: '(ES) Invalidation Source',
      majorEpisodes: '(ES) Major Episodes',
      medicalEquipment: '(ES) Durable Medical Equipment (add as many as apply)',
      notes: 'Notas',
      onsetDate: 'Fecha',
      otherReason: 'Otro motivo',
      otherReasonPlaceholder: 'Escriba otro motivo',
      outcome: '(ES) Outcome',
      primaryDiagnosisReason: '(ES) Primary Diagnosis/Reason',
      reason: 'Motivo',
      reasonPlaceholder: 'Seleccione un motivo',
      reasons: 'Motivos',
      source: 'Fuente',
      sourcePlaceholder: 'Seleccione una fuente',
      status: 'Estado',
      statusPlaceholder: 'Seleccione un estado',
      surgeries: 'Operaciones',
      type: 'Tipo',
    },
    medications: {
      add: 'Añadir medicación',
      addMedication: 'Añadir medicación',
      addTimes: 'Añadir mas tomas',
      amount: 'Cantidad',
      clickToAdd: 'Click para añadir',
      discontinued: '(ES) Discontinued',
      discontinueDate: '(ES) Discontinue date',
      discontinueFooterNote:
        '(ES) Discontinued medications will appear in Medication History',
      discontinueMedication: '(ES) Discontinue Medication',
      dosage: 'Dosis',
      edit: 'Editar medicación',
      empty: 'No se añadieron medicaciones.',
      for: '(ES) For',
      form: 'Forma',
      frequency: 'Frequencia',
      history: 'Histórico de medicaciones',
      medication: 'Medicación',
      medicationOrNdc: 'Medicación o Código NDC',
      medicationHistory: 'Medicación Anterior',
      medicationPlaceholder: 'Comience a escribir una medicación',
      noKnownMedications: 'Sin medicación conocida',
      relatedDiagnosis: '(ES) Related Diagnosis',
      relatedDiagnosisPlaceholder: '(ES) Start typing a related diagnosis',
      route: 'Ruta',
      routeFrequency: 'Ruta/Frecuencia',
      routePlaceholder: 'Seleccione ruta',
      source: 'Fuente',
      sourcePlaceholder: 'Seleccione fuente',
      specialInstructions: 'Instrucciones especiales',
      specialInstructionsPlaceholder:
        'Indique instrucciones especiales (Opcional)',
      startDate: 'Fecha de comienzo',
      startDatePlaceholder: 'Seleccione fecha',
      started: '(ES) Started',
      startedDateForDiagnosis: 'Comenzó el {{date}} para {{diagnosis}}',
      title: 'Medicaciones',
      unit: 'Unidad',
      withMeal: 'con comida',
    },
    riskAssessment: {
      riskProfile: '(ES) PHIP Profile',
    },
  },
  common: {
    moreInfo: '(ES) More Info',
    select: '(ES) Select',
  },
  contacts: {
    add: 'Añadir contacto',
    address: 'Dirección',
    clickToAdd: '(ES) Click to add a contact.',
    deleteContact: '(ES) Delete contact?',
    deleteContactBody: '(ES) This contact will be permanently removed.',
    edit: 'Editar contacto',
    languages: 'Language(s)',
    email: 'Email',
    empty: '(ES) No contacts added.',
    enterFaxNumber: 'Introduce un Fax',
    enterPhoneNumber: 'Introduce un teléfono',
    faxNumber: 'Fax',
    firstName: 'Nombre',
    lastName: 'Apellidos',
    phoneNumber: 'Número de teléfono',
    type: 'Tipo',
    yesDelete: '(ES) Yes, delete',
  },
  formBuilder: {
    addComment: '(ES) Add Comment',
    readOnly: '(ES) Read Only',
  },
  global: {
    add: 'Añadir',
    addNew: '(ES) Add new',
    addAnother: '(ES) Add another',
    addOther: '(ES) Add other',
    assessments: '(ES) Assessments',
    atTime: '(ES) at {{time}}',
    authenticatedUserMismatch: 'El usuario autenticado no coincide',
    authenticatedUserMismatchDetail:
      'La ventana se recargará para actualizar su información de usuario.',
    cancel: 'Cancelar',
    changesCannotBeSubmitted: '(ES) Changes cannot be submitted',
    chooseADate: '(ES) Choose a Date',
    chooseATime: '(ES) Choose a Time',
    clear: 'Limpiar',
    clearFilters: '(ES) Clear Filters',
    close: '(ES) Close',
    comment: '(ES) Comment',
    complete: '(ES) Complete',
    confirm: 'Confirmar',
    contactUs:
      '(ES) If this reoccurs, please contact us via Slack #product_analytics_requests or email eng@vestahealthcare.com so we can help.',
    continue: '(ES) Continue',
    date: 'Fecha',
    delete: 'Eliminar',
    duration: 'Duración',
    edit: '(ES) Edit',
    failed: '(ES) Failed',
    filter: '(ES) Filter',
    filtersApplied: '(ES) {{count}} filters applied',
    genericError: 'Hubo un error. Inténtalo de nuevo',
    invalidate: 'Invalidar',
    lastUpdated: 'Última actualización {{date}}',
    lastUpdatedBy: 'Última actualización {{date}} por {{editor}}',
    na: 'N/A',
    name: '(ES) Name',
    newUpdate: '(ES) A new update is available.',
    next: 'Siguiente',
    no: '(ES) No',
    none: 'Ninguno/a',
    noResults: '(ES) No Results',
    noResultsFound: 'No se encontraron resultados',
    note: 'Nota',
    notes: 'Notas',
    notesOptional: '(ES) Notes (optional)',
    notesRequired: '(ES) Notes (required)',
    offline: '(ES) You’re offline. Trying again in',
    offsite: 'Offsite',
    ok: 'OK',
    optional: 'Opcional',
    other: 'Otro',
    patientRequired: '(ES) Patient required',
    patients: 'Pacientes',
    pending: '(ES) Pending',
    pleaseSelectOne: '(ES) Please select one.',
    prev: 'Ant',
    preview: '(ES) Preview',
    previous: 'Anterior',
    reload: 'Recargar',
    refresh: '(ES) Refresh',
    refreshToSeeChanges:
      '(ES) "Refresh" to see those new updates. You can then edit to make any aditional changes.',
    required: 'Requerido',
    results: '(ES) Results',
    retry: '(ES) Retry',
    rn: '(ES) RN',
    save: 'Guardar',
    saveAndContinue: 'Guardar y continuar',
    seeAll: '(ES) See All',
    select: 'Seleccione',
    selectPatient: '(ES) Select patient',
    sent: '(ES) Sent',
    status: 'Estado',
    startTyping: 'Comience escribiendo',
    submit: '(ES) Submit',
    success: '(ES) Success!',
    to: 'a',
    type: 'Tipo',
    typeOther: 'Escriba otro',
    unknown: 'Desconocido',
    upload: 'Subir',
    weAreSorry: '(ES) We’re sorry, please try again.',
    yearsOld: '{{age}} años',
    yes: '(ES) Yes',
    youAreOffline: '(ES) You’re Offline',
  },
  home: {
    rn: {
      patientName: '(ES) Patient Name',
    },
  },
  interventions: {
    additionalInfoPlaceholder: 'Añadir detalles',
    completeIntervention: '(ES) Complete Intervention',
    interventionIsClosed: '(ES) This intervention is now closed',
    interventions: '(ES) Interventions',
    optionalNote: '(ES) Optional Note',
    wasInterventionDone: '(ES) Was the intervention done?',
    whyWasntTheInterventionDone: "(ES) Why wasn't the intervention done?",
    whyWasntTheInterventionDoneFully:
      "(ES) Why wasn't the intervention done fully?",
  },
  issueDetails: {
    translation: '(ES) Translation',
    originalText: '(ES) Original text',
  },
  personalDetails: {
    age: 'Edad',
    appVersion: 'App version',
    birthDate: 'Cumpleaños',
    careManager: 'CM',
    careUpdates: 'Actualizaciones',
    contact: 'Contacto',
    contacts: 'Contactos',
    emergencyContact: 'Contacto de emergencia',
    emptyContacts: 'Este paciente no tiene contactos.',
    externalId: 'ID Externa',
    gender: 'Género',
    healthDecisions: 'Decisiones de salud',
    homeAddress: 'Casa',
    joinedHT: '(ES) Joined HT',
    language: 'Idioma',
    other: 'Otro',
    partner: 'Partner',
    patientId: '(ES) Patient ID',
    patientID: '(ES) Patient ID',
    payer: 'Payer',
    phoneNumber: 'Teléfono',
    primaryContact: 'Contacto principal',
    rnOwner: '(ES) RN Owner',
    seeAll: 'Ver todas',
    startDate: '(ES) Start Date',
    startedReporting: '(ES) Started Reporting',
    status: 'Estado',
    title: 'Datos personales',
    languages: 'Idioma(s)',
    preferred: 'Preferido',
  },
  platform: {
    addSetupCode: '(ES) Add iPad ID',
    linkIpad: 'Vincular iPad',
    setupCode: 'iPad ID',
    typeFollowingCodeToUnlink:
      'Por favor introduce el siguiente código para desvincular el iPad:',
    typeIpadID: 'Introduce el iPad ID',
    unlinkIpad: 'Desvincular iPad',
  },
  search: {
    noPatientsFound: 'No se han encontrado pacientes',
    title: 'Buscar Pacientes',
    externalID: '(ES) (External ID {{id}})',
    dashID: '(ES) (Dash ID {{id}})',
  },
};
