import { Enum } from './Enum';

export class BiometricsTimeOption extends Enum {
  translationKey = 'biometricsTimeOption';

  static asArray: BiometricsTimeOption[] = [];

  static byKey: { [name: string]: BiometricsTimeOption } = {};

  static readonly BEFORE_MEAL = new BiometricsTimeOption('BEFORE_MEAL');

  static readonly AFTER_MEAL = new BiometricsTimeOption('AFTER_MEAL');

  constructor(public readonly value: string) {
    super();
    BiometricsTimeOption.asArray.push(this);
    BiometricsTimeOption.byKey[value] = this;
  }
}

export default BiometricsTimeOption;
