import React from 'react';
import { translate } from '@vestahealthcare/common/i18n';
import { Button, Modal } from 'styleguide';
import { startCase } from 'lodash';

type Message = {
  message: any;
  detail?: any;
  field?: string;
};

interface Props {
  open: boolean;
  title: any;
  messages: Message[];
  dismissText: string;
  onConfirm: () => void;
  onCancel?: () => void;
  onDismiss: () => void;
  type: string;
  hideContactUs?: boolean;
}

const MessageComponent = (props: Props) => {
  const {
    open = false,
    title = translate('global.genericError'),
    messages = [
      {
        message: translate('global.weAreSorry'),
      },
    ],
    dismissText = translate('global.ok'),
    onDismiss,
    onCancel,
    onConfirm,
    type,
    hideContactUs = false,
  } = props;

  const isWarning = !!onConfirm;

  return (
    <Modal
      color={type}
      show={open}
      onHide={() => {
        onDismiss();
        if (isWarning && onCancel) {
          onCancel();
        }
      }}
    >
      <Modal.Header
        closeButton
        modalTitle={
          <span>
            {(type === 'error' || type === 'warning') && (
              <i className="fa fa-warning" />
            )}
            {title}
          </span>
        }
      />
      <Modal.Body>
        {messages.map(({ message, field, detail }, i) => (
          <p className="large" key={i}>
            {field && `${startCase(field)}: `}
            {message}
            {detail && (
              <span>
                <br />
                <em>{detail}</em>
              </span>
            )}
          </p>
        ))}
        {(type === 'error' || type === 'warning') && !hideContactUs && (
          <p className="large">{translate('global.contactUs')}</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button color="primary" className="pull-right" onClick={onDismiss}>
          {dismissText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageComponent;
