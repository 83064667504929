import React, { useState } from 'react';

import { EncounterMethod } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { Task } from '@vestahealthcare/common/models';

import { AddIterationIcon, Button, Modal, Text } from 'styleguide';

import AddEncounterModal from './AddEncounterModal';

interface Props {
  method?: EncounterMethod;
  patientId: number | string;
  onSubmit?: () => void;
  task?: Task;
}
export const AddEncounterButton = (props: Props) => {
  const { method, task, onSubmit } = props;
  const [showEncounterModal, setShowEncounterModal] = useState(false);
  const [showEncounterSuccessModal, setShowEncounterSuccessModal] = useState(
    false,
  );

  return (
    <>
      <Button
        data-cy="add-encounter-button"
        color="secondary"
        isPill
        onClick={() => setShowEncounterModal(true)}
        style={{ whiteSpace: 'nowrap' }}
      >
        <AddIterationIcon marginRight />
        {translate('encounters.addEncounter')}
      </Button>
      <AddEncounterModal
        avoidRequiredMinutes
        method={method}
        task={task}
        patient={+props.patientId}
        onClose={() => setShowEncounterModal(false)}
        onSubmit={() => {
          setShowEncounterModal(false);
          setShowEncounterSuccessModal(true);
          if (onSubmit) onSubmit();
        }}
        open={showEncounterModal}
      />
      {showEncounterSuccessModal && (
        <Modal onHide={() => setShowEncounterSuccessModal(false)} show>
          <Modal.Header
            closeButton
            modalTitle={translate('encounters.addEncounter')}
          />
          <Modal.Body>
            <Text>{translate('encounters.addSuccess')}</Text>
          </Modal.Body>
          <Modal.Footer>
            <div className="right-side">
              <Button
                data-cy="encounter-success"
                color="primary"
                onClick={() => setShowEncounterSuccessModal(false)}
              >
                {translate('global.close')}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
