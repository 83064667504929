import React, { useEffect, useState } from 'react';

import { SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { Organization } from '@vestahealthcare/common/models';

import { Select } from 'styleguide-v2';
import { Props as SelectProps } from 'styleguide-v2/src/components/Select/Props';

type Props = Omit<SelectProps, 'items' | 'onChange'> & {
  items: SelectableInfo<Organization>[];
  onChange: (items: SelectableInfo<Organization>[]) => void;
};

export const ReferralDropdown = ({
  getItemDisabled,
  items,
  value,
  onChange,
  ...props
}: Props) => {
  const [currentValue, setCurrentValue] = useState<
    SelectableInfo<Organization>[]
  >([]);

  useEffect(() => setCurrentValue(value), [value]);

  useEffect(() => {
    if (currentValue?.length > 1) {
      const lastAdded = currentValue[currentValue.length - 1];
      const newOrganizations = currentValue.filter(
        (child) => !lastAdded?.info?.hasDescendant(child?.value as number),
      );
      if (newOrganizations.length < currentValue.length) {
        setCurrentValue(newOrganizations);
      }
    }
  }, [value]);

  return (
    <Select
      data-cy="events-filter-referral-sources"
      getItemDisabled={(child) =>
        (getItemDisabled && getItemDisabled(child)) ||
        !!(currentValue as SelectableInfo<Organization>[])?.reduce(
          (acc, item) => acc || !!item?.info?.hasDescendant(child.value),
          false,
        )
      }
      items={items}
      onChange={(values: SelectableInfo<Organization>[]) => {
        setCurrentValue(values);
        onChange(values);
      }}
      value={currentValue}
      {...props}
    />
  );
};
