import React, { Component } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { getPatientDevice } from 'dash/src/services/PatientServices';

interface Props {
  patient: Patient;
}

interface State {
  setupCode: string;
}

export default class IPadIDSetup extends Component<Props, State> {
  state: State = {
    setupCode: '',
  };

  componentDidMount() {
    this.fetchDevice();
  }

  fetchDevice = async () => {
    const device = await getPatientDevice(this.props.patient.id);
    this.setState({ setupCode: device && device.setupCode });
  };

  render() {
    const { setupCode } = this.state;

    return (
      <>
        <h4 className="grid-span-5">{translate('platform.setupCode')}</h4>
        <p className="grid-span-6">{setupCode || EMPTY}</p>
      </>
    );
  }
}
