import React, { useState } from 'react';

import { PatientLivingArrangement } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Select, TextInput } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { updatePatientLivingArrangement } from 'dash/src/services/PatientServices';

import { BaseModal } from './BaseModal';

interface Props {
  patient: Patient;
  onSubmit: (patient: Patient) => void;
}

const renderDetails = (patient: Patient) => (
  <>
    <h4 className="grid-span-5">{translate('personalDetails.livesWith')}</h4>
    <p className="grid-span-6">
      {(patient.livingArrangement &&
        patient.livingArrangement
          .map((v) => {
            if (v === PatientLivingArrangement.OTHER) {
              return `${v.toString()} - ${patient.livingArrangementOther}`;
            }
            return v.toString();
          })
          .join(', ')) ||
        EMPTY}
    </p>
  </>
);

export const LivingArrangement = React.memo((props: Props) => {
  const { patient, onSubmit } = props;
  const editable = patient.isEditable();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [livingArrangement, setLivingArrangement] = useState(
    patient.livingArrangement,
  );
  const [livingArrangementOther, setLivingArrangementOther] = useState(
    patient.livingArrangementOther,
  );

  const onCancel = () => {
    setLivingArrangement(patient.livingArrangement);
    setLivingArrangementOther(patient.livingArrangementOther);
    setSubmitted(false);
  };

  const submit = async () => {
    let result = true;

    setSubmitted(true);
    if (!isValid()) {
      return false;
    }
    setLoading(true);

    try {
      const newPatient = await updatePatientLivingArrangement(
        patient.id,
        livingArrangement,
        livingArrangementOther,
      );
      onSubmit(newPatient);
      setSubmitted(false);
    } catch (e) {
      showGlobalError(e as string);
      result = false;
    }
    setLoading(false);
    return result;
  };

  const isValid = () =>
    livingArrangement.length &&
    (!livingArrangement.find(
      (value) => value === PatientLivingArrangement.OTHER,
    ) ||
      livingArrangementOther);

  return (
    <BaseModal
      details={renderDetails(patient)}
      data-cy="patient-living-arrangement"
      editable={editable}
      loadingSubmit={loading}
      onCancel={onCancel}
      onSubmit={submit}
      title={translate('personalDetails.livesWith')}
    >
      <Select
        error={submitted && !livingArrangement?.length}
        label={translate('personalDetails.whoDoesTheClientLiveWith')}
        multiple
        onChange={(val?: Selectable[]) => {
          setLivingArrangement(
            val?.map(({ value }) => PatientLivingArrangement.byKey[value]) ||
              [],
          );
        }}
        items={PatientLivingArrangement.toSelectable(
          PatientLivingArrangement.asArray,
        )}
        required
        value={PatientLivingArrangement.toSelectable(livingArrangement || [])}
      />
      {livingArrangement.find(
        (value) => value === PatientLivingArrangement.OTHER,
      ) && (
        <>
          <br />
          <TextInput
            error={!livingArrangementOther && submitted}
            onChange={setLivingArrangementOther}
            placeholder={translate('personalDetails.addComment')}
            required
            value={livingArrangementOther}
          />
        </>
      )}
    </BaseModal>
  );
});
