import React, { useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';

import { translate } from '@vestahealthcare/common/i18n';

interface Props {
  className?: string;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  'data-cy'?: string;
  text?: string | boolean;
  width?: number;
}

export const Spinner = ({
  className,
  color = 'inherit',
  'data-cy': dataCy,
  text,
  width = 28,
}: Props) => {
  if (text) {
    const [dots, setDots] = useState<string>('');
    let number = 0;
    useEffect(() => {
      const interval = setInterval(() => {
        // eslint-disable-next-line no-plusplus
        setDots(() => new Array((number++ % 3) + 1).fill('.').join(''));
      }, 250);

      return () => clearInterval(interval);
    }, []);
    return (
      <span>
        {typeof text === 'string' ? text : translate('components.loading')}
        {dots}
      </span>
    );
  }

  return (
    <CircularProgress
      className={className}
      data-cy={dataCy || 'loading'}
      color={color}
      size={width}
      sx={{ display: 'flex', zIndex: 5 }}
    />
  );
};

export default Spinner;
