import classNames from 'classnames';
import React, { Fragment } from 'react';

import EventNoteIcon from '@mui/icons-material/EventNote';
import StarIcon from '@mui/icons-material/Star';
import { ClassNameMap, makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { LanguagePreference, UserPhone } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Chip, PhoneLink, Table, Tooltip } from 'styleguide-v2';

type ContactsInfoData = {
  fullName?: string;
  languages?: LanguagePreference[];
  phi?: boolean;
  phones?: UserPhone[];
  preferred?: boolean;
  subtype?: string;
  notes?: string;
};

type Props = {
  className?: string;
  data: ContactsInfoData[];
  label: string;
};

const RECENT_EVENTS_COLUMNS = (
  headerName: string,
  styles: ClassNameMap<'firstParragraph' | 'fullName' | 'preferred'>,
) => [
  {
    headerName,
    component: ({
      fullName,
      subtype,
      phi,
      languages,
      preferred,
      notes,
    }: ContactsInfoData) => (
      <>
        <p
          className={classNames(
            styles.firstParragraph,
            'no-margin flex middle wrap min-gap',
          )}
        >
          {preferred && (
            <>
              <Tooltip text="Preferred">
                <StarIcon className={styles.preferred} color="primary" />
              </Tooltip>
            </>
          )}
          <span className={classNames('bold', styles.fullName)}>
            {fullName}
          </span>
          {phi === true && (
            <Chip color="secondary" type="outlined" size="small">
              {`${translate('ccm.modals.info.phi')}`}{' '}
              <i className="fa fa-check" />
            </Chip>
          )}
          {phi === false && (
            <Chip color="error" type="outlined" size="small">
              {`${translate('ccm.modals.info.phi')}`}{' '}
              <i className="fa fa-close" />
            </Chip>
          )}
          {notes && (
            <Tooltip text={notes}>
              <EventNoteIcon color="primary" />
            </Tooltip>
          )}
          {languages
            ?.filter(({ language }) => !!language)
            ?.map(({ preferred, language }) => (
              <Chip
                color="info"
                key={`ccm-member-${fullName}-language-${language.value}`}
                type={preferred ? 'contained' : 'outlined'}
                size="small"
              >
                {language.toString()}
              </Chip>
            ))}
        </p>
        <p className="gray no-margin semi-bold size-xs">{subtype}</p>
      </>
    ),
    width: 200,
  },
  {
    headerName: ' ',
    component: ({ phones }: ContactsInfoData) =>
      phones?.map(
        ({ phone, primary }) =>
          (
            <PhoneLink
              compact
              key={`ccm-number${phone.number}`}
              phone={phone.number}
              primary={primary && phones.length > 1}
              type={phone.type?.toString()}
            />
          ) || EMPTY,
      ),
    width: 175,
  },
];

const useStyles = makeStyles({
  table: {
    marginTop: 1,
  },
  tableContainer: {
    display: 'flex',
    gap: '1rem',
    marginBottom: '1rem',
  },
  firstParragraph: {
    '&&': {
      margin: '2px 0 0',
    },
  },
  fullName: {
    marginRight: '0.5rem',
  },
  preferred: {
    marginTop: '-2px',
  },
});

export const ContactsInfoTable = (props: Props) => {
  const styles = useStyles();

  return (
    <div className={props.className}>
      <div className={styles.tableContainer}>
        <Table
          className={styles.table}
          config={{
            columns: RECENT_EVENTS_COLUMNS(props.label, styles),
            compact: true,
            data: props.data,
            paginationOptions: [-1],
          }}
          whiteBackground
        />
      </div>
    </div>
  );
};
