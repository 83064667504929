import classnames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { EventModel } from '@vestahealthcare/common/models';

import {
  Button,
  CheckboxWithLabel,
  CollapsableSidebar,
  DateTimePicker,
} from 'styleguide-v2';

import { UpdateEventParams } from 'dash/src/services/EventServices';

interface Props {
  event?: EventModel;
  open: boolean;
  onCancel: () => void;
  onSubmit: (id: number, params: UpdateEventParams) => Promise<boolean>;
  requireFieldsToClose?: boolean;
}

const useStyles = makeStyles({
  unknownTime: {
    alignItems: 'flex-end',
    position: 'relative',
    '& label': {
      margin: 0,
      '& > span:first-child': {
        padding: '0.5rem',
      },
    },
  },
  unknownTimeRequired: {
    top: '-2.25rem',
  },
});

export const MemberEventFollowUpInfo = ({
  event,
  open,
  onCancel,
  onSubmit,
  requireFieldsToClose,
}: Props) => {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [followUpDate, setFollowUpDate] = useState<Date>();
  const [followUpTime, setFollowUpTime] = useState<Date>();
  const [isTimeUnknown, setUnkownTime] = useState<boolean>(false);

  const reset = () => {
    setFollowUpDate(event?.followUpDatetime?.toDate());

    setFollowUpTime(
      (event?.followUpDatetimeHasTime &&
        event?.followUpDatetime?.isAfter() &&
        event?.followUpDatetime?.toDate()) ||
        undefined,
    );
    setUnkownTime(event?.followUpDatetimeHasTime === false);
  };

  useEffect(() => {
    reset();
  }, [event]);

  useEffect(() => {
    if (open) {
      setSubmitted(false);
      setLoading(false);
      reset();
    }
  }, [open]);

  const doSubmit = async () => {
    setSubmitted(true);

    if (event && followUpDate && (followUpTime || isTimeUnknown)) {
      setLoading(true);

      const followUpDatetime = moment(followUpDate.getTime());
      if (isTimeUnknown) {
        followUpDatetime.endOf('day');
      } else {
        followUpDatetime
          .hours(followUpTime?.getHours() || 0)
          .minutes(followUpTime?.getMinutes() || 0)
          .seconds(0);
      }

      try {
        await onSubmit(event.id, {
          followUpDatetime: followUpDatetime.format(),
          followUpDatetimeHasTime: !isTimeUnknown,
        });
      } finally {
        setSubmitted(false);
        setLoading(false);
      }
    }
  };

  return (
    <CollapsableSidebar
      onClose={onCancel}
      open={open}
      title={
        <h2>
          {translate(
            `memberEvents.${
              event?.followUpDatetime ? 'edit' : 'add'
            }FollowUpModalTitle`,
          )}
        </h2>
      }
      size={550}
    >
      <CollapsableSidebar.Body>
        <div className="grid-wrapper fit no-vertical-gap">
          <DateTimePicker
            className="grid-span-6"
            error={submitted && !followUpDate}
            label={translate('memberEvents.followUpDate')}
            minDate={new Date()}
            onChange={setFollowUpDate}
            required={requireFieldsToClose}
            type="date"
            value={followUpDate}
          />
          <DateTimePicker
            className="grid-span-6"
            disabled={isTimeUnknown}
            error={submitted && !isTimeUnknown && !followUpTime}
            label={translate('memberEvents.followUpTime')}
            minTime={
              followUpDate &&
              moment(followUpDate.getTime()).isSame(moment(), 'day')
                ? new Date()
                : undefined
            }
            onChange={setFollowUpTime}
            required={requireFieldsToClose && !isTimeUnknown}
            type="time"
            value={followUpTime}
          />
          <CheckboxWithLabel
            className={classnames(
              'grid-span-12',
              styles.unknownTime,
              submitted &&
                !isTimeUnknown &&
                !followUpTime &&
                styles.unknownTimeRequired,
            )}
            label={translate('memberEvents.unknownTime')}
            checked={isTimeUnknown}
            onChange={(val) => setUnkownTime(!!val)}
          />
          <br />
        </div>
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="edit-follop-up-info-close"
          onClick={onCancel}
        >
          {translate('global.cancel')}
        </Button>
        <Button
          color="secondary"
          data-cy="edit-follop-up-info-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default MemberEventFollowUpInfo;
