import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { CareTeamMemberProvider } from '@vestahealthcare/common/models';

import { TextArea, TextInput } from 'styleguide-v2';

import { CreateCareTeamMemberProviderParams } from 'dash/src/services/CareTeamServices';

type Props = {
  member: CareTeamMemberProvider;
  onChange: (params: Partial<CreateCareTeamMemberProviderParams>) => void;
};

export const ProviderFields = ({ member, onChange }: Props) => {
  const [providerPracticeName, setProviderPracticeName] = useState<string>();
  const [providerNotes, setProviderNotes] = useState<string>();

  useEffect(() => {
    setProviderPracticeName(member?.practiceName);
    setProviderNotes(member?.providerNotes);
  }, [member]);

  const handleChange = (changes: Partial<any>) => {
    onChange(changes);
  };

  return (
    <div className="grid-wrapper fit">
      <h2 className="grid-span-12" style={{ fontSize: '1em' }}>
        {translate('careTeam.modals.edit.providerFields')}
      </h2>

      <TextInput
        className="grid-span-12"
        data-cy="care-team-member-providerPracticeName"
        label={translate('careTeam.common.providerPracticeName')}
        onChange={(practiceName?: string) => {
          setProviderPracticeName(practiceName);
          handleChange({ practiceName });
        }}
        value={providerPracticeName}
      />

      <TextArea
        className="grid-span-12"
        data-cy="care-team-member-providerNotes"
        label={translate('careTeam.common.providerNotes')}
        minRows={2}
        onChange={(providerNotes?: string) => {
          setProviderNotes(providerNotes);
          handleChange({ providerNotes });
        }}
        value={providerNotes}
      />
    </div>
  );
};
