import moment from '../moment';
import { DATE_TIME_FORMAT } from '../utils/constants';
import { Employee } from './Employee';
import { make } from './Model';

export class ObservationAnswers {
  id = 0;

  updatedAt = 0;

  updatedBy?: Employee;

  version: number = 0;

  answers: { [key: string]: any } = {};

  type = '';

  constructor(obj: any) {
    Object.assign(this, obj);
    this.updatedBy = make(obj.updatedBy, Employee);
  }

  getUpdatedAtFormatted() {
    return moment.unix(this.updatedAt).format(DATE_TIME_FORMAT);
  }
}

export default ObservationAnswers;
