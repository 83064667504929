import Api from 'dash/src/services/Api';
import { Notification } from '@vestahealthcare/common/models';
import { PaginatedResponse } from '.';

interface NotificationParams {
  seen?: boolean;
  offset?: number;
  limit?: number;
  sort?: string;
}

export const fetchNotifications = async (
  params: NotificationParams,
): Promise<PaginatedResponse<Notification>> => {
  const {
    notifications: { items, pagination },
  } = await Api.getv2('notifications', params);
  return { items: items.map((i: any) => new Notification(i)), pagination };
};

export const updateNotification = async (
  notificationId: number | string,
  seen: boolean,
) => {
  const res = await Api.patchv2JSON(`notifications/${notificationId}`, {
    seen,
  });
  return new Notification(res.notification);
};

export const readAllNotifications = async () => {
  const response = await Api.postv2('notifications/actions/seen-all');
  return response;
};
