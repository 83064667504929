import { make } from './Model';
import { PodReportingSummary } from './PodReportingSummary';

export class PodReportingGroup {
  summary: PodReportingSummary;

  worklistId = 0;

  worklistName = '';

  constructor(obj: any) {
    this.summary = make(obj.summary, PodReportingSummary);
    Object.assign(this, obj);
  }
}

export default PodReportingGroup;
