/* eslint-disable consistent-return */
import { Enum } from './Enum';
import { CareTrackType } from './CareTrackType';

export class ObservationCategory extends Enum {
  translationKey = 'observationCategory';

  static asArray: ObservationCategory[] = [];

  static byKey: { [name: string]: ObservationCategory } = {};

  static CARDIOPULMONARY = new ObservationCategory('cardiopulmonary');

  static DIABETES = new ObservationCategory('diabetes');

  static SKIN = new ObservationCategory('skin');

  static FALL = new ObservationCategory('fall');

  static UTI_DEHYDRATION = new ObservationCategory('uti-dehydration');

  static GASTROINTESTINAL = new ObservationCategory('gastrointestinal');

  static PALLIATIVE = new ObservationCategory('palliative');

  static SOCIAL = new ObservationCategory('social');

  constructor(public readonly value: string) {
    super();
    ObservationCategory.asArray.push(this);
    ObservationCategory.byKey[value] = this;
  }

  // eslint-disable-next-line getter-return
  get linkedCareTrack() {
    switch (this) {
      case ObservationCategory.CARDIOPULMONARY:
        return CareTrackType.CHF_EXACERBATION;
      case ObservationCategory.DIABETES:
        return CareTrackType.DIABETES_TYPE_II;
      case ObservationCategory.SKIN:
        return CareTrackType.PRESSURE_ULCER;
      case ObservationCategory.FALL:
        return CareTrackType.FALL;
      case ObservationCategory.UTI_DEHYDRATION:
        return CareTrackType.UTI_DEHYDRATION;
      case ObservationCategory.GASTROINTESTINAL:
        return CareTrackType.GI_COMPLICATION;
      case ObservationCategory.PALLIATIVE:
        return CareTrackType.GENERAL;
      default:
    }
  }
}

export default ObservationCategory;
