import { EmployeeGroupStatus, EmployeeGroupType } from '../enums';
import { SelectableInfo } from '../enums/Enum';
import { Employee } from './Employee';
import { make } from './Model';

export class EmployeeGroup {
  id = 0;

  name = '';

  status: EmployeeGroupStatus;

  type: EmployeeGroupType;

  createdAt = 0;

  createdBy: Employee;

  updatedAt = 0;

  updatedBy: Employee;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdBy = make(obj.createdBy, Employee);
    this.status = EmployeeGroupStatus.byKey[obj.status];
    this.type = EmployeeGroupType.byKey[obj.type];
    this.updatedBy = make(obj.updatedBy, Employee);
  }

  static ADMIN = 1;

  static CLINICAL_ASSISTANTS = 3;

  static RN_OWNER = 10;

  static RNS = 6;

  static NP_ONWER = 11;

  static NPS = 7;

  static ENGAGEMENT = 14;

  static ELIGIBILLITY = 15;

  static TRIAGE = 48;

  static BILLING = 81;

  static ENROLLMENT = 83;

  static BIZ_OPS = 85;

  static CLINICAL_LEADERSHIP = 86;

  static HEALTH_COACH = 119;

  static ELIGIBILLITY_MANAGEMENT = 383;

  static INCIDENTS_MANAGEMENT = 540;

  static LICENSURE_ADMINISTRATION = 592;

  static CLINICAL_ACCOUNTS = 684;

  static TOC = 725;

  static ROSTER_INGESTION = 737;

  get active() {
    return this.status === EmployeeGroupStatus.ACTIVE;
  }

  isCareCoordinator() {
    return this.id === EmployeeGroup.CLINICAL_ASSISTANTS;
  }

  isEngagement() {
    return this.id === EmployeeGroup.ENGAGEMENT;
  }

  isHealthCoach() {
    return this.id === EmployeeGroup.HEALTH_COACH;
  }

  isRNOwner() {
    return [EmployeeGroup.RN_OWNER, EmployeeGroup.RNS].includes(this.id);
  }

  isNPOwner() {
    return [EmployeeGroup.NP_ONWER, EmployeeGroup.NPS].includes(this.id);
  }

  static toSelectable(
    groups: EmployeeGroup[],
  ): SelectableInfo<EmployeeGroup>[] {
    return groups.map((g) => ({
      value: g.id,
      label: g.name,
      info: g,
    }));
  }
}

export default EmployeeGroup;
