import React, { useState } from 'react';

import { Card, CardContent } from '@mui/material';

import { translate } from '@vestahealthcare/common/i18n';

import { Button, CollapsableSidebar, Modal, Panel } from 'styleguide-v2';

const StyleguideButtons = () => {
  const [isOpenCollapsableSidebar, setIsOpenCollapsableSidebar] = useState(
    false,
  );
  const [
    isOpenCollapsableSidebarLarge,
    setIsOpenCollapsableSidebarLarge,
  ] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalSteps, setIsOpenModalSteps] = useState(false);
  return (
    <Panel>
      <Panel.Heading title="Modals" />
      <Panel.Body className="grid-wrapper">
        <Card
          className="grid-span-6"
          sx={{ padding: '2rem', height: 'fit-content' }}
        >
          <h4 className="grid-span-12">CollapsableSidebar</h4>
          <CardContent>
            <Button
              type="outlined"
              onClick={() => setIsOpenCollapsableSidebar(true)}
            >
              Normal
            </Button>{' '}
            <Button
              type="outlined"
              onClick={() => setIsOpenCollapsableSidebarLarge(true)}
            >
              Large
            </Button>
            <CollapsableSidebar
              title="Normal"
              open={isOpenCollapsableSidebar}
              onClose={() => setIsOpenCollapsableSidebar(false)}
            >
              <CollapsableSidebar.Body>
                <div>Hello World</div>
                <span>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Similique, commodi corrupti modi ratione illum laborum in,
                  sapiente optio eos neque rerum ex incidunt minus mollitia!
                  Amet sed maxime vel rem?
                </span>
                <span>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Similique, commodi corrupti modi ratione illum laborum in,
                  sapiente optio eos neque rerum ex incidunt minus mollitia!
                  Amet sed maxime vel rem?
                </span>
              </CollapsableSidebar.Body>
              <CollapsableSidebar.Buttons>
                <Button
                  color="tertiary"
                  onClick={() => setIsOpenCollapsableSidebar(false)}
                >
                  {translate('global.cancel')}
                </Button>
                <Button
                  color="secondary"
                  data-cy="submit-consent-answer"
                  onClick={() => setIsOpenCollapsableSidebar(false)}
                >
                  {translate('global.save')}
                </Button>
              </CollapsableSidebar.Buttons>
            </CollapsableSidebar>
            <CollapsableSidebar
              title="Large"
              open={isOpenCollapsableSidebarLarge}
              onClose={() => setIsOpenCollapsableSidebarLarge(false)}
            >
              <CollapsableSidebar.Body>
                <div>Hello World</div>
                <span>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Similique, commodi corrupti modi ratione illum laborum in,
                  sapiente optio eos neque rerum ex incidunt minus mollitia!
                  Amet sed maxime vel rem?
                </span>
                <span>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Adipisci, enim exercitationem. Amet maiores quasi voluptatem
                  quidem rerum sint repellendus a hic nulla ab. Possimus ratione
                  repellat autem, quidem voluptatum architecto.
                </span>
                <span>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Laboriosam animi, ipsa explicabo recusandae quis suscipit,
                  enim dolorem eligendi qui doloremque modi pariatur, eveniet a
                  atque odio. Repellendus explicabo sint ipsum.
                </span>
                <span>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Nostrum doloremque a vitae repudiandae? Porro, distinctio
                  ratione cumque inventore maiores enim atque quod libero.
                  Similique voluptas voluptatum explicabo optio inventore
                  doloribus!
                </span>
                <span>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Fugit quasi molestias numquam unde assumenda debitis dolores
                  repellendus, natus suscipit. Ab aliquid officia rem quae
                  omnis, ipsum numquam consectetur iure ullam?
                </span>
                <span>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Voluptatum facilis iure ea ratione, maxime aspernatur pariatur
                  a labore architecto vel enim facere culpa ipsa beatae
                  veritatis sunt. Natus, recusandae dignissimos.
                </span>
                <span>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Consequatur accusantium ducimus nihil impedit laboriosam,
                  maiores totam dicta repellat repudiandae cum asperiores
                  voluptas obcaecati expedita animi omnis officiis! Mollitia,
                  inventore temporibus.
                </span>
                <span>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Earum, facere quisquam ducimus rerum nemo repellendus itaque
                  odit neque magnam. Laudantium fugiat odio quod est placeat!
                  Recusandae sunt autem doloremque possimus.
                </span>
                <span>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Modi
                  odit laboriosam non? Rem magni iste obcaecati necessitatibus
                  voluptas eius accusantium aliquid natus dolorem ea sequi
                  laudantium fugit, fugiat doloribus ipsa!
                </span>
                <span>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Blanditiis sapiente tenetur culpa beatae in ipsum. Vero
                  blanditiis facere nobis totam? Rerum architecto impedit quasi
                  quidem odio sed molestias tempora qui.
                </span>
              </CollapsableSidebar.Body>
              <CollapsableSidebar.Buttons>
                <Button
                  color="tertiary"
                  onClick={() => setIsOpenCollapsableSidebarLarge(false)}
                >
                  {translate('global.cancel')}
                </Button>
                <Button
                  color="secondary"
                  data-cy="submit-consent-answer"
                  onClick={() => setIsOpenCollapsableSidebarLarge(false)}
                >
                  {translate('global.save')}
                </Button>
              </CollapsableSidebar.Buttons>
            </CollapsableSidebar>
          </CardContent>
        </Card>
        <Card
          className="grid-span-6"
          sx={{ padding: '2rem', height: 'fit-content' }}
        >
          <h4 className="grid-span-12">Modal</h4>
          <CardContent>
            <Button type="outlined" onClick={() => setIsOpenModal(true)}>
              Normal
            </Button>{' '}
            <Button type="outlined" onClick={() => setIsOpenModalSteps(true)}>
              Steps
            </Button>
            <Modal
              title="Normal"
              open={isOpenModal}
              onClose={() => setIsOpenModal(false)}
              onSubmit={async () =>
                new Promise((resolve: (val: unknown) => void) => {
                  setTimeout(() => resolve(true), 1000);
                })
              }
            >
              <p>Hello World</p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Similique, commodi corrupti modi ratione illum laborum in,
                sapiente optio eos neque rerum ex incidunt minus mollitia! Amet
                sed maxime vel rem?
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Similique, commodi corrupti modi ratione illum laborum in,
                sapiente optio eos neque rerum ex incidunt minus mollitia! Amet
                sed maxime vel rem?
              </p>
            </Modal>
            <Modal
              title="Steps"
              open={isOpenModalSteps}
              onClose={() => setIsOpenModalSteps(false)}
              onSubmit={async (step) => {
                if (step === 1) {
                  return false;
                }
                if (step === 2) {
                  setIsOpenModalSteps(false);
                }

                return true;
              }}
              steps={[
                {
                  icon: true,
                  label: 'Initial Step',
                  detail: (
                    <>
                      <p>Step 1</p>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Similique, commodi corrupti modi ratione illum laborum
                        in, sapiente optio eos neque rerum ex incidunt minus
                        mollitia! Amet sed maxime vel rem?
                      </p>
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Voluptates veniam assumenda molestiae odit,
                        molestias sed distinctio voluptas blanditiis ea
                        excepturi repellat corrupti sapiente, cumque id
                        consectetur quae deleniti ad debitis!
                      </p>
                    </>
                  ),
                },
                {
                  icon: true,
                  label: 'Second Step',
                  detail: (
                    <>
                      <p>Step 2</p>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dignissimos expedita vitae nostrum voluptas facilis
                        officiis ratione magnam laboriosam itaque. Optio libero
                        iure totam, sunt cum in magnam ex ipsa error?
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Temporibus quibusdam qui commodi iste velit! Assumenda
                        perferendis excepturi tempore voluptate ipsam laboriosam
                        veniam hic, voluptatum iste nemo maxime blanditiis, nam
                        minus.
                      </p>
                    </>
                  ),
                },
                {
                  icon: true,
                  label: 'Final Step',
                  disabled: true,
                  detail: (
                    <>
                      <p>Step 2</p>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dignissimos expedita vitae nostrum voluptas facilis
                        officiis ratione magnam laboriosam itaque. Optio libero
                        iure totam, sunt cum in magnam ex ipsa error?
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Temporibus quibusdam qui commodi iste velit! Assumenda
                        perferendis excepturi tempore voluptate ipsam laboriosam
                        veniam hic, voluptatum iste nemo maxime blanditiis, nam
                        minus.
                      </p>
                    </>
                  ),
                },
              ]}
            />
          </CardContent>
        </Card>
      </Panel.Body>
    </Panel>
  );
};

export default StyleguideButtons;
