import { AudienceTarget } from '../enums';
import { CampaignBanner, CampaignModal, Organization } from '.';
import { makeArray } from './Model';

export class CampaignAudience {
  id = 0;

  name = '';

  targets: AudienceTarget[] = [];

  modals: CampaignModal[] = [];

  banners: CampaignBanner[] = [];

  organizations: Organization[] = [];

  active = false;

  programs: number[] = [];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.targets = obj.targets
      ? obj.targets.map((target: string) => AudienceTarget.byKey[target])
      : [];
    this.modals = makeArray(obj.modals, CampaignModal);
    this.banners = makeArray(obj.banners, CampaignBanner);
    this.organizations = makeArray(obj.organizations, Organization);
  }
}

export default CampaignAudience;
