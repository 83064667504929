import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { BaseEnum, SkilledItem, TOCItem } from '@vestahealthcare/common/models';

import { FilterItem, Panel, Select, ToggleButton } from 'styleguide-v2';
import { SortOption } from 'styleguide-v2/src/components/Panel';

import {
  TOC_TOGGLE_ITEMS,
  TOC_TOGGLE_ITEM_TOC,
} from 'dash/src/pages/Clinical/TOC/types';
import { useSelector } from 'dash/src/redux/store';
import { CacheServices } from 'dash/src/services';
import {
  GetTOCParams,
  fetchSkilledList,
  fetchTOCList,
} from 'dash/src/services/TOCServices';
import { getFilterItem, getServerFilters } from 'dash/src/utils/filterUtils';

import TOCMemberTable from './TOCMemberTable';

type KeyOfTOCParams = keyof GetTOCParams;

const SORT_OPTIONS: (toggle: string) => SortOption[] = (toggle: string) =>
  toggle === TOC_TOGGLE_ITEM_TOC
    ? [
        {
          label: translate('toc.sort.newestDate', {
            date: translate('toc.sort.status'),
          }),
          value: 'status-updated-newest',
          sort: 'statusUpdatedAt desc',
        },
        {
          label: translate('toc.sort.oldestDate', {
            date: translate('toc.sort.status'),
          }),
          value: 'status-updated-oldest',
          sort: 'statusUpdatedAt desc',
        },
      ]
    : [
        {
          label: translate('toc.sort.episodeStartNewest'),
          value: 'episode-start-newest',
          sort: 'episodeStartDate desc',
        },
        {
          label: translate('toc.sort.episodeStartOldest'),
          value: 'episode-start-oldest',
          sort: 'episodeStartDate asc',
        },
        {
          label: translate('toc.sort.episodeEndNewest'),
          value: 'episode-end-newest',
          sort: 'episodeEndDate desc',
        },
        {
          label: translate('toc.sort.episodeEndOldest'),
          value: 'episode-end-oldest',
          sort: 'episodeEndDate asc',
        },
      ];

const useStyles = makeStyles({
  toggle: {
    marginLeft: '2rem',
    '& > div': {
      height: '3rem',

      '& > button': {
        minWidth: '5rem',
      },
    },
  },
});

export const TOCDashboard = () => {
  const history = useHistory();
  const styles = useStyles();

  const member = useSelector((state) => state.memberInfoSlice.patient);

  const [filters, setFilters] = useState<
    { [x in KeyOfTOCParams]?: FilterItem }
  >({});

  const [loading, setLoading] = useState<boolean>();
  const [loadingFilters, setLoadingFilters] = useState<boolean>();
  const [sort, setSort] = useState<SortOption>();

  const [selectedToggle, setSelectedToggle] = useState<string>(
    TOC_TOGGLE_ITEM_TOC,
  );

  const [acuityLevels, setAcuityLevels] = useState<Selectable[]>([]);
  const [statuses, setStatuses] = useState<Selectable[]>([]);

  const [items, setItems] = useState<TOCItem[] | SkilledItem[]>([]);

  const sortOptions = useMemo(() => SORT_OPTIONS(selectedToggle), [
    selectedToggle,
  ]);

  const getInitialData = async () => {
    setLoadingFilters(true);

    const [a, s] = await Promise.all([
      CacheServices.getAcuityLevels(),
      CacheServices.getTOCStatus(),
    ]);

    setAcuityLevels(BaseEnum.toSelectable(a));
    setStatuses(BaseEnum.toSelectable(s));

    setLoadingFilters(false);
  };

  const getTocRecords = async () => {
    setLoading(true);

    const currentFilters = getServerFilters(filters);
    const { items } = await fetchTOCList({
      ...currentFilters,
      memberId: member ? [member.id] : undefined,
      sort: sort?.sort as string,
    });
    setItems(items);
    setLoading(false);

    setLoading(false);
  };

  const getSkilledRecords = async () => {
    setLoading(true);

    const currentFilters = getServerFilters(filters);
    const { items } = await fetchSkilledList({
      ...currentFilters,
      memberId: member ? [member.id] : undefined,
      sort: sort?.sort as string,
    });
    setItems(items);
    setLoading(false);

    setLoading(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (selectedToggle === TOC_TOGGLE_ITEM_TOC) {
      getTocRecords();
    } else {
      getSkilledRecords();
    }
  }, [filters, sort]);

  useEffect(() => {
    setItems([]);
    setSort(sortOptions[0]);
  }, [sortOptions]);

  return (
    <Panel>
      <Panel.Heading filtersV2>
        <Panel.Filters className="flex gap">
          <ToggleButton
            className={styles.toggle}
            items={TOC_TOGGLE_ITEMS}
            onChange={setSelectedToggle}
            value={selectedToggle}
          />
        </Panel.Filters>
        <Panel.FilterBar
          onClearFilters={() => {
            setFilters({});
          }}
          onChangeSort={setSort}
          onDeleteFilter={(key: string) => {
            setFilters({
              ...filters,
              [key]: undefined,
            });
          }}
          inputs={
            selectedToggle === TOC_TOGGLE_ITEM_TOC ? (
              <>
                <Select
                  data-cy="toc-filter-acuity-status"
                  items={statuses}
                  limitTags={1}
                  loading={loadingFilters}
                  multiple
                  onChange={(values: Selectable[]) =>
                    setFilters({
                      ...filters,
                      status: getFilterItem(
                        translate('toc.common.status'),
                        values,
                      ),
                    })
                  }
                  placeholder={translate('toc.common.status')}
                  placeholderV2
                  size="xs"
                  value={filters?.status?.value || []}
                />
                <Select
                  data-cy="toc-filter-acuity-level"
                  items={acuityLevels}
                  limitTags={1}
                  loading={loadingFilters}
                  multiple
                  onChange={(values: Selectable[]) =>
                    setFilters({
                      ...filters,
                      acuityLevel: getFilterItem(
                        translate('toc.common.acuityLevel'),
                        values,
                      ),
                    })
                  }
                  placeholder={translate('toc.common.acuityLevel')}
                  placeholderV2
                  size="xs"
                  value={filters?.acuityLevel?.value || []}
                />
              </>
            ) : (
              <></>
            )
          }
          sortOptions={sortOptions}
          sortDefault={sort}
        />
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <TOCMemberTable
          items={items}
          onClickRow={(item) =>
            item instanceof TOCItem
              ? history.push(
                  `/dashboard/toc/${item.id}?fullName=${item.member?.fullName}&memberId=${item.member?.id}&status=${item.status?.description}&assignee=${item.assignee?.fullName}`,
                )
              : history.push(
                  `/dashboard/skilled/${item.id}?fullName=${item.member?.fullName}&memberId=${item.member?.id}&assignee=${item.assignee?.fullName}`,
                )
          }
          toggle={selectedToggle}
        />
      </Panel.Body>
    </Panel>
  );
};
