import { EligibilityJob } from '@vestahealthcare/common/models';
import Api from 'dash/src/services/Api';

export const fetchEligibilityJobs = async (
  active: boolean,
): Promise<EligibilityJob[]> => {
  const { processExecutions } = await Api.getv2(
    `/automaticProcess/${active ? 'active' : 'passive'}`,
  );
  return (
    processExecutions &&
    processExecutions.items.map((i: any) => new EligibilityJob(i))
  );
};

export const downloadEligbilityReport = async (
  id: number,
  fileName: string,
) => {
  const blob: Blob = await Api.getv2Blob(
    `automaticProcess/eligibilityReport/${id}/download`,
  );
  const csvURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', `${fileName || 'eligibility_report'}.csv`);
  tempLink.click();
};
