import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import StyleguideSidebar from './StyleguideSidebar';
import Buttons from 'styleguide/src/pages/Styleguide/Buttons';
import Colors from 'styleguide/src/pages/Styleguide/Colors';
import Dropdowns from 'styleguide/src/pages/Styleguide/Dropdowns';
import Fields from 'styleguide/src/pages/Styleguide/Fields';
import Fonts from 'styleguide/src/pages/Styleguide/Fonts';
import JsonSchemaForms from 'styleguide/src/pages/Styleguide/JsonSchemaForms';
import Misc from 'styleguide/src/pages/Styleguide/Misc';
import Modals from 'styleguide/src/pages/Styleguide/Modals';
import Toggles from 'styleguide/src/pages/Styleguide/Toggles';
import Tables from 'styleguide/src/pages/Styleguide/Tables';
import './main.less';
import StyleguideSorter from 'styleguide/src/pages/Styleguide/StyleguideSorter';

const Styleguide = () => (
  <div id="page-wrapper">
    <StyleguideSidebar />
    <div id="page" className="styleguide">
      <Switch>
        <Route path="/dev/styleguide/buttons" component={Buttons} />
        <Route path="/dev/styleguide/colors" component={Colors} />
        <Route path="/dev/styleguide/dropdowns" component={Dropdowns} />
        <Route path="/dev/styleguide/fields" component={Fields} />
        <Route path="/dev/styleguide/fonts" component={Fonts} />
        <Route path="/dev/styleguide/forms" component={JsonSchemaForms} />
        <Route path="/dev/styleguide/modals" component={Modals} />
        <Route path="/dev/styleguide/toggles" component={Toggles} />
        <Route path="/dev/styleguide/tables" component={Tables} />
        <Route path="/dev/styleguide/sorter" component={StyleguideSorter} />
        <Route path="/dev/styleguide/misc" component={Misc} />
        <Redirect from="/dev/styleguide**" to="/dev/styleguide/buttons" />
      </Switch>
    </div>
  </div>
);

export default Styleguide;
