import { MessageType } from '@vestahealthcare/common/enums';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ChatInput, Conversation, Panel } from 'styleguide-v2';
import { MessageModel } from 'styleguide-v2/src/components/Chat/Conversation';

const MESSAGES = [
  {
    text: 'Lorem ipsum dolor sit',
    user: 1,
    date: moment().subtract(1, 'year').valueOf(),
    file: null,
    type: MessageType.SMS_INBOUND,
  },
  {
    text:
      'amet consectetur adipisicing elit. Earum placeat, esse rem veniam fuga culpa expedita quod ipsa ad nesciunt id necessitatibus nisi! Explicabo dolore animi dolorem culpa sint modi!',
    user: 1,
    date: moment().subtract(1, 'year').valueOf(),
    type: MessageType.SMS_INBOUND,
  },
  {
    text: 'Lorem ipsum dolor sit',
    user: 2,
    date: moment().subtract(4, 'weeks').valueOf(),
    type: MessageType.SMS_OUTBOUND,
  },
  {
    text:
      'amet consectetur adipisicing elit. Earum placeat, esse rem veniam fuga culpa expedita quod ipsa ad nesciunt id necessitatibus nisi! Explicabo dolore animi dolorem culpa sint modi!',
    user: 1,
    date: moment().subtract(3, 'weeks').valueOf(),
    type: MessageType.SMS_INBOUND,
  },
  {
    text:
      'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Earum placeat, esse rem veniam fuga culpa expedita quod ipsa ad nesciunt id necessitatibus nisi! Explicabo dolore animi dolorem culpa sint modi!',
    user: 2,
    date: moment().subtract(1, 'weeks').valueOf(),
    type: MessageType.SMS_OUTBOUND,
  },
  {
    text: 'Lorem ipsum dolor sit',
    user: 1,
    date: moment().subtract(1, 'week').valueOf(),
    type: MessageType.SMS_INBOUND,
  },
  {
    text:
      'amet consectetur adipisicing elit. Earum placeat, esse rem veniam fuga culpa expedita quod ipsa ad nesciunt id necessitatibus nisi! Explicabo dolore animi dolorem culpa sint modi!',
    user: 1,
    date: moment().subtract(1, 'week').valueOf(),
    type: MessageType.SMS_INBOUND,
  },
  {
    text:
      'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Earum placeat, esse rem veniam fuga culpa expedita quod ipsa ad nesciunt id necessitatibus nisi! Explicabo dolore animi dolorem culpa sint modi!',
    user: 2,
    date: moment().subtract(1, 'day').valueOf(),
    type: MessageType.SMS_OUTBOUND,
  },
  {
    text:
      'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Earum placeat, esse rem veniam fuga culpa expedita quod ipsa ad nesciunt id necessitatibus nisi! Explicabo dolore animi dolorem culpa sint modi!',
    user: 1,
    date: moment().subtract(12, 'hour').valueOf(),
    type: MessageType.SMS_INBOUND,
  },
  {
    text:
      'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Earum placeat, esse rem veniam fuga culpa expedita quod ipsa ad nesciunt id necessitatibus nisi! Explicabo dolore animi dolorem culpa sint modi!',
    user: 1,
    date: moment().subtract(10, 'hour').valueOf(),
    type: MessageType.SMS_INBOUND,
  },
  {
    text: '😊😊😊😊',
    user: 2,
    date: moment().subtract(1, 'minute').valueOf(),
    type: MessageType.SMS_OUTBOUND,
  },
  {
    text:
      'Earum placeat, esse rem veniam fuga culpa expedita quod ipsa ad nesciunt id necessitatibus nisi! Explicabo dolore animi dolorem culpa sint modi!',
    user: 2,
    date: moment().valueOf(),
    type: MessageType.SMS_OUTBOUND,
  },
];

const StyleguideButtons = () => {
  const [messages, setMessages] = useState<MessageModel[]>(MESSAGES);

  const fetchMessages = async () => {
    const response = await fetch(
      'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Moraine_Lake_17092005.jpg/1920px-Moraine_Lake_17092005.jpg',
    );
    const data = await response.blob();
    const image = new File([data], '');
    setMessages([
      ...MESSAGES,
      {
        image,
        user: 1,
        date: moment().valueOf(),
        type: MessageType.SMS_INBOUND,
      },
    ]);
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  return (
    <Panel>
      <Panel.Body>
        <Conversation
          messages={messages}
          leftUser={{ label: 'Diego' }}
          rightUser={{ label: 'Me' }}
          smoothScroll
          scrollToIndex="last"
        />
      </Panel.Body>
      <Panel.Footer>
        <ChatInput
          onSend={(message, image) =>
            setMessages([
              ...messages,
              {
                text: message || '',
                image,
                user: 2,
                date: moment().valueOf(),
                type: MessageType.SMS_OUTBOUND,
              },
            ])
          }
          showAttachFile
        />
      </Panel.Footer>
    </Panel>
  );
};

export default StyleguideButtons;
