import { Enum } from './Enum';
import Part from './BodyPart';
import { BodyPartSide } from './BodyPartSide';

export class BodySection extends Enum {
  translationKey = 'bodySection';

  static asArray: BodySection[] = [];

  static byKey: { [name: string]: BodySection } = {};

  static readonly FRONT_HEAD_NECK = new BodySection(
    'FRONT_HEAD_NECK',
    BodyPartSide.FRONT,
    [
      Part.HEAD_FRONT,
      Part.HEAD_BACK,
      Part.FACE,
      Part.NECK_FRONT,
      Part.NECK_BACK,
    ],
  );

  static readonly BACK_HEAD_NECK = new BodySection(
    'BACK_HEAD_NECK',
    BodyPartSide.BACK,
    [Part.HEAD_FRONT, Part.HEAD_BACK, Part.NECK_FRONT, Part.NECK_BACK],
  );

  static readonly CHEST_SHOULDERS = new BodySection(
    'CHEST_SHOULDERS',
    BodyPartSide.FRONT,
    [
      Part.CHEST,
      Part.CENTRAL_CHEST,
      Part.L_SHOULDER_FRONT,
      Part.R_SHOULDER_FRONT,
      Part.L_BREAST,
      Part.R_BREAST,
    ],
  );

  static readonly BACK = new BodySection('BACK', BodyPartSide.BACK, [
    Part.BACK,
    Part.UPPER_BACK,
    Part.LOWER_BACK,
    Part.L_SHOULDER_BACK,
    Part.R_SHOULDER_BACK,
  ]);

  static readonly L_FRONT_ARM = new BodySection(
    'L_FRONT_ARM',
    BodyPartSide.FRONT,
    [
      Part.L_UPPER_ARM_FRONT,
      Part.L_UPPER_ARM_BACK,
      Part.L_FOREARM_FRONT,
      Part.L_FOREARM_BACK,
    ],
  );

  static readonly L_BACK_ARM = new BodySection(
    'L_BACK_ARM',
    BodyPartSide.BACK,
    [
      Part.L_UPPER_ARM_FRONT,
      Part.L_UPPER_ARM_BACK,
      Part.L_ELBOW,
      Part.L_FOREARM_FRONT,
      Part.L_FOREARM_BACK,
    ],
  );

  static readonly R_FRONT_ARM = new BodySection(
    'R_FRONT_ARM',
    BodyPartSide.FRONT,
    [
      Part.R_UPPER_ARM_FRONT,
      Part.R_UPPER_ARM_BACK,
      Part.R_FOREARM_FRONT,
      Part.R_FOREARM_BACK,
    ],
  );

  static readonly R_BACK_ARM = new BodySection(
    'R_BACK_ARM',
    BodyPartSide.BACK,
    [
      Part.R_UPPER_ARM_FRONT,
      Part.R_UPPER_ARM_BACK,
      Part.R_ELBOW,
      Part.R_FOREARM_FRONT,
      Part.R_FOREARM_BACK,
    ],
  );

  static readonly L_FRONT_HAND = new BodySection(
    'L_FRONT_HAND',
    BodyPartSide.FRONT,
    [
      Part.L_HAND_FRONT,
      Part.L_HAND_BACK,
      Part.L_WRIST_FRONT,
      Part.L_WRIST_BACK,
      Part.L_FINGERS_FRONT,
      Part.L_FINGERS_BACK,
    ],
  );

  static readonly L_BACK_HAND = new BodySection(
    'L_BACK_HAND',
    BodyPartSide.BACK,
    [
      Part.L_HAND_FRONT,
      Part.L_HAND_BACK,
      Part.L_WRIST_FRONT,
      Part.L_WRIST_BACK,
      Part.L_FINGERS_FRONT,
      Part.L_FINGERS_BACK,
    ],
  );

  static readonly R_FRONT_HAND = new BodySection(
    'R_FRONT_HAND',
    BodyPartSide.FRONT,
    [
      Part.R_HAND_FRONT,
      Part.R_HAND_BACK,
      Part.R_WRIST_FRONT,
      Part.R_WRIST_BACK,
      Part.R_FINGERS_FRONT,
      Part.R_FINGERS_BACK,
    ],
  );

  static readonly R_BACK_HAND = new BodySection(
    'R_BACK_HAND',
    BodyPartSide.BACK,
    [
      Part.R_HAND_FRONT,
      Part.R_HAND_BACK,
      Part.R_WRIST_FRONT,
      Part.R_WRIST_BACK,
      Part.R_FINGERS_FRONT,
      Part.R_FINGERS_BACK,
    ],
  );

  static readonly ABDOMEN_HIPS_GROIN = new BodySection(
    'ABDOMEN_HIPS_GROIN',
    BodyPartSide.FRONT,
    [Part.ABDOMEN, Part.HIPS, Part.GROIN],
  );

  static readonly BUTTOCKS = new BodySection('BUTTOCKS', BodyPartSide.BACK, [
    Part.BUTTOCKS,
    Part.L_BUTTOCK,
    Part.R_BUTTOCK,
    Part.SACRUM,
  ]);

  static readonly L_FRONT_LEG = new BodySection(
    'L_FRONT_LEG',
    BodyPartSide.FRONT,
    [
      Part.L_UPPER_LEG_FRONT,
      Part.L_UPPER_LEG_BACK,
      Part.L_KNEE,
      Part.L_LOWER_LEG_FRONT,
      Part.L_LOWER_LEG_BACK,
    ],
  );

  static readonly L_BACK_LEG = new BodySection(
    'L_BACK_LEG',
    BodyPartSide.BACK,
    [
      Part.L_UPPER_LEG_FRONT,
      Part.L_UPPER_LEG_BACK,
      Part.L_LOWER_LEG_FRONT,
      Part.L_LOWER_LEG_BACK,
    ],
  );

  static readonly R_FRONT_LEG = new BodySection(
    'R_FRONT_LEG',
    BodyPartSide.FRONT,
    [
      Part.R_UPPER_LEG_FRONT,
      Part.R_UPPER_LEG_BACK,
      Part.R_KNEE,
      Part.R_LOWER_LEG_FRONT,
      Part.R_LOWER_LEG_BACK,
    ],
  );

  static readonly R_BACK_LEG = new BodySection(
    'R_BACK_LEG',
    BodyPartSide.BACK,
    [
      Part.R_UPPER_LEG_FRONT,
      Part.R_UPPER_LEG_BACK,
      Part.R_LOWER_LEG_FRONT,
      Part.R_LOWER_LEG_BACK,
    ],
  );

  static readonly L_FRONT_FOOT = new BodySection(
    'L_FRONT_FOOT',
    BodyPartSide.FRONT,
    [Part.L_FOOT, Part.L_ANKLE_FRONT, Part.L_ANKLE_BACK, Part.L_TOES],
  );

  static readonly L_BACK_FOOT = new BodySection(
    'L_BACK_FOOT',
    BodyPartSide.FRONT,
    [Part.L_FOOT, Part.L_ANKLE_FRONT, Part.L_ANKLE_BACK, Part.L_HEEL],
  );

  static readonly R_FRONT_FOOT = new BodySection(
    'R_FRONT_FOOT',
    BodyPartSide.FRONT,
    [Part.R_FOOT, Part.R_ANKLE_FRONT, Part.R_ANKLE_BACK, Part.R_TOES],
  );

  static readonly R_BACK_FOOT = new BodySection(
    'R_BACK_FOOT',
    BodyPartSide.BACK,
    [Part.R_FOOT, Part.R_ANKLE_FRONT, Part.R_ANKLE_BACK, Part.R_HEEL],
  );

  constructor(
    public readonly value: string,
    public readonly side: BodyPartSide,
    public readonly parts: Part[],
  ) {
    super();
    BodySection.asArray.push(this);
    BodySection.byKey[value] = this;
  }
}

export default BodySection;
