import { BaseEnum } from './BaseEnum';

export class IncidentType extends BaseEnum {
  static CHANGE_OF_CONDITION = 'CHANGE_OF_CONDITION';

  static ER_VISIT = 'ER_VISIT';

  static FALL_INJURY = 'FALL_INJURY';

  static FALL_NO_INJURY = 'FALL_NO_INJURY';

  static HOSPITALIZATION = 'HOSPITALIZATION';

  static FALL_TYPES = [IncidentType.FALL_INJURY, IncidentType.FALL_NO_INJURY];

  static MODAL_OMITTED_TYPES = [
    ...IncidentType.FALL_TYPES,
    IncidentType.ER_VISIT,
    IncidentType.HOSPITALIZATION,
  ];

  static getModalTypes(types: IncidentType[]) {
    return types.filter(
      ({ id }) => !IncidentType.MODAL_OMITTED_TYPES.includes(id),
    );
  }

  static getOtherModalTypes(types: IncidentType[]) {
    return types.filter(({ id }) =>
      IncidentType.MODAL_OMITTED_TYPES.includes(id),
    );
  }

  static getFallModalTypes(types: IncidentType[]) {
    return types.filter(({ id }) => IncidentType.FALL_TYPES.includes(id));
  }
}

export default IncidentType;
