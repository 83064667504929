import { Enum } from './Enum';

export class ConsumerRole extends Enum {
  translationKey = 'consumerRole';

  static asArray: ConsumerRole[] = [];

  static byKey: { [name: string]: ConsumerRole } = {};

  static readonly PLATFORM_APP = new ConsumerRole('PLATFORM_APP');

  static readonly SMARTPHONE_APP = new ConsumerRole('SMARTPHONE_APP');

  constructor(public readonly value: string) {
    super();
    ConsumerRole.asArray.push(this);
    ConsumerRole.byKey[value] = this;
  }
}

export default ConsumerRole;
