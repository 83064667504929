import classNames from 'classnames';
import moment, { Moment } from 'moment';
import React, { Fragment } from 'react';

import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import GroupIcon from '@mui/icons-material/Group';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  MemberDashboardCCM,
  MemberWarning,
  ProgramExtensionStatus,
} from '@vestahealthcare/common/models';
import MemberWarningType from '@vestahealthcare/common/models/member-warnings/MemberWarningType';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import {
  Card,
  Chip,
  Colors,
  Fonts,
  Summary,
  Tooltip,
  UNASSIGNED,
} from 'styleguide-v2';

import { MemberWarningIcon } from 'dash/src/components/MemberWarningIcon';

type Props = {
  className?: string;
  data?: MemberDashboardCCM;
  onLogCall: () => void;
  warnings?: MemberWarning[];
};

const useStyles = makeStyles({
  assigmentsText: {
    display: 'flex',
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    fontWeight: 500,
    margin: '0.5rem 0 0',
  },
  container: {
    '&&': {
      borderRadius: '8px',
    },

    display: 'flex',

    '& > div': {
      flex: 1,
      padding: '2.4rem 3.2rem',
    },
  },
  subContainer: {
    display: 'flex',
    flexFlow: 'column',
  },
  summary: {
    width: '24%',
  },
  title: {
    alignItems: 'center',
    display: 'flex',

    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontWeight: 500,
      margin: 0,
    },
  },
});

export const CCMCard = ({ className, data, onLogCall, warnings }: Props) => {
  const styles = useStyles();

  if (
    !data ||
    !data.programExtensionStatus ||
    [ProgramExtensionStatus.DISENROLLED].includes(
      data.programExtensionStatus.id,
    )
  )
    return <></>;

  const hasCCMTimeWarning = warnings?.find(
    ({ type }) => type.id === MemberWarningType.CCM_TIME,
  );

  const getTotalMinutesColor = (totalMinutes: number, hasValidCCM: boolean) => {
    if (hasCCMTimeWarning) return Colors.orange;
    if (totalMinutes >= 20 && hasValidCCM) return Colors.iconGreen;
    if (totalMinutes >= 20 || hasValidCCM) return Colors.gold;
    return Colors.orange;
  };

  const getLastCompletedColor = (lastCompletedDate?: Moment) => {
    if (!lastCompletedDate) return Colors.iconGray;
    if (
      moment().startOf('month').startOf('day').isSameOrBefore(lastCompletedDate)
    )
      return Colors.iconGreen;
    if (
      moment()
        .subtract(1, 'month')
        .startOf('month')
        .startOf('day')
        .isSameOrBefore(lastCompletedDate)
    )
      return Colors.gold;
    return Colors.orange;
  };

  const getLastCarePlanColor = () => {
    const hasWarnings = !!warnings?.filter(({ type }) =>
      [
        MemberWarningType.DUE_CARE_PLAN_INITIAL,
        MemberWarningType.DUE_CARE_PLAN_UPDATE,
      ].includes(type.id),
    )?.length;
    if (hasWarnings) return Colors.orange;
    return Colors.iconGreen;
  };

  const getLastCarePlanSubtitle = () => {
    if (data.lastCarePlanEmployee)
      return <span className="bold">with {data.lastCarePlanEmployee}</span>;
    return '';
  };

  return (
    <Card className={classNames(className, styles.container)}>
      <div className={styles.subContainer}>
        <div className="flex spaced">
          <span className={styles.title}>
            {translate('memberProfile.dashboard.ccm')}
            &nbsp;&nbsp;
            <Chip
              color={ProgramExtensionStatus.getColorStatus(
                data.programExtensionStatus,
              )}
              type="contained-2"
            >
              <span className="bold">{`CCM: ${data.programExtensionStatus?.description?.toLocaleUpperCase()}`}</span>
            </Chip>
            {warnings
              ?.filter(({ type }) =>
                [
                  MemberWarningType.DUE_CARE_PLAN_INITIAL,
                  MemberWarningType.DUE_CARE_PLAN_UPDATE,
                ].includes(type.id),
              )
              .map(({ type: { id, description } }) => (
                <Fragment key={`member-dashboar-warning-${id}`}>
                  &nbsp;
                  <Chip color="error" type="contained-2">
                    <span className="bold">
                      {description.toLocaleUpperCase()}
                    </span>
                  </Chip>
                </Fragment>
              ))}
          </span>
          <a
            className="flex middle min-gap"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onLogCall();
            }}
            style={{ color: Colors.textGreen, fontWeight: 500 }}
          >
            {translate('memberProfile.dashboard.logCall')}{' '}
            <AddIcCallIcon fontSize="small" />
          </a>
        </div>
        <br />
        <div className="flex spaced">
          <Summary
            body={data.ccmTotalMinutes}
            className={styles.summary}
            color={getTotalMinutesColor(data.ccmTotalMinutes, data.hasValidCCM)}
            footer={<i>{translate('memberProfile.dashboard.cumulative')}</i>}
            tag={
              <>
                <Chip color="action" type="contained-2">
                  <span className="bold">
                    {data.ccmProfessionalMinutes} PRO
                  </span>
                </Chip>
                {hasCCMTimeWarning && (
                  <>
                    <Chip
                      color="error"
                      icon={
                        <MemberWarningIcon
                          category={hasCCMTimeWarning.type.category}
                        />
                      }
                      type="contained-2"
                      title={translate('memberWarnings.modal.timeNoteCCM')}
                    />
                  </>
                )}
              </>
            }
            title={translate('memberProfile.dashboard.ccmTotalMinutes')}
          />
          <Summary
            body={data.lastCompletedDate?.format('M/D/YY') || EMPTY}
            className={styles.summary}
            color={getLastCompletedColor(data.lastCompletedDate)}
            footer={
              data.lastCompletedEmployee ? (
                <span className="bold">with {data.lastCompletedEmployee}</span>
              ) : undefined
            }
            title={translate('memberProfile.dashboard.ccmLastCompleted')}
          />
          <Summary
            body={data.lastCarePlanDate?.format('M/D/YY') || EMPTY}
            className={styles.summary}
            color={getLastCarePlanColor()}
            footer={getLastCarePlanSubtitle()}
            title={translate('memberProfile.dashboard.ccmLastCarePlan')}
          />
          <Summary
            body={
              <>
                <p className={styles.assigmentsText}>
                  <Tooltip
                    text={translate('memberProfile.dashboard.callCadence')}
                  >
                    <CalendarIcon />
                  </Tooltip>{' '}
                  {` ${data.callCadenceGroup || UNASSIGNED?.fullName}`}
                </p>
                <p className={styles.assigmentsText}>
                  <Tooltip
                    text={translate('memberProfile.dashboard.worklistGroup')}
                  >
                    <GroupIcon />
                  </Tooltip>{' '}
                  {` ${data.worklistGroup || UNASSIGNED?.fullName}`}
                </p>
              </>
            }
            className={styles.summary}
            color={Colors.paleGray}
            title={translate('memberProfile.dashboard.assignments')}
          />
        </div>
      </div>
    </Card>
  );
};
