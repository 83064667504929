import { Language } from '../enums';

export class Translation {
  language: Language;

  content = '';

  header = '';

  constructor(obj: any) {
    Object.assign(this, obj);
    this.language = Language.byKey[obj.language];
  }

  isEmpty() {
    return !this.content.trim() && !this.header.trim();
  }
}

export default Translation;
