import { make } from './Model';
import { Phone } from './Phone';

export class UserPhone {
  id = 0;

  primary = false;

  phone: Phone;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.phone = make(obj.phone, Phone);
  }

  static sortPrimary({ primary: a }: UserPhone, { primary: b }: UserPhone) {
    if (a) return -1;
    if (b) return 1;
    return 0;
  }
}

export default UserPhone;
