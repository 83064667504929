import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { makeStyles } from '@mui/styles';

import { Fonts } from 'styleguide-v2/src/styles/Variables';

import { Colors } from '../../styles/Colors';

interface Props {
  children?: ReactNode | string;
  className?: string;
  error?: boolean | string;
  noMargin?: boolean;
  required?: boolean;
}

const useStyles = makeStyles({
  label: {
    marginBottom: '.4rem',
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
  },
  noMargin: {
    '&&': {
      marginBottom: 0,
    },
  },
  error: {
    color: Colors.error,
  },
});

export const Label = ({
  children,
  className,
  error,
  noMargin,
  required,
}: Props) => {
  const styles = useStyles();

  return (
    <span
      className={classNames(
        className,
        styles.label,
        error && styles.error,
        noMargin && styles.noMargin,
      )}
    >
      {children}
      {required && ' *'}
    </span>
  );
};

export default Label;
