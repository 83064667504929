import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';

import { EventReporterType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  CareTeamMember,
  HealthReport,
  PaginationType,
  Patient,
} from '@vestahealthcare/common/models';

import { EmptyState } from 'styleguide';
import { IconButton, Panel, ToggleDateRange } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import {
  STORE_VALUES,
  getSelector,
} from 'dash/src/redux/actions/HealthAlertsActions';
import { useSelector } from 'dash/src/redux/store';
import { fetchHealthReportsByPatient } from 'dash/src/services';
import {
  PostHealthCheckInQuestionParams,
  postHealthCheckInQuestions,
  updatePatient,
} from 'dash/src/services/PatientServices';

import { HCISettingsModal } from './HCISettingsModal';
import HealthReportAdd from './HealthReportAdd';
import HealthReportTable from './HealthReportTable';
import './main.less';

const useStyles = makeStyles({
  dateRange: {
    marginTop: '-2.5rem',
  },
});

const PAGE_SIZE = 25;

const ClinicalProfileHealthReports = () => {
  const styles = useStyles();
  const patient = useSelector((state) => state.memberInfoSlice.patient);

  const [isAddingReport, setAddingReport] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSubmittingReport, setSubmittingReport] = useState<boolean>(false);
  const [isOpenSettings, setOpenSettings] = useState(false);

  const [dateRange, setDateRange] = useState<
    [Date | undefined, Date | undefined]
  >([undefined, undefined]);

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const [healthReports, setHealthReports] = useState<HealthReport[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();

  const fetchHealthReports = async () => {
    const dateFrom = dateRange[0] ? moment(dateRange[0].getTime()) : undefined;
    const dateTo = dateRange[1] ? moment(dateRange[1].getTime()) : undefined;
    const offset = page * pageSize;

    if (offset !== 0 && offset < healthReports?.length) return;

    setLoading(true);

    try {
      const { items, pagination } = await fetchHealthReportsByPatient(
        Number(patient?.id),
        { dateFrom, dateTo, offset, limit: pageSize },
      );

      if (offset === 0) {
        setHealthReports(items);
      } else {
        setHealthReports([...healthReports, ...items]);
      }
      setPagination(pagination);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (page !== undefined && pageSize !== undefined) {
      fetchHealthReports();
    }
  }, [dateRange, page, pageSize]);

  const submitForm = async (
    type: EventReporterType,
    reporter: Patient | CareTeamMember,
    params: PostHealthCheckInQuestionParams,
  ) => {
    if (!patient?.id) return;
    setSubmittingReport(true);

    try {
      await postHealthCheckInQuestions(patient?.id, type, reporter?.id, params);
      setAddingReport(false);
      await fetchHealthReports();
    } catch (err) {
      setSubmittingReport(false);
      showGlobalError(err as string);
    }
    setSubmittingReport(false);
  };

  const editable = patient?.isEditable();

  return (
    <Panel data-cy="health-reports-section">
      <Panel.Heading title={translate('healthReports.title')}>
        <Panel.Filters>
          <ToggleDateRange
            className={styles.dateRange}
            items={[-1, 7, 30, 90, 'custom']}
            onChange={(from, to) => {
              if (from && to) {
                // eslint-disable-next-line @typescript-eslint/no-implied-eval
                setDateRange([
                  moment(from.getTime()).toDate(),
                  moment(to.getTime()).toDate(),
                ]);
              } else if (dateRange[0] || dateRange[1]) {
                setDateRange([undefined, undefined]);
              }
              setPage(0);
            }}
          />
        </Panel.Filters>
        <Panel.Actions>
          <IconButton
            onClick={() => setOpenSettings(true)}
            tooltip={translate('global.settings')}
          >
            <SettingsIcon fontSize="large" />
          </IconButton>
          {editable && (
            <IconButton
              data-cy="add-health-report-button"
              onClick={() => setAddingReport(true)}
              tooltip={translate('healthReports.add')}
            >
              <AddIcon />
            </IconButton>
          )}
        </Panel.Actions>
      </Panel.Heading>
      <Panel.Body loading={isLoading} className="no-spacing">
        <EmptyState show={healthReports && !healthReports.length}>
          {translate('memberProfile.empty.healthReport')}{' '}
          {editable && (
            <a
              data-cy="add-health-report"
              onClick={() => setAddingReport(true)}
            >
              {translate('memberProfile.clickTo.healthReport')}
            </a>
          )}
        </EmptyState>
        <HealthReportAdd
          isSubmitting={isSubmittingReport}
          onCancel={() => setAddingReport(false)}
          onSubmit={submitForm}
          patient={patient}
          open={isAddingReport}
        />
        {!!healthReports?.length && (
          <HealthReportTable
            reports={healthReports}
            onChangePage={setPage}
            onChangePageSize={setPageSize}
            pagination={pagination}
          />
        )}

        <HCISettingsModal
          onClose={() => {
            setOpenSettings(false);
          }}
          onSubmit={async (id, params) => {
            await updatePatient(id, params);
            setOpenSettings(false);
          }}
          open={isOpenSettings}
          patient={patient}
        />
      </Panel.Body>
    </Panel>
  );
};

export default connect(getSelector([STORE_VALUES.patient]))(
  ClinicalProfileHealthReports,
);
