import { IssueDetailFieldType } from '../enums';
import { IssueDetailFieldOption } from './IssueDetailFieldOption';
import { makeArray } from './Model';

export class IssueDetailField {
  id = '';

  description = '';

  fieldType: IssueDetailFieldType;

  options?: IssueDetailFieldOption[];

  position = 0;

  followUp = false;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.fieldType = obj.fieldType
      ? IssueDetailFieldType.byKey[obj.fieldType.id]
      : IssueDetailFieldType.TEXT;
    this.options = makeArray(obj.options, IssueDetailFieldOption);
  }
}

export default IssueDetailField;
