import { useState, useRef, useEffect } from 'react';

interface TimerOptions {
  autoStart?: boolean;
}

const getPaddedNum = (num: number) => num.toString().padStart(2, '0');

export const useTimer = (options?: TimerOptions) => {
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout>();

  const clear = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      setIsRunning(false);
      setTotalSeconds(0);
      intervalRef.current = undefined;
    }
  };

  const start = () => {
    if (intervalRef.current) {
      clear();
    }
    intervalRef.current = setInterval(() => {
      setTotalSeconds((seconds) => seconds + 1);
    }, 1000);
    setIsRunning(true);
  };

  useEffect(() => {
    if (options?.autoStart) {
      start();
    }

    return clear;
  }, []);

  const minutes = getPaddedNum(Math.floor(totalSeconds / 60));
  const seconds = getPaddedNum(totalSeconds % 60);

  return {
    minutes,
    seconds,
    isRunning,
    start,
  };
};

export default useTimer;
