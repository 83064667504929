import favicon from 'dash/static/images/favicons/development.png';
import { LS_REMOTE_BACKEND } from '@vestahealthcare/common/utils/constants';

let backend;
const getAPIUrl = () => {
  backend = localStorage.getItem(LS_REMOTE_BACKEND) || '';

  let qaEnv = '1';
  if (backend.startsWith('qa')) {
    qaEnv = backend.charAt(2);
    backend = 'qa';
  }

  switch (backend) {
    case 'staging':
      return 'https://api.staging.hometeamcare.com/v2';
    case 'stage':
      return 'https://api.stage.hometeamcare.com/v2';
    case 'qa':
      return `https://api.qa${qaEnv}.hometeamcare.com/v2`;
    case 'demo':
      return 'https://api.demo.hometeamcare.com/v2';
    default:
      return 'http://localhost:8080/v2';
  }
};

export default {
  favicon,
  apiv2: {
    url: getAPIUrl(),
    credentials: {
      consumerKey: 2,
    },
  },
  remoteBackend: backend,
  auth: {
    redirectUrl: 'http://localhost:8002',
    clientId: '2jj85o78250ad8mcra411v2pa8',
    hostedDomainUrl:
      'https://nonprod-vesta-dash.auth.us-east-1.amazoncognito.com',
  },
  env: 'development',
  staticUrl: 'https://s3.amazonaws.com/hometeam-static',
  isDemo: false,
  isDev: true,
  isProd: false,
  isQA: false,
  isStage: false,
  segment: {
    writeKey: 'AumyqOPYuayxR5GMX1L407gHOaRlWnAW',
  },
  appCenter: {
    apiToken: '85e072e6f4285e95def6ea7e6e2a0065e2c2f8a4',
  },
  launchDarkly: '5e73e9866994ac0719ff7505',
  streamChat: 'vcgyqqftk5d4',
  clearTriageToken: 'lKdYlQWk1sMCRWn5EyUc',
};
