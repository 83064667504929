import { BaseEnum } from './BaseEnum';

export class IncidentAction extends BaseEnum {
  other = '';

  static STATE_FEDERAL = 'STATE_FEDERAL';

  constructor(obj: any) {
    super(obj);
    this.other = obj.other;
  }
}

export default IncidentAction;
