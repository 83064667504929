import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  InsuranceMLTC,
  InsurancePlan,
  InsurancePriority,
  MemberInsurance,
} from '@vestahealthcare/common/models';
import moment from '@vestahealthcare/common/moment';
import {
  DATE_FORMAT,
  DATE_FORMAT_SHORT_WITH_TIME,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import { Text } from 'styleguide';
import {
  Button,
  CollapsableSidebar,
  DateTimePicker,
  Select,
  SelectMLTCOption,
  TextInput,
  Tooltip,
} from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import { startCase, toLower } from 'lodash';

export interface InsuranceData {
  discontinuedAt?: number;
  enrollmentEnd?: string;
  enrollmentStart?: string;
  id?: number;
  insurancePlanId: number;
  insurancePriorityId: number;
  insuranceType: string;
  mltcPlanId?: number | null;
  subscriberId: string;
}

const useStyles = makeStyles({
  insuranceTypeEllipsis: {
    maxWidth: '37.5rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

interface Props {
  insurance: MemberInsurance;
  onClose: () => void;
  onSubmit: (data: InsuranceData) => Promise<boolean>;
}

const InsuranceEditModal = ({ insurance, onClose, onSubmit }: Props) => {
  const styles = useStyles();
  const [insurancePlans, setInsurancePlans] = useState<InsurancePlan[]>([]);
  const [insurancePriorities, setInsurancePriorities] = useState<
    InsurancePriority[]
  >([]);
  const [insuranceMLTCs, setInsuranceMLTCs] = useState<InsuranceMLTC[]>([]);
  const [insuranceTypes, setInsuranceTypes] = useState<BaseEnum[]>([]);

  const [insurancePlanId, setInsurancePlanId] = useState(
    insurance.insurancePlan?.id,
  );
  const [insuranceType, setInsuranceType] = useState(insurance?.insuranceType);
  const [insurancePriorityId, setInsurancePriorityId] = useState(
    insurance.insurancePriority?.id,
  );
  const [enrollmentStart, setEnrollmentStart] = useState(
    insurance.enrollmentStart,
  );
  const [enrollmentEnd, setEnrollmentEnd] = useState(insurance.enrollmentEnd);
  const [subscriberId, setSubscriberId] = useState(insurance.subscriberId);

  const [insuranceMLTC, setInsuranceMLTC] = useState<InsuranceMLTC | undefined>(
    insurance.mltcPlan,
  );
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const isValid = () => !!insurancePlanId && !!insurancePriorityId;

  const selectedInsurancePlan = insurancePlans?.find(
    ({ id }) => id === insurancePlanId,
  );

  const doSubmit = async () => {
    setSubmitted(true);
    if (!isValid()) return;
    setLoading(true);

    try {
      await onSubmit({
        id: insurance.id,
        subscriberId,
        insurancePlanId,
        insuranceType,
        insurancePriorityId,
        enrollmentStart,
        enrollmentEnd,
        mltcPlanId: selectedInsurancePlan?.isMedicaid
          ? insuranceMLTC?.id
          : null,
      });
    } finally {
      setLoading(false);
    }
  };

  const getInitialData = async () => {
    setLoadingData(true);
    const [
      insurancePlans,
      insurancePriorities,
      insuranceMLTCs,
      insuranceTypes,
    ] = await Promise.all([
      CacheServices.getInsurancePlans(),
      CacheServices.getInsurancePriorities(),
      CacheServices.getInsuranceMLTCs(),
      CacheServices.getInsuranceTypes(),
    ]);
    setInsurancePlans(
      insurancePlans.sort(({ name: a }, { name: b }) => a.localeCompare(b)),
    );
    setInsurancePriorities(insurancePriorities);
    setInsuranceMLTCs(
      insuranceMLTCs.sort(({ name: a }, { name: b }) => a.localeCompare(b)),
    );
    setInsuranceTypes(
      insuranceTypes.sort(({ id: a }, { id: b }) => a.localeCompare(b)),
    );
    setLoadingData(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <CollapsableSidebar
      title={
        <h2>
          {translate(
            insurance.id
              ? 'memberInsurances.editTitle'
              : 'memberInsurances.add',
          )}
        </h2>
      }
      onClose={onClose}
      open
      size={500}
    >
      <CollapsableSidebar.Body>
        <div className="grid-wrapper">
          <Select
            className="grid-span-12"
            data-cy="insurance-plan-input"
            error={submitted && !insurancePlanId}
            getItemLabel={({ name }: InsurancePlan) => name}
            items={insurancePlans}
            label={translate('memberInsurances.insurancePlan')}
            loading={loadingData}
            onChange={(insurance: InsurancePlan) =>
              setInsurancePlanId(insurance?.id)
            }
            required
            value={selectedInsurancePlan}
          />
          <Select
            className="grid-span-12"
            data-cy="insurance-plan-type-input"
            getItemLabel={({ id, description }: BaseEnum) =>
              `${id} - ${description}`
            }
            renderOption={({ id, description }: BaseEnum) => {
              const text = `${id} - ${description}`;
              return (
                <Tooltip text={text} enterDelay={250} followCursor>
                  <span className={styles.insuranceTypeEllipsis}>{text}</span>
                </Tooltip>
              );
            }}
            items={insuranceTypes}
            label={translate('memberInsurances.insuranceType')}
            loading={loadingData}
            onChange={(item?: BaseEnum) => setInsuranceType(item?.id || '')}
            value={insuranceTypes?.find(({ id }) => id === insuranceType)}
          />
          <TextInput
            className="grid-span-12"
            data-cy="subscriber-id-input"
            label={translate('memberInsurances.subscriberId')}
            onChange={(value) => setSubscriberId(value || '')}
            value={subscriberId || ''}
          />
          <Select
            className="grid-span-12"
            data-cy="insurance-priority-input"
            error={submitted && !insurancePriorityId}
            getItemLabel={({ name }: InsurancePriority) =>
              startCase(toLower(name))
            }
            items={insurancePriorities}
            label={translate('memberInsurances.priority')}
            loading={loadingData}
            onChange={(insurance: InsurancePriority) =>
              setInsurancePriorityId(insurance?.id)
            }
            required
            value={insurancePriorities?.find(
              ({ id }) => id === insurancePriorityId,
            )}
          />
          <DateTimePicker
            className="grid-span-6"
            data-cy="enrollment-start-date"
            disabled={!!insurance?.enrollmentStart}
            label={translate('memberInsurances.enrollmentStart')}
            maxDate={
              enrollmentEnd ? moment(enrollmentEnd)?.toDate() : undefined
            }
            onChange={(value: Date) => {
              if (value && moment(value.getTime()).isValid()) {
                setEnrollmentStart(moment(value.getTime()).format(DATE_FORMAT));
              } else {
                setEnrollmentStart(undefined);
              }
            }}
            value={
              enrollmentStart ? moment(enrollmentStart).toDate() : undefined
            }
          />
          <DateTimePicker
            className="grid-span-6"
            data-cy="enrollment-end-date"
            disabled={!!insurance?.enrollmentEnd}
            label={translate('memberInsurances.enrollmentEnd')}
            minDate={
              enrollmentStart ? moment(enrollmentStart).toDate() : undefined
            }
            onChange={(value: Date) => {
              if (value && moment(value.getTime()).isValid()) {
                setEnrollmentEnd(moment(value.getTime()).format(DATE_FORMAT));
              } else {
                setEnrollmentEnd(undefined);
              }
            }}
            value={enrollmentEnd ? moment(enrollmentEnd).toDate() : undefined}
          />

          {selectedInsurancePlan?.isMedicaid && (
            <>
              <Select
                className="grid-span-12"
                data-cy="insurance-mltc-name"
                items={insuranceMLTCs.filter(({ mltcId }) => !!mltcId)}
                getItemLabel={({ name }: InsuranceMLTC) => name}
                label={translate('memberInsurances.mltcName')}
                loading={loadingData}
                onChange={setInsuranceMLTC}
                renderOption={(mltc: InsuranceMLTC) => (
                  <SelectMLTCOption mltc={mltc} />
                )}
                value={insuranceMLTC}
              />
              <TextInput
                className="grid-span-6"
                data-cy="insurance-mltc-plan-type"
                disabled
                label={translate('memberInsurances.mltcPlanType')}
                onChange={() => {}}
                value={insuranceMLTC?.mltcType}
              />
              <TextInput
                className="grid-span-6"
                data-cy="insurance-mltc-plan-code"
                disabled
                label={translate('memberInsurances.mltcPlanCode')}
                onChange={() => {}}
                value={insuranceMLTC?.mltcCode}
              />
              <TextInput
                className="grid-span-12"
                data-cy="insurance-mltc-plan-id"
                disabled
                label={translate('memberInsurances.mltcPlanId')}
                onChange={() => {}}
                value={insuranceMLTC?.mltcId}
              />
            </>
          )}
          {!!insurance.createdAt && (
            <Text className="grid-span-12" small>
              {translate('global.createdAt')}{' '}
              {moment
                .unix(insurance.createdAt)
                .format(DATE_FORMAT_SHORT_WITH_TIME) || EMPTY}
              {insurance.createdBy && (
                <>
                  {' by '}
                  {insurance.createdBy.fullName}
                </>
              )}
            </Text>
          )}
        </div>
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="insurance-plan-close"
          onClick={onClose}
        >
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="insurance-plan-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default InsuranceEditModal;
