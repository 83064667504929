import { Language } from '../enums';

export class EngagementContentItem {
  id = '';

  language: Language;

  referenceLink = '';

  rawText = '';

  enabled = false;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.language = Language.byKey[obj.language.id];
  }
}

export default EngagementContentItem;
