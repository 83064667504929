export class EventStatusGroup {
  id = '';

  description = '';

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  static OPEN = 'OPEN';

  static IN_PROGRESS = 'IN_PROGRESS';

  static CLOSED = 'CLOSED';
}

export default EventStatusGroup;
