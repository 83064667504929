import { Enum } from './Enum';

export class IssueTypeRuleOperation extends Enum {
  translationKey = 'issueTypeRuleOperation';

  static asArray: IssueTypeRuleOperation[] = [];

  static byKey: { [name: string]: IssueTypeRuleOperation } = {};

  static readonly GREATER = new IssueTypeRuleOperation('GREATER');

  static readonly GREATER_OR_EQUAL = new IssueTypeRuleOperation(
    'GREATER_OR_EQUAL',
  );

  static readonly LESS = new IssueTypeRuleOperation('LESS');

  static readonly LESS_OR_EQUAL = new IssueTypeRuleOperation('LESS_OR_EQUAL');

  static readonly EQUAL = new IssueTypeRuleOperation('EQUAL');

  static readonly BETWEEN = new IssueTypeRuleOperation('BETWEEN');

  static readonly GAIN = new IssueTypeRuleOperation('GAIN');

  static readonly LOSS = new IssueTypeRuleOperation('LOSS');

  constructor(public readonly value: string) {
    super();
    IssueTypeRuleOperation.asArray.push(this);
    IssueTypeRuleOperation.byKey[value] = this;
  }

  static get asDefaultArray() {
    return [
      IssueTypeRuleOperation.GREATER,
      IssueTypeRuleOperation.GREATER_OR_EQUAL,
      IssueTypeRuleOperation.LESS,
      IssueTypeRuleOperation.LESS_OR_EQUAL,
      IssueTypeRuleOperation.EQUAL,
      IssueTypeRuleOperation.BETWEEN,
    ];
  }

  static get asBPrray() {
    return [
      IssueTypeRuleOperation.GREATER,
      IssueTypeRuleOperation.GREATER_OR_EQUAL,
      IssueTypeRuleOperation.LESS,
      IssueTypeRuleOperation.LESS_OR_EQUAL,
      IssueTypeRuleOperation.EQUAL,
    ];
  }

  static get asWeightArray() {
    return [IssueTypeRuleOperation.GAIN, IssueTypeRuleOperation.LOSS];
  }
}

export default IssueTypeRuleOperation;
