import React, { useState, ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Document, Page } from 'react-pdf';
import { Button, Spinner, DeleteIcon } from 'styleguide';
import { translate } from '@vestahealthcare/common/i18n';

import './main.less';
/*
react-pdf needs a proper configuration of CORS in S3 (or where the files are stored) to be able to
preview the pdfs. Details can be found in the README of the infrastructure repo.
*/

const KEYCODE_ESC = 27;
const KEYCODE_LEFT = 37;
const KEYCODE_RIGHT = 39;

interface Props {
  buttonWidth?: number;
  children?: ReactNode;
  file?: string;
  showAll?: boolean;
  zoom?: boolean;
  zoomed?: boolean;
  overlayOnly?: boolean;
  onCloseOverlay?: () => void;
}

const containerId = 'containerElement';

export const PDFPreview = (props: Props) => {
  const {
    buttonWidth,
    children,
    file,
    showAll,
    overlayOnly,
    onCloseOverlay,
    zoom = true,
    zoomed = false,
  } = props;
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [errorLoading, setErrorLoading] = useState(false);
  const [showOverlay, setShowOverlay] = useState(overlayOnly);
  const [overlayContainerSet, setOverlayContainerSet] = useState(false);

  useEffect(() => {
    if (zoomed) {
      renderContainer();
    }
  });

  const getContainer = () => {
    if (!overlayContainerSet) {
      const container = document.createElement('div');
      container.setAttribute('id', containerId);
      document.body.appendChild(container);
      addListeners();
      setOverlayContainerSet(true);
      return container;
    }

    const container = document.getElementById(containerId);
    return container;
  };

  const getFile = () => (file ? { url: file } : null);

  const addListeners = () => {
    document.body.addEventListener('keyup', handleKeyPress, true);
  };

  const removeListeners = () => {
    document.body.removeEventListener('keyup', handleKeyPress, true);
  };

  const handleDocumentLoad = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (overlayContainerSet) {
      switch (event.keyCode) {
        case KEYCODE_ESC:
          removeContainer();
          event.stopPropagation();
          break;

        case KEYCODE_LEFT:
          if (hasPagePrev()) {
            pagePrev();
          }
          break;

        case KEYCODE_RIGHT:
          if (hasPageNext()) {
            pageNext();
          }
          break;

        default:
      }
    }
  };

  const pageNext = () => {
    setPage(page + 1);
    renderContainer();
  };

  const pagePrev = () => {
    setPage(Math.max(1, page - 1));
    renderContainer();
  };

  const hasPageNext = () => page < numPages;

  const hasPagePrev = () => page > 1;

  const removeContainer = () => {
    if (overlayContainerSet) {
      const container = document.getElementById(containerId);
      if (container) {
        document.body.removeChild(container);
        removeListeners();
      }
      if (onCloseOverlay) {
        onCloseOverlay();
      }
      setShowOverlay(false);
    }
  };

  const renderContainer = () => {
    const container = getContainer();
    if (!errorLoading && zoom && container) {
      ReactDOM.render(renderOverlay(), container);
    }
  };

  const renderOverlay = () => (
    <div className="pdf-preview" onClick={removeContainer}>
      <div className="pdf-content">
        <DeleteIcon className="pdf-close" tag="button" />
        <Document file={getFile()} onLoadSuccess={handleDocumentLoad}>
          <Page pageNumber={page} scale={0.75} />
        </Document>
      </div>
      <div className="pdf-pagination" onClick={(e) => e.stopPropagation()}>
        {hasPagePrev() && (
          <Button onClick={pagePrev}> {translate('global.prev')} </Button>
        )}
        &nbsp;
        {hasPageNext() && (
          <Button onClick={pageNext}> {translate('global.next')} </Button>
        )}
      </div>
    </div>
  );

  const renderAllPages = () => {
    if (showAll) {
      return Array.from([numPages], (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
      ));
    }
    return <Page pageNumber={page} width={buttonWidth} />;
  };

  return (
    <>
      {showOverlay && renderContainer()}
      {!overlayOnly && (
        <div
          className={!errorLoading ? 'clickable' : ''}
          onClick={() => setShowOverlay(true)}
        >
          {children || (
            <Document
              loading={<Spinner />}
              file={getFile()}
              onLoadError={() => setErrorLoading(true)}
              onLoadSuccess={({ numPages }: { numPages: number }) => {
                setNumPages(numPages);
                setErrorLoading(false);
              }}
            >
              {numPages && renderAllPages()}
            </Document>
          )}
        </div>
      )}
    </>
  );
};

export default PDFPreview;
