import { Enum } from './Enum';

export class EventReporterType extends Enum {
  translationKey = 'eventReporterType';

  static asArray: EventReporterType[] = [];

  static byKey: { [name: string]: EventReporterType } = {};

  static readonly MEMBER = new EventReporterType('MEMBER');

  static readonly CARE_TEAM = new EventReporterType('CARE_TEAM');

  static readonly COMMUNITY_RESOURCE = new EventReporterType(
    'COMMUNITY_RESOURCE',
  );

  constructor(public readonly value: string) {
    super();
    EventReporterType.asArray.push(this);
    EventReporterType.byKey[value] = this;
  }
}

export default EventReporterType;
