import { Card } from '@mui/material';
import React, { useState } from 'react';
import { Colors, Panel, Tabs } from 'styleguide-v2';
import { BackgroundColors } from 'styleguide-v2/src/styles/Colors';

const renderColor = (color: string, hex: string) => (
  <React.Fragment key={color}>
    <div className="flex grid-span-2">{color}</div>
    <div className="color grid-span-3" style={{ backgroundColor: hex }} />
    <div className="flex grid-span-2" style={{ color: hex }}>
      Text
    </div>
    <div
      className="flex grid-span-2"
      style={{ backgroundColor: hex, color: Colors.white, padding: '1rem' }}
    >
      Text
    </div>
    <pre className="grid-span-3">{hex}</pre>
  </React.Fragment>
);

const StyleguideColors = () => {
  const tabs = [
    { value: 1, label: 'Main' },
    { value: 2, label: 'Background' },
  ];
  const [tab, setTab] = useState(1);

  return (
    <Panel>
      <Panel.Heading title="Colors">
        <Panel.Tabs>
          <Tabs
            className="bottom"
            value={tab}
            items={tabs}
            onChange={(newValue) => setTab(newValue)}
          />
        </Panel.Tabs>
      </Panel.Heading>
      <Panel.Body>
        {tab === 1 && (
          <Card
            className="grid-wrapper"
            sx={{ padding: '2rem', height: 'max-content' }}
          >
            <h4 className="grid-span-12">Main Colors</h4>
            {Object.entries(Colors).map(([color, hex]) =>
              renderColor(color, hex),
            )}
          </Card>
        )}
        {tab === 2 && (
          <Card
            className="grid-wrapper"
            sx={{ padding: '2rem', height: 'max-content' }}
          >
            <h4 className="grid-span-12">Background Colors</h4>
            {Object.entries(BackgroundColors).map(([color, hex]) =>
              renderColor(color, hex),
            )}
          </Card>
        )}
      </Panel.Body>
    </Panel>
  );
};

export default StyleguideColors;
