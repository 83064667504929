import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import { Colors } from 'styleguide';

interface Props {
  hasNew: boolean;
}

const useStyles = makeStyles({
  notification: {
    border: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: Colors.white,
    width: 38,
    height: 38,
    fontSize: 14,
  },
  text: {
    display: 'flex',
    width: '100%',
    height: '100%',
    letterSpacing: 1.5,
    flexDirection: 'column',
    justifyContent: 'center',
    userSelect: 'none',
    color: Colors.green,
    textDecoration: 'none',
  },
  unreadIcon: {
    position: 'absolute',
    left: 20,
    top: 10,
    backgroundColor: Colors.red,
    width: 8,
    height: 8,
    borderRadius: '50%',
  },
});
const NotificationIcon = (props: Props) => {
  const { hasNew } = props;
  const styles = useStyles();
  return (
    <div>
      {hasNew && <span className={styles.unreadIcon}>&nbsp;</span>}
      <div className={`${styles.notification}`}>
        <i className={classNames(styles.text, 'fa fa-bell')} />
      </div>
    </div>
  );
};

export default NotificationIcon;
