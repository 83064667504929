import { Enum, Selectable } from './Enum';

// Renamed in API to DataSource. todo: rename here
export class DataSource extends Enum {
  translationKey = 'assessmentSource';

  static asArray: DataSource[] = [];

  static byKey: { [name: string]: DataSource } = {};

  static readonly PAYER = new DataSource('PAYER');

  static readonly PATIENT = new DataSource('PATIENT');

  static readonly POC = new DataSource('POC');

  static readonly CLAIMS = new DataSource('CLAIMS');

  static readonly CLIENT_EMR = new DataSource('CLIENT_EMR');

  static readonly HIE_VENDOR = new DataSource('HIE_VENDOR');

  static readonly MDS = new DataSource('MDS');

  static readonly DS = new DataSource('DS');

  static readonly CARE_MANAGER = new DataSource('CARE_MANAGER');

  static readonly AIDE = new DataSource('AIDE');

  static readonly UAS = new DataSource('UAS', true);

  static readonly RNA = new DataSource('RNA', true);

  static readonly SALESFORCE = new DataSource('SALESFORCE', true);

  static readonly UNKNOWN = new DataSource('UNKNOWN', true); // Backfills only

  static readonly HEALTH_ALERT = new DataSource('HEALTH_ALERT', true); // Backfills only

  constructor(
    public readonly value: string,
    public readonly deprecated = false,
  ) {
    super();
    DataSource.asArray.push(this);
    DataSource.byKey[value] = this;
  }

  static toSelectable(): Selectable[] {
    return DataSource.asArray.map((enumValue) => ({
      value: enumValue.value,
      label: enumValue.toString(),
      disabled: enumValue.deprecated,
    }));
  }

  static notEditable(source?: DataSource) {
    return source === DataSource.HEALTH_ALERT;
  }
}

export default DataSource;
