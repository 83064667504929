import classnames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

import IncidentIcon from '@mui/icons-material/CrisisAlert';
import ReportIcon from '@mui/icons-material/Report';
import { ClassNameMap, makeStyles } from '@mui/styles';

import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  EventDetail,
  EventModel,
  Patient,
} from '@vestahealthcare/common/models';
import EventStatus from '@vestahealthcare/common/models/EventStatus';
import {
  DATE_FORMAT_SHORT,
  DATE_FORMAT_SHORT_WITH_TIME,
} from '@vestahealthcare/common/utils/constants';

import {
  Actions,
  Chip,
  Colors,
  Select,
  SelectAssignee,
  StatusIndicator,
  Table,
  TableItem,
  Tooltip,
  UNASSIGNED,
} from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import Session from 'dash/src/services/SessionServices';

import { EventActions } from './types/EventActions';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';

const getUrgencyColor = (urgencyLevel?: number) => {
  if (urgencyLevel === 1) {
    return Colors.textRed;
  }
  if (urgencyLevel === 2) {
    return Colors.gold;
  }
  if (urgencyLevel === 3) {
    return Colors.green;
  }
  return Colors.gray;
};

type TableProps = {
  employees: Employee[];
  eventStatuses: EventStatus[];
  groups: EmployeeGroup[];
  member?: Patient;
};

type Props = {
  className?: string;
  events: EventTableModel[];
  loading?: boolean;
  member?: Patient;
  onClickRow?: (evt: EventTableModel, idx: number) => void;
  onChangeAssignee: (
    id: number,
    employee: Employee | EmployeeGroup,
  ) => Promise<void>;
  onChangeStatus: (id: number, status: EventStatus) => void;
  onEventAction: (action: Selectable, event: EventModel, index: number) => void;
};

const OTHER = 'OTHER';

const isOverdue = (date: Moment, hasTime?: boolean) => {
  let data = date;
  if (hasTime === false) {
    data = data.endOf('day');
  }

  return data.isBefore();
};

const MEMBER_EVENTS_COLUMNS = (
  styles: ClassNameMap<
    | 'urgentContainer'
    | 'greenSelect'
    | 'redText'
    | 'bold'
    | 'lineThrough'
    | 'incidentChip'
  >,
  { employees, eventStatuses, groups, member }: TableProps,
  { onChangeAssignee, onChangeStatus, onEventAction }: Props,
  flags: LDFlagSet,
) => [
  {
    component: (evt: EventTableModel) => (
      <div className={styles.urgentContainer}>
        {evt.urgent && (
          <Tooltip
            text={
              evt.urgencyLevel
                ? translate('memberEvents.table.urgencyLevel', {
                    count: evt.urgencyLevel,
                  })
                : translate('memberEvents.table.urgent')
            }
          >
            <ReportIcon
              fontSize="large"
              style={{ color: getUrgencyColor(evt.urgencyLevel) }}
            />
          </Tooltip>
        )}
        <div>
          <p className={classnames(styles.bold, 'flex gap')}>
            <span>{evt.type.name}</span>
            {flags.showIncidents &&
              evt.detailData instanceof EventDetail &&
              evt.detailData.allowIncident &&
              !evt.detailData.hasIncident && (
                <Chip
                  className={styles.incidentChip}
                  color="error"
                  type="contained-2"
                  title={translate('events.incidentTooltip')}
                >
                  <IncidentIcon sx={{ fontSize: 14, display: 'flex' }} />
                </Chip>
              )}
          </p>
          <p>
            {evt.datetime.format(
              evt.hasTime ? DATE_FORMAT_SHORT_WITH_TIME : DATE_FORMAT_SHORT,
            )}
          </p>
        </div>
      </div>
    ),
    width: 180,
  },
  {
    component: ({
      id,
      status,
      isClosed,
      invalidatedReason,
      invalidNote,
    }: EventTableModel) =>
      isClosed ? (
        <StatusIndicator
          color={EventStatus.getColor(status)}
          status={status.description}
          subtitle={
            invalidatedReason
              ? `${
                  invalidatedReason.id === OTHER
                    ? invalidNote || ''
                    : invalidatedReason.description
                }`
              : ''
          }
        />
      ) : (
        <Select
          className={
            status.id === EventStatus.NOT_STARTED ? styles.greenSelect : ''
          }
          disableClearable
          getItemLabel={({ description }: EventStatus) => description}
          items={eventStatuses}
          key={`event-${id + 1}-status-${Date.now()}`}
          onChange={(val: EventStatus) => onChangeStatus(id, val)}
          size="small"
          value={status}
        />
      ),
    width: 255,
  },
  {
    component: ({ numberOfIssues }: EventModel) => (
      <p>
        {translate('memberEvents.table.numberOfIssues', {
          count: numberOfIssues,
        })}
      </p>
    ),
    width: 100,
  },
  {
    component: ({
      followUpDatetime,
      followUpDatetimeHasTime,
      isClosed,
    }: EventModel) =>
      followUpDatetime ? (
        <div>
          <p
            className={classnames(
              styles.bold,
              !isClosed &&
                isOverdue(followUpDatetime, followUpDatetimeHasTime) &&
                styles.redText,
            )}
          >
            {translate('memberEvents.table.followUpDate')}
          </p>
          <p
            className={classnames(
              !isClosed &&
                isOverdue(followUpDatetime, followUpDatetimeHasTime) &&
                styles.redText,
              isClosed && styles.lineThrough,
            )}
          >
            {followUpDatetime.format(
              followUpDatetimeHasTime
                ? DATE_FORMAT_SHORT_WITH_TIME
                : DATE_FORMAT_SHORT,
            )}
          </p>
        </div>
      ) : (
        <></>
      ),
  },
  {
    component: ({ id, assignee, assigneeGroup, isClosed }: EventTableModel) =>
      isClosed ? (
        assignee?.fullName || assigneeGroup?.name
      ) : (
        <SelectAssignee
          allowUnassigned
          assignee={assignee || assigneeGroup || UNASSIGNED}
          currentUser={Session.actingUser}
          key={`event-${id}-assignee`}
          items={[...groups, ...employees]}
          member={member}
          onChange={async (val?: Employee | EmployeeGroup) => {
            if (val) {
              await onChangeAssignee(id, val);
            }
          }}
          size="small"
        />
      ),
    width: 250,
  },
  {
    component: (event: EventTableModel, index: number) => {
      const { actionLoading } = event;
      const { actingUser } = Session;

      return (
        <Actions
          actions={Enum.toSelectable(
            EventActions.asActionsArray(
              (event?.detailData || event) as EventDetail,
              actingUser,
              member,
              false,
              false,
              flags,
            ),
          )}
          data-cy={`event-item-${index}-settings`}
          loading={actionLoading}
          onActionSelected={(action) => onEventAction(action, event, index)}
        />
      );
    },
    width: 40,
  },
];

const useStyles = makeStyles({
  urgentContainer: {
    display: 'flex',
    '& > svg': {
      left: '-4.875rem',
      margin: 'auto -2.65rem auto 0',
      position: 'relative',
    },
  },
  bold: {
    fontWeight: 500,
  },
  incidentChip: {
    '&&': {
      padding: '0.1rem',
    },
  },
  lineThrough: {
    textDecoration: 'line-through',
  },
  greenSelect: {
    '& .MuiOutlinedInput-root': {
      background: Colors.green,
    },
    '& input': {
      color: Colors.white,
    },
    '& svg': {
      color: Colors.white,
    },
  },
  redText: {
    color: Colors.textRed,
    fontWeight: 500,
  },
  urgentRowUnknown: {
    '&:first-child': {
      borderLeft: `3px solid ${Colors.gray}`,
    },
  },
  urgentRow1: {
    '&:first-child': {
      borderLeft: `3px solid ${Colors.textOrange}`,
    },
  },
  urgentRow2: {
    '&:first-child': {
      borderLeft: `3px solid ${Colors.gold}`,
    },
  },
  urgentRow3: {
    '&:first-child': {
      borderLeft: `3px solid ${Colors.green}`,
    },
  },
  table: {
    '&& > tbody > tr:not(.detail) > td:last-child': {
      paddingLeft: 0,
    },
  },
});

export interface EventTableModel extends EventModel, TableItem {
  actionLoading?: boolean;
}

export const MemberEventsTable = ({
  className,
  events,
  loading,
  member,
  onClickRow,
  ...others
}: Props) => {
  const styles = useStyles();
  const flags = useFlags();

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [eventStatuses, setEventStatuses] = useState<EventStatus[]>([]);
  const [groups, setGroups] = useState<EmployeeGroup[]>([]);

  const getRowClass = ({ urgent, urgencyLevel }: EventTableModel) =>
    classnames(
      urgent && !urgencyLevel && styles.urgentRowUnknown,
      urgent && urgencyLevel === 1 && styles.urgentRow1,
      urgent && urgencyLevel === 2 && styles.urgentRow2,
      urgent && urgencyLevel === 3 && styles.urgentRow3,
    );

  const getInitialData = async () => {
    const employees = await CacheServices.getEmployees();
    setEmployees(employees);

    const eventStatuses = await CacheServices.getEventStatuses();
    setEventStatuses(
      eventStatuses.filter(({ isClosedStatus }) => !isClosedStatus),
    );

    const groups = await CacheServices.getEmployeeGroupsAssignee();
    setGroups(groups);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <Table
      bottomLoading={loading}
      className={classnames(styles.table, className)}
      config={{
        data: events,
        columns: MEMBER_EVENTS_COLUMNS(
          styles,
          { employees, eventStatuses, groups, member },
          { events, onClickRow, ...others },
          flags,
        ),
        detail: true,
        getRowClass,
        paginationOptions: [-1],
      }}
      onClickRow={onClickRow}
    />
  );
};

export default MemberEventsTable;
