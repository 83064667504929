import React, { Fragment } from 'react';

import { PhoneType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { CommunityResource } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Button, PhoneLink, Table } from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

interface Props {
  className?: string;
  disabled?: number[];
  crs: CommunityResource[];
  onSelect?: (group: CommunityResource) => void;
}

const CR_COLUMNS = ({ disabled, onSelect }: Props) => {
  const colums: Column[] = [
    {
      headerName: translate('communityResources.table.name'),
      component: (cr: CommunityResource) => (
        <Fragment key={`search-cr-info-${cr.id}`}>
          <span>{`${cr.name} (${cr.id})`}</span>
        </Fragment>
      ),
    },
    {
      headerName: translate('communityResources.table.contactInfo'),
      component: ({ fax, phone, address }: CommunityResource) => (
        <>
          {phone && (
            <PhoneLink phone={phone} type={PhoneType.BUSINESS.toString()} />
          )}
          {fax && <PhoneLink phone={fax} type={PhoneType.FAX.toString()} />}
          {!fax && !phone && EMPTY}
          <p className="no-margin" style={{ fontSize: '0.875em' }}>
            {[
              address?.address1,
              address?.address2,
              address?.city,
              address?.state?.value,
              address?.zipCode,
            ]
              .filter(Boolean)
              .join(', ')}
          </p>
        </>
      ),
    },
    {
      headerName: ' ',
      component: (ctp: CommunityResource) => (
        <Button
          color="secondary"
          disabled={disabled?.includes(ctp.id)}
          onClick={() => onSelect && onSelect(ctp)}
          type="outlined"
          size="s"
        >
          {translate('global.continue')}
        </Button>
      ),
      width: 20,
    },
  ];

  return colums;
};

export const SearchTable = (props: Props) => (
  <Table
    className={props.className}
    config={{
      columns: CR_COLUMNS(props),
      compact: true,
      data: props.crs,
      paginationOptions: [-1],
    }}
  />
);

export default SearchTable;
