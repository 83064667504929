import React from 'react';
import { translate } from '@vestahealthcare/common/i18n';

interface Props {
  children?: any; // TODO: why must we do this?
  show?: boolean;
}

export const EmptyState = (props: Props) => {
  const { show = true } = props;
  if (!show) {
    return null;
  }

  return (
    <div className="empty-state" title={translate('global.noResults')}>
      <div className="italic">{props.children}</div>
    </div>
  );
};

export default EmptyState;
