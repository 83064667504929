import { Moment } from 'moment';
import React, { Fragment, useEffect, useState } from 'react';

import HelpIcon from '@mui/icons-material/HelpOutline';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  Brand,
  Organization,
  OrganizationStatuses,
  OrganizationType,
} from '@vestahealthcare/common/models';
import moment from '@vestahealthcare/common/moment';
import { isValidMail } from '@vestahealthcare/common/utils/format';

import {
  Button,
  Checkbox,
  DateTimePicker,
  DeleteIcon,
  Modal,
  Select,
  TextInput,
} from 'styleguide';
import { Tooltip } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CacheServices } from 'dash/src/services';
import {
  OrganizationParams,
  addOrganization,
  editOrganization,
  fetchOrganizationStatuses,
  fetchOrganizationTypes,
  fetchOrganizations,
} from 'dash/src/services/OrganizationServices';

interface Props {
  referral?: Organization;
  onHide: () => void;
  onSubmit: () => void;
}

const useStyles = makeStyles({
  firstIcon: {
    marginTop: '6.25rem',
  },
  icon: {
    marginTop: '1.25rem',
  },
});

export const AddEditReferralModal = (props: Props) => {
  const styles = useStyles();
  const { referral, onHide, onSubmit } = props;

  const [referralsList, setReferralsList] = useState<Organization[]>([]);
  const [typesList, setTypesList] = useState<OrganizationType[]>([]);
  const [statusesList, setStatusesList] = useState<OrganizationStatuses[]>([]);
  const [brandList, setBrandList] = useState<Brand[]>([]);

  const [name, setName] = useState('');
  const [abbr, setAbbr] = useState('');
  const [brand, setBrand] = useState<string>();
  const [affiliation, setAffiliation] = useState<Organization>();
  const [type, setType] = useState<OrganizationType>();
  const [status, setStatus] = useState<OrganizationStatuses>();
  const [startDate, setStartDate] = useState<Moment | undefined>();
  const [endDate, setEndDate] = useState<Moment | undefined>();
  const [medicareDiscovery, setMedicareDiscovery] = useState(false);
  const [automatedIncidentReporting, setAutomatedIncidentReporting] = useState(
    false,
  );
  const [mails, setMails] = useState<string[]>(['']);
  const [sendCareManagersMail, setSendCareManagersMail] = useState(false);
  const [rpmOutsourcing, setRPMOutsourcing] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (referral?.id) {
      setName(referral.name);
      setAbbr(referral.abbr || abbr);
      setBrand(referral.brandId || brand);
      setAffiliation(referral.parent || affiliation);
      setType(referral?.type || type);
      setStatus(referral?.status || status);
      setStartDate(moment(referral?.startDate) || moment());
      setEndDate(referral.endDate ? moment(referral?.endDate) : undefined);
      setMedicareDiscovery(referral?.medicareDiscovery || medicareDiscovery);
      setAutomatedIncidentReporting(referral?.incidentAuto);
      setMails(referral?.emails?.length ? referral?.emails : ['']);
      setSendCareManagersMail(referral?.incidentCareManagers);
      setRPMOutsourcing(!!referral?.rpmOutsourcing);
    }
  }, [referral]);

  useEffect(() => {
    const fetchData = async () => {
      const [
        organizations,
        organizationTypes,
        organizationStatuses,
        brands,
      ] = await Promise.all([
        fetchOrganizations(),
        fetchOrganizationTypes(),
        fetchOrganizationStatuses(),
        CacheServices.getOrganizationBrands(),
      ]);
      setReferralsList(organizations);
      setTypesList(organizationTypes);
      setStatusesList(organizationStatuses);
      setBrandList(brands);
    };
    fetchData();
  }, []);

  const submit = async () => {
    if (
      !name ||
      !type ||
      !status ||
      !startDate ||
      !brand ||
      (automatedIncidentReporting &&
        !sendCareManagersMail &&
        !!mails.filter((mail) => {
          return !isValidMail(mail);
        })?.length)
    ) {
      setShowError(true);
      return;
    }

    const params: OrganizationParams = {
      abbr,
      affiliation,
      brand,
      emails: automatedIncidentReporting
        ? mails.filter((mail) => {
            return isValidMail(mail);
          })
        : [],
      endDate,
      incidentAuto: automatedIncidentReporting,
      incidentCareManagers: automatedIncidentReporting && sendCareManagersMail,
      medicareDiscovery,
      name,
      rpmOutsourcing,
      startDate,
      status,
      type,
    };

    setLoading(true);
    try {
      if (referral) {
        await editOrganization(referral.id, params);
      } else {
        await addOrganization(params);
      }
      await onSubmit();
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  return (
    <Modal onHide={onHide} show>
      <Modal.Header
        closeButton
        modalTitle={translate(`referrals.modal.${referral ? 'edit' : 'add'}`)}
      />
      <Modal.Body className="grid-wrapper">
        <TextInput
          data-cy="referral-name"
          disabled={!!referral}
          columns={6}
          errorText={translate('global.missingRequiredField')}
          label={translate('referrals.name')}
          onChange={(value: string | undefined) => setName(value || '')}
          value={name}
          required
          showError={showError && !name}
        />
        <div className="grid-span-6" />
        <TextInput
          data-cy="referral-abbr"
          disabled={!!referral?.abbr}
          columns={6}
          label={translate('referrals.abbr')}
          onChange={(value: string | undefined) => setAbbr(value || '')}
          value={abbr}
        />
        <div className="grid-span-6" />
        <Select
          data-cy="referral-brand"
          columns={6}
          errorText={translate('global.missingRequiredField')}
          label={translate('referrals.brand')}
          onChange={(value: string) =>
            setBrand(brandList?.find(({ id }) => id === value)?.id)
          }
          options={brandList?.map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
          value={brand}
          showError={showError && !brand}
          required
        />
        <Select
          data-cy="referral-affiliation"
          columns={6}
          errorText={translate('global.missingRequiredField')}
          label={translate('referrals.affiliation')}
          onChange={(value: number) =>
            setAffiliation(referralsList.find((ref) => ref.id === value))
          }
          options={referralsList.map((ref) => ({
            label: ref.name,
            value: ref.id,
          }))}
          value={affiliation?.id}
          showError={showError && !affiliation}
        />
        <Select
          data-cy="referral-type"
          columns={12}
          errorText={translate('global.missingRequiredField')}
          label={translate('referrals.type')}
          onChange={(value: number) =>
            setType(typesList.find((ref) => ref.id === value))
          }
          options={typesList.map((ref) => ({
            label: ref.name,
            value: ref.id,
          }))}
          value={type?.id}
          required
          showError={showError && !type}
        />
        <Select
          data-cy="referral-status"
          columns={12}
          errorText={translate('global.missingRequiredField')}
          label={translate('referrals.status')}
          onChange={(value: number) =>
            setStatus(statusesList.find((ref) => ref.id === value))
          }
          options={statusesList.map((ref) => ({
            label: ref.name,
            value: ref.id,
          }))}
          value={status?.id}
          required
          showError={showError && !status}
        />
        <Checkbox
          data-cy="referral-medicare"
          columns={12}
          checked={medicareDiscovery}
          label={translate('referrals.medicare')}
          onChange={setMedicareDiscovery}
          disabled={referral && referral.hasChildren}
        />
        <DateTimePicker
          columns={6}
          data-cy="referral-startdate"
          errorText={translate('global.missingRequiredField')}
          label={translate('referrals.startDate')}
          onChange={setStartDate}
          value={startDate}
          required
          showError={showError && !startDate}
        />
        <DateTimePicker
          columns={6}
          data-cy="referral-enddate"
          errorText={translate('global.missingRequiredField')}
          label={translate('referrals.endDate')}
          onChange={setEndDate}
          value={endDate}
          showError={showError && !endDate}
        />
        <Checkbox
          data-cy="referral-automated-incident-reporting"
          columns={12}
          checked={automatedIncidentReporting}
          label={translate('referrals.automatedIncidentReporting')}
          onChange={setAutomatedIncidentReporting}
        />

        {automatedIncidentReporting && (
          <>
            <Checkbox
              data-cy="referral-mail-care-manangers"
              columns={12}
              checked={sendCareManagersMail}
              label={
                <>
                  {translate('referrals.careManagersMail')}
                  <span>&nbsp;</span>
                  <Tooltip
                    text={translate('referrals.careManagersMailTooltip')}
                  >
                    <HelpIcon color="action" />
                  </Tooltip>
                </>
              }
              onChange={setSendCareManagersMail}
            />
            {mails.map((mail, index) => (
              <Fragment key={`referral-mail-${index}`}>
                <TextInput
                  columns={11}
                  data-cy={`referral-mail-${index}`}
                  errorText={translate('common.errors.invalidEmail')}
                  label={index === 0 ? translate('referrals.mail') : ''}
                  onChange={(value: string | undefined) => {
                    mails[index] = value || '';
                    setMails([...mails]);
                  }}
                  required={!sendCareManagersMail}
                  showError={
                    showError && !sendCareManagersMail && !isValidMail(mail)
                  }
                  value={mail}
                />

                <DeleteIcon
                  className={index === 0 ? styles.firstIcon : styles.icon}
                  onClick={() => {
                    if (mails?.length === 1) {
                      setMails(['']);
                    } else {
                      mails.splice(index, 1);
                      setMails([...mails]);
                    }
                  }}
                />
              </Fragment>
            ))}
            <Button
              className="grid-span-12"
              color="tertiary"
              onClick={() => setMails([...mails, ''])}
            >
              {translate('referrals.addMail')}
            </Button>
          </>
        )}
        <Checkbox
          data-cy="referral-rpm-outsourcing"
          columns={12}
          checked={rpmOutsourcing}
          label={
            <>
              {translate('referrals.rpmOutsourcing')}
              <span>&nbsp;</span>
              <Tooltip text={translate('referrals.rpmOutsourcingTooltip')}>
                <HelpIcon color="action" />
              </Tooltip>
            </>
          }
          onChange={setRPMOutsourcing}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button color="secondary" onClick={onHide}>
          {translate('global.cancel')}
        </Button>
        <Button
          async
          color="primary"
          loading={loading}
          onClick={submit}
          data-cy="add-referral"
        >
          {translate('referrals.modal.save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEditReferralModal;
