import React from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { PhoneUser } from '@vestahealthcare/common/models';
import { PhoneUserInfo } from '@vestahealthcare/common/models/PhoneUser';

import { Colors } from 'styleguide-v2';

type Props = {
  users: PhoneUser[];
};

const useStyles = makeStyles({
  title: {
    fontWeight: 500,
    color: Colors.textGray,
  },
  subtitle: {
    color: Colors.textGray,
    fontWeight: 400,
    margin: 0,
  },
});

export const ChatUsers = ({ users }: Props) => {
  const styles = useStyles();

  const getType = (user: PhoneUserInfo) => {
    if (user.isCareTeamPerson()) return 'careTeam';
    if (user.isCaregiver()) return 'caregiver';
    return 'member';
  };

  return (
    <div>
      <p className={styles.title}>{translate('chat.otherUsers')}</p>
      {users.map(({ id, user }) => (
        <p key={id} className={styles.subtitle}>
          {user.fullName}
          {' ('}
          {translate(`chat.${getType(user)}`)}{' '}
          {user.isMember() && (
            <a target="blank" rel="noreferrer" href={`#/patients/${user.id}`}>
              {user.id}
            </a>
          )}
          {user.isCareTeamPerson() && (
            <a target="blank" rel="noreferrer" href={`#/care-team/${user.id}`}>
              {user.id}
            </a>
          )}
          {user.isCaregiver() && user.id}
          {') '}
        </p>
      ))}
    </div>
  );
};

export default ChatUsers;
