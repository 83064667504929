import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';

import ContactPageIcon from '@mui/icons-material/ContactPage';
import { makeStyles } from '@mui/styles';

import {
  AsyncTaskLeadStatus,
  AsyncTaskType,
  CareTeamRosterTaskStatus,
} from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  AsyncTask,
  CareTeamRosterTask,
  Organization,
  PaginationType,
} from '@vestahealthcare/common/models';

import { BulletedList, Text } from 'styleguide';
import {
  Colors,
  IconButton,
  Modal,
  Panel,
  Select,
  ToggleButton,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import {
  GetTaskHistoryParams,
  getTaskCareTeamHistory,
  getTaskMemberHistory,
  releaseToSF,
} from 'dash/src/services/AsyncServices';
import CacheServices from 'dash/src/services/CacheServices';
import { bulkMemberUpload } from 'dash/src/services/PatientServices';
import {
  ValidationResponse,
  bulkCTUpload,
  bulkCTValidate,
  bulkMemberValidate,
} from 'dash/src/services/RosterServices';
import Session from 'dash/src/services/SessionServices';

import { CSVUpload } from './CSVUpload';
import { FileContentTable } from './FileContentTable';
import { FileInfoPanel } from './FileInfoPanel';
import {
  PreUploadCareTeamColumns,
  PreUploadMemberColumns,
} from './MemberTableColumns';
import { UploadHistoryTable } from './UploadHistoryTable';
import { getSchemaDiff } from './utils';

interface SchemaDiff {
  extraAttrs: string[];
  missingAttrs: string[];
}

interface SelectableOrganization extends Selectable {
  item?: Organization;
}

export const DEFAULT_VALIDATION_RESP: ValidationResponse = {
  info: [],
  errors: [],
  warnings: [],
};

const FILE_ROW_LIMIT = 5000;

export interface CSVFileInfo {
  file: File;
  fileContents: any[];
  headers: string[];
  uuid?: string;
  isCareTeamFile?: boolean;
}

async function uploadFile(
  fileInfo: CSVFileInfo,
  options: {
    applyFutureLead?: boolean;
    description?: string;
    applyTermByAbsence?: boolean;
    rawArchiveId?: number;
    shouldReplaceCT?: boolean;
  },
) {
  if (fileInfo.isCareTeamFile) {
    await bulkCTUpload(
      fileInfo.file,
      options.description || '',
      !!options.shouldReplaceCT,
    );
  } else {
    await bulkMemberUpload(
      fileInfo.file,
      !!options.applyFutureLead,
      !!options.applyTermByAbsence,
      options.rawArchiveId,
    );
  }
}

interface PreuploadChecks {
  isFileTooLarge: boolean;
  isFileEmpty: boolean;
  schemaDiff?: SchemaDiff;
}

function renderPreuploadIssues({
  isFileTooLarge,
  isFileEmpty,
  schemaDiff,
}: PreuploadChecks) {
  return (
    <>
      {isFileTooLarge && (
        <Text bold color="error" className="grid-span-12">
          {translate('rosterIngestion.fileSizeError', {
            limit: FILE_ROW_LIMIT,
          })}
        </Text>
      )}
      {isFileEmpty && (
        <Text bold color="error" className="grid-span-12">
          {translate('rosterIngestion.fileEmptyError')}
        </Text>
      )}
      {!!schemaDiff?.missingAttrs.length && (
        <>
          <Text bold color="error" className="grid-span-12">
            {translate('rosterIngestion.missingColumnsError')}
          </Text>
          <BulletedList
            items={schemaDiff.missingAttrs}
            className="grid-span-12"
          />
        </>
      )}
      {!!schemaDiff?.extraAttrs.length && (
        <>
          <Text bold color="warning" className="grid-span-12">
            {translate('rosterIngestion.extraColumnsWarning')}
          </Text>
          <BulletedList
            items={schemaDiff.extraAttrs}
            className="grid-span-12"
          />
        </>
      )}
    </>
  );
}

const useStyles = makeStyles({
  clearButton: {
    marginTop: 'auto',
  },
  filterBar: {
    '&&': {
      borderBottom: `1px solid ${Colors.lighterGray}`,
      paddingBottom: '1rem',
    },
  },
  fileInput: {
    height: '15rem',
  },
  toggle: {
    '& > div': {
      height: '3rem',

      '& > button': {
        minWidth: '5rem',
      },
    },
  },
  whiteBackground: {
    backgroundColor: Colors.white,
    transition: 'none!important',
  },
});

const ROSTER_TAB_MEMBER = 'roster-ingestion-member-tab';
const ROSTER_TAB_CARE_TEAM = 'roster-ingestion-care-team-tab';
const ROSTER_TABS_ITEMS = [
  {
    label: translate('rosterIngestion.tabs.memberIngestion'),
    value: ROSTER_TAB_MEMBER,
  },
  {
    label: translate('rosterIngestion.tabs.careTeamIngestion'),
    value: ROSTER_TAB_CARE_TEAM,
  },
];

export default function MemberIngestion() {
  const styles = useStyles();
  const { disableAddMembers, showCareTeamRoster } = useFlags();
  const { canAddCareTeamRoster } = Session.actingUser;

  const [fileInfo, setFileInfo] = useState<CSVFileInfo>();
  const [validating, setValidating] = useState<boolean>(false);
  const [isFileTooLarge, setIsFileTooLarge] = useState<boolean>(false);
  const [isFileEmpty, setIsFileEmpty] = useState<boolean>(false);
  const [validationResponse, setValidationResponse] = useState(
    DEFAULT_VALIDATION_RESP,
  );
  const [items, setItems] = useState<(AsyncTask | CareTeamRosterTask)[]>([]);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [errorValidate, setErrorValidate] = useState<boolean>(false);
  const [schemaDiff, setSchemaDiff] = useState<SchemaDiff>();
  const [filters, setFilters] = useState<GetTaskHistoryParams>({});
  const [pagination, setPagination] = useState<PaginationType>({
    limit: 10,
    offset: 0,
    total: 0,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const [selectedTab, setSelectedTab] = useState<string>(ROSTER_TAB_MEMBER);

  const [referralSources, setReferralSources] = useState<
    SelectableOrganization[]
  >([]);

  const [openReleaseToSFModal, setOpenReleaseToSFModal] = useState<boolean>(
    false,
  );
  const [releaseToSFModalTask, setReleaseToSFModalTask] = useState<AsyncTask>();

  const getInitialData = async () => {
    setLoadingData(true);
    const [o] = await Promise.all([CacheServices.getOrganizations()]);
    const referralsOptions = o
      .filter(({ isActiveReferral }) => isActiveReferral)
      .map((item) => ({
        label: item.abbr || item.name,
        value: item.id,
        item,
      }));
    setReferralSources([
      { value: 0, label: translate('global.noReferral') },
      ...referralsOptions,
    ]);
    setLoadingData(false);
  };

  const fetchData = async (limit: number, offset: number) => {
    if (offset && offset < items.length) {
      setPagination({
        limit: pagination.limit,
        offset,
        total: pagination.total,
      });
      return;
    }

    setLoading(true);
    const { items: newItems, pagination: newPagination } =
      selectedTab === ROSTER_TAB_CARE_TEAM
        ? await getTaskCareTeamHistory({
            ...filters,
            limit,
            offset,
          })
        : await getTaskMemberHistory({
            ...filters,
            limit,
            offset,
          });

    if (offset) {
      setItems([...items, ...newItems]);
    } else {
      setItems(newItems);
    }

    setPagination({
      limit: newPagination.limit,
      offset: newPagination.offset,
      total: newPagination.total,
    });

    setLoading(false);

    // if any uploads arent' finished, poll to update UI %
    if (
      newItems.findIndex((upload: AsyncTask | CareTeamRosterTask) =>
        upload instanceof AsyncTask
          ? !upload.result && !upload.error
          : upload.status === CareTeamRosterTaskStatus.IN_PROGRESS,
      ) !== -1
    ) {
      setUploadInProgress(true);
    } else {
      setUploadInProgress(false);
    }
  };

  useEffect(() => {
    fetchData(pagination.limit, pagination.offset);
  }, [filters]);

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (uploadInProgress) {
      const timeout = setTimeout(
        () => fetchData(pagination.limit, pagination.offset),
        500,
      );
      return () => clearTimeout(timeout);
    }
  }, [uploadInProgress, pagination]);

  async function validate(file: File, isCareTeamFile: boolean) {
    setValidationResponse(DEFAULT_VALIDATION_RESP);
    setValidating(true);
    try {
      let resp;
      if (isCareTeamFile) {
        resp = await bulkCTValidate(file);
      } else {
        resp = await bulkMemberValidate(file);
      }
      if (!resp) throw new Error();
      setValidationResponse(resp);
    } catch (err) {
      setErrorValidate(true);
    }
    setValidating(false);
  }

  const doReleaseToSF = async () => {
    if (!releaseToSFModalTask?.rosterId) return;
    try {
      await releaseToSF(
        releaseToSFModalTask.uuid,
        releaseToSFModalTask.rosterId,
      );
      await fetchData(pagination.limit, 0);
      setReleaseToSFModalTask(undefined);
      setOpenReleaseToSFModal(false);
    } catch (e) {
      showGlobalError(e as string);
    }
  };

  useEffect(() => {
    if (!validating) {
      setErrorValidate(false);
      setFileInfo(undefined);
      setValidationResponse(DEFAULT_VALIDATION_RESP);
    }
    setFilters({});
    setItems([]);
    fetchData(pagination.limit, 0);
  }, [selectedTab]);

  useEffect(() => {
    if (fileInfo && showCareTeamRoster && canAddCareTeamRoster) {
      if (
        !!fileInfo.isCareTeamFile !== !!(selectedTab === ROSTER_TAB_CARE_TEAM)
      ) {
        setSelectedTab(
          fileInfo.isCareTeamFile ? ROSTER_TAB_CARE_TEAM : ROSTER_TAB_MEMBER,
        );
      }
    }
  }, [fileInfo]);

  return (
    <Panel>
      <Panel.Heading data-cy="add-member-page" title="Upload Roster" filtersV2>
        <Panel.Filters className="flex gap">
          {showCareTeamRoster && canAddCareTeamRoster && (
            <ToggleButton
              className={styles.toggle}
              items={ROSTER_TABS_ITEMS}
              onChange={(tab: string) => {
                setItems([]);
                setFileInfo(undefined);
                setSelectedTab(tab);
              }}
              value={selectedTab}
            />
          )}
        </Panel.Filters>
        <Panel.Actions>
          <div className="right-side">
            <IconButton
              onClick={() =>
                window.open(
                  selectedTab === ROSTER_TAB_CARE_TEAM
                    ? 'https://docs.google.com/spreadsheets/d/1lCUoFP82xmuBS-FsT6sBcca6HcU-f5Vrl10ua84YcCU/edit'
                    : 'https://docs.google.com/spreadsheets/d/1Vwh5KUAz7MqnEyyImwVCkEYiryemOPj0LoTR4-dHBsU/edit',
                  '_blank',
                  'noreferrer',
                )
              }
              tooltip={translate(
                `rosterIngestion.${
                  selectedTab === ROSTER_TAB_CARE_TEAM ? 'careTeam' : 'member'
                }TemplateFile`,
              )}
            >
              <ContactPageIcon />
            </IconButton>
          </div>
        </Panel.Actions>
        <Panel.FilterBar
          className={styles.filterBar}
          data-cy="members-filters-collapsible"
          inputs={
            <>
              {selectedTab === ROSTER_TAB_MEMBER ? (
                <>
                  <Select
                    className="grid-span-3"
                    data-cy="roster-filter-referral-sources"
                    getItemDisabled={(child: SelectableOrganization) =>
                      !!(referralSources || [])
                        .filter(({ label }) =>
                          filters.referralSourceName?.includes(label as string),
                        )
                        ?.reduce(
                          (acc, item) =>
                            acc ||
                            !!item.item?.hasDescendant(child.value as number),
                          false,
                        )
                    }
                    items={referralSources}
                    loading={loadingData}
                    multiple
                    onChange={(values: Selectable[]) => {
                      setPagination({
                        ...pagination,
                        offset: 0,
                      });
                      setFilters({
                        ...filters,
                        referralSourceName: values.map(
                          (v) => v.label as string,
                        ),
                      });
                    }}
                    placeholder={translate(
                      'rosterIngestion.filters.referralSources',
                    )}
                    placeholderV2
                    size="xs"
                    value={(referralSources || []).filter(({ label }) =>
                      filters.referralSourceName?.includes(label as string),
                    )}
                  />
                  <Select
                    className="grid-span-3"
                    data-cy="roster-filter-lead-status"
                    items={Enum.toSelectable(AsyncTaskLeadStatus.asArray)}
                    getItemLabel={({ label }) =>
                      `${translate(
                        'rosterIngestion.filters.leadStatus',
                      )}: ${label}`
                    }
                    onChange={(value?: Selectable) => {
                      setPagination({
                        ...pagination,
                        offset: 0,
                      });
                      setFilters({
                        ...filters,
                        leadStatus: value
                          ? AsyncTaskLeadStatus.byKey[value.value]
                          : undefined,
                      });
                    }}
                    placeholder={translate(
                      'rosterIngestion.filters.leadStatus',
                    )}
                    placeholderV2
                    renderOption={({ label }) => label}
                    size="xs"
                    value={
                      filters?.leadStatus
                        ? Enum.toSelectable([filters.leadStatus])[0]
                        : undefined
                    }
                  />
                </>
              ) : (
                <div style={{ height: '3.2rem' }} />
              )}
            </>
          }
        />
      </Panel.Heading>
      <Panel.Body
        className={classNames(
          styles.whiteBackground,
          'grid-wrapper fit bottom',
        )}
        loading={loading && !uploadInProgress}
      >
        <CSVUpload
          className={styles.fileInput}
          onFileChange={({ file, headers, fileContents, isCareTeamFile }) => {
            if (!fileContents.length) {
              setIsFileEmpty(true);
              return;
            }

            setIsFileEmpty(false);

            if (fileContents.length <= FILE_ROW_LIMIT) {
              const diff = getSchemaDiff(
                fileContents,
                isCareTeamFile
                  ? PreUploadCareTeamColumns
                  : PreUploadMemberColumns,
              );
              setIsFileTooLarge(false);
              setSchemaDiff(diff);

              if (!diff.missingAttrs.length) {
                setValidating(true);
                setFileInfo({
                  file,
                  headers,
                  fileContents,
                  uuid: undefined,
                  isCareTeamFile,
                });
                validate(file, isCareTeamFile);
              }
            } else {
              setIsFileTooLarge(true);
            }
          }}
        />
        {!fileInfo?.file && !isFileTooLarge && (
          <Text bold className="h3 grid-span-12">
            {translate('rosterIngestion.uploadedFileSize', {
              limit: FILE_ROW_LIMIT,
            })}
          </Text>
        )}
        {renderPreuploadIssues({
          isFileTooLarge,
          isFileEmpty,
          schemaDiff,
        })}
        {uploadInProgress && (
          <Text
            className="h3 grid-span-12"
            color={fileInfo?.file ? 'error' : 'warning'}
          >
            {translate('rosterIngestion.waitForUploadWarning')}
          </Text>
        )}
        {errorValidate && (
          <Text
            className="h3 grid-span-12"
            color={fileInfo?.file ? 'error' : 'warning'}
          >
            {translate('rosterIngestion.errorValidating')}
          </Text>
        )}
        {fileInfo?.file && (
          <FileInfoPanel
            fileInfo={fileInfo}
            onUpload={async (options) => {
              if (disableAddMembers) return;
              setValidationResponse(DEFAULT_VALIDATION_RESP);
              await uploadFile(fileInfo, options);
              await fetchData(pagination.limit, pagination.offset);
              setFileInfo(undefined);
            }}
            validating={validating}
            validationResponse={validationResponse}
            uploadInProgress={uploadInProgress}
            errorValidate={errorValidate}
            rosterType={
              selectedTab === ROSTER_TAB_CARE_TEAM
                ? AsyncTaskType.CARE_TEAM_ROSTER
                : AsyncTaskType.MEMBER_ROSTER
            }
          />
        )}
        <FileContentTable
          validationResponse={validationResponse}
          fileInfo={fileInfo}
          columns={
            fileInfo?.isCareTeamFile
              ? PreUploadCareTeamColumns
              : PreUploadMemberColumns
          }
        />

        <div className="grid-span-12">
          <Text large>Past Uploads</Text>
          <UploadHistoryTable
            pastUploads={items}
            pagination={pagination}
            onChangePage={(page) => {
              fetchData(pagination.limit, page * pagination.limit);
            }}
            onChangePageSize={(pageSize) => fetchData(pageSize, 0)}
            onReleaseToSF={(upload) => {
              setReleaseToSFModalTask(upload);
              setOpenReleaseToSFModal(true);
            }}
            rosterType={
              selectedTab === ROSTER_TAB_CARE_TEAM
                ? AsyncTaskType.CARE_TEAM_ROSTER
                : AsyncTaskType.MEMBER_ROSTER
            }
          />
        </div>
        <br />
        <br />
        <Modal
          body={translate('rosterIngestion.releaseToSFModal.body')}
          cancelText={translate('rosterIngestion.releaseToSFModal.cancel')}
          onClose={() => setOpenReleaseToSFModal(false)}
          onSubmit={doReleaseToSF}
          open={openReleaseToSFModal}
          submitText={translate('rosterIngestion.releaseToSFModal.submit')}
          title={translate('rosterIngestion.releaseToSFModal.title')}
        />
      </Panel.Body>
    </Panel>
  );
}
