/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Language as LanguageEnum } from '@vestahealthcare/common/enums';
import { Enum, Selectable } from '@vestahealthcare/common/enums/Enum';

import { Props } from './Props';
import { SelectComponent } from './Select';

interface LanguageProps extends Omit<Props, 'items' | 'value' | 'onChange'> {
  items: LanguageEnum[];
  onChange: (value?: LanguageEnum | LanguageEnum[]) => void;
  value: LanguageEnum | LanguageEnum[];
}

export const Language = ({
  items,
  multiple,
  value,
  onChange,
  ...props
}: LanguageProps) => (
  <SelectComponent
    grouped={({ preferred }) => (preferred ? 'Preferred' : 'Other')}
    items={LanguageEnum.toSelectable(items).filter(({ disabled }) => !disabled)}
    multiple={multiple}
    onChange={(values: Selectable | Selectable[]) => {
      if ((values as Selectable[])?.length) {
        onChange(
          (values as Selectable[]).map((v) => LanguageEnum.byKey[v.value]),
        );
      } else {
        onChange(
          values ? LanguageEnum.byKey[(values as Selectable).value] : undefined,
        );
      }
    }}
    value={
      // eslint-disable-next-line no-nested-ternary
      multiple
        ? Enum.toSelectable((value as LanguageEnum[]) || [])
        : value
        ? Enum.toSelectable([value as LanguageEnum])[0]
        : undefined
    }
    {...props}
  />
);
