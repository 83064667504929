import { TaskFields } from '../enums/TaskFields';

export class TaskFieldChanges {
  id = 0;

  field?: TaskFields;

  oldValue?: string;

  oldValuePretty?: string;

  newValue?: string;

  newValuePretty?: string;

  createdAt = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.field = TaskFields.byKey[obj.field];
  }
}

export default TaskFieldChanges;
