import React, { useEffect, useState } from 'react';

import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { EmployeeGroup, Patient } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Select } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CacheServices } from 'dash/src/services';
import { updatePatient } from 'dash/src/services/PatientServices';

import { BaseModal } from './BaseModal';

interface Props {
  patient: Patient;
  onSubmit: (patient: Patient) => void;
}

const DetailsComponent = React.memo(({ patient }: { patient: Patient }) => {
  return (
    <>
      <h4 className="grid-span-5" data-cy="member-work-list-group-label">
        {translate('personalDetails.workListGroup')}
      </h4>
      <p className="grid-span-6" data-cy="member-work-list-group-readonly">
        {patient.worklistGroup?.name || EMPTY}
      </p>
    </>
  );
});

const UNASSIGNED = { label: translate('global.unassigned'), value: 0 };

export const EditWorkListGroup = ({ patient, onSubmit }: Props) => {
  const { worklistGroup } = patient;

  const [buttonLoading, setButtonLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [groups, setGroups] = useState<Selectable[]>([]);

  const getInitialData = async () => {
    const groups = await CacheServices.getEmployeeGroupsWorklist();
    setGroups([UNASSIGNED, ...EmployeeGroup.toSelectable(groups)]);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const reset = () => {
    setCurrentWorkListGroup(
      worklistGroup
        ? EmployeeGroup.toSelectable([worklistGroup])[0]
        : UNASSIGNED,
    );
    setButtonLoading(false);
    setSubmitted(false);
  };

  useEffect(() => reset(), [patient]);

  const [currentWorkListGroup, setCurrentWorkListGroup] = useState<
    Selectable
  >();

  const submit = async () => {
    let result = true;
    setSubmitted(true);
    setButtonLoading(true);

    try {
      const newPatient = await updatePatient(patient.id, {
        worklistGroupId: (currentWorkListGroup?.value as number) || null,
      });

      await onSubmit(newPatient);
    } catch (e) {
      result = false;
      showGlobalError(e as string);
    }

    setButtonLoading(false);
    return result;
  };

  return (
    <BaseModal
      details={<DetailsComponent patient={patient} />}
      data-cy="member-risk-level"
      onCancel={reset}
      onSubmit={submit}
      loadingSubmit={buttonLoading}
      title={translate('personalDetails.workListGroup')}
    >
      <Select
        data-cy="edit-work-list-group-options"
        error={submitted && !currentWorkListGroup}
        label={translate('personalDetails.workListGroup')}
        onChange={setCurrentWorkListGroup}
        items={groups}
        value={currentWorkListGroup}
      />
    </BaseModal>
  );
};

export default EditWorkListGroup;
