import React from 'react';
import { Radios } from 'styleguide';
import { EMPTY } from '@vestahealthcare/common/utils/constants';
import { WidgetProps } from '../types';

const RadioWidget = (props: WidgetProps) => {
  const {
    formContext: { readonly },
    options: { enumOptions },
  }: WidgetProps = props;

  if (!enumOptions) {
    throw new Error('No enum options defined for radio');
  }

  if (readonly) {
    if (!props.value) {
      return <p>{EMPTY}</p>;
    }

    const option = enumOptions.find((option) => option.value === props.value);
    return <p>{option && option.label}</p>;
  }

  return (
    <Radios
      onChange={props.onChange}
      options={enumOptions.map(({ label, value }) => ({
        value,
        label,
      }))}
      value={props.value}
    />
  );
};

export default RadioWidget;
