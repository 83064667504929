import {
  PhoneNumber,
  PhoneNumberFormat,
  PhoneNumberUtil,
} from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export const parse = (number: string): PhoneNumber | undefined => {
  try {
    return phoneUtil.parse(number, 'US');
  } catch (e) {
    // ignoring fail parse
    return undefined;
  }
};

// TODO: is this the right default if the parse fails?
export const format = (number: string): string => {
  const parsedNumber = parse(number);
  return parsedNumber
    ? phoneUtil.format(parsedNumber, PhoneNumberFormat.NATIONAL)
    : number;
};

export const formatPhone = format;

export const validate = (number?: string): boolean => {
  if (!number || !number.length) {
    return false;
  }

  const parsedNumber = parse(number);
  return (
    !!parsedNumber &&
    (phoneUtil.isValidNumberForRegion(parsedNumber, 'US') ||
      phoneUtil.isValidNumberForRegion(parsedNumber, 'PR'))
  );
};
