export class Contact {
  address?: string;

  email?: string;

  emergency?: boolean;

  faxNumber?: string;

  firstName = '';

  isHealthProxy = false;

  isValidHealthProxy = false;

  lastName?: string;

  phoneNumber?: string;

  contactNote?: string;

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  get fullName() {
    return this.lastName
      ? `${this.firstName} ${this.lastName}`
      : this.firstName;
  }

  get initials() {
    return `${this.firstName.substring(0, 1)}${
      this.lastName && this.lastName.substring(0, 1)
    }`;
  }
}

export default Contact;
