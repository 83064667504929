import { Enum } from './Enum';

// Enum for the NetInfoType for react-natvie
export class NetInfoStateType extends Enum {
  translationKey = 'netInfoType';

  static asArray: NetInfoStateType[] = [];

  static byKey: { [name: string]: NetInfoStateType } = {};

  static readonly UNKNOWN = new NetInfoStateType('UNKNOWN');

  static readonly NONE = new NetInfoStateType('NONE');

  static readonly CELLULAR = new NetInfoStateType('CELLULAR');

  static readonly WIFI = new NetInfoStateType('WIFI');

  static readonly BLUETOOTH = new NetInfoStateType('BLUETOOTH');

  static readonly ETHERNET = new NetInfoStateType('ETHERNET');

  static readonly WIMAX = new NetInfoStateType('WIMAX');

  static readonly VPN = new NetInfoStateType('VPN');

  static readonly OTHER = new NetInfoStateType('OTHER');

  constructor(public readonly value: string) {
    super();
    NetInfoStateType.asArray.push(this);
    NetInfoStateType.byKey[value] = this;
  }
}

export default NetInfoStateType;
