import React, { useEffect, useState } from 'react';

import TrashIcon from '@mui/icons-material/Delete';

import { States } from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Address, CareTeamPerson } from '@vestahealthcare/common/models';
import {
  isValidAddress,
  isValidCity,
  isValidZipCode,
} from '@vestahealthcare/common/utils/format';

import { Toast } from 'styleguide';
import {
  Button,
  CheckboxWithLabel,
  CollapsableSidebar,
  Select,
  TextInput,
} from 'styleguide-v2';

import { UpdateCareTeamPersonParams } from 'dash/src/services/CareTeamServices';

interface Props {
  open?: boolean;
  onClose: () => void;
  onSubmit: (
    id: number,
    params: UpdateCareTeamPersonParams,
  ) => Promise<boolean>;
  person?: CareTeamPerson;
}

export const EditAddressModal = ({
  open,
  onClose,
  onSubmit,
  person,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [address1, setAddress1] = useState<string>();
  const [address2, setAddress2] = useState<string>();
  const [city, setCity] = useState<string>();
  const [state, setState] = useState<States>();
  const [zip, setZIP] = useState<string>();
  const [verified, setVerified] = useState<boolean>();

  useEffect(() => {
    setAddress1(person?.address?.address1);
    setAddress2(person?.address?.address2);
    setCity(person?.address?.city);
    setState(person?.address?.state);
    setZIP(person?.address?.zipCode);
    setVerified(person?.verifiedAddress);
  }, [person, open]);

  useEffect(() => {
    setLoading(false);
    setSubmitted(false);
  }, [open]);

  const checkValidAddress = () =>
    isValidAddress({
      address1: address1 || '',
      address2: address2 || '',
      city: city || '',
      state,
      zipCode: zip || '',
    } as Address);

  const validate = () =>
    (address1 || address2 || city || state || zip || verified) &&
    isValidAddress({
      address1: address1 || '',
      address2: address2 || '',
      city: city || '',
      state,
      zipCode: zip || '',
    } as Address);

  const doDelete = async () => {
    if (person) {
      setLoadingDelete(true);
      try {
        await onSubmit(person.id, {
          address: null,
          verifiedAddress: false,
        });
      } finally {
        setSubmitted(false);
        setLoadingDelete(false);
      }
    }
  };

  const doSubmit = async () => {
    setSubmitted(true);
    if (!validate()) {
      if (!checkValidAddress()) {
        new Toast({
          title: 'Validation Error',
          body: translate('careTeam.errors.invalidAddress'),
          type: 'error',
          position: 'bottom-right',
        });
      }
      if (verified && !checkValidAddress()) {
        new Toast({
          title: 'Validation Error',
          body: translate('careTeam.errors.invalidAddressVerified'),
          type: 'error',
          position: 'bottom-right',
        });
      }
      return;
    }
    if (person) {
      setLoading(true);
      try {
        await onSubmit(person.id, {
          address: {
            address1,
            address2,
            city,
            state,
            zipCode: zip,
          },
          verifiedAddress: verified,
        });
      } finally {
        setSubmitted(false);
        setLoading(false);
      }
    }
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={!!open}
      title={
        <h2>
          {translate('careTeam.modals.editAddressModalTitle', {
            name: person?.fullName,
          })}
        </h2>
      }
      size={550}
    >
      <CollapsableSidebar.Body>
        <div className="grid-wrapper fit">
          <TextInput
            className="grid-span-9"
            data-cy="care-team-steps-address-1"
            error={submitted && !validate()}
            label={translate('careTeam.common.address1')}
            onChange={setAddress1}
            value={address1}
          />
          <TextInput
            className="grid-span-3"
            data-cy="care-team-steps-address-2"
            label={translate('careTeam.common.address2')}
            onChange={setAddress2}
            value={address2}
          />
          <TextInput
            className="grid-span-6"
            data-cy="care-team-steps-city"
            error={
              (submitted &&
                city &&
                !isValidCity(city) &&
                translate('global.cityFormatError')) ||
              (submitted && !validate())
            }
            label={translate('careTeam.common.city')}
            onChange={setCity}
            value={city}
          />
          <Select
            className="grid-span-3"
            data-cy="care-team-state"
            error={submitted && !validate()}
            getItemLabel={(item: Selectable) => item.value}
            items={Enum.toSelectable(States.asArray)}
            label={translate('careTeam.common.state')}
            onChange={(val?: Selectable) => {
              const state = val ? States.byKey[val.value] : undefined;
              setState(state);
            }}
            value={state ? Enum.toSelectable([state])[0] : undefined}
          />
          <TextInput
            className="grid-span-3"
            data-cy="care-team-steps-zip"
            error={
              (submitted &&
                zip &&
                !isValidZipCode(zip) &&
                translate('global.zipFormatError')) ||
              (submitted && !validate())
            }
            label={translate('careTeam.common.zip')}
            onChange={setZIP}
            value={zip}
          />
          <CheckboxWithLabel
            className="grid-span-12"
            checked={!!verified}
            data-cy="care-team-steps-verified"
            label={translate('careTeam.common.verified')}
            onChange={setVerified}
          />
          <br />
        </div>
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="care-team-detail-edit-contact-close"
          onClick={onClose}
        >
          {translate('global.close')}
        </Button>
        <Button
          data-cy="care-team-detail-edit-contact-delete"
          disabled={!person?.address}
          icon={<TrashIcon />}
          loading={loadingDelete}
          onClick={doDelete}
          type="outlined"
        >
          {translate('global.delete')}
        </Button>
        <Button
          color="secondary"
          data-cy="care-team-detail-edit-contact-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default EditAddressModal;
