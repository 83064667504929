import moment, { Moment } from 'moment-timezone';
import 'frozen-moment';
import { LOCAL_TIME_FORMAT } from './utils/constants';

// Importing locales changes locale automatically.
// Make sure we have english set initially
moment.locale('en');

export { Moment } from 'moment-timezone';

const myMoment = moment;

export const toLocalTime = (time: Moment) => ({
  hour: time.hour(),
  minute: time.minute(),
  second: time.second(),
  time: time.format(LOCAL_TIME_FORMAT),
});

export default myMoment;
