import classNames from 'classnames';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { Enum, EventReporterType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  CareTeamMember,
  MemberCommunityResource,
  Patient,
} from '@vestahealthcare/common/models';

import {
  RadioGroup,
  Select,
  SelectCareTeamMemberOption,
  SelectCommunityResourceOption,
} from 'styleguide-v2';
import { RadioItem } from 'styleguide-v2/src/components/RadioButton/RadioGroup';

import { useSelector } from 'dash/src/redux/store';
import { fetchPatient } from 'dash/src/services';
import { fetchMemberCareTeamMembers } from 'dash/src/services/CareTeamServices';
import { fetchMemberCommunityResources } from 'dash/src/services/CommunityResourcesServices';

type Props = {
  addCareTeamMember?: boolean;
  className?: string;
  disabled?: boolean;
  defaultCareTeam?: number;
  defaultCommunityResource?: number;
  defaultType?: EventReporterType;
  onAddCareTeamMember?: () => void;
  onChange: (
    type: EventReporterType,
    reporter?: Patient | CareTeamMember | MemberCommunityResource,
  ) => void;
  patient?: Patient | number;
  showCommunityResources?: boolean;
  submitted?: boolean;
};

export type ReporterHandle = {
  refresh: () => void;
};

const ADD_CTM = new CareTeamMember({
  active: true,
  id: -1,
  careTeamPerson: { id: -1, fullName: translate('memberEvents.addCareTeam') },
});

export const EventReporter = forwardRef(
  (
    {
      addCareTeamMember,
      className,
      disabled,
      defaultCareTeam,
      defaultCommunityResource,
      defaultType,
      onAddCareTeamMember,
      onChange,
      patient: patientProp,
      showCommunityResources = false,
      submitted,
    }: Props,
    ref,
  ) => {
    const [patient, setPatient] = useState<Patient>();
    const [careTeamMembers, setCareTeamMembers] = useState<CareTeamMember[]>(
      [],
    );
    const [communityResources, setCommunityResources] = useState<
      MemberCommunityResource[]
    >([]);

    const [loading, setLoading] = useState<boolean>(false);
    const storeCTM = [
      ...useSelector((state) => state.memberInfoSlice.careTeamMembers),
    ]
      .sort(
        (
          { careTeamPerson: { fullName: a } },
          { careTeamPerson: { fullName: b } },
        ) => a.localeCompare(b),
      )
      .sort(({ type: a }, { type: b }) =>
        a.description.localeCompare(b.description),
      );
    const storeCRs = [
      ...useSelector((state) => state.memberInfoSlice.communityResources),
    ]
      .sort(
        (
          { communityResource: { name: a } },
          { communityResource: { name: b } },
        ) => a.localeCompare(b),
      )
      .sort(
        (
          { communityResource: { type: a } },
          { communityResource: { type: b } },
        ) => a.description.localeCompare(b.description),
      );

    if (addCareTeamMember) {
      storeCTM.push(ADD_CTM);
    }

    const [reporterType, setReporterType] = useState(
      defaultType || EventReporterType.MEMBER,
    );
    const [reporterTypes, setReporterTypes] = useState(
      Enum.toSelectable(
        EventReporterType.asArray.filter(
          (item) =>
            showCommunityResources ||
            item !== EventReporterType.COMMUNITY_RESOURCE,
        ),
      ),
    );

    const [careTeamMember, setCareTeamMember] = useState<CareTeamMember>();
    const [communityResource, setCommunityResource] = useState<
      MemberCommunityResource
    >();

    const getPatientInfo = async () => {
      if (!patientProp || typeof patientProp !== 'number') return;
      setLoading(true);

      const [patient, ctm, crs] = await Promise.all([
        fetchPatient(patientProp),
        fetchMemberCareTeamMembers(patientProp),
        showCommunityResources
          ? fetchMemberCommunityResources(patientProp)
          : Promise.resolve(null),
      ]);

      if (addCareTeamMember) {
        ctm.items.push(ADD_CTM);
      }

      setPatient(patient);
      setCareTeamMembers(ctm.items.filter(({ active }) => active));
      setCommunityResources(crs?.items?.filter(({ active }) => active) || []);

      setLoading(false);
    };

    useEffect(() => {
      if (patientProp) {
        if (patientProp instanceof Patient) {
          setPatient(patientProp);
          setCareTeamMembers(
            storeCTM.filter(({ active }) => active) as CareTeamMember[],
          );
          setCommunityResources(
            storeCRs?.filter(({ discontinuedAt }) => !discontinuedAt),
          );
        } else {
          setPatient(undefined);
          getPatientInfo();
        }
      }
    }, [patientProp]);

    const initData = async () => {
      setReporterType(defaultType || EventReporterType.MEMBER);
      const types = Enum.toSelectable(
        EventReporterType.asArray.filter(
          (item) =>
            showCommunityResources ||
            item !== EventReporterType.COMMUNITY_RESOURCE,
        ),
      );
      if (!careTeamMembers?.length) {
        types[1] = { ...types[1], disabled: true };
      }
      if (showCommunityResources && !communityResources?.length) {
        types[2] = { ...types[2], disabled: true };
      }
      setReporterTypes(types);

      if (defaultCareTeam) {
        setCareTeamMember(
          careTeamMembers?.find(({ id }) => id === defaultCareTeam),
        );
      } else if (
        careTeamMembers?.length === 1 &&
        careTeamMembers[0].id !== ADD_CTM.id
      ) {
        setCareTeamMember(careTeamMembers[0]);
      } else {
        setCareTeamMember(undefined);
      }

      if (defaultCommunityResource) {
        setCommunityResource(
          communityResources?.find(({ id }) => id === defaultCommunityResource),
        );
      } else if (communityResources?.length === 1) {
        setCommunityResource(communityResources[0]);
      } else {
        setCommunityResource(undefined);
      }
    };

    useEffect(() => {
      initData();
    }, [patient, careTeamMembers, communityResources]);

    useEffect(() => {
      if (reporterType === EventReporterType.MEMBER && patient) {
        onChange(reporterType, patient);
      } else if (
        reporterType === EventReporterType.CARE_TEAM &&
        careTeamMember?.id === ADD_CTM.id &&
        onAddCareTeamMember
      ) {
        onAddCareTeamMember();
        setCareTeamMember(undefined);
      } else if (reporterType === EventReporterType.CARE_TEAM) {
        onChange(reporterType, careTeamMember);
      } else if (reporterType === EventReporterType.COMMUNITY_RESOURCE) {
        onChange(reporterType, communityResource);
      }
    }, [reporterType, careTeamMember, communityResource]);

    useImperativeHandle(ref, () => ({
      refresh: () => {
        if (patientProp) {
          if (typeof patientProp === 'number') {
            setPatient(undefined);
            getPatientInfo();
          }
        }
      },
    }));

    return (
      <div className={classNames(className, 'grid-wrapper fit')}>
        <RadioGroup
          className="grid-span-4"
          compact
          disabled={disabled}
          items={reporterTypes as RadioItem[]}
          title={translate('memberEvents.reporterType')}
          onChange={(val) => setReporterType(EventReporterType.byKey[val])}
          value={reporterType.value}
        />
        {reporterType === EventReporterType.MEMBER && (
          <Select
            className="grid-span-8"
            disabled
            getItemLabel={({ fullName }) => fullName}
            items={[patient]}
            label={translate('memberEvents.reporterMember')}
            loading={loading}
            onChange={() => {}}
            value={patient}
          />
        )}
        {reporterType === EventReporterType.CARE_TEAM && (
          <Select
            className="grid-span-8"
            disabled={disabled}
            error={submitted && !careTeamMember}
            getItemLabel={({ careTeamPerson: { fullName }, type }) =>
              type ? `${fullName} (${type.description})` : fullName
            }
            items={
              patientProp instanceof Patient
                ? storeCTM.filter(({ active }) => active)
                : careTeamMembers
            }
            label={translate('memberEvents.reporterCareTeam')}
            loading={loading}
            onChange={setCareTeamMember}
            renderOption={(ctm: CareTeamMember) => (
              <SelectCareTeamMemberOption member={ctm} />
            )}
            required
            value={careTeamMember}
          />
        )}
        {reporterType === EventReporterType.COMMUNITY_RESOURCE && (
          <Select
            className="grid-span-8"
            disabled={disabled}
            error={submitted && !communityResource}
            getItemLabel={({
              communityResource: { name, specialities },
            }: MemberCommunityResource) =>
              specialities?.length
                ? `${name} (${specialities
                    ?.map(({ description }) => description)
                    .join(', ')})`
                : name
            }
            items={
              patientProp instanceof Patient
                ? storeCRs.filter(({ active }) => active)
                : communityResources
            }
            label={translate('memberEvents.reporterCommunityResource')}
            loading={loading}
            onChange={setCommunityResource}
            renderOption={(cr: MemberCommunityResource) => (
              <SelectCommunityResourceOption
                communityResource={cr.communityResource}
              />
            )}
            required
            value={communityResource}
          />
        )}
      </div>
    );
  },
);

export default EventReporter;
