import React from 'react';
import { Checkbox, CheckboxProps } from 'styleguide';
import classNames from 'classnames';

// Radio gets a unique implementation because the radio icons in Font Awesome v4
// are too thick. Can probably unify this design once we upgrade to v5
const Radio = ({ children, className, ...props }: CheckboxProps) => {
  const classes = classNames(className, 'radio-toggle');

  return (
    <Checkbox className={classes} {...props}>
      <span className="radio-outer" data-cy="radio-toggle">
        {props.checked && <span className="radio-inner" />}
      </span>
      {children}
    </Checkbox>
  );
};

export default Radio;
