import { Moment } from 'moment';

import { Task } from '@vestahealthcare/common/models';
import TaskCustomField from '@vestahealthcare/common/models/TaskCustomField';
import TaskDefinition from '@vestahealthcare/common/models/TaskDefinition';
import TaskDefinitionOutcome from '@vestahealthcare/common/models/TaskDefinitionOutcome';
import { TaskNote } from '@vestahealthcare/common/models/TaskNote';

import Api from 'dash/src/services/Api';

import { PaginatedResponse } from '.';

export interface TaskFetchParams {
  assigneeId?: number[];
  brandId?: string[];
  completedAtFrom?: string;
  completedAtTo?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
  createdById?: number[];
  dueDateFrom?: string;
  dueDateTo?: string;
  employeeGroupId?: number[];
  hasProgramExtensions?: boolean;
  id?: number[];
  language?: string[];
  limit?: number;
  memberId?: number[];
  offset?: number;
  programExtensionId?: number[];
  programExtensionStatus?: string[];
  queryString?: string;
  referralSourceId?: number[];
  sort?: string;
  status?: string[];
  subTasksOnly?: boolean;
  taskDefinitionId?: number[];
  taskDefinitionOutcomeId?: number[];
  topLevelOnly?: boolean;
  vitalCollectionMethod?: string[];
}

export const fetchAllTasks = async (
  params: TaskFetchParams,
): Promise<PaginatedResponse<Task>> => {
  const {
    tasks: { items, pagination },
  } = await Api.getv2('tasks', params);
  return { items: items.map((i: any) => new Task(i)), pagination };
};

export const fetchTasksWithSubtasks = async (
  params: TaskFetchParams,
): Promise<PaginatedResponse<Task>> => {
  const paginatedTasks = await fetchAllTasks(params);
  const tasksPromises = paginatedTasks.items.map((task) => fetchTask(task.id));
  const tasksWithChildren = await Promise.all(tasksPromises);

  return {
    items: tasksWithChildren,
    pagination: paginatedTasks.pagination,
  };
};

export const fetchTask = async (taskId: number) => {
  const { task } = await Api.getv2(`tasks/${taskId}`);

  return new Task(task);
};

export interface TaskParam {
  assigneeId?: number;
  description?: string;
  dueDate?: Moment;
  employeeGroupId: number;
  eventId?: number;
  memberId?: number;
  note?: string;
  status?: string;
  taskDefinitionId: number;
  taskDefinitionOutcomeId?: number;
}

export const createTask = async (param: TaskParam): Promise<Task> => {
  const transformedParam = {
    ...param,
    dueDate: param.dueDate?.unix(),
  };
  const { task } = await Api.postv2JSON('tasks', transformedParam);
  return new Task(task);
};

export const editTask = async (
  taskId: number,
  param: Partial<TaskParam>,
): Promise<Task> => {
  const transformedParam = {
    ...param,
    dueDate: param.dueDate?.unix(),
  };
  const { task } = await Api.patchv2JSON(`tasks/${taskId}`, transformedParam);
  return new Task(task);
};

export const updateTaskCustomField = async (
  taskId: number,
  customFieldId: number | string,
  value?: string,
) => {
  const res = await Api.patchv2JSON(
    `tasks/${taskId}/custom-fields/${customFieldId}`,
    { value },
  );

  return res;
};

export const fetchTaskNotes = async (taskId: number): Promise<TaskNote[]> => {
  const { taskChanges } = await Api.getv2(`tasks/${taskId}/task-changes`);
  return taskChanges.items.map((i: any) => new TaskNote(i));
};

export const fetchTaskDefinitionOutcomes = async (
  taskDefinitionId: number,
): Promise<TaskDefinitionOutcome[]> => {
  const { taskDefinitionOutcomes } = await Api.getv2(
    `task-definitions/${taskDefinitionId}/outcome-types`,
  );
  return taskDefinitionOutcomes.items.map(
    (i: any) => new TaskDefinitionOutcome(i),
  );
};

/* TASK DEFINITION */
export interface TaskCustomFieldParam extends Omit<TaskCustomField, 'type'> {
  type?: string;
}
export interface SubtaskDefinitionParam {
  customFields?: TaskCustomFieldParam[];
  id?: number;
  groupId?: number;
  name: string;
  outcomes?: TaskDefinitionOutcome[];
  position: number;
  status: string;
  version: number;
}

export interface TaskDefinitionParamsCreation {
  parentName: string;
  reference?: string;
  parentGroupId?: number;
}
export interface TaskDefinitionParamsUpdate
  extends TaskDefinitionParamsCreation {
  status: string;
  subtasksDefinitions: SubtaskDefinitionParam[];
  version: number;
}

export interface TaskDefinitionFilterParams {
  topLevelOnly?: boolean;
  status?: string;
  name?: string;
  createdById?: number;
  employeeGroupId?: number;
}

export const fetchTaskDefinitions = async (
  params?: TaskDefinitionFilterParams,
): Promise<TaskDefinition[]> => {
  const { taskDefinitions } = await Api.getv2('task-definitions', params);
  return taskDefinitions.items.map((i: any) => new TaskDefinition(i));
};

export const fetchTaskDefinition = async (
  taskId: number,
): Promise<TaskDefinition> => {
  const { taskDefinition } = await Api.getv2(
    `task-definitions/${taskId}/full-definition`,
  );
  return new TaskDefinition(taskDefinition);
};

export const createTaskDefinition = async (
  params?: TaskDefinitionParamsCreation,
): Promise<TaskDefinition> => {
  const { taskDefinition } = await Api.postv2JSON('task-definitions', params);
  return new TaskDefinition(taskDefinition);
};

export const updateTaskDefinition = async (
  taskId: number,
  params?: TaskDefinitionParamsUpdate,
): Promise<TaskDefinition> => {
  const { taskDefinition } = await Api.putv2JSON(
    `task-definitions/${taskId}`,
    params,
  );
  return new TaskDefinition(taskDefinition);
};

export const fetchSubTaskDefinitions = async (
  taskId: number,
): Promise<TaskDefinition[]> => {
  const { taskDefinitions } = await Api.getv2(
    `task-definitions/${taskId}/subtask`,
  );
  return taskDefinitions.items.map((i: any) => new TaskDefinition(i));
};

export const fetchSubTaskDefinition = async (
  taskId: number,
  subtaskId: number,
): Promise<TaskDefinition> => {
  const { taskDefinitions } = await Api.getv2(
    `task-definitions/${taskId}/subtask/${subtaskId}`,
  );
  return new TaskDefinition(taskDefinitions);
};

export const createSubTaskDefinition = async (
  taskId: number,
  params?: TaskDefinition,
): Promise<TaskDefinition> => {
  const { taskDefinition } = await Api.postv2(
    `task-definitions/${taskId}/subtask`,
    params,
  );
  return new TaskDefinition(taskDefinition);
};

export const updateSubTaskDefinition = async (
  taskId: number,
  params?: TaskDefinition,
): Promise<TaskDefinition> => {
  const { taskDefinition } = await Api.patchv2(
    `task-definitions/${taskId}/subtask`,
    params,
  );
  return new TaskDefinition(taskDefinition);
};
