/* eslint-disable max-classes-per-file */
import { Brand } from '../enums';
import { PhoneUserType } from '../enums/PhoneUserType';
import {
  PatientBrandHelper,
  hasBrandCareAtHome,
} from './Helpers/PatientBrandHelper';
import { make } from './Model';
import { Phone } from './Phone';

export class PhoneUserInfo {
  id = 0;

  firstName = '';

  lastName = '';

  fullName = '';

  type: PhoneUserType;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.type = PhoneUserType.byKey[obj.type];
  }

  isCaregiver() {
    return this.type === PhoneUserType.CAREGIVER;
  }

  isMember() {
    return this.type === PhoneUserType.PATIENT;
  }

  isCareTeamPerson() {
    return this.type === PhoneUserType.CARE_TEAM_PERSON;
  }
}

export class PhoneUser implements PatientBrandHelper {
  id = 0;

  primary = false;

  phone: Phone;

  user: PhoneUserInfo;

  brand?: Brand;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.phone = make(obj.phone, Phone);
    this.user = make(obj.user, PhoneUserInfo);
    this.brand = obj.brand ? Brand.byKey[obj.brand] : undefined;
  }

  isBrandCareAtHome(): boolean {
    return hasBrandCareAtHome(this);
  }
}

export default PhoneUser;
