import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import AddIcon from '@mui/icons-material/Add';

import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  EventEvaluation,
  EventNote,
  EventIntervention,
  EventModel,
  EventTriage,
} from '@vestahealthcare/common/models';
import { Button, IconButton, Select, Spinner, Tooltip } from 'styleguide-v2';
import { CacheServices } from 'dash/src/services';
import {
  fetchEventTriages,
  updateEventIntervention,
} from 'dash/src/services/EventServices';
import { DATE_FORMAT_SHORT_WITH_TIME } from '@vestahealthcare/common/utils/constants';
import { EventDetailNotes } from './EventDetailNotes';
import { EventDetailTriage } from './EventDetailTriage';
import { eventEvaluationDetailStyles } from './EventDetailEvaluationStyles';
import { EventDetailCard } from './EventDetailCard';

type Props = {
  className?: string;
  evaluation?: EventEvaluation;
  event: EventModel;
  editMode?: boolean;
  onAddIntervention: () => void;
  onAddTriage: () => void;
  onCompleteEvaluation: (evaluationId: number) => void;
  onEditNote: (note?: EventNote) => void;
  onUpdateEvaluation: (evaluation: EventEvaluation) => void;
  onShowTriage: (triage: EventTriage) => void;
};

export const EventDetailEvaluation = ({
  className,
  editMode,
  evaluation,
  event,
  onAddIntervention,
  onAddTriage,
  onCompleteEvaluation,
  onEditNote,
  onUpdateEvaluation,
  onShowTriage,
}: Props) => {
  const styles = eventEvaluationDetailStyles();
  const [triages, setTriages] = useState<EventTriage[]>();
  const [interventions, setInterventions] = useState<EventIntervention[]>();
  const [interventionStatuses, setInterventionStatuses] = useState<BaseEnum[]>(
    [],
  );
  const [interventionResults, setInterventionResults] = useState<BaseEnum[]>(
    [],
  );

  const isEditable = !evaluation?.closed;

  const getInitialData = async () => {
    const statuses = await CacheServices.getEventInterventionStatuses();
    setInterventionStatuses(statuses);
    const results = await CacheServices.getEventInterventionResults();
    setInterventionResults(results);
  };

  const getTriages = async () => {
    if (evaluation) {
      setTriages(undefined);
      const triages = await fetchEventTriages(evaluation?.id);
      setTriages(triages || []);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    getTriages();
    setInterventions(
      evaluation?.interventions.sort(({ type: a }, { type: b }) => a.id - b.id),
    );
  }, [evaluation]);

  const updateIntervention = async (
    intervention: EventIntervention,
    index: number,
  ) => {
    if (interventions && event?.id && evaluation?.id) {
      await updateEventIntervention(event.id, evaluation?.id, intervention.id, {
        outcomeResultId: intervention.outcomeResult,
        outcomeStatusId: intervention.outcomeStatus,
      });
      interventions[index] = intervention;
      setInterventions([...interventions]);
    }
  };

  const shouldAllowToCloseEvaluation = () =>
    interventions?.length && !anyIterventionIsOpen() && triages?.length;

  const anyIterventionIsOpen = () =>
    !!interventions?.find(
      ({ outcomeResult, outcomeStatus }) => !outcomeResult || !outcomeStatus,
    );

  return !evaluation || !triages ? (
    <div className={classnames(styles.container, className, 'flex')}>
      <Spinner className={styles.spinner} width={32} color="primary" />
    </div>
  ) : (
    <div className={classnames(styles.container, className)}>
      {!triages && <Spinner width={32} color="primary" />}
      {!!triages?.length && (
        <EventDetailCard
          className={classnames(styles.triage, styles.card)}
          title={translate('memberEvents.detail.triage')}
          titleIcon={
            isEditable && (
              <IconButton
                className={styles.cardTitleAction}
                onClick={() => onAddTriage()}
                tooltip={translate('memberEvents.detail.addTriage')}
                size="small"
              >
                <AddIcon color="primary" />
              </IconButton>
            )
          }
        >
          <EventDetailTriage
            triages={triages}
            onClickInfo={(triage) => {
              if (triage) {
                onShowTriage(triage);
              }
            }}
          />
        </EventDetailCard>
      )}
      {!!evaluation.interventions?.length && (
        <EventDetailCard
          className={styles.card}
          title={translate('memberEvents.detail.interventions')}
          titleIcon={
            isEditable && (
              <IconButton
                className={styles.cardTitleAction}
                onClick={() => onAddIntervention()}
                size="small"
                tooltip={translate('memberEvents.detail.addIntervention')}
              >
                <AddIcon color="primary" />
              </IconButton>
            )
          }
        >
          <ul className={styles.interventionContainer}>
            {interventions?.map((intervention, index) => {
              const { type, outcomeResult, outcomeStatus } = intervention;

              return (
                <li
                  key={`intervention-${index}`}
                  className={styles.interventionRow}
                >
                  <div>
                    <p>{type.description}</p>
                    <p className={styles.createdInfo}>
                      {intervention.createdAt.format(
                        DATE_FORMAT_SHORT_WITH_TIME,
                      )}{' '}
                      by {intervention.createdBy.fullName}
                    </p>
                  </div>
                  <Select
                    className={index === 0 ? styles.interventionOptions : ''}
                    disableClearable
                    getItemLabel={(it: BaseEnum) => it?.description}
                    items={interventionStatuses}
                    label={
                      index === 0
                        ? translate('memberEvents.detail.interventionStatus')
                        : undefined
                    }
                    onChange={(val: BaseEnum) => {
                      if (val) {
                        updateIntervention(
                          {
                            ...intervention,
                            outcomeStatus: val,
                          },
                          index,
                        );
                        interventions[index].outcomeStatus = val;
                        setInterventions([...interventions]);
                      }
                    }}
                    readOnly={!isEditable && !editMode}
                    size="small"
                    value={outcomeStatus}
                  />
                  <Select
                    className={index === 0 ? styles.interventionOptions : ''}
                    disableClearable
                    getItemLabel={(it: BaseEnum) => it?.description}
                    items={interventionResults}
                    label={
                      index === 0
                        ? translate('memberEvents.detail.interventionResults')
                        : undefined
                    }
                    onChange={(val: BaseEnum) => {
                      if (val) {
                        updateIntervention(
                          {
                            ...intervention,
                            outcomeResult: val,
                          },
                          index,
                        );
                        interventions[index].outcomeResult = val;
                        setInterventions([...interventions]);
                      }
                    }}
                    readOnly={!isEditable && !editMode}
                    size="small"
                    value={outcomeResult}
                  />
                </li>
              );
            })}
          </ul>
        </EventDetailCard>
      )}
      <EventDetailNotes
        className={styles.card}
        canAdd={isEditable}
        notes={evaluation.notes?.filter(({ addendum }) => !addendum)}
        onEdit={onEditNote}
      />

      <EventDetailNotes
        addendum
        className={styles.card}
        canAdd={!isEditable}
        notes={evaluation.notes?.filter(({ addendum }) => addendum)}
        onEdit={onEditNote}
      />

      {!evaluation.closed && !!triages?.length && (
        <div className={styles.buttons}>
          <Tooltip
            text={translate('memberEvents.detail.warningCloseEvaluation')}
          >
            <div style={{ width: 'fit-content', margin: 'auto' }}>
              <Button
                color="secondary"
                disabled={!shouldAllowToCloseEvaluation()}
                onClick={() => onCompleteEvaluation(evaluation.id)}
              >
                {translate('memberEvents.detail.closeEvaluation')}
              </Button>
            </div>
          </Tooltip>
        </div>
      )}

      {!evaluation.closed && !triages?.length && (
        <div className={styles.buttons}>
          <Button color="secondary" onClick={() => onAddTriage()}>
            {translate('memberEvents.detail.addTriage')}
          </Button>
        </div>
      )}

      {evaluation.closed && editMode && (
        <div className={styles.buttons}>
          <Button
            color="secondary"
            disabled={anyIterventionIsOpen()}
            onClick={() =>
              onUpdateEvaluation({
                ...evaluation,
                interventions: interventions || [],
              })
            }
          >
            {translate('memberEvents.detail.saveEvaluation')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default EventDetailEvaluation;
