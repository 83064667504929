import moment, { Moment } from 'moment';

import { DayOfWeek, MemberContactTimeType } from '../enums';
import { DATE_FORMAT } from '../utils/constants';

export class MemberContactInfoTimes {
  id = 0;

  dayOfWeek?: DayOfWeek;

  type?: MemberContactTimeType;

  startTime?: Moment;

  endTime?: Moment;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.dayOfWeek = obj.dayOfWeek ? DayOfWeek.byKey[obj.dayOfWeek] : undefined;
    this.type = MemberContactTimeType.byKey[obj.type];
    if (obj.startTime) {
      this.startTime = moment(
        `${moment().format(DATE_FORMAT)} ${obj.startTime}`,
      );
    }
    if (obj.endTime) {
      this.endTime = moment(`${moment().format(DATE_FORMAT)} ${obj.endTime}`);
    }
  }
}

export default MemberContactInfoTimes;
