import classNames from 'classnames';
import React, { useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OfficeIcon from '@mui/icons-material/Domain';
import FaxIcon from '@mui/icons-material/FaxOutlined';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import PhoneIcon from '@mui/icons-material/PhoneIphone';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import StarIcon from '@mui/icons-material/Star';
import { makeStyles } from '@mui/styles';

import { PhoneType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { format } from '@vestahealthcare/common/utils/phone';

import { IconButton } from '../IconButton';
import { Tooltip } from '../Tooltip';

type Props = {
  className?: string;
  compact?: boolean;
  phone: string;
  primary?: boolean;
  readOnly?: boolean;
  type?: string;
};

const useStyles = makeStyles({
  container: {
    height: 'fit-content',
  },
  copyIcon: {
    '&&': {
      padding: '0.25rem 0.5rem',
    },
  },
  small: {
    fontSize: '0.875em',
  },
});

const getPhoneIcon = (type?: string) => {
  let icon = <QuestionMarkIcon color="action" />;
  if (type === PhoneType.BUSINESS.toString())
    icon = <OfficeIcon color="action" />;
  if (type === PhoneType.HOME.toString()) icon = <HomeIcon color="action" />;
  if (type === PhoneType.CELL.toString()) icon = <PhoneIcon color="action" />;
  if (type === PhoneType.FAX.toString()) icon = <FaxIcon color="action" />;

  return <Tooltip text={type || 'Unknwon'}>{icon}</Tooltip>;
};

export const PhoneLink = ({
  className,
  compact,
  phone,
  primary,
  readOnly,
  type,
}: Props) => {
  const styles = useStyles();
  const [copied, setCopied] = useState<boolean>(false);

  const cophyPhone = (
    evt: React.MouseEvent<Element, MouseEvent>,
    copiedPhone: string,
  ) => {
    evt.stopPropagation();
    evt.preventDefault();
    if (copiedPhone) {
      navigator.clipboard.writeText(copiedPhone);
      setCopied(true);
    }
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <div
      className={classNames(className, styles.container, 'flex middle wrap')}
    >
      {compact && (
        <>
          {primary ? (
            <>
              <Tooltip text="Primary Phone">
                <StarIcon
                  fontSize="small"
                  color={readOnly ? 'action' : 'info'}
                />
              </Tooltip>
              &nbsp;
            </>
          ) : (
            <div style={{ padding: '0 9px' }} />
          )}
        </>
      )}
      {compact && <>{getPhoneIcon(type)}&nbsp;</>}
      {readOnly ? (
        <span className={styles.small}>{format(phone)} </span>
      ) : (
        <a className={styles.small} href={`tel:${phone}`}>
          {format(phone)}{' '}
        </a>
      )}
      {!readOnly && (
        <IconButton
          className={styles.copyIcon}
          onClick={(evt) => cophyPhone(evt, phone)}
          size="small"
        >
          <Tooltip
            text={
              copied
                ? translate('global.copied')
                : translate('global.copyPhone')
            }
          >
            <ContentCopyIcon color="info" fontSize="small" />
          </Tooltip>
        </IconButton>
      )}

      {!compact && primary && (
        <>
          &nbsp;
          <Tooltip text="Primary Phone">
            <StarIcon fontSize="small" color={readOnly ? 'action' : 'info'} />
          </Tooltip>
        </>
      )}
      {!compact && type && (
        <span className={styles.small} style={{ marginTop: '0.125rem' }}>
          {type}
        </span>
      )}
    </div>
  );
};
