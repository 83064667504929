import React, { useState } from 'react';
import { Button, EditIcon, Modal, TextInput } from 'styleguide';
import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';
import { updatePatient } from 'dash/src/services/PatientServices';

interface Props {
  member: Patient;
  onSubmit: () => void;
}

export const MemberStrengthsBarriers = ({ member, onSubmit }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [strengths, setStrengths] = useState(member.strengthsAndBarriers);
  const editable = member.isEditable();

  const onSave = async () => {
    try {
      setLoading(true);
      await updatePatient(member.id, { strengthsAndBarriers: strengths });
      setShowModal(false);
      onSubmit();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="h7 grid-span-12">
        {translate('goalsOfCare.strengthsBarriers')}
        {editable && (
          <EditIcon
            marginLeft
            data-cy="edit-strengths"
            onClick={() => setShowModal(true)}
            tag="button"
          />
        )}
      </div>
      <p className="grid-span-12">{member.strengthsAndBarriers || EMPTY}</p>
      {showModal && (
        <Modal onHide={() => setShowModal(false)} show>
          <Modal.Header
            closeButton
            modalTitle={translate('goalsOfCare.strengthsBarriers')}
          />
          <Modal.Body>
            <TextInput
              label={translate('goalsOfCare.whatAreStrengthsBarriers')}
              multiline={3}
              onChange={setStrengths}
              value={strengths}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button color="secondary" onClick={() => setShowModal(false)}>
              {translate('global.cancel')}
            </Button>
            <Button color="primary" loading={loading} onClick={onSave}>
              {translate('global.save')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default MemberStrengthsBarriers;
