import { Enum } from './Enum';

export class CampaignTiming extends Enum {
  translationKey = 'campaignTiming';

  static asArray: CampaignTiming[] = [];

  static byKey: { [name: string]: CampaignTiming } = {};

  static readonly IMMEDIATE = new CampaignTiming('IMMEDIATE');

  static readonly DELAYED = new CampaignTiming('DELAYED');

  constructor(public readonly value: string) {
    super();
    CampaignTiming.asArray.push(this);
    CampaignTiming.byKey[value] = this;
  }
}

export default CampaignTiming;
