import { Moment } from 'moment';

import {
  CommunityResource,
  Employee,
  EventMember,
  MemberDashboardEncounter,
} from '.';
import { make, makeMoment, makeUnix } from './Model';

export class SkilledItem {
  id = 0;

  agency: CommunityResource;

  assignee?: Employee;

  businessDaysSinceEndDate?: number;

  chiefComplaint = '';

  closedAt?: Moment;

  endDate?: Moment;

  episodeDuration?: number;

  lastTcmEncounter?: MemberDashboardEncounter;

  member: EventMember;

  handoffNotes = '';

  npVisit?: MemberDashboardEncounter;

  rnCarePlan?: MemberDashboardEncounter;

  sourceEventId = 0;

  startDate?: Moment;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.agency = make(obj.agency, CommunityResource);
    this.assignee = make(obj.assignee, Employee);
    this.closedAt = makeUnix(obj.closedAt);
    this.endDate = makeMoment(obj.endDate);
    this.lastTcmEncounter = make(
      obj.lastTcmEncounter,
      MemberDashboardEncounter,
    );
    this.member = make(obj.member, EventMember);
    this.npVisit = make(obj.npVisit, MemberDashboardEncounter);
    this.rnCarePlan = make(obj.rnCarePlan, MemberDashboardEncounter);
    this.startDate = makeMoment(obj.startDate);
  }

  get isClosed() {
    return !!this.closedAt;
  }
}

export default SkilledItem;
