import { Enum } from './Enum';

export class ResidenceType extends Enum {
  translationKey = 'residenceType';

  static asArray: ResidenceType[] = [];

  static byKey: { [name: string]: ResidenceType } = {};

  static readonly PRIVATE_HOME = new ResidenceType('PRIVATE_HOME');

  static readonly APARTMENT = new ResidenceType('APARTMENT');

  static readonly REHAB = new ResidenceType('REHAB');

  static readonly NURSING_HOME = new ResidenceType('NURSING_HOME');

  static readonly SENIOR_LIVING_COMMUNITY = new ResidenceType(
    'SENIOR_LIVING_COMMUNITY',
  );

  static readonly HOSPICE = new ResidenceType('HOSPICE');

  static readonly OTHER = new ResidenceType('OTHER');

  constructor(public readonly value: string) {
    super();
    ResidenceType.asArray.push(this);
    ResidenceType.byKey[value] = this;
  }
}

export default ResidenceType;
