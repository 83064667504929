import React from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { PhoneUser } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { BrandMemberIcon, Colors } from 'styleguide-v2';
import Variables from 'styleguide-v2/src/styles/Variables';

type Props = {
  'data-cy'?: string;
  userPhones: PhoneUser[];
  unread?: boolean;
};

const useStyles = makeStyles({
  bold: {
    fontWeight: 500,
    width: 'max-content',
  },
  subtitle: {
    color: Colors.gray,
    fontWeight: 500,
    fontSize: `calc(${Variables.fontSize} * 0.75)`,
    width: 'max-content',
  },
  subtitleId: {
    fontWeight: 400,
    width: 'max-content',
  },
});

export const FromUser = ({ 'data-cy': dataCy, unread, userPhones }: Props) => {
  const styles = useStyles();

  const getTitle = () => {
    if (!userPhones?.length) {
      return EMPTY;
    }
    if (userPhones.length === 1) {
      return (
        <div className="flex gap middle">
          {userPhones[0].user.fullName}
          {userPhones[0].isBrandCareAtHome() && (
            <BrandMemberIcon size="s" brand="careAtHome" />
          )}
        </div>
      );
    }
    const members = userPhones.filter(({ user }) => user.isMember());
    const careTeamPersons = userPhones.filter(({ user }) =>
      user.isCareTeamPerson(),
    );
    const caregivers = userPhones.filter(({ user }) => user.isCaregiver());
    if (members.length === 1) {
      return (
        <div className="flex gap middle">
          {members[0].user.fullName}
          {members[0].isBrandCareAtHome() && (
            <BrandMemberIcon size="s" brand="careAtHome" />
          )}
        </div>
      );
    }
    if (members.length === 0) {
      return `${caregivers.length} ${translate('chat.careTeam')}`;
    }
    if (members.length === 0 && careTeamPersons.length === 0) {
      return `${caregivers.length} ${translate('chat.caregivers')}`;
    }
    return `${members.length} ${translate('chat.members')}`;
  };

  const getSubtitle = () => {
    if (userPhones.length === 1) {
      const user = userPhones[0]?.user;
      const id = userPhones[0]?.user.id;
      let type = 'member';
      if (user.isCareTeamPerson()) type = 'careTeam';
      if (user.isCaregiver()) type = 'caregiver';

      return [
        {
          type: translate(`chat.${type}`),
          id,
        },
      ];
    }
    if (userPhones.length > 1) {
      const members = userPhones.filter(({ user }) => user.isMember());
      const caregivers = userPhones.filter(({ user }) => user.isCaregiver());
      const careTeamPersons = userPhones.filter(({ user }) =>
        user.isCareTeamPerson(),
      );
      const result = [];
      if (members?.length) {
        result.push({
          type: translate('chat.member'),
          id: members.map(({ user }) => user.id).join(', '),
        });
      }
      if (careTeamPersons?.length) {
        result.push({
          type: translate('chat.careTeam'),
          id: careTeamPersons.map(({ user }) => user.id).join(', '),
        });
      }
      if (caregivers?.length) {
        result.push({
          type: translate('chat.caregiver'),
          id: caregivers.map(({ user }) => user.id).join(', '),
        });
      }
      return result;
    }
    return [];
  };

  return (
    <div data-cy={dataCy}>
      <p className={unread ? styles.bold : ''}>{getTitle()}</p>
      {getSubtitle().map(
        (
          { type, id }: { type: string; id: string | number },
          index: number,
        ) => (
          <p key={index} className={styles.subtitle}>
            <span>{type} </span>
            <span className={styles.subtitleId}>{id}</span>
          </p>
        ),
      )}
    </div>
  );
};

export default FromUser;
