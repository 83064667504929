import { Enum } from './Enum';

export class GoalStatus extends Enum {
  translationKey = 'goalStatus';

  static asArray: GoalStatus[] = [];

  static byKey: { [name: string]: GoalStatus } = {};

  static readonly READY = new GoalStatus('READY');

  static readonly IN_PROGRESS = new GoalStatus('IN_PROGRESS');

  static readonly ON_HOLD = new GoalStatus('ON_HOLD');

  static readonly GOAL_MET = new GoalStatus('GOAL_MET');

  static readonly GOAL_NOT_MET = new GoalStatus('GOAL_NOT_MET');

  static readonly GOAL_PARTIALLY_MET = new GoalStatus('GOAL_PARTIALLY_MET');

  constructor(public readonly value: string) {
    super();
    GoalStatus.asArray.push(this);
    GoalStatus.byKey[value] = this;
  }
}

export default GoalStatus;
