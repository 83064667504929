import store from 'dash/src/redux/store';

export const GlobalActions = {
  SET_CONNECTION: 'SET_CONNECTION',
  SET_UPDATE_AVAILABLE: 'SET_UPDATE_AVAILABLE',
  TIMER_TICK: 'TIMER_TICK',
  STOP_TIMER: 'STOP_TIMER',
  START_TIMER: 'START_TIMER',
  BROADCAST_MESSAGE: 'BROADCAST_MESSAGE',
  CLOSE_MESSAGE: 'CLOSE_MESSAGE',
  USER_CHANGED_WARNING: 'USER_CHANGED_WARNING',
  SET_CONFLICTING_DATA_WARNING: 'SET_CONFLICTING_DATA_WARNING',
};

export const setConnection = (hasConnection: boolean) => ({
  hasConnection,
  type: GlobalActions.SET_CONNECTION,
});

export const setUpdateAvailable = (updateAvailable: boolean) => ({
  updateAvailable,
  type: GlobalActions.SET_UPDATE_AVAILABLE,
});

export const stopTimerAction = () => ({
  type: GlobalActions.STOP_TIMER,
});

export const startTimerAction = () => ({
  type: GlobalActions.START_TIMER,
});

export const timerTick = () => ({
  type: GlobalActions.TIMER_TICK,
});

export const userChangedWarning = () => ({
  type: GlobalActions.USER_CHANGED_WARNING,
});

export const showConflictingDataWarning = (message: string) => ({
  message,
  type: GlobalActions.SET_CONFLICTING_DATA_WARNING,
});

export const closeConflictingDataWarning = () => ({
  type: GlobalActions.SET_CONFLICTING_DATA_WARNING,
  message: null,
});

window.addEventListener('offline', () => {
  store.dispatch({
    type: GlobalActions.SET_CONNECTION,
    hasConnection: false,
  });
});

window.addEventListener('online', () => {
  store.dispatch({
    type: GlobalActions.SET_CONNECTION,
    hasConnection: true,
  });
});

export const closeGlobalMessage = () => ({
  type: GlobalActions.CLOSE_MESSAGE,
});

export const broadcastMessage = (payload: any) => broadcast(payload);
export const broadcastWarning = (payload: any) => broadcast(payload, 'warning');
export const broadcastError = (payload: any) => broadcast(payload, 'error');

const broadcast = (payload = {}, type = '') => {
  let messagePayload: any;
  if (typeof payload === 'string') {
    messagePayload = {
      messages: [{ message: payload }],
    };
  } else {
    messagePayload = { ...payload };
  }
  messagePayload.type = type;
  messagePayload.isOpen = true;
  return {
    messagePayload,
    type: GlobalActions.BROADCAST_MESSAGE,
  };
};

export const getMessagePayload = (state: any) => state.global.messagePayload;
export const getTimeRemaining = (state: any) => state.global.timeRemaining;
export const getUserChangedWarningStatus = (state: any) =>
  state.global.showUserChangedWarning;
export const getConflictingDataWarningMessage = (state: any) =>
  state.global.conflictingDataWarningMessage;
export const hasConnection = (state: any) => state.global.hasConnection;
export const hasUpdateAvailable = (state: any) => state.global.updateAvailable;
