import { make } from './Model';
import { EventModel } from './EventModel';
import { EventLinkReason } from './EventLinkReason';

export class EventLink {
  id = 0;

  sourceEvent: EventModel;

  targetEvent: EventModel;

  linkReason: EventLinkReason;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.sourceEvent = make(obj.sourceEvent, EventModel);
    this.targetEvent = make(obj.targetEvent, EventModel);
    this.linkReason = make(obj.linkReason, EventLinkReason);
  }
}

export default EventLink;
