import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  broadcastError,
  getMessagePayload,
  closeGlobalMessage,
  broadcastWarning,
} from 'dash/src/redux/actions/GlobalActions';
import store from 'dash/src/redux/store';
import Message from './Message';

const mapStateToProps = (state: any) => ({
  messagePayload: getMessagePayload(state),
});

const dispatchers = (dispatch: any) => ({
  clearAndClose: () => dispatch(closeGlobalMessage()),
});

interface Props {
  messagePayload: any;
  clearAndClose: () => void;
}

class GlobalMessages extends Component<Props> {
  getProps(payload: any) {
    let props;

    if (typeof payload === 'string') {
      // Single string
      props = { messages: [{ message: payload }] };
    } else if (payload && payload.isResponseError) {
      // Build the message with the response error
      props = {
        ...payload,
        title: payload.message,
        messages:
          payload.allErrors && payload.allErrors.length
            ? payload.allErrors
            : undefined,
      };
    } else if (payload && typeof payload.message === 'string') {
      // The legacy way: If it's an error and we pass a single `message` convert it
      // to the right structure. Also handles Error instances
      props = {
        ...payload,
        messages: [{ message: payload.message, detail: payload.detail }],
      };
    } else {
      // Assumed we got the expected structure, or just nothing:
      // { messages: [{ message, detail }] }
      props = payload || {};
    }

    return props;
  }

  render() {
    const { messagePayload, clearAndClose } = this.props;

    return (
      <>
        <Message
          open={messagePayload.isOpen}
          {...this.getProps(messagePayload)}
          onDismiss={() => clearAndClose()}
        />
      </>
    );
  }
}

export const showGlobalError = (error: string) =>
  store.dispatch(broadcastError(error));

export const showGlobalWarning = (warning: string | object) =>
  store.dispatch(broadcastWarning(warning));

export default connect(mapStateToProps, dispatchers)(GlobalMessages);
