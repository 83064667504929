import React from 'react';

import { Enum, Selectable } from './Enum';

export class Language extends Enum {
  translationKey = 'language';

  static asArray: Language[] = [];

  static byKey: { [name: string]: Language } = {};

  static readonly AFRIKAANS = new Language('AFRIKAANS', 'af');

  static readonly AKAN = new Language('AKAN', 'ak');

  static readonly ALBANIAN = new Language('ALBANIAN', 'sq');

  static readonly AMHARIC = new Language('AMHARIC', 'am');

  static readonly ANLO_EWE = new Language('ANLO_EWE', null);

  static readonly ARABIC = new Language('ARABIC', 'ar');

  static readonly ARAMAIC = new Language('ARAMAIC', null);

  static readonly ARMENIAN = new Language('ARMENIAN', 'hy');

  static readonly ASSYRIAN = new Language('ASSYRIAN', null);

  static readonly AZERBAIJANI = new Language('AZERBAIJANI', 'az');

  static readonly BALINESE = new Language('BALINESE', null);

  static readonly BALOCHI = new Language('BALOCHI', null);

  static readonly BAMBARA = new Language('BAMBARA', 'bm');

  static readonly BANGALI = new Language('BANGALI', null);

  static readonly BELARUSIAN = new Language('BELARUSIAN', 'be');

  static readonly BOSNIAN = new Language('BOSNIAN', 'bs');

  static readonly BULGARIAN = new Language('BULGARIAN', 'bg');

  static readonly BURMESE = new Language('BURMESE', 'my');

  static readonly CANTONESE = new Language('CANTONESE', null);

  static readonly CARIBEAN = new Language('CARIBEAN', null);

  static readonly CATALAN = new Language('CATALAN', 'ca');

  static readonly CEBUANO = new Language('CEBUANO', null);

  static readonly CHAMORRO = new Language('CHAMORRO', 'ch');

  static readonly CHEROKEE = new Language('CHEROKEE', null);

  static readonly CHICHEWA = new Language('CHICHEWA', 'ny');

  static readonly CHINESE = new Language('CHINESE', 'zh');

  static readonly CHINESE_TRADITIONAL = new Language(
    'CHINESE_TRADITIONAL',
    'zh-HK',
  );

  static readonly CORSA = new Language('CORSA', null);

  static readonly CREE = new Language('CREE', 'cr');

  static readonly CREOLE = new Language('CREOLE', null);

  static readonly CROATIAN = new Language('CROATIAN', 'hr');

  static readonly CZECH = new Language('CZECH', 'cs');

  static readonly DANISH = new Language('DANISH', 'da');

  static readonly DARI = new Language('DARI', null);

  static readonly DARI_PERSIAN = new Language('DARI_PERSIAN', null);

  static readonly DIDA = new Language('DIDA', null);

  static readonly DOLGAN = new Language('DOLGAN', null);

  static readonly DUTCH = new Language('DUTCH', null);

  static readonly DZONGKHA = new Language('DZONGKHA', 'dz');

  static readonly EGYPTIAN_ARABIC = new Language('EGYPTIAN_ARABIC', null);

  static readonly ENGLISH = new Language('ENGLISH', 'en');

  static readonly ERZYA = new Language('ERZYA', null);

  static readonly ESTONIAN = new Language('ESTONIAN', 'et');

  static readonly EWE = new Language('EWE', 'ee');

  static readonly FARSI = new Language('FARSI', null);

  static readonly FIJIAN = new Language('FIJIAN', 'fj');

  static readonly FILIPINO = new Language('FILIPINO', null);

  static readonly FINNISH = new Language('FINNISH', 'fi');

  static readonly FRENCH = new Language('FRENCH', 'fr');

  static readonly FULA = new Language('FULA', null);

  static readonly FULAH = new Language('FULAH', 'ff');

  static readonly FUR = new Language('FUR', null);

  static readonly GA = new Language('GA', null);

  static readonly GARIFUNA = new Language('GARIFUNA', null);

  static readonly GEORGIAN = new Language('GEORGIAN', 'ka');

  static readonly GERMAN = new Language('GERMAN', 'de');

  static readonly GIKUYU = new Language('GIKUYU', null);

  static readonly GREEK = new Language('GREEK', null);

  static readonly GUJARATI = new Language('GUJARATI', 'gu');

  static readonly HAITIAN_CREOLE = new Language('HAITIAN_CREOLE', 'ht');

  static readonly HAKKA = new Language('HAKKA', null);

  static readonly HEBREW = new Language('HEBREW', 'he');

  static readonly HINDI = new Language('HINDI', 'hi');

  static readonly HMONG = new Language('HMONG', null);

  static readonly HO = new Language('HO', null);

  static readonly HUNGARIAN = new Language('HUNGARIAN', 'hu');

  static readonly IBAN = new Language('IBAN', null);

  static readonly IBANAG = new Language('IBANAG', null);

  static readonly IBIBIO = new Language('IBIBIO', null);

  static readonly IBO = new Language('IBO', null);

  static readonly ICELANDIC = new Language('ICELANDIC', 'is');

  static readonly IGBO = new Language('IGBO', 'ig');

  static readonly IKALANGA = new Language('IKALANGA', null);

  static readonly ILLOCANO = new Language('ILLOCANO', null);

  static readonly INDONESIAN = new Language('INDONESIAN', 'id');

  static readonly ITALIAN = new Language('ITALIAN', 'it');

  static readonly JAPANESE = new Language('JAPANESE', 'ja');

  static readonly KALASHA = new Language('KALASHA', null);

  static readonly KANNANDA = new Language('KANNANDA', null);

  static readonly KARELIAN = new Language('KARELIAN', null);

  static readonly KAYBYLE = new Language('KAYBYLE', null);

  static readonly KAZAKH = new Language('KAZAKH', 'kk');

  static readonly KHALAJ = new Language('KHALAJ', null);

  static readonly KHAM = new Language('KHAM', null);

  static readonly KHMER = new Language('KHMER', null);

  static readonly KHMU = new Language('KHMU', null);

  static readonly KINYARWANDA = new Language('KINYARWANDA', 'rw');

  static readonly KIRUNDI = new Language('KIRUNDI', null);

  static readonly KOREAN = new Language('KOREAN', 'ko');

  static readonly KURDISH = new Language('KURDISH', 'ku');

  static readonly LAO = new Language('LAO', 'lo');

  static readonly LATVIAN = new Language('LATVIAN', 'lv');

  static readonly LINGALA = new Language('LINGALA', 'ln');

  static readonly LITHUANIAN = new Language('LITHUANIAN', 'lt');

  static readonly MACEDONIAN = new Language('MACEDONIAN', 'mk');

  static readonly MALAY = new Language('MALAY', 'ms');

  static readonly MALAYALAM = new Language('MALAYALAM', 'ml');

  static readonly MALDIVIAN = new Language('MALDIVIAN', 'dv');

  static readonly MALTESE = new Language('MALTESE', 'mt');

  static readonly MANDARIN = new Language('MANDARIN', null);

  static readonly MANDINKA = new Language('MANDINKA', null);

  static readonly MAORI = new Language('MAORI', 'mi');

  static readonly MARI = new Language('MARI', null);

  static readonly MARSHALLESE = new Language('MARSHALLESE', 'mh');

  static readonly MONGOLIAN = new Language('MONGOLIAN', 'mn');

  static readonly NAVAJO = new Language('NAVAJO', 'nv');

  static readonly NEPAL_BHASA = new Language('NEPAL_BHASA', null);

  static readonly NEPALI = new Language('NEPALI', 'ne');

  static readonly NORWEGIAN = new Language('NORWEGIAN', 'nn');

  static readonly OROMO = new Language('OROMO', 'om');

  static readonly OTHER = new Language('OTHER', null);

  static readonly PAHARI = new Language('PAHARI', null);

  static readonly PANGASINAN = new Language('PANGASINAN', null);

  static readonly PANJABI = new Language('PANJABI', 'pa');

  static readonly PASHTO = new Language('PASHTO', 'ps');

  static readonly PATOIS = new Language('PATOIS', null);

  static readonly PERSIAN = new Language('PERSIAN', 'fa');

  static readonly PHOENICIAN = new Language('PHOENICIAN', null);

  static readonly POLISH = new Language('POLISH', 'pl');

  static readonly PORTUGUESE = new Language('PORTUGUESE', 'pt');

  static readonly PUNJABI = new Language('PUNJABI', null);

  static readonly ROMANIAN = new Language('ROMANIAN', 'ro');

  static readonly RUSSIAN = new Language('RUSSIAN', 'ru');

  static readonly SAMOAN = new Language('SAMOAN', 'sm');

  static readonly SERBIAN = new Language('SERBIAN', 'sr');

  static readonly SERBO_CROATIAN = new Language('SERBO_CROATIAN', null);

  static readonly SICIALIAN = new Language('SICIALIAN', null);

  static readonly SIGN_LANGUAGE = new Language('SIGN_LANGUAGE', null);

  static readonly SINDHI = new Language('SINDHI', 'sd');

  static readonly SINHALA = new Language('SINHALA', 'si');

  static readonly SLOVAK = new Language('SLOVAK', 'sk');

  static readonly SLOVENIAN = new Language('SLOVENIAN', 'sl');

  static readonly SOMALI = new Language('SOMALI', 'so');

  static readonly SPANISH = new Language('SPANISH', 'es');

  static readonly SUDANESE = new Language('SUDANESE', null);

  static readonly SWAHILI = new Language('SWAHILI', 'sw');

  static readonly SWEDISH = new Language('SWEDISH', 'sv');

  static readonly TAGALOG = new Language('TAGALOG', 'tl');

  static readonly TAJIK = new Language('TAJIK', 'tg');

  static readonly TAMIL = new Language('TAMIL', 'ta');

  static readonly TATAR = new Language('TATAR', 'tt');

  static readonly TELUGU = new Language('TELUGU', 'te');

  static readonly THAI = new Language('THAI', 'th');

  static readonly TIBETAN = new Language('TIBETAN', 'bo');

  static readonly TIGRE = new Language('TIGRE', null);

  static readonly TIGRINYA = new Language('TIGRINYA', 'ti');

  static readonly TONGA = new Language('TONGA', 'to');

  static readonly TONGAN = new Language('TONGAN', null);

  static readonly TURKISH = new Language('TURKISH', 'tr');

  static readonly TUROYO = new Language('TUROYO', null);

  static readonly TUVULUAN = new Language('TUVULUAN', null);

  static readonly UKRAINIAN = new Language('UKRAINIAN', 'uk');

  static readonly URDU = new Language('URDU', 'ur');

  static readonly UZBECK = new Language('UZBECK', null);

  static readonly VIETNAMESE = new Language('VIETNAMESE', 'vi');

  static readonly WELSH = new Language('WELSH', 'cy');

  static readonly WOLOF = new Language('WOLOF', 'wo');

  static readonly WU = new Language('WU', null);

  static readonly YIDDISH = new Language('YIDDISH', 'yi');

  static readonly YORUBA = new Language('YORUBA', 'yo');

  static readonly ZULU = new Language('ZULU', 'zu');

  constructor(
    public readonly value: string,
    public readonly isoTwoCharCode: string | null,
  ) {
    super();
    Language.asArray.push(this);
    Language.byKey[value] = this;
  }

  get locale() {
    if (this.value === Language.SPANISH.value) {
      return 'es';
    }
    return 'en';
  }

  // order should be English, Spanish, Russian (if they exist), then the rest in the order they're entered
  static sortByHTPreferredOrder(values: string[]) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const preferredLanguageOrder = getPreferredLanguageOrder();
    return values.sort(
      (a, b) =>
        preferredLanguageOrder.indexOf(b) - preferredLanguageOrder.indexOf(a),
    );
  }

  // return sorted languages and adds a separator right after preferred ones
  static toSelectable(arr: Language[]): Selectable[] {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const preferredLanguageOrder = getPreferredLanguageOrder();

    const selectedOptions = arr.map((enumValue: Language) => ({
      value: enumValue.value,
      label: enumValue.toString(),
      preferred: preferredLanguageOrder.indexOf(enumValue.value) !== -1,
    }));

    const preferredOptions = selectedOptions
      .filter((language) => preferredLanguageOrder.includes(language.value))
      .sort(
        (a, b) =>
          preferredLanguageOrder.indexOf(b.value) -
          preferredLanguageOrder.indexOf(a.value),
      );

    const separatorOption = {
      label: React.createElement('hr'),
      value: '_SEPARATOR_',
      disabled: true,
    };

    const otherOptions = selectedOptions.filter(
      (language) => !preferredOptions.includes(language),
    );

    return [...preferredOptions, separatorOption, ...otherOptions];
  }

  valueOf() {
    return this.value;
  }

  static PREFERRED_LANGUAGES = [
    Language.ENGLISH,
    Language.SPANISH,
    Language.RUSSIAN,
    Language.CHINESE_TRADITIONAL,
  ];
}

const getPreferredLanguageOrder = () =>
  Language.PREFERRED_LANGUAGES.map(
    (language: Language) => language.value,
  ).reverse();

export default Language;
