import moment, { Moment } from 'moment';

import { Employee } from './Employee';
import { EmployeeGroup } from './EmployeeGroup';
import { IncidentStatus } from './IncidentStatus';
import { IncidentType } from './IncidentType';
import { make, makeArray } from './Model';
import { Patient } from './Patient';

export class Incident {
  id = 0;

  assignee: Employee;

  assigneeGroup: EmployeeGroup;

  externalReporter = false;

  member: Patient;

  reportedDate: Moment;

  reporter: Employee;

  status: IncidentStatus;

  statusUpdatedAt?: Moment;

  types: IncidentType[];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.assignee = make(obj.assignee, Employee);
    this.assigneeGroup = make(obj.assigneeGroup, EmployeeGroup);
    this.member = make(obj.member, Patient);
    this.reportedDate = moment.unix(obj.reportedDate);
    this.reporter = make(obj.reporter, Employee);
    this.status = make(obj.status, IncidentStatus);
    this.types = makeArray(obj.types, IncidentType);
    this.statusUpdatedAt = obj.statusUpdatedAt
      ? moment.unix(obj.statusUpdatedAt)
      : undefined;
  }
}

export default Incident;
