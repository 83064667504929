import { CareTeamMember } from './CareTeamMember';

export class CCMCareTeamMember extends CareTeamMember {
  subtype = '';

  constructor(obj: any) {
    super(obj);
    this.subtype = obj.subtype;
  }
}

export default CCMCareTeamMember;
