import React, { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';

import { translate } from '@vestahealthcare/common/i18n';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { IconButton } from 'styleguide-v2';
import { Option } from 'styleguide/src/components/ListPickerWithPreferred/ListPickerRow';

import EditListModal from './EditListModal';
import './main.less';

interface Props {
  autoFocus?: boolean;
  formatter?: (value: string) => string;
  items: string[];
  onSave?: (selections: string[], preferred: string) => Promise<boolean>;
  options: Option[];
  preferred: string;
  readOnly?: boolean;
  readOnlyPreferred?: boolean;
  required?: boolean;
  sort?: (values: string[]) => string[];
}

const EditList = (props: Props) => {
  const {
    items,
    preferred,
    autoFocus,
    formatter,
    options,
    sort,
    readOnly,
    readOnlyPreferred,
    required,
  } = props;

  const [isEditOpen, setIsEditOpen] = useState(false);

  const onModalSave = async (items: string[], preferred: string) => {
    const { onSave } = props;
    if (onSave) {
      return onSave(items, preferred);
    }
    return false;
  };

  const renderItems = () => {
    const formattedItems = sort ? sort(items) : items;

    if (!formattedItems.length) {
      return EMPTY;
    }

    return formattedItems.map((item: string, index: number) => {
      const formattedItem = formatter ? formatter(item) : item;
      return (
        <div className="column-list-item" key={index}>
          <span key={index}>{formattedItem}</span>
          {item === preferred && (
            <>
              &nbsp;
              <span className="small">
                {`(${translate('contacts.preferred').toLowerCase()})`}
              </span>
            </>
          )}
        </div>
      );
    });
  };

  return (
    <>
      <div className="edit-item-list">
        <div className="column-list" data-cy="member-details-list">
          {renderItems()}
        </div>
        {!readOnly && (
          <IconButton size="small" onClick={() => setIsEditOpen(true)}>
            <EditIcon />
          </IconButton>
        )}
      </div>
      {isEditOpen && (
        <EditListModal
          autoFocus={autoFocus}
          items={items}
          readOnlyPreferred={readOnlyPreferred}
          preferred={preferred}
          show={isEditOpen}
          options={options}
          required={required}
          onClose={() => setIsEditOpen(false)}
          onSave={onModalSave}
        />
      )}
    </>
  );
};

export default EditList;
