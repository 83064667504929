import { MemberWarning } from './MemberWarning';
import { MemberWarningCarePlanUpdate } from './MemberWarningCarePlanUpdate';
import { MemberWarningCategory } from './MemberWarningCategory';
import { MemberWarningInitialCarePlan } from './MemberWarningInitialCarePlan';
import { MemberWarningNPBiannual } from './MemberWarningNPBiannual';
import { MemberWarningOpenItems } from './MemberWarningOpenItems';
import { MemberWarningOpenItemsActions } from './MemberWarningOpenItemsActions';
import { MemberWarningRPM } from './MemberWarningRPM';
import { MemberWarningTOC } from './MemberWarningTOC';
import { MemberWarningTime } from './MemberWarningTime';
import { MemberWarningType } from './MemberWarningType';

export class MemberWarningDetail extends MemberWarning {
  static getDetail(detail: MemberWarningDetail) {
    switch (detail.type.id) {
      case MemberWarningType.DUE_CARE_PLAN_INITIAL:
        return new MemberWarningInitialCarePlan(detail);
      case MemberWarningType.DUE_CARE_PLAN_UPDATE:
        return new MemberWarningCarePlanUpdate(detail);
      case MemberWarningType.DUE_NP_BIANNUAL:
        return new MemberWarningNPBiannual(detail);
      case MemberWarningType.MISSING_PCP:
      case MemberWarningType.MISSING_PHARMACY:
        return new MemberWarningDetail(detail);
      case MemberWarningType.OPEN_ACTIONS:
        return new MemberWarningOpenItemsActions(detail);
      case MemberWarningType.OPEN_EVENTS:
      case MemberWarningType.OPEN_TASKS:
        return new MemberWarningOpenItems(detail);
      case MemberWarningType.RPM_AT_RISK:
      case MemberWarningType.RPM_MISSED:
        return new MemberWarningRPM(detail);
      case MemberWarningType.CCM_TIME:
      case MemberWarningType.RPM_TIME:
        return new MemberWarningTime(detail);
      case MemberWarningType.TOC:
        return new MemberWarningTOC(detail);
      default:
        return new MemberWarningDetail(detail);
    }
  }

  static isInitialCarePlan(
    detail: MemberWarningDetail,
  ): detail is MemberWarningInitialCarePlan {
    return detail instanceof MemberWarningInitialCarePlan;
  }

  static isCarePlanUpdate(
    detail: MemberWarningDetail,
  ): detail is MemberWarningCarePlanUpdate {
    return detail instanceof MemberWarningCarePlanUpdate;
  }

  static isMissingPCPInfo(detail: MemberWarningDetail) {
    return detail.type.id === MemberWarningType.MISSING_PCP;
  }

  static isMissingPharmacyInfo(detail: MemberWarningDetail) {
    return detail.type.id === MemberWarningType.MISSING_PHARMACY;
  }

  static isOpenItems(
    detail: MemberWarningDetail,
  ): detail is MemberWarningOpenItems {
    return detail instanceof MemberWarningOpenItems;
  }

  static isMissingConsent(detail: MemberWarningDetail) {
    return [
      MemberWarningType.MISSING_CONSENT_CCM,
      MemberWarningType.MISSING_CONSENT_HIE,
      MemberWarningType.MISSING_CONSENT_HIPAA,
      MemberWarningType.MISSING_CONSENT_PROGRAM,
      MemberWarningType.MISSING_CONSENT_TEXT,
    ].includes(detail.type.id);
  }

  static isNPBiannual(
    detail: MemberWarningDetail,
  ): detail is MemberWarningNPBiannual {
    return detail instanceof MemberWarningNPBiannual;
  }

  static isOpenActions(
    detail: MemberWarningDetail,
  ): detail is MemberWarningOpenItems {
    return detail.type.id === MemberWarningType.OPEN_ACTIONS;
  }

  static isOpenEvents(detail: MemberWarningDetail) {
    return detail.type.id === MemberWarningType.OPEN_EVENTS;
  }

  static isOpenTasks(detail: MemberWarningDetail) {
    return detail.type.id === MemberWarningType.OPEN_TASKS;
  }

  static isRPMRisk(detail: MemberWarningDetail): detail is MemberWarningRPM {
    return (
      detail instanceof MemberWarningRPM &&
      detail.type.id === MemberWarningType.RPM_AT_RISK
    );
  }

  static isRPMMissed(detail: MemberWarningDetail): detail is MemberWarningRPM {
    return (
      detail instanceof MemberWarningRPM &&
      detail.type.id === MemberWarningType.RPM_MISSED
    );
  }

  static isTime(detail: MemberWarningDetail): detail is MemberWarningTime {
    return (
      detail instanceof MemberWarningTime &&
      MemberWarningCategory.TIME === detail.type.category.id
    );
  }

  static isTOC(detail: MemberWarningDetail): detail is MemberWarningTOC {
    return (
      detail instanceof MemberWarningTOC &&
      MemberWarningCategory.TOC === detail.type.category.id
    );
  }
}

export default MemberWarningDetail;
