import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { PatientMedicalEquipment } from '@vestahealthcare/common/models';
import { DATE_FORMAT } from '@vestahealthcare/common/utils/constants';

import { Button, CollapsableSidebar, DateTimePicker } from 'styleguide-v2';

import { DiscontinueParams } from 'dash/src/services/EquipmentServices';

interface Props {
  dme?: PatientMedicalEquipment;
  onClose: () => void;
  onSubmit: (params: DiscontinueParams) => Promise<boolean>;
  open: boolean;
}

export const DMEDeleteModal = ({ dme, onClose, onSubmit, open }: Props) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [discontinuedAt, setDiscontinuedAt] = useState<Date>();

  useEffect(() => {
    if (open) {
      setDiscontinuedAt(undefined);
    }
  }, [open]);

  const doSubmit = async () => {
    setSubmitted(true);
    if (discontinuedAt && moment(discontinuedAt.getTime()).isValid()) {
      setLoading(true);
      try {
        await onSubmit({
          discontinueDate: moment(discontinuedAt.getTime()).format(DATE_FORMAT),
        });
      } finally {
        setSubmitted(false);
        setLoading(false);
        onClose();
      }
    }
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={
        <h2>
          {translate('memberProfile.clinicalSummary.tables.dme.deleteModal', {
            dme: dme?.description,
          })}
        </h2>
      }
      size={450}
    >
      <CollapsableSidebar.Body>
        <DateTimePicker
          error={
            submitted &&
            !discontinuedAt &&
            translate('global.missingRequiredFieldMin')
          }
          label={translate(
            'memberProfile.clinicalSummary.tables.dme.discontinueDate',
          )}
          maxDate={Date.now()}
          onChange={setDiscontinuedAt}
          value={discontinuedAt}
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button color="tertiary" data-cy="edit-group-close" onClick={onClose}>
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="edit-group-submit"
          disabled={!discontinuedAt}
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default DMEDeleteModal;
