import React from 'react';
import { Patient, Employee } from '@vestahealthcare/common/models';
import {
  TEST_PATIENT_ID,
  TEST_RN_ID,
} from '@vestahealthcare/common/utils/constants';

interface MemberProfileContextProps {
  patient: Patient;
  updatePatient?: () => Promise<Patient>;
}

// Default test patient allows us run tests without setting provider everywhere
const patient = new Patient({
  id: TEST_PATIENT_ID,
  owner: new Employee({
    id: TEST_RN_ID,
  }),
});

export const MemberProfileContext = React.createContext<
  MemberProfileContextProps
>({ patient });
