import { Moment } from 'moment';
import { MemberConsentType } from '../enums';
import moment from '../moment';
import { Employee } from './Employee';
import { make } from './Model';

export enum ConsentAnswer {
  NO = 'NO',
  YES = 'YES',
  ONLY_MEDICAL_EMERGENCY = 'ONLY_MEDICAL_EMERGENCY',
  YES_WITH_EXCEPTIONS = 'YES_WITH_EXCEPTIONS',
}

export class MemberConsentAnswer {
  id = 0;

  answer?: ConsentAnswer;

  consentRequestId = 0;

  dateReceived?: Moment;

  dateSent?: Moment;

  name = '';

  notes?: string;

  type?: MemberConsentType;

  updatedAt?: Moment;

  updatedBy?: Employee;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.dateReceived = obj.dateReceived
      ? moment.unix(obj.dateReceived)
      : undefined;
    this.dateSent = obj.dateSent ? moment.unix(obj.dateSent) : undefined;
    this.type = MemberConsentType.byKey[obj.type];
    this.updatedBy = make(obj.updatedBy, Employee);
    this.updatedAt = obj.updatedAt ? moment.unix(obj.updatedAt) : undefined;
  }

  get isReceived() {
    return (
      this.answer === ConsentAnswer.YES ||
      this.answer === ConsentAnswer.YES_WITH_EXCEPTIONS
    );
  }

  get isDenied() {
    return (
      this.answer === ConsentAnswer.NO ||
      this.answer === ConsentAnswer.ONLY_MEDICAL_EMERGENCY
    );
  }

  get hasExceptions() {
    return (
      this.answer === ConsentAnswer.YES_WITH_EXCEPTIONS ||
      this.answer === ConsentAnswer.ONLY_MEDICAL_EMERGENCY
    );
  }
}

export default MemberConsentAnswer;
