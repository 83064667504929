import { CareTeamMember } from './CareTeamMember';

export class CareTeamMemberFamily extends CareTeamMember {
  livesWithMember?: boolean;

  paid?: boolean;

  constructor(obj: any) {
    super(obj);

    this.livesWithMember = obj.livesWithMember;
    this.paid = obj.paid;
  }
}

export default CareTeamMemberFamily;
