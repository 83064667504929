import moment, { Moment } from 'moment';

import { ClinicalMember } from './ClinicalMember';
import { EmployeeGroup } from './EmployeeGroup';
import { hasBrandCareAtHome } from './Helpers/PatientBrandHelper';
import { make } from './Model';

export class CCMMember extends ClinicalMember {
  calls = 0;

  carePlanDate?: Moment;

  ccmProfessionalMinutes = 0;

  ccmTotalMinutes = 0;

  ccmValidMinutes = 0;

  hasValidCCM = false;

  lastAppointmentDate?: Moment;

  lastAppointmentType = '';

  lastCCMDate?: Moment;

  lastCCMReason = '';

  nextAppointmentDate?: Moment;

  nextAppointmentType = '';

  worklistGroup?: EmployeeGroup;

  constructor(obj: any) {
    super(obj);
    this.calls = obj.calls;
    this.ccmProfessionalMinutes = obj.ccmProfessionalMinutes;
    this.ccmTotalMinutes = obj.ccmTotalMinutes;
    this.ccmValidMinutes = obj.ccmValidMinutes;
    this.lastAppointmentType = obj.lastAppointmentType;
    this.lastCCMReason = obj.lastCCMReason;
    this.nextAppointmentType = obj.nextAppointmentType;

    this.carePlanDate = obj.carePlanDate ? moment(obj.carePlanDate) : undefined;
    this.lastCCMDate = obj.lastCCMDate ? moment(obj.lastCCMDate) : undefined;
    this.lastAppointmentDate = obj.lastAppointmentDate
      ? moment(obj.lastAppointmentDate)
      : undefined;
    this.nextAppointmentDate = obj.nextAppointmentDate
      ? moment(obj.nextAppointmentDate)
      : undefined;
    this.worklistGroup = make(obj.worklistGroup, EmployeeGroup);
  }

  get fullName() {
    if (this.firstName && this.lastName)
      return `${this.firstName} ${this.lastName}`;
    return '';
  }

  isBrandCareAtHome(): boolean {
    return hasBrandCareAtHome(this);
  }
}

export default CCMMember;
