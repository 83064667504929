import { Moment } from 'moment';

import { MemberDashboardActionStatus } from '../enums';
import { translate } from '../i18n';
import { makeMoment } from './Model';

export class MemberDashboardAction {
  subject = '';

  createdAt?: Moment;

  startedAt?: Moment;

  dueDate?: Moment;

  completedAt?: Moment;

  status = '';

  employeeName = '';

  open = false;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdAt = makeMoment(obj.createdAt);
    this.startedAt = makeMoment(obj.startedAt);
    this.dueDate = makeMoment(obj.dueDate);
    this.completedAt = makeMoment(obj.completedAt);
  }

  get date(): Moment | undefined {
    if (
      [
        MemberDashboardActionStatus.NOT_STARTED.value,
        MemberDashboardActionStatus.OVERDUE.value,
      ].includes(this.status)
    ) {
      return this.dueDate;
    }
    if (this.status === MemberDashboardActionStatus.IN_PROGRESS.value) {
      return this.startedAt;
    }
    if (this.status === MemberDashboardActionStatus.COMPLETED.value) {
      return this.completedAt;
    }
    return this.createdAt;
  }

  get dateLabel(): string {
    if (
      [
        MemberDashboardActionStatus.NOT_STARTED.value,
        MemberDashboardActionStatus.OVERDUE.value,
      ].includes(this.status)
    ) {
      return translate('common.due');
    }
    if (this.status === MemberDashboardActionStatus.IN_PROGRESS.value) {
      return translate('common.started');
    }
    if (this.status === MemberDashboardActionStatus.COMPLETED.value) {
      return translate('common.completed');
    }
    return translate('common.created');
  }
}

export default MemberDashboardAction;
