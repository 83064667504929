import React, { PureComponent } from 'react';
import BodyPart from '@vestahealthcare/common/enums/BodyPart';
import Figure from './Figure';
import PartList from './PartList';

export type PopoverPlacement = 'auto' | 'left' | 'right' | undefined;
interface Props {
  autoFocus?: boolean;
  disabled?: boolean;
  hideSelectionText?: boolean;
  initialSelection: BodyPart[];
  onChange?: Function;
  multiple?: boolean;
  'data-cy'?: string;
}

interface State {
  selectedParts: Set<BodyPart>;
}

export default class ClinicalFigure extends PureComponent<Props, State> {
  ref?: HTMLDivElement | null;

  state: State = {
    selectedParts: new Set(this.props.initialSelection),
  };

  static defaultProps = {
    disabled: false,
    hideSelectionText: false,
    multiple: false,
  };

  componentDidMount() {
    // Since we're focusing on a non-input element, we manually focus
    if (this.props.autoFocus && this.ref) {
      this.ref.focus();
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.initialSelection !== this.props.initialSelection) {
      this.setState({ selectedParts: new Set(nextProps.initialSelection) });
    }
  }

  togglePart(part: BodyPart, event?: any) {
    const { onChange, multiple } = this.props;
    const { selectedParts: existing } = this.state;
    let newSet;

    if (multiple) {
      // Remove it if it exists, add it otherwise
      // Or, if can't remove, just check that does not exist
      if (!existing.delete(part)) {
        existing.add(part);
      }

      newSet = new Set([...existing]);
    } else {
      // Just set the unique allowed item
      newSet = new Set([part]);
    }

    this.setState({ selectedParts: newSet });
    if (onChange) onChange(Array.from(newSet), part, event);
  }

  render() {
    const { disabled, hideSelectionText } = this.props;
    const { selectedParts } = this.state;

    const figure = (
      <Figure
        disabled={disabled}
        onTogglePart={(part: BodyPart, event: any) =>
          this.togglePart(part, event)
        }
        popoverPlacement="right"
        selectedParts={selectedParts}
      />
    );

    return (
      <div
        className="clinical-figure-wrapper"
        ref={(ref) => {
          this.ref = ref;
        }}
        role="button" // to support tabindex
        tabIndex={0}
      >
        <div className="clinical-figure-container">
          {figure}
          <PartList
            data-cy={this.props['data-cy']}
            disabled={disabled}
            hideSelectionText={hideSelectionText}
            onTogglePart={(part: BodyPart) => this.togglePart(part)}
            selectedParts={selectedParts}
          />
        </div>
      </div>
    );
  }
}
