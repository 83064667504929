import React from 'react';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';
import { ClassNameMap, makeStyles } from '@mui/styles';

import { MemberConsentType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import ConsentRequest from '@vestahealthcare/common/models/ConsentRequest';
import { MemberConsentAnswer } from '@vestahealthcare/common/models/MemberConsentAnswer';
import {
  DATE_FORMAT_SHORT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  BackgroundColors,
  Fonts,
  IconButton,
  Table,
  UpdatedBy,
} from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

const useStyles = makeStyles({
  consentStatus: {
    fontSize: `calc(${Fonts.fontSize} * .75)`,
    fontWeight: 500,
  },
  consentDate: {
    fontSize: `calc(${Fonts.fontSize} * .75)`,
  },
  inactive: {
    '&&': {
      backgroundColor: `${BackgroundColors.lightGray}!important`,
    },
  },
  table: {
    marginBottom: '2rem',
  },
});

interface Props {
  consents: ConsentRequest[];
  answers: MemberConsentAnswer[];
  onEdit?: (request: ConsentRequest, answer?: MemberConsentAnswer[]) => void;
  isInactive?: boolean;
}

const getAnswerIcon = (
  styles: ClassNameMap<'consentStatus' | 'consentDate'>,
  answer?: MemberConsentAnswer,
) => {
  if (answer?.answer) {
    return (
      <div className="flex">
        {answer?.isReceived && <CheckCircleIcon color="primary" />}
        {answer?.isDenied && <CancelIcon color="error" />}
        &nbsp;
        <div>
          <div className={styles.consentStatus}>
            {answer?.isReceived
              ? translate('memberConsents.received')
              : translate('memberConsents.denied')}
          </div>
          {answer?.dateReceived && (
            <div className={styles.consentDate}>
              {answer?.dateReceived?.format(DATE_FORMAT_SHORT)}
            </div>
          )}
        </div>
      </div>
    );
  }
  if (answer?.dateSent) {
    return (
      <div className="flex">
        <ArrowCircleRightIcon color="warning" />
        &nbsp;
        <div>
          <div className={styles.consentStatus}>
            {translate('memberConsents.sent')}
          </div>
          <div className={styles.consentDate}>
            {answer?.dateSent?.format(DATE_FORMAT_SHORT)}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="flex">
      <CancelIcon />
      &nbsp;
      <span className={styles.consentDate}>{translate('global.none')}</span>
    </div>
  );
};

const getVerbalAnswer = (
  { id }: ConsentRequest,
  answers: MemberConsentAnswer[],
  styles: ClassNameMap<'consentStatus' | 'consentDate'>,
) =>
  getAnswerIcon(
    styles,
    answers.find(
      ({ consentRequestId, type }) =>
        consentRequestId === id && type === MemberConsentType.VERBAL,
    ),
  );

const getWrittenAnswer = (
  { id }: ConsentRequest,
  answers: MemberConsentAnswer[],
  styles: ClassNameMap<'consentStatus' | 'consentDate'>,
) =>
  getAnswerIcon(
    styles,
    answers.find(
      ({ consentRequestId, type }) =>
        consentRequestId === id && type === MemberConsentType.WRITTEN,
    ),
  );

const hasExceptions = (
  { id }: ConsentRequest,
  answers: MemberConsentAnswer[],
  styles: ClassNameMap<'consentStatus' | 'consentDate'>,
) => {
  const answer = answers.find(
    (a) => a.consentRequestId === id && a.hasExceptions,
  );
  if (answer) {
    return (
      <div className="flex">
        <WarningIcon color="error" />
        &nbsp;
        <span className={styles.consentDate}>
          {translate('memberConsents.exception')}
        </span>
      </div>
    );
  }
  return (
    <div className="flex">
      <CancelIcon />
      &nbsp;
      <span className={styles.consentDate}>{translate('global.none')}</span>
    </div>
  );
};

const getLastUpdated = (
  { id }: ConsentRequest,
  answers: MemberConsentAnswer[],
) => {
  const answersFiltered = answers.filter(
    ({ dateReceived, consentRequestId }) =>
      consentRequestId === id && dateReceived,
  );
  if (answersFiltered.length === 0) {
    return EMPTY;
  }
  let { updatedAt, updatedBy } = answersFiltered[0];
  if (
    answersFiltered[1]?.updatedAt &&
    updatedAt?.isBefore(answersFiltered[1].updatedAt)
  ) {
    updatedAt = answersFiltered[1].updatedAt;
    updatedBy = answersFiltered[1].updatedBy;
  }

  if (updatedAt) {
    return (
      <UpdatedBy
        date={updatedAt}
        user={updatedBy ? `by ${updatedBy.fullName}` : ''}
      />
    );
  }

  return EMPTY;
};

const MEMBER_CONSENTS_COLUMNS = (
  { answers, isInactive, onEdit }: Props,
  styles: ClassNameMap<'consentStatus' | 'consentDate'>,
) => {
  const columns: Column[] = [
    {
      headerName: translate('memberConsents.name'),
      component: ({ title, version }: ConsentRequest) => (
        <>
          <p className="no-margin" data-cy="consent-title">
            {title}
          </p>
          <p className="no-margin size-xs" data-cy="consent-version">
            {`${translate('memberConsents.version')}: ${version}`}
          </p>
        </>
      ),
    },
    {
      headerName: translate('memberConsents.verbal'),
      component: (request: ConsentRequest) =>
        getVerbalAnswer(request, answers, styles),
      width: 150,
    },
    {
      headerName: translate('memberConsents.written'),
      component: (request: ConsentRequest) =>
        getWrittenAnswer(request, answers, styles),
      width: 150,
    },
    {
      headerName: translate('memberConsents.exceptions'),
      component: (request: ConsentRequest) =>
        hasExceptions(request, answers, styles),
      width: 150,
    },
    {
      headerName: translate('memberConsents.lastUpdated'),
      component: (request: ConsentRequest) => getLastUpdated(request, answers),
      width: 200,
    },
  ];

  if (!isInactive) {
    columns.push({
      headerName: '',
      component: (item: ConsentRequest) => (
        <>
          {item.enabled && onEdit && (
            <IconButton
              data-cy="referral-edit-button"
              onClick={() =>
                onEdit(
                  item,
                  answers.filter(
                    ({ consentRequestId }) => item.id === consentRequestId,
                  ),
                )
              }
              size="small"
            >
              <EditIcon fontSize="small" />
            </IconButton>
          )}
        </>
      ),
      width: 50,
    });
  }

  return columns;
};

const MemberConsentTable = (props: Props) => {
  const styles = useStyles();

  return (
    <Table
      className={styles.table}
      config={{
        columns: MEMBER_CONSENTS_COLUMNS(props, styles),
        compact: true,
        data: props.consents,
        getRowClass: () => {
          return props.isInactive ? styles.inactive : '';
        },
        paginationOptions: [-1],
        size: 'm',
      }}
      data-cy="member-consent-list"
    />
  );
};

export default MemberConsentTable;
