export class TaskAttachment {
  id = 0;

  documentId = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default TaskAttachment;
