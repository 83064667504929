import { Add, Send } from '@mui/icons-material';
import { Card, CardContent } from '@mui/material';
import React, { useState } from 'react';
import {
  Button,
  ButtonCountDown,
  FloatingActions,
  IconButton,
  Panel,
  Tabs,
  ToggleButton,
} from 'styleguide-v2';

import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';

const TOGGLE_OPTIONS = [
  { label: 'Option 1', value: 1 },
  { label: 'Option 2', value: 2 },
  { label: 'Option 3', value: 3 },
];

const TOGGLE_OPTIONS_DISABLED = [
  { label: 'Option 1', value: 1, disabled: true },
  { label: 'Option 2', value: 2 },
  { label: 'Option 3', value: 3, disabled: true },
];

const StyleguideButtons = () => {
  const [isLoading, setLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [toggleOption, setToggleOption] = useState(1);
  const [buttonsText, setButtonsText] = useState(' ');

  return (
    <Panel>
      <Panel.Heading title="Buttons">
        <Panel.Actions>
          <Button
            type={isDisabled ? 'contained' : 'outlined'}
            onClick={() => setDisabled(!isDisabled)}
          >
            Toggle Disabled
          </Button>
          <Button
            type={isLoading ? 'contained' : 'outlined'}
            onClick={() => setLoading(!isLoading)}
          >
            Toggle Loading
          </Button>
        </Panel.Actions>
      </Panel.Heading>
      <Panel.Body className="grid-wrapper">
        <Card
          className="grid-span-6"
          sx={{ padding: '2rem', height: 'max-content' }}
        >
          <CardContent className="grid-wrapper">
            <h4 className="grid-span-12">Buttons</h4>
            <Button
              className="grid-span-6"
              loading={isLoading}
              disabled={isDisabled}
              onClick={() =>
                setButtonsText(`Button primary clicked ${new Date().getTime()}`)
              }
            >
              Primary
            </Button>
            <Button
              className="grid-span-6"
              color="primary"
              type="outlined"
              loading={isLoading}
              disabled={isDisabled}
              onClick={() =>
                setButtonsText(
                  `Button outlined clicked ${new Date().getTime()}`,
                )
              }
            >
              Outlined
            </Button>
            <Button
              className="grid-span-6"
              color="secondary"
              loading={isLoading}
              disabled={isDisabled}
              onClick={() =>
                setButtonsText(`Button clicked ${new Date().getTime()}`)
              }
            >
              Secondary
            </Button>
            <Button
              className="grid-span-6"
              color="secondary"
              type="outlined"
              loading={isLoading}
              disabled={isDisabled}
              icon={<Add />}
              onClick={() =>
                setButtonsText(
                  `Button outlined secondary clicked ${new Date().getTime()}`,
                )
              }
            >
              Icon Outlined
            </Button>
            <Button
              className="grid-span-6"
              color="tertiary"
              loading={isLoading}
              disabled={isDisabled}
              onClick={() =>
                setButtonsText(`Button clicked ${new Date().getTime()}`)
              }
            >
              Tertiary
            </Button>
            <Button
              className="grid-span-6"
              color="tertiary"
              type="outlined"
              loading={isLoading}
              disabled={isDisabled}
              icon={<Add />}
              onClick={() =>
                setButtonsText(
                  `Button outlined tertiary clicked ${new Date().getTime()}`,
                )
              }
            >
              Icon Outlined
            </Button>
            <pre className="grid-span-12">{buttonsText}</pre>
          </CardContent>
        </Card>
        <Card
          className="grid-span-6"
          sx={{ padding: '2rem', height: 'max-content' }}
        >
          <CardContent className="grid-wrapper">
            <h4 className="grid-span-12">Icon Buttons</h4>
            <div className="grid-span-8 flex" style={{ gap: '.5rem' }}>
              <IconButton
                icon="plus"
                label="Add"
                loading={isLoading}
                disabled={isDisabled}
                onClick={() =>
                  setButtonsText(`Button Add clicked ${new Date().getTime()}`)
                }
              />
              <IconButton
                icon="arrow-up"
                label="Up"
                loading={isLoading}
                disabled={isDisabled}
                onClick={() =>
                  setButtonsText(`Button Up clicked ${new Date().getTime()}`)
                }
              />
              <IconButton
                icon="arrow-down"
                label="Down"
                loading={isLoading}
                disabled={isDisabled}
                onClick={() =>
                  setButtonsText(`Button Down clicked ${new Date().getTime()}`)
                }
              />
              <IconButton
                icon="trash"
                label="Trash"
                loading={isLoading}
                disabled={isDisabled}
                onClick={() =>
                  setButtonsText(`Button Trash clicked ${new Date().getTime()}`)
                }
              />
              <IconButton
                label="Send"
                loading={isLoading}
                disabled={isDisabled}
                onClick={() =>
                  setButtonsText(`Button Send clicked ${new Date().getTime()}`)
                }
              >
                <Send />
              </IconButton>
              <ButtonCountDown
                timer={10000}
                onTimer={() =>
                  setButtonsText(`Timer tick ${new Date().getTime()}`)
                }
              />
            </div>
            <div
              className="grid-span-8 grid-wrapper"
              style={{ alignItems: 'center' }}
            >
              <IconButton
                icon="plus"
                label="Add"
                size="small"
                className="grid-span-2"
                loading={isLoading}
                disabled={isDisabled}
                onClick={() =>
                  setButtonsText(`Button Add clicked ${new Date().getTime()}`)
                }
              />
              <IconButton
                icon="arrow-up"
                label="Up"
                size="small"
                className="grid-span-2"
                loading={isLoading}
                disabled={isDisabled}
                onClick={() =>
                  setButtonsText(`Button Up clicked ${new Date().getTime()}`)
                }
              />
              <IconButton
                icon="arrow-down"
                label="Down"
                size="small"
                className="grid-span-2"
                loading={isLoading}
                disabled={isDisabled}
                onClick={() =>
                  setButtonsText(`Button Down clicked ${new Date().getTime()}`)
                }
              />
              <IconButton
                icon="trash"
                label="Trash"
                size="small"
                className="grid-span-2"
                loading={isLoading}
                disabled={isDisabled}
                onClick={() =>
                  setButtonsText(`Button Trash clicked ${new Date().getTime()}`)
                }
              />
              <IconButton
                label="Send"
                loading={isLoading}
                size="small"
                className="grid-span-2"
                disabled={isDisabled}
                onClick={() =>
                  setButtonsText(`Button Send clicked ${new Date().getTime()}`)
                }
              >
                <Send />
              </IconButton>
            </div>
            <div className="grid-span-12" style={{ height: '.875rem' }} />
            <pre className="grid-span-12">{buttonsText}</pre>
          </CardContent>
        </Card>
        <Card
          className="grid-span-6"
          sx={{ padding: '2rem', height: 'max-content' }}
        >
          <CardContent className="grid-wrapper">
            <h4 className="grid-span-12">Toggle button</h4>
            <h6 className="grid-span-4">Normal</h6>

            <div className="grid-span-8">
              <ToggleButton
                value={toggleOption}
                items={TOGGLE_OPTIONS}
                disabled={isDisabled}
                onChange={(newValue) => setToggleOption(newValue)}
              />
            </div>
            <h6 className="grid-span-4">Disabled</h6>
            <div className="grid-span-8">
              <ToggleButton
                value={toggleOption}
                items={TOGGLE_OPTIONS_DISABLED}
                disabled={isDisabled}
                onChange={(newValue) => setToggleOption(newValue)}
              />
            </div>
            <h6 className="grid-span-4">Value</h6>
            <pre className="grid-span-8">{toggleOption}</pre>
          </CardContent>
        </Card>
        <Card
          className="grid-span-6"
          sx={{ padding: '2rem', height: 'max-content' }}
        >
          <CardContent className="grid-wrapper">
            <h4 className="grid-span-12">Tabs</h4>
            <h6 className="grid-span-4">Normal</h6>
            <div className="grid-span-8">
              <Tabs
                value={toggleOption}
                items={TOGGLE_OPTIONS}
                disabled={isDisabled}
                onChange={(newValue) => setToggleOption(newValue)}
              />
            </div>

            <h6 className="grid-span-4">Disabled</h6>
            <div className="grid-span-8">
              <Tabs
                value={toggleOption}
                items={TOGGLE_OPTIONS_DISABLED}
                disabled={isDisabled}
                onChange={(newValue) => setToggleOption(newValue)}
              />
            </div>
            <h6 className="grid-span-4">Value</h6>
            <pre className="grid-span-8">{toggleOption}</pre>
          </CardContent>
        </Card>
        <Card
          className="grid-span-6"
          sx={{ padding: '2rem', height: 'max-content' }}
        >
          <CardContent className="grid-wrapper">
            <h4 className="grid-span-12">Floating Actions</h4>
            <div className="grid-span-8">
              <FloatingActions
                actions={[
                  { icon: <FileCopyIcon />, label: 'Copy', value: 'Copy' },
                  { icon: <SaveIcon />, label: 'Save', value: 'Save' },
                  { icon: <PrintIcon />, label: 'Print', value: 'Print' },
                  { icon: <ShareIcon />, label: 'Share', value: 'Share' },
                ]}
                label={<h2>This is a long title</h2>}
                onClick={(val) => setToggleOption(val.value as number)}
              />
            </div>
            <pre className="grid-span-8">{toggleOption}</pre>
          </CardContent>
        </Card>
      </Panel.Body>
    </Panel>
  );
};

export default StyleguideButtons;
