import { getCurrentLanguage } from '../i18n';
import { Language } from '../enums';

export class Diagnosis {
  id = 0;

  icd10 = '';

  icd10Description = '';

  englishDescription = '';

  spanishDescription = '';

  abbreviations: string[] = [];

  preferred = false;

  majorChronicDiagnosis?: string;

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  get name() {
    const localizedDescription =
      getCurrentLanguage() === Language.SPANISH
        ? this.spanishDescription
        : this.englishDescription;

    return localizedDescription || this.icd10Description;
  }

  get fullName() {
    return this.icd10 ? `${this.prettyICD10} - ${this.name}` : this.name;
  }

  get prettyICD10() {
    return this.icd10 && this.icd10.replace(/^([A-Z][0-9]{2})(.+)/, '$1.$2');
  }
}

export default Diagnosis;
