import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import DevHome from 'dash/src/pages/Dev/DevHome';
import DevSidebar from 'dash/src/pages/Dev/DevSidebar';
import MobileDashboard from 'dash/src/pages/Dev/MobileDashboard';
import ReleasePage from 'dash/src/pages/Dev/MobileDashboard/ReleasePage';

export const DevDashboard = () => (
  <div id="page-wrapper">
    <DevSidebar />
    <div id="page">
      <Switch>
        <Route
          path="/dev/mobile-dashboard/:deviceType/:releaseId"
          component={ReleasePage}
        />
        <Route
          path="/dev/mobile-dashboard/:deviceType"
          component={MobileDashboard}
        />
        <Route path="/dev/dashboard" component={DevHome} />
        <Redirect from="/dev**" to="/dev/dashboard" />
      </Switch>
    </div>
  </div>
);

export default DevDashboard;
