import React from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import Phone from '@vestahealthcare/common/models/Phone';
import { format } from '@vestahealthcare/common/utils/phone';

import { Icon } from 'styleguide';

interface Props {
  className?: string;
  onInviteClick: () => void;
  phone: Phone;
}

const PhoneText = ({ className, onInviteClick, phone }: Props) => (
  <div className={className}>
    <a href={`tel:${phone.number}`}>{format(phone.number || '')}</a>
    <span className="small">{` (${phone.type?.toString()})`}</span>
    {phone.isSmartphone && (
      <div onClick={onInviteClick}>
        <Icon className="fa fa-mobile" />
        <a>{translate('global.sendAppInvite')}</a>
      </div>
    )}
  </div>
);

export default PhoneText;
