import moment, { Moment } from 'moment';

export class PodReportingSummary {
  businessDaysElapsed = 0;

  businessDaysRemaining = 0;

  completed = 0;

  perDay = 0;

  perDayGoal = 0;

  attempted = 0;

  notStarted = 0;

  oneAttempt = 0;

  twoAttempts = 0;

  threePlusAttempts = 0;

  total = 0;

  lastUpdatedAt: Moment;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.attempted =
      (obj.oneAttempt || 0) +
      (obj.twoAttempts || 0) +
      (obj.threePlusAttempts || 0);
    this.lastUpdatedAt = moment.unix(obj.lastUpdatedAt);
  }
}

export default PodReportingSummary;
