import {
  EligibilityJobsTable,
  EligibilityJobTable,
} from './EligibilityJobsTable';
import { translate } from '@vestahealthcare/common/i18n';
import { showGlobalError } from 'dash/src/components/GlobalMessage';
import {
  downloadEligbilityReport,
  fetchEligibilityJobs,
} from 'dash/src/services/EligibilityServices';
import React, { useEffect, useState } from 'react';
import { Panel, Tabs } from 'styleguide-v2';

const ACTIVE_TAB = 'active-tab';
const PASSIVE_TAB = 'passive-tab';

const TABS = [
  { value: ACTIVE_TAB, label: translate('eligibility.tabs.active') },
  { value: PASSIVE_TAB, label: translate('eligibility.tabs.passive') },
];

export const EligibilityJobsDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelecedTab] = useState<string>(ACTIVE_TAB);
  const [jobs, setJobs] = useState<EligibilityJobTable[]>([]);

  const getJobs = async () => {
    setLoading(true);
    try {
      const jobs = await fetchEligibilityJobs(selectedTab === ACTIVE_TAB);
      setJobs(jobs);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  useEffect(() => {
    getJobs();
    const interval = setInterval(() => {
      getJobs();
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [selectedTab]);

  return (
    <Panel id="eligibility-jobs-section">
      <Panel.Heading title={translate('eligibility.title')}>
        <Panel.Tabs>
          <Tabs items={TABS} onChange={setSelecedTab} value={selectedTab} />
        </Panel.Tabs>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <EligibilityJobsTable
          jobs={jobs}
          onClickDownload={async (job) => {
            if (job.comparisionReport) {
              const updated = jobs.find(({ id }) => id === job.id);
              if (updated) {
                updated.loading = true;
                setJobs([...jobs]);

                await downloadEligbilityReport(
                  job.id,
                  `${job.id}-eligibility-job`,
                );

                updated.loading = false;
                setJobs([...jobs]);
              }
            }
          }}
        />
      </Panel.Body>
    </Panel>
  );
};

export default EligibilityJobsDashboard;
