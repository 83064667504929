import classNames from 'classnames';
import React, { useMemo } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { HouseHoldMember } from '@vestahealthcare/common/models';

import { IconButton, MemberInfo, Table } from 'styleguide-v2';

type Props = {
  className?: string;
  items: HouseHoldMember[];
  onAdd: () => void;
  onDelete: (mcr: HouseHoldMember) => void;
  onEdit: (mcr: HouseHoldMember) => void;
};

const COMMUNITY_RESOURCE_COLUMNS = ({ onEdit, onDelete }: Props) => [
  {
    headerName: translate('houseHold.table.member'),
    component: ({ linkedMember }: HouseHoldMember) => (
      <MemberInfo
        link={`#/patients/${linkedMember?.id}`}
        member={linkedMember}
        showStatus
      />
    ),
    width: 300,
  },
  {
    headerName: translate('houseHold.table.relationship'),
    field: 'relationship.description',
  },
  {
    headerName: ' ',
    component: (hhm: HouseHoldMember) => (
      <div className="flex">
        <IconButton
          size="small"
          onClick={() => {
            onEdit(hhm);
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton size="small" onClick={() => onDelete(hhm)}>
          <DeleteIcon />
        </IconButton>
      </div>
    ),
    width: 50,
  },
];

const useStyles = makeStyles({
  table: {
    '&&.table-empty': {
      padding: '1rem 0',
      textAlign: 'start',
    },
  },
});

export const HouseHoldMembersTable = (props: Props) => {
  const styles = useStyles();
  const { className, items, onDelete, onEdit } = props;

  const activecolumns = useMemo(() => COMMUNITY_RESOURCE_COLUMNS(props), [
    onDelete,
    onEdit,
  ]);

  return (
    <>
      <Table
        className={classNames(className, styles.table)}
        config={{
          columns: activecolumns,
          compact: true,
          data: items,
          paginationOptions: [-1],
        }}
        empty
        emptyLink={translate('memberProfile.network.houseHoldMembersAdd')}
        onClickEmptyLink={props.onAdd}
      />
    </>
  );
};

export default HouseHoldMembersTable;
