import moment from 'moment';

import { MemberProgramExtension } from '../MemberProgramExtension';
import { MemberReferral } from '../MemberReferral';
import { ProgramExtension } from '../ProgramExtension';
import { ProgramExtensionStatus } from '../ProgramExtensionStatus';

export interface VestaCoreLiteHelper {
  activeReferrals?: MemberReferral[];
  programExtensions?: MemberProgramExtension[];
  isVPC: () => false | MemberReferral;
  isVestaCoreLite: () => false | MemberProgramExtension;
}

export const isVPC = ({
  activeReferrals,
}: VestaCoreLiteHelper): false | MemberReferral =>
  activeReferrals?.find(
    ({ discontinuedAt, organization: { isVPC: isVPCFlag } }) =>
      isVPCFlag &&
      (!discontinuedAt || moment().isAfter(moment.unix(discontinuedAt))),
  ) || false;

export const isVestaCoreLite = (
  item: VestaCoreLiteHelper,
): false | MemberProgramExtension =>
  item.programExtensions?.find(
    ({ programExtension: { id }, status }) =>
      [ProgramExtension.VESTA_CORE, ProgramExtension.VESTA_LITE].includes(id) &&
      status?.id === ProgramExtensionStatus.ACTIVE,
  ) || false;
