import React from 'react';

import { makeStyles } from '@material-ui/styles';

import { RiskLevel } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { CareTrack } from '@vestahealthcare/common/models';

import { BackgroundColors, Colors } from 'styleguide-v2';

const useStyles = makeStyles({
  careTracks: {
    display: 'flex',
    padding: '0.5rem 2rem 1rem',
    marginLeft: '1rem',
    backgroundColor: BackgroundColors.lightMint,
    borderLeft: `3px solid ${Colors.lightMint}`,
    '& .risk-moderate': {
      color: Colors.gold,
    },
    '& .risk-high': {
      color: Colors.orange,
    },
  },
});

const displayedLevels = [RiskLevel.HIGH.value, RiskLevel.MODERATE.value];

export default function CareTracks({
  careTracks,
}: {
  careTracks: CareTrack[];
}) {
  const styles = useStyles();

  if (!careTracks) {
    return null;
  }

  const filteredCareTracks = careTracks.filter((program) =>
    displayedLevels.includes(program.riskLevel.value),
  );

  if (!filteredCareTracks.length) {
    return null;
  }

  return (
    <div className={styles.careTracks}>
      <div>
        <h4>{translate('personalDetails.riskPrograms')}</h4>
        <p className="small">
          {filteredCareTracks.map((program, index, arr) => (
            <span
              key={program.type.valueOf()}
              className={`risk-${program.riskLevel.valueOf().toLowerCase()}`}
            >
              {program.type.abbr}
              {index < arr.length - 1 && ', '}
            </span>
          ))}
        </p>
      </div>
    </div>
  );
}
