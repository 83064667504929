import { Language } from '../enums';

export class LanguagePreference {
  language: Language;

  preferred = false;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.language = Language.byKey[obj.language];
  }
}
