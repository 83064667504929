import React, { useState } from 'react';
import {
  Button,
  Panel,
  ButtonSelect,
  DeleteIcon,
  AddIcon,
  EditIcon,
  AddIterationIcon,
  CollapseIcon,
} from 'styleguide';

const StyleguideButtons = () => {
  const returnsAPromise = () =>
    new Promise((resolve) => {
      setTimeout(resolve, 2000);
    });

  const [isLoading, setLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Panel>
      <Panel.Heading>
        <h2>Buttons</h2>
        <div className="right-side">
          <Button color="secondary" onClick={() => setLoading(!isLoading)}>
            {isLoading ? 'Stop' : 'Start'} Loading
          </Button>
          <Button color="secondary" onClick={() => setDisabled(!isDisabled)}>
            {isDisabled ? 'Enable' : 'Disable'}
          </Button>
        </div>
      </Panel.Heading>
      <Panel.Body className="grid-wrapper">
        <div className="grid-span-3 h4">Normal</div>
        <div className="grid-span-3 h4">Hover</div>
        <div className="grid-span-3 h4">Active</div>
        <div className="grid-span-3 h4">Focus</div>

        <div className="grid-span-3">
          <Button color="primary" loading={isLoading} disabled={isDisabled}>
            Primary
          </Button>
        </div>
        <div className="grid-span-3">
          <Button color="primary" className="hover">
            Primary
          </Button>
        </div>
        <div className="grid-span-3">
          <Button color="primary" className="active">
            Primary
          </Button>
        </div>
        <div className="grid-span-3">
          <Button color="primary" className="focus">
            Primary
          </Button>
        </div>

        <div className="grid-span-3">
          <Button color="secondary" loading={isLoading} disabled={isDisabled}>
            secondary
          </Button>
        </div>
        <div className="grid-span-3">
          <Button color="secondary" className="hover">
            secondary
          </Button>
        </div>
        <div className="grid-span-3">
          <Button color="secondary" className="active">
            secondary
          </Button>
        </div>
        <div className="grid-span-3">
          <Button color="secondary" className="focus">
            secondary
          </Button>
        </div>

        <div className="grid-span-3">
          <Button color="tertiary" loading={isLoading} disabled={isDisabled}>
            tertiary
          </Button>
        </div>
        <div className="grid-span-3">
          <Button color="tertiary" className="hover">
            tertiary
          </Button>
        </div>
        <div className="grid-span-3">
          <Button color="tertiary" className="active">
            tertiary
          </Button>
        </div>
        <div className="grid-span-3">
          <Button color="tertiary" className="focus">
            tertiary
          </Button>
        </div>

        <div className="grid-span-3">
          <Button
            isPill
            color="primary"
            loading={isLoading}
            disabled={isDisabled}
          >
            primary
          </Button>
        </div>
        <div className="grid-span-3">
          <Button isPill color="primary" className="hover">
            primary
          </Button>
        </div>
        <div className="grid-span-3">
          <Button isPill color="primary" className="active">
            primary
          </Button>
        </div>
        <div className="grid-span-3">
          <Button isPill color="primary" className="focus">
            primary
          </Button>
        </div>

        <div className="grid-span-3">
          <Button
            isPill
            color="secondary"
            loading={isLoading}
            disabled={isDisabled}
          >
            secondary
          </Button>
        </div>
        <div className="grid-span-3">
          <Button isPill color="secondary" className="hover">
            secondary
          </Button>
        </div>
        <div className="grid-span-3">
          <Button isPill color="secondary" className="active">
            secondary
          </Button>
        </div>
        <div className="grid-span-3">
          <Button isPill color="secondary" className="focus">
            secondary
          </Button>
        </div>

        <br className="grid-span-12" />

        <h2 className="grid-span-12">Button Dropdowns</h2>

        <div className="grid-span-3">
          <ButtonSelect
            options={[]}
            onChange={() => {}}
            color="dark-gray"
            loading={isLoading}
            disabled={isDisabled}
          />
        </div>
        <div className="grid-span-3">
          <ButtonSelect
            options={[]}
            onChange={() => {}}
            color="dark-gray"
            className="hover"
          />
        </div>
        <div className="grid-span-3">
          <ButtonSelect
            options={[]}
            onChange={() => {}}
            color="dark-gray"
            className="active"
          />
        </div>
        <div className="grid-span-3">
          <ButtonSelect
            options={[]}
            onChange={() => {}}
            color="dark-gray"
            className="focus"
          />
        </div>

        <div className="grid-span-3">
          <ButtonSelect
            options={[]}
            onChange={() => {}}
            color="shadowGreen"
            loading={isLoading}
            disabled={isDisabled}
          />
        </div>
        <div className="grid-span-3">
          <ButtonSelect
            options={[]}
            onChange={() => {}}
            color="shadowGreen"
            className="hover"
          />
        </div>
        <div className="grid-span-3">
          <ButtonSelect
            options={[]}
            onChange={() => {}}
            color="shadowGreen"
            className="active"
          />
        </div>
        <div className="grid-span-3">
          <ButtonSelect
            options={[]}
            onChange={() => {}}
            color="shadowGreen"
            className="focus"
          />
        </div>

        <div className="grid-span-3">
          <ButtonSelect
            options={[]}
            onChange={() => {}}
            color="dark-mint"
            loading={isLoading}
            disabled={isDisabled}
          />
        </div>
        <div className="grid-span-3">
          <ButtonSelect
            options={[]}
            onChange={() => {}}
            color="dark-mint"
            className="hover"
          />
        </div>
        <div className="grid-span-3">
          <ButtonSelect
            options={[]}
            onChange={() => {}}
            color="dark-mint"
            className="active"
          />
        </div>
        <div className="grid-span-3">
          <ButtonSelect
            options={[]}
            onChange={() => {}}
            color="dark-mint"
            className="focus"
          />
        </div>

        <div className="grid-span-3">
          <ButtonSelect
            options={[]}
            onChange={() => {}}
            color="green"
            loading={isLoading}
            disabled={isDisabled}
          />
        </div>
        <div className="grid-span-3">
          <ButtonSelect
            options={[]}
            onChange={() => {}}
            color="green"
            className="hover"
          />
        </div>
        <div className="grid-span-3">
          <ButtonSelect
            options={[]}
            onChange={() => {}}
            color="green"
            className="active"
          />
        </div>
        <div className="grid-span-3">
          <ButtonSelect
            options={[]}
            onChange={() => {}}
            color="green"
            className="focus"
          />
        </div>

        <div className="grid-span-3">
          <ButtonSelect
            options={[]}
            onChange={() => {}}
            color="brown"
            loading={isLoading}
            disabled={isDisabled}
          />
        </div>
        <div className="grid-span-3">
          <ButtonSelect
            options={[]}
            onChange={() => {}}
            color="brown"
            className="hover"
          />
        </div>
        <div className="grid-span-3">
          <ButtonSelect
            options={[]}
            onChange={() => {}}
            color="brown"
            className="active"
          />
        </div>
        <div className="grid-span-3">
          <ButtonSelect
            options={[]}
            onChange={() => {}}
            color="brown"
            className="focus"
          />
        </div>

        <br className="grid-span-12" />

        <h2 className="grid-span-12">Variants</h2>

        <div className="grid-span-12">
          <Button
            color="secondary"
            isPill
            loading={isLoading}
            disabled={isDisabled}
          >
            <AddIcon className="margin-right" />
            Add New
          </Button>
        </div>

        <div className="grid-span-12">
          <Button color="tertiary" loading={isLoading} disabled={isDisabled}>
            <AddIterationIcon className="margin-right" />
            Add Iteration
          </Button>
        </div>

        <div className="grid-span-12">
          <button
            className="h3 null"
            onClick={() => setIsExpanded(!isExpanded)}
            type="button"
          >
            <CollapseIcon active={isExpanded} className="margin-right" />
            Collapsible Entry
          </button>
        </div>

        <div className="grid-span-3">
          <Button
            color="primary"
            onClick={returnsAPromise}
            disabled={isDisabled}
          >
            Async Loading
          </Button>
        </div>
        <div className="grid-span-9">
          <Button
            color="secondary"
            onClick={returnsAPromise}
            disabled={isDisabled}
          >
            Async Loading
          </Button>
        </div>

        <div className="grid-span-6">
          <Button
            color="primary"
            className="btn-block"
            loading={isLoading}
            disabled={isDisabled}
          >
            Block
          </Button>
        </div>
        <div className="grid-span-6">
          <Button
            color="secondary"
            className="btn-block"
            loading={isLoading}
            disabled={isDisabled}
          >
            Block
          </Button>
        </div>

        <h2 className="grid-span-12">Icons</h2>
        <div className="grid-span-2">Delete</div>
        <div className="grid-span-1">
          <DeleteIcon size="lg" tag="button" />
        </div>
        <div className="grid-span-1">
          <DeleteIcon tag="button" />
        </div>
        <div className="grid-span-1">
          <DeleteIcon size="sm" tag="button" />
        </div>
        <div className="grid-span-7">
          <DeleteIcon size="xs" tag="button" />
        </div>
        <div className="grid-span-2">Edit</div>
        <div className="grid-span-1">
          <EditIcon size="lg" tag="button" />
        </div>
        <div className="grid-span-1">
          <EditIcon tag="button" />
        </div>
        <div className="grid-span-1">
          <EditIcon size="sm" tag="button" />
        </div>
        <div className="grid-span-7">
          <EditIcon size="xs" tag="button" />
        </div>
        <div className="grid-span-2">Collapse</div>
        <div className="grid-span-1">
          <CollapseIcon size="lg" />
        </div>
        <div className="grid-span-1">
          <CollapseIcon />
        </div>
        <div className="grid-span-1">
          <CollapseIcon size="sm" />
        </div>
        <div className="grid-span-7">
          <CollapseIcon size="xs" />
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default StyleguideButtons;
