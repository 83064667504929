import { JSONSchema, Key } from './types';
import { isArray } from 'lodash';

// Retrieve the enumValue of a value from the schema.
// Also accepts an array of values, so the return is the comma separated translated values.
// If there's no translation available, it returns the same value
export const readEnumName = (
  value: Key | Key[],
  schema?: JSONSchema,
): string => {
  if (isArray(value)) {
    return value
      .map((singleValue) => readEnumName(singleValue, schema))
      .join(', ');
  }

  const fieldSchema =
    schema && (schema.type === 'array' ? schema.items : schema);

  if (fieldSchema && isArray(fieldSchema.enum)) {
    const index = fieldSchema.enum.indexOf(value);
    if (index > -1 && isArray(fieldSchema.enumNames)) {
      return fieldSchema.enumNames[index];
    }
  }

  // Fallback to same value
  return value ? value.toString() : '';
};
