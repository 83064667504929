import classNames from 'classnames';
import React from 'react';

import { GridColumns } from '../../utils/types';
import { Tooltip } from '../Tooltip';

export interface CheckboxProps {
  autoFocus?: boolean;
  checked: boolean;
  children?: React.ReactNode;
  className?: string;
  columns?: GridColumns;
  disabled?: boolean;
  icon?: string;
  id?: string;
  label?: React.ReactNode;
  onChange: (checked: boolean) => void;
  showError?: boolean;
  size?: 'sm' | 'lg';
  slideToggle?: boolean;
}

const Checkbox = ({
  autoFocus,
  checked,
  children,
  className,
  columns,
  disabled = false,
  icon,
  id,
  label,
  onChange,
  showError = false,
  size,
  slideToggle = false,
  ...props
}: CheckboxProps) => {
  const classes = classNames(
    'toggle-btn',
    className,
    checked ? 'checked' : 'unchecked',
    columns ? `grid-span-${columns}` : undefined,
    {
      disabled,
      'has-error': showError,
      'slide-toggle': slideToggle,
    },
  );

  const computedIcon =
    icon ||
    (slideToggle && (checked ? 'toggle-on' : 'toggle-off')) ||
    (checked ? 'check-square' : 'square-o');

  const iconClasses = classNames(
    'fa',
    'fa-fw',
    `fa-${computedIcon}`,
    size && `fa-${size}`,
  );

  return (
    <button
      autoFocus={autoFocus}
      className={classes}
      disabled={disabled}
      onClick={() => onChange(!checked)}
      type="button"
      {...props}
    >
      <i className={iconClasses} />
      {children}
      {label}
    </button>
  );
};

export default Checkbox;
