import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';

import {
  Card,
  CardContent,
  CardHeader,
  EmptyState,
  IconButton,
  Modal,
  TextArea,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { updatePatientCareTeamInfo } from 'dash/src/services/PatientServices';

interface Props {
  onSubmit: (patient: Patient) => void;
  patient: Patient;
}

export const updatePatient = async (
  patient: Patient,
  onSubmit: (patient: Patient) => void,
  info = '',
) => {
  const updatedPatient = await updatePatientCareTeamInfo(patient.id, info);
  onSubmit(updatedPatient);
};

export const CareTeamInfo = ({ onSubmit, patient }: Props) => {
  const [info, setInfo] = useState(patient.careTeamInfo);
  const [show, setShow] = useState(false);

  const cancel = () => {
    setShow(false);
    setInfo(patient.careTeamInfo || '');
  };

  const submit = async () => {
    try {
      await updatePatient(patient, onSubmit, info);
      setShow(false);
    } catch (e) {
      showGlobalError(e as string);
    }
  };

  const renderEmpty = () => (
    <EmptyState>
      {translate('personalDetails.careTeam.noneAdded')}
      {patient.isEditable() && (
        <a onClick={() => setShow(true)}>
          {translate('personalDetails.careTeam.clickToAdd')}
        </a>
      )}
    </EmptyState>
  );

  const renderInfo = () => <p>{patient.careTeamInfo}</p>;

  return (
    <>
      <Card>
        <CardHeader
          title={
            <>
              {translate('personalDetails.careTeam.info')}{' '}
              <IconButton
                data-cy="add-care-team-info"
                noMargin
                onClick={() => setShow(true)}
                size="small"
                tooltip={
                  patient.careTeamInfo
                    ? translate('global.edit')
                    : translate('personalDetails.careTeam.addInfo')
                }
              >
                {patient.careTeamInfo ? <EditIcon /> : <AddIcon />}
              </IconButton>
            </>
          }
        />
        <CardContent>
          {patient.careTeamInfo ? renderInfo() : renderEmpty()}
        </CardContent>
      </Card>
      <Modal
        open={show}
        onClose={() => cancel()}
        submitText={translate('global.save')}
        onSubmit={submit}
        title={
          patient.careTeamInfo
            ? translate('personalDetails.careTeam.editTitle')
            : translate('personalDetails.careTeam.addTitle')
        }
      >
        <TextArea
          label={translate('personalDetails.careTeam.info')}
          rows={3}
          onChange={setInfo}
          placeholder={translate('personalDetails.careTeam.placeholder')}
          value={info}
        />
      </Modal>
    </>
  );
};

export default CareTeamInfo;
