import React, { useState } from 'react';

import { CareTrackSource, RiskLevel } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { CareTrack } from '@vestahealthcare/common/models';

import { ButtonModal, ButtonSelect, Select, TextInput } from 'styleguide';
import { ButtonColor } from 'styleguide/src/components/Button';

interface Props {
  onSubmit: Function;
  careTrack: CareTrack;
}

export const RiskLevelButtonModal = (props: Props) => {
  const { onSubmit, careTrack } = props;

  const [evaluationNote, setEvaluationNote] = useState(
    props.careTrack.evaluationNote,
  );
  const [riskLevel, setRiskLevel] = useState(
    getSelectedRiskLevel(props.careTrack),
  );
  const [sources, setSources] = useState(careTrack.sources);

  const modalTitle = careTrack.isEvaluated
    ? translate('riskProfile.updateRiskLevel')
    : translate('riskProfile.setRiskLevel');
  const buttonLabel = careTrack.isEvaluated
    ? translate('riskProfile.updateRiskLevel')
    : translate('riskProfile.setRiskLevel');

  return (
    <ButtonModal
      dataCy="edit-risk-level"
      modalTitle={modalTitle}
      iconType="pencil icon edit-icon"
      submitText={buttonLabel}
      onSubmit={() => {
        onSubmit({ evaluationNote, riskLevel, sources });
        return true;
      }}
    >
      <div className="grid-span-12 grid-wrapper-none risk-selector">
        <p className="medium grid-span-12">
          {translate('riskProfile.careTrackRiskLevel', {
            careTrack: careTrack.type.toString(),
          })}
        </p>
        <ButtonSelect
          dataCy="risk-level-modal"
          className="grid-span-4"
          data-cy="set-update-risk-level-button"
          color={RiskLevel.COLOR_MAP[riskLevel.value] as ButtonColor}
          onChange={(riskLevel: string) =>
            setRiskLevel(RiskLevel.byKey[riskLevel])
          }
          options={careTrack.selectableRiskLevels}
          value={riskLevel.value}
        />
      </div>
      {careTrack.isEvaluated && (
        <Select
          className="grid-span-12"
          label={translate('riskProfile.sourceOfInformation')}
          options={CareTrackSource.toSelectable(CareTrackSource.asArray)}
          value={sources && sources.map((source) => source.value)}
          onChange={(newSources: string[]) =>
            setSources(
              newSources.map((source) => CareTrackSource.byKey[source]),
            )
          }
          placeholder={translate('global.selectResponse')}
          multiple
        />
      )}
      <TextInput
        className="grid-span-12"
        multiline={3}
        onBlur={setEvaluationNote}
        label={translate('riskProfile.optionalNote')}
        placeholder={
          careTrack.isEvaluated
            ? translate('riskProfile.optionalNotePlaceholderChange')
            : translate('riskProfile.optionalNotePlaceholder')
        }
        value={evaluationNote}
      />
    </ButtonModal>
  );
};

const getSelectedRiskLevel = (careTrack: CareTrack) =>
  careTrack.isEvaluated ? careTrack.riskLevel : RiskLevel.NONE;

export default RiskLevelButtonModal;
