import { Enum } from './Enum';

export class MemberDashboardKeyContactType extends Enum {
  translationKey = 'memberDashboardKeyContactType';

  static asArray: MemberDashboardKeyContactType[] = [];

  static byKey: { [name: string]: MemberDashboardKeyContactType } = {};

  static readonly PCP = new MemberDashboardKeyContactType('PCP');

  static readonly PHARMACY = new MemberDashboardKeyContactType('PHARMACY');

  constructor(public readonly value: string) {
    super();
    MemberDashboardKeyContactType.asArray.push(this);
    MemberDashboardKeyContactType.byKey[value] = this;
  }
}

export default MemberDashboardKeyContactType;
