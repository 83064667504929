import React from 'react';
import { WidgetProps } from '../types';
import { TextInput } from 'styleguide';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

const TextareaWidget = ({
  value,
  onChange,
  formContext: { readonly },
}: WidgetProps) => {
  if (readonly) {
    return <p>{value || EMPTY}</p>;
  }

  return <TextInput onBlur={onChange} value={value} multiline={3} />;
};

export default TextareaWidget;
