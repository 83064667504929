import { make } from './Model';
import { Program } from './Program';
import { Organization } from './Organization';

export class ProgramConfiguration {
  id = 0;

  type?: string;

  program: Program;

  client?: Organization;

  payer?: Organization;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.program = make(obj.program, Program);
    this.client = make(obj.client, Organization);
    this.payer = make(obj.payer, Organization);
  }

  get name() {
    return this.program.name || `Program ID ${this.program.id}`;
  }

  get abbr() {
    return this.program.abbr || `Program ID ${this.program.id}`;
  }
}

export default ProgramConfiguration;
