import { Enum } from './Enum';

export class FormValidationError extends Enum {
  translationKey = 'formValidationError';

  static asArray: FormValidationError[] = [];

  static byKey: { [name: string]: FormValidationError } = {};

  static readonly POSITIVE_INTEGER = new FormValidationError(
    'POSITIVE_INTEGER',
  );

  constructor(public readonly value: string) {
    super();
    FormValidationError.asArray.push(this);
    FormValidationError.byKey[value] = this;
  }
}

export default FormValidationError;
