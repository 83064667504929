import React from 'react';
import { Enum, PhoneType } from '@vestahealthcare/common/enums';

import { PhoneNumber, Radio, Select, DeleteIcon } from 'styleguide';
import { translate } from '@vestahealthcare/common/i18n';
import { validate } from '@vestahealthcare/common/utils/phone';

export interface PhoneItem {
  id?: number;
  number?: string;
  type?: PhoneType;
  primary?: boolean;
}

interface PhoneRowProps {
  key?: string | number;
  phone: PhoneItem;
  onChange: (oldPhone: PhoneItem, newPhone: Partial<PhoneItem>) => void;
  onRemove?: (phone: PhoneItem) => void;
  onFocus?: () => void;
  showError?: boolean;
  showPrimaryError?: boolean;
}

export const PhoneRow = ({
  key,
  phone,
  onChange,
  onRemove,
  showPrimaryError,
  showError,
}: PhoneRowProps) => (
  <div
    data-cy={`member-phone-row-${key}`}
    className="grid-wrapper grid-span-12"
    style={{ marginBottom: '10px' }}
  >
    <Radio
      data-cy="field-primary"
      className="grid-span-1"
      checked={phone?.primary || false}
      onChange={(primary) => primary && onChange(phone, { primary })}
      showError={showPrimaryError}
    />
    <PhoneNumber
      data-cy="field-phone"
      className="grid-span-5"
      errorText={translate('global.phoneNumberInvalid')}
      value={phone?.number}
      onBlur={(number) => onChange(phone, { number })}
      placeholder={translate('contacts.enterPhoneNumber')}
      required
      showError={showError && !validate(phone.number)}
    />
    <Select
      data-cy="field-type"
      className="grid-span-5"
      errorText="Please select a phone type"
      value={phone?.type?.valueOf()}
      options={Enum.toSelectable([PhoneType.HOME, PhoneType.CELL])}
      onChange={(value: string) =>
        onChange(phone, { type: PhoneType.byKey[value] })
      }
      required
      showError={showError && !phone?.type}
    />
    {onRemove && (
      <div className="grid-span-1 icon-wrapper text-center">
        <DeleteIcon
          onClick={() => onRemove(phone)}
          data-cy="btn-remove"
          tag="button"
        />
      </div>
    )}
  </div>
);
