import { Enum } from './Enum';

export class TaskCustomFieldType extends Enum {
  translationKey = 'taskCustomFieldType';

  static asArray: TaskCustomFieldType[] = [];

  static byKey: { [name: string]: TaskCustomFieldType } = {};

  static readonly DATE = new TaskCustomFieldType('DATE');

  static readonly DATETIME = new TaskCustomFieldType('DATETIME');

  static readonly CHECKBOX = new TaskCustomFieldType('CHECKBOX');

  constructor(public readonly value: string) {
    super();
    TaskCustomFieldType.asArray.push(this);
    TaskCustomFieldType.byKey[value] = this;
  }
}

export default TaskCustomFieldType;
