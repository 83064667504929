import classNames from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';

import InfoIcon from '@mui/icons-material/InfoOutlined';
import { FormControlLabel, FormGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CustomCheckbox } from 'styleguide-v2/src/components/CheckboxWithLabel/CustomCheckbox';
import { Fonts } from 'styleguide-v2/src/styles/Variables';

import { Colors } from '../../styles/Colors';
import { Tooltip } from '../Tooltip';

type Props = {
  className?: string;
  'data-cy'?: string;
  disabled?: boolean;
  label?: string | ReactNode;
  note?: string;
  onChange?: (value?: boolean) => void;
  placeholder?: string;
  checked: boolean;
  tooltip?: string;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  helpIcon: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    left: '0.25rem',
    position: 'relative',
    top: '0.37rem',
  },
  note: {
    color: Colors.iconGray,
  },
});

const labelStyle = {
  '&': {
    margin: 0,
  },
  '& > .MuiFormControlLabel-label': {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
    margin: 0,
  },
};

export const CheckboxWithLabel = ({
  className,
  'data-cy': dataCy,
  disabled,
  label,
  note,
  onChange,
  checked,
  tooltip,
}: Props) => {
  const styles = useStyles();
  const [currentChecked, setChecked] = useState(checked);
  const classes = classNames(className, styles.container);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (onChange) onChange(event.target.checked);
  };

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  return (
    <div className={classes} data-cy={dataCy}>
      <FormGroup>
        <FormControlLabel
          control={
            <CustomCheckbox
              checked={currentChecked}
              onChange={handleChange}
              onClick={(event) => {
                event.stopPropagation();
              }}
              disabled={disabled}
            />
          }
          sx={labelStyle}
          label={
            <>
              {label && typeof label !== 'string' ? (
                label
              ) : (
                <span>{label || ''}</span>
              )}
              {note && (
                <>
                  <span>{' - '}</span>
                  <span className={styles.note}>{note}</span>
                </>
              )}
              {!!tooltip && (
                <Tooltip text={tooltip}>
                  <InfoIcon
                    className={styles.helpIcon}
                    color="action"
                    fontSize="small"
                  />
                </Tooltip>
              )}
            </>
          }
        />
      </FormGroup>
    </div>
  );
};

export default CheckboxWithLabel;
