import React, { useState, useEffect } from 'react';
import { translate } from '@vestahealthcare/common/i18n';
import classNames from 'classnames';
import { GridColumns } from '../../utils/types';

export const INT_32_MAX = 2147483647;

export interface NumberProps {
  columns?: GridColumns;
  errorText?: string;
  label?: string;
  required?: boolean;
  submitted?: boolean;
  showError?: boolean;
  unit?: string;
  value?: number;
}

// Directly extending React's TS definitions for inputs proves too unwieldy
// TODO: Apply this in other components that use inputs
export interface GenericHTMLProps {
  autoFocus?: boolean;
  className?: string;
  'data-cy'?: string;
  disabled?: boolean;
  id?: string;
  allowFloat?: boolean;
  onBlur?: (value?: number) => void;
  onChange?: (value?: number) => void;
  onFocus?: (event?: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event?: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

// Reject non-numeric strings
// Native number inputs allow the insertion of multiple decimals and scientific notation
// Could be done as regex, but this seems clearer + safer to me
export const isStringNumeric = (value: string, allowFloat = false) => {
  if (allowFloat ? value.split('.').length > 2 : value.includes('.')) {
    return false;
  }
  if (value.includes('e') || value.includes('-') || value.includes('+')) {
    return false;
  }
  return true;
};

// Reject null, undefined, non-numeric strings, and unsafe integers
export const isValidNumber = (value?: string | number, allowFloat = false) => {
  if (!value && value !== 0) {
    return false;
  }

  if (typeof value === 'string' && !isStringNumeric(value, allowFloat)) {
    return false;
  }

  const numVal = parseValue(value);
  return (
    typeof numVal === 'number' &&
    (allowFloat ? Number.isFinite(numVal) : is32Int(numVal)) &&
    numVal >= 0
  );
};

// Cast strings to number
export const parseValue = (value?: string | number, allowFloat = false) => {
  const parsed =
    value && typeof value === 'string'
      ? allowFloat
        ? Number.parseFloat(value)
        : Number.parseInt(value, 10)
      : value;
  if (typeof parsed !== 'number') {
    return undefined;
  }
  return parsed;
};

export const shouldPreventKey = (
  key: string,
  value?: number,
  allowFloat = false,
) =>
  [
    'Enter',
    '-',
    '+',
    'e',
    'ArrowUp', // keyboard increment / decrement
    'ArrowDown',
    !allowFloat && '.',
  ].includes(key) || // SOME cases of duplicate decimals
  (value && allowFloat && key === '.' && !Number.isInteger(value));

const is32Int = (value: number): boolean =>
  Number.isInteger(value) && value <= INT_32_MAX;

export const NumericInput = (props: NumberProps & GenericHTMLProps) => {
  const {
    allowFloat = false,
    autoFocus,
    className,
    columns,
    'data-cy': dataCy = 'numeric-input',
    disabled = false,
    errorText,
    id,
    label = false,
    placeholder = translate('global.enterValue'),
    required,
    showError,
    unit,
    value,
  } = props;

  const [currentValue, setValue] = useState(value);
  const [isFocused, setFocus] = useState(false);
  const isValid = isValidNumber(currentValue, allowFloat);
  const showValidationError = showError && !isFocused;

  const classes = classNames(
    'numeric-input',
    'form-group',
    className,
    columns ? `grid-span-${columns}` : undefined,
    {
      focused: isFocused,
      'has-error': showValidationError,
    },
  );

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocus(true);
    props.onFocus && props.onFocus(e);
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocus(false);
    const val = e.target.value;
    if (!val || isValidNumber(val, allowFloat)) {
      props.onBlur && props.onBlur(currentValue);
    } else {
      return false;
    }
  };

  const onChange = (e: any) => {
    const val = e.target.value;
    if (!val || isValidNumber(val, allowFloat)) {
      const parsedValue = parseValue(val, allowFloat);
      setValue(parsedValue);
      props.onChange && props.onChange(parsedValue);
    } else {
      return false;
    }
  };

  const onKeyDown = (e: any) => {
    if (shouldPreventKey(e.key, currentValue, allowFloat)) {
      e.preventDefault();
      return false;
    }
    props.onKeyDown && props.onKeyDown(e);
  };

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <>
      <div className={classes} data-cy={dataCy} id={id}>
        {label && (
          <label className="control-label">
            {label}
            {required && ' *'}
          </label>
        )}
        <div className="numeric-input-form">
          <input
            autoFocus={autoFocus}
            className="form-control"
            data-cy={`${dataCy}-input`}
            disabled={disabled}
            min={0}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            type="number"
            value={currentValue}
          />
        </div>
        {showValidationError && errorText && (
          <span
            className="error-message help-block grid-span-1"
            data-cy={`${dataCy}-error`}
          >
            {errorText}
          </span>
        )}
      </div>
      {!!unit && <span className="numeric-input-unit">{unit}</span>}
    </>
  );
};

export default NumericInput;
