import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useMemo } from 'react';

import AddCallIcon from '@mui/icons-material/AddIcCall';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { ClassNameMap, makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  BHIMember,
  PaginationType,
  RPMMember,
} from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  BackgroundColors,
  BrandMemberIcon,
  CheckboxWithLabel,
  Colors,
  IconButton,
  Table,
  Tooltip,
} from 'styleguide-v2';

import Session from 'dash/src/services/SessionServices';

interface Props {
  allChecked: boolean;
  blacklist: boolean;
  defaultPageSize?: number;
  defaultSort?: string;
  items: BHIMember[];
  pagination?: PaginationType;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onClickRow: (member: BHIMember) => void;
  onDefaultSort: () => void;
  onLogCall: (member: BHIMember) => void;
  onSelectMember: (member: BHIMember) => void;
  onSelectAll: (allChecked: boolean) => void;
  onSort: (field: string, direction: string) => void;
  selectedIds: number[];
}

const TABLE_HEADERS = {
  language: translate('ccm.common.language'),
  lastCarePlan: translate('ccm.common.lastCarePlan'),
  member: translate('ccm.common.member'),
  minutes: translate('ccm.common.bhiMinutes'),
  lastTouchPoint: translate('ccm.common.lastTouchPointDate'),
  state: translate('ccm.common.state'),
};

const SORT_FIELDS = {
  [TABLE_HEADERS.language]: 'preferredLanguage',
  [TABLE_HEADERS.lastCarePlan]: 'lastCarePlanDate',
  [TABLE_HEADERS.member]: 'memberId',
  [TABLE_HEADERS.minutes]: 'bhiMinutes',
  [TABLE_HEADERS.lastTouchPoint]: 'lastTouchPointDate',
};

const BHI_COLUMNS = (
  styles: ClassNameMap<
    | 'validMins'
    | 'minutes'
    | 'defaultMinutes'
    | 'successMinutes'
    | 'warningMinutes'
    | 'warning2Minutes'
    | 'errorMinutes'
    | 'small'
    | 'extraSmall'
    | 'rpmMinutes'
  >,
  {
    allChecked,
    blacklist,
    items,
    onLogCall,
    onSelectAll,
    onSelectMember,
    selectedIds,
  }: Props,
  showHouseHoldMembers: boolean,
  sort?: string,
) => {
  const field = sort?.split(' ')[0];
  const direction = sort?.split(' ')[1] as 'asc' | 'desc';

  return [
    {
      headerName: (
        <CheckboxWithLabel
          checked={allChecked || blacklist}
          disabled={!items.length}
          onChange={() => onSelectAll(allChecked)}
        />
      ),
      component: (member: BHIMember) => (
        <CheckboxWithLabel
          checked={blacklist !== selectedIds.includes(member.memberId)}
          onChange={() => onSelectMember(member)}
        />
      ),
      width: 50,
    },
    {
      headerName: TABLE_HEADERS.member,
      component: (member: BHIMember) => (
        <>
          <p
            className={classNames(
              'bold no-margin flex middle wrap',
              styles.small,
            )}
          >
            {member.fullName}
            &nbsp;
            {showHouseHoldMembers && !!member?.householdMembers?.length && (
              <Tooltip
                text={
                  <>
                    {member.householdMembers.map(
                      ({ id, linkedMember: hhm, relationship }) => (
                        <p
                          className="no-margin white"
                          key={`member-${hhm.id}-household-${id}`}
                        >
                          {relationship.description}: {hhm.fullName} (
                          <a
                            className="lighten"
                            href={`#/patients/${hhm.id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {hhm.id}
                          </a>
                          )
                        </p>
                      ),
                    )}
                  </>
                }
              >
                <GroupOutlinedIcon
                  color="primary"
                  sx={{ height: '2rem', width: '2rem' }}
                />
              </Tooltip>
            )}
            {member.isBrandCareAtHome() && (
              <BrandMemberIcon brand="careAtHome" size="s" />
            )}
          </p>
          (
          {Session.actingUser.isExternal ? (
            member?.memberId
          ) : (
            <a
              className={styles.small}
              href={`#/patients/${member?.memberId}`}
              rel="noreferrer"
              target="_blank"
              onClick={(evt) => evt.stopPropagation()}
            >
              {member?.memberId}
            </a>
          )}
          )
        </>
      ),
      width: 150,
    },
    {
      headerName: TABLE_HEADERS.state,
      component: ({ state }: BHIMember) => state?.value || EMPTY,
      width: 75,
    },
    {
      headerName: TABLE_HEADERS.language,
      component: ({ language }: BHIMember) => (
        <span className={styles.small}>{language?.toString() || EMPTY}</span>
      ),
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.language] ? direction : undefined,
      sortable: true,
    },
    {
      headerName: TABLE_HEADERS.lastCarePlan,
      component: ({ lastCarePlanDate }: BHIMember) => (
        <span className={styles.small}>
          {lastCarePlanDate?.format(DATE_FORMAT_SHORT) || EMPTY}
        </span>
      ),
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.lastCarePlan]
          ? direction
          : undefined,
      sortable: true,
      width: 150,
    },
    {
      headerName: TABLE_HEADERS.lastTouchPoint,
      component: ({ touchpointDate }: BHIMember) => (
        <span className={styles.small}>
          {touchpointDate?.format(DATE_FORMAT_SHORT) || EMPTY}
        </span>
      ),
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.lastTouchPoint]
          ? direction
          : undefined,
      sortable: true,
      width: 150,
    },
    {
      headerName: TABLE_HEADERS.minutes,
      component: ({ bhiMinutes }: BHIMember) => {
        if (bhiMinutes === null) return EMPTY;

        let className = styles.errorMinutes;
        if (bhiMinutes >= 20) {
          className = styles.successMinutes;
        }
        return (
          <span className={classNames(className, styles.minutes)}>
            {bhiMinutes}
          </span>
        );
      },
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.minutes] ? direction : undefined,
      sortable: true,
      width: 150,
    },
    {
      headerName: ' ',
      component: (member: BHIMember) => (
        <IconButton
          size="small"
          onClick={(evt) => {
            evt.stopPropagation();
            onLogCall(member);
          }}
          tooltip={translate('ccm.actions.logCall')}
        >
          <AddCallIcon />
        </IconButton>
      ),
      width: 20,
    },
  ];
};

const useStyles = makeStyles({
  validMins: {
    marginLeft: '0.5rem',
  },
  minutes: {
    borderRadius: '0.25rem',
    display: 'inline-block',
    fontWeight: 500,
    minWidth: '3rem',
    padding: '0.5rem 0.75rem',
    textAlign: 'center',
  },
  rpmMinutes: {
    '&&': {
      minWidth: '4.36rem',
    },
  },
  successMinutes: {
    color: Colors.textGreen,
    background: BackgroundColors.lightMint,
  },
  warningMinutes: {
    background: BackgroundColors.lightYellow,
  },
  warning2Minutes: {
    background: `${Colors.gold}45`,
    color: Colors.textRed2,
  },
  errorMinutes: {
    background: `${Colors.textRed}33`,
    color: Colors.textRed2,
  },
  defaultMinutes: {
    background: `${Colors.gray}33`,
    color: Colors.textBlack,
  },
  small: { fontSize: '0.875em', lineHeight: '16px' },
  extraSmall: { fontSize: '0.75em', lineHeight: '16px' },
});

export const BHITable = (props: Props) => {
  const { showHouseHoldMembers } = useFlags();
  const styles = useStyles();

  const columns = useMemo(
    () => BHI_COLUMNS(styles, props, showHouseHoldMembers, props.defaultSort),
    [props.onSelectMember, props.defaultSort, styles],
  );

  return (
    <Table
      config={{
        columns,
        compact: true,
        data: props.items,
        defaultPageSize: props.defaultPageSize,
        getRowBrand: ({ brand }: RPMMember) => brand,
        pagination: props.pagination,
      }}
      empty
      fixedHeader
      onChangePage={props.onChangePage}
      onChangeRowsPerPage={props.onChangePageSize}
      onClickRow={props.onClickRow}
      onDefaultSort={props.onDefaultSort}
      onSort={({ headerName, direction }) => {
        if (SORT_FIELDS[headerName as string] && direction) {
          props.onSort(SORT_FIELDS[headerName as string], direction);
        }
      }}
    />
  );
};

export default BHITable;
