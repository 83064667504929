import { AsyncTaskLeadStatus, AsyncTaskType } from '../enums';
import { Employee } from './Employee';
import { make } from './Model';

export class AsyncTask {
  archiveMetadataId?: string;

  completed = 0;

  createdAt = 0;

  createdBy: Employee;

  description = '';

  error = '';

  futureLeadsStatus?: AsyncTaskLeadStatus;

  percentage = 0;

  referralOrgName?: string;

  result?: string;

  rosterId?: string;

  tbaStatus?: string;

  total = 0;

  type: AsyncTaskType;

  uuid = '';

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdBy = make(obj.createdBy, Employee);
    this.futureLeadsStatus = obj.futureLeadsStatus
      ? AsyncTaskLeadStatus.byKey[obj.futureLeadsStatus]
      : undefined;
    this.type = obj.type;
  }
}
