import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { TaskDefinitionProvider } from 'dash/src/contexts/TaskDefinitionContext';
import { AdminHome } from 'dash/src/pages/Admin/AdminHome';
import CampaignManagement from 'dash/src/pages/Admin/CampaignManagement/AppModals';
import EditCampaign from 'dash/src/pages/Admin/CampaignManagement/AppModals/EditCampaign';
import EditCampaignBanner from 'dash/src/pages/Admin/CampaignManagement/AppModals/EditCampaignBanner';
import EditCampaignModal from 'dash/src/pages/Admin/CampaignManagement/AppModals/EditCampaignModal';
import CareTeam from 'dash/src/pages/Admin/CareTeam';
import CareTeamDetail from 'dash/src/pages/Admin/CareTeam/CareTeamDetail';
import { EmployeesDashboard } from 'dash/src/pages/Admin/Employees';
import Groups from 'dash/src/pages/Admin/Groups';
import TaskDefinitions from 'dash/src/pages/Admin/TaskDefinitions';
import SubtaskDefinitionDetail from 'dash/src/pages/Admin/TaskDefinitions/SubTaskDefinitionDetail';
import TaskDefinitionDetail from 'dash/src/pages/Admin/TaskDefinitions/TaskDefinitionDetail';

import { AdminSidebar } from './AdminSidebar';
import { CampaignManagerDashboard } from './CampaignManagement/CampaignManager';
import { ContentLibraryDashboard } from './CampaignManagement/ContentLibrary';
import CommunityResources from './CommunityResources';
import EventInterventions from './Events/interventions';
import EventMapping from './Events/mapping';
import EventTypes from './Events/types';
import { FacilitiesDashboard } from './Facilities';
import { FileHistoryPage } from './FileHistory';
import { IncidentsDashboard } from './Incidents';
import { IncidentsDetail } from './Incidents/IncidentsDetail';
import InsurancePlans from './InsurancePlans';
import Referrals from './Referrals';

export const AdminDashboard = () => (
  <div id="page-wrapper">
    <AdminSidebar />
    <TaskDefinitionProvider>
      <Switch>
        <Route path="/admin/dashboard" component={AdminHome} />
        <Route path="/admin/groups" component={Groups} />
        <Route
          path="/admin/event-interventions"
          component={EventInterventions}
        />
        <Route path="/admin/event-types" component={EventTypes} />
        <Route path="/admin/event-mapping" component={EventMapping} />
        <Route path="/admin/employees" component={EmployeesDashboard} />
        <Route path="/admin/care-team" component={CareTeam} exact />
        <Route path="/admin/care-team/:id">
          <CareTeamDetail showBackButton />
        </Route>
        <Route path="/admin/referrals" component={Referrals} />
        <Route
          path="/admin/campaigns/app-modals/campaign/:campaignId"
          component={EditCampaign}
        />
        <Route
          path="/admin/campaigns/app-modals/campaign/"
          component={EditCampaign}
        />
        <Route
          path="/admin/campaigns/app-modals/modal/:modalId"
          component={EditCampaignModal}
        />
        <Route
          path="/admin/campaigns/app-modals/modal"
          component={EditCampaignModal}
        />
        <Route
          path="/admin/campaigns/app-modals/banner/:bannerId"
          component={EditCampaignBanner}
        />
        <Route
          path="/admin/campaigns/app-modals/banner/"
          component={EditCampaignBanner}
        />
        <Route
          path="/admin/campaigns/app-modals"
          component={CampaignManagement}
        />
        <Route
          path="/admin/campaigns/content-library"
          component={ContentLibraryDashboard}
        />
        <Route
          path="/admin/campaigns/manager"
          component={CampaignManagerDashboard}
        />
        <Route
          path="/admin/task-templates/:templateId/subtask/:subtaskId"
          component={SubtaskDefinitionDetail}
        />
        <Route
          path="/admin/task-templates/:templateId"
          component={TaskDefinitionDetail}
        />
        <Route path="/admin/task-templates" component={TaskDefinitions} />
        <Route path="/admin/file-history" component={FileHistoryPage} />
        <Route path="/admin/insurance-plans" component={InsurancePlans} />
        <Route
          path="/admin/community-resources"
          component={CommunityResources}
        />
        <Route path="/admin/incidents/:id" component={IncidentsDetail} />
        <Route path="/admin/incidents" component={IncidentsDashboard} />
        <Route path="/admin/facilities" component={FacilitiesDashboard} />
        <Redirect from="/admin**" to="/admin/dashboard" />
      </Switch>
    </TaskDefinitionProvider>
  </div>
);

export default AdminDashboard;
