import { translate } from '@vestahealthcare/common/i18n';

import Api from './Api';

export interface ValidatedRow {
  messages?: string[];
  row: number;
  type:
    | 'VALID'
    | 'INVALID'
    | 'DUPLICATE_PARAM'
    | 'DUPLICATE_ENTITY'
    | 'WARNING'
    | 'VALID_CARE_TEAM_PERSON'
    | 'VALID_MEMBER';
  duplicateIdentity?: number;
}

export interface ValidationResponse {
  info: ValidatedRow[];
  errors: ValidatedRow[];
  warnings: ValidatedRow[];
}

const TIME_POLLING = 5000;

const mergeValidations = (items: ValidatedRow[]) => {
  const response: ValidationResponse = {
    info: [],
    errors: [],
    warnings: [],
  };

  items.forEach((element: ValidatedRow) => {
    switch (element.type) {
      case 'INVALID':
      case 'DUPLICATE_PARAM':
        // eslint-disable-next-line no-case-declarations
        const error = response.errors.find(
          (w) => w.type === element.type && w.row === element.row,
        );
        if (error && error.row === element.row && error.type === element.type) {
          error.messages = [
            ...(error.messages || []),
            ...(element.messages || []),
          ];
        } else {
          response.errors.push(element);
        }
        break;
      case 'DUPLICATE_ENTITY':
      case 'WARNING':
        // eslint-disable-next-line no-case-declarations
        const warning = response.warnings.find(
          (w) => w.type === element.type && w.row === element.row,
        );
        if (
          warning &&
          warning.row === element.row &&
          warning.type === element.type
        ) {
          warning.messages = [
            ...(warning.messages || []),
            ...(element.messages || []),
          ];
        } else {
          response.warnings.push(element);
        }
        break;
      case 'VALID_CARE_TEAM_PERSON':
      case 'VALID_MEMBER':
        // eslint-disable-next-line no-case-declarations
        const info = response.info.find((w) => w.row === element.row);
        // eslint-disable-next-line no-case-declarations
        const idsFound = items
          .filter((w) => w.row === element.row && w.type === element.type)
          .map((item) => item.duplicateIdentity);
        // eslint-disable-next-line no-case-declarations
        const validMessage = translate(
          `rosterIngestion.${
            element.type === 'VALID_MEMBER'
              ? 'validMemberFound'
              : 'validCTPFound'
          }`,
          {
            id: `(${idsFound.join(', ')})`,
          },
        );
        if (
          !info ||
          (info.type !== element.type && (info.messages?.length || 0) < 2)
        ) {
          if (info && info.row === element.row) {
            info.messages = [...(info.messages || []), ...[validMessage]];
          } else {
            element.messages = [validMessage];
            response.info.push(element);
          }
        }
        break;
      default:
        break;
    }
  });

  return response;
};

export const validateMemberFile = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  const { validationResult } = await Api.postv2File(
    'patients/bulk/validate',
    formData,
  );

  return mergeValidations(validationResult.items);
};

const checkValidateFileMemberReady = async (uuid: string): Promise<any> => {
  const {
    status,
    response,
  }: {
    status: number;
    response: any;
  } = await Api.getv2Status(`/patients/bulk/validate/poll`, {
    uuid,
  });

  if (status === 202) {
    return await new Promise((res, rej) => {
      setTimeout(
        () => checkValidateFileMemberReady(uuid).then(res).catch(rej),
        TIME_POLLING,
      );
    });
  }

  return response;
};

export const bulkMemberValidate = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  const { uuid } = await Api.postv2File(
    'patients/bulk/validate/async',
    formData,
  );

  const { validationResult } = await checkValidateFileMemberReady(uuid);

  return mergeValidations(validationResult.items);
};

const checkValidateFileCTReady = async (uuid: string): Promise<any> => {
  const {
    status,
    response,
  }: {
    status: number;
    response: any;
  } = await Api.getv2Status(`/care-team-roster/validate/poll`, {
    uuid,
  });

  if (status === 202) {
    return await new Promise((res, rej) => {
      setTimeout(
        () => checkValidateFileCTReady(uuid).then(res).catch(rej),
        TIME_POLLING,
      );
    });
  }

  return response;
};

export const bulkCTValidate = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  const { uuid } = await Api.postv2File(
    'care-team-roster/validate/async',
    formData,
  );

  const { validationResult } = await checkValidateFileCTReady(uuid);

  return mergeValidations(validationResult.items);
};

const checkUploadCTReady = async (uuid: string): Promise<any> => {
  const {
    status,
    response,
  }: {
    status: number;
    response: any;
  } = await Api.getv2Status(`/care-team-roster/upload/poll`, {
    uuid,
  });

  if (status === 202) {
    return await new Promise((res, rej) => {
      setTimeout(
        () => checkUploadCTReady(uuid).then(res).catch(rej),
        TIME_POLLING,
      );
    });
  }

  return response;
};

export const bulkCTUpload = async (
  file: File,
  description: string,
  shouldReplace: boolean,
) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('description', description);
  formData.append('shouldReplace', String(shouldReplace));
  const { uuid } = await Api.postv2File(
    'care-team-roster/upload/async',
    formData,
  );
  checkUploadCTReady(uuid);
};
