import { Facility } from '@vestahealthcare/common/models';

import Api, { PaginatedResponse, PaginationParams } from './Api';

export const fetchFacilities = async (
  params: PaginationParams,
): Promise<PaginatedResponse<Facility>> => {
  const {
    facilities: { items, pagination },
  } = await Api.getv2('facilities', {
    ...params,
    sort: 'name asc',
  });
  return {
    items: items?.map((facility: any) => new Facility(facility)),
    pagination,
  };
};

export type UpdateFacilityParams = {
  brandId?: string;
  ecwId?: number;
  insurancePlanIds?: number[];
  name?: string;
  npi?: number;
  organizationIds?: number[];
  type?: string;
  state?: string;
};

export const createFacility = async (
  params: UpdateFacilityParams,
): Promise<Facility> => {
  const { facility } = await Api.postv2JSON('facilities', params, {
    omitModal: true,
  });
  return new Facility(facility);
};

export const updateFacility = async (
  id: number,
  params: UpdateFacilityParams,
): Promise<Facility> => {
  const { facility } = await Api.patchv2JSON(`facilities/${id}`, params, {
    omitModal: true,
  });
  return new Facility(facility);
};

export const deleteFacility = async (id: number): Promise<void> => {
  await Api.deletev2JSON(`facilities/${id}`);
};
