import React, { useState } from 'react';
import { NumericInput, TextInput, Button, Select, Panel } from 'styleguide';
import {
  LS_IPAD_ID,
  TEST_PATIENT_ID,
  LS_TEST_PATIENT,
  LS_REMOTE_BACKEND,
  TEST_IPAD_ID,
} from '@vestahealthcare/common/utils/constants';
import {
  unlinkPlatformDevice,
  linkPlatformDevice,
} from 'dash/src/services/PatientServices';
import config from 'dash/environments';

const BACKEND_OPTIONS = ['Stage', 'QA1', 'QA2', 'Demo'].map((val) => ({
  value: val.toLowerCase(),
  label: val,
}));

export const DevSettings = () => {
  const [ipadId, setIpadId] = useState(TEST_IPAD_ID || undefined);
  const [patientId, setPatientId] = useState<number | undefined>(
    TEST_PATIENT_ID,
  );
  const [remoteBackend, setRemoteBackend] = useState(
    localStorage.getItem(LS_REMOTE_BACKEND),
  );

  if (config.isProd) {
    return null;
  }

  return (
    <Panel>
      <Panel.Heading>
        <h2>Dev Dashboard</h2>
      </Panel.Heading>
      <Panel.Body className="grid-wrapper">
        <NumericInput
          className="grid-span-3"
          label="Test Patient ID"
          onChange={(val?: number) => {
            setPatientId(val);
            localStorage.setItem(LS_TEST_PATIENT, `${val}`);
          }}
          value={patientId}
        />
        <div className="grid-span-12" />
        <TextInput
          className="grid-span-3"
          value={ipadId}
          label="Test iPad ID"
          onChange={(val?: any) => {
            setIpadId(val);
            localStorage.setItem(LS_IPAD_ID, val);
          }}
        />
        <Button
          className="grid-span-3"
          color="primary"
          disabled={!ipadId || !patientId}
          onClick={async () => {
            if (!ipadId || !patientId) return;
            return linkPlatformDevice(patientId, ipadId);
          }}
        >
          Link test iPad to test patient
        </Button>
        <Button
          className="grid-span-3"
          color="secondary"
          disabled={!patientId}
          onClick={async () => {
            if (!patientId) return;
            return unlinkPlatformDevice(patientId);
          }}
        >
          Unlink test patient iPad
        </Button>
        <div className="grid-span-12" />
        <Select
          className="grid-span-3"
          label="Remote Backend"
          onChange={(val: string) => {
            // @ts-ignore
            window.setBackend(val);
            setRemoteBackend(val);
          }}
          options={BACKEND_OPTIONS}
          value={remoteBackend}
        />
        <Button
          className="grid-span-3"
          color="secondary"
          disabled={!remoteBackend}
          // @ts-ignore
          onClick={() => window.clearBackend()}
        >
          Clear Remote Backend
        </Button>
      </Panel.Body>
    </Panel>
  );
};

export default DevSettings;
