import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';
import moment from '@vestahealthcare/common/moment';
import {
  EMPTY,
  NO_TIME_DATE_FORMAT_DO,
} from '@vestahealthcare/common/utils/constants';

import { Card, CardContent, CardHeader, Fonts, Panel } from 'styleguide-v2';

import { fetchPatientById } from 'dash/src/redux/slices/MemberInfoSlice';
import { useSelector } from 'dash/src/redux/store';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { CareTeamInfo } from './EditModals/CareTeamInfo';
import { EditAddressModal } from './EditModals/EditAddressModal';
import { EditBirthDate } from './EditModals/EditBirthDate';
import { EditBrand } from './EditModals/EditBrand';
import { EditCarePlanGroup } from './EditModals/EditCarePlanGroup';
import { EditExternalIds } from './EditModals/EditExternalIds';
import { EditGenderModal } from './EditModals/EditGenderModal';
import { EditLanguages } from './EditModals/EditLanguages';
import { EditName } from './EditModals/EditName';
import { EditPhoneModal } from './EditModals/EditPhoneModal';
import { EditPreferredContactInfo } from './EditModals/EditPreferredContactInfo';
import { EditProgramExtensions } from './EditModals/EditProgramExtensions';
import { EditResidenceModal } from './EditModals/EditResidenceModal';
import { EditRiskLevel } from './EditModals/EditRiskLevel';
import { EditSelfReporter } from './EditModals/EditSelfReporter';
import { EditStartDate } from './EditModals/EditStartDate';
import { EditVirtualVisitDate } from './EditModals/EditVirtualVisitDate';
import { EditWorkListGroup } from './EditModals/EditWorkListGroup';
import IPadIDSetup from './EditModals/IPadIDSetup';
import { LivingArrangement } from './EditModals/LivingArrangement';
import { SelfDirectingEditModal } from './EditModals/SelfDirectingEditModal';
import { ShowRPMVendor } from './EditModals/ShowRPMVendor';
import { StatusModal } from './EditModals/StatusModal';
import MemberConsents from './MemberConsents';
import MemberInsurances from './MemberInsurances';
import MemberReferrals from './MemberReferrals';
import './main.less';

const useStyles = makeStyles({
  cardContainer: {
    '&& h4': {
      alignSelf: 'flex-start',
      textTransform: 'uppercase',
      fontSize: `calc(${Fonts.fontSize} * .625)`,
      fontFamily: Fonts.fontWorkSans,
      lineHeight: '12px',
      margin: '0.25rem 0 0',
    },
    '&& h4 + :not(h4)': {
      fontSize: `calc(${Fonts.fontSize} * .875)`,
      margin: '0',
    },
    '&& p + p': {
      alignSelf: 'flex-start',
      lineHeight: '12px',
      margin: '0.25rem 0 0',
    },
    '&& p': {
      marginBottom: '0.25rem',
    },
    '&& > .grid-wrapper > .grid-wrapper': {
      gridTemplateRows: 'max-content',
    },
    '&& > .grid-wrapper > .grid-wrapper:not(:last-child)': {
      borderRight: '2px solid #f4f7f6',
      paddingRight: '7px',
    },
  },
});

const DashboardMemberDetails = () => {
  const styles = useStyles();
  const params = useQueryParams();
  const dispatch = useDispatch();
  const patient = useSelector((state) => state.memberInfoSlice.patient);

  const [consentsLoading, setConsentsLoading] = useState<boolean>(true);
  const [insurancesLoading, setInsurancesLoading] = useState<boolean>(true);
  const [referralsLoading, setReferralsLoading] = useState<boolean>(true);

  const refetchPatient = async (patient: Patient) => {
    await dispatch(fetchPatientById(patient.id));
  };

  if (!patient) {
    return null;
  }

  useEffect(() => {
    const anchor = params.get('anchor');
    if (
      anchor === 'consents' &&
      !consentsLoading &&
      !insurancesLoading &&
      !referralsLoading
    ) {
      document.querySelector('#page')?.scrollTo({
        top: 5000,
        behavior: 'smooth',
      });
    }
  }, [consentsLoading, insurancesLoading, referralsLoading]);

  return (
    <Panel id="personal-details-section" data-cy="personal-details-section">
      <Panel.Heading title={translate('personalDetails.title')} />
      <Panel.Body>
        <Card>
          <CardHeader
            title={translate('personalDetails.vestaProgramDetails')}
          />
          <CardContent className={styles.cardContainer}>
            <div className="grid-wrapper">
              <div
                className="grid-span-4 grid-wrapper program-info-column"
                data-cy="program-information"
              >
                <EditStartDate patient={patient} onSubmit={refetchPatient} />
                <EditVirtualVisitDate
                  patient={patient}
                  onSubmit={refetchPatient}
                />
                <StatusModal patient={patient} onSubmit={refetchPatient} />
                <EditBrand patient={patient} onSubmit={refetchPatient} />
                <IPadIDSetup patient={patient} />
                <h4 className="grid-span-5">
                  {translate('personalDetails.appVersion')}
                </h4>
                <p className="grid-span-6">
                  {patient.latestAppVersion || EMPTY}
                </p>
              </div>

              <div className="grid-span-4 grid-wrapper program-info-column">
                <EditPreferredContactInfo
                  patient={patient}
                  onSubmit={() => refetchPatient(patient)}
                />
                <EditSelfReporter
                  patient={patient}
                  onSubmit={() => refetchPatient(patient)}
                />
                <EditProgramExtensions
                  patient={patient}
                  onSubmit={refetchPatient}
                />
              </div>

              <div
                className="grid-span-4 grid-wrapper program-info-column"
                data-cy="personal-details-status-rn"
              >
                <EditWorkListGroup
                  patient={patient}
                  onSubmit={refetchPatient}
                />
                <EditCarePlanGroup member={patient} onSubmit={refetchPatient} />
                <EditRiskLevel patient={patient} onSubmit={refetchPatient} />
                <ShowRPMVendor patient={patient} onSubmit={refetchPatient} />
              </div>
            </div>
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardHeader title={translate('personalDetails.personalDetails')} />
          <CardContent className={styles.cardContainer}>
            <div className="grid-wrapper">
              <div className="grid-span-4 grid-wrapper personal-info-column">
                <EditName patient={patient} onSubmit={refetchPatient} />
                <h4 className="grid-span-5">
                  {translate('personalDetails.patientId')}
                </h4>
                <p className="grid-span-6">{patient.id}</p>
                <EditExternalIds patient={patient} onSubmit={refetchPatient} />
                <EditBirthDate patient={patient} onSubmit={refetchPatient} />
                <h4 className="grid-span-5">
                  {translate('personalDetails.age')}
                </h4>
                <p className="grid-span-6" data-cy="personal-info-age">
                  {patient.age || EMPTY}
                </p>
                <h4 className="grid-span-5">
                  {translate('personalDetails.deceasedDate')}
                </h4>
                <p
                  className="grid-span-6"
                  data-cy="personal-info-deceased-date"
                >
                  {patient.deceasedDate
                    ? moment(patient.deceasedDate).format(
                        NO_TIME_DATE_FORMAT_DO,
                      )
                    : EMPTY}
                </p>
                <EditGenderModal patient={patient} onSubmit={refetchPatient} />
              </div>

              <div className="grid-span-4 grid-wrapper personal-info-column">
                <EditResidenceModal
                  patient={patient}
                  onSubmit={refetchPatient}
                />
                <EditAddressModal patient={patient} onSubmit={refetchPatient} />
                <LivingArrangement
                  patient={patient}
                  onSubmit={refetchPatient}
                />
                <SelfDirectingEditModal
                  patient={patient}
                  onSubmit={refetchPatient}
                />
              </div>

              <div className="grid-span-4 grid-wrapper personal-info-column">
                <EditLanguages patient={patient} onSubmit={refetchPatient} />
                <EditPhoneModal patient={patient} onSubmit={refetchPatient} />
              </div>
            </div>
          </CardContent>
        </Card>
        <br />
        <MemberReferrals member={patient} onLoading={setReferralsLoading} />
        <br />
        <br />
        <MemberInsurances member={patient} onLoading={setInsurancesLoading} />
        <br />
        <br />
        <MemberConsents member={patient} onLoading={setConsentsLoading} />
        <br />
        <br />
        <CareTeamInfo patient={patient} onSubmit={refetchPatient} />
        <br />
      </Panel.Body>
    </Panel>
  );
};

export default DashboardMemberDetails;
