import { BaseEnum } from './BaseEnum';
import { make } from './Model';

export class IncidentChangeOfConditionDetail {
  description = '';

  additionalHelp?: boolean;

  helpType?: BaseEnum;

  notes = '';

  constructor(obj: any) {
    Object.assign(this, obj);
    this.helpType = make(obj.helpType, BaseEnum);
  }
}

export default IncidentChangeOfConditionDetail;
