import { makeArray } from './Model';
import { TaskCustomFieldType } from '../enums/TaskCustomFieldType';
import { TaskCustomFieldOption } from './TaskCustomFieldOption';

export class TaskCustomField {
  id = 0;

  label = '';

  type: TaskCustomFieldType;

  options?: TaskCustomFieldOption[];

  value?: string;

  required = false;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.options = makeArray(obj.options, TaskCustomFieldOption);
    this.type = TaskCustomFieldType.byKey[obj.type];
  }
}

export default TaskCustomField;
