import React, { useEffect, useState } from 'react';

import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { DBEnum, Patient } from '@vestahealthcare/common/models';
import {
  DATE_TIME_FORMAT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import { Select } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CacheServices } from 'dash/src/services';
import { updatePatient } from 'dash/src/services/PatientServices';

import { BaseModal } from './BaseModal';

interface Props {
  member: Patient;
  onSubmit: (member: Patient) => void;
}

const UNASSIGNED: Selectable = {
  value: 0,
  label: translate('global.unassigned'),
};

const DetailsComponent = React.memo(({ member }: { member: Patient }) => {
  return (
    <>
      <h4 className="grid-span-5" data-cy="member-care-plan-group-label">
        {translate('personalDetails.carePlanGroup')}
      </h4>
      <p className="grid-span-6" data-cy="member-care-plan-group-readonly">
        {member.carePlanGroup?.carePlanGroup?.description || EMPTY}
      </p>
    </>
  );
});

export const EditCarePlanGroup = ({ member, onSubmit }: Props) => {
  const { carePlanGroup } = member;
  const editable = member.isEditable();

  const [carePlanGroups, setCarePlanGroups] = useState<Selectable[]>([]);

  const [selectedCarePlanGroup, setSelectedCarePlanGroup] = useState<
    Selectable
  >(
    carePlanGroup?.carePlanGroup
      ? DBEnum.toSelectable([carePlanGroup?.carePlanGroup])[0]
      : UNASSIGNED,
  );
  const [buttonLoading, setButtonLoading] = useState(false);

  const fetchInitialData = async () => {
    const cpg = await CacheServices.getCarePlanGroups();
    setCarePlanGroups([
      UNASSIGNED,
      ...DBEnum.toSelectable(cpg.filter(({ active }) => active)),
    ]);
  };

  const submit = async () => {
    let result = true;

    setButtonLoading(true);

    try {
      const newPatient = await updatePatient(member.id, {
        carePlanGroup: (selectedCarePlanGroup?.value as number) || null,
      });
      await onSubmit(newPatient);
    } catch (e) {
      result = false;
      showGlobalError(e as string);
    }

    setButtonLoading(false);
    return result;
  };

  const reset = () => {
    setSelectedCarePlanGroup(
      carePlanGroup?.carePlanGroup
        ? DBEnum.toSelectable([carePlanGroup?.carePlanGroup])[0]
        : UNASSIGNED,
    );
    setButtonLoading(false);
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => reset(), [member]);

  return (
    <BaseModal
      details={<DetailsComponent member={member} />}
      data-cy="member-care-plan-group"
      editable={editable}
      onCancel={reset}
      onSubmit={submit}
      loadingSubmit={buttonLoading}
      title={translate('personalDetails.carePlanGroup')}
    >
      <Select
        data-cy="edit-risk-level-options"
        error={!selectedCarePlanGroup}
        label={translate('personalDetails.carePlanGroup')}
        note={
          !!carePlanGroup?.updatedAt &&
          !!carePlanGroup?.updatedBy &&
          translate('global.lastUpdatedBy', {
            date: carePlanGroup?.updatedAt?.format(DATE_TIME_FORMAT),
            editor: carePlanGroup?.updatedBy?.fullName,
          })
        }
        onChange={setSelectedCarePlanGroup}
        items={carePlanGroups}
        required
        value={selectedCarePlanGroup}
      />
    </BaseModal>
  );
};

export default EditCarePlanGroup;
