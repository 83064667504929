import { setUpdateAvailable } from 'dash/src/redux/actions/GlobalActions';
import store from 'dash/src/redux/store';

class UpdateService {
  get lastVersion() {
    const lastVersionString = localStorage.getItem('lastVersion');
    return lastVersionString;
  }

  async initUpdateVersion() {
    const response = await fetch('/');
    const lastModified = response.headers.get('Last-Modified');
    localStorage.setItem('lastVersion', lastModified || '');
  }

  async shouldUpdateVersion() {
    const response = await fetch('/');
    const lastModified = response.headers.get('Last-Modified');
    if (this.lastVersion && lastModified !== this.lastVersion) {
      store.dispatch(setUpdateAvailable(true));
      localStorage.setItem('lastVersion', lastModified || '');
      return true;
    }
    localStorage.setItem('lastVersion', lastModified || '');
    return false;
  }
}

export const UpdateServices = new UpdateService();

export default UpdateServices;
