import React from 'react';
import { FieldProps } from 'react-jsonschema-form';

import PhoneType from '@vestahealthcare/common/enums/PhoneType';
import { translate } from '@vestahealthcare/common/i18n';
import { ErrorWithType } from '@vestahealthcare/common/models/ErrorWithType';
import Phone from '@vestahealthcare/common/models/Phone';

import { PhoneList } from 'styleguide';

const OtherPhoneField = (props: FieldProps) => {
  const { formData, onChange, rawErrors } = props;

  // TODO: this error handling is really bad, could be done better with "errorSchema" or similar?
  const hasErrors = !!rawErrors && !!rawErrors.length;
  const errors: ErrorWithType[] = rawErrors?.map((error: string) => {
    const errorParts = error.split('::');
    const errorType = errorParts[0];
    const errorIndex = errorParts[1];
    const errorMessage = errorParts[2];
    return {
      index: parseInt(errorIndex, 10),
      message: errorMessage,
      type: errorType,
    };
  });

  return (
    <PhoneList
      allowedTypes={PhoneType.asMemberArray}
      errors={errors}
      labelNumber={translate('formBuilder.labelOtherPhoneNumber')}
      labelType={translate('formBuilder.labelOtherPhoneType')}
      // sending "Phone" type to "onChange" will not understand the "type" field, it needs to be plain string
      onChange={(phones: Phone[]) =>
        onChange(
          phones.map(({ number, type }: Phone) => ({
            number,
            type: type?.value,
          })),
        )
      }
      placeholderNumber={translate('formBuilder.placeholderNumber')}
      placeholderType={translate('formBuilder.placeholderType')}
      showError={hasErrors}
      value={formData?.map((data: Phone) => new Phone(data))}
    />
  );
};

export default OtherPhoneField;
