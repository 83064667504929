/* eslint-disable max-classes-per-file */
import { States } from '../enums';
import { makeArray } from './Model';

export class City {
  city = '';

  zip = '';

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export class StateCities {
  state: States;

  cities: City[];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.state = States.byKey[obj.state];
    this.cities = makeArray(obj.cities, City);
  }
}

export default StateCities;
