import { make } from '../Model';
import { MemberWarningType } from './MemberWarningType';

export class MemberWarning {
  type: MemberWarningType;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.type = make(obj.type, MemberWarningType);
  }
}

export default MemberWarning;
