import classNames from 'classnames';
import moment from 'moment';
import React, { ReactNode, useState } from 'react';

import ForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { makeStyles } from '@mui/styles';

import { RPMAdherenceLevel } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BiometricsRPMAdherence,
  Patient,
} from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_MIN,
  NO_TIME_DATE_FORMAT,
} from '@vestahealthcare/common/utils/constants';

import {
  BackgroundColors,
  Card,
  Chip,
  Colors,
  Fonts,
  Summary,
} from 'styleguide-v2';

import RPMAdherenceModal from './RPMAdherenceModal';
import { RPMAdherenceTable } from './RPMAdherenceTable';

type Props = {
  className?: string;
  member?: Patient;
  data: BiometricsRPMAdherence;
  showAssignments?: boolean;
  showHistoricalAdherence?: boolean;
  title?: ReactNode;
  action?: ReactNode;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',

    '& > div': {
      flex: 1,
      padding: '3.2rem',
    },

    '& > div:not(:last-child)': {
      borderRight: `1px solid ${BackgroundColors.grayLine}`,
    },
  },
  devicesText: {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    fontWeight: 500,
    margin: 0,
    maxWidth: '20rem',
  },
  subContainer: {
    display: 'flex',
    flexFlow: 'column',
  },
  fullHistory: {
    color: Colors.textGray,
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    fontWeight: '500',
  },
  subtitle: {
    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 0.875)`,
      fontWeight: 400,
      margin: 0,
    },
  },
  table: {
    '&&': {
      marginTop: '-4.125rem',
      width: '23.5rem',
    },
  },
  title: {
    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontWeight: 500,
      margin: 0,
    },
  },
});

export const RPMAdherenceCard = ({
  action,
  className,
  data,
  member,
  showAssignments,
  showHistoricalAdherence,
  title,
}: Props) => {
  const styles = useStyles();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const onClickSeeHistory = () => setOpenModal(true);

  const getReadingsColor = () => {
    if (data.opportunityLevel === RPMAdherenceLevel.MET)
      return Colors.iconGreen;
    if (data.opportunityLevel === RPMAdherenceLevel.ON_TRACK)
      return Colors.gold;
    if (data.opportunityLevel === RPMAdherenceLevel.AT_RISK)
      return Colors.orange;
    if (data.opportunityLevel === RPMAdherenceLevel.MISSED)
      return Colors.textRed;

    return Colors.mint;
  };

  const getLastReadingColor = () => {
    const daysSinceLastReading = data.lastReading
      ? moment().diff(moment.unix(data.lastReading.readAt), 'days')
      : 0;
    if (daysSinceLastReading <= 3) return Colors.iconGreen;
    if (daysSinceLastReading <= 9) return Colors.gold;
    if (daysSinceLastReading <= 29) return Colors.orange;
    return Colors.textRed;
  };

  const getTrendColor = () => {
    if (data.adherenceTrend < -10) return Colors.textRed;
    if (data.adherenceTrend < 0) return Colors.gold;
    if (data.adherenceTrend > 0) return Colors.iconGreen;
    return Colors.paleGray;
  };

  const historicalAdherence = Math.round(
    (data.completedCycles * 100) / data.totalCycles,
  );
  const getHistoriacalColor = () => {
    if (historicalAdherence > 50) return Colors.iconGreen;
    if (historicalAdherence >= 33) return Colors.gold;
    return Colors.textRed;
  };

  return (
    <>
      <Card className={classNames(className, styles.container)}>
        <div className={styles.subContainer}>
          <div className="flex spaced">
            <span className={styles.title}>
              {title || translate('biometrics.rpmAdherence.currentCycle')}
            </span>
            {action}
          </div>
          <h3 className={styles.subtitle}>
            {data.currentCycleStart.format(DATE_FORMAT_MIN)} -{' '}
            {data.currentCycleEnd.format(DATE_FORMAT_MIN)} (
            {translate('biometrics.daysRemaining', {
              count: data.currentCycleEnd.diff(moment(), 'days') + 1,
            })}
            )
          </h3>
          <br />
          <div className="flex spaced">
            <Summary
              body={data.totalReadings}
              color={getReadingsColor()}
              footer={translate('biometrics.rpmAdherence.remainingReading', {
                count: data.remainingReadings,
              })}
              tag={
                <Chip
                  color={RPMAdherenceLevel.getColor(data.opportunityLevel)}
                  type="contained-2"
                >
                  <span className="bold">
                    {data.opportunityLevel.toString().toLocaleUpperCase()}
                  </span>
                </Chip>
              }
              title={translate('biometrics.rpmAdherence.totalReadings')}
            />
            <Summary
              body={
                data?.lastReading?.readAt
                  ? moment.unix(data.lastReading.readAt).format('M/D/YY')
                  : '-'
              }
              color={getLastReadingColor()}
              footer={
                data?.lastReading?.readAt
                  ? translate('biometrics.daysAgo', {
                      count: moment().diff(
                        moment.unix(data.lastReading.readAt),
                        'days',
                      ),
                    })
                  : ''
              }
              title={translate('biometrics.rpmAdherence.lastReading')}
            />
            <Summary
              body={`${data.adherenceTrend < 0 ? '' : '+'}${
                data.adherenceTrend
              }%`}
              color={getTrendColor()}
              footer={<i>{translate('biometrics.rpmAdherence.basedOn')}</i>}
              title={translate('biometrics.rpmAdherence.trend')}
              tooltip={translate('biometrics.rpmAdherence.trendTooltip')}
            />
            {showAssignments && (
              <Summary
                body={
                  <p className={styles.devicesText}>
                    Blood Pressure, Oxygen, Pulse, Temperature, Weight
                  </p>
                }
                color={Colors.paleGray}
                title={translate('biometrics.rpmAdherence.devices')}
              />
            )}
          </div>
        </div>
        {showHistoricalAdherence && (
          <div className={styles.subContainer}>
            <div className="flex spaced">
              <span className={styles.title}>
                {translate('biometrics.rpmAdherence.historical')}
              </span>
              <a
                className={classNames('flex middle gap', styles.fullHistory)}
                onClick={(e) => {
                  e.preventDefault();
                  onClickSeeHistory();
                }}
              >
                {translate('biometrics.rpmAdherence.seeFullHistory')}
                <ForwardIcon fontSize="small" />
              </a>
            </div>
            <h3 className={styles.subtitle}>
              {translate('biometrics.rpmAdherence.onboardedRPM')}:{' '}
              {data.onboardedRPM.format(NO_TIME_DATE_FORMAT)}
            </h3>
            <br />
            <div className="flex spaced">
              <Summary
                body={`${historicalAdherence}%`}
                color={getHistoriacalColor()}
                footer={`${data.completedCycles} / ${
                  data.totalCycles
                } ${translate('biometrics.rpmAdherence.cycles')}`}
                title={translate('biometrics.rpmAdherence.allTime')}
              />
              <RPMAdherenceTable
                className={styles.table}
                data={data.recentCycles}
              />
            </div>
          </div>
        )}
      </Card>
      {showHistoricalAdherence && (
        <RPMAdherenceModal
          member={member}
          open={openModal}
          onClose={() => setOpenModal(false)}
        />
      )}
    </>
  );
};
