import { Enum } from './Enum';

export class InsuranceLeadQualification extends Enum {
  translationKey = 'insuranceLeadQualification';

  static asArray: InsuranceLeadQualification[] = [];

  static byKey: { [name: string]: InsuranceLeadQualification } = {};

  static readonly PRE_QUALIFIED = new InsuranceLeadQualification(
    'PRE_QUALIFIED',
  );

  static readonly UNQUALIFIED_OVER_65 = new InsuranceLeadQualification(
    'UNQUALIFIED_OVER_65',
  );

  static readonly UNQUALIFIED_UNDER_65 = new InsuranceLeadQualification(
    'UNQUALIFIED_UNDER_65',
  );

  constructor(public readonly value: string) {
    super();
    InsuranceLeadQualification.asArray.push(this);
    InsuranceLeadQualification.byKey[value] = this;
  }

  static toSelectable() {
    return Enum.toSelectable(InsuranceLeadQualification.asArray);
  }
}

export default InsuranceLeadQualification;
