import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import {
  Address,
  CommunityResource,
  CommunityResourceSpeciality,
  MemberCommunityResource,
} from '@vestahealthcare/common/models';
import {
  isValidAddress,
  isValidMail,
  isValidPhone,
  isValidURL,
} from '@vestahealthcare/common/utils/format';

import { Button, CollapsableSidebar, Modal, SwitchGroup } from 'styleguide-v2';

import { InfoStep } from 'dash/src/pages/CommunityResources/modals/CommunityResourceQuickAdd/InfoStep';
import {
  CommunityResourceCreateParams,
  MemberCommunityResourceAddParams,
  fetchCommunityResourceMembers,
} from 'dash/src/services/CommunityResourcesServices';

type Props = {
  communityResource?: CommunityResource;
  memberCommunityResource?: MemberCommunityResource;
  notes?: string;
  open: boolean;
  onClose: () => void;
  onCreate: (
    ctp: CommunityResourceCreateParams,
    ctm: MemberCommunityResourceAddParams,
  ) => Promise<void>;
  onEdit: (
    id: number,
    params: CommunityResourceCreateParams,
    ctm: MemberCommunityResourceAddParams,
    hasChangeParentFields: boolean,
  ) => Promise<void>;
  onlyEditPOC?: boolean;
  showNotes?: boolean;
};

export const CommunityResourceEditModal = ({
  communityResource,
  memberCommunityResource,
  onClose,
  onCreate,
  onEdit,
  open,
  onlyEditPOC,
  showNotes,
}: Props) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [openCheckSubmitModal, setOpenCheckSubmitModal] = useState<boolean>(
    false,
  );
  const [openCheckMemberSubmitModal, setOpenCheckMemberSubmitModal] = useState<
    boolean
  >(false);

  const [active, setActive] = useState<boolean>();
  const [params, setParams] = useState<
    Partial<CommunityResourceCreateParams>
  >();
  const [ctmParams, setCTMParams] = useState<
    Partial<MemberCommunityResourceAddParams>
  >();

  useEffect(() => {
    setLoadingButton(false);
    setSubmitted(false);
    setActive(communityResource?.status);
  }, [open]);

  const checkAddress = ({
    address1,
    address2,
    city,
    state,
    zipCode: zip,
  }: Address) =>
    !(address1 || address2 || city || state || zip) ||
    isValidAddress({
      address1: address1 || '',
      address2: address2 || '',
      city: city || '',
      state,
      zipCode: zip || '',
    } as Address);

  const validate = () =>
    params?.type &&
    params?.specialities?.length &&
    params?.name &&
    params?.states?.filter(({ state }) => !!state)?.length &&
    checkAddress(params?.address || ({} as Address)) &&
    ((params?.phone && isValidPhone(params?.phone)) ||
      (params?.fax && isValidPhone(params?.fax))) &&
    (!params?.pocEmail || isValidMail(params?.pocEmail)) &&
    (!params?.website || isValidURL(params?.website)) &&
    (params.mltcPlanId ||
      !params.specialities?.find(
        ({ id }) => id === CommunityResourceSpeciality.MLTC_LTSS,
      ));

  const checkSubmit = () => {
    setOpenCheckSubmitModal(true);
  };

  const checkMemberSubmit = async () => {
    if (!communityResource) return;

    setLoadingButton(true);
    const { items: members } = await fetchCommunityResourceMembers(
      communityResource.id,
    );

    if (members.filter(({ active }) => active)?.length > 1) {
      setOpenCheckMemberSubmitModal(true);
    } else {
      doSubmit();
    }

    setLoadingButton(false);
  };

  const doSubmit = async (hasChangeParentFields: boolean = true) => {
    setSubmitted(true);

    if (!validate()) return;

    if (communityResource?.id) {
      setLoadingButton(true);
      try {
        await onEdit(
          communityResource.id,
          {
            status: active ? 'ACTIVE' : 'INACTIVE',
            ...(params as Omit<CommunityResourceCreateParams, 'status'>),
          },
          ctmParams as MemberCommunityResourceAddParams,
          hasChangeParentFields,
        );
        onClose();
      } finally {
        setLoadingButton(false);
      }
    } else {
      setLoadingButton(true);
      try {
        await onCreate(
          {
            status: 'ACTIVE',
            ...(params as Omit<CommunityResourceCreateParams, 'status'>),
          },
          ctmParams as MemberCommunityResourceAddParams,
        );
        onClose();
      } finally {
        setLoadingButton(false);
      }
    }
  };

  const hasChangeParentFields = () =>
    communityResource?.status !== active ||
    communityResource?.name !== params?.name ||
    (communityResource?.address?.address1 || '') !==
      (params?.address?.address1 || '') ||
    (communityResource?.address?.address2 || '') !==
      (params?.address?.address2 || '') ||
    (communityResource?.address?.city || '') !==
      (params?.address?.city || '') ||
    (communityResource?.address?.state?.value || '') !==
      (params?.address?.state || '') ||
    (communityResource?.address?.zipCode || '') !==
      (params?.address?.zipCode || '') ||
    JSON.stringify(communityResource?.insurances || []) !==
      JSON.stringify(params?.insurances || []) ||
    JSON.stringify(communityResource?.interventions || []) !==
      JSON.stringify(params?.interventions || []) ||
    communityResource?.phone !== params?.phone ||
    communityResource?.fax !== params?.fax ||
    JSON.stringify(communityResource?.referrals || []) !==
      JSON.stringify(params?.referrals || []) ||
    JSON.stringify(communityResource?.specialities || []) !==
      JSON.stringify(params?.specialities || []) ||
    JSON.stringify(communityResource?.states || []) !==
      JSON.stringify(params?.states || []) ||
    JSON.stringify(communityResource?.times || []) !==
      JSON.stringify(params?.times || []) ||
    communityResource?.type?.id !== params?.type?.id ||
    communityResource?.website !== params?.website ||
    communityResource?.mltcPlan?.id !== params?.mltcPlanId;

  const handleSubmit = () => {
    if (onlyEditPOC) {
      if (hasChangeParentFields()) {
        checkMemberSubmit();
      } else {
        doSubmit(false);
      }
    } else if (
      communityResource?.hasLinkedMembers &&
      !active &&
      communityResource?.status
    ) {
      checkSubmit();
    } else {
      doSubmit();
    }
  };

  return (
    <>
      <CollapsableSidebar
        title={
          <h2>
            {communityResource?.id
              ? `${translate('global.edit')} ${communityResource.name}`
              : translate('communityResources.modal.titleAdd')}
          </h2>
        }
        onClose={onClose}
        open={open}
        size={600}
      >
        <CollapsableSidebar.Body>
          <div className="grid-wrapper fit">
            {!!communityResource?.id && (
              <SwitchGroup
                className="grid-span-12"
                items={[
                  {
                    label: translate('communityResources.modal.active'),
                    checked: active,
                  },
                ]}
                onChange={(items) => setActive(!!items[0].checked)}
              />
            )}
            <InfoStep
              className="grid-span-12"
              communityResource={communityResource}
              memberCommunityResource={memberCommunityResource}
              onChange={(ctp, ctm) => {
                setParams(ctp);
                setCTMParams(ctm);
              }}
              showNotes={showNotes}
              submitted={submitted}
            />
          </div>
        </CollapsableSidebar.Body>
        <CollapsableSidebar.Buttons>
          <Button
            color="tertiary"
            data-cy="edit-community-resource-close"
            onClick={onClose}
          >
            {translate('global.close')}
          </Button>
          <Button
            color="secondary"
            data-cy="edit-community-resource-submit"
            disabled={!validate()}
            loading={loadingButton}
            onClick={handleSubmit}
          >
            {communityResource?.id
              ? translate('global.save')
              : translate('global.add')}
          </Button>
        </CollapsableSidebar.Buttons>
      </CollapsableSidebar>
      <Modal
        open={openCheckSubmitModal}
        body={translate('communityResources.modal.inactivateWarning')}
        maxWidth="sm"
        onClose={() => setOpenCheckSubmitModal(false)}
        onSubmit={doSubmit}
        title={translate('global.warning')}
      />
      <Modal
        open={openCheckMemberSubmitModal}
        body={translate('communityResources.modal.updateWarning')}
        maxWidth="sm"
        onClose={() => {
          setOpenCheckMemberSubmitModal(false);
          setLoadingButton(false);
        }}
        onSubmit={async () => {
          setOpenCheckMemberSubmitModal(false);
          doSubmit();
        }}
        cancelText={translate('communityResources.modal.updateWarningCancel')}
        submitText={translate('communityResources.modal.updateWarningOK')}
        title={translate('communityResources.modal.updateWarningTitle')}
      />
    </>
  );
};

export default CommunityResourceEditModal;
