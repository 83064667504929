import React, { useEffect, useRef, useState } from 'react';

import { MessageUserType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';

import { Button, Modal, TextInput, Warning } from 'styleguide';

import { sendChat, sendSMSBatch } from 'dash/src/services/MessageServices';
import { searchPatients } from 'dash/src/services/PatientServices';

interface Props {
  ids: number[];
  isChatMessage: boolean;
  recipientType: MessageUserType;
  onHide: () => void;
}

export const SMSModal = (props: Props) => {
  const { ids, isChatMessage, recipientType, onHide } = props;

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [optedOutIds, setOptedOutIds] = useState<number[]>();
  const textRef = useRef<HTMLTextAreaElement | null>(null);

  const sendMessage = async () => {
    if (message.length < 1) {
      setShowError(true);
      return;
    }
    setShowError(false);
    setLoading(true);
    if (isChatMessage) {
      await sendChat(ids, recipientType, message);
    } else {
      await sendSMSBatch(ids, recipientType, message);
    }
    setLoading(false);
    onHide();
  };

  const insertFirstName = () => {
    insertTemplateText('<FIRST_NAME> ');
  };

  const insertLastName = () => {
    insertTemplateText('<LAST_NAME> ');
  };

  const insertTemplateText = (template: string) => {
    if (!textRef || !textRef.current) {
      return;
    }

    const start = textRef.current.selectionStart;
    const end = textRef.current.selectionEnd;

    setMessage(
      `${textRef.current.value.slice(
        0,
        start,
      )}${template}${textRef.current.value.slice(end)}`,
    );
  };

  useEffect(() => {
    if (isChatMessage) {
      setOptedOutIds([]);
      return;
    }

    setOptedOutIds(undefined);

    if (recipientType === MessageUserType.MEMBER) {
      searchPatients({ id: ids, limit: -1 }).then(({ items }) => {
        const optedOut = items
          .filter((p) => p.primaryPhone?.phone.textReminderOptOut)
          .map((p) => p.id);

        setOptedOutIds(optedOut);
      });
    }
  }, [ids, recipientType, isChatMessage]);

  return (
    <Modal loading={loading} onHide={onHide} show>
      <Modal.Header
        modalTitle={translate(
          isChatMessage ? 'messaging.sendBulkChat' : 'messaging.sendBulkSms',
        )}
      />
      <Modal.Body>
        <p>
          {translate('messaging.sendToContacts', { numContacts: ids.length })}
        </p>
        <div className="pad-bottom-20">
          <Button color="secondary" onClick={insertFirstName}>
            First Name
          </Button>
          <span className="pad-left-20">
            <Button color="secondary" onClick={insertLastName}>
              Last Name
            </Button>
          </span>
        </div>
        <TextInput
          inputRef={(ref: HTMLTextAreaElement) => {
            textRef.current = ref;
          }}
          errorText={translate('global.missingRequiredField')}
          maxLength={280}
          multiline={5}
          value={message}
          onBlur={(message) => setMessage(message || '')}
          showError={showError}
          className="pad-bottom-20"
        />
        {!!optedOutIds?.length && (
          <Warning>
            {translate('messaging.optedOutRecipientsWarning')}:{' '}
            {optedOutIds.join(', ')}
          </Warning>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="right-side">
          <Button color="secondary" onClick={onHide}>
            {translate('global.cancel')}
          </Button>
          <Button
            color="primary"
            onClick={sendMessage}
            disabled={!optedOutIds}
            loading={!optedOutIds}
          >
            {translate('global.send')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SMSModal;
