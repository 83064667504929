import { Enum } from './Enum';

export class AudienceTarget extends Enum {
  translationKey = 'audienceTarget';

  static asArray: AudienceTarget[] = [];

  static byKey: { [name: string]: AudienceTarget } = {};

  static readonly CAREGIVERS = new AudienceTarget('CAREGIVERS');

  static readonly MEMBERS = new AudienceTarget('MEMBERS');

  static readonly EVERYONE = new AudienceTarget('EVERYONE');

  constructor(public readonly value: string) {
    super();
    AudienceTarget.asArray.push(this);
    AudienceTarget.byKey[value] = this;
  }
}

export default AudienceTarget;
