import { Moment } from 'moment';
import React, { ReactNode } from 'react';

import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import { BackgroundColors, Colors, Fonts, LastUpdated } from 'styleguide-v2';

import { Data, MemberWarningsData } from './MemberWarningsData';
import { MemberWarningsTable, Table } from './MemberWarningsTable';

type Props = {
  data?: Data[];
  link?: string;
  linkText?: string;
  onClickLink?: () => void;
  openNewTab?: boolean;
  subtitle?: ReactNode;
  table?: Table;
  title: string;
  titleIcon: ReactNode;
  updatedAt?: Moment;
};

const useStyles = makeStyles({
  container: {
    borderTop: `1px solid ${BackgroundColors.grayLine}`,
    borderRight: `1px solid ${BackgroundColors.grayLine}`,
    borderBottom: `1px solid ${BackgroundColors.grayLine}`,
    borderRadius: '8px',
    padding: '1.5rem 1.5rem 0.25rem 2.3rem',
    position: 'relative',
    width: '100%',

    '&::before': {
      borderBottomLeftRadius: '8px',
      borderLeft: `8px solid ${Colors.blue}`,
      borderTopLeftRadius: '8px',
      bottom: '-1px',
      content: '""',
      display: 'block',
      left: 0,
      position: 'absolute',
      top: '-1px',
    },
  },
  link: {
    alignItems: 'center',
    color: Colors.blue,
    display: 'flex',
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 600,

    '&:hover > *': {
      color: Colors.textBlack,
    },
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0.5rem',
  },
  title: {
    display: 'flex',
    fontSize: Fonts.fontSize,
    fontWeight: 600,
    gap: '0.5rem',
  },
  subtitle: {
    color: 'black',
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
  },
  dataContainer: {
    display: 'flex',
    gap: '1rem',
    marginBottom: '1.25rem',
    marginTop: '1rem',

    '& > *': {
      flex: '1 1 calc(50% - 1rem)',
    },
  },
  tableContainer: {
    marginBottom: '1.25rem',
    marginTop: '1rem',
  },
  expanded: {
    flex: '1 1 100%',
  },
});

export const MemberWarningsCard = ({
  data,
  link,
  linkText,
  onClickLink,
  openNewTab,
  subtitle,
  table,
  title,
  titleIcon,
  updatedAt,
}: Props) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <span className={styles.title}>
          {titleIcon}
          {title}
        </span>
        {link && linkText ? (
          <a
            className={styles.link}
            href={link}
            onClick={onClickLink}
            target={openNewTab ? '_blank' : undefined}
            rel="noreferrer"
          >
            {linkText}
            <ArrowForward fontSize="small" color="info" />
          </a>
        ) : (
          <div />
        )}
        {updatedAt && (
          <LastUpdated
            lastUpdatedAt={updatedAt}
            label={translate('memberWarnings.modal.lastUpdated')}
            size="small"
          />
        )}
      </div>
      <p className={styles.subtitle}>{subtitle}</p>
      {!!data?.length && (
        <div className={styles.dataContainer}>
          {data.map(({ className, value, ...props }, index) => (
            <MemberWarningsData
              className={className ? styles.expanded : undefined}
              key={`data-${index}-${value}`}
              value={value}
              {...props}
            />
          ))}
        </div>
      )}
      {table && (
        <MemberWarningsTable className={styles.tableContainer} table={table} />
      )}
    </div>
  );
};

export default MemberWarningsCard;
