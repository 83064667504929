import { Enum } from './Enum';

export class MemberEligibilityReason extends Enum {
  translationKey = 'memberEligibilityReason';

  static asArray: MemberEligibilityReason[] = [];

  static byKey: { [name: string]: MemberEligibilityReason } = {};

  static readonly NOT_IN_NETWORK = new MemberEligibilityReason(
    'NOT_IN_NETWORK',
  );

  static readonly INACTIVE_REFERRAL = new MemberEligibilityReason(
    'INACTIVE_REFERRAL',
  );

  static readonly REFERRAL_IN_IMPLEMENTATION = new MemberEligibilityReason(
    'REFERRAL_IN_IMPLEMENTATION',
  );

  static readonly ELIGIBILITY_ERROR = new MemberEligibilityReason(
    'ELIGIBILITY_ERROR',
  );

  static readonly INACTIVE_MEDICARE = new MemberEligibilityReason(
    'INACTIVE_MEDICARE',
  );

  static readonly NOT_ENOUGH_INFORMATION = new MemberEligibilityReason(
    'NOT_ENOUGH_INFORMATION',
  );

  static readonly NO_PRIMARY_INSURANCE_IN_RESPONSE = new MemberEligibilityReason(
    'NO_PRIMARY_INSURANCE_IN_RESPONSE',
  );

  static readonly NOT_RESPONDED = new MemberEligibilityReason('NOT_RESPONDED');

  static readonly SYSTEM_ERROR = new MemberEligibilityReason('SYSTEM_ERROR');

  static readonly UNEXPECTED_ERROR_CODE = new MemberEligibilityReason(
    'UNEXPECTED_ERROR_CODE',
  );

  static readonly NO_ERROR_CODE_FOUND = new MemberEligibilityReason(
    'NO_ERROR_CODE_FOUND',
  );

  constructor(public readonly value: string) {
    super();
    MemberEligibilityReason.asArray.push(this);
    MemberEligibilityReason.byKey[value] = this;
  }
}

export default MemberEligibilityReason;
