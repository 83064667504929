import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';

interface Props {
  width?: number;
  inline?: boolean;
  overlay?: boolean;
  style?: React.CSSProperties;
}
const useStyles = makeStyles({
  block: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'center',
    minHeight: ({ width }: Props) => width || 28,
    stroke: '#addbd2',
    width: '100%',
  },
  inline: {
    display: 'inline-flex',
    justifyContent: 'center',
    stroke: '#addbd2',
  },
});

export const Spinner = (props: Props) => {
  const styles = useStyles(props);
  const { width = 28, inline = false, overlay, style } = props;

  const classes = classNames(
    'loading-spinner',
    inline ? styles.inline : styles.block,
    {
      'spinner-overlay': overlay,
    },
  );

  return (
    <div className={classes} data-cy="loading">
      <svg
        width={width}
        className="fa-spin"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 100 100"
      >
        <path
          fill="none"
          strokeMiterlimit="10"
          strokeWidth="7"
          d="M83.46 17.41a46.71 46.71 0 1 0 1.49 64"
        />
      </svg>
    </div>
  );
};

export default Spinner;
