import { DocumentType } from '../enums';
import { Employee } from './Employee';
import { make } from './Model';

export class MemberDocument {
  id = 0;

  type: DocumentType;

  patientId = 0;

  name = '';

  createdAt = 0;

  createdBy: Employee;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.type = DocumentType.byKey[obj.type];
    this.createdBy = make(obj.createdBy, Employee);
  }
}

export default MemberDocument;
