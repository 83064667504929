import { translate } from '@vestahealthcare/common/i18n';

export const EVENTS_PAGE_TAB_UNASSIGNED = 'tab-unassigned';
export const EVENTS_PAGE_TAB_ASSIGNED_ME = 'tab-assigned';
export const EVENTS_PAGE_TAB_ESCALATED = 'tab-escalated';
export const EVENTS_PAGE_TAB_NON_URGENT_FOLLOW_UP = 'tab-triage';
export const EVENTS_PAGE_TAB_URGENT_FOLLOW_UP = 'tab-urgent-follow-up';
export const EVENTS_PAGE_TAB_VITALS_ABNORMAL = 'tab-vitals-recheck';
export const EVENTS_PAGE_TAB_OPEN = 'tab-open';
export const EVENTS_PAGE_TAB_ALL = 'tab-all';

const getCount = (count?: number) => (count ? ` (${count})` : '');

export type TabsCount = {
  open?: number;
  all?: number;
  unassigned?: number;
  assigned?: number;
  escalated?: number;
  urgentFollowUp?: number;
  nonUrgentFollowUp?: number;
  vitalsAbnormal?: number;
};

export const EVENTS_PAGE_TABS = ({
  open,
  all,
  unassigned,
  assigned,
  escalated,
  urgentFollowUp,
  nonUrgentFollowUp,
  vitalsAbnormal,
}: TabsCount) => [
  {
    label: `${translate('dashboardEvents.tabs.all')}${getCount(all)}`,
    value: EVENTS_PAGE_TAB_ALL,
  },
  {
    label: `${translate('dashboardEvents.tabs.open')}${getCount(open)}`,
    value: EVENTS_PAGE_TAB_OPEN,
  },
  {
    label: `${translate('dashboardEvents.tabs.unassigned')}${getCount(
      unassigned,
    )}`,
    value: EVENTS_PAGE_TAB_UNASSIGNED,
  },
  {
    label: `${translate('dashboardEvents.tabs.assignedToMe')}${getCount(
      assigned,
    )}`,
    value: EVENTS_PAGE_TAB_ASSIGNED_ME,
  },
  {
    label: `${translate('dashboardEvents.tabs.vitalsAbnormal')}${getCount(
      vitalsAbnormal,
    )}`,
    value: EVENTS_PAGE_TAB_VITALS_ABNORMAL,
  },
  {
    label: `${translate('dashboardEvents.tabs.escalated')}${getCount(
      escalated,
    )}`,
    value: EVENTS_PAGE_TAB_ESCALATED,
  },
  {
    label: `${translate('dashboardEvents.tabs.urgentFollowUp')}${getCount(
      urgentFollowUp,
    )}`,
    value: EVENTS_PAGE_TAB_URGENT_FOLLOW_UP,
  },
  {
    label: `${translate('dashboardEvents.tabs.nonUrgentFollowUp')}${getCount(
      nonUrgentFollowUp,
    )}`,
    value: EVENTS_PAGE_TAB_NON_URGENT_FOLLOW_UP,
  },
];

export const EVENTS_PAGE_LIMIT = 50;
