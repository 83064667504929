import { Moment } from 'moment';

import { makeMoment } from '../Model';
import { MemberWarning } from './MemberWarning';

export class MemberWarningCarePlanUpdate extends MemberWarning {
  lastCarePlanDate?: Moment;

  lastCarePlanEmployee = '';

  nextCarePlanDue?: Moment;

  constructor(obj: any) {
    super(obj);
    Object.assign(this, obj);
    this.lastCarePlanDate = makeMoment(obj.lastCarePlanDate);
    this.nextCarePlanDue = makeMoment(obj.nextCarePlanDue);
  }
}

export default MemberWarningCarePlanUpdate;
