import React from 'react';
import { translate } from '@vestahealthcare/common/i18n';
import { Modal } from 'styleguide-v2';
import { IssueType } from '@vestahealthcare/common/models';

interface Props {
  alreadyMapped: IssueType[];
  open: boolean;
  onCancel: () => void;
  onSubmit: () => Promise<void>;
}

export const EventMappgingConfirmModal = ({
  alreadyMapped,
  open,
  onCancel,
  onSubmit,
}: Props) => (
  <Modal
    title={translate('events.mapping.confirmModalTitle')}
    body={translate('events.mapping.confirmModalBody', {
      events: alreadyMapped.map(({ description }) => description).join(', '),
      count: alreadyMapped.length,
    })}
    onClose={onCancel}
    open={open}
    onSubmit={onSubmit}
  />
);

export default EventMappgingConfirmModal;
