import React, { useEffect, useState } from 'react';
import { List, arrayMove } from 'react-movable';
import { translate } from '@vestahealthcare/common/i18n';
import { Button, Colors, DeleteIcon, EditIcon, Select } from 'styleguide';
import { makeStyles } from '@material-ui/styles';
import ButtonModal from 'styleguide/src/components/Modal/ButtonModal';
import { CollapseIcon } from 'styleguide/src/components/Icons';
import TaskDefinition from '@vestahealthcare/common/models/TaskDefinition';

type CheckType = boolean | ((item: TaskDefinition) => boolean);

type Props = {
  className?: string;
  color?: 'primary' | 'secondary';
  'data-cy'?: string;
  getLabel?: (item: TaskDefinition) => string;
  isEditable?: CheckType;
  items: TaskDefinition[];
  onChange: (items: TaskDefinition[]) => void;
  onEditItem?: (item: TaskDefinition) => void;
  onAddRule: (item: TaskDefinition) => void;
  onRemoveRule: (item: TaskDefinition) => void;
  onChangeRule: (item: TaskDefinition) => void;
  placeholder?: string;
  submitted?: boolean;
};

const useStyles = makeStyles({
  row: {
    background: ({ color }: Props) =>
      color === 'secondary' ? Colors.white : Colors.lightGray,
    margin: '5px 0',
    padding: '5px',
    borderRadius: '5px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 1px 2px 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  rowWrapper: {
    display: 'flex',
  },
  icon: {
    color: Colors.gray,
    width: '20px',
    margin: '5px 5px',
    padding: '5px',
  },
  input: {
    alignItems: 'center',
    border: 0,
    background: ({ color }: Props) =>
      color === 'secondary' ? Colors.white : Colors.lightGray,
    display: 'inline-flex',
    flex: 'auto',
    height: '34px',
  },
  inputError: {
    border: `${Colors.red} 2px solid`,
    borderRadius: '5px',
  },
  collapsedWrapper: {
    width: '100%',
    display: 'flex',
  },
  collapsedContent: {
    padding: '5px',
  },
  collapseIcon: {
    width: '20px',
    margin: '10px 5px',
    padding: '5px',
  },
  collapsedRow: {
    margin: '15px 0',
    padding: '5px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  collapsableSelect: {
    width: '100%',
  },
});

export const SubTaskSortableList = (props: Props) => {
  const styles = useStyles(props);
  const {
    className,
    'data-cy': dataCy,
    getLabel,
    items,
    isEditable,
    onAddRule,
    onRemoveRule,
    onChangeRule,
    onChange,
    onEditItem,
    placeholder,
    submitted,
  } = props;
  const [list, setList] = useState<TaskDefinition[]>(items || []);
  const [isCollapsed, setIsCollapsed] = useState<Boolean[]>([]);

  useEffect(() => {
    setList([...items]);
  }, [items]);

  useEffect(() => {
    setIsCollapsed(items.map(() => true));
  }, []);

  const removeItem = (index?: number) => {
    list.splice(index || 0, 1);
    setList([...list]);
    onChange([...list]);
  };

  const removeTaskPrecedent = (
    index: number,
    definition: TaskDefinition,
    collapsedIndex: number,
  ) => {
    const newDefinition = definition;
    newDefinition.taskPrecedentIds?.splice(index, 1);
    if (newDefinition.taskPrecedentIds?.length === 0)
      setIsCollapsed(
        isCollapsed.map((o, i) => (i === collapsedIndex ? true : o)),
      );
    onRemoveRule(newDefinition);
  };

  const changeRule = (
    definition: TaskDefinition,
    index: number,
    taskId: number,
  ) => {
    const newDefinition = definition;
    if (newDefinition.taskPrecedentIds)
      newDefinition.taskPrecedentIds[index] = taskId;
    onChangeRule(newDefinition);
  };

  const isCollapsable = (definition: TaskDefinition) => {
    return definition.taskPrecedentIds
      ? definition.taskPrecedentIds.length > 0
      : false;
  };

  const isRemovable = (definition: TaskDefinition) => {
    return definition.new;
  };

  const needConfirmation = (definition: TaskDefinition) => {
    return definition.taskPrecedentIds
      ? definition.taskPrecedentIds.length > 0
      : false;
  };

  const handleCollapsed = (index: number) => {
    setIsCollapsed(
      isCollapsed.map((o, i) => (i === index ? !isCollapsed[index] : o)),
    );
  };

  const addRule = (value: TaskDefinition, index: number) => {
    if (isCollapsed[index]) handleCollapsed(index);
    onAddRule(value);
  };
  return (
    <List
      lockVertically
      values={list}
      onChange={({ oldIndex, newIndex }) => {
        setList([...arrayMove(list, oldIndex, newIndex)]);
        onChange([...arrayMove(list, oldIndex, newIndex)]);
      }}
      renderList={({ children, props }) => (
        <div data-cy={dataCy} className={className} {...props}>
          {children}
        </div>
      )}
      renderItem={({ value: definition, props, isDragged, index = 0 }) => {
        const row = (
          <div {...props} className={styles.row} style={{ zIndex: 1 }}>
            <div className={styles.rowWrapper}>
              <i
                data-movable-handle
                className={`${styles.icon} fa fa-bars`}
                style={{
                  cursor: isDragged ? 'grabbing' : 'grab',
                }}
              />
              <div className={styles.collapsedWrapper} tabIndex={index}>
                {isEditable ? (
                  <input
                    autoComplete="disabled"
                    className={`${styles.input} ${
                      submitted && !definition.name ? styles.inputError : ''
                    }`}
                    placeholder={placeholder || translate('global.name')}
                    onChange={(e) => {
                      const name = e.target.value || '';
                      const newList = [...list];
                      newList[index] = { ...newList[index], name };
                      setList([...newList]);
                      onChange([...newList]);
                    }}
                    value={definition.name}
                    type="text"
                  />
                ) : (
                  <span className={styles.input}>
                    {getLabel
                      ? getLabel(definition)
                      : definition.name ||
                        placeholder ||
                        translate('global.name')}
                  </span>
                )}
                {items.length > 1 &&
                  definition.taskPrecedentIds &&
                  !definition.new && (
                    <Button
                      color="primary"
                      isPill
                      disabled={
                        definition.taskPrecedentIds.length === items.length - 1
                      }
                      onClick={() => addRule(definition, index)}
                    >
                      {translate('taskDefinitions.addRule')}
                    </Button>
                  )}
                {onEditItem && (
                  <EditIcon
                    className={`grid-span-1 ${styles.icon}`}
                    tag="button"
                    onClick={() => onEditItem(definition)}
                  />
                )}
                {isRemovable(definition) &&
                  (needConfirmation(definition) ? (
                    <ButtonModal
                      iconComponent={<DeleteIcon tag="span" />}
                      modalTitle={translate('global.confirmRemove')}
                      onSubmit={() => removeItem(index)}
                    >
                      {translate(
                        'taskDefinition.subtask.inactiveWithPrecedent',
                      )}
                    </ButtonModal>
                  ) : (
                    <DeleteIcon
                      className={`grid-span-1 ${styles.icon}`}
                      tag="button"
                      onClick={() => removeItem(index)}
                    />
                  ))}
                {isCollapsable(definition) && (
                  <CollapseIcon
                    tag="button"
                    className={styles.collapseIcon}
                    active={!isCollapsed[index]}
                    onClick={() => handleCollapsed(index)}
                  />
                )}
              </div>
            </div>
            {!isCollapsed[index] && (
              <div className={styles.collapsedContent}>
                {definition.taskPrecedentIds?.map(
                  (precedent, precedentIndex) => {
                    return (
                      <div
                        className={styles.collapsedRow}
                        key={`precedent_${precedentIndex}`}
                      >
                        {translate('taskDefinitions.subtask.dependsOn')}
                        <div className={styles.inputRow}>
                          <Select
                            className={styles.collapsableSelect}
                            onChange={(taskId: number) => {
                              changeRule(definition, precedentIndex, taskId);
                            }}
                            options={items
                              .filter(
                                (current) =>
                                  current.id !== definition.id && !current.new,
                              )
                              .map((o) => ({
                                label:
                                  o.name ||
                                  translate('taskDefinitions.subtask.name'),
                                value: o.id,
                              }))}
                            required
                            value={precedent}
                          />
                          <DeleteIcon
                            className={`grid-span-1 ${styles.icon}`}
                            tag="button"
                            onClick={() =>
                              removeTaskPrecedent(
                                precedentIndex,
                                definition,
                                index,
                              )
                            }
                          />
                        </div>
                      </div>
                    );
                  },
                )}
              </div>
            )}
          </div>
        );
        return isDragged ? (
          <div
            // eslint-disable-next-line react/prop-types
            style={{ ...props.style, borderSpacing: 5 }}
          >
            {row}
          </div>
        ) : (
          row
        );
      }}
    />
  );
};

export default SubTaskSortableList;
