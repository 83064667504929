/* eslint-disable no-console */
import React, { useState } from 'react';

import { Card } from '@mui/material';

import { StoredFilter } from '@vestahealthcare/common/models';

import { Button, Chip, Panel, Select, Tabs } from 'styleguide-v2';

const Panels = () => {
  const tabs = [
    {
      value: 1,
      label: (
        <div className="flex">
          <span>Chat</span>&nbsp;<Chip color="secondary">2</Chip>
        </div>
      ),
    },
    { value: 2, label: 'Option 2' },
  ];
  const selectItems = [
    { label: 'Option 1' },
    { label: 'Option 2' },
    { label: 'Option 3 laaaaarge' },
    { label: 'Option 4' },
  ];
  const [tab, setTab] = useState(1);
  const [isOpen, setOpen] = useState(false);
  const [hasTabs, setHasTabs] = useState(true);
  const [orientation, setOrientation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [storedFilters, setStoredFilters] = useState(
    [
      { id: 'filter1', description: 'Filter 1', filters: {}, preferred: true },
      { id: 'filter2', description: 'Filter 2', filters: {} },
      {
        id: 'filter3',
        description: 'Filter 3 Super Looooooooooong',
        filters: {},
      },
    ].map((x) => new StoredFilter(x)),
  );

  return (
    <Panel detail={orientation ? 'vertical' : 'horizontal'}>
      <Panel.Heading title="Title Example" filtersV2>
        <Panel.FilterBar
          onClearFilters={() => {}}
          onDeleteFilter={() => {}}
          onSelectFilter={(filter) =>
            console.log('Selected filter "%s"', filter.description)
          }
          onOpenFilters={() => setOpen(!isOpen)}
          chips={{
            validCCM: {
              label: 'Has Valid CCM',
              value: { label: 'Yes', value: true },
            },
            ccmStatus: {
              label: 'CCM Status',
              value: { label: 'Completed', value: 'COMPLETED' },
            },
            unreachable: {
              label: 'Unreachable',
              value: { label: 'No', value: false },
            },
            rpmAdherence: {
              label: 'RPM Adherence',
              value: [
                { label: 'At Risk', value: 'AT_RISK' },
                { label: 'Missed', value: 'AT_RISK' },
              ],
            },
          }}
          inputs={
            <>
              <Select
                items={selectItems}
                onChange={console.log}
                placeholder="Employees"
                size="xs"
              />
              <Select
                value={selectItems[0]}
                items={selectItems}
                onChange={console.log}
                placeholder="Members"
                size="xs"
              />
              <Select
                loading
                items={selectItems}
                onChange={console.log}
                placeholder="Members"
                size="xs"
              />
            </>
          }
          storedFilters={storedFilters}
        />
        <Panel.Actions>
          <Button
            color="secondary"
            type="outlined"
            onClick={() => setOrientation(!orientation)}
          >
            Toggle Orientation
          </Button>
          <Button
            color="secondary"
            type="outlined"
            onClick={() => setHasTabs(!hasTabs)}
          >
            Toggle Tabs
          </Button>
          <Button
            color="secondary"
            type="outlined"
            onClick={() => setLoading(!loading)}
          >
            Toggle Loading
          </Button>
        </Panel.Actions>
        {/* <Panel.Collapse open={false}>
          <div className="flex" style={{ gap: '1rem', marginTop: '.5rem' }}>
            <Select
              items={selectItems}
              label="Employees"
              onChange={console.log}
            />
            <Select
              value={selectItems[0]}
              items={selectItems}
              label="Members"
              onChange={console.log}
            />
            <Select
              loading
              items={selectItems}
              label="Members"
              onChange={console.log}
            />
            <SearchInput />
            <SearchInput value="Test search" loading />
          </div>
        </Panel.Collapse> */}
        {hasTabs && (
          <Panel.Tabs>
            <Tabs
              className="bottom"
              value={tab}
              items={tabs}
              onChange={(newValue) => setTab(newValue)}
            />
          </Panel.Tabs>
        )}
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <Panel.FilterModal
          open={isOpen}
          onClear={() => {}}
          onClose={() => setOpen(false)}
          onSubmit={() => {}}
          onDeleteFilter={async ({ id: deletedId }) =>
            setStoredFilters(
              [...storedFilters].filter(({ id }) => id !== deletedId),
            )
          }
          onSaveFilter={async (editFilter) =>
            editFilter?.id
              ? setStoredFilters(
                  [...storedFilters].map((filter) =>
                    filter.id === editFilter.id ? editFilter : filter,
                  ),
                )
              : setStoredFilters([
                  ...storedFilters,
                  new StoredFilter({
                    ...editFilter,
                    filters: {},
                    id: `id-${Math.random() * 100000}`,
                  }),
                ])
          }
          storedFilters={storedFilters}
        >
          <Panel.FilterGroup title="Basic Filters" count={2}>
            <Select.Choice
              onChange={() => {}}
              label="Basic Filter 1"
              size="small"
            />
            <Select.Choice
              onChange={() => {}}
              label="Basic Filter 2"
              size="small"
            />
          </Panel.FilterGroup>
          <Panel.FilterGroup title="Member Filters">
            <Select.Choice
              onChange={() => {}}
              label="Member Filter 1"
              size="small"
            />
            <Select.Choice
              onChange={() => {}}
              label="Member Filter 2"
              size="small"
            />
          </Panel.FilterGroup>
        </Panel.FilterModal>
        <Card sx={{ padding: '2rem', marginBottom: '1rem' }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
          mollitia voluptas pariatur id aspernatur repellendus quos. Accusantium
          nihil quam, nesciunt sunt eligendi delectus, inventore sint
          consequuntur reprehenderit dolores numquam modi. Lorem ipsum dolor,
          sit amet consectetur adipisicing elit. Eveniet alias nulla quibusdam
          minima, itaque cupiditate. Dolore sint quisquam porro exercitationem
          quis, facilis commodi sed incidunt vel hic dignissimos reprehenderit
          doloremque.
        </Card>
        <Card sx={{ padding: '2rem', marginBottom: '1rem' }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
          mollitia voluptas pariatur id aspernatur repellendus quos. Accusantium
          nihil quam, nesciunt sunt eligendi delectus, inventore sint
          consequuntur reprehenderit dolores numquam modi. Lorem ipsum dolor,
          sit amet consectetur adipisicing elit. Eveniet alias nulla quibusdam
          minima, itaque cupiditate. Dolore sint quisquam porro exercitationem
          quis, facilis commodi sed incidunt vel hic dignissimos reprehenderit
          doloremque.
        </Card>
        <Card sx={{ padding: '2rem', marginBottom: '1rem' }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
          mollitia voluptas pariatur id aspernatur repellendus quos. Accusantium
          nihil quam, nesciunt sunt eligendi delectus, inventore sint
          consequuntur reprehenderit dolores numquam modi. Lorem ipsum dolor,
          sit amet consectetur adipisicing elit. Eveniet alias nulla quibusdam
          minima, itaque cupiditate. Dolore sint quisquam porro exercitationem
          quis, facilis commodi sed incidunt vel hic dignissimos reprehenderit
          doloremque.
        </Card>
        <Card sx={{ padding: '2rem', marginBottom: '1rem' }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
          mollitia voluptas pariatur id aspernatur repellendus quos. Accusantium
          nihil quam, nesciunt sunt eligendi delectus, inventore sint
          consequuntur reprehenderit dolores numquam modi. Lorem ipsum dolor,
          sit amet consectetur adipisicing elit. Eveniet alias nulla quibusdam
          minima, itaque cupiditate. Dolore sint quisquam porro exercitationem
          quis, facilis commodi sed incidunt vel hic dignissimos reprehenderit
          doloremque.
        </Card>
      </Panel.Body>
      {orientation && (
        <Panel.VerticalDetail>
          <Card sx={{ padding: '2rem', marginBottom: '1rem' }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
            mollitia voluptas pariatur id aspernatur repellendus quos.
            Accusantium nihil quam, nesciunt sunt eligendi delectus, inventore
            sint consequuntur reprehenderit dolores numquam modi. Lorem ipsum
            dolor, sit amet consectetur adipisicing elit. Eveniet alias nulla
            quibusdam minima, itaque cupiditate. Dolore sint quisquam porro
            exercitationem quis, facilis commodi sed incidunt vel hic
            dignissimos reprehenderit doloremque.
          </Card>

          <Card sx={{ padding: '2rem', marginBottom: '1rem' }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
            mollitia voluptas pariatur id aspernatur repellendus quos.
            Accusantium nihil quam, nesciunt sunt eligendi delectus, inventore
            sint consequuntur reprehenderit dolores numquam modi. Lorem ipsum
            dolor, sit amet consectetur adipisicing elit. Eveniet alias nulla
            quibusdam minima, itaque cupiditate. Dolore sint quisquam porro
            exercitationem quis, facilis commodi sed incidunt vel hic
            dignissimos reprehenderit doloremque.
          </Card>

          <Card sx={{ padding: '2rem', marginBottom: '1rem' }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
            mollitia voluptas pariatur id aspernatur repellendus quos.
            Accusantium nihil quam, nesciunt sunt eligendi delectus, inventore
            sint consequuntur reprehenderit dolores numquam modi. Lorem ipsum
            dolor, sit amet consectetur adipisicing elit. Eveniet alias nulla
            quibusdam minima, itaque cupiditate. Dolore sint quisquam porro
            exercitationem quis, facilis commodi sed incidunt vel hic
            dignissimos reprehenderit doloremque.
          </Card>

          <Card sx={{ padding: '2rem', marginBottom: '1rem' }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
            mollitia voluptas pariatur id aspernatur repellendus quos.
            Accusantium nihil quam, nesciunt sunt eligendi delectus, inventore
            sint consequuntur reprehenderit dolores numquam modi. Lorem ipsum
            dolor, sit amet consectetur adipisicing elit. Eveniet alias nulla
            quibusdam minima, itaque cupiditate. Dolore sint quisquam porro
            exercitationem quis, facilis commodi sed incidunt vel hic
            dignissimos reprehenderit doloremque.
          </Card>
        </Panel.VerticalDetail>
      )}
      {!orientation && (
        <Panel.Detail width={600}>
          <Card sx={{ padding: '2rem', marginBottom: '1rem' }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
            mollitia voluptas pariatur id aspernatur repellendus quos.
            Accusantium nihil quam, nesciunt sunt eligendi delectus, inventore
            sint consequuntur reprehenderit dolores numquam modi. Lorem ipsum
            dolor, sit amet consectetur adipisicing elit. Eveniet alias nulla
            quibusdam minima, itaque cupiditate. Dolore sint quisquam porro
            exercitationem quis, facilis commodi sed incidunt vel hic
            dignissimos reprehenderit doloremque.
          </Card>

          <Card sx={{ padding: '2rem', marginBottom: '1rem' }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
            mollitia voluptas pariatur id aspernatur repellendus quos.
            Accusantium nihil quam, nesciunt sunt eligendi delectus, inventore
            sint consequuntur reprehenderit dolores numquam modi. Lorem ipsum
            dolor, sit amet consectetur adipisicing elit. Eveniet alias nulla
            quibusdam minima, itaque cupiditate. Dolore sint quisquam porro
            exercitationem quis, facilis commodi sed incidunt vel hic
            dignissimos reprehenderit doloremque.
          </Card>

          <Card sx={{ padding: '2rem', marginBottom: '1rem' }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
            mollitia voluptas pariatur id aspernatur repellendus quos.
            Accusantium nihil quam, nesciunt sunt eligendi delectus, inventore
            sint consequuntur reprehenderit dolores numquam modi. Lorem ipsum
            dolor, sit amet consectetur adipisicing elit. Eveniet alias nulla
            quibusdam minima, itaque cupiditate. Dolore sint quisquam porro
            exercitationem quis, facilis commodi sed incidunt vel hic
            dignissimos reprehenderit doloremque.
          </Card>

          <Card sx={{ padding: '2rem', marginBottom: '1rem' }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
            mollitia voluptas pariatur id aspernatur repellendus quos.
            Accusantium nihil quam, nesciunt sunt eligendi delectus, inventore
            sint consequuntur reprehenderit dolores numquam modi. Lorem ipsum
            dolor, sit amet consectetur adipisicing elit. Eveniet alias nulla
            quibusdam minima, itaque cupiditate. Dolore sint quisquam porro
            exercitationem quis, facilis commodi sed incidunt vel hic
            dignissimos reprehenderit doloremque.
          </Card>
        </Panel.Detail>
      )}
    </Panel>
  );
};

export default Panels;
