import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { translate } from '@vestahealthcare/common/i18n';
import { Warning } from 'styleguide';
import { GridColumns } from '../../utils/types';

type InputElement = HTMLInputElement | HTMLTextAreaElement;

interface Props {
  autoFocus?: boolean;
  className?: string;
  columns?: GridColumns;
  'data-cy'?: string;
  disabled?: boolean;
  errorText?: string;
  id?: string;
  value?: string;
  inputRef?: (ref: any) => void;
  label?: JSX.Element | string | boolean;
  maxLength?: number;
  multiline?: number;
  onBlur?: (value: string | undefined) => void;
  onChange?: (value: string | undefined) => void;
  onFocus?: (e: React.FocusEvent<InputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent, value: string | undefined) => void;
  placeholder?: string;
  required?: boolean;
  showError?: boolean;
  hasWarning?: boolean;
  warningText?: string;
}

const TextInput = ({
  columns,
  disabled = false,
  label = false,
  multiline = 0,
  placeholder = translate('global.enterText'),
  value = '',
  ...props
}: Props) => {
  const [currentValue, setValue] = useState(value);
  const [showWarning, setShowWarning] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const onChange = (e: any) => {
    const { onChange, hasWarning } = props;
    const val = e.target.value;
    onChange && onChange(val || undefined);
    setValue(val);
    setShowWarning(!!hasWarning);
  };

  const onBlur = (e: React.FocusEvent<InputElement>) => {
    const { onBlur } = props;
    const { value } = e.target;
    setValue(value);
    setIsFocused(false);

    if (onBlur) {
      onBlur(value);
    }
  };

  const onFocus = (e: React.FocusEvent<InputElement>) => {
    const { onFocus } = props;
    setIsFocused(true);
    onFocus && onFocus(e);
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    const { onKeyDown } = props;
    if (!multiline && event.key === 'Enter') {
      event.preventDefault();
    }
    onKeyDown && onKeyDown(event, currentValue);
  };

  const renderHelp = () => {
    const { errorText, showError, warningText } = props;
    if (isFocused) {
      return;
    }

    if (!!errorText && showError) {
      return <span className="error-message help-block">{errorText}</span>;
    }

    if (!!warningText && showWarning) {
      return (
        <Warning title={warningText} type="box" className="warning-block" />
      );
    }
  };

  const classes = classNames(
    props.className,
    'form-group',
    columns ? `grid-span-${columns}` : undefined,
    {
      'has-error': !isFocused && props.showError,
    },
  );

  const inputProps = {
    disabled,
    onBlur,
    onChange,
    onFocus,
    onKeyDown,
    placeholder,
    autoFocus: props.autoFocus,
    className: 'form-control',
    'data-cy': props['data-cy'] && `${props['data-cy']}-input`,
    maxLength: props.maxLength,
    ref: props.inputRef
      ? (element: any) => props.inputRef && props.inputRef(element)
      : undefined,
    rows: multiline || undefined,
    type: 'text',
    value: currentValue,
  };

  return (
    <div
      data-cy={props['data-cy']}
      className={classes}
      id={props.id || undefined}
    >
      {label && (
        <label className="control-label">
          {label}
          {props.required && ' *'}
        </label>
      )}
      {multiline ? <textarea {...inputProps} /> : <input {...inputProps} />}
      {renderHelp()}
    </div>
  );
};

export default TextInput;
