import React from 'react';
import { FieldProps } from 'react-jsonschema-form';
import { Table, Radio } from 'styleguide';
import { JSONSchema6, JSONSchema6Type } from 'json-schema';
import MoreInfo from '../components/MoreInfo';
import classnames from 'classnames';

interface IExtendedEnumSchema extends JSONSchema6 {
  enumNames?: string[];
  enumDescriptions?: string[];
}

const BradenScoreHeaderField = (props: FieldProps) => {
  const {
    formData,
    formContext: { readonly },
    onChange,
    schema,
  }: FieldProps = props;

  const {
    title,
    description,
    enum: enumValues,
    enumNames,
    enumDescriptions,
  }: IExtendedEnumSchema = schema;

  const renderCell = (value: JSONSchema6Type, index: number) => {
    const enabled = !!value;
    const isSelected = value === formData;
    const showMoreInfo = isSelected || !readonly;
    const classes = classnames({
      selected: isSelected,
      selectable: enabled && !readonly,
    });
    const label = enumNames && enumNames[index] ? enumNames[index] : value;

    return (
      <td
        data-cy="braden-scale-options"
        key={value ? value.toString() : index}
        className={classes}
      >
        {!readonly && enabled && (
          <Radio
            checked={formData === value}
            onChange={() => !readonly && onChange(value)}
          />
        )}
        {isSelected ? <span className="medium">{label}</span> : label}
        {enumDescriptions && enumDescriptions[index] && showMoreInfo && (
          <p>
            <MoreInfo description={enumDescriptions[index]} />
          </p>
        )}
      </td>
    );
  };

  return (
    <div className="braden-scale-table-row remove-top-margin">
      <Table>
        <tbody>
          <tr data-cy="braden-scale-table-row">
            <th>
              <p className="medium" data-cy="braden-scale-criteria">
                {title}
              </p>
              <p>{description}</p>
            </th>
            {enumValues && enumValues.map(renderCell)}
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default BradenScoreHeaderField;
