import Api from 'dash/src/services/Api';
import {
  Observation,
  ObservationAnswers,
} from '@vestahealthcare/common/models';
import { JSONSchema6 } from 'json-schema';

// NOTE: Backend returns v4, frontend expects v6
export type Schema = {
  formSchema: JSONSchema6;
  uiSchema: { [key: string]: any };
};

export const fetchSchema = async (observationType: string): Promise<Schema> =>
  Api.getv2(`form-schemas/observations-${observationType}`);

export const fetchObservations = async (
  patientId: number | string,
  observationType: string,
): Promise<ObservationAnswers> => {
  const { form } = await Api.getv2(
    `patients/${patientId}/observations/${observationType}`,
  );
  return new ObservationAnswers(form);
};

export const fetchObservationQuestions = async (
  patientId: number | string,
  questionKeys: string[],
): Promise<Observation[]> => {
  const { observations } = await Api.getv2(
    `/patients/${patientId}/observations/views/phip/`,
    {
      questions: questionKeys,
    },
  );
  return observations.items.map((question: any) => new Observation(question));
};

export const updateObservations = async (
  patientId: number | string,
  observationType: string,
  params: {
    version: number;
    answers: { [key: string]: any };
  },
): Promise<ObservationAnswers> => {
  const { form } = await Api.postv2JSON(
    `patients/${patientId}/observations/${observationType}`,
    params,
  );
  return new ObservationAnswers(form);
};
