import React from 'react';
import config from 'dash/environments';
import './main.less';

interface Props {
  type?: string;
  message?: string;
}

const getEnvironment = () => {
  if (config.isProd) {
    return;
  }
  if (config.isDemo) {
    return 'demo';
  }
  if (config.isQA) {
    return 'qa';
  }
  if (config.isStage) {
    return 'stage';
  }
  if (config.isDev) {
    return 'dev';
  }
};

const getBannerMessage = (environment: string) => {
  const remoteText = config.remoteBackend
    ? ` [${config.remoteBackend.toUpperCase()} BACKEND]`
    : '';

  return `Warning! This is not the real dash. This is the ${environment}${remoteText} dash environment.`;
};

export const Banner = (props: Props) => {
  const { type, message } = props;
  const bannerType = type || getEnvironment();

  if (!bannerType) {
    return <></>;
  }

  return (
    <div id="ht-environment-banner" className={`banner-${bannerType}`}>
      {message || getBannerMessage(bannerType)}
    </div>
  );
};

export default Banner;
