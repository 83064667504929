import { Moment } from 'moment';

import { ClinicalMember } from './ClinicalMember';
import { makeMoment } from './Model';

export class BHIMember extends ClinicalMember {
  bhiMinutes = 0;

  lastCarePlanDate?: Moment;

  touchpointDate?: Moment;

  constructor(obj: any) {
    super(obj);
    this.bhiMinutes = obj.bhiMinutes;
    this.lastCarePlanDate = makeMoment(obj.lastCarePlanDate);
    this.touchpointDate = makeMoment(obj.touchpointDate);
  }
}

export default BHIMember;
