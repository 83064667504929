import classNames from 'classnames';
import React, { Fragment } from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { EventDetail } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Button, CollapsableSidebar, UpdatedBy } from 'styleguide-v2';

interface Props {
  event?: EventDetail;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles({
  title: {
    marginBottom: 0,
  },
});

export const MemberEventHistoryModal = ({ event, open, onClose }: Props) => {
  const styles = useStyles();
  const titleStyles = classNames(styles.title, 'grid-span-12');
  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={<h2>{translate('memberEvents.modals.history')}</h2>}
      size={400}
    >
      <CollapsableSidebar.Body>
        <div className="grid-wrapper fit">
          <h6 className={titleStyles}>
            {translate('memberEvents.modals.historyEventInfo')}
          </h6>
          {event ? (
            <>
              <span className="grid-span-6">
                {translate('memberEvents.modals.historyCreated')}:
              </span>
              <UpdatedBy
                className="grid-span-6"
                date={event.createdAt}
                user={event.createdBy?.fullName}
              />
              <span className="grid-span-6">
                {translate('memberEvents.modals.historyUpdated')}:
              </span>
              <UpdatedBy
                className="grid-span-6"
                date={event.updatedAt}
                user={event.updatedBy?.fullName}
              />

              {event.isClosed &&
                (event.isInvalid ? (
                  <>
                    <span className="grid-span-6">
                      {translate('memberEvents.modals.historyInvalidated')}:
                    </span>
                    {event.closedAt ? (
                      <UpdatedBy
                        className="grid-span-6"
                        date={event.closedAt}
                        user={event.closedBy?.fullName}
                      />
                    ) : (
                      EMPTY
                    )}
                  </>
                ) : (
                  <>
                    <span className="grid-span-6">
                      {translate('memberEvents.modals.historyClosed')}:
                    </span>
                    {event.closedAt ? (
                      <UpdatedBy
                        className="grid-span-6"
                        date={event.closedAt}
                        user={event.closedBy?.fullName}
                      />
                    ) : (
                      EMPTY
                    )}
                  </>
                ))}
            </>
          ) : (
            <></>
          )}

          <h6 className={titleStyles}>
            {translate('memberEvents.modals.historyIssuesInfo')}
          </h6>
          {event?.issues?.map(
            ({ id, createdAt, createdBy, updatedAt, updatedBy, issueType }) => (
              <Fragment key={`issue-${id}`}>
                <h4 className={titleStyles}>
                  {translate('memberEvents.modals.historyIssueInfo', {
                    issue: issueType.description,
                  })}
                </h4>
                <span className="grid-span-6">
                  {translate('memberEvents.modals.historyIssueCreated')}:
                </span>
                <UpdatedBy
                  className="grid-span-6"
                  date={createdAt}
                  user={createdBy?.fullName}
                />
                <span className="grid-span-6">
                  {translate('memberEvents.modals.historyIssueUpdated')}:
                </span>
                <UpdatedBy
                  className="grid-span-6"
                  date={updatedAt}
                  user={updatedBy?.fullName}
                />
              </Fragment>
            ),
          )}
        </div>
        <br />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button color="tertiary" data-cy="close-event-close" onClick={onClose}>
          {translate('global.close')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default MemberEventHistoryModal;
