import React, { useState } from 'react';
import { CollapseIcon, Table } from 'styleguide';
import { translate, translateToArray } from '@vestahealthcare/common/i18n';

export const GoalTips = () => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <div className="grid-span-12">
      <button
        className="h6 show-criteria-btn"
        data-cy="goals-tips-collapse"
        onClick={() => setCollapsed(!collapsed)}
        type="button"
      >
        <CollapseIcon active={!collapsed} className="margin-left" />
        {collapsed
          ? translate('goalsOfCare.showTips')
          : translate('goalsOfCare.hideTips')}
      </button>
      {!collapsed && (
        <Table className="bordered-table">
          <tbody>
            <tr>
              <td>
                <ul>
                  {(translateToArray('goalsOfCare.tips') as string[]).map(
                    (tip) => (
                      <li key={tip}>
                        &bull;&nbsp;
                        {tip}
                      </li>
                    ),
                  )}
                </ul>
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default GoalTips;
