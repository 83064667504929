import { translate } from '@vestahealthcare/common/i18n';
import React, { Fragment } from 'react';
import { CollapsableSidebar, Colors, Fonts } from 'styleguide-v2';
import { EventTriage, EventTriageAdvice } from '@vestahealthcare/common/models';
import { makeStyles } from '@mui/styles';
import { VIDEO_VISIT_STRING } from './MemberEvaluationAddTriage';

interface Props {
  open: boolean;
  onClose: () => void;
  triage?: EventTriage;
}

const useStyles = makeStyles({
  sectionTitle: {
    '&&': {
      fontWeight: 500,
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} *0.875)`,
      color: Colors.iconGreen,
    },
  },
});

export const MemberEvaluationShowTriageModal = ({
  open,
  onClose,
  triage,
}: Props) => {
  const styles = useStyles();

  const advicesReduced = triage?.adviceDetails
    ?.filter(({ title }) => title !== VIDEO_VISIT_STRING)
    ?.reduce((acc, item) => {
      if (!acc[item.title]) {
        acc[item.title] = [];
      }
      acc[item.title].push(item);
      return acc;
    }, {} as { [key: string]: EventTriageAdvice[] });

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={<h2>{translate('memberEvents.showTriageModalTitle')}</h2>}
      size={550}
    >
      {advicesReduced && !!Object.keys(advicesReduced)?.length && (
        <>
          <h4 className={styles.sectionTitle}>
            {translate('memberEvents.detail.advice')}
          </h4>
          {Object.keys(advicesReduced).map((key) => (
            <Fragment key={`advice-group-${key}`}>
              <p style={{ fontWeight: 500 }}>{key}</p>
              {advicesReduced[key].map(
                ({ id, detail }) =>
                  detail && (
                    <p key={`advice-id-${id}`}>
                      {'  '}
                      {detail}
                    </p>
                  ),
              )}
            </Fragment>
          ))}
        </>
      )}
    </CollapsableSidebar>
  );
};

export default MemberEvaluationShowTriageModal;
