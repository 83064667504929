import React from 'react';

import { Colors, IconButton, Table, Tooltip, UpdatedBy } from 'styleguide-v2';
import EditIcon from '@mui/icons-material/Edit';
import ReportIcon from '@mui/icons-material/Report';
import TuneIcon from '@mui/icons-material/Tune';

import { translate } from '@vestahealthcare/common/i18n';
import { IssueType } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

type Props = {
  issueTypes: IssueType[];
  onEdit: (issueType: IssueType) => void;
};

const getUrgencyColor = (urgencyLevel?: number) => {
  if (urgencyLevel === 1) {
    return Colors.textRed;
  }
  if (urgencyLevel === 2) {
    return Colors.gold;
  }
  if (urgencyLevel === 3) {
    return Colors.green;
  }
  return Colors.gray;
};

const EVENT_MAPPING_COLUMNS = ({ onEdit }: Props) => [
  { headerName: translate('events.mapping.issueType'), field: 'description' },
  {
    headerName: translate('events.eventMapping'),
    field: 'eventType.name',
  },
  {
    headerName: translate('events.urgency'),
    component: ({ urgent, urgencyLevel, urgencyRules }: IssueType) => (
      <div className="flex" style={{ justifyContent: 'center' }}>
        {(!!urgencyRules?.length || (urgent && urgencyLevel)) && (
          <Tooltip
            placement="right"
            text={
              urgencyLevel
                ? translate('memberEvents.table.urgencyLevel', {
                    count: urgencyLevel,
                  })
                : translate('memberEvents.table.urgent')
            }
          >
            {urgencyRules?.length ? (
              <TuneIcon
                fontSize="large"
                style={{ color: getUrgencyColor(urgencyLevel) }}
              />
            ) : (
              <ReportIcon
                fontSize="large"
                style={{ color: getUrgencyColor(urgencyLevel) }}
              />
            )}
          </Tooltip>
        )}
        {(!urgent || !urgencyLevel) && !urgencyRules?.length && EMPTY}
      </div>
    ),
    width: 50,
  },
  {
    headerName: translate('events.updated'),
    component: ({ updatedAt, updatedBy }: IssueType) => (
      <UpdatedBy date={updatedAt.unix()} user={updatedBy?.fullName} />
    ),
    width: 175,
  },
  {
    headerName: '',
    component: (issueType: IssueType) => (
      <IconButton size="small" onClick={() => onEdit(issueType)}>
        <EditIcon />
      </IconButton>
    ),
    width: 20,
  },
];

export const EventsMappingTable = (props: Props) => (
  <Table
    config={{
      columns: EVENT_MAPPING_COLUMNS(props),
      compact: true,
      data: props.issueTypes,
      paginationOptions: [-1],
    }}
  />
);

export default EventsMappingTable;
