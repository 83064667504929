import { Enum } from './Enum';

export class MeasurementFrequency extends Enum {
  translationKey = 'measurementFrequency';

  static asArray: MeasurementFrequency[] = [];

  static byKey: { [name: string]: MeasurementFrequency } = {};

  static readonly WEEKS = new MeasurementFrequency('WEEKS');

  static readonly DAYS = new MeasurementFrequency('DAYS', false);

  static readonly ONCE_PER_DAY = new MeasurementFrequency('ONCE_PER_DAY');

  static readonly TWICE_PER_DAY = new MeasurementFrequency('TWICE_PER_DAY');

  static readonly THREE_PER_DAY = new MeasurementFrequency('THREE_PER_DAY');

  constructor(
    public readonly value: string,
    public readonly selectable = true,
  ) {
    super();
    MeasurementFrequency.asArray.push(this);
    MeasurementFrequency.byKey[value] = this;
  }

  static toSelectable = () =>
    MeasurementFrequency.asArray
      .filter((val) => val.selectable)
      .map((val) => ({ value: val.valueOf(), label: val.toString() }));

  get isWeekly() {
    return this === MeasurementFrequency.WEEKS;
  }
}

export default MeasurementFrequency;
