import moment, { Moment } from 'moment';
import { DBEnum } from './DBEnum';
import { Employee } from './Employee';
import { make } from './Model';

export class CarePlanGroup {
  carePlanGroup?: DBEnum;

  createdAt?: Moment;

  createdBy?: Employee;

  updatedAt?: Moment;

  updatedBy?: Employee;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.carePlanGroup = make(obj.carePlanGroup, DBEnum);
    this.createdAt = obj.createdAt ? moment.unix(obj.createdAt) : undefined;
    this.createdBy = make(obj.createdBy, Employee);
    this.updatedAt = obj.updatedAt ? moment.unix(obj.updatedAt) : undefined;
    this.updatedBy = make(obj.updatedBy, Employee);
  }
}

export default CarePlanGroup;
