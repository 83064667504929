import { Enum } from './Enum';

export class ConversationStatus extends Enum {
  translationKey = 'conversationStatus';

  static asArray: ConversationStatus[] = [];

  static byKey: { [name: string]: ConversationStatus } = {};

  static readonly OPEN = new ConversationStatus('OPEN');

  static readonly CLOSED = new ConversationStatus('CLOSED');

  constructor(public readonly value: string) {
    super();
    ConversationStatus.asArray.push(this);
    ConversationStatus.byKey[value] = this;
  }

  static toSelectable = () => Enum.toSelectable(ConversationStatus.asArray);
}

export default ConversationStatus;
