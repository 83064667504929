import I18n from 'i18n-js';
import { merge } from 'lodash';
import moment from './moment';
import locales from './locales';
import { Language } from './enums/Language';

// Please, differenciate here Language from Locale.
// - Locale is the string used by plugins
// - Language is the EnumSymbol used everywhere by APIServer/Dash
export const DEFAULT_LANGUAGE = 'ENGLISH';
export const DEFAULT_LOCALE = 'en';

export const setLocale = (locale: string) => {
  I18n.locale = locale;

  if (moment.locales().includes(locale)) {
    moment.locale(locale);
  } else {
    moment.locale(DEFAULT_LOCALE);
  }
};

export const setLanguage = (language: any) => {
  const locale = language.locale || DEFAULT_LOCALE;
  setLocale(locale);
};

// This always will return a value, even if is the device who set this up.
export const getCurrentLocale = () => I18n.locale;

export const getLanguageFromLocale = (locale: string) =>
  Language.asArray.find((language) => language.locale === locale);

// This will return a value if the locale is mapped to some language.
export const getCurrentLanguage = () =>
  I18n.locale && getLanguageFromLocale(I18n.locale);

export const translate = (key: I18n.Scope, settings?: I18n.TranslateOptions) =>
  I18n.t(key, settings);

export const translateToArray = (
  key: I18n.Scope,
  settings?: I18n.TranslateOptions,
) => {
  const translated = I18n.t(key, settings);
  return Array.isArray(translated) ? (translated as string[]) : [translated];
};

// Actually is a camelCase converter
export const toLocaleKey = (str: string) =>
  str
    .replace(/([a-z0-9])([A-Z])/g, (s, s1, s2) => `${s1}-${s2}`)
    .toLowerCase()
    .replace(/[^a-z0-9][a-z0-9]/gi, (s) => s.substr(1).toUpperCase());

export const appendTranslations = (translations: any) => {
  I18n.translations = merge(I18n.translations || {}, translations);
};

// Setup I18njs
I18n.fallbacks = true; // Uses `en` as fallback
I18n.missingTranslation = (str) => `_${str}_`;
I18n.translations = { ...locales };
