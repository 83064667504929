import { CareQuestion } from './CareQuestion';
import { make } from './Model';

export class StructuredAnswer {
  answerName = '';

  humanReadableAnswer = '';

  englishHumanReadableAnswer = '';

  selected = false;

  linkedQuestion: CareQuestion;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.linkedQuestion = make(obj.linkedQuestion, CareQuestion);
  }
}

export default StructuredAnswer;
