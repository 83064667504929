import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  CareTeamPerson,
  CareTeamPersonExtended,
  Phone,
  UserPhone,
} from '@vestahealthcare/common/models';

import { Button, Fonts, PhoneInput, TextInput } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { fetchCareTeamPeople } from 'dash/src/services/CareTeamServices';

import { SearchTable } from '../../components/SearchTable';

type Props = {
  disabledPeople?: CareTeamPerson[];
  firstName?: string;
  lastName?: string;
  onSubmit: (ctp: CareTeamPersonExtended) => void;
  phone?: Phone;
};

const useStyles = makeStyles({
  container: {},
  searchButton: {
    alignSelf: 'end',
    height: 'fit-content',
    marginBottom: '0.25rem',
    width: '9rem',
  },
  addButon: {
    height: 'fit-content',
    width: 'fit-content',
  },
  separator: {
    margin: 'auto',
    width: '99%',
  },
  subtitle: {
    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: Fonts.fontSize,
      fontWeight: 500,
    },
  },
});

export const SearchCareTeamPersonStep = ({
  disabledPeople,
  firstName: firsrtNameParam,
  lastName: lastNameParam,
  onSubmit,
  phone: phoneParam,
}: Props) => {
  const styles = useStyles();

  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [searched, setSearched] = useState<boolean>(false);
  const [carePeople, setCarePeople] = useState<CareTeamPersonExtended[]>();

  const [firstName, setFirstName] = useState<string | undefined>(
    firsrtNameParam,
  );
  const [lastName, setLastName] = useState<string | undefined>(lastNameParam);
  const [phone, setPhone] = useState<string | undefined>(phoneParam?.number);
  const [searchedName, setSearchedName] = useState<string>();

  const getCarePeople = async () => {
    const carePeople = fetchCareTeamPeople({
      extended: true,
      firstName,
      lastName,
      phoneNumber: phone,
      limit: 25,
    });
    return carePeople;
  };

  const handleSearch = async () => {
    if (!firstName && !lastName && !phone) return;

    setSearchedName(
      firstName || lastName
        ? `${firstName}${firstName && lastName ? ' ' : ''}${lastName}`
        : phone,
    );
    setLoadingSearch(true);

    try {
      const { items } = await getCarePeople();
      setCarePeople(items as CareTeamPersonExtended[]);
    } catch (e) {
      showGlobalError(e as string);
    }

    setLoadingSearch(false);
    setSearched(true);
  };

  const createNewCTP = () => {
    onSubmit(
      new CareTeamPersonExtended({
        firstName,
        lastName,
        fullName: `${firstName} ${lastName}`,
        languages: [],
        phones: phone
          ? [new UserPhone({ phone: { number: phone }, primary: true })]
          : undefined,
        members: [],
      }),
    );
  };

  useEffect(() => {
    if (firsrtNameParam || lastNameParam || phoneParam) {
      handleSearch();
    }
  }, [firsrtNameParam, lastNameParam, phoneParam]);

  return (
    <div className={classNames(styles.container, 'grid-wrapper fit')}>
      <span className="grid-span-12">
        {translate('careTeam.steps.searchCarePerson')}
      </span>
      <TextInput
        className="grid-span-6"
        data-cy="care-team-steps-first-name"
        label={translate('careTeam.common.firstName')}
        onChange={(val) => setFirstName(val?.trim())}
        value={firstName}
      />
      <TextInput
        className="grid-span-6"
        data-cy="care-team-steps-last-name"
        label={translate('careTeam.common.lastName')}
        onChange={(val) => setLastName(val?.trim())}
        value={lastName}
      />
      <PhoneInput
        className="grid-span-10"
        data-cy="care-team-steps-phone"
        label={translate('careTeam.common.phoneNumber')}
        onChange={(val) => setPhone(val?.trim())}
        value={phone}
      />
      <Button
        className={classNames(styles.searchButton, 'grid-span-2')}
        color="secondary"
        disabled={!firstName && !lastName && !phone}
        loading={loadingSearch}
        icon={<SearchIcon />}
        onClick={handleSearch}
      >
        {translate('global.search')}
      </Button>

      {searched && (
        <>
          <br />
          <hr className={classNames(styles.separator, 'grid-span-12')} />
          <br />
          {carePeople?.length ? (
            <div className="grid-span-12">
              <p>
                {translate('careTeam.steps.showingResultsFor')}
                <b>{` "${searchedName}"`}</b>:
              </p>
              <SearchTable
                disabled={disabledPeople?.map(({ id }) => id)}
                people={carePeople}
                onSelect={onSubmit}
              />
              <br />
              <h4 className={styles.subtitle}>
                {translate('careTeam.steps.notAMatch')}
              </h4>
              <p>{translate('careTeam.steps.noCarePeopleFoundBody')}</p>
              <br />
              <Button
                className={classNames(styles.addButon, 'grid-span-12')}
                color="secondary"
                type="outlined"
                onClick={createNewCTP}
              >
                {translate('careTeam.steps.noCarePeopleFoundButton')}
              </Button>
            </div>
          ) : (
            <div className="grid-span-12">
              <h4 className={styles.subtitle}>
                {translate('careTeam.steps.noCarePeopleFoundTitle')}
              </h4>
              <p>{translate('careTeam.steps.noCarePeopleFoundBody')}</p>
              <br />
              <Button
                className={classNames(styles.addButon, 'grid-span-12')}
                color="secondary"
                type="outlined"
                onClick={createNewCTP}
              >
                {translate('careTeam.steps.noCarePeopleFoundButton')}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
