import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import { BiometricsConfig } from '@vestahealthcare/common/models';

import { Card, CardContent, Colors, Fonts } from 'styleguide-v2';
import { BiometricsType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { BIOMETRICS_ORDER } from 'dash/src/pages/MemberProfile/Biometrics';
import { Measurement } from '@vestahealthcare/common/models/Measurement';

const useStyles = makeStyles({
  extraInfoContainer: {
    gap: '5px',
  },
  extraInfoTitle: {
    color: Colors.textRed,
    fontSize: `calc(${Fonts.fontSize} * 1.25)`,
    fontWeight: 500,
  },
  smallTitle: {
    fontSize: Fonts.fontSize,
    fontWeight: 500,
  },
  entries: {
    color: Colors.textRed,
    fontWeight: 500,
    textAlign: 'end',
  },
});

type Props = {
  measurements?: Measurement[];
  configs?: BiometricsConfig[];
};

export const BiometricsTableAbnormalEntries = ({
  configs,
  measurements,
}: Props) => {
  const styles = useStyles();

  const [abnormalData, setAbnormalData] = useState<{ [key: string]: number }>(
    {},
  );

  const initAbnormalData = () => {
    const data = configs?.filter(Boolean)?.reduce((acc, config) => {
      const { lowerLimit, upperLimit, type: cType } = config;
      const abnormalEntries = measurements?.filter(
        ({ value, type }) =>
          type === cType &&
          ((lowerLimit && value < lowerLimit) ||
            (upperLimit && value > upperLimit)),
      );
      if (abnormalEntries?.length) {
        acc[cType.value] = abnormalEntries.length;
      }
      return acc;
    }, {} as { [key: string]: number });
    if (data && data[BiometricsType.BP_SYSTOLIC.value]) {
      data[BiometricsType.BP_DIASTOLIC.value] =
        (data[BiometricsType.BP_DIASTOLIC.value] || 0) +
        data[BiometricsType.BP_SYSTOLIC.value];
    }
    setAbnormalData(data || {});
  };

  useEffect(() => initAbnormalData(), [configs, measurements]);

  return Object.keys(abnormalData).length ? (
    <Card>
      <CardContent
        className={classnames('grid-wrapper', styles.extraInfoContainer)}
      >
        <span className={classnames(styles.extraInfoTitle, 'grid-span-12')}>
          {translate('biometrics.abnormalEntries')}
        </span>
        {BIOMETRICS_ORDER.map(
          (type) =>
            type !== BiometricsType.BP_SYSTOLIC &&
            abnormalData &&
            abnormalData[type.value] && (
              <>
                <span className={classnames('grid-span-6', styles.smallTitle)}>
                  {type === BiometricsType.BP_DIASTOLIC
                    ? translate('biometrics.bp')
                    : type.toString()}
                </span>
                <span className={classnames('grid-span-6', styles.entries)}>
                  {translate('biometrics.readings', {
                    count: abnormalData[type.value],
                  })}
                </span>
              </>
            ),
        )}
      </CardContent>
    </Card>
  ) : (
    <></>
  );
};

export default BiometricsTableAbnormalEntries;
