import { Enum } from './Enum';

export class EmployeeGroupStatus extends Enum {
  static asArray: EmployeeGroupStatus[] = [];

  static byKey: { [name: string]: EmployeeGroupStatus } = {};

  static readonly ACTIVE = new EmployeeGroupStatus('ACTIVE');

  static readonly INACTIVE = new EmployeeGroupStatus('INACTIVE');

  constructor(public readonly value: string) {
    super();
    EmployeeGroupStatus.asArray.push(this);
    EmployeeGroupStatus.byKey[value] = this;
  }
}

export default EmployeeGroupStatus;
