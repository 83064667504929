import {
  ConversationStatus,
  MessageStatus,
  MessageType,
  MessageUserType,
} from '@vestahealthcare/common/enums';
import {
  Conversation,
  Message,
  PhoneUser,
} from '@vestahealthcare/common/models';

import Api, { PaginatedResponse } from 'dash/src/services/Api';

export interface MessageResponse {
  id: number;
  createdAt: number;
  fromPhoneNumber: number;
  message: string;
  status: MessageStatus;
  toPhoneNumber: string;
  type: MessageType;
  userId?: number;
  userType?: MessageUserType;
}

export interface MessageBatchResponse {
  id: number;
  messages: MessageResponse[];
}

export const sendChat = async (
  ids: number[],
  type: MessageUserType,
  message: string,
): Promise<MessageBatchResponse> =>
  sendMessage('/messages/chat/batch', ids, type, message);

export const sendSMSBatch = async (
  ids: number[],
  type: MessageUserType,
  message: string,
): Promise<MessageBatchResponse> =>
  sendMessage('/messages/sms/batch', ids, type, message);

const sendMessage = async (
  endpoint: string,
  ids: number[],
  type: MessageUserType,
  message: string,
): Promise<MessageBatchResponse> => {
  const memberIds = type === MessageUserType.MEMBER ? ids : [];
  const caregiverIds = type === MessageUserType.CAREGIVER ? ids : [];
  const careTeamPersonIds = type === MessageUserType.CARE_TEAM ? ids : [];

  const response = await Api.postv2JSON(endpoint, {
    memberIds,
    caregiverIds,
    careTeamPersonIds,
    message,
  });
  return response;
};

export type SMSFilters = {
  caregiverId?: number[];
  careTeamPersonId?: number[];
  count?: boolean;
  createdAtFrom?: string;
  createdAtTo?: string;
  lastConversation?: boolean;
  limit?: number;
  memberId?: number[];
  offset?: number;
  read?: boolean;
  sort?: string;
  status?: string;
  userPhone?: string[];
};

export const getSMS = async (
  filters?: SMSFilters,
): Promise<PaginatedResponse<Message>> => {
  const {
    messages: { items, pagination },
  } = await Api.getv2('/messages', {
    ...filters,
    sort: 'createdAt desc, id desc',
  });
  return { items: items.map((item: any) => new Message(item)), pagination };
};

export const getSMSConversations = async (
  filters?: SMSFilters,
): Promise<PaginatedResponse<Conversation>> => {
  const {
    conversations: { items, pagination },
  } = await Api.getv2('/conversations', {
    lastConversation: true,
    ...filters,
  });
  return {
    items: items.map((item: any) => new Conversation(item)),
    pagination,
  };
};

export const getPhoneUsers = async (
  phoneNumber: string,
): Promise<PhoneUser[]> => {
  const {
    userPhones: { items },
  } = await Api.getv2(`/phones/${phoneNumber}/users`);
  return items.map((phoneUser: any) => new PhoneUser(phoneUser));
};

export const sendSMS = async (phoneNumber: string, message: string) =>
  await Api.postv2JSON('/messages/sms', { phoneNumber, message });

export const markConversationRead = async (
  conversationId: number,
  read: boolean,
) =>
  await Api.patchv2JSON(`conversations/${conversationId}`, {
    read,
  });

export const closeConversation = async (conversationId: number) =>
  await Api.patchv2JSON(`conversations/${conversationId}`, {
    status: ConversationStatus.CLOSED.value,
  });
