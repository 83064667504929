import classNames from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import { Icon } from 'styleguide-v2/src/components/Icon';
import { Colors } from 'styleguide-v2/src/styles/Colors';

type Props = {
  'data-cy'?: string;
  className?: string;
  count?: number;
  disabled?: boolean;
  filters?: Object;
  filtersCount?: number;
  label?: string;
  onClick: () => void;
};

const useStyles = makeStyles({
  green: {
    color: Colors.iconGreen,
  },
  disabled: {
    color: Colors.textGray,
    '&&': {
      cursor: 'not-allowed',
    },
  },
});

export const FilterButton = ({
  className,
  count,
  'data-cy': dataCy,
  disabled,
  filters,
  filtersCount,
  label,
  onClick,
}: Props) => {
  const styles = useStyles();

  const getFiltersCount = (f: Object) =>
    Object.values(f).reduce((acc, value) => {
      let result = 0;
      if (value instanceof Array) {
        result = value.length ? 1 : 0;
      } else if (typeof value === 'boolean') {
        result = 1;
      } else if (typeof value === 'number') {
        result = 1;
      } else {
        result = value !== undefined ? 1 : 0;
      }
      return Number(acc) + result;
    }, 0);

  const getCount = () => {
    if (disabled) return '';
    if (count) return count;
    if (filters) return getFiltersCount(filters);
    return '';
  };

  return (
    <button
      className={classNames(
        className,
        disabled ? styles.disabled : styles.green,
      )}
      data-cy={dataCy}
      disabled={disabled}
      onClick={() => onClick()}
      type="button"
    >
      <Icon className="fa fa-filter" color={disabled ? 'gray' : 'green'} />{' '}
      {label ||
        translate('global.filters', {
          count: filtersCount || getCount() || ' ',
        })}
    </button>
  );
};

export default FilterButton;
