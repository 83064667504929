import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  Address,
  CommunityResource,
  CommunityResourceSpeciality,
  MemberCommunityResource,
} from '@vestahealthcare/common/models';
import {
  isValidAddress,
  isValidMail,
  isValidPhone,
  isValidURL,
} from '@vestahealthcare/common/utils/format';

import { Button, CollapsableSidebar, Colors, Modal } from 'styleguide-v2';

import {
  CommunityResourceCreateParams,
  MemberCommunityResourceAddParams,
  fetchCommunityResources,
} from 'dash/src/services/CommunityResourcesServices';

import { InfoStep } from './InfoStep';
import { SearchCommunityReourceStep } from './SearchStep';

type Props = {
  disabledCRs?: CommunityResource[];
  editTitle?: boolean;
  open: boolean;
  onClose: () => void;
  onLink?: (
    params: CommunityResourceCreateParams,
    cr: CommunityResource,
    ctmParams?: MemberCommunityResourceAddParams,
  ) => void;
  onSubmit: (
    params: CommunityResourceCreateParams,
    cr?: CommunityResource,
    ctmParams?: MemberCommunityResourceAddParams,
  ) => void;
  showMemberCRFields?: boolean;
  showNotes?: boolean;
};

const TOTAL_STEPS = 2;

const useStyles = makeStyles({
  subtitle: {
    color: Colors.iconGray,
    fontSize: '1.25em',
    marginLeft: '1rem',
  },
});

const MEMBER_CR = {} as MemberCommunityResource;

export const CommunityResourceQuickAdd = ({
  disabledCRs = [],
  editTitle,
  onClose,
  onLink,
  onSubmit,
  open,
  showMemberCRFields,
  showNotes,
}: Props) => {
  const styles = useStyles();

  const [duplicateModalData, setDuplicateModalData] = useState<
    CommunityResource
  >();
  const [duplicateModalOpen, setDuplicateModalOpen] = useState<boolean>(false);

  const [currentStep, setStep] = useState<number>(1);

  const [cr, setCR] = useState<CommunityResource>();
  const [params, setParams] = useState<Partial<CommunityResourceCreateParams>>(
    {},
  );
  const [ctmParams, setCTMParams] = useState<
    Partial<MemberCommunityResourceAddParams>
  >();

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);

  const reset = () => {
    setCR(undefined);
    setLoadingSave(false);
    setParams({});
    setCTMParams({});
    setStep(1);
    setSubmitted(false);
    setDuplicateModalData(undefined);
    setDuplicateModalOpen(false);
  };

  useEffect(() => {
    if (open) {
      reset();
    } else {
      setDuplicateModalOpen(false);
    }
  }, [open]);

  const selectCommunityResource = (cr: CommunityResource) => {
    setCR(cr);
    setStep(2);
  };

  const handleBack = () => {
    setStep(1);
  };

  const checkAddress = ({
    address1,
    address2,
    city,
    state,
    zipCode: zip,
  }: Address) =>
    !(address1 || address2 || city || state || zip) ||
    isValidAddress({
      address1: address1 || '',
      address2: address2 || '',
      city: city || '',
      state,
      zipCode: zip || '',
    } as Address);

  const validate = (soft?: boolean) =>
    params.type &&
    params.specialities?.length &&
    params.name &&
    (soft || checkAddress(params.address || ({} as Address))) &&
    ((params.phone && (soft || isValidPhone(params.phone))) ||
      (params.fax && (soft || isValidPhone(params.fax)))) &&
    params.states?.filter(({ state }) => !!state)?.length &&
    (soft || !params.pocEmail || isValidMail(params.pocEmail)) &&
    (soft || !params.website || isValidURL(params.website)) &&
    (params.mltcPlanId ||
      !params.specialities?.find(
        ({ id }) => id === CommunityResourceSpeciality.MLTC_LTSS,
      ));

  const handleSubmit = async () => {
    await onSubmit(
      {
        status: 'ACTIVE',
        ...(params as Omit<CommunityResourceCreateParams, 'status'>),
      },
      cr,
      ctmParams as MemberCommunityResourceAddParams,
    );
    setSubmitted(false);
    onClose();
  };

  const handleLink = async () => {
    if (!onLink || !duplicateModalData) return;
    await onLink(
      {
        status: 'ACTIVE',
        ...(params as Omit<CommunityResourceCreateParams, 'status'>),
      },
      duplicateModalData,
      ctmParams as MemberCommunityResourceAddParams,
    );
    setSubmitted(false);
    onClose();
  };

  const checkDuplicates = async () => {
    setSubmitted(true);
    if (!validate()) return;

    setLoadingSave(true);
    const { items: crs } = await fetchCommunityResources({
      name: params.name,
      limit: 1,
      sort: 'status asc',
    });
    if (
      crs?.length &&
      crs[0].name.toLocaleLowerCase() === params.name?.toLocaleLowerCase()
    ) {
      setDuplicateModalData(crs[0]);
      setDuplicateModalOpen(true);
    } else {
      handleSave();
    }
  };

  const handleSave = async () => {
    setLoadingSave(true);
    try {
      await handleSubmit();
    } finally {
      setLoadingSave(false);
    }
  };

  return (
    <>
      <CollapsableSidebar
        onClose={onClose}
        open={open}
        size={650}
        title={
          <div>
            <h2>
              {cr?.id && editTitle
                ? `${translate('global.edit')} ${cr.name}`
                : translate('communityResources.modal.quickAdd.title')}
            </h2>
            <span className={styles.subtitle}>
              (
              {translate('communityResources.modal.quickAdd.steps', {
                current: currentStep,
                total: TOTAL_STEPS,
              })}
              )
            </span>
          </div>
        }
      >
        <CollapsableSidebar.Body>
          {currentStep === 1 && (
            <SearchCommunityReourceStep
              disabledCRs={disabledCRs}
              onSubmit={selectCommunityResource}
            />
          )}
          {cr && currentStep === 2 && (
            <InfoStep
              communityResource={cr}
              memberCommunityResource={
                showMemberCRFields ? MEMBER_CR : undefined
              }
              onChange={(ctp, ctm) => {
                setParams(ctp);
                setCTMParams(ctm);
              }}
              showNotes={showNotes}
              submitted={submitted}
            />
          )}
        </CollapsableSidebar.Body>
        <CollapsableSidebar.Buttons>
          {currentStep === 2 && (
            <>
              <Button
                color="tertiary"
                data-cy="community-resource-quick-add-back"
                onClick={handleBack}
              >
                {translate('global.back')}
              </Button>
              <Button
                color="secondary"
                data-cy="community-resource-quick-add-submit"
                disabled={!validate(true)}
                loading={loadingSave}
                onClick={cr?.id ? handleSave : checkDuplicates}
              >
                {translate('communityResources.modal.quickAdd.save')}
              </Button>
            </>
          )}
        </CollapsableSidebar.Buttons>
      </CollapsableSidebar>
      <Modal
        title={translate('global.warning')}
        body={translate('communityResources.modal.quickAdd.warningDuplicate', {
          communityResource: duplicateModalData?.name,
        })}
        onClose={() => {
          setLoadingSave(false);
          setDuplicateModalOpen(false);
        }}
        onSubmit={handleSave}
        onSubmitSecondary={onLink ? handleLink : undefined}
        submitText={translate('communityResources.modal.quickAdd.yesCreate')}
        submitSecondaryText={
          onLink
            ? translate('communityResources.modal.quickAdd.linkExisting')
            : undefined
        }
        open={duplicateModalOpen}
      />
    </>
  );
};
