import React from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

import { UserPhone } from '@vestahealthcare/common/models';

import { Fonts } from 'styleguide-v2/src/styles/Variables';
import { translate } from '@vestahealthcare/common/i18n';

type Props = {
  className?: string;
  'data-cy'?: string;
  userPhone: UserPhone;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  name: {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    fontWeight: 500,
  },
  extraInfo: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 400,
  },
});

export const SelectPhoneOption = ({
  className,
  'data-cy': dataCy,
  userPhone,
}: Props) => {
  const styles = useStyles();
  const classes = classnames(className, styles.container);
  const { phone } = userPhone;

  return (
    <div className={classes} data-cy={dataCy}>
      <span className={styles.name}>{phone.getDisplayNumber()}</span>
      <span className={styles.extraInfo}>
        {userPhone.primary && `${translate('global.primary')} - `}
        {phone.type?.toString()}
      </span>
    </div>
  );
};

export default SelectPhoneOption;
