import { BaseEnum } from './BaseEnum';

export class EngagementCampaignAudience extends BaseEnum {
  active = false;

  static MEMBER = 'MEMBER';

  get isMember() {
    return this.id === EngagementCampaignAudience.MEMBER;
  }
}

export default EngagementCampaignAudience;
