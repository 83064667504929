import React, { useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';
import moment from '@vestahealthcare/common/moment';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { DateTimePicker } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { updateBirthDate } from 'dash/src/services/PatientServices';

import { BaseModal } from './BaseModal';

interface Props {
  patient: Patient;
  onSubmit: (patient: Patient) => void;
}

const renderDetails = (patient: Patient) => (
  <>
    <h4 className="grid-span-5">{translate('personalDetails.birthDate')}</h4>
    <p className="grid-span-6" data-cy="personal-info-birthdate">
      {patient.birthDate
        ? moment(patient.birthDate).format('MMM Do, YYYY')
        : EMPTY}
    </p>
  </>
);

export const EditBirthDate = React.memo((props: Props) => {
  const { patient, onSubmit } = props;
  const editable = patient.isEditable() && patient.isDemographicsEditable();
  const [date, setDate] = useState<Date | undefined>(
    moment(patient.birthDate).toDate(),
  );
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    let result = true;

    setSubmitted(true);
    const currentDate = moment(date?.getTime());
    if (
      !date ||
      !currentDate.isValid() ||
      currentDate.isAfter(moment().startOf('day'))
    ) {
      return false;
    }
    setLoading(true);

    try {
      const newPatient = await updateBirthDate(patient.id, currentDate);
      onSubmit(newPatient);
      setSubmitted(false);
    } catch (e) {
      showGlobalError(e as string);
      result = false;
    }

    setLoading(false);
    return result;
  };

  return (
    <BaseModal
      details={renderDetails(patient)}
      data-cy="patient-birth-date"
      editable={editable}
      loadingSubmit={loading}
      onSubmit={submit}
      title={translate('personalDetails.birthDate')}
    >
      <DateTimePicker
        error={submitted && !date}
        label={translate('personalDetails.birthDate')}
        onChange={setDate}
        placeholder={translate('global.chooseADate')}
        required
        value={date}
      />
    </BaseModal>
  );
});

export default EditBirthDate;
