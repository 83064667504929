import { Enum } from './Enum';

export class CareTrackSource extends Enum {
  translationKey = 'riskProgramSource';

  static asArray: CareTrackSource[] = [];

  static byKey: { [name: string]: CareTrackSource } = {};

  static PAYER = new CareTrackSource('PAYER');

  static MEMBER = new CareTrackSource('MEMBER');

  static CLAIMS = new CareTrackSource('CLAIMS');

  static MDS = new CareTrackSource('MDS');

  static DISCHARGE_SUMMARY = new CareTrackSource('DISCHARGE_SUMMARY');

  static CARE_MANAGER = new CareTrackSource('CARE_MANAGER');

  static AIDE = new CareTrackSource('AIDE');

  static POC = new CareTrackSource('POC');

  constructor(public readonly value: string) {
    super();
    CareTrackSource.asArray.push(this);
    CareTrackSource.byKey[value] = this;
  }
}

export default CareTrackSource;
