import { Moment } from 'moment';

import { BaseEnum } from './BaseEnum';
import { make, makeMoment, makeUnix } from './Model';

export class TOCEvent {
  admitDate?: Moment;

  chiefComplaint = '';

  dischargeDate?: Moment;

  tocRecordClosedAt?: Moment;

  tocRecordId = 0;

  type: BaseEnum;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.admitDate = makeMoment(obj.admitDate);
    this.dischargeDate = makeMoment(obj.dischargeDate);
    this.tocRecordClosedAt = makeUnix(obj.tocRecordClosedAt);
    this.tocRecordId = obj.tocRecordId;
    this.type = make(obj.type, BaseEnum);
  }
}

export default TOCEvent;
