import React from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { CampaignModal } from '@vestahealthcare/common/models';

import {
  AddIcon,
  Button,
  ButtonModal,
  DeleteIcon,
  EditIcon,
  EmptyState,
  Icon,
  Table,
} from 'styleguide';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { deleteCampaignModal } from 'dash/src/services/CampaignServices';

interface Props {
  modals: CampaignModal[];
  onChange: () => void;
}

export const CampaignModals = ({ modals, onChange }: Props) => (
  <article>
    <header>
      <div className="h7">{translate('campaigns.modals')}</div>
      <div className="right-side">
        <Button
          color="secondary"
          data-cy="add-modal"
          href="/#/admin/campaigns/app-modals/modal"
          isPill
        >
          <AddIcon className="margin-right" />
          {translate('campaigns.addModal')}
        </Button>
      </div>
    </header>
    {modals.length ? (
      <CampaignModalsTable modals={modals} onChange={onChange} />
    ) : (
      <EmptyState>No modals added yet.</EmptyState>
    )}
  </article>
);

const CampaignModalsTable = ({ modals, onChange }: Props) => (
  <Table>
    <thead>
      <tr>
        <th>{translate('campaigns.name')}</th>
        <th>{translate('campaigns.languages')}</th>
        <th>{translate('campaigns.includedInBanner')}</th>
        <th>{translate('campaigns.showOnLogin')}</th>
        <th style={{ width: 80 }} />
      </tr>
    </thead>
    <tbody>
      {modals.map((modal) => (
        <tr key={modal.id} data-cy="modal-information">
          <td>{modal.name}</td>
          <td>{modal.getLanguages().join(', ')}</td>
          <td>
            {modal.banners.map((banner) => (
              <p key={banner.id}>{banner.name}</p>
            ))}
          </td>
          <td data-cy="login">
            {modal.showOnLogin && (
              <Icon className="fa fa-check" data-cy="show-on-login" />
            )}
          </td>
          <td>
            <a href={`/#/admin/campaigns/app-modals/modal/${modal.id}`}>
              <EditIcon tag="button" marginRight />
            </a>
            <ButtonModal
              iconComponent={<DeleteIcon />}
              modalTitle={translate('campaigns.confirmDeleteModalTitle')}
              onSubmit={async () => {
                try {
                  await deleteCampaignModal(modal.id);
                  onChange();
                  return Promise.resolve();
                } catch (err) {
                  showGlobalError(err as string);
                }
              }}
            >
              {translate('campaigns.confirmDeleteModalBody', {
                name: modal.name,
              })}
            </ButtonModal>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export default CampaignModals;
