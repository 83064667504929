import { Moment } from 'moment';

import { EncounterMethodOutcome, RPMAdherenceLevel } from '../enums';
import { ClinicalMember } from './ClinicalMember';
import { makeMoment } from './Model';

export class RPMMember extends ClinicalMember {
  daysRemaining = 0;

  lastAttemptDate?: Moment;

  lastAttemptOutcome?: EncounterMethodOutcome;

  lastReadingDate?: Moment;

  memberId = 0;

  pastCycles = 0;

  rpmDaysWithReadings = 0;

  recentMetCycles = 0;

  rpmMinutes = 0;

  rpmAdherence?: RPMAdherenceLevel;

  rpmStartDate?: Moment;

  constructor(obj: any) {
    super(obj);
    Object.assign(this, obj);
    this.lastAttemptDate = makeMoment(obj.lastAttemptDate);
    this.lastAttemptOutcome =
      EncounterMethodOutcome.byKey[obj.lastAttemptOutcome];
    this.lastReadingDate = makeMoment(obj.lastReadingDate);
    this.rpmAdherence = RPMAdherenceLevel.byKey[obj.rpmAdherence];
    this.rpmStartDate = makeMoment(obj.rpmStartDate);
  }
}

export default RPMMember;
