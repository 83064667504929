import moment from 'moment';
import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { ClassNameMap, makeStyles } from '@mui/styles';

import { EncounterMethod } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Encounter,
  PaginationType,
  Patient,
} from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Fonts, IconButton, Table, Tooltip, UpdatedBy } from 'styleguide-v2';

const useStyles = makeStyles({
  table: {
    '&& tbody': {
      '& td': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
      '& a': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
    },
  },
  tableNote: {
    display: 'block',
    maxWidth: 'calc(100vw - 105rem)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 'fit-content',
  },
  icon: {
    '&&': {
      fontSize: `calc(${Fonts.fontSize} * 0.875)`,
      padding: '0.25rem',
    },
  },
  validIcon: {
    '&&': {
      fontSize: `calc(${Fonts.fontSize} * 0.875)`,
      verticalAlign: 'middle',
      margin: '0 33%',
    },
  },
});

type Props = {
  patient: Patient;
  encounters: Encounter[];
  pagination: PaginationType;
  onEdit?: (encounter: Encounter) => void;
  onInvalidate?: (encounter: Encounter) => void;
  onShow?: (encounter: Encounter) => void;
  onChangePage: (page: number) => void;
  onChangePageSize: (page: number) => void;
};

const ENCOUNTER_COLUMNS = (
  { patient, onEdit, onInvalidate, onShow }: Props,
  {
    tableNote,
    icon,
    validIcon,
  }: ClassNameMap<'tableNote' | 'icon' | 'validIcon'>,
) => [
  {
    headerName: translate('encounters.method'),
    component: ({ method }: Encounter) => method?.toString() || EMPTY,
    width: 50,
  },
  {
    headerName: translate('encounters.typeReason'),
    component: ({ type, channel }: Encounter) =>
      `${type?.toString() || EMPTY} / ${channel?.toString() || EMPTY}`,
    width: 150,
  },
  {
    headerName: translate('encounters.valid'),
    component: ({ valid }: Encounter) =>
      valid ? (
        <CheckCircleIcon className={validIcon} color="primary" />
      ) : (
        <CancelIcon className={validIcon} color="error" />
      ),
    width: 50,
  },
  {
    headerName: translate('encounters.notes'),
    component: ({ note }: Encounter) =>
      note ? (
        <Tooltip text={note}>
          <span className={tableNote}>{note}</span>
        </Tooltip>
      ) : (
        EMPTY
      ),
  },
  {
    headerName: translate('encounters.createdBy'),
    component: ({ createdAt, createdBy }: Encounter) => (
      <UpdatedBy date={createdAt} user={createdBy?.fullName} />
    ),
    width: 150,
  },
  {
    headerName: translate('encounters.duration'),
    component: ({ duration }: Encounter) =>
      translate('encounters.minutes', { minutes: duration }),
    width: 100,
  },
  {
    headerName: translate('encounters.source'),
    component: ({
      eventId,
      method,
      taskId,
      skilledRecordId,
      tocRecordId,
    }: Encounter) => {
      let link = '';
      if (taskId) {
        link = `/#/tasks/${taskId}`;
      }
      if (method === EncounterMethod.CHAT) {
        link = `/#/chats?memberId=${patient.id}`;
      }
      if (eventId) {
        link = `/#/patients/${patient.id}/events/${eventId}`;
      }
      if (tocRecordId) {
        link = `/#/dashboard/toc/${tocRecordId}`;
      }
      if (skilledRecordId) {
        link = `/#/dashboard/skilled/${skilledRecordId}`;
      }
      if (link) {
        return (
          <a target="_blank" rel="noreferrer" href={link}>
            {translate('encounters.gotoSource')}
          </a>
        );
      }
    },
    width: 100,
  },
  {
    headerName: '',
    component: (encounter: Encounter) => {
      const { encounterDate, encounterDateTime, valid } = encounter;
      const canUpdateEncounter = moment(
        encounterDate || encounterDateTime,
      ).isAfter(moment().subtract(2, 'months'));

      return (
        <>
          {onEdit && canUpdateEncounter && valid && (
            <IconButton
              className={icon}
              onClick={() => onEdit(encounter)}
              size="small"
            >
              <EditIcon color="primary" />
            </IconButton>
          )}
          {onInvalidate && canUpdateEncounter && valid && (
            <IconButton
              className={icon}
              onClick={() => onInvalidate(encounter)}
              size="small"
            >
              <DeleteIcon color="primary" />
            </IconButton>
          )}
          {onShow && (
            <IconButton
              className={icon}
              onClick={() => onShow(encounter)}
              size="small"
            >
              <InfoIcon color="primary" />
            </IconButton>
          )}
        </>
      );
    },
    width: 100,
  },
];

const EncounterTable = (props: Props) => {
  const styles = useStyles();

  return (
    <Table
      className={styles.table}
      config={{
        columns: ENCOUNTER_COLUMNS(props, styles),
        compact: true,
        data: props.encounters,
        pagination: props.pagination,
      }}
      onChangePage={props.onChangePage}
      onChangeRowsPerPage={props.onChangePageSize}
    />
  );
};

export default EncounterTable;
