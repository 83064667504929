import moment from 'moment';

import { Employee, OrganizationStatuses, OrganizationType } from '.';
import { SelectableInfo } from '../enums/Enum';
import { make, makeArray } from './Model';

const VPCMA = 717;
const VPCNY = 718;
const VPCMA_CLIN = 719;
const VESTA_VPCMA_PERFHEALTH = 723;
const VESTA_VPCNY_5STAR = 724;

export class Organization {
  name = '';

  abbr?: string;

  parent?: Organization;

  children: Organization[];

  type?: OrganizationType;

  id = 0;

  status?: OrganizationStatuses;

  startDate?: string;

  endDate?: string;

  medicareDiscovery?: boolean;

  incidentAuto = false;

  incidentCareManagers = false;

  emails = [] as string[];

  updatedAt?: number;

  updatedBy?: Employee;

  brandId?: string;

  rpmOutsourcing = false;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.parent = obj.parent && make(obj.parent, Organization);
    this.children = makeArray(obj.children || [], Organization);
    this.type = obj.type && make(obj.type, OrganizationType);
    this.status = obj.status && make(obj.status, OrganizationStatuses);
    this.updatedBy = obj.updatedBy && make(obj.updatedBy, Employee);
  }

  get hasChildren(): boolean {
    return !!this.children.length;
  }

  get isActiveReferral(): boolean {
    // Referrals must be the lowest level in orgs hierarchy.
    return (
      !this.hasChildren &&
      (!this.endDate || moment(this.endDate).isAfter(moment()))
    );
  }

  get hasValidDate(): boolean {
    return !this.endDate || moment(this.endDate).isAfter(moment());
  }

  hasDescendant(childOrganization: number): boolean {
    return !!this.children.find(
      (child) =>
        child.id === childOrganization ||
        child.hasDescendant(childOrganization),
    );
  }

  hasDescendantByName(childOrganization: string): boolean {
    return !!this.children.find(
      (child) =>
        child.name === childOrganization ||
        child.hasDescendantByName(childOrganization),
    );
  }

  get isVPC() {
    return [
      VPCMA,
      VPCNY,
      VPCMA_CLIN,
      VESTA_VPCMA_PERFHEALTH,
      VESTA_VPCNY_5STAR,
    ].includes(this.id);
  }

  get isVPCVesta() {
    return [VESTA_VPCMA_PERFHEALTH, VESTA_VPCNY_5STAR].includes(this.id);
  }

  static toSelectable(o: Organization[]): SelectableInfo<Organization>[] {
    return o.map((info) => ({
      value: info.id,
      label: info.name,
      info,
    }));
  }
}

export default Organization;
