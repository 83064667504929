import { Enum } from './Enum';

export class MemberDashboardActionStatus extends Enum {
  translationKey = 'memberDashboardActionStatus';

  static asArray: MemberDashboardActionStatus[] = [];

  static byKey: { [name: string]: MemberDashboardActionStatus } = {};

  static readonly NOT_STARTED = new MemberDashboardActionStatus('Not Started');

  static readonly IN_PROGRESS = new MemberDashboardActionStatus('In Progress');

  static readonly COMPLETED = new MemberDashboardActionStatus('Completed');

  static readonly OVERDUE = new MemberDashboardActionStatus('Overdue');

  static readonly PRACTICE_QUERY = new MemberDashboardActionStatus(
    'Practice Query',
  );

  static readonly PRACTICE_REVIEWED = new MemberDashboardActionStatus(
    'Practice Reviewed',
  );

  static readonly CANCELLED = new MemberDashboardActionStatus('Cancelled');

  constructor(public readonly value: string) {
    super();
    MemberDashboardActionStatus.asArray.push(this);
    MemberDashboardActionStatus.byKey[value] = this;
  }

  static getColorByString(status: string) {
    switch (status) {
      case MemberDashboardActionStatus.NOT_STARTED.value:
        return 'info';
      case MemberDashboardActionStatus.OVERDUE.value:
        return 'error';
      case MemberDashboardActionStatus.COMPLETED.value:
        return 'success';
      case MemberDashboardActionStatus.CANCELLED.value:
        return 'closed';
      default:
        return 'warning';
    }
  }
}

export default MemberDashboardActionStatus;
