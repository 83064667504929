// @ts-ignore
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import DownloadIcon from '@mui/icons-material/Download';
import { makeStyles } from '@mui/styles';

import {
  CollectionMethod,
  Language,
  MemberContactMethodType,
  MemberRisk,
  MemberStatus,
  States,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { MemberView, PaginationType } from '@vestahealthcare/common/models';
import { LS_MEMBER_FILTERS } from '@vestahealthcare/common/utils/constants';

import {
  CheckboxWithLabel,
  FilterButton,
  IconButton,
  Panel,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { MemberViewFilters } from 'dash/src/components/MemberViewFilters';
import {
  MemberViewParams,
  downloadMemberExport,
  getMembersView,
} from 'dash/src/services/PatientServices';
import Session from 'dash/src/services/SessionServices';
import { getBoolean } from 'dash/src/utils/filterUtils';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { MembersDataTable } from './MembersDataTable';

const useStyles = makeStyles({
  checkbox: {
    '&& > div > label': {
      display: 'flex',
      flexFlow: 'row-reverse',
      margin: 0,
    },
  },
});

export const MembersDashboard = () => {
  const history = useHistory();
  const styles = useStyles();

  const getFilterValue = (
    query?: string[],
    storage?: string[],
    mapper?: (value: string) => any,
  ) => {
    let result: string[] = [];
    if (query?.length) {
      result = query;
    } else if (storage?.length) {
      result = storage;
    }
    return result.map(mapper || ((x) => x)).filter(Boolean);
  };

  const initFilters = () => {
    const query = useQueryParams();

    const filtersString = localStorage.getItem(LS_MEMBER_FILTERS) || '';
    const filters = qs.parse(filtersString) || {};
    const hasCareTeamString = query.get('hasCareTeam');
    const hasCareTeam =
      hasCareTeamString !== null ? hasCareTeamString === 'true' : undefined;
    filters.hasCareTeam = filters.hasCareTeam
      ? filters.hasCareTeam === 'true'
      : undefined;
    const emptyRiskLevelString = query.get('emptyRiskLevel');
    const emptyRiskLevel =
      emptyRiskLevelString !== null ? Boolean(emptyRiskLevelString) : undefined;
    const carePlanGroupString =
      query.get('carePlanGroupId') || filters.carePlanGroupId;
    const carePlanGroupId =
      carePlanGroupString !== null && carePlanGroupString !== undefined
        ? Number(carePlanGroupString)
        : undefined;

    return {
      brandId: getFilterValue(query.getAll('brandId'), filters.brandId),
      emptyRiskLevel: emptyRiskLevel || filters.emptyRiskLevel || undefined,
      id: getFilterValue(query.getAll('id'), filters.ids, Number) as number[],
      fullName: query.get('fullName') || filters.fullName || undefined,
      carePlanGroupId,
      hasCareTeam: hasCareTeam || filters.hasCareTeam,
      status: getFilterValue(
        query.getAll('status'),
        filters.status,
        (key) => MemberStatus.byKey[key],
      ) as MemberStatus[],
      state: getFilterValue(
        query.getAll('state'),
        filters.state,
        (key) => States.byKey[key],
      ) as MemberStatus[],
      language: getFilterValue(
        query.getAll('language'),
        filters.language,
        (key) => Language.byKey[key],
      ) as Language[],
      rnOwnerId: getFilterValue(
        query.getAll('rnOwnerId'),
        filters.rnOwnerId,
        Number,
      ) as number[],
      npOwnerId: getFilterValue(
        query.getAll('npOwnerId'),
        filters.npOwnerId,
        Number,
      ) as number[],
      careCoordinatorId: getFilterValue(
        query.getAll('careCoordinatorId'),
        filters.careCoordinatorId,
        Number,
      ) as number[],
      engagementOwnerId: getFilterValue(
        query.getAll('engagementOwnerId'),
        filters.engagementOwnerId,
        Number,
      ) as number[],
      excludedReferralSourceId: getFilterValue(
        query.getAll('excludedReferralSourceId'),
        filters.excludedReferralSourceId,
        Number,
      ) as number[],
      hciReporter: getBoolean(query, filters, 'hciReporterMethod'),
      hciReporterMethod: getFilterValue(
        query.getAll('hciReporterMethod'),
        filters.hciReporterMethod,
        (item) => MemberContactMethodType.byKey[item],
      ) as MemberContactMethodType[],
      hciReporterStatus: getFilterValue(
        query.getAll('hciReporterStatus'),
        filters.hciReporterStatus,
      ) as string[],
      healthCoachId: getFilterValue(
        query.getAll('healthCoachId'),
        filters.healthCoachId,
        Number,
      ) as number[],
      podManagerId: getFilterValue(
        query.getAll('podManagerId'),
        filters.podManagerId,
        Number,
      ) as number[],
      programExtensionId: getFilterValue(
        query.getAll('programExtensionId'),
        filters.programExtensionId,
        Number,
      ) as number[],
      programExtensionStatus: getFilterValue(
        query.getAll('programExtensionStatus'),
        filters.programExtensionStatus,
      ) as string[],
      riskLevel: getFilterValue(
        query.getAll('riskLevel'),
        filters.riskLevel,
        (key) => MemberRisk.byKey[key],
      ) as MemberRisk[],
      referralSourceId: getFilterValue(
        query.getAll('referralSourceId'),
        filters.referralSourceId,
        Number,
      ) as number[],
      vitalCollectionMethod: getFilterValue(
        query.getAll('vitalCollectionMethod'),
        filters.vitalCollectionMethod,
        (key) => CollectionMethod.byKey[key],
      ) as CollectionMethod[],
    };
  };

  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [members, setMembers] = useState<MemberView[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();
  const [filters, setFilters] = useState<MemberViewParams>(initFilters());
  const [downloadingStatus, setDownloadingStatus] = useState<boolean>();
  const [downloadExtendedReport, setDownloadExtendedReport] = useState<boolean>(
    false,
  );

  const { actingUser } = Session;
  const showDownloadExport =
    actingUser.isInAdmin ||
    actingUser.isInBizOps ||
    actingUser.isInClinicalLeadership ||
    actingUser.isInEligibility;

  const fetchPatients = async () => {
    setLoading(true);
    try {
      const { items, pagination } = await getMembersView({
        ...filters,
        offset: page * pageSize,
        limit: pageSize,
        sort: 'fullName asc, id asc',
      });
      setPagination(pagination);
      if (page !== 0) {
        setMembers([...members, ...items]);
      } else {
        setMembers(items);
      }
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPatients();
  }, [filters, page, pageSize]);

  useEffect(() => {
    const {
      hciReporterMethod,
      language,
      programExtensionStatus,
      riskLevel,
      state,
      status,
      vitalCollectionMethod,
      ...rest
    } = filters;

    const flatFilters = {
      hciReporterMethod: hciReporterMethod?.map(({ value }) => value),
      state: state?.map(({ value }) => value),
      status: status?.map(({ value }) => value),
      language: language?.map(({ value }) => value),
      programExtensionStatus,
      riskLevel: riskLevel?.map(({ value }) => value),
      vitalCollectionMethod: vitalCollectionMethod?.map(({ value }) => value),
      ...rest,
    };
    const query = qs.stringify(flatFilters, { arrayFormat: 'repeat' });
    history.replace(`?${query}`);

    localStorage.setItem(LS_MEMBER_FILTERS, qs.stringify(flatFilters));
  }, [filters]);

  return (
    <Panel id="referrals-section">
      <Panel.Heading title={translate('members.title')}>
        <FilterButton
          className="filters"
          data-cy="members-filters"
          filters={filters}
          onClick={() => setOpen(!isOpen)}
        />
        {showDownloadExport && (
          <Panel.Actions>
            <CheckboxWithLabel
              className={styles.checkbox}
              label={translate('members.extendedReport')}
              checked={downloadExtendedReport || false}
              onChange={() => {
                setDownloadExtendedReport(!downloadExtendedReport);
              }}
            />
            <IconButton
              data-cy="member-export"
              tooltip={translate('members.downloadMembersFile')}
              loading={downloadingStatus}
              onClick={async () => {
                setDownloadingStatus(true);
                await downloadMemberExport(filters, downloadExtendedReport);
                setDownloadingStatus(false);
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Panel.Actions>
        )}
        <Panel.Collapse data-cy="members-filters-collapsible" open={isOpen}>
          <div className="grid-wrapper fit">
            <MemberViewFilters
              filters={filters}
              onChange={(filters) => {
                setPage(0);
                setFilters(filters);
              }}
            />
          </div>
        </Panel.Collapse>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <MembersDataTable
          members={members}
          pagination={pagination}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
        />
      </Panel.Body>
    </Panel>
  );
};

export default MembersDashboard;
