import moment from 'moment';

// datetime formats
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_LONG = 'dddd, MMMM D';
export const DATE_FORMAT_MIN = 'MMM D';
export const DATE_FORMAT_SHORT = 'M/D/YYYY';
export const DATE_FORMAT_SHORT_WITH_TIME = 'M/D/YYYY [at] h:mma';
export const DATE_TIME_FORMAT = 'MMM Do, YYYY [at] h:mma';
export const EXPORT_DATE_FORMAT = 'MM-DD-YYYY';
export const NO_TIME_DATE_FORMAT = 'MMM DD, YYYY';
export const NO_TIME_DATE_FORMAT_DO = 'MMM Do, YYYY';
export const NO_DAY_NO_TIME_DATE_FORMAT_DO = 'MMM, YYYY';
export const SHORT_HOUR_MIN_FORMAT = 'h:mma';
export const TIME_FORMAT = 'h:mm A';
export const TWELVE_HOUR_TIME_FORMAT = 'h:mm';
export const UPLOAD_DATE_FORMAT = 'YYYY-MM-DD hh:mma';
export const LOCAL_TIME_FORMAT = 'HH:mm';
export const SERVER_DATE_FORMAT_ZONED = 'YYYY-MM-DDZ';
export const SERVER_DATE_TIME_FORMAT_ZONED = 'YYYY-MM-DDThh:mm:ssZ';

// units
export const BLOOD_PRESSURE = 'mmHg';
export const POUNDS = 'lbs';
export const FAHRENHEIT = 'F';
export const BLOOD_GLUCOSE = 'mg/dl';
export const BLOOD_OXYGEN = '% SpO2';
export const HEART_RATE = 'bpm';

// common constants
export const EMPTY = '—';
export const N_A = 'N/A';

// routes
export const DASH_HOME = '/#/';

// local storage
export const TOKEN_MARKER = 'hasToken';
export const IN_FLIGHT_AUTH_CODE = 'authCode';
export const IS_INTEGRATION_TESTS = 'isIntegrationTests';

export const LS_ADMIN_CARE_TEAM_FILTERS = 'admin-care-team-filters';
export const LS_ADMIN_INCIDENTS = 'admin-incidents';
export const LS_DASHBOARD_CCM_FILTERS = 'dashboard-ccm-filters';
export const LS_DASHBOARD_CCM_FILTERS_V2 = 'dashboard-ccm-filters-v2';
export const LS_DASHBOARD_CLINICAL_SUMMARY_FILTERS = 'member-clinical-summary';
export const LS_DASHBOARD_EVENT_FILTERS = 'dashboard-event-filters';
export const LS_DASHBOARD_EVENT_FILTERS_V2 = 'dashboard-event-filters-v2';
export const LS_DASHBOARD_TASK_FILTERS = 'dashboard-task-filters';
export const LS_DASHBOARD_TASK_FILTERS_V2 = 'dashboard-task-filters-v2';
export const LS_DASHBOARD_TOC_FILTERS_V2 = 'dashboard-toc-filters-v2';
export const LS_ENCOUNTER_FILTERS = 'member-encounter-filters';
export const LS_IMPERSONATOR = 'impersonator';
export const LS_INTENDED_PATH = 'intendedPath';
export const LS_IPAD_ID = 'ipadId';
export const LS_MEMBER_EVENTS_FILTERS = 'member-event-filters';
export const LS_MEMBER_FILTERS = 'member-filters';
export const LS_MEMBER_TASK_FILTERS = 'member-task-filters';
export const LS_POD_REPORTING_FILTERS = 'pod-reporting-filters';
export const LS_POD_REPORTING_FILTERS_V2 = 'pod-reporting-filters-v2';
export const LS_REMOTE_BACKEND = 'REMOTE_BACKEND';
export const LS_TEST_PATIENT = 'testPatient';

// release dates
export const INCIDENTS_RELEASE = moment('2024-03-11');
export const TOC_RELEASE = moment('2024-03-11');

// special ids
export const UNASSIGNED_ID = 0;

// Test entity ids
export const TEST_RN_ID = 188;
export const TEST_IPAD_ID = localStorage.getItem(LS_IPAD_ID);
export const TEST_PATIENT_ID =
  Number(localStorage.getItem(LS_TEST_PATIENT)) || 3454;
