import React from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { Table } from 'styleguide-v2';
import { MemberAllergy } from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

interface Props {
  allergies: MemberAllergy[];
}

const ALLERGIES_COLUMNS = () => [
  {
    headerName: translate(
      'memberProfile.clinicalSummary.tables.allergies.agent',
    ),
    field: 'agent',
  },
  {
    headerName: translate(
      'memberProfile.clinicalSummary.tables.allergies.reaction',
    ),
    field: 'reaction',
  },
  {
    headerName: translate(
      'memberProfile.clinicalSummary.tables.allergies.onsetDate',
    ),
    component: ({ onsetDate }: MemberAllergy) =>
      onsetDate?.format(DATE_FORMAT_SHORT) || EMPTY,
  },
  {
    headerName: translate(
      'memberProfile.clinicalSummary.tables.allergies.status',
    ),
    component: ({ status }: MemberAllergy) => (
      <span className="capitalize">{status}</span>
    ),
  },
];

export const AllergiesTable = (props: Props) => (
  <Table
    config={{
      columns: ALLERGIES_COLUMNS(),
      compact: true,
      data: props.allergies,
      paginationOptions: [-1],
    }}
  />
);

export default AllergiesTable;
