import React, { useEffect, useState } from 'react';
import { List, arrayMove } from 'react-movable';

import { makeStyles } from '@material-ui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import { DeleteIcon, EditIcon } from 'styleguide/src/components/Icons';
import ButtonModal from 'styleguide/src/components/Modal/ButtonModal';
import Colors from 'styleguide/src/styles/Colors';

export type SortableData = {
  name: string;
};

type CheckType = boolean | ((item: SortableData) => boolean);

type Props = {
  className?: string;
  color?: 'primary' | 'secondary';
  'data-cy'?: string;
  getLabel?: (item: SortableData) => string;
  isEditable?: CheckType;
  isRemovable?: CheckType;
  items: SortableData[];
  onChange: (items: SortableData[]) => void;
  onEditItem?: (item: SortableData) => void;
  placeholder?: string;
  submitted?: boolean;
  confirmRemove?: boolean;
  confirmRemoveText?: string;
};

const useStyles = makeStyles({
  row: {
    background: ({ color }: Props) =>
      color === 'secondary' ? Colors.white : Colors.lightGray,
    display: 'flex',
    margin: '5px 0',
    padding: '5px',
    borderRadius: '5px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 1px 2px 0',
  },
  icon: {
    color: Colors.gray,
    width: '20px',
    margin: 'auto 5px',
    padding: '5px',
  },
  input: {
    alignItems: 'center',
    border: 0,
    background: ({ color }: Props) =>
      color === 'secondary' ? Colors.white : Colors.lightGray,
    display: 'inline-flex',
    flex: 'auto',
    height: '34px',
  },
  inputError: {
    border: `${Colors.red} 2px solid`,
    borderRadius: '5px',
  },
});

export const SortableList = (props: Props) => {
  const styles = useStyles(props);
  const {
    className,
    'data-cy': dataCy,
    getLabel,
    items,
    isEditable,
    isRemovable,
    onChange,
    onEditItem,
    placeholder,
    submitted,
    confirmRemove,
    confirmRemoveText,
  } = props;
  const [list, setList] = useState<SortableData[]>(items || []);

  useEffect(() => {
    setList([...items]);
  }, [items]);

  const removeItem = (index?: number) => {
    list.splice(index || 0, 1);
    setList([...list]);
    onChange([...list]);
  };

  const check = (check?: CheckType, item?: SortableData) => {
    if (!check) return false;
    if (typeof check === 'boolean') {
      return check;
    }
    if (item) {
      return check(item);
    }
    return false;
  };

  return (
    <List
      lockVertically
      values={list}
      onChange={({ oldIndex, newIndex }) => {
        setList([...arrayMove(list, oldIndex, newIndex)]);
        onChange([...arrayMove(list, oldIndex, newIndex)]);
      }}
      renderList={({ children, props }) => (
        <div data-cy={dataCy} className={className} {...props}>
          {children}
        </div>
      )}
      renderItem={({ value, props, isDragged, index = 0 }) => {
        const row = (
          <div {...props} className={styles.row}>
            <i
              className={`${styles.icon} fa fa-bars`}
              style={{
                cursor: isDragged ? 'grabbing' : 'grab',
              }}
            />
            {isEditable ? (
              <input
                autoComplete="disabled"
                className={`${styles.input} ${
                  submitted && !value.name ? styles.inputError : ''
                }`}
                placeholder={placeholder || translate('global.name')}
                onChange={(e) => {
                  const name = e.target.value || '';
                  const newList = [...list];
                  newList[index] = { ...newList[index], name };
                  setList([...newList]);
                  onChange([...newList]);
                }}
                value={value.name}
                type="text"
              />
            ) : (
              <span className={styles.input}>
                {getLabel
                  ? getLabel(value)
                  : value.name || placeholder || translate('global.name')}
              </span>
            )}
            {onEditItem && (
              <EditIcon
                className={`grid-span-1 ${styles.icon}`}
                tag="button"
                onClick={() => onEditItem(value)}
              />
            )}
            {check(isRemovable, value) &&
              (confirmRemove ? (
                <ButtonModal
                  iconComponent={<DeleteIcon tag="span" />}
                  modalTitle={translate('global.confirmRemove')}
                  onSubmit={() => removeItem(index)}
                >
                  {confirmRemoveText}
                </ButtonModal>
              ) : (
                <DeleteIcon
                  className={`grid-span-1 ${styles.icon}`}
                  tag="button"
                  onClick={() => removeItem(index)}
                />
              ))}
          </div>
        );
        return isDragged ? (
          <div
            // eslint-disable-next-line react/prop-types
            style={{ ...props.style, borderSpacing: 5 }}
          >
            {row}
          </div>
        ) : (
          row
        );
      }}
    />
  );
};

export default SortableList;
