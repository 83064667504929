import moment, { Moment } from 'moment';

import { ConsumerRole, DeviceOS } from '../enums';
import { Employee } from './Employee';
import { make } from './Model';
import { Phone } from './Phone';

export class AppUser {
  authenticationPhone: Phone;

  phoneNumberVerificationCode = '';

  phoneNumberVerificationCodeExpiration?: Moment;

  lastLoginAt?: Moment;

  lastLoginConsumer?: ConsumerRole;

  invitationSentAt?: Moment;

  invitationSentBy?: Employee;

  inviteOS?: DeviceOS;

  lastLoginDevice?: {
    name: string;
    type: string;
    os: string;
    osVersion?: string;
    appVersion: string;
  };

  constructor(obj: any) {
    Object.assign(this, obj);
    this.authenticationPhone = make(obj.authenticationPhone, Phone);
    this.phoneNumberVerificationCodeExpiration = obj.phoneNumberVerificationCodeExpiration
      ? moment.unix(obj.phoneNumberVerificationCodeExpiration)
      : undefined;
    this.lastLoginAt = obj.lastLoginAt
      ? moment.unix(obj.lastLoginAt)
      : undefined;
    this.lastLoginConsumer = ConsumerRole.byKey[obj.lastLoginConsumer?.role];
    this.invitationSentAt = obj.invitationSentAt
      ? moment.unix(obj.invitationSentAt)
      : undefined;
    this.invitationSentBy = make(obj.invitationSentBy, Employee);
    this.inviteOS = DeviceOS.byKey[obj.inviteOS];
  }
}

export default AppUser;
