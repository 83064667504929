import classnames from 'classnames';
import moment from 'moment';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { Brand } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { Conversation } from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  EMPTY,
  TIME_FORMAT,
} from '@vestahealthcare/common/utils/constants';

import { BackgroundColors, Colors, Icon, Table } from 'styleguide-v2';
import { BrandColors } from 'styleguide-v2/src/styles/Colors';

import FromPhone from './FromPhone';
import FromUser from './FromUser';

const COLUMNS = (showFrom?: boolean, patientId?: number) => {
  const columns = [];
  if (showFrom) {
    columns.push({
      headerName: translate('chat.from'),
      component: (c: Conversation, index: number) => (
        <FromUser
          data-cy={`sms-conversations-from-${index}`}
          userPhones={c.userPhones}
          unread={c.isUnread()}
        />
      ),
      width: 175,
    });
  }
  return [
    ...columns,
    {
      headerName: translate('chat.phoneNumber'),
      component: (c: Conversation, index: number) => (
        <FromPhone
          data-cy={`sms-conversations-phone-${index}`}
          userPhones={
            patientId
              ? c.userPhones.filter(({ user }) => user.id === patientId)
              : c.userPhones
          }
          userPhoneNumber={c.userPhone}
          unread={c.isUnread()}
          showSubtitle={!showFrom}
        />
      ),
      width: showFrom ? 140 : 175,
    },
    {
      headerName: translate('chat.message'),
      dataCy: 'sms-conversations-message',
      field: 'lastMessage.message',
    },
    {
      headerName: translate('chat.date'),
      dataCy: 'sms-conversations-date',
      component: ({ lastMessage }: Conversation) =>
        lastMessage
          ? moment
              .unix(lastMessage?.createdAt)
              .format(`${DATE_FORMAT_SHORT} ${TIME_FORMAT}`)
          : EMPTY,
      width: 170,
    },
    {
      dataCy: 'sms-conversations-icon',
      component: (c: Conversation) => (
        <Icon
          className="fa fa-chevron-right"
          size="xs"
          color={c.isUnread() ? 'green' : 'gray'}
        />
      ),
      width: 50,
    },
  ];
};

const useStyles = makeStyles({
  unread: {
    backgroundColor: `${BackgroundColors.lightMint}!important`,
    fontWeight: '500!important',
  },
  open: {
    '&:first-child': {
      borderLeft: `3px solid ${Colors.iconGreen}`,
    },
  },
  close: {
    '&:first-child': {
      borderLeft: `3px solid ${Colors.gray}`,
    },
  },
  ellipsis: {
    maxWidth: '10rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  rowCAH: {
    '&&': {
      backgroundColor: BrandColors.careAtHome.tableRow,
    },
  },
  openCAH: {
    '&:first-child': {
      borderLeft: `3px solid ${BrandColors.careAtHome.tableBorder}`,
    },
  },
});

type Props = {
  items: Conversation[];
  loading: boolean;
  onClickRow: (item: Conversation) => void;
  patientId?: number;
  showFrom?: boolean;
};

export const SMSTable = ({
  items,
  loading,
  onClickRow,
  patientId,
  showFrom,
}: Props) => {
  const styles = useStyles();
  const getRowClass = (c: Conversation) => {
    const isCAH =
      c.userPhones.findIndex(
        ({ brand }) => brand?.value === Brand.CARE_AT_HOME.value,
      ) !== -1;

    return classnames(
      styles.ellipsis,
      c.isUnread() && (isCAH ? styles.rowCAH : styles.unread),
      c.isClosed() && styles.close,
      !c.isClosed() && (isCAH ? styles.openCAH : styles.open),
    );
  };

  return (
    <Table
      bottomLoading={loading}
      config={{
        columns: COLUMNS(showFrom, patientId),
        data: items,
        noItemsLabel: translate('chat.noConversationsFound'),
        size: 'l',
        getRowBrand: (c: Conversation) => {
          const isCAH =
            c.userPhones.findIndex(
              ({ brand }) => brand?.value === Brand.CARE_AT_HOME.value,
            ) !== -1;
          return isCAH ? Brand.CARE_AT_HOME : Brand.VESTA;
        },
        getRowClass,
        paginationOptions: [-1],
      }}
      data-cy="sms-conversations"
      onClickRow={onClickRow}
    />
  );
};

export default SMSTable;
