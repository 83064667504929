import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import { Enum, Language } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import TaskStatus from '@vestahealthcare/common/enums/TaskStatus';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  PaginationType,
} from '@vestahealthcare/common/models';
import TaskDefinition from '@vestahealthcare/common/models/TaskDefinition';
import { LS_MEMBER_TASK_FILTERS } from '@vestahealthcare/common/utils/constants';

import {
  Button,
  FilterButton,
  Panel,
  Select,
  Spinner,
  Tabs,
  ToggleDateRange,
  ToggleDateRangeHandle,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import AddTaskButton from 'dash/src/pages/Tasks/AddTaskButton';
import TaskCompleteDetails from 'dash/src/pages/Tasks/TaskCompleteDetails';
import { useSelector } from 'dash/src/redux/store';
import { CacheServices } from 'dash/src/services';
import Session from 'dash/src/services/SessionServices';
import {
  editTask,
  fetchAllTasks,
  fetchTask,
} from 'dash/src/services/TaskServices';
import {
  transformDateToDaysRange,
  transformDaysRangeToDates,
} from 'dash/src/utils/dateUtils';
import {
  getAsQuery,
  getCustom,
  getDateRange,
  getStoredFilters,
  saveStoredFilters,
} from 'dash/src/utils/filterUtils';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { fetchPatientWarnings } from '../../../redux/slices/MemberInfoSlice';
import MemberTaskTable, { TaskTable } from './MemberTaskTable';

const INITIAL_PAGE_SIZE = 50;
const DEFAULT_SORT = 'dueDate asc, position asc';

const UNASSIGNED = new Employee({ fullName: translate('global.unassigned') });

const TASKS_PAGE_TAB_ASSIGNED_ME = 'tab-assigned';
const TASKS_PAGE_TAB_OPEN = 'tab-open';
const TASKS_PAGE_TAB_CLOSED = 'tab-closed';
const TASKS_PAGE_TAB_ALL = 'tab-all';

const TASK_PAGE_TABS = [
  {
    label: translate('clinicalDashboard.tasks.tabs.all'),
    value: TASKS_PAGE_TAB_ALL,
  },
  {
    label: translate('clinicalDashboard.tasks.tabs.open'),
    value: TASKS_PAGE_TAB_OPEN,
  },
  {
    label: translate('clinicalDashboard.tasks.tabs.closed'),
    value: TASKS_PAGE_TAB_CLOSED,
  },
  {
    label: translate('clinicalDashboard.tasks.tabs.assigned'),
    value: TASKS_PAGE_TAB_ASSIGNED_ME,
  },
];

const useStyles = makeStyles({
  check: {
    alignSelf: 'center',
  },
  search: {
    alignSelf: 'center',
    minWidth: '45rem',
  },
  filtersContainer: {
    display: 'flex',
    gap: '0.5rem 2rem',
    '&&': {
      marginTop: '1.5rem',
    },
  },
});

type TaskFilters = {
  assignee?: number[];
  createdBy?: number[];
  createdAtFrom?: number;
  createdAtTo?: number;
  group?: number[];
  language?: Language[];
  organization?: number[];
  queryString?: string;
  status?: TaskStatus[];
  selectedTab?: string;
  subTasksOnly: boolean;
  topLevelOnly: boolean;
  type?: number[];
};

const getFilters = (query: URLSearchParams) => {
  const filters = getStoredFilters(LS_MEMBER_TASK_FILTERS);
  const defaultCreatedDateRange =
    getDateRange(query, filters, 'createdDateRange') || -1;
  const createdAt = transformDaysRangeToDates(defaultCreatedDateRange);

  return {
    assignee: getCustom(query.getAll('assignee'), filters.assignee, (item) =>
      Number(item),
    ) as number[],
    createdBy: getCustom(query.getAll('createdBy'), filters.createdBy, (item) =>
      Number(item),
    ) as number[],
    createdAtFrom: createdAt[0]?.getTime(),
    createdAtTo: createdAt[1]?.getTime(),
    defaultCreatedDateRange,
    group: getCustom(query.getAll('group'), filters.group, (item) =>
      Number(item),
    ) as number[],
    selectedTab:
      query.get('selectedTab') || filters.selectedTab || TASKS_PAGE_TAB_OPEN,
    sort: query.get('sort') || filters.sort || DEFAULT_SORT,
    status: getCustom(
      query.getAll('status'),
      filters.status,
      (item) => TaskStatus.byKey[item],
    ) as TaskStatus[],
    type: getCustom(query.getAll('type'), filters.type, (item) =>
      Number(item),
    ) as number[],
    subTasksOnly: false,
    topLevelOnly: true,
  };
};

type Props = {
  memberId?: string;
  taskId?: string;
};

export const ClinicalTasks = ({
  match: {
    params: { memberId, taskId },
  },
}: RouteComponentProps<Props>) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQueryParams();
  const styles = useStyles();

  const member = useSelector((state) => state.memberInfoSlice.patient);

  const [loading, setLoading] = useState<boolean>(false);
  const [isOpenFilters, setOpenFilters] = useState<boolean>(false);

  const [types, setTypes] = useState<TaskDefinition[]>([]);
  const [groups, setGroups] = useState<EmployeeGroup[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);

  const refCreated = useRef<ToggleDateRangeHandle>(null);

  const {
    selectedTab: st,
    sort: defaultSort,
    defaultCreatedDateRange: dCreated,
    ...defaultFilters
  } = getFilters(query);

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(INITIAL_PAGE_SIZE);
  const [pagination, setPagination] = useState<PaginationType>();
  const [sort, setSort] = useState<string>(defaultSort);
  const [defaultCreatedDateRange, setDefaultCreatedDateRange] = useState<
    number
  >(dCreated);

  const [selectedTab, setSelectedTab] = useState<string>(st);
  const [filters, setFilters] = useState<TaskFilters>(defaultFilters);
  const {
    topLevelOnly,
    subTasksOnly,
    createdAtFrom,
    createdAtTo,
    ...countFilters
  } = filters;
  const [tasks, setTasks] = useState<TaskTable[]>([]);

  const getTabFilters = (tab: string) => {
    const commonFilters = {
      assigneeId: [],
      createdAtFrom: filters?.createdAtFrom
        ? moment(filters?.createdAtFrom).format()
        : undefined,
      createdAtTo: filters?.createdAtTo
        ? moment(filters?.createdAtTo).format()
        : undefined,
      createdById: filters?.createdBy?.length ? filters.createdBy : [],
      employeeGroupId: filters?.group?.length ? filters.group : [],
      status: filters?.status?.length
        ? filters.status.map(({ value }) => value)
        : [],
      memberId: [parseInt(memberId || '', 10)],
      referralSourceId: [],
      taskDefinitionId: filters?.type?.length ? filters.type : [],
      taskDefinitionOutcomeId: [],
      topLevelOnly: true,
    };
    if (tab === TASKS_PAGE_TAB_OPEN) {
      return {
        ...commonFilters,
        status: TaskStatus.getNotClosedStatuses(),
      };
    }
    if (tab === TASKS_PAGE_TAB_CLOSED) {
      return {
        ...commonFilters,
        status: TaskStatus.getClosedStatuses(),
      };
    }
    if (tab === TASKS_PAGE_TAB_ASSIGNED_ME) {
      return {
        ...commonFilters,
        status: TaskStatus.getNotClosedStatuses(),
        assigneeId: [Session.actingUser.id],
      };
    }
    return commonFilters;
  };

  const getInitialData = async () => {
    const [types, groups, employees] = await Promise.all([
      CacheServices.getTaskDefinitions(),
      CacheServices.getEmployeeGroupsAssignee(),
      CacheServices.getEmployees(),
    ]);
    setTypes(types || []);
    setGroups(groups || []);
    setEmployees(employees || []);
  };

  const getTasks = async () => {
    setLoading(true);
    const { items, pagination } = await fetchAllTasks({
      ...getTabFilters(selectedTab),
      offset: page * pageSize,
      limit: pageSize,
      sort,
    });
    if (page === 0) {
      setTasks(items);
    } else {
      setTasks([...tasks, ...items]);
    }
    setPagination(pagination);
    setLoading(false);
  };

  const getTaskDetails = async (task: TaskTable, index: number) => {
    const taskItem = tasks[index] || {};
    if (taskItem.open) {
      delete taskItem.detail;
      taskItem.open = false;
    } else {
      taskItem.open = true;
      taskItem.detail = (
        <div className="flex center">
          <Spinner />
        </div>
      );
      setTasks([...tasks]);
      const taskDetail = await fetchTask(task.id);
      tasks[index] = {
        ...taskDetail,
        open: true,
        detail: (
          <TaskCompleteDetails
            task={taskDetail}
            onUpdate={(newInfo) => {
              tasks[index] = { ...tasks[index], ...newInfo } as TaskTable;
              setTasks([...tasks]);
              updateWarnings();
            }}
            showSubtask
            hideAssignee
            hideStatus
            hideGroup
          />
        ),
      } as TaskTable;
    }
    setTasks([...tasks]);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (taskId) {
      getTaskDetails({ id: Number(taskId) } as TaskTable, 0);
    } else {
      getTasks();
    }
  }, [filters, page, pageSize, selectedTab, sort]);

  useEffect(() => {
    const {
      createdAtFrom,
      createdAtTo,
      language,
      status,
      subTasksOnly,
      topLevelOnly,
      ...storedFilters
    } = filters;

    const flatFilters = {
      createdDateRange:
        createdAtFrom && createdAtTo
          ? transformDateToDaysRange(
              new Date(createdAtFrom),
              new Date(createdAtTo),
              [7, 30, 90],
            )
          : -1,
      language: language?.map(({ value }) => value),
      status: status?.map(({ value }) => value),
      selectedTab,
      sort: sort !== DEFAULT_SORT ? sort : undefined,
      ...storedFilters,
    };

    const query = getAsQuery(flatFilters);
    history.replace(`?${query}`);

    saveStoredFilters(LS_MEMBER_TASK_FILTERS, flatFilters);
  }, [selectedTab, sort, filters]);

  const onChangeAssignee = async (taskId: number, assignee: Employee) => {
    try {
      await editTask(taskId, {
        assigneeId: assignee?.id,
      });
      const task = tasks.find(({ id }) => taskId === id);
      if (task) {
        task.assignee = assignee;
      }
      setTasks([...tasks]);
    } catch (e) {
      showGlobalError(e as string);
    }
  };

  const onChangeGroup = async (taskId: number, group: EmployeeGroup) => {
    try {
      await editTask(taskId, {
        employeeGroupId: group.id,
      });
      const task = tasks.find(({ id }) => taskId === id);
      if (task) {
        task.employeeGroup = group;
      }
      setTasks([...tasks]);
    } catch (e) {
      showGlobalError(e as string);
    }
  };

  const onChangeStatus = async (taskId: number, status: TaskStatus) => {
    try {
      await editTask(taskId, {
        status: status.value,
      });
      updateWarnings();
      const task = tasks.find(({ id }) => taskId === id);
      if (task) {
        task.status = status;
      }
      setTasks([...tasks]);
    } catch (e) {
      showGlobalError(e as string);
    }
  };

  const getAssigneeFilterValue = () => {
    if (selectedTab === TASKS_PAGE_TAB_ASSIGNED_ME) return [Session.actingUser];
    return (
      [UNASSIGNED, ...employees]?.filter(({ id }) =>
        filters.assignee?.includes(id),
      ) || []
    );
  };

  const getStatusFilterValue = () => {
    if (
      selectedTab === TASKS_PAGE_TAB_OPEN ||
      selectedTab === TASKS_PAGE_TAB_ASSIGNED_ME
    )
      return TaskStatus.getNotClosedStatuses().map((x) => TaskStatus.byKey[x]);
    if (selectedTab === TASKS_PAGE_TAB_CLOSED)
      return TaskStatus.getClosedStatuses().map((x) => TaskStatus.byKey[x]);
    return filters.status || [];
  };

  const updateWarnings = () => {
    setTimeout(() => {
      if (memberId) {
        dispatch(fetchPatientWarnings(+memberId));
      }
    }, 250);
  };

  return (
    <Panel>
      <Panel.Heading
        title={translate(
          `clinicalDashboard.tasks.${taskId ? 'detail' : 'title'}`,
        )}
      >
        {!taskId && (
          <>
            <Panel.Filters className={styles.filtersContainer}>
              <ToggleDateRange
                items={[-1, 7, 30, 90, 'custom']}
                label=" "
                onChange={(from, to) => {
                  if (
                    from?.getTime() !== createdAtFrom ||
                    to?.getTime() !== createdAtTo
                  ) {
                    if (from && to) {
                      setFilters({
                        ...filters,
                        createdAtFrom: from.getTime(),
                        createdAtTo: to.getTime(),
                      });
                    } else {
                      setFilters({
                        ...filters,
                        createdAtFrom: undefined,
                        createdAtTo: undefined,
                      });
                    }
                    setPage(0);
                  }
                }}
                defaultValue={defaultCreatedDateRange}
                ref={refCreated}
              />
              <FilterButton
                data-cy="tasks-filters"
                filters={countFilters}
                onClick={() => setOpenFilters(!isOpenFilters)}
              />
            </Panel.Filters>
            <Panel.Actions>
              <AddTaskButton
                onSubmit={(memberId, taskId) => {
                  updateWarnings();
                  history.push({
                    pathname: memberId
                      ? `/patients/${memberId}/tasks`
                      : `/dashboard/tasks/${taskId}`,
                    state: { taskId },
                  });
                }}
                member={member}
                v2
              />
            </Panel.Actions>
            <Panel.Collapse open={isOpenFilters}>
              <div className="grid-wrapper fit">
                <Select
                  className="grid-span-3"
                  data-cy="tasks-filter-status"
                  disabled={
                    [
                      TASKS_PAGE_TAB_OPEN,
                      TASKS_PAGE_TAB_CLOSED,
                      TASKS_PAGE_TAB_ASSIGNED_ME,
                    ].indexOf(selectedTab) !== -1
                  }
                  items={Enum.toSelectable(TaskStatus.asArray)}
                  limitTags={1}
                  multiple
                  onChange={(items?: Selectable[]) => {
                    setFilters({
                      ...filters,
                      status: items?.length
                        ? items.map(({ value }) => TaskStatus.byKey[value])
                        : undefined,
                    });
                    setPage(0);
                  }}
                  placeholder={translate(
                    'clinicalDashboard.tasks.filters.status',
                  )}
                  value={Enum.toSelectable(getStatusFilterValue())}
                />
                <Select
                  className="grid-span-3"
                  data-cy="tasks-filter-type"
                  getItemLabel={({ name }: TaskDefinition) => name}
                  items={types}
                  limitTags={1}
                  multiple
                  onChange={(type?: TaskDefinition[]) => {
                    setFilters({ ...filters, type: type?.map(({ id }) => id) });
                    setPage(0);
                  }}
                  placeholder={translate(
                    'clinicalDashboard.tasks.filters.type',
                  )}
                  value={types.filter(({ id }) => filters.type?.includes(id))}
                />
                <Select
                  className="grid-span-3"
                  data-cy="tasks-filter-group"
                  getItemLabel={({ name }: EmployeeGroup) => name}
                  items={groups}
                  limitTags={1}
                  multiple
                  onChange={(group?: EmployeeGroup[]) => {
                    setFilters({
                      ...filters,
                      group: group?.map(({ id }) => id),
                    });
                    setPage(0);
                  }}
                  placeholder={translate(
                    'clinicalDashboard.tasks.filters.group',
                  )}
                  value={groups.filter(({ id }) => filters.group?.includes(id))}
                />
                <Select
                  className="grid-span-3"
                  data-cy="tasks-filter-assignee"
                  disabled={selectedTab === TASKS_PAGE_TAB_ASSIGNED_ME}
                  getItemLabel={({ fullName }: Employee) => fullName}
                  items={[UNASSIGNED, ...employees]}
                  limitTags={1}
                  multiple
                  onChange={(assignee?: Employee[]) => {
                    setFilters({
                      ...filters,
                      assignee: assignee?.map(({ id }) => id),
                    });
                    setPage(0);
                  }}
                  placeholder={translate(
                    'clinicalDashboard.tasks.filters.assignee',
                  )}
                  value={getAssigneeFilterValue()}
                />
                <Select
                  className="grid-span-3"
                  data-cy="tasks-filter-createdBy"
                  getItemLabel={({ fullName }: Employee) => fullName}
                  items={employees}
                  limitTags={1}
                  multiple
                  onChange={(createdBy?: Employee[]) => {
                    setFilters({
                      ...filters,
                      createdBy: createdBy?.map(({ id }) => id),
                    });
                    setPage(0);
                  }}
                  placeholder={translate(
                    'clinicalDashboard.tasks.filters.createdBy',
                  )}
                  value={employees.filter(({ id }) =>
                    filters.createdBy?.includes(id),
                  )}
                />
                <div className="grid-span-6" />
                <Button
                  className="grid-span-3"
                  data-cy="events-clear-filters"
                  color="secondary"
                  type="outlined"
                  onClick={() => {
                    setPage(0);
                    setDefaultCreatedDateRange(-1);
                    setFilters({
                      subTasksOnly: false,
                      topLevelOnly: true,
                    });
                    setTimeout(() => {
                      refCreated.current?.init();
                    }, 0);
                  }}
                >
                  {translate('global.clearFilters')}
                </Button>
              </div>
            </Panel.Collapse>
            <Panel.Tabs>
              <Tabs
                items={TASK_PAGE_TABS}
                onChange={(tab) => {
                  setSelectedTab(tab);
                  setTasks([]);
                  setPage(0);
                }}
                value={selectedTab}
              />
            </Panel.Tabs>
          </>
        )}
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <MemberTaskTable
          defaultSort={defaultSort !== DEFAULT_SORT ? defaultSort : undefined}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
          onChangeAssignee={onChangeAssignee}
          onChangeGroup={onChangeGroup}
          onChangeStatus={onChangeStatus}
          onClickRow={getTaskDetails}
          onDefaultSort={() => setSort(DEFAULT_SORT)}
          onSort={(field, direction) => setSort(`${field} ${direction}`)}
          pagination={pagination}
          tasks={tasks}
          showCompleted={
            [TASKS_PAGE_TAB_ALL, TASKS_PAGE_TAB_CLOSED].indexOf(selectedTab) !==
            -1
          }
        />
      </Panel.Body>
    </Panel>
  );
};

export default ClinicalTasks;
