import moment, { Moment } from 'moment';

import { CareTeamMember } from './CareTeamMember';
import {
  DaysOfServiceHelper,
  getDaysOfService,
} from './Helpers/DaysOfServiceHelper';
import { MemberContactInfoTimes } from './MemberContactInfoTimes';
import { makeArray } from './Model';

export class CareTeamMemberAide
  extends CareTeamMember
  implements DaysOfServiceHelper {
  agencyId?: number;

  aideTimes?: MemberContactInfoTimes[];

  externalId?: string;

  otherFaxNumber?: string;

  otherNotes?: string;

  otherOrganizationName?: string;

  otherPhoneNumber?: string;

  paid?: boolean;

  temp?: boolean;

  tempUntil?: Moment;

  constructor(obj: any) {
    super(obj);

    this.agencyId = obj.agencyId;
    this.aideTimes =
      obj.aideTimes && makeArray(obj.aideTimes, MemberContactInfoTimes);
    this.externalId = obj.externalId;
    this.otherFaxNumber = obj.otherFaxNumber;
    this.otherNotes = obj.otherNotes;
    this.otherOrganizationName = obj.otherOrganizationName;
    this.paid = obj.paid;
    this.otherPhoneNumber = obj.otherPhoneNumber;
    this.temp = obj.temp;
    this.tempUntil = obj.tempUntil ? moment.unix(obj.tempUntil) : undefined;
  }

  getDaysOfService() {
    return getDaysOfService(this.aideTimes);
  }
}

export default CareTeamMemberAide;
