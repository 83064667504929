import React, { Fragment } from 'react';

import { makeStyles, ClassNameMap } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import EditIcon from '@mui/icons-material/Edit';
import { translate } from '@vestahealthcare/common/i18n';
import { Chip, Colors, IconButton, Table, Tooltip } from 'styleguide-v2';
import { EngagementContent } from '@vestahealthcare/common/models';

interface Props {
  contentItems: EngagementContent[];
  onEdit: (group: EngagementContent) => void;
}

const useStyles = makeStyles({
  contentContainer: {
    display: 'flex',
    flexFlow: 'column',
    maxWidth: 'fit-content',
  },
  description: {
    color: Colors.gray,
    maxWidth: 'calc(min(100vw, 1680px) - 125rem)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

const CONTENT_COLUMNS = (
  { onEdit }: Props,
  styles: ClassNameMap<'contentContainer' | 'description'>,
) => [
  {
    headerName: translate('campaigns.contentLibrary.id'),
    field: 'id',
  },
  {
    headerName: translate('campaigns.contentLibrary.content'),
    component: ({ name, description }: EngagementContent) => (
      <div className={styles.contentContainer}>
        <span>{name}</span>
        {description && (
          <Tooltip text={description}>
            <span className={styles.description}>{description}</span>
          </Tooltip>
        )}
      </div>
    ),
  },
  {
    headerName: translate('campaigns.contentLibrary.contentType'),
    field: 'contentType.description',
  },
  {
    headerName: translate('campaigns.contentLibrary.audience'),
    component: ({ id: cId, tags }: EngagementContent) => (
      <div className="flex gap wrap">
        {tags.map(({ id, description }) => (
          <Chip
            color="info"
            key={`content-${cId}-tag-${id}`}
            size="small"
            type="outlined"
          >
            {description}
          </Chip>
        ))}
      </div>
    ),
    width: 75,
  },
  {
    headerName: translate('campaigns.contentLibrary.fileType'),
    field: 'fileType.description',
  },
  {
    headerName: translate('campaigns.contentLibrary.languages'),
    component: ({ contentItems, name }: EngagementContent) =>
      contentItems.map(({ id, language, rawText, referenceLink }, index) => (
        <Fragment key={`content-item-${id}`}>
          {referenceLink ? (
            <Tooltip
              text={
                rawText
                  ? `${rawText} (${translate(
                      'campaigns.contentLibrary.tooltipDownload',
                    )})`
                  : translate('campaigns.contentLibrary.tooltipDownload')
              }
            >
              <a href={referenceLink}>{language.toString()}</a>
            </Tooltip>
          ) : (
            <Tooltip text={rawText}>
              <a
                download={`${name}_${language.toString()}.txt`}
                href={`data:text/plain;charset=utf-8,${encodeURIComponent(
                  rawText,
                )}`}
              >
                {language.toString()}
              </a>
            </Tooltip>
          )}
          {index !== contentItems.length - 1 && ', '}
        </Fragment>
      )),
  },
  {
    headerName: translate('campaigns.contentLibrary.active'),
    component: ({ active }: EngagementContent) => (
      <span className="flex">
        {active ? (
          <CheckCircleIcon color="primary" />
        ) : (
          <CancelIcon color="error" />
        )}
      </span>
    ),
  },
  {
    headerName: '',
    component: (content: EngagementContent) => (
      <IconButton size="small" onClick={() => onEdit(content)}>
        <EditIcon />
      </IconButton>
    ),
    width: 20,
  },
];

export const ContentLibraryTable = (props: Props) => {
  const styles = useStyles();
  return (
    <Table
      config={{
        columns: CONTENT_COLUMNS(props, styles),
        compact: true,
        data: props.contentItems,
        paginationOptions: [-1],
      }}
    />
  );
};

export default ContentLibraryTable;
