import React, { useRef } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';

import { IconButton } from '@mui/material';
import { TextInput } from 'styleguide-v2/src/components/TextInput';

type Props = {
  accept?: string;
  className?: string;
  'data-cy'?: string;
  disabled?: boolean;
  error?: boolean | string;
  file?: File;
  label?: string;
  loading?: boolean;
  onChange?: (value?: File) => void;
  placeholder?: string;
  required?: boolean;
};

export const FileInput = ({
  accept,
  className,
  'data-cy': dataCy,
  disabled,
  error,
  file,
  label,
  loading,
  onChange,
  placeholder,
  required,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <TextInput
        className={className}
        data-cy={dataCy}
        disabled={disabled}
        error={error}
        icon={
          <>
            {file && (
              <IconButton
                onClick={(evt) => {
                  evt.stopPropagation();
                  if (onChange) {
                    onChange(undefined);
                  }
                }}
                size="small"
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            )}
            <IconButton
              disabled={disabled}
              onClick={(evt) => {
                evt.stopPropagation();
                inputRef?.current?.click();
              }}
              size="small"
            >
              <AttachFileIcon color="action" />
            </IconButton>
          </>
        }
        label={label}
        loading={loading}
        onClick={() => {
          if (!disabled) {
            inputRef?.current?.click();
          }
        }}
        placeholder={placeholder}
        required={required}
        value={file?.name || ''}
        sx={{
          paddingRight: '4px',
        }}
      />
      <input
        accept={accept}
        hidden
        onChange={(evt) => {
          if (evt?.target?.files && evt?.target?.files[0]) {
            if (onChange) {
              onChange(evt.target.files[0]);
            }
          }
          // eslint-disable-next-line no-param-reassign
          evt.target.value = '';
        }}
        ref={inputRef}
        type="file"
      />
    </>
  );
};

export default FileInput;
