import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { EventType, IssueType } from '@vestahealthcare/common/models';
import {
  FilterButton,
  IconButton,
  Panel,
  SearchInput,
  Select,
} from 'styleguide-v2';
import SettingsIcon from '@mui/icons-material/Settings';

import { CacheServices } from 'dash/src/services';
import {
  fetchIssueTypes,
  GetIssueTypeParams,
  updateEventType,
  updateIssueType,
} from 'dash/src/services/EventServices';
import { showGlobalError } from 'dash/src/components/GlobalMessage';

import { EventMappingEditModal } from './EventMappingEdit';
import { EventsMappingTable } from './EventMappingTable';
import { IssueTypeUrgencyEdit } from './IssueTypeUrgencyEdit';

export const EventMappingDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [areFiltersOpen, setFiltersOpen] = useState(false);
  const [filters, setFilters] = useState<GetIssueTypeParams>({
    eventTypeId: [],
  });
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timer>();

  const [editMappings, setEditMappings] = useState(false);
  const [eventTypes, setEventTypes] = useState<EventType[]>([]);
  const [issueTypes, setIssueTypes] = useState<IssueType[]>([]);
  const [allIssueTypes, setAllIssueTypes] = useState<IssueType[]>([]);

  const [issueType, setIssueType] = useState<IssueType>();
  const [editUrgency, setEditUrgency] = useState<boolean>(false);

  const getData = async () => {
    setLoading(true);
    const issueTypes = await fetchIssueTypes(filters);
    const allIssueTypes = await CacheServices.getIssueTypes();
    const eventTypes = await CacheServices.getEventTypes();
    setIssueTypes(issueTypes);
    setAllIssueTypes(allIssueTypes);
    setEventTypes(eventTypes);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [filters]);

  return (
    <Panel id="referrals-section">
      <Panel.Heading title={translate('events.mapping.title')}>
        <FilterButton
          className="filters"
          data-cy="members-filters"
          onClick={() => setFiltersOpen(!areFiltersOpen)}
        />
        <Panel.Actions>
          <IconButton
            onClick={() => setEditMappings(true)}
            tooltip={translate('events.mapEvent')}
          >
            <SettingsIcon />
          </IconButton>
        </Panel.Actions>
        <Panel.Collapse
          data-cy="members-filters-collapsible"
          open={areFiltersOpen}
        >
          <div className="grid-wrapper">
            <SearchInput
              className="grid-span-3"
              data-cy="events-mapping-filter-name"
              label=""
              placeholder={translate('events.mapping.issueType')}
              onChange={(value) => {
                if (!value || value?.length > 1) {
                  if (searchTimer) {
                    clearTimeout(searchTimer);
                  }
                  setSearchTimer(
                    setTimeout(
                      () => setFilters({ ...filters, description: value }),
                      300,
                    ),
                  );
                }
              }}
              value={filters.description}
            />
            <Select
              className="grid-span-3"
              data-cy="events-mapping-filter-event"
              items={[
                { id: 0, name: translate('global.unassigned') },
                ...eventTypes,
              ]}
              getItemLabel={({ name }: EventType) => name}
              limitTags={1}
              multiple
              onChange={(values: EventType[]) => {
                setFilters({
                  ...filters,
                  eventTypeId: values,
                });
              }}
              placeholder={translate('events.eventMapped')}
              value={filters.eventTypeId}
            />
          </div>
        </Panel.Collapse>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <EventsMappingTable
          issueTypes={issueTypes}
          onEdit={(issueType) => {
            setEditUrgency(true);
            setIssueType(issueType);
          }}
        />
        <EventMappingEditModal
          eventTypes={eventTypes.filter(({ active }) => active)}
          issueTypes={allIssueTypes}
          onClose={() => setEditMappings(false)}
          open={editMappings}
          onSubmit={async (eventType, issueTypes) => {
            let result = true;
            try {
              await updateEventType(eventType.id, {
                ...eventType,
                issueTypes,
              });
              CacheServices.invalidateIssueTypes();
              await getData();
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
        />
        <IssueTypeUrgencyEdit
          open={editUrgency}
          issueType={issueType}
          onClose={() => setEditUrgency(false)}
          onSubmit={async (issueType, params) => {
            let result = true;
            try {
              await updateIssueType(issueType.id, params);
              await getData();
            } catch (e) {
              result = false;
            }
            return result;
          }}
        />
      </Panel.Body>
    </Panel>
  );
};

export default EventMappingDashboard;
