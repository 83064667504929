import React from 'react';
import { readEnumName } from '../utils';
import { ArrayFieldTemplateProps } from '../types';
import { Button, ButtonModal, DeleteIcon, AddIcon } from 'styleguide';
import { translate } from '@vestahealthcare/common/i18n';

const ArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
  const {
    TitleField,
    DescriptionField,
    items,
    onAddClick,
    readonly,
    formContext,
    uiSchema,
    schema,
    formData,
  } = props;

  const canEdit = !readonly && !formContext.readonly;
  const options = uiSchema['ui:options'] || ({} as any);

  // Fetch the value of the array item
  const getValue = (field: string, item?: any): string => {
    if (item) {
      const { index } = item;
      const data = formData[index] && formData[index][field];
      const fieldSchema =
        schema.items &&
        schema.items.properties &&
        schema.items.properties[field];
      return readEnumName(data, fieldSchema) || '';
    }

    return `{{${field}}}`;
  };

  const addLabel = options.addButtonText || translate('global.add');
  const removeModalTitle =
    options.removeItemTitle || translate('global.confirm');

  const getRemoveModalMessage = (item: object) =>
    (options.removeItemMessage || translate('global.confirmRemove'))
      .toString()
      // Allow to have replacement strings so we can show a name in the message
      .replace(/\{\{(.[^}]+)\}\}/, (fullMatch: string, field: string) =>
        getValue(field, item),
      );

  return (
    <>
      {TitleField}
      {DescriptionField}
      {items && items.length > 0 && (
        <ul>
          {items.map((item) => (
            <li className="array-item" key={item.index} data-cy={item.index}>
              {canEdit && !item.readonly && item.hasRemove && (
                <div className="pull-right">
                  <ButtonModal
                    modalTitle={removeModalTitle.toString()}
                    onSubmit={(event: Event) =>
                      item.onDropIndexClick(item.index)(event)
                    }
                    iconComponent={<DeleteIcon tag="button" />}
                    buttonText=""
                    submitText={translate('global.confirm')}
                  >
                    {getRemoveModalMessage(item)}
                  </ButtonModal>
                </div>
              )}
              {item.children}
            </li>
          ))}
        </ul>
      )}
      {canEdit && (
        <Button color="secondary" isPill onClick={onAddClick}>
          <AddIcon className="margin-right" />
          {addLabel}
        </Button>
      )}
    </>
  );
};

export default ArrayFieldTemplate;
