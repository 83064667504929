import { Enum } from './Enum';

export class RPMAdherenceLevel extends Enum {
  translationKey = 'RPMAdherenceLevel';

  static asArray: RPMAdherenceLevel[] = [];

  static byKey: { [name: string]: RPMAdherenceLevel } = {};

  static readonly MET = new RPMAdherenceLevel('MET');

  static readonly ON_TRACK = new RPMAdherenceLevel('ON_TRACK');

  static readonly AT_RISK = new RPMAdherenceLevel('AT_RISK');

  static readonly MISSED = new RPMAdherenceLevel('MISSED');

  static getColor(status?: RPMAdherenceLevel) {
    switch (status) {
      case RPMAdherenceLevel.MET:
        return 'secondary';
      case RPMAdherenceLevel.ON_TRACK:
        return 'warning';
      case RPMAdherenceLevel.MISSED:
        return 'error';
      case RPMAdherenceLevel.AT_RISK:
        return 'primary';
      default:
        return 'info';
    }
  }

  constructor(public readonly value: string, public readonly filter?: boolean) {
    super();
    RPMAdherenceLevel.asArray.push(this);
    RPMAdherenceLevel.byKey[value] = this;
  }
}

export default RPMAdherenceLevel;
