import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';

import { IssueTypeRuleOperation } from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { IssueType } from '@vestahealthcare/common/models';
import IssueTypeRule from '@vestahealthcare/common/models/IssueTypeRule';

import {
  Button,
  CollapsableSidebar,
  IconButton,
  Select,
  SwitchGroup,
  TextInput,
} from 'styleguide-v2';

import { UpdateIssueTypeParams } from 'dash/src/services/EventServices';

const useStyles = makeStyles({
  select: {
    '&& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-ctd5i9-MuiAutocomplete-root .MuiOutlinedInput-root': {
      paddingRight: '56px',
    },
  },
  arrowIcon: {
    '&&': {
      marginTop: '1rem',
      width: '100%',
    },
  },
  arrowIconWithTop: {
    '&&': {
      marginTop: '3.25rem',
      width: '100%',
    },
  },
  trashIcon: {
    '&&': {
      padding: 0,
      height: 'fit-content',
      width: 'fit-content',
      margin: '1.25rem auto auto',
    },
  },
  trashIconWithTop: {
    '&&': {
      padding: 0,
      height: 'fit-content',
      width: 'fit-content',
      margin: '3.5rem auto auto',
    },
  },
});

interface Props {
  issueType?: IssueType;
  onClose: () => void;
  onSubmit: (
    issueType: IssueType,
    params: UpdateIssueTypeParams,
  ) => Promise<boolean>;
  open: boolean;
}

const MEMBER_EVENT_URGENCY_LEVEL = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
];

export const IssueTypeUrgencyEdit = ({
  issueType,
  onClose,
  open,
  onSubmit,
}: Props) => {
  const styles = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [urgent, setUrgent] = useState<boolean>(false);
  const [urgencyLevel, setUrgencyLevel] = useState<number>();
  const [urgencyRules, setUrgencyRules] = useState<IssueTypeRule[]>([]);

  useEffect(() => {
    setUrgent(!!issueType?.urgent);
    setUrgencyLevel(issueType?.urgencyLevel);
    setUrgencyRules(
      issueType?.urgencyRules.length
        ? issueType?.urgencyRules
        : [new IssueTypeRule({})],
    );
  }, [issueType]);

  useEffect(() => {
    setSubmitted(false);
    setLoading(false);
  }, [open]);

  const validate = () => {
    if (urgent && !urgencyLevel) return false;
    if (urgencyRules[0]?.operationType) {
      return urgencyRules.reduce(
        (acc, { operationType, value, urgencyLevel, timeFrame }) => {
          if (!issueType || !operationType || !value || !urgencyLevel)
            return false;
          if (issueType.id === IssueType.WEIGHT && !timeFrame) return false;
          if (operationType === IssueTypeRuleOperation.BETWEEN) {
            const values = value.split('-');
            if (!values[0] || !values[1] || values[0] >= values[1]) {
              return false;
            }
          }
          if (issueType.id === IssueType.BLOOD_PRESSURE) {
            const values = value.split('/');
            if (!values[0] || !values[1]) {
              return false;
            }
          }
          return acc;
        },
        true,
      );
    }
    return true;
  };

  const doSubmit = async () => {
    if (validate() && issueType) {
      setLoading(true);
      const ur = urgencyRules[0]?.operationType ? urgencyRules : [];
      try {
        const result = await onSubmit(issueType, {
          description: issueType.description,
          urgent,
          urgencyLevel,
          urgencyRules: ur,
          eventTypeId: issueType.eventType,
        });
        if (result) {
          onClose();
        }
      } finally {
        setLoading(false);
      }
    } else {
      setSubmitted(true);
    }
  };

  const getOperationsByType = (id?: string) => {
    if (id === IssueType.WEIGHT) return IssueTypeRuleOperation.asWeightArray;
    if (id === IssueType.BLOOD_PRESSURE) return IssueTypeRuleOperation.asBPrray;
    return IssueTypeRuleOperation.asDefaultArray;
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={
        <h2>
          {translate('events.rules.modalTitle', {
            type: issueType?.description,
          })}
        </h2>
      }
      size={issueType?.isBiometricType ? 600 : 450}
    >
      <CollapsableSidebar.Body>
        <SwitchGroup
          data-cy="edit-issue-urgent"
          items={[{ label: translate('events.rules.urgent'), checked: urgent }]}
          onChange={(checks) => {
            setUrgent(!!checks[0].checked);
          }}
        />
        {urgent && (
          <Select
            data-cy="edit-issue-urgency-level"
            disableClearable
            error={submitted && !urgencyLevel}
            items={MEMBER_EVENT_URGENCY_LEVEL}
            label={translate('events.rules.urgencyLevel')}
            onChange={(urgencyLevel?: Selectable) =>
              setUrgencyLevel(urgencyLevel?.value as number)
            }
            required
            value={
              urgencyLevel
                ? MEMBER_EVENT_URGENCY_LEVEL[urgencyLevel - 1]
                : undefined
            }
          />
        )}

        {issueType?.isBiometricType && (
          <div className="grid-wrapper fit">
            {urgencyRules?.map(
              ({ operationType, timeFrame, urgencyLevel, value }, index) => (
                <Fragment key={`issue-type-urgency-rule-${index}`}>
                  <Select
                    className={classNames('grid-span-3', styles.select)}
                    data-cy={`issue-type-urgency-rule-operation-${index}`}
                    error={submitted && !operationType}
                    getItemLabel={(item: Enum) => item.toString()}
                    items={getOperationsByType(issueType?.id)}
                    label={
                      index === 0
                        ? translate('events.rules.operation')
                        : undefined
                    }
                    onChange={(op?: Selectable) => {
                      const operation = op
                        ? IssueTypeRuleOperation.byKey[op.value]
                        : undefined;
                      urgencyRules[index].operationType = operation;
                      setUrgencyRules([...urgencyRules]);
                    }}
                    placeholder={
                      index !== 0
                        ? translate('events.rules.operation')
                        : undefined
                    }
                    value={operationType}
                    required
                  />
                  {operationType === IssueTypeRuleOperation.BETWEEN && (
                    <>
                      <TextInput
                        className="grid-span-2"
                        data-cy={`issue-type-urgency-rule-from-${index}`}
                        error={
                          submitted &&
                          (value?.split('-')[0]
                            ? value?.split('-')[1] <= value?.split('-')[0] &&
                              translate('events.rules.warningInvalidRange')
                            : translate('global.missingRequiredFieldMin'))
                        }
                        label={
                          index === 0 ? translate('global.from') : undefined
                        }
                        onChange={(val) => {
                          const values = value.split('-');
                          urgencyRules[index].value = `${val || ''}-${
                            values[1] || ''
                          }`;
                          setUrgencyRules([...urgencyRules]);
                        }}
                        placeholder={
                          index !== 0 ? translate('global.from') : undefined
                        }
                        type="number"
                        value={value?.split('-')[0] || ''}
                      />
                      <TextInput
                        className="grid-span-2"
                        data-cy={`issue-type-urgency-rule-to-${index}`}
                        error={
                          submitted &&
                          (value?.split('-')[1]
                            ? value?.split('-')[1] <= value?.split('-')[0] &&
                              translate('events.rules.warningInvalidRange')
                            : translate('global.missingRequiredFieldMin'))
                        }
                        label={index === 0 ? translate('global.to') : undefined}
                        onChange={(val) => {
                          const values = value.split('-');
                          urgencyRules[index].value = `${values[0] || ''}-${
                            val || ''
                          }`;
                          setUrgencyRules([...urgencyRules]);
                        }}
                        placeholder={
                          index !== 0 ? translate('global.to') : undefined
                        }
                        type="number"
                        value={value?.split('-')[1] || ''}
                      />
                    </>
                  )}
                  {operationType !== IssueTypeRuleOperation.BETWEEN &&
                    issueType?.id !== IssueType.BLOOD_PRESSURE && (
                      <TextInput
                        className={`grid-span-${
                          issueType?.id === IssueType.WEIGHT ? 2 : 4
                        }`}
                        data-cy={`issue-type-urgency-rule-value-${index}`}
                        error={
                          submitted &&
                          !value &&
                          translate('global.missingRequiredFieldMin')
                        }
                        label={
                          index === 0
                            ? translate('events.rules.value')
                            : undefined
                        }
                        onChange={(val) => {
                          urgencyRules[index].value = val || '';
                          setUrgencyRules([...urgencyRules]);
                        }}
                        placeholder={
                          index !== 0
                            ? translate('events.rules.value')
                            : undefined
                        }
                        type="number"
                        value={value}
                      />
                    )}
                  {issueType?.id === IssueType.BLOOD_PRESSURE && (
                    <>
                      <TextInput
                        className="grid-span-2"
                        data-cy={`issue-type-urgency-rule-systolic-${index}`}
                        error={
                          submitted &&
                          !value?.split('/')[0] &&
                          translate('global.missingRequiredFieldMin')
                        }
                        label={
                          index === 0
                            ? translate('events.rules.systolic')
                            : undefined
                        }
                        onChange={(val) => {
                          const values = value.split('/');
                          urgencyRules[index].value = `${val || ''}/${
                            values[1] || ''
                          }`;
                          setUrgencyRules([...urgencyRules]);
                        }}
                        type="number"
                        value={value?.split('/')[0] || ''}
                      />
                      <TextInput
                        className="grid-span-2"
                        data-cy={`issue-type-urgency-rule-diastolic-${index}`}
                        error={
                          submitted &&
                          !value?.split('/')[1] &&
                          translate('global.missingRequiredFieldMin')
                        }
                        label={
                          index === 0
                            ? translate('events.rules.diastolic')
                            : undefined
                        }
                        onChange={(val) => {
                          const values = value.split('/');
                          urgencyRules[index].value = `${values[0] || ''}/${
                            val || ''
                          }`;
                          setUrgencyRules([...urgencyRules]);
                        }}
                        type="number"
                        value={value?.split('/')[1] || ''}
                      />
                    </>
                  )}
                  {issueType?.id === IssueType.WEIGHT && (
                    <TextInput
                      className="grid-span-2"
                      data-cy={`issue-type-urgency-rule-days-${index}`}
                      error={
                        submitted &&
                        !timeFrame &&
                        translate('global.missingRequiredFieldMin')
                      }
                      label={
                        index === 0 ? translate('events.rules.days') : undefined
                      }
                      onChange={(val) => {
                        urgencyRules[index].timeFrame = Number.parseInt(
                          val || '0',
                          10,
                        );
                        urgencyRules[index].timeFrameUnit = 'DAYS';
                        setUrgencyRules([...urgencyRules]);
                      }}
                      placeholder={
                        index !== 0 ? translate('events.rules.days') : undefined
                      }
                      type="number"
                      value={timeFrame || undefined}
                    />
                  )}
                  <ArrowForwardIcon
                    className={classNames(
                      'grid-span-1',
                      index === 0 ? styles.arrowIconWithTop : styles.arrowIcon,
                    )}
                    color="action"
                    fontSize="large"
                  />
                  <Select
                    className="grid-span-3"
                    data-cy={`issue-type-urgency-rule-urgency-${index}`}
                    error={
                      submitted &&
                      !urgencyLevel &&
                      translate('global.missingRequiredFieldMin')
                    }
                    getItemLabel={(item: Enum) => item.value}
                    items={MEMBER_EVENT_URGENCY_LEVEL}
                    label={
                      index === 0
                        ? translate('events.rules.urgency')
                        : undefined
                    }
                    onChange={(urgency?: Selectable) => {
                      urgencyRules[index].urgencyLevel =
                        (urgency?.value as number) || 0;
                      urgencyRules[index].urgent = true;
                      setUrgencyRules([...urgencyRules]);
                    }}
                    value={MEMBER_EVENT_URGENCY_LEVEL.find(
                      ({ value }) => value === urgencyLevel,
                    )}
                  />
                  <IconButton
                    className={classNames(
                      'grid-span-1',
                      index === 0 ? styles.trashIconWithTop : styles.trashIcon,
                    )}
                    disabled={urgencyRules?.length === 1}
                    onClick={() => {
                      urgencyRules.splice(index, 1);
                      setUrgencyRules([...urgencyRules]);
                    }}
                    size="small"
                  >
                    <DeleteIcon
                      color={urgencyRules?.length === 1 ? 'action' : 'primary'}
                    />
                  </IconButton>
                </Fragment>
              ),
            )}
            <div className="grid-span-4" />
            <Button
              className="grid-span-4"
              color="secondary"
              data-cy="issue-type-urgency-rule-add"
              type="outlined"
              onClick={() => {
                setUrgencyRules([...urgencyRules, new IssueTypeRule({})]);
              }}
            >
              {translate('events.rules.add')}
            </Button>
          </div>
        )}
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="edit-issue-urgency-close"
          onClick={onClose}
        >
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="edit-issue-urgency-submit"
          loading={loading}
          onClick={() => doSubmit()}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default IssueTypeUrgencyEdit;
