export class EngagementCampaignCondition {
  id = '';

  descCondition = '';

  descException = '';

  descTrigger = '';

  trigger = false;

  exception = false;

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  get isMemberOnboarded() {
    return this.id === 'MEMBER_ONBOARDED';
  }

  get isCGOnboarded() {
    return this.id === 'CAREGIVER_ONBOARDED';
  }

  get isFirstRPMVital() {
    return this.id === 'VITALS_RPM_FIRST';
  }

  get isRPMOnboarded() {
    return this.id === 'RPM_ONBOARDED';
  }

  get isPINGDischargeER() {
    return this.id === 'PING_ER_DISCHARGE';
  }

  get isPINGDischargeFacility() {
    return this.id === 'PING_FACILITY_DISCHARGE';
  }
}

export default EngagementCampaignCondition;
