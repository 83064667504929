import classNames from 'classnames';
import React, { Fragment } from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { MemberCarePlan } from '@vestahealthcare/common/models';

import { BackgroundColors, Card, Colors, Fonts } from 'styleguide-v2';

type Props = {
  className?: string;
  careplanData: MemberCarePlan[];
};

const useStyles = makeStyles({
  cardContent: {
    padding: '0 3.2rem 1rem',
  },
  container: {
    display: 'flex',
    flex: '1 1',
    flexFlow: 'column',
    gap: '1rem',
    minWidth: '55rem',

    '&& > div': {
      borderRadius: '8px',
      display: 'block',
      maxHeight: 'fit-content',
    },
  },
  dataContainer: {
    '&&': {
      borderLeft: '1px solid #eee',
      paddingLeft: '1rem',
    },
  },
  interventionsList: {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    marginBottom: '1rem',

    '&& > li:before': {
      content: '1',
      position: 'absolute',
      left: 0,
    },
  },
  sectionTitle: {
    alignSelf: 'baseline',
    color: Colors.iconGreen,
    fontSize: `calc(${Fonts.fontSize} * 1.125)`,

    '&&': {
      fontFamily: Fonts.fontFamily,
      fontWeight: 500,
      margin: 0,
    },
  },
  sectionSubTitle: {
    alignSelf: 'baseline',
    color: Colors.iconGray,

    '&&': {
      fontFamily: Fonts.fontFamily,
      margin: 0,
    },
  },
  sectionSubSubTitle: {
    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 0.875)`,
      fontWeight: 500,
      margin: 0,
    },
  },
  subContainer: {
    display: 'flex',
    flexFlow: 'column',
    padding: '2.4rem 3.2rem',
  },
  tabs: {
    padding: '0 3.2rem',
    borderBottom: `1px solid ${BackgroundColors.grayLine}`,
  },
  title: {
    alignItems: 'center',
    display: 'flex',

    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontWeight: 500,
      margin: 0,
    },
  },
  titleContainer: {
    '&&': {
      paddingBottom: '1rem',
    },
  },
});

export const CarePlanCard = ({ className, careplanData }: Props) => {
  const styles = useStyles();

  const renderCard = (data: MemberCarePlan) => (
    <Card
      className={classNames(className, styles.container)}
      key={`careplan-${data.description}`}
    >
      <div className={classNames(styles.subContainer, styles.titleContainer)}>
        <div className="flex spaced">
          <span className={styles.title}>{data.description}</span>
        </div>
      </div>
      <div className={styles.cardContent}>
        {data.goals.map((goal, idx) => (
          <Fragment
            key={`careplan-${data.description}-goal-${goal.description}`}
          >
            {idx !== 0 && <div style={{ height: '1rem' }} />}
            <p className={styles.sectionTitle}>
              {translate('memberProfile.clinicalSummary.tables.careplan.goal')}
            </p>
            <p>{goal.description}</p>
            <div className={styles.dataContainer}>
              {goal.objectives.map((objective, idxo) => (
                <Fragment
                  key={`care-plan-${data.description}-goal-${idx}-objective-${idxo}`}
                >
                  {idxo !== 0 && (
                    <hr
                      style={{
                        margin: '10px 0',
                        width: 'calc(100% - 1rem)',
                      }}
                    />
                  )}
                  <p className={styles.sectionSubTitle}>
                    {translate(
                      'memberProfile.clinicalSummary.tables.careplan.objective',
                    )}
                  </p>
                  <p>{objective.description}</p>

                  {!!objective.interventions?.length && (
                    <>
                      <p className={styles.sectionSubSubTitle}>
                        {translate(
                          'memberProfile.clinicalSummary.tables.careplan.interventions',
                        )}
                      </p>
                      <ul className={classNames(styles.interventionsList)}>
                        {objective.interventions.map((intervention, idxi) => (
                          <li
                            key={`care-plan-${data.description}-goal-${idx}-objective-${idxo}-intervention-${idxi}`}
                          >
                            {intervention}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </Fragment>
              ))}
            </div>
          </Fragment>
        ))}
      </div>
    </Card>
  );

  return (
    <div
      className="flex wrap extra-gap baseline"
      style={{ marginBottom: '5rem' }}
    >
      <div className={styles.container}>
        {careplanData
          .filter(({ goals }) => !!goals?.length)
          .filter((_, idx) => idx % 2 === 0)
          .map(renderCard)}
      </div>
      <div className={styles.container}>
        {careplanData
          .filter(({ goals }) => !!goals?.length)
          .filter((_, idx) => idx % 2 === 1)
          .map(renderCard)}
      </div>
      <br />
      <br />
    </div>
  );
};
