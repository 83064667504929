import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface Props {
  active?: boolean | (() => boolean);
  title?: string;
}

export const NavItem = (props: Props & React.HTMLProps<any>) => {
  const { active, children, className, disabled, title, ref, ...other } = props;

  const classes = classNames(className, {
    active: typeof active === 'function' ? active() : active,
    disabled,
  });

  return (
    <li className={classes} role="presentation" title={title}>
      {props.href ? (
        <Link to={props.href} {...other}>
          {children}
        </Link>
      ) : (
        <a role="button" {...other}>
          {children}
        </a>
      )}
    </li>
  );
};

export default NavItem;
