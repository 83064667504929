import { MemberDashboardKeyContactType } from '../enums';
import { Address } from './Address';
import { make } from './Model';

export class MemberDashboardKeyContact {
  type: MemberDashboardKeyContactType;

  name = '';

  phones = [] as string[];

  address: Address;

  constructor(obj: any) {
    Object.assign(this, obj);

    this.address = make(obj.address, Address);
    this.type = MemberDashboardKeyContactType.byKey[obj.type];
  }
}

export default MemberDashboardKeyContact;
