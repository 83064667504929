import { BaseEnum } from './BaseEnum';

export class EngagementAudience extends BaseEnum {
  static get MEMBERS() {
    return 'MEMBERS';
  }

  static get CAREGIVERS() {
    return 'CAREGIVERS';
  }

  get isMember() {
    return this.id === EngagementAudience.MEMBERS;
  }
}

export default EngagementAudience;
