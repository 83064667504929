import { Enum } from './Enum';

export class CareQuestionType extends Enum {
  translationKey = 'riskProgramSource';

  static asArray: CareQuestionType[] = [];

  static byKey: { [name: string]: CareQuestionType } = {};

  static FREETEXT = new CareQuestionType('FREETEXT');

  static CHECKBOX = new CareQuestionType('CHECKBOX');

  static RADIO = new CareQuestionType('RADIO');

  static SLIDER = new CareQuestionType('SLIDER');

  static RADIO_SLIDER = new CareQuestionType('RADIO_SLIDER');

  static MIN_MAX = new CareQuestionType('MIN_MAX');

  static NUMERIC = new CareQuestionType('NUMERIC');

  constructor(public readonly value: string) {
    super();
    CareQuestionType.asArray.push(this);
    CareQuestionType.byKey[value] = this;
  }
}

export default CareQuestionType;
