import { make } from './Model';
import { BiometricsTimeOption, DayOfWeek } from '../enums';
import { LocalTime } from './LocalTime';

export class BiometricsTime {
  id = 0;

  // biometricsConfig: BiometricsConfig;

  measurementDay?: DayOfWeek;

  measurementTime: LocalTime;

  option?: BiometricsTimeOption;

  deleted = false;

  createdAt = 0;

  updatedAt = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.measurementDay = DayOfWeek.byKey[obj.measurementDay];
    this.measurementTime = make(obj.measurementTime, LocalTime);
    this.option = BiometricsTimeOption.byKey[obj.option];
  }
}

export default BiometricsTime;
