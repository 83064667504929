import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
  children?: ReactNode;
  className?: string;
  title?: string;
  type?: 'box' | 'banner';
}

export const Warning = (props: Props) => {
  const { children, className, title, type = 'box', ...other } = props;

  const classes = classNames('warning', type, className);

  return (
    <p className={classes} {...other}>
      {title && (
        <span className="title">
          <i className="fa fa-warning" /> {title}
        </span>
      )}
      {children}
    </p>
  );
};

export default Warning;
