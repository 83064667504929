import React, { useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Select } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { updatePatientSelfDirectingStatue } from 'dash/src/services/PatientServices';

import { BaseModal } from './BaseModal';

interface Props {
  patient: Patient;
  onSubmit: (patinet: Patient) => void;
}

const renderDetails = (patient: Patient) => {
  let display = patient.selfDirecting
    ? translate('global.yes')
    : translate('global.no');

  if (patient.selfDirecting === undefined || patient.selfDirecting === null) {
    display = EMPTY;
  }

  return (
    <>
      <h4 className="grid-span-5">
        {translate('personalDetails.selfDirecting')}
      </h4>
      <p className="grid-span-6">{display}</p>
    </>
  );
};

export const SelfDirectingEditModal = React.memo((props: Props) => {
  const { patient, onSubmit } = props;
  const editable = patient.isEditable();
  const [isSelfDirecting, setSelfDirecting] = useState<boolean>(
    patient.selfDirecting,
  );
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    let result = true;
    setLoading(true);

    try {
      const newPatient = await updatePatientSelfDirectingStatue(
        patient.id,
        isSelfDirecting,
      );
      onSubmit(newPatient);
    } catch (e) {
      showGlobalError(e as string);
      result = false;
    }
    setLoading(false);
    return result;
  };

  return (
    <>
      <BaseModal
        data-cy="patient-self-direct"
        details={renderDetails(patient)}
        editable={editable}
        title={translate('personalDetails.selfDirecting')}
        loadingSubmit={loading}
        onCancel={() => setSelfDirecting(patient.selfDirecting)}
        onSubmit={submit}
      >
        <Select.Choice
          disableClearable
          label={translate('personalDetails.isClientSelfDirecting')}
          onChange={(value) => setSelfDirecting(!!value)}
          value={isSelfDirecting}
        />
      </BaseModal>
    </>
  );
});
