export class InsuranceMLTC {
  id = 0;

  name = '';

  mltcCode = '';

  mltcId = '';

  mltcType = '';

  state = '';

  createdAt = 0;

  updatedAt = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default InsuranceMLTC;
