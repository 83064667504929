import { IncidentDetail } from './IncidentDetail';
import { IncidentDetailEmail } from './IncidentDetailEmail';
import { IncidentDetailMember } from './IncidentDetailMember';
import { make, makeArray } from './Model';

export class IncidentDetailResponse {
  incident: IncidentDetail;

  memberDetail: IncidentDetailMember;

  emails: IncidentDetailEmail[];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.incident = make(obj.incident, IncidentDetail);
    this.memberDetail = make(obj.memberDetail, IncidentDetailMember);
    this.emails = makeArray(obj.emails, IncidentDetailEmail)?.reverse();
  }
}

export default IncidentDetailResponse;
