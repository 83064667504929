import React, { useEffect, useMemo, useState } from 'react';

import { makeStyles } from '@mui/styles';

import { RPMAdherenceLevel, States } from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';

import {
  FilterItem,
  FilterSelectable,
  Label,
  Select,
  TextInput,
} from 'styleguide-v2';

import { GetCCMMemberParams } from 'dash/src/services/CCMServices';
import { useDebounce } from 'dash/src/utils/debounceUtils';
import { getFilterItem } from 'dash/src/utils/filterUtils';

import { RPM_START_OPTIONS } from './CCMData';

type KeyGetCCMParams =
  | keyof GetCCMMemberParams
  | 'rpmStartDate'
  | 'lastReadingDate';

type Props = {
  data: {
    languages: Selectable[];
  };
  filters?: {
    [x in KeyGetCCMParams]?: FilterItem;
  };
  loading?: boolean;
  onChange: (
    filters: {
      [x in KeyGetCCMParams]?: FilterItem;
    },
  ) => void;
};

const useStyles = makeStyles({
  minutesLabel: {
    '&&': {
      alignSelf: 'center',
      flex: '0 0 fit-content',
      marginLeft: '1rem',
      minWidth: 0,
    },
  },
  minutes: {
    '&&': {
      minWidth: 0,
      flex: '0 0 6rem',
    },
  },
});

export const RPMFiltersBar = ({ data, filters, loading, onChange }: Props) => {
  const styles = useStyles();

  const defaultFrom = useMemo(
    () => (filters?.rpmMinutesFrom?.value as FilterSelectable)?.value as string,
    [filters?.rpmMinutesFrom],
  );
  const defaultTo = useMemo(
    () => (filters?.rpmMinutesTo?.value as FilterSelectable)?.value as string,
    [filters?.rpmMinutesTo],
  );

  const [fromMinutes, setFromMinutes] = useState<string>();
  const [toMinutes, setToMinutes] = useState<string>();

  const fromDebounce = useDebounce(fromMinutes, 500);
  const toDebounce = useDebounce(toMinutes, 500);

  useEffect(() => {
    let rpmMinutesFrom: FilterItem | undefined;
    let rpmMinutesTo: FilterItem | undefined;
    if (fromDebounce) {
      const number =
        fromDebounce !== undefined ? parseInt(fromDebounce, 10) : NaN;
      rpmMinutesFrom = getFilterItem(
        translate('ccm.common.rpmMinutesFrom'),
        isNaN(number) ? undefined : number,
      );
    }
    if (toDebounce) {
      const number = toDebounce !== undefined ? parseInt(toDebounce, 10) : NaN;
      rpmMinutesTo = getFilterItem(
        translate('ccm.common.rpmMinutesTo'),
        isNaN(number) ? undefined : number,
      );
    }
    if (defaultFrom !== fromDebounce || defaultTo !== toDebounce) {
      onChange({
        ...filters,
        rpmMinutesFrom,
        rpmMinutesTo,
      });
    }
  }, [fromDebounce, toDebounce]);

  useEffect(() => {
    setFromMinutes(defaultFrom);
    setToMinutes(defaultTo);
  }, [filters?.rpmMinutesFrom, filters?.rpmMinutesTo]);

  return (
    <>
      <Select
        allowAddNewValue
        data-cy="ccm-filter-bar-member-id"
        items={[]}
        inputType="number"
        multiple
        noOptionsText={translate('members.typeToSearch')}
        onChange={(values: Selectable[]) =>
          onChange({
            ...filters,
            memberIds: getFilterItem(
              translate('ccm.common.memberId'),
              values.map(({ label }) => ({
                value: label,
                label,
              })),
            ),
          })
        }
        placeholder={translate('ccm.common.memberId')}
        size="xs"
        value={filters?.memberIds?.value || []}
      />
      <Select
        data-cy="ccm-filter-bar-state"
        items={Enum.toSelectable(States.asArray)}
        limitTags={1}
        multiple
        onChange={(values: Selectable[]) =>
          onChange({
            ...filters,
            state: getFilterItem(translate('ccm.common.state'), values),
          })
        }
        placeholder={translate('ccm.common.state')}
        placeholderV2
        size="xs"
        value={filters?.state?.value || []}
      />
      <Select
        data-cy="ccm-filter-bar-language"
        items={data.languages.filter(({ disabled }) => !disabled)}
        grouped={({ preferred }) =>
          preferred ? translate('global.preferred') : translate('global.other')
        }
        limitTags={1}
        loading={loading}
        multiple
        onChange={(values: Selectable[]) =>
          onChange({
            ...filters,
            language: getFilterItem(translate('ccm.common.language'), values),
          })
        }
        placeholder={translate('ccm.common.language')}
        placeholderV2
        size="xs"
        value={filters?.language?.value || []}
      />
      <Select
        data-cy="ccm-filter-care-rpm-adherence"
        items={Enum.toSelectable(RPMAdherenceLevel.asArray)}
        limitTags={1}
        multiple
        onChange={(values: Selectable[]) =>
          onChange({
            ...filters,
            rpmAdherenceId: getFilterItem(
              translate('ccm.common.rpmAdherence'),
              values,
            ),
          })
        }
        placeholder={translate('ccm.common.rpmAdherence')}
        placeholderV2
        size="xs"
        value={filters?.rpmAdherenceId?.value || []}
      />
      <Select
        data-cy="ccm-filter-bar-rpm-start-date"
        getItemLabel={({ label }: Selectable) =>
          `${translate('ccm.common.rpmStartDate')}: ${label}`
        }
        items={RPM_START_OPTIONS}
        onChange={(value?: Selectable) =>
          onChange({
            ...filters,
            rpmStartDateFrom: undefined,
            rpmStartDateTo: undefined,
            rpmStartDate: value
              ? {
                  label: translate('ccm.common.rpmStartDate'),
                  value: {
                    label: value.label,
                    value: value.value,
                  },
                }
              : undefined,
          })
        }
        placeholder={translate('ccm.common.rpmStartDate')}
        placeholderV2
        renderOption={({ label }: Selectable) => label}
        size="xs"
        value={
          filters?.rpmStartDate
            ? RPM_START_OPTIONS.find(
                ({ value }) =>
                  // eslint-disable-next-line eqeqeq
                  value ==
                  (filters?.rpmStartDate?.value as FilterSelectable)?.value,
              )
            : undefined
        }
      />
      <Label className={styles.minutesLabel} noMargin>
        {translate('ccm.common.rpmTotalMinutes')}:
      </Label>
      <TextInput
        className={styles.minutes}
        maxLength={4}
        onChange={setFromMinutes}
        placeholder={translate('global.from')}
        value={fromMinutes}
        size="xs"
      />
      <TextInput
        className={styles.minutes}
        maxLength={4}
        onChange={setToMinutes}
        placeholder={translate('global.to')}
        value={toMinutes}
        size="xs"
      />
    </>
  );
};
