import classnames from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { CareTeamPerson } from '@vestahealthcare/common/models';

import { Fonts } from 'styleguide-v2/src/styles/Variables';

type Props = {
  className?: string;
  'data-cy'?: string;
  person: CareTeamPerson;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  name: {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    fontWeight: 500,
  },
  extraInfo: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 400,
  },
});

export const SelectCareTeamPersonOption = ({
  className,
  'data-cy': dataCy,
  person,
}: Props) => {
  const styles = useStyles();
  const classes = classnames(className, styles.container);

  const renderCTPDetails = ({ id }: CareTeamPerson) => {
    const idString = `${translate('components.select.id')} ${id}`;

    return <span className={styles.extraInfo}>{idString}</span>;
  };

  if (person.id === -1) {
    return <>{person.fullName}</>;
  }

  return (
    <div className={classes} data-cy={dataCy}>
      <span className={styles.name}>{person.fullName}</span>
      {renderCTPDetails(person)}
    </div>
  );
};

export default SelectCareTeamPersonOption;
