import React from 'react';
import { TableProps } from 'react-table';
import { Select } from 'styleguide';

const PAGE_SIZE_OPTIONS = [
  {
    value: 10,
    label: '10',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100',
  },
];

export const defaultPaginationComponent = (tableProps: Partial<TableProps>) => {
  const {
    onPageChange = () => null,
    onPageSizeChange = () => null,
    page = 0,
    pages = 0,
    pageSize,
  } = tableProps;

  return (
    <div className="flex rt-pagination">
      <div className="flex rt-pagination-nav">
        <button
          className="fa fa-chevron-left"
          disabled={page === 0}
          onClick={() => onPageChange(page - 1)}
        />
        {page + 1}
        {' of '}
        {pages}
        <button
          className="fa fa-chevron-right"
          disabled={page === pages - 1}
          onClick={() => onPageChange(page + 1)}
        />
      </div>
      <div className="flex rt-pagination-size">
        <Select
          value={pageSize}
          onChange={(value: number) => onPageSizeChange(value, page)}
          options={PAGE_SIZE_OPTIONS}
        />
        <span>per page</span>
      </div>
    </div>
  );
};
