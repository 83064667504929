import moment, { Moment } from 'moment';

import { EventStatus } from './EventStatus';
import { EventType } from './EventType';
import { make } from './Model';

export class MemberDashboardEvent {
  type: EventType;

  status: EventStatus;

  date: Moment;

  assignee = '';

  assigneeGroup = '';

  constructor(obj: any) {
    Object.assign(this, obj);
    this.type = make(obj.type, EventType);
    this.status = make(obj.status, EventStatus);
    this.date = moment.unix(obj.date);
  }
}

export default MemberDashboardEvent;
