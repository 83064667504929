import { BaseEnum } from './BaseEnum';
import { make } from './Model';

export class EventInterventionType {
  id = 0;

  abbr = '';

  description = '';

  active = false;

  category: BaseEnum;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.category = make(obj.category, BaseEnum);
    this.category.description = this.category.description.replace(
      / interventions?/i,
      '',
    );
  }

  static ESCALATION_NP_VISIT = 23;

  static getPreferred() {
    return [EventInterventionType.ESCALATION_NP_VISIT];
  }
}

export default EventInterventionType;
