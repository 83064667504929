import moment, { Moment } from 'moment';
import { Colors } from 'styleguide';

export const getNumericQueryParam = (
  query: URLSearchParams,
  param: string,
): number | undefined => {
  const val = query.get(param);
  return val ? Number.parseInt(val, 10) : undefined;
};

export const roundToNearest30 = (now: Moment) => {
  const remainder = 30 - (now.minute() % 30);
  return now.add(remainder, 'minutes');
};

export const getDueDate = (date?: Moment, time?: Moment) => {
  if (!date) return undefined;

  return moment(
    new Date(
      date.year(),
      date.month(),
      date.date(),
      time?.hour() || 0,
      time?.minute() || 0,
    ),
  );
};

// Deprecated
export const colorsForStatus: {
  [key: string]: {
    backgroundColor: string;
    color: string;
  };
} = {
  NOT_STARTED: {
    backgroundColor: Colors.white,
    color: Colors.textBlack,
  },
  ESCALATED: {
    backgroundColor: Colors.white,
    color: Colors.green,
  },
  IN_PROGRESS: {
    backgroundColor: Colors.green,
    color: Colors.white,
  },
  COMPLETED: {
    backgroundColor: Colors.tertiaryBlue,
    color: Colors.white,
  },
  DELAYED: {
    backgroundColor: Colors.white,
    color: Colors.red,
  },
  VOID: {
    backgroundColor: Colors.red,
    color: Colors.white,
  },
};
