import classNames from 'classnames';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import { AddIterationIcon, Button } from 'styleguide';
import { BackgroundColors, Colors, Fonts } from 'styleguide-v2';

import AvatarMenu from 'dash/src/components/Header/AvatarMenu';
import Banner from 'dash/src/components/Header/Banner';
import PatientSearch from 'dash/src/components/Header/PatientSearch';
import {
  getTimeRemaining,
  hasConnection,
  hasUpdateAvailable,
  startTimerAction,
  stopTimerAction,
} from 'dash/src/redux/actions/GlobalActions';
import Session from 'dash/src/services/SessionServices';
import images from 'dash/static/images';

import UnreadMessages from '../../pages/Chats/Chat/UnreadMessages';
import Notifications from './Notifications';
import './main.less';

const mapStateToProps = (state: any) => ({
  isConnected: hasConnection(state),
  updateAvailable: hasUpdateAvailable(state),
  timeRemaining: getTimeRemaining(state),
});

const dispatchers = (dispatch: any) => ({
  stopTimer: () => dispatch(stopTimerAction()),
  startTimer: () => dispatch(startTimerAction()),
});

const checkNetworkConnection = (props: Props) => {
  const { isConnected, startTimer, stopTimer } = props;

  if (isConnected) {
    stopTimer();
  } else {
    startTimer();
  }
};

interface Props {
  isConnected: boolean;
  timeRemaining: number;
  startTimer: Function;
  stopTimer: Function;
  updateAvailable: boolean;
}

const useStyles = makeStyles({
  updateAvailable: {
    backgroundColor: BackgroundColors.lightGreen,
    color: Colors.textWhite,
    cursor: 'pointer',
    fontSize: `calc(${Fonts.fontSize} * 1.125)`,
    height: '5.5rem',
    left: 0,
    lineHeight: '5.5rem',
    pointer: 'cursor',
    position: 'absolute',
    textAlign: 'center',
    top: '-6rem',
    transition: 'top 0.3s',
    width: '100vw',
    zIndex: 100,
  },
  updateAvailableShow: {
    top: 0,
  },
});

export const Header = (props: Props) => {
  const styles = useStyles();
  const { isConnected, stopTimer, timeRemaining } = props;

  useEffect(() => () => stopTimer());

  // todo: check for search permissions
  const showSearchBox = Session.actingUser && !Session.actingUser.isUnknown;

  checkNetworkConnection(props);

  let bannerConfig = {};

  if (!isConnected) {
    bannerConfig = {
      type: 'warning',
      message: `${translate('global.offline')} ${timeRemaining}s...`,
    };
  }

  return (
    <nav id="ht-nav">
      <div
        className={classNames(
          styles.updateAvailable,
          props.updateAvailable && styles.updateAvailableShow,
        )}
        onClick={() => window.location.reload()}
      >
        {translate('global.updateAvailable')}
      </div>
      <Link to="/" id="ht-nav-logo-wrapper" data-cy="ht-nav-home-link">
        <img id="ht-nav-logo" alt="logo" src={images.vestaLogo} />
      </Link>

      {!Session.actingUser.isExternal && (
        <>
          <Banner {...bannerConfig} />
          <div id="ht-nav-controls">
            <UnreadMessages />
            <Button
              color="secondary"
              data-cy="add-member-button"
              id="add-member-btn"
              isPill
              href="/#/ops/add-member"
              style={{ whiteSpace: 'nowrap' }}
            >
              <AddIterationIcon className="margin-right" />
              {translate('global.addMember')}
            </Button>
            {showSearchBox && <PatientSearch />}
            <Notifications />
            {Session.actingUser && (
              <AvatarMenu
                actingUser={Session.actingUser}
                isImpersonating={Session.isImpersonating()}
              />
            )}
          </div>
        </>
      )}
    </nav>
  );
};

export default connect(mapStateToProps, dispatchers)(Header);
