import React, { PureComponent } from 'react';
import { BodySection, BodyPart } from '@vestahealthcare/common/enums';
import BodySectionEl from './BodySection';
import BodyPartEl from './BodyPart';
import PartSelector from './PartSelector';

import './main.less';
import { PopoverPlacement } from '.';

interface Props {
  disabled?: boolean;
  selectedParts: Set<BodyPart>;
  onTogglePart: Function;
  popoverPlacement: PopoverPlacement;
  error?: boolean;
}

interface State {
  selectedParts: Set<BodyPart>;
  popoverPlacement: PopoverPlacement;
  selectedSection?: BodySection;
  hoverPart?: BodyPartEl;
}

export default class Figure extends PureComponent<Props, State> {
  static defaultProps = {
    popoverPlacement: 'auto',
    disabled: false,
  };

  /* These component wrappers need to stay the same so that props are passed
   * down correctly. Also, they can't be put outside of the class since they
   * need access to the `this` context. */
  components = {
    BodyPartEl: ({ part, ...rest }: any) => {
      const { hoverPart } = this.state;
      const { selectedParts } = this.props;

      return (
        <BodyPartEl
          data-cy={part}
          part={part}
          active={selectedParts.has(part)}
          hover={hoverPart === part}
          {...rest}
        />
      );
    },
    BodySectionEl: ({ section, ...rest }: any) => (
      <BodySectionEl
        onClick={(e, popoverPlacement) =>
          this.setSection(section, popoverPlacement)
        }
        svgRef={(ref: SVGGElement) => this.registerRef(section, ref)}
        {...rest}
      />
    ),
  };

  state: State = {
    selectedParts: new Set(),
    popoverPlacement: 'right',
    selectedSection: undefined,
    hoverPart: undefined,
  };

  sectionRefs = new Map();

  setSection(
    selectedSection?: BodySection,
    sectionPopoverPlacement?: PopoverPlacement,
  ) {
    const { disabled, popoverPlacement } = this.props;

    if (disabled) {
      return;
    }

    this.setState({
      selectedSection,
      popoverPlacement:
        popoverPlacement === 'auto'
          ? sectionPopoverPlacement
          : popoverPlacement,
    });
    if (selectedSection === undefined) {
      this.setState({ hoverPart: undefined });
    }
  }

  registerRef(section: BodySection, ref?: SVGGElement | null) {
    this.sectionRefs.set(section, ref);
  }

  togglePart(part: BodyPartEl, event: any) {
    this.props.onTogglePart(part, event);
    this.setState({ selectedSection: undefined });
    this.unhoverPart(part);
  }

  hoverPart(hoverPart: BodyPartEl) {
    this.setState({ hoverPart });
  }

  unhoverPart(part: BodyPartEl) {
    const { hoverPart } = this.state;
    if (part === hoverPart) {
      this.setState({ hoverPart: undefined });
    }
  }

  render() {
    const { disabled, error, selectedParts } = this.props;
    const { selectedSection, popoverPlacement } = this.state;
    const { BodySectionEl, BodyPartEl } = this.components;

    const target = selectedSection
      ? this.sectionRefs.get(selectedSection)
      : null;

    /**
     * ATENCIÓN/WARNING
     *
     * This is a JSX adaptation of an SVG. If this ever needs updating, it will
     * have to be done manually. Please name the elements in the editor using
     * some sort of identifier, so the dev can figure out which part is which.
     * The way <BodyPartEl> works is generating by default a <path>, but that can
     * be changed passing the prop componentClass. Keep in mind you can't write
     * any kind of HTML inside of an SVG, instead of <div> the element is <g>.
     */

    return (
      <div className="figure-overlay-wrapper">
        <PartSelector
          onTogglePart={(part: BodyPartEl, event: any) =>
            this.togglePart(part, event)
          }
          disabled={disabled}
          selectedParts={selectedParts}
          onMouseEnterPart={(part: BodyPartEl) => this.hoverPart(part)}
          onMouseLeavePart={(part: BodyPartEl) => this.unhoverPart(part)}
          onRootClose={() => this.setSection(undefined, undefined)}
          section={selectedSection}
          container={this}
          placement={popoverPlacement}
          target={target}
        />
        <svg
          className={`clinical-figure ${error ? 'error' : ''}`}
          // @ts-ignore -- disabled seems to be a real prop but doesn't exist on the type definition
          disabled={!!disabled}
          viewBox="100 0 561.9 741.1"
        >
          <g className="body-parts">
            <BodySectionEl section={BodySection.FRONT_HEAD_NECK}>
              <BodyPartEl
                part={BodyPart.NECK_FRONT}
                d="M255.4 137.9s-.7 14.1.3 17.3c0 0-7.2 14.2-22.5 14.2s-22.5-14.2-22.5-14.2c1-3.2.3-17.3.3-17.3s4.7 8.1 22.2 8.2c19.2.1 22.2-8.2 22.2-8.2z"
              />
              <BodyPartEl
                part={BodyPart.HEAD_FRONT}
                d="M265.8 109c-.7-1.9-2.6-.7-2.6-.7s1.3-18.4-3.7-30c-5.4-12.7-26.3-12.7-26.3-12.7s-20.9 0-26.3 12.7c-4.9 11.6-3.7 30-3.7 30s-1.9-1.2-2.6.7c-.8 2-.8 9.2 1.4 11s2 6.5 2 7.5 3 2 3.4 0c.1-.5.1-2.8.1-3.1-.3-4.5-2.6-39.1 25.8-39.1 31.5 0 26.2 39.7 26.2 39.7-.1.7-.1 1.4-.2 2.1 0 .1.1.2.1.4.4 2 3.1 1 3.1 0s-.1-5.8 2-7.5 2.1-9 1.3-11z"
              />
              <BodyPartEl
                part={BodyPart.FACE}
                d="M261.1 117.6c0 7.7-1.6 14.2-4.7 19.1-4.5 7.1-8 11.1-22.8 11.1-14.5 0-18.1-3.6-22.4-10-3.4-5-5-11.9-5-20.1 0-18.9 12.3-34.2 27.5-34.2s27.4 15.3 27.4 34.1z"
              />
            </BodySectionEl>
            <BodySectionEl section={BodySection.CHEST_SHOULDERS}>
              <BodyPartEl
                part={BodyPart.CHEST}
                d="M286.7 214.9s-13.4-50.7-52.5-51h-2.3c-39.1.3-52.5 51-52.5 51l1 4.8s-.4 13.8-.2 23.2 3.8 28.3 5.6 39.6c.3 2 9.9 9.4 24.1 8.4 10-.7 12.3-8.7 23.1-8.8 10.8.1 13.2 8.1 23.2 8.8 14.2 1.1 23.8-6.4 24.1-8.4 1.8-11.2 5.4-30.2 5.6-39.6s-.2-23.2-.2-23.2l1-4.8z"
              />
              <BodyPartEl
                part={BodyPart.CENTRAL_CHEST}
                d="M243.6 218.8c.7-9.5 20.8-24 8.9-36.2-5.7-5.9-9.3-7.6-18.5-7.7h-1.7c-9.2.1-13.2 2.1-18.5 7.7-11.7 12.4 8.3 26.7 8.9 36.2.7 10.1-3.8 22.5-3.6 31.1.2 8.6.8 23.4 7.4 27.2 3.9 2.2 10 1.9 13.2 0 6.5-3.8 7.1-18.6 7.4-27.2.3-8.6-4.2-21-3.5-31.1z"
              />
              <BodyPartEl
                part={BodyPart.R_BREAST}
                d="M231.7 238.5c0 12.1-9.8 21.9-21.9 21.9s-21.9-9.8-21.9-21.9c0-12.1 9.8-25 21.9-25s21.9 12.9 21.9 25z"
              />
              <BodyPartEl
                part={BodyPart.L_BREAST}
                d="M278.7 238.5c0 12.1-9.8 21.9-21.9 21.9-12.1 0-21.9-9.8-21.9-21.9 0-12.1 9.8-25 21.9-25 12.1 0 21.9 12.9 21.9 25z"
              />
              <BodyPartEl
                part={BodyPart.R_SHOULDER_FRONT}
                d="M161.7 181.2s-3.4 26.8 16.1 34.1c6.5 2.4 5.7-19.1 20-32.5 14.3-13.4 28.6-16.5 22-19.7-6.1-2.9-8.1-6.5-8.8-9.5 0 0-9.9 4.5-10.9 6.2-2.4 4.4-16.7 10.4-22 12.1-5.3 1.8-11.3 4.2-16.4 9.3z"
              />
              <BodyPartEl
                part={BodyPart.L_SHOULDER_FRONT}
                d="M304.9 181.2s3.4 26.8-16.1 34.1c-6.5 2.4-5.7-19.1-20-32.5-14.3-13.4-28.6-16.5-22-19.7 6.1-2.9 8.1-6.5 8.8-9.5 0 0 9.9 4.5 10.9 6.2 2.4 4.4 16.7 10.4 22 12.1 5.2 1.8 12 4.7 16.4 9.3z"
              />
            </BodySectionEl>
            <BodySectionEl section={BodySection.L_FRONT_ARM}>
              <BodyPartEl
                part={BodyPart.L_UPPER_ARM_FRONT}
                d="M312.3 280c-3.1 4.4-32.9 5.7-31.1-2.3 2.1-9.4 4-16 4.3-20.7.3-4.7 1.3-12.3 1.3-20.3s-.6-15.3-.4-16.5c.2-1.2 1-4.6 1-4.6s6.4-1.4 10.5-6.5c10.5-13.1 5.4-29.4 5.4-29.4 6.3 3 12.7 18.3 13.4 22.8.6 4.4.7 29.6.8 32.1.1 2.5.9 29.1-2 37.5s-2.4 6.8-3.2 7.9z"
              />
              <BodyPartEl
                part={BodyPart.L_FOREARM_FRONT}
                d="M329.7 347.1s-11.6-3.4-21.6 4.2c0 0-9.2-19.3-12.1-26.4-2.8-7.1-12.7-34.8-12.7-34.8s-3.3-8.5-1.8-9.6c8-5.8 29-2.4 30.3.5 1.3 2.9 3.2 4.5 3.9 5.7.7 1.3 6.5 11.2 9.2 27.4 2.7 16.2 4.3 29.7 4.8 33z"
              />
            </BodySectionEl>
            <BodySectionEl section={BodySection.R_FRONT_ARM}>
              <BodyPartEl
                part={BodyPart.R_UPPER_ARM_FRONT}
                d="M154.7 280.2c3.1 4.4 32.9 5.7 31.1-2.3-2.1-9.4-4-16-4.3-20.7-.3-4.7-1.3-12.3-1.3-20.3s.6-15.3.4-16.5c-.2-1.2-1-4.6-1-4.6s-6.4-1.4-10.5-6.5c-10.5-13.1-5.4-29.4-5.4-29.4-6.3 3-12.7 18.3-13.4 22.8s-.7 29.6-.8 32.1c-.1 2.5-.9 29.1 2 37.5s2.5 6.9 3.2 7.9z"
              />
              <BodyPartEl
                part={BodyPart.R_FOREARM_FRONT}
                d="M136.7 347.1s11.6-3.4 21.6 4.2c0 0 9.2-19.3 12.1-26.4 2.8-7.1 12.7-34.8 12.7-34.8s3.3-8.5 1.8-9.6c-8-5.8-29-2.4-30.3.5-1.3 2.9-3.2 4.5-3.9 5.7-.7 1.3-6.5 11.2-9.2 27.4-2.7 16.2-4.3 29.7-4.8 33z"
              />
            </BodySectionEl>
            <BodySectionEl section={BodySection.L_FRONT_HAND}>
              <BodyPartEl
                part={BodyPart.L_HAND_FRONT}
                d="M307.8 350.9c-.7.5 1.6 2.8 2.1 3.3 3.5 3.8 1 17.7 3.2 26 .6 2.3.7 12.2.7 14.7.1 3-.7 8.4 2 8.6 2.9.2 2.9-3.9 2.9-3.9s1.1-15.2 1.6-15.4c.8-.5.8 7.6 1.4 16.3.5 6.6.1 9.7 3.2 9.7 2.4 0 2.9-2.6 2.6-11.5-.2-6.1-.4-12.6-.3-13.9.2-3.4 1.3 0 1.3 0s.2.9 2.2 13.5c2.1 13.3 2.8 14 5.5 13.8 2.8-.2 2.5-3.3 2.3-4.7-.2-1-2.9-16.4-3.6-23.6-.3-3.4.9-1.8.9-1.8s.6.6 4.2 15.3c2.3 9.4 4.2 9.7 6 9.3 3-.6.8-5.8-.3-10.3s-4-15.9-4.6-18.7c-.8-4.1.1-4.3 1.5-3.6s8 8.9 13.3 8.5c5.2-.4 4.7-5.1 1.6-5.7-1.9-.4-9-7.3-12-10.5-.4-.4-2.6-4.7-3.2-5.3-3.5-3.8-9.8-7.7-10.8-8.2-1.1-.6-1.3-1.7-1.7-6.3 0 0-10.7-3.8-22 4.4z"
              />
              <BodyPartEl
                part={BodyPart.L_WRIST_FRONT}
                d="M310.3 354.3c-.5-.5-2.7-2.9-2.1-3.3 11.3-8.2 22-4.4 22-4.4.5 4.5.5 5.3 1.6 5.9 0 0-14.5-.3-20.2 6.7-.5.6-.3-3.8-1.3-4.9z"
              />
              <BodyPartEl
                part={BodyPart.L_FINGERS_FRONT}
                d="M344.2 364.8s11.4 11.6 13.3 12c3.1.6 3.7 5.4-1.6 5.7-5.2.4-11.9-7.8-13.3-8.5s-2.3-.5-1.5 3.6c.6 2.8 3.5 14.2 4.6 18.7s3.3 9.7.3 10.3c-1.8.4-3.7.1-6-9.3-3.6-14.6-4.2-15.3-4.2-15.3s-1.3-1.6-.9 1.8c.7 7.2 3.4 22.6 3.6 23.6.2 1.4.6 4.5-2.3 4.7-2.6.2-3.3-.5-5.5-13.8-2-12.7-2.2-13.5-2.2-13.5s-1.1-3.4-1.3 0c-.1 1.3.1 7.8.3 13.9.3 8.9-.2 11.5-2.6 11.5-3.1 0-2.6-3.1-3.2-9.7-.7-8.8-.6-16.8-1.4-16.3-.5.3-1.6 15.4-1.6 15.4s.1 4.1-2.9 3.9c-2.7-.2-1.9-5.6-2-8.6-.1-2.5-.1-12.4-.7-14.7 0 0-1.1-2.3-.4-2.2.7.2 20 13.2 31.5-13.2z"
              />
            </BodySectionEl>
            <BodySectionEl section={BodySection.R_FRONT_HAND}>
              <BodyPartEl
                part={BodyPart.R_HAND_FRONT}
                d="M158.7 350.9c.7.5-1.6 2.8-2.1 3.3-3.5 3.8-1 17.7-3.2 26-.6 2.3-.7 12.2-.7 14.7-.1 3 .7 8.4-2 8.6-2.9.2-2.9-3.9-2.9-3.9s-1.1-15.2-1.6-15.4c-.8-.5-.8 7.6-1.4 16.3-.5 6.6-.1 9.7-3.2 9.7-2.4 0-2.9-2.6-2.6-11.5.2-6.1.4-12.6.3-13.9-.2-3.4-1.3 0-1.3 0s-.2.9-2.2 13.5c-2.1 13.3-2.8 14-5.5 13.8-2.8-.2-2.5-3.3-2.3-4.7.2-1 2.9-16.4 3.6-23.6.3-3.4-.9-1.8-.9-1.8s-.6.6-4.2 15.3c-2.3 9.4-4.2 9.7-6 9.3-3-.6-.8-5.8.3-10.3 1-4.5 4-15.9 4.6-18.7.8-4.1-.1-4.3-1.5-3.6s-8 8.9-13.3 8.5c-5.2-.4-4.7-5.1-1.6-5.7 1.9-.4 9-7.3 12-10.5.4-.4 2.6-4.7 3.2-5.3 3.5-3.8 9.8-7.7 10.8-8.2 1.1-.6 1.3-1.7 1.7-6.3 0 0 10.7-3.8 22 4.4z"
              />
              <BodyPartEl
                part={BodyPart.R_WRIST_FRONT}
                d="M156.2 354.3c.5-.5 2.7-2.9 2.1-3.3-11.3-8.2-22-4.4-22-4.4-.5 4.5-.5 5.3-1.6 5.9 0 0 14.5-.3 20.2 6.7.5.6.3-3.8 1.3-4.9z"
              />
              <BodyPartEl
                part={BodyPart.R_FINGERS_FRONT}
                d="M122 364.6s-11.4 11.6-13.3 12c-3.1.6-3.7 5.4 1.6 5.7 5.2.4 11.9-7.8 13.3-8.5 1.4-.7 2.3-.5 1.5 3.6-.6 2.8-3.5 14.2-4.6 18.7-1 4.5-3.3 9.7-.3 10.3 1.8.4 3.7.1 6-9.3 3.6-14.6 4.2-15.3 4.2-15.3s1.3-1.6.9 1.8c-.7 7.2-3.4 22.6-3.6 23.6-.2 1.4-.6 4.5 2.3 4.7 2.6.2 3.3-.5 5.5-13.8 2-12.7 2.2-13.5 2.2-13.5s1.1-3.4 1.3 0c.1 1.3-.1 7.8-.3 13.9-.3 8.9.2 11.5 2.6 11.5 3.1 0 2.6-3.1 3.2-9.7.7-8.8.6-16.8 1.4-16.3.5.3 1.6 15.4 1.6 15.4s-.1 4.1 2.9 3.9c2.7-.2 1.9-5.6 2-8.6.1-2.5.1-12.4.7-14.7 0 0 1.1-2.3.4-2.2-.6.3-20 13.3-31.5-13.2z"
              />
            </BodySectionEl>
            <BodySectionEl section={BodySection.ABDOMEN_HIPS_GROIN}>
              <BodyPartEl
                part={BodyPart.ABDOMEN}
                d="M280.6 285.9c-.1-9.3-8.4 4.2-18.2 3.9-13.6-.3-16.4-8.4-29.1-8.4-12.8 0-15.5 8.1-29.1 8.4-9.8.2-18.2-13.2-18.2-3.9-.1 10-3.8 17.2-3.8 17.2s5.1-2.2 8.2 2.1c7.7 10.6 19.5 40.2 43 40.9 23.5-.8 35.3-30.4 43-40.9 3.1-4.3 8.2-2.1 8.2-2.1s-3.9-7.2-4-17.2zm-47.4 22.4c-.8 0-1.4-.8-1.4-1.8s.6-1.8 1.4-1.8c.8 0 1.4.8 1.4 1.8s-.6 1.8-1.4 1.8z"
              />
              <BodyPartEl
                part={BodyPart.HIPS}
                d="M293 349c.1-21.4-7.4-48-8.8-46.3 0 0-5.4-3.7-9.9 3.6-7.5 12-18.9 38.1-41.1 38.6-22.3-.5-33.6-26.5-41.1-38.6-4.5-7.2-9.9-3.6-9.9-3.6-1.4-1.7-8.9 24.8-8.8 46.3.1 13.2-1.7 13.8-.5 13.8.5 0 13.9-6.7 28.6-5.5 14.2 1.1 19.9 5.6 24.3 8.4 2.9 1.8 6.5 1.8 7.3 1.8.8 0 4.5 0 7.3-1.8 4.4-2.7 10.1-7.3 24.3-8.4 14.7-1.2 28.1 5.5 28.6 5.5 1.4 0-.3-.6-.3-13.8z"
              />
              <BodyPartEl
                part={BodyPart.GROIN}
                componentClass="ellipse"
                cx={233.2}
                cy={356.3}
                rx={13.4}
                ry={11.2}
              />
            </BodySectionEl>
            <BodySectionEl section={BodySection.L_FRONT_LEG}>
              <BodyPartEl
                part={BodyPart.L_UPPER_LEG_FRONT}
                d="M293.7 362.7s-.7 37.6-1.8 46.8-4 30.2-6.9 36.4-3.7 18-3.7 18c-19.5-8.7-41.4 3.6-41.4 3.6-.7 1.8-.3-7.2-.6-10.1-.3-2.8-3.8-46.5-4-48.9-.2-2.4-2.6-39.2-1.9-41 .2-.6 2.3.7 5.8-1.6 7.4-4.8 11.7-9.8 30.1-9.8 12.4.1 24.4 6.6 24.4 6.6z"
              />
              <BodyPartEl
                part={BodyPart.L_LOWER_LEG_FRONT}
                d="M268.9 585.2s-13.5 3.7-20.8 0c0 0-2.9-26.3-3.7-34-.8-7.7-1.9-27.2-1.6-32.8.3-5.6 1.7-25.3 1.7-26.9 0-1.6-.3-4.1-.4-4.3-.2-.2 16.2 10.8 36.8-5.2 0 0-1.4.2.4 11.3 1.8 11.2 3.2 25.9-1 40.6s-11.7 47.7-11.4 51.3z"
              />
              <BodyPartEl
                part={BodyPart.L_KNEE}
                d="M239.7 467.2c-1.3.8 2.7 19 4.1 20.6 0 0 18.2 9.7 36.8-5.7 0 0 1.8-5 1.8-8.3 0-4.7-.9-10.5-1.4-11 0-.1-21.6-8.4-41.3 4.4z"
              />
            </BodySectionEl>
            <BodySectionEl section={BodySection.R_FRONT_LEG}>
              <BodyPartEl
                part={BodyPart.R_UPPER_LEG_FRONT}
                d="M172.8 362.7s.7 37.6 1.8 46.8 4 30.2 6.9 36.4 3.7 18 3.7 18c19.5-8.7 41.4 3.6 41.4 3.6.7 1.8.3-7.2.6-10.1.3-2.8 3.8-46.5 4-48.9.2-2.4 2.6-39.2 1.9-41-.2-.6-2.3.7-5.8-1.6-7.4-4.8-11.7-9.8-30.1-9.8-12.4.1-24.4 6.6-24.4 6.6z"
              />
              <BodyPartEl
                part={BodyPart.R_LOWER_LEG_FRONT}
                d="M197.6 585.2s13.5 3.7 20.8 0c0 0 2.9-26.3 3.7-34 .8-7.7 1.9-27.2 1.6-32.8-.3-5.6-1.7-25.3-1.7-26.9 0-1.6.3-4.1.4-4.3.2-.2-16.2 10.8-36.8-5.2 0 0 1.4.2-.4 11.3-1.8 11.2-3.2 25.9 1 40.6s11.7 47.7 11.4 51.3z"
              />
              <BodyPartEl
                part={BodyPart.R_KNEE}
                d="M226.8 467.2c1.3.8-2.7 19-4.1 20.6 0 0-18.2 9.7-36.8-5.7 0 0-1.8-5-1.8-8.3 0-4.7.9-10.5 1.4-11 0-.1 21.5-8.4 41.3 4.4z"
              />
            </BodySectionEl>
            <BodySectionEl section={BodySection.L_FRONT_FOOT}>
              <BodyPartEl
                part={BodyPart.L_FOOT}
                d="M247.8 584.4c-.9-.9.2 6.2.2 7.1-.5 7.6-3.4 4.8-3 8.6.4 4.2.5 4.6-2.1 12.1s1.8 12.6 5.2 13.6c3.3 1 16.4 9.9 18.1 12.1 1.7 2.1 20.7 2.3 24.3-.4 4.2-3.1.2-1.8 4.2-3.1 1.1-.4 6.5-5.6-1.6-9.2-5.9-2.6-18.4-16.5-22.1-22.4-1.1-1.7-1.4-3.4-1.9-8.3-.1-1.4-.2-7.4-.3-9.4-.1 0-16.3 3.9-21-.7z"
              />
              <BodyPartEl
                part={BodyPart.L_ANKLE_FRONT}
                d="M245.3 603.2c-1.8-5.7 2.5-4.1 2.9-11.7.1-.9-1.1-7.9-.2-7.1 4.7 4.5 21 .6 21 .6.2 2 .2 8 .3 9.4.5 4.9 2 8.2 1.7 8.4-17.2 9.1-25.7.4-25.7.4z"
              />
              <BodyPartEl
                part={BodyPart.L_TOES}
                d="M291 623.9s6 3 6.5 5.2c.6 2.3-2 5.2-2.6 5.3-4 1.3 0 0-4.2 3.1-3.6 2.7-22.6 2.5-24.3.4l-2.4-2.1s14.5-.1 27-11.9z"
              />
            </BodySectionEl>
            <BodySectionEl section={BodySection.R_FRONT_FOOT}>
              <BodyPartEl
                part={BodyPart.R_FOOT}
                d="M218.7 584.4c.9-.9-.2 6.2-.2 7.1.5 7.6 3.4 4.8 3 8.6-.4 4.2-.5 4.6 2.1 12.1s-1.8 12.6-5.2 13.6c-3.3 1-16.4 9.9-18.1 12.1-1.7 2.1-20.7 2.3-24.3-.4-4.2-3.1-.2-1.8-4.2-3.1-1.1-.4-6.5-5.6 1.6-9.2 5.9-2.6 18.4-16.5 22.1-22.4 1.1-1.7 1.4-3.4 1.9-8.3.1-1.4.2-7.4.3-9.4 0 0 16.2 3.9 21-.7z"
              />
              <BodyPartEl
                part={BodyPart.R_ANKLE_FRONT}
                d="M221.2 603.2c1.8-5.7-2.5-4.1-2.9-11.7-.1-.9 1.1-7.9.2-7.1-4.7 4.5-21 .6-21 .6-.2 2-.2 8-.3 9.4-.5 4.9-2 8.2-1.7 8.4 17.1 9.1 25.7.4 25.7.4z"
              />
              <BodyPartEl
                part={BodyPart.R_TOES}
                d="M175.4 623.9s-6 3-6.5 5.2c-.6 2.3 2 5.2 2.6 5.3 4 1.3 0 0 4.2 3.1 3.6 2.7 22.6 2.5 24.3.4l2.4-2.1c.1 0-14.5-.1-27-11.9z"
              />
            </BodySectionEl>

            <BodySectionEl
              section={BodySection.BACK_HEAD_NECK}
              popoverPlacement="left"
            >
              <BodyPartEl
                part={BodyPart.HEAD_BACK}
                d="M548.1 108.6c-.7-1.9-2.6-.7-2.6-.7s1.3-18.4-3.7-30C536.3 65.2 515 65.2 515 65.2s-21.3 0-26.8 12.7c-5 11.6-3.7 30-3.7 30s-1.9-1.2-2.6.7c-.8 2-.8 9.2 1.4 11 2.2 1.8 2 6.5 2 7.5s2.8 2 3.2 0c.4-2 .8 2.9 1.4 4.5.3.7 1 2.7 2 4.8.2.5.5 1 .8 1.5.7 1.1 22.4 19.3 44.6.6.3-.5.7-1.6.9-2.1 1.1-2.1 1.7-4.1 2-4.8.6-1.6 1-6.5 1.4-4.5.4 2 3.2 1 3.2 0s-.1-5.8 2-7.5c2-1.7 2-9 1.3-11z"
              />
              <BodyPartEl
                part={BodyPart.NECK_BACK}
                d="M491.8 153.5s7.5 10.6 23.4 10.6c15.9 0 22.8-10.9 22.8-10.9-2.7-10.1-2.1-13.8-2.1-13.8-16.5 12.1-32.8 6-41.4 0-.3-.2-.3 9-2.7 14.1z"
              />
            </BodySectionEl>
            <BodySectionEl section={BodySection.BACK} popoverPlacement="left">
              <BodyPartEl
                part={BodyPart.LOWER_BACK}
                d="M563.9 252.5h-98.5c.7 12.8 1.7 31.7 1.4 36.2-.5 7-9 28.6-7.7 27.9 16.8-9.5 43.1-2.2 47.9 2.2 1.5 1.4 6.3 1.8 7.7 1.8 1.2 0 6.1-.4 7.7-1.8 4.9-4.4 31.2-11.7 47.9-2.2 1.3.7-7.2-20.9-7.7-27.9-.4-4.5.6-23.4 1.3-36.2z"
              />
              <BodyPartEl
                part={BodyPart.UPPER_BACK}
                d="M564.7 240.6c0-1.7-.8-9.7-.7-11.6 0-.4 1.1-7.4.9-8.5-5.4-40.2 20.1-41.6 19.6-41.9-4.4-3.3-10-5.6-14.7-7.1-5.3-1.7-19.5-7.7-22-12.1-.9-1.7-10.9-5.7-10.9-5.7-8.6 9.3-18.8 10.2-22.2 10.3-3.4-.1-13.6-1-22.2-10.3 0 0-10 4.1-10.9 5.7-2.4 4.4-16.7 10.4-22 12.1-4.7 1.5-10.2 3.9-14.7 7.1-.4.3 25.1 1.7 19.6 41.9-.1 1.1.9 8.1.9 8.5.1 1.8-.7 9.9-.7 11.6 0 .6.3 5.6.7 12.2H564c.3-6.6.6-11.6.7-12.2z"
              />
              <BodyPartEl
                part={BodyPart.L_SHOULDER_BACK}
                d="M497.8 160.3c-1.2-2.2-3.9-4.3-5.9-6.6 0 0-9.6 4.7-10.5 6.3-2.4 4.3-16 9.8-21 11.3-4.4 1.3-9.8 4.5-14 7.6-.2.2 20.9 2.9 29.6 34.9 4.6 16.9 18.9 20.5 21.2-2.7 2.9-29.9 10.2-33 .6-50.8z"
              />
              <BodyPartEl
                part={BodyPart.R_SHOULDER_BACK}
                d="M531.8 160.3c1.2-2.2 3.9-4.3 5.9-6.6 0 0 9.6 4.7 10.5 6.3 2.4 4.3 16 9.8 21 11.3 4.4 1.3 9.8 4.5 14 7.6.2.2-20.9 2.9-29.6 34.9-4.6 16.9-18.9 20.5-21.2-2.7-2.9-29.9-10.2-33-.6-50.8z"
              />
              <BodyPartEl
                part={BodyPart.BACK}
                d="M584.4 178.6c-4.4-3.3-10-5.6-14.7-7.1-5.3-1.7-19.5-7.7-22-12.1-.9-1.7-10.9-5.7-10.9-5.7-8.6 9.3-18.8 10.2-22.2 10.3-3.4-.1-13.6-1-22.2-10.3 0 0-10 4.1-10.9 5.7-2.4 4.4-16.7 10.4-22 12.1-4.7 1.5-10.2 3.9-14.7 7.1-.4.3 25.1 1.7 19.6 41.9-.1 1.1.9 8.1.9 8.5.1 1.8-.7 9.9-.7 11.6 0 1.7 2.6 41.5 2.1 48.5s-9 28.6-7.7 27.9c16.8-9.5 43.1-2.2 47.9 2.2 1.5 1.4 6.3 1.8 7.7 1.8 1.2 0 6.1-.4 7.7-1.8 4.9-4.4 31.2-11.7 47.9-2.2 1.3.7-7.2-20.9-7.7-27.9s2.1-46.8 2.1-48.5c0-1.7-.8-9.7-.7-11.6 0-.4 1.1-7.4.9-8.5-5.4-40.2 20.1-41.6 19.6-41.9z"
              />
            </BodySectionEl>
            <BodySectionEl
              section={BodySection.L_BACK_ARM}
              popoverPlacement="left"
            >
              <BodyPartEl
                part={BodyPart.L_UPPER_ARM_BACK}
                d="M435.6 280.4s11.6-4.2 18.3-3c10.3 1.8 13.2 10.2 12.8 5.7-.8-9.5-2.7-38.3-1.9-46.3.8-8.5-.2-15.8-.1-16.7.2-1.6 3.2-19.3-2.4-29.9-6.4-12-17.7-11.1-17.7-11.1-9.5 6.1-13 19.7-13.7 24.2s-.1 29.2-.3 31.7c-.1 2.5-.9 29.1 2 37.5s2.2 6.9 3 7.9z"
              />
              <BodyPartEl
                part={BodyPart.L_ELBOW}
                d="M435.4 279.8s5.2-4.4 15.8-3.6c8.7.6 14.4 6 14.4 6l-3.6 14.1s-16-.5-27.1-14.2c-1.4-1.6.5-2.3.5-2.3z"
              />
              <BodyPartEl
                part={BodyPart.L_FOREARM_BACK}
                d="M435.4 280.4c-1.4 1.8-3.3 5.3-3.8 6.2-.7 1.3-7 11.7-9.7 27.9-2.7 16.2-4.2 28.6-4.7 31.9 0 0 7.7 9.2 22.9 5 0 0 8.4-18.9 11.2-25.9 2.2-5.5 9-24.6 11.9-32.4-11.6 4-22.6-7.3-27.8-12.7z"
              />
            </BodySectionEl>
            <BodySectionEl
              section={BodySection.R_BACK_ARM}
              popoverPlacement="left"
            >
              <BodyPartEl
                part={BodyPart.R_UPPER_ARM_BACK}
                d="M593.7 280.3s-11.6-4.2-18.3-3c-10.3 1.8-13.2 11.2-12.8 6.7.8-9.5 2.7-39.3 1.9-47.3-.8-8.5.2-15.8.1-16.7-.2-1.6-3.2-19.3 2.4-29.9 6.4-12 17.7-11.1 17.7-11.1 9.5 6.1 13 19.7 13.7 24.2.6 4.4.1 29.2.3 31.7.1 2.5.9 29.1-2 37.5s-2.3 6.8-3 7.9z"
              />
              <BodyPartEl
                part={BodyPart.R_ELBOW}
                d="M594 279.8s-5.2-4.4-15.8-3.6c-8.7.6-14.4 6-14.4 6l3.6 14.1s16-.5 27.1-14.2c1.3-1.6-.5-2.3-.5-2.3z"
              />
              <BodyPartEl
                part={BodyPart.R_FOREARM_BACK}
                d="M594.2 280.4c1.4 1.8 3.3 5.3 3.8 6.2.7 1.3 7 11.7 9.7 27.9 2.7 16.2 4 29.5 4.4 32.8 0 0-8.5 8-21.8 4.2 0 0-9.2-19-12-26-2.2-5.5-9-24.6-11.9-32.4 11.7 4 22.7-7.3 27.8-12.7z"
              />
            </BodySectionEl>
            <BodySectionEl
              section={BodySection.L_BACK_HAND}
              popoverPlacement="left"
            >
              <BodyPartEl
                part={BodyPart.L_HAND_BACK}
                d="M427.8 351.1c3.9.8 13-.1 12.4.2-6.4 3.1-2.9 19.5-5.1 27.8-.6 2.3-1.3 13-1.3 15.6-.1 3 .7 8.4-2 8.6-2.9.2-2.9-3.9-2.9-3.9s-1.1-15.2-1.6-15.4c-.8-.5-.8 7.6-1.4 16.3-.5 6.6-.1 9.7-3.2 9.7-2.4 0-2.9-2.6-2.6-11.5.2-6.1.4-12.6.3-13.9-.2-3.4-1.3 0-1.3 0s-.2.9-2.2 13.5c-2.1 13.3-2.8 14-5.5 13.8-2.8-.2-2.5-3.3-2.3-4.7.2-1 2.9-16.4 3.6-23.6.3-3.4-.9-1.8-.9-1.8s-.6.6-4.2 15.3c-2.3 9.4-4.2 9.7-6 9.3-3-.6-.8-5.8.3-10.3 1-4.5 4-15.9 4.6-18.7.8-4.1-.1-4.3-1.5-3.6s-8 8.9-13.3 8.5c-5.2-.4-4.7-5.1-1.6-5.7 1.9-.4 9-7.3 12-10.5.4-.4 2.6-4.7 3.2-5.3 3.5-3.8 9.8-7.7 10.8-8.2 1.1-.6 1.6-1.6 2.1-6.1-.2-.1 2.2 3.2 9.6 4.6z"
              />
              <BodyPartEl
                part={BodyPart.L_WRIST_BACK}
                d="M436.2 360.5c.4-4.3 1.3-7.8 4-9.1.6-.3-8.4.5-12.4-.2-7.4-1.4-9.8-4.7-9.8-4.7-.5 4.5-1 5.5-2.1 6.1-.4.2-1.5.9-3 1.9 4.6 2.6 13.3 6.6 23.3 6z"
              />
              <BodyPartEl
                part={BodyPart.L_FINGERS_BACK}
                d="M403.3 364.6s-11.4 11.6-13.3 12c-3.1.6-3.7 5.4 1.6 5.7 5.2.4 11.9-7.8 13.3-8.5s1.3-.5.5 3.6c-.6 2.8-2.5 14.2-3.6 18.7-1 4.5-3.3 9.7-.3 10.3 1.8.4 3.7.1 6-9.3 3.6-14.6 4.2-15.3 4.2-15.3s1.3-1.6.9 1.8c-.7 7.2-3.4 22.6-3.6 23.6-.2 1.4-.6 4.5 2.3 4.7 2.6.2 3.3-.5 5.5-13.8 2-12.7 2.2-13.5 2.2-13.5s1.1-3.4 1.3 0c.1 1.3-.1 7.8-.3 13.9-.3 8.9.2 11.5 2.6 11.5 3.1 0 2.6-3.1 3.2-9.7.7-8.8.6-16.8 1.4-16.3.5.3 1.6 15.4 1.6 15.4s-.1 4.1 2.9 3.9c2.7-.2 1.9-5.6 2-8.6.1-2.5.1-12.4.7-14.7 0 0 1.1-2.3.4-2.2-.7.3-20 13.3-31.5-13.2z"
              />
            </BodySectionEl>
            <BodySectionEl
              section={BodySection.R_BACK_HAND}
              popoverPlacement="left"
            >
              <BodyPartEl
                part={BodyPart.R_HAND_BACK}
                d="M601.8 351.3c-3.9.8-13-.1-12.4.2 6.4 3.1 2.9 19.5 5.1 27.8.6 2.3 1.3 13 1.3 15.6.1 3-.7 8.4 2 8.6 2.9.2 2.9-3.9 2.9-3.9s1.1-15.2 1.6-15.4c.8-.5.8 7.6 1.4 16.3.5 6.6.1 9.7 3.2 9.7 2.4 0 2.9-2.6 2.6-11.5-.2-6.1-.4-12.6-.3-13.9.2-3.4 1.3 0 1.3 0s.2.9 2.2 13.5c2.1 13.3 2.8 14 5.5 13.8 2.8-.2 2.5-3.3 2.3-4.7-.2-1-2.9-16.4-3.6-23.6-.3-3.4.9-1.8.9-1.8s.6.6 4.2 15.3c2.3 9.4 4.2 9.7 6 9.3 3-.6.8-5.8-.3-10.3-1-4.5-4-15.9-4.6-18.7-.8-4.1.1-4.3 1.5-3.6 1.4.7 8 8.9 13.3 8.5 5.2-.4 4.7-5.1 1.6-5.7-1.9-.4-9-7.3-12-10.5-.4-.4-2.6-4.7-3.2-5.3-3.5-3.8-9.8-7.7-10.8-8.2-1.1-.6-1.6-1.6-2.1-6.1.2-.2-2.2 3.1-9.6 4.6z"
              />
              <BodyPartEl
                part={BodyPart.R_WRIST_BACK}
                d="M594 360.6c-.4-4.3-1.3-7.8-4-9.1-.6-.3 8.4.5 12.4-.2 7.4-1.4 9.8-4.7 9.8-4.7.5 4.5 1 5.5 2.1 6.1.4.2 1.5.9 3 1.9-4.7 2.7-13.3 6.7-23.3 6z"
              />
              <BodyPartEl
                part={BodyPart.R_FINGERS_BACK}
                d="M626.4 364.6s11.4 11.6 13.3 12c3.1.6 3.7 5.4-1.6 5.7-5.2.4-11.9-7.8-13.3-8.5-1.4-.7-1.3-.5-.5 3.6.6 2.8 2.5 14.2 3.6 18.7 1 4.5 3.3 9.7.3 10.3-1.8.4-3.7.1-6-9.3-3.6-14.6-4.2-15.3-4.2-15.3s-1.3-1.6-.9 1.8c.7 7.2 3.4 22.6 3.6 23.6.2 1.4.6 4.5-2.3 4.7-2.6.2-3.3-.5-5.5-13.8-2-12.7-2.2-13.5-2.2-13.5s-1.1-3.4-1.3 0c-.1 1.3.1 7.8.3 13.9.3 8.9-.2 11.5-2.6 11.5-3.1 0-2.6-3.1-3.2-9.7-.7-8.8-.6-16.8-1.4-16.3-.5.3-1.6 15.4-1.6 15.4s.1 4.1-2.9 3.9c-2.7-.2-1.9-5.6-2-8.6-.1-2.5-.1-12.4-.7-14.7 0 0-1.1-2.3-.4-2.2.6.3 20 13.3 31.5-13.2z"
              />
            </BodySectionEl>
            <BodySectionEl
              section={BodySection.BUTTOCKS}
              popoverPlacement="left"
            >
              <BodyPartEl
                part={BodyPart.BUTTOCKS}
                d="M570.7 316.9c-.5-1.8-7.6-6.5-23.2-5.8-20 .9-24.4 6.9-28.7 8.3-1.7.5-3.4.6-4.1.6-.9 0-2 .2-4.4-.6-4.3-1.3-8.6-7.3-28.5-8.3-15.6-.7-22.7 4-23.2 5.8-.5 1.6-4 18.1-4.6 45.4 0 .7 21.7 14.4 36.2 16.4 13.1 1.8 22-4.3 24.5-11.4 2.5 7.1 11.4 13.2 24.5 11.4 14.5-2 36.2-15.1 36.2-15.7-.6-27.3-4.2-44.4-4.7-46.1z"
              />
              <BodyPartEl
                part={BodyPart.L_BUTTOCK}
                d="M515 366.5c-2.5 7.1-11.9 13.8-25 12-14.5-2-36.2-15.7-36.2-16.4.6-27.4 4.1-43.8 4.6-45.4.5-1.8 7.6-6.5 23.2-5.8 20 .9 24.1 6.3 26.9 9.9 1.8 2.3 6.5 24.5 6.5 27.9 0 0 .7 16.3 0 17.8z"
              />
              <BodyPartEl
                part={BodyPart.R_BUTTOCK}
                d="M514.6 366.8c2.5 7.1 11.9 13.8 25 12 14.5-2 36.2-15.7 36.2-16.4-.6-27.4-4.1-43.8-4.6-45.4-.5-1.8-7.6-6.5-23.2-5.8-20 .9-24.1 6.3-26.9 9.9-1.8 2.3-6.5 24.5-6.5 27.9 0-.1-1 15.6 0 17.8z"
              />
              <BodyPartEl
                part={BodyPart.SACRUM}
                d="M530.5 317.1c-.5-1.5-2.9-2-3.7-1.7-3.4 1.7-5.6 3.4-7.5 4-2.4.7-3.4.8-4.3.8-.9 0-2.3.1-4.7-.6-1.9-.6-3.7-2-7.1-3.7-.8-.4-3.2-.4-3.7 1.2-2.3 7.1 3.4 30.7 15.5 30.4 12.1.3 17.8-23.3 15.5-30.4z"
              />
            </BodySectionEl>
            <BodySectionEl
              section={BodySection.L_BACK_LEG}
              popoverPlacement="left"
            >
              <BodyPartEl
                part={BodyPart.L_UPPER_LEG_BACK}
                d="M454.3 361.4s.6 40.5 2 49.6c1.4 9.2 5.6 37.7 7.6 44.3 4.7 15.9 1.7 25.7 1.7 25.7l41.9 5.1c.7 1.8 1-24.8 1.3-27.6s4.2-46.5 4.4-48.9c.2-2.4 2.6-40.1 2-41.9-.2-.6-.7.9-3.6 3.9-7.9 8.1-20.8 7.1-32.4 2.7-12.1-4.6-24.9-12.9-24.9-12.9z"
              />
              <BodyPartEl
                part={BodyPart.L_LOWER_LEG_BACK}
                d="M480.9 602.1s13.5-4.7 22.6-.5c0 0-3.3-19.7-.4-32.5 1.7-7.6 1.9-27.2 1.6-32.8-.3-5.6-1.7-25.3-1.7-26.9 0-1.6-.2-8-.1-8.2.1-.1 4.3-14.8 4.3-14.8l-41.7-5.4c-2.7 6.2 1.8 10.8 1 12.7 0 0 .2 6.8-.7 17.8-.9 11.3-2.8 29 1.5 43.8s13.6 39.5 13.6 46.8z"
              />
            </BodySectionEl>
            <BodySectionEl
              section={BodySection.R_BACK_LEG}
              popoverPlacement="left"
            >
              <BodyPartEl
                part={BodyPart.R_UPPER_LEG_BACK}
                d="M575.2 361.4s-.6 40.5-2 49.6c-1.4 9.2-5.6 37.7-7.6 44.3-4.7 15.9-1.7 25.7-1.7 25.7l-41.9 5.1c-.7 1.8-1-24.8-1.3-27.6-.3-2.8-4.2-46.5-4.4-48.9-.2-2.4-2.6-40.1-2-41.9.2-.6.7.9 3.6 3.9 7.9 8.1 20.8 7.1 32.4 2.7 12-4.6 24.9-12.9 24.9-12.9z"
              />
              <BodyPartEl
                part={BodyPart.R_LOWER_LEG_BACK}
                d="M548.4 602.1s-13.5-4.7-22.6-.5c0 0 3.3-19.7.4-32.5-1.7-7.6-1.9-27.2-1.6-32.8s1.7-25.3 1.7-26.9c0-1.6.2-8 .1-8.2-.1-.1-4.3-14.8-4.3-14.8l41.7-5.4c2.7 6.2-1.8 10.8-1 12.7 0 0-.2 6.8.7 17.8.9 11.3 2.8 29-1.5 43.8-4.3 14.7-13.6 39.5-13.6 46.8z"
              />
            </BodySectionEl>
            <BodySectionEl
              section={BodySection.L_BACK_FOOT}
              popoverPlacement="left"
            >
              <BodyPartEl
                part={BodyPart.L_FOOT}
                d="M471.4 607.3s0 9.7 6 16.1c4.5 4.9.2 17.4 20.7 14.5 5.3-.8 9.7-2.1 8.7-16-.3-4 2.7-7.1-1.4-12.7-.7-1-1-7-1.4-8.8s-7-4.9-22.5-.3c0 0 .4 1.8-1.6 2-4.6.4-8.5 5.2-8.5 5.2z"
              />
              <BodyPartEl
                part={BodyPart.L_ANKLE_BACK}
                d="M482.1 621.3s18.1-7.7 24.8 1c.9 1.2 0-11.4-1.4-13.1-.8-1-1-7-1.4-8.8-.4-1.8-7-4.9-22.5-.3 0 0-1.2 4.5-1.2 6.5 0 3.1 1.7 14.7 1.7 14.7z"
              />
              <BodyPartEl
                part={BodyPart.L_HEEL}
                d="M481.4 621c2.4-4.2 22-6.2 24.8 1 1.2 3.1-.3 12.6-2.7 13.7-2.3 1.1-17.3 5-20.1.5-2.8-4.5-2.5-3.7-3.2-5.8-.8-2-2.2-3.6 1.2-9.4z"
              />
            </BodySectionEl>
            <BodySectionEl
              section={BodySection.R_BACK_FOOT}
              popoverPlacement="left"
            >
              <BodyPartEl
                part={BodyPart.R_FOOT}
                d="M558.1 607.7s0 9.7-6 16.1c-4.5 4.9-.2 17.4-20.7 14.5-5.3-.8-9.7-2.1-8.7-16 .3-4-2.7-7.1 1.4-12.7.7-1 1-7 1.4-8.8s7-4.9 22.5-.3c0 0-.4 1.8 1.6 2 4.7.4 8.5 5.2 8.5 5.2z"
              />
              <BodyPartEl
                part={BodyPart.R_ANKLE_BACK}
                d="M547.4 621.6s-18.1-7.7-24.8 1c-.9 1.2 0-11.4 1.4-13.1.8-1 1-7 1.4-8.8.4-1.8 7-4.9 22.5-.3 0 0 1.2 4.5 1.2 6.5 0 3.2-1.7 14.7-1.7 14.7z"
              />
              <BodyPartEl
                part={BodyPart.R_HEEL}
                d="M548 621.8c-2.4-4.2-22-6.2-24.8 1-1.2 3.1.3 12.6 2.7 13.7 2.3 1.1 17.3 5 20.1.5 2.8-4.5 2.5-3.7 3.2-5.8.8-1.9 2.2-3.5-1.2-9.4z"
              />
            </BodySectionEl>
          </g>

          <g className="outlines">
            <path d="M233.2 65.7s-20.9 0-26.3 12.7c-4.9 11.6-3.7 30-3.7 30s-1.9-1.2-2.6.7c-.8 2-.8 9.2 1.4 11s2 6.5 2 7.5 2.8 2 3.1 0 .8 2.9 1.4 4.5c.3.7 1 2.7 2 4.8 1.3 2.7 3.3 5.7 5.8 6.3m-5.2-5.3s.7 14.1-.3 17.3" />
            <path d="M211.1 153.3s-8.6 3.2-11.4 7.3-19.8 11.1-22.8 11.6-26.5 9.4-27.5 37.1c-.2 6.9.4 20.2-.2 27.7-1.7 22 3 42 5 43.5 1.7 1.3-8.3 4-14.3 41.5-4.2 26.3-2.8 29.5-4.9 30.7-1 .6-7.3 4.4-10.8 8.2-.5.6-2.8 4.8-3.2 5.3-3 3.2-10.1 10.1-12 10.5-3.1.6-3.7 5.4 1.6 5.7 5.2.4 11.9-7.8 13.3-8.5s2.3-.5 1.5 3.6c-.6 2.8-3.5 14.2-4.6 18.7-1 4.5-3.3 9.7-.3 10.3 1.8.4 3.7.1 6-9.3 3.6-14.6 4.2-15.3 4.2-15.3s1.3-1.6.9 1.8c-.7 7.2-3.4 22.6-3.6 23.6-.2 1.4-.6 4.5 2.3 4.7 2.6.2 3.3-.5 5.5-13.8 2-12.7 2.2-13.5 2.2-13.5s1.1-3.4 1.3 0c.1 1.3-.1 7.8-.3 13.9-.3 8.9.2 11.5 2.6 11.5 3.1 0 2.6-3.1 3.2-9.7.7-8.8.6-16.8 1.4-16.3.5.3 1.6 15.4 1.6 15.4s-.1 4.1 2.9 3.9c2.7-.2 1.9-5.6 2-8.6.1-2.5.1-12.4.7-14.7 2.2-8.3-.3-22.1 3.2-26 4.4-4.9 27.8-62.3 29.1-76m-6.3-62.8s.8 6.5 3.7 9.7" />
            <path d="M233.2 367.5c.5 9-3.8 71-5 81.5s-1.2 10.1-1.3 20.3c-.1 10.6-4.5 18.2-4.8 20.8s1 11.8 1.8 31-5.4 55.8-5.6 67.3c-.2 11.5 3.5 7.6 3.1 11.8s-.5 4.6 2.1 12.1-1.8 12.6-5.2 13.6c-3.3 1-16.4 9.9-18.1 12.1-1.7 2.1-20.7 2.3-24.3-.4-4.2-3.1-.2-1.8-4.2-3.1-1.1-.4-6.5-5.6 1.6-9.2 5.9-2.6 18.4-16.5 22.1-22.4 1.4-2.2 1.5-4.4 2.3-13.3.8-9-7.3-37.2-12.3-60.2s3-40.2 0-48-.3-11.8 0-19.5-9.5-24-11.8-66.2 1-65.6 3.3-78c2.4-13.2 13.1-26.8 7.1-48s-3.7-49.3-3.7-49.3m52.9-154.7s20.9 0 26.3 12.7c4.9 11.6 3.7 30 3.7 30s1.9-1.2 2.6.7c.8 2 .8 9.2-1.4 11s-2 6.5-2 7.5-2.8 2-3.1 0-.8 2.9-1.4 4.5c-.3.7-1 2.7-2 4.8-1.3 2.7-3.3 5.7-5.8 6.3m5.3-5.3s-.7 14.1.3 17.3" />
            <path d="M255.4 153.3s8.6 3.2 11.4 7.3 19.8 11.1 22.8 11.6 26.5 9.4 27.5 37.1c.2 6.9-.4 20.2.2 27.7 1.7 22-3 42-5 43.5-1.7 1.3 8.3 4 14.3 41.5 4.2 26.3 2.8 29.5 4.9 30.7 1 .6 7.3 4.4 10.8 8.2.5.6 2.8 4.8 3.2 5.3 3 3.2 10.1 10.1 12 10.5 3.1.6 3.7 5.4-1.6 5.7-5.2.4-11.9-7.8-13.3-8.5s-2.3-.5-1.5 3.6c.6 2.8 3.5 14.2 4.6 18.7 1 4.5 3.3 9.7.3 10.3-1.8.4-3.7.1-6-9.3-3.6-14.6-4.2-15.3-4.2-15.3s-1.3-1.6-.9 1.8c.7 7.2 3.4 22.6 3.6 23.6.2 1.4.6 4.5-2.3 4.7-2.6.2-3.3-.5-5.5-13.8-2-12.7-2.2-13.5-2.2-13.5s-1.1-3.4-1.3 0c-.1 1.3.1 7.8.3 13.9.3 8.9-.2 11.5-2.6 11.5-3.1 0-2.6-3.1-3.2-9.7-.7-8.8-.6-16.8-1.4-16.3-.5.3-1.6 15.4-1.6 15.4s.1 4.1-2.9 3.9c-2.7-.2-1.9-5.6-2-8.6-.1-2.5-.1-12.4-.7-14.7-2.2-8.3.3-22.1-3.2-26-4.4-4.9-27.8-62.3-29.1-76m6.3-62.8s-.8 6.5-3.7 9.7" />
            <path d="M233.2 367.5c-.5 9 3.8 71 5 81.5 1.2 10.5 1.2 10.1 1.3 20.3.1 10.6 4.5 18.2 4.8 20.8s-1 11.8-1.8 31 5.4 55.8 5.6 67.3c.2 11.5-3.5 7.6-3.1 11.8.4 4.2.5 4.6-2.1 12.1s1.8 12.6 5.2 13.6c3.3 1 16.4 9.9 18.1 12.1 1.7 2.1 20.7 2.3 24.3-.4 4.2-3.1.2-1.8 4.2-3.1 1.1-.4 6.5-5.6-1.6-9.2-5.9-2.6-18.4-16.5-22.1-22.4-1.4-2.2-1.5-4.4-2.3-13.3-.8-9 7.3-37.2 12.3-60.2 5-23-3-40.2 0-48 3-7.8.3-11.8 0-19.5s9.5-24 11.8-66.2-1-65.6-3.3-78c-2.4-13.2-13.1-26.8-7.1-48s3.7-49.3 3.7-49.3" />
            <ellipse cx={233.2} cy={306.6} rx={1.4} ry={1.8} />
            <path d="M406.2 372.9c-1.4.7-9.4 9.9-14.7 9.5-5.2-.4-4.7-5.1-1.6-5.7 1.9-.4 9-7.3 12-10.5.4-.4 2.6-4.7 3.2-5.3 3.5-3.8 9.8-7.7 10.8-8.2 2.2-1.2.7-4.3 4.9-30.7 6-37.5 16.1-40.2 14.3-41.5-2-1.5-6.7-21.5-5-43.5.6-7.4-.1-20.8.2-27.7 1-27.7 24.5-36.6 27.5-37.1s19.9-7.5 22.8-11.6 11.4-7.3 11.4-7.3 2.6-8.6 2.3-12.1c-.3-3.5-1.5-1.7-2.9-4.4-1-2.1-1.7-4.1-2-4.8-.6-1.6-1-6.5-1.4-4.5s-3.1 1-3.1 0 .1-5.8-2-7.5-2.1-9-1.4-11c.7-1.9 2.6-.7 2.6-.7" />
            <path d="M466.7 278.2c-1.3 13.7-24.7 71.1-29.1 76-3.5 3.8-1 17.7-3.2 26-.6 2.3-.7 12.2-.7 14.7-.1 3 .7 8.4-2 8.6-2.9.2-2.9-3.9-2.9-3.9s-1.1-15.2-1.6-15.4c-.8-.5-.8 7.6-1.4 16.3-.5 6.6-.1 9.7-3.2 9.7-2.4 0-2.9-2.6-2.6-11.5.2-6.1.4-12.6.3-13.9-.2-3.4-1.3 0-1.3 0s-.2.9-2.2 13.5c-2.1 13.3-2.8 14-5.5 13.8-2.8-.2-2.5-3.3-2.3-4.7.2-1 2.9-16.4 3.6-23.6.3-3.4-.9-1.8-.9-1.8s-.6.6-4.2 15.3c-2.3 9.4-4.2 9.7-6 9.3-3-.6-.8-5.8.3-10.3 1-4.5 3.1-15.8 3.6-18.6.4-2.1 1.7-7.3 2-9" />
            <path d="M464.1 219.8c.5 4.8 1.5 6.2.8 14.2s1.8 44.2 1.8 44.2c1.5 16.4-6.6 28.1-8.7 39.3-2.3 12.4-5.6 35.8-3.3 78s12 68.5 11.8 76.2-3 11.8 0 19.5c3 7.8-5 35 0 58s15.3 44.6 14.5 53.6c0 0-2 3.5-.9 7.2 1.2 3.7 1.3 3.8.9 6" />
            <path d="M514.8 367.8c.5 9-4.4 70.7-5.5 81.2-1.2 10.5-1.2 20.1-1.3 30.3-.1 10.6-4.5 18.2-4.8 20.8s1 21.8 1.8 41c.4 9.6-1 23.6-2.4 36.5s1.5 23.3 2.6 31.7c0 0 3.2 4.9 2.2 8.5s-.2 9.2-.7 13.6c-.5 4.3-3.7 6.7-12.7 7-9 .3-11.8-1.7-13-6.2s-3.5-8.5-5.5-10.7-5.1-12.4-4.1-14.4 5.8-4.7 9.6-5m64.1-490s2.2-22.2-3.4-33.8c-6-12.4-26.6-12.7-26.6-12.7h-.9s-20.5.2-26.4 12.7c-5.6 11.6-3.4 33.8-3.4 33.8" />
            <path d="M623.4 372.9c1.4.7 9.4 9.9 14.7 9.5 5.2-.4 4.7-5.1 1.6-5.7-1.9-.4-9-7.3-12-10.5-.4-.4-2.6-4.7-3.2-5.3-3.5-3.8-9.8-7.7-10.8-8.2-2.2-1.2-.7-4.3-4.9-30.7-6-37.5-16.1-40.2-14.3-41.5 2-1.5 6.7-21.5 5-43.5-.6-7.4.1-20.8-.2-27.7-1-27.7-24.5-36.6-27.5-37.1s-19.9-7.5-22.8-11.6-11.4-7.3-11.4-7.3-2.6-8.6-2.3-12.1c.3-3.5 1.5-1.7 2.9-4.4 1-2.1 1.7-4.1 2-4.8.6-1.6 1-6.5 1.4-4.5s3.1 1 3.1 0-.1-5.8 2-7.5 2.1-9 1.4-11c-.7-1.9-2.6-.7-2.6-.7" />
            <path d="M562.9 278.2c1.3 13.7 24.7 71.1 29.1 76 3.5 3.8 1 17.7 3.2 26 .6 2.3.7 12.2.7 14.7.1 3-.7 8.4 2 8.6 2.9.2 2.9-3.9 2.9-3.9s1.1-15.2 1.6-15.4c.8-.5.8 7.6 1.4 16.3.5 6.6.1 9.7 3.2 9.7 2.4 0 2.9-2.6 2.6-11.5-.2-6.1-.4-12.6-.3-13.9.2-3.4 1.3 0 1.3 0s.2.9 2.2 13.5c2.1 13.3 2.8 14 5.5 13.8 2.8-.2 2.5-3.3 2.3-4.7-.2-1-2.9-16.4-3.6-23.6-.3-3.4.9-1.8.9-1.8s.6.6 4.2 15.3c2.3 9.4 4.2 9.7 6 9.3 3-.6.8-5.8-.3-10.3-1-4.5-3.1-15.8-3.6-18.6-.4-2.1-1.7-7.3-2-9M471 371.6s34.6 18.4 43.7-5.6m0-18.5v20.8m43.6 3.3s-34.6 18.4-43.7-5.6" />
            <path
              d="M128.3 371.6s9.6-1.4 10.5-12.7m200 12.7s-9.6-1.4-10.5-12.7"
              fill="none"
              stroke="#231f20"
              strokeMiterlimit={10}
            />
            <path d="M565.3 220.1c-.5 4.8-1.5 6.2-.8 14.2s-1.8 44.2-1.8 44.2c-1.5 16.4 6.6 28.1 8.7 39.3 2.3 12.4 5.6 35.8 3.3 78s-12 68.5-11.8 76.2 3 11.8 0 19.5c-3 7.8 5 35 0 58s-15.3 44.6-14.5 53.6c0 0 2 3.5.9 7.2-1.2 3.7-1.3 3.8-.9 6" />
            <path d="M514.7 368.2c-.5 9 4.4 70.7 5.5 81.2 1.2 10.5 1.2 20.1 1.3 30.3.1 10.6 4.5 18.2 4.8 20.8s-1 21.7-1.8 41c-.4 9.6 1 23.6 2.4 36.5s-1.5 23.3-2.6 31.7c0 0-3.2 4.9-2.2 8.5 1 3.6.2 9.2.7 13.6.5 4.3 3.7 6.7 12.7 7 9 .3 11.8-1.7 13-6.2 1.2-4.5 3.5-8.5 5.5-10.7 2-2.2 5.1-12.4 4.1-14.4s-5.8-4.7-9.6-5" />
          </g>
        </svg>
      </div>
    );
  }
}
