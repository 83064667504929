import { PhoneNumberUtil } from 'google-libphonenumber';

const validateIsPositiveInteger = (value: any): boolean => {
  const number = +value;
  return validateIsPositiveNumeric(value) && Number.isInteger(number);
};

const validateIsPositiveNumeric = (value: any): boolean => {
  const number = +value;
  return !isNaN(number) && isFinite(number) && number > 0;
};

const validateMinMax = (value: any): boolean =>
  // comes as e.g. "120/60"
  !!value &&
  value
    .split('/')
    .every(
      (part: string) =>
        !!part &&
        validateIsPositiveNumeric(part) &&
        validateIsPositiveInteger(part),
    );

const validatePhoneNumber = (value: string): boolean => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  let phoneNumber;
  try {
    phoneNumber = phoneUtil.parse(value, 'US');
  } catch {
    return false;
  }
  return phoneUtil.isValidNumberForRegion(phoneNumber, 'US');
};

export default {
  validateIsPositiveInteger,
  validateIsPositiveNumeric,
  validateMinMax,
  validatePhoneNumber,
};
