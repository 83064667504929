import React from 'react';
import { PhoneNumber } from 'styleguide';
import { EMPTY } from '@vestahealthcare/common/utils/constants';
import { WidgetProps } from '../types';

const PhoneNumberWidget = (props: WidgetProps) => {
  const {
    formContext: { readonly },
    rawErrors,
    required,
  }: WidgetProps = props;

  if (readonly) {
    if (!props.value) {
      return <p>{EMPTY}</p>;
    }

    return <p>{props.value}</p>;
  }

  const hasErrors = !!rawErrors && !!rawErrors.length;
  const errorText = hasErrors && rawErrors ? rawErrors[0] : undefined;

  return (
    <PhoneNumber
      errorText={errorText}
      value={props.value || ''}
      onChange={props.onChange}
      required={required}
      showError={hasErrors}
    />
  );
};

export default PhoneNumberWidget;
