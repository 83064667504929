/* eslint-disable max-classes-per-file */
import { Enum } from './Enum';

export class MessageStatus extends Enum {
  static asArray: MessageStatus[] = [];

  static byKey: { [name: string]: MessageStatus } = {};

  static readonly DELIVERED = new MessageStatus('DELIVERED');

  static readonly ERROR = new MessageStatus('ERROR');

  static readonly SENT = new MessageStatus('SENT');

  constructor(public readonly value: string) {
    super();
    MessageStatus.asArray.push(this);
    MessageStatus.byKey[value] = this;
  }
}

export class MessageType extends Enum {
  translationKey = 'messageType';

  static asArray: MessageType[] = [];

  static byKey: { [name: string]: MessageType } = {};

  static readonly SMS_APP_INVITE = new MessageType('SMS_APP_INVITE');

  static readonly SMS_INBOUND = new MessageType('SMS_INBOUND');

  static readonly SMS_OUTBOUND = new MessageType('SMS_OUTBOUND');

  constructor(public readonly value: string) {
    super();
    MessageType.asArray.push(this);
    MessageType.byKey[value] = this;
  }
}

export class MessageUserType extends Enum {
  translationKey = 'messaging';

  static asArray: MessageUserType[] = [];

  static byKey: { [name: string]: MessageUserType } = {};

  static readonly CAREGIVER = new MessageUserType('CAREGIVER');

  static readonly MEMBER = new MessageUserType('MEMBER');

  static readonly CARE_TEAM = new MessageUserType('CARE_TEAM');

  constructor(public readonly value: string) {
    super();
    MessageUserType.asArray.push(this);
    MessageUserType.byKey[value] = this;
  }
}
