import { HealthReport } from '@vestahealthcare/common/models';

import Api from 'dash/src/services/Api';

import { PaginatedResponse } from './index';

export const fetchHealthReports = async (
  from: string,
  to: string,
  offset?: number,
  limit?: number,
  completed?: boolean,
): Promise<PaginatedResponse<HealthReport>> => {
  const {
    healthCheckIns: { items, pagination },
  } = await Api.getv2('health-check-ins', {
    from,
    to,
    offset,
    limit,
    completed,
  });
  return { items: items.map((i: any) => new HealthReport(i)), pagination };
};
