import classNames from 'classnames';
import React, { CSSProperties, memo } from 'react';

import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Colors } from 'styleguide-v2/src/styles/Colors';
import { Fonts } from 'styleguide-v2/src/styles/Variables';

type ColorOptions = 'green' | 'gray' | 'info';
type SizeOptions = 'xs' | 'sm' | 'm' | 'lg' | 'xl';

interface Props {
  children?: any;
  className?: string;
  color?: ColorOptions;
  disabled?: boolean;
  'data-cy'?: string;
  onClick?: () => void;
  size?: SizeOptions;
  style?: CSSProperties;
}

const useStyles = makeStyles({
  icon: {
    display: 'inline-flex',
    alignContent: 'center',
    justifyContent: 'center',
    fontStyle: 'normal',
  },
});

const colorStyles = makeStyles({
  green: {
    color: Colors.iconGreen,
  },
  gray: {
    color: Colors.iconGray,
  },
  info: {
    color: Colors.blue,
  },
});

const sizeStyles = makeStyles({
  xs: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
  },
  sm: {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
  },
  m: {
    fontSize: Fonts.fontSize,
  },
  lg: {
    fontSize: `calc(${Fonts.fontSize} * 1.125)`,
  },
  xl: {
    fontSize: `calc(${Fonts.fontSize} * 1.25)`,
  },
});

export const Icon = memo(
  ({
    className,
    children,
    color = 'gray',
    'data-cy': dataCy = 'icon',
    disabled,
    onClick,
    size = 'm',
    style,
  }: Props) => {
    const styles = useStyles();
    const colors = colorStyles();
    const sizes = sizeStyles();
    const classes = classNames(
      'block',
      className,
      styles.icon,
      colors[color],
      sizes[size],
      {
        disabled,
      },
    );

    const renderIcon = () => (
      <i className={classes} data-cy={dataCy} style={style}>
        {children}
      </i>
    );

    return onClick ? (
      <IconButton disabled={disabled} onClick={() => onClick()}>
        {renderIcon()}
      </IconButton>
    ) : (
      renderIcon()
    );
  },
);

export default Icon;
