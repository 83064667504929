import { Enum } from './Enum';

export class AsyncTaskLeadStatus extends Enum {
  translationKey = 'asyncTaskLeadStatus';

  static asArray: AsyncTaskLeadStatus[] = [];

  static byKey: { [name: string]: AsyncTaskLeadStatus } = {};

  static readonly DEFAULT = new AsyncTaskLeadStatus('DEFAULT');

  static readonly ON_HOLD = new AsyncTaskLeadStatus('ON_HOLD');

  static readonly RELEASED = new AsyncTaskLeadStatus('RELEASED');

  constructor(public readonly value: string) {
    super();
    AsyncTaskLeadStatus.asArray.push(this);
    AsyncTaskLeadStatus.byKey[value] = this;
  }
}

export default AsyncTaskLeadStatus;
