import { Enum } from './Enum';

export class PatientContactType extends Enum {
  translationKey = 'patientContactType';

  static asArray: PatientContactType[] = [];

  static byKey: { [name: string]: PatientContactType } = {};

  static readonly AIDE = new PatientContactType('AIDE');

  static readonly CARDIOLOGIST = new PatientContactType('CARDIOLOGIST');

  static readonly CARE_MANAGER = new PatientContactType('CARE_MANAGER');

  static readonly CHILD = new PatientContactType('CHILD');

  static readonly DME = new PatientContactType('DME');

  static readonly ENDOCRINOLOGIST = new PatientContactType('ENDOCRINOLOGIST');

  static readonly GRANDDAUGHTER_GRANDSON = new PatientContactType(
    'GRANDDAUGHTER_GRANDSON',
  );

  static readonly HOSPICE = new PatientContactType('HOSPICE');

  static readonly IDT = new PatientContactType('IDT');

  static readonly MSW = new PatientContactType('MSW');

  static readonly NEUROLOGIST = new PatientContactType('NEUROLOGIST');

  static readonly NIECE_NEPHEW = new PatientContactType('NIECE_NEPHEW');

  static readonly OTHER = new PatientContactType('OTHER');

  static readonly OTHER_PHYSICIAN = new PatientContactType('OTHER_PHYSICIAN');

  static readonly OXYGEN = new PatientContactType('OXYGEN');

  static readonly PALLIATIVE_CARE = new PatientContactType('PALLIATIVE_CARE');

  static readonly PCP = new PatientContactType('PCP');

  static readonly PHARMACY = new PatientContactType('PHARMACY');

  static readonly PSYCHIATRIST = new PatientContactType('PSYCHIATRIST');

  static readonly PSYCHOLOGIST = new PatientContactType('PSYCHOLOGIST');

  static readonly PT = new PatientContactType('PT');

  static readonly RHEUMATOLOGIST = new PatientContactType('RHEUMATOLOGIST');

  static readonly RN = new PatientContactType('RN');

  static readonly SELF = new PatientContactType('SELF');

  static readonly SIBLING = new PatientContactType('SIBLING');

  static readonly SPOUSE = new PatientContactType('SPOUSE');

  static readonly ST = new PatientContactType('ST');

  static readonly TRANSPORTATION = new PatientContactType('TRANSPORTATION');

  static readonly UROLOGIST = new PatientContactType('UROLOGIST');

  static SINGULAR_NAME = [
    PatientContactType.DME.value,
    PatientContactType.OXYGEN.value,
    PatientContactType.PHARMACY.value,
  ];

  // only these will be displayed on the contacts modal, rest on the personal details page
  static CONTACT_MODAL_TYPES = [PatientContactType.PCP.value];

  constructor(public readonly value: string) {
    super();
    PatientContactType.asArray.push(this);
    PatientContactType.byKey[value] = this;
  }

  get lastNameOptional() {
    return PatientContactType.SINGULAR_NAME.includes(this.value);
  }

  get isSelf() {
    return this.value === PatientContactType.SELF.value;
  }
}

export default PatientContactType;
