import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import classNames from 'classnames';
import moment from 'moment';

import { translate } from '@vestahealthcare/common/i18n';
import {
  DATE_FORMAT_SHORT,
  DATE_FORMAT_SHORT_WITH_TIME,
} from '@vestahealthcare/common/utils/constants';

import { BackgroundColors, Colors } from 'styleguide-v2/src/styles/Colors';
import { MessageStatus, MessageType } from '@vestahealthcare/common/enums';
import { Conversation as ConversationModel } from '@vestahealthcare/common/models';
import { Message } from './Message';

export type MessageModel = {
  automated?: boolean;
  conversation?: ConversationModel;
  date: number;
  id?: number;
  image?: File;
  status?: MessageStatus;
  text?: string;
  type: MessageType;
  user: number;
  userLabel?: string;
};

type User = {
  label: string;
};

type Props = {
  'data-cy'?: string;
  lastConversation?: ConversationModel;
  leftUser: User;
  messages: MessageModel[];
  rightUser: User;
  scrollToIndex?: number | 'last';
  smoothScroll?: boolean;
};

const useStyles = makeStyles({
  container: {},
  '@keyframes changeBackground': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.3,
    },
    '100%': {
      opacity: 1,
    },
  },
  animateMessage: {
    animation: `$changeBackground 600ms`,
    animationDelay: '200ms',
    animationIterationCount: 2,
  },
  message: {
    marginBottom: '1rem',
    maxWidth: '60%',
  },
  rightSide: {
    marginLeft: 'auto',
  },
  dateLine: {
    borderBottom: `1px solid ${Colors.lighterGray}`,
    color: Colors.lightGray,
    lineHeight: '0.1em',
    margin: '4rem 0',
    textAlign: 'center',
    width: '100%',
    '@global': {
      span: {
        background: BackgroundColors.gray,
        fontWeight: 'bold',
        padding: '1rem',
      },
    },
  },
});

const automatedStyles = makeStyles({
  bubble: {
    background: Colors.iconGreen,
    borderRadius: '.5rem',
    color: Colors.white,
    height: '2.37rem',
    padding: '.375rem .375rem',
    position: 'relative',
    width: '2.5rem',
    '&::after': {
      border: '.75rem solid transparent',
      borderLeft: 'none',
      borderTop: `.5rem solid ${Colors.iconGreen}`,
      bottom: '-1.2rem',
      content: '""',
      position: 'absolute',
      right: '1rem',
    },
  },
});

const AutomatedMessageIcon = () => {
  const styles = automatedStyles();
  return (
    <div className={styles.bubble}>
      <SettingsIcon />
    </div>
  );
};

export const Conversation = ({
  'data-cy': dataCy,
  lastConversation,
  leftUser,
  messages,
  rightUser,
  scrollToIndex,
  smoothScroll,
}: Props) => {
  const styles = useStyles();
  const messagesRef: (HTMLDivElement | null)[] = [];

  const scrollToElement = (
    index: number,
    animate?: boolean,
    smooth?: boolean,
  ) =>
    // Allow to render images before scrolling
    setTimeout(() => {
      messagesRef[index]?.classList.remove(styles.animateMessage);
      messagesRef[index]?.scrollIntoView({
        behavior: smooth ? 'smooth' : 'auto',
      });
      if (animate) {
        setTimeout(
          () => messagesRef[index]?.classList.add(styles.animateMessage),
          100,
        );
      }
    }, 10);

  useEffect(() => {
    if (scrollToIndex === 'last') {
      scrollToElement(messages.length - 1, false, smoothScroll);
    } else if (scrollToIndex || scrollToIndex === 0) {
      scrollToElement(scrollToIndex, true, smoothScroll);
    }
  }, [messages]);

  const getColor = ({ type }: MessageModel) =>
    type === MessageType.SMS_INBOUND ? 'primary' : 'secondary';
  const getClasses = ({ type }: MessageModel) =>
    classNames(
      styles.message,
      type === MessageType.SMS_OUTBOUND ? styles.rightSide : '',
    );
  const getUserLabel = ({ automated, type, userLabel }: MessageModel) => {
    if (automated) return translate('global.automatedMessage');
    if (userLabel) return userLabel;
    if (type === MessageType.SMS_INBOUND) return leftUser.label;
    return rightUser.label;
  };

  const shouldDisplayDate = (index: number) =>
    index > 0 &&
    !moment
      .unix(messages[index].date)
      .isSame(moment.unix(messages[index - 1].date), 'day');

  let lastConversationId = 0;
  const shouldDisplayClosedConversation = (
    index: number,
    { conversation }: MessageModel,
  ) => {
    let result = false;
    if (index === 0) {
      lastConversationId = 0;
    }
    if (
      lastConversationId &&
      conversation?.id &&
      conversation?.id !== lastConversationId
    ) {
      result = true;
    }
    if (conversation?.id) {
      lastConversationId = conversation?.id;
    }
    return result;
  };

  const getDate = ({ date }: MessageModel) =>
    moment.unix(date).isSame(moment(), 'day')
      ? translate('global.today')
      : moment.unix(date).format(DATE_FORMAT_SHORT);

  const getMessageStatus = ({ status }: MessageModel) =>
    status === MessageStatus.ERROR ? (
      <span className="error-red pull-right">
        {translate('global.failed').toLocaleUpperCase()}
      </span>
    ) : (
      <></>
    );

  const getClosedConversationLabel = (c: ConversationModel) =>
    c.closedBy?.fullName
      ? translate('global.conversationClosedBy', {
          at: moment.unix(c.closedAt).format(DATE_FORMAT_SHORT_WITH_TIME),
          by: c.closedBy?.fullName || '',
        })
      : translate('global.conversationClosedAt', {
          at: moment.unix(c.closedAt).format(DATE_FORMAT_SHORT_WITH_TIME),
        });

  const getClosedConversation = (message?: MessageModel) => {
    if (message?.conversation) {
      return getClosedConversationLabel(message?.conversation);
    }
    return <></>;
  };

  return (
    <div className={styles.container} data-cy={dataCy}>
      {messages.map((message, index) => (
        <div key={index} ref={(el) => messagesRef.push(el)}>
          {shouldDisplayDate(index) && (
            <div className={styles.dateLine}>
              <span>{getDate(message)}</span>
            </div>
          )}
          {shouldDisplayClosedConversation(index, message) && (
            <div className={styles.dateLine}>
              <span>{getClosedConversation(messages[index - 1])}</span>
            </div>
          )}
          <Message
            color={getColor(message)}
            className={getClasses(message)}
            data-cy={`${dataCy}-${index}`}
            date={message.date}
            startIcon={message.automated && <AutomatedMessageIcon />}
            image={message.image}
            message={message.text}
            user={getUserLabel(message)}
            status={getMessageStatus(message)}
          />
        </div>
      ))}
      {lastConversation && lastConversation.isClosed() && (
        <div className={styles.dateLine}>
          <span>{getClosedConversationLabel(lastConversation)}</span>
        </div>
      )}
      {!messages.length && (
        <div className={styles.dateLine}>
          <span>{translate('global.noResultsFound')}</span>
        </div>
      )}
    </div>
  );
};

export default Conversation;
