import classNames from 'classnames';
import React, { useMemo, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestoreFromTrash from '@mui/icons-material/RestoreFromTrash';
import StarFilledIcon from '@mui/icons-material/Star';
import StarIcon from '@mui/icons-material/StarBorder';
import { ClassNameMap, makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { BaseEnum, CareTeamMember } from '@vestahealthcare/common/models';

import {
  Button,
  CareTeamPersonInfo,
  CollapseIcon,
  Colors,
  IconButton,
  Panel,
  PhoneLink,
  Table,
} from 'styleguide-v2';

import { CareTeamMemberExtraInfo } from 'dash/src/pages/CareTeam/components/CareTeamMemberExtraInfo';
import { CareTeamMemberPermissions } from 'dash/src/pages/CareTeam/components/CareTeamMemberPermissions';

type Props = {
  className?: string;
  members: CareTeamMember[];
  onActivate: (member: CareTeamMember) => void;
  onAdd: () => void;
  onClickPreferred: (member: CareTeamMember) => void;
  onDelete: (member: CareTeamMember) => void;
  onEdit: (member: CareTeamMember) => void;
};

const CARE_TEAM_MEMBER_COLUMNS = (
  { onActivate, onClickPreferred, onEdit, onDelete }: Props,
  styles: ClassNameMap<'startIcon'>,
  active: boolean,
) => [
  {
    headerName: translate('careTeam.common.name'),
    component: (member: CareTeamMember) => (
      <div className="flex">
        {member.active && (
          <div>
            <IconButton
              className={styles.startIcon}
              key={`care-team-member-${member?.id}-preferred`}
              tooltip={translate('careTeam.common.preferred')}
              onClick={() => onClickPreferred(member)}
              size="small"
            >
              {member.preferred ? (
                <StarFilledIcon fontSize="large" />
              ) : (
                <StarIcon fontSize="large" />
              )}
            </IconButton>
          </div>
        )}
        <CareTeamPersonInfo
          link={`#/care-team/${member.careTeamPerson?.id}`}
          person={member.careTeamPerson}
          showLanguage
          showStatus
          smallName
        />
      </div>
    ),
    width: 175,
  },
  {
    headerName: translate('careTeam.common.role'),
    component: ({ typeWithSubType }: CareTeamMember) => (
      <>
        <p className="no-margin bold">{typeWithSubType.type?.description}</p>
        <p className="no-margin gray">{typeWithSubType.subtype}</p>
      </>
    ),
    width: 175,
  },
  {
    headerName: translate('careTeam.common.phones'),
    component: ({ careTeamPerson: { phones } }: CareTeamMember) => (
      <>
        {phones.map(({ phone, primary }) => (
          <p className="no-margin" key={phone.number}>
            <PhoneLink
              phone={phone.number}
              primary={primary}
              type={phone.type?.toString()}
            />
          </p>
        ))}
      </>
    ),
    width: 200,
  },
  {
    headerName: translate('careTeam.common.permissions'),
    component: (member: CareTeamMember) => (
      <div className="flex gap wrap">
        <CareTeamMemberPermissions member={member} />
      </div>
    ),
  },
  {
    headerName: translate('careTeam.common.notes'),
    component: ({ notes }: CareTeamMember) => notes || '',
  },
  {
    headerName: translate(
      `careTeam.common.${active ? 'details' : 'inactiveReason'}`,
    ),
    component: (member: CareTeamMember) =>
      active ? (
        <CareTeamMemberExtraInfo member={member} />
      ) : (
        <span>
          {member.inactiveReason?.id === BaseEnum.OTHER
            ? member.inactiveReasonOther
            : member.inactiveReason?.description}
        </span>
      ),
  },
  {
    headerName: ' ',
    component: (member: CareTeamMember) =>
      active ? (
        <div className="flex">
          <IconButton
            size="small"
            onClick={() => {
              onEdit(member);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton size="small" onClick={() => onDelete(member)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ) : (
        <IconButton
          size="small"
          tooltip={translate('careTeam.common.activate')}
          onClick={() => onActivate(member)}
        >
          <RestoreFromTrash />
        </IconButton>
      ),
    width: 50,
  },
];

const useStyles = makeStyles({
  startIcon: {
    '&&': {
      left: '-4.5rem',
      margin: 'auto -4.25rem auto 0',
      position: 'relative',
    },
  },
  table: {
    '&&.table-empty': {
      padding: '1rem 0',
      textAlign: 'start',
    },
  },
  showInactiveButton: {
    '&&': {
      display: 'flex',
      margin: '0.5rem 0 0 auto',
    },
  },
  inactiveRow: {
    '&&': {
      backgroundColor: `${Colors.lighterGray}!important`,
    },
  },
});

export const CareTeamMemberTable = (props: Props) => {
  const styles = useStyles();
  const { className, members, onDelete, onEdit } = props;

  const activeMembers = members.filter(({ active }) => active);
  const inactiveMembers = members.filter(({ active }) => !active);

  const [showInactive, setShowInactive] = useState<boolean>(false);

  const activecolumns = useMemo(
    () => CARE_TEAM_MEMBER_COLUMNS(props, styles, true),
    [onDelete, onEdit],
  );

  const inactivecolumns = useMemo(
    () => CARE_TEAM_MEMBER_COLUMNS(props, styles, false),
    [onDelete, onEdit],
  );

  return (
    <>
      <Table
        className={classNames(className, styles.table)}
        config={{
          columns: activecolumns,
          compact: true,
          data: activeMembers,
          paginationOptions: [-1],
        }}
        empty
        emptyLink={translate('memberProfile.network.addCareTeam')}
        onClickEmptyLink={props.onAdd}
      />
      {inactiveMembers?.length ? (
        <>
          <Button
            className={styles.showInactiveButton}
            onClick={() => setShowInactive(!showInactive)}
            color="quaternary"
            size="xs"
          >
            {showInactive
              ? 'Hide Inactive Care Team Members'
              : 'Show Inactive Care Team Members'}
            <CollapseIcon open={showInactive} color="disabled" />
          </Button>
          <Panel.Collapse open={showInactive}>
            <Table
              className={classNames(className, styles.table)}
              config={{
                columns: inactivecolumns,
                compact: true,
                data: inactiveMembers,
                getRowClass: () => styles.inactiveRow,
                paginationOptions: [-1],
              }}
            />
          </Panel.Collapse>
        </>
      ) : (
        <br />
      )}
    </>
  );
};

export default CareTeamMemberTable;
