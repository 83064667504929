import {
  MemberDashboard,
  MemberDashboardEvent,
  MemberDashboardTask,
} from '@vestahealthcare/common/models';

import Api, {
  PaginatedResponse,
  PaginationParams,
} from 'dash/src/services/Api';

export const fecthMemberDashboard = async (
  memberId: number,
): Promise<MemberDashboard> => {
  const { memberDashboard } = await Api.getv2(`/member-dashboard/${memberId}`);
  return new MemberDashboard(memberDashboard);
};

export const fecthMemberDashboardEvents = async (
  memberId: number,
  params: PaginationParams,
): Promise<PaginatedResponse<MemberDashboardEvent>> => {
  const {
    events: { items, pagination },
  } = await Api.getv2(`/member-dashboard/${memberId}/events`, params);
  return {
    items: items.map((event: any) => new MemberDashboardEvent(event)),
    pagination,
  };
};

export const fecthMemberDashboardTasks = async (
  memberId: number,
  params: PaginationParams,
): Promise<PaginatedResponse<MemberDashboardTask>> => {
  const {
    tasks: { items, pagination },
  } = await Api.getv2(`/member-dashboard/${memberId}/tasks`, params);
  return {
    items: items.map((task: any) => new MemberDashboardTask(task)),
    pagination,
  };
};
