import React from 'react';
import { isArray } from 'lodash';
import { Slider } from 'styleguide';
import { EMPTY } from '@vestahealthcare/common/utils/constants';
import { WidgetProps } from '../types';
import { readEnumName } from '../utils';

const SliderWidget = ({
  id,
  value,
  onChange,
  schema,
  formContext: { readonly },
}: WidgetProps) => {
  if (readonly) {
    if (!value) {
      return <p>{EMPTY}</p>;
    }

    return <p>{readEnumName(value, schema)}</p>;
  }

  if (schema.type !== 'number' && schema.type !== 'integer') {
    throw new Error('Only numeric type fields are supported by the Slider');
  }

  const isEnum = !!schema.enum && isArray(schema.enum);

  const props = isEnum
    ? {
        // We go through indexes
        min: 0,
        max: (schema.enum && schema.enum.length - 1) || 0,
        step: 1,
      }
    : {
        min: schema.minimum || 0,
        max: schema.maximum || 0,
        step: schema.multipleOf || 1,
      };

  // Get the actual value if case we're working with an enum
  // If not it just returns the same value
  const readEnum = (indexOrValue?: number) =>
    isEnum
      ? schema.enum && indexOrValue && schema.enum[indexOrValue]
      : indexOrValue;

  const readName = (indexOrValue: number) => readEnumName(indexOrValue, schema);

  return (
    <Slider
      {...props}
      id={id}
      value={value}
      onChange={(value) => onChange(readEnum(value))}
      popoverRenderer={(value?: number) => (
        <span className="slider-step-inner">{readName(value as number)}</span>
      )}
      stepLabelRenderer={(value: number) =>
        value % 2 === 0 ? (
          <span className="slider-step-inner">{readEnum(value)}</span>
        ) : null
      }
    />
  );
};

export default SliderWidget;
