import classnames from 'classnames';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { makeStyles } from '@mui/styles';

import {
  DayOfWeek,
  Enum,
  MemberContactTimeType,
} from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';

import { Button, DateTimePicker, IconButton, Select } from 'styleguide-v2';

const useStyles = makeStyles({
  deleteIcon: {
    height: '4.5rem',
  },
  marginTop: {
    marginTop: '2.125rem!important',
  },
});

export type DatesTimeItem = {
  dates: DayOfWeek[];
  type?: MemberContactTimeType;
  startTime?: Date;
  endTime?: Date;
};

type Props = {
  buttonLabel?: string;
  className?: string;
  defaultValue?: DatesTimeItem[];
  disabled?: boolean;
  onChange: (items: DatesTimeItem[]) => void;
  readOnly?: boolean;
  showType?: boolean;
  size?: 's' | 'm';
  submitted?: boolean;
  title?: string;
};

export const EditDateTimes = ({
  buttonLabel,
  className,
  defaultValue,
  disabled,
  onChange,
  readOnly,
  showType,
  size = 'm',
  submitted,
  title,
}: Props) => {
  const styles = useStyles();

  const [value, setValue] = useState<DatesTimeItem[]>([{} as DatesTimeItem]);

  useEffect(() => {
    if (JSON.stringify(defaultValue) !== JSON.stringify(value)) {
      setValue(
        defaultValue?.length ? [...defaultValue] : [{} as DatesTimeItem],
      );
    }
  }, [defaultValue]);

  const getDaysSize = () => {
    if (size === 's') return 6;
    return showType ? 4 : 5;
  };

  const getTypeSize = () => {
    if (size === 's') return 5;
    return 3;
  };

  const getDateSize = () => {
    if (size === 's') return 6;
    return showType ? 2 : 3;
  };

  const renderDelete = (index: number) => (
    <IconButton
      className={classnames(
        'grid-span-1',
        styles.deleteIcon,
        index === 0 && styles.marginTop,
      )}
      data-cy="bulk-edit-pe-delete-row"
      disabled={value.length < 2 || disabled}
      size="small"
      onClick={() => {
        value.splice(index, 1);
        setValue([...value]);
        onChange([...value]);
      }}
    >
      <DeleteOutlineIcon fontSize="large" />
    </IconButton>
  );

  return (
    <div className={classnames(className, 'grid-wrapper fit')}>
      {value?.map((item, index) => (
        <Fragment key={`day-of-service-${index}`}>
          <Select
            className={`grid-span-${getDaysSize()}`}
            disabled={disabled}
            error={
              submitted &&
              value.length > 1 &&
              !item.dates?.length &&
              !item.type &&
              translate('global.missingRequiredFieldMin')
            }
            items={Enum.toSelectable(DayOfWeek.asArray)}
            label={
              index === 0
                ? title || translate('components.editDateTimes.title')
                : undefined
            }
            limitTags={1}
            multiple
            onChange={(items?: Selectable[]) => {
              if (value[index]) {
                value[index].dates =
                  items?.map(({ value }) => DayOfWeek.byKey[value]) || [];
                setValue([...value]);
                onChange([...value]);
              }
            }}
            placeholder={translate('components.editDateTimes.days')}
            readOnly={readOnly}
            value={Enum.toSelectable(item.dates || [])}
          />
          {showType && (
            <Select
              className={`grid-span-${getTypeSize()}`}
              disabled={disabled}
              error={
                submitted &&
                value.length > 1 &&
                !item.dates?.length &&
                !item.type &&
                translate('global.missingRequiredFieldMin')
              }
              items={Enum.toSelectable(MemberContactTimeType.asArray)}
              label={
                index === 0
                  ? translate('personalDetails.preferredContactModal.time')
                  : ''
              }
              placeholder={translate(
                'personalDetails.preferredContactModal.time',
              )}
              onChange={(item: Selectable) => {
                value[index].type = item?.value
                  ? MemberContactTimeType.byKey[item.value]
                  : undefined;
                setValue([...value]);
                onChange([...value]);
              }}
              value={item.type ? Enum.toSelectable([item.type])[0] : undefined}
            />
          )}

          {size === 's' && renderDelete(index)}

          {(!showType || item.type === MemberContactTimeType.CUSTOM) && (
            <>
              <DateTimePicker
                className={classnames(
                  `grid-span-${getDateSize()}`,
                  index === 0 && size === 'm' && styles.marginTop,
                )}
                disabled={disabled}
                error={
                  submitted &&
                  value.length > 1 &&
                  !item.startTime &&
                  !item.endTime &&
                  translate('global.missingRequiredFieldMin')
                }
                interval={15}
                maxTime={
                  item.endTime ? moment(item.endTime).toDate() : undefined
                }
                onChange={(val?: Date) => {
                  if (value[index]) {
                    value[index].startTime = val;
                    setValue([...value]);
                    onChange([...value]);
                  }
                }}
                placeholder={translate('components.editDateTimes.startTime')}
                type="time"
                value={item.startTime}
              />
              <DateTimePicker
                className={classnames(
                  `grid-span-${getDateSize()}`,
                  index === 0 && size === 'm' && styles.marginTop,
                )}
                disabled={disabled}
                error={
                  submitted &&
                  value.length > 1 &&
                  !item.startTime &&
                  !item.endTime &&
                  translate('global.missingRequiredFieldMin')
                }
                interval={15}
                minTime={
                  item.startTime ? moment(item.startTime).toDate() : undefined
                }
                onChange={(val?: Date) => {
                  if (value[index]) {
                    value[index].endTime = val;
                    setValue([...value]);
                    onChange([...value]);
                  }
                }}
                placeholder={translate('components.editDateTimes.endTime')}
                type="time"
                value={item.endTime}
              />
            </>
          )}

          {size === 's' && index !== value?.length - 1 && (
            <hr
              className="grid-span-12"
              style={{ margin: '0 1%', width: '98%' }}
            />
          )}

          {size === 'm' &&
            showType &&
            item.type !== MemberContactTimeType.CUSTOM && (
              <div className="grid-span-4" />
            )}

          {size === 'm' && renderDelete(index)}
        </Fragment>
      ))}
      {!disabled && (
        <Button
          className="grid-span-12"
          color="tertiary"
          icon={<AddIcon />}
          onClick={() => {
            setValue([...value, {} as DatesTimeItem]);
            onChange([...value, {} as DatesTimeItem]);
          }}
        >
          {buttonLabel || translate('components.editDateTimes.add')}
        </Button>
      )}
    </div>
  );
};
