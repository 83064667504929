import { isEqual } from 'lodash';

function getDiffF<T extends { [key: string]: any }>(original: T, current: T) {
  const update: { [key: string]: any } = {};

  Object.keys(current).forEach((key) => {
    if (!isEqual(original[key], current[key])) update[key] = current[key];
  });

  return update;
}

export const getDiff = getDiffF;
