import React, { useEffect, useMemo, useState } from 'react';

import { makeStyles } from '@mui/styles';

import { States } from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';

import {
  FilterItem,
  FilterSelectable,
  Label,
  Select,
  TextInput,
} from 'styleguide-v2';

import { GetCCMMemberParams } from 'dash/src/services/CCMServices';
import { useDebounce } from 'dash/src/utils/debounceUtils';
import { getFilterItem } from 'dash/src/utils/filterUtils';

type KeyGetCCMParams = keyof GetCCMMemberParams;

type Props = {
  data: {
    languages: Selectable[];
    programExtensions: Selectable[];
    programExtensionStatuses: Selectable[];
  };
  filters?: {
    [x in KeyGetCCMParams]?: FilterItem;
  };
  loading?: boolean;
  onChange: (
    filters: {
      [x in KeyGetCCMParams]?: FilterItem;
    },
  ) => void;
};

const useStyles = makeStyles({
  minutesLabel: {
    '&&': {
      alignSelf: 'center',
      flex: '0 0 fit-content',
      marginLeft: '1rem',
      minWidth: 0,
    },
  },
  minutes: {
    '&&': {
      minWidth: 0,
      flex: '0 0 6rem',
    },
  },
});

export const BHIFiltersBar = ({ data, filters, loading, onChange }: Props) => {
  const styles = useStyles();

  const defaultFrom = useMemo(
    () => (filters?.bhiMinutesFrom?.value as FilterSelectable)?.value as string,
    [filters?.bhiMinutesFrom],
  );
  const defaultTo = useMemo(
    () => (filters?.bhiMinutesTo?.value as FilterSelectable)?.value as string,
    [filters?.bhiMinutesTo],
  );

  const [fromMinutes, setFromMinutes] = useState<string>();
  const [toMinutes, setToMinutes] = useState<string>();

  const fromDebounce = useDebounce(fromMinutes, 500);
  const toDebounce = useDebounce(toMinutes, 500);

  useEffect(() => {
    let bhiMinutesFrom: FilterItem | undefined;
    let bhiMinutesTo: FilterItem | undefined;
    if (fromDebounce) {
      const number =
        fromDebounce !== undefined ? parseInt(fromDebounce, 10) : NaN;
      bhiMinutesFrom = getFilterItem(
        translate('ccm.common.bhiMinutesFrom'),
        isNaN(number) ? undefined : number,
      );
    }
    if (toDebounce) {
      const number = toDebounce !== undefined ? parseInt(toDebounce, 10) : NaN;
      bhiMinutesTo = getFilterItem(
        translate('ccm.common.bhiMinutesTo'),
        isNaN(number) ? undefined : number,
      );
    }
    if (defaultFrom !== fromDebounce || defaultTo !== toDebounce) {
      onChange({
        ...filters,
        bhiMinutesFrom,
        bhiMinutesTo,
      });
    }
  }, [fromDebounce, toDebounce]);

  useEffect(() => {
    setFromMinutes(defaultFrom);
    setToMinutes(defaultTo);
  }, [defaultFrom, defaultTo]);

  return (
    <>
      <>
        <Select
          allowAddNewValue
          data-cy="ccm-filter-bar-member-id"
          items={[]}
          inputType="number"
          multiple
          noOptionsText={translate('members.typeToSearch')}
          onChange={(values: Selectable[]) =>
            onChange({
              ...filters,
              memberIds: getFilterItem(
                translate('ccm.common.memberId'),
                values.map(({ label }) => ({
                  value: label,
                  label,
                })),
              ),
            })
          }
          placeholder={translate('ccm.common.memberId')}
          size="xs"
          value={filters?.memberIds?.value || []}
        />
        <Select
          data-cy="ccm-filter-bar-state"
          items={Enum.toSelectable(States.asArray)}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) =>
            onChange({
              ...filters,
              state: getFilterItem(translate('ccm.common.state'), values),
            })
          }
          placeholder={translate('ccm.common.state')}
          placeholderV2
          size="xs"
          value={filters?.state?.value || []}
        />
        <Select
          data-cy="ccm-filter-bar-language"
          items={data.languages.filter(({ disabled }) => !disabled)}
          grouped={({ preferred }) =>
            preferred
              ? translate('global.preferred')
              : translate('global.other')
          }
          limitTags={1}
          loading={loading}
          multiple
          onChange={(values: Selectable[]) =>
            onChange({
              ...filters,
              language: getFilterItem(translate('ccm.common.language'), values),
            })
          }
          placeholder={translate('ccm.common.language')}
          placeholderV2
          size="xs"
          value={filters?.language?.value || []}
        />
        <Label className={styles.minutesLabel} noMargin>
          {translate('ccm.common.bhiTotalMinutes')}:
        </Label>
        <TextInput
          className={styles.minutes}
          maxLength={4}
          onChange={setFromMinutes}
          placeholder={translate('global.from')}
          value={fromMinutes}
          size="xs"
        />
        <TextInput
          className={styles.minutes}
          maxLength={4}
          onChange={setToMinutes}
          placeholder={translate('global.to')}
          value={toMinutes}
          size="xs"
        />
        <Select
          data-cy="ccm-filter-bar-pe"
          items={data.programExtensions}
          limitTags={1}
          loading={loading}
          multiple
          onChange={(values: Selectable[]) =>
            onChange({
              ...filters,
              programExtensionId: getFilterItem(
                translate('ccm.common.programExtensions'),
                values,
              ),
            })
          }
          placeholder={translate('ccm.common.programExtensions')}
          placeholderV2
          size="xs"
          value={filters?.programExtensionId?.value || []}
        />
        <Select
          data-cy="ccm-filter-pe-status"
          items={data.programExtensionStatuses}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) =>
            onChange({
              ...filters,
              programExtensionStatus: getFilterItem(
                translate('ccm.common.programExtensionStatus'),
                values,
              ),
            })
          }
          placeholder={translate('ccm.common.programExtensionStatus')}
          placeholderV2
          size="xs"
          value={filters?.programExtensionStatus?.value || []}
        />
      </>
    </>
  );
};
