import { Employee } from '.';
import { translate } from '../i18n';
import { EMPTY } from '../utils/constants';
import { make } from './Model';

export class Intervention {
  id = 0;

  name = '';

  active = false;

  responsive = false;

  proactive = false;

  createdBy?: Employee;

  updatedBy?: Employee;

  createdAt = 0;

  updatedAt = 0;

  displayType = () => {
    if (this.proactive && this.responsive)
      return `${translate(
        'careTracks.interventionsLabels.proactive',
      )}/${translate('careTracks.interventionsLabels.responsive')}`;
    if (this.responsive && !this.proactive)
      return translate('careTracks.interventionsLabels.responsive');
    if (this.proactive && !this.responsive)
      return translate('careTracks.interventionsLabels.proactive');

    return EMPTY;
  };

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdBy = make(obj.createdBy, Employee);
    this.updatedBy = make(obj.updatedBy, Employee);
  }
}

export default Intervention;
