import React from 'react';

import AddIcCallIcon from '@mui/icons-material/AddIcCall';

import { translate } from '@vestahealthcare/common/i18n';
import { MemberDashboardEncounter } from '@vestahealthcare/common/models';

import { Button, Card, CardContent, CardHeader } from 'styleguide-v2';

import { TOCEncountersTable } from './TOCEncountersTable';

type Props = {
  className?: string;
  data?: MemberDashboardEncounter[];
  onLogEncounter: () => void;
  type: 'TOC' | 'SKILLED';
};

export const TOCEncountersCard = ({
  className,
  data,
  onLogEncounter,
  type,
}: Props) => {
  return (
    <Card className={className}>
      <CardHeader
        title={translate(`toc.detail.encountersTitle.${type}`)}
        action={
          <Button
            color="quaternary"
            icon={<AddIcCallIcon />}
            size="s"
            onClick={onLogEncounter}
          >
            <span className="bold">{translate('toc.detail.logEncounter')}</span>
          </Button>
        }
      />
      <CardContent sx={{ paddingTop: 0 }}>
        <TOCEncountersTable data={data} type={type} />
      </CardContent>
    </Card>
  );
};
