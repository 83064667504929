import { CareTeamMember } from './CareTeamMember';
import { makeArray } from './Model';

export class IncidentDetailEmail {
  groupName = '';

  emails?: string[];

  careTeamMembersWithoutEmail?: CareTeamMember[];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.careTeamMembersWithoutEmail = makeArray(
      obj.careTeamMembersWithoutEmail,
      CareTeamMember,
    );
  }
}

export default IncidentDetailEmail;
