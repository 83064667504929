import React, { useEffect, useState } from 'react';

import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';

import { Button, CollapsableSidebar, Modal, Select } from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import { PatchAssignToWorklistParams } from 'dash/src/services/CCMServices';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (
    params: Pick<PatchAssignToWorklistParams, 'worklistGroupId'>,
    groups: Selectable,
  ) => Promise<boolean>;
  totalMembers: number;
}

const UNASSIGNED = { label: translate('global.unassigned'), value: 0 };

export const AssignWorklistModal = ({
  open,
  onClose,
  onSubmit,
  totalMembers,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [groups, setGroups] = useState<Selectable[]>([]);
  const [worklistGroupId, setWorklistGroupId] = useState<number | undefined>(
    undefined,
  );
  const [openModal, setOpenModal] = useState<boolean>(false);

  const getInitialData = async () => {
    const groups = await CacheServices.getEmployeeGroupsWorklist();
    setGroups([
      UNASSIGNED,
      ...groups?.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    ]);
  };

  useEffect(() => {
    setWorklistGroupId(undefined);
  }, [open]);

  useEffect(() => {
    getInitialData();
  }, []);

  const doSubmit = async () => {
    setSubmitted(true);
    const group = groups?.find(({ value }) => value === worklistGroupId);
    if (worklistGroupId !== undefined && group) {
      setLoading(true);
      try {
        await onSubmit(
          {
            worklistGroupId: worklistGroupId || null,
          },
          group,
        );
        onClose();
      } finally {
        setSubmitted(false);
        setLoading(false);
      }
    }
  };

  const group = groups?.find(({ value }) => value === worklistGroupId);

  return (
    <>
      <CollapsableSidebar
        onClose={onClose}
        open={open}
        title={<h2>{translate('ccm.modals.assign.title')}</h2>}
      >
        <CollapsableSidebar.Body>
          <Select
            disableClearable
            error={submitted && worklistGroupId === undefined}
            items={groups}
            label={translate('ccm.modals.assign.group')}
            onChange={(group: Selectable) =>
              setWorklistGroupId(group?.value as number)
            }
            value={group}
            required
          />
        </CollapsableSidebar.Body>
        <CollapsableSidebar.Buttons>
          <Button
            color="tertiary"
            data-cy="assign-group-close"
            onClick={onClose}
          >
            {translate('global.close')}
          </Button>
          <Button
            color="secondary"
            data-cy="assign-group-submit"
            disabled={worklistGroupId === undefined}
            loading={loading}
            onClick={() => setOpenModal(true)}
          >
            {translate('global.save')}
          </Button>
        </CollapsableSidebar.Buttons>
      </CollapsableSidebar>
      <Modal
        body={
          group?.value
            ? translate('ccm.modals.assign.warning', {
                count: totalMembers,
                group: group?.label,
              })
            : translate('ccm.modals.assign.warningUnassigned', {
                count: totalMembers,
              })
        }
        maxWidth="sm"
        onClose={() => setOpenModal(false)}
        onSubmit={doSubmit}
        open={openModal}
        title={translate('global.warning')}
      />
    </>
  );
};

export default AssignWorklistModal;
