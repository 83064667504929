import { MessageStatus, MessageType } from '../enums';
import { make } from './Model';
import { Conversation } from './Conversation';
import { Employee } from './Employee';

export class Message {
  automated = false;

  createdAt = 0;

  createdBy: Employee;

  conversation?: Conversation;

  id = 0;

  message = '';

  status: MessageStatus;

  type: MessageType;

  userId = 0;

  userPhoneNumber = '';

  vestaPhoneNumber = '';

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdBy = make(obj.createdBy, Employee);
    this.conversation = make(obj.conversation, Conversation);
    this.type = MessageType.byKey[obj.type];
    this.status = MessageStatus.byKey[obj.status];
  }
}

export default Message;
