import moment, { Moment } from 'moment';
import { make } from './Model';
import { Employee } from './Employee';

export class EventIssueNote {
  note = '';

  createdBy: Employee;

  createdAt?: Moment;

  updatedBy: Employee;

  updatedAt?: Moment;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdBy = make(obj.createdBy, Employee);
    this.updatedBy = make(obj.updatedBy, Employee);
    this.createdAt = obj.createdAt ? moment.unix(obj.createdAt) : undefined;
    this.updatedAt = obj.updatedAt ? moment.unix(obj.updatedAt) : undefined;
  }
}
