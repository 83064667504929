import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { CareTeamMemberOther } from '@vestahealthcare/common/models';

import { TextInput } from 'styleguide-v2';

import { CreateCareTeamMemberOtherParams } from 'dash/src/services/CareTeamServices';

type Props = {
  member: CareTeamMemberOther;
  onChange: (params: Partial<CreateCareTeamMemberOtherParams>) => void;
};

export const OtherFields = ({ member, onChange }: Props) => {
  const [otherOrganizationName, setOtherOrganizationName] = useState<string>();

  useEffect(() => {
    setOtherOrganizationName(member?.organizationName);
  }, [member]);

  const handleChange = (changes: Partial<any>) => {
    onChange(changes);
  };

  return (
    <div className="grid-wrapper fit">
      <h2 className="grid-span-12" style={{ fontSize: '1em' }}>
        {translate('careTeam.modals.edit.otherFields')}
      </h2>

      <TextInput
        className="grid-span-12"
        data-cy="care-team-member-otherOrganizationName"
        label={translate('careTeam.common.otherOrganizationName')}
        onChange={(otherOrganizationName?: string) => {
          setOtherOrganizationName(otherOrganizationName);
          handleChange({ otherOrganizationName });
        }}
        value={otherOrganizationName}
      />
    </div>
  );
};
