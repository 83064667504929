import classnames from 'classnames';
import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { CareTeamMember } from '@vestahealthcare/common/models';

import { Fonts } from 'styleguide-v2/src/styles/Variables';

type Props = {
  className?: string;
  'data-cy'?: string;
  member: CareTeamMember;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  name: {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    fontWeight: 500,
  },
  extraInfo: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 400,
  },
});

export const SelectCareTeamMemberOption = ({
  className,
  'data-cy': dataCy,
  member,
}: Props) => {
  const styles = useStyles();
  const classes = classnames(className, styles.container);

  const renderCTMDetails = ({ id, typeWithSubTypeLabel }: CareTeamMember) => {
    const idString = `${translate('components.select.id')} ${id}`;

    return (
      <span className={styles.extraInfo}>
        {idString} - {typeWithSubTypeLabel}
      </span>
    );
  };

  if (member.id === -1) {
    return (
      <>
        <AddIcon />
        {member.careTeamPerson.fullName}
      </>
    );
  }

  return (
    <div className={classes} data-cy={dataCy}>
      <span className={styles.name}>{member.careTeamPerson.fullName}</span>
      {renderCTMDetails(member)}
    </div>
  );
};

export default SelectCareTeamMemberOption;
