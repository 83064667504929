import React, { useState } from 'react';

import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import SMSIcon from '@mui/icons-material/Mail';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import { Chip } from './index';

type Props = {
  onClick: () => void;
  sent?: boolean;
  type: 'android' | 'ios' | 'sms';
};

const useStyles = makeStyles({
  androidIcon: {
    position: 'relative',
    top: '0.25rem',
  },
  iosIcon: {
    position: 'relative',
    top: '0.15rem',
  },
  sendLink: {
    minHeight: '2.75rem',
    minWidth: '15rem',
  },
});

export const Invite = ({ onClick, sent, type }: Props) => {
  const styles = useStyles();
  const [isHovered, setHovered] = useState<boolean>(false);

  return (
    <div
      onMouseEnter={() => sent && setHovered(true)}
      onMouseLeave={() => sent && setHovered(false)}
    >
      <Chip
        className={styles.sendLink}
        color="info"
        type={sent && !isHovered ? 'outlined' : 'contained'}
        onClick={sent && !isHovered ? undefined : onClick}
      >
        {type === 'ios' && (
          <AppleIcon className={styles.iosIcon} fontSize="small" />
        )}
        {type === 'android' && (
          <AndroidIcon className={styles.androidIcon} fontSize="small" />
        )}
        {type === 'sms' && (
          <SMSIcon className={styles.androidIcon} fontSize="small" />
        )}{' '}
        {translate(
          sent && !isHovered
            ? 'global.sent'
            : `components.mobileInvite.${type}`,
        )}
      </Chip>
    </div>
  );
};
