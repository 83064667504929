import { Moment } from 'moment';

import { MemberDashboardActionStatus } from '../../enums/MemberDashboardActionStatus';
import { translate } from '../../i18n';
import { makeUnix } from '../Model';

export class MemberWarningItem {
  type = '';

  createdAt?: Moment;

  completedAt?: Moment;

  dueDate?: Moment;

  startedAt?: Moment;

  status = '';

  assignee = '';

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdAt = makeUnix(obj.createdAt);
    this.completedAt = makeUnix(obj.completedAt);
    this.dueDate = makeUnix(obj.dueDate);
    this.startedAt = makeUnix(obj.startedAt);
  }

  get date(): Moment | undefined {
    if (
      [
        MemberDashboardActionStatus.NOT_STARTED.value,
        MemberDashboardActionStatus.OVERDUE.value,
      ].includes(this.status)
    ) {
      return this.dueDate;
    }
    if (this.status === MemberDashboardActionStatus.IN_PROGRESS.value) {
      return this.startedAt;
    }
    if (this.status === MemberDashboardActionStatus.COMPLETED.value) {
      return this.completedAt;
    }
    return this.createdAt;
  }

  get dateLabel(): string {
    if (
      [
        MemberDashboardActionStatus.NOT_STARTED.value,
        MemberDashboardActionStatus.OVERDUE.value,
      ].includes(this.status)
    ) {
      return translate('common.due');
    }
    if (this.status === MemberDashboardActionStatus.IN_PROGRESS.value) {
      return translate('common.started');
    }
    if (this.status === MemberDashboardActionStatus.COMPLETED.value) {
      return translate('common.completed');
    }
    return translate('common.created');
  }
}

export default MemberWarningItem;
