import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import { Sidebar } from 'styleguide';
import { Chip } from 'styleguide-v2';
import { SidebarSectionProps } from 'styleguide/src/components/Sidebar';

import config from 'dash/environments';
import { fetchSMSUnreadCount } from 'dash/src/redux/slices/ChatSlice';
import { useSelector } from 'dash/src/redux/store';
import Session from 'dash/src/services/SessionServices';

const useStyles = makeStyles({
  smsIndicator: {
    position: 'absolute',
    right: '1.6rem',
  },
});

export const HomeSidebar = () => {
  const REFRESH_INTERVAL = 60 * 1000;
  const {
    showCcmClinical,
    showCommunityResources,
    showIcdDetectionPage,
    showIncidents,
    showPodReporting,
    showTocPage,
  } = useFlags();
  const dispatch = useDispatch();
  const styles = useStyles();

  const SMS_LINK = {
    href: '/dashboard/chats/sms?status=OPEN&read=false',
    text: translate('sidebar.sms'),
    dataCy: 'sidebar-sms-link',
    children: <></>,
  };

  const CLINICAL_LINKS = {
    header: 'Clinical',
    links: [
      {
        href: '/dashboard/tasks',
        text: translate('home.sidebar.tasks'),
        dataCy: 'sidebar-all-tasks-dashboard-link',
      },
      {
        href: '/dashboard/events',
        text: translate('home.sidebar.events'),
        dataCy: 'sidebar-events-link',
      },
      {
        href: '/dashboard/members',
        text: translate('clinicalDashboard.sidebar.members'),
        dataCy: 'sidebar-members-link',
      },
    ],
  };

  CLINICAL_LINKS?.links?.push(SMS_LINK);

  CLINICAL_LINKS?.links?.push(
    {
      href: '/dashboard/health-reports',
      text: translate('clinicalDashboard.sidebar.healthReports'),
      dataCy: 'sidebar-health-reports-link',
    },
    {
      href: '/dashboard/encounters',
      text: translate('clinicalDashboard.sidebar.encounters'),
      dataCy: 'sidebar-encounters-link',
    },
  );

  if (showCcmClinical) {
    CLINICAL_LINKS.links.push({
      href: '/dashboard/ccm',
      text: translate('clinicalDashboard.sidebar.ccm'),
      dataCy: 'sidebar-ccm-link',
    });
  }

  if (showPodReporting) {
    CLINICAL_LINKS?.links?.push({
      href: '/dashboard/pod-reporting',
      text: translate('clinicalDashboard.sidebar.podReporting'),
      dataCy: 'sidebar-pod-reporting-link',
    });
  }

  if (showTocPage) {
    CLINICAL_LINKS?.links?.push({
      href: '/dashboard/toc-list',
      text: translate('clinicalDashboard.sidebar.toc'),
      dataCy: 'sidebar-toc-list-link',
    });
  }

  const OPS_LINKS = {
    header: 'Operations',
    links: [
      {
        href: '/ops/messaging',
        text: 'Bulk Messaging',
        dataCy: 'sidebar-sms-link',
      },
      {
        href: '/ops/add-member',
        text: 'Add Member',
        dataCy: 'sidebar-add-member-link',
      },
      {
        href: '/ops/roster-ingestion',
        text: 'Roster Ingestion',
        dataCy: 'sidebar-roster-ingestion-link',
      },
    ],
  };

  if (showIcdDetectionPage) {
    OPS_LINKS?.links?.push({
      href: '/ops/icd-detection',
      text: 'ICD Detection',
      dataCy: 'sidebar-icd-detection-link',
    });
  }

  if (Session.actingUser.isAdmin) {
    OPS_LINKS?.links?.push({
      href: '/ops/members',
      text: 'Member Bulk Edit',
      dataCy: 'sidebar-members-link',
    });
  }

  if (Session.actingUser.isInBizOps || Session.actingUser.isAdmin) {
    OPS_LINKS.links.push({
      href: '/ops/eligibility-jobs',
      text: 'Eligibility',
      dataCy: 'sidebar-eligibility-jobs-link',
    });
  }

  const ADMIN_LINKS: SidebarSectionProps = {
    header: 'Admin',
    links: [
      {
        href: Session.actingUser.isInAdmin
          ? '/admin/campaigns/manager'
          : '/admin/dashboard',
        text: 'Campaign Management',
        dataCy: 'sidebar-campaigns-manager-link',
        childrenLinks: [
          {
            href: '/admin/campaigns/app-modals',
            text: 'App Modals/Banners',
            dataCy: 'sidebar-campaigns-modals-link',
          },
          {
            href: '/admin/campaigns/content-library',
            text: 'Content Library',
            dataCy: 'sidebar-campaigns-content-link',
          },
        ],
      },
      {
        href: '/admin/groups',
        text: 'Groups',
        dataCy: 'sidebar-groups-link',
      },
      {
        href: '/admin/employees',
        text: 'Employees',
        dataCy: 'sidebar-employees-link',
      },
      {
        href: '/admin/care-team',
        text: 'Care Team',
        dataCy: 'sidebar-care-team-link',
      },
      {
        href: '/admin/insurance-plans',
        text: 'Insurance Plans',
        dataCy: 'sidebar-insurance-plans-link',
      },
    ],
  };

  const eventLinks = {
    href: '/admin/event-types',
    text: 'Events',
    dataCy: 'sidebar-events-types-link',
    childrenLinksExpanded: true,
    childrenLinks: [
      {
        href: '/admin/event-mapping',
        text: 'Event Mapping/Config',
        dataCy: 'sidebar-events-mapping-link',
      },
    ],
  };
  if (Session.actingUser.isInClinicalLeadership) {
    eventLinks.childrenLinks.push({
      href: '/admin/event-interventions',
      text: 'Interventions',
      dataCy: 'sidebar-events-interventions-link',
    });
  }
  ADMIN_LINKS?.links?.push(eventLinks);

  ADMIN_LINKS?.links?.push({
    href: '/admin/referrals',
    text: 'Referrals',
    dataCy: 'sidebar-referrals-link',
  });

  ADMIN_LINKS?.links?.push({
    href: '/admin/facilities',
    text: 'Facilities',
    dataCy: 'sidebar-facilities-link',
  });

  if (Session.actingUser.isInAdmin)
    ADMIN_LINKS?.links?.push({
      href: '/admin/task-templates',
      text: 'Manage Task Templates',
      dataCy: 'sidebar-task-templates-link',
    });

  ADMIN_LINKS?.links?.push({
    href: '/admin/file-history',
    text: 'File History',
    dataCy: 'sidebar-file-history-link',
  });

  if (showCommunityResources) {
    ADMIN_LINKS?.links?.push({
      href: '/admin/community-resources',
      text: 'Community Resources',
      dataCy: 'sidebar-community-resources-link',
    });
  }

  if (showIncidents && Session.actingUser.isInIncidentsManagement) {
    ADMIN_LINKS?.links?.push({
      href: '/admin/incidents',
      text: 'Incidents',
      dataCy: 'sidebar-incidents-link',
    });
  }

  const DEV_LINKS = {
    header: 'Dev',
    links: [
      {
        href: '/dev',
        text: 'Dev Dashboard',
        dataCy: 'sidebar-dev-dashboard',
      },
    ],
  };

  const smsUnreadCount = useSelector((state) => state.chatSlice.smsUnreadCount);
  const LINKS = [
    CLINICAL_LINKS,
    OPS_LINKS,
    Session.actingUser.isAdmin && ADMIN_LINKS,
    !config.isProd && DEV_LINKS,
  ];

  const fetchSMSIndicator = async () => {
    dispatch(await fetchSMSUnreadCount());
  };

  useEffect(() => {
    fetchSMSIndicator();
    const interval = setInterval(() => fetchSMSIndicator(), REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  const getLinks = (): any[] => {
    if (smsUnreadCount) {
      SMS_LINK.children = (
        <>
          &nbsp;
          <Chip
            className={styles.smsIndicator}
            label={smsUnreadCount}
            color="secondary"
          />
        </>
      );
    } else {
      SMS_LINK.children = <></>;
    }
    return LINKS;
  };

  return <Sidebar collapsible sections={getLinks()} />;
};

export default HomeSidebar;
