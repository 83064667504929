import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

import {
  BiometricsType,
  IssueDetailFieldType,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  IssueDetailFieldGroup,
  IssueDetailFieldOption,
  IssueType,
} from '@vestahealthcare/common/models';
import { EventIssue } from '@vestahealthcare/common/models/EventIssue';
import IssueDetailValue from '@vestahealthcare/common/models/IssueDetailValue';
import { Measurement } from '@vestahealthcare/common/models/Measurement';
import { DATE_FORMAT_SHORT_WITH_TIME } from '@vestahealthcare/common/utils/constants';

import {
  Button,
  CollapsableSidebar,
  Colors,
  Fonts,
  SwitchGroup,
} from 'styleguide-v2';

import {
  IssueField,
  UpdateEventIssueParams,
} from 'dash/src/services/EventServices';

import { EventCustomField } from '../components/EventCustomField';

const useStyles = makeStyles({
  readingsValue: {
    margin: 0,
  },
  readingsDate: {
    color: Colors.gray,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
  },
});
interface Props {
  issue?: EventIssue;
  issueFields?: IssueDetailFieldGroup[];
  open: boolean;
  onClose: () => void;
  onSubmit: (
    issue: EventIssue,
    params: UpdateEventIssueParams,
    readings?: Measurement[],
  ) => Promise<boolean>;
  submitted?: boolean;
}

export const MemberEventEditIssue = ({
  issue,
  issueFields: defaultIssueFields,
  open,
  onClose,
  onSubmit,
  submitted,
}: Props) => {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);

  const [valid, setValid] = useState<boolean>(false);
  const [issueDetails, setIssueDetails] = useState<IssueField[]>([]);
  const [readings, setReadings] = useState<Measurement[]>([]);

  const [issueFields, setIssueFields] = useState<
    IssueDetailFieldGroup[] | undefined
  >(defaultIssueFields);

  useEffect(() => {
    setIssueFields(defaultIssueFields);
  }, [defaultIssueFields]);

  const getIssueDetailsFromDefinition = () =>
    defaultIssueFields
      ?.filter(
        ({ issueDetailField: { fieldType } }) =>
          fieldType === IssueDetailFieldType.TOGGLE,
      )
      ?.map(
        ({ issueDetailField: { id } }) =>
          ({ issueDetailFieldId: id, value: false } as IssueField),
      );

  useEffect(() => {
    setValid(!!issue?.valid);
    const issueDetails =
      issue?.details?.map(
        ({ issueDetailField: { id }, value }) =>
          ({
            issueDetailFieldId: id,
            value,
          } as IssueField),
      ) ||
      getIssueDetailsFromDefinition() ||
      [];

    const toggleIds =
      defaultIssueFields
        ?.filter(
          ({ issueDetailField: { fieldType } }) =>
            fieldType === IssueDetailFieldType.TOGGLE,
        )
        ?.map(({ issueDetailField: { id } }) => id) || [];
    toggleIds.forEach((id) => {
      const issueHasDetail = issueDetails.find(
        ({ issueDetailFieldId: fieldId, value }) =>
          fieldId === id && value !== undefined && value !== null,
      );
      if (!issueHasDetail) {
        issueDetails.push({
          issueDetailFieldId: id,
          value: false,
        });
      }
    });

    setIssueDetails(issueDetails);

    setReadings(
      issue?.readings ? [...issue.readings.map((r) => ({ ...r }))] : [],
    );
  }, [issue, defaultIssueFields]);

  useEffect(() => {
    setLoading(false);
    setSubmit(!!submitted);
  }, [open]);

  // FIXME: Check condition for VESTA-2508
  useEffect(() => {
    if (issue?.issueType?.id === IssueType.HOSPITALIZATION) {
      const dischargeDateField = issueDetails?.find(
        ({ issueDetailFieldId, value }) =>
          issueDetailFieldId ===
            IssueDetailValue.HOSPITALIZATION_DISCHARGE_DATE && value,
      );
      const medChangeField = issueFields?.find(
        ({ issueDetailField }) =>
          issueDetailField.id ===
          IssueDetailValue.HOSPITALIZATION_MEDICATION_CHANGE,
      );
      const followApptField = issueFields?.find(
        ({ issueDetailField }) =>
          issueDetailField.id === IssueDetailValue.HOSPITALIZATION_FOLLOW_UP,
      );
      if (dischargeDateField) {
        if (
          medChangeField?.required === false &&
          followApptField?.required === false
        ) {
          medChangeField.required = true;
          followApptField.required = true;
          setIssueFields([...(issueFields?.map((x) => ({ ...x })) || [])]);
        }
      } else if (
        medChangeField?.required === true &&
        followApptField?.required === true
      ) {
        medChangeField.required = false;
        followApptField.required = false;
        setIssueFields([...(issueFields?.map((x) => ({ ...x })) || [])]);
      }
    }
  }, [issueDetails]);

  const getDetail = (id: string) =>
    issueDetails.find(({ issueDetailFieldId: idid }) => idid === id);

  const followUpOptions = issueFields
    ?.reduce(
      (acc, { issueDetailField: { options } }) => [...acc, ...(options || [])],
      [] as IssueDetailFieldOption[],
    )
    .filter(({ followUpFieldId }) => !!followUpFieldId);

  const followUpIds = followUpOptions
    ?.filter(({ id }) => issueDetails?.find(({ value }) => id === value))
    ?.map(({ followUpFieldId }) => followUpFieldId);

  const filteredIssueFields = issueFields?.filter(
    ({ issueDetailField: { followUp, id } }) =>
      !followUp || followUpIds?.includes(id),
  );

  const filteredIssueFieldIds = filteredIssueFields?.map(
    ({ issueDetailField }) => issueDetailField.id,
  );

  const validate = () =>
    !filteredIssueFields?.length ||
    !filteredIssueFields.find(
      ({ issueDetailField: { id, fieldType }, required }) => {
        if (!required || fieldType === IssueDetailFieldType.TOGGLE)
          return false;
        const value = getDetail(id)?.value;
        return value === undefined || value === '';
      },
    );

  const doSubmit = async () => {
    setSubmit(true);
    if (issue && validate()) {
      setLoading(true);
      try {
        const result = await onSubmit(
          issue,
          {
            valid,
            details: issueDetails.map(
              ({ issueDetailFieldId: id, value }) =>
                ({
                  issueDetailFieldId: id,
                  value: filteredIssueFieldIds?.includes(id) ? value : null,
                } as IssueField),
            ),
          },
          readings,
        );
        if (result) {
          onClose();
        }
      } finally {
        setLoading(false);
      }
    }
  };

  interface MeasurementMapped extends Omit<Measurement, 'value'> {
    ids?: number[];
    value: string | number;
  }

  const getReadings = () => {
    let r: MeasurementMapped[] = readings.sort(
      ({ createdAt: a }, { createdAt: b }) => b - a,
    );
    const sReadings = r.filter(
      ({ type }) => type === BiometricsType.BP_SYSTOLIC,
    );
    if (sReadings?.length) {
      r = r
        .filter(({ type }) => type !== BiometricsType.BP_SYSTOLIC)
        .map(
          ({ id, value, ...rest }, index) =>
            ({
              value: `${sReadings[index].value}/${value}`,
              ids: [id, sReadings[index].id],
              id,
              ...rest,
            } as MeasurementMapped),
        );
    }

    return r?.map(({ id, ids, createdAt, type, value, valid }) => ({
      value: ids ? ids.join(',') : `${id}`,
      label: (
        <>
          <p
            className={styles.readingsValue}
          >{`${value} ${BiometricsType.getUnit(type)}`}</p>
          <p className={styles.readingsDate}>
            {`${moment
              .unix(createdAt)
              .format(DATE_FORMAT_SHORT_WITH_TIME)} ${moment
              .tz(moment.tz.guess())
              .zoneAbbr()
              .replace(/[DS]T$/, 'T')}`}
          </p>
        </>
      ),
      checked: valid,
    }));
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={
        <h2>
          {translate(
            `memberEvents.modals.${issue?.id ? 'edit' : 'add'}IssueModalTitle`,
            { type: issue?.issueType?.description },
          )}
        </h2>
      }
      size={550}
    >
      <CollapsableSidebar.Body>
        {!!issue?.readings?.length && (
          <SwitchGroup
            data-cy="edit-event-valid"
            title={['Readings', 'Valid']}
            items={getReadings() || []}
            onChange={(checks) => {
              checks.forEach(({ value, checked }) => {
                const ids = (value as string).split(',');
                ids.forEach((idString) => {
                  const r = readings.find(({ id }) => Number(idString) === id);
                  if (r) {
                    r.valid = !!checked;
                  }
                });
              });
              setReadings([...readings]);
            }}
          />
        )}

        <div className="grid-wrapper fit">
          {filteredIssueFields?.map(
            ({
              issueDetailField: {
                id,
                description,
                fieldType,
                options,
                followUp,
              },
              required,
            }) =>
              !followUp || followUpIds?.includes(id) ? (
                <EventCustomField
                  className={`grid-span-${
                    [
                      IssueDetailFieldType.TEXT_AREA,
                      IssueDetailFieldType.TOGGLE,
                    ].indexOf(fieldType) !== -1
                      ? 12
                      : 6
                  }`}
                  items={options
                    ?.sort(({ position: a }, { position: b }) => a - b)
                    ?.map(({ active, id, description }) => ({
                      value: id,
                      label: description,
                      disabled: !active,
                    }))}
                  key={`issue-add-field-${id}`}
                  label={description}
                  onChange={(value) => {
                    const detail = getDetail(id);
                    if (detail) {
                      detail.value = value;
                      setIssueDetails([...issueDetails]);
                    } else {
                      setIssueDetails([
                        ...issueDetails,
                        { issueDetailFieldId: id, value },
                      ]);
                    }
                  }}
                  required={required}
                  submitted={submit}
                  tooltip={getDetail(id)?.value}
                  type={fieldType}
                  value={getDetail(id)?.value}
                />
              ) : (
                ![
                  IssueDetailFieldType.TEXT_AREA,
                  IssueDetailFieldType.TOGGLE,
                ].includes(fieldType) && <div className="grid-span-6" />
              ),
          )}
        </div>

        <br />

        {!!issue?.id && (
          <SwitchGroup
            data-cy="edit-event-valid"
            items={[
              {
                label: translate('memberEvents.detail.validIssue'),
                checked: valid,
              },
            ]}
            onChange={(checks) => {
              setValid(!!checks[0].checked);
            }}
          />
        )}
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="edit-event-issue-close"
          onClick={onClose}
        >
          {translate('global.cancel')}
        </Button>
        <Button
          color="secondary"
          data-cy="edit-event-issue-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default MemberEventEditIssue;
