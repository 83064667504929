import Api from 'dash/src/services/Api';
import {
  CampaignAudience,
  CampaignModal,
  CampaignBanner,
} from '@vestahealthcare/common/models';

type TranslationParams = {
  language: string;
  content?: string;
  header?: string;
};

type CampaignAudienceParams = {
  active: boolean;
  targets?: string[];
  name: string;
  banners?: number[];
  modals?: number[];
  organizationIds?: number[];
};

type CampaignBannerParams = {
  name: string;
  translations: TranslationParams[];
  image: string;
  modal?: number;
  ctaLabel?: string;
};

type CampaignModalParams = {
  name: string;
  translations: TranslationParams[];
  image: string;
  ctaButtons?: string[];
  eventTriggers?: string[];
};

// Campaigns

export const fetchCampaigns = async (): Promise<CampaignAudience[]> => {
  const response = await Api.getv2('/campaigns/audiences');
  return response.campaignAudiences.items.map(
    (data: any) => new CampaignAudience(data),
  );
};

export const fetchCampaign = async (
  campaignId: number | string,
): Promise<CampaignAudience> => {
  const response = await Api.getv2(`/campaigns/audiences/${campaignId}`);
  return new CampaignAudience(response.campaignAudience);
};

export const createCampaign = async (params: CampaignAudienceParams) => {
  const response = await Api.postv2JSON('/campaigns/audiences', params);
  return new CampaignAudience(response.campaignAudience);
};

export const editCampaign = async (
  campaignId: number | string,
  params: Partial<CampaignAudienceParams>,
) => {
  const response = await Api.postv2JSON(
    `/campaigns/audiences/${campaignId}`,
    params,
  );
  return new CampaignAudience(response.campaignAudience);
};

export const deleteCampaign = async (campaignId: number | string) => {
  await Api.deletev2(`/campaigns/audiences/${campaignId}`);
};

export const activateCampaign = async (campaignId: number | string) => {
  await Api.postv2(`/campaigns/audiences/${campaignId}/actions/activate`);
};

export const deactivateCampaign = async (campaignId: number | string) => {
  await Api.postv2(`/campaigns/audiences/${campaignId}/actions/deactivate`);
};

// Modals

export const fetchCampaignModals = async (): Promise<CampaignModal[]> => {
  const response = await Api.getv2('/campaigns/modals');
  return response.campaignModals.items.map(
    (data: any) => new CampaignModal(data),
  );
};

export const fetchCampaignModal = async (
  modalId: number | string,
): Promise<CampaignModal> => {
  const response = await Api.getv2(`/campaigns/modals/${modalId}`);
  return new CampaignModal(response.campaignModal);
};

export const createCampaignModal = async (params: CampaignModalParams) => {
  const response = await Api.postv2JSON('/campaigns/modals', params);
  return new CampaignModal(response.campaignModal);
};

export const editCampaignModal = async (
  modalId: number | string,
  params: CampaignModalParams,
) => {
  const response = await Api.postv2JSON(`/campaigns/modals/${modalId}`, params);
  return new CampaignModal(response.campaignModal);
};

export const deleteCampaignModal = async (modalId: number | string) => {
  await Api.deletev2(`/campaigns/modals/${modalId}`);
};

// Banners

export const fetchCampaignBanners = async (): Promise<CampaignBanner[]> => {
  const response = await Api.getv2('/campaigns/banners');
  return response.campaignBanners.items.map(
    (data: any) => new CampaignBanner(data),
  );
};

export const fetchCampaignBanner = async (
  bannerId: number | string,
): Promise<CampaignBanner> => {
  const response = await Api.getv2(`/campaigns/banners/${bannerId}`);
  return new CampaignBanner(response.campaignBanner);
};

export const createCampaignBanner = async (params: CampaignBannerParams) => {
  const response = await Api.postv2JSON('/campaigns/banners', params);
  return new CampaignBanner(response.campaignBanner);
};

export const editCampaignBanner = async (
  bannerId: number | string,
  params: CampaignBannerParams,
) => {
  const response = await Api.postv2JSON(
    `/campaigns/banners/${bannerId}`,
    params,
  );
  return new CampaignBanner(response.campaignBanner);
};

export const deleteCampaignBanner = async (bannerId: number | string) => {
  await Api.deletev2(`/campaigns/banners/${bannerId}`);
};
