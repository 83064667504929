import React, { ReactNode } from 'react';

import { MemberStatus } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  DBEnum,
  EmployeeGroup,
  MemberView,
  PaginationType,
  ProgramExtension,
  ProgramExtensionStatus,
} from '@vestahealthcare/common/models';
import MemberProgramExtension from '@vestahealthcare/common/models/MemberProgramExtension';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Colors, MemberInfo, Table } from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

import {
  CHANGE_TYPES,
  ChangeType,
  Op,
  ProgramExtensionOp,
} from './BulkEditTypes';

const MAX_CHANGES = Object.keys(CHANGE_TYPES).length;

interface Props {
  allProgramExtension: ProgramExtension[];
  allProgramExtensionStatuses: ProgramExtensionStatus[];
  changes?: Map<string, ChangeType>;
  className?: string;
  members: MemberView[];
  onChangePage: (page: number) => void;
  onChangePageSize: (page: number) => void;
  pagination?: PaginationType;
  showState?: boolean;
}

const Removed = ({ children }: React.PropsWithChildren<any>) => (
  <span style={{ textDecoration: 'line-through', color: Colors.textRed }}>
    {children}
  </span>
);

const Added = ({ children }: React.PropsWithChildren<any>) => (
  <span style={{ color: Colors.iconGreen }}>{children}</span>
);

const Edited = ({ children }: React.PropsWithChildren<any>) => (
  <span style={{ color: Colors.textOrange }}>{children}</span>
);

const showDiff = (prev?: string, next?: string) => {
  if (!next || prev === next) return prev;

  return (
    <>
      <Removed>{prev}</Removed> <Added>{next}</Added>
    </>
  );
};

const showProgramExtensionDiff = (
  memberProgramExtensions: MemberProgramExtension[],
  changes: ProgramExtensionOp[],
  allProgramExtension: ProgramExtension[],
  allProgramExtensionStatuses: ProgramExtensionStatus[],
) => {
  const keep = memberProgramExtensions.filter(
    ({ programExtension }) =>
      !changes.find(
        ({ id, operation }) =>
          id === programExtension.id && operation !== Op.ADD,
      ),
  );

  const add = changes
    .filter(({ operation }) => operation === Op.ADD)
    .filter(
      ({ id }) =>
        !memberProgramExtensions.find(
          ({ programExtension }) => programExtension.id === id,
        ),
    )
    .map(({ id, status }) => {
      const { name, abbr } =
        allProgramExtension.find(
          (programExtension) => programExtension.id === id,
        ) || {};
      return {
        abbr,
        id,
        name,
        status,
      };
    });

  const edit = changes
    .filter(
      ({ id, operation }) =>
        operation === Op.EDIT &&
        memberProgramExtensions.find(
          ({ programExtension }) => programExtension.id === id,
        ),
    )
    .map(({ id, status }) => {
      const { name, abbr } =
        allProgramExtension.find(
          (programExtension) => programExtension.id === id,
        ) || {};
      return {
        abbr,
        id,
        name,
        status,
      };
    });

  const remove = memberProgramExtensions.filter(({ programExtension }) =>
    changes.find(
      ({ id, operation }) =>
        id === programExtension.id && operation === Op.REMOVE,
    ),
  );

  const list = [
    ...keep.map(({ programExtension: { abbr } }) => abbr),
    ...remove.map<ReactNode>(({ programExtension }) => (
      <Removed key={programExtension.id}>{programExtension.abbr}</Removed>
    )),
    ...add.map<ReactNode>(({ id, abbr, status }) => (
      <Added key={id}>
        {abbr}
        {status &&
          ` [${
            allProgramExtensionStatuses?.find(({ id }) => id === status)
              ?.description || ''
          }]`}
      </Added>
    )),
    ...edit.map<ReactNode>(({ id, abbr, status }) => (
      <Edited key={id}>
        {abbr}
        {status &&
          ` [${
            allProgramExtensionStatuses?.find(({ id }) => id === status)
              ?.description || ''
          }]`}
      </Edited>
    )),
  ];

  if (!list.length) {
    return null;
  }

  return list.reduce((prev, curr) => (
    <>
      {prev}
      {', '}
      {curr}
    </>
  ));
};

const showEmployeesDiff = (
  currentEmployees: string[],
  newEmployees: string[],
) => {
  const keep = currentEmployees.filter((item) => newEmployees.includes(item));

  const add = newEmployees.filter((item) => !currentEmployees.includes(item));

  const remove = currentEmployees.filter(
    (item) => !newEmployees.includes(item),
  );

  const list = [
    ...keep,
    ...remove.map<ReactNode>((name) => <Removed key={name}>{name}</Removed>),
    ...add.map<ReactNode>((name) => <Added key={name}>{name}</Added>),
  ];

  if (!list.length) {
    return null;
  }

  return list.reduce((prev, curr) => (
    <>
      {prev}
      {', '}
      {curr}
    </>
  ));
};

const BULK_REVIEW_COLUMNS = ({
  allProgramExtension,
  allProgramExtensionStatuses,
  changes,
  showState,
}: Props) => {
  const columns: Column[] = [
    {
      headerName: translate('memberBulkEdit.reviewTable.member'),
      component: (member: MemberView) => (
        <MemberInfo link={`#/patients/${member?.id}`} member={member} />
      ),
    },
  ];

  if (showState) {
    columns.push({
      headerName: translate('memberBulkEdit.reviewTable.state'),
      component: ({ state }: MemberView) => state.value,
    });
  }

  if (changes && changes?.size < MAX_CHANGES) {
    const size = 545 - 150 * changes?.size;
    columns.push({
      headerName: '. . .',
      component: () => '. . .',
      width: size > 50 ? size : 50,
    });
  }
  if (changes?.get(CHANGE_TYPES.STATUS)) {
    columns.push({
      headerName: translate(
        `memberBulkEdit.reviewTable.${CHANGE_TYPES.STATUS}`,
      ),
      component: ({ status }: MemberView) =>
        showDiff(
          status?.toString(),
          (changes?.get(CHANGE_TYPES.STATUS) as MemberStatus)?.toString(),
        ) || EMPTY,
    });
  }

  const cpChanges = changes?.get(CHANGE_TYPES.CARE_PLAN_GROUP);
  if (cpChanges) {
    columns.push({
      headerName: translate(
        `memberBulkEdit.reviewTable.${CHANGE_TYPES.CARE_PLAN_GROUP}`,
      ),
      component: ({ carePlanGroup }: MemberView) =>
        showDiff(
          carePlanGroup?.carePlanGroup?.description,
          (cpChanges as DBEnum)?.id ? (cpChanges as DBEnum)?.description : ' ',
        ) || EMPTY,
    });
  }

  const riskLevelChange = changes?.get(CHANGE_TYPES.RISK_LEVEL);
  if (riskLevelChange !== undefined) {
    columns.push({
      headerName: translate(
        `memberBulkEdit.reviewTable.${CHANGE_TYPES.RISK_LEVEL}`,
      ),
      component: ({ riskLevel }: MemberView) =>
        showDiff(
          riskLevel === null ? ' ' : riskLevel?.toString(),
          riskLevelChange === null ? ' ' : riskLevelChange?.toString(),
        ) || EMPTY,
    });
  }

  if (changes?.get(CHANGE_TYPES.RN_OWNER)) {
    columns.push({
      headerName: translate(
        `memberBulkEdit.reviewTable.${CHANGE_TYPES.RN_OWNER}`,
      ),
      component: ({ owner }: MemberView) =>
        showDiff(
          owner?.fullName,
          (changes?.get(CHANGE_TYPES.RN_OWNER) as Selectable)?.label as string,
        ) || EMPTY,
    });
  }

  if (changes?.get(CHANGE_TYPES.POD_MANAGER)) {
    columns.push({
      headerName: translate(
        `memberBulkEdit.reviewTable.${CHANGE_TYPES.POD_MANAGER}`,
      ),
      component: ({ podManager }: MemberView) =>
        showDiff(
          podManager?.fullName,
          (changes?.get(CHANGE_TYPES.POD_MANAGER) as Selectable)
            ?.label as string,
        ) || EMPTY,
    });
  }

  if (changes?.get(CHANGE_TYPES.NP_OWNER)) {
    columns.push({
      headerName: translate(
        `memberBulkEdit.reviewTable.${CHANGE_TYPES.NP_OWNER}`,
      ),
      component: ({ npOwner }: MemberView) =>
        showDiff(
          npOwner?.fullName,
          (changes?.get(CHANGE_TYPES.NP_OWNER) as Selectable)?.label as string,
        ) || EMPTY,
    });
  }

  if ((changes?.get(CHANGE_TYPES.CARE_COORDINATOR) as Selectable[])?.length) {
    columns.push({
      headerName: translate(
        `memberBulkEdit.reviewTable.${CHANGE_TYPES.CARE_COORDINATOR}`,
      ),
      component: ({ careCoordinators }: MemberView) =>
        showEmployeesDiff(
          careCoordinators?.map(({ fullName }) => fullName) || [],
          (changes?.get(CHANGE_TYPES.CARE_COORDINATOR) as Selectable[])?.map(
            ({ label }) => label as string,
          ),
        ) || EMPTY,
    });
  }

  if ((changes?.get(CHANGE_TYPES.ENGAGEMENT_OWNER) as Selectable[])?.length) {
    columns.push({
      headerName: translate(
        `memberBulkEdit.reviewTable.${CHANGE_TYPES.ENGAGEMENT_OWNER}`,
      ),
      component: ({ engagementOwners }: MemberView) =>
        showEmployeesDiff(
          engagementOwners?.map(({ fullName }) => fullName) || [],
          (changes?.get(CHANGE_TYPES.ENGAGEMENT_OWNER) as Selectable[])
            ?.filter(({ value }) => !!value)
            ?.map(({ label }) => label as string),
        ) || EMPTY,
    });
  }

  if ((changes?.get(CHANGE_TYPES.HEALTH_COACH) as Selectable[])?.length) {
    columns.push({
      headerName: translate(
        `memberBulkEdit.reviewTable.${CHANGE_TYPES.HEALTH_COACH}`,
      ),
      component: ({ healthCoaches }: MemberView) =>
        showEmployeesDiff(
          healthCoaches?.map(({ fullName }) => fullName) || [],
          (changes?.get(CHANGE_TYPES.HEALTH_COACH) as Selectable[])
            ?.filter(({ value }) => !!value)
            ?.map(({ label }) => label as string),
        ) || EMPTY,
    });
  }

  if (
    (changes?.get(CHANGE_TYPES.PROGRAM_EXTENSION) as ProgramExtensionOp[])
      ?.length
  ) {
    columns.push({
      headerName: translate(
        `memberBulkEdit.reviewTable.${CHANGE_TYPES.PROGRAM_EXTENSION}`,
      ),
      component: ({ programExtensions }: MemberView) =>
        showProgramExtensionDiff(
          programExtensions,
          changes?.get(CHANGE_TYPES.PROGRAM_EXTENSION) as ProgramExtensionOp[],
          allProgramExtension || [],
          allProgramExtensionStatuses || [],
        ) || EMPTY,
    });
  }

  if (changes?.get(CHANGE_TYPES.WORKLIST_GROUP)) {
    const group = changes?.get(CHANGE_TYPES.WORKLIST_GROUP) as EmployeeGroup;
    columns.push({
      headerName: translate(
        `memberBulkEdit.reviewTable.${CHANGE_TYPES.WORKLIST_GROUP}`,
      ),
      component: ({ worklistGroup }: MemberView) =>
        showDiff(worklistGroup?.name, group?.id ? group.name : ' ') || EMPTY,
    });
  }

  return columns;
};

export const BulkReviewTable = (props: Props) => (
  <Table
    className={props.className}
    config={{
      columns: BULK_REVIEW_COLUMNS(props),
      compact: true,
      data: props.members,
      defaultPageSize: props.pagination?.limit,
      pagination: props.pagination,
      paginationOptions: [10, 25, 50, 100],
    }}
    onChangePage={props.onChangePage}
    onChangeRowsPerPage={props.onChangePageSize}
  />
);

export default BulkReviewTable;
