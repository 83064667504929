/* eslint-disable max-classes-per-file */
import { Enum } from './Enum';

export class PhoneUserType extends Enum {
  static asArray: PhoneUserType[] = [];

  static byKey: { [name: string]: PhoneUserType } = {};

  static readonly PATIENT = new PhoneUserType('platform_patient');

  static readonly CAREGIVER = new PhoneUserType('platform_caregiver');

  static readonly CARE_TEAM_PERSON = new PhoneUserType('care_team_person');

  static readonly CONTACT = new PhoneUserType('contact');

  constructor(public readonly value: string) {
    super();
    PhoneUserType.asArray.push(this);
    PhoneUserType.byKey[value] = this;
  }
}
