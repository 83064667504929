import moment from 'moment';
import React from 'react';

import { DayOfWeek } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  CareTeamMember,
  CareTeamMemberAide,
  CareTeamMemberFamily,
  CareTeamMemberOther,
  CareTeamMemberProvider,
} from '@vestahealthcare/common/models';
import { TIME_FORMAT } from '@vestahealthcare/common/utils/constants';

type Props = {
  member: CareTeamMember;
};

export const CareTeamMemberExtraInfo = ({ member }: Props) => (
  <>
    {member instanceof CareTeamMemberFamily && (
      <>
        {member.paid !== null && (
          <p className="no-margin">
            <span className="bold">
              {translate('careTeam.common.paid')}
              {': '}
            </span>
            {translate(`global.${member.paid ? 'yes' : 'no'}`)}
          </p>
        )}
        {member.livesWithMember !== null && (
          <p className="no-margin">
            <span className="bold">
              {translate('careTeam.common.livesWithMember')}
              {': '}
            </span>
            {translate(`global.${member.livesWithMember ? 'yes' : 'no'}`)}
          </p>
        )}
      </>
    )}
    {member instanceof CareTeamMemberAide && (
      <div className="flex extra-gap">
        {(member.paid !== null ||
          member.temp !== null ||
          member.externalId) && (
          <div
            className="flex extra-gap wrap no-vertical"
            style={{ flex: 0.75 }}
          >
            {member.paid !== null && (
              <>
                <span>
                  <span className="bold">
                    {translate('careTeam.common.paid')}
                    {': '}
                  </span>
                  {translate(`global.${member.paid ? 'yes' : 'no'}`)}
                </span>
              </>
            )}
            {member.temp !== null && (
              <>
                <span>
                  <span className="bold">
                    {translate('careTeam.common.temp')}
                    {': '}
                  </span>
                  {translate(`global.${member.temp ? 'yes' : 'no'}`)}
                </span>
              </>
            )}
            {member.externalId !== null && (
              <span>
                <span className="bold">
                  {translate('careTeam.common.externalId')}
                  {': '}
                </span>
                {member.externalId}
              </span>
            )}
          </div>
        )}
        {!!member.aideTimes?.length && (
          <div className="flex extra-gap">
            <span className="bold" style={{ alignSelf: 'flex-start' }}>
              {translate('careTeam.common.daysOfService')}:{' '}
            </span>
            <div>
              {member
                .getDaysOfService()
                .map(({ dates, startTime, endTime }, index) => (
                  <p
                    className="no-margin"
                    key={`care-team-member-${member.id}-time--${index}`}
                  >
                    <span>{DayOfWeek.stringify(dates)}</span>
                    {(startTime || endTime) && (
                      <>
                        {': '}
                        {startTime
                          ? moment(startTime.getTime()).format(TIME_FORMAT)
                          : '*'}
                        {' - '}
                        {endTime
                          ? moment(endTime.getTime()).format(TIME_FORMAT)
                          : '*'}
                      </>
                    )}
                  </p>
                ))}
            </div>
          </div>
        )}
      </div>
    )}
    {member instanceof CareTeamMemberProvider && member.practiceName !== null && (
      <p className="no-margin">
        <span className="bold">
          {translate('careTeam.common.practiceName')}
          {': '}
        </span>
        {member.practiceName}
      </p>
    )}
    {member instanceof CareTeamMemberOther && member.organizationName !== null && (
      <p className="no-margin">
        <span className="bold">
          {translate('careTeam.common.organization')}
          {': '}
        </span>
        {member.organizationName}
      </p>
    )}
  </>
);
