import classnames from 'classnames';
import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import TaskStatus from '@vestahealthcare/common/enums/TaskStatus';
import { translate } from '@vestahealthcare/common/i18n';
import { Patient, Task } from '@vestahealthcare/common/models';

import { Chip, Fonts } from 'styleguide-v2';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  label: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
  },
  name: {
    maxWidth: '30rem',
  },
  taskList: {
    display: 'flex',
    flexFlow: 'column',
    gap: '0.25rem',
  },
  taskItem: {
    alignItems: 'center',
    display: 'flex',
    gap: '0.5rem',
  },
});

type Props = {
  className?: string;
  tasks?: Task[];
  patient?: Patient;
};

export const EventDetailTasks = ({ className, tasks, patient }: Props) => {
  const styles = useStyles();

  return !tasks?.length || !patient ? (
    <></>
  ) : (
    <div className={classnames(className, styles.container)}>
      <span className={styles.label}>
        {translate('memberEvents.tasks', {
          count: tasks.length,
        })}
      </span>
      <ul className={styles.taskList}>
        {tasks.map(({ id, taskDefinition, status }) => (
          <li className={styles.taskItem} key={`encounter-id-${id}`}>
            <a
              target="_blank"
              rel="noreferrer"
              href={`/#/patients/${patient?.id}/tasks?id=${id}`}
            >
              <span className={styles.name}>{taskDefinition.name}</span>
            </a>
            <Chip
              color={TaskStatus.COLOR_MAP.get(status)?.color}
              type={TaskStatus.COLOR_MAP.get(status)?.type}
              size="small"
            >
              {status.toString()}
            </Chip>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default EventDetailTasks;
