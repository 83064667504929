import { Enum } from './Enum';

export class CaregiverRelationship extends Enum {
  translationKey = 'caregiverRelationship';

  static asArray: CaregiverRelationship[] = [];

  static byKey: { [name: string]: CaregiverRelationship } = {};

  static readonly DAUGHTER = new CaregiverRelationship('DAUGHTER');

  static readonly FRIEND = new CaregiverRelationship('FRIEND');

  static readonly FRIEND_FAMILY_UNKNOWN = new CaregiverRelationship(
    'FRIEND_FAMILY_UNKNOWN',
  );

  static readonly GRANDDAUGHTER = new CaregiverRelationship('GRANDDAUGHTER');

  static readonly GRANDSON = new CaregiverRelationship('GRANDSON');

  static readonly HUSBAND = new CaregiverRelationship('HUSBAND');

  static readonly MEMBER = new CaregiverRelationship('MEMBER');

  static readonly NEIGHBOR = new CaregiverRelationship('NEIGHBOR');

  static readonly NEPHEW = new CaregiverRelationship('NEPHEW');

  static readonly NIECE = new CaregiverRelationship('NIECE');

  static readonly PARENT = new CaregiverRelationship('PARENT');

  static readonly SON = new CaregiverRelationship('SON');

  static readonly SIBLING = new CaregiverRelationship('SIBLING');

  static readonly WIFE = new CaregiverRelationship('WIFE');

  static readonly OTHER = new CaregiverRelationship('OTHER');

  static readonly NOT_RELATED = new CaregiverRelationship('NOT_RELATED');

  constructor(public readonly value: string) {
    super();
    CaregiverRelationship.asArray.push(this);
    CaregiverRelationship.byKey[value] = this;
  }

  toStringReplaceOther(otherRelationship = '') {
    if (this === CaregiverRelationship.OTHER) {
      return otherRelationship || 'Other';
    }
    return super.toString();
  }
}

export default CaregiverRelationship;
