import { CampaignModal, Translation } from '.';
import { make, makeArray } from './Model';
import { AbstractTranslatedEntity } from './AbstractTranslatedEntity';

export class CampaignBanner extends AbstractTranslatedEntity {
  id = 0;

  name = '';

  image = '';

  modal?: CampaignModal;

  ctaLabel?: string;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
    this.translations = obj.translations
      ? makeArray(obj.translations, Translation)
      : [];
    this.modal = obj.modal && make(obj.modal, CampaignModal);
  }
}

export default CampaignBanner;
