// TODO: this file is ancient, review whether it's still needed
import config from 'dash/environments';
import { Employee } from '@vestahealthcare/common/models';

// Segment snippet
(() => {
  // @ts-ignore
  const analytics = window.analytics ? window.analytics : [];
  if (!analytics.initialize) {
    if (analytics.invoked) {
      console.error('Segment snippet included twice.');
    } else {
      analytics.invoked = true;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'page',
        'once',
        'off',
        'on',
      ];
      analytics.factory = (t: any) => () => {
        // @ts-ignore
        const e = Array.prototype.slice.call(arguments);
        e.unshift(t);
        analytics.push(e);
        return analytics;
      };
      for (let t = 0; t < analytics.methods.length; t += 1) {
        const e = analytics.methods[t];
        analytics[e] = analytics.factory(e);
      }
      analytics.load = (t: any) => {
        const https = document.location.protocol === 'https:';
        const e = document.createElement('script');
        e.type = 'text/javascript';
        e.async = !0;
        e.src = `${
          https ? 'https://' : 'http://'
        }cdn.segment.com/analytics.js/v1/${t}/analytics.min.js`;
        document.body.appendChild(e);
      };
      analytics.SNIPPET_VERSION = '3.1.0';
    }
  }
})();

// segment is now set on window.analytics
// for some reason, we cannot re-use the analytics reference. We have to continue
// to access window.analytics.

let analyticsLoaded = false;
// @ts-ignore
if (window.analytics && window.analytics.load) {
  analyticsLoaded = true;
  // @ts-ignore
  window.analytics.load(config.segment && config.segment.writeKey);
}

const identify = (employee: Employee) => {
  if (!analyticsLoaded) {
    return;
  }

  // @ts-ignore
  window.analytics.identify(employee.id, {
    name: employee.fullName,
    firstName: employee.firstName,
    lastName: employee.lastName,
    username: employee.userName,
    email: employee.userName,
    title: employee.role.toString(),
    Revision: process.env.REVISION,
    'Build Date': process.env.BUILD_DATE,
    Environment: process.env.NODE_ENV,
  });
};

export default {
  identify,
};
