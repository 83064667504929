import images from 'dash/static/images';
import { Selectable } from '@vestahealthcare/common/enums/Enum';

const IMAGE_OPTIONS: Selectable[] = [
  {
    value: 'INFO',
    label: images.info,
  },
  {
    value: 'NEWS',
    label: images.news,
  },
  {
    value: 'MEDS',
    label: images.meds,
  },
  {
    value: 'BANDAID',
    label: images.bandaid,
  },
  {
    value: 'HOSPITAL',
    label: images.hospital,
  },
];

export default IMAGE_OPTIONS;
