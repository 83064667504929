import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { Organization } from '@vestahealthcare/common/models';

import {
  Button,
  CollapsableSidebar,
  DateTimePicker,
  FileInput,
  Select,
} from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';

interface Props {
  'data-cy'?: string;
  open?: boolean;
  onCancel?: () => void;
  onSubmit: (referral: Organization, date: Date, file: File) => void;
}

export const UploadReferralModal = (props: Props) => {
  const { open, onCancel, onSubmit } = props;

  const [submitted, setSubmitted] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [referrals, setReferrals] = useState<Organization[]>([]);

  const [date, setDate] = useState<Date>();
  const [referral, setReferral] = useState<Organization>();
  const [file, setFile] = useState<File>();

  const fetchData = async () => {
    const organizations = await CacheServices.getOrganizations();
    setReferrals(organizations.filter(({ hasValidDate }) => hasValidDate));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const reset = () => {
    setDate(undefined);
    setFile(undefined);
    setReferral(undefined);
    setSubmitted(false);
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  const submit = async () => {
    setSubmitted(true);
    if (!date || !moment(date.getTime()).isValid() || !referral || !file)
      return;
    setLoadingSubmit(true);
    await onSubmit(referral, date, file);
    setLoadingSubmit(false);
  };

  const cancelCB = () => {
    onCancel && onCancel();
  };

  return (
    <CollapsableSidebar
      onClose={cancelCB}
      open={!!open}
      title={<h2>{translate('fileHistory.uploadReferralSourceFile')}</h2>}
      size={450}
    >
      <CollapsableSidebar.Body data-cy="upload-referral-file-body">
        <Select
          data-cy="upload-referral-file-referral"
          error={submitted && !referral}
          getItemLabel={({ name }: Organization) => name}
          items={referrals}
          label={translate('fileHistory.referralSource')}
          onChange={setReferral}
          required
          value={referral}
        />
        <DateTimePicker
          data-cy="upload-referral-file-date"
          error={submitted && !date}
          label={translate('fileHistory.dateFileRecieved')}
          maxDate={new Date()}
          onChange={setDate}
          placeholder={translate('global.chooseADate')}
          required
          value={date}
        />
        <FileInput
          accept=".csv,.xls,.txt,.zip"
          data-cy="upload-referral-file-file"
          error={submitted && !file}
          file={file}
          label={translate('fileHistory.uploadFile')}
          onChange={setFile}
          required
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button data-cy="cancel-button" color="tertiary" onClick={cancelCB}>
          {translate('global.cancel')}
        </Button>
        <Button
          data-cy="save-button"
          color="secondary"
          loading={loadingSubmit}
          onClick={submit}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};
