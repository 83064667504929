import {
  DASH_HOME,
  IN_FLIGHT_AUTH_CODE,
  IS_INTEGRATION_TESTS,
  LS_INTENDED_PATH,
  TOKEN_MARKER,
} from '@vestahealthcare/common/utils/constants';

import config from 'dash/environments';
import Api from 'dash/src/services/Api';
import Session from 'dash/src/services/SessionServices';

class LoginService {
  get hostedLoginPage() {
    return (
      `${config.auth.hostedDomainUrl}/login?` +
      `response_type=code&client_id=${config.auth.clientId}&redirect_uri=${config.auth.redirectUrl}`
    );
  }

  async loginWithCode(authCode: string | null) {
    // Clear the impersonator token since this will be stale
    Session.removeImpersonator();

    return Api.postv2JSON('auth/employee-via-code', {
      code: authCode,
      redirectURL: config.auth.redirectUrl,
    });
  }

  async logout() {
    window.localStorage.removeItem(TOKEN_MARKER);
    Session.removeImpersonator();
    await Api.postv2JSON('auth/logout');
    this.redirectToLogin();
  }

  async logoutImpersonated() {
    Session.removeImpersonator();
    await Api.postv2JSON('auth/impersonate/logout');
    window.location.href = DASH_HOME;
    // @ts-ignore
    location.reload(true);
  }

  async impersonate(userId: number) {
    const currentUser = Session.actingUser;
    await Api.postv2JSON(`auth/impersonate/${userId}`);
    await Session.fetchSessionInformation();
    Session.setImpersonatorId(currentUser?.id);
    // window.location.href = DASH_HOME;
    // window.localStorage.setItem(LS_INTENDED_PATH, DASH_HOME);
    // @ts-ignore
    location.reload(true); // need this to flush out places where we store session.actingUser
  }

  get hasToken() {
    return window.localStorage.getItem(TOKEN_MARKER) || false;
  }

  get authCode() {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get('code');
  }

  get isIntegrationTests() {
    return window.localStorage.getItem(IS_INTEGRATION_TESTS) || false;
  }

  redirectToLogin() {
    window.localStorage.removeItem(LS_INTENDED_PATH);
    // @ts-ignore
    window.location = this.hostedLoginPage;
  }

  redirectToLoginAndKeepPath() {
    if (window.location.hash) {
      window.localStorage.setItem(LS_INTENDED_PATH, `/${window.location.hash}`);
    }
    // @ts-ignore
    window.location = this.hostedLoginPage;
  }

  get isAuthorizing() {
    return !!this.authCode;
  }

  get isAuthorized() {
    return !this.isAuthorizing && this.hasToken;
  }

  async authCheck() {
    if (this.isAuthorizing) {
      // Confirm no auth request in flight (auth code is one-time use so cannot make 2 requests)
      // This option replaces the debounce we used before which wasn't 100% safe
      if (window.localStorage.getItem(IN_FLIGHT_AUTH_CODE)) {
        // @ts-ignore
        window.localStorage.removeItem(IN_FLIGHT_AUTH_CODE);
      } else {
        // @ts-ignore
        window.localStorage.setItem(IN_FLIGHT_AUTH_CODE, true);
        // Swap auth code for tokens and save
        return this.loginWithCode(this.authCode)
          .then(() => {
            // We use cookies, so we can't tell if we have tokens or not - this acts as a marker
            // @ts-ignore
            window.localStorage.setItem(TOKEN_MARKER, true);
            // Navigate to intended path
            const intendedPath =
              window.localStorage.getItem(LS_INTENDED_PATH) || DASH_HOME;

            // Cleanup local localStorage now the request has completed
            window.localStorage.removeItem(IN_FLIGHT_AUTH_CODE);

            window.location.replace(intendedPath);
          })
          .catch(() => {
            // TODO LOGINUTILS - make an error page of some kind to redirect to
            window.localStorage.removeItem(IN_FLIGHT_AUTH_CODE);
          });
      }
    } else if (this.isAuthorized) {
      // fetch current employee info
      return Session.fetchSessionInformation();
    } else {
      this.redirectToLoginAndKeepPath();
    }
  }
}

export const LoginServices = new LoginService();

export default LoginServices;
