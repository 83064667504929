import React, { useState } from 'react';

import { Button, JsonSchemaForm, Panel, Select, TextInput } from 'styleguide';

import { startCase } from 'lodash';

import {
  checkboxSchema,
  clinicalFigureSchema,
  commentSchema,
  minMaxSchema,
  radioSchema,
  radioTableSchema,
  scoreSchema,
  timePickerSchema,
} from './__fixtures__';

const schemas: {
  [key: string]: any;
} = {
  checkboxSchema,
  clinicalFigureSchema,
  commentSchema,
  minMaxSchema,
  radioSchema,
  radioTableSchema,
  scoreSchema,
  timePickerSchema,
};

const stringify = (schema: any) => JSON.stringify(schema, null, 4);

const JsonSchemaForms = ({ className }: any) => {
  const [data, setData] = useState({});
  const [activeSchema, setActiveSchema] = useState(scoreSchema);
  const [isReadOnly, setReadOnly] = useState(true);
  const [error, setError] = useState<string>();

  return (
    <Panel className={className}>
      <Panel.Heading>
        <h2>Form Builder</h2>
        <div className="right-side">
          <Select
            name="schemaSelector"
            placeholder="Select schema"
            options={Object.keys(schemas).map((val: string) => ({
              label: startCase(val),
              value: val,
            }))}
            onChange={(value: string) => {
              setData({});
              setActiveSchema(schemas[value]);
            }}
            onInputChange={() => null}
            value={Object.keys(schemas).find(
              (key: string) => schemas[key] === activeSchema,
            )}
          />
          <Button color="primary" onClick={() => setReadOnly(!isReadOnly)}>
            {isReadOnly ? 'Edit' : 'Readonly'}
          </Button>
        </div>
      </Panel.Heading>
      <Panel.Body>
        <JsonSchemaForm
          schema={activeSchema.schema}
          uiSchema={activeSchema.uiSchema}
          formData={data}
          onCancel={() => setReadOnly(!isReadOnly)}
          onChange={(data: any) => setData(data)}
          onSubmit={(data: any) => setData(data)}
          readonly={isReadOnly}
        />

        <hr />
        <b>Data:</b>
        <pre>{stringify(data)}</pre>
        <b>Schema: </b>
        <TextInput
          multiline={20}
          value={stringify(activeSchema)}
          onBlur={(schema: any) => {
            try {
              const parsedSchema = JSON.parse(schema);
              setData({});
              setActiveSchema(parsedSchema);
              setError('');
            } catch (error) {
              // @ts-ignore
              setError(error.message);
            }
          }}
          errorText={error}
        />
      </Panel.Body>
    </Panel>
  );
};

export default JsonSchemaForms;
