import { Enum } from './Enum';

export class Choice extends Enum {
  translationKey = 'choice';

  static asArray: Choice[] = [];

  static byKey: { [name: string]: Choice } = {};

  static readonly YES = new Choice('YES');

  static readonly NO = new Choice('NO');

  constructor(public readonly value: string) {
    super();
    Choice.asArray.push(this);
    Choice.byKey[value] = this;
  }

  static getChoices() {
    return Choice.asArray.map((enumValue) => ({
      value: enumValue === Choice.YES,
      label: enumValue.toString(),
    }));
  }
}

export default Choice;
