import React from 'react';
import { JSONSchema6 } from 'json-schema';
import { WidgetProps } from '../types';
import { RadioTable } from 'styleguide';

interface IRadioTableSchema extends JSONSchema6 {
  columnTitles?: string[];
  enumDescriptions?: string[][];
}

const RadioTableWidget = (props: WidgetProps) => {
  const {
    formContext: { readonly },
    options: { enumOptions },
    schema,
    value,
    onChange,
  }: WidgetProps = props;
  const { title, columnTitles, enumDescriptions }: IRadioTableSchema = schema;

  if (!enumOptions || !columnTitles || !enumDescriptions) {
    throw new Error(
      'No enum options, columnTitles or enumDescriptions defined for radio table',
    );
  }

  return (
    <RadioTable
      title={title}
      options={enumOptions}
      columnTitles={columnTitles}
      descriptions={enumDescriptions}
      value={value}
      onChange={onChange}
      isReadOnly={readonly}
    />
  );
};

export default RadioTableWidget;
