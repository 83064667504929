import {
  Enum,
  MemberContactTimeType,
  MemberRisk,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';

export const RISK_LEVEL_OPTION_NONE_VALUE = 'no-risk-level';
export const RISK_LEVEL_OPTION_NONE_OPTION = {
  value: RISK_LEVEL_OPTION_NONE_VALUE,
  label: translate('memberBulkEdit.noRiskLevel'),
};
export const RISK_LEVEL_OPTIONS = [
  RISK_LEVEL_OPTION_NONE_OPTION,
  ...Enum.toSelectable(MemberRisk.asArray),
];

export const TIME_OPTION_UNKNOWN = {
  label: translate('global.unknown'),
  value: 'UNKNOWN',
};
export const TIME_OPTIONS = [
  ...Enum.toSelectable([
    MemberContactTimeType.MORNING,
    MemberContactTimeType.AFTERNOON,
  ]),
  TIME_OPTION_UNKNOWN,
];

export const VESTA_START_DATE_OPTION_BEFORE = 1;
export const VESTA_START_DATE_OPTION_AFTER = 2;
export const VESTA_START_DATE_OPTIONS = [
  { label: translate('global.before'), value: VESTA_START_DATE_OPTION_BEFORE },
  { label: translate('global.after'), value: VESTA_START_DATE_OPTION_AFTER },
];

export const ATTEMPTS_OPTIONS = [
  { label: translate('global.none'), value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3+', value: 3 },
];

export const RECENT_CYCLES_OPTIONS = [
  { label: '0/3 Adherent', value: 0 },
  { label: '1/3 Adherent', value: 1 },
  { label: '2/3 Adherent', value: 2 },
  { label: '3/3 Adherent', value: 3 },
];

export const RPM_START_OPTIONS = [
  { label: 'Last 7 days', value: 7 },
  { label: 'Last 30 days', value: 30 },
  { label: 'Last 90 days', value: 90 },
];

export const MONTHLY_CCM_STATUSES = [
  {
    label: translate('ccm.common.monthlyCCMStatusCompleted'),
    value: true,
  },
  {
    label: translate('ccm.common.monthlyCCMStatusRemaining'),
    value: false,
  },
];

export const CCM_STATUSES = [
  {
    label: translate('ccm.common.ccmStatusCompleted'),
    value: 'COMPLETED',
  },
  {
    label: translate('ccm.common.ccmStatusAttempted'),
    value: 'ATTEMPTED',
  },
  {
    label: translate('ccm.common.ccmStatusNotStarted'),
    value: 'NOT_STARTED',
  },
];

export const BIANUAL_VV_STATUSES = [
  {
    label: translate('ccm.common.bianualVVCompleted'),
    value: true,
  },
  {
    label: translate('ccm.common.bianualVVNotCompleted'),
    value: false,
  },
];
