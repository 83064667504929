import { Enum } from './Enum';

export class BiometricsLocation extends Enum {
  translationKey = 'biometricsLocation';

  static asArray: BiometricsLocation[] = [];

  static byKey: { [name: string]: BiometricsLocation } = {};

  static readonly HOME = new BiometricsLocation('HOME');

  static readonly DOCTOR_OFFICE = new BiometricsLocation('DOCTOR_OFFICE');

  constructor(public readonly value: string) {
    super();
    BiometricsLocation.asArray.push(this);
    BiometricsLocation.byKey[value] = this;
  }
}

export default BiometricsLocation;
