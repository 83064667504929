import React, { useState } from 'react';

import MAddIcon from '@mui/icons-material/Add';

import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';

import { AddIcon, Button, Modal, Text } from 'styleguide';
import { IconButton } from 'styleguide-v2';

import AddTaskModal from './AddTaskModal';

interface Props {
  member?: Patient;
  onSubmit?:
    | (() => Promise<void>)
    | ((memberId?: number, taskId?: number) => void);
  showSuccessModal?: boolean;
  v2?: boolean;
}

export const AddTaskButton = ({
  onSubmit = () => onClose(),
  member,
  showSuccessModal = false,
  v2,
}: Props) => {
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [showTaskSuccessModal, setShowTaskSuccessModal] = useState(false);

  const onClose = () => {
    setShowAddTaskModal(false);
  };

  return (
    <>
      {v2 ? (
        <IconButton
          onClick={() => setShowAddTaskModal(true)}
          tooltip={translate('memberProfile.add.task')}
        >
          <MAddIcon />
        </IconButton>
      ) : (
        <Button
          color="secondary"
          isPill
          onClick={() => setShowAddTaskModal(true)}
        >
          <AddIcon className="margin-right" />
          {translate('memberProfile.add.task')}
        </Button>
      )}
      <AddTaskModal
        member={member}
        onClose={onClose}
        onSubmit={(memberId?: number, taskId?: number) => {
          setShowTaskSuccessModal(true);
          setShowAddTaskModal(false);
          onSubmit(memberId, taskId);
        }}
        open={showAddTaskModal}
      />

      {showTaskSuccessModal && showSuccessModal && (
        <Modal onHide={() => setShowTaskSuccessModal(false)} show>
          <Modal.Header closeButton modalTitle={translate('tasks.addTask')} />
          <Modal.Body>
            <Text>{translate('tasks.addSuccess')}</Text>
          </Modal.Body>
          <Modal.Footer>
            <div className="right-side">
              <Button
                data-cy="tasks-success"
                color="primary"
                onClick={() => setShowTaskSuccessModal(false)}
              >
                {translate('global.close')}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default AddTaskButton;
