import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';

import { Button, CollapsableSidebar, TextArea } from 'styleguide-v2';

import { showGlobalError } from '../GlobalMessage';

interface Props {
  maxLength?: number;
  note?: string;
  open: boolean;
  onClose: () => void;
  onEdit: (note?: string) => Promise<void>;
  title?: string;
}

export const MemberEventAddNote = ({
  maxLength,
  note: defaultNote,
  open,
  onClose,
  onEdit,
  title,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [note, setNote] = useState<string>();

  const reset = () => {
    setLoading(false);
    setNote(defaultNote);
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  const doSubmit = async () => {
    setLoading(true);
    try {
      await onEdit(note);
      onClose();
    } catch (e) {
      showGlobalError(e as string);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={
        <h2>
          {title ||
            translate(
              `components.noteSection.modal${defaultNote ? 'Edit' : 'Add'}`,
            )}
        </h2>
      }
      size={550}
    >
      <CollapsableSidebar.Body>
        <TextArea
          label={translate('components.noteSection.note')}
          maxLength={maxLength}
          note={maxLength ? `${note?.length || 0}/${maxLength}` : undefined}
          onChange={setNote}
          rows={5}
          value={note}
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button color="tertiary" data-cy="edit-note-close" onClick={onClose}>
          {translate('global.cancel')}
        </Button>
        <Button
          color="secondary"
          data-cy="edit-note-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default MemberEventAddNote;
