import React from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

import { EngagementContent } from '@vestahealthcare/common/models';

import { Fonts } from 'styleguide-v2/src/styles/Variables';

type Props = {
  className?: string;
  'data-cy'?: string;
  content: EngagementContent;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  type: {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    fontWeight: 500,
  },
  extraInfo: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 400,
  },
});

export const SelectContentOption = ({
  className,
  'data-cy': dataCy,
  content,
}: Props) => {
  const styles = useStyles();
  const classes = classnames(className, styles.container);
  const { contentItems, contentType, fileType, id, name, tags } = content;
  const extension = fileType.description.split(' ')[0].toLocaleUpperCase();

  return (
    <div key={id} className={classes} data-cy={dataCy}>
      <span className={styles.type}>{`${name} (${extension})`}</span>
      <span className={styles.extraInfo}>
        {contentType.description}
        {' - '}
        {contentItems
          ?.map(({ language }) => language.value.substring(0, 3))
          .join(' ')}
      </span>
      <span className={styles.extraInfo}>
        {tags?.map(({ description }) => description).join(', ')}
      </span>
    </div>
  );
};

export default SelectContentOption;
