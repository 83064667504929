import { make } from './Model';

export class RosterFileMetadata {
  id = 0;

  originalFilename = '';

  fileTimestamp = 0;

  transferTimestamp = 0;

  source = '';

  sourceOrgId = 0;

  destination = '';

  destinationOrgId = 0;

  transferLocation = '';

  transferPath = '';

  s3Location = '';

  rosterSourceArchive?: RosterFileMetadata;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.rosterSourceArchive = make(
      obj.rosterSourceArchive,
      RosterFileMetadata,
    );
  }

  get processedFilename() {
    return (
      this.originalFilename ||
      this.s3Location.substring(this.s3Location.lastIndexOf('/') + 1)
    );
  }

  get rawFilename() {
    return (
      this.originalFilename ||
      this.s3Location.substring(this.s3Location.lastIndexOf('/') + 1)
    );
  }
}
