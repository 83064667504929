import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import UploadIcon from '@mui/icons-material/UploadFile';

import { translate } from '@vestahealthcare/common/i18n';
import {
  Organization,
  PaginationType,
  RosterFileMetadata,
} from '@vestahealthcare/common/models';
import { EXPORT_DATE_FORMAT } from '@vestahealthcare/common/utils/constants';

import {
  Button,
  DateTimePicker,
  FilterButton,
  IconButton,
  Panel,
  Select,
  TextInput,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CacheServices } from 'dash/src/services';
import {
  downloadFileHistory,
  fetchAllArchiveFiles,
  uploadReferralFile,
} from 'dash/src/services/FileHistoryServices';

import FileHistoryTable from './FileHistoryTable';
import { UploadReferralModal } from './UploadReferralModal';

const ROWS_PER_PAGE = 10;

type FileHistoryFilters = {
  fileDateFrom?: string;
  fileDateTo?: string;
  transferDateFrom?: string;
  transferDateTo?: string;
  sourceOrgId?: Organization[];
  destinationOrgId?: Organization[];
  filename?: string;
};

export const FileHistoryPage = () => {
  const { showFileHistoryUpload } = useFlags();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [organizations, setOrganization] = useState<Organization[]>([]);

  const [filters, setFilters] = useState<FileHistoryFilters>({});

  const [fileList, setFileList] = useState<RosterFileMetadata[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(ROWS_PER_PAGE);

  const fetchData = async () => {
    setLoading(true);
    try {
      const { sourceOrgId, destinationOrgId, ...rest } = filters;
      const { items, pagination } = await fetchAllArchiveFiles({
        ...rest,
        sourceOrgId: sourceOrgId?.map(({ id }) => id),
        destinationOrgId: destinationOrgId?.map(({ id }) => id),
        offset: page * pageSize,
        limit: pageSize,
        sort: 'id desc',
      });
      const organizations = await CacheServices.getOrganizations();
      setOrganization(organizations);
      if (page === 0) {
        setFileList(items);
      } else {
        setFileList([...fileList, ...items]);
      }
      setPagination({ ...pagination, offset: page * pageSize });
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  const refreshUI = () => {
    setRefresh(refresh + 1);
  };

  useEffect(() => {
    fetchData();
  }, [page, pageSize, filters, refresh]);

  return (
    <Panel id="file-history-section" data-cy="file-history-section">
      <Panel.Heading title={translate('fileHistory.title')}>
        <FilterButton
          className="filters"
          data-cy="caregivers-filters"
          filters={filters}
          onClick={() => setShowFilters(!showFilters)}
        />

        {showFileHistoryUpload && (
          <Panel.Actions>
            <IconButton
              onClick={() => setUploadOpen(true)}
              tooltip={translate('fileHistory.uploadReferralFile')}
            >
              <UploadIcon />
            </IconButton>
          </Panel.Actions>
        )}
        <Panel.Collapse open={showFilters}>
          <div className="grid-wrapper" data-cy="file-history-filters">
            <DateTimePicker
              className="grid-span-3"
              data-cy="file-history-start-date-filter"
              maxDate={
                filters?.fileDateTo
                  ? moment(filters.fileDateTo).toDate()
                  : new Date()
              }
              placeholder={translate('fileHistory.fileDateFrom')}
              onChange={(value: Date) => {
                setPage(0);
                setFilters({
                  ...filters,
                  fileDateFrom: value
                    ? moment(value.getTime())?.startOf('day').format()
                    : undefined,
                });
              }}
              value={
                filters?.fileDateFrom
                  ? moment(filters.fileDateFrom).toDate()
                  : undefined
              }
            />
            <DateTimePicker
              className="grid-span-3"
              data-cy="file-history-end-date-filter"
              minDate={
                filters?.fileDateFrom
                  ? moment(filters.fileDateFrom).toDate()
                  : undefined
              }
              maxDate={new Date()}
              placeholder={translate('fileHistory.fileDateTo')}
              onChange={(value?: Date) =>
                setFilters({
                  ...filters,
                  fileDateTo: value
                    ? moment(value.getTime())?.endOf('day').format()
                    : undefined,
                })
              }
              value={
                filters?.fileDateTo
                  ? moment(filters.fileDateTo).toDate()
                  : undefined
              }
            />

            <DateTimePicker
              className="grid-span-3"
              data-cy="file-history-start-date-filter"
              maxDate={
                filters?.transferDateTo
                  ? moment(filters.transferDateTo).toDate()
                  : new Date()
              }
              placeholder={translate('fileHistory.transferDateFrom')}
              onChange={(value?: Date) => {
                setPage(0);
                setFilters({
                  ...filters,
                  transferDateFrom: value
                    ? moment(value.getTime())?.startOf('day').format()
                    : undefined,
                });
              }}
              value={
                filters?.transferDateFrom
                  ? moment(filters.transferDateFrom).toDate()
                  : undefined
              }
            />
            <DateTimePicker
              className="grid-span-3"
              data-cy="file-history-end-date-filter"
              minDate={
                filters?.transferDateFrom
                  ? moment(filters.transferDateFrom).toDate()
                  : undefined
              }
              maxDate={new Date()}
              placeholder={translate('fileHistory.transferDateTo')}
              onChange={(value?: Date) => {
                setPage(0);
                setFilters({
                  ...filters,
                  transferDateTo: value
                    ? moment(value.getTime())?.endOf('day').format()
                    : undefined,
                });
              }}
              value={
                filters?.transferDateTo
                  ? moment(filters.fileDateTo).toDate()
                  : undefined
              }
            />

            <TextInput
              className="grid-span-3"
              data-cy="file-history-name-filter"
              onChange={(value?: string) =>
                setFilters({ ...filters, filename: value || undefined })
              }
              placeholder={translate('fileHistory.fileName')}
              value={filters?.filename || ''}
            />
            <Select
              className="grid-span-3"
              data-cy="file-history-source-organization-filter"
              items={organizations}
              getItemLabel={({ name }: Organization) => name}
              limitTags={1}
              multiple
              onChange={(sourceOrgId: Organization[]) => {
                setPage(0);
                setFilters({
                  ...filters,
                  sourceOrgId,
                });
              }}
              placeholder={translate('fileHistory.sourceOrganization')}
              value={filters?.sourceOrgId}
            />
            <Select
              className="grid-span-3"
              data-cy="file-history-dest-organization-filter"
              items={organizations}
              getItemLabel={({ name }: Organization) => name}
              limitTags={1}
              multiple
              onChange={(destinationOrgId?: Organization[]) => {
                setFilters({
                  ...filters,
                  destinationOrgId,
                });
              }}
              placeholder={translate('fileHistory.destinationOrganization')}
              value={filters?.destinationOrgId}
            />
            <Button
              className="grid-span-3"
              data-cy="file-history-clear-filters"
              color="secondary"
              type="outlined"
              onClick={() => setFilters({})}
            >
              {translate('global.clearFilters')}
            </Button>
          </div>
        </Panel.Collapse>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <FileHistoryTable
          files={fileList}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
          onDowndload={(file) => {
            downloadFileHistory(
              file.id,
              file.originalFilename ||
                `export_${moment
                  .unix(file.transferTimestamp)
                  .format(EXPORT_DATE_FORMAT)}.csv`,
            );
          }}
          pagination={pagination}
        />
        <UploadReferralModal
          open={uploadOpen}
          onSubmit={async (referral, date, file) => {
            try {
              await uploadReferralFile(
                referral.id,
                moment(date.getTime()).format(),
                file,
              );
              refreshUI();
              setUploadOpen(false);
            } catch (e) {
              showGlobalError(e as string);
            }
          }}
          onCancel={() => setUploadOpen(false)}
        />
      </Panel.Body>
    </Panel>
  );
};

export default FileHistoryPage;
