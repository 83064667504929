import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';

import { IconButton, Table, UpdatedBy } from 'styleguide-v2';

import { translate } from '@vestahealthcare/common/i18n';
import { EventType } from '@vestahealthcare/common/models';

interface Props {
  events: EventType[];
  onEdit: (event: EventType) => void;
}

const EVENT_COLUMNS = ({ onEdit }: { onEdit: (event: EventType) => void }) => [
  { headerName: translate('events.name'), field: 'name' },
  {
    headerName: translate('events.status'),
    component: ({ active }: EventType) => (
      <span className="flex">
        {active ? (
          <CheckCircleIcon color="primary" />
        ) : (
          <CancelIcon color="error" />
        )}
        &nbsp;
        {active ? translate('groups.active') : translate('groups.inactive')}
      </span>
    ),
  },
  {
    headerName: translate('events.created'),
    component: ({ createdAt, createdBy }: EventType) => (
      <UpdatedBy date={createdAt} user={createdBy?.fullName} />
    ),
  },
  {
    headerName: translate('events.updated'),
    component: ({ updatedAt, updatedBy }: EventType) => (
      <UpdatedBy date={updatedAt} user={updatedBy?.fullName} />
    ),
  },
  {
    headerName: '',
    component: (event: EventType) => (
      <IconButton size="small" onClick={() => onEdit(event)}>
        <EditIcon />
      </IconButton>
    ),
    width: 20,
  },
];

export const EventsTypesTable = ({ events, onEdit }: Props) => {
  return (
    <Table
      config={{
        compact: true,
        columns: EVENT_COLUMNS({ onEdit }),
        data: events,
        paginationOptions: [-1],
      }}
    />
  );
};

export default EventsTypesTable;
