import React from 'react';
import { parse } from '@vestahealthcare/common/utils/phone';
import { DeleteIcon, PhoneNumber } from 'styleguide';
import Select from 'styleguide/src/components/Select';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import Phone from '@vestahealthcare/common/models/Phone';
import PhoneType from '@vestahealthcare/common/enums/PhoneType';
import { ErrorWithType } from '@vestahealthcare/common/models/ErrorWithType';

import './main.less';

interface Props {
  'data-cy'?: string;
  allowedTypes: PhoneType[];
  disabled?: boolean;
  elementIndex?: number;
  errorText?: ErrorWithType[];
  labelNumber?: string;
  labelType?: string;
  onChange: (val: Phone) => void;
  onRemove?: (val?: number) => void;
  placeholderNumber?: string;
  placeholderType?: string;
  required?: boolean;
  showError?: boolean;
  validator?: (str: string) => boolean;
  value?: Phone;
}

export enum ErrorType {
  NUMBER = 'NUMBER',
  TYPE = 'TYPE',
}

const PhoneComponent = (props: Props) => {
  const {
    allowedTypes,
    disabled,
    elementIndex,
    errorText,
    labelNumber,
    labelType,
    onChange,
    onRemove,
    placeholderNumber,
    placeholderType,
    required,
    showError,
    validator,
    value,
  } = props;

  const phoneNumber = value?.number;
  const phoneType = value?.type;

  const typeOptions = Enum.toSelectable(allowedTypes);
  const selectedTypeOption = typeOptions.find(
    (option: Selectable) => option.value === phoneType?.value,
  );

  const errorNumberText = errorText?.find(
    (err) => err.type === ErrorType.NUMBER,
  )?.message;
  const errorTypeText = errorText?.find((err) => err.type === ErrorType.TYPE)
    ?.message;

  const getPhoneNumber = (number?: string, type?: string) => {
    const parsedNumber = () => {
      if (number) {
        if (number.length > 1) {
          // parse returns undefined at length 1
          return parse(number)?.getNationalNumber()?.toString();
        }
        return number;
      }
      return '';
    };

    return new Phone({
      number: parsedNumber(),
      type,
    });
  };

  const isValid = (number: string) => {
    if (validator) {
      return validator(number);
    }
    return true;
  };

  return (
    <div className="grid-wrapper full-width" data-cy="phone-data">
      <div className="grid-span-5">
        <PhoneNumber
          disabled={disabled}
          errorText={errorNumberText}
          label={labelNumber}
          onChange={(val: string) =>
            onChange(getPhoneNumber(val, phoneType?.value))
          }
          placeholder={placeholderNumber}
          required={required}
          showError={(!value || !isValid(value.number)) && showError}
          value={value?.number}
        />
      </div>
      <div className="grid-span-5">
        <Select
          disabled={disabled}
          errorText={errorTypeText}
          label={labelType}
          onChange={(val: string) => {
            // TODO: how to handle multiple options selected?
            if (Array.isArray(val)) {
              return;
            }
            onChange(getPhoneNumber(phoneNumber, val));
          }}
          options={typeOptions}
          placeholder={placeholderType}
          required={!!phoneNumber}
          showError={showError}
          value={selectedTypeOption}
        />
      </div>
      {onRemove && (
        <div className="grid-span-2 icon-wrapper">
          <DeleteIcon
            onClick={() => onRemove(elementIndex)}
            data-cy="delete-phone"
            tag="button"
          />
        </div>
      )}
    </div>
  );
};

export default PhoneComponent;
