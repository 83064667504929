import classnames from 'classnames';
import React, { ReactElement, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';

import { Button, Modal } from 'styleguide';
import { ButtonColor } from 'styleguide/src/components/Button';

import { ModalSize } from '.';

interface Props {
  dataCy?: string;
  ButtonClass?: React.FunctionComponent<any>;
  buttonColor?: ButtonColor;
  buttonText?: string | React.ReactElement;
  children: any;
  className?: string;
  disabled?: boolean;
  iconComponent?: ReactElement;
  iconType?: string;
  isPill?: boolean;
  loading?: boolean;
  modalColor?: string;
  modalTitle: string | React.ReactElement;
  modalSize?: ModalSize;
  onCancel?: () => void;
  onClick?: Function;
  onSubmit?: (event: any) => boolean | Promise<boolean> | Promise<void> | void;
  submitText?: string;
}

const ButtonModal = (props: Props) => {
  const {
    dataCy,
    ButtonClass,
    buttonColor,
    buttonText,
    className,
    disabled,
    iconComponent,
    iconType,
    isPill,
    loading,
    modalColor,
    modalTitle,
    modalSize,
    onCancel,
    onClick,
    onSubmit,
    submitText,
  } = props;
  const [showModal, setShowModal] = useState(false);

  const cancelCB = () => {
    onCancel && onCancel();
    setShowModal(false);
  };

  const click = () => {
    onClick && onClick();
    setShowModal(true);
  };

  const classes = classnames(
    {
      btn: isPill || buttonColor,
      [`btn-${buttonColor}`]: buttonColor || 'white',
      'btn-pill': isPill,
    },
    className,
  );

  return (
    <>
      {ButtonClass ? (
        <ButtonClass
          className={className}
          disabled={disabled}
          onClick={click}
        />
      ) : (
        <button
          data-cy={dataCy}
          disabled={disabled}
          className={classes}
          onClick={click}
          type="button"
        >
          {iconComponent ||
            (iconType && <i className={`fa fa-${iconType} margin-right`} />)}
          {buttonText}
        </button>
      )}
      <Modal
        loading={loading}
        modalSize={modalSize}
        color={modalColor}
        onHide={cancelCB}
        show={showModal}
      >
        <Modal.Header closeButton modalTitle={modalTitle} />
        <Modal.Body className="grid-span-12">{props.children}</Modal.Body>
        <Modal.Footer
          leftSide={
            <Button
              color="tertiary"
              onClick={cancelCB}
              data-cy="button-modal-cancel-btn"
            >
              {translate('global.cancel')}
            </Button>
          }
          rightSide={
            onSubmit && (
              <Button
                className="submit-btn"
                color="primary"
                data-cy="button-modal-submit-btn"
                loading={loading}
                onClick={async (event) => {
                  if (await onSubmit(event)) {
                    setShowModal(false);
                  }
                }}
              >
                {submitText || translate('global.save')}
              </Button>
            )
          }
        />
      </Modal>
    </>
  );
};

export default ButtonModal;
