import { make } from './Model';
import { IssueDetailField } from './IssueDetailField';

export class IssueDetailFieldGroup {
  id = '';

  issueTypeId = '';

  issueDetailField: IssueDetailField;

  required = false;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.issueDetailField = make(obj.issueDetailField, IssueDetailField);
  }
}

export default IssueDetailFieldGroup;
