import classNames from 'classnames';
import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { MemberDashboardEncounter } from '@vestahealthcare/common/models';

import { Card, Colors, Fonts, Tooltip } from 'styleguide-v2';

import { RecentEncountersTable } from './RecentEncountersTable';

type Props = {
  className?: string;
  data?: MemberDashboardEncounter[];
};

const useStyles = makeStyles({
  container: {
    '&&': {
      borderRadius: '8px',
    },

    display: 'flex',

    '& > div': {
      flex: 1,
      padding: '2.4rem 3.2rem',
    },
  },
  dataContainer: {
    display: 'flex',
  },
  subContainer: {
    display: 'flex',
    flexFlow: 'column',
  },
  title: {
    alignItems: 'center',
    display: 'flex',

    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontWeight: 500,
      margin: 0,
    },
  },
});

export const RecentEncountersCard = ({ className, data }: Props) => {
  const styles = useStyles();

  return (
    <Card className={classNames(className, styles.container)}>
      <div className={styles.subContainer}>
        <div className="flex spaced">
          <span className={styles.title}>
            {translate('memberProfile.dashboard.recentEncounters')}
            &nbsp;
            <Tooltip
              text={translate(
                'memberProfile.dashboard.recentEncountersTooltip',
              )}
            >
              <InfoOutlinedIcon htmlColor={Colors.iconGray} />
            </Tooltip>
          </span>
        </div>
        <br />
        <div className={styles.dataContainer}>
          <RecentEncountersTable data={data} />
        </div>
      </div>
    </Card>
  );
};
