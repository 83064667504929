export class ErrorModel {
  errorCode = '';

  description = '';

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default ErrorModel;
