import { Enum } from '@vestahealthcare/common/enums';

export class EventEvaluationActions extends Enum {
  translationKey = 'eventEvaluationActions';

  static asArray: EventEvaluationActions[] = [];

  static byKey: { [name: string]: EventEvaluationActions } = {};

  static readonly ADD_ADDENDUM = new EventEvaluationActions('ADD_ADDENDUM');

  static readonly ADD_INTERVENTION = new EventEvaluationActions(
    'ADD_INTERVENTION',
  );

  static readonly ADD_NOTE = new EventEvaluationActions('ADD_NOTE');

  static readonly ADD_TRIAGE = new EventEvaluationActions('ADD_TRIAGE');

  static get asClosedArray() {
    return [EventEvaluationActions.ADD_ADDENDUM];
  }

  static get asOpenArray() {
    return [
      EventEvaluationActions.ADD_INTERVENTION,
      EventEvaluationActions.ADD_NOTE,
      EventEvaluationActions.ADD_TRIAGE,
    ];
  }

  constructor(public readonly value: string) {
    super();
    EventEvaluationActions.asArray.push(this);
    EventEvaluationActions.byKey[value] = this;
  }
}

export default EventEvaluationActions;
