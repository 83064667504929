import { startCase } from 'lodash';

export class DashPermission {
  id = 0;

  name = '';

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  get prettyName() {
    return startCase(this.name.toLowerCase().replace(/_/g, ' '));
  }
}

export default DashPermission;
