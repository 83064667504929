import TrackingServices from 'dash/src/services/TrackingServices';
import { fetchSelf } from 'dash/src/services/EmployeeServices';
import { LS_IMPERSONATOR } from '@vestahealthcare/common/utils/constants';
import { Employee } from '@vestahealthcare/common/models';

export default class Session {
  static actingUser: Employee;

  static isOwner(employee: Employee) {
    return this.actingUser && this.actingUser.id === employee.id;
  }

  static async checkAuth() {
    return fetchSelf();
  }

  static isImpersonating() {
    const isImpersonated = window.localStorage.getItem(LS_IMPERSONATOR);
    return !!isImpersonated;
  }

  static getImpersonatorId() {
    return window.localStorage.getItem(LS_IMPERSONATOR);
  }

  static setImpersonatorId(impersonatorId?: number) {
    // @ts-ignore
    window.localStorage.setItem(LS_IMPERSONATOR, impersonatorId);
  }

  static removeImpersonator() {
    window.localStorage.removeItem(LS_IMPERSONATOR);
  }

  static fetchSessionInformation() {
    return fetchSelf()
      .then((employee) => {
        this.actingUser = employee;
        TrackingServices.identify(employee);

        const cachedUserId = window.localStorage.getItem('userId');
        if (!cachedUserId || cachedUserId !== `${employee.id}`) {
          // @ts-ignore
          window.localStorage.setItem('userId', employee.id);

          // Google Analytics
          // @ts-ignore
          if (window.ga) {
            // @ts-ignore
            window.ga('set', 'userId', employee.id);
            // @ts-ignore
            window.ga('send', 'event', 'authentication', 'user-id available');
          }
        }
      })
      .then(() => this.actingUser);
  }
}
