import React, { useState, useEffect } from 'react';
import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import classnames from 'classnames';
import ReactWidgetsDateTimePicker from 'react-widgets/lib/DateTimePicker'; // renaming
import moment from '@vestahealthcare/common/moment';
import { translate } from '@vestahealthcare/common/i18n';
import { Moment } from 'moment-timezone';
import './main.less';
import {
  DATE_FORMAT_SHORT,
  TIME_FORMAT,
} from '@vestahealthcare/common/utils/constants';
import momentLocalizer from 'react-widgets-moment';
import { GridColumns } from '../../utils/types';

momentLocalizer();

const DATE_PARSE_FORMATS = [
  'M/D/YY',
  'M/D/YYYY',
  'M D YY',
  'M D YYYY',
  'MMM D, YY',
  'MMM D, YYYY',
  'MMM D YY',
  'MMM D YYYY',
  'YYYY',
  'M/D',
];

const TIME_PARSE_FORMATS = [
  'HH:mm',
  'H:m',
  'h:m a',
  'h:m A',
  'h:ma',
  'h:mA',
  'h a',
  'h A',
  'ha',
  'hA',
];

type Props = {
  autoFocus?: boolean;
  className?: string;
  columns?: GridColumns;
  dataCy?: string;
  date?: boolean;
  disabled?: boolean;
  errorText?: string;
  id?: string;
  label?: React.ReactNode;
  max?: Moment;
  min?: Moment;
  onChange: (date?: Moment) => void;
  onKeyDown?: Function;
  open?: boolean;
  placeholder?: string;
  required?: boolean;
  showError?: boolean;
  submitted?: boolean;
  time?: boolean;
  translate?: boolean;
  validator?: Function;
  value?: Moment;
  size?: 'small';
} & DefaultProps;
type DefaultProps = Partial<typeof defaultProps>;
const defaultProps = {
  date: true,
  disabled: false,
  required: false,
  showError: false,
  time: false,
  translate: true,
};

const DateTimePicker = (props: Props) => {
  // eslint-disable-next-line no-param-reassign
  props = { ...defaultProps, ...props }; // TODO: See a better way to assign defaultProps value
  const [isUserEditing, setIsUserEditing] = useState(false);
  const [min, setMin] = useState(props.min ? moment(props.min) : null);
  const [max, setMax] = useState(props.max ? moment(props.max) : null);
  const {
    className,
    columns,
    dataCy = 'date-time-picker',
    date,
    errorText,
    id,
    label,
    placeholder,
    required,
    time,
    onChange: onChangeProps,
    onKeyDown: onKeyDownProps,
    value,
    size,
    ...other
  } = props;

  useEffect(() => {
    // We round min/max to start/end of day for date (only) pickers
    const updatedMin =
      props.min &&
      (props.time ? moment(props.min) : moment(props.min).startOf('day'));
    const updatedMax =
      props.max &&
      (props.time ? moment(props.max) : moment(props.max).endOf('day'));

    if (!updatedMin?.isSame(min)) {
      setMin(updatedMin || null);
    }

    if (!updatedMax?.isSame(max)) {
      setMax(updatedMax || null);
    }
  }, [props.time, props.max, props.min]);

  const isValid = () => {
    const { required, value, validator } = props;
    const valid = validator ? validator(value) : true;

    // valid if not required or value is a moment
    return (
      (!required || value instanceof moment) &&
      // if value is between max and min (inclusive)
      (!value || !max || max.isSameOrAfter(value)) &&
      (!value || !min || min.isSameOrBefore(value)) &&
      // if validator approves the value
      valid
    );
  };

  const showValidationError = () => {
    const { showError, submitted } = props;
    return !isUserEditing && (showError || submitted) && !isValid();
  };

  // The package supports both date + time simultaneously by default, but
  // in most of our use cases we're separating between date and time.
  const getFormat = () => {
    const { date, time } = props;
    let format = '';
    if (date) {
      format += DATE_FORMAT_SHORT;
    }
    if (time) {
      if (format) {
        format += ' ';
      }
      format += TIME_FORMAT;
    }
    return format;
  };

  const getFooterFormat = (date: Date | Moment) =>
    `Today: ${moment(date).format(DATE_FORMAT_SHORT)}`;

  const getParseFormats = () => {
    const { date, time } = props;
    const formats = [];
    if (date) {
      formats.push(...DATE_PARSE_FORMATS);
    }
    if (time) {
      formats.push(...TIME_PARSE_FORMATS);
    }
    return formats;
  };

  const onBlur = () => setIsUserEditing(false);

  const onFocus = () => setIsUserEditing(true);

  const onChange = (newVal?: Date) => {
    const { translate } = props;

    let val = newVal instanceof Date ? moment(newVal) : undefined;

    // Check if new value is within min/max range
    // with prop "translate" we decide if we want to do an automagically translation of the new value based on min/max
    if (val && translate) {
      if (min && min.isValid() && val.isBefore(min)) {
        val = moment(min);
      } else if (max && max.isValid() && val.isAfter(max)) {
        val = moment(max);
      }
    }

    onChangeProps(val);
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
    if (onKeyDownProps) {
      onKeyDownProps();
    }
  };

  const classes = classnames(
    className,
    columns && `grid-span-${columns}`,
    size && `ht-field-${size}`,
  );

  delete other.min;
  delete other.max;

  return (
    <FormGroup
      className={classes}
      data-cy={dataCy}
      controlId={id || undefined}
      validationState={showValidationError() ? 'error' : null}
    >
      {label && (
        <ControlLabel>
          {label}
          {required && ' *'}
        </ControlLabel>
      )}
      <ReactWidgetsDateTimePicker
        dataCy={dataCy}
        className={showValidationError() ? 'calendar-error' : undefined}
        date={date}
        footerFormat={getFooterFormat}
        format={getFormat()}
        // FIXME: for unknown reasons, ReactWidgets thinks the return type
        // is Moment | Date, rather than Date
        // @ts-ignore
        max={max && max.isValid() ? max.toDate() : undefined}
        // @ts-ignore
        min={min && min.isValid() ? min.toDate() : undefined}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onToggle={() => {
          /* just to avoid a warning */
        }}
        parse={getParseFormats()}
        placeholder={
          placeholder ||
          translate(time ? 'global.chooseATime' : 'global.chooseADate')
        }
        time={time}
        value={value && value.isValid() ? value.toDate() : undefined}
        {...other}
      />
      {!!errorText && showValidationError() && (
        <HelpBlock className="error-message">{errorText}</HelpBlock>
      )}
    </FormGroup>
  );
};
DateTimePicker.defaultProps = defaultProps;
export default DateTimePicker;
