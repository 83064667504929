import React, { useState, Fragment } from 'react';
import { NavItem } from 'styleguide';
import { CollapseIcon, Icon } from 'styleguide/src/components/Icons';

type LinkProps = {
  active?: boolean;
  children?: any;
  childrenLinks?: LinkProps[];
  childrenLinksExpanded?: boolean;
  dataCy: string;
  href: string;
  iconLeft?: string;
  text: string;
};

export type SidebarSectionProps = {
  header?: string;
  links?: LinkProps[];
};

const SidebarSection = ({ header, links }: SidebarSectionProps) => (
  <Fragment key={header}>
    {header && <h6>{header}</h6>}
    {links
      ?.filter((link) => !!link)
      .map((params) => (
        // eslint-disable-next-line react/jsx-key
        <SidebarLink {...params} />
      ))}
  </Fragment>
);

export const SidebarLink = ({
  active,
  children,
  childrenLinks,
  childrenLinksExpanded,
  dataCy,
  href,
  iconLeft,
  text,
}: LinkProps) => {
  const [expanded, setExpanded] = useState(!!childrenLinksExpanded);
  return (
    <Fragment key={dataCy || text || href}>
      <NavItem active={active} data-cy={dataCy} href={href}>
        {iconLeft && (
          <Icon className="margin-right">
            <i className={`fa fa-${iconLeft}`} />
          </Icon>
        )}
        {childrenLinks && (
          <CollapseIcon
            active={expanded}
            onClick={(evt) => {
              evt?.preventDefault();
              setExpanded(!expanded);
            }}
          />
        )}
        {text}
        {children}
      </NavItem>
      {childrenLinks && expanded && (
        <ul>
          {childrenLinks.map((link, idx) => (
            <SidebarLink
              active={link.active}
              dataCy={link.dataCy}
              href={link.href}
              key={`${href}-${idx}`}
              text={link.text}
            />
          ))}
        </ul>
      )}
    </Fragment>
  );
};

interface Props {
  children?: any;
  collapsible?: boolean;
  sections?: (SidebarSectionProps | undefined | false | null)[];
}

export const Sidebar = (props: Props) => {
  const { children, collapsible = false, sections = [] } = props;
  const [collapsed, setCollapsed] = useState(false);

  return (
    <ul
      className={`nav nav-stacked ${collapsed ? 'collapsed' : ''}`}
      id="page-sidebar"
    >
      {collapsible && (
        <Icon className="collapse-btn" onClick={() => setCollapsed(!collapsed)}>
          <i className={`fa fa-chevron-${collapsed ? 'right' : 'left'}`} />
        </Icon>
      )}
      {children}
      {sections
        .filter((section) => !!section)
        // TODO: unsolved in Typescript https://github.com/microsoft/TypeScript/issues/16069
        .map((props: any, index) => (
          <SidebarSection
            key={index}
            header={props.header}
            links={props.links}
          />
        ))}
    </ul>
  );
};

export default Sidebar;
