import { Employee } from './Employee';
import { make } from './Model';
import { ProgramExtension } from './ProgramExtension';
import { ProgramExtensionStatus } from './ProgramExtensionStatus';

export class MemberProgramExtension {
  id = 0;

  programExtension: ProgramExtension;

  status?: ProgramExtensionStatus;

  createdAt = 0;

  createdBy: Employee;

  updatedAt = 0;

  updatedBy: Employee;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.status = make(obj.status, ProgramExtensionStatus);
    this.programExtension = make(obj.programExtension, ProgramExtension);
    this.createdBy = make(obj.createdBy, Employee);
    this.updatedBy = make(obj.updatedBy, Employee);
  }
}

export default MemberProgramExtension;
