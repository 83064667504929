import { BaseEnum } from './BaseEnum';

export class EngagementCampaignTimingOption extends BaseEnum {
  static BUSINESS_HOURS = 'BUSINESS_HOURS';

  static BUSINESS_DAYS = 'BUSINESS_DAYS';

  toString() {
    return this.description;
  }
}

export default EngagementCampaignTimingOption;
