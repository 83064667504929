import { Enum } from './Enum';

export class BodyPartSide extends Enum {
  translationKey = 'bodyPartSide';

  static asArray: BodyPartSide[] = [];

  static byKey: { [name: string]: BodyPartSide } = {};

  static readonly FRONT = new BodyPartSide('FRONT');

  static readonly BACK = new BodyPartSide('BACK');

  constructor(public readonly value: string) {
    super();
    BodyPartSide.asArray.push(this);
    BodyPartSide.byKey[value] = this;
  }
}

export default BodyPartSide;
