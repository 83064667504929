import classNames from 'classnames';
import React, { useState } from 'react';

import MSlider from '@mui/material/Slider';
import { makeStyles } from '@mui/styles';

import { Label } from '../Label';

type Props = {
  className?: string;
  'data-cy'?: string;
  label?: string;
  marks?: boolean | { value: number; label: string }[];
  max?: number;
  min?: number;
  onChange: (value: number | number[]) => void;
  step?: number;
  value: number | number[];
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  slider: {
    margin: '0 1rem 1rem 1rem',
    '&&': {
      height: 7,
      width: 'calc(100% - 2rem)',

      '&& .MuiSlider-mark:first-child ': {
        left: '2%',
      },
      '&& .MuiSlider-mark:last-child': {
        left: '98%',
      },
      '&& .MuiSlider-mark': {
        height: 4,
        width: 4,
        borderRadius: 2,
      },
      '&& .MuiSlider-thumb': {
        height: 15,
        width: 15,
      },
    },
  },
});

export const Slider = ({
  className,
  'data-cy': dataCy,
  label,
  marks,
  max,
  min,
  onChange,
  step,
  value,
}: Props) => {
  const styles = useStyles();
  const [currentValue, setCurrentValue] = useState<number[]>();

  return (
    <div className={classNames(className, styles.container)} data-cy={dataCy}>
      {label && <Label>{label}</Label>}
      <MSlider
        className={styles.slider}
        disableSwap
        marks={marks}
        max={max}
        min={min}
        onChange={(_, newValue) => {
          if (
            newValue instanceof Array &&
            (!currentValue ||
              newValue[0] !== currentValue[0] ||
              newValue[1] !== currentValue[1])
          ) {
            onChange(newValue);
            setCurrentValue(newValue);
          }
        }}
        step={step}
        value={value}
        valueLabelDisplay="auto"
      />
    </div>
  );
};
