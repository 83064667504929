import React from 'react';
import classnames from 'classnames';

import makeStyles from '@mui/styles/makeStyles';
import WarningIcon from '@mui/icons-material/Warning';

import { BackgroundColors, Colors, Fonts } from 'styleguide-v2';
import { translate } from '@vestahealthcare/common/i18n';

const useStyles = makeStyles({
  container: {
    flex: '0 1 62.5rem',
  },
  panelContainer: {
    alignItems: 'center',
    background: BackgroundColors.lightCoral,
    borderRadius: '5px',
    display: 'flex',
    gap: '0.5rem',
    justifyContent: 'center',
    margin: '0.5rem',
    padding: '0.5rem',
  },

  text: {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    color: Colors.lightCoral,
  },

  icon: {
    height: `calc(${Fonts.fontSize} * 1)`,
    width: `calc(${Fonts.fontSize} * 1)`,
    color: Colors.lightCoral,
  },
});

type Props = {
  className?: string;
  onClickRefresh: () => void;
};

export const EventRefreshPanel = ({ className, onClickRefresh }: Props) => {
  const styles = useStyles();

  return (
    <div
      className={classnames(className, styles.container, styles.panelContainer)}
    >
      <WarningIcon className={styles.icon} />
      <span className={styles.text}>
        {translate('memberEvents.panelNewEvent')}
        {' - '}

        <a
          href=""
          onClick={(evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            onClickRefresh();
          }}
        >
          {translate('memberEvents.panelNewEventRefresh')}
        </a>
      </span>
    </div>
  );
};
export default EventRefreshPanel;
