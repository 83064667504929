import React, { useEffect, useState } from 'react';

import { Language } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import {
  EditLanguages as EditComponent,
  LanguagePreference,
} from 'dash/src/components/EditLanguages';
import { updatePatientLanguages } from 'dash/src/services/PatientServices';

import { BaseModal } from './BaseModal';

interface Props {
  patient: Patient;
  onSubmit: (patient: Patient) => {};
}

const DetailsComponent = React.memo(
  ({ languages }: { languages?: LanguagePreference[] }) => (
    <div
      data-cy="member-phone-numbers-readonly"
      className="grid-span-11 grid-wrapper"
      style={{ gridGap: 0, height: 'fit-content' }}
    >
      <h4 className="grid-span-6">{translate('personalDetails.languages')}</h4>
      <div className="grid-span-6">
        {languages?.length ? (
          languages.map((l) => (
            <p
              className="no-margin"
              key={`member-language-${l.language?.value}`}
            >
              {l.language?.toString()}{' '}
              {l.preferred ? `(${translate('global.preferred')})` : ''}
            </p>
          ))
        ) : (
          <span>{EMPTY}</span>
        )}
      </div>
    </div>
  ),
);

export const EditLanguages = ({ patient, onSubmit }: Props) => {
  const editable = patient.isEditable();

  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [languages, setLanguages] = useState<LanguagePreference[]>([]);
  const [currentLanguages, setCurrentLanguages] = useState<
    Partial<LanguagePreference>[]
  >([]);

  const isValid = () =>
    currentLanguages.every((item) => !!item.language) &&
    currentLanguages.some((item) => item.preferred);

  const reset = () => {
    const languages =
      patient.languages
        ?.map((l) => ({
          language: l,
          preferred: l === patient.language,
        }))
        .sort(({ preferred: a }, { preferred: b }) => {
          if (a) return -1;
          if (b) return 1;
          return 0;
        }) || [];
    setLanguages(languages);
    setCurrentLanguages(languages);
    setSubmitted(false);
    setLoading(false);
  };

  const submit = async (): Promise<boolean> => {
    let result = true;
    setSubmitted(true);
    if (!isValid()) return false;
    setLoadingSubmit(true);

    try {
      await updatePatientLanguages(
        patient.id,
        currentLanguages.map((l) => l.language).filter(Boolean) as Language[],
        currentLanguages.find((l) => l.preferred)?.language as Language,
      );
      onSubmit(patient);
    } catch (e) {
      result = false;
    }

    setLoadingSubmit(false);
    return result;
  };

  useEffect(() => reset(), [patient]);

  return (
    <BaseModal
      details={<DetailsComponent languages={languages} />}
      data-cy="member-phone-numbers"
      editable={editable}
      loading={loading}
      loadingSubmit={loadingSubmit}
      onSubmit={submit}
      onShowModal={reset}
      title={translate('personalDetails.languages')}
    >
      <EditComponent
        disabledPreferred={
          !!patient.isCrmIntegrated() || !patient.isLanguageEditable()
        }
        languages={currentLanguages}
        submitted={submitted}
        onChange={setCurrentLanguages}
      />
    </BaseModal>
  );
};
