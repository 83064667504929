import React, { ReactNode } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    gridArea: 'detail',
    overflowX: 'auto',
    padding: '1rem 4rem',
    width: '100%',
  },
});

type Props = {
  children?: ReactNode;
  width?: number;
};

export const VerticalDetail = ({ children }: Props) => {
  const styles = useStyles();

  return <div className={styles.container}>{children}</div>;
};
