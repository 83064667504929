import { Moment } from 'moment';

import { makeMoment } from '../Model';
import { MemberWarning } from './MemberWarning';

export class MemberWarningNPBiannual extends MemberWarning {
  lastBiannualDate?: Moment;

  lastBiannualType = '';

  lastBiannualEmployee = '';

  nextBiannualDate?: Moment;

  nextBiannualType = '';

  nextBiannualEmployee = '';

  nextBiannualDue?: Moment;

  constructor(obj: any) {
    super(obj);
    Object.assign(this, obj);
    this.lastBiannualDate = makeMoment(obj.lastBiannualDate);
    this.nextBiannualDate = makeMoment(obj.nextBiannualDate);
    this.nextBiannualDue = makeMoment(obj.nextBiannualDue);
  }
}

export default MemberWarningNPBiannual;
