import { BaseEnum } from './BaseEnum';
import { CareQuestion } from './CareQuestion';
import { Employee } from './Employee';
import { make, makeItems } from './Model';
import { Patient } from './Patient';
import { UserBase } from './UserBase';

export class HealthReport {
  caregiver: UserBase;

  careQuestions: CareQuestion[];

  channel: BaseEnum;

  direction: BaseEnum;

  completed = false;

  completedQuestions = 0;

  creator?: Employee;

  endTime?: number;

  id = 0;

  patient: Patient;

  startTime = 0;

  totalQuestions = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.caregiver = make(obj.caregiver, UserBase);
    this.careQuestions = makeItems(obj.careQuestions, CareQuestion);
    this.channel = make(obj.channel, BaseEnum);
    this.direction = make(obj.direction, BaseEnum);
    this.creator = make(obj.creator, Employee);
    this.patient = make(obj.patient, Patient);
  }
}

export default HealthReport;
