import React, { useState } from 'react';

import { Enum, PatientGender } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models/Patient';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Select, TextArea } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { updateGender } from 'dash/src/services/PatientServices';

import { BaseModal } from './BaseModal';

interface Props {
  patient: Patient;
  onSubmit: (patient: Patient) => void;
}

export const EditGenderModal = (props: Props) => {
  const { patient, onSubmit } = props;
  const editable = patient.isEditable() && patient.isDemographicsEditable();
  const [isLoading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [gender, setGender] = useState(patient.gender);
  const [genderComment, setGenderComment] = useState(patient.genderComment);

  const close = () => {
    setLoading(false);
    setSubmitted(false);
  };

  const cancel = () => {
    close();
    setGender(patient.gender);
    setGenderComment(patient.genderComment);
  };

  const submit = async () => {
    let result = true;

    setSubmitted(true);
    if (!gender) {
      return false;
    }
    setLoadingSubmit(true);

    try {
      const newPatient = await updateGender(
        patient.id,
        gender,
        genderComment || '',
      );
      onSubmit(newPatient);
      setSubmitted(false);
    } catch (e) {
      showGlobalError(e as string);
      result = false;
    }

    setLoadingSubmit(false);
    return result;
  };

  const renderDetails = (patient: Patient) => (
    <>
      <h4 data-cy="personal-details-gender-label" className="grid-span-5">
        {translate('personalDetails.gender')}
      </h4>
      <p className="grid-span-6" data-cy="personal-details-gender">
        {patient.gender ? patient.gender.toString() : EMPTY}
        {patient.genderComment && <small> -{patient.genderComment}</small>}
      </p>
    </>
  );

  return (
    <BaseModal
      details={renderDetails(patient)}
      data-cy="patient-gender"
      editable={editable}
      loading={isLoading}
      loadingSubmit={loadingSubmit}
      onCancel={cancel}
      onSubmit={submit}
      title={translate('personalDetails.gender')}
    >
      <div className="grid-wrapper fit">
        <Select
          className="grid-span-12"
          disableClearable
          error={submitted && !gender}
          label={translate('personalDetails.gender')}
          items={Enum.toSelectable(PatientGender.asArray)}
          onChange={(item?: Selectable) =>
            setGender(item ? PatientGender.byKey[item.value] : undefined)
          }
          required
          value={gender ? Enum.toSelectable([gender])[0] : undefined}
        />
        <TextArea
          className="grid-span-12"
          disabled={!gender}
          value={genderComment}
          rows={3}
          onChange={setGenderComment}
          placeholder={translate('personalDetails.addComment')}
        />
      </div>
    </BaseModal>
  );
};

export default EditGenderModal;
