import { Enum } from './Enum';

export class DeviceOS extends Enum {
  translationKey = 'deviceOS';

  static asArray: DeviceOS[] = [];

  static byKey: { [name: string]: DeviceOS } = {};

  static readonly IOS = new DeviceOS('IOS');

  static readonly ANDROID = new DeviceOS('ANDROID');

  constructor(public readonly value: string) {
    super();
    DeviceOS.asArray.push(this);
    DeviceOS.byKey[value] = this;
  }
}

export default DeviceOS;
