import { CareQuestionType, Language } from '../enums';
import moment from '../moment';
import { makeItems } from './Model';
import { StructuredAnswer } from './StructuredAnswer';

export type CareQuestionQuestionType = string;

export class CareQuestion {
  answer?: any; // TODO

  caregiverInstructions = '';

  completed = false;

  iconUrl = '';

  id = 0;

  language: Language = Language.ENGLISH;

  position = 0;

  question: CareQuestionQuestionType = '';

  questionTime?: number;

  structuredAnswers: StructuredAnswer[];

  title = '';

  englishTitle = '';

  type: CareQuestionType;

  updatedAt = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.structuredAnswers = makeItems(obj.structuredAnswers, StructuredAnswer);
    this.language = Language.byKey[obj.language] || Language.ENGLISH;
    this.type = CareQuestionType.byKey[obj.type];
  }

  get selectedAnswer() {
    return this.structuredAnswers.find((answer) => answer.selected);
  }

  get displayedAnswer() {
    return this.selectedAnswer
      ? this.selectedAnswer.humanReadableAnswer
      : this.answer;
  }

  get translatedDisplayedAnswer() {
    return this.selectedAnswer
      ? this.selectedAnswer.englishHumanReadableAnswer
      : this.answer;
  }

  get formattedTitle() {
    if (this.questionTime) {
      const time = moment(this.questionTime).format('h:mm A');
      return `${time} - ${this.title}`;
    }

    return this.title;
  }

  static CALL_FROM_NURSE = 'CALL_FROM_NURSE';

  static NO_NOT_TODAY = 'NO_NOT_TODAY';
}

export default CareQuestion;
