import React, { useState } from 'react';
import { Employee } from '@vestahealthcare/common/models';
import { Image } from 'react-bootstrap';
import config from 'dash/environments';
import { Avatar as BaseAvatar } from 'styleguide';

const AVATAR_SIZE = 38;
const DEFAULT_USER_IMAGE_SIZE = 250;

interface Props {
  user: Employee;
}

export const getUserImage = (id: number) =>
  `${config.staticUrl}/team/${id}/${DEFAULT_USER_IMAGE_SIZE}.jpg`;

export const Avatar = (props: Props) => {
  const { user, ...other } = props;

  const [hasError, setError] = useState(false);

  if (!user || !user.hasPicture || hasError) {
    return <BaseAvatar user={user} />;
  }

  const src = getUserImage(user.id);

  return (
    <Image
      alt={user.fullName}
      className="img-avatar"
      height={AVATAR_SIZE}
      onError={() => setError(true)}
      responsive
      src={src}
      title={user.fullName}
      width={AVATAR_SIZE}
      {...other}
    />
  );
};

export default Avatar;
