import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import {
  CheckboxWithLabel,
  FilterItem,
  FilterSelectable,
  SearchInput,
} from 'styleguide-v2';

import { TaskFetchParams } from 'dash/src/services/TaskServices';
import { useDebounce } from 'dash/src/utils/debounceUtils';
import { getFilterItem } from 'dash/src/utils/filterUtils';

type KeyGetTaskParams = keyof TaskFetchParams;

type Props = {
  filters?: {
    [x in KeyGetTaskParams]?: FilterItem;
  };
  onChange: (
    filters: {
      [x in KeyGetTaskParams]?: FilterItem;
    },
  ) => void;
};

const useStyles = makeStyles({
  check: {
    alignSelf: 'center',
    '&&': {
      flex: '0 0 15rem',
    },
  },
  search: {
    alignSelf: 'center',
    '&&': {
      maxWidth: '60rem',
    },
  },
});

export const TaskFiltersBar = ({ filters, onChange }: Props) => {
  const styles = useStyles();

  const [queryString, setQueryString] = useState<string>();

  const queryStringDebounce = useDebounce(queryString, 300);

  useEffect(() => {
    setQueryString(
      (filters?.queryString?.value as FilterSelectable)?.value as string,
    );
  }, [filters]);

  useEffect(() => {
    if (!queryStringDebounce) {
      if (filters?.queryString) {
        onChange({ ...filters, queryString: undefined });
      }
    } else if (queryStringDebounce.length > 2) {
      onChange({
        ...filters,
        queryString: getFilterItem(
          translate('tasks.searchTasks'),
          queryStringDebounce,
        ),
      });
    }
  }, [queryStringDebounce]);

  return (
    <>
      <SearchInput
        className={styles.search}
        label=""
        onChange={setQueryString}
        placeholder={translate('tasks.searchTasks')}
        value={queryString}
        size="small"
      />
      <CheckboxWithLabel
        className={styles.check}
        checked={
          !!(filters?.topLevelOnly?.value as FilterSelectable)?.value as boolean
        }
        label={translate('tasks.showAllParents')}
        onChange={(value) => {
          onChange({
            ...filters,
            topLevelOnly: getFilterItem(
              translate('tasks.showAllParents'),
              value,
            ),
          });
        }}
      />
      <CheckboxWithLabel
        className={styles.check}
        checked={
          !!(filters?.subTasksOnly?.value as FilterSelectable)?.value as boolean
        }
        label={translate('tasks.showAllSubTasks')}
        onChange={(value) => {
          onChange({
            ...filters,
            subTasksOnly: getFilterItem(
              translate('tasks.showAllSubTasks'),
              value,
            ),
          });
        }}
      />
    </>
  );
};
