import { makeArray } from '../Model';
import MemberWarningItemAction from './MemberWarningItemAction';
import { MemberWarningOpenItems } from './MemberWarningOpenItems';

export class MemberWarningOpenItemsActions extends MemberWarningOpenItems {
  items: MemberWarningItemAction[];

  constructor(obj: any) {
    super(obj);
    this.items = makeArray(obj.items, MemberWarningItemAction);
  }
}

export default MemberWarningOpenItemsActions;
