import { Address } from '../models';
import { validate } from './phone';

export const isValidZipCode = (code: string): boolean =>
  !!code && /^\d{5}(-\d{4})?$/.test(code);

export const isValidCity = (city: string): boolean =>
  !!city && /^[a-zA-Z ]+$/.test(city);

export const isValidMail = (mail: string): boolean =>
  !!mail && /[^\s]+@[a-z0-9.-]+/i.test(mail);

export const isValidURL = (url: string): boolean => {
  if (!url) return false;
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};

export const ellipsis = (text: string, maxLenght: number = 30): string =>
  text.length > maxLenght ? `${text.substring(0, maxLenght - 3)}...` : text;

export const isValidAddress = (address: Address): boolean =>
  !!(
    address.address1 &&
    isValidCity(address.city) &&
    address.state &&
    isValidZipCode(address.zipCode)
  );

export const isValidPhone = validate;
