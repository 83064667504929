// @ts-ignore
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';

import { ConversationStatus } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  CareTeamPerson,
  Conversation,
  PatientSearch,
} from '@vestahealthcare/common/models';

import {
  FilterButton,
  IconButton,
  Panel,
  SearchInput,
  Select,
  SelectCareTeamPersonOption,
  ToggleButton,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { SearchMember } from 'dash/src/components/SearchMember';
import { useSelector } from 'dash/src/redux/store';
import { PaginatedResponseValues } from 'dash/src/services/Api';
import {
  SMSFilters,
  getSMSConversations,
} from 'dash/src/services/MessageServices';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import {
  GetCareTeamPersonsParams,
  fetchCareTeamPeople,
} from '../../../services/CareTeamServices';
import CreateMessageModal from './components/CreateMessageModal';
import SMSTable from './components/SMSTable';

type Params = {
  patientId?: string;
};

type Props = {
  detailPath: string;
  showFrom?: boolean;
  showHeader?: boolean;
};

const SMS_TAB_UNREAD = 1;
const SMS_TAB_OPEN = 2;
const SMS_TAB_ALL = 3;
const SMS_TABS = [
  { label: translate('chat.unread'), value: SMS_TAB_UNREAD },
  { label: translate('chat.open'), value: SMS_TAB_OPEN },
  { label: translate('chat.all'), value: SMS_TAB_ALL },
];

export const Chats = ({
  detailPath = 'clinical',
  showFrom,
  showHeader,
}: Props) => {
  const history = useHistory();
  const { patientId } = useParams<Params>();
  const patient = useSelector((state) => state.memberInfoSlice.patient);
  const [isOpen, setOpen] = useState(!!patientId);
  const [loading, setLoading] = useState(false);
  const [caregiversloading, setCaregiversLoading] = useState(false);
  const [ctpFilter, setCTPFilter] = useState('');
  const [careTeamPersons, setCareTeamPersons] = useState<CareTeamPerson[]>([]);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [pagination, setPagination] = useState<
    PaginatedResponseValues | undefined
  >();
  const [showCreateMessageModal, setShowCreateMessageModal] = useState(false);

  const initFilters = (): SMSFilters => {
    const query = useQueryParams();
    const read = query.get('read') === 'false' ? false : undefined;
    const status = query.get('status') || undefined;
    const userPhone = query.getAll('userPhone') || undefined;
    const memberId =
      patientId && !isNaN(Number(patientId)) ? [Number(patientId)] : undefined;

    return {
      memberId,
      read,
      status,
      userPhone,
    };
  };
  const [filters, setFilters] = useState<SMSFilters>(initFilters());
  const { status, read, ...countFilters } = filters;

  const SEARCH_LIMIT = 25;

  const fetchConversations = async (
    currentPagination?: PaginatedResponseValues,
  ) => {
    const { offset = 0, limit = 0, total = 0 } = currentPagination || {};

    if (loading) return;
    if (offset + limit > total) return;

    setLoading(true);

    try {
      const { items, pagination } = await getSMSConversations({
        ...filters,
        limit: SEARCH_LIMIT,
        offset: currentPagination ? offset + limit : 0,
        sort: 'messageId desc',
      });
      if (currentPagination) {
        setConversations([...conversations, ...items]);
      } else {
        setConversations(items);
      }
      setPagination(pagination);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  useEffect(() => {
    setConversations([]);
    fetchConversations();
    const query = qs.stringify(
      {
        ...filters,
        read: filters.read === false ? 'false' : undefined,
      },
      { arrayFormat: 'repeat' },
    );
    history.replace(`?${query}`);
  }, [filters]);

  const getCareTeamPersons = async () => {
    setCaregiversLoading(true);
    const filters: GetCareTeamPersonsParams = {
      limit: SEARCH_LIMIT,
      sort: 'firstName asc',
    };
    if (isNaN(Number(ctpFilter))) {
      filters.fullName = ctpFilter;
    } else {
      filters.id = [Number(ctpFilter)];
    }
    const { items } = await fetchCareTeamPeople(filters);
    setCaregiversLoading(false);
    setCareTeamPersons(items);
  };

  useEffect(() => {
    if (showHeader && ctpFilter.length) {
      setCareTeamPersons([]);
      getCareTeamPersons();
    }
  }, [ctpFilter]);

  const getTabsValue = (f: SMSFilters) => {
    if (f.read === false) return SMS_TAB_UNREAD;
    if (f.status === ConversationStatus.OPEN.value) return SMS_TAB_OPEN;
    return SMS_TAB_ALL;
  };

  const onScrollBottom = () => fetchConversations(pagination);

  return (
    <Panel>
      {showHeader && (
        <Panel.Heading title={translate('chat.sms')}>
          <FilterButton
            className="filters"
            data-cy="sms-filters"
            filters={countFilters}
            onClick={() => setOpen(!isOpen)}
          />
          <Panel.Actions>
            <ToggleButton
              disabled={loading}
              items={SMS_TABS}
              onChange={(value: number) => {
                if (value === 1) {
                  setFilters({
                    ...filters,
                    read: false,
                    status: ConversationStatus.OPEN.value,
                  });
                } else if (value === 2) {
                  setFilters({
                    ...filters,
                    read: undefined,
                    status: ConversationStatus.OPEN.value,
                  });
                } else {
                  setFilters({
                    ...filters,
                    read: undefined,
                    status: undefined,
                  });
                }
              }}
              value={getTabsValue(filters)}
            />
            <IconButton
              onClick={() => setShowCreateMessageModal(true)}
              tooltip={translate('chat.createMessage')}
            >
              <AddIcon />
            </IconButton>
          </Panel.Actions>
          <Panel.Collapse data-cy="sms-filters-collapsible" open={isOpen}>
            <div className="grid-wrapper grid-span-12 fit">
              <SearchMember
                className="grid-span-4"
                data-cy="sms-member-filter"
                defaultValue={patient && patientId ? [patient] : undefined}
                multiple
                onChange={(values: PatientSearch[]) =>
                  setFilters({
                    ...filters,
                    memberId: values.map(({ id }) => id),
                  })
                }
                placeholder={translate('chat.searchMember')}
              />
              <Select
                className="grid-span-4"
                data-cy="sms-care-team-filter"
                getItemLabel={(ctm) => ctm?.fullName}
                icon={<SearchIcon />}
                items={careTeamPersons}
                loading={caregiversloading}
                multiple
                noOptionsText={
                  ctpFilter.length < 1
                    ? translate('chat.typeToSearch')
                    : translate('chat.noCareTeamPersonFound')
                }
                onChange={(values: CareTeamPerson[]) =>
                  setFilters({
                    ...filters,
                    careTeamPersonId: values.map(({ id }) => id),
                  })
                }
                onInputChange={setCTPFilter}
                placeholder={translate('chat.searchCareTeamPerson')}
                renderOption={(person: CareTeamPerson) => (
                  <SelectCareTeamPersonOption person={person} />
                )}
              />
              <SearchInput
                className="grid-span-4"
                data-cy="sms-phone-filter"
                label=""
                onSearch={(value) =>
                  setFilters({
                    ...filters,
                    userPhone: value ? [value] : undefined,
                  })
                }
                onChange={(value) => {
                  if (!value) {
                    setFilters({
                      ...filters,
                      userPhone: undefined,
                    });
                  }
                }}
                placeholder={translate('chat.searchPhoneNumber')}
                value={filters?.userPhone ? filters?.userPhone[0] : ''}
              />
            </div>
          </Panel.Collapse>
        </Panel.Heading>
      )}
      <Panel.Body
        onScrollBottom={onScrollBottom}
        loading={loading && !conversations.length}
      >
        {(!loading || !!conversations.length) && (
          <SMSTable
            items={conversations}
            loading={loading}
            onClickRow={({ userPhone }: Conversation) => {
              const path = detailPath
                .replace(':patientId', patientId || '')
                .replace(':userPhone', userPhone);
              history.push(path);
            }}
            patientId={patient?.id}
            showFrom={showFrom}
          />
        )}
        <CreateMessageModal
          open={showCreateMessageModal}
          onClose={() => setShowCreateMessageModal(false)}
          onSubmit={async () => {
            if (filters.read === false) {
              setFilters({
                ...filters,
                read: undefined,
                status: ConversationStatus.OPEN.value,
              });
            } else {
              await fetchConversations();
            }
          }}
        />
      </Panel.Body>
    </Panel>
  );
};

export default Chats;
