import moment from 'moment';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { Brand, Organization } from '@vestahealthcare/common/models';
import {
  EMPTY,
  NO_TIME_DATE_FORMAT,
} from '@vestahealthcare/common/utils/constants';

import { CollapseIcon, EditIcon, EmptyState } from 'styleguide';
import { Fonts, Table, UpdatedBy } from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import { sortBy } from 'lodash';

interface OrganizationExtended extends Organization {
  collapsed: boolean;
  detail: ReactNode;
}
interface Props {
  referrals: Organization[];
  originals: Organization[];
  onEdit: (organization: Organization) => void;
}

const COLUMNS = (
  onEdit: (organization: Organization) => void,
  toggleDetail: (organization: Organization) => void,
  brandList: Brand[],
) => [
  { headerName: 'Name', field: 'name', sortable: true, width: '20%' },
  { headerName: 'Abbr', field: 'abbr' },
  { headerName: 'Type', field: 'type.name' },
  {
    headerName: 'Brand',
    component: ({ brandId }: Organization) =>
      brandList?.find(({ id }) => id === brandId)?.name || EMPTY,
  },
  { headerName: 'Status', field: 'status.name', width: 100 },
  {
    width: 120,
    headerName: 'Start Date',
    component: (row: Organization) =>
      row.startDate ? moment(row.startDate).format(NO_TIME_DATE_FORMAT) : EMPTY,
  },
  {
    width: 120,
    headerName: 'End Date',
    component: (row: Organization) =>
      row.endDate ? moment(row.endDate).format(NO_TIME_DATE_FORMAT) : EMPTY,
  },
  { headerName: 'Medicare', field: 'medicareDiscovery' },
  {
    headerName: 'Update At',
    width: 200,
    component: ({ updatedAt, updatedBy }: OrganizationExtended) =>
      updatedAt && <UpdatedBy date={updatedAt} user={updatedBy?.fullName} />,
  },
  {
    headerName: '',
    width: 60,
    component: (row: OrganizationExtended) => (
      <>
        <EditIcon
          onClick={(evt) => {
            evt?.stopPropagation();
            onEdit(row);
          }}
          tag="button"
        />
        {row.hasChildren && (
          <>
            <span style={{ marginRight: 5 }} />
            <CollapseIcon
              onClick={() => toggleDetail(row)}
              tag="button"
              active={row.collapsed}
            />
          </>
        )}
      </>
    ),
  },
];

const useStyles = makeStyles({
  table: {
    '&& tbody': {
      '& td': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
      '& a': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
    },
  },
});

const ReferralTable = (props: Props) => {
  const styles = useStyles();
  const { referrals, originals, onEdit } = props;
  const [sortedReferrals, setSortedReferrals] = useState(referrals);
  const [brandList, setBrandList] = useState<Brand[]>([]);

  const getInitialData = async () => {
    const brands = await CacheServices.getOrganizationBrands();
    setBrandList(brands);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    setSortedReferrals([...referrals]);
  }, [referrals]);

  const sortDataByName = (direction?: string) => {
    setSortedReferrals(
      [...referrals].sort(
        (a: Organization, b: Organization) =>
          a.name.localeCompare(b.name) * (direction === 'desc' ? -1 : 1),
      ),
    );
  };

  const toggleDetail = (referral: Organization) => {
    const index = sortedReferrals.findIndex(({ id }) => id === referral.id);
    const item = sortedReferrals[index] as OrganizationExtended;
    const children: Organization[] = [];

    if (item.children) {
      item.children.forEach((ref) => {
        const objToPush = originals?.find((y) => y.id === ref.id);
        if (objToPush) children.push(objToPush);
      });
    }

    item.collapsed = !item.collapsed;
    if (item.collapsed) {
      item.detail = (
        <ReferralTable
          originals={originals}
          referrals={sortBy(children, 'name')}
          onEdit={onEdit}
        />
      );
    } else {
      delete item.detail;
    }
    setSortedReferrals([...sortedReferrals]);
  };

  const columns = useMemo(() => COLUMNS(onEdit, toggleDetail, brandList), [
    brandList,
    onEdit,
    toggleDetail,
  ]);

  return referrals?.length ? (
    <Table
      className={styles.table}
      config={{
        columns,
        compact: true,
        data: sortedReferrals,
        detail: true,
        paginationOptions: [-1],
      }}
      data-cy="referral-table"
      onSort={(column) => sortDataByName(column.direction)}
    />
  ) : (
    <EmptyState show={!referrals}>
      {translate('referrals.noRecords')}
    </EmptyState>
  );
};

export default ReferralTable;
