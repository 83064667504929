import { useDebouncedFn } from 'beautiful-react-hooks';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { MemberStatus, MessageUserType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { CareTeamPerson, Patient } from '@vestahealthcare/common/models';

import {
  Button,
  Checkbox,
  EmptyState,
  NavItem,
  NavTabs,
  Panel,
  Table,
  TextInput,
} from 'styleguide';

import { fetchCareTeamPeople } from 'dash/src/services/CareTeamServices';
import { searchPatients } from 'dash/src/services/PatientServices';

import SMSModal from './SMSModal';

type Recipient = Patient | CareTeamPerson;
interface Filter {
  name?: string;
}

const uniq = (array: any[]) => [...new Set(array)];

const MESSAGE_USER_TYPE = [MessageUserType.MEMBER, MessageUserType.CARE_TEAM];

export const Messaging = () => {
  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [recipientType, setRecipientType] = useState(MessageUserType.CARE_TEAM);
  const [loading, setLoading] = useState(true);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [idText, setIdText] = useState<string>();
  const [isChatMessage, setIsChatMessage] = useState(true);
  const [filter, setFilter] = useState<Filter>({});
  const [offset, setOffset] = useState(0);
  const [limit] = useState(10);
  const [total, setTotal] = useState(0);

  const getPatients = useDebouncedFn(
    (offset: number, limit: number, name?: string) => {
      return searchPatients({
        queryString: name,
        offset,
        limit,
        sort: 'firstName asc',
        status: [MemberStatus.ACTIVE],
      }).then(({ items, pagination: { total } }) => {
        setTotal(total);
        setRecipients(items);
        setLoading(false);
      });
    },
    500,
  );

  const getCareTeamPersons = useDebouncedFn(
    (offset: number, limit: number, name?: string) => {
      return fetchCareTeamPeople({
        fullName: name,
        offset,
        limit,
        sort: 'updatedAt desc',
      }).then(({ items, pagination: { total } }) => {
        setTotal(total);
        setRecipients(items);
        setLoading(false);
      });
    },
    500,
  );

  useEffect(() => {
    setLoading(true);
    if (recipientType === MessageUserType.MEMBER) {
      getPatients(offset, limit, filter.name);
    } else if (recipientType === MessageUserType.CARE_TEAM) {
      getCareTeamPersons(offset, limit, filter.name);
    }
  }, [recipientType, offset, limit, filter]);

  useEffect(() => {
    setIDsAndText([]);
  }, [recipientType]);

  useEffect(() => {
    parseTextIDs(idText || '');
  }, [idText]);

  const setIDsAndText = (ids: number[]) => {
    setSelectedIds(ids);
    setIdText(ids.join(' '));
  };

  const parseTextIDs = (idString: string) => {
    const ids = idString
      .split(/[^0-9]/)
      .map((id) => parseInt(id, 10))
      .filter((id) => !!id);
    setIDsAndText(ids);
  };

  const getTextReminderOptOut = (recipient: Recipient) => {
    let text = 'no';
    if (recipient instanceof CareTeamPerson) {
      text = recipient.phones?.find(({ primary }) => primary)?.phone
        .textReminderOptOut
        ? 'yes'
        : 'no';
    } else {
      text = recipient.primaryPhone?.phone.textReminderOptOut ? 'yes' : 'no';
    }

    return translate(`global.${text}`);
  };

  const getSearchLabel = () => {
    let text = 'Member';

    if (recipientType === MessageUserType.CAREGIVER) text = 'Caregiver';
    if (recipientType === MessageUserType.CARE_TEAM) text = 'CareTeamPerson';

    return translate(`messaging.search${text}`);
  };

  return (
    <div id="page-wrapper">
      <div id="page">
        <Panel>
          <Panel.Heading title={translate('messaging.contactList')}>
            <div className="right-side">
              <NavTabs>
                {MESSAGE_USER_TYPE.map((val) => (
                  <NavItem
                    active={val === recipientType}
                    key={val.valueOf()}
                    onClick={() => {
                      setRecipientType(val);
                      setIDsAndText([]);
                      setOffset(0);
                    }}
                  >
                    {val.toString()}
                  </NavItem>
                ))}
              </NavTabs>
            </div>
          </Panel.Heading>
          <Panel.Body loading={loading}>
            <div className="grid-wrapper">
              <div className="grid-span-12">
                <TextInput
                  className="pad-bottom-30"
                  label="Paste IDs"
                  multiline={3}
                  onBlur={(text) => {
                    if (idText !== text) {
                      parseTextIDs(text || '');
                    }
                  }}
                  value={idText}
                />
                <Button
                  color="secondary"
                  className="pull-right"
                  disabled={selectedIds.length < 1}
                  onClick={() => setShowMessageModal(true)}
                >
                  {translate(
                    isChatMessage
                      ? 'messaging.sendBulkChat'
                      : 'messaging.sendBulkSms',
                  )}
                </Button>
                <Checkbox
                  checked={isChatMessage}
                  className="slide-toggle"
                  icon={isChatMessage ? 'toggle-on' : 'toggle-off'}
                  label={translate(
                    isChatMessage
                      ? 'messaging.sendAsChatMessage'
                      : 'messaging.sendAsSMSMessage',
                  )}
                  onChange={setIsChatMessage}
                />
              </div>

              <div className="grid-span-12 grid-wrapper">
                <TextInput
                  data-cy="filter-name"
                  label={getSearchLabel()}
                  columns={3}
                  placeholder={translate('global.select')}
                  onChange={(name?: string) => {
                    setFilter({ ...filter, name });
                    setOffset(0);
                  }}
                  value={filter.name}
                />
              </div>

              <div className="grid-span-12 grid-wrapper">
                <h6 className="flex grid-span-4">
                  {translate('messaging.selectedItems', {
                    count: selectedIds.length,
                  })}
                </h6>
                <div className="flex grid-span-8 text-right">
                  <Button
                    className="grid-span-4"
                    color="secondary"
                    isPill
                    disabled={!selectedIds.length}
                    onClick={() => {
                      setIDsAndText([]);
                    }}
                    style={{ marginLeft: 5 }}
                  >
                    {translate('global.clearSelection')}
                  </Button>
                </div>
              </div>

              <Table
                className="grid-span-12"
                style={{ tableLayout: 'inherit' }}
                data-cy="recipients-table"
                numPages={Math.ceil(total / limit)}
                currentPage={offset / limit + 1}
                onNext={() => setOffset(offset + limit)}
                onPrev={() => setOffset(Math.max(0, offset - limit))}
              >
                <thead>
                  <tr>
                    <th style={{ width: 60 }}>
                      <Checkbox
                        checked={
                          !!(
                            recipients?.length &&
                            recipients?.every((recipient) =>
                              selectedIds.includes(recipient.id),
                            )
                          )
                        }
                        disabled={!recipients?.length}
                        onChange={(checked) => {
                          const ids =
                            recipients?.map((recipient) => recipient.id) || [];
                          if (!ids.length) return;

                          setIDsAndText(
                            checked
                              ? uniq([...selectedIds, ...ids])
                              : selectedIds.filter((id) => !ids.includes(id)),
                          );
                        }}
                      />
                    </th>
                    <th style={{ width: 160 }}>ID</th>
                    <th>{translate('global.name')}</th>
                    {!isChatMessage && (
                      <th style={{ width: 160 }}>SMS Opted Out</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {!recipients?.length && !loading ? (
                    <tr>
                      <td colSpan={9}>
                        <EmptyState show>
                          {translate('global.emptyResults')}
                        </EmptyState>
                      </td>
                    </tr>
                  ) : null}
                  {recipients?.map((recipient) => (
                    <tr key={recipient.id}>
                      <td>
                        <Checkbox
                          checked={selectedIds.includes(recipient.id)}
                          onChange={(checked) => {
                            setIDsAndText(
                              checked
                                ? uniq([...selectedIds, recipient.id])
                                : selectedIds.filter(
                                    (id) => recipient.id !== id,
                                  ),
                            );
                          }}
                        />
                      </td>
                      <td>
                        {recipientType === MessageUserType.MEMBER ? (
                          <Link
                            to={`/patients/${recipient.id}`}
                            data-cy="results-member-id"
                          >
                            {recipient.id}
                          </Link>
                        ) : (
                          recipient.id
                        )}
                      </td>
                      <td data-cy="results-member-name">
                        {recipient.fullName}
                      </td>
                      {!isChatMessage && (
                        <td data-cy="results-member-name">
                          {getTextReminderOptOut(recipient)}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              {showMessageModal && (
                <SMSModal
                  ids={selectedIds}
                  isChatMessage={isChatMessage}
                  recipientType={recipientType}
                  onHide={() => setShowMessageModal(false)}
                />
              )}
            </div>
          </Panel.Body>
        </Panel>
      </div>
    </div>
  );
};

export default Messaging;
