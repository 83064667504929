import React, { useState, useEffect } from 'react';
import Form, {
  FormValidation,
  IChangeEvent,
  UiSchema,
  AjvError,
} from 'react-jsonschema-form';
import { isPlainObject } from 'lodash';
import { JSONSchema6 } from 'json-schema';
import classNames from 'classnames';
import widgets from './widgets';
import fields from './fields';
import templates from './templates';
import { Button } from 'styleguide';
import { translate } from '@vestahealthcare/common/i18n';
import './main.less';

interface Props {
  className?: string;
  formContext?: any;
  formData: { [key: string]: any };
  html5Validate?: boolean;
  isSubmitting?: boolean;
  liveValidate?: boolean;
  omitExtraData?: boolean;
  onCancel: Function;
  onChange?: Function;
  onSubmit: Function;
  readonly?: boolean;
  readonlyClose?: boolean;
  schema: JSONSchema6;
  transformErrors?: (errors: AjvError[]) => AjvError[];
  uiSchema: UiSchema;
  validate?: boolean;
  validation?: (formData: any, errors: FormValidation) => FormValidation;
}

const translateUISchema = (input: UiSchema) => {
  const clone: UiSchema = { ...input };
  Object.keys(clone).forEach((key) => {
    if (
      [
        'ui:FieldTemplate',
        'ui:ArrayFieldTemplate',
        'ui:ObjectFieldTemplate',
      ].includes(key)
    ) {
      if (typeof clone[key] === 'string') {
        // @ts-ignore. We check type above....
        clone[key] = templates[clone[key]];
      }
      return;
    }

    if (isPlainObject(clone[key])) {
      clone[key] = translateUISchema(clone[key]);
    }
  });

  return clone;
};

const JsonSchemaForm = (props: Props) => {
  const {
    className,
    formContext,
    formData,
    html5Validate = false,
    isSubmitting = false,
    liveValidate = false,
    omitExtraData = true,
    onCancel,
    onChange,
    onSubmit,
    readonly = true,
    readonlyClose,
    schema,
    transformErrors,
    uiSchema,
    validate = false,
    validation,
  } = props;
  const [data, setData] = useState(formData);
  useEffect(() => setData(formData), [formData]);

  const classes = classNames('json-schema-form', { readonly }, className);

  const onChangeValue = (formData: any) => {
    setData(formData);
    onChange && onChange(formData);
  };

  const transformErrorsFn = (errors: AjvError[]) => {
    transformErrors && transformErrors(errors);
    return errors;
  };

  // react-jsonschema-form doesn't handle templates like it does
  // with widgets or fields (replacing reference name by the component)
  // So we do it manually.
  // related issue: https://github.com/rjsf-team/react-jsonschema-form/issues/1471
  const translatedUISchema: UiSchema = translateUISchema(uiSchema);

  return (
    // @ts-ignore - this is really lame but their type definition doesn't include omitExtraData yet
    <Form
      className={classes}
      schema={schema}
      uiSchema={translatedUISchema}
      formData={data}
      onChange={({ formData }: IChangeEvent) => onChangeValue(formData)}
      onSubmit={({ formData }: IChangeEvent) =>
        !isSubmitting && onSubmit(formData)
      }
      // @ts-ignore - Widgets use a custom JSONSchema type
      widgets={widgets}
      // @ts-ignore - Fields use a custom JSONSchema type
      fields={fields}
      FieldTemplate={templates.FieldTemplate}
      // @ts-ignore - ArrayFieldTemplate uses a custom JSONSchema type
      ArrayFieldTemplate={templates.ArrayFieldTemplate}
      ObjectFieldTemplate={templates.ObjectFieldTemplate}
      formContext={{
        ...formContext,
        readonly,
        formData: data,
        setFormData: onChangeValue,
      }}
      noHtml5Validate={!html5Validate}
      liveValidate={liveValidate}
      noValidate={!validate}
      showErrorList={false}
      transformErrors={(errors: AjvError[]) => transformErrorsFn(errors)}
      validate={validation}
      omitExtraData={omitExtraData}
    >
      {/* The Form component will render a Submit button if there's no children, for the readonly view we don't
          want to show that button so we pass true (valid react element) so it doesn't render anything. */}
      {(readonly && !readonlyClose) || (
        <div className="right-side submit-container">
          {readonly ? (
            <Button
              className="margin-right-lg"
              color="primary"
              onClick={() => onCancel()}
            >
              {translate('global.close')}
            </Button>
          ) : (
            <>
              <Button
                className="margin-right-lg"
                color="secondary"
                disabled={isSubmitting}
                onClick={() => onCancel()}
              >
                {translate('global.cancel')}
              </Button>
              <Button
                data-cy="save-button"
                color="primary"
                loading={isSubmitting}
                type="submit"
              >
                {translate('global.save')}
              </Button>
            </>
          )}
        </div>
      )}
    </Form>
  );
};

export default JsonSchemaForm;
