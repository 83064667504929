import development from './development';
import production from './production';
import staging from './staging';
import stage from './stage';
import qa from './qa';
import demo from './demo';

const getConfig = () => {
  let backend = window.location.hostname.split('.')[1] || '';

  // check if it matches any QA envs "qa1, qa2, etc" and condense it to just 'qa'
  // and capture QA env #
  let qaEnv = '1';
  if (backend.startsWith('qa')) {
    qaEnv = backend.charAt(2);
    backend = 'qa';
  }

  switch (backend) {
    case 'hometeamcare':
      return production;
    case 'staging':
      return staging;
    case 'stage':
      return stage;
    case 'qa':
      return qa(qaEnv);
    case 'demo':
      return demo;
    default:
      return development;
  }
};

export default getConfig();
