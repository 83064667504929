import classNames from 'classnames';
import React from 'react';

import {
  MemberProgramExtension,
  MemberReferral,
  Organization,
} from '@vestahealthcare/common/models';

import { MemberProgramExtensions } from '../MemberProgramExtensions';
import { Tooltip } from '../Tooltip';

type Props = {
  activeReferrals?: MemberReferral[];
  className?: string;
  hideTooltips?: boolean;
  memberId?: number;
  programExtensions?: MemberProgramExtension[];
};

export const MemberExtraInfo = ({
  activeReferrals,
  className,
  hideTooltips,
  memberId,
  programExtensions,
}: Props) => {
  const getReferral = (org: Organization, index: number) => (
    <span key={`member-${memberId}-referral-${org.id}`}>
      {org.abbr?.toLocaleUpperCase()}
      {index !== (activeReferrals?.length || 0) - 1 && ','}
    </span>
  );
  return (
    <>
      <div className={classNames('flex wrap', className)}>
        {activeReferrals?.map(({ organization }, index) =>
          hideTooltips ? (
            getReferral(organization, index)
          ) : (
            <Tooltip
              key={`member-${memberId}-referral-${organization.id}`}
              text={organization.name}
            >
              {getReferral(organization, index)}
            </Tooltip>
          ),
        ) || ''}
      </div>
      <MemberProgramExtensions
        className={className}
        hideTooltips
        memberId={memberId}
        programExtensions={programExtensions}
      />
    </>
  );
};

export default MemberExtraInfo;
