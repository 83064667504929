import { Enum } from './Enum';

export class EncounterDirection extends Enum {
  translationKey = 'encounterDirection';

  static asArray: EncounterDirection[] = [];

  static byKey: { [name: string]: EncounterDirection } = {};

  static readonly INBOUND = new EncounterDirection('INBOUND');

  static readonly OUTBOUND = new EncounterDirection('OUTBOUND');

  static toSelectable() {
    return Enum.toSelectable(EncounterDirection.asArray);
  }

  constructor(
    public readonly value: string,
    public readonly selectable: boolean = true,
  ) {
    super();
    EncounterDirection.asArray.push(this);
    EncounterDirection.byKey[value] = this;
  }
}

export default EncounterDirection;
