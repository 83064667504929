import { BaseEnum } from './BaseEnum';

export class IssueDetailFieldOption extends BaseEnum {
  position = 0;

  active = false;

  followUpFieldId = '';

  constructor(obj: any) {
    super(obj);
    Object.assign(this, obj);
  }
}

export default IssueDetailFieldOption;
