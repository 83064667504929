import { Enum } from './Enum';
import { BodyPartSide } from './BodyPartSide';

export class BodyPart extends Enum {
  translationKey = 'bodyPart';

  static asArray: BodyPart[] = [];

  static byKey: { [name: string]: BodyPart } = {};

  static readonly HEAD_FRONT = new BodyPart('HEAD_FRONT', BodyPartSide.FRONT);

  static readonly HEAD_BACK = new BodyPart('HEAD_BACK', BodyPartSide.BACK);

  static readonly FACE = new BodyPart('FACE', BodyPartSide.FRONT);

  static readonly NECK_FRONT = new BodyPart('NECK_FRONT', BodyPartSide.FRONT);

  static readonly NECK_BACK = new BodyPart('NECK_BACK', BodyPartSide.BACK);

  static readonly CHEST = new BodyPart('CHEST', BodyPartSide.FRONT);

  static readonly CENTRAL_CHEST = new BodyPart(
    'CENTRAL_CHEST',
    BodyPartSide.FRONT,
  );

  static readonly L_SHOULDER_FRONT = new BodyPart(
    'L_SHOULDER_FRONT',
    BodyPartSide.FRONT,
  );

  static readonly R_SHOULDER_FRONT = new BodyPart(
    'R_SHOULDER_FRONT',
    BodyPartSide.FRONT,
  );

  static readonly L_BREAST = new BodyPart('L_BREAST', BodyPartSide.FRONT);

  static readonly R_BREAST = new BodyPart('R_BREAST', BodyPartSide.FRONT);

  static readonly L_UPPER_ARM_FRONT = new BodyPart(
    'L_UPPER_ARM_FRONT',
    BodyPartSide.FRONT,
  );

  static readonly L_UPPER_ARM_BACK = new BodyPart(
    'L_UPPER_ARM_BACK',
    BodyPartSide.BACK,
  );

  static readonly L_FOREARM_FRONT = new BodyPart(
    'L_FOREARM_FRONT',
    BodyPartSide.FRONT,
  );

  static readonly L_FOREARM_BACK = new BodyPart(
    'L_FOREARM_BACK',
    BodyPartSide.BACK,
  );

  static readonly R_UPPER_ARM_FRONT = new BodyPart(
    'R_UPPER_ARM_FRONT',
    BodyPartSide.FRONT,
  );

  static readonly R_UPPER_ARM_BACK = new BodyPart(
    'R_UPPER_ARM_BACK',
    BodyPartSide.BACK,
  );

  static readonly R_FOREARM_FRONT = new BodyPart(
    'R_FOREARM_FRONT',
    BodyPartSide.FRONT,
  );

  static readonly R_FOREARM_BACK = new BodyPart(
    'R_FOREARM_BACK',
    BodyPartSide.BACK,
  );

  static readonly L_HAND_FRONT = new BodyPart(
    'L_HAND_FRONT',
    BodyPartSide.FRONT,
  );

  static readonly L_HAND_BACK = new BodyPart('L_HAND_BACK', BodyPartSide.BACK);

  static readonly L_FINGERS_FRONT = new BodyPart(
    'L_FINGERS_FRONT',
    BodyPartSide.FRONT,
  );

  static readonly L_FINGERS_BACK = new BodyPart(
    'L_FINGERS_BACK',
    BodyPartSide.BACK,
  );

  static readonly L_WRIST_FRONT = new BodyPart(
    'L_WRIST_FRONT',
    BodyPartSide.FRONT,
  );

  static readonly L_WRIST_BACK = new BodyPart(
    'L_WRIST_BACK',
    BodyPartSide.BACK,
  );

  static readonly R_HAND_FRONT = new BodyPart(
    'R_HAND_FRONT',
    BodyPartSide.FRONT,
  );

  static readonly R_HAND_BACK = new BodyPart('R_HAND_BACK', BodyPartSide.BACK);

  static readonly R_FINGERS_FRONT = new BodyPart(
    'R_FINGERS_FRONT',
    BodyPartSide.FRONT,
  );

  static readonly R_FINGERS_BACK = new BodyPart(
    'R_FINGERS_BACK',
    BodyPartSide.BACK,
  );

  static readonly R_WRIST_FRONT = new BodyPart(
    'R_WRIST_FRONT',
    BodyPartSide.FRONT,
  );

  static readonly R_WRIST_BACK = new BodyPart(
    'R_WRIST_BACK',
    BodyPartSide.BACK,
  );

  static readonly ABDOMEN = new BodyPart('ABDOMEN', BodyPartSide.FRONT);

  static readonly HIPS = new BodyPart('HIPS', BodyPartSide.FRONT);

  static readonly GROIN = new BodyPart('GROIN', BodyPartSide.FRONT);

  static readonly L_UPPER_LEG_FRONT = new BodyPart(
    'L_UPPER_LEG_FRONT',
    BodyPartSide.FRONT,
  );

  static readonly L_UPPER_LEG_BACK = new BodyPart(
    'L_UPPER_LEG_BACK',
    BodyPartSide.BACK,
  );

  static readonly L_KNEE = new BodyPart('L_KNEE', BodyPartSide.FRONT);

  static readonly L_LOWER_LEG_FRONT = new BodyPart(
    'L_LOWER_LEG_FRONT',
    BodyPartSide.FRONT,
  );

  static readonly L_LOWER_LEG_BACK = new BodyPart(
    'L_LOWER_LEG_BACK',
    BodyPartSide.BACK,
  );

  static readonly R_UPPER_LEG_FRONT = new BodyPart(
    'R_UPPER_LEG_FRONT',
    BodyPartSide.FRONT,
  );

  static readonly R_UPPER_LEG_BACK = new BodyPart(
    'R_UPPER_LEG_BACK',
    BodyPartSide.BACK,
  );

  static readonly R_KNEE = new BodyPart('R_KNEE', BodyPartSide.FRONT);

  static readonly R_LOWER_LEG_FRONT = new BodyPart(
    'R_LOWER_LEG_FRONT',
    BodyPartSide.FRONT,
  );

  static readonly R_LOWER_LEG_BACK = new BodyPart(
    'R_LOWER_LEG_BACK',
    BodyPartSide.BACK,
  );

  static readonly L_FOOT = new BodyPart('L_FOOT', BodyPartSide.FRONT);

  static readonly L_ANKLE_BACK = new BodyPart(
    'L_ANKLE_BACK',
    BodyPartSide.BACK,
  );

  static readonly L_ANKLE_FRONT = new BodyPart(
    'L_ANKLE_FRONT',
    BodyPartSide.FRONT,
  );

  static readonly L_TOES = new BodyPart('L_TOES', BodyPartSide.FRONT);

  static readonly R_FOOT = new BodyPart('R_FOOT', BodyPartSide.FRONT);

  static readonly R_ANKLE_BACK = new BodyPart(
    'R_ANKLE_BACK',
    BodyPartSide.BACK,
  );

  static readonly R_ANKLE_FRONT = new BodyPart(
    'R_ANKLE_FRONT',
    BodyPartSide.FRONT,
  );

  static readonly R_TOES = new BodyPart('R_TOES', BodyPartSide.FRONT);

  static readonly BACK = new BodyPart('BACK', BodyPartSide.BACK);

  static readonly UPPER_BACK = new BodyPart('UPPER_BACK', BodyPartSide.BACK);

  static readonly LOWER_BACK = new BodyPart('LOWER_BACK', BodyPartSide.BACK);

  static readonly L_SHOULDER_BACK = new BodyPart(
    'L_SHOULDER_BACK',
    BodyPartSide.BACK,
  );

  static readonly R_SHOULDER_BACK = new BodyPart(
    'R_SHOULDER_BACK',
    BodyPartSide.BACK,
  );

  static readonly L_ELBOW = new BodyPart('L_ELBOW', BodyPartSide.BACK);

  static readonly R_ELBOW = new BodyPart('R_ELBOW', BodyPartSide.BACK);

  static readonly BUTTOCKS = new BodyPart('BUTTOCKS', BodyPartSide.BACK);

  static readonly L_BUTTOCK = new BodyPart('L_BUTTOCK', BodyPartSide.BACK);

  static readonly R_BUTTOCK = new BodyPart('R_BUTTOCK', BodyPartSide.BACK);

  static readonly SACRUM = new BodyPart('SACRUM', BodyPartSide.BACK);

  static readonly L_HEEL = new BodyPart('L_HEEL', BodyPartSide.BACK);

  static readonly R_HEEL = new BodyPart('R_HEEL', BodyPartSide.BACK);

  constructor(
    public readonly value: string,
    public readonly side: BodyPartSide,
  ) {
    super();
    BodyPart.asArray.push(this);
    BodyPart.byKey[value] = this;
  }
}

export default BodyPart;
