import { Enum } from './Enum';

export class VirtualVisitStatus extends Enum {
  translationKey = 'virtualVisitStatus';

  static asArray: VirtualVisitStatus[] = [];

  static byKey: { [name: string]: VirtualVisitStatus } = {};

  static readonly SCHEDULED = new VirtualVisitStatus('SCHEDULED');

  static readonly NO_SHOW = new VirtualVisitStatus('NO_SHOW');

  static readonly RESCHEDULED = new VirtualVisitStatus('RESCHEDULED');

  static readonly CANCELLED = new VirtualVisitStatus('CANCELLED');

  static readonly COMPLETED = new VirtualVisitStatus('COMPLETED');

  static toSelectable() {
    return Enum.toSelectable(VirtualVisitStatus.asArray);
  }

  constructor(public readonly value: string) {
    super();
    VirtualVisitStatus.asArray.push(this);
    VirtualVisitStatus.byKey[value] = this;
  }
}

export default VirtualVisitStatus;
