import { Enum } from './Enum';

export class RPMVendorDeviceType extends Enum {
  translationKey = 'RPMVendorDeviceType';

  static asArray: RPMVendorDeviceType[] = [];

  static byKey: { [name: string]: RPMVendorDeviceType } = {};

  static readonly BLOOD_PRESSURE = new RPMVendorDeviceType('BLOOD_PRESSURE');

  static readonly GLUCOMETER = new RPMVendorDeviceType('GLUCOMETER');

  static readonly PULSE_OX = new RPMVendorDeviceType('PULSE_OX');

  static readonly SCALE = new RPMVendorDeviceType('SCALE');

  constructor(public readonly value: string, public readonly filter?: boolean) {
    super();
    RPMVendorDeviceType.asArray.push(this);
    RPMVendorDeviceType.byKey[value] = this;
  }

  static getIcon(type: RPMVendorDeviceType) {
    if (type === RPMVendorDeviceType.BLOOD_PRESSURE) return 'vesta-icon pulse';
    if (type === RPMVendorDeviceType.GLUCOMETER) return 'vesta-icon glucometer';
    if (type === RPMVendorDeviceType.PULSE_OX)
      return 'vesta-icon pulse-oximeter';
    if (type === RPMVendorDeviceType.SCALE) return 'vesta-icon scale';
    return '';
  }
}

export default RPMVendorDeviceType;
