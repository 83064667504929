import BradenScaleHeaderField from './BradenScaleHeaderField';
import BradenScaleRowField from './BradenScaleRowField';
import ClinicalFigureSummaryField from './ClinicalFigureSummaryField';
import CommentField from './CommentField';
import OtherPhoneField from './OtherPhoneField';
import PrimaryPhoneField from './PrimaryPhoneField';
import ScoreField from './ScoreField';
import TextOtherField from './TextOtherField';
import TitleOnlyField from './TitleOnlyField';

export default {
  BradenScaleHeaderField,
  BradenScaleRowField,
  ClinicalFigureSummaryField,
  CommentField,
  OtherPhoneField,
  PrimaryPhoneField,
  ScoreField,
  TextOtherField,
  TitleOnlyField,
};
