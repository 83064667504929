import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { translate } from '@vestahealthcare/common/i18n';

const MoreInfo = ({ description }: { description: string }) => (
  <OverlayTrigger
    trigger="focus"
    placement="top"
    overlay={
      <Popover id={description}>
        <p>{description}</p>
      </Popover>
    }
  >
    <a tabIndex={0} className="small more-info">
      <i className="fa fa-info-circle circle" />
      {translate('common.moreInfo')}
    </a>
  </OverlayTrigger>
);

export default MoreInfo;
