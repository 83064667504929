import React from 'react';
import ReactDOM from 'react-dom';
import { translate } from '@vestahealthcare/common/i18n';
import { Modal, Button } from 'styleguide';

// Since this is rendered outside the app hierarchy, we have to
// manually select the root node
export const confirmNavigation = (message: string, callback: Function) => {
  // Since we're manually mounting, we have to manually unmount as well,
  // so we have to create a node specifically for this.
  const modalRoot = document.getElementById('navigation-prompt');

  if (!modalRoot) {
    console.warn('Could not find navigation prompt node.');
    return;
  }

  const onHide = () => {
    ReactDOM.unmountComponentAtNode(modalRoot);
    callback(false);
  };

  const onSubmit = () => {
    ReactDOM.unmountComponentAtNode(modalRoot);
    callback(true);
  };

  ReactDOM.render(
    <NavConfirmModal
      onHide={onHide}
      onSubmit={onSubmit}
      message={message}
      show
    />,
    modalRoot,
  );
};

interface Props {
  message?: string;
  onHide: () => void;
  onSubmit: () => void;
  show: boolean;
}

const NavConfirmModal = (props: Props) => {
  const { message, onHide, onSubmit, show } = props;
  return (
    <Modal show={show} onHide={onHide} color="warning">
      <Modal.Header
        modalTitle={translate('global.navPromptTitle')}
        closeButton
      />
      <Modal.Body>
        <p>{message || translate('global.navPromptBody')}</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="left-side">
          <Button color="tertiary" onClick={onHide}>
            {translate('global.cancel')}
          </Button>
        </div>
        <div className="right-side">
          <Button color="primary" onClick={onSubmit}>
            {translate('global.leaveWithoutSaving')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default confirmNavigation;
