import { BaseEnum } from './BaseEnum';
import { make } from './Model';

export class CareTeamMemberSpeciality {
  specialityType: BaseEnum;

  otherSpeciality = '';

  constructor(obj: any) {
    Object.assign(this, obj);
    this.specialityType = make(obj.specialityType, BaseEnum);
  }
}

export default CareTeamMemberSpeciality;
