import React, { useState } from 'react';

import { makeStyles } from '@material-ui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import { Button, Icon, Link, Modal, Text } from 'styleguide';

const useStyles = makeStyles({
  listItem: {
    listStyleType: 'circle',
  },
  subItem: {
    marginLeft: 25,
  },
  subItemList: {
    listStyleType: 'square',
  },
});

export const EscalationCriteriaModal = () => {
  const [showModal, setShowModal] = useState(false);
  const styles = useStyles();
  return (
    <>
      <Link onClick={() => setShowModal(true)}>
        <Icon className="fa fa-info" />{' '}
        {translate('events.escalationCriteria.title')}
      </Link>
      {showModal && (
        <Modal onHide={() => setShowModal(false)} show modalSize="lg">
          <Modal.Header
            closeButton
            modalTitle={translate('events.escalationCriteria.title')}
          />
          <Modal.Body>
            <ul>
              <li className={styles.listItem}>
                <Text bold>
                  {translate('events.escalationCriteria.firstCriteria')}
                </Text>
              </li>
              <li className={styles.listItem}>
                <Text>
                  {translate('events.escalationCriteria.secondCriteria')}
                </Text>
              </li>
              <li className={styles.listItem}>
                <Text style={{ textDecoration: 'underline' }}>
                  {translate('events.escalationCriteria.thirdCriteria.title')}
                </Text>
                <ul className={styles.subItem}>
                  <li className={styles.subItemList}>
                    <Text>
                      {translate(
                        'events.escalationCriteria.thirdCriteria.firstItem',
                      )}
                    </Text>
                  </li>
                  <li className={styles.subItemList}>
                    <Text>
                      {translate(
                        'events.escalationCriteria.thirdCriteria.secondItem',
                      )}
                    </Text>
                  </li>
                  <li className={styles.subItemList}>
                    <Text>
                      {translate(
                        'events.escalationCriteria.thirdCriteria.thirdItem',
                      )}
                    </Text>
                  </li>
                </ul>
              </li>
              <li className={styles.listItem}>
                <Text style={{ textDecoration: 'underline' }}>
                  {translate('events.escalationCriteria.forthCriteria.title')}
                </Text>
                <ul className={styles.subItem}>
                  <li className={styles.subItemList}>
                    <Text>
                      {translate(
                        'events.escalationCriteria.forthCriteria.firstItem',
                      )}
                    </Text>
                  </li>
                  <li className={styles.subItemList}>
                    <Text>
                      {translate(
                        'events.escalationCriteria.forthCriteria.secondItem',
                      )}
                    </Text>
                  </li>
                  <li className={styles.subItemList}>
                    <Text>
                      {translate(
                        'events.escalationCriteria.forthCriteria.thirdItem',
                      )}
                    </Text>
                  </li>
                </ul>
              </li>
              <li className={styles.listItem}>
                <Text style={{ textDecoration: 'underline' }}>
                  {translate('events.escalationCriteria.fifthCriteria.title')}
                </Text>
                <ul className={styles.subItem}>
                  <li className={styles.subItemList}>
                    <Text>
                      {translate(
                        'events.escalationCriteria.fifthCriteria.firstItem',
                      )}
                    </Text>
                  </li>
                  <li className={styles.subItemList}>
                    <Text>
                      {translate(
                        'events.escalationCriteria.fifthCriteria.secondItem',
                      )}
                    </Text>
                  </li>
                </ul>
              </li>
              <li className={styles.listItem}>
                <Text style={{ textDecoration: 'underline' }}>
                  {translate('events.escalationCriteria.sixthCriteria.title')}
                </Text>
                <ul className={styles.subItem}>
                  <li className={styles.subItemList}>
                    <Text>
                      {translate(
                        'events.escalationCriteria.sixthCriteria.firstItem',
                      )}
                    </Text>
                  </li>
                </ul>
              </li>
              <li className={styles.listItem}>
                <Text style={{ textDecoration: 'underline' }}>
                  {translate('events.escalationCriteria.seventhCriteria.title')}
                </Text>
                <ul className={styles.subItem}>
                  <li className={styles.subItemList}>
                    <Text>
                      {translate(
                        'events.escalationCriteria.seventhCriteria.firstItem',
                      )}
                    </Text>
                  </li>
                  <li className={styles.subItemList}>
                    <Text>
                      {translate(
                        'events.escalationCriteria.seventhCriteria.secondItem',
                      )}
                    </Text>
                  </li>
                </ul>
              </li>
              <li className={styles.listItem}>
                <Text style={{ textDecoration: 'underline' }}>
                  {translate('events.escalationCriteria.eightCriteria.title')}
                </Text>
                <ul className={styles.subItem}>
                  <li className={styles.subItemList}>
                    <Text>
                      {translate(
                        'events.escalationCriteria.eightCriteria.firstItem',
                      )}
                    </Text>
                  </li>
                  <li className={styles.subItemList}>
                    <Text>
                      {translate(
                        'events.escalationCriteria.eightCriteria.secondItem',
                      )}
                    </Text>
                  </li>
                </ul>
              </li>
              <li className={styles.listItem}>
                <Text>
                  {translate('events.escalationCriteria.ninethCriteria.title')}
                </Text>
                <ul className={styles.subItem}>
                  <li className={styles.subItemList}>
                    <Text>
                      {translate(
                        'events.escalationCriteria.ninethCriteria.firstItem',
                      )}
                    </Text>
                  </li>
                  <li className={styles.subItemList}>
                    <Text>
                      {translate(
                        'events.escalationCriteria.ninethCriteria.secondItem',
                      )}
                    </Text>
                  </li>
                  <li className={styles.subItemList}>
                    <Text>
                      {translate(
                        'events.escalationCriteria.ninethCriteria.thirdItem',
                      )}
                    </Text>
                  </li>
                  <li className={styles.subItemList}>
                    <Text>
                      {translate(
                        'events.escalationCriteria.ninethCriteria.forthItem',
                      )}
                    </Text>
                  </li>
                </ul>
              </li>
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <div className="right-side">
              <Button color="primary" onClick={() => setShowModal(false)}>
                {translate('global.close')}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
