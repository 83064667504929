import classnames from 'classnames';
import React, { MouseEvent, MouseEventHandler, ReactNode } from 'react';

import {
  Button,
  IconButton as MIconButton,
  Theme,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Spinner } from 'styleguide-v2/src/components/Spinner';
import { BackgroundColors, Colors } from 'styleguide-v2/src/styles/Colors';

export type ButtonSize = 'small' | 'medium';

type StyleProps = {
  bkgColor: string;
  color: string;
};

const useStyles = makeStyles<Theme, StyleProps>({
  border: {
    '&&': {
      border: '1px solid',
      height: '2.5rem',
      width: '2.5rem',
    },
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    gap: '0.5rem',
    flexFlow: 'column',
    '@global': {
      '.MuiButton-root:not([disabled])': {
        backgroundColor: ({ bkgColor }: StyleProps) => `${bkgColor}`,
        color: ({ color }: StyleProps) => `${color}`,
      },
      '.MuiButton-root[disabled]': {
        backgroundColor: Colors.lighterGray,
      },
      '.MuiButton-startIcon': {
        display: 'contents',
      },
    },
  },
  disabled: {
    cursor: 'not-allowed',
  },
  iconButton: {
    '&&': {
      height: '4.5rem',
      minWidth: '4.5rem',
      width: '4.5rem',
    },
  },
  noCursor: {
    cursor: 'default',
  },
  noMargin: {
    '&&': {
      margin: 0,
      padding: 0,
      height: 'fit-conten',
      minWidth: 'auto',
      width: 'fit-conten',
    },
  },
  text: {
    fontWeight: '500',
  },

  smallButton: {
    '&& > span': {
      display: 'none',
    },
  },
});

interface Props {
  border?: boolean;
  color?: 'primary' | 'secondary' | 'action' | 'info' | 'default';
  className?: string;
  children?: ReactNode;
  'data-cy'?: string;
  disabled?: boolean | string;
  label?: string;
  loading?: boolean;
  icon?: string;
  noMargin?: boolean;
  onClick?: MouseEventHandler;
  size?: ButtonSize;
  tooltip?: ReactNode | string;
}

export const IconButton = (props: Props) => {
  const {
    border,
    color = 'primary',
    children,
    className,
    'data-cy': dataCy,
    disabled,
    icon,
    label,
    loading,
    noMargin,
    onClick,
    size = 'medium',
    tooltip,
  } = props;

  const getColors = () => {
    if (color === 'primary') {
      return {
        bkgColor: Colors.mint,
        color: Colors.green,
      };
    }
    if (color === 'action') {
      return {
        bkgColor: Colors.lightGray,
        color: Colors.iconGray,
      };
    }
    if (color === 'info') {
      return {
        bkgColor: BackgroundColors.lightBlue,
        color: Colors.blue,
      };
    }
    return {
      bkgColor: 'transparent',
      color: Colors.iconGray,
    };
  };

  const styles = useStyles(getColors());
  const classes = classnames('mbtn', className, styles.iconButton);

  const handleClick = (evt: MouseEvent) => {
    if (!loading && !disabled && onClick) {
      onClick(evt);
    }
  };

  const getIcon = () => {
    if (loading) {
      return <Spinner width={22} />;
    }
    if (icon) {
      return <i className={`fa fa-${icon}`} />;
    }
    return children;
  };

  const rederButton = () =>
    size === 'medium' ? (
      <div
        className={classnames(
          styles.container,
          disabled && styles.disabled,
          className,
        )}
        data-cy={dataCy}
      >
        <Button
          aria-label={typeof tooltip === 'string' ? tooltip : undefined}
          disabled={!!disabled}
          className={classes}
          onClick={handleClick}
          startIcon={getIcon()}
        />

        {label && <span className={styles.text}>{label}</span>}
      </div>
    ) : (
      <MIconButton
        aria-label={typeof tooltip === 'string' ? tooltip : undefined}
        className={classnames(
          'mbtn',
          className,
          styles.smallButton,
          !onClick && styles.noCursor,
          border && styles.border,
          noMargin && styles.noMargin,
        )}
        component="span"
        color={color !== 'action' ? color : 'default'}
        data-cy={dataCy}
        disabled={!!disabled}
        onClick={handleClick}
      >
        {getIcon()}
      </MIconButton>
    );

  if (tooltip) return <Tooltip title={tooltip}>{rederButton()}</Tooltip>;
  if (typeof disabled === 'string')
    return (
      <div style={{ width: 'fit-content' }}>
        <Tooltip title={disabled}>{rederButton()}</Tooltip>
      </div>
    );

  return rederButton();
};

export default IconButton;
