import moment from 'moment';
import React from 'react';

import { Card, CardContent } from '@mui/material';

import {
  BackgroundColors,
  Chip,
  Panel,
  PanelInfo,
  StatusIndicator,
  StatusVisit,
  Summary,
} from 'styleguide-v2';

const Misc = () => (
  <Panel>
    <Panel.Heading title="Misc" />
    <Panel.Body className="grid-wrapper">
      <Card
        className="grid-span-6"
        sx={{ padding: '2rem', height: 'max-content' }}
      >
        <CardContent
          className="grid-wrapper"
          sx={{
            background: BackgroundColors.lightMint,
            borderRadius: '.4rem',
          }}
        >
          <h4 className="grid-span-12">Chip</h4>
          <h6 className="grid-span-3" style={{ margin: 0 }}>
            Contained
          </h6>
          <div className="grid-span-9 flex" style={{ gap: '.5rem' }}>
            <Chip color="primary" label="Primary" />
            <Chip color="secondary" label="Secondary" />
            <Chip color="info" label="Info with title" title="Example title" />
            <Chip color="warning" label="Warning" />
            <Chip
              color="error"
              label="Error with title"
              title="Example title"
            />
          </div>
          <h6 className="grid-span-3" style={{ margin: 0 }}>
            Contained 2
          </h6>
          <div className="grid-span-9 flex" style={{ gap: '.5rem' }}>
            <Chip type="contained-2" color="primary" label="Primary" />
            <Chip type="contained-2" color="secondary" label="Secondary" />
            <Chip
              type="contained-2"
              color="info"
              label="Info with title"
              title="Example title"
            />
            <Chip type="contained-2" color="warning" label="Warning" />
            <Chip
              type="contained-2"
              color="error"
              label="Error with title"
              title="Example title"
            />
          </div>
          <h6 className="grid-span-3" style={{ margin: 0 }}>
            Outlined
          </h6>
          <div className="grid-span-9 flex" style={{ gap: '.5rem' }}>
            <Chip type="outlined" color="primary" label="Primary" />
            <Chip type="outlined" color="secondary" label="Secondary" />
            <Chip
              type="outlined"
              color="info"
              label="Info with title"
              title="Example title"
            />
            <Chip type="outlined" color="warning" label="Warning" />
            <Chip
              type="outlined"
              color="error"
              label="Error with title"
              title="Example title"
            />
          </div>
        </CardContent>
      </Card>
      <Card
        className="grid-span-6"
        sx={{ padding: '2rem', height: 'max-content' }}
      >
        <CardContent className="grid-wrapper">
          <h4 className="grid-span-12">Summary</h4>
          <Summary
            body="740"
            footer={
              <>
                <span style={{ fontWeight: 500 }}>100%</span> of the patients
              </>
            }
            title="Total"
          />
          <Summary
            body="22"
            footer={<i>This is a footer</i>}
            title="Summary"
            onClick={() => {}}
          />
        </CardContent>
      </Card>
      <Card
        className="grid-span-6"
        sx={{ padding: '2rem', height: 'max-content' }}
      >
        <CardContent className="grid-wrapper">
          <h4 className="grid-span-12">Status Indicator</h4>
          <div className="grid-span-6">
            <h6 style={{ margin: 0 }}>Size M</h6>
            <StatusIndicator color="info" status="Info" />
            <StatusIndicator color="warning" status="Warning" />
            <StatusIndicator color="error" status="Error" />
            <StatusIndicator color="success" status="Success" />
            <StatusIndicator color="closed" status="Closed" />
            <StatusIndicator color="default" status="Default" />
            <StatusIndicator color="loading" status="Loading" />
          </div>
          <div className="grid-span-6">
            <h6 style={{ margin: 0 }}>Size S</h6>
            <StatusIndicator color="info" status="Info" size="small" />
            <StatusIndicator color="warning" status="Warning" size="small" />
            <StatusIndicator color="error" status="Error" size="small" />
            <StatusIndicator color="success" status="Success" size="small" />
            <StatusIndicator color="closed" status="Closed" size="small" />
            <StatusIndicator color="default" status="Default" size="small" />
            <StatusIndicator color="loading" status="Loading" size="small" />
          </div>
        </CardContent>
      </Card>
      <Card
        className="grid-span-6"
        sx={{ padding: '2rem', height: 'max-content' }}
      >
        <CardContent className="grid-wrapper">
          <h4 className="grid-span-12">Status Visit</h4>
          <div className="grid-span-6">
            <StatusVisit color="warning" date={moment()} />
            <StatusVisit color="error" date={moment()} />
            <StatusVisit color="success" date={moment()} />
          </div>
        </CardContent>
      </Card>
      <Card
        className="grid-span-6"
        sx={{ padding: '2rem', height: 'max-content' }}
      >
        <CardContent className="grid-wrapper gap">
          <h4 className="grid-span-12">Panel Info</h4>
          <PanelInfo className="grid-span-6" title="Error" type="error" />
          <PanelInfo className="grid-span-6" title="Warning" type="warning" />
          <PanelInfo className="grid-span-6" title="Info" type="info" />
        </CardContent>
      </Card>
    </Panel.Body>
  </Panel>
);

export default Misc;
