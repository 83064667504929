import React, { useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';

import { translate } from '@vestahealthcare/common/i18n';
import {
  CareTeamPerson,
  Patient,
  UserPhone,
} from '@vestahealthcare/common/models';

import {
  Button,
  CollapsableSidebar,
  RadioGroup,
  Select,
  SelectCareTeamPersonOption,
  SelectMemberOption,
  SelectPhoneOption,
  TextArea,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { sendSMS } from 'dash/src/services/MessageServices';
import {
  PatientSearchParams,
  getMemberPhones,
  searchPatients,
} from 'dash/src/services/PatientServices';

import {
  GetCareTeamPersonsParams,
  fetchCareTeamPeople,
} from '../../../../services/CareTeamServices';

type Props = {
  open: boolean;
  onSubmit: () => Promise<void>;
  onClose: () => void;
};

const SEND_MESSAGE_MEMBER_TYPE = 'member';
const SEND_MESSAGE_CT_TYPE = 'care-team';
const SEND_MESSAGE_CT_TYPE_OPTIONS = [
  { label: translate('chat.member'), value: SEND_MESSAGE_MEMBER_TYPE },
  { label: translate('chat.careTeam'), value: SEND_MESSAGE_CT_TYPE },
];
const SEARCH_LIMIT = 10;

export const CreateMessageModal = ({ open, onClose, onSubmit }: Props) => {
  const [caregiversLoading, setCaregiversLoading] = useState(false);
  const [membersLoading, setMembersLoading] = useState(false);
  const [phonesLoading, setPhonesLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [userType, setUserType] = useState(SEND_MESSAGE_MEMBER_TYPE);
  const [memberFilter, setMemberFilter] = useState('');
  const [ctpFilter, setCTPFilter] = useState('');

  const [members, setMembers] = useState<Patient[]>([]);
  const [careTeamPersons, setCareTeamPersons] = useState<CareTeamPerson[]>([]);

  const [member, setMember] = useState<Patient>();
  const [careTeamPerson, setCareTeamPerson] = useState<CareTeamPerson>();
  const [memberPhones, setMemberPhones] = useState<UserPhone[]>([]);
  const [careTeamPersonPhones, setCareTeamPersonPhones] = useState<UserPhone[]>(
    [],
  );
  const [phone, setPhone] = useState<UserPhone>();
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout>();
  const [message, setMessage] = useState('');

  const reset = () => {
    setMemberFilter('');
    setMember(undefined);
    setCTPFilter('');
    setCareTeamPerson(undefined);
  };

  useEffect(() => reset(), [userType]);

  const getMembers = async () => {
    setMembersLoading(true);
    const filters: PatientSearchParams = {
      limit: SEARCH_LIMIT,
      sort: 'firstName asc',
    };
    if (isNaN(Number(memberFilter))) {
      filters.fullName = memberFilter;
    } else {
      filters.id = [Number(memberFilter)];
    }
    try {
      const { items } = await searchPatients(filters);
      setMembers(items);
    } catch (e) {
      showGlobalError(e as string);
    }
    setMembersLoading(false);
  };

  useEffect(() => {
    if (memberFilter.length && memberFilter !== member?.fullName) {
      setMembers([]);
      getMembers();
    }
  }, [memberFilter, member]);

  const getCareTeamPersons = async () => {
    setCaregiversLoading(true);
    const filters: GetCareTeamPersonsParams = {
      limit: SEARCH_LIMIT,
      sort: 'firstName asc',
    };
    if (isNaN(Number(ctpFilter))) {
      filters.fullName = ctpFilter;
    } else {
      filters.id = [Number(ctpFilter)];
    }
    try {
      const { items } = await fetchCareTeamPeople(filters);
      setCareTeamPersons(items);
    } catch (e) {
      showGlobalError(e as string);
    }
    setCaregiversLoading(false);
  };

  useEffect(() => {
    if (ctpFilter.length && ctpFilter !== careTeamPerson?.fullName) {
      setCareTeamPersons([]);
      getCareTeamPersons();
    }
  }, [ctpFilter, careTeamPerson]);

  const fetchMemberPhones = async () => {
    if (member) {
      setPhonesLoading(true);
      const phones = await getMemberPhones(member.id);
      setMemberPhones(phones);
      setPhonesLoading(false);
    }
  };
  const fetchCTPPhones = () => {
    if (careTeamPerson) {
      const phones = [...careTeamPerson.phones];
      setCareTeamPersonPhones(phones);
    }
  };

  useEffect(() => {
    setPhone(undefined);
    fetchMemberPhones();
    fetchCTPPhones();
  }, [member, careTeamPerson]);

  useEffect(() => {
    if (!phone) {
      setMessage('');
    }
  }, [phone]);

  const close = () => {
    onClose();
    setUserType(SEND_MESSAGE_MEMBER_TYPE);
    reset();
  };

  const doSubmit = async () => {
    if (!phone || !message) return;

    setSubmitLoading(true);
    try {
      await sendSMS(phone.phone.number, message);
      await onSubmit();
    } catch (e) {
      showGlobalError(e as string);
    }
    setSubmitLoading(false);
    close();
  };

  const getPhones = () => {
    if (userType === SEND_MESSAGE_CT_TYPE) return careTeamPersonPhones;
    return memberPhones;
  };

  return (
    <CollapsableSidebar
      open={open}
      onClose={close}
      title={<h2>{translate('chat.createMessage')}</h2>}
      size={400}
    >
      <CollapsableSidebar.Body>
        <RadioGroup
          data-cy="send-message-user-type"
          title={translate('chat.selectUserType')}
          onChange={setUserType}
          items={SEND_MESSAGE_CT_TYPE_OPTIONS}
          value={userType}
        />
        {userType === SEND_MESSAGE_MEMBER_TYPE && (
          <Select
            data-cy="send-message-member-select"
            getItemLabel={({ fullName }) => fullName}
            icon={<SearchIcon />}
            items={members}
            loading={membersLoading}
            noOptionsText={
              memberFilter.length < 2
                ? translate('chat.typeToSearch')
                : translate('chat.noMembersFound')
            }
            onChange={(value: Patient) => setMember(value)}
            onInputChange={(value) => {
              const timer = setTimeout(() => {
                setMemberFilter(value);
              }, 500);
              if (searchTimer) {
                clearTimeout(searchTimer);
              }
              setSearchTimer(timer);
            }}
            placeholder={translate('chat.searchMember')}
            renderOption={(member: Patient) => (
              <SelectMemberOption member={member} />
            )}
            value={member}
          />
        )}
        {userType === SEND_MESSAGE_CT_TYPE && (
          <Select
            data-cy="send-message-care-team-select"
            getItemLabel={({ fullName }) => fullName}
            icon={<SearchIcon />}
            items={careTeamPersons}
            loading={caregiversLoading}
            noOptionsText={
              ctpFilter.length < 2
                ? translate('chat.typeToSearch')
                : translate('chat.noCareTeamPersonFound')
            }
            onChange={(value: CareTeamPerson) => setCareTeamPerson(value)}
            onInputChange={(value) => {
              const timer = setTimeout(() => {
                setCTPFilter(value);
              }, 500);
              if (searchTimer) {
                clearTimeout(searchTimer);
              }
              setSearchTimer(timer);
            }}
            placeholder={translate('chat.searchCareTeamPerson')}
            renderOption={(person: CareTeamPerson) => (
              <SelectCareTeamPersonOption person={person} />
            )}
            value={careTeamPerson}
          />
        )}
        <Select
          data-cy="send-message-phone-select"
          disabled={
            (userType === SEND_MESSAGE_MEMBER_TYPE && !member) ||
            (userType === SEND_MESSAGE_CT_TYPE && !careTeamPerson)
          }
          getItemLabel={({ phone }) => phone.getDisplayNumber()}
          items={getPhones()}
          label={translate('chat.phoneNumber')}
          loading={phonesLoading}
          onChange={setPhone}
          renderOption={(phone: UserPhone) => (
            <SelectPhoneOption userPhone={phone} />
          )}
          value={phone}
        />
        <TextArea
          disabled={!phone}
          label={translate('chat.message')}
          onChange={(value?: string) => setMessage(value || '')}
          rows={5}
          value={message}
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button color="tertiary" data-cy="send-messag-cancel" onClick={close}>
          {translate('global.cancel')}
        </Button>
        <Button
          color="secondary"
          data-cy="send-messag-submit"
          disabled={!message}
          loading={submitLoading}
          onClick={doSubmit}
        >
          {translate('global.send')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default CreateMessageModal;
