import { DATE_TIME_FORMAT } from '../utils/constants';
import moment from '../moment';
import { RiskLevel, CareTrackType, CareTrackSource } from '../enums';
import { Employee } from './Employee';
import { Observation } from './Observation';
import { make, makeEnums, makeArray } from './Model';

export class CareTrack {
  id = 0;

  createdAt = 0;

  updatedAt = 0;

  riskLevel: RiskLevel;

  type: CareTrackType;

  updatedBy?: Employee;

  evaluationNote?: string;

  sources: CareTrackSource[];

  observations: Observation[];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.riskLevel = RiskLevel.byKey[obj.riskLevel] || RiskLevel.UNSPECIFIED;
    this.type = CareTrackType.byKey[obj.type];
    this.updatedBy = make(obj.updatedBy, Employee);
    this.sources = makeEnums(obj.sources, CareTrackSource);
    this.observations = makeArray(obj.observations, Observation);
  }

  get isEvaluated() {
    return this.riskLevel !== RiskLevel.UNSPECIFIED;
  }

  get isActive() {
    return this.type.activeLevels.includes(this.riskLevel);
  }

  isActiveLevel(level: RiskLevel) {
    return this.type.activeLevels.includes(level);
  }

  get hasSources() {
    return this.sources && !!this.sources.length;
  }

  get hidden() {
    return CareTrackType.isHiddenType(this.type);
  }

  get order() {
    // we always want SOCIAL last!
    if (this.type === CareTrackType.SOCIAL) {
      return 999;
    }

    // Order is base 1
    // In CareTrackType they are ordered from lower to higher risk
    // We want Higher risk first, Lower risk last.
    // Not found will be placed last
    const levels = this.type.riskLevels;
    return levels.length - levels.indexOf(this.riskLevel);
  }

  getUpdatedAtFormatted() {
    return moment.unix(this.updatedAt).format(DATE_TIME_FORMAT);
  }

  get selectableRiskLevels() {
    return RiskLevel.toSelectable(this.type.riskLevels);
  }

  isValid(riskLevels: RiskLevel[], hiddenPrograms: CareTrackType[] = []) {
    return (
      riskLevels.includes(this.riskLevel) && !hiddenPrograms.includes(this.type)
    );
  }
}

export default CareTrack;
