/* eslint-disable @typescript-eslint/no-use-before-define */
import moment, { Moment } from 'moment';

export class BiometricsRPMAdherenceCycle {
  cycleStart: Moment;

  cycleEnd: Moment;

  readings = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.cycleStart = moment.unix(obj.cycleStart);
    this.cycleEnd = moment.unix(obj.cycleEnd);
  }
}

export default BiometricsRPMAdherenceCycle;
