import { Enum } from './Enum';

export class PatientLivingArrangement extends Enum {
  translationKey = 'livingArrangement';

  static asArray: PatientLivingArrangement[] = [];

  static byKey: { [name: string]: PatientLivingArrangement } = {};

  static ALONE = new PatientLivingArrangement('ALONE');

  static CHILD = new PatientLivingArrangement('CHILD');

  static GRANDDAUGHTER = new PatientLivingArrangement('GRANDDAUGHTER');

  static GRANDSON = new PatientLivingArrangement('GRANDSON');

  static NEPHEW = new PatientLivingArrangement('NEPHEW');

  static NIECE = new PatientLivingArrangement('NIECE');

  static PARENT = new PatientLivingArrangement('PARENT');

  static SPOUSE = new PatientLivingArrangement('SPOUSE');

  static LIVE_IN_AIDE = new PatientLivingArrangement('LIVE_IN_AIDE');

  static OTHER = new PatientLivingArrangement('OTHER');

  constructor(public readonly value: string) {
    super();
    PatientLivingArrangement.asArray.push(this);
    PatientLivingArrangement.byKey[value] = this;
  }
}

export default PatientLivingArrangement;
