import React, { Component } from 'react';
import { FormGroup, ControlLabel, HelpBlock } from 'react-bootstrap';
// @ts-ignore
import ReactSelect from 'react-select'; // rename so it doesn't conflict with ours
import classNames from 'classnames';
import { MultiSelectOption, DeleteIcon } from 'styleguide';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import './main.less';
import { GridColumns } from '../../utils/types';

// TODO: Update to precise types
// Correcting these values requires splitting this component into Single and Multiselect versions
interface Props {
  autoFocus?: any;
  className?: any;
  columns?: GridColumns;
  'data-cy'?: any;
  disabled?: any;
  blockTags?: any;
  errorText?: any;
  id?: any;
  isOptionDisabled?: any;
  label?: any;
  multiple?: any;
  name?: any;
  onChange: any;
  onInputChange?: any;
  options: any[];
  placeholder?: any;
  required?: any;
  secondary?: any;
  showError?: any;
  submitted?: any;
  sort?: (a: any, b: any) => number;
  validator?: any;
  value?: any;
  size?: 'small';
}

export default class Select extends Component<Props> {
  elem?: any;

  static defaultProps = {
    'data-cy': 'select-dropdown',
    label: false,
    placeholder: 'Select One',
    multiple: false,
    secondary: false,
    required: false,
    disabled: false,
    blockTags: false,
    showError: false,
  };

  state = {
    isUserEditing: false,
  };

  componentWillReceiveProps(nextProps: Props) {
    // ReactSelect doesn't do focus when the component has been updated, only on mount.
    // If it's true and previously don't, scroll to the field
    if (nextProps.autoFocus && nextProps.autoFocus !== this.props.autoFocus) {
      this.scrollIntoView();
    }
  }

  // For select multiple, separated tags list style is not
  // included in the react-select component, So this part is totally customized.
  removeItem(removedItemValue: any) {
    const { onChange, value } = this.props;
    if (Array.isArray(value)) {
      onChange(value.filter((item) => item !== removedItemValue));
    }
  }

  renderTags() {
    const { value, options } = this.props;

    if (!value) {
      return <div />;
    }

    return (
      <div className="ht-dropdown-tags">
        {Array.isArray(value) &&
          value.map((itemValue) => {
            const item = options.find((option) => option.value === itemValue);
            if (!item) {
              return null;
            }
            return this.renderTag(item);
          })}
      </div>
    );
  }

  renderTag(item: Selectable) {
    const { blockTags, 'data-cy': dataCy } = this.props;

    if (!item) {
      return null;
    }

    return (
      <span
        className={`ht-dropdown-tag tag-display ${blockTags ? 'block' : ''}`}
        data-cy={`${dataCy}-tag`}
        key={item.value}
      >
        {item.label}
        <DeleteIcon onClick={() => this.removeItem(item.value)} tag="button" />
      </span>
    );
  }

  scrollIntoView() {
    if (this.elem) {
      // @ts-ignore
      this.elem.scrollIntoView();
    }
  }

  onBlur() {
    this.setState({ isUserEditing: false });
  }

  onFocus() {
    this.setState({ isUserEditing: true });
  }

  isValid() {
    const { multiple, required, value, validator } = this.props;
    // custom validator
    const valid = validator ? validator(value) : true;
    // valid if not required or a value provided
    const valuePresent =
      multiple && Array.isArray(value) ? value?.length : value;
    return valid && (!required || !!valuePresent);
  }

  showValidationError() {
    const { showError, submitted } = this.props;
    const { isUserEditing } = this.state;
    return !isUserEditing && (showError || submitted) && !this.isValid();
  }

  render() {
    const {
      autoFocus,
      className,
      columns,
      'data-cy': dataCy,
      disabled,
      errorText,
      id,
      isOptionDisabled,
      label,
      multiple,
      secondary,
      name,
      onChange,
      onInputChange,
      sort,
      options,
      placeholder,
      required,
      value,
      size,
    } = this.props;

    const classes = classNames(
      secondary ? 'ht-dropdown-secondary' : 'ht-dropdown',
      className,
      columns && `grid-span-${columns}`,
      size && `ht-field-${size}`,
    );

    return (
      <FormGroup
        controlId={id}
        className={classes}
        data-cy={dataCy}
        validationState={this.showValidationError() ? 'error' : null}
      >
        <span
          ref={(el) => {
            this.elem = el;
          }}
        />
        {label !== false && (
          <ControlLabel data-cy={`${dataCy}-label`}>
            {label}
            {required && ' *'}
          </ControlLabel>
        )}
        <ReactSelect
          autoFocus={autoFocus}
          placeholder={placeholder}
          clearable={false}
          value={value}
          name={name || null}
          optionComponent={multiple ? MultiSelectOption : undefined}
          valueComponent={
            multiple
              ? () => false
              : (instance: any) => this.renderTag(instance.value)
          }
          onChange={(selection: any) =>
            onChange(
              selection &&
                (multiple
                  ? selection.map((i: Selectable) => i.value)
                  : selection.value),
            )
          }
          onInputChange={(input: any) => onInputChange && onInputChange(input)}
          options={sort ? options.sort(sort) : options}
          multi={multiple}
          removeSelected={!multiple}
          disabled={disabled}
          isOptionDisabled={isOptionDisabled}
          onFocus={() => this.onFocus()}
          onBlur={() => this.onBlur()}
        />
        {!!errorText && this.showValidationError() && (
          <HelpBlock data-cy={`${dataCy}-error`} className="error-message">
            {errorText}
          </HelpBlock>
        )}
        {multiple && this.renderTags()}
      </FormGroup>
    );
  }
}
