import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { translate } from '@vestahealthcare/common/i18n';
import {
  ExternalIdSource,
  MemberExternalId,
  Patient,
} from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Button, IconButton, Select, TextInput } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { getExternalIdSources } from 'dash/src/services/ExternalIdSourceServices';
import {
  getMemberExternalIds,
  updateMemberExternalIds,
} from 'dash/src/services/PatientServices';

import { BaseModal } from './BaseModal';

interface Props {
  patient: Patient;
  onSubmit: (patient: Patient) => void;
}

const useStyles = makeStyles({
  container: {
    borderRight: '1px !important',
    paddingRight: '4px !important',
  },
  row: {
    marginBottom: '10px',
  },
  closeButton: {
    marginTop: '7px',
  },
});

const DetailsComponent = React.memo(
  ({ externalIds }: { externalIds?: MemberExternalId[] }) => {
    const styles = useStyles();
    return externalIds?.length ? (
      <div
        data-cy="external-ids-readonly"
        className={`grid-span-11 grid-wrapper ${styles.container}`}
      >
        {externalIds.map((externalId: MemberExternalId) => (
          <div
            className={`grid-span-11 grid-wrapper ${styles.container}`}
            key={externalId.id}
          >
            <h4 className="grid-span-6">{externalId.externalIdSource.name}</h4>
            <p className="grid-span-6">{externalId.externalId}</p>
          </div>
        ))}
      </div>
    ) : (
      <>
        <h4 className="grid-span-5">{translate('personalDetails.emr')}</h4>
        <p className="grid-span-6">{EMPTY}</p>
      </>
    );
  },
);

export const EditExternalIds = (props: Props) => {
  const { patient, onSubmit } = props;
  const editable = patient.isEditable() && patient.isDemographicsEditable();
  const [isLoading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [memberExternalIds, setMemberExternalIds] = useState<
    MemberExternalId[]
  >([]);
  const [modalExternalIds, setModalExternalIds] = useState<MemberExternalId[]>(
    [],
  );
  const [externalIdSources, setExternalIdSources] = useState<
    ExternalIdSource[]
  >([]);
  const [refresh, setRefresh] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const styles = useStyles();

  const fetchExternalIds = async () => {
    setLoading(true);
    const externalIds = await getMemberExternalIds(patient.id);
    setMemberExternalIds(externalIds);
    setLoading(false);
  };

  useEffect(() => {
    fetchExternalIds();
  }, [patient, refresh]);

  const fetchExternalIdSources = async () => {
    setLoading(true);
    const externalIdSources = await getExternalIdSources();
    setExternalIdSources(externalIdSources);
    setLoading(false);
  };

  useEffect(() => {
    fetchExternalIdSources();
  }, []);

  useEffect(() => {
    setModalExternalIds(memberExternalIds);
  }, [memberExternalIds]);

  const filterItemsInList = (keyPresent: number) =>
    externalIdSources.filter(
      (source) =>
        source.id === keyPresent ||
        !modalExternalIds.find((id) => id.externalIdSource.id === source.id),
    );

  const onAdd = () => {
    const newExternalId = new MemberExternalId({});
    setModalExternalIds([...modalExternalIds, newExternalId]);
  };

  const onUpdate = (
    memberExternalId: MemberExternalId,
    index: number,
    changes: Object,
  ) => {
    const updatedItem: MemberExternalId = { ...memberExternalId, ...changes };

    const externalIdSource =
      externalIdSources.find(
        (item) => item.id === updatedItem.externalIdSource.id,
      ) || updatedItem.externalIdSource;
    modalExternalIds[index] = { ...updatedItem, externalIdSource };
    setModalExternalIds([...modalExternalIds]);
  };

  const onDelete = (memberExternalId: MemberExternalId, index: number) => {
    modalExternalIds.splice(index, 1);
    setModalExternalIds([...modalExternalIds]);
  };

  const doRefresh = () => {
    setSubmitted(false);
    setRefresh(refresh + 1);
  };

  const validate = () => {
    return modalExternalIds.reduce((acc: boolean, item: MemberExternalId) => {
      return acc && !!item.externalId && !!item.externalIdSource.id;
    }, true);
  };

  const submit = async () => {
    let result = true;
    setSubmitted(true);

    if (!validate()) {
      return false;
    }

    setSubmitLoading(true);

    try {
      await updateMemberExternalIds(
        patient.id,
        modalExternalIds.map(({ externalId, externalIdSource }) => ({
          externalId,
          externalIdSourceId: externalIdSource.id,
        })),
      );
      onSubmit(patient);
      setMemberExternalIds(modalExternalIds);
    } catch (e) {
      showGlobalError(e as string);
      result = false;
    }

    setSubmitLoading(false);
    return result;
  };

  const reset = () => {
    setSubmitted(false);
    setLoading(false);
  };

  return (
    <BaseModal
      details={<DetailsComponent externalIds={memberExternalIds} />}
      data-cy="member-external-ids"
      editable={editable}
      onCancel={reset}
      onSubmit={submit}
      onShowModal={doRefresh}
      loading={isLoading}
      loadingSubmit={submitLoading}
      title={translate('personalDetails.externalIds')}
    >
      <div className="grid-wrapper">
        <label className="grid-span-5">{translate('global.externalId')}</label>
        <label className="grid-span-6">
          {translate('global.externalIdSource')}
        </label>
      </div>
      {modalExternalIds?.map((memberExternalId, index) => (
        <div className={`grid-wrapper ${styles.row}`} key={memberExternalId.id}>
          <TextInput
            required
            className="grid-span-5"
            data-cy={`external-id-${index}`}
            error={
              submitted &&
              !memberExternalId.externalId &&
              translate('global.externalIdInvalid')
            }
            value={memberExternalId.externalId}
            onChange={(val) =>
              onUpdate(memberExternalId, index, { externalId: val })
            }
          />
          <Select
            className="grid-span-6"
            data-cy={`external-id-${index}-source`}
            getItemLabel={(item: ExternalIdSource) => item.name}
            items={filterItemsInList(memberExternalId.externalIdSource.id)}
            onChange={(val: ExternalIdSource) =>
              onUpdate(memberExternalId, index, {
                externalIdSource: val,
              })
            }
            required
            error={
              submitted &&
              !memberExternalId.externalIdSource.id &&
              translate('global.externalIdTypeInvalid')
            }
            value={memberExternalId.externalIdSource}
          />

          <div className={`grid-span-1 icon-wrapper ${styles.closeButton}`}>
            <IconButton
              data-cy={`external-id-${index}-remove`}
              onClick={() => onDelete(memberExternalId, index)}
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      ))}

      <Button
        data-cy="external-id-add"
        className="btn-add"
        color="quaternary"
        icon={<AddIcon />}
        onClick={onAdd}
      >
        {translate('global.addExternalId')}
      </Button>
    </BaseModal>
  );
};

export default EditExternalIds;
