import moment, { Moment } from 'moment';
import { DATE_FORMAT } from '../utils/constants';

export class MemberAllergy {
  agent = '';

  reaction = '';

  onsetDate?: Moment;

  status = '';

  updatedAt?: Moment;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.onsetDate = obj.onsetDate
      ? moment(obj.onsetDate, DATE_FORMAT)
      : undefined;
    this.updatedAt = obj.updatedAt
      ? moment(obj.updatedAt, DATE_FORMAT)
      : undefined;
  }
}

export default MemberAllergy;
