import React from 'react';
import classnames from 'classnames';

import makeStyles from '@mui/styles/makeStyles';
import { translate } from '@vestahealthcare/common/i18n';
import { Encounter, Patient } from '@vestahealthcare/common/models';
import moment from 'moment';
import { DATE_FORMAT_SHORT_WITH_TIME } from '@vestahealthcare/common/utils/constants';
import { Fonts } from 'styleguide-v2';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  label: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
  },
});

type Props = {
  className?: string;
  encounters?: Encounter[];
  patient?: Patient;
};

export const EventDetailEncounters = ({
  className,
  encounters,
  patient,
}: Props) => {
  const styles = useStyles();
  const totalMinutes = encounters?.reduce(
    (acc, { duration }) => acc + duration,
    0,
  );

  return !encounters?.length || !patient ? (
    <></>
  ) : (
    <div className={classnames(className, styles.container)}>
      <span className={styles.label}>
        {translate('memberEvents.encounters', {
          count: encounters.length,
        })}{' '}
        {`(${translate('memberEvents.encounterMinutes', {
          count: totalMinutes,
        })})`}
      </span>
      <ul>
        {encounters.map(({ id, encounterDateTime, duration }) => (
          <li key={`encounter-id-${id}`}>
            <a
              target="_blank"
              rel="noreferrer"
              href={`/#/patients/${patient?.id}/encounters?ids=${id}`}
            >
              {`${moment
                .unix(encounterDateTime)
                .format(DATE_FORMAT_SHORT_WITH_TIME)}
              
              (${translate('memberEvents.encounterMinutes', {
                count: duration,
              })})`}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default EventDetailEncounters;
