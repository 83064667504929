import moment, { Moment } from 'moment';
import { MemberEligibilityStatus } from '../enums';
import { make } from './Model';
import { Employee } from './Employee';
import { InsurancePlan } from './InsurancePlan';
import { InsurancePriority } from './InsurancePriority';
import { InsuranceMLTC } from './InsuranceMLTC';
import { ErrorModel } from './ErrorModel';

export class MemberInsurance {
  id = 0;

  patientId = 0;

  insurancePlan: InsurancePlan;

  insuranceType = '';

  insuranceTypeName = '';

  subscriberId = '';

  insurancePriority: InsurancePriority;

  createdBy?: Employee;

  updatedBy?: Employee;

  createdAt = 0;

  updatedAt = 0;

  enrollmentStart?: string;

  enrollmentEnd?: string;

  discontinuedAt?: number;

  mltcPlan?: InsuranceMLTC;

  eligibilityStatus?: MemberEligibilityStatus;

  eligibilityError?: ErrorModel;

  eligibilityDatetime?: Moment;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdBy = obj.createdBy && make(obj.createdBy, Employee);
    this.updatedBy = obj.updatedBy && make(obj.updatedBy, Employee);
    this.insurancePlan = make(obj.insurancePlan, InsurancePlan);
    this.insurancePriority = make(obj.insurancePriority, InsurancePriority);
    this.mltcPlan = make(obj.mltcPlan, InsuranceMLTC);
    this.eligibilityStatus = obj.eligibilityStatus
      ? MemberEligibilityStatus.byKey[obj.eligibilityStatus]
      : undefined;
    this.eligibilityError = make(obj.eligibilityError, ErrorModel);
    this.eligibilityDatetime = obj.eligibilityDatetime
      ? moment.unix(obj.eligibilityDatetime)
      : undefined;
  }
}

export default MemberInsurance;
