import { IssueTypeRuleOperation } from '../enums';

export class IssueTypeRule {
  id?: number;

  operationType?: IssueTypeRuleOperation;

  value = '';

  urgent = false;

  urgencyLevel = 0;

  timeFrame?: number;

  timeFrameUnit?: string;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.operationType = IssueTypeRuleOperation.byKey[obj.operationType];
  }
}

export default IssueTypeRule;
