import { Enum } from './Enum';

export class MemberDashboardPharmacyUnderstanding extends Enum {
  translationKey = 'memberDashboardPharmacyUnderstanding';

  static asArray: MemberDashboardPharmacyUnderstanding[] = [];

  static byKey: { [name: string]: MemberDashboardPharmacyUnderstanding } = {};

  static readonly HIGH = new MemberDashboardPharmacyUnderstanding('HIGH');

  static readonly MODERATE = new MemberDashboardPharmacyUnderstanding(
    'MODERATE',
  );

  static readonly LOW = new MemberDashboardPharmacyUnderstanding('LOW');

  constructor(public readonly value: string) {
    super();
    MemberDashboardPharmacyUnderstanding.asArray.push(this);
    MemberDashboardPharmacyUnderstanding.byKey[value] = this;
  }
}

export default MemberDashboardPharmacyUnderstanding;
