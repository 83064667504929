import { Button, Select } from 'styleguide';
import { translate } from '@vestahealthcare/common/i18n';
import React from 'react';
import { Employee } from '@vestahealthcare/common/models/Employee';
import Session from 'dash/src/services/SessionServices';

interface Props {
  autofocus?: any;
  columns?: number;
  disabled?: boolean;
  employees: Employee[];
  errorText?: any;
  label?: any;
  multiple?: boolean;
  onChange: any;
  required?: any;
  showAssignToMe?: boolean;
  submitted?: any;
  value: any;
}

export const EmployeeDropdown = ({
  autofocus,
  columns,
  disabled,
  employees,
  errorText,
  label,
  multiple,
  onChange,
  required,
  showAssignToMe,
  submitted,
  value,
}: Props) => {
  return (
    <span className={columns ? `grid-span-${columns}` : ''}>
      <Select
        autoFocus={autofocus}
        disabled={disabled}
        value={value}
        onChange={onChange}
        label={label}
        required={required}
        errorText={errorText}
        multiple={multiple}
        options={employees.map(({ id, fullName }) => ({
          label: fullName,
          value: id,
        }))}
        submitted={submitted}
      />
      {showAssignToMe &&
        employees.find((value) => value.id === Session.actingUser.id) && (
          <Button
            color="tertiary"
            onClick={() => onChange(Session.actingUser.id)}
          >
            {translate('common.assignToMe')}
          </Button>
        )}
    </span>
  );
};

export default EmployeeDropdown;
