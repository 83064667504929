import { EventMember } from './EventMember';
import { EventModel } from './EventModel';
import { make } from './Model';

export class EventModelExtended extends EventModel {
  member?: EventMember;

  constructor(obj: any) {
    super(obj);
    this.member = make(obj.member, EventMember);
  }
}

export default EventModelExtended;
