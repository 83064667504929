import classNames from 'classnames';
import React, { useMemo, useState } from 'react';

import InfoIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import {
  Button,
  Card,
  Chip,
  Colors,
  Fonts,
  Panel,
  TextArea,
  Tooltip,
} from 'styleguide-v2';

import {
  ICDCodeItem,
  getICDCodesFromText,
} from 'dash/src/services/ICDCodeServices';

const useStyles = makeStyles({
  button: {
    alignSelf: 'flex-start',
  },
  container: {
    '&&': {
      alignSelf: 'baseline',
      borderRadius: '8px',
      flex: '1 1 32em',
      maxWidth: 'fit-content',
    },

    display: 'flex',

    '& > div': {
      flex: 1,
      padding: '2.4rem 3.2rem',
    },
  },
  subContainer: {
    display: 'flex',
    flexFlow: 'column',
  },
  summary: {
    width: '24%',
  },
  text: {
    flex: '1 1 20rem',
    minWidth: 'calc(100vw - 50rem)',
  },
  title: {
    alignItems: 'center',
    display: 'flex',

    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontWeight: 500,
    },
  },
});

const EXTRA_OFFSET = 15;

export const ICDDetection = () => {
  const styles = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [text, setText] = useState<string>();
  const [icdCodes, setICDCodes] = useState<ICDCodeItem[]>([]);

  const getICDCodes = async () => {
    if (!text) return;

    setLoading(true);
    const items = await getICDCodesFromText(text);
    setICDCodes(items);
    setLoading(false);
  };

  const getColor = (score: number) => {
    if (score < 0.5) return 'error';
    if (score < 0.75) return 'warning';
    return 'secondary';
  };

  const getTextDOM = (text: string, startOffset: number, endOffset: number) => {
    const start = startOffset < EXTRA_OFFSET ? 0 : startOffset - EXTRA_OFFSET;
    const end =
      endOffset + EXTRA_OFFSET > text?.length
        ? text?.length
        : endOffset + EXTRA_OFFSET;

    return (
      <p className="white no-margin">
        {start !== 0 && '...'}
        {text.substring(start, end + 1)}
        {end !== text.length && '...'}
      </p>
    );
  };

  const positiveCodes = useMemo(
    () =>
      icdCodes
        ?.filter((code) => !!code.concepts?.length)
        ?.filter(
          (code) =>
            !code.traits?.find(
              ({ name, score }) => name === 'NEGATION' && score > 0.75,
            ),
        ),
    [icdCodes],
  );

  const negativeCodes = useMemo(
    () =>
      icdCodes
        ?.filter((code) => !!code.concepts?.length)
        ?.filter((code) =>
          code.traits?.find(
            ({ name, score }) => name === 'NEGATION' && score > 0.75,
          ),
        ),
    [icdCodes],
  );

  const renderCard = (code: ICDCodeItem, idx: number) => (
    <Card
      className={classNames('grid-span-4', styles.container)}
      key={`${code.type}-${idx}`}
    >
      <div className={styles.subContainer}>
        <div
          className="flex spaced gap middle"
          style={{ marginBottom: '1rem' }}
        >
          <div className="flex gap middle">
            <span
              className={styles.title}
              style={{ textTransform: 'capitalize' }}
            >
              {code.concepts[0].code}
            </span>
            {code.text && (
              <Tooltip
                text={getTextDOM(
                  `          ${text}` || '',
                  code.beginOffset,
                  code.endOffset,
                )}
              >
                <InfoIcon
                  fontSize="large"
                  htmlColor={Colors.iconGreen}
                  style={{
                    marginTop: '0.125rem',
                    alignSelf: 'flex-start',
                  }}
                />
              </Tooltip>
            )}
          </div>
          <div className="flex gap">
            {code.traits
              .filter(({ name }) => name !== 'NEGATION')
              .map((trait) => (
                <Chip
                  className="capitalize"
                  color="info"
                  type="outlined"
                  key={`concept-${code.type}-trait-${trait.name}-idx-${idx}`}
                >
                  {trait.name.toLocaleLowerCase().replaceAll('_', ' ')}
                </Chip>
              ))}
          </div>
        </div>
        <div className="flex gap wrap">
          {code.concepts
            .sort(({ score: a }, { score: b }) => b - a)
            .map((concept, idx) => (
              <Chip
                color={getColor(concept.score)}
                key={`concept-${concept.code}-idx-${idx}`}
                title={
                  <div style={{ minWidth: '15rem' }}>
                    <p className="white no-margin">{concept.description}</p>
                    <p className="white no-margin flex spaced">
                      <span>{translate('icdDetection.score')}:</span>
                      <span>{(concept.score * 100).toFixed(2)} %</span>
                    </p>
                  </div>
                }
              >
                {concept.code}
              </Chip>
            ))}
        </div>
      </div>
    </Card>
  );

  return (
    <Panel>
      <Panel.Heading title={translate('icdDetection.title')}>
        <Panel.Actions className="grid-span-12 flex gap ">
          <TextArea
            className={styles.text}
            onChange={setText}
            value={text}
            rows={3}
          />
          <Button
            color="secondary"
            disabled={!text}
            type="contained"
            onClick={getICDCodes}
          >
            {translate('icdDetection.getICDCodes')}
          </Button>
        </Panel.Actions>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        {!!positiveCodes?.length && (
          <>
            <div className="flex wrap gap">
              {positiveCodes?.map(renderCard)}
            </div>
            <br />
          </>
        )}
        {!!negativeCodes?.length && (
          <>
            <h2 className={styles.title}>Negative Groups</h2>
            <div className="flex wrap gap">
              {negativeCodes?.map(renderCard)}
            </div>
            <br />
          </>
        )}
        <br />
      </Panel.Body>
    </Panel>
  );
};

export default ICDDetection;
