import classNames from 'classnames';
import { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import ForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  ClinicalMember,
  MemberDiagnostic,
  MemberMedication,
  Patient,
  PatientMedicalEquipment,
} from '@vestahealthcare/common/models';

import {
  BackgroundColors,
  Card,
  Colors,
  Fonts,
  Spinner,
  Tabs,
} from 'styleguide-v2';

import {
  fetchPatientDiagnostics,
  fetchPatientEquipment,
  fetchPatientMedications,
} from 'dash/src/redux/slices/MemberInfoSlice';
import { useSelector } from 'dash/src/redux/store';
import { fetchEquipment } from 'dash/src/services';
import {
  fetchMemberDiagnostics,
  fetchMemberMedications,
} from 'dash/src/services/ClinicalSummarySevices';
import Session from 'dash/src/services/SessionServices';

import { ClinicalSummaryTable } from './ClinicalSummaryTable';

type Props = {
  className?: string;
  member: Patient | ClinicalMember;
};

const useStyles = makeStyles({
  container: {
    '&&': {
      borderRadius: '8px',
    },
  },
  dataContainer: {
    '&&': {
      paddingTop: '1rem',
    },
  },
  subContainer: {
    display: 'flex',
    flexFlow: 'column',
    padding: '2.4rem 3.2rem',
  },
  tabs: {
    padding: '0 3.2rem',
    borderBottom: `1px solid ${BackgroundColors.grayLine}`,
  },
  title: {
    alignItems: 'center',
    display: 'flex',

    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontWeight: 500,
      margin: 0,
    },
  },
  titleContainer: {
    '&&': {
      paddingBottom: '1rem',
    },
  },
});

const CLINICAL_SUMMARY_PROBLEMS = 'clinical-summary-problems';
const CLINICAL_SUMMARY_MEDICATION = 'clinical-summary-medications';
const CLINICAL_SUMMARY_DME = 'clinical-summary-dme';
const TABS = [
  {
    value: CLINICAL_SUMMARY_PROBLEMS,
    label: (
      <>
        &nbsp;{translate('memberProfile.clinicalSummary.tabs.problems')}&nbsp;
      </>
    ),
  },
  {
    value: CLINICAL_SUMMARY_MEDICATION,
    label: (
      <>
        &nbsp;{translate('memberProfile.clinicalSummary.tabs.medications')}
        &nbsp;
      </>
    ),
  },
  {
    value: CLINICAL_SUMMARY_DME,
    label: translate('memberProfile.clinicalSummary.tabs.dme'),
  },
];

export const ClinicalSummaryCard = ({ className, member }: Props) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const shouldLoadData = member instanceof ClinicalMember;
  const shouldShowLink = !Session.actingUser.isExternal;
  const memberId = useMemo(
    () => (member instanceof Patient ? member?.id : member.memberId),
    [member],
  );
  const openNewTab = useMemo(() => !(member instanceof Patient), [member]);

  const [selectedTab, setSelectedTab] = useState<string>(
    CLINICAL_SUMMARY_PROBLEMS,
  );

  const [ccmDMEs, setCCMDMEs] = useState<PatientMedicalEquipment[]>();
  const [ccmMedications, setCCMMedications] = useState<MemberMedication[]>();
  const [ccmProblems, setCCMProblems] = useState<MemberDiagnostic[]>();
  const [ccmUpdateAt, setCCMUpdateAt] = useState<Moment>();

  const dmes = shouldLoadData
    ? ccmDMEs
    : useSelector((state) => state.memberInfoSlice.equipment);
  const medications = shouldLoadData
    ? ccmMedications
    : useSelector((state) =>
        state.memberInfoSlice.medications?.items
          ? [
              ...state.memberInfoSlice.medications.items,
            ].sort(({ name: a }, { name: b }) => a.localeCompare(b))
          : undefined,
      );
  const problems = shouldLoadData
    ? ccmProblems
    : useSelector((state) =>
        state.memberInfoSlice.diagnostics?.items
          ? [...state.memberInfoSlice.diagnostics.items].sort(
              (
                { status: as, onsetDate: ao },
                { status: bs, onsetDate: bo },
              ) => {
                if (as !== bs) {
                  if (!as && !bs) return 0;
                  if (as === MemberDiagnostic.ACTIVE_STATUS) return -1;
                  if (bs === MemberDiagnostic.ACTIVE_STATUS) return 1;
                  return as ? 1 : -1;
                }
                if (!ao && !bo) return 0;
                if (!ao) return 1;
                if (!bo) return -1;
                return ao.isAfter(bo) ? -1 : 1;
              },
            )
          : undefined,
      );
  const updatedAtECW = shouldLoadData
    ? ccmUpdateAt
    : useSelector((state) => state.memberInfoSlice.updatedAtECW);

  useEffect(() => {
    if (shouldLoadData) {
      if (selectedTab === CLINICAL_SUMMARY_MEDICATION && !medications)
        fetchMemberMedications(member.memberId).then(({ items, updatedAt }) => {
          setCCMMedications(items);
          setCCMUpdateAt(updatedAt);
        });
      if (selectedTab === CLINICAL_SUMMARY_PROBLEMS && !problems)
        fetchMemberDiagnostics(member.memberId).then(({ items, updatedAt }) => {
          setCCMProblems(items);
          setCCMUpdateAt(updatedAt);
        });
      if (selectedTab === CLINICAL_SUMMARY_DME && !dmes)
        fetchEquipment(member.memberId).then((items) => {
          setCCMDMEs(items);
        });
    } else {
      if (selectedTab === CLINICAL_SUMMARY_MEDICATION && !medications)
        dispatch(fetchPatientMedications(member.id));
      if (selectedTab === CLINICAL_SUMMARY_PROBLEMS && !problems)
        dispatch(fetchPatientDiagnostics(member.id));
      if (selectedTab === CLINICAL_SUMMARY_DME && !dmes)
        dispatch(fetchPatientEquipment(member.id));
    }
  }, [selectedTab]);

  useEffect(() => {
    setCCMDMEs(undefined);
    setCCMMedications(undefined);
    setCCMProblems(undefined);
    setCCMUpdateAt(undefined);
  }, [member]);

  const getData = () => {
    if (selectedTab === CLINICAL_SUMMARY_PROBLEMS) {
      return (problems || [])?.map((item) => ({
        label: `${item.code}: ${item.name}`,
      }));
    }
    if (selectedTab === CLINICAL_SUMMARY_MEDICATION) {
      return (medications || [])?.map((item) => ({
        label: item.name,
        tooltip: item.directions,
      }));
    }
    if (selectedTab === CLINICAL_SUMMARY_DME) {
      return (dmes || [])
        ?.filter(({ discontinueDate }) => !discontinueDate)
        ?.map((item) => ({
          label: item.description,
        }));
    }
    return [];
  };

  const getLoading = () =>
    (selectedTab === CLINICAL_SUMMARY_MEDICATION && !medications) ||
    (selectedTab === CLINICAL_SUMMARY_PROBLEMS && !problems) ||
    (selectedTab === CLINICAL_SUMMARY_DME && !dmes);

  return (
    <Card className={classNames(className, styles.container)}>
      <div className={classNames(styles.subContainer, styles.titleContainer)}>
        <div className="flex spaced">
          <span className={styles.title}>
            {translate('memberProfile.dashboard.clinicalSummary')}
          </span>
          {shouldShowLink && (
            <a
              className="flex middle min-gap"
              href={`#/patients/${memberId}/clinical-summary`}
              style={{ color: Colors.textGreen, fontWeight: 500 }}
              rel="noreferrer"
              target={openNewTab ? '_blank' : undefined}
            >
              {translate('memberProfile.dashboard.goToClinicalSummary')}
              <ForwardIcon fontSize="small" />
            </a>
          )}
        </div>
      </div>
      <Tabs
        className={styles.tabs}
        items={TABS}
        onChange={setSelectedTab}
        value={selectedTab}
      />
      <div className={classNames(styles.dataContainer, styles.subContainer)}>
        {getLoading() ? (
          <div className="flex middle center">
            <Spinner width={24} />
          </div>
        ) : (
          <ClinicalSummaryTable
            data={getData()}
            selectedTab={selectedTab}
            showTooltip={selectedTab === CLINICAL_SUMMARY_MEDICATION}
            updatedAt={
              selectedTab !== CLINICAL_SUMMARY_DME
                ? updatedAtECW?.calendar()
                : undefined
            }
          />
        )}
      </div>
    </Card>
  );
};
