import React from 'react';
import { DotProps } from 'recharts';
import { BiometricsConfig } from '@vestahealthcare/common/models';
import { Colors } from 'styleguide-v2';

export const BiometricsDot = ({
  cx = 0,
  cy = 0,
  color,
  config,
  // @ts-ignore: value prop it's not included in definition
  value,
}: DotProps & { color: string; config: BiometricsConfig }) => {
  const { upperLimit, lowerLimit } = config;

  if (
    (upperLimit && value > upperLimit) ||
    (lowerLimit && value < lowerLimit)
  ) {
    return (
      <svg
        x={cx}
        y={cy - 10}
        width={50}
        height={50}
        fill="white"
        viewBox="0 0 496 300"
      >
        <circle cx="0" cy="0" r="30" stroke={Colors.orange} strokeWidth="25" />
      </svg>
    );
  }

  return (
    <svg
      x={cx}
      y={cy - 10}
      width={50}
      height={50}
      fill="white"
      viewBox="0 0 496 300"
    >
      <circle cx="0" cy="0" r="30" stroke={color} strokeWidth="25" />
    </svg>
  );
};
