import classNames from 'classnames';
import React, { MouseEvent, MouseEventHandler, ReactNode } from 'react';

import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { Spinner } from 'styleguide-v2/src/components/Spinner';
import { Tooltip } from 'styleguide-v2/src/components/Tooltip';
import { BackgroundColors, Colors } from 'styleguide-v2/src/styles/Colors';

import { Fonts } from '../../styles/Variables';

type StyleProps = {
  bkgColor: string;
  bkgColorActive?: string;
  bkgColorHover?: string;
  border?: boolean;
  color: string;
  colorActive?: string;
  colorHover?: string;
};

const useStyles = makeStyles<Theme, StyleProps>({
  button: {
    alignItems: 'center',
    background: ({ bkgColor }) => bkgColor,
    border: ({ border, color, bkgColor }) =>
      border ? `1px solid ${color}` : `1px solid ${bkgColor}`,
    color: ({ color }) => color,
    borderRadius: '.75rem',
    height: 'fit-content',
    outline: 0,
    padding: '1rem 1rem',
    transition: 'background 200ms',
    '&:enabled': {
      '&:hover': {
        background: ({ bkgColor, bkgColorHover }: StyleProps) =>
          bkgColorHover || bkgColor,
        borderColor: ({ bkgColor, colorHover }: StyleProps) =>
          colorHover || bkgColor,
        color: ({ color, colorHover }: StyleProps) => colorHover || color,
      },
      '&:active': {
        background: ({ bkgColor, bkgColorActive }: StyleProps) =>
          bkgColorActive || bkgColor,
        borderColor: ({ color, colorActive }: StyleProps) =>
          colorActive || color,
        color: ({ color, colorActive }: StyleProps) => colorActive || color,
      },
    },
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.24,
    },
  },
  icon: {
    color: 'inherit',
  },
  span: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  small: {
    '&&': {
      height: '3.2rem',
      padding: '0.4rem 0.8rem',
      fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    },
  },

  extraSmall: {
    '&&': {
      fontSize: '0.875em',
      padding: '0.2rem 0.5rem',
    },
  },
});

interface Props
  extends Omit<
    React.HTMLProps<HTMLAnchorElement | HTMLButtonElement>,
    'disabled' | 'size'
  > {
  color?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'info';
  'data-cy'?: string;
  disabled?: boolean | string;
  href?: string;
  icon?: ReactNode | string;
  loading?: boolean;
  onClick?: MouseEventHandler;
  tooltip?: string;
  type?: 'contained' | 'outlined';
  size?: 'xs' | 's' | 'm' | 'small' | 'medium';
}

export const Button = (props: Props) => {
  const {
    'data-cy': dataCy,
    children,
    className,
    color = 'primary',
    disabled,
    href,
    icon,
    loading,
    onClick,
    tooltip,
    type = 'contained',
    size = 'medium',
  } = props;

  const getColors = () => {
    if (type === 'contained') {
      if (color === 'primary') {
        return {
          bkgColor: Colors.darkOrange,
          bkgColorHover: Colors.darkerOrange,
          color: Colors.white,
        };
      }
      if (color === 'secondary') {
        return {
          bkgColor: Colors.darkGreen,
          bkgColorHover: BackgroundColors.lightGreen,
          color: Colors.white,
        };
      }
      if (color === 'quaternary') {
        return {
          bkgColor: 'transparent',
          color: Colors.blue,
        };
      }
      if (color === 'info') {
        return {
          bkgColor: Colors.blue,
          bkgColorHover: BackgroundColors.lightBlue,
          color: Colors.white,
        };
      }
      return {
        bkgColor: Colors.white,
        color: Colors.gray,
        colorActive: Colors.darkGreen,
        colorHover: Colors.gray,
      };
    }
    if (color === 'primary') {
      return {
        bkgColor: Colors.white,
        bkgColorHover: Colors.paleOrange,
        border: true,
        color: Colors.darkOrange,
        colorHover: Colors.darkerOrange,
      };
    }
    if (color === 'secondary') {
      return {
        bkgColor: Colors.white,
        bkgColorHover: Colors.paleGreen,
        border: true,
        color: Colors.textGreen,
        colorHover: Colors.textLightGreen,
      };
    }
    if (color === 'quaternary') {
      return {
        bkgColor: 'transparent',
        bkgColorHover: 'transparent',
        color: Colors.blue,
      };
    }
    if (color === 'info') {
      return {
        bkgColor: 'transparent',
        border: true,
        borderColor: Colors.blue,
        color: Colors.blue,
      };
    }
    return {
      bkgColor: Colors.white,
      border: true,
      color: Colors.gray,
      colorActive: Colors.darkGreen,
      colorHover: Colors.textLightGreen,
    };
  };
  const styles = useStyles(getColors());
  const classes = classNames(
    'mbtn',
    className,
    !href && styles.button,
    ['s', 'small'].includes(size) && styles.small,
    size === 'xs' && styles.extraSmall,
  );

  const handleClick = (evt: MouseEvent) => {
    if (!loading && !disabled && onClick) {
      onClick(evt);
    }
  };

  const getIcon = () =>
    typeof icon === 'string' ? (
      <i className={`fa fa-${icon} ${styles.icon}`} />
    ) : (
      icon
    );

  const renderButton = () =>
    href ? (
      <a
        className={classNames(className, classes)}
        data-cy={dataCy}
        href={href}
        type="button"
        onClick={(e) => {
          if (handleClick) {
            e?.preventDefault();
            handleClick(e);
          }
        }}
      >
        {children}
      </a>
    ) : (
      <button
        type="button"
        data-cy={dataCy}
        disabled={!!disabled}
        className={classes}
        onClick={handleClick}
      >
        <span className={classNames(styles.span, 'semi-bold')}>
          {loading ? (
            <Spinner className={styles.spinner} width={22.5} />
          ) : (
            <>
              {getIcon()}
              {getIcon() && children && <>&nbsp;</>}
              {children}
            </>
          )}
        </span>
      </button>
    );

  return tooltip || typeof disabled === 'string' ? (
    <Tooltip text={(tooltip || disabled) as string} followCursor={!!disabled}>
      <div style={{ width: 'fit-content' }}>{renderButton()}</div>
    </Tooltip>
  ) : (
    renderButton()
  );
};

export default Button;
