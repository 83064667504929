import React from 'react';

import { translate } from '@vestahealthcare/common/i18n';

import { Button, Modal } from 'styleguide';

interface Props {
  loading?: boolean;
  open: boolean;
  onCancel: () => void;
  onSubmit: () => Promise<boolean>;
}

export const ClearConsentConfirmModal = ({
  loading,
  open,
  onCancel,
  onSubmit,
}: Props) => (
  <Modal onHide={onCancel} show={open}>
    <Modal.Header
      closeButton
      modalTitle={translate('memberConsents.clearConsentTitle')}
    />
    <Modal.Body className="grid-span-12" data-cy="clear-consent-modal-body">
      {translate('memberConsents.clearConsentBody')}
    </Modal.Body>
    <Modal.Footer
      leftSide={
        <Button data-cy="cancel-button" color="tertiary" onClick={onCancel}>
          {translate('global.cancel')}
        </Button>
      }
      rightSide={
        onSubmit && (
          <Button
            data-cy="save-button"
            color="primary"
            loading={loading}
            onClick={onSubmit}
          >
            {translate('global.confirm')}
          </Button>
        )
      }
    />
  </Modal>
);

export default ClearConsentConfirmModal;
