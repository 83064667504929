import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { makeStyles } from '@mui/styles';

import { Colors, Fonts } from 'styleguide-v2';

export type Data = {
  className?: string;
  label: string;
  value?: string;
  subvalue?: string | ReactNode;
  subvalueClassName?: string;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
    gap: '0.25rem',
  },
  label: {
    color: Colors.iconGray,
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: `calc(${Fonts.fontSize} * 0.625)`,
    letterSpacing: '1px',
    marginBottom: '0.25rem',
  },
  value: {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    fontWeight: 500,
  },
  subvalue: {
    color: Colors.iconGray,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
  },
});

export const MemberWarningsData = ({
  className,
  label,
  value,
  subvalue,
  subvalueClassName,
}: Data) => {
  const styles = useStyles();

  return (
    <div className={classNames(className, styles.container)}>
      <span className={styles.label}>{label}</span>
      <span className={styles.value}>{value}</span>
      <span className={classNames(styles.subvalue, subvalueClassName)}>
        {subvalue}
      </span>
    </div>
  );
};

export default MemberWarningsData;
