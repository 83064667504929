import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from '@vestahealthcare/common/i18n';
import { Modal, Button } from 'styleguide';
import {
  getConflictingDataWarningMessage,
  closeConflictingDataWarning,
} from 'dash/src/redux/actions/GlobalActions';

interface StateProps {
  conflictingDataWarningMessage: string | null;
}

interface DispatchProps {
  cancel: () => void;
}

const mapStateToProps = (state: any): StateProps => ({
  conflictingDataWarningMessage: getConflictingDataWarningMessage(state),
});

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  cancel: () => dispatch(closeConflictingDataWarning()),
});

class ConflictingDataWarning extends Component<StateProps & DispatchProps> {
  doCancel() {
    const { cancel } = this.props;
    cancel && cancel();
  }

  doReload() {
    // Todo: improve this
    window.location.reload();
  }

  render() {
    const { conflictingDataWarningMessage } = this.props;

    return (
      <Modal
        show={!!conflictingDataWarningMessage}
        color="warning"
        onHide={() => this.doCancel()}
      >
        <Modal.Header
          closeButton
          modalTitle={
            <>
              <i className="fa fa-warning" />{' '}
              {translate('global.changesCannotBeSubmitted')}
            </>
          }
        />
        <Modal.Body>
          <p>
            {conflictingDataWarningMessage}{' '}
            {translate('global.refreshToSeeChanges')}
          </p>
        </Modal.Body>
        <Modal.Footer
          leftSide={
            <Button color="tertiary" onClick={() => this.doCancel()}>
              {translate('global.cancel')}
            </Button>
          }
          rightSide={
            <Button color="primary" onClick={() => this.doReload()}>
              {translate('global.refresh')}
            </Button>
          }
        />
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConflictingDataWarning);
