import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { EventModel, Patient } from '@vestahealthcare/common/models';
import EventLinkReason from '@vestahealthcare/common/models/EventLinkReason';

import {
  Button,
  CollapsableSidebar,
  PanelInfo,
  Select,
  SelectEventOption,
} from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import {
  GetMemberParams,
  LinkEventParams,
  fetchMemberEvents,
} from 'dash/src/services/EventServices';

interface Props {
  event?: EventModel;
  open: boolean;
  onClose: () => void;
  onSubmit: (params: LinkEventParams) => Promise<boolean>;
  patient?: Patient;
}

export const MemberEventLinkModal = ({
  event: currentEvent,
  open,
  onClose,
  onSubmit,
  patient,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [reason, setReason] = useState<EventLinkReason>();
  const [reasons, setReasons] = useState<EventLinkReason[]>([]);
  const [event, setEvent] = useState<EventModel>();
  const [events, setEvents] = useState<EventModel[]>([]);

  const reset = async () => {
    setSubmitted(false);
    setLoading(false);
    setReason(undefined);
    setEvent(undefined);

    if (patient) {
      setLoadingModal(true);
      const { items } = await fetchMemberEvents(patient?.id, {
        from: moment().subtract(3, 'month'),
        to: moment(),
        limit: 100,
      } as GetMemberParams);
      setEvents(items.filter(({ id }) => id !== currentEvent?.id));
      setLoadingModal(false);
    }

    const reasons = await CacheServices.getEventLinkReasons();
    setReasons(reasons);
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  const doSubmit = async () => {
    setSubmitted(true);

    if (currentEvent && event && reason) {
      setLoading(true);
      try {
        const result = await onSubmit({
          sourceEventId: currentEvent,
          targetEventId: event,
          reasonId: reason,
        });
        if (result) {
          onClose();
        }
      } finally {
        setSubmitted(false);
        setLoading(false);
      }
    }
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={<h2>{translate('memberEvents.modals.linkEventTitle')}</h2>}
      size={400}
    >
      <CollapsableSidebar.Body>
        <PanelInfo
          title={translate('memberEvents.modals.infoLinkEvent', {
            event: currentEvent?.type.name,
          })}
          type="info"
        />
        <Select
          error={submitted && !reason}
          getItemLabel={({ reason }: EventLinkReason) => reason}
          items={reasons}
          label={translate('memberEvents.detail.relationship')}
          loading={loadingModal}
          onChange={setReason}
          required
          value={reason}
        />
        <Select
          error={submitted && !event}
          getItemLabel={({ type, status }: EventModel) =>
            `${type.name} (${status.description})`
          }
          renderOption={(event: EventModel) => (
            <SelectEventOption event={event} />
          )}
          items={events}
          label={translate('memberEvents.detail.events')}
          loading={loadingModal}
          onChange={setEvent}
          required
          value={event}
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button color="tertiary" data-cy="edit-event-close" onClick={onClose}>
          {translate('global.cancel')}
        </Button>
        <Button
          color="secondary"
          disabled={!event || !reason}
          data-cy="edit-event-note-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.link')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default MemberEventLinkModal;
