import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Panel } from 'styleguide';

import Session from 'dash/src/services/SessionServices';

import HomeSidebar from './HomeSidebar';

export const Home = (props: RouteComponentProps) => {
  const { firstName, homepage, isExternal } = Session.actingUser;
  if (homepage !== '/') {
    props.history.push(homepage);
  }

  return (
    <div id="page-wrapper">
      {!isExternal && <HomeSidebar />}
      <div id="page">
        <Panel>
          <Panel.Heading>
            <h2>Welcome to Dash for Vesta, {firstName}</h2>
          </Panel.Heading>
        </Panel>
      </div>
    </div>
  );
};

export default Home;
