import React, { useState, useEffect } from 'react';
import { CareTrack } from '@vestahealthcare/common/models';
import CareTrackSection from './CareTrackSection';
import { CareTrackType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { CollapseIcon } from 'styleguide';

type Params = {
  patientId: string;
};

interface TitleProps extends RouteComponentProps<Params> {
  collapsed?: boolean;
  setCollapsed: (collapsed: boolean) => void;
  title: string;
}

const CollapsibleTitle = withRouter((props: TitleProps) => (
  <div
    className="clickable h7 flex"
    onClick={() => {
      // This prevents the autoscroll from being immediately applied
      // every time the collapse changes
      if (!props.collapsed) {
        props.history.push(
          `/patients/${props.match.params.patientId}/phip-profile`,
        );
      }
      props.setCollapsed(!props.collapsed);
    }}
  >
    <CollapseIcon active={!props.collapsed} className="margin-right" />
    {props.title}
  </div>
));

interface CategoryProps {
  collapsed?: boolean;
  setCollapsed?: (collapsed: boolean) => void;
  onSubmit: Function;
  careTracks: CareTrack[];
  title: string;
}

export const CareTrackCategory = (props: CategoryProps) => {
  const { onSubmit, careTracks, collapsed, setCollapsed } = props;

  if (!careTracks.length) {
    return <></>;
  }

  return (
    <article data-cy={`care-track-${props.title}`}>
      <header>
        {setCollapsed ? (
          <CollapsibleTitle
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            title={props.title}
          />
        ) : (
          <div className="h7">{props.title}</div>
        )}
      </header>
      {!collapsed &&
        careTracks
          .sort((risk1, risk2) => risk1.order - risk2.order)
          .map((careTrack) => (
            <CareTrackSection
              onSubmit={onSubmit}
              careTrack={careTrack}
              key={careTrack.type.value}
            />
          ))}
    </article>
  );
};

interface CategoriesProps {
  careTracks: CareTrack[];
  onSubmit: Function;
  focus?: string;
}

export const CareTrackCategories = (props: CategoriesProps) => {
  const { focus, careTracks, onSubmit } = props;

  const visibleCareTracks = careTracks.filter((track) => !track.hidden);

  const focusedTrack = focus
    ? CareTrackType.byKey[focus.toUpperCase()]
    : undefined;
  const unevaluated = visibleCareTracks.filter(
    (careTrack) => !careTrack.isEvaluated,
  );
  const active = visibleCareTracks.filter((careTrack) => careTrack.isActive);
  const inactive = visibleCareTracks.filter(
    (careTrack) => careTrack.isEvaluated && !careTrack.isActive,
  );
  const [isInactiveCollapsed, setInactiveCollapsed] = useState(
    !focusedTrack ||
      !inactive.map((careTrack) => careTrack.type).includes(focusedTrack),
  );

  useEffect(() => {
    if (
      isInactiveCollapsed &&
      focusedTrack &&
      inactive.map((careTrack) => careTrack.type).includes(focusedTrack)
    ) {
      setInactiveCollapsed(false);
    }
  }, [focus, careTracks]);

  return (
    <>
      <CareTrackCategory
        onSubmit={onSubmit}
        careTracks={unevaluated}
        title={translate('riskProfile.unevaluated')}
      />
      <CareTrackCategory
        onSubmit={onSubmit}
        careTracks={active}
        title={translate('riskProfile.activePathways')}
      />
      <CareTrackCategory
        onSubmit={onSubmit}
        collapsed={isInactiveCollapsed}
        setCollapsed={setInactiveCollapsed}
        careTracks={inactive}
        title={translate('riskProfile.inactivePathways')}
      />
    </>
  );
};

export default CareTrackCategories;
