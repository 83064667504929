import { BaseEnum } from './BaseEnum';

export class SourceChannel extends BaseEnum {
  manual = false;

  constructor(obj: any) {
    super(obj);
    Object.assign(this, obj);
  }

  isOther() {
    return this.id === 'OTHER';
  }

  get isManual() {
    return this.manual !== false;
  }

  static PING = 'PING';
}

export default SourceChannel;
