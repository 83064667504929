import moment from 'moment';

import { Brand, MemberStatus } from '../enums';
import { DATE_FORMAT_SHORT, EMPTY } from '../utils/constants';
import {
  PatientBrandHelper,
  hasBrandCareAtHome,
} from './Helpers/PatientBrandHelper';
import { MemberReferral } from './MemberReferral';
import { make } from './Model';

export class PatientBase implements PatientBrandHelper {
  activeReferrals?: MemberReferral[];

  birthDate?: string;

  brand: Brand;

  fullName = '';

  id = 0;

  status?: MemberStatus;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.activeReferrals = obj.activeReferrals?.map((o: any) =>
      make(o, MemberReferral),
    );
    this.brand = Brand.byKey[obj.brand];
    this.status = MemberStatus.byKey[obj.status];
  }

  get birthDateFormatted() {
    return this.birthDate && moment(this.birthDate).format(DATE_FORMAT_SHORT);
  }

  get nameWithId() {
    return `${this.fullName} (${this.id})`;
  }

  get referralsNames() {
    return (
      this.activeReferrals
        ?.map((ref) => ref.organization.abbr?.toUpperCase())
        .join(', ') || EMPTY
    );
  }

  isBrandCareAtHome(): boolean {
    return hasBrandCareAtHome(this);
  }
}

export default PatientBase;
