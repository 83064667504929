import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  EventInterventionType,
} from '@vestahealthcare/common/models';

import { Button, CollapsableSidebar, MChip, Select } from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import { CreateEventInterventionParams } from 'dash/src/services/EventServices';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (params: CreateEventInterventionParams) => Promise<boolean>;
}

const INTERVENTION_CATEGORY_OTHER = 'OTHER';

export const MemberEvaluationAddIntervention = ({
  open,
  onClose,
  onSubmit,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [interventionCategories, setInterventionCategories] = useState<
    BaseEnum[]
  >([]);
  const [interventionCategory, setInterventionCategory] = useState<BaseEnum>();
  const [interventionTypes, setInterventionTypes] = useState<
    EventInterventionType[]
  >([]);
  const [interventionType, setInterventionType] = useState<
    EventInterventionType[]
  >([]);
  const [interventionTypeOthers, setInterventionTypeOthers] = useState<
    EventInterventionType[]
  >([]);

  const getInitialData = async () => {
    const interventionTypes = await CacheServices.getEventInterventionTypes();
    setInterventionTypes(
      interventionTypes.sort(({ id: a }, { id: b }) => {
        const aPreferred =
          EventInterventionType.getPreferred().indexOf(a) !== -1;
        const bPreferred =
          EventInterventionType.getPreferred().indexOf(b) !== -1;
        if (aPreferred === bPreferred) return 0;
        if (aPreferred) return -1;
        return 1;
      }),
    );
    const categories: BaseEnum[] = Object.values(
      interventionTypes.reduce(
        (
          acc: { [category: string]: BaseEnum },
          { category }: EventInterventionType,
        ) => {
          acc[category.id] = category;
          return acc;
        },
        {} as { [category: string]: BaseEnum },
      ),
    );
    setInterventionCategories(
      categories.sort(({ id: a }, { id: b }) => {
        if (a === INTERVENTION_CATEGORY_OTHER) return 1;
        if (b === INTERVENTION_CATEGORY_OTHER) return -1;
        return (a as string).localeCompare(b as string);
      }),
    );
    setInterventionCategory(categories[0]);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const reset = () => {
    setSubmitted(false);
    setLoading(false);
    setInterventionCategory(interventionCategories[0]);
    setInterventionType([]);
    setInterventionTypeOthers([]);
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setInterventionTypeOthers([
        ...(interventionTypeOthers || []),
        ...(interventionType || []),
      ]);
      setInterventionType([]);
    }
  }, [interventionCategory]);

  const doSubmit = async () => {
    setSubmitted(true);

    if (interventionType?.length) {
      setLoading(true);
      try {
        const result = await onSubmit({
          typeIds: [
            ...(interventionTypeOthers || []),
            ...(interventionType || []),
          ],
        });
        if (result) {
          onClose();
        }
      } finally {
        setSubmitted(false);
        setLoading(false);
      }
    }
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={<h2>{translate('memberEvents.addInterventionModalTitle')}</h2>}
      size={550}
    >
      <CollapsableSidebar.Body>
        <div className="grid-wrapper fit">
          <Select
            className="grid-span-12"
            disableClearable
            getItemLabel={({ description }: BaseEnum) => description}
            items={interventionCategories}
            label={translate('memberEvents.categoryType')}
            onChange={setInterventionCategory}
            value={interventionCategory}
          />
          <Select
            className="grid-span-12"
            error={submitted && !interventionType?.length}
            getItemLabel={({ description }: EventInterventionType) =>
              description
            }
            filterOptions={(data, options) =>
              data.filter(({ description }) =>
                description
                  .toLocaleLowerCase()
                  .includes(options.inputValue?.toLocaleLowerCase()),
              )
            }
            items={interventionTypes.filter(
              (item) =>
                item?.category?.id === interventionCategory?.id && item.active,
            )}
            label={interventionCategory?.description}
            limitTags={1}
            multiple
            onChange={setInterventionType}
            required
            value={interventionType}
          />
          <div className="grid-span-12 flex gap wrap">
            {interventionTypeOthers?.map((it, index) => (
              <MChip
                key={`issue-type-${it?.id}`}
                label={it?.description}
                onDelete={() => {
                  interventionTypeOthers?.splice(index, 1);
                  setInterventionTypeOthers([...interventionTypeOthers]);
                }}
                color="primary"
              />
            ))}
          </div>
        </div>
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button color="tertiary" data-cy="edit-event-close" onClick={onClose}>
          {translate('global.cancel')}
        </Button>
        <Button
          color="secondary"
          data-cy="edit-event-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.add')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default MemberEvaluationAddIntervention;
