import React, { useState } from 'react';
import { Button, Modal, Panel, Toast, ButtonModal } from 'styleguide';

const StyleguideModals = () => {
  const [showModal, setShowModal] = useState('');

  return (
    <Panel>
      <Panel.Heading>
        <h2>Modals</h2>
      </Panel.Heading>
      <Panel.Body className="grid-wrapper">
        <h3 className="grid-span-2">Modals</h3>
        <div className="grid-span-3">
          <Button color="secondary" onClick={() => setShowModal('default')}>
            Default Modal
          </Button>
        </div>

        <div className="grid-span-3">
          <Button color="secondary" onClick={() => setShowModal('warning')}>
            Warning Modal
          </Button>
        </div>

        <div className="grid-span-3">
          <Button color="secondary" onClick={() => setShowModal('error')}>
            Error Modal
          </Button>
        </div>

        <div className="grid-span-2" />

        <div className="grid-span-3">
          <ButtonModal
            modalTitle="Button Modal"
            buttonText="Button Modal"
            onSubmit={() => {
              /* do something on submit */
            }}
          >
            This modal will stay open on submit. 😀
          </ButtonModal>
        </div>

        <div className="grid-span-3">
          <ButtonModal
            modalTitle="Button Modal"
            buttonText="Button Modal"
            onSubmit={() => /* do something on submit */ true}
          >
            This modal will close on submit 😀
          </ButtonModal>
        </div>

        <div className="grid-span-3">
          <ButtonModal
            iconType="edit"
            modalTitle="Button Modal"
            buttonText="Button Modal"
            onSubmit={() => {
              /* do something on submit */
            }}
          >
            This modal will stay open on submit. 😀
          </ButtonModal>
        </div>

        <div className="grid-span-1" />

        <h3 className="grid-span-2">Toasts</h3>
        <div className="grid-span-5">
          <Button
            color="secondary"
            onClick={() => {
              new Toast({
                title: 'H4 Example Title',
                body: 'P1 Lorem ipsum dolor sit amet',
              });
            }}
          >
            Toast w/ Title & Body
          </Button>
        </div>
        <div className="grid-span-5">
          <Button
            color="secondary"
            onClick={() => {
              new Toast({
                title: 'H4 Example Title',
              });
            }}
          >
            Toast w/ Title Only
          </Button>
        </div>
      </Panel.Body>
      <Modal
        color={showModal}
        show={!!showModal}
        onHide={() => setShowModal('')}
      >
        <Modal.Header closeButton modalTitle="Modal Title" />
        <Modal.Body>
          <p className="large">
            P3 Inform the user of a system action or positive/neutral outcome
            lorem ipsum quiso que dolor sit amet quanticum contenticum.
          </p>
          <p>
            P1 Inform the user of a system action or positive/neutral outcome
            lorem ipsum quiso que dolor sit amet quanticum contenticum.
          </p>
          <p className="small">
            P2 Inform the user of a system action or positive/neutral outcome
            lorem ipsum quiso que dolor sit amet quanticum contenticum.
          </p>
          <p className="italic">
            P1 Inform the user of a system action or positive/neutral outcome
            lorem ipsum quiso que dolor sit amet quanticum contenticum.
          </p>
          <p className="medium">
            P1 Inform the user of a system action or positive/neutral outcome
            lorem ipsum quiso que dolor sit amet quanticum contenticum.
          </p>
        </Modal.Body>
        <Modal.Footer
          leftSide={<Button color="tertiary">Tertiary</Button>}
          rightSide={
            <div>
              <Button color="secondary">Secondary</Button>
              <Button color="primary">Primary</Button>
            </div>
          }
        />
      </Modal>
    </Panel>
  );
};

export default StyleguideModals;
