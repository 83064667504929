import classNames from 'classnames';
import React from 'react';

import { TextareaAutosize } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Colors } from 'styleguide-v2/src/styles/Colors';
import { Fonts } from 'styleguide-v2/src/styles/Variables';

import { ErrorLabel } from '../ErrorLabel';
import { Label } from '../Label';

type Props = {
  className?: string;
  'data-cy'?: string;
  disabled?: boolean;
  error?: string | boolean;
  label?: string;
  maxLength?: number;
  minRows?: number;
  maxRows?: number;
  note?: string;
  onChange?: (value?: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  rows?: number;
  value?: string;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  note: {
    color: Colors.iconGray,
    fontStyle: 'italic',
    marginRight: '0.25rem',
  },
  noteContainer: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    marginTop: '.4rem',
  },
  input: {
    borderColor: Colors.gray,
    borderRadius: '.5rem',
    fontSize: `calc(${Fonts.fontSize} * 1.125)`,
    padding: '1rem 1.5rem',

    '&:focus-visible': {
      outline: `${Colors.green} auto 1px`,
    },

    '&[disabled]': {
      cursor: 'not-allowed',
    },
  },
  error: {
    borderColor: 'red',
  },
  readOnly: {
    whiteSpace: 'pre-wrap',
  },
});

export const TextArea = ({
  className,
  'data-cy': dataCy,
  disabled,
  error,
  label,
  maxLength,
  minRows,
  maxRows,
  note,
  onChange,
  placeholder,
  readOnly,
  required,
  rows,
  value,
}: Props) => {
  const styles = useStyles();
  const classes = classNames(className, styles.container);

  return (
    <div className={classes} data-cy={dataCy}>
      {label && (
        <Label error={error} required={required}>
          {label}
        </Label>
      )}
      {readOnly ? (
        <span>{value}</span>
      ) : (
        <TextareaAutosize
          className={classNames(styles.input, error && styles.error)}
          disabled={disabled}
          value={value}
          maxLength={maxLength}
          minRows={minRows || rows}
          maxRows={maxRows || rows}
          onChange={(evt) => {
            const inputValue = evt?.target?.value;
            if (onChange) {
              onChange(inputValue);
            }
          }}
          placeholder={placeholder}
        />
      )}
      {(note || required || typeof error === 'string') && !readOnly && (
        <div className={classNames('flex spaced', styles.noteContainer)}>
          <ErrorLabel error={error} required={required} />
          {note && <span className={styles.note}>{note}</span>}
        </div>
      )}
    </div>
  );
};

export default TextArea;
