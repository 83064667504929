import moment from 'moment';

import {
  INCIDENTS_RELEASE,
  SERVER_DATE_FORMAT_ZONED,
  TOC_RELEASE,
} from '../utils/constants';
import { BaseEnum } from './BaseEnum';
import { Employee } from './Employee';
import { Encounter } from './Encounter';
import { EventEvaluation } from './EventEvaluation';
import { EventIssue } from './EventIssue';
import { EventLink } from './EventLink';
import { EventModelExtended } from './EventModelExtended';
import { EventNote } from './EventNote';
import { EventStatus } from './EventStatus';
import { EventType } from './EventType';
import { CreateIncidentParams } from './IncidentParams';
import { IncidentType } from './IncidentType';
import { IssuePrompt } from './IssuePrompt';
import { IssueType } from './IssueType';
import { make, makeArray } from './Model';
import { SourceChannel } from './SourceChannel';
import { Task } from './Task';

const getIncidentFallData = (
  event: EventDetail,
): Partial<CreateIncidentParams> | undefined => {
  const issueFall = [...event.issues]
    .reverse()
    .find(({ issueType }) => issueType.id === IssueType.FALL);
  const anticoagulant = issueFall?.details?.find(
    ({ isFallAnticoagulantField }) => isFallAnticoagulantField,
  );
  const anticoagulantIds = anticoagulant?.value?.split(',');
  const dme = issueFall?.details?.find(({ isFallDMEField }) => isFallDMEField);
  const dmeType = issueFall?.details?.find(
    ({ isFallDMETypeField }) => isFallDMETypeField,
  );
  const dmeTypeIds = dmeType?.value?.split(',');
  const evaluated = issueFall?.details?.find(
    ({ isFallEvaluatedField }) => isFallEvaluatedField,
  );
  const fallDate = issueFall?.details?.find(
    ({ isFallDateField }) => isFallDateField,
  );

  return {
    description: event.chiefComplaint,
    incidentDate: fallDate?.value ? moment(fallDate.value).toDate() : undefined,
    reportedDate: moment.unix(event.createdAt).toDate(),
    fall: {
      anticoagulantIds: dme?.isFallDME
        ? anticoagulant?.issueDetailField?.options?.filter(({ id }) =>
            anticoagulantIds?.includes(id),
          )
        : undefined,
      dme: dme?.isFallDME,
      dmeTypes: dme?.isFallDME
        ? dmeType?.issueDetailField?.options?.filter(({ id }) =>
            dmeTypeIds?.includes(id),
          )
        : undefined,
      evaluated: evaluated?.isFallEvaluated,
    },
  };
};

const getIncidentERHospitalizationData = (
  event: EventDetail,
): Partial<CreateIncidentParams> | undefined => {
  const issueHosp = [...event.issues]
    .reverse()
    .find(({ issueType }) =>
      [IssueType.ER_VISIT, IssueType.HOSPITALIZATION].includes(issueType.id),
    );

  if (!issueHosp) return undefined;

  const admitDate = issueHosp?.details?.find(
    ({ isHospAdmitDateField }) => isHospAdmitDateField,
  );
  const dischargeDate = issueHosp?.details?.find(
    ({ isHospDischargeDateField }) => isHospDischargeDateField,
  );
  const facilityName = issueHosp?.details?.find(
    ({ isHospFacilityNameField }) => isHospFacilityNameField,
  );
  const followUpAppt = issueHosp?.details?.find(
    ({ isHospFollowUpApptField }) => isHospFollowUpApptField,
  );
  const followUpAppttIds = followUpAppt?.value?.split(',');
  const medicationChange = issueHosp?.details?.find(
    ({ isHospMedChangeField }) => isHospMedChangeField,
  );
  const medicationChangeDesc = issueHosp?.details?.find(
    ({ isHospMedChangeDescField }) => isHospMedChangeDescField,
  );

  return {
    description: event.chiefComplaint,
    incidentDate: admitDate?.value
      ? moment(admitDate.value, SERVER_DATE_FORMAT_ZONED).toDate()
      : undefined,
    reportedDate: moment.unix(event.createdAt).toDate(),
    hospitalization: {
      admitDate: admitDate?.value
        ? moment(admitDate.value, SERVER_DATE_FORMAT_ZONED).toDate()
        : undefined,
      dischargeDate: dischargeDate?.value
        ? moment(dischargeDate.value, SERVER_DATE_FORMAT_ZONED).toDate()
        : undefined,
      facilityName: facilityName?.value,
      followUpAppointmentTypes: followUpAppt?.issueDetailField?.options?.filter(
        ({ id }) => followUpAppttIds?.includes(id),
      ),
      medicationChange: medicationChange?.isHospMedChange,
      medicationChangeDescription: medicationChange?.isHospMedChange
        ? medicationChangeDesc?.value
        : undefined,
    },
  };
};
export class EventDetail extends EventModelExtended {
  chiefComplaint = '';

  divertedFrom?: BaseEnum;

  encounters: Encounter[];

  evaluations: EventEvaluation[];

  interventionPreventedER?: boolean;

  issues: EventIssue[];

  links?: EventLink[];

  notes: EventNote[];

  npEscalationRequired?: boolean;

  tasks: Task[];

  wouldVisitER?: boolean;

  constructor(obj: any) {
    super(obj);
    this.chiefComplaint = obj.chiefComplaint;

    this.encounters = makeArray(
      obj.encounters.map(({ encounter }: any) => encounter),
      Encounter,
    )?.sort(
      (
        { encounterDateTime: a }: Encounter,
        { encounterDateTime: b }: Encounter,
      ) => b - a,
    );

    this.tasks = makeArray(obj.tasks, Task)?.sort(
      ({ createdAt: a }: Task, { createdAt: b }: Task) => a - b,
    );

    this.evaluations = makeArray(obj.evaluations, EventEvaluation)?.sort(
      ({ id: a }: EventEvaluation, { id: b }: EventEvaluation) => a - b,
    );

    this.issues = makeArray(
      obj.issues,
      EventIssue,
    )?.sort(
      ({ issueDatetime: a }: EventIssue, { issueDatetime: b }: EventIssue) =>
        a.isBefore(b) ? 1 : -1,
    );

    this.links = makeArray(obj.links, EventLink);

    this.notes = makeArray(
      obj.notes,
      EventNote,
    )?.sort(({ createdAt: a }: EventNote, { createdAt: b }: EventNote) =>
      a.isBefore(b) ? 1 : -1,
    );

    this.divertedFrom = make(obj.divertedFrom, BaseEnum);
    this.interventionPreventedER =
      obj.interventionPreventedER !== null
        ? Boolean(obj.interventionPreventedER)
        : undefined;
    this.npEscalationRequired =
      obj.npEscalationRequired !== null
        ? Boolean(obj.npEscalationRequired)
        : undefined;
    this.wouldVisitER =
      obj.wouldVisitER !== null ? Boolean(obj.wouldVisitER) : undefined;
  }

  getPendingPrompts() {
    return (
      this.issues?.reduce(
        (acc, { pendingPrompts }) => [...acc, ...(pendingPrompts || [])],
        [] as IssuePrompt[],
      ) || []
    );
  }

  getEvaluationOpen() {
    return this.evaluations.find(({ closed }) => !closed);
  }

  hasEvaluationOpen() {
    return !!this.getEvaluationOpen();
  }

  canCloseEvent() {
    return (
      !this.isClosed &&
      !!this.evaluations?.length &&
      !this.evaluations.find(({ closed }) => !closed) &&
      this.status.id !== EventStatus.NOT_STARTED &&
      !!this.assignee?.id
    );
  }

  canInvalidateEvent(actingUser?: Employee) {
    const userCanForceInvalidate =
      actingUser?.isInAdmin || actingUser?.isInClinicalLeadership;
    return (
      !this.isClosed &&
      (userCanForceInvalidate || (!this.evaluations?.length && this.assignee))
    );
  }

  getIncidentTypeAssociated(): IncidentType | undefined {
    if (this.type?.id === EventType.FALL) {
      const issueFall = [...this.issues]
        .reverse()
        .find(({ issueType }) => issueType.id === IssueType.FALL);
      const injury = issueFall?.details?.find(
        ({ isFallInjuryField }) => isFallInjuryField,
      );
      if (injury && injury?.isFallWithInjury) {
        return new IncidentType({
          id: IncidentType.FALL_INJURY,
          description: this.type.name,
        });
      }
      if (injury) {
        return new IncidentType({
          id: IncidentType.FALL_NO_INJURY,
          description: this.type.name,
        });
      }
    }

    const issueHosp = [...this.issues]
      .reverse()
      .find(({ issueType }) =>
        [IssueType.ER_VISIT, IssueType.HOSPITALIZATION].includes(issueType.id),
      );
    if (issueHosp) {
      return new IncidentType({
        id: IncidentType.HOSPITALIZATION,
        description: issueHosp.issueType.description,
      });
    }
    return undefined;
  }

  get hasMissingDetails() {
    return !!this.issues.find(({ missingDetails }) => missingDetails);
  }

  getMissingDetails() {
    return this.issues.filter(({ missingDetails }) => missingDetails);
  }

  canCloseDocumented(actingUser?: Employee) {
    return this.assignee?.id === actingUser?.id;
  }

  getIncidentData(): Partial<CreateIncidentParams> | undefined {
    const hasFall = this.issues.find(
      ({ source, issueType, valid }) =>
        valid &&
        source.id !== SourceChannel.PING &&
        [IssueType.FALL].includes(issueType.id),
    );
    const hasHospitalization = this.issues.find(
      ({ source, issueType, valid }) =>
        valid &&
        source.id !== SourceChannel.PING &&
        [IssueType.ER_VISIT, IssueType.HOSPITALIZATION].includes(issueType.id),
    );
    if (hasFall) {
      return getIncidentFallData(this);
    }
    if (hasHospitalization) {
      return getIncidentERHospitalizationData(this);
    }
    return undefined;
  }

  get allowIncident() {
    return (
      INCIDENTS_RELEASE.isBefore(moment.unix(this.createdAt)) &&
      !this.isInvalid &&
      this.issues.find(
        ({ source, issueType, valid }) =>
          valid &&
          source.id !== SourceChannel.PING &&
          [
            IssueType.FALL,
            IssueType.ER_VISIT,
            IssueType.HOSPITALIZATION,
          ].includes(issueType.id),
      )
    );
  }

  get hasIncident() {
    return this.incidentId && this.allowIncident;
  }

  get allowToc() {
    return (
      TOC_RELEASE.isBefore(moment.unix(this.createdAt)) &&
      !this.isInvalid &&
      this.issues.find(
        ({ source, issueType, valid }) =>
          valid &&
          source.id !== SourceChannel.PING &&
          [
            IssueType.ER_VISIT,
            IssueType.HOSPITALIZATION,
            IssueType.SNF,
          ].includes(issueType.id),
      )
    );
  }

  get allowSkilled() {
    return (
      TOC_RELEASE.isBefore(moment.unix(this.createdAt)) &&
      !this.isInvalid &&
      this.issues.find(
        ({ source, issueType, valid }) =>
          valid &&
          source.id !== SourceChannel.PING &&
          [IssueType.SKILLED].includes(issueType.id),
      )
    );
  }

  get hasToc() {
    return this.tocRecordId && this.allowToc;
  }

  get hasSkilled() {
    return this.skilledRecordId && this.allowSkilled;
  }
}

export default EventDetail;
