import React, { memo } from 'react';
import classNames from 'classnames';

interface Props {
  active?: boolean;
  children?: any;
  className?: string;
  disabled?: boolean;
  'data-cy'?: string;
  marginLeft?: boolean;
  marginRight?: boolean;
  onClick?: (event?: Event) => void;
  size?: 'sm' | 'xs' | 'lg' | '';
  tag?: 'button' | 'span' | 'i';
}

export const Icon = memo((props: Props) => {
  const {
    className,
    children,
    'data-cy': dataCy = 'icon',
    disabled,
    marginLeft,
    marginRight,
    onClick,
    size,
    tag = 'i',
  } = props;

  const classes = classNames('icon', className, size && `size-${size}`, {
    disabled,
    'margin-left': marginLeft,
    'margin-right': marginRight,
  });

  return React.createElement(
    tag,
    {
      className: classes,
      'data-cy': dataCy,
      disabled,
      onClick,
      type: tag === 'button' ? 'button' : undefined,
    },
    children,
  );
});

export const DeleteIcon = memo((props: Props) => {
  const { className, ...other } = props;
  const classes = classNames(className, 'delete-icon');

  return (
    <Icon className={classes} {...other}>
      <span className="remove-x" data-cy="delete-icon" />
    </Icon>
  );
});

export const InfoIcon = memo((props: Props) => {
  const { className, 'data-cy': dataCy = 'edit-icon', ...other } = props;
  const classes = classNames(className, 'info-icon fa fa-info');

  return <Icon data-cy={dataCy} className={classes} {...other} />;
});

export const EditIcon = memo((props: Props) => {
  const { className, 'data-cy': dataCy = 'edit-icon', ...other } = props;
  const classes = classNames(className, 'edit-icon fa fa-pencil');

  return <Icon data-cy={dataCy} className={classes} {...other} />;
});

export const AddIcon = memo((props: Props) => {
  const { className, 'data-cy': dataCy = 'add-icon', ...other } = props;
  const classes = classNames(className, 'add-icon fa fa-plus');

  return <Icon className={classes} data-cy={dataCy} {...other} />;
});

export const AddIterationIcon = memo((props: Props) => {
  const { className, 'data-cy': dataCy = 'add-iteration', ...other } = props;
  const classes = classNames(className, 'add-iteration-icon fa fa-plus-circle');

  return <Icon className={classes} data-cy={dataCy} {...other} />;
});

export const CollapseIcon = memo((props: Props) => {
  const { className, 'data-cy': dataCy = 'collapse-icon', ...other } = props;

  const chevron = props.active ? 'fa-chevron-up' : 'fa-chevron-down';
  const classes = classNames(className, 'collapse-icon fa fa-fw', chevron);

  return <Icon className={classes} data-cy={dataCy} {...other} />;
});
