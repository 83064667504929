import { Brand, Language, States } from '../enums';
import {
  PatientBrandHelper,
  hasBrandCareAtHome,
} from './Helpers/PatientBrandHelper';
import { HouseHoldMember } from './HouseHoldMember';
import { makeArray } from './Model';

export class ClinicalMember implements PatientBrandHelper {
  brand: Brand;

  firstName = '';

  householdMembers?: HouseHoldMember[];

  id = 0;

  language: Language;

  lastName = '';

  memberId = 0;

  state?: States;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.brand = Brand.byKey[obj.brand];
    this.householdMembers = makeArray(obj.householdMembers, HouseHoldMember);
    this.language = Language.byKey[obj.language];
    this.state = States.byKey[obj.state];
  }

  get fullName() {
    if (this.firstName && this.lastName)
      return `${this.firstName} ${this.lastName}`;
    return '';
  }

  isBrandCareAtHome(): boolean {
    return hasBrandCareAtHome(this);
  }
}

export default ClinicalMember;
