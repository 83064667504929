import classnames from 'classnames';
import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import MRadioGroup from '@mui/material/RadioGroup';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import { Colors } from 'styleguide-v2/src/styles/Colors';
import { Fonts } from 'styleguide-v2/src/styles/Variables';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
  },
  title: {
    fontSize: `calc(${Fonts.fontSize} * .75)`,
    fontWeight: 500,
    marginBottom: '0.4rem',
    '& :last-child': {
      marginRight: '1rem',
    },
  },
  row: {
    '& span.MuiRadio-root': {
      padding: '0 .5rem 0 1rem',
    },
    '& span.MuiFormControlLabel-label': {
      fontFamily: Fonts.fontFamily,
      fontSize: Fonts.fontSize,
      lineHeight: 1,
    },
  },
  columns: {
    '&&': {
      display: 'flex',
      flexFlow: 'row',
      flexWrap: 'wrap',
    },

    '&& > *': {
      flex: '1 1 45%',
    },
  },
  columns3: {
    '&& > *': {
      flex: '1 1 30%',
    },
  },
  compact: {
    '&&': {
      marginBottom: '5px',
    },
  },
  required: {
    color: Colors.textOrange,
  },
});

export type RadioItem = {
  value?: number | string;
  label: string;
  disabled?: boolean;
};

type Props = {
  'data-cy'?: string;
  className?: string;
  columns?: number;
  compact?: boolean;
  disabled?: boolean;
  error?: string | boolean;
  expand?: 'horizontal' | 'vertical';
  items: RadioItem[];
  onChange: (selected: any) => void;
  required?: boolean;
  title?: string;
  value?: any;
};

export const RadioGroup = ({
  'data-cy': dataCy,
  className,
  columns,
  compact,
  disabled,
  error,
  expand,
  items,
  onChange,
  required,
  title,
  value,
}: Props) => {
  const styles = useStyles();

  return (
    <div className={classnames(className, styles.container)} data-cy={dataCy}>
      {(title || error || required) && (
        <div className={classnames(styles.title, error && styles.required)}>
          {title && <span>{title}</span>}
          {(error || required) && (
            <span className={styles.required}>
              {' *'}
              {error || translate('global.required')}
            </span>
          )}
        </div>
      )}
      <MRadioGroup
        className={classnames(
          columns && styles.columns,
          columns === 3 && styles.columns3,
        )}
        onChange={(evt) => onChange(evt.target.value)}
        value={value}
        row={expand === 'horizontal'}
      >
        {items.map(
          ({ disabled: itemDisabled, value: itemValue, label }, index) => (
            <FormControlLabel
              className={classnames(styles.row, compact && styles.compact)}
              control={<Radio data-cy={`${dataCy}-${index}`} />}
              disabled={disabled || itemDisabled}
              key={itemValue || label}
              label={label}
              value={itemValue || label}
            />
          ),
        )}
      </MRadioGroup>
    </div>
  );
};
