import classNames from 'classnames';
import React, { useMemo, useState } from 'react';

import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import { makeStyles } from '@mui/styles';

import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  Patient,
} from '@vestahealthcare/common/models';

import { Colors } from 'styleguide-v2/src/styles/Colors';

import { Button } from '../Button';
import { Select } from '../Select';
import { Spinner } from '../Spinner';

type Props = {
  allowClear?: boolean;
  allowUnassigned?: boolean;
  assignee?: Employee | EmployeeGroup;
  assignToMe?: boolean;
  className?: string;
  currentUser?: Employee;
  items: (Employee | EmployeeGroup)[];
  label?: string;
  loading?: boolean;
  member?: Patient;
  onChange: (assignee?: Employee | EmployeeGroup) => Promise<void> | void;
  readOnly?: boolean;
  size?: 'xs' | 'small' | 'medium';
};

const useStyles = makeStyles({
  assignMeButton: {
    '&&': {
      backgroundColor: 'transparent',
      borderRadius: '5px',
      padding: '0.5rem 0.65rem',

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  assignMeButtonSmall: {
    '&&': {
      minHeight: '3.6rem',
      minWidth: '3.6rem',
    },
  },
  assignMeButtonExtraSmall: {
    '&&': {
      minHeight: '3.2rem',
      minWidth: '3.2rem',
    },
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    gap: '0.5rem',

    '& > div:first-child': {
      flex: 1,
    },
  },
});

export const UNASSIGNED = new Employee({
  fullName: translate('global.unassigned'),
});

const getEmployeesList = (
  items: (Employee | EmployeeGroup)[],
  currentUser?: Employee,
  member?: Patient,
  allowUnassigned?: boolean,
) => {
  const aditionalEmployees: Employee[] = [
    new Employee({
      id: currentUser?.id,
      fullName: translate('global.assignToMe'),
    }),
  ];

  if (member?.npOwner?.id) {
    aditionalEmployees.push(
      new Employee({
        id: member?.npOwner?.id,
        fullName: translate('global.assignToNp'),
      }),
    );
  }
  if (member?.owner?.id) {
    aditionalEmployees.push(
      new Employee({
        id: member?.owner?.id,
        fullName: translate('global.assignToRn'),
      }),
    );
  }
  return [
    ...(allowUnassigned ? [UNASSIGNED] : []),
    ...aditionalEmployees,
    ...items,
  ];
};

export const SelectAssignee = ({
  allowUnassigned,
  allowClear,
  assignee,
  assignToMe,
  className,
  currentUser,
  items,
  label,
  loading,
  member,
  onChange,
  readOnly,
  size,
}: Props) => {
  const styles = useStyles();
  const [currentLoading, setLoading] = useState<boolean>(false);

  const showAssignButton = useMemo(
    () =>
      !readOnly &&
      assignToMe &&
      currentUser &&
      (!items ||
        items.find(
          (item) => item instanceof Employee && item.id === currentUser.id,
        )),
    [assignToMe, items, currentUser, readOnly],
  );

  const renderSelect = () => (
    <Select
      className={className}
      color={assignee?.id === 0 ? 'secondary' : undefined}
      disableClearable={!allowClear}
      getItemLabel={(item: Employee | Selectable | EmployeeGroup) => {
        if (item instanceof EmployeeGroup) {
          return item.name || '';
        }
        if (item instanceof Employee) {
          return item.fullName || '';
        }
        return String(item.label || '');
      }}
      filterOptions={(
        data: (Employee | EmployeeGroup | Selectable)[],
        { inputValue },
      ) =>
        data.filter((item) => {
          const value = inputValue?.toLocaleLowerCase();
          if (item instanceof Employee) {
            return (
              item.fullName?.toLocaleLowerCase()?.includes(value) ||
              item.firstInitialLastName?.toLocaleLowerCase()?.includes(value)
            );
          }
          if (item instanceof EmployeeGroup) {
            return item.name?.toLocaleLowerCase().includes(value);
          }
          return (item.label as string)?.toLocaleString()?.includes(value);
        })
      }
      grouped={(item: Employee | EmployeeGroup) => {
        if (item instanceof EmployeeGroup) {
          return translate('global.groups');
        }
        if (item instanceof Employee && item.id && item.firstName) {
          return translate('global.employees');
        }
        return '';
      }}
      items={getEmployeesList(items, currentUser, member, allowUnassigned)}
      isOptionEqualToValue={(
        a: Employee | EmployeeGroup,
        b: Employee | EmployeeGroup,
      ) => {
        if (
          a instanceof Employee &&
          b instanceof Employee &&
          a.firstName &&
          b.firstName
        )
          return a.id === b.id;
        if (a instanceof EmployeeGroup && b instanceof EmployeeGroup)
          return a.id === b.id;
        return false;
      }}
      label={label}
      loading={!showAssignButton && loading}
      onChange={async (val: Employee | EmployeeGroup) => {
        setLoading(true);
        if (val instanceof Employee && !val.firstName && val.id !== 0) {
          const newAssignee = items.find(
            (item) =>
              item instanceof Employee &&
              item.id === val.id &&
              !!item.firstName,
          );
          if (newAssignee) {
            await onChange(newAssignee);
          } else if (allowClear) {
            await onChange(undefined);
          }
        } else {
          await onChange(val);
        }
        setLoading(false);
      }}
      readOnly={readOnly}
      size={size}
      type={assignee?.id === 0 ? 'contained' : undefined}
      value={assignee}
    />
  );

  return showAssignButton ? (
    <div className={styles.container}>
      {renderSelect()}
      <Button
        color="tertiary"
        className={classNames(
          styles.assignMeButton,
          size === 'small' && styles.assignMeButtonSmall,
          size === 'xs' && styles.assignMeButtonExtraSmall,
        )}
        disabled={
          !currentLoading && assignee && assignee?.id === currentUser?.id
        }
        icon={
          currentLoading ? (
            <Spinner width={17} color="primary" />
          ) : (
            <AssignmentReturnedIcon htmlColor={Colors.iconGreen} />
          )
        }
        onClick={async (evt) => {
          evt?.stopPropagation();
          if (currentLoading) return;
          setLoading(true);
          await onChange(currentUser);
          setLoading(false);
        }}
        size={size}
        tooltip={translate('components.selectAssignee.assignToMe')}
        type="outlined"
      />
    </div>
  ) : (
    renderSelect()
  );
};
