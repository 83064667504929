import React from 'react';
import { useLocation } from 'react-router-dom';

import Chat from './Chat';

export const useQuery = () => new URLSearchParams(useLocation().search);

const Chats = () => {
  const query = useQuery();

  return (
    <div style={{ height: '100%', width: '100%', display: 'flex' }}>
      <Chat
        memberId={query.get('memberId')}
        name={query.get('name')}
        rnOwnerId={query.get('rnOwnerId')}
        closed={query.get('closed')}
      />
    </div>
  );
};

export default Chats;
