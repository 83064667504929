import React, { useState } from 'react';
import { Header, Link, Panel, Table, Text, SortableList } from 'styleguide';
import { List, arrayMove } from 'react-movable';

const stringify = (schema: any) => JSON.stringify(schema, null, 4);
const StyleguideSorter = () => {
  const [text, setText] = useState('');
  const [tableItems, setTableItems] = useState([
    {
      first_name: 'Oralie',
      last_name: 'Blaszkiewicz',
      index: '1',
    },
    {
      first_name: 'Marylin',
      last_name: 'Seagar',
      index: '2',
    },
    {
      first_name: 'Cristy',
      last_name: 'Carberry',
      index: '3',
    },
  ]);
  const [items, setItems] = useState([
    { name: 'Oralie' },
    { name: 'Marylin' },
    { name: 'Cristy' },
  ]);

  return (
    <Panel>
      <Panel.Heading>
        <h2>Drag and Drop sorter</h2>
      </Panel.Heading>
      <Panel.Body className="grid-wrapper">
        <Header size="h2" columns={12}>
          Table
        </Header>
        <Link href="https://github.com/tajo/react-movable" columns={12}>
          Documentation
        </Link>
        <Text columns={12} color="warning" inline>
          {text}
        </Text>
        <List
          lockVertically
          values={tableItems}
          onChange={({ oldIndex, newIndex }) => {
            setTableItems(arrayMove(tableItems, oldIndex, newIndex));
            setText('Order Changed');
          }}
          renderList={({ children, props }) => (
            <Table className="grid-span-12">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Index</th>
                </tr>
              </thead>
              <tbody {...props}>{children}</tbody>
            </Table>
          )}
          renderItem={({ value, props, isDragged, isSelected }) => {
            const row = (
              <tr
                {...props}
                style={{
                  cursor: isDragged ? 'grabbing' : 'grab',
                }}
              >
                <td>{value.first_name}</td>
                <td>{value.last_name}</td>
                <td>{value.index}</td>
              </tr>
            );
            return isDragged ? (
              <table
                className="table"
                style={{ ...props.style, borderSpacing: 5 }}
              >
                <tbody>{row}</tbody>
              </table>
            ) : (
              row
            );
          }}
        />
        <div className="grid-span-12">
          <b>Data:</b>
          <pre>{stringify(tableItems)}</pre>
        </div>
      </Panel.Body>
      <Panel.Body className="grid-wrapper">
        <Header size="h2" columns={12}>
          Sortable List Editable
        </Header>
        <SortableList
          items={items}
          isEditable
          onChange={(items) => {
            setItems(items);
            setText('List Changed');
          }}
        />

        <Header size="h2" columns={12}>
          Sortable List Edit Icon
        </Header>
        <SortableList
          items={items}
          onChange={(items) => {
            setItems(items);
            setText('List Changed');
          }}
          onEditItem={() => {
            setText('Item Edit');
          }}
        />

        <Text columns={12} color="warning" inline>
          {text}
        </Text>
        <div className="grid-span-12">
          <b>Data:</b>
          <pre>{stringify(items)}</pre>
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default StyleguideSorter;
