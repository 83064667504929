import moment, { Moment } from 'moment';

import { BaseEnum } from './BaseEnum';
import { CareTeamMemberSpeciality } from './CareTeamMemberSpeciality';
import { CareTeamMemberType } from './CareTeamMemberType';
import {
  CareTeamMemberTypeHelper,
  getTypeWithSubType,
  getTypeWithSubTypeLabel,
} from './Helpers/CareTeamMemberType';
import { make, makeArray } from './Model';
import { Phone } from './Phone';

export class CareTeamCandidate implements CareTeamMemberTypeHelper {
  id = 0;

  firstName = '';

  lastName = '';

  fullName = '';

  phone?: Phone;

  memberId = 0;

  careTeamMemberId = 0;

  createdAt: Moment;

  deletedAt?: Moment;

  type?: CareTeamMemberType;

  aideType?: BaseEnum;

  otherRelationship?: BaseEnum;

  relationship?: BaseEnum;

  relationshipOther = '';

  specialities?: CareTeamMemberSpeciality[];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.phone = make(obj.phone, Phone);
    this.createdAt = moment.unix(obj.createdAt);
    this.deletedAt = obj.deletedAt ? moment.unix(obj.deletedAt) : undefined;
    this.type = make(obj.type, CareTeamMemberType);
    this.aideType = make(obj.aideType, BaseEnum);
    this.otherRelationship = make(obj.otherRelationship, BaseEnum);
    this.specialities = makeArray(
      obj.specialities?.map((item: BaseEnum) => ({
        specialityType: item,
        otherSpeciality:
          item.id === BaseEnum.OTHER ? obj.subtypeOther : undefined,
      })),
      CareTeamMemberSpeciality,
    );
    this.relationshipOther = obj.subtypeOther;
  }

  get consolidated() {
    return !!this.careTeamMemberId;
  }

  get typeWithSubType(): {
    type?: CareTeamMemberType;
    subtype?: string | undefined;
  } {
    return getTypeWithSubType(this);
  }

  get typeWithSubTypeLabel(): string {
    return getTypeWithSubTypeLabel(getTypeWithSubType(this));
  }
}

export default CareTeamCandidate;
