import Api from 'dash/src/services/Api';
import { PatientMedicalEquipment } from '@vestahealthcare/common/models';

export interface EquipmentParams {
  equipment: PatientMedicalEquipment;
}

export const fetchEquipment = async (
  patientId: number | string,
): Promise<PatientMedicalEquipment[]> => {
  const { equipment } = await Api.getv2(`/patients/${patientId}/dme`);
  return (
    equipment && equipment.items.map((i: any) => new PatientMedicalEquipment(i))
  );
};

export const addEquipment = async (
  patientId: number | string,
  params: EquipmentParams,
) => {
  const transformed = {
    equipment: params.equipment.medicalEquipment.value,
    otherEquipment: params.equipment.otherEquipment,
  };
  await Api.postv2JSON(`/patients/${patientId}/dme`, transformed);
};

export type DiscontinueParams = {
  discontinueDate?: string;
};

export const discontinueEquipment = async (
  patientId: number | string,
  durableMedicalEquipmentId: number,
  params?: DiscontinueParams,
): Promise<PatientMedicalEquipment> => {
  const { dme } = await Api.postv2JSON(
    `/patients/${patientId}/dme/${durableMedicalEquipmentId}/actions/discontinue`,
    params,
  );
  return dme;
};
