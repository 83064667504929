import moment from 'moment';

import { DayOfWeek, MemberContactTimeType } from '../../enums';
import { MemberContactInfoTimes } from '../MemberContactInfoTimes';

export interface DaysOfServiceHelper {
  getDaysOfService: (
    times?: MemberContactInfoTimes,
  ) => {
    dates: DayOfWeek[];
    startTime: Date | undefined;
    endTime: Date | undefined;
    type: MemberContactTimeType;
  }[];
}

export const getDaysOfService = (times?: MemberContactInfoTimes[]) => {
  const map = {} as { [x: string]: DayOfWeek[] };
  times?.forEach(({ dayOfWeek, startTime, endTime, type }) => {
    if (type && type !== MemberContactTimeType.CUSTOM) {
      if (!map[type.value]) {
        map[type.value] = [] as DayOfWeek[];
      }
      if (dayOfWeek) {
        map[type.value].push(dayOfWeek);
      }
    } else {
      const key = `${startTime?.unix() || ''}__${endTime?.unix() || ''}`;
      if (!map[key]) {
        map[key] = [] as DayOfWeek[];
      }
      if (dayOfWeek) {
        map[key].push(dayOfWeek);
      }
    }
  });

  return Object.keys(map).map((key) => {
    const hasType = key.indexOf('__') === -1;
    const type = hasType ? key : undefined;
    const start = hasType ? undefined : key.split('__')[0];
    const end = hasType ? undefined : key.split('__')[1];
    return {
      dates: map[key],
      startTime: start ? moment.unix(Number(start)).toDate() : undefined,
      endTime: end ? moment.unix(Number(end)).toDate() : undefined,
      type: type
        ? MemberContactTimeType.byKey[type]
        : MemberContactTimeType.CUSTOM,
    };
  });
};
