import moment from 'moment';
import React, { useEffect, useState } from 'react';

import EventNoteIcon from '@mui/icons-material/EventNote';
import { makeStyles } from '@mui/styles';

import {
  DayOfWeek,
  MemberContactMethodType,
  MemberContactTimeType,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  MemberContactInfoMethod,
  Patient,
} from '@vestahealthcare/common/models';
import { TIME_FORMAT } from '@vestahealthcare/common/utils/constants';

import { BackgroundColors, Colors, Fonts, Tooltip } from 'styleguide-v2';

import { DatesTimeItem } from 'dash/src/components/EditDateTimes';
import { getMemberContactInfoParsed } from 'dash/src/services/PatientServices';

type Props = {
  patient: Patient;
};

const SORT_ORDER = [
  undefined,
  MemberContactTimeType.ANY_TIME,
  MemberContactTimeType.MORNING,
  MemberContactTimeType.AFTERNOON,
  MemberContactTimeType.CUSTOM,
];

const useStyles = makeStyles({
  container: {
    backgroundColor: BackgroundColors.lightMint,
    borderLeft: `4px solid ${Colors.mint}`,
    borderRadius: '4px',
    height: 'fit-content',
    marginLeft: '1rem',
    minHeight: 'calc(100% - 3rem)',
    padding: '1rem 2rem 1rem 1rem',
  },
  columnContainer: {
    display: 'flex',
    gap: '1rem',
  },
  column: {
    display: 'flex',
    flexFlow: 'column',
  },
  title: {
    color: Colors.iconGreen,
    display: 'flex',
    fontFamily: Fonts.fontFamily,
    fontSize: `calc(${Fonts.fontSize} * 0.625)`,
    fontWeight: 500,
    gap: '0.5rem',
    letterSpacing: '1px',
    marginBottom: '0.5rem',
    textTransform: 'uppercase',
  },
  text: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
  },
});

export const PreferredContactInfo = ({ patient }: Props) => {
  const styles = useStyles();
  const [methods, setMethods] = useState<MemberContactInfoMethod[]>([]);
  const [times, setTimes] = useState<DatesTimeItem[]>([]);
  const [note, setNote] = useState('');

  const getContactInfo = async () => {
    const {
      contactMethods,
      contactDateTimes,
      note,
    } = await getMemberContactInfoParsed(patient.id);
    setMethods(contactMethods || []);
    setTimes(contactDateTimes || []);
    setNote(note);
  };

  useEffect(() => {
    getContactInfo();
  }, [patient]);

  return methods?.length || times?.length ? (
    <div className={styles.container}>
      <div className={styles.title}>
        <span>{translate('contactInfo.title')}</span>
        {note && (
          <Tooltip text={note}>
            <EventNoteIcon fontSize="small" color="primary" />
          </Tooltip>
        )}
      </div>
      <div className={styles.columnContainer}>
        <div className={styles.column}>
          {methods
            .sort((a, b) =>
              a.contactMethod
                .toString()
                .localeCompare(b.contactMethod.toString()),
            )
            ?.map(({ contactMethod, contactMethodOther }) =>
              contactMethod === MemberContactMethodType.OTHER ? (
                <>
                  <span className={styles.text}>
                    {contactMethod.toString()}
                  </span>
                  <span className={styles.text}>{contactMethodOther}</span>
                </>
              ) : (
                <span className={styles.text}>{contactMethod.toString()}</span>
              ),
            )}
        </div>
        <div className={styles.column}>
          {[...times]
            ?.sort(
              (a, b) =>
                SORT_ORDER.indexOf(a?.type) - SORT_ORDER.indexOf(b?.type),
            )
            .map(({ dates, type, startTime, endTime }, idx) => (
              <span
                className={styles.text}
                key={`member-contact-info-days-${idx}`}
              >
                {dates?.length && <>{DayOfWeek.stringify(dates)}:&nbsp;</>}
                {type !== MemberContactTimeType.CUSTOM && type?.toString()}
                {type === MemberContactTimeType.CUSTOM && (
                  <>
                    {startTime
                      ? moment(startTime.getTime()).format(TIME_FORMAT)
                      : '*'}
                    &nbsp;-&nbsp;
                    {endTime
                      ? moment(endTime.getTime()).format(TIME_FORMAT)
                      : '*'}
                  </>
                )}
              </span>
            ))}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default PreferredContactInfo;
