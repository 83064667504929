import React, { PureComponent } from 'react';
import classNames from 'classnames';

interface Props extends React.HTMLProps<HTMLUListElement> {
  className?: string;
}

export class NavTabs extends PureComponent<Props> {
  render() {
    const { children, className, ...other } = this.props;

    const classes = classNames('nav', 'nav-tabs', className);

    return (
      <ul className={classes} {...other}>
        {children}
      </ul>
    );
  }
}

export default NavTabs;
