import moment, { Moment } from 'moment';
import { make } from './Model';
import { BaseEnum } from './BaseEnum';

export class ClinicalProblem {
  id = 0;

  code = '';

  name = '';

  onSetDate: Moment;

  status: BaseEnum;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.onSetDate = moment.unix(obj.onSetDate);
    this.status = make(obj.status, BaseEnum);
  }
}

export default ClinicalProblem;
