import React, { PureComponent } from 'react';
import Part from '@vestahealthcare/common/enums/BodyPart';

import './main.less';

interface Props {
  componentClass?: 'path' | 'ellipse';
  active?: boolean;
  hover?: boolean;
  part: Part;
  'data-cy'?: string;
}

export default class BodyPart extends PureComponent<Props> {
  static defaultProps = {
    componentClass: 'path',
    hover: false,
    active: false,
  };

  render() {
    const {
      componentClass: Component,
      hover,
      active,
      part,
      ...props
    } = this.props;

    const className = `${hover ? 'hover' : ''} || ${active ? 'active' : ''}`;

    return (
      // @ts-ignore
      <Component className={className} {...props} />
    );
  }
}
