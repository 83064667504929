import React, { useState } from 'react';
import classnames from 'classnames';
import Spinner from '../Spinner';

export type ButtonColor =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'blue'
  | 'white'
  | 'mint'
  | 'brown'
  | 'gray'
  | 'green'
  | 'shadowGreen'
  | 'dark-mint'
  | 'dark-gray';

export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg';

interface Props extends React.HTMLProps<HTMLAnchorElement | HTMLButtonElement> {
  disabled?: boolean;
  href?: string;
  loading?: boolean;
  color?: ButtonColor;
  btnSize?: ButtonSize;
  isPill?: boolean;
  onClick?: React.MouseEventHandler;
}

const Button = (props: Props) => {
  const {
    children,
    className,
    color,
    disabled,
    href,
    loading,
    btnSize,
    isPill,
    onClick,
    ...other
  } = props;

  const [awaitingOnClick, setAwaitingOnClick] = useState(false);
  let myRef: HTMLElement | null;

  const handleClick = (e: React.MouseEvent) => {
    if (!onClick) {
      return;
    }

    try {
      setAwaitState(true);
      onClick(e);
      setAwaitState(false);
    } catch (e) {
      setAwaitState(false);
    }
  };

  /**
   * Sometimes this component is unmounting or already unmounted when we try to set the
   * async state.  For example, if the button exists within a modal that closes on submit.
   * In that case we don't want to setState as the button doesn't exist anymore and will
   * just cause react to yell at us.
   */
  const setAwaitState = (isAwaiting: boolean) => {
    if (myRef) {
      setAwaitingOnClick(isAwaiting);
    }
  };

  const isLoading = loading || awaitingOnClick;

  const classes = classnames(
    'btn',
    {
      [`btn-${color}`]: color || 'white',
      [`btn-${btnSize}`]: !!btnSize,
      loading: isLoading,
      'btn-pill': isPill,
    },
    className,
  );

  const tag = href ? 'a' : 'button';
  return React.createElement(
    tag,
    {
      href,
      ref: (ref) => {
        myRef = ref;
      },
      className: classes,
      disabled: disabled || isLoading,
      type: 'button',
      onClick: (e: React.MouseEvent) => handleClick(e),
      ...other,
    },
    isLoading && <Spinner inline overlay width={isPill ? 14 : 28} />,
    children,
  );
};

export default Button;
