import classNames from 'classnames';
import React, { ReactNode } from 'react';

import ForwardIcon from '@mui/icons-material/ArrowForwardIos';
import HelpIcon from '@mui/icons-material/HelpOutline';
import { makeStyles } from '@mui/styles';

import { Colors } from '../../styles/Colors';
import { Fonts } from '../../styles/Variables';
import { Tooltip } from '../Tooltip';

type Props = {
  body: ReactNode;
  className?: string;
  color?: string;
  footer?: ReactNode;
  onClick?: () => void;
  tag?: ReactNode;
  title: ReactNode;
  tooltip?: string;
  size?: 's' | 'm';
};

const useStyles = makeStyles({
  body: {
    fontSize: `calc(${Fonts.fontSize} * 2)`,
    lineHeight: `calc(${Fonts.fontSize} * 2.25)`,
  },
  container: {
    display: 'flex',
    gap: '1.5rem',
    minHeight: '8.5rem',
    minWidth: '14rem',
    width: 'max-content',
  },
  clickable: {
    cursor: 'pointer',
  },
  containerText: { display: 'flex', flexFlow: 'column', width: 'max-content' },
  footer: {
    alignItems: 'center',
    color: Colors.iconGray,
    display: 'flex',
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    gap: '0.25rem',
    marginTop: '0.5rem',
    minWidth: 'max-content',
  },
  helpIcon: {
    '&&': {
      color: Colors.iconGray,
      left: '0.375rem',
      top: '0.375rem',
      position: 'relative',
    },
  },
  title: {
    color: Colors.iconGray,
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    fontWeight: 500,
    marginBottom: '0.75rem',
  },
  verticalLine: {
    borderRadius: '0.4rem',
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    width: '0.4rem',
  },
  small: {
    fontSize: `calc(${Fonts.fontSize} * 1.5)`,
  },
});

export const Summary = ({
  body,
  className,
  color = Colors.green,
  footer,
  onClick,
  tag,
  title,
  tooltip,
  size = 'm',
}: Props) => {
  const styles = useStyles();

  return (
    <div
      className={classNames(
        className,
        styles.container,
        onClick && styles.clickable,
      )}
      onClick={onClick}
      role="presentation"
    >
      <div className={styles.verticalLine} style={{ backgroundColor: color }} />
      <div className={styles.containerText}>
        <div className={styles.title}>
          <span>{title}</span>
          {!!tooltip && (
            <Tooltip text={tooltip}>
              <HelpIcon className={styles.helpIcon} color="action" />
            </Tooltip>
          )}
        </div>
        <div
          className={classNames(
            'flex extra-gap middle',
            size === 's' && styles.small,
          )}
        >
          <span className={styles.body}>{body}</span>
          {tag}
        </div>
        {footer && (
          <span className={styles.footer}>
            {footer}
            {onClick && <ForwardIcon fontSize="small" />}
          </span>
        )}
      </div>
    </div>
  );
};
