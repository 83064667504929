import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Colors } from 'styleguide-v2/src/styles/Colors';
import classnames from 'classnames';

const useStyles = makeStyles({
  divider: {
    width: '100%',
    border: `1px solid lightgrey`,
    gridArea: 'separator',
  },

  dividerHover: {
    borderColor: Colors.blue,
  },

  hitbox: {
    cursor: 'row-resize',
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    padding: '1rem 0',
  },
});

const MIN_HEIGHT = 100;
const MAX_HEIGHT = 800;

type Props = {
  onChangeHeight: (height: number) => void;
};

export const VerticalSeparator = ({ onChangeHeight }: Props) => {
  const styles = useStyles();
  const [dividerClass, setDividerClass] = useState<string>();
  const [separatorYPosition, setSeparatorYPosition] = useState<
    undefined | number
  >(undefined);
  const [dragging, setDragging] = useState(false);

  const onMove = (clientY: number) => {
    if (dragging && separatorYPosition) {
      const newHeight = clientY - 200;
      setSeparatorYPosition(clientY);
      if (newHeight < MIN_HEIGHT) {
        onChangeHeight(MIN_HEIGHT);
      } else if (newHeight > MAX_HEIGHT) {
        onChangeHeight(MAX_HEIGHT);
      } else {
        onChangeHeight(newHeight);
      }
    }
  };

  const onMouseDown = (e: React.MouseEvent) => {
    setSeparatorYPosition(e.clientY);
    setDragging(true);
  };

  const onTouchStart = (e: React.TouchEvent) => {
    setSeparatorYPosition(e.touches[0].clientY);
    setDragging(true);
  };

  const onMouseMove = (e: MouseEvent) => {
    e.preventDefault();
    onMove(e.clientY);
  };

  const onMouseUp = () => {
    setDragging(false);
  };

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  });

  return (
    <div
      className={styles.hitbox}
      role="presentation"
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onTouchEnd={onMouseUp}
      onMouseEnter={() => setDividerClass(styles.dividerHover)}
      onMouseLeave={() => setDividerClass(undefined)}
    >
      <div className={classnames(styles.divider, dividerClass)} />
    </div>
  );
};
