import moment, { Moment } from 'moment';

import { CommunityResource } from '.';
import { make } from './Model';

export class MemberCommunityResource {
  id = 0;

  memberId = 0;

  communityResource: CommunityResource;

  pocName = '';

  pocNumber = '';

  pocExtension = '';

  pocEmail = '';

  externalId = '';

  notes = '';

  discontinuedAt?: Moment;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.communityResource = make(obj.communityResource, CommunityResource);
    this.discontinuedAt = obj.discontinuedAt
      ? moment.unix(obj.discontinuedAt)
      : undefined;
  }

  get active() {
    return !this.discontinuedAt;
  }
}

export default MemberCommunityResource;
