import { useState, useEffect } from 'react';
import { useCallbackRef } from './useCallbackRef';

// TODO move models to a package
export interface PaginationType {
  limit: number;
  offset: number;
  total: number;
}

export interface PaginatedResponse<T> {
  items: T[];
  pagination: PaginationType;
}

export function useAsyncData<T>(
  asyncFunc: () => Promise<T>,
  deps: React.DependencyList,
): [T | undefined, boolean, () => void];

export function useAsyncData<T>(
  asyncFunc: () => Promise<T>,
  deps: React.DependencyList,
  defaultValue: T,
): [T, boolean, () => void];

export function useAsyncData<T>(
  asyncFunc: () => Promise<T>,
  deps: React.DependencyList,
  defaultValue?: T,
): [T | undefined, boolean, () => void] {
  const [data, setData] = useState<T | undefined>(defaultValue);
  const [loading, setIsLoading] = useState(false);
  const savedCallback = useCallbackRef(asyncFunc);
  const fetchData = async () => {
    setIsLoading(true);
    const newData = await savedCallback.current();
    setData(newData);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, deps);
  return [data, loading, fetchData];
}

interface UsePaginatedAsyncExtras {
  fetchData: () => void;
  pagination: PaginationType;
}

export const usePaginatedAsyncData = <T>(
  asyncFunc: () => Promise<PaginatedResponse<T>>,
  deps: React.DependencyList,
): [T[], boolean, UsePaginatedAsyncExtras] => {
  const [{ items, pagination }, loading, fetchData] = useAsyncData<
    PaginatedResponse<T>
  >(asyncFunc, deps, {
    items: [],
    pagination: { limit: 0, offset: 0, total: 0 },
  });
  return [items, loading, { fetchData, pagination }];
};
