import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Employee } from '@vestahealthcare/common/models';
import Colors from '../styles/Colors';

const useStyles = makeStyles({
  avatar: {
    border: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: Colors.white,
    width: 38,
    height: 38,
    fontSize: 14,
  },
  textAvatar: {
    display: 'flex',
    width: '100%',
    height: '100%',
    letterSpacing: 1.5,
    flexDirection: 'column',
    justifyContent: 'center',
    userSelect: 'none',
    color: Colors.green,
  },
});

interface Props {
  user: Employee;
  className?: string;
}

function Avatar({ user, className = '' }: Props) {
  const styles = useStyles();
  return (
    <div className={`${styles.avatar} ${className}`}>
      <div className={styles.textAvatar} data-cy="user-avatar">
        {user ? user.initials : 'HT'}
      </div>
    </div>
  );
}

export default Avatar;
