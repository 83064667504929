import React, { Fragment } from 'react';

import { ClassNameMap, makeStyles } from '@material-ui/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';

import { translate } from '@vestahealthcare/common/i18n';
import { InsurancePlan } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Colors, IconButton, Table, Tooltip, UpdatedBy } from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

interface Props {
  insurancePlans: InsurancePlan[];
  onEdit?: (ip: InsurancePlan) => void;
}

const useStyles = makeStyles({
  icon: {
    verticalAlign: 'middle',
    margin: '0 0 0 16%',
  },
});

const drawIconActive = (active: boolean, className: string) => {
  if (active) return <CheckCircleIcon className={className} color="primary" />;
  return <CancelIcon className={className} color="error" />;
};

const drawIconExceptions = (
  { id, inNetwork, facilityExceptions }: InsurancePlan,
  className: string,
) => {
  if (facilityExceptions?.length) {
    return (
      <Tooltip
        text={
          <>
            <div className="flex spaced" style={{ minWidth: '17.5rem' }}>
              <span>
                {translate('insurancePlans.default')}
                {': '}
              </span>
              <span>
                {translate(
                  `insurancePlans.${inNetwork ? 'inNetwork' : 'outOfNetwork'}`,
                )}
              </span>
            </div>
            {facilityExceptions.map((exception, idx) => (
              <div
                className="flex spaced"
                key={`insurance-${id}-exception-${exception.id}`}
              >
                <span>
                  {idx === 0
                    ? `${translate('insurancePlans.exceptions')}: `
                    : ''}
                </span>
                <span>{exception.name}</span>
              </div>
            ))}
          </>
        }
      >
        <InfoIcon className={className} htmlColor={Colors.gold} />
      </Tooltip>
    );
  }
  return drawIconActive(inNetwork, className);
};

const INSURANCE_PLAN_COLUMNS = (
  { onEdit }: Props,
  { icon }: ClassNameMap<'icon'>,
) => {
  const columns: Column[] = [
    {
      headerName: translate('insurancePlans.dashId'),
      field: 'id',
    },
    {
      headerName: translate('insurancePlans.ecwId'),
      component: ({ ecwIds }: InsurancePlan) =>
        ecwIds?.length ? ecwIds.map(({ ecwId }) => ecwId).join(', ') : EMPTY,
    },
    {
      headerName: translate('insurancePlans.name'),
      field: 'name',
    },
    {
      headerName: translate('insurancePlans.inNetwork'),
      component: (insurance: InsurancePlan) =>
        drawIconExceptions(insurance, icon),
    },
    {
      headerName: translate('insurancePlans.isMedicare'),
      component: ({ isMedicare }: InsurancePlan) =>
        drawIconActive(isMedicare, icon),
    },
    {
      headerName: translate('insurancePlans.isMedicaid'),
      component: ({ isMedicaid }: InsurancePlan) =>
        drawIconActive(isMedicaid, icon),
    },
    {
      headerName: translate('insurancePlans.isGeneric'),
      component: ({ isGeneric }: InsurancePlan) =>
        drawIconActive(isGeneric, icon),
    },
    {
      headerName: translate('insurancePlans.created'),
      component: ({ createdAt, createdBy }: InsurancePlan) =>
        createdAt ? (
          <UpdatedBy date={createdAt} user={createdBy?.fullName} />
        ) : (
          <></>
        ),
      width: 175,
    },
  ];
  if (onEdit) {
    columns.push({
      headerName: '',
      component: (ip: InsurancePlan) => (
        <IconButton size="small" onClick={() => onEdit(ip)}>
          <EditIcon />
        </IconButton>
      ),
      width: 20,
    });
  }
  return columns;
};

export const InsurancePlansTable = (props: Props) => {
  const styles = useStyles();
  return (
    <Table
      config={{
        columns: INSURANCE_PLAN_COLUMNS(props, styles),
        compact: true,
        data: props.insurancePlans,
      }}
    />
  );
};

export default InsurancePlansTable;
