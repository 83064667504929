import Api from 'dash/src/services/Api';
import { InsurancePriority } from '@vestahealthcare/common/models';

export const fetchInsurancePriorities = async (): Promise<
  InsurancePriority[]
> => {
  const {
    insurancePriorities: { items },
  } = await Api.getv2('insurance-priorities');
  return items.map((item: any) => new InsurancePriority(item));
};
