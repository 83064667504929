import { Enum } from './Enum';

export class InsuranceQualification extends Enum {
  translationKey = 'insuranceQualification';

  static asArray: InsuranceQualification[] = [];

  static byKey: { [name: string]: InsuranceQualification } = {};

  static readonly PPO_OON_ELIGIBLE = new InsuranceQualification(
    'PPO_OON_ELIGIBLE',
  );

  static readonly PPO_OON_MISSING = new InsuranceQualification(
    'PPO_OON_MISSING',
  );

  static readonly INN_MISSING_MEDICAID = new InsuranceQualification(
    'INN_MISSING_MEDICAID',
  );

  static readonly DUAL_ELIGIBLE = new InsuranceQualification('DUAL_ELIGIBLE');

  static readonly INN_RENDER = new InsuranceQualification('INN_RENDER');

  static readonly MISSING_MEDICARE = new InsuranceQualification(
    'MISSING_MEDICARE',
  );

  static readonly QMB = new InsuranceQualification('QMB');

  static readonly UHC_NE = new InsuranceQualification('UHC_NE');

  static readonly OON_INELIGIBLE = new InsuranceQualification('OON_INELIGIBLE');

  static readonly MISSING_MEDICARE_AND_MEDICAID = new InsuranceQualification(
    'MISSING_MEDICARE_AND_MEDICAID',
  );

  static readonly INACTIVE_PART_B = new InsuranceQualification(
    'INACTIVE_PART_B',
  );

  static readonly INELIGIBLE_ERROR = new InsuranceQualification(
    'INELIGIBLE_ERROR',
  );

  static readonly CARE_AT_HOME_CINQ = new InsuranceQualification(
    'CARE_AT_HOME_CINQ',
  );

  static readonly MEDICAID_PROGRAM = new InsuranceQualification(
    'MEDICAID_PROGRAM',
  );

  static readonly NO_MEDICARE_INELIGIBLE = new InsuranceQualification(
    'NO_MEDICARE_INELIGIBLE',
  );

  static readonly INN_MEDICARE_ACTIVE_SECONDARY_NON_MEDICAID = new InsuranceQualification(
    'INN_MEDICARE_ACTIVE_SECONDARY_NON_MEDICAID',
  );

  constructor(public readonly value: string) {
    super();
    InsuranceQualification.asArray.push(this);
    InsuranceQualification.byKey[value] = this;
  }

  static toSelectable() {
    return Enum.toSelectable(InsuranceQualification.asArray);
  }
}

export default InsuranceQualification;
