import React from 'react';
import { ClinicalFigure } from 'styleguide';
import { WidgetProps } from 'react-jsonschema-form';
import { BodyPart } from '@vestahealthcare/common/enums';

const ClinicalFigureWidget = (props: WidgetProps) => {
  const {
    formContext: { readonly },
    schema: { type },
  } = props;
  const isArray = type === 'array';

  // For single selection fields, we truncate it to a single array value
  const initialSelection = isArray
    ? props.value
      ? props.value.map((val: string) => BodyPart.byKey[val])
      : []
    : props.value
    ? [BodyPart.byKey[props.value]]
    : [];

  return (
    <ClinicalFigure
      onChange={(bodyParts: BodyPart[]) =>
        props.onChange(
          isArray
            ? bodyParts.map((bodyPart) => bodyPart.value)
            : bodyParts[0] && bodyParts[0].value,
        )
      }
      initialSelection={initialSelection}
      disabled={readonly}
      multiple={isArray}
    />
  );
};

export default ClinicalFigureWidget;
