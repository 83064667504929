import { GoalStatus, GoalType } from '../enums';
import { DATE_TIME_FORMAT, DATE_FORMAT } from '../utils/constants';
import moment from '../moment';
import { Employee } from './Employee';
import { make } from './Model';

export class MemberGoal {
  createdAt = 0;

  id = 0;

  memberId = 0;

  otherType?: string;

  outcome = '';

  status: GoalStatus;

  targetDate = '';

  title = '';

  type: GoalType;

  updatedAt = 0;

  updatedBy: Employee;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.status = GoalStatus.byKey[obj.status];
    this.type = GoalType.byKey[obj.type];
    this.updatedBy = make(obj.updatedBy, Employee);
  }

  getTargetDateFormatted() {
    return moment(this.targetDate).format(DATE_FORMAT);
  }

  getUpdatedAtFormatted() {
    return moment.unix(this.updatedAt).format(DATE_TIME_FORMAT);
  }
}

export default MemberGoal;
