import React, { useEffect, useState } from 'react';

import { Close, Search } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { TextInput } from 'styleguide-v2/src/components/TextInput';

type Props = {
  className?: string;
  'data-cy'?: string;
  disabled?: boolean;
  error?: boolean | string;
  label?: string;
  loading?: boolean;
  onChange?: (value?: string) => void;
  onSearch?: (value?: string) => void;
  placeholder?: string;
  required?: boolean;
  value?: string;
  size?: 'medium' | 'small' | 'xs';
};

export const SearchInput = ({
  className,
  'data-cy': dataCy,
  disabled,
  error,
  label = 'Search',
  loading,
  onChange,
  onSearch,
  placeholder,
  required,
  value,
  size,
}: Props) => {
  const [currentValue, setValue] = useState(value);
  useEffect(() => setValue(value), [value]);

  return (
    <TextInput
      className={className}
      data-cy={dataCy}
      disabled={disabled}
      error={error}
      icon={
        <>
          <IconButton
            onClick={onSearch ? () => onSearch(currentValue) : undefined}
          >
            <Search fontSize="large" />
          </IconButton>
          {currentValue && (
            <IconButton
              onClick={() => {
                setValue('');
                if (onChange) {
                  onChange('');
                }
              }}
            >
              <Close fontSize="large" />
            </IconButton>
          )}
        </>
      }
      label={label}
      loading={loading}
      onChange={(newValue?: string) => {
        setValue(newValue);
        if (onChange) {
          onChange(newValue);
        }
      }}
      placeholder={placeholder}
      required={required}
      value={currentValue}
      size={size}
    />
  );
};

export default SearchInput;
