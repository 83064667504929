import { translate } from '@vestahealthcare/common/i18n';
import React, { useEffect, useRef, useState } from 'react';
import { Modal, Spinner } from 'styleguide-v2';
import config from 'dash/environments';
import Session from 'dash/src/services/SessionServices';
import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { makeStyles } from '@mui/styles';
import {
  ClearTriageModel,
  EventEvaluation,
  EventTriage,
  EventTriageAdvice,
  EventTriageHandout,
  EventTriageQuestion,
} from '@vestahealthcare/common/models';

interface Props {
  evaluation?: EventEvaluation;
  open: boolean;
  onClose: () => void;
  onSubmit: (
    evaluation: EventEvaluation,
    data: EventTriage,
  ) => Promise<boolean>;
}

export const VIDEO_VISIT_STRING = 'Video visit';
const TRIAGE_BASE_URL = 'https://app.cleartriage.com';
const ANSWER_YES = 'Yes';
const ANSWER_NO = 'No';

const useStyles = makeStyles({
  iframe: {
    border: 0,
    borderRadius: '1rem',
  },
  loadingContainer: {
    display: 'flex',
    height: 700,
    width: 900,

    '& > span': {
      margin: 'auto',
    },
  },
  modal: {
    '&& .MuiDialog-container .MuiPaper-root': {
      maxWidth: 'fit-content',
    },
  },
});

export const MemberEvaluationAddTriageModal = ({
  evaluation,
  open,
  onClose,
  onSubmit,
}: Props) => {
  const token = config.clearTriageToken;
  const styles = useStyles();
  const iframe = useRef<HTMLIFrameElement>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => setLoading(true), [open]);

  const initSession = () => {
    iframe.current?.contentWindow?.postMessage(
      {
        subject: 'ct-init',
      },
      TRIAGE_BASE_URL,
    );
    setLoading(false);
  };

  const parseTriageData = ({
    callSummary,
    callSummaryText,
  }: {
    callSummary: ClearTriageModel;
    callSummaryText: string;
  }) => {
    const { narrativeText, callDisposition, protocols } = callSummary;
    const questions = [] as EventTriageQuestion[];
    const adviceDetails = [] as EventTriageAdvice[];
    const handouts = [] as EventTriageHandout[];

    protocols.forEach(
      ({
        title: protocol,
        positiveQuestions,
        negativeQuestions,
        adviceSections,
        handoutsSent,
      }) => {
        positiveQuestions?.forEach((question) =>
          questions.push(
            new EventTriageQuestion({
              question,
              answer: ANSWER_YES,
              protocol,
            }),
          ),
        );
        negativeQuestions?.forEach((question) =>
          questions.push(
            new EventTriageQuestion({
              question,
              answer: ANSWER_NO,
              protocol,
            }),
          ),
        );
        adviceSections?.forEach(({ adviceTopics }) =>
          adviceTopics?.forEach(({ title, adviceDetails: details }) => {
            if (details?.length) {
              details.forEach((detail) =>
                adviceDetails.push(
                  new EventTriageAdvice({
                    title,
                    detail,
                    protocol,
                  }),
                ),
              );
            } else {
              adviceDetails.push(
                new EventTriageAdvice({
                  title,
                  protocol,
                }),
              );
            }
          }),
        );
        handoutsSent?.forEach(({ title, method, address }) =>
          handouts.push(
            new EventTriageHandout({
              title,
              method,
              address,
              protocol,
            }),
          ),
        );
      },
    );

    const videoPart = callSummaryText
      .split('\r\n')
      .find((item) => item.startsWith(VIDEO_VISIT_STRING));
    if (videoPart) {
      adviceDetails.push(
        new EventTriageAdvice({
          title: VIDEO_VISIT_STRING,
          detail: videoPart.replace(`${VIDEO_VISIT_STRING} `, ''),
        }),
      );
    }

    return {
      adviceDetails,
      disposition: callDisposition.title,
      handouts,
      narrative: narrativeText,
      questions,
    } as EventTriage;
  };

  const doSubmit = async (evaluation: EventEvaluation, event: MessageEvent) => {
    if (event.origin === TRIAGE_BASE_URL) {
      setLoading(true);
      try {
        const triageData = parseTriageData(event.data.messageBody);
        const { questions, handouts, adviceDetails } = triageData;
        if (questions?.length || handouts?.length || adviceDetails?.length) {
          await onSubmit(evaluation, triageData);
        }
      } catch (e) {
        showGlobalError(e as string);
      }
      onClose();
    } else {
      showGlobalError('Event broadcast from unexpected origin');
    }
  };

  const manageIframeEvents = (evaluation: EventEvaluation) => (
    event: MessageEvent,
  ) => {
    if (event.data && event.data.subject) {
      if (event.data.subject === 'ct-ready') {
        initSession();
      } else if (event.data.subject === 'ct-init-err') {
        showGlobalError(`Error opening triage: ${event.data.messageBody}`);
      } else if (event.data.subject === 'ct-call-summary') {
        doSubmit(evaluation, event);
      }
    }
  };

  useEffect(() => {
    if (evaluation) {
      const handler = manageIframeEvents(evaluation);
      window.addEventListener('message', handler);
      return () => window.removeEventListener('message', handler);
    }
  }, [evaluation]);

  return (
    <Modal
      className={styles.modal}
      open={open}
      title={translate('memberEvents.addTriageModalTitle')}
      onClose={onClose}
      maxWidth="md"
    >
      {open && (
        <iframe
          className={styles.iframe}
          height={window.document.body.clientHeight - 175}
          ref={iframe}
          src={`${TRIAGE_BASE_URL}/app/login/sso/${token}?firstName=${Session.actingUser.firstName}`}
          title="Triage"
          style={{
            visibility: loading ? 'hidden' : 'visible',
            position: loading ? 'absolute' : 'inherit',
          }}
          width="900"
        />
      )}
      {open && loading && (
        <div className={styles.loadingContainer}>
          <Spinner width={80} color="primary" />
        </div>
      )}
    </Modal>
  );
};

export default MemberEvaluationAddTriageModal;
