import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { BaseEnum, EventDetail } from '@vestahealthcare/common/models';

import { Button, CollapsableSidebar, Select, TextInput } from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import { InvalidateEventParams } from 'dash/src/services/EventServices';

const OTHER = 'OTHER';

interface Props {
  event?: EventDetail;
  open: boolean;
  onClose: () => void;
  onSubmit: (
    event: EventDetail,
    params: InvalidateEventParams,
  ) => Promise<boolean>;
}

export const MemberEventInvalidate = ({
  event,
  open,
  onClose,
  onSubmit,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [reasons, setReasons] = useState<BaseEnum[]>([]);
  const [reason, setReason] = useState<BaseEnum>();
  const [reasonOther, setReasonOther] = useState<string>();
  const [submitted, setSubmitted] = useState(false);

  const reset = () => {
    setLoading(false);
    setSubmitted(false);
    setReason(undefined);
  };

  const getInitialData = async () => {
    const reasons = await CacheServices.getEventInvalidateReaons();
    setReasons(reasons);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  const doSubmit = async () => {
    setSubmitted(true);

    if (event && reason && (reason.id !== OTHER || reasonOther)) {
      setLoading(true);
      try {
        const result = await onSubmit(event, {
          invalidatedReasonId: reason.id,
          invalidNote: reasonOther,
        });
        if (result) {
          onClose();
        }
      } finally {
        setSubmitted(false);
        setLoading(false);
      }
    }
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={<h2>{translate('memberEvents.modals.invalidateEventTitle')}</h2>}
      size={400}
    >
      <CollapsableSidebar.Body>
        <Select
          error={submitted && !reason}
          getItemLabel={({ description }: BaseEnum) => description}
          items={reasons}
          label={translate('memberEvents.modals.invalidateReason')}
          onChange={setReason}
          required
          value={reason}
        />
        {reason?.id === OTHER && (
          <TextInput
            error={submitted && reasonOther === undefined}
            label={translate('memberEvents.modals.other')}
            onChange={setReasonOther}
            required
            value={reasonOther}
          />
        )}
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="invalidate-event-close"
          onClick={onClose}
        >
          {translate('global.cancel')}
        </Button>
        <Button
          color="secondary"
          data-cy="invalidate-event-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {translate('memberEvents.modals.invalidateEvent')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default MemberEventInvalidate;
