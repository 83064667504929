import React, { PropsWithChildren } from 'react';

import {
  ThemeProvider as MThemeProvider,
  Theme,
  createTheme,
} from '@mui/material/styles';
import { StylesProvider, createGenerateClassName } from '@mui/styles';

import { Colors } from 'styleguide-v2/src/styles/Colors';
import { Fonts } from 'styleguide-v2/src/styles/Variables';

export const CustomTheme = createTheme({
  palette: {
    primary: {
      main: Colors.green,
      contrastText: Colors.white,
    },
    info: {
      main: Colors.blue,
      contrastText: Colors.white,
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: Fonts.fontFamily,
  },
});

type Props = {
  theme?: Theme;
};

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
  seed: 'v2',
});

export const ThemeProvider = ({
  children,
  theme,
}: PropsWithChildren<Props>) => (
  <StylesProvider generateClassName={generateClassName}>
    <MThemeProvider theme={theme || CustomTheme}>{children}</MThemeProvider>
  </StylesProvider>
);

export default ThemeProvider;
