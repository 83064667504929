import React from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { CareTeamMember } from '@vestahealthcare/common/models';

import { Chip } from 'styleguide-v2';

type Props = {
  member: CareTeamMember;
};

export const CareTeamMemberPermissions = ({ member }: Props) => (
  <>
    {member.emergencyContact && (
      <Chip color="secondary" type="outlined" size="small">
        {translate('careTeam.permissions.emergencyContact')}
      </Chip>
    )}
    {member.phiAccess ? (
      <Chip color="secondary" type="outlined" size="small">
        {translate('careTeam.permissions.phiAccess')}
      </Chip>
    ) : (
      <Chip color="error" type="outlined" size="small">
        {translate('careTeam.permissions.notPhiAccess')}
      </Chip>
    )}
    {member.phiExceptions && (
      <Chip color="secondary" type="outlined" size="small">
        {translate('careTeam.permissions.phiExceptions')}
      </Chip>
    )}
    {member.healthCareProxy && (
      <Chip color="secondary" type="outlined" size="small">
        {translate('careTeam.permissions.healthCareProxy')}
      </Chip>
    )}
    {member.hciNotifications && (
      <Chip color="secondary" type="outlined" size="small">
        {translate('careTeam.permissions.hciNotifications')}
      </Chip>
    )}
  </>
);
