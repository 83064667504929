import { Enum } from './Enum';

export class MemberRisk extends Enum {
  translationKey = 'memberRisk';

  static asArray: MemberRisk[] = [];

  static byKey: { [name: string]: MemberRisk } = {};

  static readonly LOW = new MemberRisk('LOW');

  static readonly MEDIUM = new MemberRisk('MEDIUM');

  static readonly HIGH = new MemberRisk('HIGH');

  static COLOR_MAP = new Map<MemberRisk, 'secondary' | 'warning' | 'error'>([
    [MemberRisk.LOW, 'secondary'],
    [MemberRisk.MEDIUM, 'warning'],
    [MemberRisk.HIGH, 'error'],
  ]);

  static toSelectable() {
    return Enum.toSelectable(MemberRisk.asArray);
  }

  constructor(public readonly value: string) {
    super();
    MemberRisk.asArray.push(this);
    MemberRisk.byKey[value] = this;
  }

  get description() {
    return this.toString();
  }
}

export default MemberRisk;
