import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';

import { translate } from '@vestahealthcare/common/i18n';
import { EventDetail, Patient } from '@vestahealthcare/common/models';
import { Fonts, IconButton, Modal } from 'styleguide-v2';
import classnames from 'classnames';
import EventLink from '@vestahealthcare/common/models/EventLink';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  label: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
  },
  icon: {
    '&&': {
      padding: 0,
      marginLeft: '0.25rem',
    },
  },
});

type Props = {
  className?: string;
  event?: EventDetail;
  onRemove: (evt: EventLink) => void;
  patient?: Patient;
};

export const EventDetailLinkedEvents = ({
  className,
  event,
  onRemove,
  patient,
}: Props) => {
  const styles = useStyles();
  const [removeLink, setRemoveLink] = useState<EventLink>();
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  return !event?.links?.length || !patient ? (
    <></>
  ) : (
    <div className={classnames(className, styles.container)}>
      <span className={styles.label}>
        {translate('memberEvents.detail.linkedEvents')}
      </span>
      {event.links?.map((link) => {
        const { linkReason, sourceEvent, targetEvent } = link;
        const reason =
          sourceEvent.id === event.id
            ? linkReason.reason
            : linkReason.oppositeReason || linkReason.reason;
        const currentEvent =
          sourceEvent.id === event.id ? targetEvent : sourceEvent;
        return (
          <p key={`event-${event.id}-link-${currentEvent.id}`}>
            <span>
              {reason?.charAt(0).toLocaleUpperCase()}
              {reason.substring(1)}
            </span>{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={`/#/patients/${patient?.id}/events/${currentEvent.id}`}
            >
              {currentEvent.type.name}
            </a>
            <IconButton
              className={styles.icon}
              size="small"
              onClick={() => {
                setOpenConfirmModal(true);
                setRemoveLink(link);
              }}
            >
              <DeleteIcon color="primary" />
            </IconButton>
          </p>
        );
      })}
      <Modal
        title={translate('memberEvents.modals.confirmDeleteLinkTitle')}
        body={translate('memberEvents.modals.confirmDeleteLinkBody', {
          event1: removeLink?.sourceEvent.type.name,
          event2: removeLink?.targetEvent.type.name,
        })}
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        onSubmit={async () => {
          if (removeLink) {
            await onRemove(removeLink);
          }
        }}
      />
    </div>
  );
};
export default EventDetailLinkedEvents;
