import { Enum } from './Enum';

export class Brand extends Enum {
  translationKey = 'brand';

  static asArray: Brand[] = [];

  static byKey: { [name: string]: Brand } = {};

  static readonly VESTA = new Brand('VESTA');

  static readonly CARE_AT_HOME = new Brand('CARE_AT_HOME');

  static readonly ALL = new Brand('ALL');

  constructor(public readonly value: string) {
    super();
    Brand.asArray.push(this);
    Brand.byKey[value] = this;
  }
}

export default Brand;
