import React, { Fragment } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { IncidentDetailMember } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Card, CardContent, CardHeader, Label } from 'styleguide-v2';

type Props = {
  className?: string;
  member?: IncidentDetailMember;
};

export const IncidentsDetailMember = ({ className, member }: Props) => {
  if (!member) return <></>;
  return (
    <Card className={className}>
      <CardHeader title={translate('incidents.detail.memberCardTitle')} />
      <CardContent className="grid-wrapper fit" sx={{ paddingTop: 0 }}>
        <div className="grid-span-6">
          <Label>{translate('incidents.common.member')}</Label>
          <p className="no-margin">
            {member?.fullName}{' '}
            <a
              href={`#/patients/${member?.id}`}
              rel="noreferrer"
              target="_blank"
            >
              ({member?.id})
            </a>
          </p>
          <p className="no-margin">
            {member?.gender?.toString()} {member?.birthDateFormatted}
          </p>
        </div>
        <div className="grid-span-6">
          <Label>{translate('incidents.common.location')}</Label>
          <p className="no-margin">
            {member?.address
              ? `${member?.address?.city}, ${member?.address?.state?.value}`
              : EMPTY}
          </p>
        </div>
        <div className="grid-span-6">
          <Label>{translate('incidents.common.npOwner')}</Label>
          <p className="no-margin">{member?.npOwner?.fullName || EMPTY}</p>
        </div>
        <div className="grid-span-6">
          <Label>{translate('incidents.common.rnOwner')}</Label>
          <p className="no-margin">{member?.rnOwner?.fullName || EMPTY}</p>
        </div>
        <div className="grid-span-6">
          <Label>{translate('incidents.detail.memberReferralSource')}</Label>
          {member?.activeReferrals?.filter(
            ({ discontinuedAt }) => !discontinuedAt,
          ).length ? (
            member?.activeReferrals
              ?.filter(({ discontinuedAt }) => !discontinuedAt)
              .map(({ organization: { name, id }, externalId }) => (
                <p
                  className="no-margin"
                  key={`incident-member-${member?.id}-referral-${id}`}
                >
                  {name} <span className="gray">({externalId})</span>
                </p>
              ))
          ) : (
            <p className="no-margin">{EMPTY}</p>
          )}
        </div>
        <div className="grid-span-6">
          <Label>{translate('incidents.common.insurancePlan')}</Label>
          {member?.memberInsurances?.length ? (
            member?.memberInsurances?.map(
              ({
                insurancePlan: { id, name },
                insurancePriority,
                mltcPlan,
              }) => (
                <Fragment key={`incident-member-${member?.id}-referral-${id}`}>
                  <p className="no-margin">
                    {name}
                    {'  '}
                    <span className="gray capitalize">
                      ({insurancePriority?.name?.toLocaleLowerCase()})
                    </span>
                  </p>
                  {mltcPlan?.name && (
                    <p
                      className="no-margin"
                      style={{ display: 'list-item', marginLeft: '2rem' }}
                    >
                      {mltcPlan?.name}
                    </p>
                  )}
                </Fragment>
              ),
            )
          ) : (
            <p className="no-margin">{EMPTY}</p>
          )}
        </div>
      </CardContent>
    </Card>
  );
};
