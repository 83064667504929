import { Enum } from './Enum';

export class MemberEligibilityStatus extends Enum {
  translationKey = 'memberEligibilityStatus';

  static asArray: MemberEligibilityStatus[] = [];

  static byKey: { [name: string]: MemberEligibilityStatus } = {};

  static readonly YES = new MemberEligibilityStatus('YES');

  static readonly NO = new MemberEligibilityStatus('NO');

  static readonly NOT_RAN = new MemberEligibilityStatus('NOT_RAN');

  static readonly PENDING = new MemberEligibilityStatus('PENDING');

  static readonly QUEUED = new MemberEligibilityStatus('QUEUED');

  static readonly UNDETERMINED = new MemberEligibilityStatus('UNDETERMINED');

  static readonly ERROR = new MemberEligibilityStatus('ERROR');

  static readonly CHECK = new MemberEligibilityStatus('CHECK');

  constructor(public readonly value: string) {
    super();
    MemberEligibilityStatus.asArray.push(this);
    MemberEligibilityStatus.byKey[value] = this;
  }
}

export default MemberEligibilityStatus;
