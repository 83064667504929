import moment, { Moment } from 'moment';

import { MemberDashboardPharmacyUnderstanding } from '../enums/MemberDashboardPharmacyUnderstanding';
import { DATE_FORMAT } from '../utils/constants';

export class MemberDashboardPharmacy {
  access = '';

  additional = '';

  insurance = '';

  lastAppointmentDate?: Moment;

  lastAppointmentEmployee = '';

  recommendations = '';

  understandingOfMedications: MemberDashboardPharmacyUnderstanding;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.access = obj.barriersToAdherence?.access || '';
    this.additional = obj.barriersToAdherence?.additional || '';
    this.insurance = obj.barriersToAdherence?.insurance || '';
    this.lastAppointmentDate = obj.lastAppointmentDate
      ? moment(obj.lastAppointmentDate, DATE_FORMAT)
      : undefined;
    this.understandingOfMedications =
      MemberDashboardPharmacyUnderstanding.byKey[
        obj.understandingOfMedications
      ];
  }
}

export default MemberDashboardPharmacy;
