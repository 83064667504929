import { CareTeamMember } from './CareTeamMember';

export class CareTeamMemberOther extends CareTeamMember {
  organizationName?: string;

  constructor(obj: any) {
    super(obj);

    this.organizationName = obj.organizationName;
  }
}

export default CareTeamMemberOther;
