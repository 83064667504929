import { Choice } from './Choice';
import { Language } from './Language';
import { SelectComponent, createFilterOptions as cf } from './Select';

export const createFilterOptions = cf;

export const Select = Object.assign(SelectComponent, {
  Choice,
  Language,
});

export default Select;
