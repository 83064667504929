import { Employee, EventMember } from '.';
import { PatientGender } from '../enums';
import { make } from './Model';

export class TOCDetailMember extends EventMember {
  gender?: PatientGender;

  npOwner?: Employee;

  constructor(obj: any) {
    super(obj);
    this.gender = PatientGender.byKey[obj.gender];
    this.npOwner = make(obj.npOwner, Employee);
  }
}

export default TOCDetailMember;
