import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Panel } from 'styleguide';
import {
  Build,
  BuildDetails,
  CopyLink,
  getFullDetails,
  JiraLink,
  InstallButton,
  ReleaseInfo,
} from './ReleaseInfo';
import { getReleaseDetails, DashboardType, getReleases } from './services';

interface RouteParams {
  deviceType: DashboardType;
  releaseId: string;
}

export const ReleasePage = () => {
  const { deviceType, releaseId }: RouteParams = useParams<
    RouteParams
  >() as RouteParams;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [release, setRelease] = useState<Build>();
  const [details, setDetails] = useState<BuildDetails>();

  const fetchAppVersion = async () => {
    try {
      setLoading(true);
      const releases: Build[] = await getReleases(deviceType);
      const release = releases.find(
        (release) => release.id === Number(releaseId),
      );
      if (!release) {
        throw new Error('Could not fetch release.');
      }
      const releaseDetails = await getReleaseDetails(
        deviceType,
        release.appOS,
        String(release.id),
      );
      if (!releaseDetails) {
        throw new Error('Could not fetch details.');
      }
      setRelease(release);
      setDetails(getFullDetails(releaseDetails) as BuildDetails);
      setError(undefined);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(`Could not find ${deviceType} release "${releaseId}".`);
    }
  };

  useEffect(() => {
    fetchAppVersion();
  }, [releaseId, deviceType]);

  return (
    <Panel>
      <Panel.Heading>
        <h2>
          {deviceType} Build {releaseId}
          <CopyLink deviceType={deviceType} releaseId={releaseId} />
        </h2>
        {!error && !loading && (
          <div className="right-side">
            <JiraLink details={details} />
            <InstallButton release={release} details={details} />
          </div>
        )}
      </Panel.Heading>
      <Panel.Body loading={loading}>
        {error && <p>{error}</p>}
        <ReleaseInfo
          deviceType={deviceType}
          details={details}
          release={release}
        />
      </Panel.Body>
    </Panel>
  );
};

export default ReleasePage;
