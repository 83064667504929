import React from 'react';
import { Modal } from 'styleguide';
import images from 'dash/static/images';

interface Props {
  onHide: () => void;
  show?: boolean;
}

const exampleText = `This is an example of markdown.
**This is bold text.**
_This is italic text._

# H1
## H2
### H3
#### H4
##### H5

Bulleted List:
- Elephants
- Rattlesnakes
- Flamingos

Ordered List:
1. Cake
2. Pie
3. Scones`;

export const MarkdownModal = ({ onHide, show }: Props) => (
  <Modal modalSize="lg" onHide={onHide} show={show}>
    <Modal.Header modalTitle="Markdown Instructions" closeButton />
    <Modal.Body>
      <p>
        <a
          href="https://guides.github.com/features/mastering-markdown/"
          target="_blank"
          rel="noopener noreferrer"
        >
          See here
        </a>{' '}
        for a full explanation of markdown syntax.
      </p>
      <hr />
      <div className="grid-wrapper">
        <div className="grid-span-6">
          <p className="medium">Available syntax</p>
          <p>{exampleText}</p>
        </div>
        <div className="grid-span-6">
          <p className="medium">Renders as</p>
          <img
            alt="Markdown Example"
            src={images.markdownExample}
            width="300"
          />
        </div>
      </div>
    </Modal.Body>
  </Modal>
);

export default MarkdownModal;
