import React, { ReactNode, SyntheticEvent, useEffect, useState } from 'react';

import { Box, Tabs as MTabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Fonts } from 'styleguide-v2/src/styles/Variables';

export type TabsItem = {
  disabled?: boolean;
  label: ReactNode;
  value?: any;
};

interface Props {
  className?: string;
  'data-cy'?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  items: TabsItem[];
  onChange: (value: any) => void;
  onClick?: () => void;
  value?: any;
}

const useStyles = makeStyles({
  fullWidth: {
    flex: 1,
  },
  tabContainer: {
    '&& > .MuiTabs-scroller': {
      overflowX: 'auto!important',

      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',
    },

    '&& > .MuiTabs-scroller::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

const tabStyles = {
  '&&': {
    padding: '0 0.5rem',
  },
  '& span': {
    fontSize: Fonts.fontSize,
  },
};

export const Tabs = ({
  className,
  'data-cy': dataCy,
  disabled,
  fullWidth,
  items,
  onChange,
  onClick,
  value,
}: Props) => {
  const styles = useStyles();
  const [currentValue, setValue] = useState(
    value || items[0]?.value || items[0]?.label,
  );

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: any,
  ) => {
    setValue(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <Box className={className} data-cy={dataCy} sx={{ width: '100%' }}>
      <MTabs
        className={styles.tabContainer}
        onChange={handleChange}
        value={currentValue}
      >
        {items.map(({ disabled: itemDisabled, label, value: itemValue }) => (
          <Tab
            className={fullWidth ? styles.fullWidth : ''}
            disabled={itemDisabled || disabled}
            key={itemValue}
            value={itemValue || label}
            label={label}
            onClick={() => onClick && onClick()}
            sx={tabStyles}
          />
        ))}
      </MTabs>
    </Box>
  );
};

export default Tabs;
