import { AsYouTypeFormatter } from 'google-libphonenumber';
import React from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { validate } from '@vestahealthcare/common/utils/phone';

import { TextInput } from '../TextInput';

type Props = {
  className?: string;
  'data-cy'?: string;
  disabled?: boolean;
  label?: string;
  onChange: (value?: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  tooltip?: string;
  value?: string;
  size?: 'medium' | 'small';
  submitted?: boolean;
};

export const PhoneInput = ({
  className,
  'data-cy': dataCy,
  disabled,
  label,
  onChange,
  placeholder,
  readOnly,
  required,
  tooltip,
  value,
  size,
  submitted,
}: Props) => {
  const normalize = (str?: string) => str?.replace(/\D/g, '') ?? '';
  const formatter = new AsYouTypeFormatter('US');
  const formatAsPhoneNumber = (str?: string): string => {
    formatter.clear();
    return [...normalize(str)]
      .slice(0, 10)
      .reduce((_total, digit) => formatter.inputDigit(digit), '');
  };

  return (
    <TextInput
      className={className}
      data-cy={dataCy}
      disabled={disabled}
      error={
        submitted &&
        (value
          ? !validate(value) &&
            translate('components.editPhones.phoneNumberInvalid')
          : translate('global.missingRequiredFieldMin'))
      }
      label={label}
      maxLength={14}
      onChange={(val) => onChange(normalize(val))}
      placeholder={placeholder}
      readOnly={readOnly}
      tooltip={tooltip}
      value={value ? formatAsPhoneNumber(value) : undefined}
      required={required}
      size={size}
    />
  );
};
