import React, { useState } from 'react';
import { FieldProps } from 'react-jsonschema-form';
import { translate } from '@vestahealthcare/common/i18n';
import { Button, TextInput, AddIcon } from 'styleguide';

const CommentField = (props: FieldProps) => {
  const {
    schema: { title },
    formData,
    label,
    onChange,
    formContext: { readonly },
  } = props;
  const [isButton, setIsButton] = useState(true);

  if (readonly) {
    if (!formData) {
      return null;
    }

    return <p className="remove-top-margin italic">{formData}</p>;
  }

  if (isButton && !formData) {
    return (
      <Button
        color="secondary"
        className="comment-field"
        data-cy="add-comment-button"
        isPill
        onClick={() => setIsButton(false)}
      >
        <AddIcon className="margin-right" />
        {translate('formBuilder.addComment')}
      </Button>
    );
  }

  return (
    <div className="comment-field">
      <TextInput
        data-cy="add-comment"
        autoFocus={!formData}
        onBlur={onChange}
        value={formData}
        placeholder={label || title}
      />
    </div>
  );
};

export default CommentField;
