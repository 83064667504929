import { JSONSchema6 } from 'json-schema';
import React, { PureComponent } from 'react';
import { Prompt, RouteComponentProps, withRouter } from 'react-router-dom';

import { ObservationCategory } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { ObservationAnswers, Patient } from '@vestahealthcare/common/models';

import { Button, EditIcon, JsonSchemaForm, Panel, Warning } from 'styleguide';

import config from 'dash/environments';
import { showGlobalError } from 'dash/src/components/GlobalMessage';
import {
  fetchObservations,
  fetchSchema,
  updateObservations,
} from 'dash/src/services/ObservationServices';
import { fetchById } from 'dash/src/services/PatientServices';

type Params = {
  category: string;
  patientId: string;
};

interface Props extends RouteComponentProps<Params> {}

interface State {
  formData?: ObservationAnswers;
  patient?: Patient;
  hasError?: boolean;
  isEditing: boolean;
  isLoading?: boolean;
  isSubmitting?: boolean;
  formSchema?: JSONSchema6;
  uiSchema?: {
    [key: string]: any;
  };
  error?: string;
}

class ClinicalProfileObservations extends PureComponent<Props, State> {
  state: State = {
    isEditing: false,
    isLoading: true,
  };

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error: error.toString() };
  }

  async componentDidMount() {
    await this.fetchPatient();
    await this.fetchObservations();
  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.match.params.category !== this.props.match.params.category) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isEditing: false });
      await this.fetchPatient();
      await this.fetchObservations();
    }
  }

  async fetchObservations() {
    this.setState({
      isLoading: true,
    });
    const {
      match: {
        params: { category, patientId },
      },
    } = this.props;
    const [{ formSchema, uiSchema }, formData] = await Promise.all([
      fetchSchema(category),
      fetchObservations(patientId, category),
    ]);
    this.setState({
      formSchema,
      uiSchema,
      formData,
      hasError: false,
      isLoading: false,
    });
  }

  async fetchPatient() {
    const {
      match: {
        params: { patientId },
      },
    } = this.props;
    const patient = await fetchById(patientId);
    this.setState({
      patient,
    });
  }

  submitForm = async (answers: { [key: string]: any }) => {
    const { formData } = this.state;
    const {
      match: {
        params: { category, patientId },
      },
    } = this.props;

    if (!formData) {
      throw new Error('No form loaded');
    }

    this.setState({
      isSubmitting: true,
    });

    let newFormData;

    try {
      newFormData = await updateObservations(patientId, category, {
        answers,
        version: formData.version,
      });
    } catch (err) {
      this.setState({
        isSubmitting: false,
      });
      showGlobalError(err as string);
      return;
    }

    this.setState({
      formData: newFormData,
      isEditing: false,
      isSubmitting: false,
    });
  };

  render() {
    const {
      match: { params },
    } = this.props;
    const { isProd } = config;
    const {
      formData,
      patient,
      hasError,
      isEditing,
      isLoading,
      isSubmitting,
      formSchema,
      uiSchema,
      error,
    } = this.state;

    if (hasError) {
      if (!isProd) {
        console.error(error);
      }

      return (
        <>
          <p>{translate('global.genericError')}</p>
          {!isProd && <Warning>{error}</Warning>}
        </>
      );
    }

    const category = ObservationCategory.byKey[params.category];
    if (!category) {
      return (
        <p>
          Not a valid observation category:
          {params.category}
        </p>
      );
    }

    return (
      <div id="page">
        <Panel
          id="observations-page"
          data-cy="observations-page"
          className="observations-page"
        >
          <Panel.Heading>
            <h2>{translate('sidebar.observations')}</h2>
            <div className="right-side">
              {!isEditing && patient?.isEditable() && (
                <Button
                  color="secondary"
                  isPill
                  onClick={() => this.setState({ isEditing: true })}
                >
                  <EditIcon marginRight />
                  {translate('global.edit')}
                </Button>
              )}
            </div>
          </Panel.Heading>
          <Panel.Body loading={isLoading} className="grid-wrapper-none">
            <div className="h7 grid-span-7">{category.toString()}</div>
            <Prompt when={isEditing} message="" />
            {formData && (
              <aside className="grid-span-5">
                <p className="small text-right">
                  {translate('global.lastUpdated', {
                    date: formData.getUpdatedAtFormatted(),
                  })}
                  {formData.updatedBy && ` | ${formData.updatedBy.fullName}`}
                </p>
              </aside>
            )}
            {formSchema && uiSchema && formData && (
              <JsonSchemaForm
                className="grid-span-12"
                schema={formSchema}
                isSubmitting={isSubmitting}
                uiSchema={uiSchema}
                formData={formData.answers}
                onCancel={() => {
                  this.setState({ isEditing: false });
                  this.fetchObservations();
                }}
                onSubmit={this.submitForm}
                readonly={!isEditing}
              />
            )}
            {!isEditing && (
              <PhipLink patientId={params.patientId} category={category} />
            )}
          </Panel.Body>
        </Panel>
      </div>
    );
  }
}

export const PhipLink = ({
  patientId,
  category,
}: {
  patientId: string;
  category: ObservationCategory;
}) => {
  const careTrack = category.linkedCareTrack;
  const text = careTrack
    ? translate('observations.setTrackRiskLevel', {
        careTrack: careTrack.toString(),
      })
    : translate('observations.goToPhip');

  return (
    <article className="grid-span-12">
      <div className="right-side">
        <Button
          color="secondary"
          href={`/#/patients/${patientId}/phip-profile/${
            careTrack ? careTrack.valueOf() : ''
          }`}
        >
          {text}
        </Button>
      </div>
    </article>
  );
};

export default withRouter(ClinicalProfileObservations);
