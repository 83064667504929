import { useFlags } from 'launchdarkly-react-client-sdk';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';

import { Selectable } from '@vestahealthcare/common/enums/Enum';
import TaskStatus from '@vestahealthcare/common/enums/TaskStatus';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  Encounter,
  Task,
} from '@vestahealthcare/common/models';
import TaskCustomField from '@vestahealthcare/common/models/TaskCustomField';
import TaskDefinition from '@vestahealthcare/common/models/TaskDefinition';
import TaskDefinitionOutcome from '@vestahealthcare/common/models/TaskDefinitionOutcome';
import TaskNote from '@vestahealthcare/common/models/TaskNote';
import { DATE_FORMAT_SHORT_WITH_TIME } from '@vestahealthcare/common/utils/constants';

import {
  Button,
  DateTimePicker,
  Link,
  Modal,
  Section,
  Select,
  Text,
  TextInput,
} from 'styleguide';

import EmployeeDropdown from 'dash/src/components/EmployeeDropdown';
import { AddEncounterButton } from 'dash/src/pages/MemberProfile/Encounters/AddEncounterButton';
import { TaskAttachments } from 'dash/src/pages/Tasks/TaskAttachments';
import { CacheServices } from 'dash/src/services';
import { fetchById as fetchMemberById } from 'dash/src/services/PatientServices';
import { orderRPMDevices } from 'dash/src/services/RPMVendorServices';
import Session from 'dash/src/services/SessionServices';
import {
  editTask,
  fetchTaskDefinitionOutcomes,
  fetchTaskNotes,
  updateTaskCustomField,
} from 'dash/src/services/TaskServices';

import { fetchAllEncounters } from '../../services/EncounterServices';
import { ContactInfoModal } from '../MemberProfile/Header/ContactInfoModal';
import EditTaskCustomField from './EditTaskCustomField';
import { OrderDeviceModal } from './OrderDeviceModal';
import TaskNoteDetail from './TaskNote';
import { getDueDate, roundToNearest30 } from './utils';

const COLUMNS = 2;

const useStyles = makeStyles({
  section: {
    marginBottom: 10,
  },
  details: {
    padding: 20,
  },
  header: {
    margin: -20,
    paddingBottom: 5,
    marginBottom: 10,
  },
  secondaryBtn: {
    margin: '0px 20px 20px 0px',
    width: 'fit-content',
  },
  primaryBtn: {
    margin: '0 20px 20px 0',
    width: 'fit-content',
  },
});

interface CustomFieldAnswer {
  [key: string]: string | undefined;
}

const getCustomFieldAnswers = (customFields?: TaskCustomField[]) => {
  if (!customFields) return {};

  const answers: CustomFieldAnswer = {};
  customFields.forEach((c) => (answers[`${c.id}`] = c.value));
  return answers;
};

interface Props {
  task: Task;
  hideAssignee?: boolean;
  hideGroup?: boolean;
  hideStatus?: boolean;
  onUpdate: (newTask: Task) => void;
}

export const TaskDetails = ({
  task,
  hideAssignee,
  hideGroup,
  hideStatus,
  onUpdate,
  history,
}: Props & RouteComponentProps) => {
  const { pathname } = useLocation();
  const { showOrderSmartMeterDevice } = useFlags();
  const [member, setMember] = useState(task.member);
  const [notes, setNotes] = useState<TaskNote[]>([]);
  const [description, setDescription] = useState<string>();
  const [status, setStatus] = useState<string>(task.status.value);
  const [outcome, setOutcome] = useState<
    TaskDefinitionOutcome['id'] | undefined
  >(task.taskDefinitionOutcome?.id);
  const [employeeGroupId, setEmployeeGroupId] = useState<number>(
    task.employeeGroup?.id,
  );
  const [assigneeId, setAssigneeId] = useState<number | undefined>(
    task.assignee?.id,
  );
  const [date, setDate] = useState<Moment | undefined>(
    task.dueDate ? moment.unix(task.dueDate) : undefined,
  );
  const [time, setTime] = useState<Moment | undefined>(
    task.dueDate ? moment.unix(task.dueDate) : undefined,
  );
  const [customFieldAnswers, setCustomFieldAnswers] = useState<
    CustomFieldAnswer
  >(getCustomFieldAnswers(task.customFields));

  const [showOpenSubtaskWarning, setShowOpenSubtasksWarning] = useState(false);
  const [showNoOutcomeError, setShowNoOutcomeError] = useState(false);
  const [showNoCustomFieldError, setShowNoCustomFieldError] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showOrderDeviceModal, setShowOrderDeviceModal] = useState(false);

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [employeeGroups, setEmployeeGroups] = useState<EmployeeGroup[]>([]);
  const [definitionOutcomes, setDefinitionOutcomes] = useState<Selectable[]>(
    [],
  );
  const [encounters, setEncounters] = useState<Encounter[]>([]);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const isClosed = task.status.closed && !Session.actingUser.isAdmin;

  const styles = useStyles();

  const filterOutcomes = (task: Task) => {
    if (task.status === TaskStatus.NOT_STARTED) {
      return ({ enabled }: TaskDefinitionOutcome) => enabled;
    }
    if (TaskStatus.getNotClosedStatuses().includes(task.status.value)) {
      return ({ enabled, id }: TaskDefinitionOutcome) =>
        enabled || task.taskDefinitionOutcome?.id === id;
    }
    return () => true;
  };

  const mapOutcomes = ({
    enabled,
    id: value,
    name: label,
  }: TaskDefinitionOutcome) => ({
    label,
    value,
    disabled: !enabled,
  });

  useEffect(() => {
    (async () => {
      const taskIds = [task.id];

      if (task.children) {
        taskIds.push(...task.children.map((c) => c.id));
      }

      // @ts-ignore next
      const [o, e, g, n, en] = await Promise.all([
        fetchTaskDefinitionOutcomes(task.taskDefinition.id),
        CacheServices.getEmployees(),
        CacheServices.getEmployeeGroupsAssignee(),
        fetchTaskNotes(task.id),
        task.member
          ? fetchAllEncounters(task.member.id, { taskIds })
          : { items: [] as Encounter[] },
      ]);
      // @ts-ignore next
      setDefinitionOutcomes(o.filter(filterOutcomes(task)).map(mapOutcomes));
      setEmployees(e as Employee[]);
      setEmployeeGroups(g as EmployeeGroup[]);
      setNotes(n as TaskNote[]);
      // @ts-ignore next
      setEncounters(en.items as Encounter[]);

      if (task.member) {
        const m = await fetchMemberById(task.member.id);
        setMember(m);
      }
    })();
  }, [task]);

  const onStatusChange = (newStatus: string) => {
    setStatus(newStatus);
  };

  const onChangeEmployeeGroup = (groupId: number) => {
    const employeeGroup = employeeGroups.find((g) => g.id === groupId);

    if (employeeGroup) {
      setEmployeeGroupId(employeeGroup.id);

      if (employeeGroup.isNPOwner() && member?.npOwner) {
        setAssigneeId(member.npOwner.id);
      } else if (employeeGroup.isRNOwner() && member?.owner) {
        setAssigneeId(member.owner.id);
      } else {
        setAssigneeId(undefined);
      }
    }
  };

  const updateCustomFields = async () => {
    const updateCustomFieldPromises: Array<Promise<any>> = [];
    const customFieldAnswerKeys = Object.keys(customFieldAnswers);
    if (customFieldAnswerKeys.length > 0) {
      customFieldAnswerKeys.forEach((key) => {
        const answer = customFieldAnswers[key];
        const customField = task.customFields?.find(
          (c) => c.id === Number(key),
        );

        // if the value was changed
        if (customField?.value !== answer) {
          updateCustomFieldPromises.push(
            updateTaskCustomField(task.id, key, answer),
          );
        }
      });
    }

    await Promise.all(updateCustomFieldPromises);
  };

  const hasMissingCustomFields = () => {
    const customFieldWithoutAnswer = task.customFields?.find((cf) => {
      if (cf.required && !customFieldAnswers[cf.id]) {
        return cf;
      }

      return undefined;
    });

    return !!customFieldWithoutAnswer;
  };

  const updateTask = async () => {
    const newTaskDefinitionOutcomeId =
      task.taskDefinitionOutcome === outcome ? undefined : outcome;
    const newStatus = status === task.status.value ? undefined : status;
    const newEmployeeGroupId =
      employeeGroupId === task.employeeGroup.id ? undefined : employeeGroupId;
    const newAssigneeId =
      assigneeId === task.assignee?.id ? undefined : assigneeId;

    // Update the custom fields first because there's some backend logic where some custom fields
    // are required to mark a task as COMPLETED
    await updateCustomFields();

    const newTask = await editTask(task.id, {
      status: newStatus,
      assigneeId: newAssigneeId,
      employeeGroupId: newEmployeeGroupId,
      taskDefinitionOutcomeId: newTaskDefinitionOutcomeId,
      dueDate: getDueDate(date, time),
      note: description,
    });

    setDescription(undefined);
    onUpdate && (await onUpdate(newTask));

    const notes = await fetchTaskNotes(task.id);
    setNotes(notes);
  };

  const onUpdateTask = async () => {
    setSubmitted(true);
    if (status && TaskStatus.byKey[status]) {
      if (
        !outcome &&
        task.isOutcomeRequiredForStatus(TaskStatus.byKey[status])
      ) {
        setShowNoOutcomeError(true);
      } else if (
        TaskStatus.byKey[status] === TaskStatus.COMPLETED &&
        hasMissingCustomFields()
      ) {
        setShowNoCustomFieldError(true);
      } else if (TaskStatus.byKey[status].closed && task.hasOpenSubtasks()) {
        setShowOpenSubtasksWarning(true);
      } else {
        try {
          setLoading(true);
          await updateTask();
        } finally {
          setSubmitted(false);
          setLoading(false);
        }
      }
    }
  };

  return (
    <>
      <Section className={styles.section}>
        <Section.Body>
          <div className={`grid-wrapper ${styles.details}`}>
            <header className={`grid-span-12 h4 ${styles.header}`}>
              {translate('tasks.taskDetails')}
            </header>
            <div className="grid-span-10">
              {task.description || task.taskDefinition.name}
              &nbsp;
              {task.member && task.eventId && (
                <Link
                  href={`#/patients/${task.member.id}/events/${task.eventId}`}
                >
                  ({translate('tasks.createdFromEvent')})
                </Link>
              )}
            </div>
            <div className="grid-span-10">
              {task.taskAttachments?.map((attachment) => (
                <TaskAttachments
                  key={attachment.id}
                  documentId={attachment.documentId}
                />
              ))}
            </div>
            <div className="grid-span-2">
              <div className="pull-right">
                {encounters.length > 0 && (
                  <>
                    <Link
                      href={`/#/patients/${
                        task.member?.id
                      }/encounters?ids=${encounters
                        .map((en) => en.id)
                        .join(',')}`}
                    >
                      {translate(
                        encounters.length > 1
                          ? 'tasks.encounters'
                          : 'tasks.encounter',
                        { count: encounters.length },
                      )}
                    </Link>{' '}
                    (
                    {translate('encounters.minutes', {
                      minutes: encounters.reduce(
                        (prev, curr) => prev + curr.duration,
                        0,
                      ),
                    })}
                    )
                    {encounters.map((encounter) => (
                      <Text key={encounter.id} small>
                        {translate('personalDetails.encounterAdded', {
                          formattedDate: moment
                            .unix(encounter.createdAt)
                            .format(DATE_FORMAT_SHORT_WITH_TIME),
                        })}
                      </Text>
                    ))}
                  </>
                )}
              </div>
            </div>
            {!hideStatus && (
              <Select
                columns={COLUMNS}
                disabled={isClosed}
                errorText={translate('global.missingRequiredField')}
                label={translate('tasks.status')}
                onChange={onStatusChange}
                options={TaskStatus.toSelectable(TaskStatus.asArray)}
                required
                showError={submitted && !status}
                value={status}
              />
            )}
            <Select
              label={translate('tasks.taskOutcome')}
              options={definitionOutcomes}
              value={outcome}
              onChange={(val: TaskDefinitionOutcome['id']) => {
                setOutcome(val);
                setShowNoOutcomeError(false);
              }}
              columns={COLUMNS}
              required={task.isOutcomeRequiredForStatus(
                TaskStatus.byKey[status],
              )}
              showError={showNoOutcomeError}
              errorText={translate('tasks.outcomeIsRequired')}
              disabled={isClosed}
            />
            {!hideGroup && (
              <Select
                onChange={onChangeEmployeeGroup}
                label={translate('tasks.group')}
                options={employeeGroups.map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
                value={employeeGroupId}
                columns={COLUMNS}
                disabled={isClosed}
              />
            )}
            {!hideAssignee && (
              <EmployeeDropdown
                columns={COLUMNS}
                label={translate('tasks.assignedTo')}
                value={assigneeId}
                employees={employees}
                onChange={(assignee: number | null) =>
                  setAssigneeId(assignee || 0)
                }
                disabled={isClosed}
                showAssignToMe={!isClosed}
              />
            )}

            <DateTimePicker
              columns={COLUMNS}
              disabled={isClosed}
              label={translate('tasks.dueDate')}
              onChange={setDate}
              min={moment()}
              value={date}
            />
            <DateTimePicker
              columns={COLUMNS}
              disabled={isClosed}
              date={false}
              label={translate('tasks.dueTime')}
              onChange={(val) => {
                if (!date) {
                  setDate(val);
                }
                setTime(val);
              }}
              min={
                date?.isSame(moment(), 'day')
                  ? roundToNearest30(moment())
                  : moment().startOf('day')
              }
              time
              value={time}
            />

            {task.customFields?.map((customField) => (
              <EditTaskCustomField
                columns={COLUMNS}
                customField={customField}
                disabled={isClosed}
                required={
                  customField.required &&
                  TaskStatus.byKey[status] === TaskStatus.COMPLETED
                }
                key={customField.id}
                onUpdate={(answer) =>
                  setCustomFieldAnswers({
                    ...customFieldAnswers,
                    [customField.id]: answer,
                  })
                }
                showError={
                  customField.required &&
                  showNoCustomFieldError &&
                  !customFieldAnswers[customField.id]
                }
                value={customFieldAnswers[customField.id]}
              />
            ))}

            <TextInput
              multiline={2}
              columns={12}
              disabled={isClosed}
              value={description}
              label={translate('global.note')}
              onChange={setDescription}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {member && (
              <div
                style={{
                  height: 32,
                  marginRight: 20,
                  marginTop: 10,
                }}
              >
                <AddEncounterButton
                  task={task}
                  patientId={member.id}
                  onSubmit={() => onUpdate(task)}
                />
              </div>
            )}
            <Button
              className={styles.secondaryBtn}
              color="secondary"
              onClick={() => history.push(`/dashboard/tasks/${task.id}`)}
            >
              {translate('tasks.goToTaskPage')}
            </Button>
            {task.member && (
              <Button
                className={styles.secondaryBtn}
                color="secondary"
                onClick={() => setShowContactModal(true)}
              >
                {translate('personalDetails.contact')}
              </Button>
            )}
            {showOrderSmartMeterDevice &&
              task.taskDefinition.id ===
                TaskDefinition.RPM_NEW_ORDER_REQUEST && (
                <Button
                  className={styles.primaryBtn}
                  color="primary"
                  disabled={isClosed}
                  loading={loading}
                  onClick={() => setShowOrderDeviceModal(true)}
                >
                  {translate('tasks.orderDevice')}
                </Button>
              )}
            <Button
              className={styles.primaryBtn}
              color="primary"
              disabled={isClosed}
              loading={loading}
              onClick={onUpdateTask}
            >
              {translate('tasks.updateTask')}
            </Button>
          </div>
        </Section.Body>

        {notes.map((note) => (
          <TaskNoteDetail key={note.id} taskNote={note} />
        ))}
      </Section>
      {task.taskDefinition.id === TaskDefinition.RPM_NEW_ORDER_REQUEST &&
        member && (
          <OrderDeviceModal
            member={member}
            onClose={() => setShowOrderDeviceModal(false)}
            onSubmit={async (params) => {
              await orderRPMDevices({
                memberId: member.id,
                taskId: task.id,
                ...params,
              });
              const notes = await fetchTaskNotes(task.id);
              setNotes(notes);
            }}
            open={showOrderDeviceModal}
          />
        )}
      {showContactModal && member && (
        <ContactInfoModal
          onClose={() => setShowContactModal(false)}
          member={member}
          open={showContactModal}
          getCareTeam={pathname.includes('dashboard/tasks')}
        />
      )}
      <Modal
        show={showOpenSubtaskWarning}
        color="warning"
        onHide={() => setShowOpenSubtasksWarning(false)}
      >
        <Modal.Header
          closeButton
          modalTitle={
            <>
              <i className="fa fa-warning" /> {translate('global.warning')}
            </>
          }
        />
        <Modal.Body>
          <p>{translate('tasks.attemptingToCloseWithOpenSubtasks')}</p>
        </Modal.Body>
        <Modal.Footer
          leftSide={
            <Button
              color="tertiary"
              onClick={() => setShowOpenSubtasksWarning(false)}
            >
              {translate('global.cancel')}
            </Button>
          }
          rightSide={
            <Button
              async
              color="primary"
              loading={loading}
              onClick={async () => {
                try {
                  setLoading(true);
                  await updateTask();
                  setShowOpenSubtasksWarning(false);
                } finally {
                  setLoading(false);
                }
              }}
            >
              {translate('global.continue')}
            </Button>
          }
        />
      </Modal>
    </>
  );
};

export default withRouter(TaskDetails);
