import React from 'react';
import { FieldTemplateProps } from 'react-jsonschema-form';
import MoreInfo from '../components/MoreInfo';

const FieldTemplate = (props: FieldTemplateProps) => {
  const {
    id,
    classNames,
    label,
    displayLabel,
    help,
    required,
    rawDescription,
    children,
    uiSchema,
    formContext: { readonly },
  } = props;

  const uiHidden = uiSchema['ui:hidden'];

  // 'readonly' will hide only when form is in readonly
  // 'edit' will hide only when form is in edit mode
  // true or 'both' will hide the field always
  if (
    (uiHidden === 'readonly' && readonly) ||
    (uiHidden === 'edit' && !readonly) ||
    uiHidden === true ||
    uiHidden === 'both'
  )
    return null;

  return (
    <div className={classNames} data-cy={id}>
      {displayLabel && (
        <label htmlFor={id} style={{ alignItems: 'center' }}>
          <span className="control-label">
            {label}
            {required ? ' *' : null}
          </span>
          {rawDescription ? <MoreInfo description={rawDescription} /> : null}
        </label>
      )}
      {children}
      {help}
    </div>
  );
};

export default FieldTemplate;
