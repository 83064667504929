import moment, { Moment } from 'moment';
import { make, makeArray } from './Model';
import { Employee } from './Employee';
import { EventIntervention } from './EventIntervention';
import { EventNote } from './EventNote';

export class EventEvaluation {
  id = 0;

  closed = false;

  createdBy: Employee;

  closedBy: Employee;

  updatedBy: Employee;

  createdAt: Moment;

  closedAt?: Moment;

  updatedAt?: Moment;

  interventions: EventIntervention[];

  notes: EventNote[];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdBy = make(obj.createdBy, Employee);
    this.closedBy = make(obj.closedBy, Employee);
    this.updatedBy = make(obj.updatedBy, Employee);
    this.createdAt = moment.unix(obj.createdAt);
    this.closedAt = obj.closedAt ? moment.unix(obj.closedAt) : undefined;
    this.updatedAt = obj.updatedAt ? moment.unix(obj.updatedAt) : undefined;
    this.interventions = makeArray(obj.interventions, EventIntervention);

    this.notes = makeArray(
      obj.notes,
      EventNote,
    )?.sort(({ createdAt: a }: EventNote, { createdAt: b }: EventNote) =>
      a.isBefore(b) ? 1 : -1,
    );
  }
}

export default EventEvaluation;
