import { Enum } from './Enum';

export class EncounterType extends Enum {
  translationKey = 'encounterType';

  deprecated?: boolean;

  static asArray: EncounterType[] = [];

  static byKey: { [name: string]: EncounterType } = {};

  static readonly ACP = new EncounterType('ACP');

  static readonly CCM = new EncounterType('CCM');

  static readonly RPM = new EncounterType('RPM');

  static readonly BHI = new EncounterType('BHI');

  static readonly TCM = new EncounterType('TCM');

  static readonly CLINICAL = new EncounterType('CLINICAL');

  static readonly ENGAGEMENT = new EncounterType('ENGAGEMENT');

  static readonly PHARM_VISIT = new EncounterType('PHARM_VISIT');

  static toSelectable() {
    return Enum.toSelectable(EncounterType.asArray);
  }

  constructor(public readonly value: string, deprecated?: boolean) {
    super();
    this.deprecated = deprecated;
    EncounterType.asArray.push(this);
    EncounterType.byKey[value] = this;
  }
}

export default EncounterType;
