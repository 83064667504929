import React, { useState, useEffect, useRef } from 'react';
// @ts-ignore
import Slider from 'react-slider';
import { debounce } from 'lodash';

import { FormGroup, ControlLabel, Popover, Overlay } from 'react-bootstrap';

import './main.less';

type Props = {
  id: string;
  label?: string;
  max: number;
  min: number;
  onChange: (value?: number) => any;
  popoverPlacement?: 'top' | 'bottom' | 'none';
  popoverRenderer: (value?: number) => JSX.Element;
  step?: number;
  stepLabelRenderer?: Function;
  value?: number;
} & DefaultProps;
type DefaultProps = Partial<typeof defaultProps>;
const defaultProps = {
  step: 1,
  value: 0,
  stepLabelRenderer: (value: number) =>
    value % 2 === 0 ? <span className="slider-step-inner">{value}</span> : null,
  popoverRenderer: (value: number) => (
    <span className="slider-popover-inner">{value}</span>
  ),
  popoverPlacement: 'bottom',
};

export const SliderWrapper = (props: Props) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipValue, setTooltipValue] = useState(props.value || 0);
  const handler = useRef(null);
  const sliderComponent = useRef(null);

  const resize = () => {
    // FIXME: Does showTooltip = false > true transition to force overlay repositioning
    // the tooltip. Ugly hack, but there is no way to force recalculation by direct method call
    setShowTooltip(false);
    setShowTooltip(true);
  };

  const resizeHandler = debounce(() => resize(), 300);
  useEffect(() => {
    window.addEventListener('resize', () => resizeHandler());
    resize();
    return window.removeEventListener('resize', () => resizeHandler());
  }, []);

  const change = (value: number) => {
    const { onChange } = props;
    setTooltipValue(value);
    onChange(value);
  };

  // Renders dots and labels along the slider bar
  const renderSteps = () => {
    const { min, max, step, stepLabelRenderer } = props;

    if (step) {
      if (step <= 0) {
        return null;
      }

      const init = Math.min(min, max);
      const end = Math.max(min, max);
      const steps = [];

      for (let i = init; i <= end; i += step) {
        const offset = (i / (end - init)) * 100;
        const stepLabel = (
          <span
            key={i}
            className="slider-step"
            style={{ left: `${offset}%` }}
            onClick={() => change(i)}
          >
            {stepLabelRenderer && stepLabelRenderer(i)}
          </span>
        );

        steps.push(stepLabel);
      }

      return steps;
    }
  };

  // Render popover
  const renderPopover = () => {
    const { id, popoverRenderer, popoverPlacement } = props;
    return (
      <Overlay
        placement={popoverPlacement}
        container={sliderComponent.current}
        target={handler.current || undefined}
        shouldUpdatePosition
        show={showTooltip}
      >
        <Popover id={`${id}-popover`}>{popoverRenderer(tooltipValue)}</Popover>
      </Overlay>
    );
  };

  const { id, label, value, min, max, step, popoverPlacement } = props;
  const hasPopover = popoverPlacement !== 'none';

  return (
    <FormGroup controlId={id} className="ht-slider-control">
      <ControlLabel>{label}</ControlLabel>
      <div className="ht-slider-wrapper">
        <div className="step-container">{renderSteps()}</div>
        <Slider
          ref={sliderComponent}
          value={value}
          min={min}
          max={max}
          step={step}
          onChange={(newValue: number) => change(newValue)}
          orientation="horizontal"
          onBeforeChange={(val: number) => setTooltipValue(val)}
          withBars
        >
          <div
            ref={handler}
            onTouchStart={(e) => e.preventDefault()}
            className="handler"
          >
            <span className="handler-bar" />
            <span className="handler-bar" />
            <span className="handler-bar" />
            {hasPopover && renderPopover()}
          </div>
        </Slider>
      </div>
    </FormGroup>
  );
};

SliderWrapper.defaultProps = defaultProps;
export default SliderWrapper;
