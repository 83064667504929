import React, { useState } from 'react';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { EventType, IssueType } from '@vestahealthcare/common/models';

import { Button, CollapsableSidebar, Select } from 'styleguide-v2';

import { fetchEventType } from 'dash/src/services/EventServices';

import { EventMappgingConfirmModal } from './EventMappingConfirm';

interface Props {
  eventTypes?: EventType[];
  issueTypes?: IssueType[];
  onClose: () => void;
  open: boolean;
  onSubmit: (eventType: EventType, issueTypes: IssueType[]) => Promise<boolean>;
}

const useStyles = makeStyles({
  arrow: {
    alignSelf: 'center',
    display: 'flex',
    margin: '0 0 -2rem 0',
  },
});

export const EventMappingEditModal = ({
  eventTypes,
  issueTypes,
  onClose,
  open,
  onSubmit,
}: Props) => {
  const styles = useStyles();

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [selectedIssueTypes, setSelectedIssueTypes] = useState<IssueType[]>([]);
  const [selectedEventType, setSelectedEventType] = useState<EventType>();
  const [alreadyMapped, setAlreadyMapped] = useState<IssueType[]>([]);

  const validate = () => {
    return selectedEventType && selectedIssueTypes.length;
  };

  const checkAlreadyMapped = () => {
    setSubmitted(true);
    if (selectedEventType && validate()) {
      const alreadyMapped = selectedIssueTypes.filter(
        (it) => it?.eventType?.id && it.eventType.id !== selectedEventType.id,
      );
      if (alreadyMapped.length) {
        setAlreadyMapped(alreadyMapped);
        setShowModalConfirm(true);
      } else {
        setAlreadyMapped([]);
        doSubmit();
      }
    }
  };

  const doClose = () => {
    setSelectedIssueTypes([]);
    setSelectedEventType(undefined);
    setSubmitted(false);
    onClose();
  };

  const doSubmit = async () => {
    if (selectedEventType && validate()) {
      setLoading(true);
      try {
        const detail = await fetchEventType(selectedEventType.id);
        const result = await onSubmit(selectedEventType, [
          ...selectedIssueTypes,
          ...(detail.issueTypes || []),
        ]);
        if (result) {
          doClose();
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <CollapsableSidebar
      onClose={doClose}
      open={open}
      title={<h2>{translate('events.mapping.modalTitle')}</h2>}
    >
      <CollapsableSidebar.Body>
        <Select
          error={submitted && !selectedIssueTypes.length}
          getItemLabel={({ description }: IssueType) => description}
          items={issueTypes || []}
          label={translate('events.mapping.issueType')}
          limitTags={1}
          multiple
          onChange={setSelectedIssueTypes}
          value={selectedIssueTypes}
          required
        />
        <div className={styles.arrow}>
          <ArrowDownwardIcon fontSize="large" color="action" />
        </div>

        <Select
          error={submitted && !selectedEventType}
          getItemLabel={({ name }: EventType) => name}
          items={eventTypes || []}
          label={translate('events.mapping.eventMapping')}
          onChange={setSelectedEventType}
          required
          value={selectedEventType || ''}
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="edit-event-mapping-close"
          onClick={doClose}
        >
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="edit-event-mapping-submit"
          disabled={!selectedEventType || !selectedIssueTypes?.length}
          loading={loading}
          onClick={() => checkAlreadyMapped()}
        >
          {translate('events.mapping.map')}
        </Button>
      </CollapsableSidebar.Buttons>
      <EventMappgingConfirmModal
        alreadyMapped={alreadyMapped}
        open={showModalConfirm}
        onCancel={() => setShowModalConfirm(false)}
        onSubmit={async () => await doSubmit()}
      />
    </CollapsableSidebar>
  );
};

export default EventMappingEditModal;
