import classnames from 'classnames';
import moment, { Moment } from 'moment';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import makeStyles from '@mui/styles/makeStyles';

import { translate } from '@vestahealthcare/common/i18n';

import { DateTimePicker } from 'styleguide-v2/src/components/DateTimePicker';
import {
  ToggleButton,
  ToggleItem,
} from 'styleguide-v2/src/components/ToggleButton';
import { BackgroundColors } from 'styleguide-v2/src/styles/Colors';
import { Fonts } from 'styleguide-v2/src/styles/Variables';

type ToggleDateRangeOption = number | string;

type Props = {
  className?: string;
  customExpand?: 'horizontal' | 'vertical';
  'daya-cy'?: string;
  defaultValue?: ToggleDateRangeOption;
  disabled?: boolean;
  fullWidth?: boolean;
  items: ToggleDateRangeOption[];
  label?: string;
  layout?: 'horizontal' | 'vertical';
  onChange: (
    startDate?: Date,
    endDate?: Date,
    option?: ToggleDateRangeOption,
  ) => void;
  size?: 'small' | 'xs';
};

const useStyles = makeStyles({
  customContainter: {
    display: 'flex',
    gap: '0.5rem',
  },
  dateFilterContainer: {
    display: 'flex',
    alignItems: 'end',
  },
  dateFilterDisabled: {
    '& > .MuiToggleButtonGroup-root': {
      backgroundColor: BackgroundColors.gray,
      cursor: 'not-allowed',
    },
  },
  dateInputVertical: {
    '&&': {
      marginLeft: 0,
      marginTop: '1rem',
    },
  },
  dateInput: {
    marginLeft: '0.5rem',
    width: '12.65rem',
    '& >  .MuiFormControl-root': {
      '& > .MuiOutlinedInput-root': {
        '& > input': {
          fontSize: Fonts.fontSize,
          padding: '0.375rem 0 .375rem .75rem',
        },
        '& svg': {
          height: Fonts.fontSize,
          width: Fonts.fontSize,
        },
      },
    },
  },
  fullWidth: {
    width: '100%',
    '& .MuiToggleButtonGroup-root': {
      display: 'flex',

      '& > button': {
        flex: 1,
      },
    },
  },
});

export type ToggleDateRangeHandle = {
  init: () => void;
};

export const ToggleDateRange = forwardRef(
  (
    {
      className,
      'daya-cy': dataCy,
      customExpand = 'horizontal',
      defaultValue,
      disabled,
      fullWidth,
      items,
      label,
      layout,
      onChange,
      size = 'small',
    }: Props,
    ref,
  ) => {
    const styles = useStyles();

    const [currentValue, setValue] = useState<ToggleDateRangeOption>(
      defaultValue || items[0],
    );
    const [toggleOptions, setToggleOptions] = useState<ToggleItem[]>([]);

    const defaultFrom =
      typeof defaultValue === 'number'
        ? moment().subtract(defaultValue, 'd').startOf('day')
        : moment(defaultValue?.split(',')[0]);
    const defaultTo =
      typeof defaultValue === 'number'
        ? moment().endOf('day')
        : moment(defaultValue?.split(',')[1]);

    const [fromDate, setFromDate] = useState<Moment | null>(defaultFrom);
    const [toDate, setToDate] = useState<Moment | null>(defaultTo);

    useImperativeHandle(ref, () => ({
      init: () => {
        setValue(defaultValue || items[0]);
      },
    }));

    useEffect(() => {
      setToggleOptions(
        items.map((item) =>
          typeof item === 'number'
            ? {
                label: translate(
                  `components.toggleDatePicker.${item === -1 ? 'all' : 'day'}`,
                  {
                    count: item,
                  },
                ),
                value: item,
              }
            : {
                label: translate('components.toggleDatePicker.custom'),
                value: 'custom',
              },
        ),
      );
    }, [items]);

    useEffect(() => {
      let from: Moment | null = null;
      let to: Moment | null = null;

      if (typeof currentValue === 'number') {
        if (currentValue !== -1) {
          from = moment().subtract(currentValue, 'd').startOf('day');
          to = moment().endOf('day');
        }
        setFromDate(from);
        setToDate(to);
        onChange(from?.toDate(), to?.toDate(), currentValue);
      } else {
        const values = (currentValue || '').split(',');
        if (values?.length && values[0]) {
          from = moment(values[0]);
        }
        if (values?.length && values[1]) {
          to = moment(values[1]);
        }

        if (from?.isValid()) {
          setFromDate(from);
        }
        if (to?.isValid()) {
          setToDate(to);
        }
        onChange(
          from?.isValid() ? from?.toDate() : fromDate?.toDate(),
          to?.isValid() ? to?.toDate() : toDate?.toDate(),
          currentValue,
        );
      }
    }, [currentValue]);

    return (
      <div
        className={classnames(
          className,
          customExpand === 'horizontal' ? styles.dateFilterContainer : '',
        )}
      >
        <ToggleButton
          className={classnames(
            disabled && styles.dateFilterDisabled,
            fullWidth && styles.fullWidth,
          )}
          data-cy={dataCy}
          disabled={disabled}
          items={toggleOptions}
          label={
            label === undefined
              ? translate('components.toggleDatePicker.label')
              : label
          }
          layout={layout}
          onChange={setValue}
          value={typeof currentValue === 'number' ? currentValue : 'custom'}
          size={size}
        />
        {typeof currentValue === 'string' && !disabled && (
          <div className={styles.customContainter}>
            <DateTimePicker
              className={classnames(
                styles.dateInput,
                customExpand === 'vertical' && styles.dateInputVertical,
              )}
              data-cy="biometrics-date-start"
              disabled={disabled}
              maxDate={toDate?.toDate() || Date.now()}
              onChange={(v) => {
                const date = v ? moment(v) : undefined;
                if (!date || date.isValid()) {
                  setFromDate(date || null);
                  onChange(date?.toDate(), toDate?.toDate(), currentValue);
                }
              }}
              type="date"
              value={fromDate?.toDate()}
            />
            <DateTimePicker
              className={classnames(
                styles.dateInput,
                customExpand === 'vertical' && styles.dateInputVertical,
              )}
              data-cy="biometrics-date-end"
              disabled={disabled}
              minDate={fromDate?.toDate()}
              maxDate={Date.now()}
              onChange={(v) => {
                const date = v ? moment(v) : undefined;
                if (!date || date.isValid()) {
                  setToDate(date || null);
                  onChange(fromDate?.toDate(), date?.toDate(), currentValue);
                }
              }}
              type="date"
              value={toDate?.toDate()}
            />
          </div>
        )}
      </div>
    );
  },
);

export default ToggleDateRange;
