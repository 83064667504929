import moment, { Moment } from 'moment';

import {
  AppointmentStatus,
  EncounterChannel,
  EncounterMethodOutcome,
  EncounterType,
} from '../enums';
import { DATE_FORMAT } from '../utils/constants';
import { makeUnix } from './Model';

export class MemberDashboardEncounter {
  type = '';

  typeSecondary = '';

  date: Moment;

  dateTime?: Moment;

  details = '';

  notes = '';

  employeeName = '';

  constructor(obj: any) {
    Object.assign(this, obj);
    this.type = EncounterType.byKey[obj.type]
      ? EncounterType.byKey[obj.type].toString()
      : obj.type;
    this.typeSecondary = EncounterChannel.byKey[obj.typeSecondary]
      ? EncounterChannel.byKey[obj.typeSecondary].toString()
      : obj.typeSecondary;
    this.date = moment(obj.date, DATE_FORMAT);
    this.dateTime = makeUnix(obj.dateTime);
    if (obj.details) {
      if (EncounterMethodOutcome.byKey[obj.details])
        this.details = EncounterMethodOutcome.byKey[obj.details].toString();
      else if (AppointmentStatus.byKey[obj.details])
        this.details = AppointmentStatus.byKey[obj.details].toString();
      else this.details = obj.details;
    }
  }
}

export default MemberDashboardEncounter;
