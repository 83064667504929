import React from 'react';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { PhoneUser } from '@vestahealthcare/common/models';

import { Chip, Colors } from 'styleguide-v2';
import Variables from 'styleguide-v2/src/styles/Variables';

type Props = {
  'data-cy'?: string;
  userPhones: PhoneUser[];
  userPhoneNumber?: string;
  showSubtitle?: boolean;
  unread?: boolean;
};

const useStyles = makeStyles({
  subtitle: {
    color: Colors.gray,
    fontWeight: 500,
    fontSize: `calc(${Variables.fontSize} * 0.75)`,
  },
  phoneSeparator: {
    width: '3px',
    paddingTop: '0.85rem',
    margin: '0 0.35rem',
  },
  type: {
    fontWeight: 400,
  },
  bold: {
    fontWeight: 500,
  },
});

export const FromPhone = ({
  'data-cy': dataCy,
  userPhones,
  userPhoneNumber,
  showSubtitle,
  unread,
}: Props) => {
  const styles = useStyles();

  const getTitle = () =>
    userPhoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

  const getSubtitle = () => {
    if (userPhones?.length) {
      const { primary, phone } = userPhones[0];
      return (
        <p className={styles.subtitle}>
          {primary && (
            <>
              <span>{translate('chat.primary')}</span>
              <FiberManualRecordIcon className={styles.phoneSeparator} />
            </>
          )}
          <span className={styles.type}>{phone.type?.toString()}</span>
        </p>
      );
    }
  };

  const hasChip = () =>
    userPhones?.length !== 0 &&
    userPhones.reduce(
      (acc, { phone }) => acc && !!phone.textReminderOptOut,
      true,
    );

  return (
    <div data-cy={dataCy}>
      <p className={unread ? styles.bold : ''}>{getTitle()}</p>
      {showSubtitle && getSubtitle()}
      {hasChip() && <Chip color="error" label={translate('chat.smsOptOut')} />}
    </div>
  );
};

export default FromPhone;
