import { BaseEnum } from './BaseEnum';

export class CareTeamPersonStatus extends BaseEnum {
  static DECLINED: string = 'DECLINED';

  static NEW: string = 'NEW';

  static ONBOARDED: string = 'ONBOARDED';

  static ONBOARD_IN_PROGRESS: string = 'ONBOARD_IN_PROGRESS';

  static ON_HOLD: string = 'ON_HOLD';

  static OUTREACH_INITIATED: string = 'OUTREACH_INITIATED';

  static UNREACHABLE: string = 'UNREACHABLE';

  static COLOR_MAP = new Map<
    string,
    'primary' | 'secondary' | 'info' | 'warning' | 'error' | 'action'
  >([
    [CareTeamPersonStatus.DECLINED, 'error'],
    [CareTeamPersonStatus.NEW, 'action'],
    [CareTeamPersonStatus.ONBOARDED, 'secondary'],
    [CareTeamPersonStatus.ONBOARD_IN_PROGRESS, 'warning'],
    [CareTeamPersonStatus.OUTREACH_INITIATED, 'action'],
    [CareTeamPersonStatus.UNREACHABLE, 'error'],
    [CareTeamPersonStatus.ON_HOLD, 'error'],
  ]);
}

export default CareTeamPersonStatus;
