import { Enum } from './Enum';

export class MemberConsentType extends Enum {
  translationKey = 'memberConsentType';

  static asArray: MemberConsentType[] = [];

  static byKey: { [name: string]: MemberConsentType } = {};

  static readonly VERBAL = new MemberConsentType('VERBAL');

  static readonly WRITTEN = new MemberConsentType('WRITTEN');

  static toSelectable() {
    return Enum.toSelectable(MemberConsentType.asArray);
  }

  constructor(public readonly value: string) {
    super();
    MemberConsentType.asArray.push(this);
    MemberConsentType.byKey[value] = this;
  }

  get description() {
    return this.toString();
  }
}

export default MemberConsentType;
