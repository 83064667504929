import React from 'react';
import { Checkbox } from 'styleguide';
import { WidgetProps } from '../types';

const SlideToggleWidget = (props: WidgetProps) => {
  const {
    formContext: { readonly },
    options,
  } = props;

  if (readonly) {
    return null;
  }

  return (
    <Checkbox
      onChange={props.onChange}
      label={props.value ? options.onLabel : options.offLabel}
      checked={props.value}
      className="slide-toggle"
      icon={props.value ? 'toggle-on' : 'toggle-off'}
    />
  );
};

export default SlideToggleWidget;
