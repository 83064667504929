import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

import {
  Enum,
  MemberStatus,
  ReasonWithdraw,
} from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';
import { PermissionName } from '@vestahealthcare/common/models/Employee';
import moment from '@vestahealthcare/common/moment';
import { DATE_TIME_FORMAT } from '@vestahealthcare/common/utils/constants';

import {
  CheckboxWithLabel,
  DateTimePicker,
  PanelInfo,
  Select,
  Tooltip,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { updatePatient } from 'dash/src/services/PatientServices';
import Session from 'dash/src/services/SessionServices';

import { BaseModal } from './BaseModal';

interface Props {
  patient: Patient;
  onSubmit: (patient: Patient) => void;
}

const renderDetails = ({ reasonWithdraw, status }: Patient) => (
  <>
    <h4 className="grid-span-5">{translate('personalDetails.status')}</h4>
    <p className="grid-span-6" data-cy="personal-details-status">
      {status && status.toString()}
      {status === MemberStatus.DISENROLLED_WITHDRAW && reasonWithdraw && (
        <p className="small">[{reasonWithdraw.toString()}]</p>
      )}
    </p>
  </>
);

const useStyles = makeStyles({
  disabledOption: {
    opacity: 0.38,
    width: '100%',
  },
});

export const StatusModal = (props: Props) => {
  const styles = useStyles();
  const { patient, onSubmit } = props;
  const { statusUpdatedAt, statusUpdatedBy } = patient;
  const { actingUser } = Session;

  const [status, setStatus] = useState<string>();
  const [isTest, setIsTest] = useState(false);
  const [deceasedDate, setDeceasedDate] = useState<Moment>();
  const [reasonWithdraw, setReasonWithdraw] = useState<Selectable>();

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showWarning, setShowWarning] = useState(false);

  const validate = () =>
    status !== MemberStatus.DISENROLLED_WITHDRAW.value || reasonWithdraw;

  const submit = async () => {
    let result = true;

    setSubmitted(true);
    if (!status || !validate()) return false;
    setLoading(true);

    try {
      const newPatient = await updatePatient(patient.id, {
        deceasedDate: [
          MemberStatus.DECEASED_ONBOARDED.value,
          MemberStatus.DECEASED_NON_ONBOARDED.value,
        ].includes(status)
          ? deceasedDate
          : undefined,
        isTest: Session.actingUser.can(PermissionName.ADMIN)
          ? isTest
          : undefined,
        reasonWithdraw:
          reasonWithdraw && MemberStatus.DISENROLLED_WITHDRAW.value === status
            ? ReasonWithdraw.byKey[reasonWithdraw.value]
            : undefined,
        status: MemberStatus.byKey[status].value,
      });

      onSubmit(newPatient);
      setSubmitted(false);
    } catch (e) {
      showGlobalError(e as string);
      result = false;
    }

    setLoading(false);
    return result;
  };

  useEffect(() => {
    setDeceasedDate(
      patient.deceasedDate ? moment(patient.deceasedDate) : undefined,
    );
    setIsTest(patient.test);
    setReasonWithdraw(
      patient.reasonWithdraw
        ? Enum.toSelectable([patient.reasonWithdraw])[0]
        : undefined,
    );
    setStatus(patient.status?.value);
  }, [patient.status, patient.deceasedDate, patient.reasonWithdraw]);

  useEffect(() => {
    setShowWarning(
      status === MemberStatus.INVALID.valueOf() &&
        patient.status !== MemberStatus.INVALID,
    );
  }, [status]);

  return (
    <BaseModal
      details={renderDetails(patient)}
      data-cy="patient-status"
      editable={actingUser.isInEnrollment || actingUser.isInBizOps}
      loadingSubmit={loading}
      onSubmit={submit}
      title={translate('personalDetails.status')}
    >
      <div className="grid-wrapper fit">
        <Select
          className="grid-span-12"
          data-cy="status-modal-select"
          error={submitted && !status}
          getItemDisabled={({ value }: Selectable) =>
            (value === MemberStatus.DECEASED_NON_ONBOARDED.value &&
              patient.wasEverOnboarded) ||
            (value === MemberStatus.DECEASED_ONBOARDED.value &&
              !patient.wasEverOnboarded)
          }
          label={translate('personalDetails.status')}
          items={
            actingUser.isInBizOps
              ? MemberStatus.toSelectable()
              : Enum.toSelectable([
                  MemberStatus.ACCEPTED,
                  ...(patient.status ? [patient.status] : []),
                ])
          }
          onChange={(item?: Selectable) => {
            setStatus(item?.value as string);
          }}
          renderOption={({ value, label }: Selectable) => {
            const text = label;
            if (
              MemberStatus.INVALID.value === value &&
              !Session.actingUser.canSetMemberInvalidStatus
            ) {
              return (
                <Tooltip
                  text={translate('memberBulkEdit.warningNoSetInvalidStatus')}
                  enterDelay={50}
                  followCursor
                >
                  <span
                    className={styles.disabledOption}
                    onClick={(evt) => evt.stopPropagation()}
                  >
                    {' '}
                    {text}
                  </span>
                </Tooltip>
              );
            }
            return text;
          }}
          required
          value={MemberStatus.toSelectable().find(
            ({ value }) => value === status,
          )}
        />
        {status === MemberStatus.DISENROLLED_WITHDRAW.value && (
          <Select
            className="grid-span-12"
            error={submitted && !reasonWithdraw}
            items={Enum.toSelectable(ReasonWithdraw.asArray)}
            label={translate('personalDetails.reasonWithdraw')}
            onChange={setReasonWithdraw}
            required
            value={reasonWithdraw}
          />
        )}
        {status &&
          [
            MemberStatus.DECEASED_ONBOARDED.value,
            MemberStatus.DECEASED_NON_ONBOARDED.value,
          ].includes(status) && (
            <DateTimePicker
              className="grid-span-12"
              data-cy="encounter-date"
              label={translate('personalDetails.deceasedDate')}
              maxDate={new Date()}
              onChange={(date?: Date) =>
                setDeceasedDate(date ? moment(date.getTime()) : undefined)
              }
              value={deceasedDate?.toDate()}
            />
          )}
        {showWarning && (
          <PanelInfo
            className="grid-span-12"
            type="warning"
            title={translate('personalDetails.warningInvalidStatus')}
          />
        )}
        {!!statusUpdatedAt && !!statusUpdatedBy && (
          <p className="small grey grid-span-12">
            {translate('global.lastUpdatedBy', {
              date: moment.unix(statusUpdatedAt).format(DATE_TIME_FORMAT),
              editor: statusUpdatedBy.fullName,
            })}
          </p>
        )}

        {Session.actingUser.can(PermissionName.ADMIN) && (
          <CheckboxWithLabel
            className="grid-span-12"
            checked={isTest}
            data-cy="test-toggle"
            label={translate('personalDetails.testMember')}
            onChange={() => setIsTest(!isTest)}
          />
        )}
      </div>
    </BaseModal>
  );
};
