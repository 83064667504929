import { Enum } from './Enum';

export class Frequency extends Enum {
  translationKey = 'frequency';

  static asArray: Frequency[] = [];

  static byKey: { [name: string]: Frequency } = {};

  static readonly DAILY = new Frequency('DAILY');

  static readonly WEEKLY = new Frequency('WEEKLY');

  static readonly EVERY_OTHER_WEEK = new Frequency('EVERY_OTHER_WEEK');

  static readonly MONTHLY = new Frequency('MONTHLY');

  constructor(public readonly value: string) {
    super();
    Frequency.asArray.push(this);
    Frequency.byKey[value] = this;
  }
}

export default Frequency;
