import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useMemo } from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Selectable } from '@vestahealthcare/common/enums/Enum';

import { Select } from 'styleguide-v2/src/components/Select';
import { Colors } from 'styleguide-v2/src/styles/Colors';
import { Fonts } from 'styleguide-v2/src/styles/Variables';

import { ErrorLabel } from '../ErrorLabel';
import { Label } from '../Label';

type DateType = 'datetime' | 'date' | 'time';

type Props = {
  className?: string;
  'data-cy'?: string;
  dateFormat?: string;
  disabled?: boolean;
  disableClereable?: boolean;
  error?: string | boolean;
  interval?: number;
  label?: string;
  maxDate?: any;
  maxTime?: any;
  minDate?: any;
  minTime?: any;
  onChange: (value?: any) => void;
  placeholder?: string;
  required?: boolean;
  size?: 'small' | 'medium' | 'xs';
  type?: DateType;
  value?: Date;
};

const useStyles = makeStyles({
  '@global': {
    '.MuiCalendarPicker-root, .MuiClockPicker-arrowSwitcher + div': {
      '& [role="presentation"]': {
        fontSize: Fonts.fontSize,
      },
      '& .MuiTypography-caption': {
        fontSize: `${Fonts.fontSize}`,
      },
      '& button': {
        fontSize: Fonts.fontSize,
        '& svg': {
          height: `calc(${Fonts.fontSize} * 1.5)`,
          width: `calc(${Fonts.fontSize} * 1.5)`,
        },
      },
    },
  },
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  containerNoLabel: {
    marginTop: 0,
  },
  label: {
    marginBottom: '.9rem',
  },
  time: {
    '&& > .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root': {
      paddingRight: '35px',
    },
  },
});

const inputStyles = {
  '& input': {
    fontSize: `calc(${Fonts.fontSize} * 1.125)`,
    padding: '.75rem 0 .75rem 1.5rem',
  },
  '& fieldset > legend': {
    display: 'none',
  },
  '& .MuiInputAdornment-root svg': {
    marginRight: '.75rem',
    height: `calc(${Fonts.fontSize} * 1.5)`,
    width: `calc(${Fonts.fontSize} * 1.5)`,
  },
};

const inputStylesSmall = {
  '&&.MuiFormControl-root > .MuiInputBase-root ': {
    overflow: 'auto',
    padding: '.2rem 2.5rem .2rem 1rem',
    paddingRight: '2.5rem',
    position: 'static',

    '& > input': {
      fontSize: Fonts.fontSize,
      height: '1.5rem',
      padding: '0.5rem 0 0.5rem .5rem',
    },

    '& > fieldset': {
      height: '3.59rem',
    },
  },
  '& .MuiInputAdornment-root svg': {
    marginRight: 0,
    height: `calc(${Fonts.fontSize} * 1.25)`,
    width: `calc(${Fonts.fontSize} * 1.25)`,
  },
  '& .MuiChip-root': {
    background: Colors.lightMint,
    height: '24px',
    '.MuiChip-deleteIcon': {
      color: Colors.green,
      '&:hover': {
        color: Colors.darkGreen,
      },
    },
    '.MuiChip-label': {
      fontSize: `calc(${Fonts.fontSize} * 0.875)`,
      padding: '0 1rem',
    },

    '& .MuiSvgIcon-root': {
      fontSize: `calc(${Fonts.fontSize} * 1.125)`,
    },
  },
};

const inputStylesExtraSmall = {
  '&&.MuiFormControl-root > .MuiInputBase-root ': {
    height: '3rem',
    overflow: 'auto',
    padding: '.2rem 1.25rem .2rem .5rem',
    paddingRight: '1.25rem',
    position: 'static',
  },
  '&&.MuiAutocomplete-endAdornment': {
    top: 'auto',
  },
};

const generateTimes = (min?: number, max?: number, interval: number = 15) => {
  const minutes = [];
  for (let minute = 0; minute < 60; minute += interval) {
    minutes.push(minute);
  }
  const times: Date[] = [];
  for (let hour = 0; hour < 24; hour += 1) {
    minutes.forEach((m) => {
      const date = new Date();
      date.setHours(hour);
      date.setMinutes(m);
      date.setSeconds(0);
      if ((!min || min < date.getTime()) && (!max || max > date.getTime())) {
        times.push(date);
      }
    });
  }
  return times.map((item) => ({
    value: item.getTime(),
    label: item.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }),
  }));
};

export const DateTimePicker = ({
  className,
  'data-cy': dataCy,
  dateFormat,
  disableClereable,
  disabled,
  error,
  interval = 15,
  label,
  minDate = new Date('1900-01-01'),
  onChange,
  placeholder,
  required,
  type = 'datetime',
  value,
  size,
  ...other
}: Props) => {
  const styles = useStyles();
  const [currentValue, setValue] = React.useState<Date | null>(null);
  const [openInput, setOpenInput] = React.useState<boolean>(false);
  const times = useMemo(
    () => generateTimes(other.minTime, other.maxTime, interval),
    [other.minTime, other.maxTime],
  );

  const handleChange = (newValue: Date | null) => {
    setValue(newValue);
    onChange(newValue);
  };
  const classes = classNames(
    className,
    styles.container,
    !label && styles.containerNoLabel,
  );
  const timeClasses = classNames(className, styles.time);

  /* eslint-disable react/jsx-props-no-spreading */
  const componentProps = {
    label: '',
    disabled,
    minDate,
    onChange: (newValue: Dayjs | null) =>
      handleChange(newValue ? newValue?.toDate() : null),
    renderInput: (params: any) => (
      <TextField
        {...params}
        sx={{
          ...inputStyles,
          ...(size === 'small' ? inputStylesSmall : undefined),
          ...(size === 'xs' ? inputStylesExtraSmall : undefined),
        }}
        onBlur={() => {
          if (currentValue !== null && Number.isNaN(currentValue.valueOf())) {
            setValue(null);
          }
        }}
        onClick={() => {
          setOpenInput(!openInput && !disabled);
        }}
        error={!!error}
        inputProps={{
          ...params.inputProps,
          placeholder,
        }}
      />
    ),
    value: currentValue ? dayjs(currentValue.getTime()) : null,
    ...other,
  };

  useEffect(() => setValue(value || null), [value]);

  const findTime = (array: Selectable[], item: Date) =>
    array.find(
      ({ label: itemLabel }) =>
        itemLabel ===
        item.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        }),
    );

  const timesWithOption = times;
  if (currentValue && !findTime(times, currentValue)) {
    timesWithOption.unshift({
      value: currentValue?.getTime(),
      label: currentValue?.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }),
    });
  }

  return type === 'time' ? (
    <Select
      className={timeClasses}
      disabled={disabled}
      error={error}
      icon={<AccessTimeIcon />}
      items={times}
      label={label}
      onChange={(item: Selectable) =>
        handleChange(item?.value ? new Date(item.value) : null)
      }
      placeholder={placeholder}
      value={currentValue ? findTime(timesWithOption, currentValue) : undefined}
      required={required}
      size={size}
    />
  ) : (
    <div className={classes} data-cy={dataCy}>
      {label && (
        <Label className={styles.label} error={error} required={required}>
          {label}
        </Label>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* eslint-disable react/jsx-props-no-spreading */}
        <DesktopDatePicker
          {...componentProps}
          open={openInput}
          onClose={() => {
            setOpenInput(false);
          }}
          inputFormat={dateFormat || 'MM/DD/YYYY'}
        />
      </LocalizationProvider>
      <ErrorLabel error={error} required={required} />
    </div>
  );
};

export default DateTimePicker;
