import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/Download';

import { translate } from '@vestahealthcare/common/i18n';
import { IconButton, Spinner, Table } from 'styleguide-v2';
import { EligibilityJob } from '@vestahealthcare/common/models';
import { DATE_FORMAT_SHORT } from '@vestahealthcare/common/utils/constants';

export interface EligibilityJobTable extends EligibilityJob {
  loading?: boolean;
}

interface Props {
  jobs: EligibilityJobTable[];
  onClickDownload: (job: EligibilityJobTable) => void;
}

const ELIGIBILITY_JOBS_COLUMNS = ({ onClickDownload }: Props) => [
  {
    headerName: translate('eligibility.table.date'),
    component: ({ startedAt }: EligibilityJob) =>
      startedAt?.format(DATE_FORMAT_SHORT),
  },
  {
    headerName: translate('eligibility.table.description'),
    field: 'processType',
  },
  {
    headerName: translate('eligibility.table.records'),
    field: 'numMembers',
  },
  {
    headerName: translate('eligibility.table.status'),
    component: ({ status }: EligibilityJob) => {
      switch (status) {
        case 'COMPLETE':
          return (
            <span className="flex">
              <CheckCircleIcon color="primary" />
              &nbsp;{translate('eligibility.table.completed')}
            </span>
          );
        case 'IN_PROGRESS':
          return (
            <span className="flex">
              <Spinner width={16} />
              &nbsp;{translate('eligibility.table.inProgress')}
            </span>
          );
        default:
          return (
            <span className="flex">
              <Cancel width={16} color="error" />
              &nbsp;{translate('eligibility.table.error')}
            </span>
          );
      }
    },
  },
  {
    headerName: '',
    component: (job: EligibilityJobTable) =>
      job.comparisionReport ? (
        <IconButton
          size="small"
          onClick={() => onClickDownload(job)}
          loading={job.loading}
        >
          <DownloadIcon />
        </IconButton>
      ) : (
        <></>
      ),
    width: 20,
  },
];

export const EligibilityJobsTable = (props: Props) => (
  <Table
    config={{
      columns: ELIGIBILITY_JOBS_COLUMNS(props),
      compact: true,
      data: props.jobs,
      paginationOptions: [-1],
    }}
  />
);

export default EligibilityJobsTable;
