import React, { useEffect, useState } from 'react';

import {
  Brand,
  Enum,
  FacilityType,
  States,
} from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Facility,
  InsurancePlan,
  Organization,
} from '@vestahealthcare/common/models';
import { getDiff } from '@vestahealthcare/common/utils/api';

import { Toast } from 'styleguide';
import {
  Button,
  CollapsableSidebar,
  Modal,
  Select,
  TextInput,
} from 'styleguide-v2';

import { ReferralDropdown } from 'dash/src/components/ReferralDropdown';
import { CacheServices } from 'dash/src/services';
import { UpdateFacilityParams } from 'dash/src/services/FacilityServices';

interface Props {
  facility?: Facility;
  onClose: () => void;
  onDelete: (id: number) => Promise<boolean>;
  onSubmit: (
    id: number,
    params: UpdateFacilityParams,
  ) => Promise<boolean | string>;
  open: boolean;
}

const FacilityEditModal = ({
  facility,
  onClose,
  onDelete,
  onSubmit,
  open,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [name, setName] = useState<string>();
  const [ecwId, setECWId] = useState<number>();
  const [type, setType] = useState<FacilityType>();
  const [npi, setNPI] = useState<number>();
  const [state, setState] = useState<States>();
  const [brand, setBrand] = useState<Brand>();
  const [insurance, setInsurance] = useState<InsurancePlan[]>([]);
  const [referralSource, setReferralSource] = useState<Selectable[]>([]);

  const [insurances, setInsurances] = useState<InsurancePlan[]>([]);
  const [referralSources, setReferralSources] = useState<Selectable[]>([]);

  const getInitialData = async () => {
    setLoadingData(true);
    const [i, o] = await Promise.all([
      CacheServices.getInsurancePlans(),
      CacheServices.getOrganizations(),
    ]);
    setInsurances(i);
    setReferralSources(Organization.toSelectable(o));
    setLoadingData(false);
  };

  const validate = () =>
    !!name &&
    !!ecwId &&
    !!type &&
    !!state &&
    (type !== FacilityType.VESTA || npi) &&
    (type !== FacilityType.EXTERNAL || brand) &&
    (type !== FacilityType.EXTERNAL ||
      brand !== Brand.VESTA ||
      insurance?.length ||
      referralSource?.length);
  const doSubmit = async () => {
    setSubmitted(true);
    if (validate()) {
      setLoading(true);
      try {
        const result = await onSubmit(
          facility?.id || 0,
          getDiff<UpdateFacilityParams>(
            {
              ...facility,
              state: facility?.state?.value,
              brandId: facility?.brand?.valueOf(),
              insurancePlanIds: facility?.insurances?.map(({ id }) => id),
              organizationIds: facility?.organizations?.map(({ id }) => id),
              type: facility?.type?.value,
            },
            {
              ecwId,
              name,
              npi,
              state: state?.value,
              brandId: brand?.valueOf(),
              insurancePlanIds: insurance?.map(({ id }) => id),
              organizationIds: referralSource?.map(
                ({ value }) => value as number,
              ),
              type: type?.value,
            },
          ),
        );
        if (typeof result === 'boolean' && result) {
          onClose();
        } else if (result) {
          new Toast({
            position: 'bottom-right',
            title: 'Validation error',
            body: result,
            type: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setName(facility?.name);
    setECWId(facility?.ecwId);
    setType(facility?.type);
    setNPI(facility?.npi);
    setState(facility?.state);
    setBrand(facility ? facility.brand : Brand.VESTA);
    setInsurance(facility?.insurances || []);
    setReferralSource(Organization.toSelectable(facility?.organizations || []));
  }, [facility]);

  useEffect(() => {
    setOpenDeleteModal(false);
    setLoading(false);
    setSubmitted(false);
  }, [open]);

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <>
      <CollapsableSidebar
        onClose={onClose}
        open={open}
        title={
          <h2>
            {facility?.id
              ? translate('facilities.modal.editTitle', {
                  facility: facility.name,
                })
              : translate('facilities.modal.addTitle')}
          </h2>
        }
      >
        <CollapsableSidebar.Body>
          <div className="grid-wrapper fit">
            <TextInput
              className="grid-span-12"
              error={submitted && !name}
              label={translate('facilities.modal.name')}
              onChange={(value) => {
                const safeValue = value
                  ?.trim()
                  .split(' ')
                  .filter(Boolean)
                  .join(' ');
                setName(safeValue || '');
              }}
              required
              value={name}
            />
            <TextInput
              className="grid-span-12"
              error={submitted && !ecwId}
              label={translate('facilities.modal.ecwId')}
              onChange={(value) => {
                const number = parseInt(value || '', 10);
                setECWId(isNaN(number) ? undefined : number);
              }}
              required
              type="numeric"
              value={ecwId}
            />
            <Select
              className="grid-span-12"
              disableClearable
              error={submitted && !state}
              items={Enum.toSelectable(States.asArray)}
              label={translate('facilities.modal.state')}
              onChange={(item?: Selectable) =>
                setState(item ? States.byKey[item.value] : undefined)
              }
              value={state ? Enum.toSelectable([state])[0] : []}
              required
            />
            <Select
              className="grid-span-12"
              disableClearable
              error={submitted && !type}
              items={Enum.toSelectable(FacilityType.asArray)}
              label={translate('facilities.modal.type')}
              onChange={(item?: Selectable) =>
                setType(item ? FacilityType.byKey[item.value] : undefined)
              }
              value={type ? Enum.toSelectable([type])[0] : []}
              required
            />
            {type === FacilityType.VESTA && (
              <TextInput
                className="grid-span-12"
                error={submitted && !npi}
                label={translate('facilities.modal.npi')}
                onChange={(value) => {
                  const number = parseInt(value || '', 10);
                  setNPI(isNaN(number) ? undefined : number);
                }}
                required
                type="numeric"
                value={npi}
              />
            )}
            {type === FacilityType.EXTERNAL && (
              <>
                <Select
                  className="grid-span-12"
                  disableClearable
                  error={submitted && !brand}
                  items={Enum.toSelectable([Brand.VESTA, Brand.CARE_AT_HOME])}
                  label={translate('facilities.modal.brand')}
                  loading={loadingData}
                  onChange={(item?: Selectable) =>
                    setBrand(item ? Brand.byKey[item.value] : undefined)
                  }
                  required
                  value={brand ? Enum.toSelectable([brand])[0] : undefined}
                />
                {brand === Brand.VESTA && (
                  <>
                    <i className="grid-span-12">
                      {translate(
                        'facilities.modal.memberAssociationDescription',
                      )}
                    </i>
                    <Select
                      className="grid-span-12"
                      error={
                        submitted &&
                        !insurance?.length &&
                        !referralSource?.length
                      }
                      getItemLabel={({ name }: InsurancePlan) => name}
                      items={insurances}
                      label={translate('facilities.modal.insurances')}
                      multiple
                      loading={loadingData}
                      onChange={setInsurance}
                      value={insurance}
                    />
                    <ReferralDropdown
                      className="grid-span-12"
                      error={
                        submitted &&
                        !insurance?.length &&
                        !referralSource?.length &&
                        translate(
                          'facilities.modal.oneOfTheseFieldsMustBeFilled',
                        )
                      }
                      items={referralSources}
                      label={translate('facilities.modal.referralSources')}
                      loading={loadingData}
                      multiple
                      onChange={setReferralSource}
                      value={referralSource}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </CollapsableSidebar.Body>
        <CollapsableSidebar.Buttons>
          {facility?.id ? (
            <Button
              color="primary"
              data-cy="delete-faclility-close"
              onClick={() => setOpenDeleteModal(true)}
              type="outlined"
            >
              {translate('global.delete')}
            </Button>
          ) : (
            <Button
              color="primary"
              data-cy="close-faclility-close"
              onClick={onClose}
            >
              {translate('global.close')}
            </Button>
          )}
          <Button
            color="secondary"
            data-cy="edit-faclility-submit"
            loading={loading}
            onClick={doSubmit}
          >
            {facility?.id ? translate('global.save') : translate('global.add')}
          </Button>
        </CollapsableSidebar.Buttons>
      </CollapsableSidebar>
      {facility && (
        <Modal
          body={translate('facilities.modal.deleteBody', {
            facility: facility.name,
          })}
          maxWidth="sm"
          onClose={() => setOpenDeleteModal(false)}
          onSubmit={async () => {
            const result = await onDelete(facility.id);
            if (result) {
              onClose();
            }
          }}
          open={openDeleteModal}
          title={translate('facilities.modal.deleteTitle')}
        />
      )}
    </>
  );
};

export default FacilityEditModal;
