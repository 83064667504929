import React, { ReactChild } from 'react';
import { translate } from '@vestahealthcare/common/i18n';
import { Select, Radio, DeleteIcon } from 'styleguide';

export interface Option {
  label: string | ReactChild;
  value: string | number;
  disabled?: boolean;
}

interface Props {
  value: string;
  isPreferred: boolean;
  disableSelect?: boolean;
  disableRadio?: boolean;
  options: Option[];
  onChange: (value: string, isPreferred: boolean) => void;
  validator?: (value: string) => boolean;
  autoFocus?: boolean;
  onRemove?: (value: string) => void;
  errorText?: string | boolean;
  submitted?: boolean;
  required?: boolean;
}

const ListPickerRow = (props: Props) => {
  const {
    value,
    isPreferred,
    disableSelect,
    disableRadio,
    options,
    validator,
    onChange,
    autoFocus,
    errorText,
    submitted,
    required,
    onRemove,
  } = props;

  return (
    <div
      className="grid-wrapper-none list-item-row"
      data-cy={`language-selection-${value}`}
    >
      <div className="grid-span-2 icon-wrapper">
        <Radio
          checked={isPreferred}
          disabled={!value || disableRadio}
          onChange={(isChecked) => onChange(value, isChecked)}
        />
      </div>
      <div className="grid-span-8">
        <Select
          autoFocus={autoFocus}
          placeholder={translate('assessment.languagePicker.placeholder')}
          options={options}
          value={value}
          onChange={(newValue: string) => onChange(newValue, isPreferred)}
          errorText={errorText}
          validator={validator}
          submitted={submitted}
          required={required}
          disabled={disableSelect}
        />
      </div>
      {onRemove && (
        <div className="grid-span-2 icon-wrapper text-center">
          <DeleteIcon
            onClick={() => onRemove(value)}
            data-cy="delete-language"
            tag="button"
          />
        </div>
      )}
    </div>
  );
};

export default ListPickerRow;
