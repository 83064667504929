declare global {
  interface Window {
    ga: any;
  }
}

export default async (id: string) => {
  if (!id) return;

  window.ga =
    window.ga ||
    function setGAArgs(...args: any) {
      (window.ga.q = window.ga.q || []).push(args);
    };
  window.ga.l = +new Date();

  window.ga('require', 'eventTracker');
  window.ga('require', 'urlChangeTracker');
  window.ga('require', 'cleanUrlTracker');
  window.ga('require', 'pageVisibilityTracker');
  window.ga('create', id, 'auto', {
    userId: localStorage.getItem('userId'),
  });
  window.ga('send', 'pageview');

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://www.google-analytics.com/analytics.js';
  script.async = true;
  document.head.appendChild(script);
};
