import moment from 'moment';

import { Brand, PatientGender } from '../enums';
import { DATE_FORMAT_SHORT } from '../utils/constants';
import { Address } from './Address';
import { Employee } from './Employee';
import { MemberInsurance } from './MemberInsurance';
import { MemberReferral } from './MemberReferral';
import { make, makeArray } from './Model';

export class IncidentDetailMember {
  activeReferrals: MemberReferral[];

  address?: Address;

  birthDate?: string;

  brand: Brand;

  fullName = '';

  gender: PatientGender;

  id = 0;

  memberInsurances?: MemberInsurance[];

  npOwner?: Employee;

  rnOwner?: Employee;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.activeReferrals = makeArray(obj.activeReferrals, MemberReferral);
    this.address = make(obj.address, Address);
    this.brand = Brand.byKey[obj.brand];
    this.gender = PatientGender.byKey[obj.gender];
    this.memberInsurances = makeArray(
      obj.memberInsurances,
      MemberInsurance,
    ).sort(
      (a: MemberInsurance, b: MemberInsurance) =>
        a.insurancePriority.id - b.insurancePriority.id,
    );
    this.npOwner = make(obj.npOwner, Employee);
    this.rnOwner = make(obj.rnOwner, Employee);
  }

  get birthDateFormatted() {
    return this.birthDate && moment(this.birthDate).format(DATE_FORMAT_SHORT);
  }
}

export default IncidentDetailMember;
