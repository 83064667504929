import 'whatwg-fetch';
import config from 'dash/environments';
import { translate } from '@vestahealthcare/common/i18n';
import store from 'dash/src/redux/store';
import {
  broadcastError,
  setConnection,
  userChangedWarning,
  showConflictingDataWarning,
} from 'dash/src/redux/actions/GlobalActions';
// @ts-ignore
import qs from 'qs';
// @ts-ignore
import keyMirror from 'keymirror';
import {
  ErrorModal,
  Success,
} from '@vestahealthcare/common/utils/global-messages';
import LoginService from 'dash/src/services/LoginServices';
import Session from 'dash/src/services/SessionServices';
import { Moment } from 'moment';
import { Toast } from 'styleguide';

export interface PaginationParams {
  limit?: number;
  offset?: number;
  sort?: string;
}

export interface PaginatedResponseValues {
  total: number;
  limit: number;
  offset: number;
}

export interface PaginatedResponse<T> {
  items: T[];
  pagination: PaginatedResponseValues;
}

export interface PaginatedResponseWithTime<T> extends PaginatedResponse<T>{
  lastUpdatedAt?: Moment;
}

class ResponseError extends Error {
  type: string;

  allErrors: any;

  errors: any;

  fields: any;

  hasFieldErrors: boolean;

  isResponseError: boolean;

  constructor(type: string, errors = [], customMessage = '') {
    // parse translations, i.e. errors that come with a code
    errors
      .filter(({ code }) => !!code)
      .map((error: any) =>
        Object.assign(error, {
          // TODO: no longer used
          // we suppose that message is always required
          message: translate(`errors.${error.code}.message`, {
            defaultValue: error.code,
          }),
          detail: translate(`errors.${error.code}.detail`, {
            defaultValue: '',
          }),
        }),
      );

    const mergedMessage = errors.map((error: any) => error.message).join('\n');
    super(customMessage || mergedMessage);

    this.type = type;
    this.allErrors = Array.from(errors);

    // Errors without field info
    this.errors = errors.filter(({ field }) => !field);

    // Only field errors. And convert them to { field: message, ... } object
    this.fields = errors
      .filter(({ field }) => !!field)
      .reduce(
        (all, { field, message }) =>
          field ? { ...all, [field]: message } : all,
        {},
      );

    this.hasFieldErrors = Object.keys(this.fields).length > 0;
    this.isResponseError = true;
  }
}

const REQUEST_PARAM_TYPE = keyMirror({
  json: null,
  querystring: null,
  form: null,
  pdf: null,
  file: null,
});

const RESPONSE_TYPE = keyMirror({
  json: null,
  blob: null,
});

// Perform a user refresh if the authed ID is not the same
// than the current user in Dash
const checkAuthenticatedUser = (response: any) => {
  const authedUserId = response.headers.get('Authenticated-User');
  const currentUserId = Session.actingUser && Session.actingUser.id.toString();
  if (currentUserId && authedUserId && currentUserId !== authedUserId) {
    // Warn the user that the logged in user has changed and refresh page
    // The timeout is to avoid show this modal when we do impersonate, while the page is being reloaded
    // TODO: Figure out a better approach.
    setTimeout(() => store.dispatch(userChangedWarning()), 1000);
  }
};

const removeTerminalSlash = (uri = '') =>
  uri.charAt(uri.length - 1) === '/' ? uri.substring(0, uri.length - 1) : uri;

const prependSlash = (uri = '') => (uri.charAt(0) === '/' ? uri : `/${uri}`);

const getUrl = (
  uri: string,
  body: any = {},
  requestParamType: string = REQUEST_PARAM_TYPE.querystring,
) => {
  const base = `${removeTerminalSlash(config.apiv2.url)}${prependSlash(uri)}`;
  if (requestParamType === REQUEST_PARAM_TYPE.querystring) {
    const query = qs.stringify(body, { arrayFormat: 'repeat' });
    return `${base}?${query}`;
  }

  return base;
};

const getFetchConfig = (
  method: string = 'GET',
  body: any = {},
  requestParamType: string = REQUEST_PARAM_TYPE.querystring,
): any => {
  let params;

  const headers = {
    'X-Response-Version': 2,
  };

  switch (requestParamType) {
    case REQUEST_PARAM_TYPE.querystring:
      params = {
        headers,
        credentials: 'include',
        mode: 'cors',
      };
      break;
    case REQUEST_PARAM_TYPE.json:
      params = {
        credentials: 'include',
        headers: {
          ...headers,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(body || {}),
      };
      break;
    case REQUEST_PARAM_TYPE.pdf:
      params = {
        credentials: 'include',
        headers: {
          ...headers,
          Accept: 'application/pdf',
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(body || {}),
      };
      break;
    case REQUEST_PARAM_TYPE.file:
      params = {
        credentials: 'include',
        headers: {
          ...headers,
          Accept: 'application/json',
        },
        body,
      };
      break;
    case REQUEST_PARAM_TYPE.form:
      params = {
        // qs.stringify(..., { arrayFormat: 'brackets' })
        // formats it in the appropriate way for x-www-form-urlencoded
        credentials: 'include',
        body: body && qs.stringify(body, { arrayFormat: 'brackets' }),
        headers: {
          ...headers,
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        },
      };
      break;
    default:
  }

  return { method, ...params };
};

const UNSAFE_HTTP_METHODS = ['POST', 'PUT', 'PATCH', 'DELETE'];

const shouldRetryModal = (summary: string, errors: any) =>
  new Promise((resolve, reject) => {
    ErrorModal.show({
      title:
        summary ||
        'Warning: One or more possible issues were detected. ' +
          'Please review your changes and confirm they are correct.',
      messages: errors,
      onConfirm: resolve,
      onCancel: reject,
    });
  });

const processJsonResponse = async (
  json: any,
  status: number,
  { method }: any,
  retry?: (confirmUrl: string) => any,
  omitSuccessModal = false,
  responseStatus = false,
) => {
  const { errorSummary = '', errors = [] } = json;

  // error if >= 400 status code
  const isError = Math.floor(status / 100) > 3;

  if (!isError && UNSAFE_HTTP_METHODS.includes(method) && !omitSuccessModal) {
    Success.show();
  }

  if (isError) {
    switch (status) {
      case 400: {
        throw new ResponseError(
          'badResponseType',
          errors,
          'There are some issues with your request. Please review the form data.',
        );
      }

      case 401:
        throw new ResponseError('auth', errors);

      case 403:
        throw new ResponseError('permissionDenied', errors);

      case 404:
        throw new ResponseError('notFound', errors);

      case 409: {
        // Conflict
        const { confirmUrl } = json;

        // There is no way to confirm the action.
        // Let's just show the conflict error modal
        if (!confirmUrl) {
          throw new ResponseError('conflictError', errors);
        }

        try {
          await shouldRetryModal(errorSummary, errors);
          // Strips `/v2` since Api.js adds it later
          return retry && retry(json.confirmUrl.replace(/^\/?v2/, ''));
        } catch (e) {
          throw new ResponseError(
            'cancelRetry',
            errors,
            'Your request has been cancelled',
          );
        }
      }

      case 456:
        throw new ResponseError('invalidDomain', errors);

      default: {
        throw new ResponseError(
          'other',
          errors,
          `Error ${status || 'Unknown'}`,
        );
      }
    }
  }
  if (responseStatus) {
    return {
      status,
      response: json.response,
    };
  }

  return json.response;
};

const testAuthenticatedUser = () =>
  new Promise((resolve) => {
    if (!Session.actingUser) {
      return resolve(false);
    }

    // try a basic request for fetching current user info
    fetch(getUrl('/employees/self'), getFetchConfig())
      .then((response) => resolve(response.status === 200))
      .catch(() => resolve(false));
  });

const buildMethod = (
  method: string,
  requestParamType: string,
  responseType = RESPONSE_TYPE.json,
  responseStatus = false,
) => {
  const requestConfig = { method, requestParamType };
  return function makeRequest(
    uri: string,
    body?: any,
    requestOptions: any = {},
  ): any {
    return fetch(
      getUrl(uri, body, requestParamType),
      getFetchConfig(method, body, requestParamType),
    )
      .then((response) => {
        const { status } = response;

        // Make Dash user consistent with API
        checkAuthenticatedUser(response);

        if (status === 204) {
          return response;
        }

        switch (responseType) {
          case RESPONSE_TYPE.json:
            if (responseStatus && status === 202) {
              return {
                status,
                response: null,
              };
            }
            return response
              .json()
              .then((json: any) =>
                processJsonResponse(
                  json,
                  response.status,
                  requestConfig,
                  (confirmUrl: string) =>
                    makeRequest(confirmUrl || uri, { ...body }, requestOptions),
                  requestOptions.omitSuccessModal,
                  responseStatus,
                ),
              );
          case RESPONSE_TYPE.blob: {
            if (status >= 400) {
              // Error
              return response
                .json()
                .then((json: any) =>
                  processJsonResponse(json, status, requestConfig),
                );
            }
            if (responseStatus) {
              return {
                response: response.blob(),
                status,
              };
            }
            return response.blob();
          }
          default: {
            throw new ResponseError(
              'badResponseType',
              [],
              'Unhandled RESPONSE_TYPE',
            );
          }
        }
      })
      .catch((responseError) => {
        const { type, message, errors } = responseError;
        const errorMessages = errors?.map((error: any) => error?.message).join('; ')
        switch (type) {
          case 'cancelRetry':
            break; // Do nothing

          case 'auth':
          case 'permissionDenied':
            testAuthenticatedUser().then((validAuth) => {
              if (!validAuth) {
                // Redirect to login if it's an actual auth problem
                LoginService.redirectToLogin();
              } else if (requestOptions.showToast) {
                new Toast({
                  title: 'Permission Denied',
                  body: errorMessages,
                  type: 'error',
                  position: 'bottom-right',
                });
              } else if (!requestOptions.omitModal) {
                ErrorModal.show(responseError);
                store.dispatch(broadcastError(responseError));
              }
            });
            break;

          case 'invalidDomain':
            // todo: show a proper error message
            // eslint-disable-next-line no-alert
            alert(
              'Invalid google account. Please choose a hometeamcare.com account.',
            );
            LoginService.redirectToLogin();
            break;

          case 'conflictError':
            if (requestOptions.showToast) {
              new Toast({
                title: 'Warning',
                body: errorMessages,
                type: 'warning',
                position: 'bottom-right',
              });
            } else if (!requestOptions.omitModal) {
              store.dispatch(showConflictingDataWarning(message));
            }
            break;

          case 'badResponseType':
            if (requestOptions.showToast) {
              new Toast({
                title: 'Error',
                body: errorMessages,
                type: 'error',
                position: 'bottom-right',
              });
            } else if (!requestOptions.omitModal) {
              ErrorModal.show(responseError);
              store.dispatch(broadcastError(responseError));
            }
            break;

          case 'error':
          default:
            if (requestOptions.showToast) {
              new Toast({
                title: 'Error',
                body: errorMessages,
                type: 'error',
                position: 'bottom-right',
              });
            } else if (!requestOptions.omitModal) {
              ErrorModal.show(message);

              if (message === 'Failed to fetch') {
                store.dispatch(setConnection(false));
              } else {
                store.dispatch(broadcastError(responseError));
              }
            }
        }

        throw responseError; // Continue on to the next catch
      });
  };
};

export default {
  getv2: buildMethod('GET', REQUEST_PARAM_TYPE.querystring),
  getv2Status: buildMethod(
    'GET',
    REQUEST_PARAM_TYPE.querystring,
    RESPONSE_TYPE.json,
    true,
  ),
  getv2Blob: buildMethod(
    'GET',
    REQUEST_PARAM_TYPE.querystring,
    RESPONSE_TYPE.blob,
  ),
  getv2BlobStatus: buildMethod(
    'GET',
    REQUEST_PARAM_TYPE.querystring,
    RESPONSE_TYPE.blob,
    true,
  ),

  postv2JSON: buildMethod('POST', REQUEST_PARAM_TYPE.json),
  postv2: buildMethod('POST', REQUEST_PARAM_TYPE.form),
  postv2Blob: buildMethod('POST', REQUEST_PARAM_TYPE.json, RESPONSE_TYPE.blob),
  postv2PDF: buildMethod('POST', REQUEST_PARAM_TYPE.pdf, RESPONSE_TYPE.blob),
  postv2File: buildMethod('POST', REQUEST_PARAM_TYPE.file, RESPONSE_TYPE.json),

  putv2JSON: buildMethod('PUT', REQUEST_PARAM_TYPE.json),
  putv2File: buildMethod('PUT', REQUEST_PARAM_TYPE.file, RESPONSE_TYPE.json),

  deletev2: buildMethod('DELETE', REQUEST_PARAM_TYPE.querystring),
  deletev2JSON: buildMethod('DELETE', REQUEST_PARAM_TYPE.json),

  patchv2: buildMethod('PATCH', REQUEST_PARAM_TYPE.form),
  patchv2JSON: buildMethod('PATCH', REQUEST_PARAM_TYPE.json),
};
