import favicon from '../static/images/favicons/demo.png';

export default {
  favicon,
  apiv2: {
    url: 'https://api.demo.hometeamcare.com/v2',
    credentials: {
      consumerKey: 2,
    },
  },
  auth: {
    redirectUrl: 'https://dash.demo.vestahealthcare.com',
    clientId: '60b4b6b2hrmcqdb9p4b0csbp0d',
    hostedDomainUrl:
      'https://nonprod-vesta-dash.auth.us-east-1.amazoncognito.com',
  },
  env: 'demo',
  staticUrl: 'https://s3.amazonaws.com/hometeam-static',
  isDemo: true,
  isDev: false,
  isProd: false,
  isQA: false,
  isStage: true,
  segment: {
    writeKey: 'AumyqOPYuayxR5GMX1L407gHOaRlWnAW',
  },
  appCenter: {
    apiToken: '85e072e6f4285e95def6ea7e6e2a0065e2c2f8a4',
  },
  launchDarkly: '5e73e19bcd123c077b2862fe',
  streamChat: 'c6qrdnvrcp6n',
  clearTriageToken: 'lKdYlQWk1sMCRWn5EyUc',
};
