import { Language } from '../enums';
import { Translation } from '.';

export class AbstractTranslatedEntity {
  translations: Translation[] = [];

  // Find a translation or return null if does not exist
  findTranslation(language: Language) {
    return this.translations.find(
      (translation) => translation.language === language,
    );
  }

  // Find a translation or return a new empty translation
  getTranslation(language: Language) {
    return this.findTranslation(language) || new Translation({ language });
  }

  getLanguages() {
    return this.translations
      .filter((translation) => !translation.isEmpty())
      .map((translation) => translation.language.toString())
      .sort();
  }
}

export default AbstractTranslatedEntity;
