import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/styles';

import TaskStatus from '@vestahealthcare/common/enums/TaskStatus';
import { Task } from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT_WITH_TIME,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import { Colors, Text } from 'styleguide';
import { Chip } from 'styleguide-v2';

import SubtaskPositionCircle from './SubtaskPositionCircle';
import TaskDetails from './TaskDetails';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 15,
    backgroundColor: Colors.bkgdBlue,
  },
  containerBlocked: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'not-allowed',
    padding: 15,
    color: Colors.gray,
    backgroundColor: Colors.gray,
  },
  name: {
    flex: 0.1,
  },
  description: {
    flex: 0.5,
  },
  metaContainer: {
    display: 'flex',
    flex: 0.4,
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  meta: {
    flex: 2.5,
  },

  positionContainer: {
    flex: 0.05,
  },
});

type Props = {
  task: Task;
  active?: boolean;
  onUpdate: (subtask: Task) => void;
};

export const Subtask = ({ active = false, task, onUpdate }: Props) => {
  const [currentTask, setTask] = useState<Task>(task);
  const [showDetails, setShowDetails] = useState(active);
  const styles = useStyles();

  useEffect(() => setTask(task), [task]);

  return (
    <>
      <div
        className={
          currentTask.canBeStarted ? styles.container : styles.containerBlocked
        }
        onClick={() =>
          currentTask.canBeStarted ? setShowDetails(!showDetails) : null
        }
      >
        <div className={styles.positionContainer}>
          <SubtaskPositionCircle position={currentTask.displayPosition()} />
        </div>
        <div className={styles.name}>
          <Chip
            color={
              TaskStatus.COLOR_MAP.get(currentTask.status)?.color || 'action'
            }
            type={
              TaskStatus.COLOR_MAP.get(currentTask.status)?.type || 'outlined'
            }
          >
            {currentTask.status.toString()}
          </Chip>
        </div>
        <span className={styles.description}>
          <Text small>{currentTask.taskDefinition?.name}</Text>
        </span>
        <div className={styles.metaContainer}>
          <Text small className={styles.meta}>
            {currentTask.employeeGroup?.name || EMPTY}
          </Text>
          <Text small className={styles.meta}>
            {currentTask.assignee?.fullName || EMPTY}
          </Text>
          <div className={styles.meta}>
            <Text small>
              {moment
                .unix(currentTask.createdAt)
                .format(DATE_FORMAT_SHORT_WITH_TIME)}
            </Text>
            <Text small>
              {' by '}
              {currentTask.createdBy?.fullName || EMPTY}
            </Text>
          </div>
        </div>
      </div>
      {showDetails && (
        <TaskDetails
          task={task}
          onUpdate={(task) => {
            setTask(task);
            onUpdate(task);
          }}
        />
      )}
    </>
  );
};

export default Subtask;
