import { BackgroundColors, Colors } from 'styleguide-v2/src/styles/Colors';

export type ColorOption =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'warning'
  | 'error'
  | 'action';
export type TypeOption = 'contained' | 'contained-2' | 'outlined';
export type ColorProps = {
  borderColor?: string;
  bkgColor: string;
  color: string;
};

export const getColors = (color: ColorOption, type: TypeOption) => {
  if (color === 'secondary') {
    if (type === 'outlined') {
      return {
        borderColor: Colors.green,
        bkgColor: 'transparent',
        color: Colors.green,
      };
    }
    if (type === 'contained-2') {
      return {
        bkgColor: Colors.lightMint,
        color: Colors.textGreen,
      };
    }
    return {
      bkgColor: Colors.green,
      color: Colors.white,
    };
  }
  if (color === 'info') {
    if (type === 'outlined') {
      return {
        borderColor: Colors.blue,
        bkgColor: 'transparent',
        color: Colors.blue,
      };
    }
    if (type === 'contained-2') {
      return {
        bkgColor: BackgroundColors.lightBlue,
        color: Colors.blue,
      };
    }
    return {
      bkgColor: Colors.blue,
      color: Colors.white,
    };
  }
  if (color === 'warning') {
    if (type === 'outlined') {
      return {
        borderColor: Colors.gold,
        bkgColor: 'transparent',
        color: Colors.gold,
      };
    }
    if (type === 'contained-2') {
      return {
        bkgColor: BackgroundColors.lightYellow,
        color: Colors.textBlack,
      };
    }
    return {
      bkgColor: Colors.gold,
      color: Colors.white,
    };
  }
  if (color === 'action') {
    if (type === 'outlined') {
      return {
        borderColor: Colors.gray,
        bkgColor: 'transparent',
        color: Colors.textGray,
      };
    }
    return {
      bkgColor: Colors.lighterGray,
      color: Colors.textGray,
    };
  }
  if (color === 'error') {
    if (type === 'outlined') {
      return {
        borderColor: Colors.textRed2,
        bkgColor: 'transparent',
        color: Colors.textRed2,
      };
    }
    if (type === 'contained-2') {
      return {
        bkgColor: `${Colors.textRed}40`,
        color: Colors.textRed2,
      };
    }
    return {
      bkgColor: Colors.textRed,
      color: Colors.white,
    };
  }
  if (type === 'outlined') {
    return {
      borderColor: Colors.textOrange,
      bkgColor: 'transparent',
      color: Colors.textOrange,
    };
  }
  if (type === 'contained-2') {
    return {
      bkgColor: `${Colors.orange}40`,
      color: Colors.textBlack,
    };
  }
  return {
    bkgColor: Colors.orange,
    color: Colors.white,
  };
};
