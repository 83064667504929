import classNames from 'classnames';
import React, { ReactNode, useMemo } from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import { Colors, Fonts, Table as MTable } from 'styleguide-v2';

const MEMBER_WARNING_COLUMNS = (header: string) => [
  {
    headerName: header,
    component: (data: ReactNode[]) => data[0],
    width: 190,
  },
  {
    headerName: translate('memberWarnings.table.status'),
    component: (data: ReactNode[]) => data[1],
    width: 190,
  },
];

export type Table = {
  header: string;
  data: ReactNode[][];
};

type Props = {
  className?: string;
  table: Table;
};

const useStyles = makeStyles({
  table: {
    '&& > thead > tr > th': {
      color: Colors.darkGray,
      fontSize: `calc(${Fonts.fontSize} * 0.625)`,
    },
  },
});

export const MemberWarningsTable = ({ className, table }: Props) => {
  const styles = useStyles();

  const columns = useMemo(() => MEMBER_WARNING_COLUMNS(table.header), [
    table.header,
  ]);

  return (
    <MTable
      className={classNames(className, styles.table)}
      config={{
        columns,
        compact: true,
        data: table.data,
        paginationOptions: [-1],
      }}
      whiteBackground
    />
  );
};

export default MemberWarningsTable;
