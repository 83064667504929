import { AsYouTypeFormatter } from 'google-libphonenumber';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { MemberContactTimeType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  CareTeamMemberAide,
  MemberContactInfoTimes,
  Organization,
  OrganizationType,
} from '@vestahealthcare/common/models';
import { parse } from '@vestahealthcare/common/utils/phone';

import { DateTimePicker, Select, TextArea, TextInput } from 'styleguide-v2';

import {
  DatesTimeItem,
  EditDateTimes,
} from 'dash/src/components/EditDateTimes';
import { CacheServices } from 'dash/src/services';
import { CreateCareTeamMemberAideParams } from 'dash/src/services/CareTeamServices';

type Props = {
  isAgency?: boolean;
  isOther?: boolean;
  member: CareTeamMemberAide;
  onChange: (params: Partial<CreateCareTeamMemberAideParams>) => void;
  submitted?: boolean;
};

export const AideFields = ({
  isAgency,
  isOther,
  member,
  onChange,
  submitted,
}: Props) => {
  const normalize = (str?: string) => str?.replace(/\D/g, '') ?? '';
  const formatter = new AsYouTypeFormatter('US');
  const formatAsPhoneNumber = (str?: string): string => {
    formatter.clear();
    return [...normalize(str)]
      .slice(0, 10)
      .reduce((_total, digit) => formatter.inputDigit(digit), '');
  };

  const [loading, setLoading] = useState<boolean>(false);

  const [agencies, setAgencies] = useState<Organization[]>([]);

  const [dateTimes, setDateTimes] = useState<DatesTimeItem[]>();
  const [agency, setAgency] = useState<Organization>();
  const [externalId, setExternalId] = useState<string>();
  const [faxNumber, setFaxNumber] = useState<string>();
  const [notes, setNotes] = useState<string>();
  const [organization, setOrganization] = useState<string>();
  const [paid, setPaid] = useState<boolean>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [temp, setTemp] = useState<boolean>();
  const [tempUntil, setTempUntil] = useState<Date>();

  const getInitialData = async () => {
    setLoading(true);
    if (!agencies?.length) {
      const agencies = await CacheServices.getOrganizations();
      setAgencies(
        agencies
          .filter(({ type }) => type?.id === OrganizationType.AGENCY)
          .sort(({ name: a }, { name: b }) => a.localeCompare(b)),
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    setDateTimes(
      member?.getDaysOfService ? member?.getDaysOfService() : undefined,
    );
    setExternalId(member?.externalId);
    setFaxNumber(member?.otherFaxNumber);
    setNotes(member?.otherNotes);
    setOrganization(member?.otherOrganizationName);
    setPaid(member?.paid);
    setPhoneNumber(member?.otherPhoneNumber);
    setTemp(member?.temp);
    setTempUntil(member?.tempUntil?.toDate());
  }, [member]);

  useEffect(() => {
    setAgency(agencies?.find(({ id }) => id === member?.agencyId));
  }, [member, agencies]);

  const handleChange = (changes: Partial<CreateCareTeamMemberAideParams>) => {
    onChange(changes);
  };

  const handleDateTime = (changes: DatesTimeItem[]) => {
    const contactTimes: MemberContactInfoTimes[] = [];
    changes.forEach(({ dates, type, startTime, endTime }) => {
      if (!dates?.length) {
        if (type) {
          contactTimes.push({
            type: type || MemberContactTimeType.ANY_TIME,
            startTime: startTime ? moment(startTime.getTime()) : undefined,
            endTime: endTime ? moment(endTime.getTime()) : undefined,
          } as MemberContactInfoTimes);
        }
      } else {
        dates.forEach((day) => {
          contactTimes.push({
            dayOfWeek: day,
            type: type || MemberContactTimeType.ANY_TIME,
            startTime: startTime ? moment(startTime.getTime()) : undefined,
            endTime: endTime ? moment(endTime.getTime()) : undefined,
          } as MemberContactInfoTimes);
        });
      }
    });
    handleChange({ aideTimes: contactTimes });
  };

  return (
    <div className="grid-wrapper fit">
      <h2 className="grid-span-12" style={{ fontSize: '1em' }}>
        {translate('careTeam.modals.edit.aideFields')}
      </h2>

      <EditDateTimes
        className="grid-span-12"
        onChange={(newValue?: DatesTimeItem[]) => {
          setDateTimes(newValue || []);
          handleDateTime(newValue || []);
        }}
        submitted={submitted}
        title={translate('careTeam.common.expectedDatesOfService')}
        defaultValue={dateTimes}
      />

      <Select.Choice
        className="grid-span-6"
        data-cy="care-team-member-paid"
        label={translate('careTeam.common.paid')}
        onChange={(paid?: boolean) => {
          setPaid(paid);
          handleChange({ paid });
        }}
        value={paid}
      />

      <TextInput
        className="grid-span-6"
        data-cy="care-team-member-externalId"
        label={translate('careTeam.common.externalId')}
        onChange={(externalId?: string) => {
          setExternalId(externalId);
          handleChange({ externalId });
        }}
        value={externalId}
      />

      <Select.Choice
        className="grid-span-6"
        data-cy="care-team-member-temp"
        label={translate('careTeam.common.temporal')}
        onChange={(temp?: boolean) => {
          setTemp(temp);
          handleChange({ temp });
        }}
        value={temp}
      />

      {temp === true && (
        <DateTimePicker
          className="grid-span-6"
          data-cy="care-team-member-temp"
          label={translate('careTeam.common.tempUntil')}
          minDate={Date.now()}
          onChange={(tempUntil?: Date) => {
            setTempUntil(tempUntil);
            handleChange({
              tempUntil: tempUntil
                ? moment(tempUntil.getTime()).format()
                : null,
            });
          }}
          value={tempUntil}
        />
      )}

      {isAgency && (
        <Select
          className="grid-span-6"
          data-cy="care-team-member-agency"
          items={agencies}
          getItemLabel={({ name }: Organization) => name}
          label={translate('careTeam.common.agency')}
          loading={loading}
          onChange={(agency?: Organization) => {
            setAgency(agency);
            handleChange({ agency });
          }}
          value={agency}
        />
      )}

      {isOther && (
        <>
          <TextInput
            className="grid-span-6"
            data-cy="care-team-member-organization"
            label={translate('careTeam.common.organization')}
            onChange={(name?: string) => {
              setOrganization(name);
              handleChange({ otherOrganizationName: name || null });
            }}
            value={organization}
          />
          <TextInput
            className="grid-span-6"
            data-cy="care-team-member-phone-number"
            label={translate('careTeam.common.phoneNumber')}
            onChange={(val?: string) => {
              let number = val;

              if (val && val.length > 1) {
                // parse returns undefined at length 1
                number = parse(val)?.getNationalNumber()?.toString() || '';
                number = [...number].slice(0, 10).join('');
              }
              setPhoneNumber(number);
              handleChange({ otherPhoneNumber: number || null });
            }}
            value={phoneNumber ? formatAsPhoneNumber(phoneNumber) : undefined}
          />
          <TextInput
            className="grid-span-6"
            data-cy="care-team-member-fax-number"
            label={translate('careTeam.common.faxNumber')}
            onChange={(val?: string) => {
              let number = val;

              if (val && val.length > 1) {
                // parse returns undefined at length 1
                number = parse(val)?.getNationalNumber()?.toString() || '';
                number = [...number].slice(0, 10).join('');
              }
              setFaxNumber(number);
              handleChange({ otherFaxNumber: number || null });
            }}
            value={faxNumber ? formatAsPhoneNumber(faxNumber) : undefined}
          />
          <TextArea
            className="grid-span-12"
            data-cy="care-team-member-notes"
            label={translate('careTeam.common.notes')}
            minRows={2}
            onChange={(otherNotes?: string) => {
              setNotes(otherNotes);
              handleChange({ otherNotes: otherNotes || null });
            }}
            value={notes}
          />
        </>
      )}
    </div>
  );
};
