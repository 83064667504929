import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { makeStyles } from '@mui/styles';

import {
  DayOfWeek,
  RPMAdherenceLevel,
  States,
} from '@vestahealthcare/common/enums';
import Enum, {
  Selectable,
  SelectableInfo,
} from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  Organization,
  StoredFilter,
} from '@vestahealthcare/common/models';

import {
  DateTimePicker,
  Label,
  Panel,
  Select,
  TextInput,
  ToggleDateRange,
  ToggleDateRangeHandle,
} from 'styleguide-v2';
import {
  FilterGroup,
  FilterItem,
  FilterSelectable,
} from 'styleguide-v2/src/components/Panel';

import { ReferralDropdown } from 'dash/src/components/ReferralDropdown';
import {
  FiltersVestaTeam,
  VestaTeamKey,
} from 'dash/src/components/filters/FiltersVestaTeam';
import { GetCCMMemberParams } from 'dash/src/services/CCMServices';
import { transformDateToDaysRange } from 'dash/src/utils/dateUtils';
import {
  getFilterCount,
  getFilterItem,
  getFilterValue,
} from 'dash/src/utils/filterUtils';

import {
  ATTEMPTS_OPTIONS,
  BIANUAL_VV_STATUSES,
  CCM_STATUSES,
  MONTHLY_CCM_STATUSES,
  RECENT_CYCLES_OPTIONS,
  RISK_LEVEL_OPTIONS,
  TIME_OPTIONS,
  VESTA_START_DATE_OPTIONS,
  VESTA_START_DATE_OPTION_AFTER,
  VESTA_START_DATE_OPTION_BEFORE,
} from './CCMData';

type KeyGetCCMParams = keyof GetCCMMemberParams;

type Props = {
  data: {
    brands: Selectable[];
    carePlanGroups: Selectable[];
    employees: SelectableInfo<Employee>[];
    insurances: Selectable[];
    languages: Selectable[];
    programExtensions: Selectable[];
    programExtensionStatuses: Selectable[];
    reasons: Selectable[];
    referralSources: SelectableInfo<Organization>[];
    worklistGroups: Selectable[];
  };
  disabledFilters?: KeyGetCCMParams[];
  filters?: {
    [x in KeyGetCCMParams | 'rpmStartDate' | 'lastReadingDate']?: FilterItem;
  };
  loadingFilters?: boolean;
  open: boolean;
  onChange: (
    filters: {
      [x in KeyGetCCMParams | 'rpmStartDate' | 'lastReadingDate']?: FilterItem;
    },
  ) => void;
  onClose: () => void;
  onDeleteFilter: (filter: StoredFilter) => Promise<void>;
  onEditFilter: (filter: StoredFilter) => Promise<void>;
  onSaveFilter: (filter: StoredFilter) => Promise<void>;
  storedFilters?: StoredFilter[];
};

const MEMBER_FILTERS: KeyGetCCMParams[] = [
  'memberIds',
  'memberName',
  'state',
  'language',
  'referralSource',
  'brandId',
  'insurance',
];

const PROGRAM_FILTERS: KeyGetCCMParams[] = [
  'startDateFrom',
  'startDateTo',
  'programExtensionId',
  'programExtensionStatus',
  'excludedProgramExtensionId',
  'carePlanGroupId',
  'riskLevel',
  'worklistGroupId',
];

const VESTA_TEAM_FILTERS: VestaTeamKey[] = [
  'podManagerId',
  'npOwnerId',
  'rnOwnerId',
  'healthCoachId',
  'careCoordinatorId',
  'engagementOwnerId',
];

const CCM_FILTERS: KeyGetCCMParams[] = [
  'isComplete',
  'ccmCallStatus',
  'totalMinutesFrom',
  'totalMinutesTo',
  'hasProfessionalTime',
  'hasValidCCM',
  'touchPointReason',
];

const RPM_FILTERS: (KeyGetCCMParams | 'rpmStartDate' | 'lastReadingDate')[] = [
  'rpmAdherenceId',
  'rpmStartDate',
  'rpmStartDateFrom',
  'rpmStartDateTo',
  'lastReadingDate',
  'lastReadingDateFrom',
  'lastReadingDateTo',
  'rpmMinutesFrom',
  'rpmMinutesTo',
];

const BHI_FILTERS: KeyGetCCMParams[] = ['bhiMinutesFrom', 'bhiMinutesTo'];

const CONTACT_FILTERS: KeyGetCCMParams[] = [
  'aideScheduleDay',
  'aideScheduleTime',
  'contactDay',
  'contactTime',
];

const DUE_FILTERS: KeyGetCCMParams[] = [
  'isBiannualComplete',
  'carePlanDue',
  'initialCPDue',
];

const OPS_FILTERS: KeyGetCCMParams[] = [
  'numberOfCalls',
  'careTeamDiscoveryDue',
  'isUnreachable',
];

const useStyles = makeStyles({
  container: {
    '&&': {
      marginTop: 0,
    },
  },
  dateInput: {
    '&&': {
      marginTop: '0.5rem',
    },
  },
  rpmStarDate: {
    '& > div:last-child > div': {
      flex: 1,
    },
  },
});

export const CCMFiltersModal = ({
  data,
  disabledFilters,
  filters,
  loadingFilters,
  open,
  onChange,
  onClose,
  onDeleteFilter,
  onEditFilter,
  onSaveFilter,
  storedFilters,
}: Props) => {
  const styles = useStyles();
  const { showBhi } = useFlags();

  const refLastCarePlan = useRef<ToggleDateRangeHandle>(null);
  const refRPMStartDate = useRef<ToggleDateRangeHandle>(null);
  const reflastReadingDate = useRef<ToggleDateRangeHandle>(null);
  const [vestaStartDateAfter, setVestaStartDateAfter] = useState<number>();
  const [vestaStartDate, setVestaStartDate] = useState<Date>();

  const [pendingFilters, setPendingFilters] = useState<
    {
      [x in KeyGetCCMParams | 'rpmStartDate' | 'lastReadingDate']?: FilterItem;
    }
  >(filters || {});

  const referralSelected = useMemo(() => {
    const ids = (pendingFilters?.referralSource
      ?.value as FilterSelectable[])?.map(({ value }) => value);
    return data.referralSources
      .filter(({ value }) => ids?.includes(value))
      ?.map(({ info }) => info as Organization);
  }, [data.referralSources, pendingFilters?.referralSource]);

  const exlucdeReferralSelected = useMemo(() => {
    const ids = (pendingFilters?.excludedReferralSource
      ?.value as FilterSelectable[])?.map(({ value }) => value);
    return data.referralSources
      .filter(({ value }) => ids?.includes(value))
      ?.map(({ info }) => info as Organization);
  }, [data.referralSources, pendingFilters?.excludedReferralSource]);

  useEffect(() => {
    setTimeout(() => {
      refLastCarePlan.current?.init();
    }, 0);
  }, [filters?.carePlanDateFrom?.value, filters?.carePlanDateTo?.value]);

  useEffect(() => {
    setTimeout(() => {
      refRPMStartDate.current?.init();
    }, 0);
  }, [
    filters?.rpmStartDate?.value,
    filters?.rpmStartDateFrom?.value,
    filters?.rpmStartDateTo?.value,
  ]);

  useEffect(() => {
    setTimeout(() => {
      reflastReadingDate.current?.init();
    }, 0);
  }, [
    filters?.lastReadingDate?.value,
    filters?.lastReadingDateFrom?.value,
    filters?.lastReadingDateTo?.value,
  ]);

  const resetFilters = () => {
    if (filters?.startDateFrom) {
      setVestaStartDate(
        ((filters?.startDateFrom?.value as FilterSelectable)
          ?.value as Moment)?.toDate(),
      );
      setVestaStartDateAfter(VESTA_START_DATE_OPTION_AFTER);
    } else if (filters?.startDateTo) {
      setVestaStartDate(
        ((filters?.startDateTo?.value as FilterSelectable)
          ?.value as Moment)?.toDate(),
      );
      setVestaStartDateAfter(VESTA_START_DATE_OPTION_BEFORE);
    } else {
      setVestaStartDate(undefined);
      setVestaStartDateAfter(undefined);
    }
    setPendingFilters(filters || {});
  };

  const calculateDefaultToggleValue = (
    option: number | string,
    from: Moment,
    to: Moment,
  ) => {
    const optionNumber = parseInt(option?.toString(), 10);
    if (!isNaN(optionNumber) && optionNumber !== -1) {
      return optionNumber;
    }
    if (from || to) {
      return `${from ? from.format() : ''},${to ? to.format() : ''}`;
    }
    return -1;
  };

  useEffect(() => {
    resetFilters();
  }, [filters]);

  useEffect(() => {
    let startDate: Date | undefined;
    if (
      vestaStartDate &&
      moment(vestaStartDate.getTime()).isValid() &&
      moment().diff(moment(vestaStartDate.getTime()), 'years') < 10
    ) {
      startDate = vestaStartDate;
    }

    if (
      !pendingFilters.startDateFrom &&
      !pendingFilters.startDateTo &&
      (!vestaStartDate || !startDate)
    )
      return;

    setPendingFilters({
      ...pendingFilters,
      startDateTo:
        vestaStartDateAfter === VESTA_START_DATE_OPTION_BEFORE
          ? getFilterItem(
              `${translate('ccm.common.vestaStartDate')} ${translate(
                'common.before',
              )}`,
              startDate,
            )
          : undefined,
      startDateFrom:
        vestaStartDateAfter === VESTA_START_DATE_OPTION_AFTER
          ? getFilterItem(
              `${translate('ccm.common.vestaStartDate')} ${translate(
                'common.after',
              )}`,
              startDate,
            )
          : undefined,
    });
  }, [vestaStartDate, vestaStartDateAfter]);

  return (
    <Panel.FilterModal
      className={styles.container}
      open={open}
      onClear={() => {
        setVestaStartDate(undefined);
        setVestaStartDateAfter(undefined);
        onChange({});
        setTimeout(() => refLastCarePlan.current?.init(), 0);
        setTimeout(() => refRPMStartDate.current?.init(), 0);
      }}
      onClose={onClose}
      onDeleteFilter={onDeleteFilter}
      onEditFilter={onEditFilter}
      onSaveFilter={onSaveFilter}
      onSubmit={() => onChange(pendingFilters)}
      size={400}
      storedFilters={storedFilters}
    >
      <FilterGroup
        title="Member Details"
        count={getFilterCount(pendingFilters, MEMBER_FILTERS)}
      >
        <Select
          allowAddNewValue
          data-cy="ccm-filter-modal-id"
          items={[]}
          inputType="number"
          multiple
          noOptionsText={translate('members.typeToSearch')}
          onChange={(values: Selectable[]) =>
            setPendingFilters({
              ...pendingFilters,
              memberIds: getFilterItem(
                translate('ccm.common.memberId'),
                values.map(({ label }) => ({
                  value: label,
                  label,
                })),
              ),
            })
          }
          label={translate('ccm.common.memberId')}
          size="small"
          value={pendingFilters.memberIds?.value}
        />
        <TextInput
          data-cy="ccm-filters-modal-name"
          label={translate('ccm.common.memberName')}
          onChange={(name?: string) =>
            setPendingFilters({
              ...pendingFilters,
              memberName: getFilterItem(
                translate('ccm.common.memberName'),
                name,
              ),
            })
          }
          size="small"
          value={getFilterValue(pendingFilters.memberName?.value) as string}
        />
        <Select
          data-cy="ccm-filter-modal-state"
          items={Enum.toSelectable(States.asArray)}
          label={translate('ccm.common.state')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              state: getFilterItem(translate('ccm.common.state'), values),
            });
          }}
          size="small"
          value={pendingFilters.state?.value || []}
        />
        <Select
          data-cy="ccm-filter-modal-language"
          grouped={({ preferred }) =>
            preferred
              ? translate('global.preferred')
              : translate('global.other')
          }
          items={data.languages.filter(({ disabled }) => !disabled)}
          label={translate('ccm.common.language')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              language: getFilterItem(translate('ccm.common.language'), values),
            });
          }}
          size="small"
          value={pendingFilters.language?.value || []}
        />
        <Select
          data-cy="ccm-filter-modal-brand"
          items={data.brands}
          label={translate('ccm.common.brand')}
          loading={loadingFilters}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              brandId: getFilterItem(translate('ccm.common.brand'), values),
            });
          }}
          size="small"
          value={pendingFilters.brandId?.value || []}
        />
        <ReferralDropdown
          data-cy="ccm-filter-modal-referral-sources"
          getItemDisabled={(referral: Selectable) =>
            !!(pendingFilters.excludedReferralSource
              ?.value as FilterSelectable[])?.find(
              ({ value }) => value === referral.value,
            ) ||
            exlucdeReferralSelected.reduce(
              (acc, item) =>
                acc || item.hasDescendant(referral.value as number),
              false,
            )
          }
          items={data.referralSources}
          label={translate('ccm.common.referralSources')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              referralSource: getFilterItem(
                translate('ccm.common.referralSources'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.referralSource?.value || []}
        />
        <ReferralDropdown
          data-cy="ccm-filter-modal-excluded-referral-sources"
          getItemDisabled={(referral: Selectable) =>
            !!(pendingFilters.referralSource
              ?.value as FilterSelectable[])?.find(
              ({ value }) => value === referral.value,
            ) ||
            referralSelected.reduce(
              (acc, item) =>
                acc || item.hasDescendant(referral.value as number),
              false,
            )
          }
          items={data.referralSources}
          label={translate('ccm.common.excludedReferralSources')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              excludedReferralSource: getFilterItem(
                translate('ccm.common.excludedReferralSources'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.excludedReferralSource?.value || []}
        />
        <Select
          data-cy="ccm-filter-modal-insurance"
          items={data.insurances}
          label={translate('ccm.common.insurance')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              insurance: getFilterItem(
                translate('ccm.common.insurance'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.insurance?.value || []}
        />
      </FilterGroup>
      <FilterGroup
        title="Program Details"
        count={getFilterCount(pendingFilters, PROGRAM_FILTERS)}
      >
        <div className="grid-wrapper" style={{ gap: '0 1rem' }}>
          <Label className="grid-span-12">
            {translate('ccm.common.vestaStartDate')}
          </Label>
          <Select
            className="grid-span-6"
            data-cy="ccm-filters-member-start-after"
            items={VESTA_START_DATE_OPTIONS}
            onChange={(item?: Selectable) =>
              setVestaStartDateAfter(item?.value as number | undefined)
            }
            value={
              vestaStartDateAfter
                ? VESTA_START_DATE_OPTIONS[vestaStartDateAfter - 1]
                : undefined
            }
            size="small"
          />
          <DateTimePicker
            className={classNames('grid-span-6', styles.dateInput)}
            data-cy="ccm-filters-member-start-date"
            dateFormat="M/d/yy"
            disabled={!!disabledFilters || !vestaStartDateAfter}
            maxDate={Date.now()}
            onChange={setVestaStartDate}
            value={vestaStartDate}
            size="small"
          />
        </div>
        <Select
          data-cy="ccm-filter-modal-pe"
          items={data.programExtensions}
          label={translate('ccm.common.programExtensions')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              programExtensionId: getFilterItem(
                translate('ccm.common.programExtensions'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.programExtensionId?.value || []}
        />
        <Select
          data-cy="ccm-filter-modal-pe-status"
          items={data.programExtensionStatuses}
          label={translate('ccm.common.programExtensionStatus')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              programExtensionStatus: getFilterItem(
                translate('ccm.common.programExtensionStatus'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.programExtensionStatus?.value || []}
        />
        <Select
          data-cy="ccm-filter-modal-pe"
          items={data.programExtensions}
          label={translate('ccm.common.excludedprogramExtensions')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              excludedProgramExtensionId: getFilterItem(
                translate('ccm.common.excludedprogramExtensions'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.excludedProgramExtensionId?.value || []}
        />
        <Select
          data-cy="ccm-filter-modal-care-plan-group"
          items={data.carePlanGroups}
          label={translate('ccm.common.carePlanGroup')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              carePlanGroupId: getFilterItem(
                translate('ccm.common.carePlanGroup'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.carePlanGroupId?.value || []}
        />
        <Select
          data-cy="ccm-filter-modal-risk-level"
          items={RISK_LEVEL_OPTIONS}
          label={translate('ccm.common.riskLevel')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              riskLevel: getFilterItem(
                translate('ccm.common.riskLevel'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.riskLevel?.value || []}
        />
        <Select
          data-cy="ccm-filter-modal-care-plan-group"
          items={data.worklistGroups}
          label={translate('ccm.common.employeeGroup')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              worklistGroupId: getFilterItem(
                translate('ccm.common.employeeGroup'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.worklistGroupId?.value || []}
        />
      </FilterGroup>
      <FiltersVestaTeam
        data={{ employees: data.employees }}
        filters={pendingFilters}
        keys={VESTA_TEAM_FILTERS}
        loading={loadingFilters}
        onChange={(updatedFilters) =>
          setPendingFilters({ ...pendingFilters, ...updatedFilters })
        }
      />
      <FilterGroup
        title="CCM Details"
        count={getFilterCount(pendingFilters, CCM_FILTERS)}
      >
        <Select
          data-cy="ccm-filter-monthly-ccm-status"
          items={MONTHLY_CCM_STATUSES}
          label={translate('ccm.common.monthlyCCMStatus')}
          onChange={(value: Selectable) => {
            setPendingFilters({
              ...pendingFilters,
              isComplete: getFilterItem(
                translate('ccm.common.monthlyCCMStatus'),
                value,
              ),
            });
          }}
          size="small"
          value={pendingFilters.isComplete?.value || []}
        />
        <Select
          data-cy="ccm-filter-modal-ccm-status"
          items={CCM_STATUSES}
          label={translate('ccm.common.ccmStatus')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              ccmCallStatus: getFilterItem(
                translate('ccm.common.ccmStatus'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.ccmCallStatus?.value || []}
        />
        <div className="grid-wrapper" style={{ gap: '0 1rem' }}>
          <Label className="grid-span-12">
            {translate('ccm.common.totalMinutes')}
          </Label>
          <TextInput
            className="grid-span-6"
            maxLength={4}
            onChange={(newValue?: string) => {
              const number =
                newValue !== undefined ? parseInt(newValue, 10) : NaN;
              setPendingFilters({
                ...pendingFilters,
                totalMinutesFrom: getFilterItem(
                  translate('ccm.common.totalMinutesFrom'),
                  isNaN(number) ? undefined : number,
                ),
              });
            }}
            placeholder={translate('global.from')}
            value={
              (pendingFilters.totalMinutesFrom?.value as FilterSelectable)
                ?.value as number
            }
            size="small"
          />
          <TextInput
            className="grid-span-6"
            maxLength={4}
            onChange={(newValue?: string) => {
              const number =
                newValue !== undefined ? parseInt(newValue, 10) : NaN;
              setPendingFilters({
                ...pendingFilters,
                totalMinutesTo: getFilterItem(
                  translate('ccm.common.totalMinutesTo'),
                  isNaN(number) ? undefined : number,
                ),
              });
            }}
            placeholder={translate('global.to')}
            value={
              (pendingFilters.totalMinutesTo?.value as FilterSelectable)
                ?.value as number
            }
            size="small"
          />
        </div>
        <Select.Choice
          data-cy="ccm-filter-modal-has-orofessional-time"
          label={translate('ccm.common.hasProfessionalTime')}
          onChange={(hasProfessionalTime?: boolean) => {
            setPendingFilters({
              ...pendingFilters,
              hasProfessionalTime: getFilterItem(
                translate('ccm.common.hasProfessionalTime'),
                hasProfessionalTime,
              ),
            });
          }}
          size="small"
          value={
            (pendingFilters.hasProfessionalTime?.value as FilterSelectable)
              ?.value as boolean | undefined
          }
        />
        <Select.Choice
          data-cy="ccm-filter-modal-has-valid-ccm"
          label={translate('ccm.common.hasValidCCM')}
          onChange={(hasValidCCM?: boolean) => {
            setPendingFilters({
              ...pendingFilters,
              hasValidCCM: getFilterItem(
                translate('ccm.common.hasValidCCM'),
                hasValidCCM,
              ),
            });
          }}
          size="small"
          value={
            (pendingFilters.hasValidCCM?.value as FilterSelectable)?.value as
              | boolean
              | undefined
          }
        />
        <Select
          data-cy="ccm-filter-modal-touchpoint-reasons"
          items={data.reasons}
          label={translate('ccm.common.touchpointReasons')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              touchPointReason: getFilterItem(
                translate('ccm.common.touchpointReasons'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.touchPointReason?.value || []}
        />
        <ToggleDateRange
          data-cy="ccm-filter-modal-last-care-plan-date"
          defaultValue={
            (pendingFilters.carePlanDateFrom?.value as FilterSelectable)?.value
              ? transformDateToDaysRange(
                  ((pendingFilters.carePlanDateFrom?.value as FilterSelectable)
                    ?.value as Moment)?.toDate(),
                  new Date(),
                  [7, 30, 90],
                )
              : undefined
          }
          fullWidth
          items={[-1, 7, 30, 90]}
          label={translate('ccm.common.lastCarePlan')}
          onChange={(from, to) => {
            if (from && to) {
              setPendingFilters({
                ...pendingFilters,
                carePlanDateFrom: getFilterItem(
                  translate('ccm.common.lastCarePlan'),
                  from,
                ),
              });
            } else {
              setPendingFilters({
                ...pendingFilters,
                carePlanDateFrom: undefined,
              });
            }
          }}
          ref={refLastCarePlan}
          size="small"
        />
      </FilterGroup>
      <FilterGroup
        title="RPM Details"
        count={getFilterCount(pendingFilters, RPM_FILTERS)}
      >
        <Select
          data-cy="ccm-filter-modal-rpm-adherence"
          items={Enum.toSelectable(RPMAdherenceLevel.asArray)}
          label={translate('ccm.common.rpmAdherence')}
          limitTags={1}
          multiple
          onChange={(value: Selectable) => {
            setPendingFilters({
              ...pendingFilters,
              rpmAdherenceId: getFilterItem(
                translate('ccm.common.rpmAdherence'),
                value,
              ),
            });
          }}
          size="small"
          value={pendingFilters.rpmAdherenceId?.value || []}
        />
        <ToggleDateRange
          className={styles.rpmStarDate}
          data-cy="ccm-filter-modal-rpm-start-date"
          defaultValue={calculateDefaultToggleValue(
            (filters?.rpmStartDate?.value as FilterSelectable)?.value as
              | number
              | string,
            (filters?.rpmStartDateFrom?.value as FilterSelectable)
              ?.value as Moment,
            (filters?.rpmStartDateTo?.value as FilterSelectable)
              ?.value as Moment,
          )}
          fullWidth
          items={[-1, 7, 30, 90, 'custom']}
          label={translate('ccm.common.rpmStartDate')}
          customExpand="vertical"
          onChange={(from, to, option) => {
            if (!option || option === -1) {
              setPendingFilters({
                ...pendingFilters,
                rpmStartDate: undefined,
                rpmStartDateFrom: undefined,
                rpmStartDateTo: undefined,
              });
            } else if (typeof option === 'number') {
              setPendingFilters({
                ...pendingFilters,
                rpmStartDate: {
                  label: translate('ccm.common.rpmStartDate'),
                  value: {
                    label: `Last ${option} days`,
                    value: option,
                  },
                },
                rpmStartDateFrom: undefined,
                rpmStartDateTo: undefined,
              });
            } else {
              setPendingFilters({
                ...pendingFilters,
                rpmStartDate: undefined,
                rpmStartDateFrom: from
                  ? getFilterItem(
                      translate('ccm.common.rpmStartDateFrom'),
                      from,
                    )
                  : undefined,
                rpmStartDateTo: to
                  ? getFilterItem(translate('ccm.common.rpmStartDateTo'), to)
                  : undefined,
              });
            }
          }}
          ref={refRPMStartDate}
          size="small"
        />
        <ToggleDateRange
          className={styles.rpmStarDate}
          data-cy="ccm-filter-modal-rpm-last-reading-date"
          defaultValue={calculateDefaultToggleValue(
            (filters?.lastReadingDate?.value as FilterSelectable)?.value as
              | number
              | string,
            (filters?.lastReadingDateFrom?.value as FilterSelectable)
              ?.value as Moment,
            (filters?.lastReadingDateTo?.value as FilterSelectable)
              ?.value as Moment,
          )}
          fullWidth
          items={[-1, 7, 30, 90, 'custom']}
          label={translate('ccm.common.lastReadingDate')}
          customExpand="vertical"
          onChange={(from, to, option) => {
            if (!option || option === -1) {
              setPendingFilters({
                ...pendingFilters,
                lastReadingDate: undefined,
                lastReadingDateFrom: undefined,
                lastReadingDateTo: undefined,
              });
            } else if (typeof option === 'number') {
              setPendingFilters({
                ...pendingFilters,
                lastReadingDate: {
                  label: translate('ccm.common.lastReadingDate'),
                  value: {
                    label: `Last ${option} days`,
                    value: option,
                  },
                },
                lastReadingDateFrom: undefined,
                lastReadingDateTo: undefined,
              });
            } else {
              setPendingFilters({
                ...pendingFilters,
                lastReadingDate: undefined,
                lastReadingDateFrom: from
                  ? getFilterItem(
                      translate('ccm.common.lastReadingDateFrom'),
                      from,
                    )
                  : undefined,
                lastReadingDateTo: to
                  ? getFilterItem(translate('ccm.common.lastReadingDateTo'), to)
                  : undefined,
              });
            }
          }}
          ref={reflastReadingDate}
          size="small"
        />
        <div className="grid-wrapper" style={{ gap: '0 1rem' }}>
          <Label className="grid-span-12">
            {translate('ccm.common.rpmTotalMinutes')}
          </Label>
          <TextInput
            className="grid-span-6"
            maxLength={4}
            onChange={(newValue?: string) => {
              const number =
                newValue !== undefined ? parseInt(newValue, 10) : NaN;
              setPendingFilters({
                ...pendingFilters,
                rpmMinutesFrom: getFilterItem(
                  translate('ccm.common.rpmMinutesFrom'),
                  isNaN(number) ? undefined : number,
                ),
              });
            }}
            placeholder={translate('global.from')}
            value={
              (pendingFilters.rpmMinutesFrom?.value as FilterSelectable)
                ?.value as number
            }
            size="small"
            type="numeric"
          />
          <TextInput
            className="grid-span-6"
            maxLength={4}
            onChange={(newValue?: string) => {
              const number =
                newValue !== undefined ? parseInt(newValue, 10) : NaN;
              setPendingFilters({
                ...pendingFilters,
                rpmMinutesTo: getFilterItem(
                  translate('ccm.common.rpmMinutesTo'),
                  isNaN(number) ? undefined : number,
                ),
              });
            }}
            placeholder={translate('global.to')}
            value={
              (pendingFilters.rpmMinutesTo?.value as FilterSelectable)
                ?.value as number
            }
            size="small"
            type="numeric"
          />
        </div>
        <Select
          data-cy="ccm-filter-modal-recent-cycles"
          items={RECENT_CYCLES_OPTIONS}
          label={translate('ccm.common.recentCycles')}
          limitTags={1}
          multiple
          onChange={(value: Selectable) => {
            setPendingFilters({
              ...pendingFilters,
              recentCyclesMetCount: getFilterItem(
                translate('ccm.common.recentCycles'),
                value,
              ),
            });
          }}
          size="small"
          value={pendingFilters.recentCyclesMetCount?.value || []}
        />
      </FilterGroup>
      {showBhi && (
        <FilterGroup
          title="BHI Details"
          count={getFilterCount(pendingFilters, BHI_FILTERS)}
        >
          <div className="grid-wrapper" style={{ gap: '0 1rem' }}>
            <Label className="grid-span-12">
              {translate('ccm.common.bhiTotalMinutes')}
            </Label>
            <TextInput
              className="grid-span-6"
              maxLength={4}
              onChange={(newValue?: string) => {
                const number =
                  newValue !== undefined ? parseInt(newValue, 10) : NaN;
                setPendingFilters({
                  ...pendingFilters,
                  bhiMinutesFrom: getFilterItem(
                    translate('ccm.common.bhiMinutesFrom'),
                    isNaN(number) ? undefined : number,
                  ),
                });
              }}
              placeholder={translate('global.from')}
              value={
                (pendingFilters.bhiMinutesFrom?.value as FilterSelectable)
                  ?.value as number
              }
              size="small"
              type="numeric"
            />
            <TextInput
              className="grid-span-6"
              maxLength={4}
              onChange={(newValue?: string) => {
                const number =
                  newValue !== undefined ? parseInt(newValue, 10) : NaN;
                setPendingFilters({
                  ...pendingFilters,
                  bhiMinutesTo: getFilterItem(
                    translate('ccm.common.bhiMinutesTo'),
                    isNaN(number) ? undefined : number,
                  ),
                });
              }}
              placeholder={translate('global.to')}
              value={
                (pendingFilters.bhiMinutesTo?.value as FilterSelectable)
                  ?.value as number
              }
              size="small"
              type="numeric"
            />
          </div>
        </FilterGroup>
      )}
      <FilterGroup
        title="Contact Preferences"
        count={getFilterCount(pendingFilters, CONTACT_FILTERS)}
      >
        <Select
          data-cy="ccm-filter-modal-care-team-schedule-days"
          items={Enum.toSelectable(DayOfWeek.asArray)}
          label={translate('ccm.common.aideScheduleDays')}
          limitTags={1}
          multiple
          onChange={(value: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              aideScheduleDay: getFilterItem(
                translate('ccm.common.aideScheduleDays'),
                value,
              ),
            });
          }}
          size="small"
          value={pendingFilters.aideScheduleDay?.value || []}
        />
        <Select
          data-cy="ccm-filter-modal-care-team-schedule-time"
          items={TIME_OPTIONS}
          label={translate('ccm.common.aideScheduleTime')}
          limitTags={1}
          multiple
          onChange={(newValue: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              aideScheduleTime: getFilterItem(
                translate('ccm.common.aideScheduleTime'),
                newValue,
              ),
            });
          }}
          size="small"
          value={pendingFilters.aideScheduleTime?.value || []}
        />
        <Select
          data-cy="ccm-filter-modal-preferred-days"
          items={Enum.toSelectable(DayOfWeek.asArray)}
          label={translate('ccm.common.contactPreferenceDate')}
          limitTags={1}
          multiple
          onChange={(value: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              contactDay: getFilterItem(
                translate('ccm.common.contactPreferenceDate'),
                value,
              ),
            });
          }}
          size="small"
          value={pendingFilters.contactDay?.value || []}
        />
        <Select
          data-cy="ccm-filter-care-team-schedule-time"
          items={TIME_OPTIONS}
          label={translate('ccm.common.contactPreferenceTime')}
          limitTags={1}
          multiple
          onChange={(newValue: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              contactTime: getFilterItem(
                translate('ccm.common.contactPreferenceTime'),
                newValue,
              ),
            });
          }}
          size="small"
          value={pendingFilters.contactTime?.value || []}
        />
      </FilterGroup>
      <FilterGroup
        title="Due For"
        count={getFilterCount(pendingFilters, DUE_FILTERS)}
      >
        <Select
          data-cy="ccm-filter-modal-bianual-vv-status"
          items={BIANUAL_VV_STATUSES}
          label={translate('ccm.common.bianualVVStatus')}
          onChange={(value: Selectable) => {
            setPendingFilters({
              ...pendingFilters,
              isBiannualComplete: getFilterItem(
                translate('ccm.common.bianualVVStatus'),
                value,
              ),
            });
          }}
          size="small"
          value={pendingFilters.isBiannualComplete?.value || []}
        />
        <Select.Choice
          data-cy="ccm-filter-modal-carePlanDue"
          label={translate('ccm.common.carePlanDue')}
          onChange={(value?: boolean) => {
            setPendingFilters({
              ...pendingFilters,
              carePlanDue: getFilterItem(
                translate('ccm.common.carePlanDue'),
                value,
              ),
            });
          }}
          size="small"
          value={
            (pendingFilters.carePlanDue?.value as FilterSelectable)?.value as
              | boolean
              | undefined
          }
        />
        <Select.Choice
          data-cy="ccm-filter-modal-initialCP"
          label={translate('ccm.common.initialCP')}
          onChange={(value?: boolean) => {
            setPendingFilters({
              ...pendingFilters,
              initialCPDue: getFilterItem(
                translate('ccm.common.initialCP'),
                value,
              ),
            });
          }}
          size="small"
          value={
            (pendingFilters.initialCPDue?.value as FilterSelectable)?.value as
              | boolean
              | undefined
          }
        />
      </FilterGroup>
      <FilterGroup
        title="Operational Details"
        count={getFilterCount(pendingFilters, OPS_FILTERS)}
      >
        <Select
          data-cy="ccm-filter-modal-attempts"
          items={ATTEMPTS_OPTIONS}
          label={translate('ccm.common.attempts')}
          onChange={(value: Selectable) => {
            setPendingFilters({
              ...pendingFilters,
              numberOfCalls: getFilterItem(
                translate('ccm.common.attempts'),
                value,
              ),
            });
          }}
          size="small"
          value={pendingFilters.numberOfCalls?.value || []}
        />
        <Select
          data-cy="ccm-filter-modal-careTeamDiscovery"
          items={[{ label: translate('global.yes'), value: true }]}
          label={translate('ccm.common.careTeamDiscovery')}
          onChange={(value: Selectable) => {
            setPendingFilters({
              ...pendingFilters,
              careTeamDiscoveryDue: getFilterItem(
                translate('ccm.common.careTeamDiscovery'),
                value,
              ),
            });
          }}
          size="small"
          value={pendingFilters.careTeamDiscoveryDue?.value || []}
        />
        <Select.Choice
          data-cy="ccm-filter-modal-unreachable"
          disableClearable
          label={translate('ccm.common.unreachable')}
          onChange={(value?: boolean) => {
            setPendingFilters({
              ...pendingFilters,
              isUnreachable: getFilterItem(
                translate('ccm.common.unreachable'),
                value,
              ),
            });
          }}
          size="small"
          value={
            (pendingFilters.isUnreachable?.value as FilterSelectable)?.value as
              | boolean
              | undefined
          }
        />
      </FilterGroup>
    </Panel.FilterModal>
  );
};
