import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { Fragment, useEffect, useState } from 'react';

import { MemberStatus, PhoneType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  MemberPhone,
  Patient,
  UserPhone,
} from '@vestahealthcare/common/models';
import { validate } from '@vestahealthcare/common/utils/phone';

import { PhoneLink } from 'styleguide-v2';

import { EditPhones } from 'dash/src/components/EditPhones';
import {
  getMemberPhones,
  updateMemberPhones,
} from 'dash/src/services/PatientServices';

import { BaseModal } from './BaseModal';

interface Props {
  patient: Patient;
  onSubmit: (patient: Patient) => void;
}

const sortByPrimary = (a: UserPhone | PhoneItem, b: UserPhone | PhoneItem) => {
  if (a.primary && !b.primary) return -1;
  if (b.primary && !a.primary) return 1;
  return 0;
};

const DetailsComponent = React.memo(({ phones }: { phones?: UserPhone[] }) => (
  <div
    data-cy="member-phone-numbers-readonly"
    className="grid-span-11 grid-wrapper"
    style={{ gridGap: 0, height: 'fit-content' }}
  >
    {phones?.length ? (
      phones.sort(sortByPrimary).map((phone) => (
        <Fragment key={`member-phone-${phone.id}`}>
          <h4 className="grid-span-6">
            {phone.phone.type?.toString() || ''}{' '}
            {phone.primary ? `(${translate('global.primary')})` : ''}
          </h4>
          <PhoneLink className="grid-span-6" phone={phone.phone.number} />
        </Fragment>
      ))
    ) : (
      <>
        <h4 className="grid-span-5">
          {translate('personalDetails.phoneNumber')}
        </h4>
        <p className="grid-span-7">EMPTY</p>
      </>
    )}
  </div>
));

interface PhoneItem {
  id?: number;
  number?: string;
  type?: PhoneType;
  primary?: boolean;
  isEmrIntegrated?: boolean;
  isCrmIntegrated?: boolean;
}

const itemFromMemberPhone = (item: UserPhone | MemberPhone) => ({
  id: item.id,
  number: item.phone.number,
  type: item.phone.type,
  primary: item.primary,
  readOnly: item instanceof MemberPhone && item.readOnly,
  isEmrIntegrated: item instanceof MemberPhone && item.emrIntegrated,
  isCrmIntegrated: item instanceof MemberPhone && item.crmIntegrated,
});

export const EditPhoneModal = React.memo(({ patient, onSubmit }: Props) => {
  const { showSalesforceIntegration } = useFlags();
  const editable = patient.isEditable();
  const isPatientEmrIntegrated =
    patient.isEmrIntegrated() &&
    patient.status &&
    [MemberStatus.ACCEPTED, MemberStatus.ACTIVE].includes(patient.status);
  const isPatientCrmIntegrated =
    showSalesforceIntegration &&
    patient.isCrmIntegrated() &&
    patient.status === MemberStatus.PASSIVE;
  const [phoneNumbers, setPhoneNumbers] = useState<UserPhone[]>();
  const [current, setCurrent] = useState<PhoneItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    setLoading(true);
    getMemberPhones(patient.id)
      .then((phones) => setPhoneNumbers(phones))
      .catch(() => setLoading(false));
  }, [patient, refresh]);

  useEffect(() => {
    setCurrent(
      phoneNumbers
        ? phoneNumbers.map(itemFromMemberPhone).sort(sortByPrimary)
        : [],
    );
    setSubmitted(false);
    setLoading(false);
  }, [phoneNumbers]);

  // Are all valid and do we have at least one primary
  const isValid = () =>
    current.every((item) => validate(item.number) && !!item.type) &&
    current.some((item) => item.primary);

  const doRefresh = () => setRefresh(refresh + 1);

  const reset = () => {
    setCurrent(phoneNumbers?.map(itemFromMemberPhone) || []);
    setSubmitted(false);
    setLoading(false);
  };

  const submit = async (): Promise<boolean> => {
    let result = true;
    setSubmitted(true);
    if (!isValid()) return false;
    setLoadingSubmit(true);

    try {
      await updateMemberPhones(patient.id, current);
      onSubmit(patient);
    } catch (e) {
      result = false;
    }

    setLoadingSubmit(false);
    doRefresh();
    return result;
  };

  const hasReadOnlyPhones = !!current?.find(
    ({ isEmrIntegrated, isCrmIntegrated }) =>
      (isPatientEmrIntegrated && isEmrIntegrated) ||
      (isPatientCrmIntegrated && isCrmIntegrated),
  );

  return (
    <BaseModal
      details={<DetailsComponent phones={phoneNumbers} />}
      data-cy="member-phone-numbers"
      editable={editable}
      loading={loading}
      loadingSubmit={loadingSubmit}
      onShowModal={doRefresh}
      onSubmit={submit}
      onCancel={reset}
      title={translate('personalDetails.phoneNumbers')}
    >
      <EditPhones
        className="grid-span-12"
        getItemReadOnly={(phone) =>
          Boolean(
            (isPatientEmrIntegrated && (phone as MemberPhone)?.emrIntegrated) ||
              (isPatientCrmIntegrated && (phone as MemberPhone)?.crmIntegrated),
          )
        }
        getItemReadOnlyPrimary={(phone) =>
          hasReadOnlyPhones &&
          !(
            (isPatientEmrIntegrated && (phone as MemberPhone)?.emrIntegrated) ||
            (isPatientCrmIntegrated && (phone as MemberPhone)?.crmIntegrated)
          )
        }
        hideFaxType
        phones={current?.map(
          (phone) =>
            new MemberPhone({
              id: phone.id,
              emrIntegrated: phone.isEmrIntegrated,
              crmIntegrated: phone.isCrmIntegrated,
              primary: phone.primary,
              phone: {
                number: phone.number,
                type: phone.type?.value,
              },
            }),
        )}
        onChange={(phones) =>
          setCurrent([
            ...phones.map((phone) => ({
              id: phone?.id,
              number: phone?.phone?.number,
              type: phone?.phone?.type,
              primary: phone?.primary,
              isEmrIntegrated: (phone as MemberPhone)?.emrIntegrated,
              isCrmIntegrated: (phone as MemberPhone).crmIntegrated,
            })),
          ])
        }
        submitted={submitted}
      />
      {isPatientEmrIntegrated && hasReadOnlyPhones && (
        <>
          <br />
          <p className="grey small no-margin" style={{ marginLeft: '2rem' }}>
            {translate('personalDetails.warningECWPhones')}
          </p>
        </>
      )}
      {isPatientCrmIntegrated && hasReadOnlyPhones && (
        <>
          <br />
          <p className="grey small no-margin" style={{ marginLeft: '2rem' }}>
            {translate('personalDetails.warningCRMPhones')}
          </p>
        </>
      )}
    </BaseModal>
  );
});

export default EditPhoneModal;
