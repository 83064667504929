import React from 'react';
import { FieldProps } from 'react-jsonschema-form';
import { TextInput } from 'styleguide';

const TextOtherField = (props: FieldProps) => {
  const {
    schema: { title },
    formData,
    label,
    onChange,
    formContext: { readonly },
    rawErrors,
  } = props;

  if (readonly) {
    if (!formData) {
      return null;
    }

    return <p className="remove-top-margin italic">{formData}</p>;
  }

  const hasErrors = !!rawErrors && !!rawErrors.length;
  const errorText = hasErrors && rawErrors && rawErrors[rawErrors.length - 1];

  return (
    <div className="remove-top-margin">
      <TextInput
        autoFocus={!formData}
        onChange={onChange}
        value={formData}
        placeholder={label || title}
        errorText={errorText}
        showError={hasErrors}
      />
    </div>
  );
};

export default TextOtherField;
