export class InsurancePlanEcw {
  ecwId = '';

  insuranceType: string | null = null;

  planNumber: string | null = null;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default InsurancePlanEcw;
