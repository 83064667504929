import { Brand, TimeUnit } from '../enums';
import { BaseEnum } from './BaseEnum';
import { EngagementCampaignAudience } from './EngagementCampaignAudience';
import { EngagementCampaignCondition } from './EngagementCampaignCondition';
import { EngagementCampaignTimingOption } from './EngagementCampaignTimingOption';
import { EngagementContentBase } from './EngagementContentBase';
import { make, makeArray, makeEnums } from './Model';

export class EngagementCampaign {
  active = false;

  audience: EngagementCampaignAudience[];

  conditions: EngagementCampaignCondition[];

  content: EngagementContentBase;

  delay = 0;

  delayUnit?: TimeUnit;

  deliveryMethod: BaseEnum;

  exceptions: EngagementCampaignCondition[];

  id = 0;

  name = '';

  timingOption: EngagementCampaignTimingOption;

  triggers: EngagementCampaignCondition[];

  brands?: Brand[];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.audience = makeArray(obj.audiences, EngagementCampaignAudience);
    this.content = make(obj.content, EngagementContentBase);
    this.deliveryMethod = make(obj.deliveryMethods[0], BaseEnum);
    this.conditions = makeArray(obj.conditions, EngagementCampaignCondition);
    this.exceptions = makeArray(obj.exceptions, EngagementCampaignCondition);
    this.timingOption = make(obj.timingOption, EngagementCampaignTimingOption);
    this.triggers = makeArray(obj.triggers, EngagementCampaignCondition);
    this.delayUnit = obj.delayUnit ? TimeUnit.byKey[obj.delayUnit] : undefined;
    this.brands = makeEnums(obj.brands, Brand);
  }
}

export default EngagementCampaign;
