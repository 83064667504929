import React, { PureComponent } from 'react';

import './main.less';
import { PopoverPlacement } from '.';

interface Props {
  children: any;
  onClick: (e: React.MouseEvent, popoverPlacement: PopoverPlacement) => void;
  svgRef: string;
  popoverPlacement: PopoverPlacement;
}

export default class BodySection extends PureComponent<Props> {
  static defaultProps = {
    popoverPlacement: 'right',
  };

  render() {
    const { children, onClick, svgRef, popoverPlacement } = this.props;

    return (
      <g ref={svgRef} onClick={(e) => onClick(e, popoverPlacement)}>
        {children}
      </g>
    );
  }
}
