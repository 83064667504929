import React from 'react';
import { MinMaxInput } from 'styleguide';
import { WidgetProps } from '../types';
import { BLOOD_PRESSURE } from '@vestahealthcare/common/utils/constants';

const MinMaxWidget = (props: WidgetProps) => {
  const { onChange, rawErrors }: WidgetProps = props;

  const hasErrors = !!rawErrors && !!rawErrors.length;
  // TODO: note that we're assuming only one error, this is the only way I found to update the error state when
  // the data is changed after validation
  const errorText = hasErrors && rawErrors && rawErrors.pop();

  return (
    <MinMaxInput
      className="min-max"
      errorText={errorText || undefined}
      onChange={onChange}
      units={BLOOD_PRESSURE}
      showError={hasErrors}
    />
  );
};

export default MinMaxWidget;
