import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';

import {
  MemberStatus,
  ObservationCategory,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';

import {
  CollapseIcon,
  Colors,
  Icon,
  Link,
  NavItem,
  Sidebar,
  SidebarLink,
} from 'styleguide';
import { Chip } from 'styleguide-v2';

import { fetchSMSUnreadCount } from 'dash/src/redux/slices/ChatSlice';
import { useSelector } from 'dash/src/redux/store';
import { some } from 'lodash';

const LINKS = {
  USER_FRIENDLY: 'https://forms.gle/sAo6ZXiwzr1s3Fum6',
  ELATION:
    'https://sso.app.elationemr.com/login/login.htm?fromURI=/oauth2/v1/authorize/redirect?okta_key=xrghsKuHaDdvvKP3MeMupPxzDPEv8p0bi3C2cmh3g0s',
  THOROUGH_CARE: 'https://vesta.secure.thoroughcare.com/users/sign_in#/',
  E_CLINICAL_WORKS:
    'https://nyhtvh83jwawpcd42vapp.ecwcloud.com/mobiledoc/jsp/webemr/login/newLogin.jsp',
};

const useStyles = makeStyles({
  externalLinksSection: {
    '& a.external-link': {
      color: Colors.btnBlue,
    },
  },
  smsIndicator: {
    position: 'absolute',
    right: '1.6rem',
  },
});

const isInObservations = (pathname: string) =>
  some(ObservationCategory.asArray, (obs) => pathname.includes(obs.valueOf()));

type Params = {
  patientId: string;
};

interface Props extends RouteComponentProps<Params> {
  patient: Patient;
}

const MemberDashboardSidebar = withRouter((props: Props) => {
  const REFRESH_INTERVAL = 60 * 1000;
  const styles = useStyles();
  const { showMemberDashboard, showTocPage } = useFlags();
  const {
    location: { pathname },
    match: {
      params: { patientId },
    },
    patient,
  } = props;

  const baseUrl = `/patients/${patientId}`;
  const dispatch = useDispatch();
  const smsCount = useSelector(
    (state) => state.chatSlice.patientSMSUnreadCount,
  );
  const [isHealthExpanded, setHealthExpanded] = useState(true);
  const [isObsExpanded, setObsExpanded] = useState(isInObservations(pathname));
  const [isPhipExpanded, setPhipExpanded] = useState(true);
  const [isLinksExpanded, setLinksExpanded] = useState(true);

  const [smsIndicator, setSMSIndicator] = useState(<></>);

  const fetchSMSIndicator = async () => {
    if (patient?.phones?.length) {
      dispatch(
        fetchSMSUnreadCount(patient?.phones?.map(({ phone }) => phone.number)),
      );
    }
  };

  useEffect(() => {
    if (smsCount) {
      setSMSIndicator(
        <>
          &nbsp;
          <Chip
            className={styles.smsIndicator}
            label={smsCount}
            color="secondary"
          />
        </>,
      );
    } else {
      setSMSIndicator(<></>);
    }
  }, [smsCount]);

  useEffect(() => {
    fetchSMSIndicator();
    const interval = setInterval(() => fetchSMSIndicator(), REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  return (
    <Sidebar>
      <h6
        data-cy="sidebar-health-overview-section"
        className="clickable"
        onClick={() => setHealthExpanded(!isHealthExpanded)}
      >
        <CollapseIcon active={isHealthExpanded} />
        {translate('sidebar.healthProfile')}
      </h6>
      {isHealthExpanded && (
        <ul>
          {showMemberDashboard && patient.status === MemberStatus.ACTIVE && (
            <SidebarLink
              active={pathname.includes('/dashboard')}
              href={`${baseUrl}/dashboard`}
              dataCy="sidebar-nav-clinical-profile-dashboard"
              text={translate('sidebar.dashboard')}
            />
          )}
          <SidebarLink
            active={pathname.includes('/events')}
            href={`${baseUrl}/events`}
            dataCy="sidebar-nav-clinical-profile-events"
            text={translate('sidebar.memberEvents')}
          />
          <SidebarLink
            active={pathname.includes('/tasks')}
            href={`${baseUrl}/tasks`}
            dataCy="sidebar-nav-clinical-profile-tasks"
            text={translate('sidebar.memberTasks')}
          />
          <SidebarLink
            active={pathname.includes('encounters')}
            href={`${baseUrl}/encounters`}
            dataCy="sidebar-nav-clinical-profile-encounters"
            text={translate('sidebar.encounters')}
          />
          <SidebarLink
            active={pathname.includes('health-reports')}
            href={`${baseUrl}/health-reports`}
            dataCy="sidebar-nav-clinical-profile-health-reports"
            text={translate('sidebar.healthReports')}
          />
          <SidebarLink
            active={pathname.includes('personal-details')}
            href={`${baseUrl}/personal-details`}
            dataCy="sidebar-nav-clinical-profile-personal-details"
            text={translate('sidebar.memberDetails')}
          />
          {showTocPage && (
            <SidebarLink
              active={pathname.includes('toc-list')}
              href={`${baseUrl}/toc-list`}
              dataCy="sidebar-nav-clinical-profile-toc-list"
              text={translate('sidebar.tocList')}
            />
          )}
          <SidebarLink
            active={pathname.includes('network')}
            href={`${baseUrl}/network`}
            dataCy="sidebar-nav-clinical-profile-network"
            text={translate('sidebar.memberNetwork')}
          />
          <SidebarLink
            active={pathname.includes('clinical-summary')}
            href={`${baseUrl}/clinical-summary`}
            dataCy="sidebar-nav-clinical-profile-clinical-summary"
            text={translate('sidebar.clinicalSummary')}
          />
          <SidebarLink
            active={pathname.includes('biometrics')}
            href={`${baseUrl}/biometrics`}
            dataCy="sidebar-nav-clinical-profile-biometrics"
            text={translate('sidebar.vitals')}
          />
          <NavItem
            active={!isObsExpanded && isInObservations(pathname)}
            onClick={() => setObsExpanded(!isObsExpanded)}
            data-cy="sidebar-nav-clinical-profile-observations"
          >
            <CollapseIcon active={isObsExpanded} />
            {translate('sidebar.observations')}
          </NavItem>
          {isObsExpanded && (
            <ul>
              {ObservationCategory.asArray.map((category) => (
                <SidebarLink
                  key={category.valueOf()}
                  active={pathname.includes(category.valueOf())}
                  href={`${baseUrl}/observations/${category.valueOf()}`}
                  dataCy={`sidebar-nav-clinical-profile-${category.valueOf()}`}
                  text={category.toString()}
                />
              ))}
            </ul>
          )}
          <SidebarLink
            active={pathname.includes('documents')}
            href={`${baseUrl}/documents`}
            dataCy="sidebar-nav-clinical-profile-documents"
            text={translate('sidebar.documents')}
          />
          <SidebarLink
            active={pathname.includes('chats/sms')}
            href={`${baseUrl}/chats/sms`}
            dataCy="sidebar-nav-clinical-profile-chat-sms"
            text={translate('sidebar.sms')}
          >
            {smsIndicator}
          </SidebarLink>
        </ul>
      )}
      <h6
        data-cy="sidebar-phip-profile-section"
        className="clickable"
        onClick={() => setPhipExpanded(!isPhipExpanded)}
      >
        <CollapseIcon active={isPhipExpanded} />
        {translate('sidebar.phipProfile')}
      </h6>
      {isPhipExpanded && (
        <ul>
          <SidebarLink
            active={pathname.includes('phip-profile')}
            href={`${baseUrl}/phip-profile`}
            dataCy="sidebar-nav-clinical-profile-risk-profile"
            text={translate('sidebar.phipManager')}
          />
        </ul>
      )}
      <h6
        data-cy="sidebar-phip-profile-section"
        className="clickable"
        onClick={() => setLinksExpanded(!isLinksExpanded)}
      >
        <CollapseIcon active={isLinksExpanded} />
        {translate('sidebar.helpfulLinks')}
      </h6>
      {isLinksExpanded && (
        <ul className={styles.externalLinksSection}>
          <Link
            className="external-link"
            data-cy="sidebar-nav-member-event-link"
            href={LINKS.USER_FRIENDLY}
            openNewTab
          >
            <Icon className="fa fa-external-link" />
            {translate('sidebar.links.userFriendly')}
          </Link>
          <Link
            className="external-link"
            data-cy="sidebar-nav-elation-link"
            href={LINKS.E_CLINICAL_WORKS}
            openNewTab
          >
            <Icon className="fa fa-external-link" />
            {translate('sidebar.links.eClinicalWorks')}
          </Link>
        </ul>
      )}
    </Sidebar>
  );
});

export default MemberDashboardSidebar;
