import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import RefreshIcon from '@mui/icons-material/Autorenew';
import MedicationIcon from '@mui/icons-material/DescriptionOutlined';
import { ClassNameMap, makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { CollapseIcon, Colors, Fonts, Table, Tooltip } from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

import { MAX_DASHBOARD_TABLE_ITEMS } from './contansts';

type Data = {
  label: string;
  tooltip?: string;
};

type Props = {
  className?: string;
  data: Data[];
  selectedTab: string;
  updatedAt?: string;
  showTooltip?: boolean;
};

const CLINICAL_SUMMARY_COLUMNS = (
  { showTooltip }: Props,
  styles: ClassNameMap<'tableLabel'>,
) => {
  const columns: Column[] = [
    {
      headerName: '',
      component: ({ label }: Data) =>
        label ? (
          <Tooltip text={label}>
            <span className={styles.tableLabel}>{label}</span>
          </Tooltip>
        ) : (
          EMPTY
        ),
    },
  ];

  if (showTooltip) {
    columns.push({
      headerName: '',
      component: (data: Data) => (
        <Tooltip text={data.tooltip || ''}>
          <div className="flex">
            <MedicationIcon htmlColor={Colors.iconGreen} />
          </div>
        </Tooltip>
      ),
      width: 20,
    });
  }

  return columns;
};

const useStyles = makeStyles({
  table: {
    marginTop: 1,
  },
  tableContainer: {
    display: 'flex',
    marginBottom: '1rem',
    minHeight: '2rem',
    maxHeight: '16.55rem',
    overflow: 'hidden',

    transition: 'max-height 0.3s ease',
  },
  updated: {
    color: Colors.textGray,
    fontSize: `calc(${Fonts.fontSize} * 0.625)`,
    fontStyle: 'italic',
  },
  seeMore: {
    color: Colors.textGreen,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
  },
  tableLabel: {
    display: 'block',
    maxWidth: 'calc((100vw - 32rem) / 3 - 10rem)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 'fit-content',
  },
});

export const ClinicalSummaryTable = (props: Props) => {
  const styles = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const columns = useMemo(() => CLINICAL_SUMMARY_COLUMNS(props, styles), [
    props.showTooltip,
  ]);

  useEffect(() => setOpen(false), [props.data]);

  const getEmptyText = () => {
    if (props.selectedTab === 'clinical-summary-problems')
      return translate('memberProfile.dashboard.noProblemList');
    if (props.selectedTab === 'clinical-summary-dme')
      return translate('memberProfile.dashboard.noDMEs');
    if (props.selectedTab === 'clinical-summary-medications')
      return translate('memberProfile.dashboard.noMedications');
    return '';
  };

  return (
    <div className={props.className}>
      <div
        className={styles.tableContainer}
        style={{
          maxHeight: open ? `${props.data.length * 3.3 + 1}rem` : undefined,
        }}
      >
        <Table
          className={styles.table}
          config={{
            columns,
            compact: true,
            data: props.data,
            paginationOptions: [-1],
          }}
          empty={getEmptyText()}
          whiteBackground
        />
      </div>
      <div className="flex spaced">
        {props.data.length > MAX_DASHBOARD_TABLE_ITEMS ? (
          <a
            className={classNames('flex middle min-gap', styles.seeMore)}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(!open);
            }}
          >
            <CollapseIcon open={open} htmlColor={Colors.textGreen} />
            {translate(`memberProfile.dashboard.${open ? 'hide' : 'see'}More`, {
              count: props.data.length - MAX_DASHBOARD_TABLE_ITEMS,
            })}
          </a>
        ) : (
          <div />
        )}
        {props.updatedAt ? (
          <div className="flex min-gap">
            <RefreshIcon htmlColor={Colors.iconGray} fontSize="small" />
            <span className={styles.updated}>
              {translate('memberProfile.dashboard.lastUpdatedECW')}
            </span>
            <span className={classNames('bold', styles.updated)}>
              {props.updatedAt}
            </span>
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};
