import React from 'react';
import { WidgetProps } from '../types';
import { Checkbox } from 'styleguide';

const CheckboxWidget = (props: WidgetProps) => {
  const {
    formContext: { readonly },
    options,
  }: WidgetProps = props;

  if (readonly) {
    if (!props.value) {
      return null;
    }

    return <p>{props.label}</p>;
  }

  return (
    <Checkbox
      onChange={props.onChange}
      label={options.label || props.label}
      checked={props.value}
    />
  );
};

export default CheckboxWidget;
