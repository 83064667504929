import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ForwardArrowIcon from '@mui/icons-material/ArrowForwardIos';
import ContactIcon from '@mui/icons-material/ContactPageOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { makeStyles } from '@mui/styles';

import { Language } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';
import { DATE_FORMAT_SHORT } from '@vestahealthcare/common/utils/constants';

import { TagList } from 'styleguide';
import {
  BrandMemberIcon,
  Chip,
  IconButton,
  MemberProgramExtensions,
} from 'styleguide-v2';

import { BrandBanner } from 'dash/src/components/BrandBanner';
import { fetchPatientById } from 'dash/src/redux/slices/MemberInfoSlice';
import { useSelector } from 'dash/src/redux/store';

import CareTracks from './CareTracks';
import { ChatButton } from './ChatButton';
import ContactInfoModal from './ContactInfoModal';
import { EventsInfo } from './EventsInfo';
import { IncidentButton } from './IncidentButton';
import { MemberWarnings } from './MemberWarnings';
import { PatientNote } from './PatientNote';
import { PreferredContactInfo } from './PreferredContactInfo';
import { VestaCoreLiteTag } from './VestaCoreLiteTag';
import './main.less';

const useStyles = makeStyles({
  banner: {
    width: '100vw',
  },
  brandIcon: {
    marginLeft: '.8rem',
  },
});

export const MemberProfileHeader = () => {
  const {
    showHouseHoldMembers,
    showIncidents,
    showMemberWarnings,
  } = useFlags();
  const dispatch = useDispatch();
  const styles = useStyles();

  const [showContactModal, setShowContactModal] = useState<boolean>(false);

  const careTracks = useSelector((state) => state.memberInfoSlice.careTracks);
  const patient = useSelector((state) => state.memberInfoSlice.patient);
  const household = useSelector(
    (state) => state.memberInfoSlice.patient?.householdMembers,
  );

  if (!patient) {
    return null;
  }

  const refetchPatient = async (patient: Patient) => {
    dispatch(fetchPatientById(patient.id));
  };

  return (
    <div id="page-header" className="member-header">
      <div className="header-wrapper">
        <div className="patient-info" data-cy="patient-name-page-header">
          <h1>
            {patient.nameWithId}
            {(patient?.isVestaCoreLiteOrVPC() || patient.test) && (
              <VestaCoreLiteTag patient={patient} />
            )}
            {patient?.isBrandCareAtHome() && (
              <BrandMemberIcon
                brand="careAtHome"
                className={styles.brandIcon}
                size="m"
              />
            )}
            <IconButton
              tooltip={translate('memberProfile.header.actions.careTeam')}
              onClick={() => setShowContactModal(true)}
              size="small"
            >
              <ContactIcon fontSize="large" color="action" />
            </IconButton>
            {showHouseHoldMembers && household && !!household?.length && (
              <Chip
                color="secondary"
                onClick={
                  household.length === 1
                    ? () =>
                        window.open(
                          `#/patients/${household[0].linkedMember.id}`,
                          '_blank',
                          'noreferrer',
                        )
                    : undefined
                }
                title={
                  household.length !== 1 ? (
                    <>
                      {household.map(({ id, linkedMember, relationship }) => (
                        <p
                          className="no-margin white"
                          key={`member-${linkedMember.id}-household-${id}`}
                        >
                          {relationship.description}: {linkedMember.fullName} (
                          <a
                            className="lighten"
                            href={`#/patients/${linkedMember.id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {linkedMember.id}
                          </a>
                          )
                        </p>
                      ))}
                    </>
                  ) : (
                    household[0].relationship.description
                  )
                }
                type="contained-2"
              >
                <div className="flex middle gap">
                  <GroupOutlinedIcon />
                  {household.length === 1
                    ? household[0].linkedMember.nameWithId
                    : translate('memberProfile.header.householdCount', {
                        count: household.length,
                      })}
                  {household.length === 1 && <ForwardArrowIcon />}
                </div>
              </Chip>
            )}
          </h1>
          <div className="small subtitle">
            <TagList
              tags={patient
                .getTopThreeLanguages()
                .map((lang: Language) => lang.toString())}
              defaultTag="LANGUAGE UNKNOWN"
            />
            {(patient.age || patient.birthDate) && (
              <span data-cy="patient-age-header">
                {patient.birthDate &&
                  `DOB: ${moment(patient.birthDate).format(DATE_FORMAT_SHORT)}`}
                &nbsp;
                {patient.age &&
                  `(${translate('global.yearsOld', { age: patient.age })})`}
              </span>
            )}
            {patient.gender && (
              <span data-cy="patient-gender-header">
                {patient.gender.toString()}
              </span>
            )}
            {patient.owner && (
              <span data-cy="header-rn-owner">
                {translate('global.rn')}:{patient.owner.fullName}
              </span>
            )}
            <span data-cy="header-plan-name">
              {patient.activeReferrals
                ?.map((ref) => ref.organization.abbr || ref.organization.name)
                .join(', ')}
            </span>
            {!!patient.programExtensions?.length && (
              <span>
                <MemberProgramExtensions
                  data-cy="program-extension-name"
                  programExtensions={patient.getSortedProgramExtensions()}
                />
              </span>
            )}
            {patient.status && (
              <span className="patient-status" data-cy="header-patient-status">
                {patient.status.toString()}
              </span>
            )}
          </div>
          <PatientNote patient={patient} onSubmit={refetchPatient} />
        </div>
        <div className="patient-alerts-conditions-container">
          <ChatButton patient={patient} />
          {showIncidents && (
            <span className="margin-right">
              <IncidentButton member={patient} />
            </span>
          )}
          {!showMemberWarnings && <EventsInfo />}
          <PreferredContactInfo patient={patient} />
          {showMemberWarnings && <MemberWarnings patient={patient} clickable />}
          {!showMemberWarnings && <CareTracks careTracks={careTracks} />}
        </div>
      </div>

      <BrandBanner className={styles.banner} member={patient} />

      <ContactInfoModal
        member={patient}
        open={showContactModal}
        onClose={() => setShowContactModal(false)}
      />
    </div>
  );
};

export default MemberProfileHeader;
