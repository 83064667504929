import config from 'dash/environments';
import { Build, AppOS } from './ReleaseInfo';

type AppCenterUrlType = {
  ios: string;
  android?: string;
};

export enum DashboardType {
  TABLET = 'tablet',
  SMARTPHONE = 'smartphone',
}

const headers = new Headers({
  'X-API-Token': config.appCenter.apiToken,
});

const getKey = (appOS: AppOS, id: string | number) => `${appOS}-${id}`;

const addOSAndKey = (builds: Build[], appOS: AppOS) =>
  builds.map((r: Build) => ({ ...r, appOS, key: getKey(appOS, String(r.id)) }));

export const getAppCenterUrl = (
  type: DashboardType,
  id: string | number = '',
): AppCenterUrlType => {
  const url = {
    tablet: {
      ios: `https://api.appcenter.ms/v0.1/apps/vesta-healthcare/HometeamNonProd/releases/${id}`,
    },
    smartphone: {
      ios: `https://api.appcenter.ms/v0.1/apps/vesta-healthcare/VestaPhoneiOSNonProd/releases/${id}`,
      android: `https://api.appcenter.ms/v0.1/apps/vesta-healthcare/VestaPhoneAndroidNonProd/releases/${id}`,
    },
  };

  return url[type];
};

const fetchData = (url: string) =>
  fetch(url, { headers }).then((res) => res.json());

export const getReleases = async (type: DashboardType) => {
  const { ios, android } = getAppCenterUrl(type);

  const iosReleases = await fetchData(ios);

  if (android) {
    const androidReleases = await fetchData(android);
    return [
      ...addOSAndKey(iosReleases, AppOS.IOS),
      ...addOSAndKey(androidReleases, AppOS.ANDROID),
    ];
  }

  return addOSAndKey(iosReleases, AppOS.IOS);
};

export const getReleaseDetails = async (
  type: DashboardType,
  os: AppOS,
  id: string | number,
) => {
  const { ios, android } = getAppCenterUrl(type, id);
  const isIOS = os === AppOS.IOS;

  if (isIOS) {
    const iosDetails = await fetchData(ios);
    return { ...iosDetails, key: getKey(os, id) };
  }

  if (!android) {
    throw new Error('Android not supported yet.');
  }

  const androidDetails = await fetchData(android);
  return { ...androidDetails, key: getKey(os, id) };
};
