export class PodReportingData {
  id = 0;

  name = '';

  total = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default PodReportingData;
