import { ConversationStatus } from '../enums';
import { make, makeArray } from './Model';
import { Employee } from './Employee';
import { Message } from './Message';
import { PhoneUser } from './PhoneUser';

export class Conversation {
  createdAt = 0;

  id = 0;

  lastConversation = false;

  lastMessage?: Message;

  readAt = 0;

  readBy?: Employee;

  status: ConversationStatus;

  closedAt = 0;

  closedBy?: Employee;

  userPhone = '';

  userPhones: PhoneUser[];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.lastMessage = make(obj.lastMessage, Message);
    this.userPhones = makeArray(obj.userPhones, PhoneUser);
    this.readBy = make(obj.userPhones, Employee);
    this.closedBy = make(obj.closedBy, Employee);
    this.status = ConversationStatus.byKey[obj.status];
  }

  isClosed() {
    return this.status === ConversationStatus.CLOSED;
  }

  isUnread() {
    return !this.readAt && this.status === ConversationStatus.OPEN;
  }
}

export default Conversation;
