import React from 'react';
import { ClinicalFigure } from 'styleguide';
import { FieldProps } from '../types';
import { isArray, isObject, isString, isNumber } from 'lodash';

import BodyPart from '@vestahealthcare/common/enums/BodyPart';

// Fetch all values from the form given a path.
// It will flat any array found in the path
const getArrayValues = (formData: any, path: string): any => {
  if (isString(formData) || isNumber(formData)) {
    return [formData];
  }

  if (isArray(formData)) {
    return formData.flatMap((item) => getArrayValues(item, path));
  }

  if (isObject(formData)) {
    const formDataObject: { [key: string]: any } = formData;
    const pathParts = path ? path.split(/\./) : [];
    if (pathParts && pathParts[0]) {
      return getArrayValues(formDataObject[pathParts[0]], pathParts[1]);
    }
    return [formData];
  }

  return [];
};

const ClinicalFigureSummaryField = (props: FieldProps) => {
  const {
    schema: { dataSource },
    formContext: { formData },
  } = props;
  const values = dataSource ? getArrayValues(formData, dataSource) : [];
  const bodyParts = values.map((item: string) => BodyPart.byKey[item]);

  return (
    <ClinicalFigure
      initialSelection={bodyParts}
      disabled
      multiple
      hideSelectionText
    />
  );
};

export default ClinicalFigureSummaryField;
