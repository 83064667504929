import moment, { Moment } from 'moment';

import { BaseEnum } from './BaseEnum';
import { makeArray } from './Model';

export class IncidentHospDetail {
  admitDate: Moment;

  dischargeDate?: Moment;

  facilityName = '';

  followUpAppointmentTypes?: BaseEnum[];

  medicationChange = false;

  medicationChangeDescription = '';

  constructor(obj: any) {
    Object.assign(this, obj);
    this.admitDate = moment.unix(obj.admitDate);
    this.dischargeDate = obj.dischargeDate
      ? moment.unix(obj.dischargeDate)
      : undefined;
    this.followUpAppointmentTypes = makeArray(
      obj.followUpAppointmentTypes,
      BaseEnum,
    );
  }
}

export default IncidentHospDetail;
