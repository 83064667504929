import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  CareTeamCandidate,
  CareTeamMemberType,
  CareTeamPersonExtended,
  Patient,
  PatientSearch,
  UserPhone,
} from '@vestahealthcare/common/models';
import CareTeamMemberSpeciality from '@vestahealthcare/common/models/CareTeamMemberSpeciality';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import {
  Fonts,
  IconButton,
  Label,
  MemberInfo,
  PanelInfo,
  Select,
  SwitchGroup,
  TextArea,
  TextInput,
} from 'styleguide-v2';

import { EditPhones } from 'dash/src/components/EditPhones';
import { SearchMember } from 'dash/src/components/SearchMember';
import { CacheServices } from 'dash/src/services';
import { PostQuickAddParams } from 'dash/src/services/CareTeamServices';

import { SearchTable } from '../../components/SearchTable';

type Props = {
  candidate?: CareTeamCandidate;
  onChange: (ctp: Partial<PostQuickAddParams>) => void;
  person: CareTeamPersonExtended;
  selectedMember?: Patient;
  submitted: boolean;
};

const useStyles = makeStyles({
  addButon: {
    height: 'fit-content',
    width: 'fit-content',
  },
  deleteIcon: {
    '&&': {
      alignSelf: 'baseline',
      padding: '0.125rem',
    },
  },
  disabledSwitches: {
    alignSelf: 'end',
  },
  disabled: {
    opacity: 0.5,
  },
  searchButton: {
    alignSelf: 'center',
    height: 'fit-content',
    width: '9rem',
  },
  separator: {
    margin: 'auto',
    width: '99%',
  },
  subtitle: {
    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: Fonts.fontSize,
      fontWeight: 500,
    },
  },
});

export const InfoStep = ({
  candidate,
  onChange,
  person,
  selectedMember,
  submitted,
}: Props) => {
  const styles = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSubtypes, setLoadingSubtypes] = useState<boolean>(false);

  const [memberFound, setMemberFound] = useState<PatientSearch | undefined>(
    selectedMember,
  );

  const [campaignEnabled, setCampaignEnabled] = useState<boolean>(false);
  const [emergencyContact, setEmergencyContact] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>();
  const [hciReporter, setHCIReporter] = useState<boolean>(false);
  const [healthCareProxy, setHealthCareProxy] = useState<boolean>(false);
  const [
    healthCareProxyFileLocation,
    setHealthCareProxyFileLocation,
  ] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [notes, setNotes] = useState<string>();
  const [phiAccess, setPhiAccess] = useState<boolean>(false);
  const [phiExceptions, setPhiExceptions] = useState<boolean>(false);
  const [phones, setPhones] = useState<UserPhone[]>([]);
  const [preferredContact, setPreferredContact] = useState<boolean>(false);
  const [searchMember, setSearchMember] = useState<Patient>();
  const [subtype, setSubtype] = useState<BaseEnum>();
  const [subtypes, setSubtypes] = useState<
    (BaseEnum | CareTeamMemberSpeciality)[]
  >([]);
  const [subtypeMultiple, setSubtypeMultiple] = useState<BaseEnum[]>([]);
  const [subtypeOther, setSubtypeOther] = useState<string>();
  const [type, setType] = useState<CareTeamMemberType>();
  const [types, setTypes] = useState<CareTeamMemberType[]>([]);

  const getInitialData = async () => {
    setLoading(true);
    const types = await CacheServices.getCareTeamMemberTypes();
    setTypes(types);
    setLoading(false);
  };

  const getSubTypes = async () => {
    let subtypes = [] as (BaseEnum | CareTeamMemberSpeciality)[];
    setLoadingSubtypes(true);

    switch (type?.id) {
      case CareTeamMemberType.AIDE:
        subtypes = await CacheServices.getCareTeamMemberAideTypes();
        break;
      case CareTeamMemberType.FAMILY_FRIEND:
        subtypes = await CacheServices.getCareTeamMemberFamilyRelationShips();
        break;
      case CareTeamMemberType.PROVIDER:
        subtypes = await CacheServices.getCareTeamMemberProviderSpecialities();
        break;
      case CareTeamMemberType.OTHER:
        subtypes = await CacheServices.getCareTeamMemberOtherRelationships();
        break;
      default:
        subtypes = [];
    }

    setLoadingSubtypes(false);
    setSubtypes(subtypes);
  };

  const validatePhones = () =>
    !phones?.find(({ phone }) => !phone?.number !== !phone?.type);

  const fillCandidateInfo = (candidate: CareTeamCandidate) => {
    setType(candidate.type);
    if (candidate.type?.id === CareTeamMemberType.AIDE) {
      setSubtype(candidate.aideType);
    } else if (candidate.type?.id === CareTeamMemberType.FAMILY_FRIEND) {
      setSubtype(candidate.relationship);
    } else if (candidate.type?.id === CareTeamMemberType.OTHER) {
      setSubtype(candidate.otherRelationship);
      setSubtypeOther(candidate.relationshipOther);
    } else if (candidate.type?.id === CareTeamMemberType.PROVIDER) {
      setSubtypeMultiple(
        candidate.specialities?.map(({ specialityType }) => specialityType) ||
          [],
      );
      setSubtypeOther(
        candidate.specialities?.find(
          ({ specialityType }) => specialityType.id === BaseEnum.OTHER,
        )?.otherSpeciality || '',
      );
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (type) {
      getSubTypes();
      if (candidate?.type === type) {
        fillCandidateInfo(candidate);
      } else {
        setSubtype(undefined);
        setSubtypeMultiple([]);
        setSubtypeOther('');
      }
    }
  }, [type]);

  useEffect(() => {
    setCampaignEnabled(false);
    setEmergencyContact(false);
    setHCIReporter(false);
    setHealthCareProxy(false);
    setHealthCareProxyFileLocation('');
    setNotes('');
    setPhiAccess(false);
    setPhiExceptions(false);
    setPreferredContact(false);
    setSearchMember(new Patient({}));
    setSubtype(undefined);
    setType(undefined);
  }, [memberFound]);

  useEffect(() => {
    onChange({
      campaignEnabled,
      careTeamPersonId: person?.id ? person.id : undefined,
      careTeamCandidateId: candidate ? candidate.id : undefined,
      emergencyContact,
      firstName: person?.id ? undefined : firstName,
      hciNotifications: hciReporter,
      healthCareProxy,
      healthCareProxyFileLocation,
      lastName: person?.id ? undefined : lastName,
      memberId: memberFound?.id,
      notes,
      phiAccess,
      phiExceptions,
      phones,
      preferred: preferredContact,
      aideTypeId: type?.id === CareTeamMemberType.AIDE ? subtype : undefined,
      relationshipId:
        type?.id === CareTeamMemberType.FAMILY_FRIEND ? subtype : undefined,
      otherRelationshipId:
        type?.id === CareTeamMemberType.OTHER ? subtype : undefined,
      specialityIds:
        type?.id === CareTeamMemberType.PROVIDER ? subtypeMultiple : undefined,
      specialityOther:
        type?.id === CareTeamMemberType.PROVIDER ? subtypeOther : undefined,
      relationshipOther:
        type?.id === CareTeamMemberType.OTHER ? subtypeOther : undefined,
      type,
    });
  }, [
    emergencyContact,
    firstName,
    healthCareProxy,
    healthCareProxyFileLocation,
    lastName,
    notes,
    phiAccess,
    phiExceptions,
    hciReporter,
    campaignEnabled,
    phones,
    preferredContact,
    subtype,
    subtypeMultiple,
    subtypeOther,
    type,
  ]);

  useEffect(() => {
    setFirstName(person.firstName);
    setLastName(person.lastName);
    setPhones(person?.phones);
  }, [person]);

  useEffect(() => {
    if (candidate) {
      fillCandidateInfo(candidate);
    }
  }, [candidate]);

  const ctpHasAlreadyMember = person?.hasAlreadyLinkedMember(memberFound);
  const disabledFields = ctpHasAlreadyMember || !memberFound;

  return (
    <div className="grid-wrapper fit">
      {!!person?.id && (
        <>
          <span className="grid-span-12">
            {translate('careTeam.steps.selectedCTP')}:
          </span>
          <SearchTable className="grid-span-12" people={[person]} hideSelect />
        </>
      )}
      {!person?.id && (
        <>
          <span className="grid-span-12">
            {translate('careTeam.steps.enterCTPInfo')}
          </span>
          <TextInput
            className="grid-span-6"
            data-cy="care-team-steps-first-name"
            error={submitted && !firstName}
            label={translate('careTeam.common.firstName')}
            onChange={setFirstName}
            value={firstName}
            required
          />
          <TextInput
            className="grid-span-6"
            data-cy="care-team-steps-last-name"
            error={submitted && !lastName}
            label={translate('careTeam.common.lastName')}
            onChange={setLastName}
            value={lastName}
            required
          />

          <EditPhones
            className="grid-span-12"
            phones={phones}
            onChange={(phones) => setPhones([...phones])}
            showBusinessType
            submitted={submitted && !validatePhones()}
          />
          <hr className={classNames(styles.separator, 'grid-span-12')} />
        </>
      )}

      <br />

      {!selectedMember && (
        <>
          <span className="grid-span-12">
            {translate('careTeam.steps.searchMemberToLink')}
          </span>

          <SearchMember
            className="grid-span-12"
            disabled={!!memberFound}
            onChange={setMemberFound}
            placeholder={translate('careTeam.steps.searchMemberBy')}
            value={searchMember}
          />
        </>
      )}

      <Label
        className={classNames('grid-span-12', !memberFound && styles.disabled)}
      >
        {translate('careTeam.common.name')}
      </Label>
      <span
        className={classNames(
          'grid-span-12 flex gap',
          !memberFound && styles.disabled,
        )}
      >
        {memberFound ? (
          <>
            <MemberInfo
              link={`#/patients/${memberFound?.id}`}
              member={memberFound}
            />
            {!selectedMember && (
              <IconButton
                className={styles.deleteIcon}
                onClick={() => setMemberFound(undefined)}
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            )}
          </>
        ) : (
          <p>{EMPTY}</p>
        )}
      </span>

      {ctpHasAlreadyMember && (
        <PanelInfo
          className="grid-span-12"
          type="warning"
          title={translate('careTeam.steps.memberAlreadyLinked')}
        />
      )}

      <Select
        className={classNames('grid-span-6', disabledFields && styles.disabled)}
        disabled={disabledFields}
        disableClearable
        error={submitted && !type}
        getItemLabel={({ description }) => description}
        items={types}
        label={translate('careTeam.common.role')}
        loading={loading}
        onChange={setType}
        value={type}
        required
      />

      {type?.id === CareTeamMemberType.PROVIDER ? (
        <Select
          className={classNames(
            'grid-span-6',
            disabledFields && styles.disabled,
          )}
          disabled={disabledFields}
          error={submitted && !subtypeMultiple?.length}
          getItemLabel={({ description }) => description}
          items={subtypes}
          key="select-multiple"
          label={translate('careTeam.common.subrole')}
          limitTags={1}
          loading={loadingSubtypes}
          multiple
          onChange={setSubtypeMultiple}
          value={subtypeMultiple}
          required
        />
      ) : (
        <Select
          className={classNames(
            'grid-span-6',
            disabledFields && styles.disabled,
          )}
          disabled={disabledFields}
          error={submitted && !subtype}
          getItemLabel={({ description }) => description}
          key="select-single"
          items={subtypes}
          label={translate('careTeam.common.subrole')}
          loading={loadingSubtypes}
          onChange={setSubtype}
          value={subtype}
          required
        />
      )}

      {((type?.id === CareTeamMemberType.PROVIDER &&
        subtypeMultiple?.find(({ id }) => id === 'OTHER')) ||
        (type?.id === CareTeamMemberType.OTHER && subtype?.id === 'OTHER')) && (
        <TextInput
          className="grid-span-12"
          error={submitted && !subtypeOther}
          label={translate(
            `careTeam.common.${
              type?.id === CareTeamMemberType.PROVIDER
                ? 'subroleOther'
                : 'subtypeOther'
            }`,
          )}
          onChange={setSubtypeOther}
          value={subtypeOther}
          required
        />
      )}

      <TextArea
        className={classNames(
          'grid-span-12',
          disabledFields && styles.disabled,
        )}
        disabled={disabledFields}
        label={translate('careTeam.common.notes')}
        onChange={setNotes}
        rows={3}
        value={notes}
      />

      <SwitchGroup
        className={classNames('grid-span-6', disabledFields && styles.disabled)}
        disabled={disabledFields}
        items={[
          {
            label: translate('careTeam.permissions.emergencyContact'),
            checked: emergencyContact,
          },
          {
            label: translate('careTeam.permissions.hciReporter'),
            checked: hciReporter,
          },
          {
            label: translate('careTeam.permissions.preferredContact'),
            checked: preferredContact,
          },
          {
            label: translate('careTeam.permissions.healthCareProxy'),
            checked: healthCareProxy,
          },
          {
            label: translate('careTeam.permissions.phiAccess'),
            checked: phiAccess,
          },
        ]}
        onChange={(items) => {
          setEmergencyContact(!!items[0].checked);
          setHCIReporter(!!items[1].checked);
          setPreferredContact(!!items[2].checked);
          setHealthCareProxy(!!items[3].checked);
          if (!items[3].checked) {
            setHealthCareProxyFileLocation('');
          }
          setPhiAccess(!!items[4].checked);
          if (!items[4].checked) {
            setPhiExceptions(false);
          }
        }}
      />
      <div className={classNames('grid-span-6', styles.disabledSwitches)}>
        <TextInput
          className={classNames(!healthCareProxy && styles.disabled)}
          disabled={!healthCareProxy}
          data-cy="care-team-steps-healthCareProxyFileLocation"
          error={submitted && healthCareProxy && !healthCareProxyFileLocation}
          label={translate('careTeam.permissions.healthCareProxyFileLocation')}
          onChange={setHealthCareProxyFileLocation}
          value={healthCareProxyFileLocation}
          size="small"
        />
        <SwitchGroup
          className={classNames(
            (disabledFields || !phiAccess) && styles.disabled,
          )}
          disabled={disabledFields}
          items={[
            {
              label: translate('careTeam.permissions.phiExceptions'),
              checked: phiExceptions,
              disabled: !phiAccess,
            },
          ]}
          onChange={(items) => {
            setPhiExceptions(!!items[0].checked);
          }}
        />
      </div>
    </div>
  );
};
