import classnames from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';

import { Fonts } from 'styleguide-v2/src/styles/Variables';

type Props = {
  className?: string;
  'data-cy'?: string;
  filterString?: string;
  member: Patient;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  name: {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    fontWeight: 500,
  },
  extraInfo: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 400,
  },
});

export const SelectMemberOption = ({
  className,
  'data-cy': dataCy,
  filterString,
  member,
}: Props) => {
  const styles = useStyles();
  const classes = classnames(className, styles.container);

  const renderMemberDetails = (patient: Patient) => {
    const memberInfo = [
      ...(patient.activeReferrals?.map((ref) =>
        ref.organization.abbr?.toUpperCase(),
      ) || []),
      patient.birthDateFormatted,
    ]
      .filter(Boolean)
      .join(', ');

    const externalID = patient.activeReferrals?.find(
      ({ externalId }) => externalId === filterString?.trim(),
    )?.externalId;

    const idString = externalID
      ? translate('search.externalID', { id: externalID })
      : translate('search.dashID', { id: patient.id });

    return (
      <span
        className={styles.extraInfo}
      >{`${memberInfo} ${idString} - ${patient.status
        ?.toString()
        .toUpperCase()}`}</span>
    );
  };

  return (
    <div className={classes} data-cy={dataCy}>
      <span className={styles.name}>{member.fullName}</span>
      {renderMemberDetails(member)}
    </div>
  );
};

export default SelectMemberOption;
