import React, { useEffect, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { Notification, Patient } from '@vestahealthcare/common/models';
import NotificationIcon from './NotificationIcon';
import NotificationItem from './NotificationItem';
import { Checkbox, Colors, EmptyState, Text } from 'styleguide';
import { makeStyles } from '@material-ui/styles';
import { translate } from '@vestahealthcare/common/i18n';
import {
  fetchNotifications,
  readAllNotifications,
  updateNotification,
} from 'dash/src/services/NotificationServices';
import { fetchMultiplePatients } from '../../../services';
import { useInterval } from 'beautiful-react-hooks';

const useStyles = makeStyles({
  notificationWrapper: {
    overflowY: 'auto',
    maxHeight: '550px',
  },
  notificationsHeader: {
    padding: '15px 20px',
    minWidth: '500px',
    maxWidth: '750px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #ccc',
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.1), 0px 0px 0px 0px rgba(0,0,0,0.6)',
  },
  notificationTitleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  notificationTitle: {
    maerginRight: 'auto',
    fontWeight: 'bold',
    fontSize: '18px',
    margin: '0px 0px 0px 0px',
  },
  markAllRead: {
    marginLeft: 'auto',
    display: 'flex',
    color: Colors.btnBlue,
  },
});

const REFRESH_INTERVAL = 60 * 1000;
const ROWS_PER_PAGE = 15;

const NotificationMenu = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [membersNotifications, setMembersNotifications] = useState<Patient[]>(
    [],
  );
  const [showAll, setShowAll] = useState(true);
  const [unread, setUnread] = useState<Notification[]>([]);
  const styles = useStyles();

  const getMembers = async (id: number[]) => {
    const patients = await fetchMultiplePatients({ id });
    setMembersNotifications(patients);
  };

  const getNotificationData = async () => {
    const { items } = await fetchNotifications({
      seen: showAll ? undefined : false,
      limit: ROWS_PER_PAGE,
      sort: 'seen desc, createdAt desc',
    });
    const membersIds: number[] = [];
    items.forEach((notification) => {
      if (notification.memberId && !membersIds.includes(notification.memberId))
        membersIds.push(notification.memberId);
    });
    if (membersIds.length > 0) getMembers(membersIds);
    setNotifications(items);
  };

  const markAllRead = async () => {
    await readAllNotifications();
    getNotificationData();
  };

  const editNotification = async (notification: Notification) => {
    await updateNotification(notification.id, true);
    getNotificationData();
  };

  useEffect(() => {
    getNotificationData();
  }, [showAll]);

  useEffect(() => {
    setUnread(notifications.filter((n) => !n.seen));
  }, [notifications]);

  useInterval(getNotificationData, REFRESH_INTERVAL);

  return (
    <NavDropdown
      classID="notification-menu"
      title={<NotificationIcon hasNew={unread.length > 0} />}
      noCaret
      className="pull-right"
      id="ht-nav-notification-menu"
    >
      <div className={styles.notificationsHeader}>
        <div className={styles.notificationTitleWrapper}>
          <Text className={styles.notificationTitle}>
            {translate('notification.title')} ({unread.length})
          </Text>
          <Checkbox
            checked={showAll}
            className="slide-toggle"
            icon={showAll ? 'toggle-on' : 'toggle-off'}
            label={translate(
              showAll ? 'notification.showAll' : 'notification.showUnread',
            )}
            onChange={setShowAll}
          />
        </div>
        <div className={styles.markAllRead}>
          <span className="clickable mark-all-read" onClick={markAllRead}>
            {translate('notification.markAllRead')}
          </span>
        </div>
      </div>
      <div className={styles.notificationWrapper}>
        {notifications.map((notif) => (
          <NotificationItem
            key={notif.id}
            data={notif}
            onEdit={editNotification}
            members={membersNotifications}
          />
        ))}
        <EmptyState show={!notifications.length}>
          {translate('notification.noNotifications')}
        </EmptyState>
      </div>
    </NavDropdown>
  );
};

export default NotificationMenu;
