import { Enum } from './Enum';

export class RPMVendorDeviceSize extends Enum {
  translationKey = 'RPMVendorDeviceSize';

  static asArray: RPMVendorDeviceSize[] = [];

  static byKey: { [name: string]: RPMVendorDeviceSize } = {};

  static readonly SMALL = new RPMVendorDeviceSize('SMALL');

  static readonly LARGE = new RPMVendorDeviceSize('LARGE');

  static readonly X_LARGE = new RPMVendorDeviceSize('X_LARGE');

  constructor(public readonly value: string, public readonly filter?: boolean) {
    super();
    RPMVendorDeviceSize.asArray.push(this);
    RPMVendorDeviceSize.byKey[value] = this;
  }
}

export default RPMVendorDeviceSize;
